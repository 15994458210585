import React, { Fragment, useState, useEffect,memo } from 'react'
import { Menu, Dialog, Transition } from '@headlessui/react'
import { BASE_URL } from "../utils/constant"

import clock from '../../Images/clock.svg'
import one from '../../Images/one.png'
import daily_driver from '../../Images/daily_driver.png'
import locationicon from '../../Images/location.svg'
import ReactStars from "react-rating-stars-component";
import cancelImg from '../../Images/cancel.svg'
import tick from '../../Images/tick.svg'

import axios from "axios";
import getCookie from '../utils/SecuredTokens'

function Completed(props) {

    const {
        driverName,
        title,
        bookDate,
        pickupTime,
        destination,
        amount,
        assetLocation,
        bookPicture,
        bookingId
    } = props

    let amountNum = Number(amount);
  
    let userData = getCookie("user");
    let userObject = JSON.parse(userData)
    let token = userObject?.token
    let id = userObject?.id

    let [ratePopOpen, setRatePopOpen] = useState(false)
    const [bg, setBg] = useState(false)
    const [comment, setCommment] = useState('')
    const [lowRate, setLowRate] = useState(false)
    const [mediumRate, setMediumRate] = useState(false)
    const [highRate, setHighRate] = useState(false)
    const [isDriverRated , setIsDriverRated] = useState(false)
    const [ratings, setRatings] = useState('')
    const [driverRating, setDriverRating] = useState('')

    const StarReview = ({rating}) => {
        var starComponents = []
    
        // Full star is whatever the rating will be having in mind we have a max of 5 stars
        var fullStar = Math.floor(+rating)
        // console.log(fullStar)
    
        // No star is whatever is left after fullstar, i.e max number of stars minus rating gives us no star
        var noStar = Math.floor(5 - +rating)
        // console.log(noStar)
    
        // Half star- when you take into context the full star and no star values and you subtract htem from tha max no of stars you get the half star
        var halfStar = 5 - fullStar - noStar
        // console.log(halfStar)
    
        // full star
        for (var i = 0; i < fullStar; i++) {
          starComponents.push(
            <img
              src="https://img.icons8.com/fluency/344/filled-star.png"
              className="starRating__styled"
              alt=""
            />
          )
        }
    
        //half star
        for (var i = 0; i < halfStar; i++) {
          starComponents.push(
            <img
              src="https://img.icons8.com/color/344/star-half-empty.png"
              className="starRating__styled"
              alt=""
            />
          )
        }
    
        //no star
        for (var i = 0; i < noStar; i++) {
          starComponents.push(
            <img
              src="https://img.icons8.com/color/344/star--v1.png"
              className="starRating__styled"
              alt=""
            />
          )
        }
    
        return (
          <div>
            {starComponents}
            {/* <p>{rating}</p> */}
          </div>
        )
    
    }

    const [receivedArray, setReceivedArray] = useState([])




    function handleRatePopup() {
        setRatePopOpen(true)
        setBg(true)
    }
    function closeModal() {
        setRatePopOpen(false)
        setBg(false)
    }


    const ratingChanged = (newRating) => {
        console.log(newRating, 'rate me');
        setRatings(newRating)

        if(0 < newRating < 2.5) {
            setLowRate(true)
            setMediumRate(false)
            setHighRate(false)
        }else if(2 < newRating < 4.5) {
            setLowRate(false)
            setMediumRate(true)
            setHighRate(false)
        }else if (4 < newRating < 6) {
            setLowRate(false)
            setMediumRate(false)
            setHighRate(true)
        }
    };

    const handleComment = () => {
        console.log('newRating');
    }

    const handleBadDriverRating = (e) => {
        e.preventDefault()

        if(driverRating === '2') {
            setDriverRating('')
        }else {
            setDriverRating('2')
        }

    }

    const handleMidDriverRating = (e) => {
        e.preventDefault()

        if(driverRating === '4') {
            setDriverRating('')
        }else {
            setDriverRating('4')
        }

    }

    const handleGoodDriverRating = (e) => {
        e.preventDefault()

        if(driverRating === '6') {
            setDriverRating('')
        }else {
            setDriverRating('6')
        }
    }

    const rateDriver = (e) => {
        e.preventDefault()

        let formData = new FormData();

        formData.append('user_id', userData.id)
        formData.append('booking_id', bookingId)
        formData.append('star_rating', ratings)
        formData.append('comment', comment)
        formData.append('satisfactory_level', driverRating)


        axios({
            method: "post",
            url: `${BASE_URL}/v1/rate-service`,
            data: formData,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            }
        })
        .then((response) => {
            setRatePopOpen(false)
            setBg(false)
            setIsDriverRated(true)
        })
        .catch((error) => {
            console.log(error);
        });

        console.log('rating')
    }


    // GET RATINGS
    const getRatings = () => {
        axios.get(`${BASE_URL}/v1/fetch-customer-rating/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            }
        }).then((response) => {
            setReceivedArray(response.data.data[0])
            console.log(response.data.data[0], 'responsessss')
        }).catch((error) => {
            console.log(error)
        })
    }

    useEffect(() => {
        getRatings()
    }, [isDriverRated])


    return (
        <>
            <div className={bg === true ? " blur-sm contrast-50" : "w-full"}>
                
                <Transition appear show={ratePopOpen} as={Fragment}>
                    <Dialog
                        as="div"
                        className="w-full md:w-11/12  fixed flex justify-center items-center px-6 inset-0 z-0 overflow-y-auto"
                        onClose={closeModal}
                    >
                        <div className="w-full md:w-6/12 min-h-screen px-4 text-center ">
                            {/* <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Dialog.Overlay className="fixed inset-0" />
                            </Transition.Child> */}

                            {/* This element is to trick the browser into centering the modal contents. */}

                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >


                                <div className="bg-black mt-40 p-4 w-full flex flex-col rounded-3xl">

                                    <div className="flex justify-end">
                                        <button className='w-1/12'>
                                            <img src={cancelImg} className='w-[30px]' onClick={closeModal} alt="Cancel Modal" />
                                        </button>
                                    </div>

                                    <div className="w-full flex">
                                        <div className="flex w-full md:w-5/12 p-2 md:p-6">
                                            <div className=" md:w-full md:h-52 bg-white p-2 md:p-4 rounded-xl py-3 flex justify-center items-start flex-col px-4  relative">
                                                <div className="rounded-xl border-gray-400 border flex items-center justify-center mb-1">
                                                    <img src={`https://api.roadlers.com/storage/${bookPicture}`} alt="" className='rounded-xl object-contain object-center w-[100px]'  />
                                                </div>
                                                <h5 className='md:font-bold  md:text-xs font-semibold text-xx text-black mt-1'>{title}</h5>
                                                <hr className='md:w-16 w-12  mt-1 marg border-t border-black' />


                                                <div className="flex justify-between text-xs  mt-2 w-full">
                                                    <h3 className="font-bold">&#8358;
                                                        {amountNum.toLocaleString('en-US', { maximumFractionDigits: 2 })}
                                                    </h3>

                                                    <div className="flex ">
                                                        <img src={locationicon} alt="" className='md:w-4 w-4' />

                                                        <p className="font-bold">{assetLocation}</p>

                                                    </div>

                                                </div>
                                                <div className="flex items-center text-xs  mt-2">
                                                    <div className="flex w-full justify-between">
                                                        <button className='w-full p-1 bg-transparent border border-black  md:text-xs text-xx text-black font-bold rounded-md'>Book Now</button>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="flex flex-col  gap-4 w-8/12 p-6">

                                            <textarea
                                                placeholder="Write Your Comment"
                                                className="text-white border border-white bg-transparent p-4 rounded-xl lg:h-24"
                                                type="text"
                                                value={comment}
                                                rows="4" cols="30"
                                                onChange={e => setCommment(e.target.value)}

                                            />



                                            <p className="text-white text-xs text-left ">
                                                Rate ride and give driver a card, red highly unpleased, yellow average, green - happy with service.
                                            </p>


                                            <div className="flex justify-between items-center w-full">
                                                <div className="w-7/12">
                                                    <ReactStars
                                                        count={5}
                                                        onChange={ratingChanged}
                                                        size={28}
                                                        isHalf={true}
                                                        activeColor="#ffa100"
                                                    />
                                                </div>


                                                <div className="flex justify-between w-5/12">
                                                    <button className="w-6 h-6 bg-red-700 flex justify-content" onClick={handleBadDriverRating}>
                                                        {
                                                            driverRating === '2' &&
                                                            <img src={tick} alt="Tick" />
                                                        }
                                                    </button>
                                                    <button className="w-6 h-6 bg-amber-400 flex justify-content" onClick={handleMidDriverRating}>
                                                        {
                                                            driverRating === '4' &&
                                                            <img src={tick} alt="Tick" />
                                                        }
                                                    </button>
                                                    <button className="w-6 h-6 bg-green-700 flex justify-content" onClick={handleGoodDriverRating}>
                                                        {
                                                            driverRating === '6' &&
                                                            <img src={tick} alt="Tick" />
                                                        }
                                                    </button>

                                                </div>
                                            </div>

                                            <div className="flex justify-end">
                                                <button className="submit-btn bg-[#FFA100] flex justify-center w-2/4 font-bold px-4 py-2 rounded-xl" onClick={rateDriver}>
                                                    Rate now
                                                </button>
                                            </div>



                                        </div>
                                    </div>



                                </div>

                            </Transition.Child>
                        </div>
                    </Dialog>
                </Transition>


                <div className={bg === true ? " blur-sm contrast-50" : "w-full"}>
                    <div className="border border-white rounded-2xl flex gap-2 justify-between">
                        <div className="rounded-2xl bg-white h-full">
                        <img src={`https://api.roadlers.com/storage/${bookPicture}`} alt="" className='lg:w-36 lg:h-36 md:w-36 md:h-full w-28 h-26 rounded-2xl object-contain object-center' />
                        </div>

                        <div className="md:p-2 p-1 w-4/12">
                            <div className="flex flex-col gap-3">
                                <h2 className='lg:text-base md:text-sm text-xs font-bold text-white'>{title}</h2>
                                <hr className="border-t border-white w-3/5 md:w-4/5" />



                                <div className="flex items-center gap-2">
                                    <img src={one} alt="" className='md:w-4 w-3' />
                                    <h4 className='text-white font-regular lg:text-xs md:text-xx text-xx'>{bookDate}</h4>
                                </div>

                                <div className="flex items-center gap-2">
                                    <img src={clock} alt="" className='w-5 md:w-6 -ml-1' />
                                    <h4 className='text-white font-regular lg:text-xs  text-xx -ml-1'>{pickupTime}</h4>
                                </div>

                                <h4 className='text-white font-regular lg:text-xs  text-xx'>{destination}</h4>
                            </div>

                        </div>

                        
                        {
                            (!isDriverRated && receivedArray?.length === 0 )&&
                            <div className="p-1 w-2/12">
                                <div className="flex flex-col w-full items-end justify-end ">
                                    <button
                                        onClick={handleRatePopup}
                                        className='w-full bg-amber-500 font-normal md:font-extrabold text-black w-16 md:w-24 py-2 text-xx md:text-xs rounded-lg lg:mt-20 md:mt-16 mt-12 mr-3'>Rate Driver</button>
                                </div>
                            </div>
                        }


                        {
                            (isDriverRated || receivedArray?.length !== 0) && 

                            <div className="p-1 w-3/12 flex-col flex ">
                                <div className="flex flex-col w-full items-end justify-end ">
                                    <button
                                        className='w-full border border-amber-500 font-bold md:font-extrabold text-[#fff] w-16 md:w-24 py-2 text-xx md:text-xs rounded-lg lg:mt-16 md:mt-12 mt-10 mr-2' disabled={true}
                                    >
                                            Rated
                                    </button>
                                </div>
                                <div className="flex mt-3">
                                    <p className="cardRate__styled flex"><StarReview rating={receivedArray?.star_rating}/></p>
                                </div>
                                
                            </div>
                        }


                    </div>
                </div>
            </div>
        </>
    )
}

export default memo(Completed)