import React, { Fragment, useState, useEffect, useContext, useRef, memo } from 'react'
import { Menu, Dialog, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import Header from '../Header'
import { BASE_URL } from "../utils/constant"
import { baseUrl, fetchData } from '../utils/fetchData'

import dashboard from '../../Images/dashboard.png'
import admin_request from '../../Images/admin_request.svg'
import service_p from '../../Images/service_p.svg'
import reports from '../../Images/reports.svg'
import customer from '../../Images/customers.png'
import notifications from '../../Images/notifications.png'
import download from '../../Images/download.svg'
import car_asset from '../../Images/car_asset.svg'
import icon from '../../Images/icon.svg'
import car from '../../Images/car.png'
import message from '../../Images/message.svg'
import reject from '../../Images/reject.png'
import accept from '../../Images/accept.png'
import pen from '../../Images/pen.svg'
import close from '../../Images/close.png'


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { NavLink, useParams } from "react-router-dom";
import axios from "axios";
import moment from 'moment';
import getCookie from '../utils/SecuredTokens'


function ScreenA(props) {




    let userr = getCookie("user");
    let userObject = JSON.parse(userr)
    let token = userObject?.token



    const { vid } = useParams()


    const { id, image_one, image_two, image_three, image_four, year, name, price, location,
        airport_price, status, display, vehicle_type, user, country,
        asset_id, plate_number, monthly_price, vehicle_address,
        passengers, hours, vehicle_color,
        insurance_option, service, notes, document,
        air_con,
        comeWithDriver,
        travel_available, ownerProfile, msg } = props


    const [providerInfo, setProviderInfo] = useState([]);

    useEffect(() => {
        const fetchProviderDetail = async () => {
            let providerData = await fetchData(`${baseUrl}/v1/list-customers/${user}`);
            setProviderInfo(providerData)
        }

        fetchProviderDetail()
    }, [])



    const [documents, setDocuments] = useState(false);
    const [coverImage, setCoverImage] = useState();
    const [coverImagePreview, setCoverImagePreview] = useState()

    const fileInputRef = useRef()
    const docRef = useRef(null)


    function handleUploadImage(e) {
        e.preventDefault()
        fileInputRef.current.click()
    }

    function handleCancelImage(e) {
        e.preventDefault()
        setCoverImage('')
    }





    let proofOfOwner = document.filter(doc => {
        return doc?.doc_type?.toLowerCase().includes('proof of ownership')
    })

    let hackneyStatusOwner = document.filter(doc => {
        return doc?.doc_type?.toLowerCase().includes('hackney')
    })

    let carInsuranceOwner = document.filter(doc => {
        return doc?.doc_type?.toLowerCase().includes('car insurance')
    })

    let vehicleLicenceOwner = document.filter(doc => {
        return doc?.doc_type?.toLowerCase().includes('vehicle license')
    })

    let roadWorthiness = document.filter(doc => {
        return doc?.doc_type?.toLowerCase().includes('road worthiness')
    })

    const [HackneyStatus, setHackneyStatus] = useState(hackneyStatusOwner[0]?.status);
    const [worthinessStatus, setWorthinessStatus] = useState(roadWorthiness[0]?.status);
    const [VehicleLicenseStatus, setVehicleLicenseStatus] = useState(vehicleLicenceOwner[0]?.status);
    const [insuranceStatus, setInsuranceStatus] = useState(carInsuranceOwner[0]?.status);
    const [proofStatus, setProofStatus] = useState(proofOfOwner[0]?.status);







    const [vehImageStatus, setVehImageStatus] = useState(status);
    const [vehImageDisplay, setVehImageDisplay] = useState(display);

    let [bg, setBg] = useState(false)

    let [vehicleNoteOpen, setVehicleNoteOpen] = useState(false)
    let [noteOpen, setNoteOpen] = useState(false)
    let [licenceNoteOpen, setLicenceOpen] = useState(false)
    let [insuranceNoteOpen, setInsuranceNoteOpen] = useState(false)
    let [worthinessNoteOpen, setWorthinessNoteOpen] = useState(false)
    let [hackneyNoteOpen, setHackneyNoteOpen] = useState(false)

    // get the previous comment data of the providers  document
    let [adminNote, setAdminNote] = useState(proofOfOwner[0]?.comment)
    let [licenceNote, setLicenceNote] = useState(vehicleLicenceOwner[0]?.comment)
    let [insuranceNote, setInsuranceNote] = useState(carInsuranceOwner[0]?.comment)
    let [worthinessNote, setWorthinessNote] = useState(roadWorthiness[0]?.comment)
    let [hackneyNote, setHackneyNote] = useState(hackneyStatusOwner[0]?.comment)


    let [vehicleNote, setVehicleNote] = useState(msg)





    function closeModal() {
        setNoteOpen(false)
        setLicenceOpen(false)
        setInsuranceNoteOpen(false)
        setWorthinessNoteOpen(false)
        setBg(false)
        setHackneyNoteOpen(false)
        setVehicleNoteOpen(false)
    }

    function adminHackneyActionModal() {
        setBg(true)
        setHackneyNoteOpen(true)
    }
    function adminVehicleProfileModal() {
        setBg(true)
        setVehicleNoteOpen(true)
    }


    function adminProofOfOwnerActionModal() {
        setBg(true)
        setNoteOpen(true)
    }

    function adminLicenceActionModal() {
        setBg(true)
        setLicenceOpen(true)
    }



    function adminInsuranceActionModal() {
        setBg(true)
        setInsuranceNoteOpen(true)
    }


    function adminWorthinessActionModal() {
        setBg(true)
        setWorthinessNoteOpen(true)
    }


    function handleAcceptProof(e) {
        e.preventDefault();
        let docId = proofOfOwner[0]?.id
        let formData = new FormData();
        formData.append("id", docId);
        formData.append("comment", proofOfOwner[0]?.comment);
        formData.append("status", 'Accept');
        // formData.append("status", 'Accepted');
        axios({
            method: "post",
            url: `${BASE_URL}/v1/verify-vehicle-document`,
            data: formData,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            },
        }).then(function (response) {
            setProofStatus('Accept')
            const message = response.data.data;

        }).catch(function (error) {
            //handle error
            console.log("error ", error.response.data);
        });
    };

    function handleRejectProof(e) {
        e.preventDefault();
        let docId = proofOfOwner[0]?.id
        let formData = new FormData();
        formData.append("id", docId);
        formData.append("comment", proofOfOwner[0]?.comment);
        formData.append("status", 'Rejected');
        axios({
            method: "post",
            url: `${BASE_URL}/v1/verify-vehicle-document`,
            data: formData,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            },
        }).then(function (response) {
            setProofStatus('Rejected')
            const message = response.data.data;


            let formData = new FormData();
            formData.append("id", id);
            // 4 because the verification of the document shows the car, not the verification of the profile
            formData.append("display", '4');
            formData.append("status", 'Rejected');
            axios({
                method: "post",
                url: `${BASE_URL}/v1/verify-vehicle-profile`,
                data: formData,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    "Access-Control-Allow-Origin": "*",
                },
            }).then(function (response) {
                const message = response.data.data;
                setVehImageStatus('Rejected')
                setVehImageDisplay('4')


            }).catch(function (error) {
                //handle error

            });

        }).catch(function (error) {
            //handle error

        });
    };


    // this function attach a comment to proof of ownwership document via a post request

    function adminProofOfOwnerAction(e) {
        e.preventDefault();
        let docId = proofOfOwner[0]?.id

        let formData = new FormData();
        formData.append("id", docId);
        formData.append("comment", adminNote);

        formData.append("status", proofOfOwner[0]?.status);
        axios({
            method: "post",
            url: `${BASE_URL}/v1/verify-vehicle-document`,
            data: formData,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            },
        }).then(function (response) {

            const message = response.data.data;
            setNoteOpen(false)

        }).catch(function (error) {
            //handle error
            console.log("error ", error.response.data);
        });
    };


    function handleAcceptInsurance(e) {
        e.preventDefault();
        let docId = carInsuranceOwner[0]?.id
        let formData = new FormData();
        formData.append("id", docId);
        formData.append("comment", carInsuranceOwner[0]?.comment);
        formData.append("status", 'Accept');
        // formData.append("status", 'Accepted');
        axios({
            method: "post",
            url: `${BASE_URL}/v1/verify-vehicle-document`,
            data: formData,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            },
        }).then(function (response) {
            setInsuranceStatus('Accept')
            const message = response.data.data;
        }).catch(function (error) {
            //handle error
            console.log("error ", error.response.data);
        });
    };

    function handleRejectInsurance(e) {
        e.preventDefault();

        // set the status of that document to rejected
        let docId = carInsuranceOwner[0]?.id
        let formData = new FormData();
        formData.append("id", docId);
        formData.append("comment", carInsuranceOwner[0]?.comment);
        formData.append("status", 'Rejected');
        axios({
            method: "post",
            url: `${BASE_URL}/v1/verify-vehicle-document`,
            data: formData,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            },
        }).then(function (response) {
            setInsuranceStatus('Rejected')

            const message = response.data.data;


            // set the status of the car to rejected

            // let formData = new FormData();
            // formData.append("id", id);
            // // 4 because the verification of the document shows the car, not the verification of the profile
            // formData.append("display", '4');
            // formData.append("status", 'Rejected');
            // axios({
            //     method: "post",
            //     url: `${BASE_URL}/v1/verify-vehicle-profile`,
            //     data: formData,
            //     headers: {
            //         "Content-Type": "application/json",
            //         Authorization: `Bearer ${token}`,
            //         "Access-Control-Allow-Origin": "*",
            //     },
            // }).then(function (response) {
            //     const message = response.data.data;
            //     setVehImageStatus('Rejected')
            //     setVehImageDisplay('4')


            // }).catch(function (error) {
            //     //handle error
            //     console.log("error ", error.response.data);
            // });
        }).catch(function (error) {
            //handle error
            console.log("error ", error.response.data);
        });
    };

    function adminInsuranceAction(e) {
        e.preventDefault();
        let docId = carInsuranceOwner[0]?.id

        let formData = new FormData();
        formData.append("id", docId);
        formData.append("comment", insuranceNote);

        formData.append("status", carInsuranceOwner[0]?.status);
        axios({
            method: "post",
            url: `${BASE_URL}/v1/verify-vehicle-document`,
            data: formData,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            },
        }).then(function (response) {

            const message = response.data.data;
            setInsuranceNoteOpen(false)

        }).catch(function (error) {
            //handle error
            console.log("error ", error.response.data);
        });
    };


    function handleAcceptVehicleLicense(e) {
        e.preventDefault();
        let docId = vehicleLicenceOwner[0]?.id
        let formData = new FormData();
        formData.append("id", docId);
        formData.append("comment", vehicleLicenceOwner[0]?.comment);
        formData.append("status", 'Accept');
        // formData.append("status", 'Accepted');
        axios({
            method: "post",
            url: `${BASE_URL}/v1/verify-vehicle-document`,
            data: formData,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            },
        }).then(function (response) {
            const message = response.data.data;
            setVehicleLicenseStatus('Accept')

        }).catch(function (error) {
            //handle error
        });
    };

    function handleRejectVehicleLicense(e) {
        e.preventDefault();
        let docId = vehicleLicenceOwner[0]?.id
        let formData = new FormData();
        formData.append("id", docId);
        formData.append("comment", vehicleLicenceOwner[0]?.comment);
        formData.append("status", 'Rejected');
        axios({
            method: "post",
            url: `${BASE_URL}/v1/verify-vehicle-document`,
            data: formData,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            },
        }).then(function (response) {
            const message = response.data.data;
            setVehicleLicenseStatus('Rejected')


            // set the status of the car to rejected

            let formData = new FormData();
            formData.append("id", id);
            // 4 because the verification of the document shows the car, not the verification of the profile
            formData.append("display", '4');
            formData.append("status", 'Rejected');
            axios({
                method: "post",
                url: `${BASE_URL}/v1/verify-vehicle-profile`,
                data: formData,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    "Access-Control-Allow-Origin": "*",
                },
            }).then(function (response) {
                const message = response.data.data;
                setVehImageStatus('Rejected')
                setVehImageDisplay('4')


            }).catch(function (error) {
                //handle error
            });

        }).catch(function (error) {
            //handle error
            console.log("error ", error.response.data);
        });
    };

    // this function attach a comment to licence document via a post request

    function adminLicenceAction(e) {
        e.preventDefault();
        let docId = vehicleLicenceOwner[0]?.id

        let formData = new FormData();
        formData.append("id", docId);
        formData.append("comment", licenceNote);

        formData.append("status", vehicleLicenceOwner[0]?.status);
        axios({
            method: "post",
            url: `${BASE_URL}/v1/verify-vehicle-document`,
            data: formData,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            },
        }).then(function (response) {

            const message = response.data.data;
            setLicenceOpen(false)

        }).catch(function (error) {
            //handle error
            console.log("error ", error.response.data);
        });
    };


    function handleAcceptWorthiness(e) {
        e.preventDefault();
        let docId = roadWorthiness[0]?.id
        let formData = new FormData();
        formData.append("id", docId);
        formData.append("comment", roadWorthiness[0]?.comment);
        formData.append("status", 'Accept');
        // formData.append("status", 'Accepted');
        axios({
            method: "post",
            url: `${BASE_URL}/v1/verify-vehicle-document`,
            data: formData,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            },
        }).then(function (response) {
            const message = response.data.data;
            setWorthinessStatus('Accept')
        }).catch(function (error) {
            //handle error
            console.log("error ", error.response.data);
        });
    };

    function handleRejectWorthiness(e) {
        e.preventDefault();
        let docId = roadWorthiness[0]?.id
        let formData = new FormData();
        formData.append("id", docId);
        formData.append("comment", roadWorthiness[0]?.comment);
        formData.append("status", 'Rejected');
        axios({
            method: "post",
            url: `${BASE_URL}/v1/verify-vehicle-document`,
            data: formData,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            },
        }).then(function (response) {
            const message = response.data.data;
            setWorthinessStatus('Rejected')

            // set the status of the car to rejected

            // let formData = new FormData();
            // formData.append("id", id);
            // // 4 because the verification of the document shows the car, not the verification of the profile
            // formData.append("display", '4');
            // formData.append("status", 'Rejected');
            // axios({
            //     method: "post",
            //     url: `${BASE_URL}/v1/verify-vehicle-profile`,
            //     data: formData,
            //     headers: {
            //         "Content-Type": "application/json",
            //         Authorization: `Bearer ${token}`,
            //         "Access-Control-Allow-Origin": "*",
            //     },
            // }).then(function (response) {
            //     const message = response.data.data;
            //     setVehImageStatus('Rejected')
            //     setVehImageDisplay('4')


            // }).catch(function (error) {
            //     //handle error
            // });
        }).catch(function (error) {
            //handle error
            console.log("error ", error.response.data);
        });
    };


    function adminWorthinessAction(e) {
        e.preventDefault();
        let docId = roadWorthiness[0]?.id

        let formData = new FormData();
        formData.append("id", docId);
        formData.append("comment", worthinessNote);

        formData.append("status", roadWorthiness[0]?.status);
        axios({
            method: "post",
            url: `${BASE_URL}/v1/verify-vehicle-document`,
            data: formData,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            },
        }).then(function (response) {

            const message = response.data.data;
            setWorthinessNoteOpen(false)

        }).catch(function (error) {
            //handle error
            console.log("error ", error.response.data);
        });
    };

    function handleAcceptHackney(e) {
        e.preventDefault();
        let docId = hackneyStatusOwner[0]?.id
        let formData = new FormData();
        formData.append("id", docId);
        formData.append("comment", hackneyStatusOwner[0]?.comment);
        formData.append("status", 'Accept');
        // formData.append("status", 'Accepted');
        axios({
            method: "post",
            url: `${BASE_URL}/v1/verify-vehicle-document`,
            data: formData,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            },
        }).then(function (response) {
            const message = response.data.data;
            setHackneyStatus('Accept')

        }).catch(function (error) {
            //handle error
            console.log("error ", error.response.data);
        });
    };

    function handleRejectHackney(e) {
        e.preventDefault();
        let docId = hackneyStatusOwner[0]?.id
        let formData = new FormData();
        formData.append("id", docId);
        formData.append("comment", hackneyStatusOwner[0]?.comment);
        formData.append("status", 'Rejected');
        axios({
            method: "post",
            url: `${BASE_URL}/v1/verify-vehicle-document`,
            data: formData,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            },
        }).then(function (response) {
            const message = response.data.data;
            console.log('messagemessagemessagemessage', message)
            setHackneyStatus('Rejected')


            // set the status of the car to rejected

            // let formData = new FormData();
            // formData.append("id", id);
            // // 4 because the verification of the document shows the car, not the verification of the profile
            // formData.append("display", '4');
            // formData.append("status", 'Rejected');
            // axios({
            //     method: "post",
            //     url: `${BASE_URL}/v1/verify-vehicle-profile`,
            //     data: formData,
            //     headers: {
            //         "Content-Type": "application/json",
            //         Authorization: `Bearer ${token}`,
            //         "Access-Control-Allow-Origin": "*",
            //     },
            // }).then(function (response) {
            //     const message = response.data.data;
            //     setVehImageStatus('Rejected')
            //     setVehImageDisplay('4')


            // }).catch(function (error) {
            //     //handle error
            //     console.log("error ", error.response.data);
            // });

        }).catch(function (error) {
            //handle error
            console.log("error ", error.response.data);
        });
    };

    function adminHackneyAction(e) {
        e.preventDefault();
        let docId = hackneyStatusOwner[0]?.id

        let formData = new FormData();
        formData.append("id", docId);
        formData.append("comment", hackneyNote);

        formData.append("status", hackneyStatusOwner[0]?.status);
        axios({
            method: "post",
            url: `${BASE_URL}/v1/verify-vehicle-document`,
            data: formData,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            },
        }).then(function (response) {

            const message = response.data.data;
            setHackneyNoteOpen(false)

        }).catch(function (error) {
            //handle error
            console.log("error ", error.response.data);
        });
    };



    function handleVerifyVehicleDoc(e) {
        e.preventDefault();
        let formData = new FormData();
        formData.append("id", id);
        formData.append("display", '1');
        formData.append("status", 'Approved');

        axios({
            method: "post",
            url: `${BASE_URL}/v1/verify-vehicle-profile`,
            data: formData,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            },
        }).then(function (response) {
            const respData = response.data.data;
            const respMessage = response.data.message;


            const notify = () => { toast('Account Successfully Approved') };
            notify()
            setVehImageStatus('Approved')
            setVehImageDisplay('1')

        }).catch(function (error) {
            //handle error
            console.log("error ", error.response.data);
        });
    }

    console.log('proofStatus',proofStatus)
    console.log('VehicleLicenseStatus',VehicleLicenseStatus)
    

    function handleVerifyVehicle(e) {
        e.preventDefault();

        let formData = new FormData();
        formData.append("id", id);

        // 2 because the verification of the document shows the car, not the verification of the profile
        formData.append("display", '2');
        formData.append("status", proofStatus && VehicleLicenseStatus ==='Accept'  ?'Pending':'Rejected');

        axios({
            method: "post",
            url: `${BASE_URL}/v1/verify-vehicle-profile`,
            data: formData,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            },
        }).then(function (response) {
            const respData = response.data.data;
            const respMessage = response.data.message;


            const notify = () => { toast('Vehicle Profile Accepted') };
            notify()
            
            setVehImageStatus('Pending')
            setVehImageDisplay('2')

            docRef.current?.scrollIntoView({ behavior: 'smooth' });


        }).catch(function (error) {
            //handle error
            console.log("error ", error.response.data);
        });
    }




    function adminVehicleAction(e) {
        e.preventDefault();

        let formData = new FormData();
        formData.append("id", id);

        // 2 because the verification of the document shows the car, not the verification of the profile
        formData.append("display", '2');
        formData.append("status_msg", vehicleNote);

        axios({
            method: "post",
            url: `${BASE_URL}/v1/verify-vehicle-profile`,
            data: formData,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            },
        }).then(function (response) {
            const respData = response.data.data;
            const respMessage = response.data.message;

            setVehicleNoteOpen(false)
            const notify = () => { toast('Message Updated') };
            notify()


        }).catch(function (error) {
            //handle error
            console.log("error ", error.response.data);
        });
    }

    function handleAcceptImage(e) {
        e.preventDefault();

        let formData = new FormData();
        formData.append("id", id);

        // 3 because the verification of the document shows the car, not the verification of the profile
        formData.append("display", '3');
        formData.append("status", 'Pending');
        formData.append("notes", 'Pending job');

        axios({
            method: "post",
            url: `${BASE_URL}/v1/verify-vehicle-profile`,
            data: formData,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            },
        }).then(function (response) {
            const message = response.data.data;
            setVehImageStatus('Pending')
            setVehImageDisplay('3')

        }).catch(function (error) {
            //handle error
            console.log("error ", error.response.data);
        });
    }

    function handleRejectImage(e) {
        e.preventDefault();

        let formData = new FormData();
        formData.append("id", id);
        // 4 because the verification of the document shows the car, not the verification of the profile
        formData.append("display", '4');
        formData.append("status", 'Rejected');
        axios({
            method: "post",
            url: `${BASE_URL}/v1/verify-vehicle-profile`,
            data: formData,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            },
        }).then(function (response) {
            const message = response.data.data;
            setVehImageStatus('Rejected')
            setVehImageDisplay('4')


        }).catch(function (error) {
            //handle error
            console.log("error ", error.response.data);
        });
    }


    useEffect(() => {

        if (coverImage) {
            const reader = new FileReader()
            reader.onloadend = () => {
                setCoverImagePreview(reader.result)
            }
            reader.readAsDataURL(coverImage)

            let requestId = id
            let formData = new FormData();
            formData.append("id", requestId);
            formData.append("file", coverImage);

            axios({
                method: "post",
                url: `${BASE_URL}/v1/upload-vehicle-image4`,
                data: formData,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    "Access-Control-Allow-Origin": "*",
                },
            }).then(function (response) {
                const message = response.data.data;
                setVehImageStatus('Pending')


            }).catch(function (error) {
                //handle error
                console.log("error ", error.response.data);
            });


        } else {
            setCoverImagePreview(null)
        }

        console.log('imgddddd', coverImage)



    }, [coverImage]);

    const handlee = () => {
        setCoverImage(null)
    }

    const handleToggleChange = (e) => {
        e.preventDefault()

        let formData = new FormData();
        formData.append("id", id);
       
        formData.append("display", '0');
        formData.append("status", 'Pending');
        axios({
            method: "post",
            url: `${BASE_URL}/v1/verify-vehicle-profile`,
            data: formData,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            },
        }).then(function (response) {
            const message = response.data.data;
            setVehImageStatus('Pending')
            setVehImageDisplay('0')
        }).catch(function (error) {
            //handle error
            console.log("error ", error.response.data);
        });
    }




    function handleToggleChangeProofStatus(e) {
        e.preventDefault();
        let docId = proofOfOwner[0]?.id
        let formData = new FormData();
        formData.append("id", docId);
        formData.append("comment", proofOfOwner[0]?.comment);
        formData.append("status", 'Pending');
        axios({
            method: "post",
            url: `${BASE_URL}/v1/verify-vehicle-document`,
            data: formData,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            },
        }).then(function (response) {
            setProofStatus('Pending')
            const message = response.data.data;
            console.log('messagemessagemessagemessage', message)

            // set the status of the car to rejected

            let formData = new FormData();
            formData.append("id", id);
            // 4 because the verification of the document shows the car, not the verification of the profile
            formData.append("display", '0');
            formData.append("status", 'Pending');
            axios({
                method: "post",
                url: `${BASE_URL}/v1/verify-vehicle-profile`,
                data: formData,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    "Access-Control-Allow-Origin": "*",
                },
            }).then(function (response) {
                const message = response.data.data;
                setVehImageStatus('Pending')
                setVehImageDisplay('0')


            }).catch(function (error) {
                //handle error
                console.log("error ", error.response.data);
            });
        }).catch(function (error) {
            //handle error
            console.log("error ", error.response.data);
        });
    };



    function handleToggleChangeInsuranceStatus(e) {
        e.preventDefault();
        let docId = carInsuranceOwner[0]?.id
        let formData = new FormData();
        formData.append("id", docId);
        formData.append("comment", carInsuranceOwner[0]?.comment);
        formData.append("status", 'Pending');
        axios({
            method: "post",
            url: `${BASE_URL}/v1/verify-vehicle-document`,
            data: formData,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            },
        }).then(function (response) {
            setInsuranceStatus('Pending')
            const message = response.data.data;
            console.log('messagemessagemessagemessage', message)

            // set the status of the car to rejected

            // let formData = new FormData();
            // formData.append("id", id);
            // // 4 because the verification of the document shows the car, not the verification of the profile
            // formData.append("display", '0');
            // formData.append("status", 'Pending');
            // axios({
            //     method: "post",
            //     url: `${BASE_URL}/v1/verify-vehicle-profile`,
            //     data: formData,
            //     headers: {
            //         "Content-Type": "application/json",
            //         Authorization: `Bearer ${token}`,
            //         "Access-Control-Allow-Origin": "*",
            //     },
            // }).then(function (response) {
            //     const message = response.data.data;
            //     setVehImageStatus('Pending')
            //     setVehImageDisplay('0')


            // }).catch(function (error) {
            //     //handle error
            //     console.log("error ", error.response.data);
            // });
        }).catch(function (error) {
            //handle error
            console.log("error ", error.response.data);
        });
    };



    function handleToggleChangeLicenseStatus(e) {
        e.preventDefault();
        let docId = vehicleLicenceOwner[0]?.id
        let formData = new FormData();
        formData.append("id", docId);
        formData.append("comment", vehicleLicenceOwner[0]?.comment);
        formData.append("status", 'Pending');
        axios({
            method: "post",
            url: `${BASE_URL}/v1/verify-vehicle-document`,
            data: formData,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            },
        }).then(function (response) {
            setVehicleLicenseStatus('Pending')

            const message = response.data.data;
            console.log('messagemessagemessagemessage', message)

            // set the status of the car to rejected

            let formData = new FormData();
            formData.append("id", id);
            // 4 because the verification of the document shows the car, not the verification of the profile
            formData.append("display", '0');
            formData.append("status", 'Pending');
            axios({
                method: "post",
                url: `${BASE_URL}/v1/verify-vehicle-profile`,
                data: formData,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    "Access-Control-Allow-Origin": "*",
                },
            }).then(function (response) {
                const message = response.data.data;
                setVehImageStatus('Pending')
                setVehImageDisplay('0')


            }).catch(function (error) {
                //handle error
                console.log("error ", error.response.data);
            });
        }).catch(function (error) {
            //handle error
            console.log("error ", error.response.data);
        });
    }

    function handleToggleChangeWorthinessStatus(e) {
        e.preventDefault();
        let docId = roadWorthiness[0]?.id
        let formData = new FormData();
        formData.append("id", docId);
        formData.append("comment", roadWorthiness[0]?.comment);
        formData.append("status", 'Pending');
        axios({
            method: "post",
            url: `${BASE_URL}/v1/verify-vehicle-document`,
            data: formData,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            },
        }).then(function (response) {
            setWorthinessStatus('Pending')

            const message = response.data.data;
            console.log('messagemessagemessagemessage', message)

            // set the status of the car to rejected

            // let formData = new FormData();
            // formData.append("id", id);
            // // 4 because the verification of the document shows the car, not the verification of the profile
            // formData.append("display", '0');
            // formData.append("status", 'Pending');
            // axios({
            //     method: "post",
            //     url: `${BASE_URL}/v1/verify-vehicle-profile`,
            //     data: formData,
            //     headers: {
            //         "Content-Type": "application/json",
            //         Authorization: `Bearer ${token}`,
            //         "Access-Control-Allow-Origin": "*",
            //     },
            // }).then(function (response) {
            //     const message = response.data.data;
            //     setVehImageStatus('Pending')
            //     setVehImageDisplay('0')


            // }).catch(function (error) {
            //     //handle error
            //     console.log("error ", error.response.data);
            // });
        }).catch(function (error) {
            //handle error
            console.log("error ", error.response.data);
        });
    }

    function handleToggleChangeHackneyStatus(e) {
        e.preventDefault();
        let docId = hackneyStatusOwner[0]?.id
        let formData = new FormData();
        formData.append("id", docId);
        formData.append("comment", hackneyStatusOwner[0]?.comment);
        formData.append("status", 'Pending');
        axios({
            method: "post",
            url: `${BASE_URL}/v1/verify-vehicle-document`,
            data: formData,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            },
        }).then(function (response) {
            setHackneyStatus('Pending')

            const message = response.data.data;
            console.log('messagemessagemessagemessage', message)

            // set the status of the car to rejected

            // let formData = new FormData();
            // formData.append("id", id);
            // // 4 because the verification of the document shows the car, not the verification of the profile
            // formData.append("display", '0');
            // formData.append("status", 'Pending');
            // axios({
            //     method: "post",
            //     url: `${BASE_URL}/v1/verify-vehicle-profile`,
            //     data: formData,
            //     headers: {
            //         "Content-Type": "application/json",
            //         Authorization: `Bearer ${token}`,
            //         "Access-Control-Allow-Origin": "*",
            //     },
            // }).then(function (response) {
            //     const message = response.data.data;
            //     setVehImageStatus('Pending')
            //     setVehImageDisplay('0')


            // }).catch(function (error) {
            //     //handle error
            //     console.log("error ", error.response.data);
            // });
        }).catch(function (error) {
            //handle error
            console.log("error ", error.response.data);
        });
    }



    function displayStatus() {
        if (vehImageDisplay === '3' || vehImageDisplay === '2' || vehImageDisplay === '1' || vehImageStatus === 'Approved') {
            return (
                <div className="flex justify-end mt-4">

                    <button
                        onClick={(e) => adminVehicleProfileModal(e)}
                        className=''
                        type='button'
                    >
                        <img src={message} alt="" className='w-8 -ml-1 mr-2 focus:outline-none' />

                    </button>
                    <button
                        onClick={(e) => handleToggleChange(e)}


                        className='bg-blue-900 rounded text-white py-1 px-2 font-bold text-sm mr-2'>Accepted</button>
                </div>
            )
        }
        if (vehImageDisplay === '4') {
            return (
                <div className="flex justify-end mt-4">

                    <button
                        onClick={(e) => adminVehicleProfileModal(e)}
                        className=''
                        type='button'
                    >
                        <img src={message} alt="" className='w-8 -ml-1 mr-2 focus:outline-none' />

                    </button>
                    <button

                        onClick={(e) => handleToggleChange(e)}

                        className='bg-red-700 rounded text-white py-1 px-2 font-bold text-sm mr-2'>Rejected</button>
                </div>)
        }

    }




    return (

        <>

            <Transition appear show={noteOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="w-full md:w-11/12  fixed flex justify-center items-center px-6 inset-0 z-10 overflow-y-auto"
                    onClose={closeModal}
                >
                    <div className="w-full xl:w-5/12 lg:w-6/12 md:w-8/12 min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-25 bg-white"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-25"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="w-full md:w-8/12 inline-block  max-w-md  overflow-hidden text-left align-middle transition-all transform shadow-xl rounded-2xl">


                                <div className="bg-black p-2 p-12 ">
                                    <form>

                                        <textarea
                                            placeholder="Add a note"
                                            className="w-full text-white border border-white bg-transparent p-4 rounded-md lg:h-24"
                                            type="text"
                                            value={adminNote}
                                            rows="4"
                                            onChange={e => setAdminNote(e.target.value)}

                                        />


                                        <button
                                            onClick={adminProofOfOwnerAction}
                                            type="button"

                                            className='rounded-md w-full text-center mt-4 py-2 px-8 text-black text-xs font-bold bg-amber-500'>

                                            Submit Note</button>
                                    </form>

                                </div>




                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>


            <Transition appear show={licenceNoteOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="w-full md:w-11/12  fixed flex justify-center items-center px-6 inset-0 z-10 overflow-y-auto"
                    onClose={closeModal}
                >
                    <div className="w-full xl:w-5/12 lg:w-6/12 md:w-8/12 min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-25 bg-white"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-25"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="w-full md:w-8/12 inline-block  max-w-md  overflow-hidden text-left align-middle transition-all transform shadow-xl rounded-2xl">


                                <div className="bg-black p-2 p-12 ">
                                    <form>

                                        <textarea
                                            placeholder="Add a note"
                                            className="w-full text-white border border-white bg-transparent p-4 rounded-md lg:h-24"
                                            type="text"
                                            value={licenceNote}
                                            rows="4"
                                            onChange={e => setLicenceNote(e.target.value)}

                                        />


                                        <button
                                            onClick={adminLicenceAction}
                                            type="button"

                                            className='rounded-md w-full text-center mt-4 py-2 px-8 text-black text-xs font-bold bg-amber-500'>

                                            Submit Note</button>
                                    </form>

                                </div>




                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>


            <Transition appear show={insuranceNoteOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="w-full md:w-11/12  fixed flex justify-center items-center px-6 inset-0 z-10 overflow-y-auto"
                    onClose={closeModal}
                >
                    <div className="w-full xl:w-5/12 lg:w-6/12 md:w-8/12 min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-25 bg-white"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-25"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="w-full md:w-8/12 inline-block  max-w-md  overflow-hidden text-left align-middle transition-all transform shadow-xl rounded-2xl">


                                <div className="bg-black p-2 p-12 ">
                                    <form>

                                        <textarea
                                            placeholder="Add a note"
                                            className="w-full text-white border border-white bg-transparent p-4 rounded-md lg:h-24"
                                            type="text"
                                            value={insuranceNote}
                                            rows="4"
                                            onChange={e => setInsuranceNote(e.target.value)}

                                        />
                                        <button
                                            onClick={adminInsuranceAction}
                                            type="button"

                                            className='rounded-md w-full text-center mt-4 py-2 px-8 text-black text-xs font-bold bg-amber-500'>

                                            Submit Note</button>
                                    </form>

                                </div>




                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>


            <Transition appear show={worthinessNoteOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="w-full md:w-11/12  fixed flex justify-center items-center px-6 inset-0 z-10 overflow-y-auto"
                    onClose={closeModal}
                >
                    <div className="w-full xl:w-5/12 lg:w-6/12 md:w-8/12 min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-25 bg-white"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-25"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="w-full md:w-8/12 inline-block  max-w-md  overflow-hidden text-left align-middle transition-all transform shadow-xl rounded-2xl">


                                <div className="bg-black p-2 p-12 ">
                                    <form>


                                        <textarea
                                            placeholder="Add a note"
                                            className="w-full text-white border border-white bg-transparent p-4 rounded-md lg:h-24"
                                            type="text"
                                            value={worthinessNote}
                                            rows="4"
                                            onChange={e => setWorthinessNote(e.target.value)}

                                        />
                                        <button
                                            onClick={adminWorthinessAction}
                                            type="button"

                                            className='rounded-md w-full text-center mt-4 py-2 px-8 text-black text-xs font-bold bg-amber-500'>

                                            Submit Note</button>
                                    </form>

                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>


            <Transition appear show={hackneyNoteOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="w-full md:w-11/12  fixed flex justify-center items-center px-6 inset-0 z-10 overflow-y-auto"
                    onClose={closeModal}
                >
                    <div className="w-full xl:w-5/12 lg:w-6/12 md:w-8/12 min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-25 bg-white"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-25"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="w-full md:w-8/12 inline-block  max-w-md  overflow-hidden text-left align-middle transition-all transform shadow-xl rounded-2xl">


                                <div className="bg-black p-2 p-12 ">
                                    <form>


                                        <textarea
                                            placeholder="Add a note"
                                            className="w-full text-white border border-white bg-transparent p-4 rounded-md lg:h-24"
                                            type="text"
                                            value={hackneyNote}
                                            rows="4"
                                            onChange={e => setHackneyNote(e.target.value)}

                                        />
                                        <button
                                            onClick={adminHackneyAction}
                                            type="button"

                                            className='rounded-md w-full text-center mt-4 py-2 px-8 text-black text-xs font-bold bg-amber-500'>

                                            Submit Note</button>
                                    </form>

                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>


            <Transition appear show={vehicleNoteOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="w-full md:w-11/12  fixed flex justify-center items-center px-6 inset-0 z-10 overflow-y-auto"
                    onClose={closeModal}
                >
                    <div className="w-full xl:w-5/12 lg:w-6/12 md:w-8/12 min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-25 bg-white"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-25"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="w-full md:w-8/12 inline-block  max-w-md  overflow-hidden text-left align-middle transition-all transform shadow-xl rounded-2xl">


                                <div className="bg-black p-2 p-12 ">
                                    <form>


                                        <textarea
                                            placeholder="Add a note"
                                            className="w-full text-white border border-white bg-transparent p-4 rounded-md lg:h-24"
                                            type="text"
                                            value={vehicleNote}
                                            rows="4"
                                            onChange={e => setVehicleNote(e.target.value)}

                                        />
                                        <button
                                            onClick={adminVehicleAction}
                                            type="button"

                                            className='rounded-md w-full text-center mt-4 py-2 px-8 text-black text-xs font-bold bg-amber-500'>

                                            Submit Note</button>
                                    </form>

                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>



            <ToastContainer />

            <div className='md:border-r md:border-amber-500 h-100 xl:w-7/12 md:w-6/12 w-full   md:pr-20 pb-20 pt-12 '>

                <div className="flex justify-start"><h6 className='font-semibold text-amber-500 mb-3 md:hidden'>Provider’s ID: {providerInfo.user_id}</h6></div>
                <button className='mt-4 p-2 bg-blue-900 rounded-md text-sm font-normal text-white md:hidden'>Pending Approval</button>



                <h2 className='text-white font-bold md:text-lg text-base mt-8 mb-8 md:mb-0'>Vehicle Profile </h2>


                <main className='xl:w-full lg:w-10/12 md:w-8/12'>
                    <div className="flex flex-col md:flex-row">
                        <div className="pb-20">
                            <form className='xl:w-full lg:w-9/12 md:w-6/12 w-full mt-4'>
                                <div className="flex my-8 md:my-12 w-full gap-2 xl:gap-4 md:gap-2">
                                    <div className="flex flex-col w-1/2">
                                        <label htmlFor="vehicle name" className='text-xs text-white font-normal'>Vehicle Name</label>
                                        <input type="text"
                                            value={name}
                                            readOnly
                                            className='text-xs mt-2  bg-transparent rounded-lg border border-white p-2 cart-bg  focus:outline-none text-white' />
                                    </div>
                                    <div className="flex flex-col w-1/2">
                                        <label htmlFor="Year" className='text-xs text-white font-normal'>Year</label>
                                        <input
                                            readOnly

                                            type="text"
                                            value={year}
                                            className='text-xs mt-2 bg-transparent rounded-lg border border-white p-2  cart-bg focus:outline-none text-white' />

                                    </div>
                                </div>

                                <div className="flex flex-col w-full ">
                                    <label htmlFor="Phone Number" className='text-xs text-white font-normal'>Plate Number <span className='text-red-700'>*</span> </label>

                                    <input
                                        type="phone"
                                        value={plate_number}
                                        readOnly
                                        className='text-xs mt-2 bg-transparent rounded-lg cart-bg border border-white p-2 focus:outline-none text-white' />

                                </div>


                                <div className="flex flex-col w-full my-8 md:my-12">
                                    <label htmlFor="" className='text-xs text-white font-normal'>Vehicle Type <span className='text-red-700'>*</span> </label>

                                    <input
                                        type="phone"
                                        readOnly

                                        value={vehicle_type}
                                        className='text-xs mt-2 bg-transparent rounded-lg cart-bg border border-white p-2 focus:outline-none text-white' />

                                </div>


                                <div className="flex flex-col w-full ">
                                    <label htmlFor="No of passenger seats" className='text-xs text-white font-normal'>No of passenger seats <span className='text-red-700'>*</span> </label>

                                    <input type="phone"
                                        value={passengers}
                                        readOnly
                                        className='text-xs mt-2 bg-transparent rounded-lg cart-bg border border-white p-2 focus:outline-none text-white' />

                                </div>



                                <div className="flex flex-col my-8 md:my-12">
                                    <label htmlFor="Address:" className='text-xs text-white font-normal'>Address: <span className='text-red-700'>*</span></label>

                                    <input
                                        type="text"
                                        value={vehicle_address}
                                        readOnly
                                        className='cart-bg text-xs mt-2 bg-transparent rounded-lg border border-white p-2 focus:outline-none text-white' />

                                </div>




                                <div className="flex flex-col">
                                    <label htmlFor="Daily Price" className='text-xs text-white font-normal'>Daily Rental Price<span className='text-red-700'>*</span></label>
                                    <div className="rounded-lg border border-white mt-2 flex px-2">
                                        <input type="text"
                                            value={Number(price).toLocaleString('en-US', { maximumFractionDigits: 2 })}
                                            readOnly

                                            className='w-11/12 text-xs bg-transparent rounded-lg  p-2 focus:outline-none text-white cart-bg' />
                                        <img src={icon} alt="" className='w-8' />
                                    </div>

                                </div>



                                <div className="flex my-6 md:mt-6 justify-between">
                                    <div className="flex flex-col mr-4">
                                        <label htmlFor="VAT 7.5%" className='text-xs text-white font-normal'>VAT 7.5%</label>

                                    </div>
                                    <div className="flex flex-col">
                                        <label htmlFor="Service fee 10%" className='text-xs text-white font-normal'>Service fee 10%</label>


                                    </div>
                                </div>

                                <div className="flex flex-col md:mt-12 mt-8">
                                    <label htmlFor="Airport Pick-up price" className='text-xs text-white font-normal'>Airport Pick-up price <span className='text-red-700'>*</span></label>
                                    <div className="rounded-lg border border-white mt-2 flex px-2">
                                        <input
                                            type="text"
                                            readOnly
                                            value={Number(airport_price).toLocaleString('en-US', { maximumFractionDigits: 2 })} className='w-11/12 text-xs bg-transparent rounded-lg  p-2 focus:outline-none text-white cart-bg' />
                                        <img src={icon} alt="" className='w-8' />
                                    </div>

                                </div>


                                <div className="flex my-6 md:mt-6 justify-between">
                                    <div className="flex flex-col mr-4">
                                        <label htmlFor="VAT 7.5%" className='text-xs text-white font-normal'>VAT 7.5%</label>

                                    </div>
                                    <div className="flex flex-col">
                                        <label htmlFor="Service fee 10%" className='text-xs text-white font-normal'>Service fee 10%</label>


                                    </div>
                                </div>









                                <div className="flex flex-col md:my-12 my-8">
                                    <label htmlFor="Monthly Price" className='text-xs text-white font-normal'>Monthly Price </label>
                                    <div className="rounded-lg border border-white mt-2 flex px-2">
                                        <input
                                            type="text"
                                            value={Number(monthly_price).toLocaleString('en-US', { maximumFractionDigits: 2 })}
                                            readOnly
                                            className='w-11/12 text-sm bg-transparent rounded-lg  p-2 focus:outline-none text-white' />
                                        <img src={icon} alt="" className='w-8' />
                                    </div>

                                </div>

                                <div className="flex flex-col ">
                                    <label htmlFor="Address:" className='text-xs text-white font-normal'>Hours Available: <span className='text-red-700'>*</span></label>

                                    <input type="text"
                                        value={hours}
                                        readOnly
                                        className='cart-bg text-xs mt-2 bg-transparent rounded-lg border border-white p-2 focus:outline-none text-white' />

                                </div>

                                <div className="flex flex-col my-8 md:mt-12">
                                    <label htmlFor="Address:" className='text-xs text-white font-normal'>Does this vehicle have a working Air-Conditioner? <span className='text-red-700'>*</span></label>

                                    <div className="flex gap-6 mt-4">
                                        <div className="flex items-center gap-4">
                                            <button
                                                type='button'

                                                className={air_con === 'yes' ? "w-6 h-6 border-white rounded-full border-2 bg-amber-500" : "w-6 h-6 border-white rounded-full border-2"}>

                                            </button>
                                            <label htmlFor="" className='text-xs text-white'>Yes</label>
                                        </div>


                                        <div className="flex items-center gap-4">

                                            <button
                                                type='button'

                                                className={air_con === 'no' ? "w-6 h-6 border-white rounded-full border-2 bg-amber-500" : "w-6 h-6 border-white rounded-full border-2 "}>

                                            </button>
                                            <label htmlFor="" className='text-xs text-white'>No</label>

                                        </div>

                                    </div>

                                </div>

                                <div className="flex flex-col my-8 md:my-12">
                                    <label htmlFor="Address:" className='text-xs text-white font-normal'>Is this vehicle avaliable for Travel? <span className='text-red-700'>*</span></label>

                                    <div className="flex gap-6 mt-4">


                                        <div className="flex items-center gap-4">
                                            <button
                                                type='button'

                                                className={travel_available === 'yes' ? "w-6 h-6 border-white rounded-full border-2 bg-amber-500" : "w-6 h-6 border-white rounded-full border-2"}>

                                            </button>
                                            <label htmlFor="" className='text-xs text-white'>Yes</label>
                                        </div>


                                        <div className="flex items-center gap-4">

                                            <button
                                                type='button'

                                                className={travel_available === 'no' ? "w-6 h-6 border-white rounded-full border-2 bg-amber-500" : "w-6 h-6 border-white rounded-full border-2 "}>

                                            </button>
                                            <label htmlFor="" className='text-xs text-white'>No</label>

                                        </div>

                                    </div>

                                </div>



                                <div className="flex flex-col my-8 md:my-12">
                                    <label htmlFor="Address:" className='text-xs text-white font-normal'>Vehicle color<span className='text-red-700'>*</span></label>

                                    <input type="text"
                                        readOnly

                                        value={vehicle_color} className='cart-bg text-xs mt-2 bg-transparent rounded-lg border border-white p-2 focus:outline-none text-white' />

                                </div>

                                <div className="flex flex-col my-8 md:my-12">
                                    <label htmlFor="service:" className='text-xs text-white font-normal'>Service<span className='text-red-700'>*</span></label>

                                    <input
                                        readOnly

                                        type="text" value={service} className='cart-bg text-xs mt-2 bg-transparent rounded-lg border border-white p-2 focus:outline-none text-white' />

                                </div>







                                <div className="flex my-6 md:my-12 gap-1 xl:gap-4 md:gap-2">
                                    <div className="flex flex-col w-1/2">
                                        <label htmlFor="State" className='text-xs text-white font-normal'>Vehicle Location <span className='text-red-700'>*</span></label>
                                        <input
                                            readOnly

                                            type="text" value={location} className='text-xs mt-2 bg-transparent rounded-lg border border-white p-2 focus:outline-none text-white' />
                                    </div>
                                    <div className="flex flex-col w-1/2">
                                        <label htmlFor="Country" className='text-xs text-white font-normal'>&nbsp;</label>
                                        <input

                                            readOnly

                                            value={country}
                                            type="text" placeholder='Nigeria' className='text-sm mt-2 bg-transparent rounded-lg border border-white p-2 focus:outline-none text-white' />

                                    </div>
                                </div>


                                <div className="flex flex-col my-6 md:mt-12">
                                    <label htmlFor="Address:" className='text-xs text-white font-normal'>Does this vehicle Come with a Driver? <span className='text-red-700'>*</span></label>

                                    <div className="flex gap-6 mt-4">
                                        <div className="flex items-center gap-4">
                                            <button
                                                type='button'

                                                className={comeWithDriver === 'yes' ? "w-6 h-6 border-white rounded-full border-2 bg-amber-500" : "w-6 h-6 border-white rounded-full border-2"}>

                                            </button>
                                            <label htmlFor="" className='text-xs text-white'>Yes</label>
                                        </div>


                                        <div className="flex items-center gap-4">

                                            <button
                                                type='button'

                                                className={comeWithDriver === 'no' ? "w-6 h-6 border-white rounded-full border-2 bg-amber-500" : "w-6 h-6 border-white rounded-full border-2 "}>

                                            </button>
                                            <label htmlFor="" className='text-xs text-white'>No</label>

                                        </div>

                                    </div>

                                </div>




                                <div className="flex flex-col my-6 md:mt-12">
                                    <label htmlFor="Address:" className='text-xs text-white font-normal'>Does this vehicle have a comprehensive  insurance? <span className='text-red-700'>*</span></label>

                                    <div className="flex gap-6 mt-4">
                                        <div className="flex items-center gap-4">
                                            <button
                                                type='button'

                                                className={insurance_option !== null ? 'w-6 h-6 border-white rounded-full border-2 bg-amber-500' : "w-6 h-6 border-white rounded-full border-2 "}>

                                            </button>
                                            <label htmlFor="" className='text-xs text-white'>Yes</label>
                                        </div>


                                        <div className="flex items-center gap-4">

                                            <button
                                                type='button'

                                                className={insurance_option === null ? 'w-6 h-6 border-white rounded-full border-2 bg-amber-500' : "w-6 h-6 border-white rounded-full border-2 "}>

                                            </button>
                                            <label htmlFor="" className='text-xs text-white'>No</label>

                                        </div>

                                    </div>

                                </div>


                                <div className="flex flex-col my-6 md:mt-12">

                                    <label htmlFor="service:" className='text-xs text-white font-normal'>Select a vehicle insurance option
                                        <span className='text-red-700 '>


                                            *</span></label>

                                    <input
                                        readOnly

                                        type="text" value={insurance_option} className='cart-bg text-xs mt-2 bg-transparent rounded-lg border border-white p-2 focus:outline-none text-white' />

                                </div>

                                <div className="flex flex-col my-6 md:mt-12">
                                    <label htmlFor="service:" className='text-xs text-white font-normal'>Vehicle Description</label>

                                    <input
                                        readOnly

                                        type="text" value={notes} className='cart-bg text-xs mt-2 bg-transparent rounded-lg border border-white p-2 focus:outline-none text-white' />

                                </div>


                                <div className="flex  mt-8 flex-wrap justify-start gap-4 md:gap-4 w-full md:w-10/12">
                                    <div className="">

                                        <div className="bg-white rounded-lg md:h-36 md:w-36 w-28 h-28 overflow-hidden justify-center items-center flex">
                                            <img src={`https://api.roadlers.com/storage/${image_one}`}
                                                alt="" className=' w-full  rounded-lg  ' />
                                        </div>


                                        <div className="flex justify-between items-center mt-3">
                                            <p className='text-white'>Image 1  <span className='text-red-400'>*</span> </p>
                                            <img src={icon} alt="" className='w-9' />
                                        </div>

                                    </div>
                                    <div className="">

                                        <div className="bg-white  rounded-lg md:h-36 md:w-36 w-28 h-28 overflow-hidden justify-center items-center flex">
                                            <img src={`https://api.roadlers.com/storage/${image_two}`}
                                                alt="" className=' w-full  rounded-lg  object-cover object-center' />
                                        </div>

                                        <div className="flex justify-between items-center mt-3">
                                            <p className='text-white'>Image 2  <span className='text-red-400'>*</span> </p>
                                            <img src={icon} alt="" className='w-9' />
                                        </div>

                                    </div>

                                    <div className="">


                                        <div className="bg-white rounded-lg md:h-36 md:w-36 w-28 h-28 overflow-hidden justify-center items-center flex">
                                            <img src={`https://api.roadlers.com/storage/${image_three}`}
                                                alt="" className=' w-full  rounded-lg  object-cover object-center' />
                                        </div>


                                        <div className="flex justify-between items-center mt-3">
                                            <p className='text-white'>Image 3  <span className='text-red-400'>*</span> </p>
                                            <img src={icon} alt="" className='w-9' />
                                        </div>

                                    </div>

                                    <div className="">



                                        {image_four != null ?

                                            (
                                                coverImagePreview ?
                                                    (<div className="border  bg-white relative  rounded-lg md:h-36 md:w-36 w-28 h-28 overflow-hidden justify-center items-center flex">

                                                        <button
                                                            onClick={(event) => {
                                                                event.preventDefault()
                                                                fileInputRef.current.click()
                                                            }}
                                                            type='button'
                                                            className='absolute top-2 right-2 p-2 bg-black text-amber-500 text-xs rounded-full text-white   border border-amber-500'>
                                                            <img src={close} alt="" className='w-3' />
                                                        </button>
                                                        <img
                                                            src={coverImagePreview}
                                                            alt=""
                                                            className=' w-full  rounded-lg  object-cover object-center'
                                                            onClick={handlee}
                                                        />
                                                    </div>) :
                                                    (<div className="border relative bg-white   rounded-lg md:h-36 md:w-36 w-28 h-28 overflow-hidden justify-center items-center flex">
                                                        <button
                                                            onClick={(event) => {
                                                                event.preventDefault()
                                                                fileInputRef.current.click()
                                                            }}
                                                            type='button'
                                                            className='absolute top-2 right-2 p-2 bg-black text-amber-500 text-xs rounded-full text-white   border border-amber-500'>
                                                            <img src={close} alt="" className='w-3' />
                                                        </button>

                                                        <img src={`https://api.roadlers.com/storage/${image_four}`}
                                                            alt="" className=' w-full  rounded-lg  object-cover object-center' />
                                                    </div>)
                                            ) :


                                            (

                                                coverImagePreview ?
                                                    <div className="border  bg-white relative  rounded-lg md:h-36 md:w-36 w-28 h-28 overflow-hidden justify-center items-center flex">

                                                        <button
                                                            onClick={(event) => {
                                                                event.preventDefault()
                                                                fileInputRef.current.click()
                                                            }}
                                                            type='button'
                                                            className='absolute top-2 right-2 p-2 bg-black text-amber-500 text-xs rounded-full text-white   border border-amber-500'>
                                                            <img src={close} alt="" className='w-3' />
                                                        </button>
                                                        <img
                                                            src={coverImagePreview}
                                                            alt=""
                                                            className=' w-full  rounded-lg  object-cover object-center'
                                                            onClick={handlee}
                                                        />
                                                    </div>


                                                    : <div className="border border-white  bg-white  py-4 px-2 rounded-lg md:h-36 md:w-36 w-28 h-28 overflow-hidden justify-center items-center flex">
                                                        <button
                                                            onClick={(event) => {
                                                                event.preventDefault()
                                                                fileInputRef.current.click()
                                                            }}
                                                            type='button'
                                                            className='focus:bg-amber-500 focus:text-black bg-black text-amber-500 text-xs rounded-md text-white px-2 py-1 w-28 border border-amber-500'>Add Image</button>

                                                    </div>)

                                        }


                                        <div className="flex justify-between items-center mt-3">
                                            <p className='text-white'>Cover Image  <span className='text-red-400'>*</span> </p>
                                            <img src={icon} alt="" className='w-9' />
                                        </div>



                                        <input
                                            type="file"
                                            style={{ display: 'none' }}
                                            ref={fileInputRef}
                                            accept="image/*"
                                            onChange={(event) => {
                                                const file = event.target.files[0]
                                                if (file) {
                                                    setCoverImage(file)
                                                }
                                                else {
                                                    setCoverImage(null)
                                                }
                                            }} />

                                    </div>


                                </div>





                                <div className="flex  mt-6   gap-2 ">

                                    {(vehImageDisplay === '0' && (vehImageStatus === null || vehImageStatus === 'Pending')) ?

                                        <>
                                            <button
                                                onClick={(e) => adminVehicleProfileModal(e)}
                                                className=''
                                                type='button'
                                            >
                                                <img src={message} alt="" className='w-8 -ml-1 mr-2 focus:outline-none' />

                                            </button>
                                            <button
                                                onClick={handleAcceptImage}
                                                className='bg-blue-900 rounded text-white p-1 font-bold text-sm  w-20'>Accept
                                            </button>
                                            <button
                                                onClick={handleRejectImage}
                                                className='bg-white rounded text-black p-1 font-bold text-sm  w-20'>Reject
                                            </button>
                                        </>
                                        :


                                        displayStatus()



                                    }

                                </div>


                                <div className="flex gap-2 mt-10 w-full">
                                    <a href={`tel: ${providerInfo.phone_number}`} className='border-theme-color font-semibold theme-color p-2 md:text-base text-center hover:bg-amber-500 text-sm rounded-md w-2/4'>Call Now</a>

                                    {vehImageDisplay === '2' && vehImageStatus === 'Pending' || vehImageDisplay === '1' || vehImageStatus === 'Approved' ?
                                        (
                                            <button
                                                type='button'

                                                className='bg-amber-500 font-semibold text-black p-2 rounded-md w-2/4 md:text-base text-sm'>
                                                Verified
                                            </button>)
                                        :
                                        (
                                            vehImageDisplay === '4' ? (

                                                <button
                                                    type='button'
                                                    className='cursor-not-allowed bg-black font-semibold text-white p-2 rounded-md w-2/4 md:text-base text-sm'>
                                                    Failed to Verify
                                                </button>
                                            ) :
                                                (<button
                                                    onClick={handleVerifyVehicle}
                                                    type='button'

                                                    className='bg-white font-semibold text-black p-2 rounded-md w-2/4 md:text-base text-sm'>
                                                    Click to Verify
                                                </button>)


                                        )


                                    }


                                </div>

                            </form>


                            {ownerProfile &&
                                <form className='xl:w-full lg:w-9/12 md:w-6/12 w-full mt-24'>

                                    <h2 className='text-white font-bold md:text-lg text-base mt-8 mb-8 md:mb-0'>Owner's Profile </h2>

                                    <div className="flex my-8 md:my-12 w-full gap-2 xl:gap-4 md:gap-2">
                                        <div className="flex flex-col w-1/2">
                                            <label htmlFor="vehicle name" className='text-xs text-white font-normal'>First Name</label>
                                            <input type="text"
                                                value={ownerProfile?.firstname}
                                                readOnly
                                                className='text-xs mt-2  bg-transparent rounded-lg border border-white p-2 cart-bg  focus:outline-none text-white' />
                                        </div>
                                        <div className="flex flex-col w-1/2">
                                            <label htmlFor="Year" className='text-xs text-white font-normal'>Last Name</label>
                                            <input
                                                readOnly

                                                type="text"
                                                value={ownerProfile?.lastname}
                                                className='text-xs mt-2 bg-transparent rounded-lg border border-white p-2  cart-bg focus:outline-none text-white' />

                                        </div>
                                    </div>

                                    <div className="flex flex-col w-full ">
                                        <label htmlFor="Phone Number" className='text-xs text-white font-normal'>Availability <span className='text-red-700'>*</span> </label>

                                        <input
                                            type="phone"
                                            value={ownerProfile?.availability}
                                            readOnly
                                            className='text-xs mt-2 bg-transparent rounded-lg cart-bg border border-white p-2 focus:outline-none text-white' />

                                    </div>


                                    <div className="flex flex-col w-full my-8 md:my-12">
                                        <label htmlFor="" className='text-xs text-white font-normal'>Category<span className='text-red-700'>*</span> </label>

                                        <input
                                            type="phone"
                                            readOnly

                                            value={ownerProfile?.category}
                                            className='text-xs mt-2 bg-transparent rounded-lg cart-bg border border-white p-2 focus:outline-none text-white' />

                                    </div>


                                    <div className="flex my-8 md:my-12 w-full gap-2 xl:gap-4 md:gap-2">
                                        <div className="flex flex-col w-1/2">
                                            <label htmlFor="vehicle name" className='text-xs text-white font-normal'>Phone Number</label>
                                            <input type="text"
                                                value={ownerProfile?.phone_number}
                                                readOnly
                                                className='text-xs mt-2  bg-transparent rounded-lg border border-white p-2 cart-bg  focus:outline-none text-white' />
                                        </div>
                                        <div className="flex flex-col w-1/2">
                                            <label htmlFor="Year" className='text-xs text-white font-normal'>Date of Birth</label>
                                            <input
                                                readOnly

                                                type="text"
                                                value={ownerProfile?.dob}
                                                className='text-xs mt-2 bg-transparent rounded-lg border border-white p-2  cart-bg focus:outline-none text-white' />

                                        </div>
                                    </div>



                                    <div className="flex flex-col my-8 md:my-12">
                                        <label htmlFor="Address:" className='text-xs text-white font-normal'>Address: <span className='text-red-700'>*</span></label>

                                        <input
                                            type="text"
                                            value={ownerProfile?.address}
                                            readOnly
                                            className='cart-bg text-xs mt-2 bg-transparent rounded-lg border border-white p-2 focus:outline-none text-white' />

                                    </div>













                                    <div className="flex my-6 md:my-12 gap-1 xl:gap-4 md:gap-2">
                                        <div className="flex flex-col w-1/2">
                                            <label htmlFor="State" className='text-xs text-white font-normal'>Identification<span className='text-red-700'>*</span></label>
                                            <input
                                                readOnly

                                                type="text" value={ownerProfile?.identification} className='text-xs mt-2 bg-transparent rounded-lg border border-white p-2 focus:outline-none text-white' />
                                        </div>
                                        <div className="flex flex-col w-1/2">
                                            <label htmlFor="Country" className='text-xs text-white font-normal'>ID Number &nbsp;</label>
                                            <input

                                                readOnly

                                                value={ownerProfile?.id_number}
                                                type="text" className='text-sm mt-2 bg-transparent rounded-lg border border-white p-2 focus:outline-none text-white' />

                                        </div>
                                    </div>








                                    <div className="flex my-6 md:my-12 gap-1 xl:gap-4 md:gap-2">
                                        <div className="flex flex-col w-1/2">
                                            <label htmlFor="State" className='text-xs text-white font-normal'>State and Country <span className='text-red-700'>*</span></label>
                                            <input
                                                readOnly

                                                type="text" value={ownerProfile?.state} className='text-xs mt-2 bg-transparent rounded-lg border border-white p-2 focus:outline-none text-white' />
                                        </div>
                                        <div className="flex flex-col w-1/2">
                                            <label htmlFor="Country" className='text-xs text-white font-normal'>&nbsp;</label>
                                            <input

                                                readOnly

                                                value={ownerProfile?.country}
                                                type="text" className='text-sm mt-2 bg-transparent rounded-lg border border-white p-2 focus:outline-none text-white' />

                                        </div>
                                    </div>




                                    <div className="flex flex-col my-6 md:mt-12">
                                        <label htmlFor="service:" className='text-xs text-white font-normal'>Became a Partner On</label>

                                        <input
                                            readOnly

                                            type="text" value={moment(ownerProfile?.created_at).format("DD/MM/YYYY")} className='cart-bg text-xs mt-2 bg-transparent rounded-lg border border-white p-2 focus:outline-none text-white' />

                                    </div>





                                    {/* <div className="flex gap-2 mt-10 w-full">

                                        {ownerProfile?.status === 'Pending' ?
                                            <button
                                                type='button'

                                                className='bg-blue-700 font-semibold text-white p-2 rounded-md w-2/4 md:text-base text-sm'>
                                                Pending
                                            </button> :

                                            <button
                                                type='button'

                                                className='bg-amber-500 font-semibold text-black p-2 rounded-md w-2/4 md:text-base text-sm'>
                                                Verified
                                            </button>
                                        }


                                    </div> */}

                                </form>
                            }

                        </div>











                    </div>


                </main>
            </div>

            <div
                ref={docRef}
                className=' xl:px-12 md:pl-12 xl:w-6/12 md:w-6/12  w-full pt-0 md:pt-8' >
                <div className="flex justify-end hidden md:flex"><h4 className='font-bold text-amber-500 mb-8'>Provider’s ID: {providerInfo.user_id}</h4></div>

                <h2 className='mb-10 text-white font-bold md:text-lg text-base'>Vehicle Documents</h2>

                {
                    proofStatus ?
                        <div className="my-8">
                            <div className="flex justify-between border border-white p-2 md:p-3 items-center">
                                <p className='text-white md:text-sm text-xs font-normal'>Proof of ownership</p>

                                <a
                                    className='text-center border-theme-color px-3 py-1 md:text-sm md:px-2 md:py-0 text-xs text-white'

                                    href={`https://api.roadlers.com/storage/${proofOfOwner[0]?.document}`} target="_blank">View</a>



                            </div>
                            {proofStatus === 'Pending' ?
                                (
                                    <div className="flex justify-end mt-4">

                                        <button
                                            onClick={(e) => adminProofOfOwnerActionModal(e)}
                                            className=''
                                            type='button'
                                        >
                                            <img src={message} alt="" className='w-8 -ml-1 mr-2 focus:outline-none' />

                                        </button>

                                        <button
                                            onClick={handleAcceptProof}
                                            className='bg-blue-900 rounded text-white p-1 font-bold text-sm mr-2'>Accept</button>
                                        <button
                                            onClick={handleRejectProof}

                                            className='bg-white rounded text-black p-1 font-bold text-sm'>
                                            Reject</button>
                                    </div>
                                ) :
                                (

                                    proofStatus === 'Accept' ?
                                        <div className="flex justify-end mt-4">

                                            <button
                                                onClick={(e) => adminProofOfOwnerActionModal(e)}
                                                className=''
                                                type='button'
                                            >
                                                <img src={message} alt="" className='w-8 -ml-1 mr-2 focus:outline-none' />

                                            </button>

                                            <button
                                                onClick={(e) => handleToggleChangeProofStatus(e)}
                                                className='bg-blue-900 rounded text-white py-1 px-2 font-bold text-sm mr-2'>Accepted</button>

                                        </div> :
                                        <div className="flex justify-end mt-4">

                                            <button
                                                onClick={(e) => adminProofOfOwnerActionModal(e)}
                                                className=''
                                                type='button'
                                            >
                                                <img src={message} alt="" className='w-8 -ml-1 mr-2 focus:outline-none' />

                                            </button>




                                            <button
                                                onClick={(e) => handleToggleChangeProofStatus(e)}
                                                className='bg-red-700 rounded text-white py-1 px-2 font-bold text-sm mr-2'>Rejected</button>
                                        </div>


                                )
                            }



                        </div> : ''
                }

                {worthinessStatus ?

                    <div className="">
                        <div className="flex justify-between border border-white p-2 md:p-3 items-center">
                            <p className='text-white md:text-sm text-xs font-normal'>Certificate of road worthiness</p>

                            <a className='text-center border-theme-color px-3 py-1 md:text-sm md:px-2 md:py-0 text-xs text-white'

                                href={`https://api.roadlers.com/storage/${roadWorthiness[0]?.document}`} target="_blank">View</a>


                        </div>

                        {worthinessStatus === 'Pending' ?
                            (
                                <div className="flex justify-end mt-4">
                                    <button
                                        onClick={(e) => adminWorthinessActionModal(e)}
                                        className=''
                                        type='button'
                                    >
                                        <img src={message} alt="" className='w-8 -ml-1 mr-2 focus:outline-none' />

                                    </button>

                                    <button
                                        onClick={handleAcceptWorthiness}
                                        className='bg-blue-900 rounded text-white p-1 font-bold text-sm mr-2'>Accept</button>
                                    <button
                                        onClick={handleRejectWorthiness}

                                        className='bg-white rounded text-black p-1 font-bold text-sm'>
                                        Reject</button>
                                </div>
                            ) :
                            (

                                worthinessStatus === 'Accept' ?
                                    <div className="flex justify-end mt-4">

                                        <button
                                            onClick={(e) => adminWorthinessActionModal(e)}
                                            className=''
                                            type='button'
                                        >
                                            <img src={message} alt="" className='w-8 -ml-1 mr-2 focus:outline-none' />

                                        </button>

                                        <button
                                            onClick={(e) => handleToggleChangeWorthinessStatus(e)}

                                            className='bg-blue-900 rounded text-white py-1 px-2 font-bold text-sm mr-2'>Accepted</button>

                                    </div> :
                                    <div className="flex justify-end mt-4">

                                        <button
                                            onClick={(e) => adminWorthinessActionModal(e)}
                                            className=''
                                            type='button'
                                        >
                                            <img src={message} alt="" className='w-8 -ml-1 mr-2 focus:outline-none' />

                                        </button>

                                        <button
                                            onClick={(e) => handleToggleChangeWorthinessStatus(e)}

                                            className='bg-red-700 rounded text-white py-1 px-2 font-bold text-sm mr-2'>Rejected</button>
                                    </div>


                            )
                        }

                    </div> : ''
                }


                {
                    insuranceStatus ?
                        <div className="my-8">
                            <div className="flex justify-between border border-white p-2 md:p-3 items-center">
                                <p className='text-white md:text-sm text-xs font-normal'>Certificate of car insurance</p>

                                <a
                                    className='text-center border-theme-color px-3 py-1 md:text-sm md:px-2 md:py-0 text-xs text-white'

                                    href={`https://api.roadlers.com/storage/${carInsuranceOwner[0]?.document}`} target="_blank">View</a>


                            </div>

                            {insuranceStatus === 'Pending' ?
                                (
                                    <div className="flex justify-end mt-4">

                                        <button
                                            onClick={(e) => adminInsuranceActionModal(e)}
                                            className=''
                                            type='button'
                                        >
                                            <img src={message} alt="" className='w-8 -ml-1 mr-2 focus:outline-none' />

                                        </button>

                                        <button
                                            onClick={handleAcceptInsurance}
                                            className='bg-blue-900 rounded text-white p-1 font-bold text-sm mr-2'>Accept</button>
                                        <button
                                            onClick={handleRejectInsurance}

                                            className='bg-white rounded text-black p-1 font-bold text-sm'>
                                            Reject</button>
                                    </div>
                                ) :
                                (

                                    insuranceStatus === 'Accept' ?
                                        <div className="flex justify-end mt-4">

                                            <button
                                                onClick={(e) => adminInsuranceActionModal(e)}
                                                className=''
                                                type='button'
                                            >
                                                <img src={message} alt="" className='w-8 -ml-1 mr-2 focus:outline-none' />

                                            </button>

                                            <button
                                                onClick={(e) => handleToggleChangeInsuranceStatus(e)}

                                                className='bg-blue-900 rounded text-white py-1 px-2 font-bold text-sm mr-2'>Accepted</button>

                                        </div> :
                                        <div className="flex justify-end mt-4">

                                            <button
                                                onClick={(e) => adminInsuranceActionModal(e)}
                                                className=''
                                                type='button'
                                            >
                                                <img src={message} alt="" className='w-8 -ml-1 mr-2 focus:outline-none' />

                                            </button>

                                            <button
                                                onClick={(e) => handleToggleChangeInsuranceStatus(e)}

                                                className='bg-red-700 rounded text-white py-1 px-2 font-bold text-sm mr-2'>Rejected</button>
                                        </div>


                                )
                            }
                        </div> : ''
                }




                {
                    VehicleLicenseStatus ?

                        <div className="my-8">
                            <div className="flex justify-between border border-white p-2 md:p-3 items-center">
                                <p className='text-white md:text-sm text-xs font-normal'>Vehicle license / registration </p>
                                <a
                                    className='text-center border-theme-color px-3 py-1 md:text-sm md:px-2 md:py-0 text-xs text-white'

                                    href={`https://api.roadlers.com/storage/${vehicleLicenceOwner[0]?.document}`} target="_blank">View</a>


                            </div>

                            {VehicleLicenseStatus === 'Pending' ?
                                (
                                    <div className="flex justify-end mt-4">

                                        <button
                                            onClick={(e) => adminLicenceActionModal(e)}
                                            className=''
                                            type='button'
                                        >
                                            <img src={message} alt="" className='w-8 -ml-1 mr-2 focus:outline-none' />

                                        </button>

                                        <button
                                            onClick={handleAcceptVehicleLicense}
                                            className='bg-blue-900 rounded text-white p-1 font-bold text-sm mr-2'>Accept</button>
                                        <button
                                            onClick={handleRejectVehicleLicense}

                                            className='bg-white rounded text-black p-1 font-bold text-sm'>
                                            Reject</button>
                                    </div>
                                ) :
                                (

                                    VehicleLicenseStatus === 'Accept' ?
                                        <div className="flex justify-end mt-4">

                                            <button
                                                onClick={(e) => adminLicenceActionModal(e)}
                                                className=''
                                                type='button'
                                            >
                                                <img src={message} alt="" className='w-8 -ml-1 mr-2 focus:outline-none' />

                                            </button>

                                            <button
                                                onClick={(e) => handleToggleChangeLicenseStatus(e)}

                                                className='bg-blue-900 rounded text-white py-1 px-2 font-bold text-sm mr-2'>Accepted</button>

                                        </div> :
                                        <div className="flex justify-end mt-4">

                                            <button
                                                onClick={(e) => adminLicenceActionModal(e)}
                                                className=''
                                                type='button'
                                            >
                                                <img src={message} alt="" className='w-8 -ml-1 mr-2 focus:outline-none' />

                                            </button>
                                            <button

                                                onClick={(e) => handleToggleChangeLicenseStatus(e)}

                                                className='bg-red-700 rounded text-white py-1 px-2 font-bold text-sm mr-2'>Rejected</button>
                                        </div>


                                )
                            }
                        </div> : ''
                }


                {HackneyStatus ?
                    <div className="mb-8">
                        <div className="flex justify-between border border-white p-2 md:p-3 items-center">
                            <p className='text-white md:text-sm text-xs font-normal'>Hackney permit  </p>
                            <a
                                className='text-center border-theme-color px-3 py-1 md:text-sm md:px-2 md:py-0 text-xs text-white'

                                href={`https://api.roadlers.com/storage/${hackneyStatusOwner[0]?.document}`} target="_blank">View</a>

                        </div>

                        {HackneyStatus === 'Pending' ?
                            (
                                <div className="flex justify-end mt-4">

                                    <button
                                        onClick={(e) => adminHackneyActionModal(e)}
                                        className=''
                                        type='button'
                                    >
                                        <img src={message} alt="" className='w-8 -ml-1 mr-2 focus:outline-none' />

                                    </button>

                                    <button
                                        onClick={handleAcceptHackney}
                                        className='bg-blue-900 rounded text-white p-1 font-bold text-sm mr-2'>Accept</button>
                                    <button
                                        onClick={handleRejectHackney}

                                        className='bg-white rounded text-black p-1 font-bold text-sm'>
                                        Reject</button>
                                </div>
                            ) :
                            (

                                HackneyStatus === 'Accept' ?
                                    <div className="flex justify-end mt-4">

                                        <button
                                            onClick={(e) => adminHackneyActionModal(e)}
                                            className=''
                                            type='button'
                                        >
                                            <img src={message} alt="" className='w-8 -ml-1 mr-2 focus:outline-none' />

                                        </button>

                                        <button

                                            onClick={(e) => handleToggleChangeHackneyStatus(e)}

                                            className='bg-blue-900 rounded text-white py-1 px-2 font-bold text-sm mr-2'>Accepted</button>

                                    </div> :
                                    <div className="flex justify-end mt-4">

                                        <button
                                            onClick={(e) => adminHackneyActionModal(e)}
                                            className=''
                                            type='button'
                                        >
                                            <img src={message} alt="" className='w-8 -ml-1 mr-2 focus:outline-none' />

                                        </button>


                                        <button
                                            onClick={(e) => handleToggleChangeHackneyStatus(e)}

                                            className='bg-red-700 rounded text-white py-1 px-2 font-bold text-sm mr-2'>Rejected</button>
                                    </div>


                            )
                        }
                    </div> : ''
                }




                {
                    (proofStatus !== 'Accept' || VehicleLicenseStatus !== 'Accept' || vehImageDisplay === '3' || vehImageDisplay === '4') ?
                        <button


                            className='cursor-not-allowed bg-black text-gray-500 md:text-base text-sm w-full md:my-8 my-16 my p-2 text-center rounded-sm font-bold '>Click to Approve Account</button>

                        :

                        (
                            vehImageDisplay === '1' ? (
                                <button
                                    className='bg-blue-900 md:text-base text-white text-sm w-full md:my-8 my-16 my p-2 text-center rounded-sm font-bold '>Account & Document Verified</button>

                            ) : (

                                <button
                                    onClick={handleVerifyVehicleDoc}

                                    className='bg-amber-500 text-black md:text-base text-sm w-full md:my-8 my-16 my p-2 text-center rounded-sm font-bold '>Click to Approve Account</button>

                            )

                        )
                }

            </div>
        </>

    )
}

export default memo(ScreenA)