
import React, { Fragment, useState, useEffect } from 'react'

import users from '../../Images/user2.svg'
import sendicon from '../../Images/send.png'
import locationicon from '../../Images/location.svg'
import pen from '../../Images/pen.svg'
import like from '../../Images/Like-icon.png'
import like_two from '../../Images/Like-icon2.png'
import { RWebShare } from "react-web-share";

import { IoIosShareAlt } from "react-icons/io";

import { NavLink, useNavigate } from "react-router-dom";


function BookNow(props) {
    const {
        id,
        image,
        name,
        price,
        location,
        year,
        imageThree,
        
    } = props

    let amount = price;
    let amountNum = Number(price);

    const [liked, setLiked] = useState(false);
    const [allLike, setAllLike] = useState([]);

    let likes = []


    function handleLike(e) {
        e.preventDefault()
        if (localStorage.getItem("likedVehicle")) {
            likes = JSON.parse(localStorage.getItem("likedVehicle"))
        }
        likes.push(id)
        setLiked(true)
        // console.log('likerererere', liked)
        localStorage.setItem("likedVehicle", JSON.stringify(likes))


    }

    function handleunLike(e) {
        e.preventDefault()
        setLiked(false)

        let liked = localStorage.getItem('likedVehicle');
        let likedList = JSON.parse(liked)
        let index = likedList.findIndex((item) => item === id)


        likedList.splice(index, 1);
        localStorage.setItem('likedVehicle', JSON.stringify(likedList));

    }

    useEffect(() => {
        if (localStorage.getItem("likedVehicle")) {
            likes = JSON.parse(localStorage.getItem("likedVehicle"))
            setAllLike(likes)
        }

    }, [liked]);



    return <>
        <div className="flex flex-col items-center">
            <NavLink to={'/car-booking/' + id}>
                <div className="xl:w-56 lg:w-52  md:w-44 w-36 bg-white rounded-lg py-3 px-3 md:p-6 relative">
                    <div className="rounded-xl   xl:w-44 lg:w-40  md:w-32 w-full  xl:h-28 lg:h-24 md:h-20 h-16 flex items-center overflow-hidden md:overflow-hidden justify-center w-full border border-gray-300">
                        {/* <img src={image} alt="Car rentals Truck Hire in Lagos Nigeria " className='w-20 md:w-40 object-cover object-center' /> */}
                        {
                            image === null?
                            
                              ( <img src={`https://api.roadlers.com/storage/${imageThree}`} alt="Car rentals Truck Hire in Lagos Nigeria " className='rounded-md md:h-full md:w-full object-cover object-center'/>
                              )
                            :
                              (  <img src={`https://api.roadlers.com/storage/${image}`} alt="Car rentals Truck Hire in Lagos Nigeria " className='rounded-md md:h-full md:w-full object-cover object-center'/>

                              )
                            
                        }
                     

                    </div>
                    <h5 className='truncate md:font-bold text-black md:text-sm font-extrabold text-xx mt-2'>{name} ({year})</h5>
                    <hr className='md:w-20 w-12 md:mt-1 mt-1 marg border-t border-black' />

                    <div className="flex justify-between md:mt-2 mt-2 items-center font-bold w-full">
                        <h5 className='md:text-sm text-xx'>&#8358;{amountNum.toLocaleString('en-US', { maximumFractionDigits: 2 })}</h5>

                        <div className="flex items-center absolute right-0 ">
                            <img src={locationicon} alt="Car rentals Truck Hire in Lagos Nigeria " className='md:w-6 w-4' />
                            <p className=' lg:text-xs md:text-xx text-xxx lg:w-16 w-12  truncate'>{location}</p>
                        </div>
                    </div>

                    <div className="flex items-center text-xs md:mt-3 mt-1">
                        <div className="flex w-full justify-between items-center gap-1">


                            <NavLink className="h-6 md:h-7 bg-amber-500 border border-black w-2/4 text-center flex items-center justify-center md:text-xs text-xxx text-black font-bold rounded-md "
                                exact
                                to={'/car-booking/' + id}>
                                Book Now </NavLink>
                            <RWebShare
                                data={{
                                    text: "Like humans, flamingos make friends for life",
                                    url: `https://roadlers.com/car-booking/${id}`,
                                    title: "Book Vehicles on Roadlers",
                                }}
                              
                            >
                                <button>
                                    <img src={sendicon} alt="Car rentals Truck Hire in Lagos Nigeria " className='cursor-pointer ' />
                                </button>
                            </RWebShare>






                            {(allLike.includes(id)) ?
                                (
                                    <button
                                        onClick={handleunLike}
                                    >
                                        <img src={like_two} alt="Car rentals Truck Hire in Lagos Nigeria " className='cursor-pointer w-9 h-8 p-0' />
                                    </button>
                                ) :
                                (
                                    <button
                                        onClick={handleLike}
                                    >
                                        <img src={like} alt="Car rentals Truck Hire in Lagos Nigeria " className='cursor-pointer w-9 h-8 p-0' />
                                    </button>
                                )
                            }




                        </div>

                    </div>





                </div>
            </NavLink>

        </div>

    </>;
}

export default BookNow;
