import React from 'react';
import classnames from 'classnames';
import { usePagination } from './usePagination';
import './pagination.css';
import prev from '../Images/prev.png'
import fprev from '../Images/fprev.png'
import next from '../Images/next.png'
import fnext from '../Images/fnext.png'
const Pagination = props => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });

  // If there are less than 2 times in pagination range we shall not render the component
  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <ul
      className={classnames('pagination-container', { [className]: className })}
    >
     
      <li>
      
        <button 
        onClick={onPrevious}

          className={classnames('arrow', {
          disabled: currentPage === 1
        })}>
        
            <img src={prev} alt="" />
        </button>

      </li>
      {paginationRange.map(pageNumber => {
         
        // If the pageItem is a DOT, render the DOTS unicode character
        if (pageNumber === '...') {
          return <li className="pagination-item dots">&#8230;</li>;
        }
		
        // Render our Page Pills
        return (
          <li
            className={classnames('pagination-item', {
              selected: pageNumber === currentPage
            })}
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </li>
        );
      })}
      {/*  Right Navigation arrow */}
      <li
       
      >
        <button 
                onClick={onNext}


          className={classnames('arrow', {
            disabled: currentPage === lastPage

        })}>
        
            <img src={next} alt="" />
        </button>
      </li>
    </ul>
  );
};

export default Pagination;