import React, { memo } from 'react';
import one from '../../Images/one.png'
import pen from '../../Images/pen.svg'
function Bulk(props) {
  const { driverOne, driverTwo, driverThree } = props
  const { priceOne, priceTwo, priceThree } = props
  const { amountOne, amountTwo, amountThree, themeButton, buttonOne, buttonTwo, title } = props



  return <>
    <main className='mt-8 md:mt-4 md:mb-16'>
{/* Small devices */}
      <div className="flex gap-8 border rounded-lg p-4 justify-between  md:hidden">
        <div className="flex flex-col w-7/12">
          <h4 className='text-white text-sm font-semibold w-full mb-4'>{title}</h4>

          <div className="flex items-center mb-4">
            <img src={one} alt="" className='w-4 mr-2 ml-0' />

            <p className='text-white text-xx'>26/ march/ 2022</p>
          </div>

          {driverOne ? <img src={driverOne} alt="driverOne" className='w-20' /> : ''}
          {driverTwo ? <img src={driverTwo} alt="driverTwo" className='w-20' /> : ''}
          {driverThree ? <img src={driverThree} alt="driverThree" className='w-20' /> : ''}

          <div className="flex items-center mt-6">
            <p className='mr-2 font-semibold text-xx theme-color mr-4'>Edit Accounts</p>
            <img src={pen} alt="" className='w-3' />

          </div>

        </div>

        <div className="flex flex-col gap-4 w-5/12 text-center items-center justify-center">

          <div className="items-center">
            <h4 className='theme-color font-semibold text-sm'>Accounts</h4>
            {priceOne ? <h5 className='font-semibold text-white text-xs'>{priceOne}</h5> : ''}
            {priceTwo ? <h5 className='font-semibold text-white text-xs'>{priceTwo}</h5> : ''}
            {priceThree ? <h5 className='font-semibold text-white text-xs'>{priceThree}</h5> : ''}

          </div>
          <div className="items-center">
            <h4 className='theme-color font-semibold'>Amount</h4>

            {amountOne ? <h5 className='font-semibold text-white text-xs'>{amountOne}</h5> : ''}
            {amountTwo ? <h5 className='font-semibold text-white text-xs'>{amountTwo}</h5> : ''}
            {amountThree ? <h5 className='font-semibold text-white text-xs'>{amountThree}</h5> : ''}
          </div>
          <div className="flex flex-col gap-4 w-full">
            {buttonOne || buttonTwo ? <button className='bg-red-700 text-white p-2 text-xx rounded-lg w-full'>Due Today</button> : ''}
            {buttonOne || buttonTwo ? <button className='border border-white text-white p-2 text-xx rounded-lg w-full'>Pay All</button>
              : ''}
            {themeButton ? <button className='theme text-black font-semibold p-2 text-xx rounded-lg w-full'>Due in 2 Days</button> : ''}




          </div>

        </div>
      </div>

{/* big devices */}

      <div className="flex border rounded-lg p-8 justify-between hidden md:flex">

        <div className="">
          {driverOne ? <img src={driverOne} alt="driverOne" className='xl:w-44 lg:w-40 md:w-32' /> : ''}
          {driverTwo ? <img src={driverTwo} alt="driverTwo" className='xl:w-44 lg:w-40 md:w-32' /> : ''}
          {driverThree ? <img src={driverThree} alt="driverThree" className='xl:w-44 lg:w-40 md:w-32' /> : ''}
        </div>
        <div className="flex flex-col items-start">
          <h4 className='text-white xl:text-lg md:text-base font-semibold w-32 mb-4'>{title}</h4>
          <div className="flex items-center mb-4">
            <img src={one} alt="" className='xl:w-7 lg:w-6 md:w-6 mr-4 ml-0' />

            <p className='text-white'>26/ march/ 2022</p>
          </div>

          <div className="flex items-center">
            <p className='mr-2 font-normal theme-color mr-4'>Edit Accounts</p>
            <img src={pen} alt="" className='w-4' />

          </div>

        </div>
        <div className="flex flex-col items-center gap-4">
          <h4 className='theme-color font-semibold'>Accounts</h4>
          {priceOne ? <h5 className='font-semibold text-white'>{priceOne}</h5> : ''}
          {priceTwo ? <h5 className='font-semibold text-white'>{priceTwo}</h5> : ''}
          {priceThree ? <h5 className='font-semibold text-white'>{priceThree}</h5> : ''}





        </div>
        <div className="flex flex-col items-center gap-4">
          <h4 className='theme-color font-semibold'>Amount</h4>

          {amountOne ? <h5 className='font-semibold text-white'>{amountOne}</h5> : ''}
          {amountTwo ? <h5 className='font-semibold text-white'>{amountTwo}</h5> : ''}
          {amountThree ? <h5 className='font-semibold text-white'>{amountThree}</h5> : ''}
        </div>
        <div className="flex flex-col gap-2">
          {buttonOne || buttonTwo ? <button className='bg-red-700 text-white p-2 text-sm rounded-lg md:w-24 lg:w-32 xl:w-40'>Due Today</button> : ''}
          {buttonOne || buttonTwo ? <button className='border border-white text-white p-2 text-sm rounded-lg md:w-24 lg:w-32 xl:w-40'>Pay All</button>
            : ''}
          {themeButton ? <button className='theme text-black font-semibold p-2 text-sm rounded-lg md:w-24 lg:w-32 xl:w-40'>Due in 2 Days</button> : ''}




        </div>
      </div>
    </main>
  </>;
}

export default memo(Bulk);
