import apiRequest from "../http-common"

const getHistoryService = (user_id) => {
    return apiRequest.get(`/transaction-history/${user_id}`)
}

const TransactionService = {
    getHistoryService
}

export default TransactionService