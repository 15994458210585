import React, { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import Header from '../Header'
import google from '../../Images/google.svg'
import fbb from '../../Images/fbb.svg'
import usertheme from '../../Images/usertheme.svg'
import closed from '../../Images/closed.svg'
import success from '../../Images/success.png'
import upload from '../../Images/gsoupload.png'
import approvals from '../../Images/57.png'
import { useDispatch, useSelector } from "react-redux"

import { NavLink, useNavigate } from "react-router-dom";


import car from '../../Images/carr.png'

function Onboarding() {

    const { carInit } = useSelector((state) => state.carInit)

    console.log("test", carInit)

    const [bg, setBg] = useState(false)

    let [uploadOpen, setUploadOpen] = useState(false)

    function closeModal() {
        setUploadOpen(false)
        setBg(false)
        

    }

    function uploadModal() {
        setUploadOpen(true)
        setBg(true)

    }

    return (
        <div className={bg === true ? " blur-sm contrast-50" : "w-full"}>


<Transition appear show={uploadOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed flex items-center justify-center w-full px-6 mt-8  inset-0 z-10 overflow-y-auto"
                    onClose={closeModal}
                >
                    <div className="min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="md:w-10/12 p-6  inline-block  max-w-md pt-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-md">
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg font-medium leading-6 text-gray-900"
                                >
                                    <img src={upload} alt="" />
                                </Dialog.Title>

                                <div className=" flex flex-col gap-4 items-center mb-8">

                                    

                                    <NavLink to='/' className='text-xs text-center rounded-md text-white font-bold bg-black p-2 w-full'>Duplicate Previous Upload</NavLink>
                                    <NavLink to='/driver-car-registration' className='text-xs text-center rounded-md text-black font-bold bg-amber-500 p-2 w-full'>Start a new Upload</NavLink>

                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>

            <Header user='customer' />
            <div className="flex justify-center md:p-28 px-12 pt-20 p-6 flex-col items-center w-full pb-20 mb-20 relative">
                <h1 className=" text-white font-bold md:text-3xl text-lg mb-8">Welcome Onboard!</h1>
                <div className="bg-white w-full md:w-2/6 h-auto rounded-lg pb-8">
                    {/* <div className="flex justify-end p-6 ">
                        <img src={close} alt="" className='w-6 cursor-pointer'/>

                    </div> */}
                    <div className="flex flex-col md:p-3 p-6 justify-center items-center ">
                        <img src={approvals} alt="" className='md:w-full blurr md:h-full h-68 w-full' />
                    <NavLink
                     className="bg-black p-2 md:text-sm text-xs text-center text-white font-bold rounded w-10/12 hover:text-white" exact to="/pending-notice"> Done</NavLink>
                        


                    {/* <button 
                    onClick={uploadModal}
                    className='bg-amber-500 p-2 md:text-sm text-xs text-black font-bold rounded w-10/12 mt-4'>Add Another Vehicle</button>
                  
                     */}

                    <NavLink
                    exact to="/driver-car-registration"
                    className='bg-amber-500 p-2 md:text-sm text-xs text-center text-black font-bold rounded w-10/12 mt-4'>Add Another Vehicle
                  
                    </NavLink>

                    </div>
                </div>

               
            </div>

        </div>
    )
}

export default Onboarding