import React, { Fragment, useState, useEffect, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { BASE_URL } from "../utils/constant"
import { HashLink} from "react-router-hash-link";
import dashboard from '../../Images/dashboard.png'
import admin_request from '../../Images/admin_request.svg'
import service_p from '../../Images/service_p.svg'
import reports from '../../Images/reports.svg'
import customer from '../../Images/customers.png'
import notifications from '../../Images/notifications.png'
import download from '../../Images/download.svg'
import car_asset from '../../Images/car_asset.svg'
import icon from '../../Images/icon.svg'
import car from '../../Images/car.png'
import message from '../../Images/message.svg'
import success from '../../Images/success.png'

import payment from '../../Images/payment.png'
import naira from '../../Images/naira.png'
import one from '../../Images/one.png'
import eyee from '../../Images/eyee.png'
import mark from '../../Images/mark.png'
import cancelImg from '../../Images/cancel.svg'
// import success from '../../Images/success.png'

import pen from '../../Images/pen.svg'
import axios from "axios";
import moment from 'moment';



import { useFormik } from 'formik'
import * as Yup from 'yup'
import { NavLink, useNavigate } from "react-router-dom";
import Switch from "react-switch";
import Select from 'react-select'
import getCookie from '../utils/SecuredTokens';

const Navigation = ({statusApproval}) => {

   

    let userData = getCookie("user");
    let userObject = JSON.parse(userData) 
    let token = userObject?.token
    // let id = userObject?.id
    let id = JSON.parse(localStorage.getItem("userId"))
   
    let userCategory = JSON.parse(localStorage.getItem("userCategory"))
   
    const [bg, setBg] = useState(false)

    const [providerNotfication, setProviderNotification] = useState(false)
    const [providerTransaction, setProviderTransaction] = useState(false)
    const [transactionHistArray, setTransactionHistArray] = useState([])
    const [providerNotificationArray, setProviderNotificationArray] = useState([])

    const closeModal = () => {
      setBg(false)
      setProviderNotification(false)
      setProviderTransaction(false)
    }


    // HANDLE PROVIDER NOTIFICATION MODAL
    const handleProviderNotificationModal = () => {
      setBg(true)
      setProviderNotification(true)
    }

    // HANDLE PROVIDER TRANSACTION MODAL
    const handleProviderTransactionModal = () => {
        setBg(true)
        setProviderTransaction(true)
    }

    // GET REQUEST FOR TRANSACTION HISTORY
    const getTransactionHistory = () => {
        axios.get(`${BASE_URL}/v1/transaction-history/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            }
        }).then((response) => {
            setTransactionHistArray(response.data.data)
        }).catch((error) => {

        })
    }

    useEffect(() => {
        getTransactionHistory()
    }, [providerTransaction])

    // GET REQUEST FOR PROVIDER NOTIFICATION
    const getProviderNotifcation = () => {
        axios.get(`${BASE_URL}/v1/etch-provider-notification/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            }
        }).then((response) => {
            setProviderNotificationArray(response.data.data)
        }).catch((error) => {
        })
    }

    useEffect(() => {
        getProviderNotifcation()
    }, [])

    
  return (
 
          <div className='border-r border-amber-500 h-100 w-1/5 w-full pb-16 hidden provider-nav-sidebar md:block pt-20 '>
          <div sttle={{position: "absolute", zIndex: "999"}}>
              <Transition appear show={providerNotfication} as={Fragment}>
                  <Dialog
                      as="div"
                      className="fixed px-6 inset-0 z-[1000] overflow-y-auto"
                      onClose={closeModal}
                  >
                      <div className="min-h-screen px-4 text-center">
                          <Transition.Child
                              as={Fragment}
                              enter="ease-out duration-300"
                              enterFrom="opacity-0"
                              enterTo="opacity-25 bg-white"
                              leave="ease-in duration-200"
                              leaveFrom="opacity-25"
                              leaveTo="opacity-0"
                          >
                              <Dialog.Overlay className="fixed inset-0" />
                          </Transition.Child>

                          {/* This element is to trick the browser into centering the modal contents. */}
                          <span
                              className="inline-block h-screen align-middle"
                              aria-hidden="true"
                          >
                              &#8203;
                          </span>
                          <Transition.Child
                              as={Fragment}
                              enter="ease-out duration-300"
                              enterFrom="opacity-0 scale-95"
                              enterTo="opacity-100 scale-100"
                              leave="ease-in duration-200"
                              leaveFrom="opacity-100 scale-100"
                              leaveTo="opacity-0 scale-95"
                          >
                              <div className="notification-modal-wrapper inline-block w-full max-w-md pt-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-transparent shadow-xl rounded-2xl">
                                  <div className="bg-project rounded-2xl p-6">
                                      <div className="header flex mb-6">
                                          <div className='w-11/12'>
                                              <h4 className='text-white text-center font-bold text-2xl'>Notifications</h4>
                                          </div>
                                          <button className='w-1/12'>
                                              <img src={cancelImg} className='w-[30px]' onClick={closeModal} alt="Cancel Modal" />
                                          </button>
                                      </div>

                                      <div className="notification-lists-container overflow-y-scroll h-[450px]">

                                            {
                                                providerNotificationArray?.map((item) => {
                                                    return(
                                                    <div className="notification-box bg-white p-6 rounded-xl mt-4">
                                                        <div className="flex justify-between mb-4">
                                                          <h5 className='font-bold text-lg '>{item?.data?.title}</h5>
      
                                                          <h5 className='font-bold text-lg'>{moment(item?.created_at).format("YYYY/MM/DD")}</h5>
                                                        </div>
                                                        
      
                                                        <p>
                                                        {item?.data?.message}
                                                        </p>
                                                    </div>
                                                    )
                                                })
                                            }
                                          
                                      </div>
                                  </div>
                              </div>
                          </Transition.Child>
                      </div>
                  </Dialog>
              </Transition>
          </div>

          <div sttle={{position: "absolute", zIndex: "999"}}>
              <Transition appear show={providerTransaction} as={Fragment}>
                  <Dialog
                      as="div"
                      className="fixed px-6 inset-0 z-[1000] overflow-y-auto"
                      onClose={closeModal}
                  >
                      <div className="min-h-screen px-4 text-center">
                          <Transition.Child
                              as={Fragment}
                              enter="ease-out duration-300"
                              enterFrom="opacity-0"
                              enterTo="opacity-25 bg-white"
                              leave="ease-in duration-200"
                              leaveFrom="opacity-25"
                              leaveTo="opacity-0"
                          >
                              <Dialog.Overlay className="fixed inset-0" />
                          </Transition.Child>

                          {/* This element is to trick the browser into centering the modal contents. */}
                          <span
                              className="inline-block h-screen align-middle"
                              aria-hidden="true"
                          >
                              &#8203;
                          </span>
                          <Transition.Child
                              as={Fragment}
                              enter="ease-out duration-300"
                              enterFrom="opacity-0 scale-95"
                              enterTo="opacity-100 scale-100"
                              leave="ease-in duration-200"
                              leaveFrom="opacity-100 scale-100"
                              leaveTo="opacity-0 scale-95"
                          >
                              <div className="notification-modal-wrapper inline-block w-full max-w-md pt-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-transparent shadow-xl rounded-2xl">
                                  <div className="bg-project rounded-2xl p-6">
                                      <div className="header flex mb-6">
                                          <div className='w-11/12'>
                                              <h4 className='text-white text-center font-bold text-lg'>Transaction History</h4>
                                          </div>
                                          <button className='w-1/12'>
                                              <img src={cancelImg} className='w-[30px]' onClick={closeModal} alt="Cancel Modal" />
                                          </button>
                                      </div>

                                      <div className="notification-lists-container overflow-y-scroll h-[450px]">
                                          {/* {notifyListData.map((item) => ( */}
                                            {
                                                transactionHistArray?.map((item) => {
                                                    return (
                                                        <div className="notification-box bg-white p-6 rounded-xl mt-4">
                                                            {
                                                                item.transact_mode === 'debit' &&
                                                                <div className="flex justify-between mb-4">
                                                                    <h5 className='font-bold text-[#A90202] text-lg '>Transfer Successful</h5>

                                                                    <h5 className='font-bold text-[#A90202] text-lg'>{moment(item?.created_at).format("YYYY/MM/DD")}</h5>
                                                                </div>
                                                            }

                                                            {
                                                                item.transact_mode === 'credit' &&
                                                                <div className="flex justify-between mb-4">
                                                                    <h5 className='font-bold text-[#106B08] text-lg'>Earning</h5>

                                                                    <h5 className='font-bold text-[#106B08] text-lg'>{moment(item?.created_at).format("YYYY/MM/DD")}</h5>
                                                                </div>
                                                            }
                                                            
        
                                                            <p>
                                                            {item?.contents}
                                                            </p>
                                                        </div>
                                                    )
                                                })
                                            }
                                          {/* ))} */}
                                      </div>
                                  </div>
                              </div>
                          </Transition.Child>
                      </div>
                  </Dialog>
              </Transition>
          </div>


                {
                    statusApproval === 'Pending' ? 
                    <button className='mt-12 py-2 px-5 bg-[#083D8C] rounded-md text-sm font-bold text-white'>Pending Approval </button> : 
                    <div className="mt-20"></div>
                    // <button className='mt-12 py-2 px-5 bg-amber-500 rounded-md text-sm font-bold text-black'>Switch To Customer </button>
                }

                <div className='mt-6 my-3'>
                    <span className='flex items-center text-white'> <img src={dashboard} alt="" className='mr-3 w-8' /> 
                    {
                        userCategory === 'business' && 
                        <NavLink to="/approved-service-company" className='font-light' >My Profile</NavLink>
                    }

                    {
                        userCategory === 'individual' && 
                        <NavLink to="/approved-service" className='font-light' >My Profile</NavLink>
                    }
                    </span>
                    
                </div>

                <div className='my-3'>
                    <span className='flex items-center text-white'> <img src={payment} alt="" className='mr-3 w-8' /> 
                    <NavLink to="/service-provider-dashboard#wallet" className='font-light'>My Wallet</NavLink></span>
                </div>

                <div className='my-3'>
                    <span className='flex items-center text-white'> <img src={admin_request} alt="" className='mr-3 w-8' /> 
                    <HashLink to="/service-provider-dashboard#myRequests" className='font-light'>My Request </HashLink></span>
                </div>

                <div className='my-3'>
                    <span className='flex items-center text-white'> <img src={car_asset} alt="" className='mr-3 w-8' /> 
                    <HashLink to="/service-provider-dashboard#asset" className='font-light'>My Assets</HashLink></span>
                </div>

                <div className='my-3'>
                    <span className='flex items-center text-white cursor-pointer' onClick={handleProviderTransactionModal}> <img src={naira} alt="" className='mr-3 w-8' /> 
                    Transaction History</span>
                </div>

                <div className='my-3'>
                    <span className='flex items-center text-white curosor-pointer'> <img src={one} alt="" className='md:ml-1 md:mr-5 w-6' /> 
                    <HashLink to="/service-provider-dashboard#calendar" className='font-light'>My Calendar</HashLink>
                    </span>
                </div>

                <div className='my-3'>
                    <span className='flex items-center text-white cursor-pointer' onClick={handleProviderNotificationModal}> <img src={notifications} alt="" className='mr-3 w-8' /> 
                    Notification</span>
                </div>

                {/* <div className='my-3'>
                    <span className='flex items-center text-white'> <img alt="" className='mr-3 w-8' /> 
                    <a href="" className='font-light'>How it works</a></span>
                </div> */}

            </div>
  
  )
}
export default Navigation;