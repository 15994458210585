import React, { Fragment, useState, useEffect, useContext, useRef, memo } from 'react'
import { Menu, Dialog, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import icon from '../../Images/icon.svg'
import car from '../../Images/car.png'
import message from '../../Images/message.svg'
import { NavLink, useParams } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../utils/constant"
import getCookie from '../utils/SecuredTokens'

function ScreenD(props) {

    const docRef = useRef(null)




    let user = getCookie("user");
    let userObject = JSON.parse(user)
    let token = userObject?.token


    const { id } = useParams()



    const { providers } = props
    const [GuarantorOne, setGuarantorOne] = useState(false);
    const [GuarantorTwo, setGuarantorTwo] = useState(false);

    console.log('providersprovidersprovidersproviders', providers)
    function handleGuarantorOne(e) {
        e.preventDefault()
        setGuarantorOne(true)
    }

    function handleGuarantorOneHide(e) {
        e.preventDefault()
        setGuarantorOne(false)
    }



    function handleGuarantorTwo(e) {
        e.preventDefault()
        setGuarantorTwo(true)
    }
    function handleGuarantorTwoHide(e) {
        e.preventDefault()
        setGuarantorTwo(false)
    }


    let licenseDoc = providers.driver_document.filter(license => {
        return license?.doc_type?.toLowerCase().includes('driver_licence') &&

            license?.driver_id === id.toString()
    })

    let PassportDoc = providers.driver_document.filter(passport => {
        return passport?.doc_type?.toLowerCase().includes('passport') &&

            passport?.driver_id === id.toString()
    })

    let guarantor_one = providers.guarantor.filter(guarantor => {
        return guarantor?.guarantor_type?.toLowerCase().includes('guarantor1')
    })

    let guarantor_two = providers.guarantor.filter(guarantor => {
        return guarantor?.guarantor_type?.toLowerCase().includes('guarantor2')
    })

    const [driverLicenseStatus, setDriverLicenseStatus] = useState(licenseDoc[0]?.status);
    const driverLicense = licenseDoc[0]?.document;

    let [licenceNote, setLicenceNote] = useState(licenseDoc[0]?.comment);




    const [driverPassportStatus, setDriverPassportStatus] = useState(PassportDoc[0]?.status);
    const driverPassport = PassportDoc[0]?.document;

    let [passportNote, setPassportNote] = useState(PassportDoc[0]?.comment);

    let [guarantorNote, setGuarantorNote] = useState(guarantor_one[0]?.comment);






    const [driverGuarantorOneStatus, setDriverGuarantorOneStatus] = useState(guarantor_one[0]?.status);
    const [driverGuarantorTwoStatus, setDriverGuarantorTwoStatus] = useState(guarantor_two[0]?.status);


    const [driverStatus, setDriverStatus] = useState(providers.status);
    const [driverDisplay, setDriverDisplay] = useState(providers.display);

    let [licenceNoteOpen, setLicenceNoteOpen] = useState(false)
    let [passportNoteOpen, setPassportNoteOpen] = useState(false)

    let [guarantorNoteOpen, setGuarantorNoteOpen] = useState(false)


    let [bg, setBg] = useState(false)



    function closeModal() {
        setGuarantorNoteOpen(false)
        setLicenceNoteOpen(false)
        setPassportNoteOpen(false)
        setBg(false)

    }
    function adminGuanrantorActionModal() {
        setBg(true)
        setGuarantorNoteOpen(true)
    }

    function adminLicenceActionModal() {
        setBg(true)
        setLicenceNoteOpen(true)
    }

    function adminPassportActionModal() {
        setBg(true)
        setPassportNoteOpen(true)
    }



    function handleRejectLicense(e) {
        e.preventDefault();
        let docId = licenseDoc?.[0].id
        let formData = new FormData();
        formData.append("id", docId);
        formData.append("comment", licenseDoc?.[0].comment);
        formData.append("status", 'Rejected');
        axios({
            method: "post",
            url: `${BASE_URL}/v1/verify-driver-document`,
            data: formData,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            },
        }).then(function (response) {
            const message = response.data.data;
            console.log('messagemessagemessagemessage', message)
            setDriverLicenseStatus('Rejected')

            // set the status of driver to rejected
            let providerId = providers.id
            let formData = new FormData();
            formData.append("id", providerId);
            formData.append("status", 'Rejected');
            formData.append("display", '1');
            axios({
                method: "post",
                url: `${BASE_URL}/v1/verify-driver-profile`,
                data: formData,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    "Access-Control-Allow-Origin": "*",
                },
            }).then(function (response) {
                const respData = response.data.data;
                console.log('messagemessagemessagemessage', respData)
                setDriverDisplay('0')
                setDriverStatus('Rejected')

            }).catch(function (error) {
                //handle error
                console.log("error ", error.response.data);
            });
        }).catch(function (error) {
            //handle error
            console.log("error ", error.response.data);
        });

    };

    function handleAcceptLicense(e) {
        e.preventDefault();
        let docId = licenseDoc?.[0].id
        let formData = new FormData();
        formData.append("id", docId);
        formData.append("comment", licenseDoc?.[0].comment);
        formData.append("status", 'Accept');
        axios({
            method: "post",
            url: `${BASE_URL}/v1/verify-driver-document`,
            data: formData,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            },
        }).then(function (response) {
            const message = response.data.data;
            console.log('messagemessagemessagemessage', message)
            setDriverLicenseStatus('Accept')
        }).catch(function (error) {
            //handle error
            console.log("error ", error.response.data);
        });





    };

    // this function attach a comment to licence document via a post request

    function adminLicenceAction(e) {
        e.preventDefault();
        let docId = licenseDoc?.[0].id

        let formData = new FormData();
        formData.append("id", docId);
        formData.append("comment", licenceNote);

        formData.append("status", licenseDoc?.[0].status);
        axios({
            method: "post",
            url: `${BASE_URL}/v1/verify-driver-document`,
            data: formData,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            },
        }).then(function (response) {

            const message = response.data.data;
            setLicenceNoteOpen(false)

        }).catch(function (error) {
            //handle error
            console.log("error ", error.response.data);
        });
    };


    function handleRejectPassport(e) {
        e.preventDefault();
        let docId = PassportDoc[0]?.id
        let formData = new FormData();
        formData.append("id", docId);
        formData.append("comment", PassportDoc[0]?.comment);
        formData.append("status", 'Rejected');
        axios({
            method: "post",
            url: `${BASE_URL}/v1/verify-driver-document`,
            data: formData,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            },
        }).then(function (response) {
            const message = response.data.data;
            console.log('messagemessagemessagemessage', message)
            setDriverPassportStatus('Rejected')



            // set the status of driver to rejected
            let providerId = providers.id
            let formData = new FormData();
            formData.append("id", providerId);
            formData.append("status", 'Rejected');
            formData.append("display", '1');
            axios({
                method: "post",
                url: `${BASE_URL}/v1/verify-driver-profile`,
                data: formData,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    "Access-Control-Allow-Origin": "*",
                },
            }).then(function (response) {
                const respData = response.data.data;
                console.log('messagemessagemessagemessage', respData)
                setDriverDisplay('0')
                setDriverStatus('Rejected')

            }).catch(function (error) {
                //handle error
                console.log("error ", error.response.data);
            });
        }).catch(function (error) {
            //handle error
            console.log("error ", error.response.data);
        });

    };

    function handleAcceptPassport(e) {
        e.preventDefault();
        let docId = PassportDoc[0]?.id
        let formData = new FormData();
        formData.append("id", docId);
        formData.append("comment", PassportDoc[0]?.comment);
        formData.append("status", 'Accept');
        axios({
            method: "post",
            url: `${BASE_URL}/v1/verify-driver-document`,
            data: formData,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            },
        }).then(function (response) {
            const message = response.data.data;
            console.log('messagemessagemessagemessage', message)
            setDriverPassportStatus('Accept')
        }).catch(function (error) {
            //handle error
            console.log("error ", error.response.data);
        });





    };

    function adminPassportAction(e) {
        e.preventDefault();
        let docId = PassportDoc[0]?.id

        let formData = new FormData();
        formData.append("id", docId);
        formData.append("comment", passportNote);

        formData.append("status", PassportDoc[0]?.status);
        axios({
            method: "post",
            url: `${BASE_URL}/v1/verify-driver-document`,
            data: formData,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            },
        }).then(function (response) {

            const message = response.data.data;
            setPassportNoteOpen(false)

        }).catch(function (error) {
            //handle error
            console.log("error ", error.response.data);
        });
    };


    function handleRejectGuarantorOne(e) {
        e.preventDefault();
        let docId = guarantor_one[0]?.id
        let formData = new FormData();
        formData.append("id", docId);
        formData.append("status_msg", 'Guarantor Rejected');
        formData.append("status", 'Rejected');
        axios({
            method: "post",
            url: `${BASE_URL}/v1/verify-guarantor`,
            data: formData,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            },
        }).then(function (response) {
            const message = response.data.data;
            console.log('messagemessagemessagemessage', message)
            setDriverGuarantorOneStatus('Rejected')

            // set the status of driver to rejected
            let providerId = providers.id
            let formData = new FormData();
            formData.append("id", providerId);
            formData.append("status", 'Rejected');
            formData.append("display", '0');
            axios({
                method: "post",
                url: `${BASE_URL}/v1/verify-driver-profile`,
                data: formData,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    "Access-Control-Allow-Origin": "*",
                },
            }).then(function (response) {
                const respData = response.data.data;
                console.log('messagemessagemessagemessage', respData)
                setDriverDisplay('0')
                setDriverStatus('Rejected')

            }).catch(function (error) {
                //handle error
                console.log("error ", error.response.data);
            });

        }).catch(function (error) {
            //handle error
            console.log("error ", error.response.data);
        });

    };

    function handleAcceptGuarantorOne(e) {
        e.preventDefault();
        let docId = guarantor_one[0]?.id
        let formData = new FormData();
        formData.append("id", docId);
        formData.append("status_msg", 'Guarantor Accepted');
        formData.append("status", 'Accept');


        axios({
            method: "post",
            url: `${BASE_URL}/v1/verify-guarantor`,
            data: formData,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            },
        }).then(function (response) {
            const message = response.data.data;
            setDriverGuarantorOneStatus('Accept')
            console.log('messagemessagemessagemessage', driverGuarantorOneStatus)

        }).catch(function (error) {
            //handle error
            console.log("error ", error.response.data);
        });





    };


    function adminGuarantorAction(e) {
        e.preventDefault();
        let docId = guarantor_one[0]?.id
        let formData = new FormData();
        formData.append("id", docId);

        formData.append("comment", guarantorNote);


        axios({
            method: "post",
            url: `${BASE_URL}/v1/verify-guarantor`,
            data: formData,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            },
        }).then(function (response) {
            const message = response.data.data;
            // setDriverGuarantorOneStatus('Accept')

        }).catch(function (error) {
            //handle error
            console.log("error ", error.response.data);
        });





    };

    function handleRejectGuarantorTwo(e) {
        e.preventDefault();
        let docId = guarantor_two[0]?.id
        let formData = new FormData();
        formData.append("id", docId);
        formData.append("status_msg", 'Guarantor Rejected');
        formData.append("status", 'Rejected');
        axios({
            method: "post",
            url: `${BASE_URL}/v1/verify-guarantor`,

            data: formData,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            },
        }).then(function (response) {
            const message = response.data.data;
            console.log('messagemessagemessagemessage', message)
            setDriverGuarantorTwoStatus('Rejected')

            // set the status of driver to rejected
            let providerId = providers.id
            let formData = new FormData();
            formData.append("id", providerId);
            formData.append("status", 'Rejected');
            formData.append("display", '0');
            axios({
                method: "post",
                url: `${BASE_URL}/v1/verify-driver-profile`,
                data: formData,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    "Access-Control-Allow-Origin": "*",
                },
            }).then(function (response) {
                const respData = response.data.data;
                console.log('messagemessagemessagemessage', respData)
                setDriverDisplay('0')
                setDriverStatus('Rejected')

            }).catch(function (error) {
                //handle error
                console.log("error ", error.response.data);
            });
        }).catch(function (error) {
            //handle error
            console.log("error ", error.response.data);
        });

    };

    function handleAcceptGuarantorTwo(e) {
        e.preventDefault();
        let docId = guarantor_two[0]?.id
        let formData = new FormData();
        formData.append("id", docId);
        formData.append("status_msg", 'Guarantor Accepted');
        formData.append("status", 'Accept');


        axios({
            method: "post",
            url: `${BASE_URL}/v1/verify-guarantor`,
            data: formData,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            },
        }).then(function (response) {
            const message = response.data.data;
            console.log('messagemessagemessagemessage', message)
            setDriverGuarantorTwoStatus('Accept')
        }).catch(function (error) {
            //handle error
            console.log("error ", error.response.data);
        });





    };



    function verifyProviderProfile(e) {
        e.preventDefault();
        let providerId = providers.id
        let formData = new FormData();
        formData.append("id", providerId);
        formData.append("status", driverLicenseStatus === 'Rejected' ? 'Rejected' : 'Pending');
        formData.append("display", '2');
        axios({
            method: "post",
            url: `${BASE_URL}/v1/verify-driver-profile`,
            data: formData,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            },
        }).then(function (response) {
            const respData = response.data.data;
            console.log('messagemessagemessagemessage', respData)

            setDriverDisplay('2')
            docRef.current?.scrollIntoView({ behavior: 'smooth' });

        }).catch(function (error) {
            //handle error
            console.log("error ", error.response.data);
        });
    };
    function toggleVerifyProvider(e) {
        e.preventDefault();
        let providerId = providers.id
        let formData = new FormData();
        formData.append("id", providerId);
        formData.append("status", 'Pending');
        formData.append("display", '0');
        axios({
            method: "post",
            url: `${BASE_URL}/v1/verify-driver-profile`,
            data: formData,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            },
        }).then(function (response) {
            const respData = response.data.data;
            console.log('messagemessagemessagemessage', respData)

            setDriverDisplay('0')


        }).catch(function (error) {
            //handle error
            console.log("error ", error.response.data);
        });
    };


    function handleToggleLicense(e) {
        e.preventDefault();
        let docId = licenseDoc?.[0].id
        let formData = new FormData();
        formData.append("id", docId);
        formData.append("comment", licenseDoc?.[0].comment);
        formData.append("status", 'Pending');
        axios({
            method: "post",
            url: `${BASE_URL}/v1/verify-driver-document`,
            data: formData,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            },
        }).then(function (response) {
            const message = response.data.data;
            console.log('messagemessagemessagemessage', message)
            setDriverLicenseStatus('Pending')
        }).catch(function (error) {
            //handle error
            console.log("error ", error.response.data);
        });





    };


    function handleToggleGuarantorOne(e) {
        e.preventDefault();
        let docId = guarantor_one[0]?.id
        let formData = new FormData();
        formData.append("id", docId);
        formData.append("status_msg", 'Guarantor Pending');
        formData.append("status", 'Pending');


        axios({
            method: "post",
            url: `${BASE_URL}/v1/verify-guarantor`,
            data: formData,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            },
        }).then(function (response) {
            const message = response.data.data;
            setDriverGuarantorOneStatus('Pending')

        }).catch(function (error) {
            //handle error
            console.log("error ", error.response.data);
        });





    };

    function handleToggleGuarantorTwo(e) {
        e.preventDefault();

        let docId = guarantor_two[0]?.id
        let formData = new FormData();
        formData.append("id", docId);
        formData.append("status_msg", 'Guarantor Pending');
        formData.append("status", 'Pending');



        axios({
            method: "post",
            url: `${BASE_URL}/v1/verify-guarantor`,
            data: formData,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            },
        }).then(function (response) {
            const message = response.data.data;
            setDriverGuarantorTwoStatus('Pending')

        }).catch(function (error) {
            //handle error
            console.log("error ", error.response.data);
        });





    };

    function handleTogglePassport(e) {
        e.preventDefault();
        let docId = PassportDoc[0]?.id
        let formData = new FormData();
        formData.append("id", docId);
        formData.append("comment", PassportDoc[0]?.comment);
        formData.append("status", 'Pending');
        axios({
            method: "post",
            url: `${BASE_URL}/v1/verify-driver-document`,
            data: formData,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            },
        }).then(function (response) {
            const message = response.data.data;
            setDriverPassportStatus('Pending')

        }).catch(function (error) {
            //handle error
            console.log("error ", error.response.data);
        });

    };

    function verifyProvider(e) {
        e.preventDefault();
        let providerId = providers.id
        let formData = new FormData();
        formData.append("id", providerId);
        formData.append("status", 'Approved');
        formData.append("display", '1');
        axios({
            method: "post",
            url: `${BASE_URL}/v1/verify-driver-profile`,
            data: formData,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            },
        }).then(function (response) {
            const respData = response.data.data;
            console.log('messagemessagemessagemessage', respData)
            setDriverDisplay('1')
            setDriverStatus('Approved')

        }).catch(function (error) {
            //handle error
            console.log("error ", error.response.data);
        });
    };




    return (
        <>



            <Transition appear show={licenceNoteOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="w-full md:w-11/12  fixed flex justify-center items-center px-6 inset-0 z-10 overflow-y-auto"
                    onClose={closeModal}
                >
                    <div className="w-full xl:w-5/12 lg:w-6/12 md:w-8/12 min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-25 bg-white"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-25"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="w-full md:w-8/12 inline-block  max-w-md  overflow-hidden text-left align-middle transition-all transform shadow-xl rounded-2xl">


                                <div className="bg-black p-2 p-12 ">
                                    <form>

                                        <textarea
                                            placeholder="Add a note"
                                            className="w-full text-white border border-white bg-transparent p-4 rounded-md lg:h-24"
                                            type="text"
                                            value={licenceNote}
                                            rows="4"
                                            onChange={e => setLicenceNote(e.target.value)}

                                        />


                                        <button
                                            onClick={adminLicenceAction}
                                            type="button"

                                            className='rounded-md w-full text-center mt-4 py-2 px-8 text-black text-xs font-bold bg-amber-500'>

                                            Submit Note</button>
                                    </form>

                                </div>




                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>


            <Transition appear show={passportNoteOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="w-full md:w-11/12  fixed flex justify-center items-center px-6 inset-0 z-10 overflow-y-auto"
                    onClose={closeModal}
                >
                    <div className="w-full xl:w-5/12 lg:w-6/12 md:w-8/12 min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-25 bg-white"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-25"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="w-full md:w-8/12 inline-block  max-w-md  overflow-hidden text-left align-middle transition-all transform shadow-xl rounded-2xl">


                                <div className="bg-black p-2 p-12 ">
                                    <form>

                                        <textarea
                                            placeholder="Add a note"
                                            className="w-full text-white border border-white bg-transparent p-4 rounded-md lg:h-24"
                                            type="text"
                                            value={passportNote}
                                            rows="4"
                                            onChange={e => setPassportNote(e.target.value)}

                                        />


                                        <button
                                            onClick={adminPassportAction}
                                            type="button"

                                            className='rounded-md w-full text-center mt-4 py-2 px-8 text-black text-xs font-bold bg-amber-500'>

                                            Submit Note</button>
                                    </form>

                                </div>




                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>

            <Transition appear show={guarantorNoteOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="w-full md:w-11/12  fixed flex justify-center items-center px-6 inset-0 z-10 overflow-y-auto"
                    onClose={closeModal}
                >
                    <div className="w-full xl:w-5/12 lg:w-6/12 md:w-8/12 min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-25 bg-white"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-25"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="w-full md:w-8/12 inline-block  max-w-md  overflow-hidden text-left align-middle transition-all transform shadow-xl rounded-2xl">


                                <div className="bg-black p-2 p-12 ">
                                    <form>

                                        <textarea
                                            placeholder="Add a note"
                                            className="w-full text-white border border-white bg-transparent p-4 rounded-md lg:h-24"
                                            type="text"
                                            value={guarantorNote}
                                            rows="4"
                                            onChange={e => setGuarantorNote(e.target.value)}

                                        />


                                        <button
                                            onClick={adminGuarantorAction}
                                            type="button"

                                            className='rounded-md w-full text-center mt-4 py-2 px-8 text-black text-xs font-bold bg-amber-500'>

                                            Submit Note</button>
                                    </form>

                                </div>




                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>



            <div className='md:border-r md:border-amber-500 h-100 xl:w-7/12 md:w-8/12   w-full xl:px-20 md:px-2  md:pr-20 pb-20 md:pt-24 pt-32 '>



                <main className='xl:w-full lg:w-10/12 md:w-full'>

                    <h2 className='text-white font-bold md:text-lg text-base mt-8'>Service Provider’s Profile  </h2>

                    <div className="flex  flex-col md:flex-row">


                        <div className="pb-20 md:w-full">


                            <form className='w-full mt-4'>
                                <div className="flex my-6 md:my-12 w-full gap-1 md:gap-1">
                                    <div className="flex flex-col w-1/2">
                                        <label htmlFor="First name" className='text-xs text-amber-500 font-normal'>First Name <span className='text-red-700'>*</span></label>
                                        <input type="text"
                                            value={providers?.firstname}
                                            className='cart-bg text-sm mt-2 cart-bg rounded-xl border border-white px-3 py-3 focus:outline-none text-white'

                                        />
                                    </div>
                                    <div className="flex flex-col w-1/2">
                                        <label htmlFor="Last Name" className='text-xs text-amber-500 font-normal'>Last Name <span className='text-red-700'>*</span></label>
                                        <input
                                            type="text"
                                            value={providers?.lastname}
                                            className='cart-bg text-sm mt-2 cart-bg rounded-xl border border-white px-3 py-3 focus:outline-none text-white'

                                        />

                                    </div>
                                </div>

                                <div className="flex flex-col w-full">
                                    <label htmlFor="Address" className='text-xs text-amber-500 font-normal'>Address <span className='text-red-700'>*</span></label>

                                    <input
                                        type="phone"
                                        value={providers?.address}
                                        className='cart-bg text-sm mt-2 cart-bg rounded-xl border border-white px-3 py-3 focus:outline-none text-white'
                                    />

                                </div>


                                <div className="flex flex-col w-full mt-12">
                                    <label htmlFor="Means of Identification" className='text-xs text-amber-500 font-normal'>Means of Identification  <span className='text-red-700'>*</span></label>

                                    <input
                                        type="text"
                                        value={providers.identification}
                                        className='cart-bg text-sm mt-2 cart-bg rounded-xl border border-white px-3 py-3 focus:outline-none text-white'

                                    />

                                </div>

                                <div className="flex flex-col w-full mt-12">
                                    <label htmlFor="ID Number" className='text-xs text-amber-500 font-normal'>ID Number <span className='text-red-700'>*</span></label>

                                    <input
                                        type="text"
                                        value={providers.id_number}
                                        className='cart-bg text-sm mt-2 cart-bg rounded-xl border border-white px-3 py-3 focus:outline-none text-white'

                                    />

                                </div>

                                <div className="flex flex-col w-full mt-12">
                                    <label htmlFor="Phone Number" className='text-xs text-amber-500 font-normal'>Phone Number <span className='text-red-700'>*</span></label>

                                    <input
                                        type="text"
                                        value={providers.phone_number}
                                        className='cart-bg text-sm mt-2 cart-bg rounded-xl border border-white px-3 py-3 focus:outline-none text-white'

                                    />

                                </div>








                                <div className="flex flex-col my-6 md:my-12">
                                    <label htmlFor="Category:" className='text-xs text-amber-500 font-normal'>Category <span className='text-red-700'>*</span></label>


                                    <div className="text-sm mt-2 cart-bg w-full rounded-xl border border-white pl-5 pr-0   text-white">
                                        <input
                                            type="text"
                                            value={providers.category}
                                            className='cart-bg w-full text-sm  cart-bg rounded-xl  px-3 py-2 focus:outline-none text-white'

                                        />

                                    </div>

                                </div>

                                <div className="flex flex-col">
                                    <label htmlFor="Birthday date:" className='text-xs text-amber-500 font-normal'>Birthday date. <span className='text-red-700'>*</span></label>
                                    <div className="cart-bg rounded-xl border border-white mt-2 flex items-center px-2">
                                        <input type="text"
                                            value={providers.dob}
                                            className='w-11/12 text-sm cart-bg rounded-xl  p-3 focus:outline-none text-white' />
                                        <img src={icon} alt="" className='md:w-8 w-10' />
                                    </div>

                                </div>

                                <div className="flex flex-col my-6 md:my-12">
                                    <label htmlFor="Service Offering:" className='text-xs text-amber-500 font-normal'>Service Offering <span className='text-red-700'>*</span></label>

                                    <div className="text-sm mt-2 cart-bg w-full rounded-2xl border border-white pl-5 pr-0 py-2  text-white">
                                        <input type="text"
                                            value={providers.service}
                                            className=' text-sm cart-bg rounded-xl  p-2 focus:outline-none text-white' />
                                    </div>


                                </div>



                                <div className="flex flex-col my-6 md:my-12">
                                    <label htmlFor="Availability:" className='text-xs text-amber-500 font-normal'>Availability <span className='text-red-700'>*</span></label>

                                    <div className="text-sm mt-2 cart-bg w-full rounded-2xl border border-white pl-5 pr-0   text-white">
                                        <input type="text"
                                            value={providers.availability}
                                            className=' text-sm cart-bg rounded-xl w-full  p-2 focus:outline-none text-white' />
                                    </div>

                                </div>




                                <div className="flex my-6 flex my-6 md:my-12 gap-1 md:gap-1">
                                    <div className="flex flex-col w-1/2">
                                        <label htmlFor="State" className='text-xs text-amber-500 font-normal'>State <span className='text-red-700'>*</span></label>
                                        <input type="text"
                                            value={providers.state}

                                            className='text-sm mt-2 cart-bg rounded-xl border border-white px-3 py-2 focus:outline-none text-white' />
                                    </div>
                                    <div className="flex flex-col w-1/2">
                                        <label htmlFor="Country" className='text-xs text-amber-500 font-normal'>Country <span className='text-red-700'>*</span></label>
                                        <input
                                            type="text"
                                            value={providers.country}

                                            placeholder='Nigeria' className='text-sm mt-2 cart-bg rounded-xl border border-white px-3 py-2 focus:outline-none text-white' />

                                    </div>
                                </div>





                                <div className="flex gap-2 mt-10">
                                    <a href={`tel: ${providers.phone_number}`}

                                        className='text-center hover:bg-amber-500 hover:text-black border-theme-color font-semibold theme-color p-2 rounded-md w-2/4 md:text-base text-sm'>Call Now</a>


                                    {driverDisplay === '2' || driverStatus === 'Approved' ?
                                        <button
                                            onClick={toggleVerifyProvider}

                                            className='bg-amber-500 font-semibold text-black p-2 rounded-md w-2/4 md:text-base text-sm'>Verified</button>
                                        :
                                        <button
                                            onClick={verifyProviderProfile}
                                            className='bg-white font-semibold text-black p-2 rounded-md w-2/4 md:text-base text-sm'>Click to Verify </button>

                                    }
                                </div>

                            </form>
                        </div>
                    </div>

                </main>
            </div>

            <div
                ref={docRef}

                className='xl:w-5/12   xl:px-12 lg:pl-8 lg:px-3 md:px-6 md:w-full  w-full pt-0 md:pt-32' >
                <div className="flex justify-end hidden md:flex"><h4 className='font-bold text-amber-500 mb-8'>Provider’s ID: {providers?.driver_uniqid}</h4></div>

                <h2 className='mb-10 text-white font-bold md:text-lg text-base'>My Documents</h2>

                {
                    driverLicense !== undefined ?

                        <div className="my-6 md:my-8">
                            <div className="flex justify-between border border-white p-2 items-center">
                                <p className='text-white md:text-sm text-xs font-normal'>Driver’s License </p>

                                <a href={`https://api.roadlers.com/storage/${driverLicense}`} target="_blank"
                                    className='text-center border-theme-color px-3 py-1 md:text-sm md:px-2 md:py-0 text-xs text-white'>
                                    View</a>

                            </div>

                            <div className="flex justify-end mt-4">
                                {/*  */}
                                {driverLicenseStatus === 'Pending' ?
                                    (
                                        <>

                                            <button
                                                onClick={(e) => adminLicenceActionModal(e)}
                                                className=''
                                                type='button'
                                            >
                                                <img src={message} alt="" className='w-8 -ml-1 mr-2 focus:outline-none' />

                                            </button>

                                            <button

                                                onClick={handleAcceptLicense}
                                                className='bg-blue-900 rounded text-white p-1 font-bold text-sm mr-2'>Accept</button>
                                            <button
                                                onClick={handleRejectLicense}

                                                className='bg-white rounded text-black p-1 font-bold text-sm'>Reject</button>
                                        </>
                                    ) :
                                    (



                                        driverLicense === undefined ?
                                            (
                                                ''
                                            ) : (
                                                driverLicenseStatus === 'Accept' ?

                                                    <button
                                                        onClick={handleToggleLicense}

                                                        className='bg-blue-900 rounded text-white p-1 font-bold text-sm mr-2'>Accepted</button>
                                                    :
                                                    <button
                                                        onClick={handleToggleLicense}

                                                        className='bg-red-700 rounded text-white p-1 font-bold text-sm mr-2'>Rejected</button>

                                            )

                                    )
                                }
                            </div>
                        </div> : ''
                }

                <div className="">

                    {guarantor_one.length === 0 ?
                        ('') :
                        (<div className="flex justify-between border border-white p-2 items-center">
                            <p className='text-white md:text-sm text-xs font-normal'>Guarantor 1</p>

                            {!!GuarantorOne ?

                                <button
                                    type='button'
                                    onClick={handleGuarantorOneHide}
                                    className='bg-amber-500  text-black font-semibold  px-3 py-1 md:text-sm md:px-2 md:py-0 text-xs '>Hide</button> :

                                <button
                                    type='button'
                                    onClick={handleGuarantorOne}
                                    className='border-theme-color font-semibold px-3 py-1 md:text-sm md:px-2 md:py-0 text-xs text-white'>View</button>
                            }
                        </div>)

                    }

                    {!!GuarantorOne ?

                        <div className=" my-8 ">
                            <h4 className='text-white'>Guarantor 1</h4>

                            <div className="">
                                <div className="flex gap-2 mt-8">
                                    <div className="flex flex-col w-1/2">
                                        <label htmlFor="First name" className='text-xs text-amber-500 font-normal'>First Name <span className='text-red-700'>*</span></label>
                                        <input type="text" value={guarantor_one?.[0].firstname} className='cart-bg text-sm mt-2 cart-bg rounded-xl border border-white p-2 focus:outline-none text-white' />
                                    </div>
                                    <div className="flex flex-col w-1/2">
                                        <label htmlFor="Last Name" className='text-xs text-amber-500 font-normal'>Last Name <span className='text-red-700'>*</span></label>
                                        <input type="text" value={guarantor_one?.[0].lastname} className='cart-bg text-sm mt-2 cart-bg rounded-xl border border-white p-2 focus:outline-none text-white' />

                                    </div>
                                </div>

                                <div className="flex flex-col w-full mt-8">
                                    <label htmlFor="Address" className='text-xs text-amber-500 font-normal'>Address <span className='text-red-700'>*</span></label>

                                    <input type="phone" value={guarantor_one?.[0].address}
                                        className='cart-bg text-sm mt-2 cart-bg rounded-xl border border-white p-2 focus:outline-none text-white' />

                                </div>


                                <div className="flex flex-col w-full mt-8">
                                    <label htmlFor="Address" className='text-xs text-amber-500 font-normal'>Means of Identification  <span className='text-red-700'>*</span></label>

                                    <input type="phone" value={guarantor_one?.[0].identification}
                                        className='cart-bg text-sm mt-2 cart-bg rounded-xl border border-white p-2 focus:outline-none text-white' />

                                </div>


                                <div className="flex flex-col w-full  mt-8">
                                    <label htmlFor="ID Number" className='text-xs text-amber-500 font-normal'>ID Number <span className='text-red-700'>*</span></label>

                                    <input type="text" value={guarantor_one?.[0].id_number}
                                        className='cart-bg text-sm mt-2 cart-bg rounded-xl border border-white p-2 focus:outline-none text-white' />

                                </div>

                                <div className="flex flex-col w-full  mt-8">
                                    <label htmlFor="Phone Number" className='text-xs text-amber-500 font-normal'>Phone Number <span className='text-red-700'>*</span></label>

                                    <input type="text" value={guarantor_one?.[0].phone_number}
                                        className='cart-bg text-sm mt-2 cart-bg rounded-xl border border-white p-2 focus:outline-none text-white' />

                                </div>
                            </div>
                        </div> : ''
                    }
                    {driverGuarantorOneStatus === 'Pending' ?
                        (
                            <div className="flex justify-end mt-4">


                                <button
                                    onClick={(e) => adminGuanrantorActionModal(e)}
                                    className=''
                                    type='button'
                                >
                                    <img src={message} alt="" className='w-8 -ml-1 mr-2 focus:outline-none' />

                                </button>

                                <button
                                    onClick={handleAcceptGuarantorOne}
                                    className='bg-blue-900 rounded text-white p-1 font-bold text-sm mr-2'>Accept</button>
                                <button
                                    onClick={handleRejectGuarantorOne}

                                    className='bg-white rounded text-black p-1 font-bold text-sm'>Reject</button>
                            </div>
                        ) :
                        (


                            guarantor_one.length === 0 ?
                                (
                                    ''
                                ) : (
                                    driverGuarantorOneStatus === 'Accept' ?
                                        <div className="flex justify-end mt-4">

                                            <button
                                                onClick={(e) => adminGuanrantorActionModal(e)}
                                                className=''
                                                type='button'
                                            >
                                                <img src={message} alt="" className='w-8 -ml-1 mr-2 focus:outline-none' />

                                            </button>

                                            <button
                                                onClick={
                                                    handleToggleGuarantorOne
                                                }


                                                className='bg-blue-900 rounded text-white p-1 font-bold text-sm mr-2'>Accepted</button>
                                        </div>
                                        :
                                        <div className="flex justify-end mt-4">

                                            <button
                                                onClick={(e) => adminGuanrantorActionModal(e)}
                                                className=''
                                                type='button'
                                            >
                                                <img src={message} alt="" className='w-8 -ml-1 mr-2 focus:outline-none' />

                                            </button>

                                            <button
                                                onClick={
                                                    handleToggleGuarantorOne
                                                }
                                                className='bg-red-700 rounded text-white p-1 font-bold text-sm mr-2'>Rejected</button>
                                        </div>

                                )
                        )

                    }

                </div>


                {/* guarantor two */}
                {/* <div className="my-6 md:my-12">
                    {guarantor_two.length === 0 ? ('') :
                        (<div className="flex justify-between border border-white p-2 items-center">
                            <p className='text-white md:text-sm text-xs font-normal'>Guarantor 2</p>

                            {!!GuarantorTwo ?

                                <button
                                    type='button'
                                    onClick={handleGuarantorTwoHide}
                                    className='font-semibold bg-amber-500  text-black   px-3 py-1 md:text-sm md:px-2 md:py-0 text-xs '>Hide</button> :

                                <button
                                    type='button'
                                    onClick={handleGuarantorTwo}
                                    className='font-semibold border-theme-color px-3 py-1 md:text-sm md:px-2 md:py-0 text-xs text-white'>View</button>
                            }
                        </div>)
                    }

                    {GuarantorTwo ?
                        <div className=" my-8">
                            <h4 className='text-white'>Guarantor 2</h4>

                            <div className="">
                                <div className="flex gap-2 mt-8">
                                    <div className="flex flex-col w-1/2">
                                        <label htmlFor="First name" className='text-xs text-amber-500 font-normal'>First Name <span className='text-red-700'>*</span></label>
                                        <input type="text" value={guarantor_two?.[0].firstname} className='cart-bg text-sm mt-2 cart-bg rounded-xl border border-white p-2 focus:outline-none text-white' />
                                    </div>
                                    <div className="flex flex-col w-1/2">
                                        <label htmlFor="Last Name" className='text-xs text-amber-500 font-normal'>Last Name <span className='text-red-700'>*</span></label>
                                        <input type="text" value={guarantor_two?.[0].lastname} className='cart-bg text-sm mt-2 cart-bg rounded-xl border border-white p-2 focus:outline-none text-white' />

                                    </div>
                                </div>

                                <div className="flex flex-col w-full mt-8">
                                    <label htmlFor="Address" className='text-xs text-amber-500 font-normal'>Address <span className='text-red-700'>*</span></label>

                                    <input type="phone" value={guarantor_two?.[0].address}
                                        className='cart-bg text-sm mt-2 cart-bg rounded-xl border border-white p-2 focus:outline-none text-white' />

                                </div>


                                <div className="flex flex-col w-full mt-8">
                                    <label htmlFor="Means of identification" className='text-xs text-amber-500 font-normal'>Means of Identification  <span className='text-red-700'>*</span></label>

                                    <input type="text" value={guarantor_two?.[0].identification}
                                        className='cart-bg text-sm mt-2 cart-bg rounded-xl border border-white p-2 focus:outline-none text-white' />

                                </div>


                                <div className="flex flex-col w-full  mt-8">
                                    <label htmlFor="ID Number" className='text-xs text-amber-500 font-normal'>ID Number <span className='text-red-700'>*</span></label>

                                    <input type="text" value={guarantor_two?.[0].id_number}
                                        className='cart-bg text-sm mt-2 cart-bg rounded-xl border border-white p-2 focus:outline-none text-white' />

                                </div>

                                <div className="flex flex-col w-full  mt-8">
                                    <label htmlFor="Phone Number" className='text-xs text-amber-500 font-normal'>Phone Number <span className='text-red-700'>*</span></label>

                                    <input type="text" value={guarantor_two?.[0].phone_number}
                                        className='cart-bg text-sm mt-2 cart-bg rounded-xl border border-white p-2 focus:outline-none text-white' />

                                </div>
                            </div>
                        </div> : ''
                    }

                    {
                        driverGuarantorTwoStatus === 'Pending' ? (
                            <div className="flex justify-end mt-4">

                                <button
                                    onClick={handleAcceptGuarantorTwo}

                                    className='bg-blue-900 rounded text-white p-1 font-bold text-sm mr-2'>Accept</button>
                                <button
                                    onClick={handleRejectGuarantorTwo}

                                    className='bg-white rounded text-black p-1 font-bold text-sm'>Reject</button>
                            </div>
                        ) : (








                            guarantor_two.length === 0 ?
                                (
                                    ''
                                ) : (
                                    driverGuarantorTwoStatus === 'Accept' ?

                                        <div className="flex justify-end mt-4">

                                            <button
                                                onClick={
                                                    handleToggleGuarantorTwo
                                                }
                                                className='bg-blue-900 rounded text-white p-1 font-bold text-sm mr-2'>Accepted</button>
                                        </div>
                                        :
                                        <div className="flex justify-end mt-4">

                                            <button
                                                onClick={
                                                    handleToggleGuarantorTwo
                                                }
                                                className='bg-red-700 rounded text-white p-1 font-bold text-sm mr-2'>Rejected</button>
                                        </div>
                                )



                        )
                    }

                </div> */}


                {

                    driverPassport !== undefined ?

                        <div className="my-6 md:my-12">
                            <div className="flex justify-between border border-white p-2 items-center">
                                <p className='text-white md:text-sm text-xs font-normal'>White background passport</p>

                                <a href={`https://api.roadlers.com/storage/${driverPassport}`} target="_blank"
                                    className='text-center border-theme-color px-3 py-1 md:text-sm md:px-2 md:py-0 text-xs text-white'>
                                    View</a>

                            </div>

                            <div className="flex justify-end mt-4">
                                {/*  */}


                                {driverPassportStatus === 'Pending' ?
                                    (
                                        <div className='flex justify-end'>

                                            <button
                                                onClick={(e) => adminPassportActionModal(e)}
                                                className=''
                                                type='button'
                                            >
                                                <img src={message} alt="" className='w-8 -ml-1 mr-2 focus:outline-none' />

                                            </button>
                                            <button

                                                onClick={handleAcceptPassport}
                                                className='bg-blue-900 rounded text-white p-1 font-bold text-sm mr-2'>Accept</button>
                                            <button
                                                onClick={handleRejectPassport}

                                                className='bg-white rounded text-black p-1 font-bold text-sm'>Reject</button>
                                        </div>
                                    ) :

                                    (
                                        driverPassport === undefined ?
                                            (
                                                ''
                                            ) : (
                                                driverPassportStatus === 'Accept' ?
                                                    <div className='flex justify-end mt-4'>

                                                        <button
                                                            onClick={(e) => adminPassportActionModal(e)}
                                                            className=''
                                                            type='button'
                                                        >
                                                            <img src={message} alt="" className='w-8 -ml-1 mr-2 focus:outline-none' />

                                                        </button>

                                                        <button

                                                            onClick={handleTogglePassport}

                                                            className='bg-blue-900 rounded text-white p-1 font-bold text-sm mr-2'>Accepted</button>
                                                    </div>

                                                    :
                                                    <div className='flex justify-end mt-4'>

                                                        <button
                                                            onClick={(e) => adminPassportActionModal(e)}
                                                            className=''
                                                            type='button'
                                                        >
                                                            <img src={message} alt="" className='w-8 -ml-1 mr-2 focus:outline-none' />

                                                        </button>
                                                        <button
                                                            onClick={handleTogglePassport}

                                                            className='bg-red-700 rounded text-white p-1 font-bold text-sm mr-2'>Rejected</button>

                                                    </div>

                                            )
                                    )
                                }
                            </div>
                        </div> : ''
                }


                {/* <div className="my-6 md:my-8">
                    <div className="flex justify-between border border-white p-2 items-center">
                        <p className='text-white md:text-sm text-xs font-normal'>Other docs e.g. CV, referal letter </p>
                        <button className='border-theme-color px-3 py-1 md:text-sm md:px-2 md:py-0 text-xs text-white'>View</button>
                    </div>


                </div> */}



                {driverDisplay === '1' && driverStatus === 'Approved' ? (
                    <button

                        className='w-full md:my-8 my-16 my bg-blue-900 text-white md:text-base text-sm p-2 text-center rounded-sm font-bold '>
                        Account Approved
                    </button>
                ) : (

                    (driverLicenseStatus === null || driverLicenseStatus === 'Pending' || driverLicenseStatus === 'Rejected' || driverGuarantorOneStatus === 'Rejected' || driverGuarantorOneStatus === 'Pending' || driverGuarantorOneStatus === null || driverGuarantorTwoStatus === 'Rejected' || driverGuarantorTwoStatus === 'Pending' || driverGuarantorTwoStatus === null) ?
                        (
                            <button

                                className='cursor-not-allowed bg-black text-gray-500 md:text-base text-sm w-full md:my-8 my-16 my p-2 text-center rounded-sm font-bold '>Click to Approve Account</button>


                        ) : (
                            <button
                                onClick={verifyProvider}
                                className='w-full md:my-8 my-16 my bg-amber-500 md:text-base text-sm p-2 text-center rounded-sm font-bold '>
                                Click to Approve Account
                            </button>
                        )

                )



                }

            </div>



        </>
    )
}

export default memo(ScreenD)