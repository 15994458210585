
import React, { Fragment, useState, useEffect, useContext } from 'react'
import { Menu, Dialog, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import cartwhite from '../Images/cart-white.svg';
import user_icon from '../Images/userg.png'
import { NavLink, useNavigate } from "react-router-dom";
import dashboard from '../Images/dashboard.png'
import one from '../Images/one.png'
import axios from "axios";
import Spin from "../Images/spinner.gif"
import DataContext from '../Context/DataContext'
import { GoogleMap, useJsApiLoader, Autocomplete } from '@react-google-maps/api';
import { useCookies } from 'react-cookie';


function TopForm(props) {
    const google = window.google;
    const { component } = props


    const { } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyDkMuW-hGvdRfUhsb2R9RFqA0Pq4Qxg4Cs",
        libraries: ['places'],

    })

    let navigate = useNavigate();

    const [cookies, setCookie, removeCookie] = useCookies();

    let userData = cookies?.user || JSON.parse(localStorage.getItem("gogglelogin"))
    let token = userData?.token
    let user_id = userData?.id

    





    let { cart, cartLength, handleFilter, handleSearch, handleSearchLocation } = useContext(DataContext)

    let { search, setSearch } = useContext(DataContext)
    let { searchLocation, setSearchLocation } = useContext(DataContext)
    let { load, setLoad } = useContext(DataContext)










    return (
        <div className="md:my-20 mb-12 md:mt-40 mt-32 flex flex-col  xl:px-64 lg:px-16 md:px-12 px-0  w-full">

        {/* for desktop */}
            <form action="" className='hidden w-full  md:flex  justify-center xl:gap-4  lg:gap-2 '>

                <div className="flex gap-4 xl:w-6/12 lg:w-6/12">
                    <input
                        type="text"
                        name=""
                        value={search}
                        // onChange={handleSearch}
                        onChange={e => setSearch(e.target.value)}

                        className='w-7/12 bg-transparent border border-white text-white py-2 px-4 rounded-md placeholder:text-xs '
                        placeholder='Search by car, model, company....'

                    />

                    <input
                        type="text"
                        name=""
                        className='w-5/12 bg-transparent border border-white text-white py-2 rounded-md placeholder:text-xs px-4'
                        placeholder='Pick-Up Location'
                        value={searchLocation}
                        onChange={e => setSearchLocation(e.target.value)}



                    />
                </div>


                <div className="xl:w-6/12 lg:w-6/12 flex gap-4">
                    {load ? (
                        <div className="bg-amber-500 rounded-md px-2 py-1 w-24 font-bold flex items-center">
                            <h4 className="text-black text-xs">Loading</h4>
                            <input
                                type="image"
                                src={Spin}
                                value="Loading"
                                className="w-8"
                                alt="loading..."
                            />
                        </div>
                    ) :
                        (<button
                            type='submit'
                            onClick={handleFilter}
                            // style={searchLocation?{display: "none"}:{display: "block"}}

                            className="bg-amber-500 rounded-md px-3 py-1 xl:w-24 lg:w-20 md:w-16 font-bold text-sm">
                            Search
                        </button>)
                    }



                    <div className="text-white gap-3 xl:px-4 lg:px-4 md:px-4 text-xs bg-transparent border border-white rounded-md flex items-center justify-center">
                        <img src={user_icon} alt="" className='w-5' />


                        <Menu as="div" className="relative inline-block text-left">
                            <div>
                                <Menu.Button className="inline-flex w-full justify-center rounded-md xl:px-4 md:px-2 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                                    Account
                                    <ChevronDownIcon
                                        className="ml-2 -mr-1 h-5 w-5 text-violet-200 hover:text-violet-100"
                                        aria-hidden="true"
                                    />
                                </Menu.Button>
                            </div>
                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items className="absolute right-0 mt-2 w-44 p-4 origin-top-right divide-y divide-gray-100 rounded-md bg-black shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <div className="px-1 py-1 flex flex-col gap-4 items-center justify-center" >
                                        <Menu.Item>
                                            {({ active }) => (

                                                <div className="flex items-center gap-4">

                                                    <img src={dashboard} alt="" className='w-6' />
                                                    <NavLink to="/user-profile"
                                                        className='text-white'>

                                                        My Profile
                                                    </NavLink>
                                                </div>
                                            )}


                                        </Menu.Item>
                                        <Menu.Item>

                                            <div className="flex items-center gap-4">
                                                <img src={user_icon} alt="" className='w-5' />
                                                <NavLink to="/customers-dashboard#bookings"
                                                    className='text-white'>

                                                    My Account
                                                </NavLink>

                                            </div>

                                        </Menu.Item>
                                        <Menu.Item>

                                            <div className="flex items-center gap-4">

                                                <img src={one} alt="" className='w-5' />
                                                <a href="#bookings"
                                                    className='text-white'
                                                >

                                                    My Bookings

                                                </a>
                                            </div>



                                        </Menu.Item>
                                    </div>

                                </Menu.Items>
                            </Transition>
                        </Menu>

                    </div>


                    <div className="flex justify-center items-center bg-transparent border gap-1 border-white rounded-md xl:px-3 lg:px-2 py-1 lg:w-6/12 md:w-9/12 xl:w-4/12  text-xs text-white">
                        <img src={cartwhite} alt="" className='w-7' />
                        <NavLink to="/cart" className="flex gap-2 items-center">  Cart

                            {
                                cartLength > 0 ? (
                                    <span className='bg-amber-500 p-2 rounded-full w-4 h-4 text-black text-sm font-bold flex items-center justify-center text-xs'>{cartLength} </span>

                                ) : (
                                    ''
                                )
                            }

                        </NavLink>
                    </div>
                </div>

            </form>


{/* for mobile */}
            <form className={component==='cart'?'flex flex-col md:hidden w-full px-6':'flex flex-col md:hidden w-full px-8'}>
                <div className="flex w-full gap-1">
                    <input
                        type="text"
                        name=""
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                        className='text-xs w-full bg-transparent border border-white text-white p-2 rounded-md placeholder-text-xxx  '
                        placeholder='Search by car, model, company....' />


                    <Autocomplete className='autcomplete-style'>

                        <input
                            type="text"
                            name=""
                            value={searchLocation}
                            onChange={e => setSearchLocation(e.target.value)}

                            className='text-xs w-full bg-transparent border border-white text-white p-2 rounded-md placeholder-text-xxx  px-1' placeholder='Pick-Up Location' />

                    </Autocomplete>


                    {load ? (
                        <div className="bg-amber-500 rounded-md p-2 w-3/12 font-bold flex items-center">
                            <h4 className="text-black text-xs">Loading</h4>
                            <input
                                type="image"
                                src={Spin}
                                value="Loading"
                                className="w-5"
                                alt="loading..."
                            />
                        </div>
                    ) :
                        (
                            <button
                                onClick={handleFilter}
                                className="bg-amber-500 rounded-md text-center py-1 w-3/12 px-2 font-bold text-xx">Search</button>
                        )}
                </div>


            </form>

        </div>
    )
}

export default TopForm