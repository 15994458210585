import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import SpecialOffersService from "../services/SpecialOffersService"

const initialState = {}

export const ticketOffers = createAsyncThunk(
  "offers/goldenTicketOffers", 
  async () => {
  const res = await SpecialOffersService.goldenTicketOffer()
  return res.data
})

export const mealOffers = createAsyncThunk(
  "offers/mealOffer", 
  async () => {
  const res = await SpecialOffersService.mealTicketOffer()
  return res?.data
})

const specialOfferSlice = createSlice({
  name: "getSpecialOffers",
  initialState,
  extraReducers: {
    [ticketOffers.fulfilled]: (state, action) => {
      state.push(action.payload)
    },

    [mealOffers.fulfilled]: (state, action) => {
      state.push(action.payload)
    },
  },
})

const { reducer } = specialOfferSlice
export default reducer
