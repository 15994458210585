import React, { Fragment, useState, useEffect, useContext, useRef, memo } from 'react'
import { Menu, Dialog, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import icon from '../../Images/icon.svg'
import car from '../../Images/car.png'
import message from '../../Images/message.svg'
import { NavLink, useParams } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../utils/constant"
import getCookie from '../utils/SecuredTokens'

function CarOwnerD(props) {

    const docRef = useRef(null)




    let user = getCookie("user");
    let userObject = JSON.parse(user)
    let token = userObject?.token


    const { id } = useParams()



    const { providers } = props
    const [GuarantorOne, setGuarantorOne] = useState(false);
    const [GuarantorTwo, setGuarantorTwo] = useState(false);

    console.log('providersprovidersprovidersproviders', providers)
    function handleGuarantorOne(e) {
        e.preventDefault()
        setGuarantorOne(true)
    }

    function handleGuarantorOneHide(e) {
        e.preventDefault()
        setGuarantorOne(false)
    }



    function handleGuarantorTwo(e) {
        e.preventDefault()
        setGuarantorTwo(true)
    }
    function handleGuarantorTwoHide(e) {
        e.preventDefault()
        setGuarantorTwo(false)
    }






  



   

   


    return (
        <>



       



            <div className='md:border-r md:border-amber-500 h-100 xl:w-7/12 md:w-8/12   w-full xl:px-20 md:px-2  md:pr-20 pb-20 md:pt-24 pt-32 '>



                <main className='xl:w-full lg:w-10/12 md:w-full'>

                    <h2 className='text-white font-bold md:text-lg text-base mt-8'>Car Owner's Profile  </h2>

                    <div className="flex  flex-col md:flex-row">


                        <div className="pb-20 md:w-full">


                            <form className='w-full mt-4'>
                                <div className="flex my-6 md:my-12 w-full gap-1 md:gap-1">
                                    <div className="flex flex-col w-1/2">
                                        <label htmlFor="First name" className='text-xs text-amber-500 font-normal'>First Name <span className='text-red-700'>*</span></label>
                                        <input type="text"
                                            value={providers?.firstname}
                                            className='cart-bg text-sm mt-2 cart-bg rounded-xl border border-white px-3 py-3 focus:outline-none text-white'

                                        />
                                    </div>
                                    <div className="flex flex-col w-1/2">
                                        <label htmlFor="Last Name" className='text-xs text-amber-500 font-normal'>Last Name <span className='text-red-700'>*</span></label>
                                        <input
                                            type="text"
                                            value={providers?.lastname}
                                            className='cart-bg text-sm mt-2 cart-bg rounded-xl border border-white px-3 py-3 focus:outline-none text-white'

                                        />

                                    </div>
                                </div>

                                <div className="flex flex-col w-full">
                                    <label htmlFor="Address" className='text-xs text-amber-500 font-normal'>Address <span className='text-red-700'>*</span></label>

                                    <input
                                        type="phone"
                                        value={providers?.address}
                                        className='cart-bg text-sm mt-2 cart-bg rounded-xl border border-white px-3 py-3 focus:outline-none text-white'
                                    />

                                </div>


                                <div className="flex flex-col w-full mt-12">
                                    <label htmlFor="Means of Identification" className='text-xs text-amber-500 font-normal'>Means of Identification  <span className='text-red-700'>*</span></label>

                                    <input
                                        type="text"
                                        value={providers.identification}
                                        className='cart-bg text-sm mt-2 cart-bg rounded-xl border border-white px-3 py-3 focus:outline-none text-white'

                                    />

                                </div>

                                <div className="flex flex-col w-full mt-12">
                                    <label htmlFor="ID Number" className='text-xs text-amber-500 font-normal'>ID Number <span className='text-red-700'>*</span></label>

                                    <input
                                        type="text"
                                        value={providers.id_number}
                                        className='cart-bg text-sm mt-2 cart-bg rounded-xl border border-white px-3 py-3 focus:outline-none text-white'

                                    />

                                </div>

                                <div className="flex flex-col w-full mt-12">
                                    <label htmlFor="Phone Number" className='text-xs text-amber-500 font-normal'>Phone Number <span className='text-red-700'>*</span></label>

                                    <input
                                        type="text"
                                        value={providers.phone_number}
                                        className='cart-bg text-sm mt-2 cart-bg rounded-xl border border-white px-3 py-3 focus:outline-none text-white'

                                    />

                                </div>








                                <div className="flex flex-col my-6 md:my-12">
                                    <label htmlFor="Category:" className='text-xs text-amber-500 font-normal'>Category <span className='text-red-700'>*</span></label>


                                    <div className="text-sm mt-2 cart-bg w-full rounded-xl border border-white pl-5 pr-0   text-white">
                                        <input
                                            type="text"
                                            value={providers.category}
                                            className='cart-bg w-full text-sm  cart-bg rounded-xl  px-3 py-2 focus:outline-none text-white'

                                        />

                                    </div>

                                </div>

                                <div className="flex flex-col">
                                    <label htmlFor="Birthday date:" className='text-xs text-amber-500 font-normal'>Birthday date. <span className='text-red-700'>*</span></label>
                                    <div className="cart-bg rounded-xl border border-white mt-2 flex items-center px-2">
                                        <input type="text"
                                            value={providers.dob}
                                            className='w-11/12 text-sm cart-bg rounded-xl  p-3 focus:outline-none text-white' />
                                        <img src={icon} alt="" className='md:w-8 w-10' />
                                    </div>

                                </div>

                                <div className="flex flex-col my-6 md:my-12">
                                    <label htmlFor="Service Offering:" className='text-xs text-amber-500 font-normal'>Service Offering <span className='text-red-700'>*</span></label>

                                    <div className="text-sm mt-2 cart-bg w-full rounded-2xl border border-white pl-5 pr-0 py-2  text-white">
                                        <input type="text"
                                            value={providers.service}
                                            className=' text-sm cart-bg rounded-xl  p-2 focus:outline-none text-white' />
                                    </div>


                                </div>



                                <div className="flex flex-col my-6 md:my-12">
                                    <label htmlFor="Availability:" className='text-xs text-amber-500 font-normal'>Availability <span className='text-red-700'>*</span></label>

                                    <div className="text-sm mt-2 cart-bg w-full rounded-2xl border border-white pl-5 pr-0   text-white">
                                        <input type="text"
                                            value={providers.availability}
                                            className=' text-sm cart-bg rounded-xl w-full  p-2 focus:outline-none text-white' />
                                    </div>

                                </div>




                                <div className="flex my-6 flex my-6 md:my-12 gap-1 md:gap-1">
                                    <div className="flex flex-col w-1/2">
                                        <label htmlFor="State" className='text-xs text-amber-500 font-normal'>State <span className='text-red-700'>*</span></label>
                                        <input type="text"
                                            value={providers.state}

                                            className='text-sm mt-2 cart-bg rounded-xl border border-white px-3 py-2 focus:outline-none text-white' />
                                    </div>
                                    <div className="flex flex-col w-1/2">
                                        <label htmlFor="Country" className='text-xs text-amber-500 font-normal'>Country <span className='text-red-700'>*</span></label>
                                        <input
                                            type="text"
                                            value={providers.country}

                                            placeholder='Nigeria' className='text-sm mt-2 cart-bg rounded-xl border border-white px-3 py-2 focus:outline-none text-white' />

                                    </div>
                                </div>





                                <div className="flex gap-2 mt-10">
                                    <a href={`tel: ${providers.phone_number}`}

                                        className='text-center hover:bg-amber-500 hover:text-black border-theme-color font-semibold theme-color p-2 rounded-md w-2/4 md:text-base text-sm'>Call Now</a>


                                  
                                </div>

                            </form>
                        </div>
                    </div>

                </main>
            </div>

           



        </>
    )
}

export default memo(CarOwnerD)