import React, { Fragment, useState, useEffect, useContext,memo } from 'react'

import CurrencyInput from 'react-currency-input-field';
import gift from '../../Images/gift.png'
import gift_faint from '../../Images/gift_faint.png'
import pen from '../../Images/pen.svg'
import del from '../../Images/del.svg'
import axios from "axios";
import { BASE_URL } from "../utils/constant"
import getCookie from '../utils/SecuredTokens';

const GiftCards = ({giftss}) => {
    let [dailyAmount, setDailyAmount] = useState('')
   

    let userData = getCookie("user");
    let userObject = JSON.parse(userData) 
    let token = userObject?.token


    const updateDailyPrice = async () => {

        alert(giftss.id)
        
                await axios({
                    method: "get",
                    url: `${BASE_URL}/v1/delete-giftcard-pricing/${giftss.id}`,
                   
                    headers: {
                        'Content-Type': 'application/json',
        
                        Authorization: `Bearer ${token}`,
        
                        "Access-Control-Allow-Origin": "*",
        
        
                    }
                })
                    .then((response) => {
                        const respData = response.data.data
                    
                        console.log('fiatWalletAddress', respData)
                        // window.location.reload(true)
        
        
                        window.location.reload(true)
        

                    })
                    .catch((error) => {
                        console.log(error);
                    });
            };
    return (
      
        <div className="flex mb-16 w-full">
            <div className="w-5/12 md:w-60 mr-4">
              
                <img src={`https://api.roadlers.com/storage/${giftss?.image}`} alt="" className='md:w-full' />

            </div>
            <div className="w-7/12">
                <div className="flex items-center gap-5 mb-6">
                    <h5 className='theme-color text-base  font-extrabold'>{giftss?.title}</h5>
                    <img src={pen} alt="" className='md:w-6 w-5' />

                </div>

                <p className='text-white leading-8 tracking-wider text-sm '>{giftss?.description}</p>

                <div className="mt-4 flex items-center">
                    <h5 className='text-white md:text-base text-sm font-extrabold'>₦{giftss?.commission}</h5>
                  <button
onClick={updateDailyPrice}
                  
                  >
                    <img src={del} alt="" className='md:w-8 w-6' />
                  </button>  
                </div>
            </div>
        </div>

  
   

    )
}

export default memo(GiftCards)