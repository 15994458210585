import React from 'react'

const NoResult = () => {
    return (
        <div className="flex items-center justify-center">
            <h2
                className="text-white p-2 mt-12 mb-12 text-xl font-bold ">

                No Result Found
            </h2>
        </div>
    )
}

export default NoResult