import { createSlice } from "@reduxjs/toolkit";
const initialState = false;
const badgeSlice = createSlice({
  name: "badge",
  initialState,
  reducers: {
    setBadge: (state, action) => {
      return { badge: action.payload };
    },
  },
});
const { reducer, actions } = badgeSlice;
export const { setBadge} = actions
export default reducer;