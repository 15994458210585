import React, { useState, useEffect } from 'react'
import Header from '../Header'
import google from '../../Images/google.svg'
import Spin from '../../Images/spinner.gif';

import fbb from '../../Images/fbb.svg'
import user from '../../Images/userg.png'
import axios from "axios";
import open from '../../Images/blackeye.png'
import closed from '../../Images/closed.svg'
import email from '../../Images/email.svg'
import calls from '../../Images/calls.svg'
import ref from '../../Images/ref.svg'
import car from '../../Images/carr.png'
import { NavLink, useNavigate } from "react-router-dom";
import { useParams, useLocation } from "react-router-dom";

import { BASE_URL } from "../utils/constant"


import { useFormik } from 'formik'
import * as Yup from 'yup'

const SuperAdminRegister = () => {
    const [load, setLoad] = useState("")
 


    
  







    let navigate = useNavigate();

    const [passwordShown, setPasswordShown] = useState(false);
    const phoneRegExp = /^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/


    const togglePassword = (e) => {
        e.preventDefault()
        // When the handler is invoked
        // inverse the boolean state of passwordShown
        setPasswordShown(!passwordShown);
    };

    const formikSuperadmin = useFormik({
        initialValues: {
            name: '',
            email: '',
            phone_number: '',
            password: '',
            password_confirmation: '',
            user_role:'super_admin'
        
        },
        validationSchema: Yup.object({
            name: Yup.string().min(3, "Must be 3 Characters or more").required("This is Required"),
            email: Yup.string().email("Invalid Email Address").required("This is Required"),
            phone_number: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required("Phone Number is Required"),

            
      password: Yup.string()
      .required("This is Required")
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/,
        "Must Contain at least 6 Characters, One Uppercase, One Lowercase and One Number"
      ),
      password_confirmation: Yup.string()
        .oneOf([Yup.ref("password"), null], "Password must Match")
        .required("Confirm Password is Required"),

            acceptTerms: Yup.bool().oneOf([true], 'Accept Terms & Conditions is required')

        }),


        onSubmit: (values) => {
            console.log('values', values)
            setLoad(Spin);
            console.log('loadingggg', load)

            axios({
                method: 'post',
                url: `${BASE_URL}/v1/super-admin-signup`,               

                data: values,
                config: { headers: { 'Content-Type': 'application/json' } }

            })
                .then(function (response) {
                    //handle success



                    if (response.data.status === true) {

                        // localStorage.setItem("userToken", JSON.stringify(response.data.data.token))



                        const messsage = response.data.message
                        navigate('/login')

                    }
                    else {

                        const messsage = response.data.message
                        navigate('/login')

                    }



                })
                .catch(function (error) {
                    //handle error
                    console.log("error ", error.response.data.email)

                    setLoad('');
                });

        },
    })




    return (
        <div>

            <Header user='customer' />
            <div className="flex justify-center md:pt-40 pt-32 flex-col items-center w-full pb-20 mb-8 relative">
                <h1 className="md:px-20 px-12 text-white font-bold text-base md:text-2xl mb-4 md:mb-8">Need A driver/Car? Sign-up Here </h1>
                <div className="md:bg-white px-12 md:p-6 md:px-16 w-full md:w-2/6 h-auto rounded-lg">
                    <form onSubmit={formikSuperadmin.handleSubmit} className='flex flex-col items-center justify-center mt-12 md:mt-28 relative'>
                        <img src={user} className="md:w-20  w-16 absolute md:-top-12 -top-9 left-50" alt="" />

                        <input
                            type="text"
                            placeholder='Your Name:'
                            className='md:text-sm text-xs p-3 w-full border border-gray-500 rounded-lg my-4 placeholder:text-xs placeholder:text-gray-700'
                            name="name"
                            id="name"
                            onBlur={formikSuperadmin.handleBlur}
                            onChange={formikSuperadmin.handleChange}
                            value={formikSuperadmin.values.name}
                        />

                        {formikSuperadmin.touched.name && formikSuperadmin.errors.name ? <p className='text-red-600 text-left text-xs w-full -mt-3 mb-4'>{formikSuperadmin.errors.name}</p> : null}

                        <div className='p-3 w-full bg-white flex gap-1 items-center  justify-center border border-gray-500 rounded-lg mt-2'>
                            <input
                                type="email"
                                placeholder='Your Email:'
                                className='md:text-sm text-xs w-11/12 placeholder:text-xs placeholder:text-gray-700'
                                name="email"
                                id="email"
                                onBlur={formikSuperadmin.handleBlur}
                                onChange={formikSuperadmin.handleChange}
                                value={formikSuperadmin.values.email}
                            />

                            <img src={email} className="w-6" alt="" />
                        </div>

                        {formikSuperadmin.touched.email && formikSuperadmin.errors.email ? <p className='text-red-600 text-left text-xs w-full mt-2 mb-4'>{formikSuperadmin.errors.email}</p> : null}



                        <div className='p-3 w-full bg-white flex gap-1 items-center  justify-center border border-gray-500 rounded-lg mt-6'>
                            <input
                                type="phone"
                                placeholder='Phone Number:'
                                className='md:text-sm text-xs w-11/12 placeholder:text-xs placeholder:text-gray-700'
                                name="phone_number"
                                id="phone_number"
                                onBlur={formikSuperadmin.handleBlur}
                                onChange={formikSuperadmin.handleChange}
                                value={formikSuperadmin.values.phone_number}

                            />

                            <img src={calls} className="w-6" alt="" />
                        </div>
                        {formikSuperadmin.touched.phone_number && formikSuperadmin.errors.phone_number ? <p className='text-red-600 text-left text-xs w-full mt-1 mb-4'>{formikSuperadmin.errors.phone_number}</p> : null}



                        <div className='p-3 w-full bg-white flex gap-1 items-center  justify-center border border-gray-500 rounded-lg mt-6'>
                            <input
                                type={passwordShown ? "text" : "password"}
                                placeholder='Password: A minimum of 8 characters'
                                className='md:text-sm text-xs w-11/12 placeholder:text-xs placeholder:text-gray-700'
                                name="password"
                                id="password"
                                onBlur={formikSuperadmin.handleBlur}
                                onChange={formikSuperadmin.handleChange}
                                value={formikSuperadmin.values.password}


                            />



                            {passwordShown ? (

                                <button onClick={e => togglePassword(e)}><img src={open} className="w-6 h-6" alt="" /></button>

                            ) : (
                                <button onClick={e => togglePassword(e)}><img src={closed} className="w-6 h-6" alt="" /></button>

                            )}


                        </div>
                        {formikSuperadmin.touched.password && formikSuperadmin.errors.password ? <p className='text-red-600 text-left text-xs w-full mt-2 mb-4'>{formikSuperadmin.errors.password}</p> : null}


                        <div className='p-3 w-full bg-white flex gap-1 items-center  justify-center border border-gray-500 rounded-lg mt-6'>
                            <input
                                type={passwordShown ? "text" : "password"}
                                placeholder='Confirm Password'
                                className='md:text-sm text-xs w-11/12 placeholder:text-xs placeholder:text-gray-700'
                                name="password_confirmation"
                                id="password_confirmation"
                                onBlur={formikSuperadmin.handleBlur}
                                onChange={formikSuperadmin.handleChange}
                                value={formikSuperadmin.values.password_confirmation}


                            />



                            {passwordShown ? (

                                <button onClick={e => togglePassword(e)}><img src={open} className="w-6 h-6" alt="" /></button>

                            ) : (
                                <button onClick={e => togglePassword(e)}><img src={closed} className="w-6 h-6" alt="" /></button>

                            )}


                        </div>
                        {formikSuperadmin.touched.password_confirmation && formikSuperadmin.errors.password_confirmation ? <p className='text-red-600 text-left text-xs w-full mt-2 mb-4'>{formikSuperadmin.errors.password_confirmation}</p> : null}


                    
                        <div className="flex items-center justify-center gap-2 my-6 w-full">
                            <input
                                type="checkbox"
                                // className={'form-check-input ' + (formik.errors.acceptTerms && formik.touched.acceptTerms ? ' is-invalid' : '')}
                                name="acceptTerms"
                                id="acceptTerms"
                                required

                            />
                            <p className='md:text-gray-500 text-white font-semibold text-xx md:text-sm'>I read & agreed to the
                                <NavLink className="font-semibold theme-color" exact to="#"> Terms and Conditions</NavLink>

                            </p>
                        </div>
                        {formikSuperadmin.touched.acceptTerms && formikSuperadmin.errors.acceptTerms ? <p className='text-red-600 text-left text-xs w-full mt-2 mb-4'>{formikSuperadmin.errors.acceptTerms}</p> : null}


                        {
                            load ? (<div className="bg-amber-500 w-11/12 font-semibold rounded-lg my-4 p-1 flex justify-center items-center "><h4 className='text-black text-sm'>Please Wait!</h4><input type="image" src={Spin} value="Loading" className="w-10" /></div>) :
                                (<button type="submit" className='bg-amber-500 p-3 w-11/12 font-semibold rounded-lg my-4'>Sign-Up</button>)
                        }

                    </form>

                    <p className='text-center my-4 text-sm font-semibold md:text-gray-500 text-white'>Already have an account?

                        <NavLink className="font-semibold theme-color" exact to="/login"> Login</NavLink>

                    </p>
                    <div className="flex gap-3 items-center justify-center my-16">
                        <div className="flex orange rounded-lg px-4 py-1 h-10"><button className='focus:outline-none text-white text-xs md:text-sm'>Sign-up with</button> <img className='w-6' src={google} alt="" /></div>
                        <div className="flex bg-blue-700 rounded-lg px-4 py-1 h-10"><button className='focus:outline-none text-white text-xs md:text-sm'>Sign-up with</button> <img className='w-6' src={fbb} alt="" /></div>
                    </div>

                </div>
                <img src={car} alt="" className='absolute -bottom-4 md:right-96 w-44 md:w-64' />



            </div>

            <div className="full mt-8 flex mb-28 justify-center px-12">
                <div className="md:w-2/6 w-full flex justify-between gap-3" >
                    <div className="bg-transparent md:w-48 md:h-40 w-1/2 h-32  rounded-lg  border border-white flex items-center justify-center flex-col">
                        <h3 className='text-white font-bold text-sm md:text-xl'>Drivers</h3>
                        <button className='bg-white font-bold p-2 rounded-md md:text-sm text-xs mt-4'>Sign-Up Here</button>
                    </div>
                    <div className="bg-transparent md:w-48 md:h-40 w-1/2 h-32 rounded-lg  border border-white flex items-center justify-center flex-col">
                        <h3 className='text-white font-bold text-sm md:text-xl'>Vehicle Owners</h3>
                        <button className='bg-white font-bold p-2 rounded-md md:text-sm text-xs mt-4'>Sign-Up Here</button>
                    </div>

                </div>

            </div>

        </div>
    )
}


export default SuperAdminRegister;