import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import CreateWalletService from "../services/CryptoWalletService"

const initialState = {}

// Create Crypto Wallet
export const createCryptoWallet = createAsyncThunk(
    "crypto/create", 
    async (walletData) => {
    const res = await CreateWalletService.createWallet(walletData)
    return res.data
})


// Withdraw from crypto wallet
export const withdrawCryptoWallet = createAsyncThunk(
    "crypto/withdraw", 
    async (walletData) => {
    const res = await CreateWalletService.createWithdrawal(walletData)
    return res.data
})


// CancelCrypto Withdrawal
export const cancelCryptoWithdrawal = createAsyncThunk(
    "crypto/cancel", 
    async (walletData, withdrawal_id) => {
    const res = await CreateWalletService.cancelWithdrawal(walletData, withdrawal_id)
    return res.data
})

// Get Cryptocurrency
export const getCryptocurrency = createAsyncThunk (
  "crypto/getCurrency",
  async () => {
    const res = await CreateWalletService.fetchCryptoCurrency()
    return res.data
  }
)

// Get CyptoWallet Details
export const getCryptoWalletDetails = createAsyncThunk (
  "crypto/walletDetails",
  async (currency) => {
    const res = await CreateWalletService.fetchCryptoWalletDetails(currency)
    return res.data
  }
)

// Get CryptoWallet Transactions
export const getCryptoWalletTransaction = createAsyncThunk (
  "crypto/walletTransactions",
   async () => {
     const res = await CreateWalletService.fetchCryptoWalletTransactions()
     return res.data
   }
)


// Wallet to Wallet Transfer
export const walletToWallet = createAsyncThunk (
  "crypto,walletTowalletTransfer",
  async (walletTransferData) => {
    const res = await CreateWalletService.walletTransfer(walletTransferData)
    return res.data
  }
)

const CryptoWalletSlice = createSlice({
    name: "cryptoWallet",
    initialState,
    extraReducers: {
      [createCryptoWallet.fulfilled]: (state, action) => {
        state.push(action.payload)
      },

      [withdrawCryptoWallet.fulfilled]: (state, action) => {
        state.push(action.payload)
      },

      [cancelCryptoWithdrawal.fulfilled]: (state, action) => {
        state.push(action.payload)
      },

      [getCryptocurrency.fulfilled]: (state, action) => {
        state.push(action.payload)
      },

      [getCryptoWalletDetails.fulfilled]: (state, action) => {
        state.push(action.payload)
      },

      [walletToWallet.fulfilled]: (state, action) => {
        state.push(action.payload)
      },
    },
  })
  
  const { reducer } = CryptoWalletSlice
  export default reducer