
import React, { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { BASE_URL } from "../utils/constant"

import Header from '../Header'
import Subheader from '../Subheader'

import cartwhite from '../../Images/cart-white.svg';
import one from '../../Images/one.png'
import user_booking from '../../Images/user_booking.png'

import dashboard from '../../Images/dashboard.png'
import payment from '../../Images/payment.png'
import ref from '../../Images/ref.svg'
import refs from '../../Images/refs.png'
import naira from '../../Images/naira.png'
import offers from '../../Images/offers.png'
import notifications from '../../Images/notifications.png'
import pen from '../../Images/pen.svg'
import eyes from '../../Images/eyes.svg'
import icon from '../../Images/icon.svg'
import btc from '../../Images/btc.svg'
import user from '../../Images/userg.png'
import ReactTooltip from "react-tooltip";
import axios from "axios";
import transfer from '../../Images/transfer.png'
import top from '../../Images/top.png'
import Spin from "../../Images/spinner.gif"

import { NavLink, useNavigate } from "react-router-dom";
import { NavHashLink as Link } from "react-router-hash-link";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import bitcoin from '../../Images/btc.png';
import ethereum from '../../Images/ethereum.png';
import usdt from '../../Images/usdt.png';
import binance from '../../Images/binance.png';
import { useFormik } from 'formik'
import * as Yup from 'yup'
import mark from '../../Images/mark.png'
import closed_eye from '../../Images/closed_eye.png'

import eyess from '../../Images/eyes2.svg'
import closed from "../../Images/closed.svg"

import TopForm from '../TopForm'
import LeftNavBar from './LeftNavBar'
import getCookie from '../utils/SecuredTokens'
// import {Link}  from 'react-scroll';
import { GoogleMap, useJsApiLoader, Autocomplete } from '@react-google-maps/api';

function UserProfile() {
    let navigate = useNavigate();

    let userData = getCookie("user");
    let userObject = JSON.parse(userData)
    let token = userObject?.token
    let user_id = userObject?.id
    let user_email = userObject?.email



    const google = window.google;
    const { } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyDkMuW-hGvdRfUhsb2R9RFqA0Pq4Qxg4Cs",
        libraries: ['places'],

    })

    const [load, setLoad] = useState("")

    let [isOpen, setIsOpen] = useState(false)
    let [topUpOpen, setTopUpOpen] = useState(false)
    let [transferOpen, setTransferOpen] = useState(false)
    let [amount, setAmount] = useState('')
    let [credit, setCredit] = useState('')
    let [password, setPassword] = useState('')

    let [usernamec, setUsername] = useState('')
    const [firstname, setFirstname] = useState('')
    const [userResponse, setUserResponse] = useState([])
    const [lastname, setLastname] = useState('')
    const [address, setAddress] = useState('')
    const [birthday, setBirthday] = useState('')
    const [state, setState] = useState('')
    const [country, setCountry] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [change, setChange] = useState('')
    
    const [email, setEmail] = useState('')

    const [customerId, setCustomerId] = useState('')

    const [balance, setBalance] = useState('')
    const [walletAddress, setWalletAddress] = useState('')
    const [initial, setInitials] = useState('')
    const [url, setUrl] = useState('')

    const [bg, setBg] = useState(false)
    const [viewWallet, setViewWallet] = useState(true)
    const [viewCrypto, setViewCrypto] = useState(true)
    let [bvnOpen, setBvnOpen] = useState(false)
    let [bvn, setBvn] = useState('')

    const [alert, setAlert] = useState('')

    function closeModal() {
        setIsOpen(false)
        setTransferOpen(false)
        setTopUpOpen(false)
        setBg(false)
        setBvnOpen(false)


    }

    function openModal() {
        setIsOpen(true)
        setBg(true)

    }
    let [bvnSetting, setBvnSetting] = useState('')
    const getUserWallet = async () => {
        await axios.get(`${BASE_URL}/v1/wallet/get-fiat-wallet/${user_id}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            }
        }).then((response) => {
            const allwalletdatas = response.data.data;
            console.log('allwalletdatas', allwalletdatas)
            const account_balance = allwalletdatas.balance
            const wallet_address = allwalletdatas.fiat_wallet_address
            const bvnSetup = allwalletdatas.bvn
            setCustomerId(allwalletdatas.user_id)
            const balance = parseFloat(account_balance).toFixed(2);
            setBalance(balance)
            setWalletAddress(wallet_address)
            setCustomerId(allwalletdatas.user_id)
            setBvnSetting(allwalletdatas.bvn)
            setBvnSetting(bvnSetup => {
                return bvnSetup
            })
        });
    };
    useEffect(() => {
        getUserWallet();
    }, []);

    const getUserName = async () => {
        await axios.get(`${BASE_URL}/v1/show-username/${user_id}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            }
        }).then((response) => {
            const alluserName = response.data;

            const username = alluserName.username;
            setUserResponse(alluserName)

            setAddress(alluserName.address)
            setEmail(alluserName.email)
            setBirthday(alluserName.dob)
            setState(alluserName.state)
            setCountry(alluserName.country)
            const fullname = response.data.name;
            console.log('fullname', fullname)
            const nameSplit = fullname.split(/(\s+)/);
            const firstName = nameSplit[0]
            const phone = response.data.phone_number;
            setFirstname(firstName)

            const lastName = nameSplit[2]

            setLastname(lastName)

            const initials = [];
            for (let i = 0; i < lastName?.length; i++) {
                const lastname_split = lastName[i]?.split(" ");
                initials.push(lastname_split[0]);
                setInitials(initials[0]);
                console.log('firstWords', initials[0])
            }

            setPhoneNumber(phone)
            setUsername(username)
            setChange(alluserName.change_username)

        });
    };
    useEffect(() => {
        getUserName();
    }, []);

    const [bookingOpen, setBookingOpen] = useState(true)

    function closeBookingHeader(e) {
        e.preventDefault();
        setBookingOpen(false)
    }
    function openBookingHeader(e) {
        e.preventDefault();
        setBookingOpen(true)
    }
    const handleChangeUsername = async (e) => {
        e.preventDefault();
        let formData = new FormData();

        formData.append('id', user_id)
        formData.append('username', usernamec)
        axios({
            method: 'post',
            url: `${BASE_URL}/v1/update-username`,
            data: formData,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            }
        })
            .then(function (response) {
                if (response.data.message === 'success') {
                    const notify = () => { toast('Username Changed Successfully') };
                    notify()
                }
                setChange('1')
            })
            .catch(function (error) {
                //handle error
                const notify = () => { toast(error.response.data.message) };
                notify()
                console.log(error)

            });


    }
    const updateProfile = async (e) => {
        setLoad(Spin);

        e.preventDefault();

        if (address === null || address === 'null' || address === ''
            || birthday === null || birthday === 'null' || birthday === ''
            || state === null || state === 'null' || state === ''
            || country === null || country === 'null' || country === ''

        ) {
            const notify = () => { toast('Please, Fill all the Fields') };
            notify()
        } else {
            let formData = new FormData();
            formData.append('firstname', firstname)
            formData.append('lastname', lastname)
            formData.append('address', address)
            formData.append('phone_number', phoneNumber)
            formData.append('email', user_email)
            formData.append('id', user_id)
            formData.append('dob', birthday)
            formData.append('state', state)
            formData.append('country', country)

            let object = {};
            formData.forEach((value, key) => object[key] = value);
            let json = JSON.stringify(object)

            axios({
                method: 'put',
                url: `${BASE_URL}/v1/update-profile`,
                data: json,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    "Access-Control-Allow-Origin": "*",
                }
            })
                .then(function (response) {
                    setLoad('');

                    if (response.data.message === 'success') {
                        const notify = () => { toast('Profile Updated Successfully') };
                        notify()
                    }


                   
                        navigate('/customers-dashboard')
                    


                })
                .catch(function (error) {
                    //handle error

                    const notify = () => { toast(error.response.data.message) };
                    notify()
                    console.log(error)

                });

        }


    }
    return (
        <div className={bg === true ? " blur-sm contrast-50" : "w-full"}>
            <Header user='login_customer' />
            <ToastContainer />
            <hr className='hidden md:block' />
            <div className="flex pt-16">
                <div className='md:border-r md:border-white h-100 w-3/12 pb-24 px-12 ml-12 hidden md:block'>

                    <div className='pt-28'>
                        <span className='flex items-center text-white'> <img src={dashboard} alt="" className='mr-3 w-8' />
                            <NavLink to="/user-profile" className='font-light'>My Profile</NavLink>
                        </span>
                    </div>

                    <div className='my-3'>
                        <span className='flex items-center text-white'> <img src={payment} alt="" className='mr-3 w-8' />
                            <NavLink to="/customers-dashboard" className='font-light'>My Wallet</NavLink></span>
                    </div>

                    <div className='my-3 ml-1'>
                        <span className='flex items-center text-white'>
                            <img src={one} alt="" className='mr-3 w-6' />
                            <Link to="/customers-dashboard#bookings" className="ml-1 font-light">My Bookings</Link>

                        </span>
                    </div>
                    <div className='my-3'>
                        <span className='flex items-center text-white'> <img src={refs} alt="" className='mr-3 w-8' />

                            <Link to="/customers-dashboard#referrals" className="ml-1 font-light">Referral Bonus</Link>

                        </span>
                    </div>

                    <div className='my-3'>
                        <span className='flex items-center text-white'> <img src={offers} alt="" className='md:ml-1 md:mr-5 w-6' />
                            <a href="/customers-dashboard#offers" className='font-light'>Special Offers</a></span>
                    </div>

                    <div className='my-3'>
                        <span className='flex items-center text-white'> <img src={naira} alt="" className='mr-3 w-8' />
                            <a href="" className='font-light'>Transaction History</a></span>
                    </div>
                    <div className='my-3'>
                        <span className='flex items-center text-white'> <img src={notifications} alt="" className='mr-3 w-8' /> <a href="" className='font-light'>Notification</a></span>
                    </div>
                </div>
                <div className="flex flex-col xl:w-5/12 lg:w-6/12 md:w-7/12 w-full px-12 md:px-20">
                    <div className="flex w-full  justify-end">
                        <button>
                            <img src={eyes} alt="" className=' mt-0 md:pt-20 w-6 flex ' />
                        </button>
                    </div>
                    <div className="flex items-center mt-2 mb-6 justify-between w-full">
                        <h2 className='text-white font-bold text-lg'>My Profile   </h2>
                        {/* <img src={pen} alt="" className='w-6' /> */}
                    </div>
                    <form onSubmit={handleChangeUsername} className="mt-4 mb-6" autoComplete="off">
                        <div className="flex flex-col ">
                            <div className="flex items-center">
                                <label htmlFor="Phone Number " className='text-xs text-amber-500 font-normal'>Change Username </label> <label htmlFor="" className='text-xx text-white ml-4'>Use something you can remember</label>
                            </div>

                            <div className="rounded-lg border border-white mt-2 flex px-2 py-1">
                                {/* <input type="date" placeholder='Pick' className='w-11/12 text-sm bg-transparent rounded-2xl  p-3 focus:outline-none text-white' /> */}
                                <input
                                    data-tip data-for="registerTip"
                                    type="text"
                                    autoComplete='off'
                                    placeholder={usernamec}
                                    className='w-11/12 text-sm bg-transparent  focus:outline-none focus:bg-transparent text-white placeholder-text-xx'
                                    name="username"
                                    id="username"
                                    value={usernamec}
                                    onChange={e => setUsername(e.target.value)}


                                />

                                <img data-tip data-for="registerTip" src={icon} alt="" className='w-10' />
                            </div>


                            <ReactTooltip id="registerTip" place="top" effect="solid">
                                This is a one time username change, <br />once the button
                                is clicked   you can no <br />
                                longer change your username.
                            </ReactTooltip>


                        </div>

                        {
                        change==='1'?
                        <button type='button' className='cursor-not-allowed bg-amber-500 font-semibold text-black p-1 rounded-md w-2/5 mt-4'>Changed </button>:
                        <button type='submit' className='bg-white font-semibold text-black p-1 rounded-md w-2/5 mt-4'>Change </button>}


                    </form>


                    <form className='w-full pl-30'>
                        <div className="flex my-6 md:my-12 w-full gap-1">
                            <div className="flex flex-col w-1/2">
                                <label htmlFor="First name" className='text-xs text-amber-500 font-normal'>First Name </label>
                                <input
                                    type="text"
                                    defaultValue={firstname}

                                    className='text-sm mt-2 bg-transparent rounded-lg border border-white px-3 py-2 focus:outline-none text-white'

                                    value={firstname}
                                    onChange={e => setFirstname(e.target.value)}

                                />
                            </div>


                            <div className="flex flex-col w-1/2">
                                <label htmlFor="Last Name" className='text-xs text-amber-500 font-normal'>Last Name </label>
                                <input type="text"
                                    defaultValue={lastname}

                                    className='text-sm mt-2 bg-transparent rounded-lg border border-white px-3 py-2 focus:outline-none text-white'


                                    value={lastname}
                                    onChange={e => setLastname(e.target.value)}
                                />

                            </div>


                        </div>





                        <div className="flex my-6 md:my-12 w-full gap-1">
                            <div className="flex flex-col w-1/2">
                                <label htmlFor="Phone Number " className='text-xs text-amber-500 font-normal'>Phone Number  </label>
                                <input
                                    type="phone"
                                    defaultValue={phoneNumber}

                                    className='text-sm mt-2 bg-transparent rounded-lg border border-white px-3 py-2 focus:outline-none text-white'

                                    value={phoneNumber}
                                    onChange={e => setPhoneNumber(e.target.value)}

                                />
                            </div>


                            <div className="flex flex-col w-1/2">
                                <label htmlFor="Email Address" className='text-xs text-amber-500 font-normal'>Email Address</label>
                                <input type="email"
                                    defaultValue={email}

                                    className='text-sm mt-2 bg-transparent rounded-lg border border-white px-3 py-2 focus:outline-none text-white'

                                    readOnly
                                    value={email}

                                />

                            </div>


                        </div>


















                        <div className="flex flex-col my-6 md:my-12">
                            <label htmlFor="Address" className='text-xs text-amber-500 font-normal'>Address </label>
                            <Autocomplete className='w-full'>

                                <input
                                    type="text"
                                    placeholder='Type Here ...'
                                    className='w-full text-sm mt-2 bg-transparent rounded-lg border border-white px-3 py-2 focus:outline-none text-white'
                                    value={address}
                                    onChange={e => setAddress(e.target.value)}
                                />
                            </Autocomplete>

                        </div>
                        <div className="flex flex-col my-6">
                            <label htmlFor="Birthday date:" className='text-xs text-amber-500 font-normal'>Birthday date.</label>
                            <div className="rounded-lg border border-white mt-2 flex px-2">
                                <input type="date"
                                    placeholder='Pick'
                                    className='w-11/12 text-sm bg-transparent  p-2 focus:outline-none text-white'
                                    value={birthday}
                                    onChange={e => setBirthday(e.target.value)}
                                />
                                <img src={icon} alt="" className='w-10' />
                            </div>

                        </div>

                        <div className="flex  w-full gap-1">
                            <div className="flex flex-col w-1/2">
                                <label htmlFor="State" className='text-xs text-amber-500 font-normal'>State </label>
                                <input type="text"
                                    placeholder='Type here...'
                                    value={state}
                                    onChange={e => setState(e.target.value)}
                                    className='text-sm mt-2 bg-transparent rounded-lg border border-white px-3 py-2 focus:outline-none text-white' />
                            </div>
                            <div className="flex flex-col w-1/2">
                                <label htmlFor="Country" className='text-xs text-amber-500 font-normal'>Country </label>
                                <input type="text"
                                    placeholder='Type Here'
                                    value={country}
                                    onChange={e => setCountry(e.target.value)}
                                    className='text-sm mt-2 bg-transparent rounded-lg border border-white px-3 py-2 focus:outline-none text-white' />
                            </div>
                        </div>
                        <div className="flex gap-2 mt-16 mb-28 w-full">
                            {/* <button
                                type='button'
                                className='bg-amber-500 font-semibold text-black p-1 rounded-md md:w-2/4 w-3/4'>
                                    Change Password
                                    </button> */}
                            <NavLink to="/customers-dashboard" className='bg-amber-500 font-semibold text-black p-1 rounded-md w-1/4 text-center'>Skip</NavLink>





                            {load ? (
                                <div className="bg-white p-1 w-2/4 font-semibold rounded-md flex justify-center items-center">
                                    <h4 className="text-black text-base">Saving...</h4>
                                    <input
                                        type="image"
                                        src={Spin}
                                        value="Loading"
                                        className="w-6"
                                    />
                                </div>
                            ) : (
                                <button
                                    type='submit'
                                    onClick={updateProfile}
                                    className='bg-white font-semibold text-black p-1 rounded-md w-2/4'>
                                    Save
                                </button>
                            )}

                        </div>
                    </form>
                </div>
            </div>
            <hr className='hidden md:block' />
        </div>
    )
}
export default UserProfile