import React from 'react'
import Header from "./Header"
import { NavLink, useNavigate } from "react-router-dom"


function CookiePolicy() {
    return (
        <div>
            <Header user="customer" />

            <div className="fixed bg-black md:p-6 p-4 md:w-3/12 w-7/12 md:mt-20 md:mb-20 mt-16 mb-24 md:ml-16 ml-8 rounded-md flex justify-center items-center">
                <h4 className="text-white md:text-2xl text-base  font-bold">Cookie Policy</h4>


            </div>

            <div className='flex gap-10 md:ml-32 ml-8 md:mr-16 mr-8 pt-48 pb-48'>
                < div className='w-2 bg-[#FFA100] min-h-screen ' >

                </div>

                <div className="flex flex-col gap-3 md:ml-32 ml-8 md:mr-16 mr-8 w-10/12 ">

                    <div className=" md:w-9/12 w-full text-white leading-8 h-full">

                        <h1 className='text-left text-xl text-[#FFA100]'>
                            <strong>How we use Cookies</strong>
                        </h1>
                        <p className="mt-8 leading-8 tracking-wide text-sm  text-justify">
                            Our website (including our mobile site) uses cookies. Cookies are small text files that are placed on your computer or mobile device when you visit our website. Our website uses both session cookies and persistent cookies. Session cookies enable you to move from page to page within our website and any information you enter to be remembered. A session cookie is deleted when you close your browser or after a short time. Persistent cookies allow our website to remember your preferences and settings when you visit our website in the future. Persistent cookies expire after a set period of time. Cookies are used in the following ways on our website:
                        </p>

                        <ul className='mt-8'>
                            <li>
                                <strong className='text-base text-[#FFA100]'>Google Analytics -</strong> we use Google Analytics to provide us with information about how you use our website. We use this information to compile statistics and to help us to improve our website and the services we offer. Google uses cookies as part of this process to collect anonymous information, including the number of visitors to the website, where they have come to the website from, the pages they visit and the length of time they have spent on the website.
                            </li>
                            <li className='mt-8'>

                                <strong className='text-base text-[#FFA100]'>Online Application System  -</strong>
                                we use session cookies on our online application system to assist in the navigation around the site. Please see our privacy policy for more information

                            </li>
                            <li className='mt-8'>

                                <strong className='text-base text-[#FFA100]'>Contact Us -</strong>
                                we use a session cookie on our contact us page to allow you to contact us via our website.
                            </li>
                            <li className='mt-8'>

                                <strong className='text-base text-[#FFA100]'>Mobile Site - </strong>
                                we use persistent cookies and session cookies on our mobile site to enable the site to work effectively and to remember your preferences and settings.
                            </li>
                            <li className='mt-8'>

                                <strong className='text-base text-[#FFA100]'>Video Content - </strong>
                                we use an online video software provider to host and play our video content. That provider uses session cookies to collect certain non-personally identifiable information about you when you view our videos, including your IP address, browser type, Internet Service Provider and operating system. They also collect information relating to how videos are played, including when and for long, and whether they are played in full screen mode. This information helps them to monitor how their software is being used and us to monitor how our videos are being viewed.
                            </li>
                            <li className='mt-8'>
                                <strong className='text-base text-[#FFA100]'>Email Subscriptions Service - </strong>
                                session cookies are used in our email subscriptions service to track recipients' interaction with our email updates and to facilitate our online subscriptions manager website.
                                The use of cookies in these ways is to improve the functionality of our website and your experience when using it. Cookies can be disabled by changing the settings on your browser. If you do decide to disable cookies, you may not be able to access some areas of our website.
                                By continuing to use our website without changing your settings, you consent to our use of cookies as described above. You can unsubscribe from our email updates or disable tracking (where relevant) at any time either by using the link provided at the bottom of the email or by contacting
                                <a href="mailto:support@roadlers.com">support@roadlers.com.</a>


                            </li>
                        </ul>



                    </div>

                    <div className="flex justify-end text-center md:w-9/12 w-full mt-24">
                        <NavLink className="font-semibold rounded bg-white text-black p-2 " exact to="/login">
                            Accept Cookies
                        </NavLink>


                    </div>
                </div>
            </div>

        </div>
    )
}

export default CookiePolicy

