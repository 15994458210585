import icon from '../../Images/icon.svg'
import driverOne from '../../Images/driver1.png'
import pen from '../../Images/pen.svg'
import cancel from '../../Images/cancel.svg'
import message from '../../Images/message.svg'

import { useRef, useState, useEffect } from 'react'
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import { GoogleMap, useJsApiLoader, Autocomplete } from '@react-google-maps/api';
import { count } from 'rsuite/esm/utils/ReactChildren'
import { Multiselect } from 'multiselect-react-dropdown'
import Dropdown from '../Common/Dropdown'
import moment from 'moment';
import { BASE_URL } from "../utils/constant"
import Spin from "../../Images/spinner.gif"
import getCookie from '../utils/SecuredTokens'


function ApprovedIndividualComponent(props) {
  

    let userData = getCookie("user");
    let userObject = JSON.parse(userData) 
    let token = userObject?.token

  
    const { } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyDkMuW-hGvdRfUhsb2R9RFqA0Pq4Qxg4Cs",
        libraries: ['places'],
    })


    const [license, setLicense] = useState()
    const [licenseSelected, setLicenseSelected] = useState(false)

    const [selectedFile, setSelectedFile] = useState();
	const [isSelected, setIsSelected] = useState(false);

    const [isLicenseSaved, setIsLicenseSaved] = useState(false)
    const [isPassportSaved, setIsPassportSaved] = useState(false)

    // GUARANTOR 1 VALUES
    const [firstName1, setFirstName1] = useState('')
    const [lastName1, setLastName1] = useState('')
    const [idNumber1, setIdNumber1] = useState('')
    const [phone1, setPhone1] = useState('')
    const [address1, setAddress1] = useState('')

    const [showGuarantorForm, setShowGuarantorForm] = useState(false)
    const [isDocumentSaved, setIsDocumentSaved] = useState(false)
    const [editPossible, setEditPossible] = useState(false)
    const [editInfo, setShowEditInfo] = useState(false)
    

    const fileInputRef = useRef()
    const passportRef = useRef()

    // HANDLE DRIVER LICENSE FILE UPLOAD
    const changeLicense = (e) => {
        setLicense(e.target.files[0]);
        setLicenseSelected(true)
    }

    // HANDLE SHOW GUARANTOR FORM
    const handleShowGuarantorForm = (e) => {
        e.preventDefault()
        setShowGuarantorForm((showGuarantorForm) => !showGuarantorForm)
    }

    // HANDLE BACkGROUND PASSPORT FILE UPLOAD
    const changeHandler = (event) => {
		setSelectedFile(event.target.files[0]);
		setIsSelected(true);
	};

    let notify = () => { toast("File Successfully Uploaded!") };


    const {
        firstName,
        lastname,
        address,
        dob,
        service,
        availability,
        countryLocation,
        stateLocation,
        category,
        providerId,
        driverDoc,
        statusProfile,
        guarantorDoc,
        editDate,
        driverId
    } = props

    const [editAddress, setEditAddress] = useState('')
    const [editDob, setEditDob] = useState('')
    const [editStateLocation, setEditStateLocation] = useState('')
    const [editCountryValue, setEditCountryValue] = useState('')
    const [categoryChosen, setCategoryChosen] =  useState('')
    const [servicesChosen, setServicesChosen] = useState('')

    const [profileSaved, setProfileSaved] = useState(false)

    let userIdProvider = JSON.parse(localStorage.getItem("userIdProvider"))

    // SETTING ID OF INDIVIDUAL DOCS
    const passportId = driverDoc?.find((item) => (item?.doc_type.includes('passport'))) 
    const licenseId = driverDoc?.find((item) => (item?.doc_type.includes('passport'))) 

    // SETTING THE ADDRESS FROM THE AUTOCOMPLETE TO THE STORED RESPECTIVE VALUES
    const [autocomplete, setAutocomplete] = useState(null)
    const [autocomplete1, setAutocomplete1] = useState(null)


    useEffect(() => {
        if(category == 'individual') {
            setCategoryChosen('Individual')
        }else{
            setCategoryChosen('Business')
        }
    }, [])

    useEffect(() => {
        if(service == 'driver') {
            setServicesChosen('Driver')
        }else if(service == 'vehicle') {
            setServicesChosen('Vehicle')
        }else {
            setServicesChosen('Driver & Vehicle')
        }
    }, [])

    const today = new Date()
    const getToday = moment(today).format("YYYY-MM-DD")
    const profileEditDate = moment(editDate).format("YYYY-MM-DD")
    const compareDate = moment(getToday).isAfter(profileEditDate)

    const onPlaceChanged1 = () => {
        if(autocomplete1 !== null) {
            const places = autocomplete1.getPlace().formatted_address
            if(places) {
                setEditAddress(places)
            }else {
                setEditAddress(autocomplete1.getPlace().name)
            }
        }
    }


    const onPlaceChanged = () => {
        if(autocomplete !== null) {
            const places = autocomplete.getPlace().formatted_address
            if(places) {
                setAddress1(places)
            }else {
                setAddress1(autocomplete.getPlace().name)
            }
        }
    }

    // Means of Identification
    const [selectedId, setSelectedId] = useState('')

    const [passportLoad, setPassportLoad] = useState("")
    // UPDATE DRIVERS PASSPORT
    const updatePassport = (e) => {
        e.preventDefault()
        setPassportLoad(Spin)

        let formDatas = new FormData();
        formDatas.append('id', passportId.id)
        formDatas.append('driver_id', driverId)
        formDatas.append('doc_type', 'passport')
        formDatas.append('file', selectedFile)

        axios({
            method: 'post',
            url: `${BASE_URL}/v1/update-passport`,
            data: formDatas,

            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*"

            }
        })
            .then(function (response) {
                setPassportLoad("")
                notify()
                setIsPassportSaved(true)
            })
            .catch(function (error) {
                //handle error
                setPassportLoad("")
                let notify = () => { toast.error((error.response.data.message), {
                    toastClassName: 'error'
                } ) };
                notify()
            });
    }

    // ARRAY OF ID LIST
    const idArray = [
        {value: 'bvn', label: 'BVN'}, 
        {value: 'nin', label: 'NIN'},
        {value: 'passport', label: 'PASSPORT'},
        {value: 'license', label: 'LICENSE'}
    ]


    const [licenseLoad, setLicenseLoad] = useState("")
    // UPDATE DRIVERS LICENSE
    const updateLicense = (e) => {
        e.preventDefault()
        setLicenseLoad(Spin)

        let formDatas = new FormData();
        formDatas.append('id', licenseId.id)
        formDatas.append('driver_id', driverId)
        formDatas.append('doc_type', 'passport')
        formDatas.append('file', license)

        axios({
            method: 'post',
            url: `${BASE_URL}/v1/update-driver-licence`,
            data: formDatas,

            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*"

            }
        })
            .then(function (response) {
                setLicenseLoad("")
                notify()
            })
            .catch(function (error) {
                //handle error
                setLicenseLoad("")
                let notify = () => { toast.error((error.response.data.message), {
                    toastClassName: 'error'
                } ) };
                notify()
            });
    }

    const [firstnameError, setFirstnameError] = useState('')
    const [lastnameError, setLastnameError] = useState('')
    const [selectedIdError, setSelectedIdError] = useState('')
    const [idNumberError, setIdNumberError] = useState('')
    const [phoneError, setPhoneError] = useState('')
    const [addressError, setAddressError] = useState('')


    const [profileLoad, setProfileLoad] = useState("")
    // UPDATE DRIVERS PROFILE
    const onDriverUpdate = (e) => {
        e.preventDefault()
        setProfileLoad(Spin)

        let formDatas = new FormData();
        formDatas.append('id', driverId)
        formDatas.append('driver_id', driverId)
        formDatas.append('firstname', firstName)
        formDatas.append('lastname', lastname)
        formDatas.append('category', category)
        formDatas.append('dob', editDob || dob)
        formDatas.append('service', service)
        formDatas.append('availability',updateAvialablity ||availability )
        formDatas.append('address', editAddress || address)
        formDatas.append('state', editStateLocation || stateLocation)
        formDatas.append('country', editCountryValue || countryLocation)


        axios({
            method: 'post',
            url: `${BASE_URL}/v1/update-driver-profile`,
            data: formDatas,

            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*"

            }
        })
            .then(function (response) {
                setProfileLoad("")
                setProfileSaved(true)
                notify()
            })
            .catch(function (error) {
                //handle error
                setProfileLoad("")
                let notify = () => { toast.error((error.response.data.message), {
                    toastClassName: 'error'
                } ) };
                notify()
            });
    }

    const [guarantorLoad, setGuarantorLoad] = useState("")
    // HANDLE GURANTOR 1 SUBMIT
    const handleGuarantor1Submit = (e) => {
        e.preventDefault()
        setGuarantorLoad(Spin)
        
        const formData1 = new FormData();
        formData1.append('firstname', firstName1);
        formData1.append('lastname', lastName1);
        formData1.append('identification', selectedId);
        formData1.append('id_number', idNumber1);
        formData1.append('phone_number', phone1);
        formData1.append('address', address1);
        // formData1.append('guarantor_type', 'guarantor1');
        formData1.append('driver_id', driverId)
        formData1.append('user_id', userIdProvider)

        if(firstName1 === ''){
            setFirstnameError('This field must be filled')

            setGuarantorLoad("")
            setTimeout(() => {
                setFirstnameError('')
            }, 5000)
        }else if(lastName1 === '') {
            setLastnameError('This field must be filled')

            setGuarantorLoad("")
            setTimeout(() => {
                setLastnameError('')
            }, 5000)
        }else if(address1 === '') {
            setAddressError('This field must be filled')

            setGuarantorLoad("")
            setTimeout(() => {
                setAddressError('')
            }, 5000)
        }else if(selectedId === '') {
            setSelectedIdError('This field must be filled')

            setGuarantorLoad("")
            setTimeout(() => {
                setSelectedIdError('')
            }, 5000)
        }else if(idNumber1 === '' || !idNumber1) {
            setIdNumberError('This field must be filled')

            setGuarantorLoad("")
            setTimeout(() => {
                setIdNumberError('')
            }, 5000)
        }else if(phone1 === ''){
            setPhoneError('This field must be filled')

            setGuarantorLoad("")
            setTimeout(() => {
                setPhoneError('')
            }, 5000)
        }else {
            axios({
                method: 'post',
                url: `${BASE_URL}/v1/update-guarantor`,
                // data: { document: `${license?.name}`, doc_type: `company logo`, id: `${id}` },
                data: formData1,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    "Access-Control-Allow-Origin": "*",
                }
            })
            .then(function (response) {
                const customer = response.data;
                setIsDocumentSaved(true)
                setGuarantorLoad("")
            })
            .catch(function (error) {
                //handle error
                setGuarantorLoad("")
                let notify = () => { toast.error((error.response.data.message), {
                    toastClassName: 'error'
                } ) };
                notify()
            });
        }

    }

    // FOR MULTISELECT
    const convertArray = (array, property) => {
        var result = []
        for(var i = 0; i<array.length; i+=1) {
            result.push(array[i][property])
        }
        setUpdateAvailability(result)
    }

    const [availabilitySelect, setAvailabilitySelect] = useState([])
    const [updateAvialablity, setUpdateAvailability] = useState([])
    useEffect(() => {
        setUpdateAvailability([])
        convertArray(availabilitySelect, 'value')
        // availabilitySelect.map((item) => {
            // setUpdateAvailability(item['value'])
            // setUpdateAvailability( updateAvialablity.push(item.value))
        // })
    }, [availabilitySelect.length])


    // AVAILABILITY SERVICE OPTIONS
    const availabilityOptionData = [
        {value: 'Night Driver', label: 'Night Driver', id: 1},
        {value: 'Day Driver', label: 'Day Driver', id: 2},
        {value: 'Full-Time Driver', label: 'Full-Time Driver', id: 3},
        {value: 'Daily Driver', label: 'Daily Driver', id: 4}
    ]

    const [optionsValue] = useState(availabilityOptionData)

    // ACCESSING PASSPORT USER UPLOADED
    const imgSrc = driverDoc?.find((item) => (item?.doc_type === 'passport')) 
    

  return (
    <>
        <div className='md:border-r md:border-amber-500 h-100 md:w-6/12 w-full md:px-20 pb-20 pt-12 md:pt-36 component-approval-form-page'>
            <ToastContainer />

            <div className="flex items-center mt-8 justify-between">
                <h2 className='text-white font-bold text-lg'>Service Provider’s Profile  </h2>
                <img src={pen} alt="" className='w-6' />
            </div>

            {
                imgSrc?.document && 
                <div className="w-[150px] h-[150px] overflow-hidden mt-8 rounded-full">
                    <img src={`https://api.roadlers.com/storage/${imgSrc?.document}`} alt="Image"  className='w-full rounded-full object-fit'/>
                </div>
            }


            <main className='w-full'>
                <div className="flex w-full">
                    <div className="pb-20 w-full">
                        <form className='w-full mt-4'>
                            <div className="flex my-6 md:my-12 w-full gap-5 component-approval-form-name-wrapper">
                                <div className="flex flex-col w-1/2">
                                    <label htmlFor="First name" className='text-xs text-[#FFA100] font-normal'>First Name <span className='text-red-700'>*</span></label>
                                    <input 
                                        type="text" 
                                        value={firstName} 
                                        className='text-sm mt-2 bg-[#1D1C1C] rounded-xl border border-white px-3 py-3 focus:outline-none text-white' 
                                    />
                                </div>
                                <div className="flex flex-col w-1/2">
                                    <label htmlFor="Last Name" className='text-xs text-[#FFA100] font-normal'>Last Name <span className='text-red-700'>*</span></label>
                                    <input 
                                        type="text" 
                                        value={lastname ? lastname : ''} 
                                        className='text-sm mt-2 bg-[#1D1C1C] rounded-xl border border-white px-3 py-3 focus:outline-none text-white' 
                                    />
                                </div>
                            </div>

                            <div className="flex flex-col">
                                <label htmlFor="Address" className='text-xs text-[#FFA100] font-normal'>Address <span className='text-red-700'>*</span></label>

                                <Autocomplete className='autcomplete-style'
                                    onPlaceChanged={onPlaceChanged1}
                                    onLoad={(value) => setAutocomplete1(value)}
                                >
                                <input  
                                    defaultValue={editAddress || address} 
                                    onChange={e => setEditAddress(e.target.value)}
                                    className='text-sm mt-2 bg-[#1D1C1C] w-full rounded-xl border border-white px-3 py-3 focus:outline-none text-white' 
                                />
                                </Autocomplete>
                            </div>



                            <div className="flex flex-col my-6 md:my-12">
                                <label htmlFor="Category:" className='text-xs text-[#FFA100] font-normal'>Category <span className='text-red-700'>*</span></label>
                                <input type="text" value={categoryChosen} className='text-sm mt-2 bg-[#1D1C1C] rounded-xl border border-white px-3 py-3 focus:outline-none text-white' />
                            </div>

                            <div className="flex flex-col">
                                <label htmlFor="Birthday date:" className='text-xs text-[#FFA100] font-normal'>Birthday date.  <span className='text-red-700'>*</span></label>
                                <div className="rounded-xl border border-white mt-2 flex px-2 bg-[#1D1C1C]">
                                    <input type="date" 
                                        defaultValue={editDob || dob} 
                                        onChange={e => setEditDob(e.target.value)}
                                        className='w-11/12 text-sm bg-[#1D1C1C] rounded-xl  p-3 focus:outline-none text-white' />
                                    <img src={icon} alt="" className='w-12' />
                                </div>

                            </div>

                            <div className="flex flex-col my-6 md:my-12">
                                <label htmlFor="Service Offering:" className='text-xs text-[#FFA100] font-normal'>Service Offering  <span className='text-red-700'>*</span></label>

                                <input type="text" value={servicesChosen} className='text-sm mt-2 bg-[#1D1C1C] rounded-xl border border-white px-3 py-3 focus:outline-none text-white' />
                            </div>



                            <div className="flex flex-col my-6 md:my-12">
                                <div className="label-wrapper flex justify-between items-center mb-3 relative">
                                    <label htmlFor="Availability:" className='text-xs text-[#FFA100] font-normal'>Availability  <span className='text-red-700'>*</span></label>
                                    {statusProfile === "Rejected" || statusProfile === "Approved" && 
                                    <img src={pen} alt="" 
                                        className='w-6 cursor-pointer' 
                                        onClick={() => {setEditPossible(editPossible => !editPossible)}}
                                        onMouseOver={() => setShowEditInfo(true)}
                                        onMouseOut={() => setShowEditInfo(false)}
                                    />}

                                    {
                                        editInfo && 
                                        <div className="flex absolute bottom-8 right-0 items-center rounded-xl p-4 bg-[#fff]">
                                            <p className='text-center'> Click this if you wish to change your availability</p>
                                        </div>
                                    }
                                </div>
                                {
                                    !editPossible && 
                                    <input type="text" value={availability} className='text-sm  bg-[#1D1C1C] rounded-xl border border-white px-3 py-3 focus:outline-none text-white' />
                                }

                                {
                                    editPossible &&
                                    <Multiselect 
                                        customCloseIcon={<img src={cancel} className="cursor-pointer"/>}
                                        options={optionsValue} 
                                        displayValue="label" 
                                        onRemove={e => (setAvailabilitySelect(e))}
                                        onSelect={e => (setAvailabilitySelect(e))}

                                        placeholder="Tick all the ones that apply to you"
                                        hidePlaceholder={true}
                                        style={{
                                        chips: {
                                            background: '#fff',
                                            color: '#000'
                                        },
                                        multiselectContainer: {
                                            color: '#000',
                                            cursor: 'pointer',
                                            background: '#1D1C1C'
                                        },
                                        searchBox: {
                                            border: 'solid 1px #fff',
                                            'border-radius': '13px',
                                            'padding': '10px'
                                        },
                                        closeIcon: {
                                            'filter': 'grayscale(100%)'
                                        }
                                        }}
                                    />
                                }

                            </div>




                            <div className="flex my-6 md:my-12 w-full gap-5 component-approval-form-location-wrapper">
                                <div className="flex flex-col w-1/2">
                                    <label htmlFor="State" className='text-xs text-[#FFA100] font-normal'>State  <span className='text-red-700'>*</span></label>
                                    <input type="text" 
                                        defaultValue={editStateLocation || stateLocation} 
                                        onChange={e => setEditStateLocation(e.target.value)}
                                        className='text-sm mt-2 bg-[#1D1C1C] rounded-xl border border-white px-3 py-3 focus:outline-none text-white' />
                                </div>
                                <div className="flex flex-col w-1/2">
                                    <label htmlFor="Country" className='text-xs text-[#FFA100] font-normal'>Country  <span className='text-red-700'>*</span></label>
                                    <input type="text" defaultValue={editCountryValue || countryLocation} className='text-sm mt-2 bg-[#1D1C1C] rounded-xl border border-white px-3 py-3 focus:outline-none text-white' 
                                        onChange={e => setEditCountryValue(e.target.value)}
                                    />
                                </div>
                            </div>




                            {/* <div className="flex gap-2 mt-10">
                                <button className='mt-4 w-1/2 p-2 bg-blue-900 rounded-md text-sm font-normal text-white'>Go to Dashboard</button>
                            </div> */}
                            <div className="flex  mt-[20px] flex-col ">
                            {
                                statusProfile === 'Pending' &&
                                <button className='bg-blue-900 rounded text-white px-4 w-1/2 py-2 font-semibold text-sm'>Pending</button>
                            }

                            {
                                statusProfile === 'Approved' &&
                                <>
                                    
                                    <button className='bg-amber-500 rounded text-black px-4 py-1 font-bold text-sm  md:w-28'>Approved</button>
                                    <div className="my-6 mb-3 flex gap-5 mt-8 md:gap-2 w-11/12">

                                        <button className='bg-blue-900 rounded text-white px-4 py-2 md:py-2 text-xs md:px-3 font-bold md:text-sm w-8/12'>Account Verified</button>
                                        {
                                            (compareDate === false) &&
                                            <button
                                                className='bg-white bg-opacity-50 rounded text-black  px-4 py-2 md:py-2 text-xs  font-bold md:text-sm w-4/12'
                                                disabled={true}
                                            >
                                                Save
                                            </button>
                                        }

                                        {
                                            compareDate === true &&
                                            (
                                                !profileSaved ?
                                                <button
                                                className='flex justify-center items-center bg-white rounded text-black  px-4 py-2 md:py-2 text-xs  font-bold md:text-sm w-4/12'
                                                onClick={onDriverUpdate}
                                                >
                                                    Save
                                                    {
                                                        profileLoad && 
                                                        <input
                                                            type="image"
                                                            src={Spin}
                                                            value="Loading"
                                                            className="w-4 md:w-6"
                                                        />
                                                    }
                                                </button> :
                                                <button
                                                    className='bg-white bg-opacity-50 rounded text-black  px-4 py-2 md:py-2 text-xs  font-bold md:text-sm w-4/12'
                                                    disabled={true}
                                                >
                                                    Saved
                                                </button>
                                            )
                                        }

                                    </div>
                                    <p className='text-red-600 mb-2 text-xs'>You are eigible to edit 30 days after approval</p>                           
                                </>
                            }

                            {
                                statusProfile === 'Rejected' && 
                                <>
                                    <div className="flex items-center gap-[20px]">
                                        <button className='bg-red-600 rounded text-white px-4 py-1 font-semibold text-sm w-1/4'>Rejected</button>

                                        <p className='text-[#FFA100]'>Edit your informations and resubmit for Approval</p>
                                    </div>
                                    <div className="my-6 flex gap-5 mt-8 md:gap-2 w-11/12">
                                        {
                                            
                                                !profileSaved ?
                                                <button
                                                className='flex justify-center items-center bg-white rounded text-black  px-4 py-2 md:py-2 text-xs  font-bold md:text-sm w-4/12'
                                                onClick={onDriverUpdate}
                                                >
                                                    Save
                                                    {
                                                        profileLoad && 
                                                        <input
                                                            type="image"
                                                            src={Spin}
                                                            value="Loading"
                                                            className="w-4 md:w-6"
                                                        />
                                                    }
                                                </button> :
                                                <button
                                                    className='bg-white bg-opacity-50 rounded text-black  px-4 py-2 md:py-2 text-xs  font-bold md:text-sm w-4/12'
                                                    disabled={true}
                                                >
                                                    Saved
                                                </button>
                                            
                                        }
                                    </div> 
                                </>
                            }
                            </div>

                        </form>
                    </div>
                </div>

            </main>
        </div>

        <div className='md:px-16 md:w-4/12 w-full mt-12 md:mt-0 pb-12 md:pt-36 component-approval-doc-section' >
            <div className="md:flex justify-end hidden"><h4 className='font-bold text-amber-500 mb-8'>Provider’s ID: {providerId}</h4></div>

            <h2 className='mb-10 text-white font-bold text-lg'>My Documents</h2>

            {
                (driverDoc?.filter(obj => (obj?.doc_type.includes("driver_licence"))).map((docItem) => {
                    return (
                    <div className="my-8">
                        {
                            (docItem.status == "Pending") &&

                            <div className="flex justify-between border border-white p-2 md:p-3 items-center">
                                <p className='text-white md:text-sm text-xs font-normal'>Driver’s License </p>
                                <a href={`https://api.roadlers.com/storage/${docItem.document}`} target="_blank" className='border-theme-color px-3 py-1 md:text-sm md:px-2 md:py-0 text-xs text-white'>View</a>
                            </div>
                        }

                        {
                            (docItem.status === "Accept") &&
                            (
                                (moment(getToday).isAfter(moment(docItem.edit_date).format("YYYY-MM-DD")) === false) ?
                                <div className="flex justify-between border border-white p-2 md:p-3 items-center">
                                    <p className='text-white md:text-sm text-xs font-normal'>Driver’s License </p>
                                    <a href={`https://api.roadlers.com/storage/${docItem.document}`} target="_blank" className='border-theme-color px-3 py-1 md:text-sm md:px-2 md:py-0 text-xs text-white'>View</a>
                                </div> :
                                <div className="flex justify-between border bg-[#1D1C1C] border-white p-2 md:p-3 items-center">
                                    {
                                        licenseSelected && license?.name ?(
                                            <p className='text-white md:text-base text-xs font-normal'>{license?.name}</p>
                                        ) : (
                                            <p className='text-white md:text-base text-xs font-normal'>Driver’s License<span className="text-red-600">*</span> </p>
                                        )
                                    }


                                    {/* use useRef to trigger this input field for Document upload */}
                                    <input
                                        type="file"
                                        style={{ display: 'none' }}
                                        ref={fileInputRef}
                                        accept="image/jpeg,image/jpg,application/pdf"

                                        onChange={changeLicense} 
                                    />


                                    {
                                        ( license?.name) ? (
                                            <button
                                                onClick={(event) => {
                                                    event.preventDefault()
                                                    fileInputRef.current.click()
                                                }}
                                                className='border-theme-color px-3 py-1 md:text-base md:px-2 md:py-0 text-xs text-white'>
                                                    Change
                                            </button>

                                        ) : (
                                            <button
                                                onClick={(event) => {
                                                    event.preventDefault()
                                                    fileInputRef.current.click()
                                                }}
                                                className='border-theme-color px-3 py-1 md:text-base md:px-2 md:py-0 text-xs text-white'>
                                                    Upload
                                            </button>
                                        )
                                    }

                                </div>
                            )
                        }

                        {
                            (docItem.status === "Rejected") &&

                            <div className="flex justify-between border bg-[#1D1C1C] border-white p-2 md:p-3 items-center">
                            {
                                licenseSelected && license?.name ?(
                                    <p className='text-white md:text-base text-xs font-normal'>{license?.name}</p>
                                ) : (
                                    <p className='text-white md:text-base text-xs font-normal'>Driver’s License<span className="text-red-600">*</span> </p>
                                )
                            }


                            {/* use useRef to trigger this input field for Document upload */}
                            <input
                                type="file"
                                style={{ display: 'none' }}
                                ref={fileInputRef}
                                accept="image/jpeg,image/jpg,application/pdf"

                                onChange={changeLicense} 
                            />


                            {
                                ( license?.name) ? (
                                    <button
                                        onClick={(event) => {
                                            event.preventDefault()
                                            fileInputRef.current.click()
                                        }}
                                        className='border-theme-color px-3 py-1 md:text-base md:px-2 md:py-0 text-xs text-white'>
                                            Change
                                    </button>

                                ) : (
                                    <button
                                        onClick={(event) => {
                                            event.preventDefault()
                                            fileInputRef.current.click()
                                        }}
                                        className='border-theme-color px-3 py-1 md:text-base md:px-2 md:py-0 text-xs text-white'>
                                            Upload
                                    </button>
                                )
                            }

                        </div>
                        }
        
                        <div className="flex justify-end mt-4">
                            {
                                docItem.status === "Rejected" ?
                                <div className="flex justify-end mt-2 gap-3">
                                {
                                    !isLicenseSaved &&
                                    <button className='flex justify-center items-center bg-white rounded text-[#000] px-4 py-1 font-semibold text-sm' onClick={updateLicense}>Resend
                                        {
                                            licenseLoad && 
                                            <input
                                                type="image"
                                                src={Spin}
                                                value="Loading"
                                                className="w-4 md:w-6"
                                            />
                                        }
                                    </button>
                                }

                                {
                                    isLicenseSaved &&
                                    <button className='bg-amber-600 rounded text-[#000] px-4 py-1 font-semibold text-sm' disabled={true}>Saved</button>
                                }

                                <button className='bg-red-600 rounded text-white px-4 py-1 font-semibold text-sm' disabled={true}>Rejected</button>
                            </div>  :
                                <>
                                        {
                                            docItem.status === "Pending" &&
                                            <button className='bg-blue-900 rounded text-white px-4 py-1 font-semibold text-sm' disabled={true}>Pending</button>
                                        }

                                        {
                                            docItem.status === "Accept" &&
                                            <div className="flex justify-end mt-2 gap-3">
                                                {
                                                    (!isLicenseSaved && (moment(getToday).isAfter(moment(docItem.edit_date).format("YYYY-MM-DD")) === true)) &&
                                                    <button className=' flex justify-center items-center bg-white rounded text-[#000] px-4 py-1 font-semibold text-sm' onClick={updateLicense}>Resend
                                                        {
                                                            licenseLoad && 
                                                            <input
                                                                type="image"
                                                                src={Spin}
                                                                value="Loading"
                                                                className="w-4 md:w-6"
                                                            />
                                                        }
                                                    </button>
                                                }

                                                {
                                                    isLicenseSaved &&
                                                    <button className='bg-amber-600 rounded text-[#000] px-4 py-1 font-semibold text-sm' disabled={true}>Saved</button>
                                                }
                                                <button className='bg-amber-500 rounded text-white px-4 py-1 font-semibold text-sm' disabled={true}>Accepted</button>
                                            </div>

                                        }
                                </>
                            }

        
                        </div>
                    </div>
                    )
                }))
            }

            <div className="flex justify-between border border-white p-2 md:p-3  items-center">
                <p className='text-white md:text-sm text-xs font-normal'>Guarantors Form </p>
                <button className='border-theme-color px-3 py-1 md:text-sm md:px-2 md:py-0 text-xs text-white' onClick={handleShowGuarantorForm}>View</button>
            </div>

            {
                (guarantorDoc?.map((docItem) => {
                    return (
                        <div className="">
                            {
                                showGuarantorForm &&
                                <>
                                    <div className="md:mt-4 mt-4">
                                        {/* <h4 className='text-amber-500 text-bold'>Guarantor  <span className="text-red-600">*</span></h4> */}
                    
                                        <div className="flex my-4 md:my-6 w-full gap-5 gurantor-name-container-edit">
                                            <div className="flex flex-col w-1/2 firstname-wrapper">
                                                <label htmlFor="First name" className='text-xs text-white font-normal'>First Name <span className="text-red-600">*</span></label>
                                                <input
                                                    type="text"
                                                    placeholder='Type here.....'
                                                    className='text-base my-2 bg-[#1D1C1C] rounded-xl border border-white px-3 py-3 focus:outline-none text-white'
                                                    name='firstname1'
                                                    defaultValue={docItem.firstname || firstName1}
                                                    onChange={(e) => setFirstName1(e.target.value)}
                                                />
                                                <p className='text-red-600 mt-1'>{firstnameError}</p>
                                            </div>
                                            <div className="flex flex-col w-1/2 lastname-wrapper">
                                                <label htmlFor="Last Name" className='text-xs text-white font-normal'>Last Name <span className="text-red-600">*</span></label>
                                                <input
                                                    type="text"
                                                    placeholder='Type here.....' className='text-base my-2 bg-[#1D1C1C] rounded-xl border border-white px-3 py-3 focus:outline-none text-white' 
                                                    name='lastname1'
                                                    defaultValue={docItem.lastname || lastName1}
                                                    onChange={(e) => setLastName1(e.target.value)}
                                                    
                                                />
                                                <p className='text-red-600 mt-1'>{lastnameError}</p>
                                            </div>
                                        </div>
                    
                                        <div className="flex flex-col">
                                            <label htmlFor="Address" className='text-xs text-white font-normal'>Address <span className="text-red-600">*</span></label>
                    
                                            <Autocomplete className='autcomplete-style'
                                                onPlaceChanged={onPlaceChanged}
                                                onLoad={(value) => setAutocomplete(value)}
                                            >
                                            <input
                                                type="phone"
                                                placeholder='Type here.....' className='text-base w-full my-2 bg-[#1D1C1C] rounded-xl border border-white px-3 py-3 focus:outline-none text-white' 
                                                name='address1'  
                                                defaultValue={docItem.address || address1}
                                                onChange={(e) => setAddress1(e.target.value)}
                                            />
                                            </Autocomplete>
                                            <p className='text-red-500 mt-1'>{addressError}</p>
                                        </div>
                    
                                        <div className="flex flex-col my-4 md:my-6">
                                            <label htmlFor="Category:" className='text-xs text-white font-normal'>Means of Identification <span className="text-red-600">*</span></label>
                    
                                            <div className="text-base mt-2 bg-[#1D1C1C] w-full rounded-xl border border-white pl-5 pr-0 py-3  text-white">
                    
                                                <Dropdown 
                                                    holder= {docItem.identification || `Select ID`}
                                                    optionArray= {idArray}
                                                    setSelectedOption= {setSelectedId}
                                                    selectedOption= {selectedId}
                                                />
                                            </div>
                                            <p className='text-red-500 mt-1'>{selectedIdError}</p>
                    
                                        </div>
                    
                                        <div className="flex flex-col">
                                            <label htmlFor="ID Number" className='text-xs text-white font-normal'>ID Number <span className="text-red-600">*</span></label>
                    
                                            <input 
                                                type="text" 
                                                Value='' 
                                                placeholder='Type here.....' className='text-base my-2 bg-[#1D1C1C] rounded-xl border border-white px-3 py-3 focus:outline-none text-white' 
                                                name='idNumber1'
                                                defaultValue={docItem.id_number || idNumber1}
                                                onChange={(e) => setIdNumber1(e.target.value)}
                                            />
                                            <p className='text-red-500 mt-1'>{idNumberError}</p>
                                        </div>
                    
                                        <div className="flex flex-col my-4 md:my-6 ">
                                            <label htmlFor="Phone Number" className='text-xs text-white font-normal'>Phone Number <span className="text-red-600">*</span></label>
                    
                                            <input 
                                                type="phone" 
                                                placeholder='Type here.....' className='text-base my-2 bg-[#1D1C1C] rounded-xl border border-white px-3 py-3 focus:outline-none text-white' 
                                                name='phone1'
                                                defaultValue={docItem.phone_number || phone1}
                                                onChange={(e) => setPhone1(e.target.value)}
                                            />
                                            <p className='text-red-500 mt-1'>{phoneError}</p>
                                        </div>
                                    </div>

                                    <div className="flex justify-end mt-4">
                                    {
                                        docItem.status === "Rejected" ?
                                        <div className="flex justify-end mt-2 mb-4 gap-3">
                                            {
                                                !isDocumentSaved &&
                                                <button className='flex justify-center items-center bg-white rounded text-[#000] px-4 py-1 font-semibold text-sm' onClick={handleGuarantor1Submit}>Resend
                                                    {
                                                        guarantorLoad && 
                                                        <input
                                                            type="image"
                                                            src={Spin}
                                                            value="Loading"
                                                            className="w-4 md:w-6"
                                                        />
                                                    }
                                                </button>
                                            }

                                            {
                                                isDocumentSaved &&
                                                <button className='bg-amber-600 rounded text-[#000] px-4 py-1 font-semibold text-sm' disabled={true}>Saved</button>
                                            }

                                            <button className='bg-red-600 rounded text-white px-4 py-1 font-semibold text-sm' disabled={true}>Rejected</button>
                                        </div>  :
                                        <>
                                                {
                                                    docItem.status === "Pending" &&
                                                    <button className='bg-blue-900 rounded text-white px-4 py-1 mb-4 font-semibold text-sm' disabled={true}>Pending</button>
                                                }

                                                {
                                                    docItem.status === "Accept" &&
                                                    <div className="flex justify-end mt-2 mb-4 gap-3">
                                                        {
                                                            (!isDocumentSaved && (moment(getToday).isAfter(moment(docItem.edit_date).format("YYYY-MM-DD")) === true)) &&
                                                            <button className='flex justify-center items-center bg-white rounded text-[#000] px-4 py-1 font-semibold text-sm' onClick={handleGuarantor1Submit}>Resend
                                                                {
                                                                    guarantorLoad && 
                                                                    <input
                                                                        type="image"
                                                                        src={Spin}
                                                                        value="Loading"
                                                                        className="w-4 md:w-6"
                                                                    />
                                                                }
                                                            </button>
                                                        }

                                                        {
                                                            isDocumentSaved &&
                                                            <button className='bg-amber-600 rounded text-[#000] px-4 py-1 font-semibold text-sm' disabled={true}>Saved</button>
                                                        }

                                                        <button className='bg-amber-500 rounded text-white px-4 py-1 font-semibold text-sm' disabled={true}>Accepted</button>
                                                    </div>
                                                }
                                        </>
                                    }

                
                                    </div>
                                </>
                            }
                        </div>
                    )
                }))
            }



            {/* <div className="my-8">
                <div className="flex justify-between border border-white p-2 md:p-3  items-center">
                    <p className='text-white md:text-sm text-xs font-normal'>Guarantor 2</p>
                    <button className='border-theme-color px-3 py-1 md:text-sm md:px-2 md:py-0 text-xs text-white'>View</button>
                </div>

                <div className="flex justify-end mt-4">
                    <button className='bg-amber-500 rounded text-black p-1 font-bold text-sm  w-20'>Accepted</button>

                </div>
            </div> */}

            {
                (driverDoc?.filter(obj => (obj?.doc_type.includes("passport"))).map((docItem) => {
                    return (

                <div className="mt-8">
                    {
                        (docItem.status === "Pending") &&
                        <div className="flex justify-between border border-white p-2 md:p-3  items-center">
                            <p className='text-white md:text-sm text-xs font-normal'>White backgroud passport</p>
                            <a href={`https://api.roadlers.com/storage/${docItem.document}`} target="_blank" className='border-theme-color px-3 py-1 md:text-sm md:px-2 md:py-0 text-xs text-white'>View</a>
                        </div>
                    }

                    {
                        (docItem.status === "Accept") &&
                        (
                            (moment(getToday).isAfter(moment(docItem.edit_date).format("YYYY-MM-DD")) === false) ?
                            <div className="flex justify-between border border-white p-2 md:p-3  items-center">
                                <p className='text-white md:text-sm text-xs font-normal'>White backgroud passport</p>
                                <a href={`https://api.roadlers.com/storage/${docItem.document}`} target="_blank" className='border-theme-color px-3 py-1 md:text-sm md:px-2 md:py-0 text-xs text-white'>View</a>
                            </div>
                            :
                            <div className="flex justify-between border bg-[#1D1C1C] border-white p-2 md:p-3 items-center">
                                {
                                    isSelected && selectedFile?.name ?(
                                        <p className='text-white md:text-base text-xs font-normal'>{selectedFile?.name}</p>
                                    ) : (
                                        <p className='text-white md:text-base text-xs font-normal'>Passport<span className="text-red-600">*</span> </p>
                                    )
                                }


                                {/* use useRef to trigger this input field for Document upload */}
                                <input
                                    type="file"
                                    style={{ display: 'none' }}
                                    ref={passportRef}
                                    accept="image/jpeg,image/jpg,application/pdf"

                                    onChange={changeHandler} 
                                />


                                {
                                    ( selectedFile?.name) ? (
                                        <button
                                            onClick={(event) => {
                                                event.preventDefault()
                                                passportRef.current.click()
                                            }}
                                            className='border-theme-color px-3 py-1 md:text-base md:px-2 md:py-0 text-xs text-white'>
                                                Change
                                        </button>

                                    ) : (
                                        <button
                                            onClick={(event) => {
                                                event.preventDefault()
                                                passportRef.current.click()
                                            }}
                                            className='border-theme-color px-3 py-1 md:text-base md:px-2 md:py-0 text-xs text-white'>
                                                Upload
                                        </button>
                                    )
                                }

                            </div>

                        )
                    }

                    {
                        (docItem.status === "Rejected") &&
                        <div className="flex justify-between border bg-[#1D1C1C] border-white p-2 md:p-3 items-center">
                            {
                                isSelected && selectedFile?.name ?(
                                    <p className='text-white md:text-base text-xs font-normal'>{selectedFile?.name}</p>
                                ) : (
                                    <p className='text-white md:text-base text-xs font-normal'>White backgroud passport<span className="text-red-600">*</span> </p>
                                )
                            }


                            {/* use useRef to trigger this input field for Document upload */}
                            <input
                                type="file"
                                style={{ display: 'none' }}
                                ref={passportRef}
                                accept="image/jpeg,image/jpg,application/pdf"

                                onChange={changeHandler} 
                            />


                            {
                                ( selectedFile?.name) ? (
                                    <button
                                        onClick={(event) => {
                                            event.preventDefault()
                                            passportRef.current.click()
                                        }}
                                        className='border-theme-color px-3 py-1 md:text-base md:px-2 md:py-0 text-xs text-white'>
                                            Change
                                    </button>

                                ) : (
                                    <button
                                        onClick={(event) => {
                                            event.preventDefault()
                                            passportRef.current.click()
                                        }}
                                        className='border-theme-color px-3 py-1 md:text-base md:px-2 md:py-0 text-xs text-white'>
                                            Upload
                                    </button>
                                )
                            }

                        </div>
                    }

                        <div className="flex justify-end mt-4">
                            {
                                docItem.status === "Rejected" ?
                                <div className="flex justify-end mt-2 gap-3">
                                    {
                                        !isPassportSaved &&
                                        <button className='flex justify-center items-center bg-white rounded text-[#000] px-4 py-1 font-semibold text-sm' onClick={updatePassport}>Resend
                                            {
                                                passportLoad && 
                                                <input
                                                    type="image"
                                                    src={Spin}
                                                    value="Loading"
                                                    className="w-4 md:w-6"
                                                />
                                            }
                                        
                                        </button>
                                    }

                                    {
                                        isPassportSaved &&
                                        <button className='bg-amber-600 rounded text-[#000] px-4 py-1 font-semibold text-sm' disabled={true}>Saved</button>
                                    }

                                    <button className='bg-red-600 rounded text-white px-4 py-1 font-semibold text-sm' disabled={true}>Rejected</button>
                                </div>  :
                                <>
                                        {
                                            docItem.status === "Pending" &&
                                            <button className='bg-blue-900 rounded text-white px-4 py-1 font-semibold text-sm' disabled={true}>Pending</button>
                                        }

                                        {
                                            docItem.status === "Accept" &&
                                            <div className="flex justify-end mt-2 gap-3">
                                                {
                                                    (!isPassportSaved && (moment(getToday).isAfter(moment(docItem.edit_date).format("YYYY-MM-DD")) === true)) &&
                                                    <button className='flex justify-center items-center bg-white rounded text-[#000] px-4 py-1 font-semibold text-sm' onClick={updatePassport}>Resend
                                                    {
                                                        passportLoad && 
                                                        <input
                                                            type="image"
                                                            src={Spin}
                                                            value="Loading"
                                                            className="w-4 md:w-6"
                                                        />
                                                    }
                                                    </button>
                                                }

                                                {
                                                    isPassportSaved &&
                                                    <button className='bg-amber-600 rounded text-[#000] px-4 py-1 font-semibold text-sm' disabled={true}>Saved</button>
                                                }

                                                <button className='bg-amber-500 rounded text-white px-4 py-1 font-semibold text-sm' disabled={true}>Accepted</button>
                                            </div>
                                        }
                                </>
                            }

        
                        </div>
                </div>
                    )
                }))
            }

        </div>
    </>
  )
}

export default ApprovedIndividualComponent