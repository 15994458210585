import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { setMessage } from "./message"
import AuthService from "../services/auth.service"

const user = JSON.parse(localStorage.getItem("user"))

export const register = createAsyncThunk(
  "auth/register",
  async (registerData, thunkAPI) => {
    try {
      const response = await AuthService.register(registerData)
      thunkAPI.dispatch(setMessage(response?.data.message))
      return response.data
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      thunkAPI.dispatch(setMessage(message))
      return thunkAPI.rejectWithValue()
    }
  }
)
export const login = createAsyncThunk(
  "auth/login",
  async (loginData, thunkAPI) => {
    try {
      const data = await AuthService.login(loginData)
      thunkAPI.dispatch(setMessage(data))
      return { user: data }
    } catch (error) {
      //   console.log(error)
      const err =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      thunkAPI.dispatch(setMessage(err))
      return thunkAPI.rejectWithValue(err)
    }
  }
)

export const logout = createAsyncThunk("auth/logout", async () => {
  await AuthService.logout()
})
const initialState = user
  ? { isLoggedIn: true, user }
  : { isLoggedIn: false, user: null }
const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: {
    [register.fulfilled]: (state, action) => {
      state.isLoggedIn = true
    },
    [register.rejected]: (state, action) => {
      state.isLoggedIn = false
    },
    [login.fulfilled]: (state, action) => {
      state.isLoggedIn = true
      state.user = action.payload.user
    },
    [login.rejected]: (state, action) => {
      state.isLoggedIn = false
      state.user = null
    },
    [logout.fulfilled]: (state, action) => {
      state.isLoggedIn = false
      state.user = null
    },
  },
})
const { reducer } = authSlice
export default reducer

// import AuthService here to make asynchronous requests and dispatch setMessage from the message state in the store to pass the success or error response data

// createAsyncThunk provides a thunk that will take care of the action types and dispatching the right actions based on the returned promise. Just basically the whole confusing redux flow slimfitted into a function in the toolkit.
