import React, { useState, useEffect, useMemo, Fragment } from 'react'
import { Menu, Dialog, Transition } from '@headlessui/react'
import { BASE_URL } from "../utils/constant"

import Header from '../Header'
import icon from '../../Images/icon.svg'
import { NavLink, useNavigate } from "react-router-dom";
import Navigation from './Navigation'
import { Multiselect } from 'multiselect-react-dropdown'
import Select from 'react-select'
import countryList from 'react-select-country-list'
import eyee from '../../Images/eyee.png'
import rightTick from '../../Images/right-tick.svg'
import wrongTick from '../../Images/wrong-tick.svg'
import topArrow from "../../Images/topArrow.svg"
import bottomArrow from "../../Images/bottomArrow.svg"
import RoadlersLogo from "../../Images/RoadlersCenter.svg"
import roadlersRR from '../../Images/RoadlersRR.svg'

import pen from '../../Images/pen.svg'
import cancel from '../../Images/cancel.svg'

import axios from "axios";
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useSelector } from 'react-redux'
import { useRef } from 'react'
import Dropdown from '../Common/Dropdown'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import MultiSelect from '../Common/MultiSelect'

// import { setServiceOption } from '../../slices/serviceOption'

// import { useDispatch } from 'react-redux'
import { GoogleMap, useJsApiLoader, Autocomplete } from '@react-google-maps/api';
import Resizer from "react-image-file-resizer";
import useAnalyticsEventTracker from '../Common/useAnalyticsEventTracker'
import { businessDocUploadEvent, providerRegisterEvent, providerRegisterPage } from '../Common/facebookPixelEvent'
import getCookie from '../utils/SecuredTokens'
// import { setserviceUser } from '../../slices/serviceUser';



function IndividualAccount() {
    const gaEventTracker = useAnalyticsEventTracker('Individual Account Registration');
    const { } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyDkMuW-hGvdRfUhsb2R9RFqA0Pq4Qxg4Cs",
        libraries: ['places'],
    })

    // SERVICE DASHBOARD LOADER
    const [dashboardLoad, setDashboardLoad] = useState(true)

    const [autocomplete, setAutocomplete] = useState(null)
    const [addressAuto, setAddressAuto] = useState('')

    const onPlaceChanged = () => {
        if(autocomplete !== null) {
            const places = autocomplete.getPlace().formatted_address
            if(places) {
                setAddressAuto(places)
            }else {
                setAddressAuto(autocomplete.getPlace().name)
            }
            
        }
    }

        // RESIZER
        const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
            file,
            300,
            400,
            "JPEG",
            80,
            0,
            (uri) => {
                resolve(uri);
            },
            "base64"
            );
        });

    const dataURIToBlob = (dataURI) => {
        const splitDataURI = dataURI.split(",");
        const byteString =
            splitDataURI[0].indexOf("base64") >= 0
            ? atob(splitDataURI[1])
            : decodeURI(splitDataURI[1]);
        const mimeString = splitDataURI[0].split(":")[1].split(";")[0];
        const ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);
        return new Blob([ia], { type: mimeString });
    };

    // Facebook Pixel
    useEffect(() => {
        providerRegisterPage()
    }, [])

    const [addressError, setAddressError] = useState('')

    // const dispatch = useDispatch()
    let navigate = useNavigate();
    const [load, setLoad] = useState("")

    const [selectedFile, setSelectedFile] = useState();

	const [isSelected, setIsSelected] = useState(false);

    const [license, setLicense] = useState()
    const [businessCert, setBusinessCert] = useState()
    const [licenseSelected, setLicenseSelected] = useState(false)
    const [certificate, setcertificate] = useState(false)
    const [hover, setHover] = useState(false)

    // Means of Identification
    const [selectedId, setSelectedId] = useState('')

    // Category Choice
    const [selectedCategory, setSelectedCategory] = useState('')
    const categoryValue = selectedCategory;

    // let token = JSON.parse(localStorage.getItem("userToken"))
    let id = JSON.parse(localStorage.getItem("userId"))

    let userData = getCookie("user");
    let userObject = JSON.parse(userData) 
    let token = userObject?.token
    // let id = userObject?.id
   

    let [username, setUsername] = useState('')
    const [first_name, setFirstName] = useState('')
    const [last_name, setLastName] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [showSection, setShowSection] = useState(false)
    const [showBusinessSection, setShowBusinessSection] = useState(false)
    const [showDobForm, setShowDobForm] = useState(true)
    const [showCompanyForm, setShowCompanyForm] = useState(false)
    const [showGuarantorOne, setShowGuarantorOne] = useState(false)
    const [showGuarantorTwo, setShowGuarantorTwo] = useState(false)


    const [address, setAddress] = useState('');
    const [identification, setIdentification] = useState('');
    const [idNumber, setIdNumber] = useState('');
    const [phone, setPhone] = useState('');
    const [category, setCategory] = useState('');
    const [birthday, setBirthday] = useState('');

    const [availability, setAvailability] = useState([]);
    const [state, setState] = useState('');
    const [country, setCountry] = useState('');
    const [work, setWork] = useState('');

    const [addressTick, setAddressTick] = useState(false)
    const [idTick, setIdTick] = useState(false)
    const [companyTick, setCompanyTick] = useState(false)

    const [showAvailability, setShowAvailability] = useState(true)
    const [bg, setBg] = useState(false)
    const [changePasswordModalOpen, setChangePasswordModalOpen] = useState(false)
    const [currentPassword, setCurrentPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] =  useState('')
    const [categorySelected, setCategorySelected] = useState('')

    const [providerCategory, setProviderCategory] = useState('')

    // MANAGING DATA TO PREFIL AFTER REGISTERING
    const [receivedAddress, setReceivedAddress] = useState('')
    const [receivedAvailability, setReceivedAvailability] = useState('')
    const [receivedBirth, setReceivedBirth] = useState('')
    const [receivedCategory, setReceivedCategory] = useState('')
    const [receivedCountry, setReceivedCountry] = useState('')
    const [receivedCompany, setReceivedCompany] = useState('')
    const [receivedIdentification, setReceivedIdentification ] = useState('')
    const [receivedIdNo, setReceivedIdNo] = useState('')
    const [receivedService, setReceivedService] = useState('')
    const [receivedState, setReceivedState] = useState('')

    const [registeredProvider, setRegisteredProvider] = useState(false)

    function closeModal() {
        setBg(false)
        setChangePasswordModalOpen(false)
    }

    const openPassowrdModal = (e) => {
        e.preventDefault()
        setBg(true)
        setChangePasswordModalOpen(true)
        gaEventTracker('Open change password modal')
    }


    // CHANGE PASSWORD FUNCTION
    const changePasswordFunc = (e) => {
        e.preventDefault()

        const formData = new FormData();

        formData.append('current_password', currentPassword)
        formData.append('new_password', newPassword)
        formData.append('c_password', confirmPassword)


        axios({
            method: 'post',
            url: `${BASE_URL}/v1/change-password`,
            data: formData,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            }
        }).then((response) => {
            setBg(false)
            setChangePasswordModalOpen(false)
        }).catch((error) => {
            let notify = () => { toast.error((error.response.data.message), {
                toastClassName: 'error'
            } ) };
            notify()
        })

    }

    const fileInputRef = useRef()
    const businessRef = useRef(null)


    const passportRef = useRef()
    // Country Value
    const [value, setValue] = useState([])
    const optionsCountry = useMemo(() => countryList().getData(), [])
  
    const changeHandlerCountry = value => {
      setValue(value)
    }

    // HANDLE IDCHANGE 
    const handleIdChange = (e) => {
        formikProfile.handleChange(e)

        if(e.target.value) {
            setIdTick(true)
        }else {
            setIdTick(false)
        }
    }

    // HANDLE ADDRESS CHANGE
    const handleAddressChange = (e) => {

        if(e.target.value) {
            setAddressTick(true)
        }else {
            setAddressTick(false)
        }
    }

    // HANDLE COMPANY CHANGE
    const handleCompanyChange = (e) => {
        formikProfile.handleChange(e)

        if(e.target.value) {
            setCompanyTick(true)
        }else {
            setCompanyTick(false)
        }
    }

    const getUserName = async () => {
        await axios.get(`${BASE_URL}/v1/show-username/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            }
        }).then((response) => {
            const alluserName = response.data;
            const username = alluserName.username;
            setProviderCategory(alluserName.category)
            setReceivedAddress(alluserName.address)
            setReceivedIdentification(alluserName.identification)
            setReceivedIdNo(alluserName.id_number)
            setReceivedService(alluserName.service)
            setReceivedCompany(alluserName.company_name)
            setReceivedState(alluserName.state)
            setReceivedCountry(alluserName.country)
            setReceivedBirth(alluserName.dob)
            setReceivedAvailability(alluserName.availability)
            const fullname = response.data.name;
            const nameSplit = fullname.split(/(\s+)/);
            const firstName = nameSplit[0]
            const phone = response.data.phone_number;
            setFirstName(firstName)
            const lastName = nameSplit[2]
            setLastName(lastName)
            setPhoneNumber(phone)
            setUsername(username)
            setDashboardLoad(false)
        });
    };
    useEffect(() => {
        getUserName();
    }, []);

    // Checking if user selected business or individual
    const checkCategory = () => {
        if(providerCategory == 'business') {
            setCategorySelected('Business')
            setShowAvailability(false)
            setShowDobForm(false)
            setShowCompanyForm(true)
            
        }else {
            setCategorySelected('Individual')
            setShowAvailability(true)
            setShowDobForm(true)
            setShowCompanyForm(false)  
        }
    }

    useEffect(() => {
        checkCategory()
    }, [last_name])

    // HANDLE BACkGROUND PASSPORT FILE UPLOAD
    const changeHandler = (event) => {
		setSelectedFile(event.target.files[0]);
		setIsSelected(true);
	};

    // HANDLE DRIVER LICENSE FILE UPLOAD
    const changeLicense =async (e) => {
        const file = e.target.files[0]
        const image = await resizeFile(file);
        const newFile = dataURIToBlob(image);
        const formData = new FormData();
        formData.append("image", newFile);
        setLicense(newFile);
        setLicenseSelected(true)
    }

    // const changeCertificate = (e) => {
    //     setBusinessCert(e.target.files[0])
    //     setcertificate(true)
    // }

    // FOR MULTISELECT
    const convertArray = (array, property) => {
        var result = []
        for(var i = 0; i<array.length; i+=1) {
            result.push(array[i][property])
        }
        setUpdateAvailability(result)
    }

    // SET SERVICE USER TO DISPATCH
    const handleServiceUser = (e) => {
        setWork(e.target.value)
        // dispatch(setServiceOption(work))
        // dispatch(setserviceUser(work))

        if (e.target.value === 'vehicle') {
            setShowAvailability(false)
        }else {
            setShowAvailability(true)
        }
    }

    localStorage.setItem("userService", JSON.stringify(work))

    let userService1 = JSON.parse(localStorage.getItem("userService"))




    const [availabilitySelect, setAvailabilitySelect] = useState([])
    const [updateAvialablity, setUpdateAvailability] = useState([])
    useEffect(() => {
        setUpdateAvailability([])
        convertArray(availabilitySelect, 'value')
        // availabilitySelect.map((item) => {
            // setUpdateAvailability(item['value'])
            // setUpdateAvailability( updateAvialablity.push(item.value))
        // })
    }, [availabilitySelect.length, updateAvialablity.length])


    // AVAILABILITY SERVICE OPTIONS
    const availabilityOptionData = [
        {value: 'Night Driver', label: 'Night Driver', id: 1},
        {value: 'Day Driver', label: 'Day Driver', id: 2},
        {value: 'Full-Time Driver', label: 'Full-Time Driver', id: 3},
        {value: 'Daily Driver', label: 'Daily Driver', id: 4}
    ]

    const [optionsValue] = useState(availabilityOptionData)

    
    // ARRAY OF ID LIST
    const idArray = [
        {value: 'bvn', label: 'BVN'}, 
        {value: 'nin', label: 'NIN'},
        {value: 'passport', label: 'PASSPORT'},
        {value: 'license', label: 'LICENSE'}
    ]


    // ARRAY OF CATEGORY LIST
    const categoryArray = [
        {value: 'individual', label: 'Individual'},
        {value: 'business', label: 'Business'}
    ]

    // GET YEARS VALUE
    const years = require('ye-ars')

    const options = {
        initialYear: 2022,
        count: 5
    }

    const result = years(options)

    const passportClick = () => {
        passportRef.current.focus()
    }

    const phoneRegExp = /^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/

    // const onSelect = (selectedList, selectedItem) => {
    //     availabilityOptions.push(selectedItem);
    // }
    
    // const onRemove = (selectedList, removedItem) => {
        
    // }

    // HANDLE COMPANY'S DETAILS WHEN YOU SUBMIT
    const handleCompanyDetailSubmit = (e) => {
        e.preventDefault()

        navigate('/approved-service-company')
    }

    // HANDLE DRIVERS DOCUMENT SUBMIT
    const handleDriversDocument = (e) => {
        e.preventDefault()

        setShowGuarantorOne(true)
    }

    // HANDLE SUBMIT AND FINISH
    const handleFinishSubmit = (e) => {
        e.preventDefault()

        if(work === 'driver') {
            navigate('/pending-notice')
        }else {
            navigate('/driver-car-registration')
        }
    }



    const handleSubmit = (e) => {
        e.preventDefault()

        let formData = new FormData();

        formData.append('id', id)
        formData.append('firstname', first_name)
        formData.append('lastname', last_name)
        formData.append('address', address)
        formData.append('identification', identification)
        formData.append('id_number', idNumber)
        formData.append('phone_number', phoneNumber)
        formData.append('category', category)
        formData.append('dob', birthday)
        formData.append('service', work)
        formData.append('availability', availability)
        formData.append('state', state)
        formData.append('country', country)
    }

    // USING FORMIK TO HANDLE DATA INPUT 

    // const numRegExp = "^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$"
    const formikProfile = useFormik({
        initialValues: {
            id_number: '',
            dob: '',
            availability: '',
            state: '',
            company_name: ''
        },
        validationSchema: Yup.object({
            id_number: Yup.string()
                .required("This is required"),
            dob: Yup.string(),
                // .required("This is required"),
            state: Yup.string()
                .required("This is required"),  
            company_name: Yup.string()
                // .required("This is required")
        }),
        onSubmit: (values) => {
            if(addressAuto === '') {
                setAddressError('This is required')

                setTimeout(() => {
                    setAddressError('')
                }, 5000)
            } else{
                if(providerCategory === 'business') {
                    axios({
                        method: 'post',
                        url: `${BASE_URL}/v1/update-provider`,
                        data: {...values, address: `${addressAuto}` , identification: `${selectedId}`, category: `${providerCategory}`, phone_number: `${phoneNumber}`, firstname: `${first_name}`, lastname: `${last_name}`, service: `${work}`, id: `${id}`, availability: `${updateAvialablity}`, country: `${value.label}`  
                    },
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                            "Access-Control-Allow-Origin": "*",
                        }
                    })
                    .then(function (response) {
                        gaEventTracker('Save Account Btn')
                        providerRegisterEvent()
                        const customer = response.data;
                        // TEMPORARY CASE WILL CHANGE DURING RESTRUCTURE
                        localStorage.setItem("userIdProvider", JSON.stringify(customer.data.user_id))
                        localStorage.setItem("businessId", JSON.stringify(customer.data.id))
                        setShowSection(true)
                        setRegisteredProvider(true)
                        setShowBusinessSection(true)
                        businessRef.current?.scrollIntoView({behavior: 'smooth'});
                    })
                    .catch(function (error) {
                        let notify = () => { toast.error((error.response.data.message), {
                            toastClassName: 'error'
                        } ) };
                        notify()
                    });
                }else {
                    axios({
                        method: 'post',
                        url: `${BASE_URL}/v1/update-provider`,
                        data: {...values,address: `${addressAuto}`, identification: `${selectedId}`, category: `${providerCategory}`, phone_number: `${phoneNumber}`, firstname: `${first_name}`, lastname: `${last_name}`, service: `${work}`, id: `${id}`, availability: `${updateAvialablity}`, country: `${value.label}`  },
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                            "Access-Control-Allow-Origin": "*",
                        }
                    })
                    .then(function (response) {
                        gaEventTracker('Save Account Btn')
                        providerRegisterEvent()
                        const customer = response.data;
                        // TEMPORARY CASE WILL CHANGE DURING RESTRUCTURE
                        localStorage.setItem("userIdProvider", JSON.stringify(customer.data.user_id))
                        localStorage.setItem("driverId", JSON.stringify(customer.data.id))
                        setRegisteredProvider(true)
    
                        if(work === 'vehicle'){
                            navigate('/driver-car-registration')
                        }else {
                            navigate('/individual-account-registration-2')
                        }
                    })
                    .catch(function (error) {
                        let notify = () => { toast.error((error.response.data.message), {
                            toastClassName: 'error'
                        } ) };
                        notify()
                    });
                }
            }
        }
    })



    // MAKING USE OF DRIVER ID
    let driverId = JSON.parse(localStorage.getItem("driverId"))
    let businessId = JSON.parse(localStorage.getItem("businessId"))


    // HANDLE SUBMIT BUSINESS FILE
	const handleSubmission = (e) => {
        e.preventDefault()

        let id = JSON.parse(localStorage.getItem("userId"))

        const formData = new FormData();
        const formDatas = new FormData();
        formData.append('file', license);
        formData.append('business_id', businessId)
        formData.append('doc_type', 'company_logo')
        formData.append('user_id', id)

        formDatas.append('file', selectedFile);
        formDatas.append('doc_type', "business_certificate")
        formDatas.append('business_id', businessId)
        formDatas.append('user_id', id)

        if(selectedFile) {
            const certificatePost = axios({
                method: 'post',
                url: `${BASE_URL}/v1/business-certificate`,
                data: formDatas,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    "Access-Control-Allow-Origin": "*",
                }
            })
    
            const businessLogoPost = axios({
                method: 'post',
                url: `${BASE_URL}/v1/company-logo`,
                data: formData,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    "Access-Control-Allow-Origin": "*",
                }
            })
    
            axios.all([certificatePost, businessLogoPost]).then(axios.spread((...responses) => {
                const responseOne = responses[0]
                const responseTwo = responses[1]
                // use/access the results 
                gaEventTracker('Submit Business Doc')
                businessDocUploadEvent()
                navigate('/approved-service-company')
              })).catch(error => {
                let notify = () => { toast.error((error.response.data.message), {
                    toastClassName: 'error'
                } ) };
                notify()
              })
        }else {
            let notify = () => { toast.error(('Please upload your company certificate'), {
                toastClassName: 'error'
            } ) };
            notify()
        }

        
	};

    return(
    dashboardLoad ? 
    <div className='h-[100vh] w-full flex justify-center items-center'>
        <div class="lds-ring">
            <img src={roadlersRR}  alt="roadlers logo" className='embed-logo'/>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
    :
    <div className={bg === true ? " blur-sm contrast-50 backdrop-brightness-50" : "w-full account-reg-overall "}>
        <ToastContainer />
        <Header user='provider' />
        <Transition appear show={changePasswordModalOpen} as={Fragment}>
            <Dialog
                as="div"
                className="fixed flex items-center justify-center w-full px-6 mt-8  inset-0 z-10 "
                onClose={closeModal}
            >
                <div className="min-h-screen px-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-25 bg-white"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-25"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="inline-block h-screen align-middle"
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className="w-12/12 inline-block  max-w-md pt-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-project shadow-xl rounded-md">
                            <Dialog.Title
                                as="h3"
                                className="text-lg bg-project flex justify-center font-medium leading-6 text-gray-900"
                            >
                                <img src={RoadlersLogo} alt="Roadlers" className='w-[20px]' />
                            </Dialog.Title>
                            <div className="bg-project p-6 px-12">
                                        <form>
                                            <div className="mt-3">
                                                <label htmlFor="Current Password" className='text-xx md:text-sm text-[#FFA100] ml-5'>Current Password <span className='text-red-600'>*</span> </label>
                                                <div className="flex items-center text-sm mt-1 px-2 w-full rounded-lg border border-white  bg-[#000] text-white">
                                                    <input
                                                        type="password"
                                                        placeholder='Current password'
                                                        className='p-2 md:text-sm text-xs w-full bg-transparent placeholder:text-xs placeholder:text-white     focus:outline-none text-white'
                                                        value={currentPassword}
                                                        onChange={e => setCurrentPassword(e.target.value)}
                                                        autoComplete='current-password'
                                                    />
                                                </div>
                                            </div>


                                            <div className="mt-3">
                                                <label htmlFor="" className='text-xx md:text-sm text-[#FFA100] ml-5'>New Password <span className='text-red-600'>*</span> </label>
                                                <div className="flex items-center text-sm mt-1 px-2 w-full rounded-lg border border-white bg-[#000]  text-white">
                                                    <input
                                                        type="password"
                                                        className='p-2 w-full md:text-sm text-xs bg-transparent placeholder:text-xs placeholder:text-white  focus:outline-none text-white'
                                                        autoComplete='new-password'
                                                        placeholder='New password'
                                                        value={newPassword}
                                                        onChange={e => setNewPassword(e.target.value)}
                                                    />
                                                </div>
                                            </div>

                                            <div className="mt-3">
                                                <label htmlFor="" className='text-xx md:text-sm text-[#FFA100] ml-5'>Confirm Password <span className='text-red-600'>*</span> </label>
                                                <div className="flex items-center text-sm mt-1 px-2 w-full rounded-lg border border-white bg-[#000]  text-white">
                                                    <input
                                                        type="password"
                                                        className='p-2 w-full md:text-sm text-xs bg-transparent placeholder:text-xs placeholder:text-white  focus:outline-none text-white'
                                                        autoComplete='new-password'
                                                        placeholder='Confirm Password'
                                                        value={confirmPassword}
                                                        onChange={e => setConfirmPassword(e.target.value)}
                                                    />
                                                </div>
                                            </div>

                                            <div className="mt-6 w-full flex items-center justify-center">
                                                <button
                                                    type="submit"
                                                    className="inline-flex justify-center px-8 py-2 text-sm font-bold text-black bg-amber-500 border border-transparent rounded-md  focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                                                    onClick={e => {
                                                        e.preventDefault()
                                                        changePasswordFunc(e)
                                                        gaEventTracker('Change Password Btn')
                                                    }}
                                                >
                                                    Change Password
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>

        <main className='flex md:px-12  px-12 flex-col items-center account-reg-wrapper'>
            <div className='account-reg-subwrapper h-100 md:w-8/12 w-full md:px-20 pb-20 pt-12 md:pt-36'>
                <div className="flex items-center gap-[40px] w-full profile-header">
                    <div className="flex items-center gap-[40px] profile-subheader">
                        <h2 className='text-white font-bold text-2xl '>Complete Your Profile  </h2>
                        <p className='text-white text-base'>You are almost done</p>
                    </div>
                    <img src={pen} alt="" className='w-6' />
                </div>

                <main className='w-full'>
                    <div className="flex w-full items-center">
                        <div className="pb-20 w-full">
                            {/* FORMIKPROFILE HANDLER */}
                            <form className='w-full mt-4' onSubmit={formikProfile.handleSubmit} validator={() => ({})}>
                                <div className="flex w-full">
                                    <div className="flex my-6 md:my-12 w-full gap-5 profile-name-container">
                                        <div className="flex flex-col w-[50%]">
                                            <label htmlFor="First name" className='text-xs text-[#FFA100] font-normal'>First Name <span className="text-red-600">*</span></label>
                                            <input
                                                type="text"
                                                value={first_name}
                                                className='text-base mt-2 bg-[#1D1C1C] rounded-xl border border-white px-3 py-3 focus:outline-none text-white'
                                            />
                                        </div>
                                        <div className="flex flex-col w-[50%]">
                                            <label htmlFor="Last Name" className='text-xs text-[#FFA100] font-normal'>Last Name <span className="text-red-600">*</span></label>
                                            <input
                                                type="text"
                                                value={last_name}
                                                className='text-base mt-2 bg-[#1D1C1C] rounded-xl border border-white px-3 py-3 focus:outline-none text-white' />

                                        </div>
                                    </div>
                                </div>


                                <div className="flex">
                                    <div className="flex flex-col flex-[100%]">
                                        <label htmlFor="Address" className='text-xs text-[#FFA100] font-normal'>Address <span className="text-red-600">*</span></label>
                                        
                                        <Autocomplete className='autcomplete-style'
                                            onPlaceChanged={onPlaceChanged}
                                            onLoad={(value) => setAutocomplete(value)}
                                        >
                                            <input
                                                name="address"
                                                type="text"
                                                placeholder='Type here.....' className='text-base mt-2 bg-[#1D1C1C] rounded-xl border border-white px-3 py-3 focus:outline-none text-white w-full'
                                                value={addressAuto}
                                                onChange={e => setAddressAuto(e.target.value)}
                                            />
                                            
                                        </Autocomplete>
                                        <p className='text-red-600'>{addressError}</p>
                                    </div>
                                </div>

                                <div className="flex">
                                    <div className="flex flex-[100%] items-center justify-between gap-5  my-6 md:my-12 identification-wrapper register-provider">
                                        <div className='flex flex-col w-[50%] means-of-id'>
                                            <label htmlFor="Category:" className='text-xs text-[#FFA100] font-normal'>Means of Identification <span className="text-red-600">*</span></label>

                                            <div className="text-base mt-2 bg-[#1D1C1C] w-full rounded-xl border border-white pl-5 pr-0 py-3  text-white relative">
                                                <Dropdown 
                                                    holder= {receivedIdentification || 'Select ID'}
                                                    optionArray= {idArray}
                                                    setSelectedOption= {setSelectedId}
                                                    selectedOption= {selectedId}
                                                />
                                            </div>                                    
                                        </div>

                                        <div className='flex flex-col w-[50%] id-no-input'>
                                            <label htmlFor="ID Number " className='text-xs text-[#FFA100] font-normal'>ID Number <span className="text-red-600">*</span></label>

                                            <input
                                                name="id_number"
                                                type="text"
                                                placeholder='Type here.....' className='text-base mt-2 bg-[#1D1C1C] rounded-xl border border-white px-3 py-3 focus:outline-none text-white'
                                                onBlur={formikProfile.handleBlur}
                                                onChange={handleIdChange}
                                                defaultValue={receivedIdNo || formikProfile.values.id_number}
                                            />
                                            {formikProfile.touched.id_number && formikProfile.errors.id_number ? (
                                            <p className="text-red-600 text-left text-xs w-full mt-1 ">
                                                {formikProfile.errors.id_number}
                                            </p>
                                            ) : null}
                                        </div>
                                    </div>
                                    
                                </div>


                                <div className="flex">
                                    <div className="flex flex-[100%] flex-col my-6 md:my-12 md:mb-0 ">
                                        <label htmlFor="Phone Number" className='text-xs text-[#FFA100] font-normal'>Phone Number <span className="text-red-600">*</span></label>

                                        <input
                                            name="phoneNumber"
                                            type="phone"
                                            value={phoneNumber}
                                            placeholder='Type here.....'
                                            className='text-base mt-2 bg-[#1D1C1C] rounded-xl border border-white px-3 py-3 focus:outline-none text-white'
                                        />
                                    </div>
                                </div>


                                <div className="flex items-center justify-between gap-5 w-full">
                                    <div className="flex flex-col w-1/2 ">
                                        <label htmlFor="Category:" className='text-xs text-[#FFA100] font-normal'>Category <span className="text-red-600">*</span></label>

                                        <input
                                                type="text"
                                                value={categorySelected}
                                                className='text-base mt-2 bg-[#1D1C1C] rounded-xl border border-white px-3 py-3 focus:outline-none text-white normal-case' />
                                        
                                    </div>

                                    <div className="flex flex-col my-6 md:my-12 w-1/2 ">
                                        <label htmlFor="Service Offering:" className='text-xs text-[#FFA100] font-normal'>Service Offering <span className="text-red-600">*</span></label>
                                        <div className="text-base mt-2 bg-[#1D1C1C] w-full rounded-xl border border-white pl-5 pr-0 py-3  text-white">
                                            <select
                                                defaultValue={receivedService || work}
                                                onChange={handleServiceUser}
                                                className='bg-transparent w-11/12 focus:outline-none text-white'
                                            >
                                                <option className=' text-black font-bold'>Select Service</option>
                                                <option value="driver" className=' text-black font-bold'>Only Driver</option>
                                                <option value="vehicle" className=' text-black font-bold'>Only Vehicle</option>
                                                <option value="vehicle_driver" className=' text-black font-bold'> Driver & Vehicle</option>
                                                {/* <option value='car only' className=' text-black font-bold'>Car Only</option>
                                                <option value='driver and car' className='text-black font-bold'>Driver & Car</option> */}
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                {
                                    showDobForm &&
                                    (<div className="flex flex-col my-6 md:my-12 md:mt-0 w-full ">
                                    <label htmlFor="Birthday date:" className='text-xs text-[#FFA100] font-normal'>Birthday date. <span className="text-red-600">*</span></label>
                                    <div className="rounded-xl border bg-[#1D1C1C] border-white mt-2 items-center flex px-2">
                                        <input
                                            name="dob"
                                            type="date"
                                            className='w-11/12 text-base rounded-xl  p-3 focus:outline-none text-white'
                                            onBlur={formikProfile.handleBlur}
                                            onChange={formikProfile.handleChange}
                                            defaultValue={receivedBirth || formikProfile.values.dob}   
                                        />
                                        <img src={icon} alt="" className='w-10 md:w-12' />
                                    </div>
                                    {/* {formikProfile.touched.dob && formikProfile.errors.dob ? (
                                        <p className="text-red-600 text-left text-xs w-full mt-1 mb-4">
                                            {formikProfile.errors.dob}
                                        </p>
                                    ) : null} */}

                                    </div>)
                                }

                                {/* IF SERRVICE IS VEHICLE ONLY DON'T SHOW THIS */}

                                {
                                    showAvailability && (
                                        <div className="flex flex-col my-6 md:my-12 md:mt-0 w-full">
                                            <label htmlFor="Availability:" className='text-xs text-[#FFA100] font-normal pb-2'>Availability <span className="text-red-600">*</span></label>
        
                                            {/* <div className="text-base mt-2 bg-transparent w-full rounded-xl border border-white pl-5 pr-0 py-3  text-white"> */}
        
                                                <Multiselect 
                                                    customCloseIcon={<img src={cancel} className="cursor-pointer"/>}
                                                    options={optionsValue} 
                                                    displayValue="label" 
                                                    onRemove={e => (setAvailabilitySelect(e))}
                                                    onSelect={e => (setAvailabilitySelect(e))}
        
                                                    placeholder={"Tick all the ones that apply to you"}
                                                    hidePlaceholder={true}
                                                    style={{
                                                    chips: {
                                                        background: '#fff',
                                                        color: '#000'
                                                    },
                                                    multiselectContainer: {
                                                        color: '#000',
                                                        cursor: 'pointer',
                                                        background: '#1D1C1C'
                                                    },
                                                    searchBox: {
                                                        border: 'solid 1px #fff',
                                                        'border-radius': '13px',
                                                        'padding': '10px'
                                                    },
                                                    closeIcon: {
                                                        'filter': 'grayscale(100%)'
                                                    }
                                                    }}
                                                />
                                            {/* </div> */}
                                        </div>
                                    )
                                }

                                {/* IF BUSINESS IS CHOSEN SHOW THIS COMPANY INPUT */}

                                {
                                    showCompanyForm && 
                                    (
                                    <div className="flex" ref={businessRef}>
                                        <div className="flex flex-[100%] flex-col">
                                            <label htmlFor="Address" className='text-xs text-[#FFA100] font-normal'>Company Name <span className="text-red-600">*</span></label>
        
                                            <input
                                                name="company_name"
                                                type="text"
                                                placeholder='Type here.....' className='text-base mt-2 mb-4 bg-[#1D1C1C] rounded-xl border border-white px-3 py-3 focus:outline-none text-white'
                                                onBlur={formikProfile.handleBlur}
                                                onChange={handleCompanyChange}
                                                defaultValue={receivedCompany || formikProfile.values.company_name}
                                            />
                                            {/* {formikProfile.touched.company_name && formikProfile.errors.company_name ? (
                                                <p className="text-red-600 text-left text-xs w-full mt-1 mb-4">
                                                    {formikProfile.errors.company_name}
                                                </p>
                                            ) : null} */}
                                        </div>
                                        
                                    </div>
                                    )
                                }


                                <div className="flex flex-[100%] my-6 md:my-12 w-full gap-5">
                                    <div className="flex flex-col w-1/2">
                                        <label htmlFor="State" className='text-xs text-[#FFA100] font-normal'>State <span className="text-red-600">*</span></label>
                                        <input
                                            name="state"
                                            type="text"
                                            placeholder='Type here.....'
                                            className='text-base mt-2 bg-[#1D1C1C] rounded-xl border border-white px-3 py-3 focus:outline-none text-white'
                                            onBlur={formikProfile.handleBlur}
                                            onChange={formikProfile.handleChange}
                                            defaultValue={receivedState || formikProfile.values.state}
                                        />
                                    {formikProfile.touched.state && formikProfile.errors.state ? (
                                        <p className="text-red-600 text-left text-xs w-full mt-1 mb-4">
                                            {formikProfile.errors.state}
                                        </p>
                                    ) : null}
                                    </div>
                                    <div className="flex flex-col w-1/2">
                                        <label htmlFor="Country" className='text-xs text-[#FFA100] font-normal'>Country <span className="text-red-600">*</span></label>

                                        <Select options={optionsCountry} value={value} onChange={changeHandlerCountry} />
                                    </div>  
                                </div>

                                <div className="flex gap-2 mt-10 w-full register-action-container">
                                    <button className='bg-amber-500 font-semibold text-black p-2 rounded-md md:w-2/4 w-3/4'
                                        onClick={openPassowrdModal}
                                    >
                                        Change Password
                                    </button>    
                                    {
                                        registeredProvider ? 
                                        <div
                                            className='bg-[grey] text-center font-semibold text-black p-2 rounded-md w-1/4'>Saved
                                        </div> :
                                        <button
                                            // onClick={handleSubmit}
                                            type='submit'
                                            className='bg-white font-semibold text-black p-2 rounded-md w-1/4'>Save
                                        </button>
                                    }

                                </div>

                            {/* DOCUMENTS NEEDED TO UPLOADED */}
                            </form>

                        </div>
                    </div>

                    {
                                showBusinessSection && 
                                (
                                    <div className={' md:pr-0 w-full pt-0 md:pt-32'} id="business-section" >
                                        {/* <div className="md:flex justify-end hidden"><img src={eyee} alt="" className='w-10' /></div> */}
                        
                        
                                        <div className="flex justify-between relative items-center w-full md:w-4/5">
                                            <h2 className='text-white font-bold text-lg'>Business Documents</h2>

                                            {
                                                hover && 
                                                <div className="business-icon-wrapper ">
                                                    <p>
                                                    You can either upload images of documents or scanned pdfs
                                                    </p>
                                                </div>
                                            }

                                            <img src={icon} alt="" className='cursor-pointer' 
                                                onMouseOver={()=>setHover(true)} 
                                                onMouseOut={()=>setHover(false)}
                                            />
                                        </div>

                                        {/* COMPANY DOCUMENT FORM */}
                                        <form>

                                            <div className="mt-6">
                                                <div className="flex justify-between border bg-[#1D1C1C] border-white p-2 md:p-3 items-center">
                                                    {
                                                        isSelected && selectedFile?.name ?(
                                                            <p className='text-white md:text-base text-xs font-normal'>{selectedFile?.name}</p>
                                                        ) : (
                                                            <p className='text-white md:text-base text-xs font-normal'>Company Business Certificate<span className="text-red-600">*</span> </p>
                                                        )
                                                    }


                                                    {/* use useRef to trigger this input field for Document upload */}
                                                    <input
                                                        type="file"
                                                        style={{ display: 'none' }}
                                                        ref={passportRef}
                                                        accept="image/jpeg,image/jpg,application/pdf"

                                                        onChange={changeHandler} 
                                                    />


                                                    {
                                                        ( selectedFile?.name) ? (
                                                            <button
                                                                onClick={(event) => {
                                                                    event.preventDefault()
                                                                    passportRef.current.click()
                                                                }}
                                                                className='border-theme-color px-3 py-1 md:text-base md:px-2 md:py-0 text-xs text-white'>
                                                                    Change
                                                            </button>

                                                        ) : (
                                                            <button
                                                                onClick={(event) => {
                                                                    event.preventDefault()
                                                                    passportRef.current.click()
                                                                }}
                                                                className='border-theme-color px-3 py-1 md:text-base md:px-2 md:py-0 text-xs text-white'>
                                                                    Upload
                                                            </button>
                                                        )
                                                    }

                                                </div>
                                            </div>

                                            <div className="">
                                                <div className="flex justify-between border bg-[#1D1C1C] border-white p-2 md:p-3 items-center">
                                                    {
                                                        licenseSelected && license?.name ?(
                                                            <p className='text-white md:text-base text-xs font-normal'>{license?.name}</p>
                                                        ) : (
                                                            <p className='text-white md:text-base text-xs font-normal'>Company Logo <span className="text-[#FFA100]">{'(Optional)'}</span> </p>
                                                        )
                                                    }


                                                    {/* use useRef to trigger this input field for proof of ownership upload */}
                                                    <input
                                                        type="file"
                                                        style={{ display: 'none' }}
                                                        ref={fileInputRef}
                                                        accept="image/jpeg,image/jpg,application/pdf"

                                                        onChange={changeLicense} 
                                                    />


                                                    {
                                                        ( license?.name) ? (
                                                            <button
                                                                onClick={(event) => {
                                                                    event.preventDefault()
                                                                    fileInputRef.current.click()
                                                                }}
                                                                className='border-theme-color px-3 py-1 md:text-base md:px-2 md:py-0 text-xs text-white'>
                                                                    Change
                                                            </button>

                                                        ) : (
                                                            <button
                                                                onClick={(event) => {
                                                                    event.preventDefault()
                                                                    fileInputRef.current.click()
                                                                }}
                                                                className='border-theme-color px-3 py-1 md:text-base md:px-2 md:py-0 text-xs text-white'>
                                                                    Upload
                                                            </button>
                                                        )
                                                    }

                                                </div>
                                            </div>
                            

                                            <div className="bg-white md:w-1/4 w-2/4 my-8 mb-10 p-2 text-center rounded-md font-bold text-center cursor-pointer"
                                                onClick={handleSubmission}
                                            >
                                                Next
                                            </div>
                                        </form>        
                                    </div>                    
                                )
                            }
                </main>
            </div>
        </main>
    </div>);
}

export default IndividualAccount;