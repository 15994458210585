

import React from 'react'
import { Navigate, Outlet } from 'react-router-dom';


import getCookie from '../Components/utils/SecuredTokens';
const ProtectedRoute = ({ auth, component: Component, ...rest }) => {
    
    // const auth = null; // determine if authorized, from context or however you're doing it
    let googleUser = JSON.parse(localStorage.getItem("googleClicked"))
   

    let user = getCookie("user");

    if(user){
        let userObject = JSON.parse(user);
        let userRole = userObject?.user_role

        if(userRole==='customer'|| googleUser ==='true'){
            return <Outlet/>
        }
        else{
            return <Navigate to="/login" />
        }
       
    }
    else{
        return <Navigate to="/login" />

    }


    // If authorized, return an outlet that will render child elements
    // If not, return element that will navigate to login page

}

export default ProtectedRoute
