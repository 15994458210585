import React from 'react'
import Header from "./Header"
import { NavLink, useNavigate } from "react-router-dom"


function PrivacyPolicy() {
    return (
        <div>
            <Header user="customer" />

            <div className="fixed bg-black md:p-6 p-4 md:w-3/12 w-7/12 md:mt-20 md:mb-20 mt-16 mb-24 md:ml-16 ml-8 rounded-md flex justify-center items-center">
                <h4 className="text-white md:text-2xl text-base  font-bold">Privacy Policy</h4>


            </div>

            <div className='flex gap-10 md:ml-32 ml-8 md:mr-16 mr-8 pt-48 pb-48'>
                < div className='w-2 bg-[#FFA100] min-h-screen ' >

                </div>

                <div className="flex flex-col gap-3 md:ml-32 ml-8 md:mr-16 mr-8 w-10/12 ">

                    <div className=" md:w-9/12 w-full text-white leading-8 h-full">

                        <h1 className='text-left text-xl text-[#FFA100]'>
                            <strong>INTRODUCTION </strong>
                        </h1>
                        <p className="mt-8 leading-8 tracking-wide text-sm  text-justify">
                            Roadlers respects your privacy and is committed to protecting your personal data. This privacy policy will inform you as to how we look after your personal data when you visit our website (regardless of where you visit it from) and tell you about your privacy rights and how the law protects you.
                            <br />
                            <br />
                            We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, the terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, accessible from roadlers.com. Please also use the Glossary to understand the meaning of some of the terms used in this privacy policy.

                        </p>


                        <ol className='policy'>
                            <li>
                                <h1 className=' text-left text-xl text-[#FFA100] mt-8'>
                                    <strong>DEFINITIONS</strong>
                                </h1>


                                <p className="mt-8 leading-8 tracking-wide text-sm  text-justify">
                                    <strong className='text-base text-[#FFA100]'>Cookies: </strong> Cookies are small files stored on your device (computer or mobile device).
                                </p>

                                <p className="mt-8 leading-8 tracking-wide text-sm  text-justify">
                                    <strong className='text-base text-[#FFA100]'>Data Controller: </strong>
                                    Data Controller means the natural or legal person who (either alone or jointly or in common with other persons) determines the purposes for which and the manner in which any personal information are, or are to be, processed. For the purpose of this Privacy Policy, we are a Data Controller of your Personal Data.
                                </p>


                                <p className="mt-8 leading-8 tracking-wide text-sm  text-justify">
                                    <strong className='text-base text-[#FFA100]'>Data Processors (or Service Providers): </strong>
                                    Data Processor (or Service Provider) means any natural or legal person who processes the data on behalf of the Data Controller. We may use the services of various Service Providers in order to process your data more effectively.
                                </p>



                                <p className="mt-8 leading-8 tracking-wide text-sm  text-justify">
                                    <strong className='text-base text-[#FFA100]'>Data Subject (or User): </strong>
                                    Data Subject is any living individual who is using our Service and is the subject of Personal Data.
                                </p>


                                <p className="mt-8 leading-8 tracking-wide text-sm  text-justify">
                                    <strong className='text-base text-[#FFA100]'>Personal Data: </strong>

                                    Personal data, or personal information, means any information about an individual from which that person can be identified. It does not include data where the identity has been removed (anonymous data).
                                </p>

                                <p className="mt-8 leading-8 tracking-wide text-sm  text-justify">
                                    <strong className='text-base text-[#FFA100]'>Service: </strong>

                                    Service is the roadlers.com website operated by ROADLERS.
                                </p>

                                <p className="mt-8 leading-8 tracking-wide text-sm  text-justify">
                                    <strong className='text-base text-[#FFA100]'>Usage Data: </strong>
                                    Usage Data is data collected automatically either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).
                                </p>

                            </li>

                            <li>
                                <h1 className=' text-left text-xl text-[#FFA100] mt-8'>
                                    <strong>PURPOSE OF THIS PRIVACY POLICY  </strong>
                                </h1>
                                <p className="mt-8 leading-8 tracking-wide text-sm  text-justify">
                                    This privacy policy aims to give you information on how Roadlers collects and processes your personal data through your use of this website, including any data you may provide through this website when you sign up to our newsletter.
                                    <br />
                                    <br />
                                    This website is not intended for children and we do not knowingly collect data relating to children. It is important that you read this privacy policy together with any other privacy policy or fair processing policy we may provide on specific occasions when we are collecting or processing personal data about you so that you are fully aware of how and why we are using your data. This privacy policy supplements other notices and privacy policies and is not intended to override them.
                                </p>


                                <p className="mt-8 leading-8 tracking-wide text-sm  text-justify">
                                    <strong className='text-base text-[#FFA100]'>CONTROLLER: </strong>
                                    Roadlers is the controller and responsible for your personal data (collectively referred to as Roadlers, “we”, “us” or “our” in this privacy policy). If you have any questions about this privacy policy, including any requests to exercise your legal rights, please contact us at support@roadlers.com
                                </p>


                                <p className="mt-8 leading-8 tracking-wide text-sm  text-justify">
                                    <strong className='text-base text-[#FFA100]'>CHANGES TO THE PRIVACY POLICY AND YOUR DUTY TO INFORM US OF CHANGES: </strong>
                                    We keep our privacy policy under regular review. Historic versions can be obtained by contacting us. It is important that the personal data we hold about you is accurate and current. Please keep us informed if your personal data changes during your relationship with us.
                                </p>

                                <p className="mt-8 leading-8 tracking-wide text-sm  text-justify">
                                    <strong className='text-base text-[#FFA100]'>THIRD-PARTY LINKS: </strong>
                                    This website may include links to third-party websites, plug-ins and applications. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. We do not control these third-party websites and are not responsible for their privacy statements. When you leave our website, we encourage you to read the privacy policy of every website you visit.
                                </p>

                            </li>

                            <li>
                                <h1 className=' text-left text-xl text-[#FFA100] mt-8'>
                                    <strong>THE DATA WE COLLECT ABOUT YOU  </strong>
                                </h1>
                                <p className="mt-8 leading-8 tracking-wide text-sm  text-justify">
                                    We may collect, use, store and transfer different kinds of Personal Data about you which we have grouped together as follows:
                                </p>


                                <p className="mt-8 leading-8 tracking-wide text-sm  text-justify">
                                    <strong className='text-base text-[#FFA100]'>Identity Data: </strong>
                                    Identity Data includes first name, last name, username or similar identifier, marital status, title, date of birth and gender.
                                </p>


                                <p className="mt-8 leading-8 tracking-wide text-sm  text-justify">
                                    <strong className='text-base text-[#FFA100]'>Contact Data: </strong>
                                    Contact Data includes billing address, delivery address, email address, fax and telephone numbers.
                                </p>

                                <p className="mt-8 leading-8 tracking-wide text-sm  text-justify">
                                    <strong className='text-base text-[#FFA100]'>Affiliation Data: </strong>
                                    Affiliation Data includes name and address of employer, work designation or title, and relationship affiliations with any person or organization.
                                </p>

                                <p className="mt-8 leading-8 tracking-wide text-sm  text-justify">
                                    <strong className='text-base text-[#FFA100]'>Technical Data: </strong>

                                    Technical Data includes internet protocol (IP) address, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform, and other technology on the devices you use to access this website.
                                </p>

                                <p className="mt-8 leading-8 tracking-wide text-sm  text-justify">
                                    <strong className='text-base text-[#FFA100]'>Usage Data: </strong>

                                    Usage Data includes information about how you use our website, products and services.
                                </p>

                                <p className="mt-8 leading-8 tracking-wide text-sm  text-justify">
                                    <strong className='text-base text-[#FFA100]'>Marketing and Communications Data: </strong>


                                    Marketing and Communications Data includes your preferences in receiving marketing from us and our third parties and your communication preferences.
                                    <br /><br />  We also collect, use and share Aggregated Data such as statistical or demographic data for any purpose. Aggregated Data could be derived from your personal data but is not considered personal data in law as this data will not directly or indirectly reveal your identity. For example, we may aggregate your Usage Data to calculate the percentage of users accessing a specific website feature. However, if we combine or connect Aggregated Data with your personal data so that it can directly or indirectly identify you, we treat the combined data as personal data which will be used in accordance with this privacy policy.
                                    <br /><br /> We do not collect any Special Categories of Personal Data about you (this includes details about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union membership, information about your health, and genetic and biometric data). Nor do we collect any information about criminal convictions and offences.

                                </p>

                            </li>


                            <li>
                                <h1 className=' text-left text-xl text-[#FFA100] mt-8'>
                                    <strong>HOW IS YOUR PERSONAL DATA COLLECTED? </strong>
                                </h1>
                                <p className="mt-8 leading-8 tracking-wide text-sm  text-justify">
                                    We use different methods to collect data from and about you including through:
                                </p>


                                <p className="mt-8 leading-8 tracking-wide text-sm  text-justify">
                                    <strong className='text-base text-[#FFA100]'>Direct interactions. </strong>

                                    You may give us your Identity and Contact Data by filling in forms or by corresponding with us by post, phone, email or otherwise. This includes personal data you provide when you:
                                </p>

                                <p>a) access our website;</p>
                                <p>b) seek out our Services;</p>
                                <p>c)	when services are provided to us by external or third-party organizations or vendors;</p>
                                <p>d)	apply for our products or services;</p>
                                <p>e)	create an account on our website;</p>
                                <p>f)	subscribe to our Service;</p>
                                <p>g)	request marketing to be sent to you;</p>
                                <p>h)	enter a competition, promotion or survey; or;</p>
                                <p>i)	give us feedback or contact us.</p>

                                <p className="mt-8 leading-8 tracking-wide text-sm  text-justify">
                                    <strong className='text-base text-[#FFA100]'>Automated technologies or interactions. </strong>

                                    As you interact with our website, we will automatically collect Technical Data about your equipment, browsing actions and patterns. We collect this personal data by using cookies, and other similar technologies. We may also receive Technical Data about you if you visit other websites employing our cookies. Please see our cookie policy for further details
                                </p>



                                <p className="mt-8 leading-8 tracking-wide text-sm  text-justify">
                                    <strong className='text-base text-[#FFA100]'>Third parties or publicly available sources. </strong>

                                    We will receive personal data about you from various third parties and public sources as set out below:
                                </p>

                                <p>i.	Technical Data from the following parties:</p>

                                <div className='ml-4'>
                                    <p>a)	analytics providers such as Google;</p>
                                    <p>b)	advertising networks; and</p>
                                    <p>c)	search information providers.</p>
                                </div>


                                <p>ii.	Contact, Financial and Transaction Data from providers of technical, payment and delivery services</p>
                                <p>iii.	Identity and Contact Data from data brokers or aggregators</p>
                                <p>iv.	Identity and Contact Data from publicly available sources such as the Companies Register.</p>



                            </li>


                            <li>
                                <h1 className=' text-left text-xl text-[#FFA100] mt-8'>
                                    <strong>HOW WE USE YOUR PERSONAL DATA  </strong>
                                </h1>
                                <p className="mt-8 leading-8 tracking-wide text-sm  text-justify">
                                    We will only use your personal data when the law allows us to. Most commonly, we will use your personal data in the following circumstances:
                                </p>
                                <ul className='mt-8'>
                                    <li>
                                        Where we need to perform the contract, we are about to enter into or have entered into with you.
                                    </li>
                                    <li>
                                        Where we need to perform the contract, we are about to enter into or have entered into with you.
                                    </li>

                                    <li>
                                        Where we need to comply with a legal obligation.
                                    </li>
                                </ul>

                                <p className='mt-8'>
                                    Generally, we do not rely on consent as a legal basis for processing your personal data although we will get your consent before sending third party direct marketing communications to you via email or text message. You have the right to withdraw consent to marketing at any time by contacting us.
                                </p>

                            </li>


                            <li>
                                <h1 className=' text-left text-xl text-[#FFA100] mt-8'>
                                    <strong>PROMOTIONAL OFFERS FROM US </strong>
                                </h1>
                                <p className="mt-8 leading-8 tracking-wide text-sm  text-justify">
                                    We may use your Identity, Contact, Technical, Usage and Profile Data to form a view on what we think you may want or need, or what may be of interest to you. This is how we decide which products, services and offers may be relevant for you (we call this marketing).
                                </p>


                                <p className='mt-6'>
                                    You will receive marketing communications from us if you have requested information from us or purchased [goods or services] from us and you have not opted out of receiving that marketing.
                                </p>

                            </li>

                            <li>
                                <h1 className=' text-left text-xl text-[#FFA100] mt-8'>
                                    <strong>COOKIES </strong>
                                </h1>
                                <p className="mt-8 leading-8 tracking-wide text-sm  text-justify">
                                    You can set your browser to refuse all or some browser cookies, or to alert you when websites set or access cookies. If you disable or refuse cookies, please note that some parts of this website may become inaccessible or not function properly. For more information about the cookies we use, please see our cookie policy.
                                </p>

                            </li>


                            <li>
                                <h1 className=' text-left text-xl text-[#FFA100] mt-8'>
                                    <strong>DATA SECURITY </strong>
                                </h1>
                                <p className="mt-8 leading-8 tracking-wide text-sm  text-justify">
                                    We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorized way, altered or disclosed. In addition, we limit access to your personal data to those employees, agents, contractors and other third parties who have a business need to know. They will only process your personal data on our instructions, and they are subject to a duty of confidentiality.
                                    <br /><br />
                                    We have put in place procedures to deal with any suspected personal data breach and will notify you and any applicable regulator of a breach where we are legally required to do so.

                                </p>




                            </li>


                            <li>
                                <h1 className=' text-left text-xl text-[#FFA100] mt-8'>
                                    <strong>DATA RETENTION </strong>
                                </h1>
                                <p className="mt-8 leading-8 tracking-wide text-sm  text-justify">
                                    We will only retain your personal data for as long as reasonably necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, regulatory, tax, accounting or reporting requirements. We may retain your personal data for a longer period in the event of a complaint or if we reasonably believe there is a prospect of litigation in respect to our relationship with you.

                                    <br /><br />
                                    To determine the appropriate retention period for personal data, we consider the amount, nature and sensitivity of the personal data, the potential risk of harm from unauthorized use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal, regulatory, tax, accounting or other requirements.

                                </p>




                            </li>


                            <li>
                                <h1 className=' text-left text-xl text-[#FFA100] mt-8'>
                                    <strong>YOUR LEGAL RIGHTS </strong>
                                </h1>
                                <p className="mt-8 leading-8 tracking-wide text-sm  text-justify">
                                    Under certain circumstances, you have rights under data protection laws in relation to your personal data. Please see the Glossary below to find out more about these rights:
                                </p>
                                <ul className='mt-8'>
                                    <li>
                                        •	Request access to your personal data.
                                    </li>
                                    <li>
                                        •	Request correction of your personal data.
                                    </li>

                                    <li>
                                        •	Request erasure of your personal data.
                                    </li>

                                    <li>
                                        •	Object to processing of your personal data.
                                    </li>

                                    <li>
                                        •	Request restriction of processing your personal data.
                                    </li>

                                    <li>
                                        •	Request transfer of your personal data.
                                    </li>

                                    <li>
                                        •	Right to withdraw consent.
                                    </li>
                                </ul>

                                <p className='mt-8'>
                                    If you wish to exercise any of the rights set out above, please contact us at  <a href="mailto:support@roadlers.com">support@roadlers.com.</a>

                                </p>

                                <p className="mt-8 leading-8 tracking-wide text-sm  text-justify">
                                    <strong className='text-base text-[#FFA100]'>NO FEE USUALLY REQUIRED: </strong>
                                    You will not have to pay a fee to access your personal data (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we could refuse to comply with your request in these circumstances.
                                </p>

                                <p className="mt-8 leading-8 tracking-wide text-sm  text-justify">
                                    <strong className='text-base text-[#FFA100]'>WHAT WE MAY NEED FROM YOU: </strong>
                                    We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal data (or to exercise any of your other rights). This is a security measure to ensure that personal data is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response.
                                </p>

                                <p className="mt-8 leading-8 tracking-wide text-sm  text-justify">
                                    <strong className='text-base text-[#FFA100]'>TIME LIMIT TO RESPOND: </strong>
                                    We try to respond to all legitimate requests within one month. Occasionally it could take us longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated.
                                </p>

                            </li>




                            <li>
                                <h1 className=' text-left text-xl text-[#FFA100] mt-8'>
                                    <strong>GLOSSARY   </strong>
                                </h1>


                                <p className='mt-8'>
                                    <strong className='text-base text-[#FFA100]'>LAWFUL BASIS   </strong>
                                </p>

                                <p className="mt-8 leading-8 tracking-wide text-sm  text-justify">
                                    <strong className=' text-[#FFA100]'>Legitimate Interest </strong>
                                    means the interest of our business in conducting and managing our business to enable us to give you the best service/product and the best and most secure experience. We make sure we consider and balance any potential impact on you (both positive and negative) and your rights before we process your personal data for our legitimate interests. We do not use your personal data for activities where our interests are overridden by the impact on you (unless we have your consent or are otherwise required or permitted to by law). You can obtain further information about how we assess our legitimate interests against any potential impact on you in respect of specific activities by contacting us.
                                </p>

                                <p className="mt-8 leading-8 tracking-wide text-sm  text-justify">
                                    <strong className=' text-[#FFA100]'> Performance of Contract </strong>
                                    means processing your data where it is necessary for the performance of a contract to which you are a party or to take steps at your request before entering into such a contract.

                                </p>

                                <p className="mt-8 leading-8 tracking-wide text-sm  text-justify">
                                    <strong className=' text-[#FFA100]'> Comply with a legal obligation</strong>
                                    means processing your personal data where it is necessary for compliance with a legal obligation that we are subject to
                                </p>


                                <p className='mt-8'>
                                    <strong className='text-base text-[#FFA100]'>YOUR LEGAL RIGHTS      </strong>
                                    <br />
                                    <br />
                                    You have the right to
                                </p>

                                <p className="mt-8 leading-8 tracking-wide text-sm  text-justify">
                                    <strong className=' text-[#FFA100]'>Request access </strong>
                                    to your personal data (commonly known as a “data subject access request”). This enables you to receive a copy of the personal data we hold about you and to check that we are lawfully processing it.  </p>
                                <p className="mt-8 leading-8 tracking-wide text-sm  text-justify">
                                    <strong className=' text-[#FFA100]'> Request correction </strong>
                                    of the personal data that we hold about you. This enables you to have any incomplete or inaccurate data we hold about you corrected, though we may need to verify the accuracy of the new data you provide to us.
                                </p>

                                <p className="mt-8 leading-8 tracking-wide text-sm  text-justify">
                                    <strong className=' text-[#FFA100]'> Request erasure</strong>
                                    of your personal data. This enables you to ask us to delete or remove personal data where there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove your personal data where you have successfully exercised your right to object to processing (see below), where we may have processed your information unlawfully or where we are required to erase your personal data to comply with local law. Note, however, that we may not always be able to comply with your request of erasure for specific legal reasons which will be notified to you, if applicable, at the time of your request.
                                </p>

                                <p className="mt-8 leading-8 tracking-wide text-sm  text-justify">
                                    <strong className=' text-[#FFA100]'>Object to processing</strong>
                                    of your personal data where we are relying on a legitimate interest (or those of a third party) and there is something about your particular situation which makes you want to object to processing on this ground as you feel it impacts on your fundamental rights and freedoms. You also have the right to object where we are processing your personal data for direct marketing purposes. In some cases, we may demonstrate that we have compelling legitimate grounds to process your information which override your rights and freedoms
                                </p>


                                <p className="mt-8 leading-8 tracking-wide text-sm  text-justify">
                                    <strong className=' text-[#FFA100]'>Request restriction of processing</strong>
                                    of your personal data. This enables you to ask us to suspend the processing of your personal data in the following scenarios:
                                </p>

                                <ul className='mt-8'>
                                    <li>
                                        •	If you want us to establish the data’s accuracy.
                                    </li>
                                    <li>
                                        •	Where our use of the data is unlawful but you do not want us to erase it.
                                    </li>

                                    <li>
                                        •	Where you need us to hold the data even if we no longer require it as you need it to establish, exercise or defend legal claims.
                                    </li>

                                    <li>
                                        •	You have objected to our use of your data but we need to verify whether we have overriding legitimate grounds to use it.
                                    </li>


                                </ul>

                                <p className="mt-8 leading-8 tracking-wide text-sm  text-justify">
                                    <strong className=' text-[#FFA100]'>Request the transfer</strong>
                                    of your personal data to you or to a third party. We will provide to you, or a third party you have chosen, your personal data in a structured, commonly used, machine-readable format. Note that this right only applies to automated information which you initially provided consent for us to use or where we used the information to perform a contract with you.
                                </p>

                                <p className="mt-8 leading-8 tracking-wide text-sm  text-justify">
                                    <strong className=' text-[#FFA100]'>Withdraw consent at any time</strong>
                                    where we are relying on consent to process your personal data. However, this will not affect the lawfulness of any processing carried out before you withdraw your consent. If you withdraw your consent, we may not be able to provide certain products or services to you. We will advise you if this is the case at the time you withdraw your consent.
                                </p>
                            </li>

                        </ol>





                    </div>

                    <div className="flex justify-end text-center md:w-9/12 w-full mt-24">
                        <NavLink className="font-semibold rounded bg-white text-black p-2 " exact to="/login">
                            Accept Cookies
                        </NavLink>


                    </div>
                </div>
            </div>

        </div>
    )
}

export default PrivacyPolicy

