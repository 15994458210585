import React, { useState, useRef } from 'react'
import cartwhite from '../../Images/cart-white.svg';
import daily_driver from '../../Images/daily_driver.png';
import ratings from '../../Images/ratings.svg'
import locationicon from '../../Images/location.svg'
import message from '../../Images/message.png'
import mark from '../../Images/mark.png'
import logo_circle from '../../Images/logo-circle.png'
import like from '../../Images/Like-icon.png'
import gift_faint from '../../Images/gift_faint.png'
import drivee from '../../Images/drivee.png'
import prev from '../../Images/prev.png'
import onee from '../../Images/onee.png'
import next from '../../Images/next.png'
import user from '../../Images/userg.png'
import send from '../../Images/send.png'
import infoIcon from "../../Images/infoIcon.svg"
import clockb from '../../Images/clockb.png'
import Spin from '../../Images/spinner.gif';
import axios from "axios";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { addBooking, addCart } from '../../slices/booking';

import { BASE_URL } from "../utils/constant"


import TopForm from '../TopForm';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

// MUI
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { GoogleMap, useJsApiLoader, Autocomplete } from '@react-google-maps/api';
import { useEffect } from 'react';




function OneOffDriverComponent(props) {
    const { } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyDkMuW-hGvdRfUhsb2R9RFqA0Pq4Qxg4Cs",
        libraries: ['places'],

    })

    const [autocomplete, setAutocomplete] = useState(null)

    const onPlaceChanged = () => {
        if(autocomplete !== null) {
            const places = autocomplete.getPlace().formatted_address
            setPickupLocation(places)
        }
    }


    useEffect(() => {
        handlePriceThroughCode()
    }, [])

    let navigate = useNavigate();
    const dispatch = useDispatch()

    const { textOne, textTwo, title, Location, price, image, imageTwo, imageThree, priceTwo, type, p1, p2, p3, p4, list, tokenCode, dayCode } = props

    let amountNum = Number(price);
    const [status, setStatus] = useState('Start Booking - Let’s Move')

    const refDiv = useRef()
    console.log("myref", refDiv.current)
    const [load, setLoad] = useState('')

    const [proceed, setProceed] = useState(false)

    const [pickupDate, setPickupDate] = useState(new Date())
    const [numOfDay, setNumOfDay] = useState(' ')
    const [destination, setDestination] = useState('')
    const [destinationState, setDestinationState] = useState('')
    const [pickupLocation, setPickupLocation] = useState('')
    const [pickupTime, setPickupTime] = useState(null)
    const [nightOrDay, setNightOrDay] = useState('')
    const [note, setNote] = useState('')

    const [oneOffOptionArray, setOneOffOptionArray] = useState([])
    const [insurancePrice, setInsurancePrice] = useState(0)


    // Booking Total Price
    const [bookingPrice, setBookingPrice] = useState('12000')
    let num = Number(numOfDay)

    const [isInsured, setIsInsured] = useState(false)
    // HOVER INSURANCE INFO
    const [insuanceInfo, setInsuranceInfo] = useState(false)

    const priceBooked = Number(bookingPrice)

    // Total Price
    // const bookingPrice = priceTwo * numOfDay

    // SET PRICE IF INSURED OR NOT
    const dailyDrivePriceSet = () => {
        if(isInsured) {
            return priceBooked + (0.05 * priceBooked)
        }else {
            return priceBooked
        }
    }


    const dailyDriverPrice = dailyDrivePriceSet()
    console.log(dailyDriverPrice, 'daily driver price')

    const [width, setWidth] = useState(8)
    let [question, setQuestion] = useState(1)
    const [allQuestion, setAllQuestion] = useState(8)

    const [numOfDayError, setNumOfDayError] = useState('')
    const [showFieldsTwo, setshowFieldsTwo] = useState(false)
    const onClickTwo = (e) => {
        e.preventDefault()

        if(numOfDay === '' || !numOfDay) {
            setNumOfDayError('This is required')

            setTimeout(() => {
                setNumOfDayError('')
            }, 3000)
        }else {
            setshowFieldsTwo(false)
            setshowFieldsThree(true)
            setQuestion(++question)
            setWidth(36)
            console.log('width in two', width)
        }



    }

    const [pickupDateError, setPickupDateError] = useState('')
    const [showFieldsOne, setshowFieldsOne] = useState(true)
    const onClickOne = (e) => {
        e.preventDefault()

        if(!pickupDate) {
            setPickupDateError('This is required')

            setTimeout(() => {
                setPickupDateError('')
            }, 3000)
        }else {
            setshowFieldsOne(false)
            setshowFieldsTwo(true)
            setQuestion(++question)
            setWidth(20)
        }


        console.log('width in one', width)


    }

    const [nightOrDayError, setNightOrDayError] = useState('')
    const [showFieldsThree, setshowFieldsThree] = useState(false)
    const onClickThree = (e) => {
        e.preventDefault()

        if(!nightOrDay || nightOrDay === '') {
            setNightOrDayError('This is required')

            setTimeout(() => {
                setNightOrDayError('')
            }, 3000);
        }else {
            setshowFieldsThree(false)
            setshowFieldsFour(true)
            setQuestion(++question)
            setWidth(40)
            setStatus('Almost Done - Keep Moving')
            console.log('width in three', width)
        }



    }

    const [pickupLocationError, setPickupLocationError] = useState('')
    const [showFieldsFour, setshowFieldsFour] = useState(false)
    const onClickFour = (e) => {
        e.preventDefault()

        if(pickupLocation === '') {
            setPickupLocationError('This is required')

            setTimeout(() => {
                setPickupLocationError('')
            }, 3000)
        }else {
            setshowFieldsFour(false)
            setshowFieldsFive(true)
            setQuestion(++question)
            setWidth(55)
            setStatus('Almost Done - Keep Moving')
            console.log('width in four', width)
        }

    }

    const [pickupTimeError, setPickupTimeError] = useState('')
    const [showFieldsFive, setshowFieldsFive] = useState(false)
    const onClickFive = (e) => {
        e.preventDefault()

        if(pickupTime === '' || pickupTime == null) {
            setPickupTimeError('This is required')

            setTimeout(() => {
                setPickupTimeError('')
            }, 3000)
        }else {
            setshowFieldsFive(false)
            setshowFieldsSix(true)
            setQuestion(++question)
            setWidth(60)
            setStatus('Almost Done - Keep Moving')
            console.log('width in five', width)
        }
    }

    const [showFieldsSix, setshowFieldsSix] = useState(false)
    const [showFieldsSixb, setshowFieldsSixb] = useState(false)
    const [showFieldsEight, setShowFieldsEight] = useState(false)


    const [destinationError, setDestinationError] = useState('')
    const [showFieldsSeven, setshowFieldsSeven] = useState(false)
    const onClickSix = (e) => {
        e.preventDefault()
        // setWidth()

        if (destination === '') {
            setDestinationError('This is required')

            setTimeout(() => {
                setDestinationError('')
            }, 3000)

        }else {
            setshowFieldsSix(false)
            setStatus('Almost Done - Keep Moving')
            if (destination === 'Out of State') {
                setshowFieldsSixb(true)
                setWidth(80)
            }
            else {
                setshowFieldsSeven(true)
                setWidth(100)
            }
            setQuestion(++question)
            
    
            console.log('width in six', width)
        }

    }

    const[destinationStateError, setDestinationStateError] = useState('')
    const onClickSixb = (e) => {
        e.preventDefault()

        if(destinationState === '') {
            setDestinationStateError('This is required')

            setTimeout(() => {
                setDestinationStateError('')
            }, 3000)
        }else {
            setStatus('Almost Done - Keep Moving')
            setshowFieldsSixb(false)
            setshowFieldsSix(false)
            setshowFieldsSeven(true)
            setWidth(100)
        }
    }

    const [noteError, setNoteError] = useState('')
    const onClickSeven = (e) => {
        e.preventDefault()

        if(note === ''){
            setNoteError('This is required') 

            setTimeout(() => {
                setNoteError('')
            }, 3000)
        }else {
            setshowFieldsSixb(false)
            setshowFieldsSix(false)
            setshowFieldsSeven(false)
            setStatus('Completed - Proceed to Move with Ease')
            setShowFieldsEight(true)
            setQuestion(++question)
            setWidth(100)
        }
    }

    const onClickEight = (e) => {
        e.preventDefault()
        setProceed(true)
        setStatus('Completed - Proceed to Move with Ease')
    }

    const onClickStart = (e) => {
        e.preventDefault()
        setshowFieldsOne(true)
        setshowFieldsTwo(false)
        setshowFieldsThree(false)
        setshowFieldsFour(false)
        setshowFieldsFive(false)
        // setshowFieldsFiveb(false)
        setshowFieldsSix(false)
        setShowFieldsEight(false)
        setQuestion(question - 1)
        setWidth(10)
    }


    const onClickOneBack = (e) => {
        e.preventDefault()
        setshowFieldsOne(false)

        setshowFieldsTwo(true)
        setshowFieldsThree(false)
        setshowFieldsFour(false)
        setshowFieldsFive(false)
        // setshowFieldsFiveb(false)
        setshowFieldsSix(false)
        setShowFieldsEight(false)
        setQuestion(question - 1)
        console.log('1 back', question)

        setWidth(20)

    }

    const onClickFiveBack = (e) => {
        e.preventDefault()
        setshowFieldsOne(false)

        setshowFieldsTwo(false)
        setshowFieldsThree(false)
        setshowFieldsFour(false)
        setshowFieldsFive(false)
        setshowFieldsFive(true)
        setshowFieldsSix(false)
        setShowFieldsEight(false)
        setQuestion(question - 1)
        console.log('five back', question)
        setWidth(70)

    }

    const onClickSixBack = (e) => {
        e.preventDefault()
        setshowFieldsOne(false)

        setshowFieldsTwo(false)
        setshowFieldsThree(false)
        setshowFieldsFour(false)
        setshowFieldsFive(false)
        setshowFieldsFive(false)
        setshowFieldsSix(true)
        setshowFieldsSixb(false)
        setShowFieldsEight(false)
        setQuestion(question - 1)
        console.log('sixb back', question)
        setWidth(80)
    }

        const onClickSevenBack = (e) => {
        e.preventDefault()
        setshowFieldsOne(false)

        setshowFieldsTwo(false)
        setshowFieldsThree(false)
        setshowFieldsFour(false)
        setshowFieldsFive(false)
        setshowFieldsFive(false)
        setshowFieldsSix(true)
        setshowFieldsSixb(false)
        setshowFieldsSeven(false)
        setShowFieldsEight(false)
        setQuestion(question - 1)
        console.log('seven back', question)
        setWidth(85)
    }

    const onClickEightBack = (e) => {
        e.preventDefault()
        setshowFieldsOne(false)

        setshowFieldsTwo(false)
        setshowFieldsThree(false)
        setshowFieldsFour(false)
        setshowFieldsFive(false)
        setshowFieldsFive(false)
        setshowFieldsSix(false)
        setshowFieldsSixb(false)
        setshowFieldsSeven(true)
        setShowFieldsEight(false)
        setQuestion(question - 1)
        console.log('eight back', question)
        setWidth(95)
    }

    const onClickTwoBack = (e) => {
        e.preventDefault()


        setshowFieldsThree(true)

        setshowFieldsOne(false)

        setshowFieldsTwo(false)

        setshowFieldsFour(false)
        setshowFieldsFive(false)
        // setshowFieldsFiveb(false)
        setshowFieldsSix(false)
        setShowFieldsEight(false)
        setQuestion(question - 1)
        console.log('2 back', question)

        setWidth(40)
    }

    const onClickThreeBack = (e) => {
        e.preventDefault()
        setshowFieldsOne(false)

        setshowFieldsTwo(false)
        setshowFieldsThree(false)
        setshowFieldsFour(true)
        setshowFieldsFive(false)
        // setshowFieldsFiveb(false)
        setshowFieldsSix(false)
        setShowFieldsEight(false)
        setQuestion(question - 1)
        console.log('3 back', question)
        setWidth(50)
    }

    const [noOfDaySelect, setNoOfDaySelect] = useState('')
    const handleNumOfDayChange = (e) => {


        const noOfDayChange =  e.target.value
        const daysPickedArray = noOfDayChange?.split(" ")
        const getDayPicked = daysPickedArray[0]
        setNoOfDaySelect(getDayPicked)
        setBookingPrice(daysPickedArray[1])
    }

    useEffect(() => {
        setNumOfDay(noOfDaySelect)
        console.log(numOfDay, "noOfDay")
    }, [noOfDaySelect])



    // Handling the amount to set when using giftcard link
    const handlePriceThroughCode = () => {
        setNumOfDay(dayCode)

        if(dayCode === '1'){
            setBookingPrice('12000')
        }else if(dayCode === '2') {
            setBookingPrice('24000')
        }else if(dayCode === '3') {
            setBookingPrice('36000')
        }else if(dayCode === '4') {
            setBookingPrice('40000')
        }else if(dayCode === '5') {
            setBookingPrice('45000')
        }else if(dayCode === '6') {
            setBookingPrice('49000')
        }else if(dayCode === '7') {
            setBookingPrice('50000')
        }else if(dayCode === '8') {
            setBookingPrice('51000')
        }else if(dayCode === '9') {
            setBookingPrice('52000')
        }else if(dayCode === '10') {
            setBookingPrice('53000')
        }else if(dayCode === '11') {
            setBookingPrice('54000')
        }else if(dayCode === '12') {
            setBookingPrice('55000')
        }else if(dayCode === '13') {
            setBookingPrice('56000')
        }else if(dayCode === '14') {
            setBookingPrice('58000')
        }else if(dayCode === '15') {
            setBookingPrice('60000')
        }else if(dayCode === '16') {
            setBookingPrice('61000')
        }else if(dayCode === '17') {
            setBookingPrice('63000')
        }else if(dayCode === '18') {
            setBookingPrice('65000')
        }else if(dayCode === '19') {
            setBookingPrice('67000')
        }else if(dayCode === '20') {
            setBookingPrice('70000')
        }else if(dayCode === '21') {
            setBookingPrice('75000')
        }else if(dayCode === '22') {
            setBookingPrice('80000')
        }else if(dayCode === '23') {
            setBookingPrice('82000')
        }else if(dayCode === '24') {
            setBookingPrice('83000')
        }else if(dayCode === '25') {
            setBookingPrice('84000')
        }else if(dayCode === '26') {
            setBookingPrice('85000')
        }
    }


    // FETCH PRICES FOR DAILY DRIVER
    const fetchPrices = (e) => {
        axios
            .get(`${BASE_URL}/v1/view-pricing`, {
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((response) => {
                const transData = response.data.data;
                setOneOffOptionArray(transData)
            });
    }
    useEffect(() => {
        fetchPrices();
    }, []);



    // HANDLE INSURANCE CHANGE
    const handleInsuranceChange = (e) => {
        e.preventDefault()
        if(e.target.value === "Yes") {
            setIsInsured(true)
            setInsurancePrice(0.05 * priceBooked)
        }else {
            setIsInsured(false)
            setInsurancePrice(0)
        }
    }

    const handleProceedNow = (e) => {
        console.log('now starting form data')
        e.preventDefault();

        // let userId = JSON.parse(localStorage.getItem("userId"))
        // let providerId = JSON.parse(localStorage.getItem("service_providerId"))
        // let vehicle_image = image

        let exist = JSON.parse(localStorage.getItem("cart"));
        if (exist == null) {
            exist = [];
        }

        let bookingDetails = {
            'pickup_date': moment(pickupDate).format("YYYY/MM/DD"),
            'pickup_location': pickupLocation,
            'destination': destination,
            'destination_state': destinationState,
            'notes': note,
            'num_of_day': numOfDay,
            'pickup_time': moment(pickupTime).format("hh:mm a") ,
            'booking_title': 'Daily Driver',
            'amount': dailyDriverPrice,
            'daily_price': amountNum,
            'category': 'Daily Driver',
            'book_picture': image,
            'token_code': tokenCode,
            'insurance_price': insurancePrice,
        }

        exist.push(bookingDetails);
        exist.map((item, i) => {
            item.id = i + 1;
        });
        localStorage.setItem("cart", JSON.stringify(exist));

        
        dispatch(addCart(exist))
        navigate('/cart')
    }





    return (

        <div>
            <TopForm />

            <div className="flex md:flex-row flex-col gap-12 md:px-28 px-8 mt-16 md:w-10/12 w-full  m-auto">
                <div className="flex flex-col md:w-5/12 w-full">

                    <div className="relative w-11/12 rounded-xl flex">
                        <img src={image} alt="" className='w-full items-center justify-center' />

                        <button className='absolute  top-2 left-2'> <img src={like} alt="" className='w-12' /></button>

                        <button className='absolute  bottom-2 right-4'> <img src={send} alt="" className='w-8' /></button>
                        <div className="absolute bottom-8 rounded-r-lg font-bold text-center bg-amber-500 p-1 w-24">
                            <h4>&#8358;
                                {amountNum?.toLocaleString('en-US', { maximumFractionDigits: 2 })}
                            </h4>
                        </div>
                    </div>



                    <div className="w-11/12 bg-white p-4 rounded-xl mt-4 md:flex hidden flex-col gap-1">
                        <h3 className='font-bold'>{title}</h3>
                        <hr className='border-t border-gray-500 w-28' />
                        <div className="flex gap-1 items-center ml-0 pl-0 -ml-2">
                            <img src={locationicon} alt="" className='md:w-5 w-4' />
                            <p className='text-xs font-semibold'>{Location}</p>
                        </div>
                        <p className='text-xs font-semibold leading-6'>
                            At Roadlers, our executive drivers are professionally trained individuals dedicated to your safety and comfort. He/she would work with you 
                            <span className='font-bold'> 10 hours daily, extra hours are charged at 1,000 naira hourly. </span>
                        </p>
                        <br />
                        {p1 ? <ul>
                            <li className='text-xs font-semibold leading-6'>&bull; {p1}</li>
                            <li className='text-xs font-semibold leading-6'>&bull; {p2}</li>
                            <li className='text-xs font-semibold leading-6'>&bull; {p3}</li>
                            <li className='text-xs font-semibold leading-6'>&bull; {p4}</li>

                        </ul> : null}

                        <p className='text-xs font-semibold leading-6'><span className='font-bold'>Traveling across Nigeria?</span> make an out of state booking and our support team will assist you in completing your order.</p>


                        <div className="flex justify-between mt-2">
                            <img src={ratings} alt="" className='w-32' />
                            <button className="border border-black rounded-3xl py-1 px-5 text-xs">Verified</button>
                        </div>
                    </div>
                </div>

                <form onSubmit={handleProceedNow} className='md:w-7/12 '>

                    <div className="flex flex-col  w-full">
                        <h4 className=' text-[#FFA100] font-medium md:font-semibold'>{status}</h4>


                        <div className="w-full mt-12 relative">
                            <input type="range" min="1" max={allQuestion} value={question} className="slider" id="myRange" />
                            <p className='text-white font-semibold absolute -top-5 left-0'>{question}/{allQuestion}</p>
                            <p className='text-white font-semibold absolute -top-5 right-0'>{allQuestion}</p>
                            <div
                                id="background"

                            ></div>
                            <div
                                id="progress"
                                ref={refDiv}
                                style={{ width: width + '%' }}
                            >
                            </div>


                        </div>

                        {type ? (
                            <div className="flex bg-white p-3 justify-between items-center mt-12 rounded-xl">
                                <input type="text" className='w-11/12  px-1 placeholder:text-black' placeholder='Add A Note:' />
                                <img src={message} alt="" className='md:w-9 w-6' />

                            </div>
                        ) : ''}

                        {showFieldsOne ? (
                            <>
                                <div className="flex flex-col gap-3 mt-12">
                                    <label htmlFor="" className='text-white text-sm'>Pick-Up Date</label>
                                    <div className="flex bg-white border border-white text-white p-3 justify-between items-center mt-1 rounded-xl">
                                        <DatePicker selected={pickupDate} onChange={(date) => setPickupDate(date)} />
                                    </div>
                                    <p className="text-red-600">{pickupDateError}</p>
                                </div>


                                <div className="flex gap-2 items-center justify-between">

                                    <div className="flex gap-2 items-center cursor-pointer">
                                        <div className="bg-black border border-white  my-8 p-2 text-center items-center gap-2 rounded-md font-bold  flex">
                                            <button onClick={onClickOne} className='text-white font-bold flex items-center justify-center'>OK <img src={mark} alt="" /></button>
                                        </div>
                                        <h4 className='text-white font-bold'>Press Enter</h4>
                                    </div>

                                    <div className="flex gap-4 text-white font-extrabold">
                                        <button
                                            onClick={onClickOne}
                                        >
                                            <img src={next} alt="Next-btn" />
                                        </button>
                                    </div>
                                </div>
                            </>
                        ) : ''}
                        {showFieldsTwo ?
                            (
                                <>
                                        {
                                            tokenCode && dayCode ? 
                                            <>
                                                <div className="flex flex-col gap-3 mt-12">  
                                                    <label className='text-white'>Number of Days</label>             
                                                    <input 
                                                        className='w-full text-[#242321] bg-white focus:outline-none py-3 px-4  placeholder:text-black rounded-lg md:placeholder:text-sm placeholder:text-xs'
                                                        name="numOfDay"
                                                        id="numOfDay"
                                                        value={dayCode}
                                                    />
                                                </div>
                                            </>
                                            :
                                            <>
                                            <label className=' mt-16 text-white mb-3'>Number of Days Needed</label>  
                                            <div className="flex  border border-white bg-white p-3 justify-between items-center  rounded-lg">
                                                <select

                                                    className='w-full text-[#242321] bg-white focus:outline-none  placeholder:text-black rounded-lg md:placeholder:text-sm placeholder:text-xs'
                                                    name="numOfDay"
                                                    id="numOfDay"
                                                    // value={numOfDay}
                                                    onChange={handleNumOfDayChange}
                                                >
                                                    <option className='md:text-sm text-xx'>Number of Days Needed</option>
                                                    {
                                                        oneOffOptionArray.filter((item) => item?.heading?.includes('Daily Driver Price')).map((optionsArray) => (
                                                            <option value={optionsArray?.period?.split("", 1) + " " + optionsArray?.commission} className='md:text-sm text-xx'>{optionsArray?.period}</option>
                                                        ))
                                                    }

                                                </select>   
                                            </div>
                                            <p className="text-red-600 mt-2">{numOfDayError}</p>
                                            </>
                                            
                                        }

                                    <div className="flex items-center justify-between">

                                        <div className="flex gap-2 items-center cursor-pointer">
                                            <div className="bg-black border border-white  my-8 p-2 text-center items-center gap-2 rounded-md font-bold  flex">
                                                <button onClick={onClickTwo} className='text-white font-bold flex items-center justify-center'>OK <img src={mark} alt="" /></button>


                                            </div>
                                            <h4 className='text-white font-bold'>Press Enter</h4>
                                        </div>

                                        <div className="flex gap-4 text-white font-extrabold">
                                            <button
                                                onClick={onClickStart}
                                            >
                                                <h2>
                                                <img src={prev} alt="Prev-btn" />
                                                </h2></button>
                                            <button
                                                onClick={onClickTwo}
                                            >
                                                <img src={next} alt="Next-btn" />
                                            </button>
                                        </div>
                                    </div>
                                </>) : ''}

                        {showFieldsThree ? (
                            <>
                                <div className="flex bg-white p-3 justify-between items-center mt-12 rounded-xl">
                                    <select

                                        className='w-full bg-transparent outline-none focus:outline-none  px-1 placeholder:text-black'

                                        name="nightOrDay"
                                        id="nightOrDay"
                                        value={nightOrDay}
                                        onChange={e => setNightOrDay(e.target.value)}

                                    >
                                        <option>Do you want a Night or Day driver?</option>
                                        <option value="Night" className='bg-black text-white'>Night</option>
                                        <option value="Day" className='bg-black text-white'>Day</option>
                                        <option value="Both" className='bg-black text-white'>Both</option>

                                    </select>
                                </div>
                                <p className="text-red-600 mt-2">{nightOrDayError}</p>
                                <div className="flex items-center justify-between">
                                    <div className="flex gap-2 items-center cursor-pointer">
                                        <div className="bg-black border border-white  my-8 p-2 text-center items-center gap-2 rounded-md font-bold  flex">


                                            <button onClick={onClickThree} className='text-white font-bold flex items-center justify-center'>OK <img src={mark} alt="" /></button>


                                        </div>
                                        <h4 className='text-white font-bold'>Press Enter</h4>
                                    </div>

                                    <div className="flex gap-4 text-white font-extrabold">
                                        <button
                                            onClick={onClickOneBack}

                                        >
                                            <h2>
                                            <img src={prev} alt="Prev-btn" />
                                            </h2></button>
                                        <button

                                            onClick={onClickThree}
                                        >
                                            <img src={next} alt="Next-btn" />
                                        </button>
                                    </div>
                                </div>
                            </>
                        ) : ''}


                        {showFieldsFour ? (

                            <>
                                <div className="flex bg-white p-3 justify-between items-center mt-12 rounded-lg">
                                    <Autocomplete className='autcomplete-style'
                                    onPlaceChanged={onPlaceChanged}
                                    onLoad={(value) => setAutocomplete(value)}
                                    >
                                        <input
                                            type="text"
                                            className='w-11/12  px-1 placeholder:text-black'
                                            placeholder='Pick-up Location:'
                                            name="pickupLocation"
                                            id="pickupLocation"
                                            value={pickupLocation}
                                            onChange={e => setPickupLocation(e.target.value)}
                                        />
                                    </Autocomplete>
                                    <img src={locationicon} alt="" className='md:w-6 w-4' />
                                </div>
                                <p className="text-red-600 mt-2">{pickupLocationError}</p>


                                <div className="flex items-center justify-between">
                               
                               <div className="flex gap-2 items-center cursor-pointer">
                                   <div className="bg-black border border-white  my-8 p-2 text-center items-center gap-2 rounded-md font-bold  flex">

                                       <button onClick={onClickFour} className='text-white font-bold flex items-center justify-center'>OK <img src={mark} alt="" /></button>


                                   </div>
                                   <h4 className='text-white font-bold'>Press Enter</h4>
                               </div>

                               <div className="flex gap-4 text-white font-extrabold">
                                           <button
                                               onClick={onClickTwoBack}
                                           >
                                               <h2>
                                               <img src={prev} alt="Prev-btn" />
                                               </h2></button>
                                           <button

                                               onClick={onClickFour}
                                           >
                                               <img src={next} alt="Next-btn" />
                                           </button>
                                       </div>

                            </div>
                            </>
                        ) : ''}

                        {
                            showFieldsFive ? (
                                <>
                                    <div className="flex-col flex gap-3 mt-12">
                                        <label htmlFor="" className="text-sm text-white">Pick Up Time</label>
                                        <div className="flex time border border-white bg-white justify-between items-center rounded-xl">
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <TimePicker

                                                    value={pickupTime}
                                                    onChange={(time) => {
                                                        setPickupTime(time);
                                                    }}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </LocalizationProvider>
                                        </div>
                                        <p className="text-red-600">{pickupTimeError}</p>
                                    </div>

                                    <div className="flex justify-between items-center">

                                        <div className="flex gap-2 items-center cursor-pointer">
                                            <div className="bg-black border border-white  my-8 p-2 text-center items-center gap-2 rounded-md font-bold  flex">

                                                <button onClick={onClickFive} className='text-white font-bold flex items-center justify-center'>OK <img src={mark} alt="" /></button>


                                            </div>
                                            <h4 className='text-white font-bold'>Press Enter</h4>
                                        </div>


                                        <div className="flex gap-4 text-white font-extrabold">
                                                <button
                                                    onClick={onClickThreeBack}

                                                >
                                                    <h2>
                                                    <img src={prev} alt="Prev-btn" />
                                                    </h2></button>
                                                <button

                                                    onClick={onClickFive}
                                                >
                                                    <img src={next} alt="Next-btn" />
                                                </button>
                                            </div>
                                    </div>
                                </>


                            ) : ''
                        }


                        {
                            showFieldsSix ? (
                                <>
                                    <div className="flex border  bg-white p-3 justify-between items-center mt-16 rounded-lg">
                                        <select
                                            name="destination"
                                            id="destination"
                                            value={destination}
                                            onChange={e => setDestination(e.target.value)}
                                            className='w-full text-black bg-white focus:outline-none  placeholder:text-black rounded-lg md:placeholder:text-sm placeholder:text-xs'
                                        >
                                            <option className='md:text-sm text-xx'>Destination</option>
                                            <option value="Within State" className='md:text-sm text-xx'>Within State</option>
                                            <option value="Out of State" className='md:text-sm text-xx'>Out of State</option>
                                        </select>

                                    </div>
                                    <p className="text-red-600 mt-2">{destinationError}</p>

                                    <div className="flex justify-between items-center">

                                        <div className="flex gap-2 items-center cursor-pointer">

                                            <div className="bg-black border border-white  my-8 p-2 text-center items-center gap-2 rounded-md font-bold  flex">
                                                <button
                                                    type='button'
                                                    onClick={onClickSix}
                                                    className='text-white font-bold flex items-center justify-center'>OK <img src={mark} alt="" /></button>
                                            </div>
                                            <h4 className='text-white font-bold' onClick={onClickSix}>Press Enter</h4>
                                        </div>


                                        <div className="flex gap-4 text-white font-extrabold">
                                            <button
                                                onClick={onClickFiveBack}
                                            >
                                                <h2>
                                                <img src={prev} alt="Prev-btn" />
                                                </h2></button>

                                                <button

                                                    onClick={onClickSix}
                                                    >
                                                    <img src={next} alt="Next-btn" />
                                                </button>
                                        </div>

                                    </div>
                                </>
                            ) : ''
                        }

                        {
                            showFieldsSixb ? (
                                <>
                                    <div className="flex  bg-white p-3 justify-between items-center mt-16 rounded-lg">
                                        <select
                                            name="destination"
                                            id="destination"
                                            value={destinationState}
                                            onChange={e => setDestinationState(e.target.value)}
                                            className='w-full text-black bg-white focus:outline-none  placeholder:text-black rounded-lg md:placeholder:text-sm placeholder:text-xs'>
                                            <option selected="selected" className='md:text-sm text-xx'>Your Travel Destination State:</option>
                                            <option value="Abia" className='md:text-sm text-xx'>Abia</option>
                                            <option value="Abuja FCT" className='md:text-sm text-xx'>Abuja FCT</option>
                                            <option value="Adamawa" className='md:text-sm text-xx'>Adamawa</option>
                                            <option value="Akwa Ibom" className='md:text-sm text-xx'>Akwa Ibom</option>
                                            <option value="Anambra" className='md:text-sm text-xx'>Anambra</option>
                                            <option value="Bauchi" className='md:text-sm text-xx'>Bauchi</option>
                                            <option value="Bayelsa" className='md:text-sm text-xx'>Bayelsa</option>
                                            <option value="Benue" className='md:text-sm text-xx'>Benue</option>
                                            <option value="Borno" className='md:text-sm text-xx'>Borno</option>
                                            <option value="Cross River" className='md:text-sm text-xx'>Cross River</option>
                                            <option value="Delta" className='md:text-sm text-xx'>Delta</option>
                                            <option value="Ebonyi" className='md:text-sm text-xx'>Ebonyi</option>
                                            <option value="Edo" className='md:text-sm text-xx'>Edo</option>
                                            <option value="Ekiti" className='md:text-sm text-xx'>Ekiti</option>
                                            <option value="Enugu" className='md:text-sm text-xx'>Enugu</option>
                                            <option value="Gombe" className='md:text-sm text-xx'>Gombe</option>
                                            <option value="Imo" className='md:text-sm text-xx'>Imo</option>
                                            <option value="Jigawa" className='md:text-sm text-xx'>Jigawa</option>
                                            <option value="Kaduna" className='md:text-sm text-xx'>Kaduna</option>
                                            <option value="Kano" className='md:text-sm text-xx'>Kano</option>
                                            <option value="Katsina" className='md:text-sm text-xx'>Katsina</option>
                                            <option value="Kebbi" className='md:text-sm text-xx'>Kebbi</option>
                                            <option value="Kogi" className='md:text-sm text-xx'>Kogi</option>
                                            <option value="Kwara" className='md:text-sm text-xx'>Kwara</option>
                                            <option value="Lagos" className='md:text-sm text-xx'>Lagos</option>
                                            <option value="Nassarawa" className='md:text-sm text-xx'>Nassarawa</option>
                                            <option value="Niger" className='md:text-sm text-xx'>Niger</option>
                                            <option value="Ogun" className='md:text-sm text-xx'>Ogun</option>
                                            <option value="Ondo" className='md:text-sm text-xx'>Ondo</option>
                                            <option value="Osun" className='md:text-sm text-xx'>Osun</option>
                                            <option value="Oyo" className='md:text-sm text-xx'>Oyo</option>
                                            <option value="Plateau" className='md:text-sm text-xx'>Plateau</option>
                                            <option value="Rivers" className='md:text-sm text-xx'>Rivers</option>
                                            <option value="Sokoto" className='md:text-sm text-xx'>Sokoto</option>
                                            <option value="Taraba" className='md:text-sm text-xx'>Taraba</option>
                                            <option value="Yobe" className='md:text-sm text-xx'>Yobe</option>
                                            <option value="Zamfara" className='md:text-sm text-xx'>Zamfara</option>
                                        </select>

                                    </div>
                                    <p className="text-red-600 mt-2">{destinationStateError}</p>

                                    <div className="flex justify-between items-center">

                                        <div className="flex gap-2 items-center cursor-pointer">

                                            <div className="bg-black border border-white  my-8 p-2 text-center items-center gap-2 rounded-md font-bold  flex">
                                                <button
                                                    type='button'
                                                    onClick={onClickSixb}
                                                    className='text-white font-bold flex items-center justify-center'>OK <img src={mark} alt="" /></button>
                                            </div>
                                            <h4 className='text-white font-bold' onClick={onClickSixb}>Press Enter</h4>
                                        </div>


                                        <div className="flex gap-4 text-white font-extrabold">
                                            <button
                                                onClick={onClickSixBack}
                                            >
                                                <h2>
                                                <img src={prev} alt="Prev-btn" />
                                                </h2></button>

                                                <button

                                                    onClick={onClickSixb}
                                                    >
                                                    <img src={next} alt="Next-btn" />
                                                </button>
                                        </div>
                                    </div>
                                </>
                            ) : ''
                        }


                        {showFieldsSeven ? (
                            <>
                                <div className="flex bg-white p-3 justify-between items-center mt-12 rounded-xl">
                                    <input
                                        type="text"
                                        className='w-11/12  px-1 placeholder:text-black'
                                        placeholder='Add A Note'
                                        value={note}

                                        onChange={e => setNote(e.target.value)}
                                    />
                                    <img src={message} alt="" className='md:w-6 w-4'


                                    />

                                </div>
                                <p className="text-red-600 mt-2">{noteError}</p>
                                    <div className="flex justify-between items-center">

                                        <div className="flex gap-2 items-center cursor-pointer">

                                            <div className="bg-black border border-white  my-8 p-2 text-center items-center gap-2 rounded-md font-bold  flex">
                                                <button
                                                    type='button'
                                                    onClick={onClickSeven}
                                                    className='text-white font-bold flex items-center justify-center'>OK <img src={mark} alt="" /></button>
                                            </div>
                                            <h4 className='text-white font-bold' onClick={onClickSeven}>Press Enter</h4>
                                        </div>


                                        <div className="flex gap-4 text-white font-extrabold">
                                            <button
                                                onClick={onClickSevenBack}
                                            >
                                                <h2>
                                                <img src={prev} alt="Prev-btn" />
                                                </h2>
                                            </button>

                                            <button
                                                onClick={onClickSeven}
                                            >
                                                <img src={next} alt="Next-btn" />
                                            </button>
                                        </div>
                                    </div>
                            </>
                        ) : ''}

                        {showFieldsEight ? (
                            <>
                                <label className='text-white font-bold mt-8' >Would you like to be covered by insurance on this trip?</label>
                                <div className="flex  items-center mt-4 rounded-lg bg-white relative">
                                    <select
                                        className='w-11/12 bg-transparent p-3 placeholder:text-black rounded-lg md:placeholder:text-sm placeholder:text-xs'
                                        // onBlur={formik.handleBlur}   
                                        onChange={handleInsuranceChange}
                                    >
                                        <option className='md:text-sm text-xx'>Select your preferred choice</option>
                                        <option value='Yes' className='md:text-sm text-xx'>Yes</option>
                                        <option value='No' className='md:text-sm text-xx'>No</option>
                                    </select>

                                    <div class={` ${insuanceInfo ? 'absolute special-occasion-box bg-white rounded-xl p-4 bottom-[-150px] right-[-120px] w-[170px]' : 'hidden'}`}>
                                        <p className='text-black text-center'>
                                        Your insurance coverage is 5% of the price of your service. It covers only this particular transaction on Roadlers.  
                                        </p>
                                    </div>

                                    <img 
                                        src={infoIcon} 
                                        alt="infoIcon" 
                                        className='cursor-pointer absolute right-[70px]' 
                                        onMouseOver={() => setInsuranceInfo(true)}
                                        onMouseOut={() => setInsuranceInfo(false)}
                                    />

                                </div>
                                <div className="flex justify-between items-center">

                                    <div className="flex gap-2 items-center cursor-pointer">

                                        <div className="bg-black border border-white  my-8 p-2 text-center items-center gap-2 rounded-md font-bold  flex">
                                            <button
                                                type='button'
                                                onClick={onClickEight}
                                                className='text-white font-bold flex items-center justify-center'>OK <img src={mark} alt="" /></button>
                                        </div>
                                        <h4 className='text-white font-bold' onClick={onClickEight}>Press Enter</h4>
                                    </div>


                                    <div className="flex gap-4 text-white font-extrabold">
                                        <button
                                            onClick={onClickEightBack}
                                        >
                                            <h2>
                                            <img src={prev} alt="Prev-btn" />
                                            </h2></button>
                                    </div>
                                </div>
                            </>
                        ) : ''}








 
                        
                            <div className="flex gap-5 w-full">
                            {
                                destinationState ? (
                                    ''
                                ) : (
                                    <>
                                        {
                                        isInsured ?
                                        <button type='button' className='mt-8 p-2 rounded-lg border border-white w-5/12 text-white font-bold'>
                                            &#8358;jkjk{((priceBooked) + (priceBooked * 0.05)).toLocaleString('en-US', { maximumFractionDigits: 2 })}
                                        </button>
                                        :
                                        <button type='button' className='mt-8 p-2 rounded-lg border border-white w-5/12 text-white font-bold'>
                                            &#8358;{(priceBooked ).toLocaleString('en-US', { maximumFractionDigits: 2 })}
                                        </button>
                                        }
                                    </>
                                )
                            }

                            {proceed &&
                                (load ? (
                                    <div className="bg-amber-500 p-1 w-full font-semibold rounded-lg mt-8 flex justify-center items-center"><h4 className='text-black text-sm'>Please Wait!</h4><input type="image" src={Spin} value="Loading" className="w-10" /></div>
                                ) : (

                                    <button

                                        type="submit"

                                        className='mt-8 p-2 rounded-lg  bg-amber-500 w-7/12 text-black md:text-base text-sm font-bold'>Proceed to Booking</button>
                                ))
                            }    

                            </div>



                        <div className="md:hidden w-full bg-white p-4 rounded-xl mt-12 flex flex-col gap-1">
                            <h3 className='font-bold'>{title}</h3>
                            <hr className='border-t border-gray-500 w-28' />
                            <div className="flex gap-1 items-center ml-0 pl-0 -ml-2 mt-1">
                                <img src={locationicon} alt="" className='md:w-5 w-4' />
                                <p className='text-xs font-semibold'>{Location}</p>

                            </div>
                            <p className='text-xs font-semibold leading-6'>
                                At Roadlers, our executive drivers are professionally trained individuals dedicated to your safety and comfort. He/she would work with you 
                                <span className='font-bold'> 10 hours daily, extra hours are charged at 1,000 naira hourly. </span>
                            </p>
                            <br />

                            {p1 ? <ul>
                                <li className='text-xs font-semibold leading-6'>&bull; {p1}</li>
                                <li className='text-xs font-semibold leading-6'>&bull; {p2}</li>
                                <li className='text-xs font-semibold leading-6'>&bull; {p3}</li>
                                <li className='text-xs font-semibold leading-6'>&bull; {p4}</li>

                            </ul> : null}
                            <p className='text-xs font-semibold leading-6'><span className='font-bold'>Traveling across Nigeria?</span> make an out of state booking and our support team will assist you in completing your order.</p>



                            <div className="flex justify-between mt-2">
                                <img src={ratings} alt="" className='w-24' />
                                <button className="border border-black rounded-3xl py-1 px-5 text-xs">Verified</button>
                            </div>
                        </div>

                        <div className="flex flex-col p-6 border border-white mt-12 rounded-lg">
                            <div className="flex gap-3 items-center">
                                <img src={logo_circle} alt="" className='w-10' />
                                <h3 className='text-white font-bold'>Roadlers</h3>
                            </div>
                            <h4 className='font-bold my-4 text-white'>Listing Company</h4>
                            <p className='text-white leading-7 md:text-sm text-xs'>This vehicle is listed by Roadlers, on behalf of an accredited partner, accompanied by highly trained drivers and dedicated customer care agents to meet your expectation.</p>

                        </div>
                    </div>
                </form>

            </div>
        </div>
    )
}

export default OneOffDriverComponent