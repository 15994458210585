import apiRequest from "../http-common.js"

const requestGiftCard = (requestData) => {
    return apiRequest.post("/request-gift-card", requestData)
}

const getGiftCardService = () => {
    return apiRequest.get("/gift-cards")
}

const useGiftCardBook = (bookingData) => {
    return apiRequest.post("/gift-card-booking", bookingData)
}

const GiftCardService = {
    requestGiftCard,
    getGiftCardService,
    useGiftCardBook
}

export default GiftCardService;