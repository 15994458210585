import React, { useState, useEffect } from 'react'
import Header from '../Header'
import axios from "axios";
import { BASE_URL } from "../utils/constant"
import getCookie from '../utils/SecuredTokens';

function ProvidersNotification() {
    const [walletAddress, setWalletAddress] = useState('')
    const [status, setStatus] = useState('')
    const [transactions, setTransactions] = useState([])
    
    


    let userData = getCookie("user");
    let userObject = JSON.parse(userData) 
    let token = userObject?.token
    // let id = userObject?.id

    let id = JSON.parse(localStorage.getItem("userId"))

    const getUserWallet = async () => {


        await axios.get(`${BASE_URL}/v1/wallet/get-fiat-wallet/${id}`, {

            headers: {
                'Content-Type': 'application/json',

                Authorization: `Bearer ${token}`,

                "Access-Control-Allow-Origin": "*",


            }
        }).then((response) => {
            const allwalletdatas = response.data.data;
           
            const wallet_address = allwalletdatas.fiat_wallet_address

            setWalletAddress(wallet_address)
      



        });
    };
    useEffect(() => {
        getUserWallet();
    }, []);



    const getTransactions = async () => {
        await axios.get(`${BASE_URL}/v1/bookings-calender/${id}`, {
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',

                Authorization: `Bearer ${token}`,

                "Access-Control-Allow-Origin": "*",


            }
        }).then((response) => {
            const transactions = response.data.data;
            setTransactions(transactions)
            setStatus(transactions.status)
                });
            }

    useEffect(() => {
        getTransactions();

    }, [walletAddress]);




    return (

        <>
            <Header user='provider' />

        
        <div className='flex justify-center h-screen'>
            <div className="md:w-3/12  w-full bg-project p-6 md:mt-32 mt-24 ">

                <h1 className='md:text-2xl text-xl text-white font-semibold text-center'>Transaction History</h1>
                {transactions.map((transaction, id) => (
                <div className="bg-white p-3 mt-12 rounded-md">
                    <div className="flex gap-6 text-red-500 font-bold mb-4">
                        <h4 >Transfer {transaction.status}         </h4>
                        <h4> 12/03/2022</h4>


                    </div>

                    <p className='text-xs md:text-sm leading-6'>
                        You have successfully transferred ₦20,000 to ID-346FTRW, Ojo Ola.
                    </p>
                </div>
                ))}


            </div>
        </div>
        </>
    )
}

export default ProvidersNotification