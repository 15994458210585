import React, { forwardRef, useRef, Fragment, useState, useEffect, memo } from 'react'
import { BASE_URL } from "../utils/constant"

import { Menu, Dialog, Transition } from '@headlessui/react'
import { NavLink, useNavigate } from "react-router-dom";

import locate from '../../Images/locate.png'
import message from '../../Images/message.svg'
import one from '../../Images/one.png'
import header from '../../Images/header.jpg'
import wallet from '../../Images/wallets.png'
import wallets from '../../Images/wallet.png'

import icon from '../../Images/pdficon.png'
import download from '../../Images/download.png'
import meals from '../../Images/meal.png'
import moment from 'moment';
import { saveAs } from "file-saver";

import clock from '../../Images/clock.png'
import transfer from '../../Images/transfer.png'
import unsuccessful from '../../Images/unsuccessful.png'
import success from '../../Images/success.png'
import cancelImg from '../../Images/cancel.svg'

import daily_driver from '../../Images/daily_driver.png';
import drivee from '../../Images/drivee.png';
import dailyGiftCard from '../../Images/gift_faint.png'
import monthlyGiftCard from '../../Images/gift.png'
import Spin from '../../Images/spinner.gif';

import { useReactToPrint } from 'react-to-print';

//from Customers Dashboard
import ReactToPrint from "react-to-print";
import jsPDF from 'jspdf'

import axios from 'axios'
import getCookie from '../utils/SecuredTokens';




function Bookings(props) {


    let navigate = useNavigate();



    let FileSaver = require('file-saver');

    // props coming from the customers dashboard
    let { image,
        date,
        lastname,
        firstname,
        id,
        Address,
        to,
        status,
        price,
        name,
        meal,
        days,
        time,
        paymentStatus,
        notes,
        destination,
        destinationState,
        vehicleId,
        email,
        phone,
        title,
        insurance,
        code,
        receiverEmail,
        bookStatus,
        key_id,
        myBooking,
        username

    } = props




    let mappedDataArray = myBooking



    let [unsuccessfulOpen, setUnsuccessfulOpen] = useState(false)
    let [successfulOpen, setSuccessfulOpen] = useState(false)


    let amount = price
    let insuranceFee = Number(insurance)
    let vat = Number(amount) * 7.5 / 100

    let payStack = 0
    if((amount*0.015)+100>=2000){
        payStack=2000
    }else{
    payStack = (amount ) *0.015 +100

    }
    let subAmount = amount - vat - insuranceFee-payStack

    let amountNum = Number(amount)

    // let pickupDate = new Date(date);
    // let dueDate = Math.abs(new Date() - pickupDate);

    // let duration = moment.duration(dueDate, 'milliseconds');
    // let dayss = Math.round(duration.asDays());

    const mealRide = JSON.parse(localStorage.getItem("mealOffer"))
    console.log(mealRide?.status)

    let [imageBlob, setImageBlob] = useState(header)
    let [walletImageBlob, setWalletImageBlob] = useState(header)

    let [headerBlob, setHeaderBlob] = useState(header)
    let [walletBlob, setWalletBlob] = useState(wallet)
    let [iconBlob, setIconBlob] = useState(icon)


    let [payOpen, setPayOpen] = useState(false)
    let [bg, setBg] = useState(false)
    let [payWallet, setPayWallet] = useState(false)
    const [billingRef, setBillingRef] = useState([]);
    const [totalAmount, setTotalAmount] = useState();
    const [load, setLoad] = useState('')


    let [amounts, setAmounts] = useState(amountNum)


    function payOptionModal(e) {
        e.preventDefault()
        setPayOpen(true)
        setBg(true)
    }
    function payWithWalletOption(e) {
        e.preventDefault()
        setPayOpen(false)
        setBg(true)
        setPayWallet(true)
    }

    function closeModal() {
        setPayOpen(false)
        setBg(false)
        setPayWallet(false)
        setUnsuccessfulOpen(false)
        setSuccessfulOpen(false)

    }
    // convert the header image to blob coz you need to convert image to blob before you use them on jsPDF

    const getImageBlobUrl = async () => {
        const response = await fetch(header);
        const blob = await response.blob();
        const bUrl = URL.createObjectURL(blob);
        setImageBlob((blobUrls) => {
            let newBlobUrls = [blobUrls];
            newBlobUrls = bUrl;
            // return newBlobUrls;
            setHeaderBlob(newBlobUrls)
        });
    };

    // convert the wallet image to blob
    const getWalletBlobUrl = async () => {
        const response = await fetch(wallet);
        const blob = await response.blob();
        const bUrl = URL.createObjectURL(blob);
        setWalletImageBlob((blobUrls) => {
            let newBlobUrls = [blobUrls];
            newBlobUrls = bUrl;
            // return newBlobUrls;
            setWalletBlob(newBlobUrls)
        });
    };

    useEffect(() => {
        getWalletBlobUrl()
    }, []);

    useEffect(() => {
        getImageBlobUrl()
    }, []);



    const getIconBlobUrl = async () => {
        const response = await fetch(icon);
        const blob = await response.blob();
        const bUrl = URL.createObjectURL(blob);
        setIconBlob((blobUrls) => {
            let newBlobUrls = [blobUrls];
            newBlobUrls = bUrl;
            // return newBlobUrls;
            setIconBlob(newBlobUrls)
        });
    };

    useEffect(() => {
        getIconBlobUrl()
    }, []);

    const generatePDF = () => {

        let doc = new jsPDF("p", "mm", "a4");
        let width = doc.internal.pageSize.getWidth();
        let height = doc.internal.pageSize.getHeight();
        let date = new Date()

        //the receipt header image
        doc.addImage(headerBlob, 'JPEG', 0, 0, width, 40)

        //the reciept For header
        doc.text(17, 60, 'RECEIPT FOR')


        doc.addFont('helvetica', 'normal')

        // the receipt header content
        doc.setFontSize(11)

        doc.text(17, 80, `${firstname} ${lastname}`) //start from indent 17 from the left and add 80mm to bottom
        doc.text(80, 80, 'Request ID') //start from indent 80 from the left and add 80mm to bottom
        doc.text(130, 80, `: ${id}`)

        doc.text(17, 90, `${email}`)
        doc.text(80, 90, 'Wallet Name')
        doc.text(130, 90, `: ${username}`)


        doc.text(17, 100, `${phone}`)
        doc.text(80, 100, `Date`)
        doc.text(130, 100, `: ${moment(date).format('LL')}`)


        // the receipt main content
        doc.setFontSize(11)
        doc.rect(0, 129, width, 10, 'F')
        doc.setTextColor(255, 255, 255)
        doc.text(17, 135, 'NO.')
        doc.text(40, 135, 'DESCRIPTION')
        doc.text(100, 135, 'PRICE')
        doc.text(130, 135, 'PERIOD')
        doc.text(160, 135, 'TOTAL')

        doc.setTextColor(0, 0, 0)
        doc.setFontSize(10)


        doc.text(17, 147, '1')
        doc.text(40, 147, name)
        doc.text(100, 147, `#${(subAmount).toLocaleString('en-US', { maximumFractionDigits: 2 })}`)
        doc.text(130, 147, days < 2 ? days + ' Day' : days + ' Days')
        doc.text(160, 147, `#${(subAmount).toLocaleString('en-US', { maximumFractionDigits: 2 })}`)



        // doc.text(100, 160, `Subtotal          : `)
        // doc.text(160, 160, `#${subAmount-(subAmount * 0.015 + 100).toLocaleString('en-US', { maximumFractionDigits: 2 })}`)


        doc.text(100, 168, 'Discount          :')
        doc.text(160, 168, '0.00')

        doc.text(100, 176, '7.5% VAT          :')
        doc.text(160, 176, `#${vat.toLocaleString('en-US', { maximumFractionDigits: 2 })}`)

        doc.text(100, 186, 'Insurance        :')
        doc.text(160, 186, `#${Number(insuranceFee)?.toLocaleString('en-US', { maximumFractionDigits: 2 })}`)



        doc.text(100, 200, 'PayStack        :')
        doc.text(160, 200, `#${Number(payStack)?.toLocaleString('en-US', { maximumFractionDigits: 2 })}`)




        doc.setFontSize(10)
        doc.setDrawColor(234, 150, 3)
        doc.setFillColor(234, 150, 3)
        doc.rect(95, 208, width, 12, 'F')

        doc.text(100, 215, 'Total          :')
        doc.text(160, 215, `#${(amountNum)?.toLocaleString('en-US', { maximumFractionDigits: 2 })}`)





        doc.addImage(walletBlob, 'JPEG', 12, 240, 30, 35)
        doc.setFontSize(8)

        doc.text(43, 260, 'CURRENCY')

        // doc.setFontType('italic')
        doc.text(433, 265, 'Naira')

        doc.setDrawColor(234, 150, 3) //gold line color
        doc.line(0, 270, 120, 270) // horizontal line

        doc.addImage(iconBlob, 'JPEG', 17, 280, 6, 6)
        doc.setFontSize(9)
        doc.text(27, 284, 'EMAIL')
        doc.text(27, 289, 'admin@roadlers.com')


        doc.save('Roadlers.pdf')

        // let document = doc.save('Roadlers.pdf')

    }

   




    let userData = getCookie("user");
    let userObject = JSON.parse(userData)
    let token = userObject?.token


    let [dueDate, setDueDate] = useState('')

    const getDue = async () => {
        await axios({
            method: "get",
            url: `${BASE_URL}/v1/booking-due-date/${key_id}`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            },
        }).then(function (response) {
            // localStorage.removeItem("cart")
            const date = response.data.data;
            setDueDate(date)



        });
    };

    useEffect(() => {
        getDue();

    }, []);




    const payWithThirdParty = async (e) => {
        e.preventDefault();
        setLoad(Spin);




        let formDatas = new FormData();
        formDatas.append('billingReference', myBooking.billing_reference)
        // formDatas.append('amount', parseFloat(total_amount - (total_amount * 0.1) ))

        formDatas.append('amount', parseFloat(myBooking.amount))

        formDatas.append('currency', 'NGN')

        if (parseFloat(myBooking.amount) > 0) {
            axios({
                method: 'post',
                url: `${BASE_URL}/v1/initialize-paystack-transaction`,
                data: formDatas,

                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    "Access-Control-Allow-Origin": "*",
                }
            })
                .then(function (response) {
                    localStorage.removeItem("cart")
                    // setCartUpdate((prevState) => !prevState)


                    setLoad('');
                    console.log('initialize-paystack-transaction', response.data);
                    const respData = response.data.data
                    const url = respData.authorization_url
                    if (url) {
                        window.open(url, "_self")

                    }
                })
                .catch(function (error) {
                    //handle error
                    console.log('yeahhhh', error)


                    setLoad('');
                });
        } else {

        }








    }


    // HANDLE PAY WITH WALLET
    const payWithWallet = async (e) => {
        e.preventDefault();

        setPayOpen(false)
        setLoad(Spin);


        let formDatas = new FormData();
        formDatas.append('billingReference', myBooking.billing_reference)
        // formDatas.append('amount', parseFloat(total_amount - (total_amount * 0.1) ))

        formDatas.append('amount', parseFloat(myBooking.amount))

        formDatas.append('currency', 'NGN')
        if (parseFloat(myBooking.amount) > 0) {
            axios({
                method: 'post',
                url: `${BASE_URL}/v1/pay-with-wallet`,
                data: formDatas,

                headers: {
                    'Content-Type': 'application/json',

                    Authorization: `Bearer ${token}`,
                    "Access-Control-Allow-Origin": "*",
                }
            })
                .then(function (response) {
                    setLoad('');

                    const respData = response.data.data
                    const url = respData.authorization_url
                    if (url) {
                        window.open(url, "_self")
                    }
                    // handleNotificationAlertIcon(currentNoteLength.length)
                    // dispatch(mealOffers()).then((response) => {
                    //     console.log(response.payload.data.status, 'meal offer')
                    //     setMealOfferData(response.payload.data)
                    //     // localStorage.setItem("mealOffer", JSON.stringify(response.payload.data))

                    //     // const mealRide = JSON.parse(localStorage.getItem("mealOffer"))
                    //     // console.log(mealRide?.status, "let's see")
                    // })
                    localStorage.removeItem("cart")
                    // setCartUpdate((prevState) => !prevState)

                    // setSuccessfulOpen(true)
                    navigate('/transaction-success')

                })
                .catch(function (error) {
                    //handle error
                    console.log(error)
                    setLoad('');



                    if (error.response.data.message) {
                        setUnsuccessfulOpen(true)
                    }

                });

        } else {

        }

    }

    return (
        <div>

            <Transition appear show={payOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed flex items-center justify-center w-full px-6 mt-8  inset-0 z-10 overflow-y-auto"
                    onClose={closeModal}
                >
                    <div className="min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="w-10/12 inline-block  max-w-md pt-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-md">
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg font-medium leading-6 text-gray-900"
                                >
                                    <img src={transfer} alt="" />
                                </Dialog.Title>

                                <div className="bg-project p-6">

                                    <form>


                                        <div className="mt-2">


                                            <div className="flex items-center text-sm mt-1 p-2 w-full rounded-md border border-white  text-white">
                                                &#8358;
                                                <input
                                                    type="text"
                                                    placeholder='Amount'
                                                    className=' md:text-sm text-xs w-full bg-transparent placeholder:text-xs   focus:outline-none text-white'
                                                    disabled
                                                    value={myBooking.amount.toLocaleString('en-US', { maximumFractionDigits: 2 })}
                                                    onChange={e => setAmounts(e.target.value)}
                                                />


                                            </div>
                                        </div>


                                        <div className="mt-4 w-full">



                                            <button
                                                type="submit"
                                                className="inline-flex w-full justify-center px-4 py-2 text-sm font-bold text-black bg-amber-500 border border-transparent rounded-md  focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                                                // onClick={e => payWithWallet(e)}
                                                onClick={e => payWithWalletOption(e)}
                                            >
                                                Pay with Wallet
                                            </button>
                                        </div>


                                        <div className="mt-4 w-full">



                                            {load ? (
                                                <div
                                                    className="inline-flex items-center justify-center w-full justify-center px-4 py-1 text-sm font-bold  bg-white border border-transparent rounded-md  focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"

                                                >
                                                    <h4 className="text-black text-xs">Please Wait !</h4>
                                                    <input
                                                        type="image"
                                                        src={Spin}
                                                        value="Loading"
                                                        className="w-8"
                                                        alt="loading..."
                                                    />
                                                </div>
                                            ) :

                                                (<button
                                                    type="submit"
                                                    className="inline-flex w-full justify-center px-4 py-2 text-sm font-bold  bg-white border border-transparent rounded-md  focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                                                    onClick={e => payWithThirdParty(e)}
                                                >
                                                    Pay with Bank
                                                </button>)}
                                        </div>


                                    </form>

                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>


            <Transition appear show={payWallet} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed flex items-center justify-center w-full px-6 mt-8  inset-0 z-10 overflow-y-auto"
                    onClose={closeModal}
                >
                    <div className="min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="w-10/12 inline-block  max-w-md pt-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-md">
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg font-medium leading-6 text-gray-900"
                                >
                                    <img src={wallets} alt="" />
                                </Dialog.Title>

                                <div className="bg-project border border-gray-600 p-6">
                                    <form>
                                        <div className="mt-4 w-full">
                                            <button
                                                type="submit"
                                                className="inline-flex w-full justify-center px-4 py-2 text-sm font-bold text-black bg-white border border-transparent rounded-md  focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                                                onClick={e => payWithWallet(e)}
                                            >
                                                From Naira Wallet
                                            </button>
                                        </div>

                                        <div className="mt-4 w-full">
                                            <button
                                                type="submit"
                                                className="inline-flex w-full justify-center px-4 py-2 text-sm font-bold text-black bg-amber-500 border border-transparent rounded-md  focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                                            // onClick={handlePayWithCrypto}
                                            >
                                                From Crypto Wallet
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>

            <Transition appear show={successfulOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed flex items-center justify-center w-full px-6 mt-8  inset-0 z-10 overflow-y-auto"
                    onClose={closeModal}
                >
                    <div className="min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="w-10/12 inline-block  max-w-md pt-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-md">
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg font-medium leading-6 text-gray-900"
                                >
                                </Dialog.Title>
                                <div className="bg-white pb-4 pr-4 pt-1 pl-4">
                                    <div className="flex w-full justify-end">
                                        <button className=''>
                                            <img src={cancelImg} className='w-[30px]' onClick={closeModal} alt="Cancel Modal" />
                                        </button>
                                    </div>

                                    <img src={success} alt="" />
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>




            <Transition appear show={unsuccessfulOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed flex items-center justify-center w-full px-6 mt-8  inset-0 z-10 overflow-y-auto"
                    onClose={closeModal}
                >
                    <div className="min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="w-10/12 inline-block  max-w-md pt-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-md">
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg font-medium leading-6 text-gray-900"
                                >
                                </Dialog.Title>

                                <div className="bg-white p-4">
                                    <div className="flex w-full justify-end">
                                        <button className=''>
                                            <img src={cancelImg} className='w-[30px]' onClick={closeModal} alt="Cancel Modal" />
                                        </button>
                                    </div>

                                    <img src={unsuccessful} alt="" />
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
            <div className="flex md:hidden border rounded-lg cart-bg p-5 gap-3 mb-12 mt-12">
                <div className="flex flex-col  w-7/12">
                    <h4 className='text-white text-xs font-semibold  w-full tracking-widest'>{name}</h4>
                    <hr className="border-t border-gray-400 w-4/5 mt-2" />


                    <div className="flex items-center mb-8 -ml-2 mt-2">
                        <img src={locate} alt="" className='w-5 ' />

                        {destination === "Out of State" ?
                            (
                                <p className='text-white text-xx w-3/4 leading-5 tracking-widest'>{Address} to {destinationState}</p>

                            ) : (
                                <p className='text-white text-xx w-3/4 leading-5 tracking-widest'>{Address}</p>

                            )
                        }
                        {/* {Address ? <p className='text-white text-xx w-3/4 leading-5'>{Address}</p> : <p className='text-white text-xs w-3/4 leading-5'>{to}</p>} */}

                    </div>


                    {
                        !receiverEmail ?
                            <>
                                {
                                    vehicleId ?
                                        <div className="w-11/12 flex items-center justify-center h-full bg-white rounded-md">
                                            <img src={`https://api.roadlers.com/storage/${image}`} alt="driverOne" className='rounded-md h-11/12 w-full' />
                                        </div> :
                                        <div className="w-4/5 flex items-center justify-center bg-white rounded-md ">
                                            <img src={name === 'Daily Driver' ? daily_driver : drivee} alt="" className='w-full' />
                                        </div>

                                }
                            </>
                            :
                            <div className="w-4/5 flex items-center justify-center bg-project rounded-md ">
                                <img src={name === 'Daily Driver' ? dailyGiftCard : monthlyGiftCard} alt="" className='w-full' />
                            </div>

                    }

                    <div className="flex items-center w-full mt-8">


                        <Menu as="div" className="relative inline-block text-left">


                            {
                                notes ? (
                                    <div className="relative">
                                        <Menu.Button className="inline-flex w-full justify-center rounded-md  py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                                            <img src={message} alt="" className='w-8 -ml-1 ' />


                                        </Menu.Button>
                                        <div className="flex items-center justify-center absolute top-2 left-3 bg-red-600 rounded-full p-1 h-3 w-3 text-xxx text-white">i</div>

                                    </div>
                                ) : (
                                    <img src={message} alt="" className='w-8 -ml-1 ' />


                                )
                            }






                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items className="absolute left-8 right-0 mt-2 w-44 p-4 origin-top-right divide-y divide-gray-100 rounded-md bg-black shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <div className="px-1 py-1 flex flex-col gap-4 items-center justify-center" >
                                        <Menu.Item>

                                            <div className="flex items-center gap-4">

                                                <p className="text-white">{notes}</p>
                                            </div>



                                        </Menu.Item>

                                    </div>

                                </Menu.Items>
                            </Transition>
                        </Menu>




                        <p className=' font-semibold theme-color text-xxx w-full ml-4 tracking-widest'>ID: {id}</p>

                    </div>
                </div>
                <div className="flex flex-col w-5/12 gap-2">

                    <div className='text-xs bg-transparent rounded-md border border-white w-full text-center py-2 focus:outline-none text-white'>
                        <label className='text-center text-white font-semibold tracking-widest'>{days < 2 ? days + ' Day' : days + ' Days'}</label>

                    </div>

                    <div className="flex items-center gap-2">
                        <img src={one} alt="" className='w-4' />
                        <h4 className='text-white font-normal text-xx tracking-widest'>{moment(date).format("DD/MM/YYYY")}</h4>
                    </div>

                    <div className="flex items-center gap-2">
                        <img src={clock} alt="" className='-ml-1 w-6' />
                        <h4 className='text-white font-normal text-xx tracking-widest'>{time}</h4>
                    </div>

                    {
                        destination === "Out of State" ? (
                            <h4 className='text-white font-normal text-xx tracking-widest tracking-widest'>{destination}</h4>

                        ) : (
                            <h4 className='text-white font-normal text-xx tracking-widest'>{destination}</h4>

                        )
                    }


                    {
                        paymentStatus === "PAID" ? (


                            <div className="flex flex-col gap-2  items-center w-full mt-6">

                                <div className="flex items-center justify-start gap-2 w-full mt-4">

                                    <button
                                        onClick={generatePDF}
                                    ><img src={download} alt="" className='w-6' /></button>

                                    <h5 className='font-bold text-amber-500 text-xs tracking-widest'>&#8358;{amountNum.toLocaleString('en-US', { maximumFractionDigits: 2 })}</h5>

                                </div>


                                {

                                    bookStatus === 'Pending' ?
                                        (<button className='w-full bg-amber-500 font-bold text-black p-2 text-xs rounded-lg mt-3'>In Progress</button>)
                                        :
                                        bookStatus === 'In Progress' ?
                                            (
                                                <button className='w-full bg-amber-500 font-bold text-black p-2 text-xs rounded-lg mt-3'>{bookStatus}</button>

                                            ) :
                                            bookStatus === 'Closed' ? (
                                                <button className='w-full bg-green-700 font-bold text-white p-2 text-xs rounded-lg mt-3'>{bookStatus}</button>

                                            ) :
                                                bookStatus === 'Open' ? (
                                                    <button className='w-full bg-amber-500 font-bold text-black p-2 text-xs rounded-lg mt-3'>Booked</button>

                                                )
                                                    : ''

                                }



                                {dueDate === 'Due' ?
                                    <h4 className='text-amber-500  text-sm font-bold'>
                                        {dueDate}
                                    </h4> : <h4 className='text-amber-500  text-sm font-bold'>
                                        Due in {dueDate}
                                    </h4>
                                }


                            </div>
                        ) : (

                            <div className="flex flex-col gap-2  items-center w-full mt-6">

                                <div className="flex items-center justify-start gap-2 w-full mt-4">



                                    <h5 className='font-bold text-amber-500 text-xs tracking-widest'>&#8358;{amountNum.toLocaleString('en-US', { maximumFractionDigits: 2 })}</h5>

                                </div>

                                <button
                                    onClick={payOptionModal}

                                    className='mt-4 bg-red-700 w-full border font-bold border-white text-white p-1 py-2 text-sm rounded-lg'>Pay Now</button>







                            </div>
                        )
                    }


                </div>




            </div>


            {/* //for desktop */}
            <div className="hidden md:flex md:border rounded-xl cart-bg p-8 gap-7 mb-12 mt-12">
                <div className="w-4/12">

                    {
                        !to ?
                            <>
                                {
                                    vehicleId ?
                                        <div className="flex items-center justify-center bg-white p-1 rounded-xl  w-full h-full">

                                            <img src={`https://api.roadlers.com/storage/${image}`} alt="" className='rounded-md w-full md:w-full object-cover object-center' />

                                        </div> :
                                        <div className="w-1/5 flex items-center justify-center p-4 bg-white rounded-md ">
                                            <img src={name !== 'Daily Driver' ? drivee : daily_driver} alt="" className='w-full' />
                                        </div>

                                }
                            </>
                            :
                            <div className="w-1/5 flex items-center justify-center p-4 bg-project rounded-md ">
                                <img src={name !== 'Daily Driver' ? monthlyGiftCard : dailyGiftCard} alt="" className='w-full' />
                            </div>

                    }
                </div>

                <div className="flex flex-col w-1/5 gap-1">
                    <h4 className='text-white text-sm font-semibold  pb-1 w-3/4'>{name}</h4>
                    <hr className='border-t border-white w-24' />

                    <div className="flex items-center mb-4 -ml-2 mt-2">
                        <img src={locate} alt="" className='w-6 ' />

                        {Address ?
                            (
                                destination === "Out of State" ?
                                    (
                                        <p className='text-white text-xs w-3/4 leading-5'>{Address} to {destinationState}</p>

                                    ) : (
                                        <p className='text-white text-xs w-3/4 leading-5'>{Address}</p>

                                    )
                            )
                            : <p className='text-white text-xs w-3/4 leading-5'>
                                {to}
                            </p>}


                    </div>

                    <div className="flex items-center">

                        <Menu as="div" className="relative inline-block text-left">


                            {
                                notes ? (
                                    <div className="relative">
                                        <Menu.Button className="inline-flex w-full justify-center rounded-md  py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                                            <img src={message} alt="" className='w-6 -ml-1 mr-2' />

                                        </Menu.Button>
                                        <div className="flex items-center justify-center absolute top-2 left-3 bg-red-600 rounded-full p-1 h-3 w-3 text-xxx text-white">i</div>

                                    </div>
                                ) : (
                                    <img src={message} alt="" className='w-6 -ml-1 mr-2' />

                                )
                            }






                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items className="absolute right-0 mt-2 w-44 p-4 origin-top-right divide-y divide-gray-100 rounded-md bg-black shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <div className="px-1 py-1 flex flex-col gap-4 items-center justify-center" >
                                        <Menu.Item>

                                            <div className="flex items-center gap-4">

                                                <p className="text-white">{notes}</p>
                                            </div>



                                        </Menu.Item>

                                    </div>

                                </Menu.Items>
                            </Transition>
                        </Menu>



                        <p className='mr-2 font-semibold theme-color mr-4 text-xs'>{id}</p>

                    </div>

                </div>
                {

                    to ?

                        (
                            <div className="flex flex-col gap-4 w-1/5">

                                <div className="text-sm bg-transparent rounded-md border border-white w-3/4 text-center py-1 focus:outline-none text-white">
                                    <p>{status < 2 ? status + ' Day' : status + ' Days'}</p>
                                </div>

                                <div className="flex items-center gap-2">

                                    <h4 className='text-white font-semibold text-xs'>Card Link</h4>
                                </div>

                                <div className="flex items-center gap-2">
                                    <input
                                        type="text" name="" id=""
                                        placeholder='http://www.roa...'
                                        className="placeholder:text-xs text-sm bg-white rounded-md border border-white w-3/4 py-1 px-2 focus:outline-none"
                                        value={
                                            name === 'Daily Driver' ?
                                                `https://roadlers.com/one-off-driver/${code}` :
                                                `https://roadlers.com/full-time-driver/${code}`
                                        }
                                    />
                                </div>

                            </div>) : (
                            <div className="flex flex-col gap-4 w-1/5">

                                <div className="text-sm bg-transparent rounded-md border border-white w-3/4 text-center py-1 focus:outline-none text-white">
                                    <p> {status < 2 ? status + ' Day' : status + ' Days'}</p>
                                </div>

                                <div className="flex items-center gap-2">
                                    <img src={one} alt="" className='w-5' />
                                    <h4 className='text-white font-semibold text-xs'>{moment(date).format("DD/MM/YYYY")}</h4>
                                </div>

                                <div className="flex items-center gap-2">
                                    <img src={clock} alt="" className='w-6' />
                                    <h4 className='text-white font-semibold text-xs'>{time}</h4>
                                </div>

                                <h4 className='text-white font-normal text-xs'>{destination}</h4>


                            </div>
                        )}




                <div className="flex flex-col items-center justify-center gap-4  w-1/5">


                    <h5 className='font-semibold text-amber-500 text-sm'>&#8358;{amountNum.toLocaleString('en-US', { maximumFractionDigits: 2 })}</h5>

                    {mealRide?.status === "ACTIVE" ? <img src={meals} alt="" className='w-16' /> : ''}

                </div>






                {to ?
                    (
                        <div className="flex flex-col gap-4  justify-center items-center w-1/5">
                            <button className='w-full bg-amber-500 font-bold text-black p-2 text-sm rounded-lg mt-3'>Copy</button>

                            <button


                            ><img src={download} alt="" className='w-6' /></button>



                        </div>
                    ) : (

                        paymentStatus === 'PAID' ? (
                            <div className="flex flex-col gap-4  items-center justify-center w-1/5">
                                {/* <button className='w-full bg-red-700 font-bold text-white p-2 text-sm rounded-lg mt-3'>Due</button> */}

                                {

                                    bookStatus === 'Pending' ?
                                        (<button className='w-full bg-amber-500 font-bold text-black p-2 text-xs rounded-lg mt-3'>In Progress</button>)
                                        :
                                        bookStatus === 'In Progress' ?
                                            (
                                                <button className='w-full bg-amber-500 font-bold text-black p-2 text-xs rounded-lg mt-3'>{bookStatus}</button>

                                            ) :
                                            bookStatus === 'Closed' ? (
                                                <button className='w-full bg-green-700 font-bold text-white p-2 text-xs rounded-lg mt-3'>{bookStatus}</button>

                                            ) :
                                                bookStatus === 'Open' ? (
                                                    <button className='w-full bg-amber-500 font-bold text-black p-2 text-xs rounded-lg mt-3'>Booked</button>

                                                )
                                                    : ''

                                }


                                <button
                                    onClick={generatePDF}
                                ><img src={download} alt="" className='w-6' /></button>





                                {dueDate === 'Due' ?
                                    <h4 className='text-amber-500  text-sm font-bold'>
                                        {dueDate}
                                    </h4> : <h4 className='text-amber-500  text-sm font-bold'>
                                        Due in {dueDate}
                                    </h4>
                                }




                            </div>
                        ) : (
                            <div className="flex   flex-col gap-4 justify-center items-center w-1/5 h-full">

                                <button
                                    onClick={payOptionModal}

                                    className='mt-12 bg-red-700 w-full border font-bold border-white text-white p-1 py-2 text-sm rounded-lg'>Pay Now</button>



                                {/* <button
                                    onClick={generatePDF}

                                ><img src={download} alt="" className='w-6' /></button>
 */}


                            </div>
                        )




                    )
                }


            </div>

        </div>
    )
}

export default memo(Bookings)