import React from 'react'
import Header from '../Header'

import cartwhite from '../../Images/cart-white.svg';
import daily_driver from '../../Images/daily_driver.png';
import car from '../../Images/asset2.png';
import monthly from '../../Images/monthly.png';
import airport from '../../Images/airport.png';

import GiftCardComponent from './GiftCardComponent';
import RelatedService from '../RelatedService';
import Ratings from '../Ratings';
import google from '../../Images/google.png'
import gift_faint from '../../Images/gift_faint.png'
import gift from '../../Images/gift.png'
import ratings from '../../Images/ratings.svg'
function GiftCardRequest() {
  return (
    <div>
            <Header user='customer' />

              <div className="mt-16">
                <GiftCardComponent
                    textOne="A personal driver booking link will be sent to the receiver via email and SMS to use the services any day they need it. We would also let the receiver know that the gift is from you!"  
                    textTwo="At Roadlers all our executive drivers are highly trained professional individuals, supported by dedicated customer care agents to meet your needs."
                    monthTitle="Monthly Drivers Gift Card"
                    dayTitle="Daily Drivers Gift Card"
                    Location="Lagos"
                    priceTwo="₦12,000"
                    gift="gift"
                    monthImage={gift}
                    dayImage={gift_faint}
                    listing="This vehicle is listed by Roadlers, accompanied by highly trained chauffeurs and dedicated customer care agents to meet your expectations.GSO Drive is a professional chauffeur company based in Lagos."
                />
            </div>



            <div className="md:mt-40 mt-16 mb-8 md:px-56 px-6">
                <h5 className='md:font-bold  md:text-xl font-semibold text-sm text-white '>Related Services</h5>
                <hr className='md:w-28 w-12 md:mt-2 marg border-t border-white md:mb-12 mb-4' />
            </div>


            <div className=" flex gap-6 items-center justify-center grid lg:grid-cols-4 md:px-56 px-6 grid-cols-2">



                <RelatedService
                    image={airport}
                    name="Car Rental From ₦25,000"
                    status="View Offer"

                />
                <RelatedService
                    image={monthly}
                    name="Monthly full-time driving service "
                    status="Access Offer"

                />

                <RelatedService
                    image={airport}
                    name="Travel driving service "
                    status="Access Offer"

                />

                <RelatedService
                    image={airport}
                    name="Airport Pickups"
                    status="Learn More"

                />
            </div>



            <div className="mt-16 mb-8 md:px-56 px-6">
                <h5 className='md:font-bold  md:text-xl font-semibold text-sm text-white '>Reviews</h5>
                <hr className='md:w-28 w-12 md:mt-2 marg border-t border-white md:mb-12 mb-4' />
            </div>


            <div className=" md:flex mt-12 gap-12 md:px-56 mb-20 px-6">
            {/* <Ratings 
                name="Chinyere" 
                google={google}
                ratings={ratings}
                text="The driver did a good job. He was professional and he drove really well too. The people I went with also attested to that."
                initial="C"
                add
                

            /> */}


            
            

        </div>




    </div>
  )
}

export default GiftCardRequest