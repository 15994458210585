import React, { useEffect,useState, useRef } from 'react'


import cartwhite from '../../Images/cart-white.svg';
import daily_driver from '../../Images/daily_driver.png';
import ratings from '../../Images/ratings.svg'
import locationicon from '../../Images/location.svg'
import message from '../../Images/message.png'
import mark from '../../Images/mark.png'
import logo_circle from '../../Images/logo-circle.png'
import like from '../../Images/Like-icon.png'
import gift_faint from '../../Images/gift_faint.png'
import drivee from '../../Images/drivee.png'
import prev from '../../Images/prev.png'
import onee from '../../Images/onee.png'
import next from '../../Images/next.png'
import user from '../../Images/userg.png'
import send from '../../Images/send.png'
import clockb from '../../Images/clockb.png'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import Spin from '../../Images/spinner.gif';
import TopForm from '../TopForm'
import { BASE_URL } from "../utils/constant"
import { ToastContainer, toast } from 'react-toastify';

import { useCookies } from 'react-cookie';
import { useDispatch } from 'react-redux';
import { giftCardRequest } from '../../slices/giftcard';

import {useLocation} from "react-router-dom"


function GiftCardComponent(props) {
    let navigate = useNavigate();
    const dispatch = useDispatch()
    const [cookies, setCookie, removeCookie] = useCookies();

    // console.log('cookies.access_token', cookies.gift_type)

    const giftType = cookies.gift_type;
    const giftPrice = cookies.gift_price

    const [dayPrice, setDayPrice] = useState(giftPrice)
    const [load, setLoad] = useState(false)

    const { title, textOne, textTwo, dayTitle, monthTitle, Location, monthImage, dayImage, priceTwo, gift, type, p1, p2, p3, p4, listing } = props

    const [proceed, setProceed] = useState(false)
    const [noOfMonth, setNoOfMonth] = useState()
    const [monthValue, setMonthValue] = useState()

    const refDiv = useRef()

    const [width, setWidth] = useState(7)
    const [status, setStatus] = useState('Start Booking - Let’s Move')
    let [question, setQuestion] = useState(1)
    const [allQuestion, setAllQuestion] = useState(6)
    const [linkId, setLinkId] = useState('')
    const [showFieldsTwo, setshowFieldsTwo] = useState(false)

    const monthPrice = giftPrice * noOfMonth

    const onClickStart = (e) => {
        e.preventDefault()
        setshowFieldsOne(true)
        setshowFieldsTwo(false)
        setshowFieldsThree(false)
        setshowFieldsFour(false)
        setshowFieldsFive(false)
        // setshowFieldsFiveb(false)
        setshowFieldsSix(false)
        setQuestion(question - 1)
        setWidth(10)
    }

    const onClickOneBack = (e) => {
        e.preventDefault()
        setshowFieldsOne(false)

        setshowFieldsTwo(true)
        setshowFieldsThree(false)
        setshowFieldsFour(false)
        setshowFieldsFive(false)
        // setshowFieldsFiveb(false)
        setshowFieldsSix(false)
        setQuestion(question - 1)
        console.log('1 back', question)

        setWidth(20)

    }

    const onClickFiveBack = (e) => {
        e.preventDefault()
        setshowFieldsOne(false)

        setshowFieldsTwo(false)
        setshowFieldsThree(false)
        setshowFieldsFour(false)
        setshowFieldsFive(false)
        setshowFieldsFive(true)
        setshowFieldsSix(false)
        setQuestion(question - 1)
        console.log('five back', question)
        setWidth(70)

    }


    const onClickTwoBack = (e) => {
        e.preventDefault()


        setshowFieldsThree(true)
        setshowFieldsOne(false)
        setshowFieldsTwo(false)
        setshowFieldsFour(false)
        setshowFieldsFive(false)
        // setshowFieldsFiveb(false)
        setshowFieldsSix(false)
        setQuestion(question - 1)
        console.log('2 back', question)

        setWidth(40)


    }


    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const [receiverMail, setReceiverMail] = useState("")
    const [receiverMailError, setReceiverMailError] = useState("")
    const onClickTwo = (e) => {
        e.preventDefault()

        if(receiverMail?.length === 0) {
            setReceiverMailError("This is required")

            setTimeout(() => {
                setReceiverMailError("")
            }, 5000)
        }else if(!receiverMail?.match(mailformat)){
            setReceiverMailError("This mail is invalid")

            setTimeout(() => {
                setReceiverMailError("")
            }, 5000)
        }
        else {
            setshowFieldsTwo(false)
            setshowFieldsThree(true)
            setQuestion(++question)
            setWidth(36)
            setStatus('Almost Done - Keep Moving')
        }

    }


    const onClickThreeBack = (e) => {
        e.preventDefault()
        setshowFieldsOne(false)

        setshowFieldsTwo(false)
        setshowFieldsThree(false)
        setshowFieldsFour(true)
        setshowFieldsFive(false)
        // setshowFieldsFiveb(false)
        setshowFieldsSix(false)
        setQuestion(question - 1)
        console.log('3 back', question)
        setWidth(50)


    }

    const [receiverName, setReceiverName] = useState("")
    const [receiverNameError, setReceiverNameError] = useState("")
    const [showFieldsOne, setshowFieldsOne] = useState(true)
    const onClickOne = (e) => {
        e.preventDefault()

        if(receiverName?.length === 0 || !receiverName) {
            setReceiverNameError("This is required")

            setTimeout(() => {
                setReceiverNameError("")
            }, 5000)
        }else if(receiverName?.trim().length < 3) {
            setReceiverNameError("Must be 3 Characters or more")

            setTimeout(() => {
                setReceiverNameError("")
            }, 5000)
        } 
        else {
            e.preventDefault()
        
            setshowFieldsOne(false)
    
            setshowFieldsTwo(true)
            setQuestion(++question)
            setWidth(20)
    
            setStatus('Start Booking - Let’s Move')
        }


    }

    const phoneRegExp = /^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/
    const [phoneNo, setPhoneNo] = useState("")
    const [phoneNoError, setPhoneNoError] = useState("")
    const [showFieldsThree, setshowFieldsThree] = useState(false)
    const onClickThree = (e) => {
        e.preventDefault()

        if(phoneNo?.length === 0 || !phoneNo) {
            setPhoneNoError("This is required")

            setTimeout(() => {
                setPhoneNoError("")
            }, 5000)
        }else if(!phoneNo?.match(phoneRegExp)) {
            setPhoneNoError("This number is not correct")

            setTimeout(() => {
                setPhoneNoError("")
            }, 5000)
        }else {
            setshowFieldsThree(false)
            setshowFieldsFour(true)
    
            setQuestion(++question)
            setWidth(60)
            setStatus('Almost Done - Keep Moving')
        }

    }

    const [noOfDays, setNoOfDays] = useState()
    const [noOfDaysError, setNoOfDaysError] = useState("")
    const [showFieldsFour, setshowFieldsFour] = useState(false)
    const onClickFour = (e) => {
        e.preventDefault()

        if(giftType?.includes('Month')) {
            if(noOfMonth?.length === 0 || !noOfMonth) {
                setNoOfDaysError("This is required")

                setTimeout(() => {
                    setNoOfDaysError("")
                }, 5000)
            }else {
                setshowFieldsFour(false)
                setshowFieldsFive(true)
                setQuestion(++question)
                setWidth(75)
                setStatus('Almost Done - Keep Moving')
            }
        }else {
            if(noOfDays === "" || !noOfDays) {
                setNoOfDaysError("This is required")
    
                setTimeout(() => {
                    setNoOfDaysError("")
                }, 5000)
            }else {
                setshowFieldsFour(false)
                setshowFieldsFive(true)
                setQuestion(++question)
                setWidth(75)
                setStatus('Almost Done - Keep Moving')
            }
        }


    }


    const [emailNotification, setEmailNotification] = useState("")
    const [emailNotificationError, setEmailNotificationError] = useState("")
    const [showFieldsFive, setshowFieldsFive] = useState(false)
    const onClickFive = (e) => {
        e.preventDefault()

        if(emailNotification?.length === 0) {
            setEmailNotificationError("This is required")

            setTimeout(() => {
                setEmailNotificationError("")
            }, 5000)
        }else {
            setshowFieldsFive(false)
            setshowFieldsSix(true)
            setQuestion(++question)
            setWidth(100)
            setStatus('Completed')
        }

    }

    const [customerNote, setCustomerNote] = useState("")
    const [showFieldsSix, setshowFieldsSix] = useState(false)
    const [total, setTotal] = useState(0)

    const onClickSix = () => {
        setProceed(true)
    }

  


    const giftCardAmountFunc = () => {
        if (giftType?.includes('Month')) {
            return Number(giftPrice * noOfMonth)
        } else {
            return dayPrice
        }
    }

    const giftCardTypeName = () => {
        if (giftType?.includes('Month')) {
            return 'Full-Time Driver'
        } else {
            return 'Daily Driver'
        }
    }

    // destination choice 
    const pickDest = () => {
        if(giftType?.includes('Month')) {
            return ''
        } else {
            return '12000'
        }
    }

    const pickSetDest = pickDest()

    // Get currentdate
    const currentDate = new Date();
    const dateUsed = `${currentDate.getFullYear()}-${currentDate.getMonth()+1}-${currentDate.getDate()}`;
    console.log(dateUsed.toString())

    let giftCardAmount = giftCardAmountFunc()
    let giftCardTitle = giftCardTypeName()
    
    // HANDLE CONDITIONS FOR CHANGE OF MONTH
    const handleMonthChange = (e) => {

        if (e.target.value === '1') {
            setMonthValue(1)
        }else if (e.target.value === '2') {
            setMonthValue(2)
        }else if (e.target.value === '3') {
            setMonthValue(3)
        }else if (e.target.value === '4') {
            setMonthValue(4)
        }else if (e.target.value === '5') {
            setMonthValue(5)
        }else if (e.target.value === '6') {
            setMonthValue(6)
        }else if (e.target.value === '7') {
            setMonthValue(7)
        }else if (e.target.value === '8') {
            setMonthValue(8)
        }else if (e.target.value === '9') {
            setMonthValue(9)
        }else if (e.target.value === '10') {
            setMonthValue(10)
        }else if (e.target.value === '11') {
            setMonthValue(11)
        }else if (e.target.value === '12') {
            setMonthValue(12)
        }else {
            setMonthValue(1)
        }
    }


// HANDLE CONDITIONS FOR CHANGE OF DAY
   const handleDayChange = (e) => {

    if (e.target.value === '1') {
        setDayPrice(12000)
    }else if (e.target.value === '2') {
        setDayPrice(24000)
    }else if (e.target.value === '3') {
        setDayPrice(36000)
    }else if (e.target.value === '4') {
        setDayPrice(40000)
    }else if (e.target.value === '5') {
        setDayPrice(45000)
    }else if (e.target.value === '6') {
        setDayPrice(49000)
    }else if (e.target.value === '7') {
        setDayPrice(50000)
    }else if (e.target.value === '8') {
        setDayPrice(51000)
    }else if (e.target.value === '9') {
        setDayPrice(52000)
    }else if (e.target.value === '10') {
        setDayPrice(53000)
    }else if (e.target.value === '11') {
        setDayPrice(54000)
    }else if (e.target.value === '12') {
        setDayPrice(55000)
    }else if (e.target.value === '13') {
        setDayPrice(56000)
    }else if (e.target.value === '14') {
        setDayPrice(58000)
    }else if (e.target.value === '15') {
        setDayPrice(60000)
    }else if (e.target.value === '16') {
        setDayPrice(61000)
    }else if (e.target.value === '17') {
        setDayPrice(63000)
    }else if (e.target.value === '18') {
        setDayPrice(65000)
    }else if (e.target.value === '19') {
        setDayPrice(67000)
    }else if (e.target.value === '20') {
        setDayPrice(70000)
    }else if (e.target.value === '21') {
        setDayPrice(75000)
    }else if (e.target.value === '22') {
        setDayPrice(80000)
    }else if (e.target.value === '23') {
        setDayPrice(82000)
    }else if (e.target.value === '24') {
        setDayPrice(83000)
    }else if (e.target.value === '25') {
        setDayPrice(84000)
    }else if (e.target.value === '26') {
        setDayPrice(85000)
    }

   }


   const handleCardSubmit = (e) => {
    e.preventDefault()
    const formData = new FormData();

    formData.append('gift_card_type', 'MONTH')
    formData.append('receiver_name', receiverName)
    formData.append('receiver_email', receiverMail)
    formData.append('receiver_phone_no', phoneNo)
    formData.append('number_of_days', noOfDays)
    formData.append('receiver_email_notifcation', emailNotification)
    formData.append('note', customerNote)
    formData.append('amount', dayPrice)
    formData.append('pickup_date', dateUsed)
    formData.append('pickup_time', '')
    formData.append('booking_title', giftCardTitle)
    formData.append('month_value',noOfMonth )

    
    const token = JSON.parse(localStorage.getItem("userToken"))

    let bookingDetails = {
        'category': 'Gift Card',
        'booking_title': giftCardTitle,
        // 'pickup_location': values.receiver_name && values.receiver_email,
        'num_of_day':  noOfDays,
        'amount': giftCardAmount,
        'destination': '',
        'giftcard_receiver_name': receiverName,
        'giftcard_receiver_email': receiverMail,
        'note': customerNote,
        'giftcard_receiver_phone_no': phoneNo,
        'pickup_date': dateUsed,
        'daily_price': pickSetDest,
        'linkId': linkId,
        'month_value': noOfMonth,
        'monthly_price': giftPrice
        // 'book_picture': monthImage || dayImage
    }

    let cartExist = JSON.parse(localStorage.getItem("cart"));
    if (cartExist == null) {
        cartExist = [];
    }

    cartExist.push(bookingDetails)
    cartExist.map((item, i) => {
        item.id = i + 1;
    });
    localStorage.setItem("cart", JSON.stringify(cartExist));
    console.log(cartExist)
    navigate('/cart')

    // axios({
    //     method: 'post',
    //     url: `${BASE_URL}/v1/request-gift-card`,
    //     data: formData,
    //     headers: {
    //         'Content-Type': 'application/json',
    //         Authorization: `Bearer ${token}`,
    //         "Access-Control-Allow-Origin": "*",
    //     }
    // }).then(function (response) {
    //     //handle success
    //     setLoad(true);

    //     let cartExist = JSON.parse(localStorage.getItem("cart"));
    //     if (cartExist == null) {
    //         cartExist = [];
    //     }

    //     cartExist.push(bookingDetails)
    //     cartExist.map((item, i) => {
    //         item.id = i + 1;
    //     });
    //     localStorage.setItem("cart", JSON.stringify(cartExist));
    //     console.log(cartExist)
    //     navigate('/cart')

    // }).catch(function (error) {
    //     //handle error
    //     let notify = () => { toast.error((error.response.data.message), {
    //         toastClassName: 'error'
    //     } ) };
    //     notify()
    //     // window.location.reload()
    // })
    setLoad(false);
}

    return (

        <div>
        <TopForm/>
        <ToastContainer />
          

            <div className="flex md:flex-row flex-col gap-12 md:px-28 px-8 mt-16 md:w-10/12 w-full  m-auto">
                <div className="flex flex-col md:w-5/12 w-full ">


                    <div className="relative w-11/12 border rounded-xl border-gray-300 p-14 flex">
                        {giftType?.includes('Month') ? <>
                            <img src={monthImage} alt="" className='w-full items-center justify-center' />
                            <button className='absolute  top-4 left-4'> <img src={like} alt="" className='w-10' /></button>
                        </> : <>
                            <img src={dayImage} alt="" className='w-full items-center justify-center' />
                            <button className='absolute  top-4 left-4'> <img src={like} alt="" className='w-10' /></button>
                        </>
                        }
                    </div>







                    {giftType?.includes('Month') ? (
                        <div className="flex flex-col w-10/12 mt-6 mb-8 md:hidden">
                            <h3 className='text-amber-500 font-semibold text-sm '> Monthly Drivers Gift Card</h3>
                            <hr className='border-gray-500 border-t mt-2 w-7/12 ' />
                        </div>
                    ) : (
                        <div className="flex flex-col w-10/12 mt-6 mb-8 md:hidden">
                            <h3 className='text-amber-500 font-semibold text-sm '> Daily Drivers Gift Card</h3>
                            <hr className='border-gray-500 border-t mt-2 w-7/12 ' />
                        </div>
                    )



                    }




                    <div className="w-11/12 bg-white p-4 rounded-xl mt-4 md:flex hidden flex-col gap-1">

                        {giftType?.includes('Month') ?
                            (<h3 className='font-bold'>{monthTitle}</h3>) : (<h3 className='font-bold'>{dayTitle}</h3>)

                        }


                        <hr className='border-t border-gray-500 w-28' />
                        <div className="flex gap-1 items-center ml-0 pl-0 -ml-2">
                            <img src={locationicon} alt="" className='md:w-5 w-4' />
                            <p className='text-xs font-semibold'>{Location}</p>

                        </div>
                        <p className='text-xs font-semibold leading-6'>{textOne}</p>
                        <br />
                        {p1 ? <ul>
                            <li className='text-xs font-semibold leading-6'>&bull; {p1}</li>
                            <li className='text-xs font-semibold leading-6'>&bull; {p2}</li>
                            <li className='text-xs font-semibold leading-6'>&bull; {p3}</li>
                            <li className='text-xs font-semibold leading-6'>&bull; {p4}</li>

                        </ul> : null}

                        <p className='text-xs font-semibold leading-6'>{textTwo}</p>


                        <div className="flex justify-between mt-2">
                            <img src={ratings} alt="" className='w-32' />
                            <button className="border border-black rounded-3xl py-1 px-5 text-xs">Verified</button>
                        </div>
                    </div>
                </div>


                <form  className='md:w-6/12 '>
                    <div className="flex flex-col  w-full">
                        <h4 className=' text-amber-500 font-medium md:font-semibold'>{status}</h4>

                        <div className="w-full mt-12 relative">
                            <input type="range" min="1" max={allQuestion} value={question} className="slider" id="myRange" />
                            <p className='text-white text-sm font-semibold absolute -top-6 left-0'>{question}/{allQuestion}</p>
                            <p className='text-white text-sm  font-semibold absolute -top-6 right-0'>{allQuestion}</p>

                            <div
                                id="background"

                            ></div>
                            <div
                                id="progress"
                                ref={refDiv}
                                style={{ width: width + '%' }}
                            >
                            </div>


                        </div>

                        {type ? (
                            <div className="flex bg-white p-3 justify-between items-center mt-12 rounded-xl">
                                <input type="text" className='w-11/12  px-1 placeholder:text-black' placeholder='Add A Note:' />
                                <img src={message} alt="" className='md:w-9 w-6' />

                            </div>
                        ) : ''}

                        {showFieldsOne && gift ? (
                            <>
                                <div className="flex justify-between bg-white items-center mt-12 rounded-lg">
                                    <input type="text"
                                        className='w-full   bg-transparent p-3 rounded-lg placeholder:text-black'
                                        placeholder="Receiver's Name"

                                        name="receiver_name"
                                        id="receiver_name"
                                        onChange={(e) => setReceiverName(e.target.value)}
                                        value={receiverName}
                                    />

                                </div>
                                <p className='text-red-600 text-left text-xs w-full mt-3 mb-4'>{receiverNameError}</p>

                                <div className="flex gap-2 items-center justify-between">
                                    <div className="flex gap-2 items-center cursor-pointer">
                                        <div className="bg-black border border-white  my-8 p-2 text-center items-center gap-2 rounded-md font-bold  flex">
                                            <button onClick={onClickOne} className='text-white font-bold flex items-center justify-center'>OK <img src={mark} alt="" /></button>
                                        </div>
                                        <h4 className='text-white font-bold'>Press Enter</h4>
                                    </div>

                                    <div className="flex gap-4 text-white font-extrabold">
                                        <button
                                            onClick={onClickOne}
                                        >
                                            <img src={next} alt="Next-btn" />
                                        </button>
                                    </div>
                                </div>
                            </>
                        ) : ''}
                        {showFieldsTwo && gift ?
                            (
                                <>


                                    <div className="flex bg-white  justify-between items-center mt-12 rounded-lg">

                                        <input type="email"
                                            className='w-full p-3 bg-transparent rounded-lg placeholder:text-black bg-transparent'
                                            placeholder="Receiver's Email Address"
                                            name="receiver_email"
                                            id="receiver_email"
                                            onChange={(e) => setReceiverMail(e.target.value)}
                                            value={receiverMail}
                                        />

                                    </div>
                                    <p className='text-red-600 text-left text-xs w-full mt-3 mb-4'>{receiverMailError}</p>

                                    <div className="flex items-center justify-between">

                                        <div className="flex gap-2 items-center cursor-pointer">
                                            <div className="bg-black border border-white  my-8 p-2 text-center items-center gap-2 rounded-md font-bold  flex">
                                                <button onClick={onClickTwo} className='text-white font-bold flex items-center justify-center'>OK <img src={mark} alt="" /></button>


                                            </div>
                                            <h4 className='text-white font-bold'>Press Enter</h4>
                                        </div>

                                        <div className="flex gap-4 text-white font-extrabold">
                                            <button
                                                onClick={onClickStart}
                                            >
                                                <h2>
                                                   <img src={prev} alt="Prev-btn" />
                                                </h2></button>
                                            <button
                                                onClick={onClickTwo}
                                            >
                                                <img src={next} alt="Next-btn" />
                                            </button>
                                        </div>
                                    </div>
                                </>) : ''}

                        {showFieldsThree && gift ? (
                            <>
                                <div className="flex bg-white  justify-between items-center mt-12 rounded-lg">
                                    <input type="phone"
                                        className='w-full  p-3 rounded-lg placeholder:text-black bg-transparent'
                                        placeholder="Receiver's Phone Number"
                                        name="receiver_phone_no"
                                        id="receiver_phone_no"
                                        onChange={(e) => setPhoneNo(e.target.value)}
                                        value={phoneNo}
                                    />

                                </div>

                                <p className='text-red-600 text-left text-xs w-full mt-3 mb-4'>{phoneNoError}</p>

                                <div className="flex items-center justify-between">

                                    <div className="flex gap-2 items-center cursor-pointer">
                                        <div className="bg-black border border-white  my-8 p-2 text-center items-center gap-2 rounded-md font-bold  flex">
                                            <button onClick={onClickThree} className='text-white font-bold flex items-center justify-center'>OK <img src={mark} alt="" /></button>
                                        </div>
                                        <h4 className='text-white font-bold'>Press Enter</h4>
                                    </div>

                                    <div className="flex gap-4 text-white font-extrabold">
                                        <button
                                            onClick={onClickOneBack}
                                        >
                                            <h2>
                                               <img src={prev} alt="Prev-btn" />
                                            </h2></button>
                                        <button

                                            onClick={onClickThree}
                                        >
                                            <img src={next} alt="Next-btn" />
                                        </button>
                                    </div>

                                </div>

                           
                            </>
                        ) : ''}


                        {showFieldsFour && gift ? (
                            <>
                                <div className="flex  justify-between items-center mt-12 rounded-lg bg-white">
                                {
                                        giftType?.includes('Month') ? 

                                        <select
                                        className='w-11/12 bg-transparent  p-3 placeholder:text-black rounded-lg md:placeholder:text-sm placeholder:text-xs'
                                        name="number_of_days"
                                        id="number_of_days"
                                        onChange={(e) => setNoOfMonth(e.target.value)}
                                        value={noOfMonth}
                                    >
                                        <option className='md:text-sm text-xx'>Number of Months</option>
                                        <option value='1' className='md:text-sm text-xx'>1 Month</option>
                                        <option value='2' className='md:text-sm text-xx'>2 Months</option>
                                        <option value='3' className='md:text-sm text-xx'>3 Months</option>
                                        <option value='4' className='md:text-sm text-xx'>4 Months</option>
                                        <option value='5' className='md:text-sm text-xx'>5 Months</option>
                                        <option value='6' className='md:text-sm text-xx'>6 Months</option>
                                        <option value='7' className='md:text-sm text-xx'>7 Months</option>
                                        <option value='8' className='md:text-sm text-xx'>8 Months</option>
                                        <option value='9' className='md:text-sm text-xx'>9 Months</option>
                                        <option value='10' className='md:text-sm text-xx'>10 Months</option>
                                        <option value='11' className='md:text-sm text-xx'>11 Months</option>
                                        <option value='12' className='md:text-sm text-xx'>12 Months</option>
                                        </select> :

                                        <select
                                            className='w-11/12 bg-transparent  p-3 placeholder:text-black rounded-lg md:placeholder:text-sm placeholder:text-xs'
                                            name="number_of_days"
                                            id="number_of_days"
                                            onChange={(e) => setNoOfDays(e.target.value)}
                                            value={noOfDays}
                                        >
                                            <option className='md:text-sm text-xx'>Number of Days</option>
                                            <option value='1' className='md:text-sm text-xx'>1 Day</option>
                                            <option value='2' className='md:text-sm text-xx'>2 Days</option>
                                            <option value='3' className='md:text-sm text-xx'>3 Days</option>
                                            <option value='4' className='md:text-sm text-xx'>4 Days</option>
                                            <option value='5' className='md:text-sm text-xx'>5 Days</option>
                                            <option value='5' className='md:text-sm text-xx'>6 Days</option>
                                            <option value='7' className='md:text-sm text-xx'>7 Days</option>
                                            <option value='8' className='md:text-sm text-xx'>8 Days</option>
                                            <option value='9' className='md:text-sm text-xx'>9 Days</option>
                                            <option value='10' className='md:text-sm text-xx'>10 Days</option>
                                            <option value="11" className='md:text-sm text-xx'>11 Days</option>
                                            <option value="12" className='md:text-sm text-xx'>12 Days</option>
                                            <option value="13" className='md:text-sm text-xx'>13 Days</option>
                                            <option value="14" className='md:text-sm text-xx'>14 Days</option>
                                            <option value="15" className='md:text-sm text-xx'>15 Days</option>
                                            <option value="16" className='md:text-sm text-xx'>16 Days</option>
                                            <option value="17" className='md:text-sm text-xx'>17 Days</option>
                                            <option value="18" className='md:text-sm text-xx'>18 Days</option>
                                            <option value="19" className='md:text-sm text-xx'>19 Days</option>
                                            <option value="20" className='md:text-sm text-xx'>20 Days</option>
                                            <option value="21" className='md:text-sm text-xx'>21 Days</option>
                                            <option value="22" className='md:text-sm text-xx'>22 Days</option>
                                            <option value="23" className='md:text-sm text-xx'>23 Days</option>
                                            <option value="24" className='md:text-sm text-xx'>24 Days</option>
                                            <option value="25" className='md:text-sm text-xx'>25 Days</option>
                                            <option value="26" className='md:text-sm text-xx'>26 Days</option>
                                        </select>
                                }
                                </div>

                                <p className='text-red-600 text-left text-xs w-full mt-3 mb-4'>{noOfDaysError}</p>

                                <div className="flex items-center justify-between">

                                    <div className="flex gap-2 items-center cursor-pointer">
                                        <div className="bg-black border border-white  my-8 p-2 text-center items-center gap-2 rounded-md font-bold  flex">
                                            <button onClick={onClickFour} className='text-white font-bold flex items-center justify-center'>OK <img src={mark} alt="" /></button>
                                        </div>
                                        <h4 className='text-white font-bold'>Press Enter</h4>
                                    </div>

                                    <div className="flex gap-4 text-white font-extrabold">
                                        <button
                                            onClick={onClickTwoBack}
                                        >
                                            <h2>
                                               <img src={prev} alt="Prev-btn" />
                                            </h2></button>
                                        <button

                                            onClick={onClickFour}
                                        >
                                            <img src={next} alt="Next-btn" />
                                        </button>
                                    </div>

                                </div>

                                
                            </>
                        ) : ''}

                        {
                            showFieldsFive && gift ? (
                                <>
                                    <div className="flex bg-white p-3 justify-between items-center mt-12 rounded-xl">
                                        <select

                                            className='w-full bg-transparent outline-none md:text-sm text-xs py-1 focus:outline-none  px-1 placeholder:text-black'
                                            name="receiver_email_notifcation"
                                            id="receiver_email_notifcation"
                                            onChange={(e) => setEmailNotification(e.target.value)}
                                            value={emailNotification}
                                        >
                                            <option className='md:text-sm text-xx'>Send receiver a notification of gift card</option>
                                            <option value='Yes' className='md:text-sm text-xx'>Yes</option>
                                            <option value='No' className='md:text-sm text-xx' >No</option>
                                        </select>

                                    </div>
                                    <p className='text-red-600 text-left text-xs w-full mt-3 mb-4'>{emailNotificationError}</p>

                                    <div className="flex justify-between items-center">

                                        <div className="flex gap-2 items-center cursor-pointer">
                                            <div className="bg-black border border-white  my-8 p-2 text-center items-center gap-2 rounded-md font-bold  flex">
                                                <button onClick={onClickFive} className='text-white font-bold flex items-center justify-center'>OK <img src={mark} alt="" /></button>
                                            </div>
                                            <h4 className='text-white font-bold'>Press Enter</h4>
                                        </div>


                                        <div className="flex gap-4 text-white font-extrabold">
                                            <button
                                                onClick={onClickThreeBack}

                                            >
                                                <h2>
                                                   <img src={prev} alt="Prev-btn" />
                                                </h2></button>
                                            <button

                                                onClick={onClickFive}
                                            >
                                                <img src={next} alt="Next-btn" />
                                            </button>
                                        </div>
                                </div>
                                </>
                            ) : ''
                        }


                        {showFieldsSix ? (
                            <>
                                <div className="flex bg-white p-3 justify-between items-center mt-12 rounded-xl">
                                    <input type="text"
                                        className='w-11/12  px-1 placeholder:text-black'
                                        placeholder='Add A Note'
                                        name="note"
                                        id="note"
                                        onChange={(e) => setCustomerNote(e.target.value)}
                                        value={customerNote}
                                    />
                                    <img src={message} alt="" className='md:w-6 w-4'

                                    />

                                </div>

                                {load ? (<div className="w-2/4 bg-black border border-white  my-8 p-2 text-center items-center gap-2 rounded-md font-bold  flex"><h4 className='text-white text-sm'>Please Wait!</h4><input type="image" src={Spin} value="Loading" className="w-10" /></div>) :
                                    (

                                        <div className="flex justify-between items-center">

                                            <div className="flex gap-2 items-center cursor-pointer">

                                            <div className="bg-black border border-white  my-8 p-2 text-center items-center gap-2 rounded-md font-bold  flex">
                                                <button
                                                    type='button'
                                                    onClick={onClickSix}
                                                    className='text-white font-bold flex items-center justify-center'>OK <img src={mark} alt="" /></button>
                                            </div>
                                            <h4 className='text-white font-bold' onClick={onClickSix}>Press Enter</h4>
                                        </div>



                                            <div className="flex gap-4 text-white font-extrabold">
                                                <button
                                                    onClick={onClickFiveBack}
                                                >
                                                    <h2>
                                                       <img src={prev} alt="Prev-btn" />
                                                    </h2></button>
                                            </div>

                                </div>
                                    )
                                }

                            </>
                        ) : ''}



                        {proceed ? (<div className="flex gap-5 w-full">
                            {/* <button className='mt-8 p-2 rounded-lg border border-white w-5/12 text-white font-bold'> &#8358;{(total).toLocaleString('en-US', { maximumFractionDigits: 2 })}</button> */}
                            {load ? (
                                <div className="bg-amber-500 p-1 w-full font-semibold rounded-lg mt-8 flex justify-center items-center"><h4 className='text-black text-sm'>Please Wait!</h4><input type="image" src={Spin} value="Loading" className="w-10" /></div>
                            ) : (
                                <button
                                    onClick={(e) => handleCardSubmit(e)}
                                    className='mt-8 p-2 rounded-lg  bg-amber-500 w-7/12 text-black md:text-base text-sm font-bold'>
                                        Proceed to Booking
                                    </button>
                            )}
                        </div>) : (null)
                        }



                        <div className="flex">
                            {giftType?.includes('Month') ? (
                                <button className='mt-8 p-2 rounded-xl border border-white w-32 text-white font-bold'>&#8358; {
                                    monthPrice ? monthPrice.toLocaleString('en-US', { maximumFractionDigits: 2 }) : '0'
                                }
                                </button>) :
                                (<button className='mt-8 p-2 rounded-xl border border-white w-32 text-white font-bold'>&#8358;
                                    {
                                        noOfDays ? (dayPrice ? (dayPrice * noOfDays).toLocaleString('en-US', { maximumFractionDigits: 2 }) : '0') : '0'
                                    }
                                </button>)
                            }
                        </div>


                        <div className="md:hidden w-full bg-white p-4 rounded-xl mt-12 flex flex-col gap-1">



                            {giftType?.includes('Month') ? (
                                <h3 className='font-bold'> Monthly Drivers Gift Card</h3>
                            ) : (
                                <h3 className='font-bold'>Daily Drivers Gift Card</h3>
                            )
                            }




                            <hr className='border-t border-gray-500 w-28' />
                            <div className="flex gap-1 items-center ml-0 pl-0 -ml-2 mt-1">
                                <img src={locationicon} alt="" className='md:w-5 w-4' />
                                <p className='text-xs font-semibold'>{Location}</p>

                            </div>
                            <p className='text-xs font-semibold leading-6'>{textOne}</p>
                            <br />

                            {p1 ? <ul>
                                <li className='text-xs font-semibold leading-6'>&bull; {p1}</li>
                                <li className='text-xs font-semibold leading-6'>&bull; {p2}</li>
                                <li className='text-xs font-semibold leading-6'>&bull; {p3}</li>
                                <li className='text-xs font-semibold leading-6'>&bull; {p4}</li>

                            </ul> : null}
                            <p className='text-xs font-semibold leading-6'>{textTwo}</p>



                            <div className="flex justify-between mt-2">
                                <img src={ratings} alt="" className='w-24' />
                                <button className="border border-black rounded-3xl py-1 px-5 text-xs">Verified</button>
                            </div>
                        </div>

                        <div className="flex flex-col p-6 border border-white mt-12 rounded-lg">
                            <div className="flex gap-3 items-center">
                                <img src={logo_circle} alt="" className='w-10' />
                                <h3 className='text-white font-bold'>Roadlers</h3>
                            </div>
                            <h4 className='font-bold my-4 text-white'>Listing Company</h4>
                            <p className='text-white leading-7 md:text-sm text-xs'>{listing}</p>

                        </div>
                    </div>

                </form>

            </div>
        </div >
    )
}

export default GiftCardComponent