import { createSlice } from "@reduxjs/toolkit";
const initialState = [];
const carInitSlice = createSlice({
  name: "carInit",
  initialState,
  reducers: {
    setCarInit: (state, action) => {
     return {...state, data: action.payload,};
        // state.carForm = payload.carForm;
    },
      clearCarInit: () => {
      return []
    },
  },
});
const { reducer, actions } = carInitSlice;
export const { setCarInit } = actions
export default reducer;