
import React, { Fragment, useState, useEffect, useContext, memo } from 'react'

import { Dialog, Transition } from '@headlessui/react'
import CurrencyInput from 'react-currency-input-field';
import axios from "axios";
import { BASE_URL } from "../utils/constant"
import getCookie from '../utils/SecuredTokens';

const VehicleMargin = ({ vehiclemargin }) => {
    let [marginOpen, setMarginOpen] = useState(false)
    let [bg, setBg] = useState(false)

    function closeModal() {
        setMarginOpen(false)
        setBg(false)
    }

    function marginModal() {
        setMarginOpen(true)
        setBg(true)

    }

    let userData = getCookie("user");
    let userObject = JSON.parse(userData) 
    let token = userObject?.token
   

    let [minimum, setMinimum] = useState(vehiclemargin?.minimum)
    let [maximum, setMaximum] = useState(vehiclemargin?.maximum)
    const [pricingType, setPricingType] = useState(vehiclemargin?.pricing_type);

    let [marginPrice, setMarginPrice] = useState(vehiclemargin?.commission)


    const updateVehicleMargin = async () => {
        let formData = new FormData();
        formData.append("title", 'Vehicle Price Margin');
        formData.append("id", vehiclemargin.id);

        formData.append("heading", 'Vehicle Price Margin');
        formData.append("price_category", 'vehicle_price');
        formData.append("pricing_type", pricingType);
        formData.append("commission", marginPrice);
        // formData.append("description", );
       
        formData.append("minimum", minimum);
        formData.append("maximum", maximum);
        // formData.append("period", insurancePeriod);
        await axios({
            method: "post",
            url: `${BASE_URL}/v1/update-pricing`,
            data: formData,
            headers: {
                'Content-Type': 'application/json',

                Authorization: `Bearer ${token}`,

                "Access-Control-Allow-Origin": "*",


            }
        })
            .then((response) => {
                const respData = response.data.message;
                console.log('respData', respData)



                // window.location.reload(true)

            })
            .catch((error) => {
                console.log(error);
            });
    };



    return (
        <div>

            <Transition appear show={marginOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="w-full md:w-11/12  fixed flex justify-center items-center px-6 inset-0 z-10 overflow-y-auto"
                    onClose={closeModal}
                >
                    <div className="w-full xl:w-5/12 lg:w-6/12 md:w-11/12 min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-25 bg-white"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-25"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="w-full md:w-11/12 inline-block  max-w-md  overflow-hidden text-left align-middle transition-all transform shadow-xl rounded-2xl">


                                <div className="bg-black p-10">
                                    <h3 className="text-white mb-8">Update Vehicle Margin Price</h3>

                                    <form action="" className="flex xl:flex-row md:flex-row flex-col gap-4 items-center gap-4 ">


                                        <CurrencyInput
                                            prefix='₦'

                                            allowNegativeValue={false}
                                            className='w-full text-white p-2 bg-transparent border-b border-white '

                                            value={minimum}
                                            onValueChange={(value) => {
                                                setMinimum(value)
                                            }}
                                        />

                                        <CurrencyInput
                                            prefix='₦'
                                            allowNegativeValue={false}
                                            value={maximum}
                                            className='w-full text-white p-2 bg-transparent border-b border-white '

                                            onValueChange={(value) => {
                                                setMaximum(value)
                                            }}
                                        />




                                        

                                        <select name="" id=""
                                            className='w-full text-white p-2 bg-black border-b border-white '
                                            onChange={e => setPricingType(e.target.value)}

                                        >
                                            <option value={pricingType}>{pricingType}</option>
                                            <option value="FLAT">Flat</option>
                                            <option value="PERCENTAGE">Percentage</option>
                                        </select>

                                        {pricingType === 'FLAT' ? (
                                        <CurrencyInput
                                            prefix='₦'
                                            allowNegativeValue={false}
                                            value={marginPrice}
                                            className='w-full text-white p-2 bg-transparent border-b border-white '

                                            onValueChange={(value) => {
                                                setMarginPrice(value)
                                            }}
                                        />
                                    ) : (
                                        <CurrencyInput
                                            suffix='%'
                                            allowNegativeValue={false}
                                            value={marginPrice}
                                            className='w-full text-white p-2 bg-transparent border-b border-white '

                                            onValueChange={(value) => {
                                                setMarginPrice(value)
                                            }}
                                        />
                                    )

                                    }
                                        <button

                                            type="button"
                                            onClick={updateVehicleMargin}

                                            className='rounded-md text-center  py-2 px-2 text-black text-xs font-bold bg-amber-500'>

                                            Update</button>
                                    </form>





                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>


            <div className="  flex  gap-4 items-center   px-0  p-2 ">
                <div className="w-3/12 text-white justify-center flex-col flex items-center ">
                    <p className="text-amber-500">Minimum</p>
                    <input

                        value={'₦' + Number(vehiclemargin?.minimum).toLocaleString('en-US', { maximumFractionDigits: 2 })}

                        className='w-full p-1 curr rounded-md border border-white cart-bg text-center text-white  md:text-base  text-sm font-semibold'
                    />

                </div>

                <div className="w-3/12 text-white justify-center flex flex-col items-center ">
                    <p className="text-amber-500">Maximum</p>


                    {vehiclemargin?.maximum === 'undefined'?(
                        <input
                        value='>'
                        className='w-full  p-1 curr rounded-md border border-white cart-bg text-center text-white  md:text-base  text-sm font-semibold'
                    />
                    ):(
                        <input
                        value={'₦' + Number(vehiclemargin?.maximum).toLocaleString('en-US', { maximumFractionDigits: 2 })}
                        className='w-full  p-1 curr rounded-md border border-white cart-bg text-center text-white  md:text-base  text-sm font-semibold'
                    />
                    )}
                    

                </div>

                <div className="mt-4 xl:w-3/12 lg:4/12 md:5/12">

                    {vehiclemargin?.pricing_type === 'FLAT' ? (
                        <input
                            value={'₦' + Number(vehiclemargin?.commission).toLocaleString('en-US', { maximumFractionDigits: 2 })
                            }
                            className='w-full  bg-transparent  text-center text-amber-500  md:text-base  text-sm font-semibold'
                        />
                    ) : (

                        <input

                            value={vehiclemargin?.commission + '%'}
                            className='w-full bg-transparent   text-center text-amber-500  md:text-base  text-sm font-semibold'
                        />
                    )
                    }

                </div>

                <button
                    onClick={marginModal}
                    type="submit" className='mt-4 xl:w-2/12 md:w-3/12 bg-white  xl:p-1 md:p-2 p-2 text-black font-semibold  text-sm rounded-md '>Change</button>


            </div>
        </div>
    )
}

export default memo(VehicleMargin)