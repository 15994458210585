import apiRequest from "../http-common"

const createWallet = (walletData) => {
  return apiRequest.post("/create-wallet", walletData)
}

const createWithdrawal = (walletData) => {
    return apiRequest.post("/create-withdrawal", walletData)
}

const cancelWithdrawal = (walletData, withdrawal_id) => {
    return apiRequest.post(`/cancel-withdrawal/${withdrawal_id}`, walletData)
}

const fetchCryptoCurrency = () => {
    return apiRequest.get("/crypto-currencies")
}

const fetchCryptoWalletDetails = (currency) => {
    return apiRequest.get(`crypto-wallet-info/${currency}`)
}

const fetchCryptoWalletTransactions = () => {
    return apiRequest.get("/")
}

const walletTransfer = (transferData) => {
    return apiRequest.post("/", transferData)
}
  

const CreateWalletService = {
    createWallet,
    createWithdrawal,
    cancelWithdrawal,
    fetchCryptoCurrency,
    fetchCryptoWalletDetails,
    walletTransfer,
    fetchCryptoWalletTransactions
}

export default CreateWalletService
