import { BrowserRouter as Router, Routes, Route, useHistory } from 'react-router-dom';
import { Disclosure } from '@headlessui/react'
import { ChevronUpIcon } from '@heroicons/react/solid'
import ReactLogo from './Images/one.png';
import Footer from './Components/Footer'
import SuperAdmin from './Components/SuperAdmin/SuperAdmin';
import SuperAdminRegister from './Components/SuperAdmin/SuperAdminRegister';
import RegisterAdmin from './Components/SuperAdmin/RegisterAdmin';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';


// import Admin from './Components/Admin/Admin';
// import Admin from './Components/Admin/Admin';
import ScreenAssets from './Components/Admin/ScreenAssets';
import ScreenDriver from './Components/Admin/Screendriver';
import AdminCartComponent from './Components/Admin/AdminCartComponent';
import AdminCustomerRequestsComponent from './Components/Admin/AdminCustomerRequestsComponent';

import ProtectedRoute from './Auth/ProtectedRoute'
import AdminProtectedRoute from './Auth/AdminProtectedRoute'
import SuperAdminProtectedRoute from './Auth/SuperAdminProtectedRoute'
import ServiceProviderProtectedRoute from './Auth/ServiceProviderProtectedRoute'



// Customers
import Register from './Components/Customers/Register';

import Terms from './Components/Customers/Terms';

import UserProfile from './Components/Customers/UserProfile';
import FulltimeDriver from './Components/Customers/FulltimeDriver';
import GiftCardRequest from './Components/Customers/GiftCardRequest';
import OneOffDriver from './Components/Customers/OneOffDriver';
import OneOffDriverGift from './Components/Customers/OneOffDriverGft';
import CustomersDashboard from './Components/Customers/CustomersDashboard';
import CarBooking from './Components/Customers/CarBooking';
import Cart from './Components/Customers/Cart';
import SearchPage from './Components/Customers/SearchPage';


// Providers
import ServiceProvider from './Components/Providers/ServiceProvider';
import IndividualAccount from './Components/Providers/IndividualAccount';
import DriverCarRegistration from './Components/Providers/DriverCarRegistration';
import ServiceProviderDashboard from './Components/Providers/ServiceProviderDashboard';
import BusinessAccount from './Components/Providers/BusinessAccount';
import PendingNotice from './Components/Providers/PendingNotice';
import Rejected from './Components/Providers/Rejected';
import Accepted from './Components/Providers/Accepted';
import AcceptOnce from './Components/Providers/AcceptOnce';

import PendingApproval from './Components/Providers/PendingApproval';
import Onboarding from './Components/Providers/Onboarding';
import ProvidersNotification from './Components/Providers/ProvidersNotification';


import TopForm from './Components/TopForm';


import ApprovedService from './Components/ApprovedService';
import ApprovedServiceCompany from './Components/ApprovedServiceCompany';

import Home from './Components/Home';
import TransactionSuccess from './Components/TransactionSuccess';
import TransactionFailed from './Components/TransactionFailed';
import ResetPassword from './Components/ResetPassword';
import TransactionHistory from './Components/TransactionHistory';


import Login from './Components/Login';
import Log from './Components/Log';

import store from './store'
import { Provider } from 'react-redux';
import { DataProvider } from './Context/DataContext'
import { CustomerProvider } from './Context/CustomerContext'
import { useContext, useEffect, lazy, Suspense } from "react";
// import { NotificationProvider } from './Context/NotificationContext';
import FulltimeDriverGift from './Components/Customers/FullTimeDriverGift';
import IndividualAccountReg2 from './Components/Providers/IndividualAccountReg2';
import PendingApprovalEdit from './Components/Providers/PendingApprovalEdit';
import ApprovedServiceEdit from './Components/Providers/ApprovedServiceEdit';
import DriverRegisteration from './Components/Providers/DriverRegistration';
import ReactGA from 'react-ga';
import CarOwnerDetails from './Components/Admin/CarOwnerDetails';
import CookiePolicy from './Components/CookiePolicy';
import PrivacyPolicy from './Components/PrivacyPolicy';
const Admin = lazy(() => import('./Components/Admin/Admin'));


const TRACKING_ID = "UA-238384512-1"; // OUR_TRACKING_ID

ReactGA.initialize(TRACKING_ID);


export default function App() {
  let persistor = persistStore(store);

  

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          {/* <NotificationProvider> */}
          <Router>
            <DataProvider>
              <CustomerProvider>
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="home" element={<Home />} />
                  <Route path="full-time-driver" element={<FulltimeDriver />} />
                  <Route path="full-time-driver/:code" element={<FulltimeDriverGift />} />
                  <Route path="one-off-driver" element={<OneOffDriver />} />
                  <Route path="one-off-driver/:code" element={<OneOffDriverGift />} />
                  <Route path="gift-card-request" element={<GiftCardRequest />} />
                  <Route path="car-booking/:id" element={<CarBooking />} />
                  <Route path="search" element={<SearchPage />} />

                  <Route path="rejected" element={<Rejected />} />
                  <Route path="accepted" element={<Accepted />} />
                  <Route path="accepted_status" element={<AcceptOnce />} />



                  {/* CUSTOMERS PROTECTED ROUTES*/}

                  {/* <Route path="customers-dashboard" element={<CustomersDashboard />} /> */}

                  <Route path='/' element={<ProtectedRoute />}>
                    <Route path="customers-dashboard" element={<CustomersDashboard />} />
                    <Route path="transaction-success" element={<TransactionSuccess />} />
                    <Route path="transaction-failed" element={<TransactionFailed />} />
                    <Route path="transaction-history" element={<TransactionHistory />} />
                    <Route path="user-profile" element={<UserProfile />} />
                  </Route>

                  {/* SERVICE PROVIDERS PROTECTED ROUTES*/}
                  <Route path='/' element={<ServiceProviderProtectedRoute />}>
                    <Route path="service-provider-dashboard" element={<ServiceProviderDashboard />} />
                    <Route path="onboarding" element={<Onboarding />} />
                    <Route path="pending-notice" element={<PendingNotice />} />
                    <Route path="driver-car-registration" element={<DriverCarRegistration />} />
                    <Route path="pending-approval" element={<PendingApproval />} />
                    <Route path="pending-approval-edit/:vehicleId" element={<PendingApprovalEdit />} />
                    <Route path="individual-account-registration" element={<IndividualAccount />} />
                    <Route path="individual-account-registration-2" element={<IndividualAccountReg2 />} />
                    <Route path="business-account-registration" element={<BusinessAccount />} />
                    <Route path="approved-service" element={<ApprovedService />} />
                    <Route path="approved-service-edit/:providerId" element={<ApprovedServiceEdit />} />
                    <Route path="approved-service-company" element={<ApprovedServiceCompany />} />
                    <Route path="driver-registeration" element={<DriverRegisteration />} />
                  </Route>




                  {/* ADMIN PROTECTED ROUTES*/}

                  <Route path='/' element={<AdminProtectedRoute />}>

                    <Route path="Admin" element={
                      <Suspense fallback={<div>...loading</div>}>
                        <Admin />
                      </Suspense>
                    } />

                    <Route path="Screenassets/:id" element={<ScreenAssets />} />
                    <Route path="Screendriver/:id" element={<ScreenDriver />} />
                    <Route path="carowner/:id" element={<CarOwnerDetails />} />
                    <Route path="admin-cart-customer" element={<AdminCartComponent />} />
                    <Route path="admin-requests-customer" element={<AdminCustomerRequestsComponent />} />
                  </Route>



                  {/* SUPERADMIN PROTECTED ROUTES*/}
                  <Route path='/' element={<SuperAdminProtectedRoute />}>
                    <Route path="superadmin" element={<SuperAdmin />} />
                    <Route path="admin-cart-customer" element={<AdminCartComponent />} />
                    <Route path="admin-requests-customer" element={<AdminCustomerRequestsComponent />} />



                  </Route>



                  {/* <Route path="password-reset" element={<ResetPassword />} /> */}
                  {/* <Route path="password-reset/:id" element={<ResetPassword />} /> */}
                  <Route path="password-reset/:id" element={<ResetPassword />} />
                  <Route path="cart" element={<Cart />} />
                  
                  <Route path="providers-notification" element={<ProvidersNotification />} />
                  <Route path="login" element={<Login />} />
                  <Route path="log" element={<Log />} />
                  <Route path="sign-up" element={<Register />} />
                  <Route path="terms" element={<Terms />} />
                  <Route path="cookie-policy" element={<CookiePolicy />} />
                  <Route path="privacy-policy" element={<PrivacyPolicy />} />
                  <Route path="register-superadmin" element={<SuperAdminRegister />} />
                  <Route path="service-provider" element={<ServiceProvider />} />
                </Routes>
                <Footer />

              </CustomerProvider>

            </DataProvider>

          </Router>

          {/* </NotificationProvider> */}

        </PersistGate>
      </Provider>
    </>

  )
}
