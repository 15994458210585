import React from 'react'
import OneOffDriverComponent from './OneOffDriverComponent';
import RelatedService from '../RelatedService';
import Ratings from '../Ratings';
import google from '../../Images/google.png'
import ratings from '../../Images/ratings.svg'
import dd from '../../Images/dd.png';
import monthly from '../../Images/monthly.png';
import airport from '../../Images/airport.png';
import Header from '../Header'


function OneOffDriver() {
  return (
    <div>
    <Header user='customer' />


    <div className="mt-16">
        <OneOffDriverComponent
            textOne="At Roadlers, our executive drivers are professionally trained individuals dedicated to your safety and comfort. He/she would work with you 10 hours daily, extra hours are charged at 1,000 naira hourly. "
            textTwo="Traveling across Nigeria? make an out of state booking and our support team will assist you in completing your order."
            title="Daily / Travel drivers"
            Location="Lagos"
            price= "12000"
            priceTwo="12000"
            // status="Start Booking - Let’s Move"
            image={dd}
        />
    </div>



    <div className="md:mt-40 mt-16 mb-8 md:px-56 px-6">
        <h5 className='md:font-bold  md:text-xl font-semibold text-sm text-white '>Related Services</h5>
        <hr className='md:w-28 w-12 md:mt-2 marg border-t border-white mb-12' />
    </div>


    <div className=" flex gap-6 items-center justify-center lg:grid-cols-4 grid grid-cols-2 md:px-56 px-6">



    <RelatedService
        image={airport}
        name="Car Rental From ₦25,000"
        status="View Offer"
    />
    <RelatedService
        image={monthly}
        name="Monthly full-time driving service "
        status="Access Offer"
    />

    <RelatedService
        image={airport}
        name="Travel driving service "
        status="Access Offer"
    />

    <RelatedService
        image={airport}
        name="Airport Pickups"
        status="Learn More"
    />
</div>



<div className="mt-16 mb-8 md:px-56 px-6">
    <h5 className='md:font-bold  md:text-xl font-semibold text-sm text-white '>Reviews</h5>
    <hr className='md:w-28 w-12 md:mt-2 marg border-t border-white mb-12' />
</div>


<div className=" md:flex mt-12 gap-12 md:px-56 mb-20">
            <Ratings 
                name="Chinyere" 
                google={google}
                ratings={ratings}
                text="The driver did a good job. He was professional and he drove really well too. The people I went with also attested to that."
                initial="C"
                add
            />
        </div>
    </div>
  )
}

export default OneOffDriver