import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
// import "swiper/swiper.min.css";
// import "swiper/components/effect-coverflow/effect-coverflow.min.css"
// import "swiper/components/pagination/pagination.min.css"

import prev from '../Images/prev.png'
import next from '../Images/next.png'

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import Special from '../Images/special-events.png';
import Ride from '../Images/ride-with-us.png';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
function Ratings(props) {
    const { name, google, ratings, text, initial, add } = props
    const responsive = {
        // 0: { items: 1 },
        // 568: { items: 0 },
        1024: { items: 1 },
    };

    const items = [

        <div className="flex gap-24">
            <div className="bg-white md:p-7 p-4 rounded-lg flex gap-8 sm:w-310">
                <div className="bg-green-600 h-6 w-6  rounded-full p-4 flex text-center items-center justify-center">
                    <h1 className='font-bold text-white text-xl'>{initial}</h1></div>

                <div className='flex flex-col' >
                    <h4 className='text-amber-500 font-bold md:text-lg text-sm mb-4'>{name}</h4>
                    <p className='font-semibold leading-5 md:text-sm text-xs tracking-wider'>{text}</p>
                    <div className="flex justify-between mt-4 items-center">
                        <img src={google} alt="" className='md:h-5 h-3' />
                        <img src={ratings} alt="" className='md:w-32 w-20 mr-0' />

                    </div>
                </div>
            </div>

            <div className="bg-white md:p-7 p-4 rounded-lg flex gap-3  sm:w-310">
                <div className="bg-green-600 h-6 w-6  rounded-full p-4 flex text-center items-center justify-center"><h1 className='font-bold text-white text-xl'>{initial}</h1></div>

                <div className='flex flex-col' >
                    <h4 className='text-amber-500 font-bold md:text-lg text-sm mb-4'>{name}</h4>
                    <p className='font-semibold leading-5 md:text-sm text-xs tracking-wider'>{text}</p>
                    <div className="flex justify-between mt-4 items-center">
                        <img src={google} alt="" className='md:h-5 h-3' />
                        <img src={ratings} alt="" className='md:w-32 w-20 mr-0' />

                    </div>
                </div>
            </div>
        </div>,

        <div className="flex gap-16">
            <div className="bg-white md:p-7 p-4 rounded-lg flex gap-3  sm:w-310">
                <div className="bg-green-600 h-6 w-6  rounded-full p-4 flex text-center items-center justify-center"><h1 className='font-bold text-white text-xl'>{initial}</h1></div>

                <div className='flex flex-col' >
                    <h4 className='text-amber-500 font-bold md:text-lg text-sm mb-4'>{name}</h4>
                    <p className='font-semibold leading-5 md:text-sm text-xs'>{text}</p>
                    <div className="flex justify-between mt-4 items-center">
                        <img src={google} alt="" className='md:h-5 h-3' />
                        <img src={ratings} alt="" className='md:w-32 w-20 mr-0' />

                    </div>
                </div>
            </div>,
            <div className="bg-white md:p-7 p-4 rounded-lg flex gap-3  sm:w-310">
                <div className="bg-green-600 h-6 w-6  rounded-full p-4 flex text-center items-center justify-center"><h1 className='font-bold text-white text-xl'>{initial}</h1></div>

                <div className='flex flex-col' >
                    <h4 className='text-amber-500 font-bold md:text-lg text-sm mb-4'>{name}</h4>
                    <p className='font-semibold leading-5 md:text-sm text-xs'>{text}</p>
                    <div className="flex justify-between mt-4 items-center">
                        <img src={google} alt="" className='md:h-5 h-3' />
                        <img src={ratings} alt="" className='md:w-32 w-20 mr-0' />

                    </div>
                </div>
            </div>
        </div>

    ];

    return (




        <div className='md:w-full '>
            <div className='md:flex  md:gap-5 gap-5  w-full m-auto  fff hidden'>

                <AliceCarousel
                    mouseTracking
                    items={items}
                    responsive={responsive}
                    controlsStrategy="alternate"
                    renderPrevButton={() => { return <button className='w-12'> <img src={prev} alt="" className='' /></button> }}
                    renderNextButton={() => { return <button className='w-12'> <img src={next} alt="" className='' /></button> }}
                />

            </div>





            <div className='flex   w-6/12 bg-red-600 m-auto  fff hidden overflow-scroll'>

                <div className="bg-white md:p-7 p-4 rounded-lg flex gap-3  sm:w-300 w-300">
                    <div className="bg-green-600 h-6 w-6  rounded-full p-4 flex text-center items-center justify-center"><h1 className='font-bold text-white text-xl'>{initial}</h1></div>

                    <div className='flex flex-col' >
                        <h4 className='text-amber-500 font-bold md:text-lg text-sm mb-4'>{name}</h4>
                        <p className='font-semibold leading-5 md:text-sm text-xs'>{text}</p>
                        <div className="flex justify-between mt-4 items-center">
                            <img src={google} alt="" className='md:h-5 h-3' />
                            <img src={ratings} alt="" className='md:w-32 w-20 mr-0' />

                        </div>
                    </div>
                </div>


            </div>




        </div>

    )
}

export default Ratings