import React from 'react'
import Header from '../Header'
import Example from '../Menu'
import cartwhite from '../../Images/cart-white.svg';
import daily_driver from '../../Images/daily_driver.png';
import car from '../../Images/asset2.png';
import monthly from '../../Images/monthly.png';
import airport from '../../Images/airport.png';
import dd from '../../Images/dd.png';
import drivee from '../../Images/drivee.png';

import FullTimeDriverComponent from './FullTimeDriverComponent';
import RelatedService from '../RelatedService';
import Ratings from '../Ratings';
import google from '../../Images/google.png'
import ratings from '../../Images/ratings.svg'
// import dd from '../../Images/dd.png';
import {useParams} from "react-router-dom"

function FulltimeDriverGift() {
    const params = useParams();

    const tokenCode = params.code

    const giftCardCode = tokenCode.substring(0, tokenCode.indexOf(':'))
    const dayCode = tokenCode.substring(tokenCode.indexOf(':') + 1)
    return (
        <>
            <Header user='customer' />

            <div className="md:mt-40 mt-32">
                <FullTimeDriverComponent
                    // textOne="This is a trained professional individuals, supported by dedicated customer care agents to meet your needs. He would work with you 10 hours daily from morning till evening 6 days a week. Some of the benefits include:"
                    //   list
                       p1="Thorough background check."    
                       p2="Full medical insurance for the drivers"   
                       p3="Change of driver upon request anytime."   
                       p4="Basic daily car maintenance routine checks."        
                    //    title="Full-Time drivers"
                       Location="Nigeria"
                       image={drivee}
                       tokenCode={giftCardCode}
                       dayCode={dayCode}
                    //    status="Almost Done - Keep Moving"
                />
            </div>



            <div className="md:mt-40 mt-16 mb-4 md:px-56 px-6">
                <h5 className='md:font-bold  md:text-xl font-semibold text-sm text-white '>Related Offers</h5>
                <hr className='md:w-28 w-20 md:mt-2 marg border-t border-white md:mb-12 mb-4' />
            </div>


            <div className=" md:flex  gap-6 items-center justify-center grid grid-cols-2 px-6">



                <RelatedService
                    image={airport}
                    name="Car Rental From ₦25,000"
                    status="View Offer"
                   

                />
                <RelatedService
                    image={monthly}
                    name="Monthly full-time driving service "
                    status="Access Offer"

                />

                <RelatedService
                    image={airport}
                    name="Travel driving service "
                    status="Access Offer"

                />

                <RelatedService
                    image={airport}
                    name="Airport Pickups"
                    status="Learn More"

                />
            </div>



            <div className="mt-16 mb-8 md:px-56 px-6">
                <h5 className='md:font-bold  md:text-xl font-semibold text-sm text-white '>Reviews</h5>
                <hr className='md:w-28 w-16 md:mt-2 marg border-t border-white mb-12' />
            </div>


            <div className="flex mt-12 gap-12 md:px-56 mb-20 px-6">
                <Ratings
                    name="Chinyere"
                    google={google}
                    ratings={ratings}
                    text="The driver did a good job. He was professional and he drove really well too. The people I went with also attested to that."
                    initial="C"
                    add
                />





            </div>



        </>
    )
}

export default FulltimeDriverGift