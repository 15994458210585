import React, { Fragment, useState, useEffect, useContext,useCallback,memo } from 'react'
import { Menu, Dialog, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import Header from '../Header'
import ScreenD from './ScreenD'
import { BASE_URL } from "../utils/constant"

import dashboard from '../../Images/dashboard.png'
import admin_request from '../../Images/admin_request.svg'
import service_p from '../../Images/service_p.svg'
import reports from '../../Images/reports.svg'
import customer from '../../Images/customers.png'
import notifications from '../../Images/notifications.png'
import download from '../../Images/download.svg'
import car_asset from '../../Images/car_asset.svg'
import icon from '../../Images/icon.svg'
import car from '../../Images/car.png'
import message from '../../Images/message.svg'
import roadlersRR from '../../Images/RoadlersRR.svg'

import { NavLink, useParams } from "react-router-dom";
import { NavHashLink as Link } from "react-router-hash-link";

import axios from "axios";
import getCookie from '../utils/SecuredTokens'
import CarOwnerD from './CarOwnerD'

function CarOwnerDetails() {
    function ScrollToTopOnMount() {
        useEffect(() => {
            window.scrollTo(0, 0);
        }, []);

        return null;
    }

    // SCREEN Driver LOADER
    const [dashboardLoad, setDashboardLoad] = useState(true)

    const { id } = useParams();

    console.log('car owner id', id)


    


    let userr = getCookie("user");
    let userObject = JSON.parse(userr)
    let token = userObject?.token

    const [providers, setProviders] = useState([]);
    // const [assetResult, setAssetResults] = useState(providers);
    const getProvider = useCallback(async () => {
        const cancelTokenSource = axios.CancelToken.source()
        await axios({
            method: "get",
            url: `${BASE_URL}/v1/search-providerby-id/${id}`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
                cancelToken: cancelTokenSource.token,

            },
        }).then(function (response) {
            // localStorage.removeItem("cart")
            const selectedProvider = response.data.data;


            console.log('allVehicleallVehicleallVehicle', selectedProvider)

            setProviders(selectedProvider);
            setDashboardLoad(false)
        });
    },[])


    useEffect(() => {
        getProvider();

    }, []);

    return (

        dashboardLoad ?
            (<>
                <ScrollToTopOnMount />

                <div className='h-[100vh] w-full flex justify-center items-center'>
                    <div class="lds-ring">
                        <img src={roadlersRR} alt="roadlers logo" className='embed-logo' />
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </>
            ) :
            (<div>
                <Header user='admin' />
                <main className='flex  px-12 flex-col md:flex-row'>
                    <div className='border-r border-amber-500 h-100 xl:w-2/12 md:w-3/12 md:pt-20 pb-16 hidden md:block'>
                        <button className='mt-12 p-2 bg-blue-900 rounded-md text-sm font-normal text-white'>Pending Approval</button>
                        <div className='mt-6 my-3'>
                            <span className='flex items-center text-white'>
                                <img src={dashboard} alt="" className='mr-3 w-8' />

                                <Link to="/Admin" className='font-light'>Admin Dashboard </Link>

                            </span>
                        </div>

                        <div className='my-3'>
                            <span className='flex items-center text-white'>
                                <img src={admin_request} alt="" className='mr-3 w-8' />

                                <Link to="/Admin#request" className='font-light'>Request </Link>
                            </span>
                        </div>

                        <div className='my-3'>
                            <span className='flex items-center text-white'>
                                <img src={car_asset} alt="" className='mr-3 w-8' />

                                <Link to="/Admin#assets" className='font-light'>Assets </Link>
                            </span>
                        </div>

                        <div className='my-3'>
                            <span className='flex items-center text-white'>
                                <img src={service_p} alt="" className='mr-3 w-8' />

                                <Link to="/Admin#providers" className='font-light'>Service Providers </Link>
                            </span>
                        </div>

                        <div className='my-3'>
                            <span className='flex items-center text-white'>
                                <img src={customer} alt="" className='mr-3 w-8' />
                                <a href="" className='font-light'></a>
                                <Link to="/Admin#customers" className='font-light'>Customers </Link>

                            </span>
                        </div>

                        <div className='my-3'>
                            <span className='flex items-center text-white'> <img src={reports} alt="" className='mr-3 w-8' /> <a href="" className='font-light'>Reports</a></span>
                        </div>
                    </div>

                    <div className='xl:w-10/12 md:w-9/12 flex xl:px-20 md:px-12 px-8 flex flex-col xl:flex-row md:flex-row w-full'>

                        {providers?.map((provider, i) => {
                            return (
                                <CarOwnerD
                                    providers={provider}
                                />
                            );
                        })}

                    </div>
                </main>
            </div>)
    )
}

export default memo(CarOwnerDetails)
