import { useEffect, useState, useRef } from 'react'
import { BASE_URL } from "../utils/constant"

import message from '../../Images/message.svg'
import icon from '../../Images/icon.svg'
import cancel from '../../Images/cancel.svg'
import pen from '../../Images/pen.svg'
import Dropdown from '../Common/Dropdown'
import { Multiselect } from 'multiselect-react-dropdown'
import { GoogleMap, useJsApiLoader, Autocomplete } from '@react-google-maps/api';
import Resizer from "react-image-file-resizer";
import moment from 'moment';
import Spin from "../../Images/spinner.gif"

import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import getCookie from '../utils/SecuredTokens'

function VehicleApprovalEditComponent(props) {
    const {
        vehicleName,
        vehicleYear,
        vehicleColor,
        vehicleCountry,
        vehicleState,
        vehicleNote,
        vehicleType,
        vehicleId,
        plateNo,
        passengerSeats,
        address,
        dailyPrice,
        airportPrice,
        monthlyPrice,
        hoursAvailable,
        driverAvailable,
        services,
        airCondition,
        travelAvailable,
        vehicleInsurance,
        statusDoc,
        img1,
        img2,
        img3,
        vehicleDoc,
        providerId,
        editDate,
        carOwnerId, 
        carOwnerProfile,
    } = props

    let userData = getCookie("user");
    let userObject = JSON.parse(userData) 
    let token = userObject?.token
   



    console.log(vehicleDoc?.filter(obj => obj.doc_type.includes("proof of ownership")), "possible")


    const { } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyDkMuW-hGvdRfUhsb2R9RFqA0Pq4Qxg4Cs",
        libraries: ['places'],
    })

    // SETTING THE ADDRESS FROM THE AUTOCOMPLETE TO THE STORED RESPECTIVE VALUES
    const [autocomplete, setAutocomplete] = useState(null)

    const onPlaceChanged = () => {
        if(autocomplete !== null) {
            const places = autocomplete.getPlace().formatted_address
            if(places) {
                setEditAddress(places)
            }else {
                setEditAddress(autocomplete.getPlace().name)
            }
        }
    }

    // RESIZER
    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
            file,
            300,
            400,
            "JPEG",
            80,
            0,
            (uri) => {
                resolve(uri);
            },
            "base64"
            );
        });

    const dataURIToBlob = (dataURI) => {
        const splitDataURI = dataURI.split(",");
        const byteString =
            splitDataURI[0].indexOf("base64") >= 0
            ? atob(splitDataURI[1])
            : decodeURI(splitDataURI[1]);
        const mimeString = splitDataURI[0].split(":")[1].split(";")[0];
        const ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);
        return new Blob([ia], { type: mimeString });
    };

    // MULTI TASK
    const [availabilitySelect, setAvailabilitySelect] = useState([])

    const [airConditioner, setAirConditioner] = useState(airCondition)
    const [availableTravel, setAvailableTravel] = useState(travelAvailable)
    const [comesWithDriver, setComesWithDriver] = useState (driverAvailable)
    const [compInsurance, setCompInsurance] = useState(vehicleInsurance)

    const [editVehicleName, setEditVehicleName] = useState('')
    const [editVehicleYear, setEditVehicleYear] = useState('')
    const [editPlateNo, setEditPlateNo] = useState('')
    const [editVehicleType, setEditVehicleType] = useState('')
    const [editPassengerSeats, setEditPassengerSeats] = useState('')
    const [editAddress, setEditAddress] = useState('')
    const [editDailyPrice, setEditDailyPrice] = useState('')
    const [editAirportPrice, setEditAirportPrice] = useState('')
    const [editMonthlyPrice, setEditMonthlyPrice] = useState('')
    const [editHoursAvailable, setEditHoursAvailable] = useState('')
    const [editServices, setEditServices] = useState('')
    const [editVehicleColor, setEditVehicleColor] = useState('')
    const [editVehicleCountry, setEditVehicleCountry] = useState('')
    const [editVehicleState, setEditVehicleState] = useState('')
    const [editVehicleNote, setEditVehicleNote] = useState('')

    const fileInputRef = useRef()
    const licenceInputRef = useRef()
    const insuranceInputRef = useRef()
    const worthinessInputRef = useRef()
    const hackneyInputRef = useRef()

    const refDiv = useRef()


    const [proof, setProof] = useState()
    const [licence, setLicence] = useState()
    const [insurance, setInsurance] = useState()
    const [worthiness, setWorthiness] = useState()
    const [hackney, setHackney] = useState()
    useEffect(() => {
        console.log(hackney, 'hackneyfile')
    }, [hackney])

    const [isProofSaved, setIsProofSaved] = useState(false)
    const [isHackneySaved, setIsHackneySaved] = useState(false)
    const [isLicenseSaved, setIsLicenseSaved] = useState(false)
    const [isInsuranceSaved, setInsuranceSaved] = useState(false)
    const [isWorthinessSaved, setIsWorthinessSaved] = useState(false)

    const [upload, setUpload] = useState(false);
    const [dataSave, setDataSave] = useState({})
    const [licenceUpload, setLicenceUpload] = useState(false);
    const [insuranceUpload, setInsuranceUpload] = useState(false);
    const [hackneyUpload, setHackneyUpload] = useState(false);
    const [worthinessUpload, setWorthinessUpload] = useState(false);

    const [selectedImage, setSelectedImage] = useState();
    const [selectedImage1, setSelectedImage1] = useState()
    const [selectedImage2, setSelectedImage2] = useState()

    const [isEditCarImage, setIsEditCarImage] = useState(false)
    const [isEditVehicle, setIsEditVehicle] = useState(false)

    const [selectedVechicleType, setSelectedVehicleType] = useState('')

    const [vehicleEditInfo, setVehiceEditInfo] = useState(false)
    const [isEditService , setIsEditService] = useState(false)
    const [editServiceInfo, setEditServiceInfo] = useState(false)
    const [showImageInfo, setShowImageInfo] = useState(false)

    const [profileSaved, setProfileSaved] = useState(false)

    const [vehicleProfileSpin, setVehicleProfileSpin] = useState("")

    const today = new Date()
    const getToday = moment(today).format("YYYY-MM-DD")
    const profileEditDate = moment(editDate).format("YYYY-MM-DD")
    const compareDate = moment(getToday).isAfter(profileEditDate)

    const image1Ref = useRef()
    const image2Ref = useRef()
    const image3Ref = useRef()


    // SETTING INDIVIDUAL DOC OBJECTS
    const proofId = vehicleDoc?.find((item) => (item?.doc_type.includes("proof of ownership")))
    const licenseId = vehicleDoc?.find((item) => (item?.doc_type.includes("vehicle license")))
    const insuranceId = vehicleDoc?.find((item) => (item?.doc_type.includes("car insurance")))
    const hackneyId = vehicleDoc?.find((item) => (item?.doc_type.includes("hackney permit")))
    const worthinessId = vehicleDoc?.find((item) => (item?.doc_type.includes("road worthiness")))

    const imageChange = (e) => {
        if (e.target.files && e.target.files.length > 0) {
        setSelectedImage(e.target.files[0]);
        }
    };

    const imageChange1 = (e) => {
        if (e.target.files && e.target.files.length > 0) {
        setSelectedImage1(e.target.files[0]);
        }
    };

    const imageChange2 = (e) => {
        if (e.target.files && e.target.files.length > 0) {
        setSelectedImage2(e.target.files[0]);
        }
    };

    const removeSelectedImage = () => {
        setSelectedImage();
    };


    const removeSelectedImage1 = () => {
        setSelectedImage1();
    };

    const removeSelectedImage2 = () => {
        setSelectedImage2();
    };

    const handleAirCon = (e) => {
        setAirConditioner(e.target.value)
    };

    const handleTravel = (e) => {
        setAvailableTravel(e.target.value)
    };

    const handleComesWithDriver = (e) => {
        setComesWithDriver(e.target.value)
    }

    const handleInsurance = (e) => {
        setCompInsurance(e.target.value)
    }


    const setStateVehicleCondition = () => {
        setAirConditioner(airCondition)
        setAvailableTravel(travelAvailable)
        setCompInsurance(vehicleInsurance)
    }

    const vehicleArray = [
        {value: 'SUV Car(cars)', label: 'SUV Car'}, 
        {value: 'Saloon Car(cars)', label: 'Saloon Car '},
        {value: 'Luxury Car(cars)', label: 'Luxury Car'},
        {value: 'Mini Bus(bus)', label: 'Mini Bus'},
        {value: 'Coaster Bus(bus)', label: 'Coaster Bus'},
        {value: 'Luxury  Bus/Van(bus, vans)', label: 'Luxury  Bus/Van'},
        {value: 'Cargo Van/Truck(vans,trucks)', label: 'Cargo Van/Truck'},
        {value: 'Pickup/Tow Truck(trucks)', label: 'Pickup/Tow Truck'},
        {value: '10-40ft Trucks(trucks)', label: '10-40ft Trucks'},
        {value: 'Flat Bird Trailer(trucks)', label: 'Flat Bird Trailer'},
        {value: '5-40 Tons Trailer(trucks)', label: '5-40 Tons Trailer'},
        {value: 'Caterpillars Trailer(trucks)', label: 'Caterpillars Trailer'},
        {value: 'Delivery Bikes(bikes)', label: 'Delivery Bikes'},
        {value: 'Power Bikes(bikes)', label: 'Power Bikes'},
        {value: 'Emergency Van(vans)', label: 'Emergency Van'},
        {value: 'Others', label: 'Others'},
    ]

    // MULTISELECT ..........
    const convertArray = (array, property) => {
        var result = []
        for(var i = 0; i<array.length; i+=1) {
            result.push(array[i][property])
        }
        setUpdateAvailability(result)
    }

    const [updateAvialablity, setUpdateAvailability] = useState([])
    useEffect(() => {
        setUpdateAvailability([])
        convertArray(availabilitySelect, 'value')
    }, [availabilitySelect.length])

    // AVAILABILITY SERVICE OPTIONS
    const availabilityOptionData = [
        {value: 'Full-tank per day', label: 'Full-tank per day', id: 3},
        {value: 'No-fuel', label: 'No-fuel ', id: 4},
        {value: 'Available  Night', label: 'Available  Night ', id: 5},
        {value: 'Available Monthly Full-time', label: 'Available Monthly Full-time', id: 6},
        {value: 'Available  Weekly', label: 'Available  Weekly', id: 7}
    ]

    const [optionsValue] = useState(availabilityOptionData)

    const [hackneyName, setHackneyName] = useState("")

    let notify = () => { toast("File Successfully Uploaded!") };
    let userId = JSON.parse(localStorage.getItem("userId"))

    // HANDLE FILE CHANGE  FOR RESPECTIVE DOCS
    const handleHackneyFileChange = async (e) => {
        const file = e.target.files[0]
        setHackneyName(file?.name)
        // const image = await resizeFile(file);
        if (file?.type === "application/pdf") {
            setHackney(file)
            setHackneyUpload(true)
        }
        else {
            const image = await resizeFile(file);
            const newFile = dataURIToBlob(image);
            setHackneyUpload(true)
            setHackney(newFile)
        }
    }


    const [worthinessName, setWorthinessName] =  useState("")
    const handleWorthinessFileChange = async (e) => {
        const file = e.target.files[0]
        setWorthinessName(file?.name)
        // const image = await resizeFile(file);
        if (file?.type === "application/pdf") {
            setWorthiness(file)
            setWorthinessUpload(true)
        }
        else {
            const image = await resizeFile(file);
            const newFile = dataURIToBlob(image);
            setWorthinessUpload(true)
            setWorthiness(newFile)
        }
    }

    const [insuranceName, setInsuranceName] = useState("")

    const handleInsuranceFileChange = async (e) => {
        const file = e.target.files[0]
        setInsuranceName(file?.name)
        // const image = await resizeFile(file);
        if (file?.type === "application/pdf") {
            setInsurance(file)
            setInsuranceUpload(true)
        }
        else {
            const image = await resizeFile(file);
            const newFile = dataURIToBlob(image);
            setInsuranceUpload(true)
            setInsurance(newFile)
        }
    }


    const [licenseName, setLicenseName] = useState("")
    const handleLicenseFileChange = async (e) => {
        const file = e.target.files[0]
        setLicenseName(file?.name)
        // const image = await resizeFile(file);
        if (file?.type === "application/pdf") {
            setLicence(file)
            setLicenceUpload(true)
        }
        else {
            const image = await resizeFile(file);
            const newFile = dataURIToBlob(image);
            setLicenceUpload(true)
            setLicence(newFile)
        }
    }

    const [proofName, setProofName] = useState("")
    const handleProofFileChange = async (e) => {
        const file = e.target.files[0]
        setProofName(file?.name)
        // const image = await resizeFile(file);
        if (file?.type === "application/pdf") {
            setProof(file)
            setUpload(true)
        }
        else {
            const image = await resizeFile(file);
            const newFile = dataURIToBlob(image);
            setUpload(true)
            setProof(newFile)
        }
    }

    


        


    const [proofLoad, setProofLoad] = useState("")
    // HANDLE PROOF SUBMIT
    const handleProofSubmit = (e) => {
        e.preventDefault()
        setProofLoad(Spin)

        if (proof) {
            const reader = new FileReader()
            reader.readAsDataURL(proof)
            let formDatas = new FormData();
            formDatas.append('file', proof)
            formDatas.append('doc_type', 'proof of ownership')
            formDatas.append('id', proofId.id)
            formDatas.append('vehicle_id', vehicleId)


            axios({
                method: 'post',
                url: `${BASE_URL}/v1/update-vehicle-document`,
                data: formDatas,

                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    "Access-Control-Allow-Origin": "*"
                }
            })
            .then(function (response) {
                setProofLoad("")
                localStorage.setItem("proof-of-ownership", JSON.stringify(response.data.data))
                notify()
                setIsProofSaved(true)
            })
            .catch(function (error) {
                setProofLoad("")
                let notify = () => { toast.error((error.response.data.message), {
                    toastClassName: 'error'
                } ) };
                notify()
            });

        } else {
            setProofLoad("")
            let notify = () => { toast.error(('Upload a document before you save'), {
                toastClassName: 'error'
            } ) };
            notify()
        }
    }

    const [licenseLoad, setLicenseLoad] = useState("")
    // HANDLE LICENSE UPDATE
    const handleLicenseUpdate = (e) => {
            e.preventDefault()
            setLicenseLoad(Spin)
    
            if (licence) {
                const reader = new FileReader()
                reader.readAsDataURL(licence)
                let formDatas = new FormData();
                formDatas.append('file', licence)
                formDatas.append('doc_type', 'vehicle license')
                formDatas.append('id', licenseId.id)
                formDatas.append('vehicle_id', vehicleId)
    
                axios({
                    method: 'post',
                    url: `${BASE_URL}/v1/update-vehicle-document`,
                    data: formDatas,
    
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                        "Access-Control-Allow-Origin": "*"
                    }
                })
                .then(function (response) {
                    setLicenseLoad("")
                    localStorage.setItem("licence", JSON.stringify(response.data.data))
                    notify()
                    setIsLicenseSaved(true)
                })
                .catch(function (error) {
                    //handle error
                    setLicenseLoad("")
                    let notify = () => { toast.error((error.response.data.message), {
                        toastClassName: 'error'
                    } ) };
                    notify()
                });
    
            } else {
                setLicenseLoad("")
                let notify = () => { toast.error(('Upload a document before you save'), {
                    toastClassName: 'error'
                } ) };
                notify()
            }
    }

    const [insuranceLoad, setInsuranceLoad] = useState("")
    // HANDLE INSURANCE UPDATE
    const handleInsuranceUpdate = (e) => {
        e.preventDefault()
        setInsuranceLoad(Spin)

        if (insurance) {
            const reader = new FileReader()
            reader.readAsDataURL(insurance)
            let formDatas = new FormData();
            formDatas.append('file', insurance)
            formDatas.append('doc_type', 'car insurance')
            formDatas.append('id', insuranceId.id)
            formDatas.append('vehicle_id', vehicleId)

            axios({
                method: 'post',
                url: `${BASE_URL}/v1/update-vehicle-document`,
                data: formDatas,

                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    "Access-Control-Allow-Origin": "*"
                }
            })
            .then(function (response) {
                setInsuranceLoad("")
                localStorage.setItem("insurance", JSON.stringify(response.data.data))
                notify()
                setInsuranceSaved(true)
            })
            .catch(function (error) {
                //handle error
                setInsuranceLoad("")
                let notify = () => { toast.error((error.response.data.message), {
                    toastClassName: 'error'
                } ) };
                notify()
            });

        } else {
            setInsuranceLoad("")
            let notify = () => { toast.error(('Upload a document before you save'), {
                toastClassName: 'error'
            } ) };
            notify()
        }
    }

    const [worthyLoad, setWorthyLoad] = useState("")
    // HANDLE WORTHINESS UPDATE
    const handleWorthinessUpdate = (e) => {
        e.preventDefault()
        setWorthyLoad(Spin)

        if (worthiness) {
            const reader = new FileReader()
            reader.readAsDataURL(worthiness)
            let formDatas = new FormData();
            formDatas.append('file', worthiness)
            formDatas.append('doc_type', 'road worthiness')
            formDatas.append('id', worthinessId.id)
            formDatas.append('vehicle_id', vehicleId)

            axios({
                method: 'post',
                url: `${BASE_URL}/v1/update-vehicle-document`,
                data: formDatas,

                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    "Access-Control-Allow-Origin": "*"
                }
            })
            .then(function (response) {
                setWorthyLoad("")
                localStorage.setItem("worthiness", JSON.stringify(response.data.data))
                notify()
                setIsWorthinessSaved(true)
            })
            .catch(function (error) {
                //handle error
                setWorthyLoad("")
                let notify = () => { toast.error((error.response.data.message), {
                    toastClassName: 'error'
                } ) };
                notify()
            });

        } else {
            setWorthyLoad("")
            let notify = () => { toast.error(('Upload a document before you save'), {
                toastClassName: 'error'
            } ) };
            notify()
        }      
    }


    const [hackneyLoad, setHackneyLoad] = useState("")
    // HANDLE HACKNEY UPDATE
    const handleHackneyUpdate = (e) => {
        e.preventDefault()
        setHackneyLoad(Spin)

        if (hackney) {
            const reader = new FileReader()
            reader.readAsDataURL(hackney)
            let formDatas = new FormData();
            formDatas.append('file', hackney)
            formDatas.append('doc_type', 'hackney permit')
            formDatas.append('id', hackneyId.id)
            formDatas.append('vehicle_id', vehicleId)

            axios({
                method: 'post',
                url: `${BASE_URL}/v1/update-vehicle-document`,
                data: formDatas,

                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    "Access-Control-Allow-Origin": "*"
                }
            })
            .then(function (response) {
                setHackneyLoad("")
                localStorage.setItem("hackney", JSON.stringify(response.data.data))
                notify()
                setIsHackneySaved(true)
            })
            .catch(function (error) {
                //handle error
                setHackneyLoad("")
                let notify = () => { toast.error((error.response.data.message), {
                    toastClassName: 'error'
                } ) };
                notify()
            });

        } else {
            setHackneyLoad("")
            let notify = () => { toast.error(('Upload a document before you save'), {
                toastClassName: 'error'
            } ) };
            notify()
        }      
    }

    let userOwnership = JSON.parse(localStorage.getItem("proof-of-ownership"))
    let ownership = userOwnership?.document

    let userLicense = JSON.parse(localStorage.getItem("licence"))
    let license = userLicense?.document

    let userInsurance = JSON.parse(localStorage.getItem("insurance"))
    // let insuranceFile = userInsurance?.document


    let userWorthiness = JSON.parse(localStorage.getItem("worthiness"))
    // let worthinessFile = userWorthiness?.document

    let userHackney = JSON.parse(localStorage.getItem("hackney"))
    // let hackneyFile = userHackney?.document






    // UPDATE VEHICLE PROFILE 
    const handleVehiclProfileSubmit = (e) => {
        e.preventDefault()

        setVehicleProfileSpin(Spin)

        let formData = new FormData();
        formData.append('vehicle_id', vehicleId)
        formData.append('vehicle_name', editVehicleName || vehicleName)
        formData.append('year', editVehicleYear || vehicleYear)
        formData.append('plate_number', editPlateNo || plateNo)
        formData.append('daily_price', editDailyPrice || dailyPrice)
        formData.append('monthly_price', editMonthlyPrice || monthlyPrice)
        formData.append('airport_price', editAirportPrice || airportPrice)
        formData.append('hour_available', editHoursAvailable || hoursAvailable)
        formData.append('air_con', airConditioner || airCondition)
        formData.append('travel_available', availableTravel || travelAvailable)
        formData.append('vehicle_insurance', compInsurance || vehicleInsurance)
        formData.append('vehicle_type', selectedVechicleType || vehicleType)
        formData.append('come_with_driver', comesWithDriver || driverAvailable)
        formData.append('service', updateAvialablity || vehicleInsurance)
        formData.append('notes', editVehicleNote || vehicleNote)
        formData.append('state', editVehicleState || vehicleState)
        formData.append('country', editVehicleCountry || vehicleCountry)
        formData.append('color', editVehicleColor || vehicleColor)
        formData.append('address', editAddress || address)
        formData.append('file', selectedImage || img1)
        formData.append('file2',selectedImage1 || img2)
        formData.append('file3',selectedImage2 || img3)


        axios({
            method: 'post',
            url: `${BASE_URL}/v1/update-vehicle`,
            data: formData,

            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*"
            }
        })
        .then(function (response) {
            localStorage.setItem("proof-of-ownership", JSON.stringify(response.data.data))
            notify()
            setVehicleProfileSpin("")
            setProfileSaved(true)
        })
        .catch(function (error) {
            //handle error
            setVehicleProfileSpin("")
            let notify = () => { toast.error((error.response.data.message), {
                toastClassName: 'error'
            } ) };
            notify()
        });        
    }

    useEffect(() => {
        setStateVehicleCondition()
    }, [])



    // HANDLE FIRST SAVE OF DOCUMENTS
    // HANDLE HACKNEY SEND
    const handleHackneySend = (e) => {
        e.preventDefault()
        setHackneyLoad(Spin)

        if (hackney) {
            let formDataHackney = new FormData();

            formDataHackney.append('file', hackney)
            formDataHackney.append('doc_type', 'hackney permit')
            formDataHackney.append('vehicle_id', vehicleId)

            axios({
                method: 'post',
                url: `${BASE_URL}/v1/hackney-permit`,
                data: formDataHackney,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token || null,
                    "Access-Control-Allow-Origin": "*",
                }
            })
            .then(function (response) {
                setHackneyLoad("")
                notify()
                setIsHackneySaved(true)
            })
            .catch(function (error) {
                //handle error
                setHackneyLoad("")
                let notify = () => { toast.error((error.response.data.message), {
                    toastClassName: 'error'
                } ) };
                notify()
            });
        } else {
            setHackneyLoad("")
            let notify = () => { toast.error(('Upload a document before you save'), {
                toastClassName: 'error'
            } ) };
            notify()
        }
    }


    // WORTHINESS FIRST SAVE
    const handleWorthinessSend = (e) => {
        e.preventDefault()
        setWorthyLoad(Spin)

        if (worthiness) {
            const reader = new FileReader()
            reader.readAsDataURL(worthiness)
            let formDataWorthiness = new FormData();
            formDataWorthiness.append('file', worthiness)
            formDataWorthiness.append('doc_type', 'road worthiness')
            formDataWorthiness.append('vehicle_id', vehicleId)

            axios({
                method: 'post',
                url: `${BASE_URL}/v1/road-worthiness`,
                data: formDataWorthiness,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token || null,
                    "Access-Control-Allow-Origin": "*",
                }
            })
            .then(function (response) {
                setWorthyLoad("")
                notify()
                setIsWorthinessSaved(true)
            })
            .catch(function (error) {
                //handle error
                setWorthyLoad("")
                let notify = () => { toast.error((error.response.data.message), {
                    toastClassName: 'error'
                } ) };
                notify()
            });

        } else {
            setWorthyLoad("")
            let notify = () => { toast.error(('Upload a document before you save'), {
                toastClassName: 'error'
            } ) };
            notify()
        }  
    }

    // INSURANCE FIRST SAVE
    const handleInsuranceSend = (e) => {
        e.preventDefault()
        setInsuranceLoad(Spin)

        if (insurance) {
            const reader = new FileReader()
            reader.readAsDataURL(insurance)
            let formDataInsurance = new FormData();
            formDataInsurance.append('file', insurance)
            formDataInsurance.append('doc_type', 'car insurance')
            formDataInsurance.append('vehicle_id', vehicleId)

            axios({
                method: 'post',
                url: `${BASE_URL}/v1/car-insurance`,
                data: formDataInsurance,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token || null,
                    "Access-Control-Allow-Origin": "*",
                }
            })
            .then(function (response) {
                setInsuranceLoad("")
                notify()
                setInsuranceSaved(true)
            })
            .catch(function (error) {
                //handle error
                setInsuranceLoad("")
                let notify = () => { toast.error((error.response.data.message), {
                    toastClassName: 'error'
                } ) };
                notify()
            });

        } else {
            setInsuranceLoad("")
            let notify = () => { toast.error(('Upload a document before you save'), {
                toastClassName: 'error'
            } ) };
            notify()
        }
    }

    // LICENSE FIRST SAVE
    const handleLicenseSend = (e) => {
        e.preventDefault()
        setLicenseLoad(Spin)
    
        if (licence) {
            const reader = new FileReader()
            reader.readAsDataURL(licence)
            let formDataLicense = new FormData();
            formDataLicense.append('file', licence)
            formDataLicense.append('doc_type', 'vehicle license')
            formDataLicense.append('vehicle_id', vehicleId)

            axios({
                method: 'post',
                url: `${BASE_URL}/v1/vehicle-licence`,
                data: formDataLicense,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token || null,
                    "Access-Control-Allow-Origin": "*",
                }
            })
            .then(function (response) {
                setLicenseLoad("")
                notify()
                setIsLicenseSaved(true)
            })
            .catch(function (error) {
                //handle error
                setLicenseLoad("")
                let notify = () => { toast.error((error.response.data.message), {
                    toastClassName: 'error'
                } ) };
                notify()
            });

        } else {
            setLicenseLoad("")
            let notify = () => { toast.error(('Upload a document before you save'), {
                toastClassName: 'error'
            } ) };
            notify()
        }
    }

    // PROOF OF OWNSERSHIP FIRST SAVE
    const handleProofSend = (e) => {
        e.preventDefault()
        setProofLoad(Spin)

        if (proof) {
            const reader = new FileReader()
            reader.readAsDataURL(proof)
            let formDatas = new FormData();
            formDatas.append('file', proof)
            formDatas.append('doc_type', 'proof of ownership')
            formDatas.append('vehicle_id', vehicleId)


            axios({
                method: 'post',
                url: `${BASE_URL}/v1/proof-of-ownership`,
                data: formDatas,

                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    "Access-Control-Allow-Origin": "*"

                }
            })
            .then(function (response) {
                setProofLoad("")
                notify()
                setIsProofSaved(true)
            })
            .catch(function (error) {
                setProofLoad("")
                let notify = () => { toast.error((error.response.data.message), {
                    toastClassName: 'error'
                } ) };
                notify()
            });

        } else {
            setProofLoad("")
            let notify = () => { toast.error(('Upload a document before you save'), {
                toastClassName: 'error'
            } ) };
            notify()
        }
    }


  return (
    <>
        <div className='md:border-r md:border-amber-500 h-100 w-full md:w-6/12 md:px-20 md:pb-20 pt-12 component-approval-form-page'>
            <ToastContainer />

            <h2 className='text-white font-bold text-lg md:mt-40 mt-16'>Vehicle Profile </h2>


            <main className=''>
                <div className="flex w-full">


                    <div className="pb-20 w-full">
                        <form className='w-full mt-4 '>
                            {/* NAME AND YEAR OF VEHICLE */}
                            <div className="flex gap-5 my-6 md:my-6 w-full component-approval-form-name-wrapper">
                                <div className="flex flex-col w-1/2">
                                    <label htmlFor="vehicle name" className='text-xs text-white font-normal'>Vehicle Name</label>
                                    <input
                                        type="text"
                                        placeholder='Prado'
                                        className='text-sm mt-2 bg-[#000] rounded-2xl border border-white px-3 py-3 focus:outline-none text-white'
                                        defaultValue={vehicleName || editVehicleName}
                                        onChange={(e) => setEditVehicleName(e.target.value)}
                                    />
                                </div>
                                <div className="flex flex-col w-1/2">
                                    <label htmlFor="Year" className='text-xs text-white font-normal'>Year</label>
                                    <input
                                        type="text"
                                        placeholder='2020'
                                        className='text-sm mt-2 bg-[#000] rounded-2xl border border-white px-3 py-3 focus:outline-none text-white'
                                        defaultValue={vehicleYear || editVehicleYear}
                                        onChange={(e) => setEditVehicleYear(e.target.value)}
                                    />

                                </div>
                            </div>

                            {/* PLATE NUMBER */}
                            <div className="flex flex-col my-6">
                                <label htmlFor="Plate Number"
                                    className='text-xs text-white font-normal'>Plate Number <span className="text-red-600">*</span>  </label>

                                <input
                                    type="text"
                                    className='text-sm mt-2 bg-[#000] rounded-2xl border border-white px-3 py-3 focus:outline-none text-white' 
                                    defaultValue={editPlateNo || plateNo}
                                    onChange={e => setEditPlateNo(e.target.value)}
                                    />
                            </div>


                            {/* VEHICLE TYPE */}
                            <div className="flex flex-col my-6">
                                <div className="flex justify-between items-center relative">
                                    <label htmlFor="Vehicle Type"
                                        className='text-xs text-white font-normal'>Vehicle Type <span className="text-red-600">*</span>  
                                    </label>

                                    {
                                    statusDoc === 'Approved' &&
                                        <div className='flex flex-end items-center gap-1 text-[#FFA100] text-xs'>
                                            <span className='cursor-pointer'>Edit</span>
                                            <img src={pen} alt="Edit Pen " className='w-6 cursor-pointer' 
                                                onClick={() => setIsEditVehicle(isEditVehicle => !isEditVehicle)}
                                                onMouseOver={() => setVehiceEditInfo(true)}
                                                onMouseOut={() => setVehiceEditInfo(false)}
                                            />
                                        </div>
                                    }


                                    {statusDoc === 'Rejected' &&
                                        <div className='flex flex-end items-center gap-1 text-[#FFA100] text-xs'>
                                            <span className='cursor-pointer'>Edit</span>
                                            <img src={pen} alt="Edit Pen " className='w-6 cursor-pointer' 
                                                onClick={() => setIsEditVehicle(isEditVehicle => !isEditVehicle)}
                                                onMouseOver={() => setVehiceEditInfo(true)}
                                                onMouseOut={() => setVehiceEditInfo(false)}
                                            />
                                        </div>
                                    }

                                    { vehicleEditInfo &&
                                        <div className="flex absolute bottom-8 right-0 items-center rounded-xl p-4 bg-[#fff]">
                                            <p className='text-center'> Click this if you wish to change vehicle Type</p>
                                        </div>
                                    }
                                </div>

                                {
                                    !isEditVehicle &&
                                    <input
                                        type="text"
                                        placeholder="Select vehicle type"
                                        defaultValue={editVehicleType || vehicleType}
                                        onChange={e => setEditVehicleType(e.target.value)}
                                        className='text-sm mt-2 bg-[#000] rounded-2xl border border-white px-3 py-3 focus:outline-none text-white' 
                                    />
                                }

                                {
                                    isEditVehicle &&
                                    <div className="text-base mt-2  w-full rounded-xl border border-white bg-[#000] px-3 py-3 text-[#fff]">
                                        <Dropdown
                                            bgColor='bg-[#000]'
                                            holder= {selectedVechicleType || 'Select vehicle Type'}
                                            optionArray= {vehicleArray}
                                            setSelectedOption= {setSelectedVehicleType}
                                            selectedOption= {selectedVechicleType}
                                        />
                                    </div>
                                }

                            </div>


                            {/* NO OF PASSENGER SEATS */}
                            <div className="flex flex-col my-6">
                                <label htmlFor="Number of Passenger Seats"
                                    className='text-xs text-white font-normal'>No of passenger seats <span className="text-red-600">*</span>  </label>

                                <input
                                    type="text"
                                    placeholder="Type here..."
                                    defaultValue={editPassengerSeats || passengerSeats}
                                    onChange={e => setEditPassengerSeats(e.target.value)}
                                    className='text-sm mt-2 bg-[#000] rounded-2xl border border-white px-3 py-3 focus:outline-none text-white' />

                            </div>

                            {/* ADDRESS */}
                            <div className="flex flex-col my-6 md:my-6">
                                <label htmlFor="Address:" className='text-xs text-white font-normal'>Address: <span className="text-red-600">*</span> </label>
                                <Autocomplete className='autcomplete-style'
                                    onPlaceChanged={onPlaceChanged}
                                    onLoad={(value) => setAutocomplete(value)}
                                >
                                    <input type="text"
                                        defaultValue={editAddress || address}
                                        onChange={e => setEditAddress(e.target.value)}
                                        className='text-sm mt-2 bg-[#000] rounded-2xl w-full border border-white px-3 py-3 focus:outline-none text-white' 
                                    />
                                </Autocomplete>
                            </div>



                            {/* DAILY RENTAL PRICE */}
                            <>
                                <div className="flex flex-col mt-6">
                                    <label htmlFor="Daily Price" className='text-xs text-white font-normal'>Daily Rental Price <span className="text-red-600">*</span> </label>
                                    <div className="rounded-2xl bg-[#000] border border-white mt-2 flex items-center text-white px-2">

                                        <span className=''>&#8358;</span>
                                        <input
                                            type="text"
                                            defaultValue={editDailyPrice || dailyPrice}
                                            onChange={e => setEditDailyPrice(e.target.value)}
                                            className='w-11/12 text-sm bg-[#000] rounded-2xl  px-3 py-3 focus:outline-none text-white' />
                                        <img src={icon} alt="" className='w-12' />


                                    </div>

                                </div>

                                <div className="flex  mt-4 mb-6 justify-between">
                                    <div className="flex flex-col mr-4">
                                        <label htmlFor="VAT 7.5%" className='text-xs text-white font-normal'>VAT 7.5%</label>
                                    </div>
                                    <div className="flex flex-col">
                                        <label htmlFor="Service fee 3.5% to 10%" className='text-xs text-white font-normal'>Service fee 3.5% to 10%</label>
                                    </div>
                                </div>
                            </>


                            {/* AIRPORT PICKUP PRICE */}
                            <>
                                <div className="flex flex-col mt-6">
                                    <label htmlFor="Daily Price" className='text-xs text-white font-normal'>Airport Pick-up price </label>
                                    <div className="rounded-2xl bg-[#000] border border-white mt-2 flex px-2 flex items-center text-white">
                                        <span className=''>&#8358;</span>

                                        <input
                                            type="text"
                                            defaultValue={editAirportPrice || airportPrice}
                                            onChange={e => setEditAirportPrice(e.target.value)}
                                            className='w-11/12 text-sm bg-[#000] rounded-2xl  px-3 py-3 focus:outline-none text-white' />
                                        <img src={icon} alt="" className='w-12' />
                                    </div>

                                </div>



                                <div className="flex  mt-4 mb-6 justify-between">
                                    <div className="flex flex-col mr-4">
                                        <label htmlFor="VAT 7.5%" className='text-xs text-white font-normal'>VAT 7.5%</label>
                                    </div>
                                    <div className="flex flex-col">
                                        <label htmlFor="Service fee 3.5% to 10%" className='text-xs text-white font-normal'>Service fee 3.5% to 10%</label>
                                    </div>
                                </div>
                            </>


                            {/* MONTHLY PRICE */}
                            <>
                                <div className="flex flex-col mt-6">
                                    <label htmlFor="Daily Price" className='text-xs text-white font-normal'>Monthly Price </label>
                                    <div className="rounded-2xl border bg-[#000] border-white mt-2 flex px-2 flex items-center text-white">
                                        <span className=''>&#8358;</span>

                                        <input
                                            type="text"
                                            defaultValue={editMonthlyPrice || monthlyPrice}
                                            onChange={e => setEditMonthlyPrice(e.target.value)}
                                            className='w-11/12 text-sm bg-[#000] rounded-2xl  px-3 py-3 focus:outline-none text-white' />
                                        <img src={icon} alt="" className='w-12' />
                                    </div>

                                </div>



                                <div className="flex mt-4 mb-6 justify-between">
                                    <div className="flex flex-col mr-4">
                                        <label htmlFor="VAT 7.5%" className='text-xs text-white font-normal'>VAT 7.5%</label>
                                    </div>
                                    <div className="flex flex-col">
                                        <label htmlFor="Service fee 3.5% to 10%" className='text-xs text-white font-normal'>Service fee 3.5% to 10%</label>

                                    </div>
                                </div>
                            </>





                            {/* HOURS AVAILABLE */}
                            <div className="flex flex-col my-6 md:my-6">
                                <label htmlFor="Hours Available:" className='text-xs text-white font-normal'>Hours Available  </label>
                                <input
                                    type="text"
                                    className='text-sm mt-2 bg-[#000] rounded-2xl border border-white px-3 py-3 focus:outline-none text-white' 
                                    defaultValue={editHoursAvailable || hoursAvailable}  
                                    onChange={e => setEditHoursAvailable(e.target.value)}  
                                />
                                    
                            </div>


                            {/* WORKING AIRCONDITIONER */}
                            <div className=" mb-8 flex justify-start flex-col items-start w-full my-6">
                                <p className='md:text-sm text-xx mb-4 text-white'>Does this vehicle have a working Air-Conditioner?</p>

                                <div className="radio-container flex items-center w-full gap-5 pl-3">
                                    <label className='text-white radio-wrapper'>
                                        <input type="radio" name="air-con" defaultValue='yes' className="radio-style cursor-pointer" checked={airConditioner === "yes" }  onChange={handleAirCon}  />
                                        Yes
                                    </label>

                                    <label className='text-white radio-wrapper'>
                                        <input type="radio" name="air-con" defaultValue='no' className="radio-style cursor-pointer" checked={airConditioner === "no"} onChange={handleAirCon} />
                                        No
                                    </label>
                                </div>


                            </div>



                            {/* AVAILABLE FOR TRAVEL */}
                            <div className=" mb-8 flex justify-start flex-col items-start w-full my-6">
                                <p className='md:text-sm text-xx mb-4 text-white'>Is this vehicle avaliable for Travel? <span className='text-red-600'>*</span></p>

                                <div className="radio-container flex items-center w-full gap-5 pl-3">
                                    <label className='text-white radio-wrapper'>
                                        <input type="radio" name="available-vehicle" defaultValue='yes' className="radio-style cursor-pointer" checked={availableTravel === "yes"} onChange={ handleTravel}/>
                                        Yes
                                    </label>

                                    <label className='text-white radio-wrapper'>
                                        <input type="radio" name="available-vehicle" defaultValue='no' className="radio-style cursor-pointer" checked={availableTravel === "no"}  onChange={ handleTravel}/>
                                        No
                                    </label>
                                </div>



                            </div>

                            {/* COMES WITH DRIVER */}
                            <div className=" mb-8 flex justify-start flex-col items-start w-full my-6">
                                <p className='md:text-sm text-xx mb-4 text-white'>Does Your vehicle come with driver? <span className='text-red-600'>*</span></p>

                                <div className="radio-container flex items-center w-full gap-5 pl-3">
                                    <label className='text-white radio-wrapper'>
                                        <input type="radio" name="available-driver" defaultValue='yes' className="radio-style cursor-pointer" checked={comesWithDriver === "yes"} onChange={ handleComesWithDriver}/>
                                        Yes
                                    </label>

                                    <label className='text-white radio-wrapper'>
                                        <input type="radio" name="available-driver" defaultValue='no' className="radio-style cursor-pointer" checked={comesWithDriver === "no"}  onChange={ handleComesWithDriver}/>
                                        No
                                    </label>
                                </div>



                            </div>


                            {/* VEHICLE COLOR */}
                            <div className="flex flex-col my-6 md:my-6">
                                <label htmlFor="Vehicle Color" className='text-xs text-white font-normal'>Vehicle Color <span className="text-red-600">*</span> </label>

                                <input
                                    type="text"
                                    className='text-sm mt-2 bg-[#000] rounded-2xl border border-white px-3 py-3 focus:outline-none text-white' 
                                    defaultValue={editVehicleColor || vehicleColor}    
                                    onChange={e => setEditVehicleColor(e.target.value)}
                                />

                            </div>


                            {/* SERVICE OFFERED */}
                            <div className="flex flex-col my-6 md:my-6">
                                <div className="flex justify-between items-center relative mb-2">
                                    <label htmlFor="Address:" className='text-xs text-white font-normal'>Service <span className="text-red-600">*</span> </label>

                                    {
                                        statusDoc === 'Approved' &&
                                        <img src={pen} alt="Edit Pen" className='w-6 cursor-pointer' 
                                            onClick={() => setIsEditService(isEditService => !isEditService)}
                                            onMouseOver={() => setEditServiceInfo(true)}
                                            onMouseOut={() => setEditServiceInfo(false)}
                                        />
                                    }

                                    {
                                        statusDoc === 'Rejected' &&
                                        <img src={pen} alt="Edit Pen" className='w-6 cursor-pointer' 
                                            onClick={() => setIsEditService(isEditService => !isEditService)}
                                            onMouseOver={() => setEditServiceInfo(true)}
                                            onMouseOut={() => setEditServiceInfo(false)}
                                        />
                                    }

                                    {
                                        editServiceInfo &&
                                        <div className="flex absolute bottom-8 right-0 items-center rounded-xl p-4 bg-[#fff]">
                                            <p className='text-center'> Click this if you wish to change service type</p>
                                        </div>
                                    }
                                </div>

                                {
                                    !isEditService &&
                                    <input
                                        type="text"
                                        className='text-sm bg-[#000] rounded-2xl border border-white px-3 py-3 focus:outline-none text-white' 
                                        defaultValue={editServices || services}
                                        onChange={e => setEditServices(e.target.value)}    
                                    />
                                }

                                {
                                    isEditService &&
                                    <Multiselect 
                                    customCloseIcon={<img src={cancel} className="cursor-pointer"/>}
                                    options={optionsValue} 
                                    displayValue="label" 
                                    onRemove={e => (setAvailabilitySelect(e))}
                                    onSelect={e => (setAvailabilitySelect(e))}

                                    placeholder="Tick all the ones that apply to you"
                                    hidePlaceholder={true}
                                    style={{
                                        chips: {
                                        border: 'solid 1px black',
                                        background: '#fff',
                                        color: '#000'
                                        },
                                        multiselectContainer: {
                                        color: '#000',
                                        cursor: 'pointer',
                                        },
                                        searchBox: {
                                        border: 'solid 1px #fff',
                                        'borderRadius': '13px',
                                        'padding': '10px',
                                        background: '#000'
                                        },
                                        closeIcon: {
                                        'filter': 'grayscale(100%)'
                                        }
                                    }}
                                />
                                }

                            </div>




                            {/* VEHICLE LOCATION */}
                            <div className="flex gap-5 my-6 md:my-6 w-full component-approval-form-location-wrapper">
                                <div className="flex flex-col w-1/2">
                                    <label htmlFor="State" className='text-xs text-white font-normal'>Vehicle Location <span className="text-red-600">*</span> </label>
                                    <input 
                                    type="text" 
                                    defaultValue={editVehicleState || vehicleState}
                                    onChange={e => setEditVehicleState(e.target.value)}
                                    className='text-sm mt-2 bg-[#000] rounded-2xl border border-white px-3 py-3 focus:outline-none text-white' />
                                </div>
                                <div className="flex flex-col w-1/2">
                                    <label htmlFor="Country" className='text-xs text-white font-normal'>&nbsp;</label>
                                    <input type="text" 
                                    defaultValue={editVehicleCountry || vehicleCountry} 
                                    onChange={e => setEditVehicleCountry(e.target.value)}
                                    className='text-sm mt-2 bg-[#000] rounded-2xl border border-white px-3 py-3 focus:outline-none text-white' />
                                </div>
                            </div>

                            
                            {/* COMPREHENSIVE INSURANCE */}
                            <div className=" my-6 flex justify-start flex-col items-start w-full mb-12">
                                <p className='md:text-sm text-xx mb-4 text-white'>Does this vehicle have a comprehensive insurance? <span className='text-red-600'>*</span></p>

                                <div className="radio-container flex items-center w-full gap-5 pl-3">
                                    <label className='text-white radio-wrapper'>
                                        <input type="radio" name="comprehensive insurance" defaultValue='yes' className="radio-style cursor-pointer" checked={compInsurance === "yes"} onChange={ handleInsurance}/>
                                        Yes
                                    </label>

                                    <label className='text-white radio-wrapper'>
                                        <input type="radio" name="comprehensive insurance" defaultValue='no' className="radio-style cursor-pointer" checked={compInsurance === "no"} onChange={ handleInsurance}/>
                                        No
                                    </label>
                                </div>


                            </div>

                            {/* VEHICLE DESCRIPTION */}
                            <div className="flex flex-col my-6">
                                <label htmlFor="Address:" className='text-xs text-white font-normal'>Vehicle Description <span className="text-red-600">*</span> </label>

                                <div className="flex items-center text-sm mt-2 px-2 bg-[#000] w-full rounded-2xl border border-white  text-white">
                                    <input 
                                    type="text" 
                                    defaultValue={editVehicleNote || vehicleNote}
                                    onChange={e => setEditVehicleNote(e.target.value)}
                                    className='w-11/12 p-2 text-sm mt-2 bg-[#000] rounded-2xl  focus:outline-none text-white' />
                                    <img src={message} alt="" className='w-6' />
                                </div>
                            </div>

                            
                            {/* IMAGE SECTION */}
                            {
                                statusDoc === "Approved" || "Rejected" &&
                                <div className="flex justify-end w-full relative ">
                                    <img src={pen} alt="Edit Pen"  className='cursor-pointer w-6'
                                        onClick={() => setIsEditCarImage(isEditCarImage => !isEditCarImage)}
                                        onMouseOver={() => setShowImageInfo(true)}
                                        onMouseOut={() => setShowImageInfo(false)}
                                    />

                                    {
                                        showImageInfo &&
                                        <div className="flex absolute bottom-8 right-0 items-center rounded-xl p-4 bg-[#fff]">
                                            <p className='text-center'> Click this if you wish to change vehicle image</p>
                                        </div>
                                    }

                                </div>
                            }
                            
                            {
                                !isEditCarImage && 
                                <div className="flex flex-wrap justify-start gap-4 md:gap-5 mt-8 w-full md:w-full img-view ">
                                    <div className="upload-overall">
                                        <div className={'bg-[#fff] upload-img-overall' }>
                                            <div className='upload-img-container'>
                                                <div className='img-preview'>
                                                    <img
                                                    src={`https://api.roadlers.com/storage/${img1}`}
                                                    className="uploaded-img "
                                                    alt="Thumb"
                                                    />
                                                </div>
                                            </div>
                                        </div>  
                                        <p className='text-white mt-3'>Image 1 <span className='text-red-500'>*</span></p>
                                    </div>


                                    <div className={'bg-[#fff] upload-img-overall' }>
                                        <div className="upload-img-overall">
                                            <div className='upload-img-container'>
                                                <div className='img-preview'>
                                                    <img
                                                    src={`https://api.roadlers.com/storage/${img2}`}
                                                    className="uploaded-img object-cover object-center"
                                                    alt="Thumb"
                                                    />
                                                </div>         
                                            </div>
                                        </div> 
                                        <p className='text-white mt-3'>Image 2 <span className='text-red-500'>*</span></p>
                                    </div>


                                    <div className={ 'bg-[#fff] upload-img-overall individual-img-approval' }>
                                        <div className="upload-img-overall">
                                            <div className='upload-img-container'>
                                                <div className='img-preview'>
                                                    <img
                                                    src={`https://api.roadlers.com/storage/${img3}`}
                                                    className="uploaded-img object-cover object-center"
                                                    alt="Thumb"
                                                    />
                                                </div>
                                            </div>
                                        </div>   
                                        <p className='text-white mt-3'>Image 3 <span className='text-red-500'>*</span></p>
                                    </div>




                                </div>
                            }

                            {
                                isEditCarImage && 
                                <div className="flex flex-wrap justify-start gap-4 md:gap-5 mt-8 w-full md:w-full img-view ">
                                    <div className="upload-overall mb-6">
                                        <div className={!selectedImage ? 'bg-[#000] upload-img-overall' : 'bg-white upload-img-overall' }>
                                            <div className='upload-img-container'>
                                            {/* <img src={car} alt="" className='contrast-75 brightness-50 md:w-36 w-28 h-auto bg-white rounded-md p-2 object-cover object-center' /> */}
                                                        <input
                                                        accept="image/*"
                                                        style={{ display: 'none' }}
                                                        ref={image1Ref}
                                                        type="file"
                                                        onChange={imageChange}
                                                        />

                                                        {
                                                            !selectedImage ? 
                                                            <div className=
                                                                "upload-img-cta absolute w-10/12 rounded-md border text-white font-light text-xx md:text-base border-amber-400 py-1 flex justify-center items-center z-[10] cursor-pointer"
                                                                onClick={(e) => {
                                                                    e.preventDefault()
                                                                    image1Ref.current.click()
                                                                }}
                                                            >
                                                            Upload Image
                                                            </div>
                                                            :
                                                            // <div className=
                                                            //     "absolute top-6 md:top-[40%] left-4 w-8/12 md:left-7 rounded-md border text-white font-light text-xx md:text-base border-amber-400 bg-amber-400 py-1 flex justify-center items-center z-[-1] cursor-pointer"
                                                            //     onClick={imageChange}
                                                            // >
                                                            //   Change Image
                                                            // </div>
                                                            ''
                                                        }

                                                        {selectedImage && (
                                                        <div className='img-preview'>
                                                            <img
                                                            src={URL.createObjectURL(selectedImage)}
                                                            className="uploaded-img "
                                                            alt="Thumb"
                                                            />
                                                            <button  onClick={removeSelectedImage} className="delete-img">
                                                                <img src={cancel} alt="cancel btn" />
                                                            </button>
                                                        </div>
                                                        )}
                                            </div>
                                            <p className='text-white mt-3'>Image 1 <span className='text-red-500'>*</span> <span className='text-[#FFA100]'>(Front View)</span></p>
                                        </div>  
                                    </div>
                            
                                    <div className={!selectedImage1 ? 'bg-[#000] upload-img-overall individual-img-wrapper' : 'bg-white upload-img-overall individual-img-wrapper' }>
                                        <div className="upload-img-overall">
                                            <div className='upload-img-container'>
                                            {/* <img src={car} alt="" className='contrast-75 brightness-50 md:w-36 w-28 h-auto bg-white rounded-md p-2 object-cover object-center' /> */}
                                                        <input
                                                        accept="image/*"
                                                        style={{ display: 'none' }}
                                                        type="file"
                                                        ref={image2Ref}
                                                        onChange={imageChange1}
                                                        />

                                                        {
                                                            !selectedImage1 ? 
                                                            <div className=
                                                                "w-10/12 upload-img-cta rounded-md border text-white font-light text-xx md:text-base border-amber-400 py-1 flex justify-center items-center z-[10] cursor-pointer"
                                                                onClick={(e) => {
                                                                    e.preventDefault()
                                                                    image2Ref.current.click()
                                                                }}
                                                            >
                                                            Upload Image
                                                            </div>
                                                            :
                                                            // <div className=
                                                            //     "absolute top-6 md:top-[40%] left-4 w-8/12 md:left-7 rounded-md border text-white font-light text-xx md:text-base border-amber-400 bg-amber-400 py-1 flex justify-center items-center z-[-1] cursor-pointer"
                                                            //     onClick={imageChange}
                                                            // >
                                                            //   Change Image
                                                            // </div>
                                                            ''
                                                        }

                                                        {selectedImage1 && (
                                                        <div className='img-preview'>
                                                            <img
                                                            src={URL.createObjectURL(selectedImage1)}
                                                            className="uploaded-img object-cover object-center"
                                                            alt="Thumb"
                                                            />
                                                            <button  onClick={removeSelectedImage1} className="delete-img">
                                                                <img src={cancel} alt="cancel btn" />
                                                            </button>
                                                        </div>
                                                        )}
                                            </div>
                                        </div> 
                                        <p className='text-white mt-3'>Image 2 <span className='text-red-500'>*</span> <span className='text-[#FFA100]'>(Interior)</span></p>
                                    </div>

                                    <div className={!selectedImage2 ? 'bg-[#000] upload-img-overall individual-img-wrapper' : 'bg-white upload-img-overall individual-img-wrapper' }>
                                            <div className="upload-img-overall">
                                                <div className='upload-img-container'>
                                                {/* <img src={car} alt="" className='contrast-75 brightness-50 md:w-36 w-28 h-auto bg-white rounded-md p-2 object-cover object-center' /> */}
                                                            <input
                                                            accept="image/*"
                                                            style={{ display: 'none' }}
                                                            ref={image3Ref}
                                                            type="file"
                                                            onChange={imageChange2}
                                                            />

                                                            {
                                                                !selectedImage2 ? 
                                                                <div className=
                                                                    " w-10/12 upload-img-cta rounded-md border text-white font-light text-xx md:text-base border-amber-400 py-1 flex justify-center items-center z-[10] cursor-pointer"
                                                                    onClick={(e) => {
                                                                        e.preventDefault()
                                                                        image3Ref.current.click()
                                                                    }}
                                                                >
                                                                Upload Image
                                                                </div>
                                                                :
                                                                // <div className=
                                                                //     "absolute top-6 md:top-[40%] left-4 w-8/12 md:left-7 rounded-md border text-white font-light text-xx md:text-base border-amber-400 bg-amber-400 py-1 flex justify-center items-center z-[-1] cursor-pointer"
                                                                //     onClick={imageChange}
                                                                // >
                                                                //   Change Image
                                                                // </div>
                                                                ''
                                                            }

                                                            {selectedImage2 && (
                                                            <div className='img-preview'>
                                                                <img
                                                                src={URL.createObjectURL(selectedImage2)}
                                                                className="uploaded-img object-cover object-center"
                                                                alt="Thumb"
                                                                />
                                                                <button  onClick={removeSelectedImage2} className="delete-img">
                                                                    <img src={cancel} alt="cancel btn" />
                                                                </button>
                                                            </div>
                                                            )}
                                                </div>
                                            </div>   
                                            <p className='text-white mt-3'>Image 3 <span className='text-red-500'>*</span> <span className='text-[#FFA100]'>(Back View)</span></p>
                                        </div>
                                </div>
                            }

                            {/* CAR OWNER INFORMATION SECTION */}
                            {
                                carOwnerId !== "null" && 
                                <div className="mt-[80px] text-white">
                                    <h4 className='text-white text-lg font-bold'>Car Owner's Information</h4>
                                    <div className="flex gap-5 my-6 md:my-6 w-full component-approval-form-name-wrapper">
                                        <div className="flex flex-col w-1/2">
                                            <label htmlFor="first name" className='text-xs text-white font-normal'>First Name</label>
                                            <input
                                                type="text"
                                                placeholder='First Name'
                                                className='text-sm mt-2 bg-[#000] rounded-2xl border border-white px-3 py-3 focus:outline-none text-white'
                                                defaultValue={carOwnerProfile?.firstname}
                                                // onChange={(e) => setEditVehicleName(e.target.value)}
                                            />
                                        </div>
                                        <div className="flex flex-col w-1/2">
                                            <label htmlFor="last name" className='text-xs text-white font-normal'>Last Name</label>
                                            <input
                                                type="text"
                                                placeholder='Last Name'
                                                className='text-sm mt-2 bg-[#000] rounded-2xl border border-white px-3 py-3 focus:outline-none text-white'
                                                defaultValue={carOwnerProfile?.lastname}
                                                // onChange={(e) => setEditVehicleYear(e.target.value)}
                                            />

                                        </div>
                                    </div>

                                    <div className="mt-4">
                                        <div className="flex flex-col my-6 md:my-6">
                                            <label htmlFor="Owner's Address" className='text-xs text-white font-normal'>Address</label>
                                            <input
                                                type="text"
                                                className='text-sm mt-2 bg-[#000] rounded-2xl border border-white px-3 py-3 focus:outline-none text-white' 
                                                 defaultValue={carOwnerProfile?.address}
                                            />    
                                        </div>
                                    </div>

                                    <div className="mt-4">
                                        <div className="flex flex-col my-6 md:my-6">
                                            <label htmlFor="Owner's Identification" className='text-xs text-white font-normal'>Identification</label>
                                            <input
                                                type="text"
                                                className='text-sm mt-2 bg-[#000] rounded-2xl border border-white px-3 py-3 focus:outline-none text-white' 
                                                 defaultValue={carOwnerProfile?.identification.toUpperCase()}
                                            />    
                                        </div>
                                    </div>

                                    <div className="mt-4">
                                        <div className="flex flex-col my-6 md:my-6">
                                            <label htmlFor="Owner's Identification Number" className='text-xs text-white font-normal'>Identification Number</label>
                                            <input
                                                type="text"
                                                className='text-sm mt-2 bg-[#000] rounded-2xl border border-white px-3 py-3 focus:outline-none text-white' 
                                                 defaultValue={carOwnerProfile?.id_number}
                                            />    
                                        </div>
                                    </div>

                                    <div className="mt-4">
                                        <div className="flex flex-col my-6 md:my-6">
                                            <label htmlFor="Owner's Phone Number" className='text-xs text-white font-normal'>Phone Number</label>
                                            <input
                                                type="text"
                                                className='text-sm mt-2 bg-[#000] rounded-2xl border border-white px-3 py-3 focus:outline-none text-white' 
                                                 defaultValue={carOwnerProfile?.phone_number}
                                            />    
                                        </div>
                                    </div>

                                    <div className=" mt-4 flex flex-col">
                                        <label htmlFor="Birthday date:" className='text-xs text-[#FFF] font-normal'>Birthday date.</label>
                                        <div className="rounded-2xl border border-white mt-2 flex px-2 bg-[#000]">
                                            <input type="date" 
                                                defaultValue={carOwnerProfile?.dob} 
                                                // onChange={e => setEditDob(e.target.value)}
                                                className='w-11/12 text-sm bg-[#000] rounded-2xl  p-3 focus:outline-none text-white' />
                                            <img src={icon} alt="" className='w-12' />
                                        </div>

                                    </div>

                                    <div className="flex gap-5 my-6 md:my-6 w-full component-approval-form-name-wrapper">
                                        <div className="flex flex-col w-1/2">
                                            <label htmlFor="Owner's State" className='text-xs text-white font-normal'>State</label>
                                            <input
                                                type="text"
                                                placeholder='State'
                                                className='text-sm mt-2 bg-[#000] rounded-2xl border border-white px-3 py-3 focus:outline-none text-white'
                                                defaultValue={carOwnerProfile?.state}
                                                // onChange={(e) => setEditVehicleName(e.target.value)}
                                            />
                                        </div>
                                        <div className="flex flex-col w-1/2">
                                            <label htmlFor="Owner's country" className='text-xs text-white font-normal'>Country</label>
                                            <input
                                                type="text"
                                                placeholder='Country'
                                                className='text-sm mt-2 bg-[#000] rounded-2xl border border-white px-3 py-3 focus:outline-none text-white'
                                                defaultValue={carOwnerProfile?.country}
                                                // onChange={(e) => setEditVehicleYear(e.target.value)}
                                            />

                                        </div>
                                    </div>
                                </div>
                            }
 




                            {/*APPROVED SERVICE  */}
                            <div className="flex  mt-[70px] flex-col ">
                            {
                                (statusDoc == 'Pending' || !statusDoc) &&
                                <button className='bg-blue-900 rounded text-white px-4 py-1 w-1/2 font-semibold text-sm'>Pending</button>
                            }

                            {
                                statusDoc === 'Approved' &&
                                <>
                                    <button className='bg-amber-500 rounded text-black px-4 py-1 font-bold text-sm  md:w-28'>Approved</button>
                                    <div className="my-6 mb-2 flex gap-5 mt-8 md:gap-2 w-11/12">
                                        <button className='bg-blue-900 rounded text-white px-4 py-2 md:py-2 text-xs md:px-3 font-bold md:text-sm w-8/12'>Account Verified</button>
                                        {
                                            (compareDate === false) &&
                                            <button
                                                className='bg-white bg-opacity-50 rounded text-black  px-4 py-2 md:py-2 text-xs  font-bold md:text-sm w-4/12'
                                                disabled={true}
                                            >
                                                Save
                                            </button>
                                        }

                                        {
                                            (compareDate === true) &&
                                            (
                                                !profileSaved ?
                                                <button
                                                    className='flex justify-center items-center bg-white rounded text-black  px-4 py-2 md:py-2 text-xs  font-bold md:text-sm w-4/12'
                                                    onClick={handleVehiclProfileSubmit}
                                                >
                                                    Save
                                                    {
                                                        vehicleProfileSpin && 
                                                        <input
                                                            type="image"
                                                            src={Spin}
                                                            value="Loading"
                                                            className="w-4 md:w-6"
                                                        />
                                                    }
                                                </button> :
                                                <button
                                                    className='bg-white bg-opacity-50 rounded text-black  px-4 py-2 md:py-2 text-xs  font-bold md:text-sm w-4/12'
                                                    disabled={true}
                                                >
                                                    Saved
                                                </button>
                                            )
                                        }


                                    </div> 
                                    <p className='text-red-600 mb-2 text-xs'>You are eigible to edit 30 days after approval</p>                            
                                </>
                            }

                            {
                                statusDoc === 'Rejected' && 
                                <>
                                    <div className="flex items-center gap-[20px]">
                                        <button className='bg-red-600 rounded text-white px-4 py-1 font-semibold text-sm w-1/4' disabled={true}>Rejected</button>

                                        <p className='text-[#FFA100]'>Edit your informations and resubmit for Approval</p>
                                    </div>
                                    <div className="my-6 flex gap-5 mt-8 md:gap-2 w-11/12">
                                        {
                                            !profileSaved ?
                                            <button
                                                className='flex justify-center items-center  bg-white rounded text-black  px-4 py-2 md:py-2 text-xs  font-bold md:text-sm w-4/12'
                                                onClick={handleVehiclProfileSubmit}
                                            >
                                                Save
                                                {
                                                    vehicleProfileSpin && 
                                                    <input
                                                        type="image"
                                                        src={Spin}
                                                        value="Loading"
                                                        className="w-4 md:w-6"
                                                    />
                                                }
                                            </button>:
                                            <button
                                                className='bg-white bg-opacity-50 rounded text-black  px-4 py-2 md:py-2 text-xs  font-bold md:text-sm w-4/12'
                                                disabled={true}
                                            >
                                                Saved
                                            </button>

                                        }
                                    </div> 
                                </>
                            }
                            </div>

                        </form>
                    </div>
                </div>

            </main>
        </div>
        <div className="border-t border-amber-400 -px-12"></div>
        <div className=' md:w-4/12 w-full md:pt-36 pt-16 mb-12 md:px-16 component-approval-doc-section' >
            <div className="md:flex justify-end hidden "><h4 className='font-bold text-amber-500 mb-8 '>Provider’s ID: {providerId}</h4></div>

            <h2 className='mb-10 text-white font-bold text-lg'>Vehicle Documents</h2>

            {/*PROOF OF OWNERSHIP  */}
            {
                (vehicleDoc?.filter(obj => obj.doc_type.includes("proof of ownership")).map((docItem) => {
                    return (
                        <div className="my-8">
                            {
                                (docItem.status === "Pending" ) &&

                                <div className="flex justify-between border border-white p-2 md:p-3 items-center">
                                    <p className='text-white md:text-sm text-xs font-normal'>Proof of ownership <span className='text-red-600'>*</span></p>
                                    <a href={`https://api.roadlers.com/storage/${docItem.document}`} target="_blank" className='border-theme-color px-3 py-1 md:text-sm md:px-2 md:py-0 text-xs text-white'>View</a>
                                </div>
                            }

                            {
                                (docItem.status === "Accept") &&
                                (
                                    (moment(getToday).isAfter(moment(docItem.edit_date).format("YYYY-MM-DD")) === false)  ?
                                    <div className="flex justify-between border border-white p-2 md:p-3 items-center">
                                        <p className='text-white md:text-sm text-xs font-normal'>Proof of ownership <span className='text-red-600'>*</span></p>
                                        <a href={`https://api.roadlers.com/storage/${docItem.document}`} target="_blank" className='border-theme-color px-3 py-1 md:text-sm md:px-2 md:py-0 text-xs text-white'>View</a>
                                    </div>:
                                    <div className="flex justify-between border bg-[#1D1C1C] border-white p-2 md:p-3 items-center">
                                        {
                                            proofName?.length ? (
                                                <p className='text-white md:text-base text-xs font-normal'>{proofName}</p>
                                            ) : (
                                                <p className='text-white md:text-base text-xs font-normal'>Proof of ownership <span className="text-red-600">*</span> </p>
                                            )
                                        }
            
            
                                        {/* use useRef to trigger this input field for proof of ownership upload */}
                                        <input
                                            type="file"
                                            style={{ display: 'none' }}
                                            ref={fileInputRef}
                                            accept="image/jpeg,image/jpg,application/pdf"
            
                                            onChange={(e) => handleProofFileChange(e)} 
                                        />
            
                                        {
                                            ( upload && proof ) ? (
                                                <button
                                                    onClick={(event) => {
                                                        event.preventDefault()
                                                        fileInputRef.current.click()
                                                    }}
                                                    className='border-theme-color px-3 py-1 md:text-base md:px-2 md:py-0 text-xs text-white'>
                                                        Change
                                                </button>
            
                                            ) : (
                                                <button
                                                    onClick={(event) => {
                                                        event.preventDefault()
                                                        fileInputRef.current.click()
                                                    }}
                                                    className='border-theme-color px-3 py-1 md:text-base md:px-2 md:py-0 text-xs text-white'>
                                                        Upload
                                                </button>
                                            )
                                        }
            
                                    </div>
                                )

                            }

                            {
                                (docItem.status === "Rejected") &&
                                <div className="flex justify-between border bg-[#1D1C1C] border-white p-2 md:p-3 items-center">
                                    {
                                        proofName.length ? (
                                            <p className='text-white md:text-base text-xs font-normal'>{proofName}</p>
                                        ) : (
                                            <p className='text-white md:text-base text-xs font-normal'>Proof of ownership <span className="text-red-600">*</span> </p>
                                        )
                                    }
        
        
                                    {/* use useRef to trigger this input field for proof of ownership upload */}
                                    <input
                                        type="file"
                                        style={{ display: 'none' }}
                                        ref={fileInputRef}
                                        accept="image/jpeg,image/jpg,application/pdf"
        
                                        onChange={(e) => handleProofFileChange(e)} 
                                    />
    
        
                                    {
                                        ( upload && proof) ? (
                                            <button
                                                onClick={(event) => {
                                                    event.preventDefault()
                                                    fileInputRef.current.click()
                                                }}
                                                className='border-theme-color px-3 py-1 md:text-base md:px-2 md:py-0 text-xs text-white'>
                                                    Change
                                            </button>
        
                                        ) : (
                                            <button
                                                onClick={(event) => {
                                                    event.preventDefault()
                                                    fileInputRef.current.click()
                                                }}
                                                className='border-theme-color px-3 py-1 md:text-base md:px-2 md:py-0 text-xs text-white'>
                                                    Upload
                                            </button>
                                        )
                                    }
        
                                </div>
                            }
            
                            <div className="flex justify-end mt-4">
                                {
                                    docItem.status === "Rejected" ?
                                    
                                    <div className="flex justify-end mt-2 gap-3">
                                        {
                                            !isProofSaved &&
                                            <button className='flex justify-center items-center bg-white rounded text-[#000] px-4 py-1 font-semibold text-sm' onClick={handleProofSubmit}>Resend
                                                    {
                                                        proofLoad && 
                                                        <input
                                                            type="image"
                                                            src={Spin}
                                                            value="Loading"
                                                            className="w-4 md:w-6"
                                                        />
                                                    }
                                            </button>
                                        }

                                        {
                                            isProofSaved &&
                                            <button className='bg-amber-600 rounded text-[#000] px-4 py-1 font-semibold text-sm' disabled={true}>Saved</button>
                                        }
                                        <button className='bg-red-600 rounded text-white px-4 py-1 font-semibold text-sm' disabled={true}>Rejected</button>
                                    </div> :
                                    <>
                                        {
                                            docItem.status === "Pending" &&
                                            <button className='bg-blue-900 rounded text-white px-4 py-1 font-semibold text-sm' disabled={true}>Pending</button>
                                        }

                                        {
                                            docItem.status === "Accept" &&
                                            <div className="flex justify-end mt-2 gap-3">
                                                {
                                                    (!isProofSaved && (moment(getToday).isAfter(moment(docItem.edit_date).format("YYYY-MM-DD")) === true)) &&
                                                    <button className='flex justify-center items-center bg-white rounded text-[#000] px-4 py-1 font-semibold text-sm' onClick={handleProofSubmit}>Resend
                                                        {
                                                            proofLoad && 
                                                            <input
                                                                type="image"
                                                                src={Spin}
                                                                value="Loading"
                                                                className="w-4 md:w-6"
                                                            />
                                                        }
                                                    </button>
                                                }

                                                {
                                                    isProofSaved &&
                                                    <button className='bg-amber-600 rounded text-[#000] px-4 py-1 font-semibold text-sm' disabled={true}>Saved</button>
                                                }

                                                <button className='bg-amber-500 rounded text-white px-4 py-1 font-semibold text-sm' disabled={true}>Accepted</button>
                                            </div>
                                        }
                                    </>
                                }
                            </div>
                        </div>
                    )
                }))
            }

            {/* VEHICLE LICENSE */}
            {
                (vehicleDoc?.filter(obj => obj.doc_type.includes("vehicle license")).map((docItem) => {
                    return (
                        <div className="my-8">
                            {
                                (docItem.status === "Pending" ) &&

                                <div className="flex justify-between border border-white p-2 md:p-3 items-center">
                                <p className='text-white md:text-sm text-xs font-normal'>Vehicle license / registration <span className='text-red-600'>*</span></p>
                                    <a href={`https://api.roadlers.com/storage/${docItem.document}`} target="_blank" className='border-theme-color px-3 py-1 md:text-sm md:px-2 md:py-0 text-xs text-white'>View</a>
                                </div>
                            }

                            {
                                ( docItem.status === "Accept") &&
                                (
                                    (moment(getToday).isAfter(moment(docItem.edit_date).format("YYYY-MM-DD")) === false) ?
                                    <div className="flex justify-between border border-white p-2 md:p-3 items-center">
                                    <p className='text-white md:text-sm text-xs font-normal'>Vehicle license / registration <span className='text-red-600'>*</span></p>
                                        <a href={`https://api.roadlers.com/storage/${docItem.document}`} target="_blank" className='border-theme-color px-3 py-1 md:text-sm md:px-2 md:py-0 text-xs text-white'>View</a>
                                    </div>:
                                    <div className="flex justify-between border bg-[#1D1C1C] border-white p-2 md:p-3 items-center">
                                        {licenseName?.length ?
                                            <p className='text-white md:text-base text-xs font-normal'>{licenseName}</p>:
                                            <p className='text-white md:text-base text-xs font-normal'>Vehicle license / registration  <span className="text-red-600">*</span></p>

                                        }
                                        


                                        <input
                                            type="file"
                                            style={{ display: 'none' }}
                                            ref={licenceInputRef}
                                            accept="image/jpeg,image/jpg,application/pdf"


                                            onChange={(e) => handleLicenseFileChange(e)} />


                                        {
                                            (licence && licenceUpload ) ? (
                                                <button
                                                    onClick={(event) => {
                                                        event.preventDefault()
                                                        licenceInputRef.current.click()
                                                    }}
                                                    className='border-theme-color px-3 py-1 md:text-base md:px-2 md:py-0 text-xs text-white'>
                                                        Change
                                                </button>
                                            ) : (
                                                <button
                                                    onClick={(event) => {
                                                        event.preventDefault()
                                                        licenceInputRef.current.click()
                                                    }}
                                                    className='border-theme-color px-3 py-1 md:text-base md:px-2 md:py-0 text-xs text-white'>
                                                        Upload
                                                </button>
                                            )
                                        }
                                    </div>
                                )
                            }


                            {
                                (docItem.status === "Rejected") &&

                                <div className="flex justify-between border bg-[#1D1C1C] border-white p-2 md:p-3 items-center">
                                    {licenseName?.length ?
                                        <p className='text-white md:text-base text-xs font-normal'>{licenseName}</p>:
                                        <p className='text-white md:text-base text-xs font-normal'>Vehicle license / registration  <span className="text-red-600">*</span></p>

                                    }
                                    


                                    <input
                                        type="file"
                                        style={{ display: 'none' }}
                                        ref={licenceInputRef}
                                        accept="image/jpeg,image/jpg,application/pdf"


                                        onChange={(e) => handleLicenseFileChange(e)} />


                                    {
                                        (licence && licenceUpload ) ? (
                                            <button
                                                onClick={(event) => {
                                                    event.preventDefault()
                                                    licenceInputRef.current.click()
                                                }}
                                                className='border-theme-color px-3 py-1 md:text-base md:px-2 md:py-0 text-xs text-white'>
                                                    Change
                                            </button>
                                        ) : (
                                            <button
                                                onClick={(event) => {
                                                    event.preventDefault()
                                                    licenceInputRef.current.click()
                                                }}
                                                className='border-theme-color px-3 py-1 md:text-base md:px-2 md:py-0 text-xs text-white'>
                                                    Upload
                                            </button>
                                        )
                                    }
                                </div>
                            }
                            
            
                            <div className="flex justify-end mt-4">
                                {
                                    docItem.status === "Rejected" ?
                                    
                                    <div className="flex justify-end mt-2 gap-3">
                                        {
                                            !isLicenseSaved &&
                                            <button className='flex justify-center items-center bg-white rounded text-[#000] px-4 py-1 font-semibold text-sm' onClick={handleLicenseUpdate}>Resend
                                                        {
                                                            licenseLoad && 
                                                            <input
                                                                type="image"
                                                                src={Spin}
                                                                value="Loading"
                                                                className="w-4 md:w-6"
                                                            />
                                                        }
                                            </button>
                                        }

                                        {
                                            isLicenseSaved &&
                                            <button className='bg-amber-600 rounded text-[#000] px-4 py-1 font-semibold text-sm' disabled={true}>Saved</button>
                                        }

                                        <button className='bg-red-600 rounded text-white px-4 py-1 font-semibold text-sm' disabled={true}>Rejected</button>
                                    </div> :
                                    <>
                                        {
                                            docItem.status === "Pending" &&
                                            <button className='bg-blue-900 rounded text-white px-4 py-1 font-semibold text-sm' disabled={true}>Pending</button>
                                        }

                                        {
                                            docItem.status === "Accept" &&
                                            <div className="flex justify-end mt-2 gap-3">
                                                {
                                                    (!isLicenseSaved && (moment(getToday).isAfter(moment(docItem.edit_date).format("YYYY-MM-DD")) === true)) &&
                                                    <button className='flex justify-center items-center bg-white rounded text-[#000] px-4 py-1 font-semibold text-sm' onClick={handleLicenseUpdate}>Resend
                                                            {
                                                                licenseLoad && 
                                                                <input
                                                                    type="image"
                                                                    src={Spin}
                                                                    value="Loading"
                                                                    className="w-4 md:w-6"
                                                                />
                                                            }
                                                    </button>
                                                }

                                                {
                                                    isLicenseSaved &&
                                                    <button className='bg-amber-600 rounded text-[#000] px-4 py-1 font-semibold text-sm' disabled={true}>Saved</button>
                                                }
                                                <button className='bg-amber-500 rounded text-white px-4 py-1 font-semibold text-sm' disabled={true}>Accepted</button>
                                            </div>

                                        }
                                    </>
                                }
                            </div>
                        </div>
                    )
                }))
            }


            {/* CERTIFICATE OF CAR INSURANCE */}
            {
                (vehicleDoc?.filter(obj => obj.doc_type.includes("car insurance")).map((docItem) => {
                    return (
                        <div className="my-8">
                            {
                                (docItem.status === "Pending") &&

                                <div className="flex justify-between border border-white p-2 md:p-3 items-center">
                                <p className='text-white md:text-sm text-xs font-normal'>Certificate of car insurance</p>
                                    <a href={`https://api.roadlers.com/storage/${docItem.document}`} target="_blank" className='border-theme-color px-3 py-1 md:text-sm md:px-2 md:py-0 text-xs text-white'>View</a>
                                </div>
                            }

                            {
                                (docItem.status === "Accept") &&
                                (
                                    (moment(getToday).isAfter(moment(docItem.edit_date).format("YYYY-MM-DD")) === false) ?
                                    <div className="flex justify-between border border-white p-2 md:p-3 items-center">
                                    <p className='text-white md:text-sm text-xs font-normal'>Certificate of car insurance</p>
                                        <a href={`https://api.roadlers.com/storage/${docItem.document}`} target="_blank" className='border-theme-color px-3 py-1 md:text-sm md:px-2 md:py-0 text-xs text-white'>View</a>
                                    </div> :
                                    <div className="">
                                        <div className="flex justify-between border bg-[#1D1C1C] border-white p-2 md:p-3 items-center">
                                        <input
                                        type="file"
                                        style={{ display: 'none' }}
                                        ref={insuranceInputRef}

                                        accept="image/jpeg,image/jpg,application/pdf"

                                        onChange={(e) => handleInsuranceFileChange(e)} />

                                            {
                                                insuranceName?.length ?
                                                <p className='text-white md:text-base text-xs font-normal'>{insuranceName} </p> :
                                                <p className='text-white md:text-base text-xs font-normal'>Certificate of car insurance <span  className='text-[#EBAF4A]'> {'(optional)'}</span> </p>
                                            }
                                        
                                        {   (insurance && insuranceUpload ) ? ( 
                                            <button
                                            onClick={(event) => {
                                                event.preventDefault()
                                                insuranceInputRef.current.click()
                                            }}
                                            className='border-theme-color px-3 py-1 md:text-base md:px-2 md:py-0 text-xs text-white'>Change</button>
                                        
                                        ):(
                                            <button
                                                onClick={(event) => {
                                                    event.preventDefault()
                                                    insuranceInputRef.current.click()
                                                }}
                                                className='border-theme-color px-3 py-1 md:text-base md:px-2 md:py-0 text-xs text-white'>Upload</button>
                                        )    
                                        }
                                        </div>
                                    </div>                                       
                                )

                            }

                            {
                                (docItem.status === "Rejected") &&
                                <div className="">
                                    <div className="flex justify-between border bg-[#1D1C1C] border-white p-2 md:p-3 items-center">
                                    <input
                                    type="file"
                                    style={{ display: 'none' }}
                                    ref={insuranceInputRef}

                                    accept="image/jpeg,image/jpg,application/pdf"

                                    onChange={(e) => handleInsuranceFileChange(e)} />

                                        {
                                            insuranceName?.length ?
                                            <p className='text-white md:text-base text-xs font-normal'>{insuranceName} </p> :
                                            <p className='text-white md:text-base text-xs font-normal'>Certificate of car insurance <span  className='text-[#EBAF4A]'> {'(optional)'}</span> </p>
                                        }
                                    
                                    {   (insurance && insuranceUpload ) ? ( 
                                        <button
                                        onClick={(event) => {
                                            event.preventDefault()
                                            insuranceInputRef.current.click()
                                        }}
                                        className='border-theme-color px-3 py-1 md:text-base md:px-2 md:py-0 text-xs text-white'>Change</button>
                                    
                                    ):(
                                        <button
                                            onClick={(event) => {
                                                event.preventDefault()
                                                insuranceInputRef.current.click()
                                            }}
                                            className='border-theme-color px-3 py-1 md:text-base md:px-2 md:py-0 text-xs text-white'>Upload</button>
                                    )    
                                    }
                                    </div>
                                </div>    

                            }

            
                            <div className="flex justify-end mt-4">
                                {
                                    docItem.status === "Rejected" ?
                                    
                                    <div className="flex justify-end mt-2 gap-3">
                                        { !isInsuranceSaved && 

                                        <button className='flex justify-center items-center bg-white rounded text-[#000] px-4 py-1 font-semibold text-sm' onClick={handleInsuranceUpdate}>Resend
                                                    {
                                                        insuranceLoad && 
                                                        <input
                                                            type="image"
                                                            src={Spin}
                                                            value="Loading"
                                                            className="w-4 md:w-6"
                                                        />
                                                    }
                                        </button>
                                        }

                                        { isInsuranceSaved && 

                                        <button className='bg-amber-600 rounded text-[#000] px-4 py-1 font-semibold text-sm'>Saved</button>
                                        }
                                        <button className='bg-red-600 rounded text-white px-4 py-1 font-semibold text-sm' disabled={true}>Rejected</button>
                                    </div> :
                                    <>
                                        {
                                            docItem.status === "Pending" &&
                                            <button className='bg-blue-900 rounded text-white px-4 py-1 font-semibold text-sm' disabled={true}>Pending</button>
                                        }

                                        {
                                            docItem.status === "Accept" &&
                                            <div className="flex justify-end mt-2 gap-3">
                                                { (!isInsuranceSaved && (moment(getToday).isAfter(moment(docItem.edit_date).format("YYYY-MM-DD")) === true)) && 
                                                    <button className='flex justify-center items-center bg-white rounded text-[#000] px-4 py-1 font-semibold text-sm' onClick={handleInsuranceUpdate}>Resend
                                                        {
                                                            insuranceLoad && 
                                                            <input
                                                                type="image"
                                                                src={Spin}
                                                                value="Loading"
                                                                className="w-4 md:w-6"
                                                            />
                                                        }
                                                    </button>
                                                }

                                                { isInsuranceSaved && 
                                                    <button className='bg-amber-600 rounded text-[#000] px-4 py-1 font-semibold text-sm'>Saved</button>
                                                }

                                                <button className='bg-amber-500 rounded text-white px-4 py-1 font-semibold text-sm' disabled={true}>Accepted</button>
                                            </div>

                                        }
                                    </>
                                }
                            </div>
                        </div>
                    )
                }))
            }


            {/* CERTIFICATE OF ROAD WORHTINESS */}
            {
                (vehicleDoc?.filter(obj => obj.doc_type.includes("road worthiness")).map((docItem) => {
                    return (
                        <div className="my-8">

                            {
                                (docItem.status === "Pending" ) &&
                                <div className="flex justify-between border border-white p-2 md:p-3 items-center">
                                <p className='text-white md:text-sm text-xs font-normal'>Certificate of road worthiness</p>
                                    <a href={`https://api.roadlers.com/storage/${docItem.document}`} target="_blank" className='border-theme-color px-3 py-1 md:text-sm md:px-2 md:py-0 text-xs text-white'>View</a>
                                </div>
                            }

                            {
                                (docItem.status === "Accept" ) &&
                                (
                                    (moment(getToday).isAfter(moment(docItem.edit_date).format("YYYY-MM-DD")) === false) ?
                                    <div className="flex justify-between border border-white p-2 md:p-3 items-center">
                                    <p className='text-white md:text-sm text-xs font-normal'>Certificate of road worthiness</p>
                                        <a href={`https://api.roadlers.com/storage/${docItem.document}`} target="_blank" className='border-theme-color px-3 py-1 md:text-sm md:px-2 md:py-0 text-xs text-white'>View</a>
                                    </div>:
                                    <div className="">
                                        <div className="flex justify-between border bg-[#1D1C1C] border-white p-2 md:p-3 items-center">
                                        <input
                                        type="file"
                                        style={{ display: 'none' }}
                                        ref={worthinessInputRef}
                                        accept="image/jpeg,image/jpg,application/pdf"
                                        onChange={(e) => handleWorthinessFileChange(e)} />

                                            {
                                                worthinessName?.length ?
                                                <p className='text-white md:text-base text-xs font-normal'>{worthinessName} </p> :
                                                <p className='text-white md:text-base text-xs font-normal'>Certificate of road worthiness <span  className='text-[#EBAF4A]'> {'(optional)'}</span> </p>
                
                                            }
                                            {   (worthiness && worthinessUpload) ? (
                                                <button
                                                onClick={(event) => {
                                                    event.preventDefault()
                                                    worthinessInputRef.current.click()
                                                }}
                                                className='border-theme-color px-3 py-1 md:text-base md:px-2 md:py-0 text-xs text-white'>Change</button>
                                        
                                    
                                            ):(
                                                <button
                                                onClick={(event) => {
                                                    event.preventDefault()
                                                    worthinessInputRef.current.click()
                                                }}
                                                className='border-theme-color px-3 py-1 md:text-base md:px-2 md:py-0 text-xs text-white'>Upload</button>
                                        
            
                                            )}
                                        
                                    
                                        </div>
                                    </div>
                                )

                            }

                            {
                                (docItem.status === "Rejected") &&    
                                <div className="">
                                    <div className="flex justify-between border bg-[#1D1C1C] border-white p-2 md:p-3 items-center">
                                    <input
                                    type="file"
                                    style={{ display: 'none' }}
                                    ref={worthinessInputRef}
                                    accept="image/jpeg,image/jpg,application/pdf"
                                    onChange={(e) => handleWorthinessFileChange(e)} />

                                        {
                                            worthinessName?.length ?
                                            <p className='text-white md:text-base text-xs font-normal'>{worthinessName} </p> :
                                            <p className='text-white md:text-base text-xs font-normal'>Certificate of road worthiness <span  className='text-[#EBAF4A]'> {'(optional)'}</span> </p>
            
                                        }
                                        {   (worthiness && worthinessUpload) ? (
                                            <button
                                            onClick={(event) => {
                                                event.preventDefault()
                                                worthinessInputRef.current.click()
                                            }}
                                            className='border-theme-color px-3 py-1 md:text-base md:px-2 md:py-0 text-xs text-white'>Change</button>
                                    
                                
                                        ):(
                                            <button
                                            onClick={(event) => {
                                                event.preventDefault()
                                                worthinessInputRef.current.click()
                                            }}
                                            className='border-theme-color px-3 py-1 md:text-base md:px-2 md:py-0 text-xs text-white'>Upload</button>
                                    
                                
                                        )}
                                    
                                
                                    </div>
                                </div>
                            }
            
                            <div className="flex justify-end mt-4">
                                {
                                    docItem.status === "Rejected" ?
                                    
                                    <div className="flex justify-end mt-2 gap-3">
                                        {
                                            !isWorthinessSaved &&
                                            <button className='flex justify-center items-center bg-white rounded text-[#000] px-4 py-1 font-semibold text-sm' onClick={handleWorthinessUpdate}>Resend
                                                    {
                                                        worthyLoad && 
                                                        <input
                                                            type="image"
                                                            src={Spin}
                                                            value="Loading"
                                                            className="w-4 md:w-6"
                                                        />
                                                    }
                                            </button>
                                        }

                                        {
                                            isWorthinessSaved &&
                                            <button className='bg-amber-600 rounded text-[#000] px-4 py-1 font-semibold text-sm' disabled={true}>Saved</button>
                                        }

                                        <button className='bg-red-600 rounded text-white px-4 py-1 font-semibold text-sm' disabled={true}>Rejected</button>
                                    </div> :
                                    <>
                                        {
                                            docItem.status === "Pending" &&
                                            <button className='bg-blue-900 rounded text-white px-4 py-1 font-semibold text-sm' disabled={true}>Pending</button>
                                        }

                                        {
                                            docItem.status === "Accept" &&
                                            <div className="flex justify-end mt-2 gap-3">
                                                {
                                                    (!isWorthinessSaved && (moment(getToday).isAfter(moment(docItem.edit_date).format("YYYY-MM-DD")) === true)) &&
                                                    <button className='flex justify-center items-center bg-white rounded text-[#000] px-4 py-1 font-semibold text-sm' onClick={handleWorthinessUpdate}>Resend
                                                        {
                                                            worthyLoad && 
                                                            <input
                                                                type="image"
                                                                src={Spin}
                                                                value="Loading"
                                                                className="w-4 md:w-6"
                                                            />
                                                        }
                                                    </button>
                                                }

                                                {
                                                    isWorthinessSaved &&
                                                    <button className='bg-amber-600 rounded text-[#000] px-4 py-1 font-semibold text-sm' disabled={true}>Saved</button>
                                                }

                                                <button className='bg-amber-500 rounded text-white px-4 py-1 font-semibold text-sm' disabled={true}>Accepted</button>
                                            </div>
                                        }
                                    </>
                                }
                            </div>
                        </div>
                    )
                }))
            }






            {/* HACKNEY PERMIT */}
            {
                (vehicleDoc?.filter(obj => obj.doc_type.includes("hackney permit")).map((docItem) => {
                    return (
                        <div className="my-8">

                            {
                                (docItem.status === "Pending") &&
                                <div className="flex justify-between border border-white p-2 md:p-3 items-center">
                                <p className='text-white md:text-sm text-xs font-normal'>Hackney permit  </p>
                                    <a href={`https://api.roadlers.com/storage/${docItem.document}`} target="_blank" className='border-theme-color px-3 py-1 md:text-sm md:px-2 md:py-0 text-xs text-white'>View</a>
                                </div>
                            }

                            {
                                (docItem.status === "Accept") &&
                                (
                                (moment(getToday).isAfter(moment(docItem.edit_date).format("YYYY-MM-DD")) === false) ?
                                <div className="flex justify-between border border-white p-2 md:p-3 items-center">
                                <p className='text-white md:text-sm text-xs font-normal'>Hackney permit  </p>
                                    <a href={`https://api.roadlers.com/storage/${docItem.document}`} target="_blank" className='border-theme-color px-3 py-1 md:text-sm md:px-2 md:py-0 text-xs text-white'>View</a>
                                </div> :
                                <div className="">
                                        <div className="flex justify-between border bg-[#1D1C1C] border-white p-2 md:p-3 items-center">
                                        <input
                                        type="file"
                                        style={{ display: 'none' }}
                                        ref={hackneyInputRef}
                
                                        accept="image/jpeg,image/jpg,application/pdf"
                
                                        onChange={(e) => handleHackneyFileChange(e)} />
        
                                    
                                            {
                                                hackneyName?.length ?
                                                <p className='text-white md:text-base text-xs font-normal'>{hackneyName} </p> :
                                                <p className='text-white md:text-base text-xs font-normal'>Hackney permit <span  className='text-[#EBAF4A]'> {'(optional)'}</span> </p>
                
                                            }
                                        
                                        
                                            {   (hackneyUpload && hackney ) ? (
                                                <button
                                                onClick={(event) => {
                                                    event.preventDefault()
                                                    hackneyInputRef.current.click()
                                                }}
                                                className='border-theme-color px-3 py-1 md:text-base md:px-2 md:py-0 text-xs text-white'>Change</button>
                                        
                                            ):(
                                                <button
                                                onClick={(event) => {
                                                    event.preventDefault()
                                                    hackneyInputRef.current.click()
                                                }}
                                                className='border-theme-color px-3 py-1 md:text-base md:px-2 md:py-0 text-xs text-white'>Upload</button>
                                        
                                    
                                            )}
                
                                            
                                        </div>
                
                
                                </div>   
                                )
                            }

                            {
                                (docItem.status === "Rejected") &&        
                            <div className="">
                                <div className="flex justify-between border bg-[#1D1C1C] border-white p-2 md:p-3 items-center">
                                <input
                                type="file"
                                style={{ display: 'none' }}
                                ref={hackneyInputRef}
        
                                accept="image/jpeg,image/jpg,application/pdf"
        
                                onChange={(e) => handleHackneyFileChange(e)} />

                            
                                    {
                                        hackneyName?.length ?
                                        <p className='text-white md:text-base text-xs font-normal'>{hackneyName} </p> :
                                        <p className='text-white md:text-base text-xs font-normal'>Hackney permit <span  className='text-[#EBAF4A]'> {'(optional)'}</span> </p>
                                    }
                                   
                                
                                    {   (hackneyUpload && hackney  ) ? (
                                        <button
                                        onClick={(event) => {
                                            event.preventDefault()
                                            hackneyInputRef.current.click()
                                        }}
                                        className='border-theme-color px-3 py-1 md:text-base md:px-2 md:py-0 text-xs text-white'>Change</button>
                                
                            
                                    ):(
                                        <button
                                        onClick={(event) => {
                                            event.preventDefault()
                                            hackneyInputRef.current.click()
                                        }}
                                        className='border-theme-color px-3 py-1 md:text-base md:px-2 md:py-0 text-xs text-white'>Upload</button>
                                
                            
                                    )}
        
                                    
                                </div>
        
        
                            </div>
                            }
            
                            <div className="flex justify-end mt-4">
                                {
                                    docItem.status === "Rejected" ?
                                    
                                    <div className="flex justify-end mt-2 gap-3">
                                        {
                                            !isHackneySaved &&
                                            <button className='flex justify-center items-center bg-white rounded text-[#000] px-4 py-1 font-semibold text-sm' onClick={handleHackneyUpdate}>Resend
                                                    {
                                                        hackneyLoad && 
                                                        <input
                                                            type="image"
                                                            src={Spin}
                                                            value="Loading"
                                                            className="w-4 md:w-6"
                                                        />
                                                    }
                                            </button>
                                        }

                                        {
                                            isHackneySaved &&
                                            <button className='bg-amber-600 rounded text-[#000] px-4 py-1 font-semibold text-sm' disabled={true}>Saved</button>
                                        }

                                        <button className='bg-red-600 rounded text-white px-4 py-1 font-semibold text-sm' disabled={true}>Rejected</button>
                                    </div> :
                                    <>
                                        {
                                            docItem.status === "Pending" &&
                                            <button className='bg-blue-900 rounded text-white px-4 py-1 font-semibold text-sm' disabled={true}>Pending</button>
                                        }

                                        {
                                            docItem.status === "Accept" &&
                                            <div className="flex justify-end mt-2 gap-3">
                                                    {
                                                        (!isHackneySaved && (moment(getToday).isAfter(moment(docItem.edit_date).format("YYYY-MM-DD")) === true)) &&
                                                        <button className='flex justify-center items-center bg-white rounded text-[#000] px-4 py-1 font-semibold text-sm' onClick={handleHackneyUpdate}>Resend
                                                            {
                                                                hackneyLoad && 
                                                                <input
                                                                    type="image"
                                                                    src={Spin}
                                                                    value="Loading"
                                                                    className="w-4 md:w-6"
                                                                />
                                                            }
                                                        </button>
                                                    }

                                                    {
                                                        isHackneySaved &&
                                                        <button className='bg-amber-600 rounded text-[#000] px-4 py-1 font-semibold text-sm' disabled={true}>Saved</button>
                                                    }

                                                    <button className='bg-amber-500 rounded text-white px-4 py-1 font-semibold text-sm' disabled={true}>Accepted</button>
                                            </div>

                                        }
                                    </>
                                }
                            </div>
                        </div>
                    )
                }))
            }


            {/* SET CONDITIONS IF THE DOCUMENT NEVER EXISTED */}
            {/* PROOF OF OWNERSHIP FIRST SAVE */}
            {
                vehicleDoc?.filter(obj => obj.doc_type.includes("proof of ownership")).length === 0 &&
                <div className="mt-4">
                    <div className="flex justify-between border bg-[#1D1C1C] border-white p-2 md:p-3 items-center">
                        {
                            proofName?.length ? (
                                <p className='text-white md:text-base text-xs font-normal'>{proofName}</p>
                            ) : (
                                <p className='text-white md:text-base text-xs font-normal'>Proof of ownership <span className="text-red-600">*</span> </p>
                            )
                        }


                        {/* use useRef to trigger this input field for proof of ownership upload */}
                        <input
                            type="file"
                            style={{ display: 'none' }}
                            ref={fileInputRef}
                            accept="image/jpeg,image/jpg,application/pdf"

                            onChange={(e) => handleProofFileChange(e)} 
                        />


                        {
                            ( upload && proof) ? (
                                <button
                                    onClick={(event) => {
                                        event.preventDefault()
                                        fileInputRef.current.click()
                                    }}
                                    className='border-theme-color px-3 py-1 md:text-base md:px-2 md:py-0 text-xs text-white'>
                                        Change
                                </button>

                            ) : (
                                <button
                                    onClick={(event) => {
                                        event.preventDefault()
                                        fileInputRef.current.click()
                                    }}
                                    className='border-theme-color px-3 py-1 md:text-base md:px-2 md:py-0 text-xs text-white'>
                                        Upload
                                </button>
                            )
                        }

                    </div>
                    <div className="flex justify-end mt-2 gap-3">
                        {
                            !isProofSaved &&
                            <button className='flex justify-center items-center bg-white rounded text-[#000] px-4 py-1 font-semibold text-sm' onClick={handleProofSend}>Send
                                    {
                                        proofLoad && 
                                        <input
                                            type="image"
                                            src={Spin}
                                            value="Loading"
                                            className="w-4 md:w-6"
                                        />
                                    }
                            </button>
                        }

                        {
                            isProofSaved &&
                            <button className='bg-amber-600 rounded text-[#000] px-4 py-1 font-semibold text-sm' disabled={true}>Saved</button>
                        }
                    </div>
                </div>

            }


                        {/* LICENSE FIRST SAVE */}
                        {
                vehicleDoc?.filter(obj => obj.doc_type.includes("vehicle license")).length === 0 &&
                <div className="mt-8">
                    <div className="flex justify-between border bg-[#1D1C1C] border-white p-2 md:p-3 items-center">
                        {licenseName?.length ?
                            <p className='text-white md:text-base text-xs font-normal'>{licenseName}</p>:
                            <p className='text-white md:text-base text-xs font-normal'>Vehicle license / registration  <span className="text-red-600">*</span></p>

                        }
                        


                        <input
                            type="file"
                            style={{ display: 'none' }}
                            ref={licenceInputRef}
                            accept="image/jpeg,image/jpg,application/pdf"


                            onChange={(e) => handleLicenseFileChange(e)} />


                        {
                            (licence && licenceUpload ) ? (
                                <button
                                    onClick={(event) => {
                                        event.preventDefault()
                                        licenceInputRef.current.click()
                                    }}
                                    className='border-theme-color px-3 py-1 md:text-base md:px-2 md:py-0 text-xs text-white'>
                                        Change
                                </button>
                            ) : (
                                <button
                                    onClick={(event) => {
                                        event.preventDefault()
                                        licenceInputRef.current.click()
                                    }}
                                    className='border-theme-color px-3 py-1 md:text-base md:px-2 md:py-0 text-xs text-white'>
                                        Upload
                                </button>
                            )
                        }
                    </div>

                    <div className="flex justify-end mt-2 gap-3">
                        {
                            !isLicenseSaved &&
                            <button className='flex justify-center items-center bg-white rounded text-[#000] px-4 py-1 font-semibold text-sm' onClick={handleLicenseSend}>Send
                                        {
                                            licenseLoad && 
                                            <input
                                                type="image"
                                                src={Spin}
                                                value="Loading"
                                                className="w-4 md:w-6"
                                            />
                                        }
                            </button>
                        }

                        {
                            isLicenseSaved &&
                            <button className='bg-amber-600 rounded text-[#000] px-4 py-1 font-semibold text-sm' disabled={true}>Saved</button>
                        }


                    </div>
                </div>
            }

            {/* CAR INSURANCE FIRST SAVE */}
            {
                vehicleDoc?.filter(obj => obj.doc_type.includes("car insurance")).length === 0 &&
                <div className="mt-8">
                    <div className="flex justify-between border bg-[#1D1C1C] border-white p-2 md:p-3 items-center">
                    <input
                    type="file"
                    style={{ display: 'none' }}
                    ref={insuranceInputRef}

                    accept="image/jpeg,image/jpg,application/pdf"

                    onChange={(e) => handleInsuranceFileChange(e)} />

                        {
                            insuranceName?.length ?
                            <p className='text-white md:text-base text-xs font-normal'>{insuranceName} </p> :
                            <p className='text-white md:text-base text-xs font-normal'>Certificate of car insurance <span  className='text-[#EBAF4A]'> {'(optional)'}</span> </p>
                        }
                    
                    {   (insurance && insuranceUpload ) ? ( 
                        <button
                        onClick={(event) => {
                            event.preventDefault()
                            insuranceInputRef.current.click()
                        }}
                        className='border-theme-color px-3 py-1 md:text-base md:px-2 md:py-0 text-xs text-white'>Change</button>
                    
                    ):(
                        <button
                            onClick={(event) => {
                                event.preventDefault()
                                insuranceInputRef.current.click()
                            }}
                            className='border-theme-color px-3 py-1 md:text-base md:px-2 md:py-0 text-xs text-white'>Upload</button>
                    )    
                    }
                    </div>

                    <div className="flex justify-end mt-2 gap-3">
                        { !isInsuranceSaved && 

                        <button className='flex justify-center items-center bg-white rounded text-[#000] px-4 py-1 font-semibold text-sm' onClick={handleInsuranceSend}>Send
                                    {
                                        insuranceLoad && 
                                        <input
                                            type="image"
                                            src={Spin}
                                            value="Loading"
                                            className="w-4 md:w-6"
                                        />
                                    }
                        </button>
                        }

                        { isInsuranceSaved && 

                        <button className='bg-amber-600 rounded text-[#000] px-4 py-1 font-semibold text-sm'>Saved</button>
                        }
                    </div>
                </div>   

            }





            {/* WORTHINESS UPLOAD */}
            {
                vehicleDoc?.filter(obj => obj.doc_type.includes("road worthiness")).length === 0 &&
                <div className="mt-8">
                    <div className="flex justify-between border bg-[#1D1C1C] border-white p-2 md:p-3 items-center">
                    <input
                    type="file"
                    style={{ display: 'none' }}
                    ref={worthinessInputRef}
                    accept="image/jpeg,image/jpg,application/pdf"
                    onChange={(e)=> handleWorthinessFileChange(e)} />

                        {
                            worthinessName?.length ?
                            <p className='text-white md:text-base text-xs font-normal'>{worthinessName} </p> :
                            <p className='text-white md:text-base text-xs font-normal'>Certificate of road worthiness <span  className='text-[#EBAF4A]'> {'(optional)'}</span> </p>

                        }
                        {   (worthiness && worthinessUpload) ? (
                            <button
                            onClick={(event) => {
                                event.preventDefault()
                                worthinessInputRef.current.click()
                            }}
                            className='border-theme-color px-3 py-1 md:text-base md:px-2 md:py-0 text-xs text-white'>Change</button>
                    
                
                        ):(
                            <button
                            onClick={(event) => {
                                event.preventDefault()
                                worthinessInputRef.current.click()
                            }}
                            className='border-theme-color px-3 py-1 md:text-base md:px-2 md:py-0 text-xs text-white'>Upload</button>
                    
                
                        )}
                    
                
                    </div>
                    <div className="flex justify-end mt-2 gap-3">
                        {
                            !isWorthinessSaved &&
                            <button className='flex justify-center items-center bg-white rounded text-[#000] px-4 py-1 font-semibold text-sm' onClick={handleWorthinessSend}>Send
                                    {
                                        worthyLoad && 
                                        <input
                                            type="image"
                                            src={Spin}
                                            value="Loading"
                                            className="w-4 md:w-6"
                                        />
                                    }
                            </button>
                        }

                        {
                            isWorthinessSaved &&
                            <button className='bg-amber-600 rounded text-[#000] px-4 py-1 font-semibold text-sm' disabled={true}>Saved</button>
                        }

                    </div>
                </div>
            }


            {/* HACKNEY PERMIT FIRST SAVE */}
            {
                vehicleDoc?.filter(obj => obj.doc_type.includes("hackney permit")).length === 0 && 
                <div className="mt-8">
                    <div className="flex justify-between border bg-[#1D1C1C] border-white p-2 md:p-3 items-center">
                        <input
                        type="file"
                        style={{ display: 'none' }}
                        ref={hackneyInputRef}

                        accept="image/jpeg,image/jpg,application/pdf"

                        onChange={(e) => handleHackneyFileChange(e)} />
                
                        {
                            hackneyName?.length ?
                            <p className='text-white md:text-base text-xs font-normal'>{hackneyName} </p> :
                            <p className='text-white md:text-base text-xs font-normal'>Hackney permit <span  className='text-[#EBAF4A]'> {'(optional)'}</span> </p>

                        }
                    
                    
                        {   (hackneyUpload && hackney  ) ? (
                            <button
                            onClick={(event) => {
                                event.preventDefault()
                                hackneyInputRef.current.click()
                            }}
                            className='border-theme-color px-3 py-1 md:text-base md:px-2 md:py-0 text-xs text-white'>Change</button>
                    
                
                        ):(
                            <button
                            onClick={(event) => {
                                event.preventDefault()
                                hackneyInputRef.current.click()
                            }}
                            className='border-theme-color px-3 py-1 md:text-base md:px-2 md:py-0 text-xs text-white'>Upload</button>
                    
                
                        )}

                        
                    </div>

                    <div className="flex justify-end mt-2 gap-3">
                            {
                                !isHackneySaved &&
                                <button className='flex justify-center items-center bg-white rounded text-[#000] px-4 py-1 font-semibold text-sm' onClick={handleHackneySend}>Send
                                    {
                                        hackneyLoad && 
                                        <input
                                            type="image"
                                            src={Spin}
                                            value="Loading"
                                            className="w-4 md:w-6"
                                        />
                                    }
                                </button>
                            }

                            {
                                isHackneySaved &&
                                <button className='bg-amber-600 rounded text-[#000] px-4 py-1 font-semibold text-sm' disabled={true}>Saved</button>
                            }
                    </div>


                </div>
            }



        </div>
    </>
  )
}

export default VehicleApprovalEditComponent