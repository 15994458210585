import React, { Fragment, useState, useEffect, useContext, useRef, useCallback } from 'react'
import CustomerContext from '../../Context/CustomerContext'
import { BASE_URL } from "../utils/constant"
import { Dialog, Transition } from '@headlessui/react'
import getCookie from '../utils/SecuredTokens'
import Header from '../Header'
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import bitcoin from '../../Images/btc.png';
import ethereum from '../../Images/ethereum.png';
import usdt from '../../Images/usdt.png';
import binance from '../../Images/binance.png';

import dashboard from '../../Images/dashboard.png'
import payment from '../../Images/payment.png'
import service_provider from '../../Images/service_provider.png'
import price_setting from '../../Images/price_setting.png'
import customer from '../../Images/customers.png'
import blackeye from '../../Images/blackeye.png'

import notificationIcon from '../../Images/notifications.png'
import eyes from '../../Images/eyes.svg'
import btc from '../../Images/btc.svg'
import pie from '../../Images/pie.svg'
import Subheader from '../Subheader';
import Bulk from './Bulk';
import driverOne from '../../Images/driver1.png'
import driverTwo from '../../Images/driver2.png'
import driverThree from '../../Images/driver3.png'
import Payment from './Payment';
import one from '../../Images/one.png'
import Provider from './Provider';
import Actions from './Actions';
import Notes from './Notes';
import providerOne from '../../Images/provider1.png'
import providerTwo from '../../Images/provider2.png'
import providerThree from '../../Images/provider3.png'
import providerFour from '../../Images/provider4.png'
import gift from '../../Images/gift.png'
import gift_faint from '../../Images/gift_faint.png'
import pen from '../../Images/pen.svg'
import del from '../../Images/del.svg'
import user from '../../Images/user1.svg'
import users from '../../Images/userg.png'

import icon from '../../Images/icon.svg'
import naira from '../../Images/naira.png'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import DatePicker from "react-datepicker";
import moment from 'moment';

import CurrencyInput from 'react-currency-input-field';

import { ExportToExcel } from './ExportToExcel';

import message from '../../Images/message.svg'
import google from '../../Images/google.svg'
import Spin from '../../Images/spinner.gif';

import fbb from '../../Images/fbb.svg'
import calls from '../../Images/calls.svg'
import email from '../../Images/email.svg'

import open from '../../Images/blackeye.png'
import closed from '../../Images/closed.svg'

import Charts from "./Charts";
import NoResult from "../Admin/NoResult";

import DailyDriverPrice from "./DailyDriverPrice";
import FullTimePrice from "./FullTimePrice";
import InsuranceRate from "./InsuranceRate";
import VehicleMargin from "./VehicleMargin";
import GiftCards from "./GiftCards";
import cancelImg from '../../Images/cancel.svg'
import roadlersRR from '../../Images/RoadlersRR.svg'
import { useMemo } from 'react'


function SuperAdmin() {

    function ScrollToTopOnMount() {
        useEffect(() => {
            window.scrollTo(0, 0);
        }, []);

        return null;
    }

    // SUPERADMIN LOADER
    const [dashboardLoad, setDashboardLoad] = useState(true)

    let navigate = useNavigate();

    const [fiatWallet, setFiatWalletAddress] = useState('')

    const fileInputRef = useRef()

    const [coverImage, setCoverImage] = useState();
    const [coverImagePreview, setCoverImagePreview] = useState()

    const [transactionHistModal, setTransactionHistModal] = useState(false)
    const [notificationModal, setNotificationModal] = useState(false)







    useEffect(() => {

        if (coverImage) {
            const reader = new FileReader()
            reader.onloadend = () => {
                setCoverImagePreview(reader.result)
            }
            reader.readAsDataURL(coverImage)




        } else {
            setCoverImagePreview(null)
        }

        console.log('imgddddd', coverImage)



    }, [coverImage]);







    let userr = getCookie("user");
    let userObject = JSON.parse(userr)
    let token = userObject?.token
    let userId = userObject?.id


    let { handleGotoCart, handleGotoRequest } = useContext(CustomerContext)



    const [visibleAsset, setVisibleAsset] = useState(8);
    const [visiblePayment, setVisiblePayment] = useState(4);
    function showMore() {
        setVisibleAsset((prevValue) => prevValue + 8)
    }

    function showMorePayment() {
        setVisiblePayment((prevValue) => prevValue + 4)
    }

    const [revenue, setRevenue] = useState('');
    const [cost, setCost] = useState('');



    // provider date filter
    const [providerFilterOpen, setProviderFilterOpen] = useState(false);
    const [providerFilterTwo, setProviderFilterOpenTwo] = useState(false);
    const [providerStartDate, setProviderStartDate] = useState('');
    const [providerEndDate, setProviderEndDate] = useState('');



    const [notes, setNotes] = useState([]);
    const [paymentRequests, setPaymentRequests] = useState([]);
    const [blockUsers, setBlockUsers] = useState([]);
    const [providerValue, setProviderValue] = useState("");
    const [providers, setProviders] = useState([]);
    const [providerResult, setProviderResults] = useState(providers);
    const [open, setOpen] = useState(false);
    const [firstOpen, setFirstOpen] = useState(false);
    const [transactionStartDate, setTransactionStartDate] = useState('');
    const [transactionEndDate, setTransactionEndDate] = useState('');
    let setSelectedStartDate = moment(transactionStartDate).format("DD/MM/YYYY")
    let setSelectedEndDate = moment(transactionEndDate).format("DD/MM/YYYY")


    const handleProviderSearch = (e) => {
        e.preventDefault();

        let search = e.target.value;

        setProviderValue(search)

        setProviderResults(
            providers.filter((data) => {
                let dataString = data?.user_id.toString()
                if (dataString === null) {
                    return false
                } else {
                    return dataString.includes(search)
                }
            })
        )
    }

    const handleProviderActionSearch = (e) => {
        e.preventDefault();

        let search = e.target.value;


        // setProviderValue(search)

        // setProviderResults(
        //     providers.filter((data) => {
        //         let dataString = data?.user_id.toString()
        //         if (dataString === null) {
        //             return false
        //         } else {
        //             return dataString.includes(search)
        //         }
        //     })
        // )
    }



    const handleProviderBusiness = (e) => {
        e.preventDefault()
        setProviderResults(
            providers.filter((data) => {
                return data?.category === 'business'
            })
        )
    }

    const handleCarOwner = (e) => {
        e.preventDefault()
        setProviderResults(
            providers.filter((data) => {
                return (data?.service === 'vehicle_driver' || data?.service === 'vehicle')
            })
        )
    }

    const handleDriver = (e) => {
        e.preventDefault()
        setProviderResults(
            providers.filter((data) => {
                return (data?.service === 'vehicle_driver' || data?.service === 'driver')
            })
        )
    }

    const getAdminProviders = useCallback(async () => {
        await axios({
            method: "get",
            url: `${BASE_URL}/v1/admin-list-provider`,

            headers: {
                "Content-Type": "application/json",

                Authorization: `Bearer ${token}`,

                "Access-Control-Allow-Origin": "*",
            },
        }).then(function (response) {
            // localStorage.removeItem("cart")
            const allProviders = response.data.data;



            setDashboardLoad(false)

            setProviders(allProviders);
            setProviderResults(allProviders)


            // setResult(allRequests)
        });
    }, []);



    const getNotes = async () => {
        await axios({
            method: "get",
            url: `${BASE_URL}/v1/admin-notes`,

            headers: {
                "Content-Type": "application/json",

                Authorization: `Bearer ${token}`,

                "Access-Control-Allow-Origin": "*",
            },
        }).then(function (response) {
            // localStorage.removeItem("cart")
            const allNotes = response.data.data;

            setNotes(allNotes);



            // setResult(allRequests)
        });
    };

    const getPaymentRequests = useCallback(async () => {
        await axios({
            method: "get",
            url: `${BASE_URL}/v1/payout-requests`,

            headers: {
                "Content-Type": "application/json",

                Authorization: `Bearer ${token}`,

                "Access-Control-Allow-Origin": "*",
            },
        }).then(function (response) {
            // localStorage.removeItem("cart")
            const allRequests = response.data.data;

            setPaymentRequests(allRequests);

            console.log('allRequests', allRequests)

            // setResult(allRequests)
        });
    }, [paymentRequests]);




    const getBlockNotice = async () => {
        await axios({
            method: "get",
            url: `${BASE_URL}/v1/list-block-request`,

            headers: {
                "Content-Type": "application/json",

                Authorization: `Bearer ${token}`,

                "Access-Control-Allow-Origin": "*",
            },
        }).then(function (response) {
            // localStorage.removeItem("cart")
            const blockResp = response.data.data;

            setBlockUsers(blockResp);

            console.log('blockResp', blockResp)


            // setResult(allRequests)
        });
    };

    useEffect(() => {
        getNotes();
    }, []);

    useEffect(() => {
        getPaymentRequests();
    }, []);

    useEffect(() => {
        getBlockNotice();
    }, []);

    useEffect(() => {
        getAdminProviders();
        // return null;
    }, []);

    let [dailyPriceOpen, setDailyPriceOpen] = useState(false)
    let [fullTimePriceOpen, setFullTimePriceOpen] = useState(false)
    let [insuranceOpen, setInsuranceOpen] = useState(false)
    let [marginOpen, setMarginOpen] = useState(false)
    let [giftOpen, setGiftOpen] = useState(false)
    let [registerAdminOpen, setRegisterAdminOpen] = useState(false)
    let [registerSuperadminOpen, setRegisterSuperadminOpen] = useState(false)


    let [bg, setBg] = useState(false)


    function registerAdminModal() {
        // setRegisterAdminOpen(true)
        setRegisterAdminOpen((prev) => !prev)

        setBg(true)

    }
    function registerSuperadminModal() {
        setRegisterSuperadminOpen(true)
        setBg(true)

    }
    function closeModal() {
        setDailyPriceOpen(false)
        setFullTimePriceOpen(false)
        setInsuranceOpen(false)
        setMarginOpen(false)
        setGiftOpen(false)
        setRegisterAdminOpen(false)
        setRegisterSuperadminOpen(false)
        setTransactionHistModal(false)
        setNotificationModal(false)
        setBg(false)
    }



    function notifyModal() {
        setNotificationModal(true)

        setBg(true)

    }
    function transModal() {
        setTransactionHistModal(true)

        setBg(true)

    }

    function marginModal() {
        setMarginOpen(true)
        setBg(true)

    }

    function giftModal() {
        setGiftOpen(true)
        setBg(true)

    }

    function insuranceModal() {
        setInsuranceOpen(true)
        setBg(true)

    }

    function dailyDriverModal() {
        setDailyPriceOpen(true)
        setBg(true)

    }

    function fullTimeModal() {
        setFullTimePriceOpen(true)
        setBg(true)

    }

    const [visible, setVisible] = useState(2);


    function showMoreRequest() {
        setVisible((prevValue) => prevValue + 2);
    }

    const [adminBalance, setAdminBalance] = useState('')

    const [customerId, setCustomerId] = useState("");

    const [balance, setBalance] = useState("");
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [dob, setDob] = useState("");
    let [username, setUsername] = useState("");
    let [phoneNumber, setPhone] = useState("");
    let [address, setAddress] = useState("");
    let [state, setState] = useState("");
    let [country, setCountry] = useState("");

    const [initial, setInitials] = useState("");
    const [transactions, setTransactions] = useState([]);
    const [notifications, setNotifications] = useState([]);

    const [transUser, setTransUser] = useState("");

    const [customers, setCustomers] = useState([]);
    const [drivers, setDrivers] = useState([]);
    const [assets, setAssets] = useState([]);
    const [providersData, setprovidersData] = useState([]);

    const [dailyDriverPrice, setDailyDriverPrice] = useState([]);
    const [fullTimePrice, setFullTimePrice] = useState([]);
    const [insuranceRate, setInsuranceRate] = useState([]);
    const [vehicleMargin, setVehicleMargin] = useState([]);
    const [giftCards, setGiftCards] = useState([]);


    const fileName = "myfile";



    // daily driver state
    const [period, setPeriod] = useState("");
    const [amount, setAmount] = useState("");

    // full time driver price state
    const [fulltimeDescription, setFulltimeDescription] = useState("");
    const [fulltimePriceInput, setFulltimePriceInput] = useState("");


    // insurance price state

    const [insuranceDescription, setInsuranceDescription] = useState("");
    const [insuranceCommission, setInsuranceCommission] = useState("");



    // vehicle Margin state

    const [minimum, setMinimum] = useState("");
    const [maximum, setMaximum] = useState("");
    const [pricingType, setPricingType] = useState("");
    const [marginPrice, setMarginPrice] = useState("");



    // gift card state

    const [giftName, setGiftName] = useState("");
    const [giftDesc, setGiftDesc] = useState("");
    const [giftPrice, setGiftPrice] = useState("");




    const [dailyId, setDailyId] = useState("");
    const [priceData, setPriceData] = useState("");

    const handleAddDailyPrice = (e) => {
        let formData = new FormData();
        formData.append("title", 'driver_pricing margin');
        formData.append("heading", 'Daily Driver Price');
        formData.append("price_category", 'driver_price');
        formData.append("commission", amount);
        // formData.append("description", price);
        // formData.append("margin", price);
        // formData.append("minimum", price);
        // formData.append("maximum", price);
        formData.append("period", period);
        axios({
            method: 'post',
            url: `${BASE_URL}/v1/new-pricing`,
            data: formData,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            }
        })
            .then(function (response) {
                //handle success
                const respData = response.data.message;
                const errors = response.data.errors;
                if (respData) {
                    console.log("respData response ", respData)

                }
                window.location.reload(true)

            })
            .catch(function (error) {
                //handle error
                console.log("error ", error.response.data)

                const notify = () => { toast(error.response.data.message) };
                notify()
            });
    };

    const handleAddFullTimePrice = (e) => {
        let formData = new FormData();
        formData.append("title", 'Full-time Driving Price');
        formData.append("heading", 'Full-time Driving Price');
        formData.append("price_category", 'fulltime_driver');
        formData.append("commission", fulltimePriceInput);
        formData.append("description", fulltimeDescription);
        // formData.append("margin", price);
        // formData.append("minimum", price);
        // formData.append("maximum", price);
        // formData.append("period", period);
        axios({
            method: 'post',
            url: `${BASE_URL}/v1/new-pricing`,
            data: formData,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            }
        })
            .then(function (response) {
                //handle success
                const respData = response.data.message;
                const errors = response.data.errors;
                if (respData) {
                    console.log("respData response ", respData)

                }
                window.location.reload(true)

            })
            .catch(function (error) {
                //handle error
                console.log("error ", error.response.data)

                const notify = () => { toast(error.response.data.message) };
                notify()
            });
    };



    const handleAddInsurance = (e) => {
        let formData = new FormData();
        formData.append("title", 'Insurance Rate');
        formData.append("heading", 'Insurance Rate');
        formData.append("price_category", 'insurance');
        formData.append("commission", insuranceCommission);
        // formData.append("description", );
        // formData.append("margin", price);
        // formData.append("minimum", price);
        // formData.append("maximum", price);
        formData.append("description", insuranceDescription);
        axios({
            method: 'post',
            url: `${BASE_URL}/v1/new-pricing`,
            data: formData,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            }
        })
            .then(function (response) {
                //handle success
                const respData = response.data.message;
                const errors = response.data.errors;
                if (respData) {
                    console.log("respData response ", respData)

                }
                window.location.reload(true)

            })
            .catch(function (error) {
                //handle error
                console.log("error ", error.response.data)

                const notify = () => { toast(error.response.data.message) };
                notify()
            });
    };

    const handleAddMargin = (e) => {
        let formData = new FormData();


        formData.append("title", 'Vehicle Price Margin');


        formData.append("heading", 'Vehicle Price Margin');
        formData.append("price_category", 'vehicle_price');
        formData.append("pricing_type", pricingType);

        // formData.append("description", );
        formData.append("commission", marginPrice);
        formData.append("minimum", minimum);
        formData.append("maximum", maximum);
        axios({
            method: 'post',
            url: `${BASE_URL}/v1/new-pricing`,
            data: formData,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            }
        })
            .then(function (response) {
                //handle success
                const respData = response.data.message;
                const errors = response.data.errors;
                if (respData) {
                    console.log("respData response ", respData)

                }
                window.location.reload(true)

            })
            .catch(function (error) {
                //handle error
                console.log("error ", error.response.data)

                const notify = () => { toast(error.response.data.message) };
                notify()
            });
    };

    const handleAddGift = (e) => {
        let formData = new FormData();
        formData.append("title", giftName);
        formData.append("heading", 'Gift Cards');
        formData.append("price_category", 'gift_price');

        formData.append("commission", giftPrice);
        formData.append("description", giftDesc);
        formData.append("file", coverImage);
        // formData.append("minimum", minimum);
        // formData.append("maximum", maximum);
        // formData.append("period", insurancePeriod);
        axios({
            method: 'post',
            url: `${BASE_URL}/v1/new-pricing`,
            data: formData,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            }
        })
            .then(function (response) {
                //handle success
                const respData = response.data.message;
                const errors = response.data.errors;
                if (respData) {
                    console.log("respData response ", respData)

                }
                window.location.reload(true)

            })
            .catch(function (error) {
                //handle error
                console.log("error ", error.response.data)

                const notify = () => { toast(error.response.data.message) };
                notify()
            });
    };


    const handleSubmit = (e) => {
        localStorage.setItem("cartUserId", JSON.stringify(customerId))

        e.preventDefault();
        let formData = new FormData();
        formData.append("customer_id", customerId);

        axios({
            method: "post",
            url: `${BASE_URL}/v1/search-customer`,

            data: formData,
            headers: {
                "Content-Type": "application/json",

                Authorization: `Bearer ${token}`,

                "Access-Control-Allow-Origin": "*",
            },
        })
            .then(function (response) {
                const alluserName = response.data.data;
                console.log("customer alluserName ", alluserName);

                const fullname = alluserName.name;
                const dob = alluserName.dob;
                const userAddress = alluserName.address;
                const userState = alluserName.state;
                const userCountry = alluserName.country;
                const phone = alluserName.phone_number;
                const user_name = alluserName.username;
                const nameSplit = fullname.split(/(\s+)/);
                const firstName = nameSplit[0];

                setFirstname(firstName);

                const lastName = nameSplit[2];
                setLastname(lastName);

                setDob(dob);
                setAddress(userAddress);
                setUsername(user_name);
                setPhone(phone);
                setState(userState);
                setCountry(userCountry);

                const initials = [];
                for (let i = 0; i < lastName.length; i++) {
                    const lastname_split = lastName[i].split(" ");
                    initials.push(lastname_split[0]);
                    setInitials(initials[0]);
                }



                localStorage.setItem("cartUserId", JSON.stringify(alluserName.id))


                axios({
                    method: "get",
                    url: `${BASE_URL}/v1/wallet/get-fiat-wallet/${alluserName.id}`,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                        "Access-Control-Allow-Origin": "*",
                    },
                })
                    .then(function (response) {
                        const customer = response.data.data;

                        setBalance(customer.balance);

                    })
            })
            .catch(function (error) {
                //handle error
                console.log("error ", error.response.data);
            });
    };


    const fetchTransactions = (e) => {
        axios
            .get(`${BASE_URL}/v1/list-transactions`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((response) => {
                const transData = response.data.data;
                setTransactions(transData)



            });
    }

    const fetchNotifications = (e) => {
        axios
            .get(`${BASE_URL}/v1/fetch-admin-notifications`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((response) => {
                const notificationData = response.data.data;
                setNotifications(notificationData)


            });
    }
    const fetchRevenue = (e) => {
        axios
            .get(`${BASE_URL}/v1/fetch-total-revenue`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((response) => {
                const transData = response.data.data;
                //  setTransactions(transData.pieChartData)
                console.log(transData, 'trannnan')
                setRevenue(transData.totalRevenue)
                setCost(0)


            });
    }
    const fetchCustomers = (e) => {
        axios
            .get(`${BASE_URL}/v1/registered-customers`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((response) => {
                const cusData = response.data.data;
                setCustomers(cusData)


            });
    }

    const fetchDrivers = (e) => {
        axios
            .get(`${BASE_URL}/v1/list-drivers`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((response) => {
                const driverData = response.data.data;
                setDrivers(driverData)


            });
    }

    const getAssets = async () => {
        await axios({
            method: "get",
            url: `${BASE_URL}/v1/vehicle-status`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            },
        }).then(function (response) {
            // localStorage.removeItem("cart")
            const allVehicle = response.data.data;

            setAssets(allVehicle);

        });
    };

    const getProvidersData = useCallback(async () => {
        await axios({
            method: "get",
            url: `${BASE_URL}/v1/provider-details`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            },
        }).then(function (response) {
            // localStorage.removeItem("cart")
            const respData = response.data.data;

            setprovidersData(respData);

        });
    }, [])


    const fetchPrices = (e) => {
        axios
            .get(`${BASE_URL}/v1/view-pricing`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((response) => {
                const transData = response.data.data;
                setPriceData(transData)
                let dailyDriver = transData.filter((data) => {
                    return data.price_category === 'driver_price'
                })

                let fullTimeDriver = transData.filter((data) => {
                    return data.price_category === 'fulltime_driver'
                })

                let insurance = transData.filter((data) => {
                    return data.price_category === 'insurance'
                })

                let vehicleMarg = transData.filter((data) => {
                    return data.price_category === 'vehicle_price'
                })

                let gift = transData.filter((data) => {
                    return data.price_category === 'gift_price'
                })

                setDailyDriverPrice(dailyDriver)

                setDailyId(dailyDriver.id)

                setFullTimePrice(fullTimeDriver)

                setInsuranceRate(insurance)

                setVehicleMargin(vehicleMarg)

                setGiftCards(gift)

            });
    }




    useEffect(() => {
        fetchNotifications();
    }, []);

    useEffect(() => {
        fetchPrices();
    }, []);

    useEffect(() => {
        fetchCustomers();
    }, []);
    useEffect(() => {
        fetchDrivers();
    }, []);

    useEffect(() => {
        getAssets();
    }, []);

    useEffect(() => {
        fetchRevenue();
    }, []);

    useEffect(() => {
        getProvidersData();
    }, []);

    useEffect(() => {
        fetchTransactions();
    }, []);






    const currentMonth = new Date().getMonth() + 1;

    const month = new Date().toLocaleString(
        'default', { month: 'long' }
    );

    const [load, setLoad] = useState("")


    const [passwordShown, setPasswordShown] = useState(false);
    const phoneRegExp = /^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/


    const togglePassword = (e) => {
        e.preventDefault()
        // When the handler is invoked
        // inverse the boolean state of passwordShown
        setPasswordShown(!passwordShown);
    };


    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            phone_number: '',
            password: '',
            password_confirmation: '',
            user_role: 'admin'

        },
        validationSchema: Yup.object({
            name: Yup.string().min(3, "Must be 3 Characters or more").required("This is Required"),
            email: Yup.string().email("Invalid Email Address").required("This is Required"),
            phone_number: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required("Phone Number is Required"),
            password: Yup.string()
                .required("This is Required")
                .matches(
                    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/,
                    "Must Contain at least 6 Characters, One Uppercase, One Lowercase and One Number"
                ),
            password_confirmation: Yup.string()
                .oneOf([Yup.ref("password"), null], "Password must Match")
                .required("Confirm Password is Required"),

            acceptTerms: Yup.bool().oneOf([true], 'Accept Terms & Conditions is required')

        }),


        onSubmit: (values) => {
            setLoad(Spin);
            axios({
                method: 'post',
                url: `${BASE_URL}/v1/add-admin`,
                data: values,
                headers: {
                    'Content-Type': 'application/json',

                    Authorization: `Bearer ${token}`,

                    "Access-Control-Allow-Origin": "*",
                }
            })
                .then(function (response) {

                    if (response.data.status === true) {
                        const messsage = response.data.message
                        navigate('/login')
                    }
                    else {
                        const messsage = response.data.message
                        navigate('/login')
                    }
                })
                .catch(function (error) {
                    //handle error
                    console.log("error ", error)
                    setLoad('');
                });

        },
    })






    const formikSuperadmin = useFormik({
        initialValues: {
            name: '',
            email: '',
            phone_number: '',
            password: '',
            password_confirmation: '',

        },
        validationSchema: Yup.object({
            name: Yup.string().min(3, "Must be 3 Characters or more").required("This is Required"),
            email: Yup.string().email("Invalid Email Address").required("This is Required"),
            phone_number: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required("Phone Number is Required"),


            password: Yup.string()
                .required("This is Required")
                .matches(
                    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/,
                    "Must Contain at least 6 Characters, One Uppercase, One Lowercase and One Number"
                ),
            password_confirmation: Yup.string()
                .oneOf([Yup.ref("password"), null], "Password must Match")
                .required("Confirm Password is Required"),

            acceptTerms: Yup.bool().oneOf([true], 'Accept Terms & Conditions is required')

        }),


        onSubmit: (values) => {
            console.log('values', values)
            setLoad(Spin);
            console.log('loadingggg', load)

            axios({
                method: 'post',

                url: `${BASE_URL}/v1/super-admin-signup`,

                data: values,
                config: { headers: { 'Content-Type': 'application/json' } }

            })
                .then(function (response) {
                    //handle success

                    console.log('response.data.data outside', response.data.data.token);
                    console.log('response.data.data second', response.data.data);

                    if (response.data.status === true) {

                        localStorage.setItem("userToken", JSON.stringify(response.data.data.token))



                        const messsage = response.data.message
                        navigate('/login')

                    }
                    else {

                        const messsage = response.data.message
                        navigate('/login')

                    }



                })
                .catch(function (error) {
                    //handle error
                    console.log("error ", error.response.data.email)

                    setLoad('');
                });

        },
    })

    function handleFilterDate(date) {
        let dateTime = moment(date).format("DD/MM/YYYY")
    }

    const createWalletFiat = async () => {

        let formData = new FormData();
        formData.append("currency_code", 'NGN');

        // convert the formdata to JSON data
        let object = {};
        formData.forEach((value, key) => object[key] = value);
        let json = JSON.stringify(object)

        await axios({
            method: "post",
            url: `${BASE_URL}/v1/wallet/create-fiat-wallet`,
            data: json,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            }
        })
            .then((response) => {

                const fiatWalletAddress = response.data.data.fiat_wallet_address
                console.log('fiatWalletAddress super', fiatWalletAddress)
                setFiatWalletAddress(fiatWalletAddress)
            })
            .catch((error) => {
                console.log(error);
            });
    };
    useEffect(() => createWalletFiat(), []);


    const getWalletDetails = async () => {
        await axios.get(`${BASE_URL}/v1/wallet/get-fiat-wallet/${userId}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            }
        }).then((response) => {
            const allwalletdatas = response.data.data;
            console.log('allwalletdatas', allwalletdatas)

            const account_reference = allwalletdatas.account_reference
            const account_balance = allwalletdatas.balance
            const bvnSetup = allwalletdatas.bvn
            const virtualAccount = allwalletdatas.virtual_account_number
            const virtualBank = allwalletdatas.bank_name
            const balance = parseFloat(account_balance).toFixed(2);

            setAdminBalance(balance)



        });
    };
    useEffect(() => {
        getWalletDetails();
    }, []);

    // useEffect(() => {
    //     getWalletDetails({});
    // }, [fiatUpdate]);


    return (
        <>



            <div className={bg === true ? "blur-sm contrast-50 backdrop-brightness-50" : "w-full"}>

                <Header user='super_admin' />
            <ScrollToTopOnMount />



                <Transition appear show={transactionHistModal} as={Fragment}>
                    <Dialog
                        as="div"
                        className="fixed px-6 inset-0 z-10 overflow-y-auto"
                        onClose={closeModal}
                    >
                        <div className="min-h-screen px-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-25 bg-white"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-25"
                                leaveTo="opacity-0"
                            >
                                <Dialog.Overlay className="fixed inset-0" />
                            </Transition.Child>

                            {/* This element is to trick the browser into centering the modal contents. */}
                            <span
                                className="inline-block h-screen align-middle"
                                aria-hidden="true"
                            >
                                &#8203;
                            </span>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <div className="inline-block w-full max-w-md pt-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-transparent shadow-xl rounded-2xl">
                                    <div className="bg-project p-6">
                                        <div className="header flex mb-6 items-center">
                                            <div className='w-4/12'>
                                                <h4 className='text-white text-center font-bold'>Transactions </h4>
                                            </div>


                                            <div className="p-2  border w-7/12  text-white rounded-md border-white flex">


                                                <button
                                                    className="mr-4 text-xx focus:outline-none"
                                                    type='button'
                                                    onClick={() => {
                                                        setFirstOpen((isOpen) => !isOpen)


                                                    }}>

                                                    {transactionStartDate ? setSelectedStartDate : '2 March 2022'}
                                                </button>


                                                <label htmlFor="" className='mr-4 text-xx'> - </label>

                                                <button
                                                    className="mr-4 text-xx focus:outline-none"
                                                    type='button'
                                                    onClick={() => setOpen((isOpen) => !isOpen)}>

                                                    {transactionEndDate ? setSelectedEndDate : '2 April 2022'}


                                                </button>

                                                <img src={one} alt="" className="w-5" />



                                                <div className="dateee">
                                                    <DatePicker
                                                        selected={transactionStartDate}
                                                        onChange={(date) => {
                                                            setTransactionStartDate(date)
                                                            { handleFilterDate(date) }

                                                            setFirstOpen(false)
                                                        }}

                                                        open={firstOpen}

                                                        style={{ marginRight: "50px" }}



                                                    />
                                                </div>

                                                <div className="dateee">
                                                    <DatePicker
                                                        selected={transactionEndDate}
                                                        onChange={(date) => {
                                                            setTransactionEndDate(date)
                                                            console.log('setSelectedEndDate', transactionEndDate)

                                                            setOpen(false)
                                                        }}

                                                        open={open}

                                                        style={{ color: "white" }}



                                                    />
                                                </div>

                                            </div>
                                            <button className='w-1/12'>
                                                <img src={cancelImg} className='w-[30px]' onClick={closeModal} alt="Cancel Modal" />
                                            </button>

                                        </div>

                                        <div className="notification-lists-container overflow-y-scroll h-[450px]">

                                            {transactionStartDate && transactionEndDate ?


                                                (transactions?.filter(obj => (moment(obj.created_at).format("YYYY/MM/DD")) >= (moment(transactionStartDate).format("YYYY/MM/DD")) && (moment(obj.created_at).format("YYYY/MM/DD")) <= (moment(transactionEndDate).format("YYYY/MM/DD"))).map((item, i) => {
                                                    return (

                                                        <div className="notification-box bg-white p-6 rounded-xl mt-4">
                                                            {item.category === "Car Booking" && item.status === 'PAID' ?

                                                                <div className="flex flex-col">
                                                                    <div className="header-wrapper flex w-full justify-between items-center">
                                                                        <h5 className='font-bold text-base text-[#106B08]'>Car Booking</h5>
                                                                        <h5 className='font-bold text-sm text-[#106B08]'>{moment(item.created_at).format("DD/MM/YYYY")}</h5>
                                                                    </div>



                                                                    <div className='mt-2 flex flex-col'>


                                                                        <div className='mt-2 flex text-base justify-between'>
                                                                            <h3 className='mt-2 text-black font-extrabold'>
                                                                                Amount:
                                                                            </h3>

                                                                            <h3 className='mt-2 text-[#106B08]  font-extrabold'>

                                                                                &#8358;{Number(item.amount).toLocaleString('en-US', { maximumFractionDigits: 2 })}

                                                                            </h3>
                                                                        </div>

                                                                        <div className='mt-2 flex  justify-between items-center'>
                                                                            <h3 className='mt-2 text-base text-black font-extrabold'>
                                                                                Reference:
                                                                            </h3>

                                                                            <p className='mt-2 text-[#106B08] text-xs font-extrabold'>
                                                                                {item.billing_reference}
                                                                            </p>
                                                                        </div>



                                                                    </div>

                                                                </div>
                                                                :
                                                                ''
                                                            }


                                                        </div>
                                                    );
                                                })
                                                ) :

                                                (transactions?.map((item, i) => {
                                                    return (

                                                        <div className="notification-box bg-white p-6 rounded-xl mt-4">
                                                            {item.category === "Car Booking" && item.status === 'PAID' ?

                                                                <div className="flex flex-col">
                                                                    <div className="header-wrapper flex w-full justify-between items-center">
                                                                        <h5 className='font-bold text-base text-[#106B08]'>Car Booking</h5>
                                                                        <h5 className='font-bold text-sm text-[#106B08]'>{moment(item.created_at).format("DD/MM/YYYY")}</h5>
                                                                    </div>



                                                                    <div className='mt-2 flex flex-col'>


                                                                        <div className='mt-2 flex text-base justify-between'>
                                                                            <h3 className='mt-2 text-black font-extrabold'>
                                                                                Amount:
                                                                            </h3>

                                                                            <h3 className='mt-2 text-[#106B08]  font-extrabold'>

                                                                                &#8358;{Number(item.amount).toLocaleString('en-US', { maximumFractionDigits: 2 })}

                                                                            </h3>
                                                                        </div>

                                                                        <div className='mt-2 flex  justify-between items-center'>
                                                                            <h3 className='mt-2 text-base text-black font-extrabold'>
                                                                                Reference:
                                                                            </h3>

                                                                            <p className='mt-2 text-[#106B08] text-xs font-extrabold'>
                                                                                {item.billing_reference}
                                                                            </p>
                                                                        </div>



                                                                    </div>

                                                                </div>
                                                                :
                                                                ''
                                                            }


                                                        </div>
                                                    );
                                                })
                                                )

                                            }
                                        </div>
                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </Dialog>
                </Transition>

                <div style={{ position: "absolute", zIndex: "999" }}>
                    <Transition appear show={notificationModal} as={Fragment}>
                        <Dialog
                            as="div"
                            className="fixed px-6 inset-0 z-[1000] overflow-y-auto"
                            onClose={closeModal}
                        >
                            <div className="min-h-screen px-4 text-center">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-25 bg-white"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-25"
                                    leaveTo="opacity-0"
                                >
                                    <Dialog.Overlay className="fixed inset-0" />
                                </Transition.Child>

                                {/* This element is to trick the browser into centering the modal contents. */}
                                <span
                                    className="inline-block h-screen align-middle"
                                    aria-hidden="true"
                                >
                                    &#8203;
                                </span>
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                >
                                    <div className="notification-modal-wrapper inline-block w-full max-w-md pt-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-transparent shadow-xl rounded-2xl">
                                        <div className="bg-project rounded-2xl p-6">
                                            <div className="header flex mb-6">
                                                <div className='w-11/12'>
                                                    <h4 className='text-white text-center font-bold text-2xl'>Notifications</h4>
                                                </div>
                                                <button className='w-1/12'>
                                                    <img src={cancelImg} className='w-[30px]' onClick={closeModal} alt="Cancel Modal" />
                                                </button>
                                            </div>

                                            <div className="notification-lists-container overflow-y-scroll h-[450px]">
                                                {notifications.map((item) => (
                                                    <div className="notification-box bg-white p-6 rounded-xl mt-4">
                                                        <h5 className='font-bold text-base'>{moment(item.created_at).format("DD/MM/YYYY")}</h5>

                                                        <p>
                                                            {item.contents}
                                                        </p>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </Transition.Child>
                            </div>
                        </Dialog>
                    </Transition>
                </div>


                <Transition appear show={registerSuperadminOpen} as={Fragment}>
                    <Dialog
                        as="div"
                        className="w-full md:w-11/12  fixed flex justify-center items-center px-6 inset-0 z-10 overflow-y-auto"
                        onClose={closeModal}
                    >
                        <div className="w-full md:w-full min-h-screen px-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-25 bg-white"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-25"
                                leaveTo="opacity-0"
                            >
                                <Dialog.Overlay className="fixed inset-0" />
                            </Transition.Child>

                            {/* This element is to trick the browser into centering the modal contents. */}
                            <span
                                className="inline-block h-screen align-middle"
                                aria-hidden="true"
                            >
                                &#8203;
                            </span>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <div className="w-full md:w-9/12 inline-block  max-w-md  overflow-hidden text-left align-middle transition-all transform shadow-xl rounded-2xl">

                                    <div className="bg-white px-12 md:p-6 md:px-16 w-full  h-auto rounded-lg">
                                        <form onSubmit={formikSuperadmin.handleSubmit} className='flex flex-col items-center justify-center mt-12  relative'>
                                            <img src={users} className="md:w-20  w-16 absolute md:-top-12 -top-9 left-50" alt="" />

                                            <input
                                                type="text"
                                                placeholder='Your Name:'
                                                className='md:text-sm text-xs p-3 w-full border border-gray-500 rounded-lg my-4 placeholder:text-xs placeholder:text-gray-700'
                                                name="name"
                                                id="name"
                                                onBlur={formikSuperadmin.handleBlur}
                                                onChange={formikSuperadmin.handleChange}
                                                value={formikSuperadmin.values.name}
                                            />

                                            {formikSuperadmin.touched.name && formikSuperadmin.errors.name ? <p className='text-red-600 text-left text-xs w-full -mt-3 mb-4'>{formikSuperadmin.errors.name}</p> : null}

                                            <div className='p-3 w-full bg-white flex gap-1 items-center  justify-center border border-gray-500 rounded-lg mt-2'>
                                                <input
                                                    type="email"
                                                    placeholder='Your Email:'
                                                    className='md:text-sm text-xs w-11/12 placeholder:text-xs placeholder:text-gray-700'
                                                    name="email"
                                                    id="email"
                                                    onBlur={formikSuperadmin.handleBlur}
                                                    onChange={formikSuperadmin.handleChange}
                                                    value={formikSuperadmin.values.email}
                                                />

                                                <img src={email} className="w-6" alt="" />
                                            </div>

                                            {formikSuperadmin.touched.email && formikSuperadmin.errors.email ? <p className='text-red-600 text-left text-xs w-full mt-2 mb-4'>{formikSuperadmin.errors.email}</p> : null}



                                            <div className='p-3 w-full bg-white flex gap-1 items-center  justify-center border border-gray-500 rounded-lg mt-6'>
                                                <input
                                                    type="phone"
                                                    placeholder='Phone Number:'
                                                    className='md:text-sm text-xs w-11/12 placeholder:text-xs placeholder:text-gray-700'
                                                    name="phone_number"
                                                    id="phone_number"
                                                    onBlur={formikSuperadmin.handleBlur}
                                                    onChange={formikSuperadmin.handleChange}
                                                    value={formikSuperadmin.values.phone_number}

                                                />

                                                <img src={calls} className="w-6" alt="" />
                                            </div>
                                            {formikSuperadmin.touched.phone_number && formikSuperadmin.errors.phone_number ? <p className='text-red-600 text-left text-xs w-full mt-1 mb-4'>{formikSuperadmin.errors.phone_number}</p> : null}



                                            <div className='p-3 w-full bg-white flex gap-1 items-center  justify-center border border-gray-500 rounded-lg mt-6'>
                                                <input
                                                    type={passwordShown ? "text" : "password"}
                                                    placeholder='Password: A minimum of 8 characters'
                                                    className='md:text-sm text-xs w-11/12 placeholder:text-xs placeholder:text-gray-700'
                                                    name="password"
                                                    id="password"
                                                    onBlur={formikSuperadmin.handleBlur}
                                                    onChange={formikSuperadmin.handleChange}
                                                    value={formikSuperadmin.values.password}


                                                />



                                                {passwordShown ? (

                                                    <button onClick={e => togglePassword(e)}><img src={open} className="w-6 h-6" alt="" /></button>

                                                ) : (
                                                    <button onClick={e => togglePassword(e)}><img src={closed} className="w-6 h-6" alt="" /></button>

                                                )}


                                            </div>
                                            {formikSuperadmin.touched.password && formikSuperadmin.errors.password ? <p className='text-red-600 text-left text-xs w-full mt-2 mb-4'>{formikSuperadmin.errors.password}</p> : null}


                                            <div className='p-3 w-full bg-white flex gap-1 items-center  justify-center border border-gray-500 rounded-lg mt-6'>
                                                <input
                                                    type={passwordShown ? "text" : "password"}
                                                    placeholder='Confirm Password'
                                                    className='md:text-sm text-xs w-11/12 placeholder:text-xs placeholder:text-gray-700'
                                                    name="password_confirmation"
                                                    id="password_confirmation"
                                                    onBlur={formikSuperadmin.handleBlur}
                                                    onChange={formikSuperadmin.handleChange}
                                                    value={formikSuperadmin.values.password_confirmation}


                                                />



                                                {passwordShown ? (

                                                    <button onClick={e => togglePassword(e)}><img src={open} className="w-6 h-6" alt="" /></button>

                                                ) : (
                                                    <button onClick={e => togglePassword(e)}><img src={closed} className="w-6 h-6" alt="" /></button>

                                                )}


                                            </div>
                                            {formikSuperadmin.touched.password_confirmation && formikSuperadmin.errors.password_confirmation ? <p className='text-red-600 text-left text-xs w-full mt-2 mb-4'>{formikSuperadmin.errors.password_confirmation}</p> : null}




                                            {
                                                load ? (<div className="bg-amber-500 w-11/12 font-semibold rounded-lg my-4 p-1 flex justify-center items-center "><h4 className='text-black text-sm'>Please Wait!</h4><input type="image" src={Spin} value="Loading" className="w-10" /></div>) :
                                                    (<button type="submit" className='bg-amber-500 p-3 w-11/12 font-semibold rounded-lg my-4'>Sign-Up</button>)
                                            }

                                        </form>



                                    </div>

                                </div>
                            </Transition.Child>
                        </div>
                    </Dialog>
                </Transition>

                <Transition appear show={registerAdminOpen} as={Fragment}>
                    <Dialog
                        as="div"
                        className="w-full md:w-11/12  fixed flex justify-center items-center px-6 inset-0 z-10 overflow-y-auto"
                        onClose={closeModal}
                    >
                        <div className="w-full md:w-full min-h-screen px-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-25 bg-white"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-25"
                                leaveTo="opacity-0"
                            >
                                <Dialog.Overlay className="fixed inset-0" />
                            </Transition.Child>

                            {/* This element is to trick the browser into centering the modal contents. */}
                            <span
                                className="inline-block h-screen align-middle"
                                aria-hidden="true"
                            >
                                &#8203;
                            </span>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <div className="w-full md:w-9/12 inline-block  max-w-md  overflow-hidden text-left align-middle transition-all transform shadow-xl rounded-2xl">


                                    <div className="bg-white px-12 md:p-6 md:px-16 w-full  h-auto rounded-lg">
                                        <form onSubmit={formik.handleSubmit} className='flex flex-col items-center justify-center mt-12  relative'>
                                            <img src={users} className="md:w-20  w-16 absolute md:-top-12 -top-9 left-50" alt="" />

                                            <input
                                                type="text"
                                                placeholder='Admin Name:'
                                                className='md:text-sm text-xs p-3 w-full border border-gray-500 rounded-lg my-4 placeholder:text-xs placeholder:text-gray-700'
                                                name="name"
                                                id="name"
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                                value={formik.values.name}
                                            />

                                            {formik.touched.name && formik.errors.name ? <p className='text-red-600 text-left text-xs w-full -mt-3 mb-4'>{formik.errors.name}</p> : null}

                                            <div className='p-3 w-full bg-white flex gap-1 items-center  justify-center border border-gray-500 rounded-lg mt-2'>
                                                <input
                                                    type="email"
                                                    placeholder='Admin Email:'
                                                    className='md:text-sm text-xs w-11/12 placeholder:text-xs placeholder:text-gray-700'
                                                    name="email"
                                                    id="email"
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    value={formik.values.email}
                                                />

                                                <img src={email} className="w-6" alt="" />
                                            </div>

                                            {formik.touched.email && formik.errors.email ? <p className='text-red-600 text-left text-xs w-full mt-2 mb-4'>{formik.errors.email}</p> : null}



                                            <div className='p-3 w-full bg-white flex gap-1 items-center  justify-center border border-gray-500 rounded-lg mt-6'>
                                                <input
                                                    type="phone"
                                                    placeholder='Admin Phone Number:'
                                                    className='md:text-sm text-xs w-11/12 placeholder:text-xs placeholder:text-gray-700'
                                                    name="phone_number"
                                                    id="phone_number"
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    value={formik.values.phone_number}

                                                />

                                                <img src={calls} className="w-6" alt="" />
                                            </div>
                                            {formik.touched.phone_number && formik.errors.phone_number ? <p className='text-red-600 text-left text-xs w-full mt-1 mb-4'>{formik.errors.phone_number}</p> : null}



                                            <div className='p-3 w-full bg-white flex gap-1 items-center  justify-center border border-gray-500 rounded-lg mt-6'>
                                                <input
                                                    type={passwordShown ? "text" : "password"}
                                                    placeholder='Password: A minimum of 8 characters'
                                                    className='md:text-sm text-xs w-11/12 placeholder:text-xs placeholder:text-gray-700'
                                                    name="password"
                                                    id="password"
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    value={formik.values.password}


                                                />



                                                {passwordShown ? (

                                                    <button onClick={e => togglePassword(e)}><img src={open} className="w-6 h-6" alt="" /></button>

                                                ) : (
                                                    <button onClick={e => togglePassword(e)}><img src={closed} className="w-6 h-6" alt="" /></button>

                                                )}


                                            </div>
                                            {formik.touched.password && formik.errors.password ? <p className='text-red-600 text-left text-xs w-full mt-2 mb-4'>{formik.errors.password}</p> : null}


                                            <div className='p-3 w-full bg-white flex gap-1 items-center  justify-center border border-gray-500 rounded-lg mt-6'>
                                                <input
                                                    type={passwordShown ? "text" : "password"}
                                                    placeholder='Confirm Password'
                                                    className='md:text-sm text-xs w-11/12 placeholder:text-xs placeholder:text-gray-700'
                                                    name="password_confirmation"
                                                    id="password_confirmation"
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    value={formik.values.password_confirmation}


                                                />



                                                {passwordShown ? (

                                                    <button onClick={e => togglePassword(e)}><img src={open} className="w-6 h-6" alt="" /></button>

                                                ) : (
                                                    <button onClick={e => togglePassword(e)}><img src={closed} className="w-6 h-6" alt="" /></button>

                                                )}


                                            </div>
                                            {formik.touched.password_confirmation && formik.errors.password_confirmation ? <p className='text-red-600 text-left text-xs w-full mt-2 mb-4'>{formik.errors.password_confirmation}</p> : null}






                                            {
                                                load ? (<div className="bg-amber-500 w-full font-semibold rounded-lg my-4 p-1 flex justify-center items-center "><h4 className='text-black text-sm'>Please Wait!</h4><input type="image" src={Spin} value="Loading" className="w-10" /></div>) :
                                                    (<button type="submit" className='bg-amber-500 p-3 w-full font-semibold rounded-lg mt-8 mb-4'>Add Admin</button>)
                                            }

                                        </form>




                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </Dialog>
                </Transition>


                <Transition appear show={dailyPriceOpen} as={Fragment}>
                    <Dialog
                        as="div"
                        className="w-full md:w-11/12  fixed flex justify-center items-center px-6 inset-0 z-10 overflow-y-auto"
                        onClose={closeModal}
                    >
                        <div className="w-full xl:w-5/12 lg:w-6/12 md:w-8/12 min-h-screen px-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-25 bg-white"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-25"
                                leaveTo="opacity-0"
                            >
                                <Dialog.Overlay className="fixed inset-0" />
                            </Transition.Child>

                            {/* This element is to trick the browser into centering the modal contents. */}
                            <span
                                className="inline-block h-screen align-middle"
                                aria-hidden="true"
                            >
                                &#8203;
                            </span>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <div className="w-full md:w-9/12 inline-block  max-w-md  overflow-hidden text-left align-middle transition-all transform shadow-xl rounded-2xl">


                                    <div className="bg-black p-10">


                                        <form action="" className="flex md:flex-row flex-col gap-4 items-center gap-4 ">


                                            <input
                                                type="text"
                                                value={period}
                                                onChange={e => setPeriod(e.target.value)}
                                                placeholder="Period"
                                                className='w-full text-white p-2 bg-transparent border-b border-white' />



                                            <input
                                                type="number"
                                                value={amount}
                                                onChange={e => setAmount(e.target.value)}
                                                placeholder="Amount"
                                                className='w-full text-white p-2 bg-transparent border-b border-white' />



                                            <button

                                                type="button"
                                                onClick={handleAddDailyPrice}

                                                className='w-full rounded-md text-center  py-2 px-2 text-black text-xs font-bold bg-amber-500'>

                                                Add</button>
                                        </form>





                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </Dialog>
                </Transition>



                <Transition appear show={fullTimePriceOpen} as={Fragment}>
                    <Dialog
                        as="div"
                        className="w-full md:w-11/12  fixed flex justify-center items-center px-6 inset-0 z-10 overflow-y-auto"
                        onClose={closeModal}
                    >
                        <div className="w-full xl:w-5/12 lg:w-6/12 md:w-8min-h-screen px-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-25 bg-white"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-25"
                                leaveTo="opacity-0"
                            >
                                <Dialog.Overlay className="fixed inset-0" />
                            </Transition.Child>

                            {/* This element is to trick the browser into centering the modal contents. */}
                            <span
                                className="inline-block h-screen align-middle"
                                aria-hidden="true"
                            >
                                &#8203;
                            </span>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <div className="w-full md:w-8/12 inline-block  max-w-md  overflow-hidden text-left align-middle transition-all transform shadow-xl rounded-2xl">


                                    <div className="bg-black p-10">


                                        <form action="" className="flex md:flex-row flex-col  gap-4 items-center gap-4 ">


                                            <input
                                                type="text"
                                                value={fulltimeDescription}
                                                onChange={e => setFulltimeDescription(e.target.value)}
                                                placeholder="Description"
                                                className='w-full text-white p-2 bg-transparent border-b border-white ' />


                                            <input
                                                type="text"
                                                value={fulltimePriceInput}
                                                onChange={e => setFulltimePriceInput(e.target.value)}
                                                placeholder="Price"
                                                className='w-full text-white p-2 bg-transparent border-b border-white ' />

                                            <button

                                                type="button"
                                                onClick={handleAddFullTimePrice}

                                                className='w-full rounded-md text-center  py-2 px-2 text-black text-xs font-bold bg-amber-500'>

                                                Add</button>
                                        </form>





                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </Dialog>
                </Transition>

                <Transition appear show={insuranceOpen} as={Fragment}>
                    <Dialog
                        as="div"
                        className="w-full md:w-11/12  fixed flex justify-center items-center px-6 inset-0 z-10 overflow-y-auto"
                        onClose={closeModal}
                    >
                        <div className="w-full xl:w-5/12 lg:w-6/12 md:w-8/12 min-h-screen px-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-25 bg-white"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-25"
                                leaveTo="opacity-0"
                            >
                                <Dialog.Overlay className="fixed inset-0" />
                            </Transition.Child>

                            {/* This element is to trick the browser into centering the modal contents. */}
                            <span
                                className="inline-block h-screen align-middle"
                                aria-hidden="true"
                            >
                                &#8203;
                            </span>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <div className="w-full md:w-9/12 inline-block  max-w-md  overflow-hidden text-left align-middle transition-all transform shadow-xl rounded-2xl">


                                    <div className="bg-black p-10">


                                        <form action="" className="flex md:flex-row flex-col  gap-4 items-center gap-4 ">


                                            <input
                                                type="text"
                                                value={insuranceDescription}
                                                onChange={e => setInsuranceDescription(e.target.value)}
                                                placeholder="Description"
                                                className='w-full text-white p-2 bg-transparent border-b border-white ' />


                                            <CurrencyInput
                                                suffix='%'
                                                allowNegativeValue={false}
                                                value={insuranceCommission}
                                                className='w-full text-white p-2 bg-transparent border-b border-white '
                                                placeholder="Commission"

                                                onValueChange={(value) => {
                                                    setInsuranceCommission(value)
                                                }}
                                            />

                                            <button

                                                type="button"
                                                onClick={handleAddInsurance}

                                                className='w-full rounded-md text-center  py-2 px-2 text-black text-xs font-bold bg-amber-500'>

                                                Add</button>
                                        </form>





                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </Dialog>
                </Transition>

                <Transition appear show={marginOpen} as={Fragment}>
                    <Dialog
                        as="div"
                        className="w-full md:w-11/12  fixed flex justify-center items-center px-6 inset-0 z-10 overflow-y-auto"
                        onClose={closeModal}
                    >
                        <div className="w-full xl:w-5/12 lg:w-6/12 md:w-8/12 min-h-screen px-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-25 bg-white"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-25"
                                leaveTo="opacity-0"
                            >
                                <Dialog.Overlay className="fixed inset-0" />
                            </Transition.Child>

                            {/* This element is to trick the browser into centering the modal contents. */}
                            <span
                                className="inline-block h-screen align-middle"
                                aria-hidden="true"
                            >
                                &#8203;
                            </span>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <div className="w-full md:w-full inline-block  max-w-md  overflow-hidden text-left align-middle transition-all transform shadow-xl rounded-2xl">


                                    <div className="bg-black p-6">


                                        <form action="" className="flex md:flex-row flex-col  gap-4 items-center gap-4 ">


                                            <CurrencyInput
                                                prefix='₦'

                                                allowNegativeValue={false}
                                                className='w-full text-white p-2 bg-transparent border-b border-white '
                                                placeholder='Min'
                                                value={minimum}
                                                onValueChange={(value) => {
                                                    setMinimum(value)
                                                }}
                                            />

                                            <CurrencyInput
                                                prefix='₦'
                                                allowNegativeValue={false}
                                                value={maximum}
                                                className='w-full text-white p-2 bg-transparent border-b border-white '
                                                placeholder='Max'

                                                onValueChange={(value) => {
                                                    setMaximum(value)
                                                }}
                                            />

                                            <select name="" id=""
                                                className='w-full text-white p-2 bg-black border-b border-white '
                                                onChange={e => setPricingType(e.target.value)}

                                            >
                                                <option>Pricing Type</option>
                                                <option value="FLAT">Flat</option>
                                                <option value="PERCENTAGE">Percentage</option>
                                            </select>


                                            {pricingType === 'FLAT' ? (
                                                <CurrencyInput
                                                    prefix='₦'
                                                    allowNegativeValue={false}
                                                    value={marginPrice}
                                                    className='w-full text-white p-2 bg-transparent border-b border-white '

                                                    onValueChange={(value) => {
                                                        setMarginPrice(value)
                                                    }}
                                                />
                                            ) : (
                                                <CurrencyInput
                                                    suffix='%'
                                                    allowNegativeValue={false}
                                                    value={marginPrice}
                                                    className='w-full text-white p-2 bg-transparent border-b border-white '

                                                    onValueChange={(value) => {
                                                        setMarginPrice(value)
                                                    }}
                                                />
                                            )

                                            }
                                            <button

                                                type="button"
                                                onClick={handleAddMargin}

                                                className='w-full rounded-md text-center  py-2 px-2 text-black text-xs font-bold bg-amber-500'>

                                                Add </button>
                                        </form>





                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </Dialog>
                </Transition>

                <Transition appear show={giftOpen} as={Fragment}>
                    <Dialog
                        as="div"
                        className="w-full   fixed flex justify-center items-center px-6 inset-0 z-10 overflow-y-auto"
                        onClose={closeModal}
                    >
                        <div className="w-full   min-h-screen px-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-25 bg-white"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-25"
                                leaveTo="opacity-0"
                            >
                                <Dialog.Overlay className="fixed inset-0" />
                            </Transition.Child>

                            {/* This element is to trick the browser into centering the modal contents. */}
                            <span
                                className="inline-block h-screen align-middle"
                                aria-hidden="true"
                            >
                                &#8203;
                            </span>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <div className="w-full  inline-block  max-w-md  overflow-hidden text-left align-middle transition-all transform shadow-xl rounded-2xl">


                                    <div className="bg-black px-4 py-6">


                                        <form action="" className="flex gap-4 items-center gap-4 ">



                                            <div className="w-6/12 relative">


                                                {coverImagePreview ?
                                                    <div className=" relative  rounded-lg w-full justify-center items-center flex">


                                                        <img
                                                            src={coverImagePreview}
                                                            alt=""
                                                            className=' w-full  rounded-lg  object-cover object-center'
                                                            onClick={(event) => {
                                                                event.preventDefault()
                                                                fileInputRef.current.click()
                                                            }}
                                                        />
                                                    </div>


                                                    : <div className="py-4 px-2 rounded-lg w-full justify-center items-center flex">


                                                        <img src={gift_faint} alt="" className='brightness-50 opacity-25 md:w-full h-full' />

                                                        <h2
                                                            onClick={(event) => {
                                                                event.preventDefault()
                                                                fileInputRef.current.click()
                                                            }}
                                                            className="cursor-pointer text-white absolute left-12 top-14  text-base">Upload Image</h2>


                                                    </div>

                                                }


                                                <input
                                                    type="file"
                                                    style={{ display: 'none' }}
                                                    ref={fileInputRef}
                                                    accept="image/*"
                                                    onChange={(event) => {
                                                        const file = event.target.files[0]
                                                        if (file) {
                                                            setCoverImage(file)
                                                        }
                                                        else {
                                                            setCoverImage(null)
                                                        }
                                                    }} />



                                            </div>


                                            <div className=" flex flex-col w-6/12">
                                                <input
                                                    type="text"
                                                    value={giftName}
                                                    onChange={e => setGiftName(e.target.value)}
                                                    placeholder="Name of Gift"
                                                    className='curr w-full text-white p-2 bg-transparent ' />

                                                <textarea
                                                    placeholder="Type here..."
                                                    className='focus:outline-none bg-transparent text-white p-2 bg-transparent '
                                                    value={giftDesc}
                                                    onChange={e => setGiftDesc(e.target.value)}

                                                >

                                                </textarea>
                                                <div className="flex gap-4 ">
                                                    <input
                                                        type="text"
                                                        value={giftPrice}
                                                        onChange={e => setGiftPrice(e.target.value)}
                                                        placeholder="Price"
                                                        className='w-3/6 text-white p-1 bg-transparent border border-white ' />




                                                    <button
                                                        onClick={handleAddGift}

                                                        type="button" className='bg-white w-4/12 p-1 text-black font-bold rounded-md  text-sm text-center'>Save</button>

                                                </div>
                                            </div>


                                        </form>





                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </Dialog>
                </Transition>


                <main className='flex md:px-12 pt-20 flex-col md:flex-row'>

                    <NavLink


                        className="ml-12 mt-12 p-2 btn-color w-40 text-center rounded-md text-sm font-bold md:hidden" to="/Admin">Switch to Admin</NavLink>




                    <div className='border-r border-white h-100 xl:w-1/5  lg:w-2/5 md:w-3/5 pb-20 hidden md:block md:pt-16'>

                        <NavLink


                            className="p-2 btn-color rounded-md text-sm font-bold" to="/Admin" >Switch to Admin</NavLink>

                        <div className='mt-6 my-3'>
                            <span className='flex items-center text-white'> <img src={dashboard} alt="" className='mr-3 w-8' /> <a href="/superadmin#dashboard" className='font-light' >Dashboard</a></span>
                        </div>

                        <div className='my-3'>
                            <span className='flex items-center text-white'> <img src={payment} alt="" className='mr-3 w-8' /> <a href="/superadmin#payments" className='font-light'>Payments</a></span>
                        </div>

                        <div className='my-3'>
                            <span className='flex items-center text-white'> <img src={service_provider} alt="" className='mr-3 w-8' /> <a href="/superadmin#providers" className='font-light'>Service Providers</a></span>
                        </div>

                        <div className='my-3'>
                            <span className='flex items-center text-white'> <img src={price_setting} alt="" className='mr-3 w-8' /> <a href="/superadmin#settings" className='font-light'>Price Setting</a></span>
                        </div>

                        <div className='my-3'>
                            <span className='flex items-center text-white'> <img src={customer} alt="" className='mr-3 w-8' /> <a href="/superadmin#customers" className='font-light'>Customers</a></span>
                        </div>

                        <div className='my-3'>


                            <span className='flex items-center text-white'>
                                <img src={notificationIcon} alt="" className='mr-3 w-8' />

                                <button

                                    onClick={notifyModal}

                                    className='flex items-center text-white'>
                                    Notifications</button>
                            </span>

                        </div>
                        <div className='my-3'>

                            <span
                                onClick={transModal}

                                className='flex items-center text-white'> <img src={naira} alt="" className='mr-3 w-8' /> Transaction History</span>

                        </div>

                        <div className="w-4/6 py-1 mt-8 bg-black text-white rounded-md text-center  ">
                            <button
                                onClick={registerAdminModal}
                                className="text-sm font-light" exact to="/register-admin" >Register Admin </button>

                        </div>

                        <div className="w-4/6 py-1 mt-4 bg-black text-white rounded-md text-center  ">
                            <button
                                onClick={registerSuperadminModal}


                                className="text-sm font-light" exact to="/register-superadmin">Register superAdmin </button>

                        </div>

                    </div>
                    <div id='dashboard' className='md:border-r md:border-white h-100 lg:w-3/5  xl:w-2/5 w-full px-12 lg:p-16 xl:px-20 pb-20'>
                        <div className="flex md:justify-end justify-between items-center mt-8 md:mt-0">
                            <h2 className='text-white font-semibold  text-base md:text-xl w-full md:hidden'>Current Earnings</h2>

                            <img src={eyes} alt="" className=' mt-0 md:mt-8 w-6' />

                        </div>
                        <h2 className='text-white font-semibold  text-lg md:text-xl w-full hidden md:block'>Current Earnings</h2>

                        <div className='md:h-48 bg-black border-theme-color mt-12 rounded-lg px-8 py-4 flex flex-col'>
                            <h4 className='green-color md:text-base text-sm'>Revenue in {month}</h4>
                            <h3 className='text-white mt-1 md:text-lg  text-base'>Roadlers</h3>
                            <h1 className='theme-color md:text-2xl font-bold my-4 text-base'>
                                &#8358;{Number(revenue).toLocaleString('en-US', { maximumFractionDigits: 2 })}

                            </h1>

                            <h6 className='text-white'>Cost:
                                &#8358;{Number(cost).toLocaleString('en-US', { maximumFractionDigits: 2 })}

                            </h6>

                        </div>

                        <button
                            onClick={transModal}
                            className='border-theme-color md:text-base text-sm bg-white p-2 w-full mt-6 rounded-lg font-semibold'>Transaction History</button>

                        <div className='mt-16'>
                            <h2 className='text-white font-semibold md:text-xl text-base'>
                                Crypto Balance
                            </h2>
                            <div className='w-full  gap-4 mt-4'>

                                <div className="bg-white p-3 rounded-lg w-full mb-4">
                                    <p className='text-xs'>Current Balance</p>
                                    <div className="flex justify-between my-2">
                                        <div className="flex items-center">
                                            <img src={bitcoin} alt="" className='w-10 h-10 flex ' />
                                            <h5 className='lg:text-sm md:text-xs font-bold'>Bitcoin (BTC)</h5>

                                        </div>
                                        <div className="flex flex-col gap-2">
                                            <p className='text-xs font-bold text-amber-500'>₦0.00</p>
                                            <p className='text-sm font-semibold'>0</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="bg-white p-3 rounded-lg w-full mb-4">
                                    <p className='text-xs'>Current Balance</p>
                                    <div className="flex justify-between my-2">
                                        <div className="flex items-center">
                                            <img src={usdt} alt="" className='w-10 h-10 flex ' />
                                            <h5 className='lg:text-sm md:text-xs font-bold'>Tether (USDT)</h5>

                                        </div>
                                        <div className="flex flex-col gap-2">
                                            <p className='text-xs font-bold text-amber-500'>₦0.00</p>
                                            <p className='text-sm font-semibold'>0</p>
                                        </div>
                                    </div>

                                </div>

                                <div className="bg-white p-3 rounded-lg w-full mb-4">
                                    <p className='text-xs'>Current Balance</p>
                                    <div className="flex justify-between my-2">
                                        <div className="flex items-center">
                                            <img src={binance} alt="" className='w-10 h-10 flex ' />
                                            <h5 className='lg:text-sm md:text-xs font-bold'>Binance (BNB)</h5>

                                        </div>
                                        <div className="flex flex-col gap-2">
                                            <p className='text-xs font-bold text-amber-500'>₦0.00</p>
                                            <p className='text-sm font-semibold'>0</p>
                                        </div>
                                    </div>

                                </div>


                                <div className="bg-white p-3 rounded-lg w-full mb-4">
                                    <p className='text-xs'>Current Balance</p>
                                    <div className="flex justify-between my-2">
                                        <div className="flex items-center">
                                            <img src={ethereum} alt="" className='w-10 h-10 flex ' />
                                            <h5 className='lg:text-sm md:text-xs font-bold'>Ethereum (ETH)</h5>

                                        </div>
                                        <div className="flex flex-col gap-2">
                                            <p className='text-xs font-bold text-amber-500'>₦0.00</p>
                                            <p className='text-sm font-semibold'>0</p>
                                        </div>
                                    </div>

                                </div>

                            </div>

                            <button className='border-theme-color md:text-base text-sm bg-black text-white p-2 w-full mt-6 rounded-lg font-semibold'>Transaction History</button>

                        </div>


                    </div>
                    <div className="md:hidden w-full h-16 px-12 bg-white flex justify-between items-center">
                        <h2 className='text-sm font-bold'>Financial Summary in {month}</h2>
                        <img src={blackeye} alt="" className='w-6' />
                    </div>

                    <div className='xl:px-20 px-12 xl:w-2/5 lg:w-2/5 md:w-2/5 lg:px-16 w-full' >




                        <h2 className='text-white font-bold text-2xl mt-20 hidden md:block'>Financial Summary in {month}</h2>
                        <div className="pie flex  md:justify-start justify-center mt-8">
                            <div className='flex md:justify-start  chart' >
                                <Charts />
                            </div>


                            <div className="my-8 md:hidden -ml-6">
                                <div className="flex my-3 items-center ">
                                    <div className="w-4 h-4 green mr-2"></div>
                                    <p className='text-white font-normal text-sm'>Revenue</p>
                                </div>

                                <div className="flex  items-center">
                                    <div className="w-4 h-4 theme mr-2"></div>
                                    <p className='text-white font-normal text-sm'>Gross Profit</p>
                                </div>

                                <div className="flex my-3  items-center ">
                                    <div className="w-4 h-4 blue mr-2"></div>
                                    <p className='text-white font-normal text-sm'>Service Cost</p>
                                </div>

                                <div className="flex  items-center">
                                    <div className="w-4 h-4 red mr-2"></div>
                                    <p className='text-white font-normal text-sm'>Vehicle Cost</p>
                                </div>

                                <div className="flex my-3  items-center">
                                    <div className="w-4 h-4 bg-white mr-2"></div>
                                    <p className='text-white font-normal text-sm'>VAT</p>
                                </div>


                            </div>

                        </div>

                        <div className="my-8 hidden md:block">
                            <div className="flex my-2 items-center ">
                                <div className="w-4 h-4 green mr-2"></div>
                                <h5 className='text-white font-normal text-lg'>Revenue</h5>
                            </div>

                            <div className="flex my-2  items-center">
                                <div className="w-4 h-4 theme mr-2"></div>
                                <h5 className='text-white font-normal text-lg'>Gross Profit</h5>
                            </div>

                            <div className="flex my-2  items-center ">
                                <div className="w-4 h-4 blue mr-2"></div>
                                <h5 className='text-white font-normal text-lg'>Service Cost</h5>
                            </div>

                            <div className="flex my-2  items-center">
                                <div className="w-4 h-4 red mr-2"></div>
                                <h5 className='text-white font-normal text-lg'>Vehicle Cost</h5>
                            </div>

                            <div className="flex my-2  items-center">
                                <div className="w-4 h-4 bg-white mr-2"></div>
                                <h5 className='text-white font-normal  text-lg'>VAT</h5>
                            </div>


                        </div>

                        <div className='mt-16'>
                            <h2 className='text-white md:font-bold font-semibold md:text-2xl text-base mb-8'>Account Summary</h2>
                            <div className='flex justify-start w-full items-center mb-4 '>
                                <ExportToExcel apiData={drivers} fileName={'Roadlers Drivers'} />

                                <h2 className='md:text-xl text-sm blue-color border border-white rounded-md px-6 py-2 mr-8'>{drivers?.length}</h2>

                                <p className='text-white md:text-base text-xs md:font-semibold font-medium '>Drivers Accounts</p>
                            </div>

                            <div className='flex justify-start w-full items-center mb-4 '>
                                <ExportToExcel apiData={assets} fileName={'Roadlers Vehicles'} />


                                <h2 className='md:text-xl text-sm faint-red-color border border-white rounded-md px-6 py-2 mr-8'>{assets?.length}</h2>

                                <p className='text-white md:text-base text-xs md:font-semibold font-medium'>Assets (Vehicles)</p>
                            </div>


                            <div className='flex justify-start w-full items-center mb-4 '>
                                <ExportToExcel apiData={customers} fileName={'Roadlers Customers'} />


                                <h2 className='md:text-xl text-sm theme-color border border-white rounded-md px-6 py-2 mr-8'>{customers?.length}</h2>

                                <p className='text-white md:text-base text-xs md:font-semibold font-medium'>Registerd Customers</p>
                            </div>

                            <div className='flex justify-start w-full items-center mb-4 '>
                                <ExportToExcel apiData={transactions} fileName={'Roadlers Transactions'} />
                                <h2 className='md:text-xl text-sm text-green-400 border border-white rounded-md px-6 py-2 mr-8'>{transactions?.length}</h2>

                                <p className='text-white md:text-base text-xs md:font-semibold font-medium'>Transactions</p>
                            </div>

                            <div className='flex justify-start w-full items-center mb-4 '>
                                <ExportToExcel apiData={providersData} fileName={'Roadlers Service providers'} />
                                <h2 className='md:text-xl text-sm text-green-400 border border-white rounded-md px-6 py-2 mr-8'>{providersData?.length}</h2>

                                <p className='text-white md:text-base text-xs md:font-semibold font-medium'>Providers Details</p>
                            </div>


                        </div>

                    </div>


                </main>

                <Subheader text='Payment Requests' />

                {/* <div id='payments' className='xl:px-64 lg:px-32 md:px-16 px-12 md:mt-20 mt-10'>
                    <div className="flex justify-end mb-8 md:mb-4">

                        <div className="p-2 px-3 border  text-white rounded-md border-white flex">
                            <p className='mr-4 text-sm md:text-base'>2 March 2022 - 2 April 2022</p>    <img src={one} alt="" className='w-4 md:w-6' />
                        </div>

                    </div>

                    <h2 className='text-white font-semibold text-lg hidden md:block'>Bulk Payment Due</h2>
                    <Bulk driverOne={driverOne} priceOne='100' title='Full-time Driver' amountOne='₦6,000,750' buttonOne='Due Today' />
                    <Bulk driverTwo={driverTwo} priceTwo='200' title='One-off Services' amountTwo='₦2,080,750' buttonTwo='Pay All' />
                    <Bulk driverThree={driverThree} priceThree='300' title='Cars Booked' amountThree='₦9,000,750' themeButton='Due in 2 Days' />
                </div> */}

                <ToastContainer />

                <div id='payments' className='px-6 xl:px-64 lg:px-32 md:px-16 '>
                    {paymentRequests.slice(0, visiblePayment).map((paymentRequest, i) => {

                        return (
                            <Payment
                                key={paymentRequests.id}
                                paymentRequest={paymentRequest}
                                paymentRequestsUsername={paymentRequest.provider_username}
                                paymentRequestsAccountName={paymentRequest.account_name}
                                paymentRequestsAccountNumber={paymentRequest.account_number}
                                paymentRequestsDate={paymentRequest.created_at}
                                paymentRequestsStatus={paymentRequest.status}
                                paymentRequestsBalance={paymentRequest.wallet_balance}
                                paymentRequestsWithdrawAmount={paymentRequest.withdrawal_amount}

                            />
                        )

                    })}

                    {!paymentRequests?.length || paymentRequests?.length <= visiblePayment ? ('') : (

                        <div className="w-full flex items-center justify-center my-12 md:mb-12">
                            <button
                                onClick={showMorePayment}

                                className='border border-theme-color theme-color font-semibold p-3 text-sm rounded-lg w-36'>Load More</button>

                        </div>

                    )}
                </div>

                <Subheader text='Service Providers' />

                <div id='providers' className='xl:px-64 lg:px-32 md:px-16 mt-20  date-pick'>

                    <div className="flex md:hidden px-6">
                        <div className="w-4/12">
                            <div className="flex flex-col gap-4 justify-between">
                                <div className="flex items-center">
                                    <button

                                        onClick={handleCarOwner}

                                        className="w-4 h-4 border-2 rounded-full border-white bg-amber-500 mr-2"></button>
                                    <h4 className="text-white text-xs">Car Owners</h4>
                                </div>

                                <div className="flex items-center">
                                    <button

                                        onClick={handleDriver}

                                        className="w-4 h-4 border-2 rounded-full border-white mr-2"></button>

                                    <h4 className="text-white text-xs">Drivers</h4>
                                </div>

                                <div className="flex items-center">
                                    <button

                                        onClick={handleProviderBusiness}

                                        className="w-4 h-4 border-2 rounded-full border-white mr-2"></button>

                                    <h4 className="text-white text-xs">Businesses</h4>
                                </div>
                            </div>
                        </div>

                        <div className="flex justify-between w-8/12 flex-col md:hidden gap-7 date-pick">
                            <div className="p-2  border items-center justify-between   text-white rounded-md border-white flex">
                                <>
                                    <DatePicker
                                        selected={providerStartDate}
                                        onChange={(date) => {
                                            setProviderStartDate(date)

                                        }}
                                        selectsStart
                                        startDate={providerStartDate}
                                        endDate={providerEndDate}
                                        open={providerFilterOpen}

                                    />
                                    <DatePicker
                                        selected={providerEndDate}
                                        onChange={(date) => setProviderEndDate(date)}
                                        selectsEnd
                                        startDate={providerStartDate}
                                        endDate={providerEndDate}
                                        minDate={providerStartDate}
                                        open={providerFilterTwo}

                                    />
                                </>


                                <span
                                    onClick={() => {
                                        setProviderFilterOpen((providerFilterOpen) => !providerFilterOpen)
                                        setProviderFilterOpenTwo(false)

                                    }}

                                    className="text-xx">

                                    {providerStartDate ?
                                        moment(providerStartDate).format("DD/MM/YYYY") : '2 March 2022'
                                    }
                                </span>
                                <span className="text-sm"> - </span>
                                <span
                                    onClick={() => {
                                        setProviderFilterOpenTwo((providerFilterOpen) => !providerFilterOpen)
                                        setProviderFilterOpen(false)
                                    }}
                                    className="text-xx">
                                    {providerEndDate ?
                                        moment(providerEndDate).format("DD/MM/YYYY") : ' 2 April 2022'
                                    }

                                </span>
                                <img src={one} alt="" className="w-4 h-4" />
                            </div>



                            <form className="flex w-full">
                                <input
                                    type="text"
                                    className="focus:outline-none mr-2 placeholder-text-xx border w-3/5 md:w-60 rounded-md border-white text-white  bg-transparent p-1"
                                    placeholder="Provider's ID"
                                    onChange={(e) => handleProviderSearch(e)}


                                    value={providerValue}
                                />
                                <button
                                    type="button"
                                    className="md:w-28 w-2/5 bg-amber-500 text-sm font-bold rounded-md"
                                >
                                    Search
                                </button>
                            </form>

                            <div className="flex justify-between w-full gap-2">
                                <button


                                    className="w-1/3 md:w-24 bg-blue-700 p-1 text-white font-bold rounded-md text-xs">
                                    Due
                                </button>
                                <button


                                    className="w-1/3 md:w-24  p-1 text-black bg-amber-500 font-bold rounded-md text-xx">
                                    Due Soon
                                </button>
                                <button


                                    className="w-1/3 md:w-24 bg-white p-1 text-black font-bold rounded-md text-xs">
                                    Fresh
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="hidden md:block">
                        <div className="flex justify-between ">
                            <form className='flex w-96'>
                                <input
                                    onChange={handleProviderSearch}
                                    value={providerValue}

                                    type="text" className='focus:outline-none mr-2 border w-60 rounded-md border-white text-white  bg-transparent p-2' placeholder="Provider's ID" />
                                <button type="submit" className='w-28 bg-amber-500 font-bold rounded-md'>Search</button>
                            </form>

                            <div className="p-2 px-3 border items-center justify-between  w-64 text-white rounded-md border-white flex">

                                <>
                                    <DatePicker
                                        selected={providerStartDate}
                                        onChange={(date) => {
                                            setProviderStartDate(date)

                                        }}
                                        selectsStart
                                        startDate={providerStartDate}
                                        endDate={providerEndDate}
                                        open={providerFilterOpen}

                                    />
                                    <DatePicker
                                        selected={providerEndDate}
                                        onChange={(date) => setProviderEndDate(date)}
                                        selectsEnd
                                        startDate={providerStartDate}
                                        endDate={providerEndDate}
                                        minDate={providerStartDate}
                                        open={providerFilterTwo}

                                    />
                                </>


                                <span
                                    onClick={() => {
                                        setProviderFilterOpen((providerFilterOpen) => !providerFilterOpen)
                                        setProviderFilterOpenTwo(false)

                                    }}

                                    className="text-sm">

                                    {providerStartDate ?
                                        moment(providerStartDate).format("DD/MM/YYYY") : '2 March 2022'
                                    }
                                </span>
                                <span className="text-sm"> - </span>
                                <span
                                    onClick={() => {
                                        setProviderFilterOpenTwo((providerFilterOpen) => !providerFilterOpen)
                                        setProviderFilterOpen(false)
                                    }}
                                    className="text-sm">
                                    {providerEndDate ?
                                        moment(providerEndDate).format("DD/MM/YYYY") : ' 2 April 2022'
                                    }

                                </span>

                                <img src={one} alt="" className='w-5' />
                            </div>
                        </div>
                    </div>
                </div>

                <div id='providers' className='xl:px-64 lg:px-32 md:px-16 mt-8 '>
                    <div className="flex justify-between items-center w-full  hidden md:flex">
                        <div className='flex gap-4'>
                            <div className="flex items-center mr-6">
                                <button
                                    onClick={handleDriver}

                                    className='w-6 h-6 border-2 rounded-full border-white theme mr-4'></button>
                                <h4 className='text-white'>Drivers</h4>
                            </div>

                            <div className="flex items-center mr-6">
                                <button


                                    onClick={handleCarOwner}

                                    className='w-6 h-6 border-2 rounded-full border-white mr-4'></button>

                                <h4 className='text-white'>Cars Owners</h4>
                            </div>


                            <div className="flex items-center mr-6">
                                <button
                                    onClick={handleProviderBusiness}
                                    className='w-6 h-6 border-2 rounded-full border-white mr-4'></button>

                                <h4 className='text-white'>Businesses</h4>
                            </div>


                        </div>




                        <div className='flex gap-4'>
                            <button className='xl:w-24 lg:w-20 md:w-16 bg-red-700 p-2 text-white font-bold rounded-lg mr-3'>Due</button>
                            <button className='xl:w-24  lg:w-20 md:w-16 p-2 text-black theme font-bold rounded-lg mr-3'>Due Soon</button>
                            <button className='xl:w-24 lg:w-20 md:w-16 bg-white p-2 text-black font-bold rounded-lg'>Fresh</button>
                        </div>

                    </div>



                    {!providerResult?.length ? (<NoResult />) :

                        (<div className="px-6 my-12 lg:grid-cols-4 md:grid-cols-3 grid-cols-2 grid gap-6 md:pag-8 lg:gap-16 ">
                            {

                                providerStartDate && providerEndDate ?
                                    (providerResult.filter(obj => (moment(obj.created_at).format("YYYY/MM/DD")) >= (moment(providerStartDate).format("YYYY/MM/DD")) && (moment(obj.created_at).format("YYYY/MM/DD")) <= (moment(providerEndDate).format("YYYY/MM/DD"))).slice(0, visibleAsset).map((provider, i) => {

                                        return (
                                            <Provider
                                                image={providerOne}
                                                providers={provider}
                                            />
                                        );
                                    })) :
                                    (providerResult?.slice(0, visibleAsset).map((provider, i) => {
                                        return (
                                            <Provider
                                                image={providerOne}
                                                providers={provider}
                                            />
                                        )
                                    }))
                            }


                        </div>)}


                    {!providerResult?.length || providerResult?.length <= visibleAsset ? ('') : (
                        <div className="w-full flex items-center justify-center my-20">
                            <button
                                onClick={showMore}

                                className='border border-theme-color theme-color font-semibold p-3 text-sm rounded-lg w-36'>Load More</button>

                        </div>
                    )}
                </div>

                <Subheader text='Admin Request' />
                <div className='mb-40 hidden md:block '>
                    <div id='providers' className='xl:px-64 lg:px-32 md:px-16 mt-20 hidden md:block date-pick'>
                        <div className="">
                            <div className="flex justify-between ">
                                <form className='flex w-96'>
                                    <input
                                        onChange={handleProviderActionSearch}
                                        value={providerValue}

                                        type="text" className='focus:outline-none mr-2 border w-60 rounded-md border-white text-white  bg-transparent p-2' placeholder="Provider's ID" />
                                    <button type="button" className='w-28 bg-amber-500 font-bold rounded-md'>Search</button>
                                </form>

                                <div className="p-2 px-3 border items-center justify-between  w-64 text-white rounded-md border-white flex">

                                    <>
                                        <DatePicker
                                            selected={providerStartDate}
                                            onChange={(date) => {
                                                setProviderStartDate(date)

                                            }}
                                            selectsStart
                                            startDate={providerStartDate}
                                            endDate={providerEndDate}
                                            open={providerFilterOpen}

                                        />
                                        <DatePicker
                                            selected={providerEndDate}
                                            onChange={(date) => setProviderEndDate(date)}
                                            selectsEnd
                                            startDate={providerStartDate}
                                            endDate={providerEndDate}
                                            minDate={providerStartDate}
                                            open={providerFilterTwo}

                                        />
                                    </>


                                    <span
                                        onClick={() => {
                                            setProviderFilterOpen((providerFilterOpen) => !providerFilterOpen)
                                            setProviderFilterOpenTwo(false)

                                        }}

                                        className="text-sm">

                                        {providerStartDate ?
                                            moment(providerStartDate).format("DD/MM/YYYY") : '2 March 2022'
                                        }
                                    </span>
                                    <span className="text-sm"> - </span>
                                    <span
                                        onClick={() => {
                                            setProviderFilterOpenTwo((providerFilterOpen) => !providerFilterOpen)
                                            setProviderFilterOpen(false)
                                        }}
                                        className="text-sm">
                                        {providerEndDate ?
                                            moment(providerEndDate).format("DD/MM/YYYY") : ' 2 April 2022'
                                        }

                                    </span>

                                    <img src={one} alt="" className='w-5' />
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="flex xl:px-64 lg:px-32 md:px-16">
                        <div className="w-1/2">

                            <div className="flex justify-between">
                                <div className='flex justify-between mt-12'>
                                    <div className="flex items-center mr-6">
                                        <button className='w-6 h-6 border-2 rounded-full border-white focus:bg-amber-500 mr-4'></button>
                                        <h4 className='text-white'>Actions</h4>
                                    </div>

                                    <div className="flex items-center mr-6">
                                        <button className='w-6 h-6 border-2 rounded-full focus:bg-amber-500 border-white mr-4'></button>

                                        <h4 className='text-white'>Notes</h4>
                                    </div>




                                </div>



                            </div>


                        </div>
                        <div className="w-1/2">

                        </div>


                    </div>

                    <div className="mt-12 flex justify-between xl:px-64 lg:px-32 md:px-16 ">



                        {!blockUsers?.length ? (<NoResult />) :

                            (<div className="w-6/12 flex xl:gap-6 lg:gap-4 md:gap-2 grid grid-cols-2">


                                {blockUsers?.slice(0, visible).map((blockUser, i) => {
                                    return (
                                        <Actions
                                            image={providerOne}
                                            blockUser={blockUser}
                                        />
                                    )
                                })

                                }



                            </div>)}


                        <div className=" w-4/12 flex flex-col items-end">
                            <img src={message} alt="" className="w-12 mb-2" />
                            <div className="w-full">


                                {notes?.slice(0, visible).reverse().map((note, i) => {
                                    return (
                                        <Notes
                                            key={note.id}
                                            note={note} />
                                    )
                                })
                                }




                            </div>


                        </div>
                    </div>


                    {(blockUsers || [] || notes).length === 0 ? (
                        <></>
                    ) : (
                        <main className="md:px-64 px-12">
                            <div className="w-full flex items-center justify-center my-12">
                                <button
                                    onClick={showMoreRequest}
                                    className="border border-theme-color theme-color font-semibold p-3 text-sm rounded-lg w-36"
                                >
                                    Load More
                                </button>
                            </div>
                        </main>
                    )}
                </div>





                <Subheader text='Set Pricing' />
                <div>
                    <div id='settings' className="flex flex-col md:flex-row justify-between xl:px-40 lg:px-24 md:px-16 px-12 my-8">


                        <div className="flex flex-col xl:w-4/12 lg:w-6/12 md:w-6/12">
                            <h2 className='text-white md:text-lg text-base font-bold mt-8 mb-12'>Daily Driver’s Price</h2>

                            <div className="chats  w-full daily overflow-y-scroll p-1">
                                {
                                    dailyDriverPrice.map((dailyPrice, i) => {
                                        return (
                                            <DailyDriverPrice
                                                dailyPrices={dailyPrice}
                                            />
                                        )
                                    }
                                    )}

                            </div>
                            <button
                                onClick={dailyDriverModal}

                                className='xl:w-4/12 md:w-5/12 w-7/12 bg-amber-500 px-4 p-1 text-black md:text-base text-sm font-bold rounded-md mt-6'>Add New</button>

                        </div>



                        <div className="md:w-5/12 w-full mt-16 md:mt-8">
                            <h2 className='text-white  font-bold mb-12 md:text-lg text-base'>Full-time Driving Price</h2>

                            {
                                fullTimePrice.map((fullTime, i) => {
                                    return (
                                        <FullTimePrice
                                            fullTimePrices={fullTime}
                                        />
                                    )
                                }
                                )}

                            <button
                                onClick={fullTimeModal}

                                className='bg-amber-500 px-9 p-1 text-black font-bold md:text-base text-sm rounded-md mt-6'>Add New</button>

                        </div>

                        <div className="md:hidden w-full mt-16 md:mt-0">
                            <h2 className='text-white md:text-lg text-base font-bold mb-12'>Vehicle Price Margin</h2>

                            {
                                vehicleMargin.map((vehiclemarg, i) => {
                                    return (
                                        <VehicleMargin
                                            vehiclemargin={vehiclemarg}
                                        />
                                    )
                                }
                                )}
                            <button
                                onClick={marginModal}

                                className='bg-amber-500 px-9 p-1 text-black font-bold md:text-base text-sm rounded-md mt-6'>Add New</button>


                        </div>


                        <div className="md:hidden w-full mt-16 md:mt-0">
                            <h2 className='text-white md:text-lg text-base font-bold mb-12'>Insurance Rates</h2>

                            {
                                insuranceRate.map((insuranceRate, i) => {
                                    return (
                                        <InsuranceRate
                                            insuranceRates={insuranceRate}
                                        />
                                    )
                                }
                                )}



                            <button
                                onClick={insuranceModal}

                                className='bg-amber-500 px-9 p-1 text-black font-bold md:text-base text-sm rounded-md mt-6'>Add New</button>

                        </div>
                    </div>
                </div>

                <div className='mt-28 pb-20'>
                    <div className="flex justify-between xl:px-40 lg:px-24 md:px-16 px-12 my-8">

                        <div className="w-5/12 hidden md:block">
                            <h2 className='text-white text-lg font-bold mb-12'>Insurance Rates</h2>


                            {
                                insuranceRate.map((insuranceRate, i) => {
                                    return (
                                        <InsuranceRate
                                            insuranceRates={insuranceRate}
                                        />
                                    )
                                }
                                )}



                            <button
                                onClick={insuranceModal}

                                className='bg-amber-500 px-9 p-1 text-black font-bold md:text-base text-sm rounded-md mt-6'>Add New</button>


                        </div>

                        <div className="w-5/12 hidden md:block">
                            <h2 className='text-white text-lg font-bold mb-12'>Vehicle Price Margin</h2>
                            {
                                vehicleMargin.map((vehiclemarg, i) => {
                                    return (
                                        <VehicleMargin
                                            vehiclemargin={vehiclemarg}
                                        />
                                    )
                                }
                                )}
                            <button
                                onClick={marginModal}

                                className='bg-amber-500 px-9 p-1 text-black font-bold md:text-base text-sm rounded-md mt-6'>Add New</button>



                        </div>
                    </div>




                    <div className="xl:px-40 lg:px-24 md:px-16 px-8 w-full mt-20">
                        <h2 className='text-white md:text-lg text-base font-bold mb-8'>Gift Cards</h2>

                        <div className="flex md:flex-row flex-col gap-6">
                            {
                                giftCards.map((giftC, i) => {
                                    return (
                                        <GiftCards
                                            giftss={giftC}
                                        />
                                    )
                                }
                                )}
                        </div>


                        <button
                            onClick={giftModal}

                            type="submit" className='md:w-1/5 bg-transparent   p-1 text-white border border-white font-bold rounded-md w-2/5 md:text-base text-sm text-center'>Add New</button>

                    </div>

                </div>



                <Subheader text='Customers' />

                <div id='customers' className="">
                    <div className="flex md:flex-row flex-col xl:px-40 lg:px-28 md:px-12 px-12 md:w-full">
                        <div className="xl:w-7/12  md:flex md:flex-col w-full md:border-r md:border-white pb-20 xl:pr-12 md:pr-6">
                            <form
                                onSubmit={handleSubmit}
                                className="flex w-full md:w-96 mt-20 gap-3 "
                            >
                                <input
                                    type="text"
                                    className="focus:outline-none border md:w-60 w-3/5 rounded-md border-white text-white  bg-transparent p-2"
                                    placeholder="Customer’s ID"
                                    value={customerId}
                                    onChange={(e) => setCustomerId(e.target.value)}
                                />
                                <button
                                    type="submit"
                                    className="md:w-28 w-2/5 bg-amber-500 font-bold rounded-md"
                                >
                                    Search
                                </button>
                            </form>

                            <div className="mt-24 w-full  flex  gap-12 md:flex-row flex-col ">
                                <div className="md:w-4/12 w-full">
                                    <img src={user} alt="" className="w-12 mb-4" />
                                    <h1 className="text-white font-extrabold mb-4 text-xl">
                                        {firstname} {lastname}
                                    </h1>

                                    <a href={`tel: ${phoneNumber}`}
                                        className=" bg-amber-500 font-bold text-center rounded-md p-2">
                                        Call Customer</a>
                                </div>

                                <div className=" md:w-7/12">
                                    <h2 className="text-white font-bold mb-1 text-base">
                                        Wallet
                                    </h2>
                                    <div className="md:h-48 md:w-11/12 bg-black border-theme-color mt-12 rounded-lg px-8 py-4 flex flex-col">
                                        <h4 className="text-white text-sm">Current Balance</h4>
                                        <h3 className="text-white mt-1 md:text-lg text-base font-extrabold">
                                            {firstname} {initial}.
                                        </h3>
                                        <h1 className="theme-color md:text-xl font-bold my-4 text-xl">


                                            &#8358;{Number(balance).toLocaleString('en-US', { maximumFractionDigits: 2 })}

                                        </h1>
                                        <h6 className="text-white">{username}</h6>
                                    </div>
                                    <button
                                        type="submit"
                                        onClick={handleGotoCart}

                                        className="w-11/12 bg-white mt-4 font-bold rounded-md p-2"
                                    >
                                        Go To Customer’s  Cart
                                    </button>



                                    <div className="flex md:w-72 w-full gap-2 justify-between mt-6">
                                        {/* <button
                                        type="submit"
                                        className="md:w-36 w-1/2 border border-theme-color bg-red-700 text-white font-bold rounded-md p-2"
                                    >
                                        Due
                                    </button> */}
                                        <button
                                            type="submit"
                                            onClick={handleGotoRequest}

                                            className="md:w-36 w-1/2 bg-amber-500 font-bold rounded-md p-2"
                                        >
                                            View Request
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className=" xl:w-5/12 md:w-5/12 md:mt-44 xl:pl-20 md:pl-12 pb-20">
                            <h2 className="text-white font-extrabold mb-1 text-lg">Profile </h2>

                            <form className="w-full mt-4">
                                <div className="flex my-6 w-full gap-1">
                                    <div className="flex flex-col w-1/2">
                                        <label
                                            htmlFor="first name"
                                            className="text-xs text-white font-normal"
                                        >
                                            First Name
                                        </label>
                                        <input
                                            type="text"
                                            value={firstname}
                                            className="text-sm mt-2 bg-transparent rounded-2xl border border-white p-3 focus:outline-none text-white"
                                        />
                                    </div>
                                    <div className="flex flex-col w-1/2">
                                        <label
                                            htmlFor="Last name"
                                            className="text-xs text-white font-normal"
                                        >
                                            Last Name
                                        </label>
                                        <input
                                            type="text"
                                            Value={lastname}
                                            className="text-sm mt-2 bg-transparent rounded-2xl border border-white p-3 focus:outline-none text-white"
                                        />
                                    </div>
                                </div>

                                <div className="flex flex-col">
                                    <label
                                        htmlFor="Phone Number"
                                        className="text-xs text-white font-normal"
                                    >
                                        Phone Number
                                    </label>

                                    <input
                                        type="phone"
                                        value={phoneNumber}
                                        className="text-sm mt-2 bg-transparent rounded-2xl border border-white p-3 focus:outline-none text-white"
                                    />
                                </div>

                                <div className="flex flex-col my-6">
                                    <label
                                        htmlFor="Address:"
                                        className="text-xs text-white font-normal"
                                    >
                                        Address:
                                    </label>

                                    <input
                                        type="text"
                                        value={address}
                                        className="text-sm mt-2 bg-transparent rounded-2xl border border-white p-3 focus:outline-none text-white"
                                    />
                                </div>

                                <div className="flex flex-col">
                                    <label
                                        htmlFor="Birthday date:"
                                        className="text-xs text-white font-normal"
                                    >
                                        Birthday date.
                                    </label>
                                    <div className="rounded-2xl border border-white mt-2 flex px-2">
                                        <input
                                            value={dob === null ? '' : dob}


                                            className="w-11/12 text-sm bg-transparent rounded-2xl  p-3 focus:outline-none text-white"
                                        />
                                        <img src={icon} alt="" className="w-12" />
                                    </div>
                                </div>

                                <div className="flex my-6 w-full gap-1">
                                    <div className="flex flex-col w-1/2 ">
                                        <label
                                            htmlFor="State"
                                            className="text-xs text-white font-normal"
                                        >
                                            State
                                        </label>
                                        <input
                                            type="text"
                                            value={state}
                                            className="text-sm mt-2 bg-transparent rounded-2xl border border-white p-3 focus:outline-none text-white"
                                        />
                                    </div>
                                    <div className="flex flex-col w-1/2">
                                        <label
                                            htmlFor="Country"
                                            className="text-xs text-white font-normal"
                                        >
                                            Country
                                        </label>
                                        <input
                                            type="text"
                                            value={country}
                                            className="text-sm mt-2 bg-transparent rounded-2xl border border-white p-3 focus:outline-none text-white"
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}

export default SuperAdmin;
