import React, { useState, useEffect, useMemo } from 'react'
import { BASE_URL } from "../utils/constant"

import Header from '../Header'
import icon from '../../Images/icon.svg'
import { NavLink, useNavigate } from "react-router-dom";
import Navigation from './Navigation'
import { Multiselect } from 'multiselect-react-dropdown'
import Select from 'react-select'
import countryList from 'react-select-country-list'
import eyee from '../../Images/eyee.png'
import rightTick from '../../Images/right-tick.svg'
import wrongTick from '../../Images/wrong-tick.svg'
import topArrow from "../../Images/topArrow.svg"
import bottomArrow from "../../Images/bottomArrow.svg"

import pen from '../../Images/pen.svg'
import cancel from '../../Images/cancel.svg'

import axios from "axios";
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useSelector } from 'react-redux'
import { useRef } from 'react'
import Dropdown from '../Common/Dropdown'
import { GoogleMap, useJsApiLoader, Autocomplete } from '@react-google-maps/api';
import useAnalyticsEventTracker from '../Common/useAnalyticsEventTracker';
import { driverRegisterEvent, driverRegisterPage1 } from '../Common/facebookPixelEvent';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import getCookie from '../utils/SecuredTokens';
// import MultiSelect from '../Common/MultiSelect'




function DriverRegisteration() {

    const gaEventTracker = useAnalyticsEventTracker('Driver Registration 1')
    const { } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyDkMuW-hGvdRfUhsb2R9RFqA0Pq4Qxg4Cs",
        libraries: ['places'],
    })

    const [autocomplete, setAutocomplete] = useState(null)
    const [addressAuto, setAddressAuto] = useState('')

    const onPlaceChanged = () => {
        if(autocomplete !== null) {
            const places = autocomplete.getPlace().formatted_address
            if(places) {
                setAddressAuto(places)
            }else {
                setAddressAuto(autocomplete.getPlace().name)
            }
        }
    }

    const [addressError, setAddressError] = useState('')


    let navigate = useNavigate();
    const [load, setLoad] = useState("")


    const [selectedFile, setSelectedFile] = useState();

	const [isSelected, setIsSelected] = useState(false);

    const [license, setLicense] = useState()
    const [businessCert, setBusinessCert] = useState()
    const [licenseSelected, setLicenseSelected] = useState(false)
    const [certificate, setcertificate] = useState(false)

    // Means of Identification
    const [selectedId, setSelectedId] = useState('')

    // DRIVER TYPE STATE
    const [selectedDriverType, setSelectedDriverType] = useState('')

    // Category Choice
    const [selectedCategory, setSelectedCategory] = useState('individual')
    const categoryValue = selectedCategory;

    // let token = JSON.parse(localStorage.getItem("userToken"))
    // let id = JSON.parse(localStorage.getItem("userId"))

    let userData = getCookie("user");
    let userObject = JSON.parse(userData) 
    let token = userObject?.token
    let id = JSON.parse(localStorage.getItem("userId"))
   

    let [username, setUsername] = useState('')
    const [first_name, setFirstName] = useState('')
    const [last_name, setLastName] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [showSection, setShowSection] = useState(false)
    const [showBusinessSection, setShowBusinessSection] = useState(false)
    const [showDobForm, setShowDobForm] = useState(true)
    const [showCompanyForm, setShowCompanyForm] = useState(false)
    const [showGuarantorOne, setShowGuarantorOne] = useState(false)
    const [showGuarantorTwo, setShowGuarantorTwo] = useState(false)
    const [registeredCategory, setRegisteredCategory] = useState('')


    const [address, setAddress] = useState('');
    const [identification, setIdentification] = useState('');
    const [idNumber, setIdNumber] = useState('');
    const [phone, setPhone] = useState('');
    const [category, setCategory] = useState('');
    const [birthday, setBirthday] = useState('');

    const [availability, setAvailability] = useState([]);
    const [state, setState] = useState('');
    const [country, setCountry] = useState('');
    const [work, setWork] = useState('driver');

    const [addressTick, setAddressTick] = useState(false)
    const [idTick, setIdTick] = useState(false)
    const [companyTick, setCompanyTick] = useState(false)

    const [showAvailability, setShowAvailability] = useState(true)

    // Facebook Pixel
    useEffect(() => {
        driverRegisterPage1()
    }, [])


    const fileInputRef = useRef()

    // TEMPORAL FIX

    const passportRef = useRef()
    // Country Value
    const [value, setValue] = useState([])
    const optionsCountry = useMemo(() => countryList().getData(), [])
  
    const changeHandlerCountry = value => {
      setValue(value)
    }

    // HANDLE IDCHANGE 
    const handleIdChange = (e) => {
        formikProfile.handleChange(e)

        if(e.target.value) {
            setIdTick(true)
        }else {
            setIdTick(false)
        }
    }

    // HANDLE ADDRESS CHANGE
    const handleAddressChange = (e) => {
        formikProfile.handleChange(e)

        if(e.target.value) {
            setAddressTick(true)
        }else {
            setAddressTick(false)
        }
    }

    // HANDLE COMPANY CHANGE
    const handleCompanyChange = (e) => {
        formikProfile.handleChange(e)

        if(e.target.value) {
            setCompanyTick(true)
        }else {
            setCompanyTick(false)
        }
    }

    // Checking if user selected business or individual
    useEffect(() => {
        if(selectedCategory === 'business') {
            setShowDobForm(false)
            setShowCompanyForm(true)
        }else {
            setShowDobForm(true)
            setShowCompanyForm(false)
        }
    }, [selectedCategory])

    const getUserName = async () => {
        await axios.get(`${BASE_URL}/v1/show-username/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            }
        }).then((response) => {
            const alluserName = response.data;
            const username = alluserName.username;
            const fullname = response.data.name;
            const nameSplit = fullname.split(/(\s+)/);
            const firstName = nameSplit[0]
            const phone = response.data.phone_number;
            setFirstName(firstName)
            const lastName = nameSplit[2]
            setLastName(lastName)
            setRegisteredCategory(alluserName.category)
            setPhoneNumber(phone)
            setUsername(username)
        });
    };
    useEffect(() => {
        getUserName();
    }, []);

    // HANDLE BACkGROUND PASSPORT FILE UPLOAD
    const changeHandler = (event) => {
		setSelectedFile(event.target.files[0]);
		setIsSelected(true);
	};

    // HANDLE DRIVER LICENSE FILE UPLOAD
    const changeLicense = (e) => {
        setLicense(e.target.files[0]);
        setLicenseSelected(true)
    }

    // const changeCertificate = (e) => {
    //     setBusinessCert(e.target.files[0])
    //     setcertificate(true)
    // }

    // FOR MULTISELECT
    const convertArray = (array, property) => {
        var result = []
        for(var i = 0; i<array.length; i+=1) {
            result.push(array[i][property])
        }
        setUpdateAvailability(result)
    }

    localStorage.setItem("userService", JSON.stringify(work))

    let userService1 = JSON.parse(localStorage.getItem("userService"))



    const [availabilitySelect, setAvailabilitySelect] = useState([])
    const [updateAvialablity, setUpdateAvailability] = useState([])
    useEffect(() => {
        setUpdateAvailability([])
        convertArray(availabilitySelect, 'value')
        // availabilitySelect.map((item) => {
            // setUpdateAvailability(item['value'])
            // setUpdateAvailability( updateAvialablity.push(item.value))
        // })
    }, [availabilitySelect.length])


    // AVAILABILITY SERVICE OPTIONS
    const availabilityOptionData = [
        {value: 'Night Driver', label: 'Night Driver', id: 1},
        {value: 'Day Driver', label: 'Day Driver', id: 2},
        {value: 'Full-Time Driver', label: 'Full-Time Driver', id: 3},
        {value: 'Daily Driver', label: 'Daily Driver', id: 4}
    ]

    const [optionsValue] = useState(availabilityOptionData)

    
    // ARRAY OF ID LIST
    const idArray = [
        {value: 'bvn', label: 'BVN'}, 
        {value: 'nin', label: 'NIN'},
        {value: 'passport', label: 'PASSPORT'},
        {value: 'license', label: 'LICENSE'}
    ]

    // ARRAY FOR TYPE OF DRIVER PERSONAL
    const driverTypeArray = [
        {value: 'driver', label: 'Driver Only'}, 
        {value: 'driver_vehicle', label: 'Driver & Car Owner'},
        {value: 'vehicle', label: 'Car Owner Only'},
    ]


    // ARRAY OF CATEGORY LIST
    const categoryArray = [
        {value: 'individual', label: 'Individual'},
        {value: 'business', label: 'Business'}
    ]

    // GET YEARS VALUE
    const years = require('ye-ars')
    const options = {
        initialYear: 2022,
        count: 5
    }

    const result = years(options)

    const phoneRegExp = /^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/
    
    // HANDLE DRIVERS DOCUMENT SUBMIT
    const handleDriversDocument = (e) => {
        e.preventDefault()

        setShowGuarantorOne(true)
    }

    // HANDLE SUBMIT AND FINISH
    const handleFinishSubmit = (e) => {
        e.preventDefault()

        if(work === 'driver') {
            navigate('/pending-notice')
        }else {
            navigate('/driver-car-registration')
        }
    }

    // SETTING PENDING NOTICE DIRECTION
    localStorage.setItem("pendingDirection", JSON.stringify("driver-pending"))


    // const numRegExp = "^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$"
    const formikProfile = useFormik({
        initialValues: {
            // address: '',
            id_number: '',
            dob: '',
            availability: '',
            state: '',
            company_name: '',
            firstname: '',
            lastname: '',
            phone_number: ''

        },
        validationSchema: Yup.object({
            id_number: Yup.string()
                .required("This is required"),
            dob: Yup.string(),
                // .required("This is required"),
            state: Yup.string()
                .required("This is required"),  
            company_name: Yup.string(),
                // .required("This is required")
            firstname: Yup.string()
                .required("This is required"),
            lastname: Yup.string()
                .required("This is required"),
            phone_number: Yup.string()
                .required("This is required"),
        }),
        onSubmit: (values) => {
            if(addressAuto === '') {
                setAddressError('This is required')

                setTimeout(() => {
                    setAddressError('')
                }, 5000)
            } else {
                if(registeredCategory === 'business') {

                    
                    axios({
                        method: 'post',
                        url: `${BASE_URL}/v1/add-driver-business`,
                        data: {...values, address: `${addressAuto}`, identification: `${selectedId}`, category: `${categoryValue}`, service: `${work}`, id: `${id}`, availability: `${updateAvialablity}`, country: `${value.label}`  , driver_type: `${selectedDriverType}` 
                    },
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                            "Access-Control-Allow-Origin": "*",
                        }
                    })
                    .then(function (response) {
                        driverRegisterEvent()
                        gaEventTracker('Save Driver Reg Btn')
                        const customer = response.data;
                        // TEMPORARY CASE WILL CHANGE DURING RESTRUCTURE
                        localStorage.setItem("userIdProvider", JSON.stringify(customer.data.user_id))
                        
                        localStorage.setItem("storageDriverType", JSON.stringify(selectedDriverType))

                        if(selectedDriverType === 'vehicle'){
                            localStorage.setItem("carownerId", JSON.stringify(customer.data.id))
                            localStorage.removeItem("driverId")
                            navigate('/driver-car-registration')
                        }else {
                            localStorage.setItem("driverId", JSON.stringify(customer.data.id))
                            localStorage.removeItem("carownerId")
                            navigate('/individual-account-registration-2')
                        }

                    })
                    .catch(function (error) {
                        let notify = () => { toast.error((error.response.data.message), {
                            toastClassName: 'error'
                        } ) };
                        notify()
                    });
                }else {
                    axios({
                        method: 'post',
                        url: `${BASE_URL}/v1/add-driver`,
                        data: {...values,address: `${addressAuto}`, identification: `${selectedId}`, category: `${categoryValue}`, service: `${work}`, id: `${id}`, availability: `${updateAvialablity}`, country: `${value.label}`  },
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                            "Access-Control-Allow-Origin": "*",
                        }
                    })
                    .then(function (response) {
                        driverRegisterEvent()
                        gaEventTracker('Save Driver Reg Btn')
                        const customer = response.data;
                        // TEMPORARY CASE WILL CHANGE DURING RESTRUCTURE
                        localStorage.setItem("userIdProvider", JSON.stringify(customer.data.user_id))
                        localStorage.setItem("driverId", JSON.stringify(customer.data.id))
    
                        navigate('/individual-account-registration-2')
                    })
                    .catch(function (error) {
                        let notify = () => { toast.error((error.response.data.message), {
                            toastClassName: 'error'
                        } ) };
                        notify()
                    });
                }
            }
        }
    })


    // MAKING USE OF DRIVER ID
    let driverId = JSON.parse(localStorage.getItem("driverId"))
    let businessId = JSON.parse(localStorage.getItem("businessId"))

    return <div>
        <ToastContainer />
        <Header user='provider' />

        <main className='flex md:px-12  px-12 flex-col md:flex-row driver-reg-overall '>
        <Navigation/>
           

            <div className=' h-100 md:w-8/12 w-full md:px-20 pb-20 pt-12 md:pt-36 driver-reg-wrapper'>
                <div className="flex items-center gap-[40px] w-full driver-register-header">
                    <h2 className='text-white font-bold text-2xl '>Register Your Driver  </h2>
                    {/* <p className='text-white text-base'>You are almost done</p>
                    <img src={pen} alt="" className='w-6' /> */}
                </div>

                <main className='w-full'>
                    <div className="flex w-full items-center">
                        <div className="pb-20 w-full">
                            {/* FORMIKPROFILE HANDLER */}
                            <form className='w-full mt-4' onSubmit={formikProfile.handleSubmit} validator={() => ({})}>
                                <div className="flex w-full">
                                    <div className="flex my-6 md:my-12 w-full gap-5 profile-name-container">
                                        <div className="flex flex-col w-1/2">
                                            <label htmlFor="First name" className='text-xs text-[#FFA100] font-normal'>First Name <span className="text-red-600">*</span></label>
                                            <input
                                                name='firstname'
                                                type="text"
                                                className='text-base mt-2 bg-[#1D1C1C] rounded-xl border border-white px-3 py-3 focus:outline-none text-white'
                                                placeholder='First Name'
                                                onBlur={formikProfile.handleBlur}
                                                onChange={formikProfile.handleChange}
                                                value={formikProfile.values.firstname}
                                            />
                                    {formikProfile.touched.firstname && formikProfile.errors.firstname ? (
                                        <p className="text-red-600 text-left text-xs w-full mt-1 mb-4">
                                            {formikProfile.errors.firstname}
                                        </p>
                                    ) : null}
                                        </div>
                                        <div className="flex flex-col w-1/2">
                                            <label htmlFor="Last Name" className='text-xs text-[#FFA100] font-normal'>Last Name <span className="text-red-600">*</span></label>
                                            <input
                                                name="lastname"
                                                type="text"
                                                className='text-base mt-2 bg-[#1D1C1C] rounded-xl border border-white px-3 py-3 focus:outline-none text-white' 
                                                placeholder='Last Name'
                                                onBlur={formikProfile.handleBlur}
                                                onChange={formikProfile.handleChange}
                                                value={formikProfile.values.lastname}
                                            />
                                    {formikProfile.touched.lastname && formikProfile.errors.lastname ? (
                                        <p className="text-red-600 text-left text-xs w-full mt-1 mb-4">
                                            {formikProfile.errors.lastname}
                                        </p>
                                    ) : null}
                                        </div>
                                    </div>
                                </div>


                                <div className="flex">
                                    <div className="flex flex-col flex-[100%]">
                                        <label htmlFor="Address" className='text-xs text-[#FFA100] font-normal'>Address <span className="text-red-600">*</span></label>

                                        <Autocomplete className='autcomplete-style'
                                            onPlaceChanged={onPlaceChanged}
                                            onLoad={(value) => setAutocomplete(value)}
                                        >
                                            <input
                                                name="address"
                                                type="text"
                                                placeholder='Type here.....' className='text-base mt-2 bg-[#1D1C1C] rounded-xl border border-white px-3 py-3 focus:outline-none text-white w-full'
                                                value={addressAuto}
                                                onChange={e => setAddressAuto(e.target.value)}
                                            />
                                            
                                        </Autocomplete>
                                        <p className='text-red-600'>{addressError}</p>
                                    </div>
                                </div>

                                <div className="flex">
                                    <div className="flex flex-[100%] items-center justify-between gap-5  my-6 md:my-12 identification-wrapper-driver">
                                        <div className='flex flex-col w-[50%] means-of-id-driver'>
                                            <label htmlFor="Category:" className='text-xs text-[#FFA100] font-normal'>Means of Identification <span className="text-red-600">*</span></label>

                                            <div className="text-base mt-2 bg-[#1D1C1C] w-full rounded-xl border border-white pl-5 pr-0 py-3  text-white relative">
                                                <Dropdown 
                                                    holder= 'Select ID'
                                                    optionArray= {idArray}
                                                    setSelectedOption= {setSelectedId}
                                                    selectedOption= {selectedId}
                                                />
                                            </div>                                    
                                        </div>

                                        <div className='flex flex-col w-[50%] id-no-input-driver'>
                                            <label htmlFor="ID Number " className='text-xs text-[#FFA100] font-normal'>ID Number <span className="text-red-600">*</span></label>

                                            <input
                                                name="id_number"
                                                type="text"
                                                placeholder='Type here.....' className='text-base mt-2 bg-[#1D1C1C] rounded-xl border border-white px-3 py-3 focus:outline-none text-white'
                                                onBlur={formikProfile.handleBlur}
                                                onChange={handleIdChange}
                                                value={formikProfile.values.id_number}
                                            />
                                            {formikProfile.touched.id_number && formikProfile.errors.id_number ? (
                                            <p className="text-red-600 text-left text-xs w-full mt-1 ">
                                                {formikProfile.errors.id_number}
                                            </p>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>


                                <div className="flex flex-col">
                                    <div className="flex flex-[100%] flex-col mb-6 md:mb-12 md:mb-0 ">
                                        <label htmlFor="Phone Number" className='text-xs text-[#FFA100] font-normal'>Phone Number <span className="text-red-600">*</span></label>

                                        <input
                                            name="phone_number"
                                            type="phone"
                                            placeholder='Type here.....'
                                            className='text-base mt-2 bg-[#1D1C1C] rounded-xl border border-white px-3 py-3 focus:outline-none text-white'
                                            onBlur={formikProfile.handleBlur}
                                            onChange={formikProfile.handleChange}
                                            value={formikProfile.values.phone_number}
                                        />
                                        {formikProfile.touched.phone_number && formikProfile.errors.phone_number ? (
                                        <p className="text-red-600 text-left text-xs w-full mt-1 mb-4">
                                            {formikProfile.errors.phone_number}
                                        </p>
                                    ) : null}
                                    </div>
                                </div>


                                {
                                    showDobForm &&
                                    (<div className="flex flex-col my-6 md:my-12  w-full ">
                                    <label htmlFor="Birthday date:" className='text-xs text-[#FFA100] font-normal'>Birthday date. <span className="text-red-600">*</span></label>
                                    <div className="rounded-xl border bg-[#1D1C1C] border-white mt-2 items-center flex px-2">
                                        <input
                                            name="dob"
                                            type="date"
                                            className='w-11/12 text-base rounded-xl  p-3 focus:outline-none text-white'
                                            onBlur={formikProfile.handleBlur}
                                            onChange={formikProfile.handleChange}
                                            value={formikProfile.values.dob}   
                                        />
                                        <img src={icon} alt="" className='w-10 md:w-12' />
                                    </div>
                                    {/* {formikProfile.touched.dob && formikProfile.errors.dob ? (
                                        <p className="text-red-600 text-left text-xs w-full mt-1 mb-4">
                                            {formikProfile.errors.dob}
                                        </p>
                                    ) : null} */}

                                    </div>)
                                }

                                {/* DECIDE WHAT TYPE OF DRIVER IT IS */}

                                {
                                    registeredCategory === "business" &&
                                    <div className='flex flex-col my-6 md:my-12  w-full'>
                                        <label htmlFor="Category:" className='text-xs text-[#FFA100] font-normal'>Type Of Driver <span className="text-red-600">*</span></label>

                                        <div className="text-base mt-2 bg-[#1D1C1C] w-full rounded-xl border border-white pl-5 pr-0 py-3  text-white relative">
                                            <Dropdown 
                                                holder= {'Select Driver Type'}
                                                optionArray= {driverTypeArray}
                                                setSelectedOption= {setSelectedDriverType}
                                                selectedOption= {selectedDriverType}
                                            />
                                        </div>                                    
                                    </div>
                                }


                                {/* IF SERRVICE IS VEHICLE ONLY DON'T SHOW THIS */}

                                {
                                    showAvailability && (
                                        <div className="flex flex-col my-6 md:my-12 md:mt-0 w-full">
                                            <label htmlFor="Availability:" className='text-xs text-[#FFA100] font-normal pb-2'>Availability <span className="text-red-600">*</span></label>
        
                                            {/* <div className="text-base mt-2 bg-transparent w-full rounded-xl border border-white pl-5 pr-0 py-3  text-white"> */}
        
                                                <Multiselect 
                                                    customCloseIcon={<img src={cancel} className="cursor-pointer"/>}
                                                    options={optionsValue} 
                                                    displayValue="label" 
                                                    onRemove={e => (setAvailabilitySelect(e))}
                                                    onSelect={e => (setAvailabilitySelect(e))}
        
                                                    placeholder="Tick all the ones that apply to you"
                                                    hidePlaceholder={true}
                                                    style={{
                                                    chips: {
                                                        background: '#fff',
                                                        color: '#000'
                                                    },
                                                    multiselectContainer: {
                                                        color: '#000',
                                                        cursor: 'pointer',
                                                        background: '#1D1C1C'
                                                    },
                                                    searchBox: {
                                                        border: 'solid 1px #fff',
                                                        'border-radius': '13px',
                                                        'padding': '10px'
                                                    },
                                                    closeIcon: {
                                                        'filter': 'grayscale(100%)'
                                                    }
                                                    }}
                                                />
                                            {/* </div> */}
                                        </div>
                                    )
                                }

                                {/* IF BUSINESS IS CHOSEN SHOW THIS COMPANY INPUT */}

                                {
                                    showCompanyForm && 
                                    (
                                    <div className="flex">
                                        <div className="flex flex-[100%] flex-col">
                                            <label htmlFor="Address" className='text-xs text-[#FFA100] font-normal'>Company Name <span className="text-red-600">*</span></label>
        
                                            <input
                                                name="company_name"
                                                type="text"
                                                placeholder='Type here.....' className='text-base mt-2 mb-4 bg-[#1D1C1C] rounded-xl border border-white px-3 py-3 focus:outline-none text-white'
                                                onBlur={formikProfile.handleBlur}
                                                onChange={handleCompanyChange}
                                                value={formikProfile.values.company_name}
                                            />
                                            {/* {formikProfile.touched.company_name && formikProfile.errors.company_name ? (
                                                <p className="text-red-600 text-left text-xs w-full mt-1 mb-4">
                                                    {formikProfile.errors.company_name}
                                                </p>
                                            ) : null} */}
                                        </div>
                                        
                                    </div>
                                    )
                                }


                                <div className="flex flex-[100%] my-6 md:my-12 w-full gap-5">
                                    <div className="flex flex-col w-1/2">
                                        <label htmlFor="State" className='text-xs text-[#FFA100] font-normal'>State <span className="text-red-600">*</span></label>
                                        <input
                                            name="state"
                                            type="text"
                                            placeholder='Type here.....'
                                            className='text-base mt-2 bg-[#1D1C1C] rounded-xl border border-white px-3 py-3 focus:outline-none text-white'
                                            onBlur={formikProfile.handleBlur}
                                            onChange={formikProfile.handleChange}
                                            value={formikProfile.values.state}
                                        />
                                    {formikProfile.touched.state && formikProfile.errors.state ? (
                                        <p className="text-red-600 text-left text-xs w-full mt-1 mb-4">
                                            {formikProfile.errors.state}
                                        </p>
                                    ) : null}
                                    </div>
                                    <div className="flex flex-col w-1/2">
                                        <label htmlFor="Country" className='text-xs text-[#FFA100] font-normal'>Country <span className="text-red-600">*</span></label>

                                        <Select options={optionsCountry} value={value} onChange={changeHandlerCountry} />
                                    </div>  
                                </div>

                                <div className="flex gap-2 mt-10 w-full">
                                    <button
                                        // onClick={handleSubmit}
                                        type='submit'
                                        className='bg-white font-semibold text-black p-2 rounded-md w-1/4'>Save
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </main>
            </div>
        </main>
    </div>;
}

export default DriverRegisteration;