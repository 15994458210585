import axios from 'axios'
import { useState } from 'react';
import { BASE_URL } from "./Components/utils/constant"
import getCookie from './Components/utils/SecuredTokens';


    let user = getCookie("user");
    let userObject;
    user? userObject = JSON.parse(user):userObject=''
       
export default axios.create({
    baseURL: `${BASE_URL}/v1`,
    headers:{
        "Content-type": "application/json",
        Authorization: 'Bearer ' + userObject?.token || null
    }
})