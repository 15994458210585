import React, {Fragment, useState, useEffect, useRef } from 'react'

import locate from '../Images/locate.svg'
import message from '../Images/message.svg'
import one from '../Images/one.png'
import rating from '../Images/ratings-img.svg'
import downloadBtn from '../Images/download-btn.svg'
import { Menu, Dialog, Transition } from "@headlessui/react";
import cancelImg from '../Images/cancel.svg'
import { BASE_URL } from "./utils/constant"

import header from '../Images/header.jpg'
import icon from '../Images/pdficon.png'
import wallet from '../Images/wallets.png'

import clock from '../Images/clock.svg'
import axios from 'axios'
import jsPDF from 'jspdf'
import moment from 'moment';
import getCookie from './utils/SecuredTokens'



function Requests(props) {
  
    let userr = getCookie("user");
    let userObject = JSON.parse(userr)
    let token = userObject?.token

    const { image,
        date,
        vehicleId,
        id,
        email,
        billingRef,
        vat,    
        insurance,
        location,
        provider,
        amount,
        category,
        booking_title,
        destination,
        num_of_day,
        booking_id,
        pickup_time,
        payment_status,
        pickup_location,
        bookValueStatus,
        user_id,
        bookPicture,
        destinationState,
        driverId,
        driverType,
        customerMail,
        insurancePrice,
        bookDate,
    } = props

    const [confirm, setConfirm] = useState(false);
    const [confirmation, setConfirmation] = useState('');
    const [bookStatus, setBookStatus] = useState('')

    const [bg, setBg] = useState(false)
    const [rejectRequestModal, setRejectRequestModal] = useState(false)
    const [cancelRequestModal, setCancelRequestModal] = useState(false)
    const [dueResponse, setDueResponse] = useState('')

    const [reject, setReject] = useState(false);

    let amountNum = Number(amount);
    const noOfDay = Number(num_of_day)
    const insurePrice = Number(insurance)
    const vatPrice = Number(vat)

    // REQUIREMENTS FOR GENERATING PDF
    let [headerBlob, setHeaderBlob] = useState(header)
    let [iconBlob, setIconBlob] = useState(icon)
    let [imageBlob, setImageBlob] = useState(header)
    let [walletBlob, setWalletBlob] = useState(wallet)

    const [receivedArray, setReceivedArray] = useState([])


    const StarReview = ({rating}) => {
        var starComponents = []
    
        // Full star is whatever the rating will be having in mind we have a max of 5 stars
        var fullStar = Math.floor(+rating)
    
        // No star is whatever is left after fullstar, i.e max number of stars minus rating gives us no star
        var noStar = Math.floor(5 - +rating)
    
        // Half star- when you take into context the full star and no star values and you subtract htem from tha max no of stars you get the half star
        var halfStar = 5 - fullStar - noStar
    
        // full star
        for (var i = 0; i < fullStar; i++) {
          starComponents.push(
            <img
              src="https://img.icons8.com/fluency/344/filled-star.png"
              className="starRating__styled"
              alt=""
            />
          )
        }
    
        //half star
        for (var i = 0; i < halfStar; i++) {
          starComponents.push(
            <img
              src="https://img.icons8.com/color/344/star-half-empty.png"
              className="starRating__styled"
              alt=""
            />
          )
        }
    
        //no star
        for (var i = 0; i < noStar; i++) {
          starComponents.push(
            <img
              src="https://img.icons8.com/color/344/star--v1.png"
              className="starRating__styled"
              alt=""
            />
          )
        }
    
        return (
          <div>
            {starComponents}
            {/* <p>{rating}</p> */}
          </div>
        )
    
    }

    // GET RATINGS
    const getRatings = () => {
        axios.get(`${BASE_URL}/v1/fetch-vehicle-rating/${vehicleId}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            }
        }).then((response) => {
            setReceivedArray(response.data.data[0])
        }).catch((error) => {
        })
    }

    useEffect(() => {
        getRatings()
    }, [])

    // convert the header image to blob coz you need to convert image to blob before you use them on jsPDF

    const getImageBlobUrl = async () => {
        const response = await fetch(header);
        const blob = await response.blob();
        const bUrl = URL.createObjectURL(blob);
        setImageBlob((blobUrls) => {
            let newBlobUrls = [blobUrls];
            newBlobUrls = bUrl;
            // return newBlobUrls;
            setHeaderBlob(newBlobUrls)
        });
    };

    useEffect(() => {
        getImageBlobUrl()
    }, []);

    const getIconBlobUrl = async () => {
        const response = await fetch(icon);
        const blob = await response.blob();
        const bUrl = URL.createObjectURL(blob);
        setIconBlob((blobUrls) => {
            let newBlobUrls = [blobUrls];
            newBlobUrls = bUrl;
            // return newBlobUrls;
            setIconBlob(newBlobUrls)
        });
    };

    useEffect(() => {
        getIconBlobUrl()
    }, []);


    const getDueDate = () => {
        axios({
            method: 'get',
            url: `${BASE_URL}/v1/booking-due-date/${id}`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            }
        })
        .then(function (response) {
            //handle success
            setDueResponse(response.data.data)
        }).catch((err) => {
        }) 
    }

    useEffect(() => {
        getDueDate()
    }, [])

    const getVehicleCalendar = () => {
        axios({
            method: 'get',
            url: `${BASE_URL}/v1/fetch-vehicle-rating/${vehicleId}`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            }
        })
        .then(function (response) {
            //handle success
        }).catch((err) => {
        })
    }

    useEffect(() => {
        getVehicleCalendar()
    }, [])

    const getRequestStatus = () => {
        axios({
            method: 'get',
            url: `${BASE_URL}/v1/request-book-status/${id}`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            }
        })
        .then(function (response) {
            //handle success
            setBookStatus(response.data.data)
        }).catch((err) => {
        })
    }

    useEffect(() => {
        getRequestStatus()
    }, [confirm])


    // HANLDE REJECT FUNCTION
    function handleReject() {
        let formData = new FormData();
        formData.append('book_status', 'Rejected')
        formData.append('id', id)

        axios({
            method: 'post',
            url: `${BASE_URL}/v1/confirm-request`,
            data: formData,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            }
        })
        .then(function (response) {
            //handle success
            setConfirm(false)
            setBg(false)
            setRejectRequestModal(false)
            setCancelRequestModal(false)
            getRequestStatus()
        }).catch((err) => { 
            alert(err.response.data.message)    
        })
    }

    // CONFIRM REQUEST FUNCTION
    const confirmButton = async () => {
        let formData = new FormData();

        formData.append('id', id)
        formData.append('book_status', 'Pending')

        await axios({
            method: "post",
            url: `${BASE_URL}/v1/confirm-request`,
            data: formData,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            }
        })
            .then((response) => {
                let confirmatn = response.data.message.book_status;
                setConfirm(true)
                setConfirmation(confirmatn);
            })
            .catch((error) => {
                alert(error.response.data.message)
            });
    };



    // CONFIRM REQUEST AGAIN
    function handleConfirm() {
        let formData = new FormData();
        formData.append('book_status', 'Pending')
        formData.append('id', id)

        axios({
            method: 'post',
            url: `${BASE_URL}/v1/confirm-request`,
            data: formData,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            }
        })
        .then(function (response) {
            //handle success
            let confirmatn = response.data.message.book_status;         
            setConfirm((true))
            setConfirmation(confirmatn)
            getRequestStatus()
        }).catch((err) => {
            alert(err.response.message.data)
        })
    }

    // HANDLE REJECTMODAL
    const handleRejectModal = () => {
        setBg(true)
        setRejectRequestModal(true)
    }

    const handleCancelModal = () => {
        setBg(true)
        setCancelRequestModal(true)
    }

    const closeModal = () => {
        setBg(false)
        setRejectRequestModal(false)
        setCancelRequestModal(false)
    }


    // GENERATE PDF
    const generatePDF = (e) => {
        e.preventDefault()

        // console.log('generate pdf')

        let doc = new jsPDF("p", "mm", "a4");
        let width = doc.internal.pageSize.getWidth();
        let height = doc.internal.pageSize.getHeight();
        let date = new Date()

        //the receipt header image
        doc.addImage(headerBlob, 'JPEG', 0, 0, width, 40)

        //the reciept For header
        doc.text(17, 60, 'RECEIPT FOR')


        // doc.addFont('helvetica', 'normal')

        // the receipt header content
        doc.setFontSize(11)

        doc.text(17, 80, `${booking_title}`) //start from indent 17 from the left and add 80mm to bottom
        doc.text(80, 80, 'Booking ID') //start from indent 80 from the left and add 80mm to bottom
        doc.text(130, 80, `: ${booking_id}`)

        doc.text(17, 90, `${email}`)
        doc.text(80, 90, 'Wallet Number')
        doc.text(130, 90, `: ${id}`)


        doc.text(17, 100, `${billingRef}`)
        doc.text(80, 100, `Date`)
        doc.text(130, 100, `: ${moment(date).format('LL')}`)


        // the receipt main content
        // doc.setFontSize(11)
        // doc.rect(0, 129, width, 10, 'F')
        // doc.setTextColor(255, 255, 255)
        // doc.text(17, 135, 'NO.')
        // doc.text(40, 135, 'DESCRIPTION')
        // doc.text(100, 135, 'PRICE')
        // doc.text(130, 135, 'PERIOD')
        // doc.text(160, 135, 'TOTAL')

        // doc.setTextColor(0, 0, 0)
        // doc.setFontSize(10)


        // doc.text(17, 147, '1')
        // doc.text(40, 147, destination)
        // doc.text(100, 147, `${amountNum.toLocaleString('en-US', { maximumFractionDigits: 2 })}`)
        // doc.text(130, 147, `${num_of_day} days`)
        // doc.text(160, 147, `${(amountNum * noOfDay).toLocaleString('en-US', { maximumFractionDigits: 2 })}`)


        // doc.text(100, 160, `Subtotal          : `)
        // doc.text(160, 160, `${amountNum * noOfDay.toLocaleString('en-US', { maximumFractionDigits: 2 })}`)


        // doc.text(100, 168, 'Insurance          :')
        // doc.text(160, 168, insurance)

        // doc.text(100, 176, '7.5% VAT          :')
        // doc.text(160, 176, `${vat.toLocaleString('en-US', { maximumFractionDigits: 2 })}`)

      
        // // doc.setTextColor(255, 255, 255)
        // // doc.text(17, 135, 'NO.')
        // // doc.text(40, 135, 'DESCRIPTION')
        // // doc.text(100, 135, 'PRICE')
        // // doc.text(130, 135, 'PERIOD')
        // // doc.text(160, 135, 'TOTAL')

        // doc.setFontSize(10)
        // doc.setDrawColor(234, 150, 3)
        // doc.setFillColor(234, 150, 3)
        // doc.rect(95, 190, width, 12,'F')

        // doc.text(100, 197, 'Total          :')
        // doc.text(160, 197, `${(amountNum - insurePrice - vatPrice).toLocaleString('en-US', { maximumFractionDigits: 2 })}`)





        // doc.addImage(walletBlob, 'JPEG', 12, 240, 30, 35)
        // doc.setFontSize(8)

        // doc.text(43, 260, 'CURRENCY')

        // // doc.setFontType('italic')
        // doc.text(433, 265, 'Naira')

        // doc.setDrawColor(234, 150, 3) //gold line color
        // doc.line(0, 270, 120, 270) // horizontal line

        // doc.addImage(iconBlob, 'JPEG', 17, 280, 6, 6)
        // doc.setFontSize(9)
        // doc.text(27, 284, 'EMAIL')
        // doc.text(27, 289, 'admin@roadlers.com')


        doc.save('Roadlers.pdf')
    }

    


    return (
        <div>
            {/* HANDLE REJECT REQUEST MODAL */}
            <div style={{position: "absolute", zIndex: "999"}}>
              <Transition appear show={rejectRequestModal} as={Fragment}>
                  <Dialog
                      as="div"
                      className="fixed px-6 inset-0 z-[1000] overflow-y-auto"
                      onClose={closeModal}
                  >
                      <div className="min-h-screen px-4 text-center">
                          <Transition.Child
                              as={Fragment}
                              enter="ease-out duration-300"
                              enterFrom="opacity-0"
                              enterTo="opacity-25 bg-white"
                              leave="ease-in duration-200"
                              leaveFrom="opacity-25"
                              leaveTo="opacity-0"
                          >
                              <Dialog.Overlay className="fixed inset-0" />
                          </Transition.Child>

                          {/* This element is to trick the browser into centering the modal contents. */}
                          <span
                              className="inline-block h-screen align-middle"
                              aria-hidden="true"
                          >
                              &#8203;
                          </span>
                          <Transition.Child
                              as={Fragment}
                              enter="ease-out duration-300"
                              enterFrom="opacity-0 scale-95"
                              enterTo="opacity-100 scale-100"
                              leave="ease-in duration-200"
                              leaveFrom="opacity-100 scale-100"
                              leaveTo="opacity-0 scale-95"
                          >
                              <div className="notification-modal-wrapper inline-block w-full max-w-md pt-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-transparent shadow-xl rounded-2xl">
                                  <div className="bg-white rounded-2xl p-6">
                                      <div className="header flex justify-between mb-6">
                                          <div className=''>
                                              <h4 className='text-[#000] font-bold text-2xl'>Rejection Notice </h4>
                                          </div>

                                          <button className=''>
                                              <img src={cancelImg} className='w-[30px]' onClick={closeModal} alt="Cancel Modal" />
                                          </button>
                                      </div>

                                      <div className="notification-lists-container h-[200px]">
                                            <p className='text-xl'>
                                            By rejecting a request your ranking on the platform drops, and the request goes to another service provider, it can’t be retrieved.
                                            </p>

                                            <div className="btn-container w-full flex justify-center mt-4 ">
                                                <button className='bg-amber-600 rounded-xl flex font-bold text-base justify-center p-3 px-6' onClick={handleReject}>
                                                    Reject Now
                                                </button>
                                            </div>
                                      </div>
                                  </div>
                              </div>
                          </Transition.Child>
                      </div>
                  </Dialog>
              </Transition>
            </div>

            {/* HANDLE CANCEL REQUEST MODAL */}
            <div sttle={{position: "absolute", zIndex: "999"}}>
              <Transition appear show={cancelRequestModal} as={Fragment}>
                  <Dialog
                      as="div"
                      className="fixed px-6 inset-0 z-[1000] overflow-y-auto"
                      onClose={closeModal}
                  >
                      <div className="min-h-screen px-4 text-center">
                          <Transition.Child
                              as={Fragment}
                              enter="ease-out duration-300"
                              enterFrom="opacity-0"
                              enterTo="opacity-25 bg-white"
                              leave="ease-in duration-200"
                              leaveFrom="opacity-25"
                              leaveTo="opacity-0"
                          >
                              <Dialog.Overlay className="fixed inset-0" />
                          </Transition.Child>

                          {/* This element is to trick the browser into centering the modal contents. */}
                          <span
                              className="inline-block h-screen align-middle"
                              aria-hidden="true"
                          >
                              &#8203;
                          </span>
                          <Transition.Child
                              as={Fragment}
                              enter="ease-out duration-300"
                              enterFrom="opacity-0 scale-95"
                              enterTo="opacity-100 scale-100"
                              leave="ease-in duration-200"
                              leaveFrom="opacity-100 scale-100"
                              leaveTo="opacity-0 scale-95"
                          >
                              <div className="notification-modal-wrapper inline-block w-full max-w-md pt-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-transparent shadow-xl rounded-2xl">
                                  <div className="bg-white rounded-2xl p-6">
                                      <div className="header flex justify-between mb-6">
                                          <div className=''>
                                              <h4 className='text-[#000] font-bold text-2xl'>Cancel Notice  </h4>
                                          </div>

                                          <button className=''>
                                              <img src={cancelImg} className='w-[30px]' onClick={closeModal} alt="Cancel Modal" />
                                          </button>
                                      </div>

                                      <div className="notification-lists-container h-[200px]">
                                            <p className='text-xl'>
                                            By canceling a request your ranking on the platform drops, and the request goes to another service provider, it can’t be retrieved.
                                            </p>

                                            <div className="btn-container w-full flex justify-center mt-4 ">
                                                <button className='bg-amber-600 rounded-xl flex font-bold text-base justify-center p-3 px-6' onClick={handleReject}>
                                                    Cancel Now
                                                </button>
                                            </div>
                                      </div>
                                  </div>
                              </div>
                          </Transition.Child>
                      </div>
                  </Dialog>
              </Transition>
            </div>

            <div className="flex md:hidden border rounded-lg p-5 gap-3 mb-12 mt-12">
                <div className="flex flex-col gap-4 w-7/12">
                    {category ? (
                        <h4 className='text-white text-xs font-semibold  w-full'>{booking_title}</h4>

                    ) : (
                        <h4 className='text-white text-xs font-semibold  w-full'>{category}</h4>
                    )

                    }
                    <hr className="border-t border-gray-400 w-4/5" />
                    <img src={`https://api.roadlers.com/storage/${image}`} alt="driverOne" className='w-24 rounded-xl object-center object-contain' />
                    <div className="flex items-start mb-4 -ml-2 mt-2">
                        <img src={locate} alt="" className='w-6 ' />
                        <p className='text-white text-xx w-full leading-5'>{pickup_location}</p>
                    </div>
                    <div className="flex items-center w-full">
                        <img src={message} alt="" className='w-6 -ml-1 ' />

                        <p className=' font-semibold theme-color text-xxx w-full'>ID: {booking_id}</p>

                    </div>
                </div>
                <div className="flex flex-col w-5/12 gap-2">
                    {provider ? (<div className="text-xs bg-transparent rounded-md border border-white w-full text-center py-1 focus:outline-none text-white">
                    {num_of_day} {'Days'} 
                    </div>) : (<select className='text-xs bg-transparent rounded-md border border-white w-full text-center py-1 focus:outline-none text-white'>
                        <option className='text-black font-semibold'>3 Days</option>
                        <option className=' text-black font-semibold'>3 Days</option>
                        <option className=' text-black font-semibold'>3 Days</option>


                    </select>)}



                    <div className="flex items-center gap-2">
                        <img src={one} alt="" className='w-4' />
                        <h4 className='text-white font-normal text-xxx'>{date}</h4>
                    </div>

                    <div className="flex items-center gap-2">
                        <img src={clock} alt="" className='-ml-1 w-6' />
                        <h4 className='text-white font-normal text-xx'>{pickup_time}</h4>
                    </div>

                    <h4 className='text-white font-normal text-xx'>{destination}</h4>
                    <div className="flex flex-col items-center justify-start  w-full">


                        <h5 className='font-bold text-amber-500 text-xs'>&#8358;{amountNum.toLocaleString('en-US', { maximumFractionDigits: 2 })}</h5>

                    </div>


                    {
                        bookStatus === 'Rejected'? 
                        <>
                            <div className="flex flex-col gap-2  items-center w-full mt-6">
                                <div
                                    className='bg-white w-full border text-center font-bold border-white text-[#000] p-1 py-2 text-sm rounded-lg'>
                                    Rejected
                                </div>
            
                                {/* <h4 className='text-amber-500 font-semibold'>Due in 4 days</h4> */}
                                        
                            </div>
                        </> :
                        (
                            bookStatus === 'Completed' ? 
                            <>
                                {/* COMPLETED */}
                                <div className="flex flex-col gap-2  items-center w-full mt-6">
                                    <div
                                        className='w-full bg-[#06AE2B] font-bold text-center text-white p-2 text-sm rounded-lg mt-3'>
                                        Completed
                                    </div>
        
                                    <div className="completed-container_extra">
                                        <div className="rating-wrapper">
                                            <p className="cardRate__styled flex"><StarReview rating={receivedArray?.star_rating}/></p>
                                        </div>
        
                                        <button onClick={generatePDF}>
                                            <img src={downloadBtn} alt="download btn" />
                                        </button>
                                    </div>
                                    
                                </div>
                            </> :
                            (
                                bookStatus === 'In Progress' ? 
                                <>
                                    {/* IN PROGRESS */}
                                    <div className="flex flex-col gap-2  items-center w-full mt-6">
                                        <div
                                            className='w-full bg-amber-500 font-bold text-center text-[#000] p-2 text-sm rounded-lg mt-3'>
                                            In Progress
                                        </div>
                                        {
                                        <h4 className='text-amber-500 font-semibold'>Due Today</h4>
                                        }
                                    </div> 
                                </> :
                                (bookStatus==='Pending' ? (
                                    <div className="flex flex-col gap-2  items-center w-full mt-6">
                                        <button
                                            onClick={handleCancelModal}
                                            className='bg-transparent w-full border font-bold border-white text-white p-1 py-2 text-sm rounded-lg'>
                                            Cancel
                                        </button>
            
            
                                        <button
                                            className='w-full bg-blue-500 font-bold text-black p-2 text-sm rounded-lg mt-3'>
                                            Pending
                                        </button>
            
                                        { dueResponse !== 'Due' &&
                                            <h4 className='text-amber-500 font-semibold'>Due in {dueResponse}</h4>
                                        }
                                    </div>
                                    ) : (
                                        (dueResponse !== 'Due' && ( bookValueStatus !== 'Abandoned' || bookValueStatus !== 'Rejected')) ?
                                    
                                        <div className="flex flex-col gap-2  items-center w-full mt-6">
                                            <button
                                                onClick={handleRejectModal}
                
                                                className='bg-transparent w-full border font-bold border-white text-white p-1 py-2 text-sm rounded-lg'>
                                                Reject
                                            </button>
                
                
                                            <button
                                                onClick={handleConfirm}
                                                className='w-full bg-[#CF0303] font-bold text-white p-2 text-sm rounded-lg mt-3'>
                                                Confirm
                                            </button>
                
                                            { dueResponse !== 'Due' &&
                                                <h4 className='text-amber-500 font-semibold'>Due in {dueResponse}</h4>
                                            }
                                            
                                        </div>
                                        :
                                        <div className="flex flex-col gap-2  items-center w-full mt-6">
                                            
                                            <div
                                                className='bg-white w-full border text-center font-bold border-white text-[#000] p-1 py-2 text-sm rounded-lg'>
                                                Abandoned
                                            </div>  
                                        </div>


                                    )
                                )
                            )
                        )
                    }
                </div>
            </div>


            {/* DESKTOP VIEW */}
            <div className="hidden md:flex md:border bg-[#1D1C1C] rounded-lg p-8 gap-7 mb-12 mt-12">

                <div className="w-1/5 rounded-xl bg-white ">
                    <img src={`https://api.roadlers.com/storage/${image}`} alt="driverOne" className='w-full h-full rounded-xl object-center object-contain' />
                </div>


                <div className="flex flex-col w-1/5 gap-2">
                    {category ? (
                        <h4 className='text-white text-[16px] font-semibold border-b border-white pb-1 w-3/4'>{booking_title}</h4>

                    ) : (
                        <h4 className='text-white text-[16px] font-semibold border-b border-white pb-1 w-3/4'>{category}</h4>
                    )

                    }

                    <div className="flex items-start mb-4 -ml-2 mt-2">
                        <img src={locate} alt="" className='w-6 ' />

                        <p className='text-white text-xs w-3/4 leading-5'>{pickup_location}</p>
                    </div>

                    <div className="flex items-center">
                        <img src={message} alt="" className='w-6 -ml-1 mr-2' />

                        <p className='mr-2 font-semibold theme-color mr-4 text-xs'>ID: {booking_id}</p>

                    </div>

                </div>

                <div className="flex flex-col gap-4 w-1/5">
                    {provider ? (
                        <div className="text-sm bg-transparent rounded-md border border-white w-3/4 text-center py-1 focus:outline-none text-white">{num_of_day} Days</div>

                    ) : (<select className='text-sm bg-transparent rounded-md border border-white w-3/4 text-center py-1 focus:outline-none text-white'>
                        <option className='theme text-black font-semibold'>3 Days</option>
                        <option className='theme text-black font-semibold'>3 Days</option>
                        <option className='theme text-black font-semibold'>3 Days</option>
                    </select>)}




                    <div className="flex items-center gap-2">
                        <img src={one} alt="" className='w-6' />
                        <h4 className='text-white font-semibold text-sm'>{date}</h4>
                    </div>

                    <div className="flex items-center gap-2">
                        <img src={clock} alt="" className='w-6' />
                        <h4 className='text-white font-semibold text-sm'>{pickup_time}</h4>
                    </div>

                    <h4 className='text-white font-normal text-sm'>{destination}</h4>


                </div>
                <div className="flex flex-col items-center justify-center gap-4  w-1/5">


                    <h5 className='font-bold text-white'>&#8358;{amountNum.toLocaleString('en-US', { maximumFractionDigits: 2 })}</h5>

                </div>

                {
                    bookStatus === 'Rejected'? 
                    <>
                        <div className="flex flex-col gap-2  items-center w-1/5">
                            <div
                                className='bg-white w-full border text-center font-bold border-white text-[#000] p-1 py-2 text-sm rounded-lg'>
                                Rejected
                            </div>
        
                            {/* <h4 className='text-amber-500 font-semibold'>Due in 4 days</h4> */}
                                    
                        </div>
                    </> :
                    (
                        bookStatus === 'Completed' ? 
                        <>
                            {/* COMPLETED */}
                            <div className="flex flex-col gap-2  items-center w-1/5">
                                <div
                                    className='w-full bg-[#06AE2B] flex justify-center font-bold text-white p-2 text-sm rounded-lg mt-3'>
                                    Completed
                                </div>
    
                                <div className="completed-container_extra">
                                    <div className="rating-wrapper">
                                            <p className="cardRate__styled flex"><StarReview rating={receivedArray ? receivedArray?.star_rating : 0 }/></p>
                                    </div>
    
                                    <button onClick={generatePDF}>
                                        <img src={downloadBtn} alt="download btn" />
                                    </button>
                                </div>
                                
                            </div>
                        </> :
                        (
                            bookStatus === 'In Progress' ? 
                            <>
                                {/* IN PROGRESS */}
                                <div className="flex flex-col gap-2  items-center w-1/5">
                                    <div
                                        className='w-full bg-amber-500 font-bold text-center text-[#000] p-2 text-sm rounded-lg mt-3'>
                                        In Progress
                                    </div>
                                    {
                                     <h4 className='text-amber-500 font-semibold'>Due Today</h4>
                                    }
                                </div> 
                            </> :
                            (bookStatus==='Pending' ? (
                                <div className="flex flex-col gap-2  items-center w-1/5">
                                    <button
                                        onClick={handleCancelModal}
                                        className='bg-transparent w-full border font-bold border-white text-white p-1 py-2 text-sm rounded-lg'>
                                        Cancel
                                    </button>
        
        
                                    <button
                                        className='w-full bg-blue-500 font-bold text-black p-2 text-sm rounded-lg mt-3'>
                                        Pending
                                    </button>
        
                                    { dueResponse !== 'Due' &&
                                        <h4 className='text-amber-500 font-semibold'>Due in {dueResponse}</h4>
                                    }
                                </div>
                                ) : (
                                    (dueResponse !== 'Due' && ( bookValueStatus !== 'Abandoned' || bookValueStatus !== 'Rejected')) ?
                                
                                    <div className="flex flex-col gap-2  items-center w-1/5">
                                        <button
                                            onClick={handleRejectModal}
            
                                            className='bg-transparent w-full border font-bold border-white text-white p-1 py-2 text-sm rounded-lg'>
                                            Reject
                                        </button>
            
            
                                        <button
                                            onClick={handleConfirm}
                                            className='w-full bg-[#CF0303] font-bold text-white p-2 text-sm rounded-lg mt-3'>
                                            Confirm
                                        </button>
            
                                        { dueResponse !== 'Due' &&
                                            <h4 className='text-amber-500 font-semibold'>Due in {dueResponse}</h4>
                                        }
                                        
                                    </div>
                                    :
                                    <div className="flex flex-col gap-2 justify-center  items-center w-1/5">
                                        
                                        <div
                                            className='bg-white w-full border text-center font-bold border-white text-[#000] p-1 py-2 text-sm rounded-lg'>
                                            Abandoned
                                        </div>  
                                    </div>


                                )
                            )
                        )
                    )
                }

            </div>

        </div>
    )
}

export default Requests