import React, { useState } from 'react'
import Header from './Header'
import google from '../Images/google.svg'
import fbb from '../Images/fbb.svg'
import user from '../Images/userg.png'
import closed from '../Images/closed.svg'
import open from '../Images/blackeye.png'
import car from '../Images/carr.png'
import userIcon from "../Images/userIcon.svg"
import { NavLink, useNavigate, useParams, useSearchParams } from "react-router-dom";
import axios from "axios";
import Spin from '../Images/spinner.gif';
import { BASE_URL } from "./utils/constant"

import { useFormik } from 'formik'
import * as Yup from 'yup'


function Resetpassword() {
    let navigate = useNavigate();
    const paramsId = useParams()
    const [params] =  useSearchParams();
    const [load, setLoad] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    const paramsMail = params.get("email")



    const [passwordShown, setPasswordShown] = useState(false);
    const togglePassword = (e) => {
        e.preventDefault()
        // When the handler is invoked
        // inverse the boolean state of passwordShown
        setPasswordShown(!passwordShown);
    };

    const handleSubmit = (e) => {
        e.preventDefault()


        const data = {
            tokenValue: paramsId.id,
            password: newPassword,
            password_confirmation: confirmPassword,
            email: paramsMail,
        }

        var passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;

        if(newPassword === '' || confirmPassword === '') {
            alert('Please type in your new password')
        }else if(!newPassword.match(passw)) {
            alert('Password must have one number, one uppercase, one lower case and must be at least 6 characters')
        }else if(newPassword !== confirmPassword) {
            alert('Confirm password wrong')
        }else {
            setLoad(Spin);

            axios({
                method: 'post',
                url: `${BASE_URL}/v1/reset-password`,
                data: data,
                     headers:{ 
                         'Content-Type': 'application/json' ,
                        //    Authorization: `Bearer ${token}`,
                           "Access-Control-Allow-Origin" : "*",
                        } 
            })
                .then(function (response) {
                    //handle success
                    // if (response.data.status && response.data.data.token) {
                    // localStorage.setItem("userToken", JSON.stringify(response.data.data.token))
                    //     console.log(response);
                    //     const messsage = response.data.message
                    //     navigate('/login')
                    // }
                    // else {
                    //     const messsage = response.data.message
                    //     setLoad('');
                    // }
    
                    console.log(response)
    
                    navigate('/login')
    
                }).catch(function (error) {
                    //handle error
                    console.log("error ", error)
                    alert(error)
                    setLoad('');
                });
        }
    }

    // const formik = useFormik({
    //     initialValues: {

    //         email: '',
    //     },
    //     validationSchema: Yup.object({

    //         email: Yup.string().email("Invalid Email Address").required("This is Required"),

    //     }),
    //     onSubmit: (values) => {
    //         let token = JSON.parse(localStorage.getItem("userToken"))
    //         console.log(values)
    //         setLoad(Spin);

    //         axios({
    //             method: 'post',
    //             url: 'https://api.roadlers.com/api/v1/password-reset-link',
    //             data: values,
    //                  headers:{ 
    //                      'Content-Type': 'application/json' ,
    //                     //    Authorization: `Bearer ${token}`,
    //                        "Access-Control-Allow-Origin" : "*",
    //                     } 
    //         })
    //             .then(function (response) {
    //                 //handle success
    //                 if (response.data.status && response.data.data.token) {
    //                 localStorage.setItem("userToken", JSON.stringify(response.data.data.token))
    //                     console.log(response);
    //                     const messsage = response.data.message
    //                     navigate('/login')
    //                 }
    //                 else {
    //                     const messsage = response.data.message
    //                     setLoad('');
    //                 }
    //             }).catch(function (error) {
    //                 //handle error
    //                 console.log("error ", error)
    //                 alert('Email/Password not correct')
    //                 setLoad('');
    //             });
    //     },
    // })

    return (
        <div>

            <Header user='customer' />
            <div className="flex justify-center md:pt-40 pt-32 flex-col items-center w-full px-12  pb-20 mb-20 relative">
                <h1 className="md:px-20  text-white font-bold md:text-3xl text-xl mb-0 md:mb-8"> Reset Password </h1>
                <div className="bg-white md:bg-white p-4 px-10 md:p-6 md:px-16 w-full  md:w-2/6 h-auto rounded-lg">
                    <div className="image-icon_wrapper">
                        <img src={userIcon} alt="UserIcon" className='w-[40px] md:w-[80px]' />
                    </div>

                    <form onSubmit={handleSubmit} className='flex flex-col items-center justify-center mt-8 relative'>
                        <div className="password-container">
                            <label > New Password <span className='text-[#FF3A3A]'>*</span></label>
                            <input
                                type="password"
                                placeholder='A minimum of 6 characters with one uppercase'
                                className='md:text-sm text-xs p-3 w-full border border-gray-500 rounded-lg my-4 placeholder:text-xs placeholder:text-gray-700'
                                onChange={e => setNewPassword(e.target.value)}
                                value={newPassword}
                            />
                        </div>

                        <div className="password-container">
                            <label > Confirm Password <span className='text-[#FF3A3A]'>*</span></label>
                            <input
                                type="password"
                                placeholder='xxxxxxxxxxxxxxxxxxxx'
                                className='md:text-sm text-xs p-3 w-full border border-gray-500 rounded-lg my-4 placeholder:text-xs placeholder:text-gray-700'
                                onChange={e => setConfirmPassword(e.target.value)}
                                value={confirmPassword}
                            />
                        </div>


                        {
                            load ? (<div className="bg-amber-500 p-1 w-full font-semibold rounded-lg my-4 flex justify-center items-center"><h4 className='text-black text-sm'>Please Wait!</h4><input type="image" src={Spin} value="Loading" className="w-10" /></div>) :
                                (<button className='bg-amber-500 p-3 w-full font-semibold rounded-lg my-4'>Change Password</button>)
                        }
                    </form>

                </div>
                <img src={car} alt="" className='absolute -bottom-4 md:right-96 md:w-56 w-44' />
            </div>

        </div>
    )
}

export default Resetpassword