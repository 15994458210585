import { createContext, useState, useEffect,useCallback } from 'react'
import axios from "axios";
import { useNavigate,useHistory } from "react-router-dom";
import Spin from "../Images/spinner.gif"
import { BASE_URL } from "../Components/utils/constant"
import getCookie from '../Components/utils/SecuredTokens';


const DataContext = createContext({})

// We need to export a provider
export const DataProvider = ({ children }) => {

    let navigate = useNavigate()

    const [load, setLoad] = useState("")

  
    let userData = getCookie("user");
    let userObject
    let token
   
    // check if userData truely exists
    userData ? userObject = JSON.parse(userData) : userObject = ''
    userObject ? token = userObject?.token : token = ''
  

    let [carSearch, setCarSearch] = useState([])


    const [assets, setAssets] = useState([]);
    const [result, setResult] = useState(assets);
    const getAssets = async ()=> {
        await axios.get(`${BASE_URL}/v1/list-vehicle`).then((response) => {
            const allVehicle = response.data.data;
            setAssets(allVehicle);
            setResult(allVehicle)
            setCarSearch(allVehicle)
        });
    }




    useEffect(() => {
        getAssets()
    }, [])


    let cart = JSON.parse(localStorage.getItem("cart"))
    let [cartLength, setCartLength] = useState(0)
    let [search, setSearch] = useState('')
    let [searchLocation, setSearchLocation] = useState('')


   




    // for search page button
    let [filterSearch, setFilterSearch] = useState('')
    let [searchFilterLocation, setFilterSearchLocation] = useState('')

    const handleFilterSearchLocation= (e)=> {
        setFilterSearch(e.target.value)
    }
    
    const handleFilterSearch= (e)=> {
        setFilterSearchLocation(e.target.value)
    }



    const handleSearchLocation = (e)=> {
        setSearch(e.target.value)
    }
    const handleSearch= (e)=> {
        setSearchLocation(e.target.value)
    }

    const handleFilter = (e)=> {
        console.log('type search')
        e.preventDefault()
        setLoad(Spin)

        let car_search = result.filter((data) => {
            // return data.vehicle_name.includes(search)
            return (
                ((data.vehicle_name.toLowerCase().includes(search.toLowerCase()) 
                || (data.vehicle_type.toLowerCase().includes(search.toLowerCase()))))
                && (data.state.toLowerCase().includes(searchLocation.toLowerCase()))
                )

;
        })




        console.log('car_search',car_search)

        setCarSearch(car_search)
        setLoad("")
        navigate('/search')

    }


    





    useEffect(() => {
        setCartLength(cart?.length)

    }, [cart])


    return <DataContext.Provider value={{

        cart,
        cartLength,
        search,
        setSearch,
        searchLocation,
        setSearchLocation,
        handleFilter,
        handleSearch,
        handleSearchLocation,
        carSearch,
        setCarSearch,
        load,
        setLoad,
     
    }}>
        {children}
    </DataContext.Provider>
}

export default DataContext