import React, { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { BASE_URL } from "../utils/constant"
import getCookie from '../utils/SecuredTokens';

import Header from '../Header'
import Subheader from '../Subheader'
import Assets from './Assets'
import { NavLink, useNavigate } from "react-router-dom";
import Navigation from './Navigation'
import CurrencyInput from 'react-currency-input-field';

import { ToastContainer, toast } from 'react-toastify';


import dashboard from '../../Images/dashboard.png'
import admin_request from '../../Images/admin_request.svg'
import service_p from '../../Images/service_p.svg'
import reports from '../../Images/reports.svg'
import customer from '../../Images/customers.png'
import notifications from '../../Images/notifications.png'
import download from '../../Images/download.svg'
import car_asset from '../../Images/car_asset.svg'
import roadlersCar from '../../Images/roadlersCar.svg'
import roadlersRR from '../../Images/RoadlersRR.svg'
import icon from '../../Images/icon.svg'
import car from '../../Images/car.png'
import message from '../../Images/message.svg'
import promotion from '../../Images/promotion.svg'

import payment from '../../Images/payment.png'
import naira from '../../Images/naira.png'
import one from '../../Images/one.png'
import eyee from '../../Images/eyee.png'
import mark from '../../Images/mark.png'
import ratings from '../../Images/ratings.svg'
import transferProvider from '../../Images/transferProvider.svg'
import success from '../../Images/success.png'
import cancelImg from '../../Images/cancel.svg'
import top from '../../Images/top.png'
import withdrawFunds from '../../Images/withdrawFunds.svg'
import padlock from '../../Images/padlock.svg'


import clock from '../../Images/clock.svg'
import prado from '../../Images/Prado.png'
import call from '../../Images/call.svg'
import driverOne from '../../Images/driver1.png'
import driverThree from '../../Images/driver3.png'
import daily_driver from '../../Images/daily_driver.png'
import asset_one from '../../Images/asset1.png'
import asset_two from '../../Images/asset2.png'
import Spin from "../../Images/spinner.gif"

import pen from '../../Images/pen.svg'
import axios from "axios";
import DatePicker from "react-datepicker";
import moment from 'moment';


import  { DateObject, Calendar } from "react-multi-date-picker";
import "react-multi-date-picker/styles/backgrounds/bg-dark.css"
import Requests from '../Requests';
import { providerDashboardPage } from '../Common/facebookPixelEvent'
import useAnalyticsEventTracker from "../Common/useAnalyticsEventTracker"
// import 'react-calendar/dist/Calendar.css';


function ServiceProviderDashboard() {
    const gaEventTracker = useAnalyticsEventTracker('Provider Dashboard Page');

    let [isOpen, setIsOpen] = useState(false)
    const [pageLoad, setPageLoad] = useState(false)

    const [value, onChange] = useState(new Date());
    

   

    let user = getCookie("user");
    let userObject = JSON.parse(user) || JSON.parse(localStorage.getItem("gogglelogin"))
    let token = userObject?.token
    // let id = userObject?.id

    let id = JSON.parse(localStorage.getItem("userId"))


    const [visible, setVisible] = useState(8);
    const [visibleList, setVisibleList] = useState(4)

    const [fiatWallet, setFiatWalletAddress] = useState('')
    const [accountRef, setAccountRef] = useState('')
    const [balance, setBalance] = useState('')
    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [username, setUsername] = useState('')
    const [initial, setInitials] = useState('')
    const [myListings, setMyListings] = useState([])
    const [vehicleRequest, setVehicleRequest] = useState([])
    const [driverRequest, setDriverRequest] = useState([])
    const [providerAssets, setProviderAssets] = useState([])
    const [driverAssets, setDriverAssets] = useState([])
    const [providerOverview, setProviderOverview] = useState([])
    const [carType, setCarType] = useState('')
    const [vehicleBookStatus, setVehicleBookStatus] = useState('')
    const [searchCar, setSearchCar] = useState(carType || vehicleBookStatus || '')
    const [findCar, setFindCar] = useState([])
    const [driverBookStatus, setDriverBookStatus] = useState('')
    const [driverType, setDriverType] = useState('')
    const [searchDriver, setSearchDriver] = useState(driverType || driverBookStatus ||'')
    const [driverpendingType, setDriverPendingType] = useState('')
    const [carPendingType, setCarPendingType] = useState('')
    const [findDriver, setFindDriver] = useState([])
    const [abandonedReq, setAbandonedReq] = useState([])
    const [providerServiceChoice, setProviderServiceChoice] = useState()

    // STATES FOR TRANSFER
    const [transferOpen, setTransferOpen] = useState(false)
    const [amount, setAmount] = useState('')
    const [credit, setCredit] = useState('')
    const [password, setPassword] = useState('')
    let [successfulOpen, setSuccessfulOpen] = useState(false)
    const [bg, setBg] = useState(false)

    // STATES FOR  BVN
    const [bvn, setBvn] = useState('')
    const [alert, setAlert] = useState(false)
    const [bvnOpen, setBvnOpen] = useState(false)

    // STATES FOR WITHDRAWAL
    const [withdrawalModalOpen, setWithdrawalModalOpen] = useState(false)
    const [withdrawalAmount, setWithdrawalAmount] = useState('')
    const [creditName, setCreditName] = useState('')
    const [withdrawalPassword, setWithdrawalPassword] = useState('')
    const [virtualAccountStatus, setVirtualAccountStatus] = useState('')
    const [virtualAccountNo, setVirtualAccountNo] = useState('')
    const [bankAccountNo, setBankAccountNo] = useState('')
    const [bankName, setBankName] = useState('')
    const [bankCode, setBankCode] = useState('')
    const [selectBankName, setSelectBankName] =  useState('')
    const [padlockOpen, setPadlockOpen] = useState(false)


    // STATES FOR DATE FILTER
    const [open, setOpen] = useState(false);
    const [firstOpen, setFirstOpen] = useState(false);

    // request date filter
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    // STATES FOR DRIVER ASSET FILTER
    const [driverFilterOpen, setDriverFilterOpen] = useState(false)
    const [driverFilterTwo, setDriverFilterTwo] = useState(false);
    const [driverStartDate, setDriverStartDate] = useState('');
    const [driverEndDate, setDriverEndDate] = useState('');

    // STATES FOR REQUEST ASSET FILTER
    const [requestFilterOpen, setRequestFilterOpen] = useState(false)
    const [requestFilterTwo, setRequestFilterTwo] = useState(false);
    const [requestStartDate, setRequestStartDate] = useState('');
    const [requestEndDate, setRequestEndDate] = useState('');

    // STATE FOR DRIVER AND VEHICLE BOOK STATUS

    const [bankNamesArray, setBankNamesArray] = useState([])
    const [ratingsArray, setRatingsArray] = useState([])

    const [overallCalendarArray, setOverallCalendarArray] = useState([])
    const [allCalendar, setAllCalendar] = useState([])

    // SERVICE DASHBOARD LOADER
    const [dashboardLoad, setDashboardLoad] = useState(true)

    const [formLoad, setFormLoad] = useState("")

    
    const allCalendarArray = []

    useEffect(() => {
        providerDashboardPage()
        gaEventTracker('Provider Dashboard Page')
    }, [])



    allCalendarArray.push(allCalendar)

        // SET UNAVAILABILITY DATES...........
        const format = "MM/DD/YYYY";
        const today = new Date()
        const tomorrow = new Date()
    
        tomorrow.setDate(tomorrow.getDate() + 1)
    
        const [dates, setDates] = useState([])
        const [dateArray, setDateArray] = useState([])
        let dateNewArray = []
      
        useEffect(() => {
            dates?.forEach((date, index) => {
                dateNewArray.push(date.format())
            })
            setDateArray(dateNewArray)
        }, [dates])

    let setSelectedStartDate = moment(startDate).format("DD/MM/YYYY")
    let setSelectedEndDate = moment(endDate).format("DD/MM/YYYY")


    tomorrow.setDate(tomorrow.getDate() + 1)
    const getToday = moment(today).format("DD/MM/YYYY")
    const getTomorrow = moment(tomorrow).format("DD/MM/YYYY")

    function handleFilterDate(date) {
        let dateTime = moment(date).format("DD/MM/YYYY")
    }


    // RATINGS SECTION
    const StarReview = ({rating}) => {
        var starComponents = []
    
        // Full star is whatever the rating will be having in mind we have a max of 5 stars
        var fullStar = Math.floor(+rating)
    
        // No star is whatever is left after fullstar, i.e max number of stars minus rating gives us no star
        var noStar = Math.floor(5 - +rating)
    
        // Half star- when you take into context the full star and no star values and you subtract htem from tha max no of stars you get the half star
        var halfStar = 5 - fullStar - noStar
    
        // full star
        for (var i = 0; i < fullStar; i++) {
          starComponents.push(
            <img
              src="https://img.icons8.com/fluency/344/filled-star.png"
              className="starRating__styled"
              alt=""
            />
          )
        }
    
        //half star
        for (var i = 0; i < halfStar; i++) {
          starComponents.push(
            <img
              src="https://img.icons8.com/color/344/star-half-empty.png"
              className="starRating__styled"
              alt=""
            />
          )
        }
    
        //no star
        for (var i = 0; i < noStar; i++) {
          starComponents.push(
            <img
              src="https://img.icons8.com/color/344/star--v1.png"
              className="starRating__styled"
              alt=""
            />
          )
        }
    
        return (
          <div>
            {starComponents}
            {/* <p>{rating}</p> */}
          </div>
        )
    
    }



    function closeModal() {
        setIsOpen(false)
        setTransferOpen(false)
        setBg(false)
        setSuccessfulOpen(false)
        setBvnOpen(false)
        setWithdrawalModalOpen(false)
    }

    function openModal() {
        setIsOpen(true)
    }

    // HANDLE TYPE OF CAR FOR FILTER
    const handleCarType = (e) => {
        setCarType(e.target.value)
        setSearchCar(e.target.value)
    }

    // HANDLE TYPE OF DRIVER
    const handleDriverType = (e) => {
        setDriverType(e.target.value)
        setSearchDriver(e.target.value)
    }

    const [bookBalance, setBookBalance] = useState ("")
    // GET BOOKED BACLANCE
    const getBookedBalance = () => {
        axios.get(`${BASE_URL}/v1/provider-booked-balance/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            }
        }).then((response) => {
            setBookBalance(response?.data.data)
        }).catch((error) => {

        })
    }

    // FETCH  BANK NAMES
    const getBankNames = () => {
        axios.get(`${BASE_URL}/v1/fetch-bank-names`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            }
        }).then((response) => {
            setBankNamesArray(response.data.data)
        }).catch((error) => {
        })
    }

    useEffect(() => {
        getBankNames()
    }, [])



    //get user wallet details
    const getWalletDetails = async () => {
        await axios.get(`${BASE_URL}/v1/wallet/get-fiat-wallet/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            }
        }).then((response) => {
            const allwalletdatas = response.data;

            const account_reference = allwalletdatas.account_reference
            const account_balanc = allwalletdatas.data.balance
            const tryBalance = Number(account_balanc)
            setVirtualAccountNo(allwalletdatas.data.virtual_account_number)

            setAccountRef(account_reference)
            setBalance(account_balanc)
        });
    };

    useEffect(() => {
        getWalletDetails();
    }, []);


    // CREATE WALLET
    const createWalletFiat = async () => {
        let formData = new FormData();
        formData.append("currency_code", 'NGN');

        // convert the formdata to JSON data
        let object = {};
        formData.forEach((value, key) => object[key] = value);
        let json = JSON.stringify(object)


        await axios({
            method: "post",
            url: `${BASE_URL}/v1/wallet/create-fiat-wallet`,
            data: json,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            }
        })
            .then((response) => {
                const respLike = response.data.success
                const fiatWalletAddress = response.data.data.fiat_wallet_address
                setFiatWalletAddress(fiatWalletAddress)
            })
            .catch((error) => {
            });
    };
    useEffect(() =>
        !fiatWallet ? createWalletFiat() : '',
    []);



    //get the provider details
    const getProviderDetails = async () => {

        await axios.get(`${BASE_URL}/v1/show-username/${id}`, {

            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            }
        }).then((response) => {
            const alluserName = response.data;
            const fullname = alluserName.name;
            const user_name = alluserName.username
            setProviderServiceChoice(alluserName?.service)
            const nameSplit = fullname.split(/(\s+)/);
            const firstName = nameSplit[0]
            setFirstname(firstName)
            const lastName = nameSplit[2]
            setLastname(lastName)
            setUsername(user_name)
            localStorage.setItem("providerService", JSON.stringify(alluserName.service))
            const initials = [];
            for (let i = 0; i < lastName.length; i++) {
                const lastname_split = lastName[i]?.split(" ");
                initials.push(lastname_split[0]);
                setInitials(initials[0]);
            }
        });
    };

    useEffect(() => {
        getProviderDetails();
    }, []);


    // GET LIST OF REQUEST MADE FROM CUSTOMERS
    const getProviderRequest = async () => {
        await axios.get(`${BASE_URL}/v1/list-request-service-provider`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            }
        }).then((response) => {
            const list = response.data.data;
            setMyListings(list)
        }).catch((error) => {
        })
    };

    // GET DRIVER REQUEST
    const getProviderDriverRequest = async () => {
        await axios.get(`${BASE_URL}/v1/driver-request`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            }
        }).then((response) => {
            const list = response.data.data;
            setDriverRequest(list)
        }).catch((error) => {
        })
    };

    // GET VEHICLE REQUEST
    const getProviderVehicleRequest = async () => {
        await axios.get(`${BASE_URL}/v1/vehicle-request`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            }
        }).then((response) => {
            const list = response.data.data;
            setVehicleRequest(list)
        }).catch((error) => {
        })
    };




    // GET ABANDONED AND REJECTED REQUEST
    const abandonedRequest = async () => {
        await axios.get(`${BASE_URL}/v1/abandon-request`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            }
        }).then((response) => {
            const list = response.data.data;
            setAbandonedReq(list)
        }).catch((error) => {
        })
    };

    // PROVIDER ASSETS
    const getProviderAssets = async () => {

        await axios.get(`${BASE_URL}/v1/vehicle-assets/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            }
        }).then((response) => {
            const providerAsset = response.data.data;
            setProviderAssets(providerAsset)
        }).catch((err) => {
        })
    };

    // GET DRIVER ASSETS
    const getDriverAssets = async () => {


        await axios.get(`${BASE_URL}/v1/driver-assets/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            }
        }).then((response) => {
            const driverAsset = response.data.data;
            setDriverAssets(driverAsset)
        }).catch((err) => {
        })
    }
    

    const getOverallCalendar = () => {
        axios.get(`${BASE_URL}/v1/view-calendar`, {

            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            }
        }).then((response) => {
            setOverallCalendarArray(response.data.data)
        }).catch((err) => {
        } )
    }

    useEffect(() => {
        getOverallCalendar()
    }, [])

    useEffect(() => {
        const getOffDays = overallCalendarArray?.map(({off_days}) => off_days?.split(',') )
        const offAllArray = [].concat.apply([], getOffDays)
        setAllCalendar(offAllArray)
    }, [overallCalendarArray])



    // REQUEST OVERVIEW
    const getProviderOverview = async () => {

        await axios.get(`${BASE_URL}/v1/request-overview/${id}`, {

            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            }
        }).then((response) => {
            const overview = response.data.data;
            setProviderOverview(overview)
            setDashboardLoad(false)
        }).catch((error) => {
            
        })
    };

    useEffect(() => {
        getProviderRequest();
        getProviderAssets();
        getDriverAssets();
        abandonedRequest();
        getProviderDriverRequest();
        getProviderVehicleRequest();
        getBookedBalance()
    }, []);

    useEffect(() => {
        getProviderOverview();
    }, [])

    function showMore(){
        setVisible((prevValue)=> prevValue + 8)
    }

    function showMoreList(){
        setVisibleList((prevValue) => prevValue + 4)
    }

    const handleDriverPending = (e) => {
        e.preventDefault()
         
        if (driverBookStatus == null) {
            setDriverBookStatus('')
            setSearchDriver('')
        }else {
            setDriverBookStatus(null)
            setSearchDriver(null)
        }
    }

    const handleDriverBooked = (e) => {
        e.preventDefault()
         
        if (driverBookStatus === "Booked") {
            setDriverBookStatus('')
            setSearchDriver('')
        }else {
            setDriverBookStatus('Booked')
            setSearchDriver('Booked')
        }
    }

    const handleDriverFree = (e) => {
        e.preventDefault()
         
        if (driverBookStatus === "Free") {
            setDriverBookStatus('')
            setSearchDriver('')
        }else {
            setDriverBookStatus('Free')
            setSearchDriver('Free')
        }
    }

    const handleVehiclePending = (e) => {
        e.preventDefault()

        if(searchCar == null){
            // setVehicleBookStatus('')
            setSearchCar('')
        }else {
            // setVehicleBookStatus(null)
            setSearchCar(null)
        }
    }

    const handleVehicleFree = (e) => {
        e.preventDefault()

        if(vehicleBookStatus === "Free"){
            setVehicleBookStatus('')
            setSearchCar('')
        }else {
            setVehicleBookStatus('Free')
            setSearchCar('Free')
        }
    }

    const handleVehicleBooked = (e) => {
        e.preventDefault()

        if(vehicleBookStatus === "Booked"){
            setVehicleBookStatus('')
            setSearchCar('')
        }else {
            setVehicleBookStatus('Booked')
            setSearchCar('Booked')
        }
    }


    


    // SEARCH CAR FUNCTION
    useEffect(() => {
        const filteredData = providerAssets?.filter((vehicle) => (vehicle?.asset_id?.toLowerCase().includes(searchCar?.toLowerCase())) 
        || (vehicle?.vehicle_type?.toLowerCase().includes(searchCar?.toLowerCase())) 
        ||
        vehicle?.book_status?.toLowerCase().includes(searchCar?.toLowerCase()) || 
        vehicle?.status?.includes(searchCar)
        );

        setFindCar(filteredData)
    }, [providerAssets, searchCar, carType, vehicleBookStatus ])


    // SEARCH DRIVER FUNCTION
    useEffect(() => {
        const filteredData = driverAssets?.filter((driver) => 
        driver?.driver_uniqid?.toLowerCase().includes(searchDriver?.toLowerCase()) ||
        driver?.availability?.toLowerCase().includes(searchDriver?.toLowerCase()) 
        ||
        driver?.book_status?.toLowerCase().includes(searchDriver?.toLowerCase()) ||
        driver?.status.includes(searchDriver)
        );

        setFindDriver(filteredData)
    }, [driverAssets, searchDriver, driverType, driverBookStatus ])

    // TRANSFER FIAT WALLET FUNCTION
    const transferFiat = async (e) => {
        e.preventDefault()

        let formData = new FormData();
        formData.append("amount", amount);
        formData.append("creditWalletUsername", credit);
        formData.append("debitWalletUsername", username);



        // convert the formdata to JSON data
        let object = {};
        formData.forEach((value, key) => object[key] = value);
        let json = JSON.stringify(object)
        axios({
            method: 'post',
            url: `${BASE_URL}/v1/wallet/transfer`,
            data: formData,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            }
        }).then(function (response) {
            //handle success
            if (response.data.status === true) {
                setSuccessfulOpen(true)
                setTransferOpen(false)
                // window.location.reload();
            }
        })
            .catch(function (error) {
                //handle error
                // setLoad('');
            });
    };


    // ONTRANSFER OPEN
    const onTransferOpen = () => {
        setBg(true)
        setTransferOpen(true)
        setBvnOpen(false)
    }

    // CREATE VIRTUAL ACCOUNT WITH BVN
    function handleCreateVirtualWallet(e) {
        e.preventDefault()
        let formData = new FormData();
        formData.append("bvn", bvn);
        formData.append("firstName", firstname)
        formData.append("lastName", lastname)
        axios({
            method: 'post',
            url: `${BASE_URL}/v1/setup-virtual-account`,
            data: formData,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            }
        }).then((response) => {
            const respData = response.data.data
            setVirtualAccountStatus(respData.status)
            // setCarSearch(respData)
            setWithdrawalModalOpen(true)
            setBg(true)
        }).catch(error => {
            let notify = () => { toast(error) };
            notify()
        })
    }

    // BVNMODAL
    function bvnModal() {
        if(virtualAccountNo) {
            setWithdrawalModalOpen(true)
            setBg(true)
        }else {
            setBvnOpen(true)
            setBg(true)
        }
    }

    useEffect(() => {
        if(virtualAccountNo) {
            setPadlockOpen(false)
        }else {
            setPadlockOpen(true)
        }
    }, [virtualAccountNo])

    const getbankUserName = () => {
        let formData = new FormData();

        formData.append("account_number", bankAccountNo )
        formData.append("bank_name", selectBankName )
        formData.append("bank_code", bankCode )
        axios({
            method: 'post',
            url: `${BASE_URL}/v1/verify-account-number`,
            data: formData,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            }
        }).then(function (response) {
            //handle success
            if (response.data.status === true) {
                setCreditName(response?.data.data.accountName)
                setCreditNameError("")
                
                // window.location.reload();
            }
        })
            .catch(function (error) {
                //handle error
                setCreditName("")
                setCreditNameError(error.response.data.message)
                setTimeout(() => {
                    setCreditNameError("")
                }, 5000)
                // let notify = () => { toast(error.response.data.message) };
                // notify()
            });  
    }

    useEffect(() => {
        if(bankName && bankAccountNo.length === 10 ) {
            getbankUserName()
        }else {
            setCreditName("")
        }
    }, [bankAccountNo, bankName])

    const [creditNameError, setCreditNameError] = useState("")
    const [bankAcctNoError, setBankAcctNoError] = useState("")
    const [bankNameError, setBankNameError] = useState("")
    const [withdrawalAmountError, setWithdrawalAmountError] = useState("")
    const [withdrawalPasswordError, setWithdrawalPasswordError] = useState("")
    const [payoutError, setPayoutError] = useState("")

    // WITHDRAW AMOUNT FUNCTION
    const withdrawFunction = (e) => {
        e.preventDefault()
        setFormLoad(Spin)
        let formData = new FormData();

        formData.append("withdrawal_amount", withdrawalAmount);
        formData.append("account_name", creditName);
        formData.append("account_number", bankAccountNo )
        formData.append("bank_name", selectBankName )
        formData.append("bank_code", bankCode )
        formData.append("provider_username", username)


        if (withdrawalAmount === "") {
            setWithdrawalAmountError("Withdrawal Amount cannot be empty")
            setTimeout(() => {
                setWithdrawalAmountError("")
            }, 5000)
        }else if (selectBankName === "") {
            setBankNameError("Bank name cannot be empty")
            setTimeout(() => {
                setBankNameError("")
            }, 5000)
        }else if (bankAccountNo === '') {
            setBankAcctNoError("Account number cannot be empty")
            setTimeout(() => {
                setBankAcctNoError("")
            }, 5000)
        }
        else if(creditName === '') {
            setCreditNameError("Account name cannot be empty")
            setTimeout(() => {
                setCreditNameError("")
            }, 5000)
        }else if (withdrawalPassword === "") {
            setWithdrawalPasswordError("Password cannot be empty")
            setTimeout(() => {
                setWithdrawalPasswordError("")
            }, 5000)
        } else {
            axios({
                method: 'post',
                url: `${BASE_URL}/v1/request-payout`,
                data: formData,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    "Access-Control-Allow-Origin": "*",
                }
            }).then(function (response) {
                //handle success
                setFormLoad("")
                if (response.data.status === true) {
                    setSuccessfulOpen(true)
                    setWithdrawalModalOpen(false)
                    setWithdrawalAmount("")
                    setCreditName("")
                    setBankAccountNo("")
                    setBankCode("")
                    setSelectBankName("")
                    setBankName("")
                    setWithdrawalPassword("")
                    // window.location.reload();
                }
            })
                .catch(function (error) {
                    //handle error
                    let notify = () => { toast(error.response.data.message) };
                    notify()
                    setFormLoad("")
                    setPayoutError(error?.response.data.message)
                    setTimeout(() => {
                        setPayoutError("")
                    }, 7000)
                }); 
        }   
    }

    // GET RATINGS
    const getProviderRatings = () => {
        axios.get(`${BASE_URL}/v1/fetch-provider-rating/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            }
        }).then((response) => {
            setRatingsArray(response?.data?.data[0])
        }).catch((error) => {
        })
    }

    useEffect(() => {
        getProviderRatings()
    }, [])
   


    return (
    dashboardLoad ? 
    <div className='h-[100vh] w-full flex justify-center items-center'>
        <div class="lds-ring">
            <img src={roadlersRR}  alt="roadlers logo" className='embed-logo'/>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
    :
    <div className={bg === true ? " blur-sm contrast-50 backdrop-brightness-50 provider-dashboard-overall" : "w-full provider-dashboard-overall"}>
        <ToastContainer />



        {/* WITHDRAWAL MODAL */}
        <>
            <div className="">
                <Transition appear show={withdrawalModalOpen} as={Fragment}>
                    <Dialog
                        as="div"
                        className="w-full  fixed px-6 inset-0 z-10  flex justify-center items-center"
                        onClose={closeModal}
                    >
                        <div className="w-full md:w-6/12 min-h-screen px-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Dialog.Overlay className="fixed inset-0" />
                            </Transition.Child>

                            {/* This element is to trick the browser into centering the modal contents. */}
                            <span
                                className="inline-block h-screen align-middle"
                                aria-hidden="true"
                            >
                                &#8203;
                            </span>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <div className="w-full md:w-8/12  inline-block max-w-md pt-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium  flex justify-center leading-6 text-gray-900"
                                    >
                                        <img src={withdrawFunds} alt="" className='w-[65%]'/>
                                    </Dialog.Title>

                                    <div className="bg-project p-6 px-14">
                                        <form>
                                            <div className="mt-2">
                                                <label htmlFor="" className='text-xx md:text-sm text-[#FFA100] ml-5'>Withdrawal Amount</label>
                                                <div className="flex items-center text-sm mt-1 px-2 w-full rounded-lg border border-white bg-[#000]  text-white">


                                                    <CurrencyInput

                                                        placeholder="₦0.00"
                                                        decimalsLimit={2}
                                                        allowNegativeValue={false}
                                                        prefix="₦"
                                                        // type="text"
                                                        className='p-2 md:text-sm text-xs w-full bg-transparent placeholder:text-xs placeholder:text-[#FFA100]   focus:outline-none text-white'


                                                        value={withdrawalAmount}
                                                        onValueChange={(value) => {
                                                            setWithdrawalAmount(value)
                                                        }}



                                                    />
                                                </div>
                                                <p className='mt-2 text-red-600 text-sm'>{withdrawalAmountError}</p>
                                            </div>

                                            <div className="mt-3">
                                                <label htmlFor="wallet" className='text-xx md:text-sm text-[#FFA100] ml-5'>Bank</label>
                                                <div className="flex items-center text-sm mt-1 px-2 w-full rounded-lg border border-white bg-[#000]  text-white">
                                                    <select
                                                        value={bankName}
                                                        onChange={e => {setBankName(e.target.value)
                                                            // withdrawFunction()
                                                            const banknameSplit = e.target.value.split(',')
                                                            const bankSelectName = banknameSplit[0]
                                                            setSelectBankName(bankSelectName)
                                                            const bankSelectCode = banknameSplit[1]
                                                            setBankCode(bankSelectCode)
                                                            console.log(banknameSplit, bankSelectName, bankSelectCode, "bank name split")
                                                        }}
                                                        className='p-2 md:text-sm text-xs w-full bg-transparent placeholder:text-xs placeholder:text-white  focus:outline-none text-white'
                                                    >
                                                        <option className=' text-black font-bold'>Select Bank</option>
                                                        {
                                                            bankNamesArray?.map((item, index) => {
                                                                return (
                                                                    <option key={index} value={item.name + ',' + item.code} className=' text-black font-bold'>{item.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                                <p className='mt-2 text-red-600 text-sm'>{bankNameError}</p>
                                            </div>

                                            <div className="mt-3">
                                                <label htmlFor="wallet" className='text-xx md:text-sm text-[#FFA100] ml-5'>Account Number</label>
                                                <div className="flex items-center text-sm mt-1 px-2 w-full rounded-lg border border-white bg-[#000]  text-white">
                                                    <input
                                                        type="text"
                                                        placeholder='Type Here'
                                                        className='p-2 md:text-sm text-xs w-full bg-transparent placeholder:text-xs placeholder:text-white  focus:outline-none text-white'
                                                        value={bankAccountNo}
                                                        onChange={e => {setBankAccountNo(e.target.value)}}
                                                        
                                                    />
                                                </div>
                                                <p className='mt-2 text-red-600 text-sm'>{bankAcctNoError}</p>
                                            </div>

                                            <div className="mt-3">
                                                <label htmlFor="wallet" className='text-xx md:text-sm text-[#FFA100] ml-5'>Account Name</label>
                                                <div className="flex items-center text-sm mt-1 px-2 w-full rounded-lg border border-white bg-[#000]  text-white">
                                                    <input
                                                        type="text"
                                                        placeholder='Type Here...'
                                                        className='p-2 md:text-sm text-xs w-full bg-transparent placeholder:text-xs placeholder:text-white  focus:outline-none text-white'
                                                        value={creditName}
                                                        
                                                    />
                                                </div>
                                                <p className='mt-2 text-red-600 text-sm'>{creditNameError}</p>
                                            </div>


                                            <div className="mt-3">
                                                <label htmlFor="" className='text-xx md:text-sm text-[#FFA100] ml-5'>Your Password</label>
                                                <div className="flex items-center text-sm mt-1 px-2 w-full rounded-lg border border-white bg-[#000]  text-white">
                                                    <input
                                                        type="password"
                                                        className='p-2 w-full md:text-sm text-xs bg-transparent placeholder:text-xs placeholder:text-white  focus:outline-none text-white'
                                                        autoComplete='new-password'
                                                        placeholder='xxxxxxxxx'
                                                        value={withdrawalPassword}
                                                        onChange={e => setWithdrawalPassword(e.target.value)}
                                                    />
                                                </div>
                                                <p className='mt-2 text-red-600 text-sm'>{withdrawalPasswordError}</p>
                                            </div>

                                            <div className="mt-6 w-full flex items-center justify-center">
                                                <button
                                                    type="submit"
                                                    className="inline-flex justify-center w-full px-4 py-2 text-sm font-bold text-black bg-amber-500 border border-transparent rounded-md  focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                                                    onClick={withdrawFunction}
                                                >
                                                    {
                                                        formLoad === "" ?
                                                        'Request Withdrawals' :
                                                        <input
                                                            type="image"
                                                            src={Spin}
                                                            value="Loading"
                                                            className="w-4 md:w-6"
                                                        />
                                                    }
                                                </button>
                                            </div>
                                            <p className='mt-2 text-red-600 text-sm'>{payoutError}</p>
                                        </form>
                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </Dialog>
                </Transition>
            </div>
        </>


        {/* BVN SETUP ACCOUNT MODAL */}
        <>
                <Transition appear show={bvnOpen}  as={Fragment}>
                    <Dialog
                        as="div"
                        className="w-full md:w-12/12  fixed flex justify-center items-center px-6 inset-0 z-10"
                        onClose={closeModal}
                    >
                        <div className="w-full md:w-12/12 min-h-screen px-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-25 bg-white"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-25"
                                leaveTo="opacity-0"
                            >
                                <Dialog.Overlay className="fixed inset-0" />
                            </Transition.Child>

                            {/* This element is to trick the browser into centering the modal contents. */}
                            <span
                                className="inline-block h-screen align-middle"
                                aria-hidden="true"
                            >
                                &#8203;
                            </span>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <div className="w-full md:w-3/12 inline-block  max-w-md pt-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium flex justify-center leading-6 text-gray-600"
                                    >
                                        <img src={top} alt="" className='w-[65%]' />
                                    </Dialog.Title>

                                    <div className="bg-[#242321] p-10 px-14">

                                        <form >
                                            <div className="mt-1 flex flex-col gap-4">
                                                <label htmlFor="" className='text-sm font-semibold text-amber-500'>Set Up Your Wallet </label>

                                                <label htmlFor="" className='text-xs  text-white'>BVN Required </label>
                                                <input


                                                    // type="text"
                                                    className='flex bg-[#000] items-center text-sm mt-2 p-2 w-full placeholder:text-white rounded-md border border-white  text-white'
                                                    name="bvn"
                                                    id="bvn"
                                                    placeholder='What is your BVN?'
                                                    value={bvn}

                                                    onChange={e => setBvn(e.target.value)}
                                                />

                                                {/* TEXT COPIED ALERT */}
                                                <div className={alert ? `mx-auto sm:w-3/4 md:w-2/4 fixed inset-x-0 top-10 flex items-center z-[10000] transition duration-150 ease-in` : `hidden`}>
                                                    <div
                                                        className="flex items-center bg-green-500 rounded-lg px-6 py-4 justify-center w-full mt-6"
                                                    >
                                                        <p className='m-0 text-white text-center text-lg'> Wallet Address has been successfully copied</p>
                                                    </div>
                                                </div>

                                            </div>



                                            <div className="flex gap-2 items-center cursor-pointer mt-2">
                                                <button
                                                    onClick={handleCreateVirtualWallet}
                                                    type="submit"
                                                    className='rounded-md mt-4 py-2 px-8 text-black text-xs font-bold bg-amber-500'>Done </button>
                                            </div>


                                            {/* 
                                            <div className="mt-3 w-full flex items-center justify-center">
                                                <button
                                                    type="button"
                                                    className="inline-flex justify-center px-4 py-2 text-sm font-bold text-black bg-amber-500 border border-transparent rounded-md  focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                                                    onClick={closeModal}
                                                >
                                                    Close
                                                </button>
                                            </div> */}
                                        </form>

                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </Dialog>
                </Transition>
            </>


        {/* SUCCESFUL MODAL */}
        <Transition appear show={successfulOpen} as={Fragment}>
            <Dialog
                as="div"
                className="fixed flex items-center justify-center w-full px-6 mt-8  inset-0 z-10 overflow-y-auto"
                onClose={closeModal}
            >
                <div className="min-h-screen px-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-25 bg-white"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-25"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="inline-block h-screen align-middle"
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className="w-10/12 inline-block  max-w-md pt-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-md">
                            <Dialog.Title
                                as="h3"
                                className="text-lg font-medium leading-6 text-gray-900"
                            >
                            </Dialog.Title>
                            <div className="bg-white pb-4 pr-4 pt-1 pl-4">
                                <div className="flex w-full justify-end">
                                    <button className=''>
                                        <img src={cancelImg} className='w-[30px]' onClick={closeModal} alt="Cancel Modal" />
                                    </button>
                                </div>

                                <img src={success} alt="" />
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>

        {/* TRANSFER TO CUSTOMER MODAL */}
        <>
            <div className="">
                <Transition appear show={transferOpen} as={Fragment}>
                    <Dialog
                        as="div"
                        className="w-full  fixed px-6 inset-0 z-10  flex justify-center items-center"
                        onClose={closeModal}
                    >
                        <div className="w-full md:w-6/12 min-h-screen px-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Dialog.Overlay className="fixed inset-0" />
                            </Transition.Child>

                            {/* This element is to trick the browser into centering the modal contents. */}
                            <span
                                className="inline-block h-screen align-middle"
                                aria-hidden="true"
                            >
                                &#8203;
                            </span>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <div className="w-full md:w-8/12  inline-block max-w-md pt-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg flex justify-center font-medium leading-6 text-gray-900"
                                    >
                                        <img src={transferProvider} alt="" className='w-[75%]'/>
                                    </Dialog.Title>

                                    <div className="bg-project p-6 px-12">
                                        <form>
                                            <div className="mt-2">
                                                <label htmlFor="" className='text-xx md:text-sm text-[#FFA100] ml-5'>Transfer Amount</label>
                                                <div className="flex items-center text-sm mt-1 px-2 w-full rounded-lg bg-[#000] border border-white  text-white">


                                                    <CurrencyInput

                                                        placeholder="₦0.00"
                                                        decimalsLimit={2}
                                                        allowNegativeValue={false}
                                                        prefix="₦"
                                                        // type="text"
                                                        className='p-2 md:text-sm text-xs w-full bg-[#000] placeholder:text-xs placeholder:text-[#FFA100]  focus:outline-none text-white'


                                                        value={amount}
                                                        onValueChange={(value) => {
                                                            setAmount(value)
                                                        }}



                                                    />
                                                </div>
                                            </div>


                                            <div className="mt-3">
                                                <label htmlFor="wallet" className='text-xx md:text-sm text-[#FFA100] ml-5'>Transfer To</label>
                                                <div className="flex items-center text-sm mt-1 px-2 w-full rounded-lg border border-white  bg-[#000] text-white">
                                                    <input
                                                        type="text"
                                                        placeholder='Customer Username'
                                                        className='p-2 md:text-sm text-xs w-full bg-transparent placeholder:text-xs placeholder:text-white     focus:outline-none text-white'
                                                        value={credit}
                                                        onChange={e => setCredit(e.target.value)}
                                                        autoComplete='new-password'
                                                    />
                                                </div>
                                            </div>


                                            <div className="mt-3">
                                                <label htmlFor="" className='text-xx md:text-sm text-[#FFA100] ml-5'>Your Password</label>
                                                <div className="flex items-center text-sm mt-1 px-2 w-full rounded-lg border border-white bg-[#000]  text-white">
                                                    <input
                                                        type="password"
                                                        className='p-2 w-full md:text-sm text-xs bg-transparent placeholder:text-xs placeholder:text-white  focus:outline-none text-white'
                                                        autoComplete='new-password'
                                                        placeholder='xxxxxxxxx'
                                                        value={password}
                                                        onChange={e => setPassword(e.target.value)}
                                                    />
                                                </div>
                                            </div>

                                            <div className="mt-6 w-full flex items-center justify-center">
                                                <button
                                                    type="submit"
                                                    className="inline-flex justify-center px-8 py-2 text-sm font-bold text-black bg-amber-500 border border-transparent rounded-md  focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                                                    onClick={e => transferFiat(e)}
                                                >
                                                    Transfer
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </Dialog>
                </Transition>
            </div>
        </>


        <Header user='provider' />

       
        <main className='flex flex-col md:flex-row md:px-12 px-12 provider-dashboard-section-one'>

            <Navigation/>

            {/* <div className='md:border-r md:border-amber-500 h-100 md:w-6/12 w-full md:px-20 pb-20 pt-12 md:pt-36'> */}

            <div className='h-100 md:w-2/5 w-full md:px-20 pb-20 pt-12 md:pt-36 provider-wallet-section'>


                <h2 className='text-white font-bold mb-1 text-md'>My Wallet</h2>
                <div className='md:h-48 md:w-80 border-theme-color bg-[#1D1C1C] mt-12 rounded-lg px-8 py-4 flex flex-col'>
                    <h4 className='text-white text-sm'>Current Balance</h4>
                    <h3 className='text-white mt-1 md:text-lg text-base font-extrabold'>{firstname} . {initial}</h3>
                    <h1 className='theme-color md:text-4xl font-bold my-4 mb-2 text-2xl'>₦{Number(balance).toLocaleString('en-US', { maximumFractionDigits: 2 })}</h1>
                    <h6 className='theme-color mb-2'>Book Balance - ₦{Number(bookBalance).toLocaleString('en-US', { maximumFractionDigits: 2 })}</h6>
                    <h6 className='text-white'>{username}</h6>

                </div>

                {/* DOES THIS WORK WITH THE SAME ENDPOINT AS CUSTOMERS */}
                <div className="flex gap-3 w-full md:w-80">
                    <button className='bg-white w-1/2 my-8 p-2 flex item-center text-center justify-center border border-amber-500 rounded-md font-bold md:text-base text-sm' onClick={bvnModal}> {padlockOpen && <span><img src={padlock} alt="padlock"  width={20}/></span>} Withdraw
                    </button>
                    <button className='bg-white w-1/2 my-8 p-2 text-center border border-amber-500 rounded-md font-bold md:text-base 
                    text-sm' onClick={onTransferOpen}>Transfer
                    </button>

                </div>

                <div className="md:w-80 w-[100%] relative bg-[#000] mt-2 px-1 py-2 text-center border border-[#FFA100] rounded-md font-bold text-center">
                    {
                        providerServiceChoice === "driver" ?
                        <NavLink
                            to='/driver-registeration'
                            className='w-[100%] h-[100%] text-white flex justify-center hover:text-[#fff]'>Add Driver
                        </NavLink>:
                        <NavLink
                            to='/driver-car-registration'
                            className='w-[100%] h-[100%] text-white flex justify-center hover:text-[#fff]'>Add Vehicle
                            <span className='absolute right-0 bottom-[5]'><img src={roadlersCar} alt="Vehicle Image" /></span>
                        </NavLink>

                    }
                </div>



            </div>

            <div className='md:px-20 md:w-2/5 w-full pt-12 pb-20 md:pt-32 provider-status-section' >
                <h2 className='text-white font-bold text-lg'>My Status</h2>

                <div className="flex gap-5 items-center mt-6">
                    <h1
                        className='theme-color border rounded-md w-20 text-center py-4 border-white text-2xl font-extrabold'>
                        {myListings.length}</h1>
                    <p className='text-white font-bold md:text-lg text-base'>Total Request so far</p>
                </div>

                <div className="md:w-80 w-full mt-4">
                    {/* CHECK WHERE THIS BUTTON LEADS TO */}
                    <button
                        className='md:text-base text-sm w-full text-white bg-transparent mt-2 md:p-1 p-2 text-center border border-white rounded-md font-bold '>
                        View Requests
                    </button>

                </div>

                <div className="md:w-80 w-full flex gap-2 items-center mt-6">
                    <button className='bg-red-700 md:p-1 p-2 text-white w-1/2 rounded-md font-bold'>{providerOverview.open} Waiting</button>
                    <button className=' w-1/2 md:p-1 p-2 blue text-black rounded-md font-bold'>{providerOverview.pending} Pending</button>
                </div>

                {/* providerOverview */}


                {/* RATING ENDPOINT NEEDED HERE */}
                <div className="mt-12 flex-flex-col items-baseline justify-start">
                    <h4 className='font-bold text-white'>My Ratings</h4>
                    {ratingsArray?.star_rating ?
                        <h4 className='theme-color font-extrabold mt-4'>{ratingsArray?.star_rating}/5</h4> :
                        <h4 className='theme-color font-extrabold mt-4'>0/5</h4> 
                    }
                    <div className="flex items-center w-full gap-4">
                        {
                            ratingsArray?.star_rating ?
                            <p className="cardRate__styled flex"><StarReview rating={ratingsArray?.star_rating}/></p> :
                            <p className="cardRate__styled flex"><StarReview rating={0}/></p>
                        }
                        
                        <div className="flex gap-4 w-1/2">
                            <button disabled={true} className='bg-red-700 text-white w-8 h-8 font-bold'>0</button>
                            <button disabled={true} className='bg-amber-400 w-8 h-8 font-bold'>0</button>
                            <button disabled={true} className='bg-green-500 text-white w-8 h-8 font-bold'>0</button>
                        </div>
                    </div>
                </div>
            </div>
        </main>





        {/* MY REQUESTS================================================================================= */}

        <Subheader text='My Requests' id="myRequests" />


        {
            myListings?.length === 0 ? 
            <div className="h-[200px] w-full flex items-center justify-center">
                <h1 className='text-white text-lg'>No Request Yet</h1>
            </div> :
            <main  className='md:px-40 px-12 mt-20 my-Request-container' id="myRequests" >
                <div className="flex justify-end mb-8 md:mb-4">
                    <div className="p-2 px-3 border  text-white rounded-md border-white flex">
                        <button
                            className="mr-4 text-xs md:text-base"
                            type='button'
                            onClick={() => {
                                setRequestFilterOpen((requestFilterOpen) => !requestFilterOpen)
                                setRequestFilterTwo(false)
                            }}>

                            {requestStartDate ? moment(requestStartDate).format("DD/MM/YYYY") : getToday}
                        </button>


                        <label htmlFor="" className='mr-4 text-xs md:text-base'> - </label>

                        <button
                            className="mr-4 text-xs md:text-base"
                            type='button'
                            onClick={() => {setRequestFilterTwo((requestFilterOpen) => !requestFilterOpen)
                                    setRequestFilterOpen(false)
                            }}>

                            {requestEndDate ? moment(requestEndDate).format("DD/MM/YYYY") : getTomorrow}


                        </button>

                        <img src={one} alt="" className="w-6" />



                        <div className="dateee">
                            <DatePicker
                                selected={requestStartDate}
                                onChange={(date) => {
                                    setRequestStartDate(date)
                                }}
                                selectsStart
                                startDate={requestStartDate}
                                endDate={requestEndDate}
                                open={requestFilterOpen}
                                style={{ marginRight: "50px" }}
                            />
                        </div>

                        <div className="dateee">
                            <DatePicker
                                selected={requestEndDate}
                                onChange={(date) => {
                                    setRequestEndDate(date)
                                }}
                                selectsEnd
                                open={requestFilterTwo}
                                startDate={requestStartDate}
                                endDate={requestEndDate}
                                minDate={requestStartDate}
                                style={{ color: "white" }}
                            />
                        </div>

                    </div>
                </div>

                <div className="" id='request'>
                    {
                        requestStartDate && requestEndDate ?

                        (myListings.filter(obj => (moment(obj.pickup_date).format("YYYY/MM/DD")) >= (moment(requestStartDate).format("YYYY/MM/DD")) && (moment(obj.pickup_date).format("YYYY/MM/DD")) <= (moment(requestEndDate).format("YYYY/MM/DD")) && (obj.payment_status === "PAID")).slice(0,visibleList).map((myListing, i) => {
                            return (
                                <Requests
                                    image={myListing.book_picture}
                                    date={myListing.pickup_date}
                                    id={myListing.id}
                                    email={myListing.email}
                                    vehicleId={myListing.vehicle_id}
                                    amount={myListing.first_price}
                                    category={myListing.category}
                                    booking_title={myListing.booking_title}
                                    destination={myListing.destination}
                                    num_of_day={myListing.num_of_day}
                                    booking_id={myListing.booking_id}
                                    pickup_time={myListing.pickup_time}
                                    payment_status={myListing.payment_status}
                                    pickup_location={myListing.pickup_location}
                                    user_id={myListing.user_id}
                                    vat={myListing.vat}
                                    insurance={myListing.insurance_price}
                                    billingRef={myListing.billing_reference}
                                    provider 
                                    bookValueStatus={myListing.book_status}
                                />
                            )
                        })):
                        (myListings.filter(obj => (obj.payment_status === "PAID")).slice(0,visibleList).map((myListing, i) => {
                            return (
                                <Requests
                                    image={myListing.book_picture}
                                    date={myListing.pickup_date}
                                    id={myListing.id}
                                    vehicleId={myListing.vehicle_id}
                                    amount={myListing.first_price}
                                    category={myListing.category}
                                    booking_title={myListing.booking_title}
                                    destination={myListing.destination}
                                    num_of_day={myListing.num_of_day}
                                    booking_id={myListing.booking_id}
                                    pickup_time={myListing.pickup_time}
                                    payment_status={myListing.payment_status}
                                    pickup_location={myListing.pickup_location}
                                    user_id={myListing.user_id}
                                    provider 
                                    bookValueStatus={myListing.book_status}
                                />
                            )
                        }))
                    }
                </div>
            </main>
        }

        {
            !myListings?.length || myListings?.length <= visibleList ? ('') : (
                <main className='md:px-40 px-12'>
                    <div className="w-full flex items-center justify-center my-16">
                        <button onClick={showMoreList} className='border border-theme-color theme-color font-semibold p-3 text-sm rounded-lg w-36'>Load More</button>
                    </div>
                </main>
            )
        }


        {/* DRIVER REQUEST */}
        
        {
            driverRequest?.length !== 0 &&
            <>
                    <Subheader text='Driver Requests' id="driverRequests" />

                    <main  className='md:px-40 px-12 mt-20' id="myRequests" >
                        <div className="flex justify-end mb-8 md:mb-4">
                            <div className="p-2 px-3 border  text-white rounded-md border-white flex">
                                <p className='mr-4 text-xs md:text-base'>2 March 2022 - 2 April 2022</p> <img src={one} alt="" className='w-4 md:w-6' />
                            </div>
                        </div>

                        <div className="" id='request'>
                            {
                                driverRequest?.slice(0,visibleList).map((myListing, i) => {
                                    return (
                                        <Requests
                                            image={myListing.book_picture}
                                            date={myListing.pickup_date}
                                            id={myListing.id}
                                            vehicleId={myListing.vehicle_id}
                                            amount={myListing.amount}
                                            category={myListing.category}
                                            booking_title={myListing.booking_title}
                                            destination={myListing.destination}
                                            num_of_day={myListing.num_of_day}
                                            booking_id={myListing.booking_id}
                                            pickup_time={myListing.pickup_time}
                                            payment_status={myListing.payment_status}
                                            pickup_location={myListing.pickup_location}
                                            user_id={myListing.user_id}
                                            provider 
                                            bookValueStatus={myListing.book_status}
                                        />
                                    )
                                })
                            }
                        </div>
                    </main>

                    {
                        !driverRequest?.length || driverRequest?.length <= visibleList ? ('') : (
                            <main className='md:px-40 px-12'>
                                <div className="w-full flex items-center justify-center my-16">
                                    <button onClick={showMoreList} className='border border-theme-color theme-color font-semibold p-3 text-sm rounded-lg w-36'>Load More</button>
                                </div>
                            </main>  
                        )
                    }
            </>
        }

        {/* VEHICLE REQUEST */}

        {
            vehicleRequest?.length !== 0 && 
            <>
                    <Subheader text='Vehicle Requests' id="vehicleRequests" />

                    <main  className='md:px-40 px-12 mt-20' id="myRequests" >
                        <div className="flex justify-end mb-8 md:mb-4">
                            <div className="p-2 px-3 border  text-white rounded-md border-white flex">
                                <p className='mr-4 text-xs md:text-base'>2 March 2022 - 2 April 2022</p> <img src={one} alt="" className='w-4 md:w-6' />
                            </div>
                        </div>

                        <div className="" id='request'>
                            {
                                vehicleRequest?.slice(0,visibleList).map((myListing, i) => {
                                    return (
                                        <Requests
                                            image={myListing.book_picture}
                                            date={myListing.pickup_date}
                                            id={myListing.id}
                                            vehicleId={myListing.vehicle_id}
                                            amount={myListing.amount}
                                            category={myListing.category}
                                            booking_title={myListing.booking_title}
                                            destination={myListing.destination}
                                            num_of_day={myListing.num_of_day}
                                            booking_id={myListing.booking_id}
                                            pickup_time={myListing.pickup_time}
                                            payment_status={myListing.payment_status}
                                            pickup_location={myListing.pickup_location}
                                            user_id={myListing.user_id}
                                            provider 
                                            bookValueStatus={myListing.book_status}
                                        />
                                    )
                                })
                            }
                        </div>
                    </main>

                    {
                        !vehicleRequest?.length || vehicleRequest?.length <= visibleList ? ('') : (
                            <main className='md:px-40 px-12'>
                                <div className="w-full flex items-center justify-center my-16">
                                    <button onClick={showMoreList} className='border border-theme-color theme-color font-semibold p-3 text-sm rounded-lg w-36'>Load More</button>
                                </div>
                            </main>    
                        )
                    }
            </>
        }

        {/*ABANDONED REQUESTS  */}

        {
            (abandonedReq?.length !== 0  ) &&

            <>
            <Subheader text='Abandoned and Rejected Requests' id="myAbandonedRequests" />
            <main  className='md:px-40 px-12 mt-20' id="myRequests" >
                <div className="flex justify-end mb-8 md:mb-4">
                    <div className="p-2 px-3 border  text-white rounded-md border-white flex">
                        <p className='mr-4 text-xs md:text-base'>2 March 2022 - 2 April 2022</p> <img src={one} alt="" className='w-4 md:w-6' />
                    </div>
                </div>

                <div className="" id='request'>
                    {
                        abandonedReq?.slice(0,visibleList).map((myListing, i) => {
                            return (
                                <Requests
                                    image={myListing.book_picture}
                                    date={myListing.pickup_date}
                                    id={myListing.id}
                                    vehicleId={myListing.vehicle_id}
                                    amount={myListing.amount}
                                    category={myListing.category}
                                    booking_title={myListing.booking_title}
                                    destination={myListing.destination}
                                    num_of_day={myListing.num_of_day}
                                    booking_id={myListing.booking_id}
                                    pickup_time={myListing.pickup_time}
                                    payment_status={myListing.payment_status}
                                    pickup_location={myListing.pickup_location}
                                    user_id={myListing.user_id}
                                    provider 
                                    bookValueStatus={myListing.book_status}
                                />
                            )
                        })
                    }
                </div>
            </main>

            {
                !abandonedReq?.length || abandonedReq?.length <= visibleList ? ('') : (
                    <main className='md:px-40 px-12'>
                        <div className="w-full flex items-center justify-center my-16">
                            <button onClick={showMoreList} className='border border-theme-color theme-color font-semibold p-3 text-sm rounded-lg w-36'>Load More</button>
                        </div>
                    </main>
                )
            }
            </>
        }



        {/* ASSETS SECTION===============================================================================================*/}
        <Subheader text='My Assets' />

        <main id='asset' className='md:px-40 mt-16 px-8 provider-assets-section relative'>
            <div  className="flex md:hidden">
                <div className="w-5/12">
                <div className="radio-container flex items-center w-full gap-5 pl-3">
                        <label className='text-white radio-wrapper'>
                            <input type="radio" name="cars-type" value="" className="radio-style cursor-pointer" checked={carType == ""} onChange={handleCarType} />
                            All
                        </label>

                        <label className='text-white radio-wrapper'>
                            <input type="radio" name="cars-type" value='cars' className="radio-style cursor-pointer" checked={carType === "cars"} onChange={handleCarType} />
                            Cars
                        </label>

                        <label className='text-white radio-wrapper'>
                            <input type="radio" name="cars-type" value='bus' className="radio-style cursor-pointer" checked={carType === "bus"} onChange={handleCarType}  />
                            Buses
                        </label>

                        <label className='text-white radio-wrapper'>
                            <input type="radio" name="cars-type" value='vans' className="radio-style cursor-pointer" checked={carType === "vans"} onChange={handleCarType}  />
                            Vans
                        </label>

                        <label className='text-white radio-wrapper'>
                            <input type="radio" name="cars-type" value='trucks' className="radio-style cursor-pointer" checked={carType === "trucks"} onChange={handleCarType}  />
                            Trucks
                        </label>

                        <label className='text-white radio-wrapper'>
                            <input type="radio" name="cars-type" value='bikes' className="radio-style cursor-pointer" checked={carType === "bikes"} onChange={handleCarType}  />
                            Bikes
                        </label>
                    </div>
                </div>

                <div className="flex justify-between w-7/12 flex-col md:hidden gap-17">
                    <div className="p-2 px-3 border  text-white rounded-md border-white flex">
                        <button
                            className="mr-4 text-xs md:text-base left-daterange-picker"
                            type='button'
                            onClick={() => {
                                setFirstOpen((isOpen) => !isOpen)
                            }}>
                            {startDate ? setSelectedStartDate : getToday}
                        </button>


                        <label htmlFor="" className='mr-4 text-xs md:text-base daterange-separate'> - </label>

                        <button
                            className="mr-4 text-xs md:text-base right-daterange-picker"
                            type='button'
                            onClick={() => setOpen((isOpen) => !isOpen)}>

                            {endDate ? setSelectedEndDate : getTomorrow}


                        </button>

                        <img src={one} alt="" className="w-6 daterange-calendar-img" />



                        <div className="dateee">
                            <DatePicker
                                selected={startDate}
                                onChange={(date) => {
                                    setStartDate(date)
                                    { handleFilterDate(date) }

                                    setFirstOpen(false)
                                }}

                                open={firstOpen}

                                style={{ marginRight: "50px" }}



                            />
                        </div>

                        <div className="dateee">
                            <DatePicker
                                selected={endDate}
                                onChange={(date) => {
                                    setEndDate(date)

                                    setOpen(false)
                                }}

                                open={open}

                                style={{ color: "white" }}
                            />
                        </div>

                    </div>
                    <form className='flex w-full gap-2'>
                        <input type="text" className='focus:outline-none placeholder:text-xs border w-8/12 md:w-60 rounded-md border-white text-white  bg-transparent p-1' placeholder="Asset's ID"  onChange={e => setSearchCar(e.currentTarget.value)} />
                        <button type="submit" className='md:w-28 w-4/12 bg-amber-500 font-bold md:text-sm text-xs rounded-md'>Submit</button>
                    </form>

                    <div className='flex justify-between w-full gap-2'>
                        <button className='w-1/3 md:w-24 bg-blue-700 p-2 text-white font-bold rounded-lg text-xs' onClick={handleVehiclePending}>Pending</button>
                        <button className='w-1/3 md:w-24  p-2 text-black bg-amber-500 font-bold rounded-lg text-xs'onClick={handleVehicleBooked}>Booked</button>
                        <button className='w-1/3 md:w-24 bg-white p-2 text-black font-bold rounded-lg text-xs' onClick={handleVehicleFree}>Free</button>
                    </div>
                </div>

            </div>



            {/* DESKTOP VIEW */}
            <div className="md:flex justify-between hidden provider-asset-header">
                <form className='flex w-96'>
                    <input type="text" className='focus:outline-none mr-2 border w-60 rounded-md border-white text-white  bg-transparent p-2' placeholder="Provider's ID" onChange={e => setSearchCar(e.currentTarget.value)} />
                    <button type="submit" className='w-28 bg-amber-500 font-bold rounded-md'>Search</button>
                </form>

                <div className="p-2 px-3 border  text-white rounded-md border-white flex">
                    <button
                        className="mr-4 text-xs md:text-base"
                        type='button'
                        onClick={() => {
                            setFirstOpen((isOpen) => !isOpen)
                        }}>
                        {startDate ? setSelectedStartDate : getToday}
                    </button>


                    <label htmlFor="" className='mr-4 text-xs md:text-base'> - </label>

                    <button
                        className="mr-4 text-xs md:text-base"
                        type='button'
                        onClick={() => setOpen((isOpen) => !isOpen)}>

                        {endDate ? setSelectedEndDate : getTomorrow}


                    </button>

                    <img src={one} alt="" className="w-6" />



                    <div className="dateee">
                        <DatePicker
                            selected={startDate}
                            onChange={(date) => {
                                setStartDate(date)
                                { handleFilterDate(date) }

                                setFirstOpen(false)
                            }}

                            open={firstOpen}

                            style={{ marginRight: "50px" }}



                        />
                    </div>

                    <div className="dateee">
                        <DatePicker
                            selected={endDate}
                            onChange={(date) => {
                                setEndDate(date)
                                setOpen(false)
                            }}

                            open={open}

                            style={{ color: "white" }}
                        />
                    </div>

                </div>
            </div>

            <div className="md:flex justify-between mt-16 hidden">
                <div className='flex justify-between'>
                    <div className="radio-container flex items-center w-full gap-5 pl-3">
                        <label className='text-white radio-wrapper'>
                            <input type="radio" name="cars-type" value="" className="radio-style cursor-pointer" checked={carType == ""} onChange={handleCarType} />
                            All
                        </label>

                        <label className='text-white radio-wrapper'>
                            <input type="radio" name="cars-type" value='cars' className="radio-style cursor-pointer" checked={carType === "cars"} onChange={handleCarType} />
                            Cars
                        </label>

                        <label className='text-white radio-wrapper'>
                            <input type="radio" name="cars-type" value='bus' className="radio-style cursor-pointer" checked={carType === "bus"} onChange={handleCarType}  />
                            Buses
                        </label>

                        <label className='text-white radio-wrapper'>
                            <input type="radio" name="cars-type" value='vans' className="radio-style cursor-pointer" checked={carType === "vans"} onChange={handleCarType}  />
                            Vans
                        </label>

                        <label className='text-white radio-wrapper'>
                            <input type="radio" name="cars-type" value='trucks' className="radio-style cursor-pointer" checked={carType === "trucks"} onChange={handleCarType}  />
                            Trucks
                        </label>

                        <label className='text-white radio-wrapper'>
                            <input type="radio" name="cars-type" value='bikes' className="radio-style cursor-pointer" checked={carType === "bikes"} onChange={handleCarType}  />
                            Bikes
                        </label>
                    </div>
                </div>

                <div className='flex justify-between activity-btn-container'>
                    <button className='w-24 bg-blue-700 p-2 text-white font-bold rounded-lg mr-3' onClick={handleVehiclePending}>Pending</button>
                    <button className='w-24  p-2 text-black bg-amber-500 font-bold rounded-lg mr-3' onClick={handleVehicleBooked}>Booked</button>
                    <button className='w-24 bg-white p-2 text-black font-bold rounded-lg' onClick={handleVehicleFree}>Free</button>
                </div>

            </div>


            <div className="my-16 w-full lg:grid-cols-4 grid-cols-2 lg:grid lg:gap-16 gap-4 grid ">

                {
                    startDate && endDate ?

                    (findCar?.filter(obj => (moment(obj.created_at).format("YYYY/MM/DD")) >= (moment(startDate).format("YYYY/MM/DD")) && (moment(obj.created_at).format("YYYY/MM/DD")) <= (moment(endDate).format("YYYY/MM/DD"))).slice(0,visible).map((providerAsset, i) => {
                        return(
                            <Assets
                            image={providerAsset.image1}
                            name={providerAsset.vehicle_name}
                            price={providerAsset.daily_price}
                            send='send' 
                            year={providerAsset.year}
                            location={providerAsset.state} 
                            assetId={providerAsset.asset_id} 
                            vehicleId={providerAsset.id} 
                            key={providerAsset.asset_id} 
                            assetStatus={providerAsset.status}
                            />
                        )
                    
                    })) :

                    (findCar?.slice(0,visible).map((providerAsset, i) => {
                        return(
                            <Assets
                            image={providerAsset.image1}
                            name={providerAsset.vehicle_name}
                            price={providerAsset.daily_price}
                            send='send' 
                            year={providerAsset.year}
                            location={providerAsset.state} 
                            assetId={providerAsset.asset_id} 
                            vehicleId={providerAsset.id} 
                            key={providerAsset.asset_id} 
                            assetStatus={providerAsset.status}
                            />
                        )
                    
                    }))
                }


                


                {/* <Assets image={asset_one} name='Prado (2016)' price='₦65,000' status='Show' send='send' />
                <Assets image={asset_two} name='Toyota Corolla (2013)' price='₦35,000' status='Hide' send='send' />
                <Assets image={asset_one} name='Prado (2016)' price='₦65,000' status='Show' send='send' />

                <Assets image={asset_two} name='Toyota Corolla (2013)' price='₦35,000' status='Pending' send='send' />
                <Assets image={asset_one} name='Prado (2016)' price='₦35,000' status='Show' send='send' />
                <Assets image={asset_two} name='Toyota Corolla (2013)' price='₦35,000' status='Hide' send='send' />
                <Assets image={asset_one} name='Prado (2016)' price='₦35,000' status='Show' send='send' /> */}
            </div>

            {
                !findCar?.length || findCar?.length <= visible ? ('') : (
                    <div className="w-full flex items-center justify-center my-20">
                        <button 
                            onClick={showMore}
                            className='border border-theme-color theme-color font-semibold p-3 text-sm rounded-lg w-36'
                        >
                            Load More
                        </button>
                    </div>
                )
            }
        </main>
        

        {/*MY DRIVERS SECTION*/}
        <Subheader text={`Driver's Accounts`} />
        <main id='asset' className='md:px-40 mt-16 px-8 provider-assets-section relative'>
        <div  className="flex md:hidden driver-sect-header-overall">
            <div className="w-5/12">
            <div className="radio-container flex items-center w-full gap-5 pl-3">
                        <label className='text-white radio-wrapper'>
                            <input type="radio" name="driver-type" value="" className="radio-style cursor-pointer" checked={driverType === ""} onChange={handleDriverType} />
                            All
                        </label>

                        <label className='text-white radio-wrapper'>
                            <input type="radio" name="driver-type" value='night driver' className="radio-style cursor-pointer" checked={driverType === "night driver"} onChange={handleDriverType} />
                            Night Driver
                        </label>

                        <label className='text-white radio-wrapper'>
                            <input type="radio" name="driver-type" value='day driver' className="radio-style cursor-pointer" checked={driverType === "day driver"} onChange={handleDriverType}  />
                            Day Driver
                        </label>

                        <label className='text-white radio-wrapper'>
                            <input type="radio" name="driver-type" value='full-time driver' className="radio-style cursor-pointer" checked={driverType === "full-time driver"} onChange={handleDriverType}  />
                            Full-Time Driver
                        </label>
                    </div>
            </div>

            <div className="flex justify-between w-7/12 flex-col md:hidden gap-17 drivers-extra-header-sect">

                <div className="p-2 px-3 border  text-white rounded-md border-white flex daterange-picker-overall">
                    <button
                        className="mr-4 text-xs md:text-base left-daterange-picker"
                        type='button'
                        onClick={() => {
                            setDriverFilterOpen((driverFilterOpen) => !driverFilterOpen)
                            setDriverFilterTwo(false)
                        }}>

                        {driverStartDate ? moment(driverStartDate).format("DD/MM/YYYY") : getToday}
                    </button>


                    <label htmlFor="" className='mr-4 daterange-separate text-xs md:text-base'> - </label>

                    <button
                        className="mr-4 text-xs md:text-base right-daterange-picker"
                        type='button'
                        onClick={() => {setDriverFilterTwo((driverFilterOpen) => !driverFilterOpen)
                                setDriverFilterOpen(false)
                        }}>

                        {driverEndDate ? moment(driverEndDate).format("DD/MM/YYYY") : getTomorrow}


                    </button>

                    <img src={one} alt="" className="w-6 daterange-calendar-img" />



                    <div className="dateee">
                        <DatePicker
                            selected={driverStartDate}
                            onChange={(date) => {
                                setDriverStartDate(date)
                            }}
                            selectsStart
                            startDate={driverStartDate}
                            endDate={driverEndDate}
                            open={driverFilterOpen}
                            style={{ marginRight: "50px" }}
                        />
                    </div>

                    <div className="dateee">
                        <DatePicker
                            selected={driverEndDate}
                            onChange={(date) => {
                                setDriverEndDate(date)
                            }}
                            selectsEnd
                            open={driverFilterTwo}
                            startDate={driverStartDate}
                            endDate={driverEndDate}
                            minDate={driverStartDate}
                            style={{ color: "white" }}
                        />
                    </div>

                </div>
                <form className='flex w-full gap-2'>
                    <input type="text" className='focus:outline-none placeholder:text-xs border w-8/12 md:w-60 rounded-md border-white text-white  bg-transparent p-1' placeholder="Asset's ID" onChange={e => setSearchDriver(e.currentTarget.value)} />
                    <button type="submit" className='md:w-28 w-4/12 bg-amber-500 font-bold md:text-sm text-xs rounded-md'>Submit</button>
                </form>

                <div className='flex justify-between w-full gap-2'>
                    <button className='w-1/3 md:w-24 bg-blue-700 p-2 text-white font-bold rounded-lg text-xs' onClick={handleDriverPending}>Pending</button>
                    <button className='w-1/3 md:w-24  p-2 text-black bg-amber-500 font-bold rounded-lg text-xs'onClick={handleDriverBooked}>Booked</button>
                    <button className='w-1/3 md:w-24 bg-white p-2 text-black font-bold rounded-lg text-xs' onClick={handleDriverFree}>Free</button>
                </div>
            </div>

        </div>





        {/* DESKTOP VIEW */}

        <div className="md:flex justify-between hidden provider-asset-header">
            <form className='flex w-96'>
                <input type="text" className='focus:outline-none mr-2 border w-60 rounded-md border-white text-white  bg-transparent p-2' placeholder= "Driver's ID" onChange={e => setSearchDriver(e.currentTarget.value)} />
                <button type="submit" className='w-28 bg-amber-500 font-bold rounded-md'>Search</button>
            </form>

            <div className="p-2 px-3 border  text-white rounded-md border-white flex">
                <button
                    className="mr-4 text-xs md:text-base"
                    type='button'
                    onClick={() => {
                        setDriverFilterOpen((driverFilterOpen) => !driverFilterOpen)
                        setDriverFilterTwo(false)
                    }}>

                    {driverStartDate ? moment(driverStartDate).format("DD/MM/YYYY") : getToday}
                </button>


                <label htmlFor="" className='mr-4 text-xs md:text-base'> - </label>

                <button
                    className="mr-4 text-xs md:text-base"
                    type='button'
                    onClick={() => {setDriverFilterTwo((driverFilterOpen) => !driverFilterOpen)
                            setDriverFilterOpen(false)
                    }}>

                    {driverEndDate ? moment(driverEndDate).format("DD/MM/YYYY") : getTomorrow}


                </button>

                <img src={one} alt="" className="w-6" />



                <div className="dateee">
                    <DatePicker
                        selected={driverStartDate}
                        onChange={(date) => {
                            setDriverStartDate(date)
                        }}
                        selectsStart
                        startDate={driverStartDate}
                        endDate={driverEndDate}
                        open={driverFilterOpen}
                        style={{ marginRight: "50px" }}
                    />
                </div>

                <div className="dateee">
                    <DatePicker
                        selected={driverEndDate}
                        onChange={(date) => {
                            setDriverEndDate(date)
                        }}
                        selectsEnd
                        open={driverFilterTwo}
                        startDate={driverStartDate}
                        endDate={driverEndDate}
                        minDate={driverStartDate}
                        style={{ color: "white" }}
                    />
                </div>

            </div>
        </div>


        <div className="md:flex justify-between mt-16 hidden">
        <div className='flex justify-between'>
                    <div className="radio-container flex items-center w-full gap-5 pl-3">
                        <label className='text-white radio-wrapper'>
                            <input type="radio" name="driver-type" value='' className="radio-style cursor-pointer" checked={driverType === ""} onChange={handleDriverType} />
                            All
                        </label>

                        <label className='text-white radio-wrapper'>
                            <input type="radio" name="driver-type" value='night driver' className="radio-style cursor-pointer" checked={driverType === "night driver"} onChange={handleDriverType} />
                            Night Driver
                        </label>

                        <label className='text-white radio-wrapper'>
                            <input type="radio" name="driver-type" value='day driver' className="radio-style cursor-pointer" checked={driverType === "day driver"} onChange={handleDriverType}  />
                            Day Driver
                        </label>

                        <label className='text-white radio-wrapper'>
                            <input type="radio" name="driver-type" value='full-time driver' className="radio-style cursor-pointer" checked={driverType === "full-time driver"} onChange={handleDriverType}  />
                            Full-Time Driver
                        </label>
                    </div>
                </div>

            <div className='flex justify-between activity-btn-container'>
                <button className='w-24 bg-blue-700 p-2 text-white font-bold rounded-lg mr-3' onClick={handleDriverPending}>Pending</button>
                <button className='w-24  p-2 text-black bg-amber-500 font-bold rounded-lg mr-3' onClick={handleDriverBooked}>Booked</button>
                <button className='w-24 bg-white p-2 text-black font-bold rounded-lg' onClick={handleDriverFree}>Free</button>
            </div>

        </div>


        <div className="my-16 w-full lg:grid-cols-4 grid-cols-2 lg:grid lg:gap-16 gap-4 grid ">

            {
                driverStartDate && driverEndDate ?

                (findDriver?.filter(obj => (moment(obj.created_at).format("YYYY/MM/DD")) >= (moment(driverStartDate).format("YYYY/MM/DD")) && (moment(obj.created_at).format("YYYY/MM/DD")) <= (moment(driverEndDate).format("YYYY/MM/DD"))).slice(0,visible).map((providerAsset, i) => {
                    return(
                        <Assets
                            image={providerAsset.driver_picture}
                            // image={providerAsset.image1}
                            name={providerAsset.name}
                            status='Hide'
                            send='send' 
                            year={providerAsset.year}
                            location={providerAsset.state} 
                            assetId={providerAsset.driver_uniqid} 
                            key={providerAsset.asset_id} 
                            service={providerAsset.service}
                            assetStatus={providerAsset.status}
                            assetAvailability={providerAsset.availability}
                            bookStatus={providerAsset.book_status}
                            uniqueId={providerAsset.driver_uniqid}
                            driverId={providerAsset.id}
                            firstName={providerAsset.firstname}
                            lastName={providerAsset.lastname}
                        />
                    )
                
                })) :

                (findDriver?.slice(0,visible).map((providerAsset, i) => {
                    return(
                        <Assets
                            image={providerAsset.driver_picture}
                            // image={providerAsset.image1}
                            name={providerAsset.name}
                            status='Hide'
                            send='send' 
                            year={providerAsset.year}
                            location={providerAsset.state} 
                            assetId={providerAsset.driver_uniqid} 
                            key={providerAsset.asset_id} 
                            service={providerAsset.service}
                            assetStatus={providerAsset.status}
                            assetAvailability={providerAsset.availability}
                            bookStatus={providerAsset.book_status}
                            driverId={providerAsset.id}
                            uniqueId={providerAsset.driver_uniqid}
                            firstName={providerAsset.firstname}
                            lastName={providerAsset.lastname}
                        />
                    )
                
                }))
            }
        </div>


        {
            !findDriver?.length || findDriver?.length <= visible ? ('') : (
                <div className="w-full flex items-center justify-center my-20">
                    <button 
                        onClick={showMore}
                        className='border border-theme-color theme-color font-semibold p-3 text-sm rounded-lg w-36'>Load More
                    </button>
        
                </div>
            )
        }
    </main>


        {/*CALENDAR SECTION*/}
        <Subheader text='My Calendar' />
        <main id='calendar' className='md:px-40 px-12 my-16 w-full provider-calendar-section' >
            <p className='text-white text-lg font-semibold w-full md:w-2/4 calendar-header-text' >All crossed out dates indicates your bookings and you can manually pick off days    </p>

            <div className="flex flex gap-4 justify-between mt-12">
                <h4 className="text-white text-base font-bold">Dates Booked</h4>

                <div className="flex gap-3 w-full md:w-2/5 calendar-subheader-info">

                    <div className="w-1/2 p-2 md:px-3  items-center justify-center bg-amber-500 text-black rounded-md flex">
                        <p className=' md:mr-4 text-center md:font-bold font-semibold text-xs md:text-base'>{allCalendar.length} days Booked</p>
                    </div>

                    <div className=" w-1/2 p-2 md:px-3 border justify-between text-white rounded-md border-white flex items-center">
                        <p className='mr-1 text-center md:font-bold font-semibold text-xs md:text-base'>Next 3 Months</p>
                        <img src={one} alt="" className='w-4 md:w-5' />
                    </div>

                </div>



            </div>

            <div className="w-full flex md:flex-row flex-col gap-8 justify-between my-20 calendar-list-wrapper">
                <div className="md:w-4/12 w-full calendar-list">
                    <Calendar
                        value={allCalendar}
                        // onChange={setDates}
                        multiple
                        sort
                        format={format}
                        calendarPosition="bottom-center"
                        className='bg-dark cal-dar'
                        // plugins={[<DatePanel />]}
                    />
                </div>
                <div className="md:w-4/12 w-full calendar-list">
                    <Calendar
                        value={allCalendar}
                        // onChange={setDates}
                        multiple
                        sort
                        format={format}
                        calendarPosition="bottom-center"
                        className='bg-dark cal-dar'
                        // plugins={[<DatePanel />]}
                    />
                </div>
                <div className="md:w-4/12 w-full calendar-list">
                    <Calendar
                        value={allCalendar}
                        // onChange={setDates}
                        multiple
                        sort
                        format={format}
                        calendarPosition="bottom-center"
                        className='bg-dark cal-dar'
                        // plugins={[<DatePanel />]}
                    />
                </div>

            </div>
        </main>


    </div>);
}

export default ServiceProviderDashboard;