import React from 'react';
import Header from '../Header'
import Navigation from './Navigation'
import { useParams } from 'react-router-dom'
import { BASE_URL } from "../utils/constant"

import dashboard from '../../Images/dashboard.png'
import admin_request from '../../Images/admin_request.svg'
import service_p from '../../Images/service_p.svg'
import reports from '../../Images/reports.svg'
import customer from '../../Images/customers.png'
import notifications from '../../Images/notifications.png'
import download from '../../Images/download.svg'
import car_asset from '../../Images/car_asset.svg'
import icon from '../../Images/icon.svg'
import car from '../../Images/car.png'
import message from '../../Images/message.svg'
import payment from '../../Images/payment.png'
import naira from '../../Images/naira.png'
import one from '../../Images/one.png'
import { NavLink, useNavigate } from "react-router-dom";
import VehicleApprovalComponent from './VehicleApprovalComponent'
import roadlersRR from '../../Images/RoadlersRR.svg'


import { useState, useEffect, useRef } from "react";
import Switch from "react-switch";
import axios from "axios";
import Spin from '../../Images/spinner.gif';
import { useFormik } from 'formik'
import * as Yup from 'yup'
import VehicleApprovalEditComponent from './VehicleApprovalEditComponent';
import getCookie from '../utils/SecuredTokens';

function PendingApprovalEdit() {

    const params = useParams()

  
   

    let userData = getCookie("user");
    let userObject = JSON.parse(userData) 
    let token = userObject?.token
    // let id = userObject?.id

    let id = JSON.parse(localStorage.getItem("userId"))

    // SERVICE DASHBOARD LOADER
    const [dashboardLoad, setDashboardLoad] = useState(true)
    const [vehicleDataSet, setVehicleDataSet] = useState([])

    const getProvider = () => {
        axios({
            method: 'GET',
            url: `${BASE_URL}/v1/pending-approval-vehicle/${id}`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            }
        })
        .then(function (response) {
            const vehicle = response.data;
            setVehicleDataSet(vehicle.data)
            setDashboardLoad(false)
        })
        .catch(function (error) {

        });
    }

    useEffect(() => {
        getProvider()
    }, [])

    return (
    dashboardLoad ? 
    <div className='h-[100vh] w-full flex justify-center items-center'>
        <div class="lds-ring">
            <img src={roadlersRR}  alt="roadlers logo" className='embed-logo'/>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
    :
    <div>
        <Header user='provider' />


        <main className='flex flex-col md:flex-row md:px-12 px-12 overall-approval-page'>
            {/* <button className='mt-40 p-2 bg-blue-900 rounded-md text-sm font-normal text-white'>Pending Approval</button> */}

            <Navigation />

            {
                vehicleDataSet?.filter(obj => obj.asset_id.includes(params.vehicleId)).map((vehicleItem, index) => {
                    return (
                        <VehicleApprovalEditComponent 
                            key={index}
                            vehicleName={vehicleItem.vehicle_name}
                            vehicleYear={vehicleItem.year}
                            vehicleColor={vehicleItem.vehicle_color}
                            vehicleCountry={vehicleItem.country}
                            vehicleState={vehicleItem.state}
                            vehicleNote={vehicleItem.notes}
                            vehicleType={vehicleItem.vehicle_type}
                            vehicleId={vehicleItem.id}
                            plateNo={vehicleItem.plate_number}
                            editDate={vehicleItem.edit_date}
                            passengerSeats={vehicleItem.passenger_seat}
                            address={vehicleItem.vehicle_address}
                            dailyPrice={vehicleItem.daily_price}
                            airportPrice={vehicleItem.airport_price}
                            monthlyPrice={vehicleItem.monthly_price}
                            hoursAvailable={vehicleItem.hour_available}
                            services={vehicleItem.service}
                            airCondition={vehicleItem.air_con}
                            travelAvailable={vehicleItem.travel_available}
                            vehicleInsurance={vehicleItem.vehicle_insurance}
                            driverAvailable={vehicleItem.come_with_driver}
                            statusDoc={vehicleItem.status}
                            img1={vehicleItem.image1}
                            img2={vehicleItem.image2}
                            img3={vehicleItem.image3}
                            vehicleDoc={vehicleItem.vehicle_document}
                            providerId={vehicleItem.asset_id}
                            driverId={vehicleItem.driver_id}
                            carOwnerId={vehicleItem.carowner_id}
                            carOwnerProfile={vehicleItem.carowner_profile}
                        />
                    )
                })
            }
        </main>
    </div>);
}

export default PendingApprovalEdit;


// Just some styles
const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      paddingTop: 50,
    },
    preview: {
      marginTop: 50,
      display: "flex",
      flexDirection: "column",
    },
    image: { maxWidth: "100%", maxHeight: 320 },
    delete: {
      cursor: "pointer",
      padding: 15,
      background: "red",
      color: "white",
      border: "none",
    },
  };