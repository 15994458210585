import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import BookService from "../services/BookService"

const initialState = {}

export const addBooking = createAsyncThunk(
  "ride/book", 
  async (bookingData) => {
  const res = await BookService.bookRide(bookingData)
  return res.data
})

export const addCart = createAsyncThunk(
  "cart/add", 
  async (bookingData) => {
  const res = await BookService.addToCart(bookingData)
  return res.data
})

const bookingSlice = createSlice({
  name: "booking",
  initialState,
  extraReducers: {
    [addBooking.fulfilled]: (state, action) => {
      state.push(action.payload)
    },
    [addCart.fulfilled]: (state, action) => {
      state.push(action.payload)
    },
  },
})

const { reducer } = bookingSlice
export default reducer
