import React, { useContext } from 'react'
import Header from "../Header"
import { NavLink, useNavigate } from "react-router-dom"
import CustomerContext from '../../Context/CustomerContext'


function Terms() {

    let { terms,handleTerms } = useContext(CustomerContext)

 

    return (
        <div>
            <Header user="customer" />

            <div className="fixed bg-black md:p-6 p-4 md:w-3/12 w-7/12 md:mt-20 md:mb-20 mt-16 mb-24 md:ml-16 ml-8 rounded-md flex justify-center items-center">
                <h4 className="text-white md:text-2xl text-base  font-bold">Terms & Conditions</h4>


            </div>

            <div className='flex gap-10 md:ml-32 ml-8 md:mr-16 mr-8 pt-48 pb-48'>

                < div className='w-2 bg-[#FFA100] min-h-screen ' >

                </div>

                <div className="flex flex-col gap-3 md:ml-32 ml-8 mr-16 w-10/12 ">

                    <div className=" md:w-9/12 w-full text-white leading-8 ">
                        <p className="leading-8 tracking-wide text-sm  text-justify">
                            These Terms and Conditions apply to your use of our Car Rental and Chauffeur Services and relates
                            to the appointment of <strong>ROADLERS LIMITED</strong> to provide Car for hire and Chauffeur Services to you
                            <strong>(Customer)</strong> pursuant to the terms and conditions set out below.
                            Your acceptance of the terms and conditions stated herein shall be governed by and construed in
                            accordance with the laws of the Federal Republic of Nigeria and subject to the exclusive jurisdiction of
                            the Nigerian courts.
                            Acceptance of the terms and conditions of this Agreement is on the basis that you are a person
                            (including representative of an entity) who is able to give the confirmations and representations
                            contained herein. Acceptance of these terms and conditions constitutes your agreement that:
                        </p>
                        <h1 className='text-xl text-white font-bold my-6'>
                            1. Services

                        </h1>
                        <p className="leading-8 tracking-wide text-sm text-justify">
                            Subject to the terms and conditions set forth in this Agreement, and upon Customer’s request,
                            ROADLERS will make a rental car and chauffeur available to the Customer (Car Rental And
                            Chauffeur Services).
                            <br />
                            <br />
                            The Car Rental And Chauffeur Services will be provided by Roadlers to the Customer on a pre-
                            paid basis in accordance with the Customer’s selected plan and the terms and conditions stated
                            herein.
                        </p>

                        <h1 className='text-xl text-white font-bold my-6'>
                            2.  The Customer shall be entitled to select and pay for on a pre-paid basis, any of the plans on a
                            daily, weekly, monthly basis.

                        </h1>


                        <h1 className='text-xl text-white font-bold my-6'>
                            3. Terms

                        </h1>

                        <p>
                            The term of this Agreement will commence on the day the Customer pays for the service and
                            accepts these terms and conditions and shall continue until the expiration of the agreed period.
                            <br />
                            <br />
                            The vehicle will be provided to you for the agreed period, and can be reviewed at any time.
                            <br />
                            <br />
                            Upon refusal to release the vehicle on time, you will be breaking the conditions of this
                            agreement. Extra charges will be incurred daily for every day you have the vehicle after you
                            should have returned it to us.
                        </p>

                        <h1 className='text-xl text-white font-bold my-6'>
                            4. Pricing

                        </h1>
                        <p>
                            Customer agrees that the Car Rental and Chauffeur Services provided under this Agreement
                            are on a prepaid basis. Therefore, payment shall be made by the Customer to Roadlers before
                            the services are deployed by Roadlers to the Customer. Customer shall pay the fees
                            associated with their selected Plan plus any taxes and regulatory fees in effect at the time the
                            Chauffeur Service is provided.
                        </p>


                        <h1 className='text-xl text-white font-bold my-6'>
                            5.   Responsibilities of Parties
                        </h1>

                        <p className='text-base my-6'>
                            Customer Responsibilities. Customer shall:

                        </p>
                        <div className='ml-8'>

                            <ol className='terms' >
                                <li>ensure that the chauffeur to be provided by Roadlers pursuant to this Agreement is
                                    used only for lawful purposes and within Lagos State;</li>

                                <li>
                                    not sell, rent or get rid of the vehicle or any of its parts.
                                </li>
                                <li>
                                    not give or try to give anyone the legal rights to the vehicle or transfer legal ownership
                                </li>
                                <li>
                                    not let anyone work on the vehicle without our permission. If we do give you
                                    permission, we will only give you a refund if you have a receipt for the work, we have
                                    given you permission for.
                                </li>
                                <li>
                                    let us know as soon as you become aware of a fault with the vehicle, or if the vehicle is
                                    stolen or involved in an accident.
                                </li>

                                <li>
                                    not carry any object or substance which, because of its condition or smell, may harm
                                    the vehicle or delay us renting or selling it.
                                </li>
                                <li>
                                    treat the chauffeur with respect and kindness.
                                </li>

                                <li>
                                    not assign the chauffeur to menial or domestic assignments (aside from driving and
                                    cleaning of the vehicle to be utilized for the Chauffeur Services) nor in any way subject
                                    the Chauffeur to any form of corporal punishment; and
                                </li>

                                <li>
                                    provide the day’s itinerary to the chauffeur before commencement of the day’s trip.
                                </li>
                            </ol>
                        </div>

                        <p className='text-base my-6'>
                            ROADLERS Responsibilities. Roadlers shall:

                        </p>

                        <div className='ml-8'>

                            <ol className='terms' >
                                <li>provide the chauffeur and car to the Customer on-demand and pursuant to the terms
                                    and conditions agreed herein;</li>

                                <li>
                                    provide to the Customer prior to deployment, details of the chauffeur to be deployed to
                                    the Customer;
                                </li>
                                <li>
                                    promptly inform the Customer where there is going to be a change of chauffeur and the
                                    reason thereof;
                                </li>
                                <li>
                                    ensure that chauffeurs deployed to the Customer are of good manners, well behaved
                                    and have adequate understanding of road traffic signs and regulations; and
                                </li>
                                <li>
                                    ensure that the chauffeurs deployed under this Agreement are licensed under the
                                    national laws to operate and drive the vehicles that they have been deployed to drive.
                                </li>

                                <li>
                                    have maintained the vehicle to at least the manufacturer’s recommended standard. The
                                    vehicle is road worthy and suitable for you to use at the start of the rental period.
                                </li>

                            </ol>
                        </div>

                        <h1 className='text-xl text-white font-bold my-6'>
                            6.   Independent Contractor
                        </h1>

                        <p>
                            ROADLERS is serving as an independent contractor in providing the Services under this
                            agreement. Roadlers and its affiliates, directors, members, employees or agents, are neither
                            employees nor partners of the Customer.
                        </p>

                        <br />

                        <p>
                            Neither party to this Agreement shall assume directly or indirectly, any liability of or for the other
                            party. Neither party shall have the authority to bind or obligate the other party nor neither party
                            shall represent that it has such authority.
                        </p>



                        <h1 className='text-xl text-white font-bold my-6'>
                            7.   Non-Solicitation
                        </h1>

                        <p>
                            The Customer agrees that during the period commencing from the Effective Date and after the
                            expiration of the agreed period, Customer shall not directly or indirectly, personally or through
                            others, solicit or attempt to solicit affiliates, members, employees or agents of Roadlers.
                        </p>


                        <h1 className='text-xl text-white font-bold my-6'>
                            8.   Representations and Warranties
                        </h1>

                        <p>Representations and Warranties of Roadlers. Roadlers hereby represents and warrants to
                            Customer that:</p>

                        <div className='ml-8'>

                            <ol className='terms' >
                                <li>on the date of this Agreement, it has been duly incorporated and validly exists under
                                    the laws of the Federal Republic of Nigeria and Roadlers has the corporate power and
                                    authority to own, hold, use, operate its trade and assets to carry on its business, as
                                    currently conducted in accordance with the terms of this Agreement.</li>

                                <li>
                                    it has the necessary skills, knowledge, experience, expertise, capital, net worth,
                                    adequate and competent personnel, systems and procedures to discharge its
                                    obligations under this Agreement.
                                </li>
                                <li>
                                    it has complied with all the laws, rules, authorizations issued by the Federal, State and
                                    Local authorities in Nigeria and taken all required permissions for performing under this
                                    Agreement.
                                </li>
                                <li>
                                    it has the corporate power and authority to execute, deliver and perform this Agreement
                                    and all corporate actions on the part of the board of directors or shareholders,
                                    necessary for the authorization, execution, delivery of and the performance of all its
                                    obligations under this Agreement have been taken.
                                </li>
                                <li>
                                    it has obtained and shall continue to hold all necessary approvals, consents of third
                                    parties, permits and agreements required for the provision of the Services to enable it
                                    to discharge its obligations under this Agreement and the exercise by Customer of the
                                    rights granted by Roadlers to Customer.
                                </li>



                            </ol>
                        </div>

                        <p className='my-6'>Representations and Warranties of Customer. Customer hereby represents and warrants to
                            Roadlers that:</p>


                        <div className='ml-8'>

                            <ol className='terms' >
                                <li>it has the requisite power and authority to execute, deliver and perform its obligations
                                    under this Agreement;</li>

                                <li>
                                    it will make all payments promptly as and when required; and
                                </li>
                                <li>
                                    it will abide by this Agreement, including all applicable terms of use; and also abide by
                                    all applicable laws.
                                </li>



                            </ol>
                        </div>

                        <p className='my-6'><strong>Disclaimer.</strong> To the fullest extent permitted by law, customer agrees that the services are being
                            provided “as is” and “as available,” and except for the warranties expressly set forth in this
                            agreement, Roadlers makes no other warranties, express or implied, including but not limited to
                            the implied warranties of merchantability, non-infringement, and fitness for a particular purpose
                            and any warranties arising from a course of dealing or usage in trade, together with similar
                            warranties, whether arising under any law or otherwise. In the event Roadlers may not disclaim
                            any such warranty as a matter of law, the scope and duration of such warranty shall be limited
                            to the fullest extent permitted by law. Roadlers further does not represent or warrant that any
                            portion of the services will always be available, accessible, uninterrupted, timely, secure,
                            continuous, or error-free.</p>



                        <h1 className='text-xl text-white font-bold my-6'>
                            9.   Protection of Property
                        </h1>

                        <p>The protection and security of a Customer’s property during the performance of the Car Rental
                            and Chauffeur Services shall be the responsibility of the Customer. The Customer shall
                            therefore ensure the protection and security of its property during the performance of these
                            services. Roadlers shall not be liable for any claims by Customer for theft or loss of property in
                            connection with the performance of the Chauffeur Services by a chauffeur provided pursuant to
                            this Agreement.</p>






                        <h1 className='text-xl text-white font-bold my-6'>
                            10.   Conditions for using the vehicle
                        </h1>

                        <p>Customer shall not rent the vehicle to anyone else. Customer must also not use the vehicle or
                            let someone else use it:</p>


                        <div className='ml-8'>

                            <ol className='terms' >
                                <li>For any illegal purposes or in a way which would cause nuisance;</li>
                                <li>To carry passengers for a fee;</li>
                                <li>For driving lessons;</li>
                                <li>To tow or push any vehicle, trailer or other object, without our written permission;</li>
                                <li> For racing, pace making, or to test the vehicle’s reliability or speed;</li>
                                <li>Off roads or on roads unsuitable for the vehicle;</li>
                                <li>Outside the area of operation agreed, unless we have given you written permission;</li>
                                <li>If it is loaded beyond the manufacturer’s maximum weight recommendations;</li>
                                <li>To carry unsecured loads;</li>
                                <li>To carry more passengers than the vehicle was manufactured to legally carry.</li>



                            </ol>
                        </div>


                        <h1 className='text-xl text-white font-bold my-6'>
                            11.  Insurance
                        </h1>

                        <p>By signing this document, you are accepting the conditions of our motor insurance. We have a
                            legal responsibility to have comprehensive motor insurance. Cover for damage to property is
                            limited to insurers terms of condition. You may arrange your own motor insurance to cover your
                            legal responsibility for any loss or accident for the full period you are renting the vehicle for.</p>



                        <h1 className='text-xl text-white font-bold my-6'>
                            12.  Indemnification
                        </h1>

                        <p>
                            Customer shall indemnify, defend and hold harmless Roadlers from and against any and all
                            loss, claim, liability, damage, cost or expense (including, without limitation, court costs and
                            reasonable attorneys&#39; fees) arising in connection with the receipt and use of the Car and
                            Chauffeur Services or in any way related to Customer’s performance under this agreement,
                            including, but in no way limited to, a breach by Customer of its representations and
                            warranties, except those losses, claims, liabilities, damages, costs or expenses arising out of
                            the willful misconduct of Roadlers, its employees, agents or other representatives.</p>


                        <h1 className='text-xl text-white font-bold my-6'>
                            13.  Limitation of Liability
                        </h1>

                        <p>
                            Roadlers shall not be liable to Customer for any incidental, indirect, special, punitive or
                            consequential damages of any kind, including, but not limited to, any loss of use, loss of
                            business or loss of profit. Any Roadlers liability to Customer for any damages of any kind
                            under this Agreement shall not exceed, in amount, a sum equivalent to half of the amounts
                            payable by customer to Roadlers under a particular subscribed service. Remedies under
                            this agreement are exclusive and limited to those expressly described in this agreement.
                        </p>


                        <h1 className='text-xl text-white font-bold my-6'>
                            14.  Privacy
                        </h1>

                        <p>
                            Roadlers privacy policy is available at and incorporated by reference. Roadlers takes its
                            Customer’s privacy seriously and shall use information provided by Customer in accordance
                            with the terms contained herein and in the Site’s privacy policy available at roadlers.com.
                            Roadlers endeavors to use commercially reasonable technical and operational safeguards
                            designed to protect the Customer’s Conﬁdential Information from unauthorized use or
                            disclosure.
                        </p>
                        <br />
                        <p>
                            For the purpose of this Agreement, Confidential Information means any proprietary and
                            confidential information disclosed, in the course of and during the Term of the Agreement, by
                            one party <strong>(the Disclosing Party)</strong> to another party <strong>(the Receiving Party)</strong>
                        </p>


                        <h1 className='text-xl text-white font-bold my-6'>
                            15.  Termination
                        </h1>

                        <p>
                            Either party shall have the right to terminate this Agreement at anytime upon giving the other
                            party at least fifteen (15) days’ notice.
                        </p>
                        <br />
                        <p>
                            Notwithstanding any other provision of this Agreement, this Agreement shall automatically
                            terminate at the end of the Customer’s subscribed plan where the Customer has refused to
                            renew Customer’s subscription at the end of a current subscription.
                        </p>
                        <br />
                        <p>
                            Roadlers shall have the exclusive right to terminate this Agreement without notice where
                            Customer is in breach of any of the material terms of this agreement or where Customer is
                            alleged to have assaulted, caused bodily injury, threatened the life of, created unsafe working
                            conditions for, any of Roadlers employees, agents, members, or representatives as the case
                            may be and, in this case, Customer shall not be entitled to a refund.
                        </p>

                        <br />
                        <p>
                            Where Customer intends to terminate this agreement as a result of Roadlers inability to meet
                            performance standards in the discharge of the Car Rental and Chauffeur Services, the
                            Customer shall in the first instance, report dissatisfaction with the Car Rental And Chauffeur
                            Services provided to Roadlers management team. Where Roadlers has within seven (7) days
                            failed to attend to Customer’s complaint by remedying the poor service, the Customer shall
                            have the right to terminate this Agreement and shall, in that case, be entitled to a refund of the
                            remaining part of the subscribed fee by Roadlers.
                        </p>



                        <h1 className='text-xl text-white font-bold my-6'>
                            16.  Force Majeure
                        </h1>

                        <p>
                            If Roadlers performance of any obligation under this Agreement is prevented, restricted or
                            interfered with by causes including failure or malfunction of Customer-supplied equipment,
                            acts of God, pandemics or epidemics, explosions, vandalism, cable cuts, storms, fires, floods
                            or other catastrophes, power failure, national emergencies, insurrections, riots, wars, strike,
                            lockouts, boycotts, work stoppages or other labor difficulties, or any law, order, regulation or
                            other actions of any governmental authority, agency, instrumentality, or of any civil or military
                            authority, then Roadlers shall be excused from such performance on a day-to-day basis to the
                            extent of such restriction or interference. Roadlers shall use commercially reasonable efforts
                            under the circumstances to avoid or remove such causes of nonperformance with reasonable
                            dispatch. If such occurrence occurs for a period of at least sixty (60) days, then Customer shall
                            have the option to terminate the affected portion of this Agreement, without liability.
                        </p>


                        <h1 className='text-xl text-white font-bold my-6'>
                            17.  Survival of Claims and Obligations
                        </h1>

                        <p>
                            The termination of this Agreement does not affect any claim or obligation that a party may have
                            at the date of termination.
                        </p>

                        <h1 className='text-xl text-white font-bold my-6'>
                            18.  Notices
                        </h1>

                        <p>
                            For the purpose of this Agreement,
                        </p>


                        <div className='ml-8'>

                            <ol className='terms' >
                                <li>Roadlers address shall be:<br />
                                    Address: 2 nd Floor, Suite 205, Edo House,<br />
                                    Plot 1225 Bishop Oluwole Str,<br />
                                    Victoria Island, Lagos<br />
                                    Telephone: +234 904 444 2200<br />
                                    Email: support@roadlers.com</li>
                                <li>Customer’s address shall be provided on the Customer’s Application Form or may be
                                    provided in time to Roadlers</li>


                            </ol>
                        </div>

                        <br />

                        <p>
                            Notices shall be sent by registered post, courier or other electronic transmission; and if posted,
                            shall be deemed to have been received 3 business days after the date of posting or, in the case
                            of a facsimile or other electronic transmission, upon confirmation of complete receipt being
                            given by the intended recipient party; or if couriered, on delivery.
                        </p>



                        <h1 className='text-xl text-white font-bold my-6'>
                            19.  Service Availability Parameters
                        </h1>

                        <p>
                            Telephone support. Roadlers shall provide telephone support for the Customer from 8:00 A.M.
                            to 6:00 P.M. (Monday – Friday). Calls received out of office hours and during weekends or
                            national or state public holidays will be recorded, and best efforts will be made to answer/ action
                            the call.
                        </p>

                        <br />


                        <p>
                            Email support. Roadlers will provide monitored email services to Customer from 8:00 A.M. to
                            6:00 P.M. (Monday – Friday). Emails received outside of office hours or during weekends and
                            public holidays will be collected, however no action can be guaranteed until the next working
                            day.
                        </p>





                        <h1 className='text-xl text-white font-bold my-6'>
                            20.  Dispute Resolution
                        </h1>

                        <p>
                            In the event of any dispute, difference or claim arising out of or in connection with this
                            Agreement, the parties shall meet in good faith, within a month of the knowledge of the dispute,
                            for negotiations in an effort to resolve the dispute amicably.
                        </p>

                        <br />


                        <p>
                            If the dispute is not settled by negotiation within 14 days of commencement of such
                            negotiations or within a longer period as agreed to by the parties, the dispute, difference or
                            claim shall be referred to the Lagos Multi-Door Courthouse (LMDC) for mediation which shall be
                            concluded in accordance with the LMDC Mediation Procedure Rules or other rules mutually
                            agreed by the parties.
                        </p>


                        <br />

                        <p>
                            If the parties fail to reach a settlement via mediation within 14 days, the matter shall be settled
                            by arbitration in accordance with the Arbitration and Conciliation Act, Laws of the Federation of
                            Nigeria (LFN) 1990. The Arbitration shall be conducted by a sole arbitrator to be appointed by
                            the LMDC. Provided always that the parties may by mutual agreement extend the period of time
                            specified in this agreement or opt for the appointment of three arbitrators as against a Sole
                            Arbitrator if considered desirable. Such appointment shall be in accordance with the Arbitration
                            and Conciliation Act, LFN, 1990.
                        </p>



                        <h1 className='text-xl text-white font-bold my-6'>
                            21.  Governing Law and Jurisdiction
                        </h1>

                        <p>
                            The provisions of this Agreement shall be governed by and construed in accordance with the
                            laws of the Federal Republic of Nigeria.
                        </p>

                        <h1 className='text-xl text-white font-bold my-6'>
                            22.  General
                        </h1>

                        <p>
                            Technical Support. Roadlers shall not be responsible for any charges incurred by Customer for
                            Customer’s engagement of a third party to provide Car Rental And Chauffeur Services, whether
                            related or unrelated to the Services.
                        </p>
                        <br />


                        <p>
                            Assignability. Without first obtaining Roadlers express, written consent, Customer shall not
                            assign or otherwise transfer (including without limitation, a transfer due to a change of control),
                            whether voluntarily, involuntarily or by operation of law, its rights or obligations under this
                            Agreement.
                        </p>

                        <br />


                        <p>
                            Relationship of the parties. Nothing in this Agreement will create, or shall be construed to create
                            any partnership, joint venture, agency, franchise, sales representative or employment
                            relationship between the parties.
                        </p>
                        <br />

                        <p>
                            Severability. If any provision of this agreement is held to be unenforceable, the parties shall
                            substitute for the affected provision an enforceable provision which approximates the intent and
                            economic effect of the affected provision and the remaining provisions of this Agreement will be
                            unimpaired and will remain in full force and effect.
                        </p>
                        <br />

                        <p>
                            Waiver, Remedies Non-Exclusive. No failure of delay on the part of any party in exercising any
                            right or remedy provided in this Agreement will operate as a waiver thereof; nor will any single
                            or partial exercise of or failure to exercise any such right or remedy preclude any other or
                            further exercise thereof or the exercise of any other right or remedy provided herein or at law or
                            in equity. Except as expressly provided herein, no remedy specified in this Agreement is
                            intended to be exclusive of any other remedy, and each and every remedy will be cumulative
                            and in addition to every other right or remedy provided herein or available at law or in equity.
                        </p>
                        <br />

                        <p>
                            Applicable Law. This Agreement shall be governed by and construed in accordance with the
                            laws of the Federal Republic of Nigeria.
                        </p>
                        <br />

                        <p>
                            Headings. The headings of the sections, subsections, and paragraphs of this Agreement are
                            inserted for convenient reference only and are not intended to be part of or to affect the
                            meaning or interpretation of this Agreement.
                        </p>
                        <br />

                        <p>
                            No Third-Party Beneficiaries. This Agreement shall be binding upon and shall inure to the
                            benefit of the parties hereto and their respective heirs, successors, legal representatives and
                            permitted assigns. Nothing in this Agreement, express or implied, is intended to confer upon
                            any party other than the parties hereto (and their respective heirs, successors, legal
                            representatives and permitted assigns) any rights, remedies, liabilities or obligations under or
                            by reason of this agreement.
                        </p>
                        <br />
                        <p>
                            Entire Agreement. This Agreement constitutes the entire understanding and agreement
                            between the parties related to the subject matter hereof and shall supersede and/or replace any
                            and all prior or contemporaneous oral and written communications.
                        </p>
                        <br />
                        <p>
                            Amendments/Counterparts. This Agreement may only be amended by a writing signed by both
                            of the parties. This Agreement may be executed in multiple counterparts. Facsimiles of party’s
                            authorized representative’s signature shall be deemed to be binding upon such party, unless
                            otherwise prohibited by law.
                        </p>
                    </div>

                    <div className="flex justify-end text-center md:w-9/12 w-full mt-24">

                        {terms === true
                            && <button className="font-semibold rounded bg-amber-500 text-black p-2 md:w-[80px] w-5/12" >
                                Agreed
                            </button>}

                        {terms === false
                            && <button  onClick={(e)=>handleTerms(e)} className="font-semibold rounded bg-white text-black p-2 md:w-[80px] w-5/12" >
                                Agree
                            </button>}


                    </div>
                </div>

            </div>
        </div>
    )
}

export default Terms