import apiRequest from "../http-common"

const bookRide = (bookingData) => {
  return apiRequest.post("/add-booking", bookingData)
}

const addToCart = (bookingData) => {
  return apiRequest.post("/add-to-cart", bookingData)
}

const BookService = {
  bookRide,
  addToCart
}

export default BookService
