import { configureStore } from "@reduxjs/toolkit"
import authReducer from "./slices/auth"
import messageReducer from "./slices/message"
import notifReducer from "./slices/notif"
import badgeReducer from "./slices/badge"
import carInitReducer from "./slices/carInit"
import  serviceOptionReducer from "./slices/serviceOption"
import  serviceUserReducer from "./slices/serviceUser"
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import thunkMiddleware from 'redux-thunk';

const reducers = combineReducers({
  auth: authReducer,
  message: messageReducer,
  notif: notifReducer,
  badge: badgeReducer,
  serviceOption: serviceOptionReducer,
  serviceUser: serviceUserReducer,
  carInit: carInitReducer
});
const persistConfig = {
  key: 'root',
  storage
};
const persistedReducer = persistReducer(persistConfig, reducers);
const store = configureStore({
  reducer: persistedReducer,
  devTools: false,
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware(
    {
      serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
  }
  ),
})
export default store

// Store will just bring Actions and Reducers together and hold the Application state.

// configureStore() is a constant that handles middleware automatically... basically just documentation stuff to hold all reducers created in the ../slices folder, nothing else.
