import axios from 'axios'
import { BASE_URL } from "./constant"
import getCookie from './SecuredTokens';

export const baseUrl = BASE_URL

export const fetchData = async (url) => {
    // let users = getCookie("user");
    // let userObject = JSON.parse(users)
    // let token = userObject?.token
    // let id = userObject?.id


    let users = getCookie("user");
    let userObject;
    let token;
    users? userObject = JSON.parse(users):userObject=''
    users? token = JSON.parse(users)?.token:token=''

    // destructiure data from response
    const { data } = await axios.get((url), {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
        }
    })
    return data;
}