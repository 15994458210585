import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import GiftCardService from '../services/GiftCardService'

const initialState = {}

// Request GiftCard
export const giftCardRequest = createAsyncThunk(
    "giftcard/request",
    async (requestData) => {
    const res = await GiftCardService.requestGiftCard(requestData)
    return res.data
})

// Fetch GiftCard
export const getGiftCard = createAsyncThunk (
  "giftcard/get",
  async () => {
    const res = await GiftCardService.getGiftCardService()
    return res.data
  }
)

// Book Via GiftCard
export const bookViaGiftCard = createAsyncThunk (
  "giftcard/bookVia",
  async (bookingData) => {
    const res = await GiftCardService.useGiftCardBook(bookingData)
    return res.data
  }
)

const GiftCardSlice = createSlice({
    name: "giftcard",
    initialState,
    extraReducers: {
      [giftCardRequest.fulfilled]: (state, action) => {
        state.push(action.payload)
      },

      [getGiftCard.fulfilled]: (state, action) => {
        state.push(action.payload)
      },

      [bookViaGiftCard.fulfilled]: (state, action) => {
        state.push(action.payload)
      },
    },
})





const { reducer } = GiftCardSlice
export default reducer