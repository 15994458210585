import React, { useState, useEffect, useRef, useCallback } from 'react'
import daily_driver from '../../Images/daily_driver.png';
import drivee from '../../Images/drivee.png';
import { BASE_URL } from "../utils/constant"

import locate from '../../Images/locate.png'
import prado from '../../Images/Prado.png'
import clock from '../../Images/clock.png'
import one from '../../Images/one.png'
import axios from "axios";
import car from '../../Images/driver3.png';
import dailyGiftCard from '../../Images/gift_faint.png'
import monthlyGiftCard from '../../Images/gift.png'
import moment from 'moment';
import { useNavigate } from "react-router-dom";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';




function AdminCart(props) {
    const notify = () => { toast("Cart Item Removed!") };
    let navigate = useNavigate()
    const {
        id,
        vehicleId,
        key,
        name,
        title,
        price,
        daily_price,
        total,
        date,
        address,
        image,
        time,
        state,
        days,
        year,
        destination_state,
        receiverEmail,
        receiverName,
        resumption_date,
        setCartUpdate,
        insurance,
        numMonths
    } = props

    let amount = price;
    let tot = total;
    let amountTotal = Number(tot);
    let amountNum = Number(amount);
    let dailyPrice = Number(daily_price);

    const [vehicle, setVehicle] = useState([]);
    const [deleteCart, setDeleteCart] = useState(false);

    let localCart = JSON.parse(localStorage.getItem('cart'));


    const [cart, setCart] = useState(localCart);



    const getCar = async () => {
        await axios.get(`${BASE_URL}/v1/view-vehicle/${vehicleId}`).then((response) => {
            const vehicleAsset = response.data;
            console.log('car details', vehicleAsset)
            setVehicle(vehicleAsset);
        });
    };

    useEffect(
        () => getCar(),
        []
    );

    const RemoveItem = (e) => {
        notify()

        e.preventDefault()

        let localData = localStorage.getItem('cart');
        let newList = JSON.parse(localData)
        console.log(newList)
        let index = newList.findIndex(item => item.id === id)
        newList.splice(index, 1);
        console.log(newList)
        localStorage.setItem('cart', JSON.stringify(newList));
        setCartUpdate((prevState) => !prevState)
        navigate('/cart')



    }


    useEffect(() => {
        setCart({})
        setCart(JSON.parse(localStorage.getItem('cart')))
    }, [setCart])



    let [dayChange, setDayChange] = useState(days);
    let [insuranceChange, setInsuranceChange] = useState(insurance);

    let [amountChange, setAmountChange] = useState(amountTotal);
    let [cartState, setCartState] = useState(cart);

 

    // console.log('titleeeeeeeeeeeeeeeeeeeeeee', cart)

    return (

        <div>
            {/* FOR MOBILE */}
            <div className="md:hidden block px-4 my-12">
                <div className="border cart-bg border-white rounded-xl flex px-3 py-5  h-72">

                    <div className="w-5/12 flex flex-col justify-between">
                        <div className="">
                            {


                                title !== "Full-Time Driver" ?

                                    title === "Daily Driver" ? (
                                        <h3 className='tracking-wider text-white text-base font-normal  pb-1 w-5/6'>{title} </h3>

                                    ) : (
                                        <h3 className='tracking-widest text-white text-xx font-extrabold  pb-1 w-5/6'>{vehicle.vehicle_name + " "}  {"(" + vehicle.year + ")"} </h3>

                                    )
                                    : <h3 className='tracking-widest text-white text-xx font-extrabold  pb-1 w-5/6'>Full Time Driver </h3>

                            }
                            {/* <h3 className='text-white text-xx font-extrabold  pb-1 w-5/6'>{vehicle.vehicle_name + " "}  {"(" + vehicle.year + ")"} </h3> */}

                            <hr className='border-t border-white w-2/4' />
                            <div className="flex items-center mb-2 -ml-2 mt-4 w-full">
                                <img src={locate} alt="" className='w-4 ' />
                                {
                                    destination_state ? (
                                        <p className='tracking-widest text-white text-xxx w-full lading-4'>{address} to {destination_state}</p>
                                    ) : (
                                        <p className='tracking-widest text-white text-xxx w-full lading-4'>{address} </p>
                                    )
                                }

                            </div>
                        </div>

                        <div className="flex w-10/12 p-2 rounded-md bg-white h-24 items-center justify-center">
                            {
                                !receiverEmail ?
                                    <>
                                        {
                                            vehicleId ?
                                                <div className="flex   ">
                                                    <img src={car} alt="" className='w-full h-5/6' />
                                                </div> :
                                                <div className="flex  ">
                                                    <img src={title === 'Daily Driver' ? daily_driver : drivee} alt="" className='w-full h-5/6' />
                                                </div>

                                        }
                                    </>
                                    :
                                    <div className="flex bg-project p-2 rounded  ">
                                        <img src={title === 'Daily Driver' ? dailyGiftCard : monthlyGiftCard} alt="" className='w-full' />
                                    </div>

                            }
                        </div>
                        <button
                            onClick={RemoveItem}

                            className="tracking-widest bg-transparent border border-amber-500 text-amber-500 rounded-md px-3 py-1 w-16 font-normal text-xx mt-8"
                        >
                            Remove

                        </button>
                    

                    </div>

                    <div className="w-5/12  flex flex-col justify-start items-baseline">
                        <h3 className='text-white text-xx font-extrabold  pb-1 w-full tracking-widest'>Booking Details</h3>
                        <hr className='border-t border-white w-2/4' />


                        <div className="mt-10 flex  items-center  text-xx bg-transparent rounded-md border border-white w-11/12 text-center py-2 focus:outline-none text-white">

                            {!numMonths ?

                                <>

                                    <label className="text-xx bg-transparent  ml-1 mr-1 tracking-widest" >{dayChange < 1 ? 1 : dayChange}</label>
                                    <p className=' text-xx tracking-widest'>{dayChange < 2 ? ' Day' : ' Days'} </p>

                                   
                                </> :

                                <>
                                    <label className="text-xx bg-transparent  ml-1 mr-1 tracking-widest" >{numMonths < 2 ? numMonths + ' Month' : numMonths + ' Months'}</label>
                                   

                                </>

                            }


                        </div>

                        <div className="flex items-center gap-2 mt-4">
                            <img src={one} alt="" className='w-5' />
                            <h4 className='text-white font-semibold text-xx tracking-widest'>{moment(date).format("DD/MM/YYYY")}</h4>
                        </div>

                        <div className="flex items-center gap-2 mt-2 -ml-1">
                            <img src={clock} alt="" className='w-7' />
                            <h4 className='text-white font-semibold text-xx tracking-widest'>{moment(time).format("hh:mm a")}</h4>
                        </div>

                        <h4 className='text-white font-normal text-xx mt-2 tracking-widest'>{state}</h4>


                        <h3 className='text-white text-xx font-extrabold  pb-1 w-full mt-8 tracking-widest'>Sub-total</h3>

                    </div>

                    <div className="w-3/12  flex flex-col">
                        <h3 className='text-white text-xx font-extrabold  pb-1 w-full tracking-wide'>Price per Day</h3>
                        <hr className='border-t border-white w-2/4' />
                        {
                            destination_state ? (
                                <p className='text-white mt-8 text-xs ml-3 font-bold tracking-widest'>&#8358; 0.00</p>

                            ) : (
                                <p className='text-white mt-10 text-xs ml-3 font-bold tracking-widest'>
                                    &#8358;{dailyPrice?.toLocaleString('en-US', { maximumFractionDigits: 2 })}
                                </p>
                            )
                        }


                        {
                            destination_state ? (
                                <div className="mt-16">
                                    <p className='text-amber-500 text-xs ml-3 font-bold tracking-widest'>
                                        &#8358;0.00
                                    </p>
                                    <p>Insurance</p>

                                </div>
                            ) : (

                                <div className="mt-16">
                                    <p className='text-amber-500 text-xs ml-3 font-bold tracking-widest'>
                                        &#8358;{insurance?.toLocaleString('en-US', { maximumFractionDigits: 2 })}
                                    </p>
                                    <p className='text-white tracking-wider text-xx ml-3'>Insurance</p>

                                </div>

                            )
                        }
                        {
                            destination_state ? (
                                <p className='text-amber-500 mt-12 text-xs ml-3 font-bold tracking-widest'>&#8358; 0.00</p>

                            ) : (

                                <p className='text-amber-500 mt-12 text-xs ml-3 font-bold tracking-widest'>
                                    &#8358;{Number(total)?.toLocaleString('en-US', { maximumFractionDigits: 2 })}
                                </p>
                            )
                        }
                    </div>
                </div>
            </div>





            {/* for desktop */}
            <div className="hidden md:block lg:px-56 md:px-20 mb-8 ">
                <div className="border cart-bg border-white rounded-2xl flex p-6 gap-1">
                    <div className="w-1/4">
                        {
                            title !== "Full-Time Driver" ?

                                title === "Daily Driver" ? (
                                    <h3 className='tracking-wider text-white text-base font-normal  pb-1 w-5/6'>{title} </h3>

                                ) : (
                                    <h3 className='tracking-wider text-white text-base font-normal  pb-1 w-5/6'>{vehicle.vehicle_name + " "}  {"(" + vehicle.year + ")"} </h3>

                                )
                                : <h3 className='tracking-wider text-white text-base font-normal  pb-1 w-5/6'>Full Time Driver </h3>
                        }



                        <hr className='border-t border-white w-2/4' />
                        <div className="flex items-center mb-4 -ml-2 mt-4 w-full">
                            <img src={locate} alt="" className='w-6 ' />

                            {destination_state ? (
                                <p className='tracking-wider text-white text-xs w-3/4 leading-5'>{address} to {destination_state}</p>

                            ) : (
                                receiverEmail ? (
                                    <p className='tracking-wider text-white text-xs w-3/4 leading-5'>{receiverEmail}</p>

                                ) : (
                                    <p className='tracking-wider text-white text-xs w-3/4 leading-5'>{address}</p>

                                )

                            )}
                        </div>
                        {/* CONDITIONALS TO DETERMINE WHAT PICTURE SHOWS IN THE CART */}
                        {/* {
                            title !== 'Daily Driver || Full-Time Driver' ?
                            <div className="flex bg-white p-4 rounded w-8/12 ">
                                <img src={title === 'Gift Card' ? daily_driver : car} alt="" className='w-32' />
                            </div> : 
                            <div className="flex bg-white p-4 rounded w-8/12 ">
                                <img src={title === 'Daily Driver' ? daily_driver : drivee} alt="" className='w-32' />
                            </div>
                        } */}
                        {
                            !receiverEmail ?
                                <>
                                    {
                                        vehicleId ?
                                            <div className="flex items-center justify-center bg-white p-1 rounded h-40 w-10/12">
                                                <img src={car} alt="" className='w-32 h-28' />
                                            </div> :
                                            <div className="flex   rounded w-10/12 h-36">
                                                <img src={title === 'Daily Driver' ? daily_driver : drivee} alt="" className='w-full' />
                                            </div>

                                    }
                                </>
                                :
                                <div className="flex bg-project rounded w-10/12 ">
                                    <img src={title === 'Daily Driver' ? dailyGiftCard : monthlyGiftCard} alt="" className='w-32' />
                                </div>

                        }
                    </div>

                    <div className="w-1/4  flex flex-col">
                        <h3 className='tracking-wider text-white text-base font-semibold  pb-1 w-5/6 '>Booking Details</h3>
                        <hr className='border-t border-white w-2/4' />

                        <div className="mt-4 flex justify-center px-2 items-center bg-transparent rounded-md border border-white w-8/12 text-center py-2 focus:outline-none text-white">
                            {
                                !numMonths ?
                                    <>
                                        <label className="text-sm bg-transparent " >{dayChange < 1 ? 1 : dayChange}</label>
                                        <p className='mr-3 text-sm'> {dayChange < 2 ? ' Day' : ' Days'} </p>

                                        
                                    </> :
                                    <>
                                        <label className="text-xs bg-transparent  flex" >{numMonths < 2 ? numMonths + ' Month' : numMonths + ' Months'}</label>
                                       
                                    </>

                            }

                        </div>

                        <div className="flex items-center gap-2 mt-4">
                            <img src={one} alt="" className='w-6' />
                            <h4 className='tracking-wider text-white font-semibold text-sm '>{moment(date).format("DD/MM/YYYY")}</h4>
                        </div>

                        <div className="flex items-center gap-2 mt-4">
                            <img src={clock} alt="" className='w-8 -ml-1' />
                            <h4 className='tracking-wider text-white font-semibold text-sm'>{moment(time).format("hh:mm a")}</h4>

                        </div>

                        <h4 className='tracking-wider text-white font-normal text-sm mt-4'>{state}</h4>

                        <ToastContainer position="top-left" />

                        <button
                            onClick={RemoveItem}
                            className="tracking-wider bg-transparent border border-amber-500 text-amber-500 rounded-md px-3 py-1 w-24 font-normal text-xs mt-4">
                            Remove
                        </button>

                    </div>

                    <div className="w-1/4  flex flex-col">
                        <h3 className='tracking-wider text-white text-base font-semibold  pb-1 w-5/6'>Price per Day</h3>
                        <hr className='border-t border-white w-2/4' />
                        {destination_state ? (
                            <p className='tracking-wider text-white mt-8 text-base'>&#8358;0</p>

                        ) : (
                            <p className='tracking-wider text-base text-white mt-8'>&#8358;{dailyPrice?.toLocaleString('en-US', { maximumFractionDigits: 2 })}</p>

                        )}

                        {destination_state ? (
                            <div className="mt-16">
                                <p className='text-white text-xs  font-semibold tracking-wider'>
                                    &#8358;0.00
                                </p>
                                <p className='text-amber-500 tracking-wider text-sm '>Insurance</p>

                            </div>

                        ) : (
                            <div className="mt-16">
                                <p className='text-white text-base  font-semibold tracking-wider'>
                                    &#8358;{Number(insurance)?.toLocaleString('en-US', { maximumFractionDigits: 2 })}
                                </p>
                                <p className='text-amber-500 tracking-wider text-sm '>Insurance</p>

                            </div>
                        )}




                    </div>

                    <div className="w-1/4  flex flex-col">
                        <h3 className='tracking-wider text-white text-base font-semibold  pb-1 w-5/6'>Sub-total</h3>
                        <hr className='border-t border-white w-2/4' />

                        {destination_state ? (
                            <p className='tracking-wider text-amber-500 mt-8'>&#8358;0</p>


                        ) : (
                            <p className='text-amber-500 mt-8 tracking-wider'>

                                &#8358;{Number(total)?.toLocaleString('en-US', { maximumFractionDigits: 2 })}
                            </p>


                        )}

                    </div>
                </div>

            </div>
        </div>
    )
}

export default AdminCart