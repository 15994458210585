import React, { Fragment, useState, useEffect, useContext, useCallback,memo } from 'react'
import { Menu, Dialog, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import Header from '../Header'
import Screen from './ScreenA'
import { BASE_URL } from "../utils/constant"

import dashboard from '../../Images/dashboard.png'
import admin_request from '../../Images/admin_request.svg'
import service_p from '../../Images/service_p.svg'
import reports from '../../Images/reports.svg'
import customer from '../../Images/customers.png'
import notifications from '../../Images/notifications.png'
import download from '../../Images/download.svg'
import car_asset from '../../Images/car_asset.svg'
import icon from '../../Images/icon.svg'
import car from '../../Images/car.png'
import message from '../../Images/message.svg'
import roadlersRR from '../../Images/RoadlersRR.svg'

import { NavLink, useParams } from "react-router-dom";
import { NavHashLink as Link } from "react-router-hash-link";

import axios from "axios";
import getCookie from '../utils/SecuredTokens'
import { fetchData } from '../utils/fetchData'

function Screenassets() {

    function ScrollToTopOnMount() {
        useEffect(() => {
            window.scrollTo(0, 0);
        }, []);

        return null;
    }
    const { id } = useParams();




    let userr = getCookie("user");
    let userObject = JSON.parse(userr)
    let token = userObject?.token

    const [assets, setAssets] = useState([]);
    const [assetResult, setAssetResults] = useState(assets);
    const [vehicleDoc, setVehicleDoc] = useState();

    const [vehImageDisplay, setVehImageDisplay] = useState('');

    // SCREEN ASSET LOADER
    const [dashboardLoad, setDashboardLoad] = useState(true)




    useEffect(() => {
        let subscribed = true;
        const getAssets = async () => {
            let allVehicle = await fetchData(`${BASE_URL}/v1/fetch-vehiclebyid/${id}`);

            if (subscribed) {
                setAssets(allVehicle.data);
                setAssetResults(allVehicle.data);
                setVehImageDisplay(allVehicle.data.display)
                setDashboardLoad(false)
            }
        }
        getAssets()

        // clean up the function
        return () => {
            subscribed = false;
        }
    }, [id])



    return (

        dashboardLoad ?
            (<>
                <ScrollToTopOnMount />

                <div className='h-[100vh] w-full flex justify-center items-center'>
                    <div className="lds-ring">
                        <img src={roadlersRR} alt="roadlers logo" className='embed-logo' />
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </>
            ) :
            (<div>
                <Header user='admin' />


                <main className='flex  md:pt-20 md:px-12 px-0 flex-col md:flex-row w-full'>
                    <div className='border-r  border-amber-500 h-100 xl:w-2/12 md:w-3/12  pb-16 hidden md:block'>
                        <button className='mt-12 p-2 bg-blue-900 rounded-md text-sm font-normal text-white'>Pending Approval</button>
                        <div className='mt-6 my-3'>
                            <span className='flex items-center text-white'>
                                <img src={dashboard} alt="" className='mr-3 w-8' />
                                <Link to="/Admin" className='font-light'>Admin Dashboard </Link>

                            </span>
                        </div>

                        <div className='my-3'>
                            <span className='flex items-center text-white'>
                                <img src={admin_request} alt="" className='mr-3 w-8' />

                                <Link to="/Admin#request" className='font-light'>Request </Link>
                            </span>
                        </div>

                        <div className='my-3'>
                            <span className='flex items-center text-white'>
                                <img src={car_asset} alt="" className='mr-3 w-8' />

                                <Link to="/Admin#assets" className='font-light'>Assets </Link>
                            </span>
                        </div>

                        <div className='my-3'>
                            <span className='flex items-center text-white'>
                                <img src={service_p} alt="" className='mr-3 w-8' />

                                <Link to="/Admin#providers" className='font-light'>Service Providers </Link>
                            </span>
                        </div>

                        <div className='my-3'>
                            <span className='flex items-center text-white'>
                                <img src={customer} alt="" className='mr-3 w-8' />
                                <a href="" className='font-light'></a>
                                <Link to="/Admin#customers" className='font-light'>Customers </Link>

                            </span>
                        </div>

                        <div className='my-3'>
                            <span className='flex items-center text-white'> <img src={reports} alt="" className='mr-3 w-8' /> <a href="" className='font-light'>Reports</a></span>
                        </div>
                    </div>

                    <div className='xl:w-10/12 md:w-9/12 flex xl:px-20 md:px-12 px-8 flex-col  md:flex-row '>

                        {assets?.map((asset, i) => {
                            return (
                                <Screen
                                    // image={asset_one}
                                    image_one={asset.image1}
                                    image_two={asset.image2}
                                    image_three={asset.image3}
                                    image_four={asset.image4}
                                    name={asset.vehicle_name}
                                    year={asset.year}
                                    price={asset.daily_price}
                                    monthly_price={asset.monthly_price}
                                    airport_price={asset.airport_price}
                                    hours={asset.hour_available}
                                    air_con={asset.air_con}
                                    travel_available={asset.travel_available}
                                    vehicle_color={asset.vehicle_color}
                                    insurance_option={asset.insurance_option}
                                    comeWithDriver={asset.come_with_driver}
                                    service={asset.service}
                                    location={asset.state}
                                    country={asset.country}
                                    notes={asset.notes}
                                    vehicle_type={asset.vehicle_type}
                                    vehicle_address={asset.vehicle_address}
                                    passengers={asset.passenger_seat}
                                    plate_number={asset.plate_number}
                                    status={asset.status}
                                    display={asset.display}
                                    user={asset?.user_id}
                                    ownerProfile={asset.carowner_profile}
                                    document={asset.vehicle_document2}
                                    key={asset.id}
                                    id={asset.id}
                                    msg={asset.status_msg}

                                    asset_id={asset.asset_id}
                                />
                            );
                        })}
                    </div >


                </main>
            </div>)
    )
}

export default memo(Screenassets)
