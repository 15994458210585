import React, { Fragment, useState, useEffect, useCallback, useRef, memo } from 'react'
import { Menu, Dialog, Transition } from '@headlessui/react'
import { BASE_URL } from "../utils/constant"

import cartwhite from '../../Images/cart-white.svg';
import daily_driver from '../../Images/daily_driver.png';
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import car from '../../Images/driver3.png';
import ratings from '../../Images/ratings.svg'
import locationicon from '../../Images/location.svg'
import message from '../../Images/message.png'
import mark from '../../Images/mark.png'
import logo_circle from '../../Images/logo-circle.png'
import like from '../../Images/Like-icon.png'
import gift_faint from '../../Images/gift_faint.png'
import drivee from '../../Images/drivee.png'
import prev from '../../Images/prev.png'
import onee from '../../Images/onee.png'
import next from '../../Images/next.png'
import user from '../../Images/userg.png'
import send from '../../Images/send.png'
import jeep from '../../Images/jeep.jpg'
import kia from '../../Images/kia.jpg'
import interior from '../../Images/interior.png'
import like_two from '../../Images/Like-icon2.png'
import TopForm from '../TopForm'

import clockb from '../../Images/clockb.png'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import axios from "axios";
import Spin from '../../Images/spinner.gif';
import Xmark from '../../Images/xmark.png';
import Vmark from '../../Images/vmark.png';

import { useCookies } from 'react-cookie';
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Cart from './Cart';

import moment from 'moment';

import TimeInput from "react-time-picker-input";
import { RWebShare } from "react-web-share";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
// import { TimePicker } from 'antd';

import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import { GoogleMap, useJsApiLoader, Autocomplete } from '@react-google-maps/api';
import getCookie from '../utils/SecuredTokens';

function CarBookingComponent(props) {

    // const google = window.google;

    const [autocomplete, setAutocomplete] = useState(null)

    const { } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyDkMuW-hGvdRfUhsb2R9RFqA0Pq4Qxg4Cs",
        libraries: ['places'],

    })




    const onPlaceChanged = () => {
        if (autocomplete !== null) {
            const places = autocomplete.getPlace().formatted_address
            setPickupLocation(places)
        }
    }





    // const [token, setToken] = useState('jjj')
    // const [userLoggedIn, setUserLoggedIn] = useState('')

    let userData = getCookie("user");
    let userObject
    let token
    let userLoggedIn
    // check if userData truely exists
    userData ? userObject = JSON.parse(userData) : userObject = ''
    userObject ? token = userObject?.token : token = ''
    userObject ? userLoggedIn = userObject?.id : userLoggedIn = ''


    const onKeyDown = (e) => {
        e.preventDefault();
    };


    let navigate = useNavigate();
    const [load, setLoad] = useState('')


    let { vehicleId,
        serviceProviderId,
        textOne,
        textTwo,
        title,
        year,
        Location,
        price,
        airportPrice,
        image,
        imageTwo,
        imageThree,
        priceTwo,
        p1,
        p2,
        p3,
        p4,
        list,
        airCon,
        vat,
        bookPicture,
        travelAvailable,
        vehicle_color,
        service,
        comeWithDriver,
        firstPrice,
        commissions,
        plateNumber,

    } = props







    console.log('vehicleId', vehicleId)

    localStorage.setItem("service_providerId", JSON.stringify(serviceProviderId))

    const carTitle = title + ' (' + year + ')'
    let amount = price;
    let amountNum = Number(price);

    const refDiv = useRef()






    const [bookings, setBookings] = useState([])

    const [status, setStatus] = useState('Start Booking - Let’s Move')


    const [open, setOpen] = useState(false);

    const [pickupDate, setPickupDate] = useState('')
    const [startDates, setStartDates] = useState(new Date());
    const [min, setMin] = useState(0);

    // set the minimum booking time to 6 hrs away


    //check for today
    let today = new Date();

    let hr = today.getHours();
    let mi = today.getMinutes();



    const { id } = useParams();


    const [calendar, setCalendar] = useState([]);
    const [vId, setVid] = useState(vehicleId)

    const getCalendar = useCallback(async () => {
        await axios({
            method: "get",
            url: `${BASE_URL}/v1/fetch-vehicle-calendar/${id}`,
            headers: {
                "Content-Type": "application/json",


                "Access-Control-Allow-Origin": "*",
            },
        }).then(function (response) {
            // localStorage.removeItem("cart")
            const selectedAsset = response.data.data[0].off_days;
            let stringSplit = selectedAsset.split(",")

            setCalendar(stringSplit)
            // setCalendar(selectedAsset[0].off_days)
        });
    }, [id]);

    console.log('calendar', calendar)

    useEffect(() => {

        getCalendar();
    }, []);

    const shouldDisableDate = date => {


        return calendar.includes(moment(date).format("MM/DD/YYYY"));
    }

    const [numOfDay, setNumOfDay] = useState(1)
    const [pickupLocation, setPickupLocation] = useState('')


    // the minimum hours for booking id 6, add 6 hours to the current time

    function addHours(numOfHours, date = new Date()) {
        date.setTime(date.getTime() + numOfHours * 60 * 60 * 1000);
        return date;
    }

    const [pickupTime, setPickupTime] = useState(addHours(6))






    const [destination, setDestination] = useState('')
    const [destinationState, setDestinationState] = useState('')
    const [notes, setNotes] = useState('')
    const [insurance, setInsurance] = useState('')
    const [insurancePrice, setInsurancePrice] = useState(0)



    const [width, setWidth] = useState(8)


    let [question, setQuestion] = useState(1)
    const [allQuestion, setAllQuestion] = useState(7)

    const [proceed, setProceed] = useState(false)



    const [showImageTwo, setshowImageTwo] = useState(false)
    const [showImageOne, setshowImageOne] = useState(true)
    const [showImageThree, setshowImageThree] = useState(false)

    const [showFieldsTwo, setshowFieldsTwo] = useState(false)

    let num = Number(numOfDay)
    let pricee = Number(price)
    let prices;

    const [total, setTotal] = useState(0)




    const onClickTwo = (e) => {
        e.preventDefault()
        setshowFieldsTwo(false)
        setshowFieldsThree(true)
        setQuestion(++question)
        setWidth(36)

        setTotal(num * pricee)

    }

    const onClickTwoBack = (e) => {
        e.preventDefault()


        setshowFieldsThree(true)

        setshowFieldsOne(false)

        setshowFieldsTwo(false)

        setshowFieldsFour(false)
        setshowFieldsFive(false)
        setshowFieldsFiveb(false)
        setshowFieldsSix(false)
        setQuestion(question - 1)
        console.log('2 back', question)

        setWidth(40)

        setStatus('Start Booking - Let’s Move')


        setTotal(num * pricee)



    }

    const [showFieldsOne, setshowFieldsOne] = useState(true)





    const onClickOne = (e) => {
        e.preventDefault()

        if (moment(startDates).format('YYYY/MM/DD') < moment(today).format('YYYY/MM/DD')) {
            setAlert('You can not select  past dates')
        } else {
            setshowFieldsOne(false)

            setshowFieldsTwo(true)
            setshowFieldsThree(false)
            setshowFieldsFour(false)
            setshowFieldsFive(false)
            setshowFieldsFiveb(false)
            setshowFieldsSix(false)

            setQuestion(++question)
            setWidth(20)

            setStatus('Start Booking - Let’s Move')
            setAlert('')


            setTotal(num * pricee)

            if (moment(startDates).format('YYYY/MM/DD') === moment(today).format('YYYY/MM/DD')) {
                setMin(hr + 6)
            }
            else {
                setMin(0)

            }
        }



    }

    const onClickOneBack = (e) => {
        e.preventDefault()
        setshowFieldsOne(false)

        setshowFieldsTwo(true)
        setshowFieldsThree(false)
        setshowFieldsFour(false)
        setshowFieldsFive(false)
        setshowFieldsFiveb(false)
        setshowFieldsSix(false)
        setQuestion(question - 1)
        console.log('1 back', question)

        setWidth(20)

        setStatus('Start Booking - Let’s Move')
        setTotal(num * pricee)


    }

    const onClickThreeBack = (e) => {
        e.preventDefault()
        setshowFieldsOne(false)

        setshowFieldsTwo(false)
        setshowFieldsThree(false)
        setshowFieldsFour(true)
        setshowFieldsFive(false)
        setshowFieldsFiveb(false)
        setshowFieldsSix(false)
        setQuestion(question - 1)
        console.log('3 back', question)
        setWidth(50)

        setStatus('Almost Done - Keep Moving')

        setTotal(num * pricee)

    }


    const onClickFourBack = (e) => {
        e.preventDefault()
        setshowFieldsOne(false)

        setshowFieldsTwo(false)
        setshowFieldsThree(false)
        setshowFieldsFour(false)
        setshowFieldsFive(true)
        setshowFieldsFiveb(false)
        setshowFieldsSix(false)
        setQuestion(question - 1)
        console.log('four back', question)
        setWidth(75)
        setStatus('Almost Done - Keep Moving')

        setTotal(num * pricee)


    }

    const onClickFiveBack = (e) => {

        if (destination === 'Out of State') {
            setshowFieldsFiveb(true)


        }
        else {
            setshowFieldsFive(true)

        }
        e.preventDefault()
        setshowFieldsOne(false)

        setshowFieldsTwo(false)
        setshowFieldsThree(false)
        setshowFieldsFour(false)
        // setshowFieldsFive(false)
        setshowFieldsSix(false)
        setQuestion(question - 1)

        setWidth(70)
        setStatus('Almost Done - Keep Moving')


    }

    const [showFieldsThree, setshowFieldsThree] = useState(false)
    const [alert, setAlert] = useState('')
    const onClickThree = (e) => {
        e.preventDefault()

        if (pickupLocation === '') {

            setAlert('This is Required *')
            setTimeout(() => {
                setAlert("")
            }, 3000)
            setshowFieldsThree(true)

            setshowFieldsFour(false)

        } else {
            setAlert('')

            setshowFieldsThree(false)

            setshowFieldsFour(true)
            setQuestion(++question)
            setWidth(60)
            setStatus('Almost Done - Keep Moving')
        }






    }

    const [showFieldsFour, setshowFieldsFour] = useState(false)
    const onClickFour = e => {
        e.preventDefault()



        if (pickupTime === null) {


            setAlert('This is Required *')
            setTimeout(() => {
                setAlert("")
            }, 3000)
            setshowFieldsFour(true)
            setshowFieldsFive(false)




        } else if (pickupTime < hr) {
            setAlert('Time invalid *')
            setTimeout(() => {
                setAlert("")
            }, 3000)
            setshowFieldsFour(true)
            setshowFieldsFive(false)
        }
        else {
            setAlert('')






            setshowFieldsFour(false)
            setshowFieldsFive(true)
            setQuestion(++question)
            setWidth(67)
            setStatus('Almost Done - Keep Moving')
        }



    }

    const [showFieldsFive, setshowFieldsFive] = useState(false)
    const [showFieldsFiveb, setshowFieldsFiveb] = useState(false)


    const onClickFive = (e) => {
        e.preventDefault()
        setStatus('Almost Done - Keep Moving')

        setshowFieldsFive(false)



        if (destination === '') {
            setAlert('This is Required *')
            setTimeout(() => {
                setAlert("")
            }, 3000)
            setshowFieldsFive(true)

        } else {
            setAlert('')

            if (destination === 'Out of State') {
                setshowFieldsFiveb(true)
                setQuestion(question)

                setWidth(90)

            }
            else {
                if (destination === 'Airport Pickup') {
                    setTotal(num * airportPrice)
                } else {
                    setTotal(num * pricee)

                }
                setshowFieldsSix(true)
                setQuestion(++question)
                setWidth(90)

            }

        }
    }

    const onClickFiveb = (e) => {
        e.preventDefault()
        setQuestion(question)
        setWidth(90)

        setStatus('Almost Done - Keep Moving')


        if (destinationState === '') {
            setshowFieldsFiveb(true)
            setshowFieldsSix(false)
            setAlert('This is Required *')
            setTimeout(() => {
                setAlert("")
            }, 3000)
        }
        else {
            setshowFieldsFiveb(false)

            setshowFieldsSix(true)
            setQuestion(++question)


            setAlert('')

        }


    }


    const [showFieldsSix, setshowFieldsSix] = useState(false)


    const onClickSix = () => {
        setshowFieldsSix(false)
        setshowFieldsSeven(true)
        setStatus('Almost Done - Keep Moving')

        console.log(destination, 'destination')
        setWidth(95)
        setQuestion(7)

        if (insurance === 'No') {
            setTotal(num * pricee)
            setInsurancePrice(0)
        }
        else {
            setTotal((num * pricee) + (num * pricee * 0.05))
            setInsurancePrice(num * pricee * 0.05)
            if (destination === 'Airport Pickup') {
                setTotal((num * airportPrice) + (num * airportPrice * 0.05))
                setInsurancePrice(num * airportPrice * 0.05)
            }

        }


    }

    const onClickSevenBack = () => {
        setshowFieldsSix(true)
        setshowFieldsSeven(false)
        setStatus('Almost Done - Keep Moving')
        setQuestion(6)
        setProceed(false)

        setWidth(95)

    }
    const onClickSeven = () => {
        setProceed(true)
        console.log('vehicleId', vehicleId)
        setStatus('Completed - Proceed to Move with Ease')

        if (insurance === 'No') {


            if (destination === 'Airport Pickup') {
                setTotal((num * airportPrice))
                setInsurancePrice(0)
            }
            else {
                setTotal(num * pricee)
                setInsurancePrice(0)
            }
        }
        else {

            if (destination === 'Airport Pickup') {
                setTotal((num * airportPrice) + (num * airportPrice * 0.05))
                setInsurancePrice(num * airportPrice * 0.05)
            }
            else {
                setTotal((num * pricee) + (num * pricee * 0.05))
                setInsurancePrice(num * pricee * 0.05)
            }
        }


    }

    function handleInsurance(e) {
        e.preventDefault()
        setInsurance(e.target.value)
        let insured = e.target.value


        if (insured === 'No') {

            if (destination === 'Airport Pickup') {
                setTotal((num * airportPrice))
                setInsurancePrice(0)
            }
            else {
                setTotal(num * pricee)
                setInsurancePrice(0)
            }
        }
        else {

            if (destination === 'Airport Pickup') {
                setTotal((num * airportPrice) + (num * airportPrice * 0.05))
                setInsurancePrice((num * airportPrice * 0.05))




            }
            else {
                setTotal((num * pricee) + (num * pricee * 0.05))
                setInsurancePrice(num * pricee * 0.05)
            }
        }

    }



    const onClickStart = (e) => {
        e.preventDefault()
        setshowFieldsOne(true)
        setshowFieldsTwo(false)
        setshowFieldsThree(false)
        setshowFieldsFour(false)
        setshowFieldsFive(false)
        setshowFieldsFiveb(false)
        setshowFieldsSix(false)
        setQuestion(question - 1)
        setWidth(10)
    }

    const [showFieldsSeven, setshowFieldsSeven] = useState(false)



    const handleProceed = (e) => {
        e.preventDefault();

        let userId = JSON.parse(localStorage.getItem("userId"))
        let providerId = JSON.parse(localStorage.getItem("service_providerId"))
        let vehicle_image = bookPicture

        let exist = JSON.parse(localStorage.getItem("cart"));
        if (exist == null) {
            exist = [];
        }

        if (destinationState != '') {
            price = 0
        } else {
            price = price
        }


        let bookingDetails = {
            'pickup_date': moment(startDates).format("YYYY/MM/DD"),
            'pickup_location': pickupLocation,
            'destination': destination,
            'destination_state': destinationState,
            'provider_id': providerId,
            'plate_number': plateNumber,
            // 'driver_id': providerId,
            'first_price': firstPrice * numOfDay,
            'commission': commissions,
            'vehicle_id': vehicleId,
            'notes': notes,
            'num_of_day': numOfDay,
            'pickup_time': moment(pickupTime).format("hh:mm a"),
            'book_picture': vehicle_image === null ? imageThree : vehicle_image,
            'booking_title': carTitle,
            'airportPrice': Number(airportPrice),
            // add 1.5% plus 100 as paystack commission
            'amount': destinationState === '' ? total + (((total * 0.015) + 100)>=3000?3000:((total * 0.015) + 100)) : 0,
            // 'amount': destinationState === '' ? total : 0,

            'insurance_price': insurancePrice,
            'come_with_driver': comeWithDriver,
            'vat': vat,
            'daily_price': price,
            'resumption_date': moment(startDates).format("YYYY/MM/DD"),
            'book_date': moment().format('YYYY/MM/DD'),
            'category': 'Car Booking',
            'newBookingId': Math.floor(Math.random() * 100000) + 1,

        }

        exist.push(bookingDetails);
        exist.map((item, i) => {
            item.id = i + 1;
        });
        localStorage.setItem("cart", JSON.stringify(exist));



        axios({
            method: 'post',
            url: `${BASE_URL}/v1/add-to-cart`,
            data: bookingDetails,

            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            }

        }).then(function (response) {
            console.log('ori e o pe handle add to cart', response)
        })

        navigate('/cart')


    }
    const [liked, setLiked] = useState(false);
    const [allLike, setAllLike] = useState([]);
    let likes = []

    function handleLike(e) {
        e.preventDefault()
        if (localStorage.getItem("likedVehicle")) {
            likes = JSON.parse(localStorage.getItem("likedVehicle"))

        }

        likes.push(vehicleId)

        setLiked((prev) => !prev)

        localStorage.setItem("likedVehicle", JSON.stringify(likes))


    }

    function handleunLike(e) {
        e.preventDefault()
        setLiked((prev) => !prev)


        let liked = localStorage.getItem('likedVehicle');
        let likedList = JSON.parse(liked)
        let index = likedList.findIndex((item) => item === vehicleId)


        likedList.splice(index, 1);
        localStorage.setItem('likedVehicle', JSON.stringify(likedList));

    }

    useEffect(() => {
        if (localStorage.getItem("likedVehicle")) {
            let likes = JSON.parse(localStorage.getItem("likedVehicle"))
            setAllLike(likes)
        }

    }, [liked]);

    const responsive = {
        // 0: { items: 1 },
        // 568: { items: 2 },
        1024: { items: 1 },
    };

    const items = [
        <div className="item bg-white md:h-80   rounded-xl flex justify-center items-center overflow-hidden" data-value="1">
            <img src={`https://api.roadlers.com/storage/${image}`} alt="" className='w-full rounded-xl  object-cover object-center' />
        </div>,
        <div className="item bg-white  md:h-80   rounded-xl flex justify-center items-center overflow-hidden" data-value="2">
            <img src={`https://api.roadlers.com/storage/${imageTwo}`} alt="" className="w-full rounded-xl  object-cover object-center" />
        </div>,
        <div className="item bg-white   md:h-80  rounded-xl flex justify-center items-center overflow-hidden" data-value="3">
            <img src={`https://api.roadlers.com/storage/${imageThree}`} alt="" className='w-full rounded-xl   object-cover object-center' />
        </div>,

        <div className="item bg-white   md:h-80  rounded-xl flex justify-center items-center overflow-hidden" data-value="3">
            <img src={`https://api.roadlers.com/storage/${bookPicture}`} alt="" className='w-full rounded-xl   object-cover object-center' />
        </div>,
    ];


    function handleLocation(e) {
        setPickupLocation(e.target.value)
        let locater = e.target.value


    }


    const [firstCar, setFirstCar] = useState(true);
    const [secondCar, setSecondCar] = useState(false);
    const [thirdCar, setThirdCar] = useState(false);
    const [fourthCar, setFourthCar] = useState(false);



    function showSecond(e) {


        setFirstCar(false)
        setSecondCar(true)
        setThirdCar(false)
        setFourthCar(false)

    }
    function showThird(e) {
        setSecondCar(false)
        setThirdCar(true)
        setFirstCar(false)
        setFourthCar(false)

    }

    function showFirst(e) {


        setFirstCar(true)
        setSecondCar(false)
        setThirdCar(false)
        setFourthCar(false)


    }

    function showFourth(e) {


        setFirstCar(false)
        setSecondCar(false)
        setThirdCar(false)
        setFourthCar(true)


    }

    return (
        <div>
            =
            <TopForm />

            <div className="flex md:flex-row flex-col gap-12 xl:px-32 lg:px-24 md:px-12 px-8 mt-16 xl:w-10/12 md:w-full w-full  m-auto">
                <div className="flex flex-col md:w-5/12 w-full ">
                    <div className="relative w-full">



                        <div className="flex w-full hidden md:block">

                            <AliceCarousel
                                mouseTracking
                                items={items}
                                responsive={responsive}
                                controlsStrategy="alternate"

                                renderPrevButton={() => { return <button className='w-12'> <img src={prev} alt="" /></button> }}
                                renderNextButton={() => { return <button className='w-12'> <img src={next} alt="" /></button> }}
                            />



                            {/* <img src={`https://api.roadlers.com/public/${image}`} alt="" className='w-9/12' /> */}






                        </div>


                        {/* mobile view */}
                        <div className="flex gap-5   w-8/12 items-center mb-5">
                            <div className="flex flex-col w-10/12 md:hidden  ">
                                <h3 className='text-amber-500 font-semibold text-sm  '>{title + " "}  {"(" + year + ")"} </h3>
                                <hr className='border-amber-500 border-t mt-2  ' />
                            </div>


                        </div>

                        <div className="w-full flex justify-between  gap-6">
                            <div className="w-10/12 md:hidden ">

                                <div className="w-full rounded-xl bg-white h-52 overflow-hidden flex justify-center items-center">
                                    {
                                        firstCar ? <img src={`https://api.roadlers.com/storage/${image}`} alt="" loading="lazy" className='w-full h-52 object-cover object-center' /> : ''


                                    }

                                    {secondCar ? <img src={`https://api.roadlers.com/storage/${imageTwo}`} alt="" loading="lazy" className='w-full h-52 object-cover object-center' /> : ''}

                                    {thirdCar ? <img src={`https://api.roadlers.com/storage/${imageThree}`} alt="" loading="lazy" className='w-full h-52 object-cover object-center' /> : ''}
                                    {fourthCar ? <img src={`https://api.roadlers.com/storage/${bookPicture}`} alt="" loading="lazy" className='w-full h-52 object-cover object-center' /> : ''}

                                </div>




                                {/* <img src={`https://api.roadlers.com/public/${image}`} alt="" className='w-9/12' /> */}




                            </div>

                            <div className="w-3/12 flex flex-col gap-3 md:hidden h-full">
                                <div className="item bg-white  rounded h-16" data-value="1">
                                    <img src={`https://api.roadlers.com/storage/${imageTwo}`} alt="" className='w-full rounded  h-16 object-cover object-center overflow-hidden' />
                                </div>
                                <div className="item bg-white flex items-center justify-center mt-4  rounded h-16" data-value="1">
                                    <img src={`https://api.roadlers.com/storage/${imageThree}`} alt="" className='w-full  rounded h-16 object-cover object-center overflow-hidden' />
                                </div>


                                {/* handle navigation for cars */}

                                {firstCar ? <div className="flex gap-2 ">
                                    <button
                                        type='button'
                                        onClick={showFourth}
                                        className='w-12'> <img src={prev} alt="" /></button>
                                    <button
                                        type='button'

                                        onClick={showSecond}

                                        className='w-12'> <img src={next} alt="" /></button>
                                </div> : ''
                                }

                                {
                                    secondCar ?
                                        <div className="flex gap-2 ">
                                            <button
                                                type='button'
                                                onClick={showFirst}
                                                className='w-12'> <img src={prev} alt="" /></button>
                                            <button
                                                type='button'

                                                onClick={showThird}

                                                className='w-12'> <img src={next} alt="" /></button>
                                        </div> : ''
                                }

                                {
                                    thirdCar ?
                                        <div className="flex gap-2">
                                            <button
                                                type='button'
                                                onClick={showSecond}
                                                className='w-12'> <img src={prev} alt="" /></button>
                                            <button
                                                type='button'

                                                onClick={showFourth}

                                                className='w-12'> <img src={next} alt="" /></button>
                                        </div> : ''
                                }

                                {fourthCar ? <div className="flex gap-2 ">
                                    <button
                                        type='button'
                                        onClick={showThird}
                                        className='w-12'> <img src={prev} alt="" /></button>
                                    <button
                                        type='button'

                                        onClick={showFirst}

                                        className='w-12'> <img src={next} alt="" /></button>
                                </div> : ''
                                }


                            </div>



                        </div>

                        <div className="absolute bottom-10 md:bottom-28 rounded-r-lg font-bold text-center bg-amber-500 p-1 w-24">

                            <h4>&#8358;

                                {
                                    destination === 'Airport Pickup' ? Number(airportPrice).toLocaleString('en-US', { maximumFractionDigits: 2 }) :
                                        amountNum.toLocaleString('en-US', { maximumFractionDigits: 2 })
                                }
                            </h4>
                        </div>
                        <div className="flex  bg-red-600">
                            {/* <button
                                className='absolute  top-4 left-4'>
                                <img src={like} alt="" className='w-12' />
                            </button> */}





                            {(allLike.includes(vehicleId)) ?

                                (
                                    <button
                                        type='button'

                                        onClick={handleunLike}
                                        className='absolute top-16 md:top-4 left-4'

                                    >

                                        <img src={like_two} alt="" className='cursor-pointer w-9 h-8 p-0' />
                                    </button>


                                ) :
                                (
                                    <button
                                        type='button'
                                        className='absolute  top-16 md:top-4 left-4'

                                        onClick={handleLike}

                                    >
                                        <img src={like} alt="" className='cursor-pointer w-9 h-8 p-0' />

                                    </button>

                                )
                            }
                            {/* <button className='absolute  top-4 left-4'> <img src={like} alt="" className='w-12' /></button> */}
                        </div>


                        <RWebShare
                            data={{
                                text: "Book Vehicles on Roadlers",
                                url: `https://roadlers.com/car-booking/${vehicleId}`,
                                title: "Book Vehicles on Roadlers",
                            }}
                            onClick={() => console.log("shared successfully!")}
                        >
                            <button className='absolute  bottom-5 md:bottom-28 right-28 md:right-6'> <img src={send} alt="" className='w-6 md:w-8' /></button>

                        </RWebShare>
                    </div>









                    <div className="w-full bg-white p-4 rounded-xl  md:flex hidden flex-col gap-1">
                        <h3 className='font-bold md:mt-0 mt-8'>{title + " "}  {"(" + year + ")"}</h3>
                        <hr className='border-t border-gray-500 w-28' />
                        <div className="flex gap-1 items-center ml-0 pl-0 -ml-2">
                            <img src={locationicon} alt="" className='md:w-5 w-4' />
                            <p className='text-xs font-semibold'>{Location}</p>

                        </div>
                        <p className='text-xs font-semibold leading-6'>{textOne}</p>
                        <br />


                        <p className='text-xs font-semibold leading-6'>{textTwo}</p>


                        <div className="flex justify-between mt-2">
                            <img src={ratings} alt="" className='w-32' />
                            <button className="border border-black rounded-3xl py-1 px-5 text-xs">Verified </button>

                        </div>
                    </div>


                    <h4 className='text-amber-500 font-bold mt-8 hidden md:block'>Features</h4>

                    <ul className='text-white test-xx mt-4 w-11/12 hidden md:block'>

                        <li className='mb-4 text-xs'>
                            {airCon === 'no' ?
                                (<div className="flex justify-between">
                                    <label htmlFor="">Air Condition</label>
                                    <img className="bg-white w-5 h-5 p-1" src={Xmark} alt="" />
                                </div>) : (
                                    <div className="flex justify-between">
                                        <label htmlFor="">Air Condition</label>
                                        <img className="bg-white w-5 h-5 p-1" src={Vmark} alt="" />
                                    </div>)
                            }

                        </li>
                        <li className='mb-4 text-xs'>
                            {service?.includes('Full-tank per day') ?
                                (<div className="flex justify-between">
                                    <label htmlFor="">Full Tank</label>
                                    <img className="bg-white w-5 h-5 p-1" src={Vmark} alt="" />
                                </div>) : (
                                    <div className="flex justify-between">
                                        <label htmlFor="">Full Tank</label>
                                        <img className="bg-white w-5 h-5 p-1" src={Xmark} alt="" />
                                    </div>)
                            }

                        </li>
                        <li className='mb-4 text-xs'>
                            {travelAvailable === 'no' ?
                                (<div className="flex justify-between">
                                    <label htmlFor="">Travel Available</label>
                                    <img className="bg-white w-5 h-5 p-1" src={Xmark} alt="" />
                                </div>) : (
                                    <div className="flex justify-between">
                                        <label htmlFor="">Travel Available</label>
                                        <img className="bg-white w-5 h-5 p-1" src={Vmark} alt="" />
                                    </div>)
                            }

                        </li>
                        <li className='mb-4 text-xs flex justify-between items-center'>

                            <h4 className=' text-white'>Colour</h4>
                            <p className='bg-black text-white p-2 rounded text-center'>{vehicle_color}</p>
                        </li>
                    </ul>


                </div>




                <form onSubmit={handleProceed} className='md:w-7/12 '>

                    <div className="flex flex-col  w-full">
                        <h4 className='tracking-wider text-white font-bold text-lg md:text-base md:mt-0 mt-8'>{status}</h4>


                        <div className="w-full mt-12 relative">
                            <input type="range" min="1" max={allQuestion} value={question} className="slider" id="myRange" />
                            <p className='text-white font-semibold absolute -top-5 left-0'>{question}/{allQuestion}</p>
                            <p className='text-white font-semibold absolute -top-5 right-0'>{allQuestion}</p>
                            <div
                                id="background"

                            ></div>
                            <div
                                id="progress"
                                ref={refDiv}
                                style={{ width: width + '%' }}

                            >

                            </div>


                        </div>





                        {showFieldsOne ? (
                            <>

                                <div className="mt-12">
                                    <label htmlFor="Pick Up Date" className=' text-white md:text-sm text-xs'>Pick Up Date</label>

                                    <div className="mt-2 flex bg-white border  text-black dater-pick  justify-between items-center  rounded-lg">


                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DesktopDatePicker
                                                open={open}
                                                onOpen={() => setOpen(true)}
                                                onClose={() => setOpen(false)}
                                                value={startDates}
                                                disablePast
                                                disableMaskedInput={true}
                                                showToolbar={false}

                                                shouldDisableDate={shouldDisableDate}
                                                onChange={(date) => {
                                                    setStartDates(date);
                                                }}
                                                renderInput={(params) => <TextField

                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    onClick={(e) => setOpen(true)}
                                                    onKeyDown={onKeyDown}
                                                    {...params} />}
                                            />

                                        </LocalizationProvider>

                                    </div>

                                    <p className='text-red-500 mt-2 text-xs'>{alert}</p>


                                </div>



                                <div className="flex gap-2 items-center justify-between">
                                    <div className="flex gap-2 items-center cursor-pointer">
                                        <div className="bg-black border border-white  my-8 p-2 text-center items-center gap-2 rounded-md font-bold  flex">
                                            <button
                                                onClick={onClickOne}
                                                type='button'
                                                className='text-white font-bold flex items-center justify-center'>OK <img src={mark} alt="" /></button>
                                        </div>
                                        <h4 className='text-white font-bold'>Press Enter</h4>
                                    </div>

                                    <div className="flex gap-4 text-white font-extrabold">

                                        <button
                                            onClick={onClickOne}
                                        >
                                            <img src={next} alt="" className="w-9 md:w-12" />
                                        </button>
                                    </div>
                                </div>

                            </>
                        ) : ''}
                        {showFieldsTwo ?
                            (
                                <>

                                    <div className="flex flex-col mt-12">

                                        <label htmlFor="Number of Days" className='mb-2 text-white md:text-sm text-xs'>Number of Days</label>

                                        <div className=" flex  border bg-white  p-3 justify-between items-center  rounded-lg">


                                            <input
                                                className='w-full text-black bg-white focus:outline-none  placeholder:text-black rounded-lg md:placeholder:text-sm placeholder:text-xs'
                                                type="number"
                                                name="numOfDay"
                                                id="numOfDay"
                                                value={numOfDay}
                                                min='1'

                                                onChange={(e) => setNumOfDay(e.target.value)}

                                            />

                                        </div>

                                    </div>


                                    <div className="flex items-center justify-between">
                                        <div className="flex gap-2 items-center cursor-pointer">
                                            <div className="bg-black border border-white  my-8 p-2 text-center items-center gap-2 rounded-md font-bold  flex">
                                                <button
                                                    onClick={onClickTwo}
                                                    className='text-white font-bold flex items-center justify-center'>OK <img src={mark} alt="" /></button>
                                            </div>
                                            <h4 className='text-white font-bold'>Press Enter</h4>
                                        </div>


                                        <div className="flex gap-4 text-white font-extrabold">
                                            <button
                                                onClick={onClickStart}
                                            >
                                                <img src={prev} alt="" className="w-9 md:w-12" />

                                            </button>
                                            <button

                                                onClick={onClickTwo}
                                            >
                                                <img src={next} alt="" className="w-9 md:w-12" />

                                            </button>
                                        </div>
                                    </div>

                                </>) : ''}

                        {showFieldsThree ? (
                            <>
                                <div className="flex bg-white w-full p-3 justify-between items-center mt-16 rounded-lg">
                                    <Autocomplete className='autcomplete-style'

                                        onPlaceChanged={onPlaceChanged}
                                        onLoad={(value) => setAutocomplete(value)}

                                    >

                                        <input
                                            type="text"
                                            className='w-11/12  px-1 placeholder:text-black'
                                            placeholder='Pick-up Location:'
                                            required
                                            value={pickupLocation}
                                            onChange={handleLocation}

                                        />
                                    </Autocomplete>

                                    <img src={locationicon} alt="" className='md:w-6 w-4' />
                                </div>
                                <p className='text-red-500 mt-2 text-xs'>{alert}</p>


                                <div className="flex items-center justify-between">
                                    <div className="flex gap-2 items-center cursor-pointer">
                                        <div className="bg-black border border-white  my-8 p-2 text-center items-center gap-2 rounded-md font-bold  flex">
                                            <button
                                                onClick={onClickThree}
                                                className='text-white font-bold flex items-center justify-center'>OK <img src={mark} alt="" /></button>
                                        </div>
                                        <h4 className='text-white font-bold'>Press Enter</h4>
                                    </div>

                                    <div className="flex gap-4 text-white font-extrabold">
                                        <button
                                            onClick={onClickOneBack}
                                        >

                                            <img src={prev} alt="" className="w-9 md:w-12" />
                                        </button>
                                        <button
                                            onClick={onClickThree}
                                        >
                                            <img src={next} alt="" className="w-9 md:w-12" />
                                        </button>
                                    </div>
                                </div>
                            </>
                        ) : ''}

                        {showFieldsFour ? (
                            <>
                                <div className="mt-8">
                                    <label htmlFor="Pick Up Time" className=' text-white md:text-sm text-xs'>Pick Up Time</label>
                                    {
                                        moment(today).format('YYYY/MM/DD') === moment(startDates).format('YYYY/MM/DD') &&
                                        <div className='mt-4'>

                                            <label htmlFor="Pick Up Time" className='text-red-500 text-xs'>If the Pick Up Date is today, your Pick Up time starts 6hours from now</label>

                                        </div>
                                    }


                                    <div className="time mt-2 flex  bg-white w-full  justify-between items-center  rounded-lg timer-pick">



                                        {/* <div className="md:hidden block w-full mobile-time"> */}
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>


                                            <DesktopTimePicker

                                                open={open}
                                                onOpen={() => setOpen(true)}
                                                onClose={() => setOpen(false)}
                                                value={pickupTime}

                                                minTime={new Date(0, 0, 0, min)}


                                                onChange={(time) => {
                                                    setPickupTime(time);
                                                }}
                                                renderInput={(params) => <TextField
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    onClick={(e) => setOpen(true)}
                                                    onKeyDown={onKeyDown}
                                                    {...params} />}
                                            />

                                        </LocalizationProvider>
                                        {/* </div> */}

                                        {/* <div className="hidden md:block w-full">
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <TimePicker
                                                    value={pickupTime}
                                                    minTime={new Date(0, 0, 0, min)}
                                                    onChange={(time) => {
                                                        setPickupTime(time);
                                                    }}
                                                    renderInput={(params) => <TextField onKeyDown={onKeyDown} {...params} />}
                                                />
                                            </LocalizationProvider>
                                        </div> */}





                                    </div>
                                    <p className='text-red-500 mt-2 text-xs'>{alert}</p>


                                </div>

                                <div className="flex items-center justify-between">
                                    <div className="flex gap-2 items-center cursor-pointer">
                                        <div className="bg-black border border-white  my-8 p-2 text-center items-center gap-2 rounded-md font-bold  flex">
                                            <button
                                                onClick={onClickFour}
                                                className='text-white font-bold flex items-center justify-center'>OK <img src={mark} alt="" /></button>
                                        </div>
                                        <h4 className='text-white font-bold'>Press Enter</h4>
                                    </div>

                                    <div className="flex gap-4 text-white font-extrabold">
                                        <button
                                            onClick={onClickTwoBack}
                                        >

                                            <img src={prev} alt="" className="w-9 md:w-12" />
                                        </button>
                                        <button

                                            onClick={onClickFour}
                                        >
                                            <img src={next} alt="" className="w-9 md:w-12" />
                                        </button>
                                    </div>
                                </div>
                            </>
                        ) : ''}



                        {
                            showFieldsFive ? (
                                <>
                                    <div className="flex border  bg-white p-3 justify-between items-center mt-16 rounded-lg">


                                        <select
                                            name="destination"
                                            id="destination"
                                            value={destination}
                                            onChange={e => {
                                                setDestination(e.target.value)
                                                if (e.target.value === 'Airport Pickup') {
                                                    setTotal(num * airportPrice)

                                                } else {
                                                    setTotal(num * pricee)

                                                }
                                            }}
                                            className='w-full text-black bg-white focus:outline-none  placeholder:text-black rounded-lg md:placeholder:text-sm placeholder:text-xs'
                                        >
                                            <option className='md:text-sm text-xx'>Destination</option>
                                            <option value="Within State" className='md:text-sm text-xs'>Within State</option>
                                            {travelAvailable === 'yes' ? (
                                                <option value="Out of State" className='md:text-sm text-xs'>Out of State</option>) : ('')}
                                            <option value="Airport Pickup" className='md:text-sm text-xs'>Airport Pickup</option>
                                        </select>



                                    </div>
                                    <p className='text-red-500 mt-2 text-xs'>{alert}</p>


                                    <div className="flex items-center justify-between">
                                        <div className="flex gap-2 items-center cursor-pointer">
                                            <div className="bg-black border border-white  my-8 p-2 text-center items-center gap-2 rounded-md font-bold  flex">
                                                <button onClick={onClickFive}
                                                    className='text-white font-bold flex items-center justify-center'>OK <img src={mark} alt="" /></button>
                                            </div>
                                            <h4 className='text-white font-bold'>Press Enter</h4>
                                        </div>

                                        <div className="flex gap-4 text-white font-extrabold">
                                            <button
                                                onClick={onClickThreeBack}
                                            >

                                                <img src={prev} alt="" className="w-9 md:w-12" />
                                            </button>
                                            <button onClick={onClickFive}>
                                                <img src={next} alt="" className="w-9 md:w-12" />
                                            </button>
                                        </div>
                                    </div>
                                </>
                            ) : ''
                        }

                        {
                            showFieldsFiveb ? (
                                <>
                                    <div className="flex  bg-white p-3 justify-between items-center mt-16 rounded-lg">
                                        <select
                                            name="destination"
                                            id="destination"
                                            value={destinationState}
                                            onChange={e => setDestinationState(e.target.value)}
                                            className='w-full text-black bg-white focus:outline-none  placeholder:text-black rounded-lg md:placeholder:text-sm placeholder:text-xs'>
                                            <option selected="selected" className='md:text-sm text-xx'>Your Travel Destination State:</option>
                                            <option value="Abia" className='md:text-sm text-xx'>Abia</option>
                                            <option value="Abuja FCT" className='md:text-sm text-xx'>Abuja FCT</option>

                                            <option value="Adamawa" className='md:text-sm text-xx'>Adamawa</option>
                                            <option value="Akwa Ibom" className='md:text-sm text-xx'>Akwa Ibom</option>
                                            <option value="Anambra" className='md:text-sm text-xx'>Anambra</option>
                                            <option value="Bauchi" className='md:text-sm text-xx'>Bauchi</option>
                                            <option value="Bayelsa" className='md:text-sm text-xx'>Bayelsa</option>
                                            <option value="Benue" className='md:text-sm text-xx'>Benue</option>
                                            <option value="Borno" className='md:text-sm text-xx'>Borno</option>
                                            <option value="Cross River" className='md:text-sm text-xx'>Cross River</option>
                                            <option value="Delta" className='md:text-sm text-xx'>Delta</option>
                                            <option value="Ebonyi" className='md:text-sm text-xx'>Ebonyi</option>
                                            <option value="Edo" className='md:text-sm text-xx'>Edo</option>
                                            <option value="Ekiti" className='md:text-sm text-xx'>Ekiti</option>
                                            <option value="Enugu" className='md:text-sm text-xx'>Enugu</option>
                                            <option value="Gombe" className='md:text-sm text-xx'>Gombe</option>
                                            <option value="Imo" className='md:text-sm text-xx'>Imo</option>
                                            <option value="Jigawa" className='md:text-sm text-xx'>Jigawa</option>
                                            <option value="Kaduna" className='md:text-sm text-xx'>Kaduna</option>
                                            <option value="Kano" className='md:text-sm text-xx'>Kano</option>
                                            <option value="Katsina" className='md:text-sm text-xx'>Katsina</option>
                                            <option value="Kebbi" className='md:text-sm text-xx'>Kebbi</option>
                                            <option value="Kogi" className='md:text-sm text-xx'>Kogi</option>
                                            <option value="Kwara" className='md:text-sm text-xx'>Kwara</option>
                                            <option value="Lagos" className='md:text-sm text-xx'>Lagos</option>
                                            <option value="Nassarawa" className='md:text-sm text-xx'>Nassarawa</option>
                                            <option value="Niger" className='md:text-sm text-xx'>Niger</option>
                                            <option value="Ogun" className='md:text-sm text-xx'>Ogun</option>
                                            <option value="Ondo" className='md:text-sm text-xx'>Ondo</option>
                                            <option value="Osun" className='md:text-sm text-xx'>Osun</option>
                                            <option value="Oyo" className='md:text-sm text-xx'>Oyo</option>
                                            <option value="Plateau" className='md:text-sm text-xx'>Plateau</option>
                                            <option value="Rivers" className='md:text-sm text-xx'>Rivers</option>
                                            <option value="Sokoto" className='md:text-sm text-xx'>Sokoto</option>
                                            <option value="Taraba" className='md:text-sm text-xx'>Taraba</option>
                                            <option value="Yobe" className='md:text-sm text-xx'>Yobe</option>
                                            <option value="Zamfara" className='md:text-sm text-xx'>Zamfara</option>
                                        </select>

                                    </div>
                                    <p className='text-red-500 mt-2 text-xs'>{alert}</p>


                                    <div className="flex items-center justify-between">

                                        <div className="flex gap-2 items-center cursor-pointer">
                                            <div className="bg-black border border-white  my-8 p-2 text-center items-center gap-2 rounded-md font-bold  flex">
                                                <button onClick={onClickFiveb} className='text-white font-bold flex items-center justify-center'>OK <img src={mark} alt="" /></button>
                                            </div>
                                            <h4 className='text-white font-bold'>Press Enter</h4>
                                        </div>




                                        <div className="flex gap-4 text-white font-extrabold">
                                            <button
                                                onClick={onClickFourBack}

                                            >

                                                <img src={prev} alt="" className="w-9 md:w-12" />
                                            </button>
                                            <button

                                                onClick={onClickFiveb}
                                            >
                                                <img src={next} alt="" className="w-9 md:w-12" />
                                            </button>
                                        </div>
                                    </div>
                                </>
                            ) : ''
                        }


                        {showFieldsSix ? (
                            <>
                                <div className="flex bg-white p-3 justify-between items-center mt-12 rounded-lg">
                                    <input
                                        type="text"
                                        className='w-11/12  px-1 placeholder:text-black'
                                        placeholder='Add A Note'
                                        name="notes"
                                        id="notes"
                                        value={notes}
                                        onChange={e => setNotes(e.target.value)}
                                    />
                                    <img src={message} alt="" className='md:w-6 w-4' />

                                </div>

                                <div className="flex items-center justify-between">

                                    <div className="flex gap-2 items-center cursor-pointer">
                                        <div className="bg-black border border-white  my-8 p-2 text-center items-center gap-2 rounded-md font-bold  flex">

                                            <button
                                                type="button"
                                                onClick={onClickSix}
                                                className='text-white font-bold flex items-center justify-center'>OK <img src={mark} alt="" /></button>
                                        </div>
                                        <h4 className='text-white font-bold'>Press Enter</h4>
                                    </div>

                                    <div className="flex gap-4 text-white font-extrabold">
                                        <button
                                            onClick={onClickFiveBack}
                                        >

                                            <img src={prev} alt="" className="w-9 md:w-12" />
                                        </button>

                                        <button

                                            onClick={onClickSix}
                                        >
                                            <img src={next} alt="" className="w-9 md:w-12" />
                                        </button>

                                    </div>

                                </div>
                            </>
                        ) : ''}


                        {
                            showFieldsSeven ? (
                                <>
                                    <div className="mt-12">
                                        <label htmlFor="Insurance" className=' text-white md:text-sm text-xs tracking-wider'>Would you like to be covered by insurance on this trip?</label>
                                        <div className="relative flex border  bg-white p-3 justify-between items-center mt-4 rounded-lg">
                                            <select
                                                name="insurance"
                                                id="insurance"
                                                value={insurance}
                                                onChange={handleInsurance}
                                                className='w-full text-black bg-white focus:outline-none  placeholder:text-black rounded-lg md:placeholder:text-sm placeholder:text-xs'
                                            >

                                                <option value='Yes' className='md:text-sm text-xs'>Yes</option>
                                                <option value='No' className='md:text-sm text-xs'>No</option>

                                            </select>

                                            <div className="absolute right-9 flex items-center justify-center text-white bg-black rounded-full p-1 md:h-4 h-4 w-4 md:w-4">
                                                <Menu as="div" className="relative inline-block text-left">
                                                    <div>
                                                        <Menu.Button className="inline-flex w-full justify-center rounded-md  px-4 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                                                            <h4 className="text-white text-xx">i</h4>

                                                        </Menu.Button>
                                                    </div>
                                                    <Transition
                                                        as={Fragment}
                                                        enter="transition ease-out duration-100"
                                                        enterFrom="transform opacity-0 scale-95"
                                                        enterTo="transform opacity-100 scale-100"
                                                        leave="transition ease-in duration-75"
                                                        leaveFrom="transform opacity-100 scale-100"
                                                        leaveTo="transform opacity-0 scale-95"
                                                    >
                                                        <Menu.Items className="z-50 absolute md:left-0  bottom-8 right-0 mt-2 md:w-76 w-72 p-4 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                            <div className="px-1 py-1 flex flex-col gap-4 items-center justify-center" >
                                                                <Menu.Item>
                                                                    {({ hover }) => (

                                                                        <div className="flex items-center  text-black">
                                                                            <p>Your insurance coverage is 5% of the price of your service. It covers only this particular transaction on Roadlers.

                                                                            </p>
                                                                        </div>
                                                                    )}


                                                                </Menu.Item>

                                                            </div>

                                                        </Menu.Items>
                                                    </Transition>
                                                </Menu>

                                            </div>




                                        </div>

                                    </div>

                                    <div className="flex items-center justify-between">
                                        <div className="flex gap-2 items-center cursor-pointer">
                                            <div className="bg-black border border-white  my-8 p-2 text-center items-center gap-2 rounded-md font-bold  flex">
                                                <button
                                                    type='button'
                                                    onClick={onClickSeven} className='text-white font-bold flex items-center justify-center'>OK <img src={mark} alt="" /></button>
                                            </div>
                                            <h4 className='text-white font-bold'>Press Enter</h4>
                                        </div>

                                        <div className="flex gap-4 text-white font-extrabold">
                                            <button
                                                onClick={onClickSevenBack}
                                            >

                                                <img src={prev} alt="" className="w-9 md:w-12" />
                                            </button>

                                        </div>
                                    </div>
                                </>
                            ) : ''
                        }





{/* <p className='md:mt-12 text-white'>Kindly Note that a charge of 1.5% of the total price will be added</p> */}



                        <div className="flex gap-5 w-full">
                            {
                                destinationState ? (
                                    ''
                                ) : (
                                    total === 0 ? (


                                        <button type='button' className='mt-8 p-2 rounded-lg border border-white w-5/12 text-white font-bold'>
                                            &#8358;{(pricee).toLocaleString('en-US', { maximumFractionDigits: 2 })}
                                        </button>


                                    ) : (
                                        destination === 'Airport Pickup' ?
                                            (
                                                <button type='button' className='mt-8 p-2 rounded-lg border border-white w-5/12 text-white font-bold'>
                                                    &#8358;{(total).toLocaleString('en-US', { maximumFractionDigits: 2 })}
                                                </button>

                                            ) :
                                            (
                                                destination === 'Within State' ?
                                                    <button type='button' className='mt-8 p-2 rounded-lg border border-white w-5/12 text-white font-bold'>
                                                        &#8358;{(total).toLocaleString('en-US', { maximumFractionDigits: 2 })}
                                                    </button>
                                                    :
                                                    ''
                                            )

                                    )
                                )
                            }

                            {
                                proceed ? (
                                    load ? (
                                        <div className="bg-amber-500 p-1 w-full font-semibold rounded-lg mt-8 flex justify-center items-center"><h4 className='text-black text-sm'>Please Wait!</h4><input type="image" src={Spin} value="Loading" className="w-10" /></div>
                                    ) : (


                                        <button
                                            type="submit"
                                            className='mt-8 p-1 md:p-2 rounded-lg  bg-amber-500 w-7/12 text-black md:text-base text-xs font-bold'>Proceed to Booking</button>


                                    )
                                ) : null
                            }



                        </div>






                        <div className="md:hidden w-full bg-white p-4 rounded-xl mt-12 flex flex-col gap-1">
                            <h3 className='font-bold'>{title}</h3>
                            <hr className='border-t border-gray-500 w-28' />
                            <div className="flex gap-1 items-center ml-0 pl-0 -ml-2 mt-1">
                                <img src={locationicon} alt="" className='md:w-5 w-4' />
                                <p className='text-xs font-semibold'>{Location}</p>

                            </div>
                            <p className='text-xs font-semibold leading-6'>{textOne}</p>
                            <br />


                            <p className='text-xs font-semibold leading-6'>{textTwo}</p>



                            <div className="flex justify-between mt-2">
                                <img src={ratings} alt="" className='w-24' />
                                <button className="border border-black rounded-3xl py-1 px-5 text-xs">Verified</button>
                            </div>
                        </div>



                        <h4 className='text-amber-500 font-bold mt-8 md:hidden'>Features</h4>

                        <ul className='text-white test-xx mt-4 w-11/12 md:hidden'>

                            <li className='mb-4 text-xs'>
                                {airCon === null || airCon === 'no' ?
                                    (<div className="flex justify-between">
                                        <label htmlFor="">Air Condition</label>
                                        <img className="bg-white w-5 h-5 p-1" src={Xmark} alt="" />
                                    </div>) : (
                                        <div className="flex justify-between">
                                            <label htmlFor="">Air Condition</label>
                                            <img className="bg-white w-5 h-5 p-1" src={Vmark} alt="" />
                                        </div>)
                                }

                            </li>
                            <li className='mb-4 text-xs'>
                                {service?.includes('Full-tank per day') ?
                                    (<div className="flex justify-between">
                                        <label htmlFor="">Full Tank</label>
                                        <img className="bg-white w-5 h-5 p-1" src={Vmark} alt="" />
                                    </div>) : (
                                        <div className="flex justify-between">
                                            <label htmlFor="">Full Tank</label>
                                            <img className="bg-white w-5 h-5 p-1" src={Xmark} alt="" />
                                        </div>)
                                }


                            </li>
                            <li className='mb-4 text-xs'>
                                {travelAvailable === null || travelAvailable === 'no' ?
                                    (<div className="flex justify-between">
                                        <label htmlFor="">Travel Available</label>
                                        <img className="bg-white w-5 h-5 p-1" src={Xmark} alt="" />
                                    </div>) : (
                                        <div className="flex justify-between">
                                            <label htmlFor="">Travel Available</label>
                                            <img className="bg-white w-5 h-5 p-1" src={Vmark} alt="" />
                                        </div>)
                                }

                            </li>


                            <li className='mb-4 text-xs flex justify-between items-center'>

                                <h4 className=' text-white'>Colour</h4>
                                <p className='bg-black text-white p-2 rounded text-center'>{vehicle_color}</p>
                            </li>
                        </ul>


                        <div className="flex flex-col p-6 border border-white md:mt-12 mt-20 rounded-lg">
                            <div className="flex gap-3 items-center">
                                <img src={logo_circle} alt="" className='w-10' />
                                <h3 className='text-white font-bold'>Roadlers</h3>
                            </div>
                            <h4 className='font-bold my-4 text-white'>Listing Company</h4>
                            <p className='text-white md:leading-8 leading-6 tracking-wide  md:text-sm text-xs'>This vehicle is listed by Roadlers, on behalf of an accredited partner, accompanied by highly trained drivers and dedicated customer care agents to meet your expectation.</p>

                        </div>


                    </div>

                </form>

                <div className="hidden md:hidden">
                    {/* <Cart bookings={bookings}/> */}
                </div>

            </div>
        </div>
    )
}

export default memo(CarBookingComponent)