

import React, { Fragment, useState, useEffect, useRef, useContext, useMemo } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { NavHashLink as Link } from "react-router-hash-link";
import { BASE_URL } from "./utils/constant"

import { useCookies } from 'react-cookie';
import Header from './Header'
import { NavLink, useNavigate } from "react-router-dom";

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';


import IntroImg from '../Images/figure.png'
import CallIcon from '../Images/call.png'
import DriversIcon from '../Images/drivers-icon.png'
import DriversIconW from '../Images/drivers-icon-white.png'
import CarsIcon from '../Images/cars-icon.png'
import PickupIcon from '../Images/pickups-icon.png'
import TravelIcon from '../Images/travel-icon.png'
import ChickenRepublic from '../Images/chicken-rep.png';
import Car from '../Images/truck.png';
import Money from '../Images/money.png';
import Check from '../Images/checkmark.png';

import CallCenter from '../Images/call-center.png';
// import DriversIcon from '../../images/drivers-icon-white.png';
import Special from '../Images/special-events.png';
import Refer from '../Images/refer-a-friend.png';
import Ride from '../Images/ride-with-us.png';
import VehicleIcon from '../Images/vehicle-owners.png';
import PlayButton from '../Images/play-icon.png';
import FullDay from '../Images/gift_faint.png';
import FullMonth from '../Images/gift.png';
import BookNow from './Customers/BookNow';
import asset_one from '../Images/driver3.png'
import provider_three from '../Images/provider3.png'
import asset_two from '../Images/asset2.png'
import asset_three from '../Images/corolla-red-car.png'
import full_time from '../Images/full-time.png'
import temporary from '../Images/temporary.png'
import travel from '../Images/travel.png'
import video from '../Images/video.png'
import google from '../Images/google.png'
import location from '../Images/locationicon.png'
import date from '../Images/date.png'

import user from "../Images/userg.png"

import ratings from '../Images/ratings.svg'
import Ratings from './Ratings';
import Paginate from './Paginate';
import Paginating from './Pagination';

import AOS from 'aos';
import "aos/dist/aos.css";
// import Swiper core and required modules
import prev from '../Images/prev.png'
import fprev from '../Images/fprev.png'
import next from '../Images/next.png'
import fnext from '../Images/fnext.png'
import cancelImg from '../Images/cancel.svg'
import Spin from "../Images/spinner.gif"

import axios from "axios";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import SwiperCore, {
    EffectCoverflow, Pagination
} from 'swiper/core';


import { Autoplay, Navigation } from "swiper";

import DatePicker from "react-datepicker";

import MomentUtils from "@date-io/moment";

import { GoogleMap, useJsApiLoader, Autocomplete } from '@react-google-maps/api';

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { baseUrl, fetchData } from './utils/fetchData';
// install Swiper modules
SwiperCore.use([EffectCoverflow, Pagination, Autoplay]);

let PageSize = 8;

function Home() {

    // function ScrollToTopOnMount() {
    //     useEffect(() => {
    //       window.scrollTo(0, 0);
    //     }, []);

    //     return null;
    //   }
    const notify = () => { toast('respData') };
    const vehicleRef = useRef(null)


    const google = window.google;

    const { } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyDkMuW-hGvdRfUhsb2R9RFqA0Pq4Qxg4Cs",
        libraries: ['places'],

    })



    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);



    const videoPlayerRef = useRef()


    let navigate = useNavigate();

    const [currentPage, setCurrentPage] = useState(1)
    // const [postPerPage, setPostPerPage] = useState(8)


    const [alert, setAlert] = useState('')

    // filter data
    const [vehicleType, setVehicleType] = useState('')
    const [searchLocation, setSearchLocation] = useState('')
    const [searchPrice, setSearchPrice] = useState(0)
    const [searchModel, setSearchModel] = useState('')
    const [searchAvailability, setSearchAvailability] = useState('')







    const [startDate, setStartDate] = useState('');


    const [car, setCar] = useState(true)
    const [bus, setBus] = useState(false)
    const [truck, setTruck] = useState(false)
    const [van, setVan] = useState(false)
    const [bike, setBike] = useState(false)
    const [all, setAll] = useState(false)

    const [suv, setSuv] = useState(false)
    const [saloon, setSaloon] = useState(false)
    const [luxury, setLuxury] = useState(false)


    // handles the button bg color for filter
    const [suvState, setSuvState] = useState("inactive");
    const [saloonState, setSaloonState] = useState("inactive");
    const [luxuryState, setLuxuryState] = useState("inactive");

    const [tenState, setTenState] = useState("inactive");
    const [fifteenState, setFifteenState] = useState("inactive");
    const [twentyOneState, setTwentyOneState] = useState("inactive");

    const [withinState, setWithinState] = useState("inactive");
    const [interState, setInterState] = useState("inactive");


    async function setSUVFilter(e) {
        e.preventDefault()
        setVehicleType('SUV Car(cars)')
        setSuvState('active')

        setSaloonState('inactive')
        setLuxuryState('inactive')

        await axios({
            method: 'get',
            url: `${BASE_URL}/v1/filter-search-vehicle?search_location=${searchLocation}&search_vehicle=SUV Car(cars)&price=${searchPrice}&model=${searchModel}&availability=${searchAvailability}&date=${startDate}`,
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
            }
        }).then((response) => {
            const respData = response.data.data
            setAssets(respData);
            setResult(respData)
        }).catch((error) => {
            console.log(error)
        })


    }

    async function setSaloonFilter(e) {
        e.preventDefault()
        setVehicleType('Saloon Car(cars)')
        setSaloonState('active')


        setSuvState('inactive')
        setLuxuryState('inactive')

        await axios({
            method: 'get',
            url: `${BASE_URL}/v1/filter-search-vehicle?search_location=${searchLocation}&search_vehicle=Saloon Car(cars)&price=${searchPrice}&model=${searchModel}&availability=${searchAvailability}&date=${startDate}`,
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
            }
        }).then((response) => {
            const respData = response.data.data
            setAssets(respData);
            setResult(respData)
        }).catch((error) => {
            console.log(error)
        })

    }
    async function setLuxuryFilter(e) {
        e.preventDefault()
        setVehicleType('Luxury Car(cars)')
        setLuxuryState('active')
        setSaloonState('inactive')
        setSuvState('inactive')

        await axios({
            method: 'get',
            url: `${BASE_URL}/v1/filter-search-vehicle?search_location=${searchLocation}&search_vehicle=Luxury Car(cars)&price=${searchPrice}&model=${searchModel}&availability=${searchAvailability}&date=${startDate}`,
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
            }
        }).then((response) => {
            const respData = response.data.data
            setAssets(respData);
            setResult(respData)
        }).catch((error) => {
            console.log(error)
        })

    }

    async function clickModelTen(e) {
        e.preventDefault()
        setSearchModel(2010)
        setTenState('active')

        setFifteenState('inactive')
        setTwentyOneState('inactive')

        await axios({
            method: 'get',
            url: `${BASE_URL}/v1/filter-search-vehicle?search_location=${searchLocation}&search_vehicle=${vehicleType}&price=${searchPrice}&model=2010&availability=${searchAvailability}&date=${startDate}`,
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
            }
        }).then((response) => {
            const respData = response.data.data
            setAssets(respData);
            setResult(respData)
        }).catch((error) => {
            console.log(error)
        })
    }

    async function clickModelFifteen(e) {
        e.preventDefault()
        setSearchModel(2015)

        setTenState('inactive')

        setFifteenState('active')
        setTwentyOneState('inactive')

        await axios({
            method: 'get',
            url: `${BASE_URL}/v1/filter-search-vehicle?search_location=${searchLocation}&search_vehicle=${vehicleType}&price=${searchPrice}&model=2015&availability=${searchAvailability}&date=${startDate}`,
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
            }
        }).then((response) => {
            const respData = response.data.data
            setAssets(respData);
            setResult(respData)
        }).catch((error) => {
            console.log(error)
        })
    }
    async function clickModelTwentyOne(e) {
        e.preventDefault()
        setSearchModel(2021)
        setTenState('inactive')

        setFifteenState('inactive')
        setTwentyOneState('active')

        await axios({
            method: 'get',
            url: `${BASE_URL}/v1/filter-search-vehicle?search_location=${searchLocation}&search_vehicle=${vehicleType}&price=${searchPrice}&model=2021&availability=${searchAvailability}&date=${startDate}`,
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
            }
        }).then((response) => {
            const respData = response.data.data
            setAssets(respData);
            setResult(respData)
        }).catch((error) => {
            console.log(error)
        })
    }
    async function clickWithinState(e) {
        e.preventDefault()
        setSearchAvailability('no')

        setWithinState('active')
        setInterState('inactive')

        await axios({
            method: 'get',
            url: `${BASE_URL}/v1/filter-search-vehicle?search_location=${searchLocation}&search_vehicle=Saloon Car(cars)&price=${searchPrice}&model=${searchModel}&availability=no&date=${startDate}`,
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
            }
        }).then((response) => {
            const respData = response.data.data
            setAssets(respData);
            setResult(respData)
        }).catch((error) => {
            console.log(error)
        })
    }

    async function clickInterState(e) {
        e.preventDefault()
        setSearchAvailability('yes')

        setWithinState('inactive')
        setInterState('active')

        await axios({
            method: 'get',
            url: `${BASE_URL}/v1/filter-search-vehicle?search_location=${searchLocation}&search_vehicle=Saloon Car(cars)&price=${searchPrice}&model=${searchModel}&availability=yes&date=${startDate}`,
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
            }
        }).then((response) => {
            const respData = response.data.data
            setAssets(respData);
            setResult(respData)
        }).catch((error) => {
            console.log(error)
        })
    }


    const [filterOpen, setFilterOpen] = useState(false)
    const [accessOpen, setAccessOpen] = useState(false)
    const [chatOpen, setChatOpen] = useState(false)


    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [name, setName] = useState('')
    const [bg, setBg] = useState(false)


    //Search State
    const [value, setValue] = useState('');
    const [locationValue, setLocationValue] = useState('');


    const [assets, setAssets] = useState([]);
    const [result, setResult] = useState(assets);

    const currentData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return result.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, assets, result]);


    const [load, setLoad] = useState("")





    const getAssets = async () => {
        await axios.get(`${BASE_URL}/v1/list-vehicle`).then((response) => {
            const allVehicle = response.data.data;
            console.log('my vehicles', allVehicle)
            setAssets(allVehicle);
            setResult(allVehicle)
        });
    };
    useEffect(() => {
        getAssets()
    }, [])

    // useEffect(() => {
    //     const getAssets= async () => {
    //        let allVehicle = await fetchData(`${baseUrl}/v1/list-vehicle`); 
    //        setAssets(allVehicle.data);
    //        setResult(allVehicle.data)
    //     }
    //     getAssets()
    // }, [])



    const handleSearch = (e) => {
        e.preventDefault();

        let search = e.target.value.toLowerCase();
        setValue(search)

        setResult(
            assets.filter((data) => {
                return data.vehicle_name.toLowerCase().includes(search.toLowerCase()) || (data.year && data.year.toLowerCase().includes(search.toLowerCase()));
            })
        )
    }


    const handleSearchByLocation = (e) => {
        e.preventDefault();
        let searchLocation = e.target.value.toLowerCase();
        setLocationValue(searchLocation)
        setResult(
            assets.filter((data) => {
                return data.state.toLowerCase().includes(searchLocation.toLowerCase())
            })
        )
    }





    const [gifts, setGifts] = useState([]);
    const [id, setId] = useState('');
    const [cookies, setCookie, removeCookie] = useCookies();



    // for the date picker in the filter

    const [open, setOpen] = useState(false);




    const clickBus = async (e) => {
        e.preventDefault()
        setBus(true)
        setCar(false)
        setTruck(false)
        setVan(false)
        setBike(false)
        setAll(false)
        setSuv(false)
        setSaloon(false)
        setLuxury(false)
        const searchVehicle = 'Bus'
        await axios({
            method: 'get',
            url: `${BASE_URL}/v1/filter-search-vehicle?search_vehicle=${searchVehicle}`,
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
            }
        }).then(function (response) {
            const respData = response.data.data;

            setAssets(respData);
            setResult(respData)
        })
            .catch(function (error) {
                console.log("error ", error)
            });
    }






    const clickMini = async (e) => {
        e.preventDefault()
        setBus(true)
        setCar(false)
        setTruck(false)
        setVan(false)
        setBike(false)
        setAll(false)

        setSuv(false)
        setSaloon(false)
        setLuxury(false)
        const searchVehicle = 'Mini Bus(bus)'

        await axios({
            method: 'get',
            url: `${BASE_URL}/v1/filter-search-vehicle?search_vehicle=${searchVehicle}`,
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
            }
        }).then(function (response) {
            const respData = response.data.data;

            setAssets(respData);
            setResult(respData)
        })
            .catch(function (error) {
                console.log("error ", error)
            });
    }


    const clickCoaster = async (e) => {
        e.preventDefault()
        setBus(true)
        setCar(false)
        setTruck(false)
        setVan(false)
        setBike(false)
        setAll(false)

        setSuv(false)
        setSaloon(false)
        setLuxury(false)
        const searchVehicle = 'Coaster Bus(bus)'
        await axios({
            method: 'get',
            url: `${BASE_URL}/v1/filter-search-vehicle?search_vehicle=${searchVehicle}`,
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
            }
        }).then(function (response) {
            const respData = response.data.data;

            setAssets(respData);
            setResult(respData)
        })
            .catch(function (error) {
                console.log("error ", error)
            });
    }


    const clickLuxuryB = async (e) => {
        e.preventDefault()
        setBus(true)
        setCar(false)
        setTruck(false)
        setVan(false)
        setBike(false)
        setAll(false)

        setSuv(false)
        setSaloon(false)
        setLuxury(false)
        const searchVehicle = 'Luxury  Bus'
        await axios({
            method: 'get',
            url: `${BASE_URL}/v1/filter-search-vehicle?search_vehicle=${searchVehicle}`,
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
            }
        }).then(function (response) {
            const respData = response.data.data;

            setAssets(respData);
            setResult(respData)
        })
            .catch(function (error) {
                console.log("error ", error)
            });
    }



















    const clickTruck = async (e) => {
        e.preventDefault()
        setTruck(true)
        setVan(false)
        setBus(false)
        setCar(false)
        setBike(false)
        setAll(false)

        setSuv(false)
        setSaloon(false)
        setLuxury(false)
        const searchVehicle = 'Truck'
        await axios({
            method: 'get',
            url: `${BASE_URL}/v1/filter-search-vehicle?search_vehicle=${searchVehicle}`,
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
            }
        }).then(function (response) {
            const respData = response.data.data;

            setAssets(respData);
            setResult(respData)

        })
            .catch(function (error) {
                console.log("error ", error)
            });


    }

    const clickFlatTrailer = async (e) => {
        e.preventDefault()
        setTruck(true)
        setVan(false)
        setBus(false)
        setCar(false)
        setBike(false)
        setAll(false)

        setSuv(false)
        setSaloon(false)
        setLuxury(false)
        const searchVehicle = 'Flat Bird Trailer'
        await axios({
            method: 'get',
            url: `${BASE_URL}/v1/filter-search-vehicle?search_vehicle=${searchVehicle}`,
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
            }
        }).then(function (response) {
            const respData = response.data.data;

            setAssets(respData);
            setResult(respData)

        })
            .catch(function (error) {
                console.log("error ", error)
            });


    }

    const clickTonsTrailer = async (e) => {
        e.preventDefault()
        setTruck(true)
        setVan(false)
        setBus(false)
        setCar(false)
        setBike(false)
        setAll(false)

        setSuv(false)
        setSaloon(false)
        setLuxury(false)
        const searchVehicle = 'Tons Trailer'
        await axios({
            method: 'get',
            url: `${BASE_URL}/v1/filter-search-vehicle?search_vehicle=${searchVehicle}`,
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
            }
        }).then(function (response) {
            const respData = response.data.data;

            setAssets(respData);
            setResult(respData)

        })
            .catch(function (error) {
                console.log("error ", error)
            });


    }

    const clickCaterpillar = async (e) => {
        e.preventDefault()
        setTruck(true)
        setVan(false)
        setBus(false)
        setCar(false)
        setBike(false)
        setAll(false)

        setSuv(false)
        setSaloon(false)
        setLuxury(false)
        const searchVehicle = 'Caterpillar'
        await axios({
            method: 'get',
            url: `${BASE_URL}/v1/filter-search-vehicle?search_vehicle=${searchVehicle}`,
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
            }
        }).then(function (response) {
            const respData = response.data.data;

            setAssets(respData);
            setResult(respData)

        })
            .catch(function (error) {
                console.log("error ", error)
            });


    }




    const clickVan = async (e) => {
        e.preventDefault()
        setVan(true)
        setBus(false)
        setCar(false)
        setTruck(false)
        setBike(false)
        setAll(false)

        setSuv(false)
        setSaloon(false)
        setLuxury(false)

        const searchVehicle = 'Van'
        await axios({
            method: 'get',
            url: `${BASE_URL}/v1/filter-search-vehicle?search_vehicle=${searchVehicle}`,
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
            }
        }).then(function (response) {
            const respData = response.data.data;

            setAssets(respData);
            setResult(respData)

        })
            .catch(function (error) {
                console.log("error ", error)
            });


    }

    const clickVanEmergency = async (e) => {
        e.preventDefault()
        setVan(true)
        setBus(false)
        setCar(false)
        setTruck(false)
        setBike(false)
        setAll(false)

        setSuv(false)
        setSaloon(false)
        setLuxury(false)

        const searchVehicle = 'Emergency'
        await axios({
            method: 'get',
            url: `${BASE_URL}/v1/filter-search-vehicle?search_vehicle=${searchVehicle}`,
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
            }
        }).then(function (response) {
            const respData = response.data.data;

            setAssets(respData);
            setResult(respData)

        })
            .catch(function (error) {
                console.log("error ", error)
            });


    }

    const clickVanLuxury = async (e) => {
        e.preventDefault()
        setVan(true)
        setBus(false)
        setCar(false)
        setTruck(false)
        setBike(false)
        setAll(false)

        setSuv(false)
        setSaloon(false)
        setLuxury(false)
        const searchVehicle = 'Luxury  Bus/Van'
        await axios({
            method: 'get',
            url: `${BASE_URL}/v1/filter-search-vehicle?search_vehicle=${searchVehicle}`,
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
            }
        }).then(function (response) {
            const respData = response.data.data;
            setAssets(respData);
            setResult(respData)
        })
            .catch(function (error) {
                console.log("error ", error)
            });
    }



    // Handle get vehicle for all CArs on the database
    const clickCar = async (e) => {
        e.preventDefault()
        setCar(true)
        setBus(false)
        setTruck(false)
        setVan(false)
        setBike(false)
        setAll(false)
        vehicleRef.current?.scrollIntoView({ behavior: 'smooth' });

        const searchVehicle = 'car'
        await axios({
            method: 'get',
            url: `${BASE_URL}/v1/filter-search-vehicle?search_vehicle=${searchVehicle}`,
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
            }
        }).then(function (response) {
            const suvVehicle = response.data.data;

            setAssets(suvVehicle);
            setResult(suvVehicle)

        })
            .catch(function (error) {
                console.log("error ", error)
            });


    }


    const clickAll = async (e) => {
        e.preventDefault()
        setCar(false)
        setBus(false)
        setTruck(false)
        setVan(false)
        setBike(false)
        setAll(true)
        await axios({
            method: 'get',
            url: `${BASE_URL}/v1/list-vehicle`,
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
            }
        }).then(function (response) {
            const allVehicle = response.data.data;

            setAssets(allVehicle);
            setResult(allVehicle)

        })
            .catch(function (error) {
                console.log("error ", error)
            });


    }





    const clickBike = async (e) => {
        e.preventDefault()
        setBike(true)
        setCar(false)
        setBus(false)
        setTruck(false)
        setVan(false)
        setSuv(false)
        setSaloon(false)
        setLuxury(false)
        setAll(false)

        const searchVehicle = 'bike'
        await axios({
            method: 'get',
            url: `${BASE_URL}/v1/filter-search-vehicle?search_vehicle=${searchVehicle}`,
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
            }
        }).then(function (response) {
            const suvVehicle = response.data.data;

            setAssets(suvVehicle);
            setResult(suvVehicle)

        })
            .catch(function (error) {
                console.log("error ", error)
            });


    }


    const clickDelivery = async (e) => {
        e.preventDefault()
        setBike(true)
        setCar(false)
        setBus(false)
        setTruck(false)
        setVan(false)
        setSuv(false)
        setSaloon(false)
        setLuxury(false)
        setAll(false)

        const searchVehicle = 'Delivery'
        await axios({
            method: 'get',
            url: `${BASE_URL}/v1/filter-search-vehicle?search_vehicle=${searchVehicle}`,
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
            }
        }).then(function (response) {
            const suvVehicle = response.data.data;

            setAssets(suvVehicle);
            setResult(suvVehicle)

        })
            .catch(function (error) {
                console.log("error ", error)
            });


    }


    const clickPowerBike = async (e) => {
        e.preventDefault()
        setBike(true)
        setCar(false)
        setBus(false)
        setTruck(false)
        setVan(false)
        setSuv(false)
        setSaloon(false)
        setLuxury(false)
        setAll(false)

        const searchVehicle = 'Power Bike'
        await axios({
            method: 'get',
            url: `${BASE_URL}/v1/filter-search-vehicle?search_vehicle=${searchVehicle}`,
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
            }
        }).then(function (response) {
            const suvVehicle = response.data.data;

            setAssets(suvVehicle);
            setResult(suvVehicle)

        })
            .catch(function (error) {
                console.log("error ", error)
            });


    }



    //Handle get vehicle for all SUVs on the database
    const clickSuv = async (e) => {
        e.preventDefault()
        setSuv(true)
        setSaloon(false)
        setLuxury(false)
        const searchVehicle = 'SUV Car(cars)'
        await axios({
            method: 'get',
            url: `${BASE_URL}/v1/filter-search-vehicle?search_vehicle=${searchVehicle}`,
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
            }
        }).then(function (response) {
            const suvVehicle = response.data.data;

            setAssets(suvVehicle);
            setResult(suvVehicle)

        })
            .catch(function (error) {
                console.log("error ", error)
            });


    }


    // end of Handle get vehicle for all SUVs on the database



    //Handle get vehicle for all saloon cars on the database



    const clickSaloon = async (e) => {
        e.preventDefault()
        setSuv(false)
        setSaloon(true)
        setLuxury(false)
        const searchVehicle = 'Saloon Car(cars)'
        await axios({
            method: 'get',
            url: `${BASE_URL}/v1/filter-search-vehicle?search_vehicle=${searchVehicle}`,
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
            }
        }).then(function (response) {
            const suvVehicle = response.data.data;

            setAssets(suvVehicle);
            setResult(suvVehicle)

        })
            .catch(function (error) {
                console.log("error ", error)
            });


    }


    // end of Handle get vehicle for all saloon cars on the database


    //Handle get vehicle for all luxury cars on the database

    const clickLuxury = async (e) => {
        e.preventDefault()
        setLuxury(true)
        setSaloon(false)
        setSuv(false)
        const searchVehicle = 'Luxury'
        await axios({
            method: 'get',
            url: `${BASE_URL}/v1/filter-search-vehicle?search_vehicle=${searchVehicle}`,
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
            }
        }).then(function (response) {
            const luxVehicle = response.data.data;
            console.log('luxVehicle', luxVehicle)
            setAssets(luxVehicle);
            setResult(luxVehicle)
        })
            .catch(function (error) {
                //handle error
                console.log("error ", error)
            });

    }




    // end of Handle get vehicle for all luxury cars on the database


    function closeModal() {
        setFilterOpen(false)

        setChatOpen(false)

        setAccessOpen(false)

        setBg(false)

    }

    function filterModal(e) {
        e.preventDefault()
        setFilterOpen(true)
        setBg(true)

    }

    function accessModal() {

        setAccessOpen(true)
        setBg(true)

    }

    function chatModal() {

        setChatOpen(true)
        setBg(true)

    }









    //get the gift cards
    // const getGiftCards = async () => {
    //     let id = JSON.parse(localStorage.getItem("userId"))
    //     let token = JSON.parse(localStorage.getItem("userToken"))


    // await axios.get(`${BASE_URL}/v1/view-pricing`, {

    //         headers: {
    //             'Content-Type': 'application/json',

    //             Authorization: `Bearer ${token}`,

    //             "Access-Control-Allow-Origin": "*",


    //         }
    //     }).then((response) => {
    //         const giftCardData = response.data.data;
    //         console.log('gift card', giftCardData)
    //         setGifts(giftCardData)


    //     });
    // };

    const fetchPrices = (e) => {
        axios
            .get(`${BASE_URL}/v1/view-pricing`, {
                headers: {
                    "Content-Type": "application/json",

                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((response) => {
                const transData = response.data.data;



                let gift = transData.filter((data) => {
                    return data.price_category === 'gift_price'
                })



                setGifts(gift)

            });
    }
    useEffect(() => {
        fetchPrices();
    }, []);


    let userLoggedIn = JSON.parse(localStorage.getItem("userId"))


    function accessOffer(e) {
        e.preventDefault()

        let formData = new FormData();

        formData.append('fullname', name)
        formData.append('email', email)
        formData.append('phone_number', phone)




        axios({
            method: 'post',
            url: `${BASE_URL}/v1/access-offer`,


            data: formData,

            headers: {
                'Content-Type': 'application/json',

                // Authorization: `Bearer ${token}`,

                "Access-Control-Allow-Origin": "*",


            }

        })
            .then(function (response) {
                //handle success
                const message = response.data.status


                if (message === true) {
                    navigate('/full-time-driver')
                }


                else {


                }



            })
            .catch(function (error) {
                //handle error
                console.log("error ", error)
                // setLoad('');

            });


    }

    function startChat(e) {
        e.preventDefault()

        if (name === '' || email === '' || phone === '') {

            setAlert('This is Required *')
        } else {
            setAlert('')

            let formData = new FormData();

            formData.append('name', name)
            formData.append('email', email)
            formData.append('phone_number', phone)
            axios({
                method: 'post',
                url: `${BASE_URL}/v1/chat-message`,


                data: formData,

                headers: {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                }

            })
                .then(function (response) {
                    //handle success
                    const message = response.data.status


                    if (message === true) {
                        //Render whatsapp chat

                        window.open('https://api.whatsapp.com/send?phone=2349044442200')
                    }
                    else {
                    }
                })
                .catch(function (error) {
                    //handle error
                    console.log("error ", error)
                    // setLoad('');
                });
        }
    }

    const ResetFilter = async (e) => {
        e.preventDefault();

        await axios.get(`${BASE_URL}/v1/list-vehicle`).then((response) => {
            const allVehicle = response.data.data;
            console.log('my vehicles', allVehicle)
            setAssets(allVehicle);
            setResult(allVehicle)

        });
        setSuvState('inactive')
        setSaloonState('inactive')
        setLuxuryState('inactive')
        setTenState('inactive')
        setFifteenState('inactive')
        setTwentyOneState('inactive')
        setWithinState('inactive')
        setInterState('inactive')
        setSearchPrice(0)

    }



    // useEffect(() => {
    //     getAssets();
    // }, []);



    // handle Filter pop up
    const handleFilterSearch = (e) => {
        e.preventDefault();
        closeModal()
        // axios({
        //     method: 'get',
        //     url: `${BASE_URL}/v1/filter-search-vehicle?search_location=${searchLocation}&search_vehicle=${vehicleType}&price=${searchPrice}&model=${searchModel}&availability=${searchAvailability}&date=${startDate}`,
        //     headers: {
        //         'Content-Type': 'application/json',
        //         "Access-Control-Allow-Origin": "*",
        //     }
        // }).then((response) => {
        //     const respData = response.data.data
        //     setAssets(respData);
        //     setResult(respData)
        // }).catch((error) => {
        //     console.log(error)
        // })

    }

    return (
        <div className={bg === true ? " blur-sm contrast-50 backdrop-brightness-50" : "w-full"}>
            {/* <ScrollToTopOnMount/> */}


            <div>






                <Transition appear show={filterOpen} as={Fragment}>
                    <Dialog
                        as="div"
                        className="fixed flex  items-center justify-center w-full px-6 mt-12  inset-0 z-10 overflow-y-auto"
                        onClose={closeModal}
                    >
                        <div className="min-h-screen w-full md:w-4/12 px-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Dialog.Overlay className="fixed inset-0" />
                            </Transition.Child>

                            {/* This element is to trick the browser into centering the modal contents. */}
                            <span
                                className="inline-block h-screen align-middle"
                                aria-hidden="true"
                            >
                                &#8203;
                            </span>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <div className="w-full inline-block  max-w-md pt-3 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow rounded-md">


                                    <div className=" p-6">

                                        <form>


                                            <div className="">
                                                <div className=" w-full gap-4 flex items-center justify-end">




                                                    <div className="flex w-full justify-end">
                                                        <button
                                                            type='button'
                                                            className=''>
                                                            <img src={cancelImg} className=' md:mt-0 w-[30px]' onClick={closeModal} alt="Cancel Modal" />
                                                        </button>
                                                    </div>
                                                </div>

                                                <label htmlFor="" className='text-xs md:text-sm font-bold text-black'>Location</label>


                                                <div className="mt-3 rounded p-1 border border-gray-600 flex justify-between items-center text-sm  w-full ">

                                                    {/* <Autocomplete className='autcomplete-style'> */}

                                                    <input
                                                        type="text"

                                                        className='text-black md:text-sm text-xs w-full bg-transparent placeholder:text-black placeholder:text-xs  focus:outline-none'
                                                        placeholder="e.g Lagos"
                                                        value={searchLocation}
                                                        onChange={async (e) => {
                                                            setSearchLocation(e.target.value)

                                                            await axios({
                                                                method: 'get',
                                                                url: `${BASE_URL}/v1/filter-search-vehicle?search_location=${e.target.value}&search_vehicle=${vehicleType}&price=${searchPrice}&model=${searchModel}&availability=${searchAvailability}&date=${startDate}`,
                                                                headers: {
                                                                    'Content-Type': 'application/json',
                                                                    "Access-Control-Allow-Origin": "*",
                                                                }
                                                            }).then((response) => {
                                                                const respData = response.data.data
                                                                setAssets(respData);
                                                                setResult(respData)
                                                            }).catch((error) => {
                                                                console.log(error)
                                                            })
                                                        }}



                                                    />
                                                    {/* </Autocomplete> */}


                                                    <img src={location} alt="Car rentals Truck Hire in Lagos Nigeria " />


                                                </div>
                                            </div>



                                            <div className="mt-3">
                                                <label htmlFor="" className=' text-xs md:text-sm font-bold text-black'>Category</label>

                                                <div className="mt-3 flex gap-4">

                                                    <button
                                                        onClick={setSUVFilter}


                                                        type='button'
                                                        className={suvState === "active" ? 'bg-amber-500 border border-black p-1 w-1/3 font-bold text-black rounded md:text-xs text-xx' : 'focus:bg-transparent border border-black p-1 w-1/3 font-bold text-black rounded md:text-xs text-xx'}>
                                                        SUVs
                                                    </button>

                                                    <button
                                                        type='button'
                                                        // onClick={clickSaloon}
                                                        onClick={setSaloonFilter}

                                                        className={saloonState === "active" ? 'bg-amber-500 border border-black p-1 w-1/3 font-bold text-black rounded md:text-xs text-xx' : 'focus:bg-transparent border border-black p-1 w-1/3 font-bold text-black rounded md:text-xs text-xx'}>

                                                        Saloon Cars
                                                    </button>

                                                    <button
                                                        type='button'
                                                        onClick={setLuxuryFilter}

                                                        className={luxuryState === "active" ? 'bg-amber-500 border border-black p-1 w-1/3 font-bold text-black rounded md:text-xs text-xx' : 'focus:bg-transparent border border-black p-1 w-1/3 font-bold text-black rounded md:text-xs text-xx'}>
                                                        Luxury Cars
                                                    </button>
                                                </div>





                                            </div>






                                            <div className="mt-3">
                                                <label htmlFor="" className='text-xs md:text-sm font-bold text-black'>Model</label>

                                                <div className="flex gap-4 mt-3">
                                                    <button
                                                        type='button'
                                                        onClick={clickModelTen}

                                                        className={tenState === 'active' ? 'bg-amber-500 border border-black p-1   w-1/3 font-bold text-black rounded md:text-xs text-xx' : ' border border-black p-1   w-1/3 font-bold text-black rounded md:text-xs text-xx'}>
                                                        2007 - 2010
                                                    </button>

                                                    <button
                                                        type='button'
                                                        onClick={clickModelFifteen}
                                                        className={fifteenState === 'active' ? 'bg-amber-500 border border-black p-1   w-1/3 font-bold text-black rounded md:text-xs text-xx' : ' border border-black p-1   w-1/3 font-bold text-black rounded md:text-xs text-xx'}>

                                                        2011 - 2015
                                                    </button>

                                                    <button
                                                        type='button'
                                                        onClick={clickModelTwentyOne}
                                                        className={twentyOneState === 'active' ? 'bg-amber-500 border border-black p-1   w-1/3 font-bold text-black rounded md:text-xs text-xx' : ' border border-black p-1   w-1/3 font-bold text-black rounded md:text-xs text-xx'}>

                                                        2016 - 2021
                                                    </button>
                                                </div>





                                            </div>


                                            <div className="mt-3 relative">
                                                <label htmlFor="" className=' text-xs md:text-sm font-bold text-black'>Price</label>


                                                <div class="flex justify-between mt-3">

                                                    <h4>₦{Number(searchPrice).toLocaleString('en-US', { maximumFractionDigits: 2 })}</h4>
                                                    <h4>₦900,000</h4>
                                                </div>

                                                <div class="price slidecontainer mt-2" >
                                                    <input type="range" min="0" max="900000"
                                                        value={searchPrice}

                                                        onChange={async (e) => {

                                                            setSearchPrice(e.target.value)
                                                            await axios({
                                                                method: 'get',
                                                                url: `${BASE_URL}/v1/filter-search-vehicle?search_location=${searchLocation}&search_vehicle=Saloon Car(cars)&price=${e.target.value}&model=${searchModel}&availability=${searchAvailability}&date=${startDate}`,
                                                                headers: {
                                                                    'Content-Type': 'application/json',
                                                                    "Access-Control-Allow-Origin": "*",
                                                                }
                                                            }).then((response) => {
                                                                const respData = response.data.data
                                                                setAssets(respData);
                                                                setResult(respData)
                                                            }).catch((error) => {
                                                                console.log(error)
                                                            })
                                                        }}

                                                        class="mySlider" id="myRange" />

                                                    <div
                                                        id="background"

                                                    ></div>

                                                    <div
                                                        id="progress"
                                                        // ref={refDiv}
                                                        style={{ width: searchPrice / 900000 * 100 + '%' }}

                                                    >

                                                    </div>
                                                </div>


                                            </div>


                                            <div className="mt-3">
                                                <label htmlFor="" className=' text-xs md:text-sm font-bold text-black'>Availability</label>

                                                <div className="flex gap-4 mt-3">
                                                    <button
                                                        type='button'

                                                        onClick={clickWithinState}
                                                        className={withinState === 'active' ? 'bg-amber-500 border border-black p-1   w-1/3 font-bold text-black rounded md:text-xs text-xx' : ' border border-black p-1   w-1/3 font-bold text-black rounded md:text-xs text-xx'}>

                                                        Within State
                                                    </button>

                                                    <button
                                                        type='button'
                                                        onClick={clickInterState}
                                                        className={interState === 'active' ? 'bg-amber-500 border border-black p-1   w-1/3 font-bold text-black rounded md:text-xs text-xx' : ' border border-black p-1   w-1/3 font-bold text-black rounded md:text-xs text-xx'}>

                                                        Inter-state
                                                    </button>


                                                </div>





                                            </div>


                                            {/* <div className="my-4 flex flex-col gap-3 dateee">
                                                <label htmlFor="" className='mt-3 text-xs md:text-sm font-bold text-black'>Date</label>


                                                <button
                                                    type='button'
                                                    onClick={() => setOpen((isOpen) => !isOpen)}> <div

                                                        className="flex  items-center gap-4 ">
                                                        <img src={date} alt="Car rentals Truck Hire in Lagos Nigeria " className="w-7 " />
                                                        <label className='text-xs font-regular' htmlFor="input-id">When you need it</label>
                                                    </div></button>


                                                <div className="">
                                                    <DatePicker
                                                        selected={startDate}
                                                        onChange={(date) => {
                                                            setStartDate(date)
                                                            setOpen(false)
                                                        }}

                                                        open={open}

                                                        style={{ color: "white" }}



                                                    />
                                                </div>
                                            </div> */}




                                            <div className=" flex gap-4 py-8">


                                                <button
                                                    type='button'
                                                    onClick={handleFilterSearch}

                                                    className='bg-black border p-2   w-2/3 font-bold text-white rounded md:text-xs text-xx'>
                                                    Apply Filter ({assets.length}) Results
                                                </button>

                                                <button
                                                    type='button'
                                                    onClick={ResetFilter}
                                                    className='focus:bg-amber-500 border border-black p-2   w-1/3 font-bold text-black rounded md:text-xs text-xx'>
                                                    Reset All
                                                </button>



                                            </div>



                                        </form>

                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </Dialog>
                </Transition>



                <Transition appear show={accessOpen} as={Fragment}>
                    <Dialog
                        as="div"
                        className="fixed flex items-center justify-center w-full px-6 mt-8  inset-0 z-10 overflow-y-auto"
                        onClose={closeModal}
                    >
                        <div className="min-h-screen px-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Dialog.Overlay className="fixed inset-0" />
                            </Transition.Child>

                            {/* This element is to trick the browser into centering the modal contents. */}
                            <span
                                className="inline-block h-screen align-middle"
                                aria-hidden="true"
                            >
                                &#8203;
                            </span>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <div className="w-full md:w-8/12 inline-block  max-w-md pt-6 my-8 overflow-hidden text-left align-middle transition-all transform  shadow-xl rounded-md">


                                    <div className="bg-black px-6 py-20 rounded-3xl shadow-3xl border">

                                        <form>
                                            <h3 className='text-white text-2xl text-center'>Access all Offers</h3>
                                            <p className='text-white text-xs mt-3 text-center'>Access comprehensive details entailing all driving benefits and prices will be sent to you immediately.</p>

                                            <div className="mt-4">


                                                <input
                                                    type="text"
                                                    placeholder='Your Name:'
                                                    className='rounded-lg p-2 md:text-sm text-xs w-full bg-white placeholder:text-xs   focus:outline-none text-black'


                                                    value={name}
                                                    onChange={e => setName(e.target.value)}
                                                />
                                            </div>


                                            <div className="mt-4">


                                                <input
                                                    type="text"
                                                    placeholder='Your Email:'
                                                    className='rounded-lg p-2 md:text-sm text-xs w-full bg-white placeholder:text-xs   focus:outline-none text-black'


                                                    value={email}
                                                    onChange={e => setEmail(e.target.value)}
                                                />
                                            </div>


                                            <div className="mt-4">


                                                <input
                                                    type="text"
                                                    placeholder='Phone Number:'
                                                    className='rounded-lg p-2 md:text-sm text-xs w-full bg-white placeholder:text-xs   focus:outline-none text-black'


                                                    value={phone}
                                                    onChange={e => setPhone(e.target.value)}
                                                />
                                            </div>



                                            <div className="mt-8 w-full flex justify-center">



                                                <button
                                                    type="submit"
                                                    className="inline-flex justify-center px-4 py-1 text-sm font-bold text-black bg-amber-500 border border-transparent rounded-md  focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                                                    onClick={e => accessOffer(e)}
                                                >
                                                    Access
                                                </button>
                                            </div>













                                        </form>

                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </Dialog>
                </Transition>

                <Transition appear show={chatOpen} as={Fragment}>
                    <Dialog
                        as="div"
                        className="fixed flex items-center justify-center w-full px-6 mt-8  inset-0 z-10 overflow-y-auto"
                        onClose={closeModal}
                    >
                        <div className="min-h-screen px-4 text-center w-full xl:w-4/12 lg:w-5/12 md:w-6/12">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Dialog.Overlay className="fixed inset-0" />
                            </Transition.Child>

                            {/* This element is to trick the browser into centering the modal contents. */}
                            <span
                                className="inline-block h-screen align-middle"
                                aria-hidden="true"
                            >
                                &#8203;
                            </span>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <div className="w-full md:w-8/12  inline-block  max-w-md pt-6 my-8 overflow-hidden text-left align-middle transition-all transform  shadow-xl rounded-md">


                                    <div className="bg-project px-6 py-12 rounded-3xl shadow-3xl border border-gray-800">

                                        <form>
                                            <h3 className='text-white text-2xl text-center'>Hello</h3>
                                            <p className="text-xs text-white text-center mt-4 mb-20">
                                                The designated officer will work with you to provide a chauffeur service to your desired destination.
                                            </p>

                                            <div className="mt-4 relative w-full flex flex-col">

                                                <img
                                                    src={user}
                                                    className="md:w-16  w-12 items-center left-20 justify-center absolute md:-top-12 -top-9 "
                                                    alt="Car rentals Truck Hire in Lagos Nigeria "
                                                />
                                                <input
                                                    type="text"
                                                    placeholder='Your Name:'
                                                    className='rounded-lg p-2 md:text-sm text-xs w-full bg-white placeholder:text-xs   focus:outline-none text-black'


                                                    value={name}
                                                    onChange={e => setName(e.target.value)}
                                                />
                                                <p className='text-red-500 mt-2 text-xs'>{alert}</p>

                                            </div>


                                            <div className="mt-4">


                                                <input
                                                    type="text"
                                                    placeholder='Your Email:'
                                                    className='rounded-lg p-2 md:text-sm text-xs w-full bg-white placeholder:text-xs   focus:outline-none text-black'


                                                    value={email}
                                                    onChange={e => setEmail(e.target.value)}
                                                />
                                                <p className='text-red-500 mt-2 text-xs'>{alert}</p>

                                            </div>


                                            <div className="mt-4">


                                                <input
                                                    type="text"
                                                    placeholder='Phone Number:'
                                                    className='rounded-lg p-2 md:text-sm text-xs w-full bg-white placeholder:text-xs   focus:outline-none text-black'


                                                    value={phone}
                                                    onChange={e => setPhone(e.target.value)}
                                                />
                                                <p className='text-red-500 mt-2 text-xs'>{alert}</p>

                                            </div>



                                            <div className="mt-8 w-full flex justify-center">



                                                <button
                                                    type="submit"
                                                    className="inline-flex justify-center px-4 py-1 text-sm font-bold text-black bg-amber-500 border border-transparent rounded-md  focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                                                    onClick={e => startChat(e)}
                                                >
                                                    Let's Chat
                                                </button>
                                            </div>













                                        </form>

                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </Dialog>
                </Transition>





            </div>





            <Header user='customer' />

            <div className="flex xl:px-36 lg:px-28 md:px-12 px-8 md:flex-row gap-5 flex-col pt-36 md:pt-44 md:items-center md:justify-between">
                <div className="md:w-6/12">
                    <div className="flex flex-col gap-6 ">
                        <h1 data-aos="fade-down" data-aos-duration="3000" className='tracking-wide text-white md:text-5xl text-3xl font-extrabold ' >Save Time</h1>
                        <h1 data-aos="fade-down" data-aos-duration="4000" className="tracking-wide cta-text md:text-5xl text-3xl font-extrabold">Reduce Stress</h1>
                        <h1 data-aos="fade-down" data-aos-duration="5000" className="tracking-wide text-amber-500 md:text-5xl text-3xl font-extrabold">Move with Ease</h1>
                    </div>

                    <p className='tracking-wider text-white hidden md:block md:text-sm md:mt-12 mt-8 leading-6 md:leading-6'>Book a car or driver when you need one, get the best <br className='hidden md:block' /> price from numerous dealers and enjoy your ride!</p>
                    <a href="tel:2349044442200" className='bg-amber-500 p-2 mt-12 md:w-32 md:flex hidden items-center rounded-md'>
                        <span className='tracking-wider font-extrabold w-full'>Book Now</span>
                        <img src={CallIcon} alt="phone-call" className='w-7' />
                    </a>



                </div>

                <div className="md:w-6/12 bg-vid">
                    <img src={IntroImg} alt="Car rentals Truck Hire in Lagos Nigeria" className='w-full' />
                    {/* klklk */}
                </div>

                <p className='text-white md:hidden text-sm leading-12 tracking-wider'>Get cars and personalized drivers for a day or a month. Enjoy comfortable rides to any destination at a fixed rate.</p>

                <a href="tel:2349044442200" className='cta-btn p-2 mt-4 w-32 md:hidden flex items-center gap-1 rounded rounded-md'>

                    <span className=' w-full text-sm tracking-wider font-extrabold'>Book Now</span>
                    <img src={CallIcon} alt="phone-call" className='w-8' />
                </a>
            </div>


            <section className="" >
                <div data-aos="fade-up" id='services' className="xl:px-28 lg:px-20 md:px-16  md:pt-28 pt-20 px-8 flex flex-col text-center justify-center w-full">
                    <h1 className='text-white  md:text-3xl text-xl font-bold md:tracking-wider'>Looking For The Best Options To Move Through The Day?</h1>

                    <p className="tracking-wider md:hidden text-xs leading-6 m-auto text-amber-500 md:mt-6 mt-3 w-full">
                        Book a car or driver when you need one, get the best prices from registered dealers and enjoy your ride!
                    </p>

                    <p className="tracking-wider md:w-4/6 hidden md:block text-base leading-6 m-auto text-yellow-500 md:mt-6 mt-3 w-full">
                        Get cars and personalized drivers for a day or a month. Enjoy comfortable rides to any destination at a fixed rate.
                    </p>
                </div>

                <div className="md:flex md:gap-3 grid grid-cols-2 gap-6 w-full xl:px-44 lg:px-36 md:px-24 px-8 md:mt-20 mt-8 align-start justify-center items-start">
                    <div className="md:w-1/4 flex flex-col items-center justify-center gap-6">
                        <img src={DriversIcon} alt="Car rentals Truck Hire in Lagos Nigeria " className='md:w-22 w-16' />
                        <h2 className='tracking-wider md:font-bold font-bold text-white md:text-lg text-base'>Drivers</h2>
                        <p className='tracking-wider text-white  text-sm md:font-normal md:w-8/12 h-24 md:leading-6  text-center'>
                            Hire a professional <strong>driver</strong>  for a day or more.
                        </p>
                        <a href="#drivers" className='hover:bg-amber-500 hover:text-black text-white border border-white rounded-md py-2 px-4 text-sm md:text-sm text-xs '>Learn More</a>
                    </div>
                    <div className="md:w-1/4 flex flex-col items-center justify-center gap-6">
                        <img src={CarsIcon} alt="Car rentals Truck Hire in Lagos Nigeria " className='md:w-22 w-16' />
                        <h2 className='tracking-wider md:font-bold font-bold text-white md:text-lg text-base'>Cars</h2>
                        <p className='tracking-wider text-white  h-24 text-sm font-light md:font-normal md:w-8/12 w-full md:leading-6 leading-6 text-center'>

                            We have various model of <strong>cars</strong>  from verified dealers listed.
                        </p>

                        <a href="#vehicles" className='tracking-wider hover:bg-amber-500 hover:text-black text-white border border-white rounded-md py-2 px-4 text-sm md:text-sm text-xs '>Learn More</a>

                    </div>
                    <div className="mt-12 md:mt-0 md:w-1/4 flex flex-col items-center justify-center gap-6">
                        <img src={PickupIcon} alt="Car rentals Truck Hire in Lagos Nigeria " className='md:w-22 w-16' />
                        <h2 className='tracking-wider md:font-bold text-center font-bold text-white md:text-lg text-base'>Airport Pickups</h2>
                        <p className='tracking-wider text-white  text-sm font-light md:font-normal md:w-8/12 h-24 w-full md:leading-6 leading-6 text-center'>
                            Enjoy quick airport pick up and drop-off across Nigeria.
                        </p>

                        <a href="#air" className='hover:bg-amber-500 hover:text-black text-white border border-white rounded-md py-2 px-4 text-sm md:text-sm text-xs '>Learn More</a>

                    </div>
                    <div className="mt-12 md:mt-0 md:w-1/4 flex flex-col items-center justify-center gap-6">
                        <img src={TravelIcon} alt="Car rentals Truck Hire in Lagos Nigeria " className='md:w-22 w-16' />
                        <h2 className='tracking-wider md:font-bold font-bold text-white md:text-lg text-base'>Travels</h2>
                        <p className='tracking-wider text-white  text-sm font-light md:font-normal h-24 md:w-8/12 w-full md:leading-6 leading-6 text-center'>
                            Traveling across the country? Get comfortable rides.
                        </p>



                        <a href="#air" className='hover:bg-amber-500 hover:text-black text-white border border-white rounded-md py-2 px-4 text-sm md:text-sm text-xs '>Learn More</a>

                    </div>
                </div>
            </section>

            <div className="flex flex-col px-8 w-full" data-aos="fade-down"
                ref={vehicleRef}

            >
                <div className="flex flex-col md:mt-24 mt-16 justify-center items-center  w-full ">
                    <p className='tracking-wider text-amber-500 text-center text-lg hidden md:block'>Best Pricing</p>
                    <h1 className='tracking-wider text-white text-center md:text-2xl text-xl font-bold mt-4 '>Find it - Book it - Ride it</h1>




                </div>

                <div className="flex md:justify-center md:items-center w-full">
                    <div className="mt-8 flex md:gap-8 gap-2 md:w-auto w-full sm:w-full">
                        <button
                            onClick={clickCar}
                            className={car === true ? "bg-amber-500 rounded-md text-xs md:text-base md:px-3 py-1 md:py-0 w-1/4 md:w-24 font-bold" : "hover:bg-amber-500 hover:text-black bg-transparent border text-xs md:text-base border-white rounded-md md:px-3 py-2 md:py-1 w-1/4 md:w-24  text-white"}>Cars</button>
                        <button onClick={clickBus} className={bus === false ? "hover:bg-amber-500 hover:text-black bg-transparent border text-xs md:text-base border-white rounded-md md:px-3 py-2 md:py-1 w-1/4 md:w-24  text-white" : "bg-amber-500  text-xs md:text-base font-bold  rounded-md md:px-3 py-2 md:py-1 w-1/4 md:w-24  text-black"}>Buses</button>
                        <button onClick={clickVan} className={van === false ? "hover:bg-amber-500 hover:text-black bg-transparent border text-xs md:text-base border-white rounded-md md:px-3 py-2 md:py-1 w-1/4 md:w-24  text-white" : "bg-amber-500  text-xs md:text-base font-bold  rounded-md md:px-3 py-2 md:py-1 w-1/4 md:w-24  text-black"}>Vans</button>
                        <button onClick={clickTruck} className={truck === false ? "hover:bg-amber-500 hover:text-black bg-transparent border text-xs md:text-base border-white rounded-md md:px-3 py-2 md:py-1 w-1/4 md:w-24  text-white" : "bg-amber-500  text-xs md:text-base font-bold  rounded-md md:px-3 py-2 md:py-1 w-1/4 md:w-24  text-black"}>Trucks</button>
                        <button onClick={clickBike} className={bike === false ? "md:flex hidden hover:bg-amber-500 hover:text-black bg-transparent border text-xs md:text-base border-white rounded-md md:px-3 py-2 md:py-1 w-1/4 md:w-24  text-white" : "md:flex hidden bg-amber-500  text-xs md:text-base font-bold  rounded-md md:px-3 py-2 md:py-1 w-1/4 md:w-24  text-black"}>Delivery</button>
                        <button onClick={clickBike} className={bike === false ? "flex justify-center md:hidden hover:bg-amber-500 hover:text-black bg-transparent border text-xs md:text-base border-white rounded-md md:px-3 py-2 md:py-1 w-1/4 md:w-24  text-white" : "flex justify-center md:hidden bg-amber-500  text-xs md:text-base font-bold  rounded-md md:px-3 py-2 md:py-1 w-1/4 md:w-24  text-black"}>Bikes</button>
                        <button onClick={clickAll} className={all === false ? "hover:bg-amber-500 hover:text-black bg-transparent border text-xs md:text-base border-white rounded-md md:px-3 py-2 md:py-1 w-1/4 md:w-24  text-white" : "bg-amber-500  text-xs md:text-base font-bold  rounded-md md:px-3 py-2 md:py-1 w-1/4 md:w-24  text-black"}>All</button>


                    </div>
                </div>


                <div className="mt-16 flex flex-col gap-8 xl:px-36 lg:px-16 md:px-12 w-full">
                    <form action="" className='w-full m-auto md:flex gap-5 justify-center hidden'>
                        <input
                            type="text"
                            name=""
                            className='w-4/12 bg-white py-2 px-4 rounded-md placeholder:text-xs '
                            placeholder='Search by car name, model, and category'
                            onChange={handleSearch}
                            value={value}
                        />

                        <input
                            type="text"
                            name=""
                            className='w-3/12 bg-white py-2 rounded-md placeholder:text-xs px-4'
                            placeholder='Pick-Up Location e.g. Lagos'
                            onChange={(e) => handleSearchByLocation(e)}
                            value={locationValue}
                        />
                        <button
                            type="button"

                            className="bg-amber-500 rounded-md px-3 py-1 w-24 font-bold text-sm">Search</button>
                        <button
                            type="button"

                            onClick={filterModal}

                            className="bg-transparent border border-white rounded-md px-3 py-1 w-24  text-xs text-white">+ Add Filter</button>
                    </form>

                    <form action="" className='w-full m-auto flex flex-col gap-5 justify-center md:hidden'>
                        <div className="flex gap-3">
                            <input
                                type="text"
                                name=""
                                className='w-8/12 bg-white py-2 px-4 rounded-md placeholder:text-xs '
                                placeholder='Search by car name, model, and category'
                                onChange={(e) => handleSearch(e)}
                                value={value}

                            />


                            {load ? (
                                <div className="flex bg-amber-500 rounded-md px-3 py-1 w-4/12 font-bold text-xs">
                                    <h4 className="text-black text-xs">Loading</h4>
                                    <input
                                        type="image"
                                        src={Spin}
                                        value="Loading"
                                        className="w-8"
                                        alt="loading..."
                                    />
                                </div>
                            ) :
                                (
                                    <button
                                        type="button"
                                        // onClick={handleFilter}
                                        className="bg-amber-500 rounded-md px-3 py-1 w-4/12 font-bold text-xs">Search</button>

                                )}

                        </div>
                        <div className="flex gap-3">
                            <input
                                onChange={(e) => handleSearchByLocation(e)}
                                value={locationValue}
                                type="text"
                                name="" className='w-8/12 bg-white py-2 rounded-md placeholder:text-xs px-4'
                                placeholder='Pick-Up Location e.g. Lagos' />
                            <button
                                type="button"
                                onClick={filterModal}
                                className="bg-transparent border border-white rounded-md px-3 py-1 w-4/12  text-xx text-white">+ Add Filter</button>





                        </div>

                    </form>



                    {
                        car ?
                            <div className="flex  justify-around xl:w-3/6 lg:w-4/6 md:w-5/6 w-full gap-2 m-auto  mt-8 md:px-10">
                                <div className="flex md:gap-3 gap-2 items-center">
                                    <button
                                        onClick={clickSuv}
                                        className={suv === true ? "bg-amber-500 rounded-full p-2 md:w-6 w-5 h-5 md:h-6 border-2 border-white " : 'bg-transparent rounded-full p-2 md:w-6 w-5 h-5 md:h-6 border-2 border-white '}></button>
                                    <h4 className='text-white md:text-sm text-xs'> SUVs</h4>

                                </div>

                                <div className="flex  md:gap-3 gap-2 items-center">
                                    <button onClick={clickSaloon} className={saloon === true ? "bg-amber-500 rounded-full p-2 md:w-6 w-5 h-5 md:h-6 border-2 border-white " : "bg-transparent rounded-full p-2 md:w-6 w-5 h-5 md:h-6 border-2 border-white "}></button>
                                    <h4 className='text-white md:text-sm text-xs'> Saloon Cars</h4>

                                </div>

                                <div className="flex  md:gap-3 gap-2 items-center">
                                    <button onClick={clickLuxury} className={luxury === true ? "bg-amber-500 rounded-full p-2 md:w-6 w-5 h-5 md:h-6 border-2 border-white " : "bg-transparent rounded-full p-2 md:w-6 w-5 h-5 md:h-6 border-2 border-white "}></button>
                                    <h4 className='text-white md:text-sm text-xs'> Luxury Cars</h4>

                                </div>


                            </div> : ''}

                    {
                        bus ?
                            <div className="flex  justify-around lg:w-3/6 md:w-5/6 w-full gap-2 m-auto  mt-8 md:px-10">
                                <div className="flex md:gap-3 gap-2 items-center">
                                    <button
                                        onClick={clickMini}
                                        className='focus:bg-amber-500 bg-transparent rounded-full p-2 md:w-6 w-5 h-5 md:h-6 border-2 border-white '></button>
                                    <h4 className='text-white md:text-sm text-xs'> Mini Bus</h4>

                                </div>

                                <div className="flex  md:gap-3 gap-2 items-center">
                                    <button onClick={clickCoaster} className="focus:bg-amber-500 bg-transparent rounded-full p-2 md:w-6 w-5 h-5 md:h-6 border-2 border-white "></button>
                                    <h4 className='text-white md:text-sm text-xs'> Coaster Bus</h4>

                                </div>

                                <div className="flex  md:gap-3 gap-2 items-center">
                                    <button onClick={clickLuxuryB} className="focus:bg-amber-500 bg-transparent rounded-full p-2 md:w-6 w-5 h-5 md:h-6 border-2 border-white "></button>
                                    <h4 className='text-white md:text-sm text-xs'> Luxury Bus/Van</h4>

                                </div>


                            </div> : ''}

                    {
                        van ?
                            <div className="flex  justify-around lg:w-3/6 md:w-5/6 w-full gap-2 m-auto  mt-8 md:px-10">
                                <div className="flex md:gap-3 gap-2 items-center">
                                    <button
                                        onClick={clickVanEmergency}
                                        className="focus:bg-amber-500 bg-transparent rounded-full p-2 md:w-6 w-5 h-5 md:h-6 border-2 border-white "></button>

                                    <h4 className='text-white md:text-sm text-xs'> Emergency Van</h4>

                                </div>



                                <div className="flex  md:gap-3 gap-2 items-center">
                                    <button
                                        onClick={clickVanLuxury}

                                        className="focus:bg-amber-500 bg-transparent rounded-full p-2 md:w-6 w-5 h-5 md:h-6 border-2 border-white "></button>

                                    <h4 className='text-white md:text-sm text-xs'> Luxury Van</h4>

                                </div>


                            </div> : ''}

                    {truck ?
                        <div className="flex  justify-around lg:w-4/6 md:w-5/6 w-full gap-2 m-auto  mt-8 md:px-10">
                            <div className="flex md:gap-3 gap-2 items-center">
                                <button
                                    onClick={clickFlatTrailer}

                                    className="focus:bg-amber-500 bg-transparent rounded-full p-2 md:w-6 w-5 h-5 md:h-6 border-2 border-white "></button>

                                <h4 className='text-white md:text-sm text-xs'> Flat Bird Trailer</h4>

                            </div>

                            <div className="flex  md:gap-3 gap-2 items-center">
                                <button
                                    onClick={clickTonsTrailer}

                                    className="focus:bg-amber-500 bg-transparent rounded-full p-2 md:w-6 w-5 h-5 md:h-6 border-2 border-white "></button>
                                <h4 className='text-white md:text-sm text-xs'> 5-40 Tons Trailer</h4>

                            </div>

                            <div className="flex  md:gap-3 gap-2 items-center">
                                <button
                                    onClick={clickCaterpillar}

                                    className="focus:bg-amber-500 bg-transparent rounded-full p-2 md:w-6 w-5 h-5 md:h-6 border-2 border-white "></button>

                                <h4 className='text-white md:text-sm text-xs'> Caterpillars Trailer</h4>

                            </div>


                        </div> : ''}

                    {bike ?
                        <div className="flex  justify-around lg:w-3/6 md:w-5/6 w-full gap-2 m-auto  mt-8 md:px-10">
                            <div className="flex md:gap-3 gap-2 items-center">
                                <button
                                    onClick={clickDelivery}

                                    className="focus:bg-amber-500 bg-transparent rounded-full p-2 md:w-6 w-5 h-5 md:h-6 border-2 border-white "></button>

                                <h4 className='text-white md:text-sm text-xs'> Delivery Bikes</h4>

                            </div>

                            <div className="flex  md:gap-3 gap-2 items-center">
                                <button
                                    onClick={clickPowerBike}

                                    className="focus:bg-amber-500 bg-transparent rounded-full p-2 md:w-6 w-5 h-5 md:h-6 border-2 border-white "></button>
                                <h4 className='text-white md:text-sm text-xs'> Power Bikes</h4>

                            </div>




                        </div> : ''}

                    <div
                        id='vehicles' className=" my-8 xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-4 grid-cols-2 md:gap-4 xl:gap-8 gap-4 grid w-full">
                        {
                            currentData.map((asset, i) => {
                                return (

                                    <BookNow
                                        key={asset.id}
                                        id={asset.id}
                                        image={asset.image4}
                                        imageThree={asset.image3}
                                        name={asset.vehicle_name}
                                        price={asset.daily_price}
                                        year={asset.year}
                                        comeWithDriver={asset.come_with_driver}
                                        location={asset.state} />

                                )
                            })



                        }




                    </div>



                    <div className="flex w-full items-center justify-center gap-3">
                        <Paginating
                            className="pagination-bar"
                            currentPage={currentPage}
                            totalCount={assets.length}
                            pageSize={PageSize}
                            onPageChange={page => {
                                setCurrentPage(page)
                                vehicleRef.current?.scrollIntoView({ behavior: 'smooth' });

                            }}
                        />
                    </div>

                </div>
            </div>


            <div className="bgg relative hidden md:block">

                <div className="w-full relative flex items-center justify-center py-56">
                    <div className="">
                        <img className='absolute xl:left-32 lg:left-20 md:left-10' src={Car} alt="car" />
                    </div>


                    <div className="mt-20 ml-20">
                        <div className=" flex flex-col gap-2 items-center">
                            <img className='w-24' src={Money} alt="money" />
                            <h1 className='text-2xl font-extrabold'>₦1,000</h1>
                            <p className='font-bold text-sm'>For Every Referral</p>
                        </div>
                    </div>

                    <div className="mt-4 ml-20">
                        <a href="#promotions" className='border border-black font-semibold py-1 px-3 text-sm rounded-md'>Learn More</a>

                    </div>

                    <div className=" absolute xl:right-64 lg:right-32 md:right-10 top-48 flex gap-2 flex-col items-center ">
                        <h2 className='text-2xl font-extrabold'>Free Lunch</h2>
                        <p className='font-bold text-sm'>In Your First 30 days</p>
                        <img className='' src={ChickenRepublic} alt="chicken" />

                    </div>

                </div>

            </div>


            <div className="bgg relative px-8 -mt-4 md:hidden">

                <div className="w-full relative flex items-center justify-center py-52">
                    <div className="theme">
                        <img className='absolute top-0  right-14 w-16 mt-60 mr-52' src={Car} alt="car" />
                    </div>





                    <div className="relative ">
                        <div className="absolute -mt-0 -right-3 mr-2 w-24 flex flex-col items-center">
                            <img className=' w-12' src={Money} alt="money" />
                            <h1 className=' text-sm font-extrabold'>₦1,000</h1>
                            <p className='font-bold  text-xx'>For Every Referral</p>
                        </div>
                    </div>

                    <div className="absolute mt-4 sm:mt-0  ml-20">
                        <a href="#promotions"
                            className='border border-black font-semibold md:text-base text-xx py-1 px-1 md:px-3 rounded-md'>Learn More
                        </a>

                    </div>

                    <div className=" absolute  -right-5  top-36  flex flex-col items-center justify-center text-center">
                        <h2 className='text-sm font-extrabold'>Free Lunch</h2>
                        <p className='font-bold  text-xx'>In Your First 30 days</p>
                        <img className='w-16' src={ChickenRepublic} alt="chicken" />

                    </div>

                </div>

            </div>
            <div id="drivers"></div>
            <section className="hidden md:block">
                <div className="xl:px-36 lg:px-28 md:px-16  flex  items-center justify-center flex-col">
                    <h2 className='tracking-wider text-white md:text-2xl text-lg text-center font-bold'>What Kind of Driver Do You Need?</h2>
                </div>

                <div className="flex gap-5 items-center mt-8 justify-center">
                    <NavLink to='one-off-driver' className='hover:bg-amber-500 hover:text-black border border-white py-2 px-6 text-white font-bold text-sm rounded-md'>Daily</NavLink>
                    <NavLink to='full-time-driver' className='hover:bg-amber-500 hover:text-black border border-white text-white  py-2 px-6  font-bold rounded-md text-sm '>Full-Time</NavLink>
                    <NavLink to='one-off-driver' className='hover:bg-amber-500 hover:text-black border border-white py-2 px-6 text-white font-bold text-sm rounded-md'>Travel</NavLink>
                </div>
                <div className="flex gap-6 xl:px-72 lg:px-40 md:px-28 mt-16 pb-16">
                    <div className="w-1/3 relative">
                        <img src={temporary} alt="Car rentals Truck Hire in Lagos Nigeria " className='w-full' />

                        <div className="first absolute top-28 bg-black border border-amber-500 p-5 rounded-2xl flex flex-col items-center justify-center">

                            <h4 className='opacity-100 text-amber-500 font-bold mb-8 tracking-wider'> Need A Temporary Driver?</h4>
                            <p className='opacity-100 text-white text-sm mb-12 leading-6 tracking-wide text-justify'>We offer driving services in Nigeria for business and leisure. Our professional drivers will save you time and ensure your comfort in your car for the entire day.  </p>

                            <NavLink to='one-off-driver' className='tracking-wide hover:bg-amber-500 hover:text-black border border-white py-1 px-4 text-white font-bold text-sm rounded-md'>Book Now</NavLink>

                        </div>

                    </div>

                    <div className="w-1/3 relative">
                        <img src={full_time} alt="Car rentals Truck Hire in Lagos Nigeria " className='w-full' />

                        <div className="first absolute top-28 bg-black border border-amber-500 p-5 rounded-2xl flex flex-col items-center justify-center">

                            <h4 className='opacity-100 text-amber-500 font-bold mb-8 tracking-wider'>Need A Driver Full-time?</h4>
                            <p className='opacity-100 text-white text-sm mb-4 md:mb-8 leading-6 tracking-wide text-justify'>

                                Our full-time drivers are trained in all aspects of customer care and safety. And there are several benefits such as medical insurance, standard background checks amongst others. </p>

                            <button
                                onClick={accessModal}

                                className='tracking-wide hover:bg-amber-500 hover:text-black border border-white py-1 px-4 mb-6 text-white font-bold text-sm rounded-md'
                            >Access Offers
                            </button>

                        </div>

                    </div>

                    <div className="w-1/3 relative">
                        <img src={travel} alt="Car rentals Truck Hire in Lagos Nigeria " className='w-full' />

                        <div className="first absolute top-28 bg-black border border-amber-500 p-5 rounded-2xl flex flex-col items-center justify-center">

                            <h4 className='opacity-100 text-amber-500 font-bold mb-8 tracking-wider'>Need A Travel Driver?</h4>
                            <p className='opacity-100 text-white text-sm mb-16 leading-6 tracking-wide text-justify'>

                                Travel in style and comfort with our chauffeur-driven cars. We provide a range of services to cater to the special needs of our clients. Access some of our amazing travel packages today! </p>

                            <NavLink to='one-off-driver' className='tracking-wide hover:bg-amber-500 hover:text-black border border-white py-1 px-4 mb-12 text-white font-bold text-sm rounded-md'>Book Now</NavLink>

                        </div>

                    </div>
                </div>
            </section>

            <section className='driver block md:hidden' >

                <div className=" flex  items-center  w-full justify-center flex-col px-8 -mt-4">
                    <h2 className='text-white text-center text-xl font-bold tracking-wider'>What Kind of Driver Do You Need?</h2>
                </div>

                <div className="flex gap-2 items-center my-8 px-8 w-full justify-center">

                    <NavLink to='one-off-driver' className='text-center border border-white py-2 px-2 w-1/3 text-white font-bold text-xs rounded-md'>Daily</NavLink>
                    <NavLink to='full-time-driver' className='text-center bg-amber-500  py-2 px-2 text-black w-1/3 font-bold rounded-md text-xs '>Full-Time</NavLink>
                    <NavLink to='one-off-driver' className='text-center border border-white py-2 px-2 text-white w-1/3 font-bold text-xs rounded-md'>Travel</NavLink>
                </div>

                <Swiper spaceBetween={0} effect={''} loop={false} grabCursor={true} centeredSlides={true} slidesPerView={1.2} coverflowEffect={{
                    "rotate": 0,
                    "stretch": 0,
                    "depth": 100,
                    "modifier": 1,
                    "slideShadows": false
                }}

                    pagination={true}
                    className="mySwiper">


                    <SwiperSlide className="flex  justify-center ">
                        <div id='drivers' className="w-11/12 relative ">
                            <img src={temporary} alt="Car rentals Truck Hire in Lagos Nigeria " className='w-full' />

                            <div className="first absolute top-36 bg-black border border-amber-500 px-5 py-4 rounded-2xl flex flex-col items-center justify-center">

                                <h4 className='opacity-100 text-base text-amber-500 font-bold mb-4'> Need A Temporary Driver?</h4>
                                <p className='opacity-100 text-white text-center  text-sm  leading-6 mt-2 mb-4'>We offer driving services in Nigeria for business and leisure. Our professional drivers will save you time and ensure your comfort in your car for the entire day.  </p>

                                <NavLink to='one-off-driver' className='border border-white py-1 px-4  text-white font-bold text-sm rounded-md mb-6'>Book Now</NavLink>

                            </div>

                        </div>
                    </SwiperSlide>

                    <SwiperSlide className="flex  justify-center ">
                        <div className="w-11/12 relative">
                            <img src={full_time} alt="Car rentals Truck Hire in Lagos Nigeria " className='w-full' />

                            <div className="first absolute top-36 bg-black  border border-amber-500 px-5 py-4 rounded-2xl flex flex-col items-center justify-center">

                                <h4 className='opacity-100 text-amber-500 text-base font-bold mb-8 mt-2'>Need A Driver Full-time?</h4>
                                <p className='opacity-100 text-white text-center text-sm  leading-6  tracking-wider mb-8'>

                                    Our full-time drivers are trained in all aspects of customer care and safety. And there are several benefits such as medical insurance, standard background checks amongst others. </p>

                                <button
                                    onClick={accessModal}
                                    className=' border border-white py-1 px-4  text-white font-bold text-sm rounded-md mb-8'>Access Offers</button>

                            </div>

                        </div>
                    </SwiperSlide>

                    <SwiperSlide className="flex  justify-center">
                        <div className="w-11/12 relative">
                            <img src={travel} alt="Car rentals Truck Hire in Lagos Nigeria " className='w-full' />

                            <div className="first absolute top-36 h-92 bg-black border h-68 border-amber-500 px-5 py-4 rounded-2xl flex flex-col items-center justify-center">

                                <h4 className='opacity-100 text-amber-500 font-bold mb-8 text-base'>Need A Travel Driver?</h4>
                                <p className='opacity-100 text-white text-center text-sm  leading-6 tracking-wider mb-12 mt-4'>

                                    Travel in style and comfort with our chauffeur-driven cars. We provide a range of services to cater to the special needs of our clients. Access some of our amazing travel packages today! </p>

                                <NavLink to='one-off-driver' className='mb-12 border border-white py-1 px-4 text-white font-bold text-sm rounded-md mb-8'>Book Now</NavLink>

                            </div>

                        </div>
                    </SwiperSlide>





                </Swiper>
            </section>



            <div className="bg-white md:mt-96 mt-12" id='air'>

                <div id='chat' className="relative flex xl:px-52 lg:px-36 md:px-16 md:pt-20 pt-12  px-6 justify-between w-full " >
                    <div className=" w-7/12 ">
                        <h1 className='font-bold md:text-2xl text-sm md:w-96 w-full leading-12 tracking-wide'><span className='tracking-wide text-amber-500'>Whenever You Need Us,</span> <span className='text-gray-500'>We're Here For You.</span> </h1>
                        <div className="flex items-center mt-4 md:mt-2">
                            <img src={Check} alt="check-mark" className='md:w-10 w-6' />
                            <p className=' text-xs md:text-base font-bold tracking-wide'>Airport pickups and travel</p>

                        </div>
                        <div className="flex items-center mt-1 md:mt-2">
                            <img src={Check} alt="check-mark" className='md:w-10 w-6' />
                            <p className='text-xs md:text-base font-bold tracking-wide'>Make a special request</p>

                        </div>

                        <div className="flex items-center mt-1 md:mt-2">
                            <img src={Check} alt="check-mark" className='md:w-10 w-6' />
                            <p className='text-xs md:text-base font-bold tracking-wide'>Ask more questions</p>

                        </div>

                        <button
                            onClick={chatModal}

                            className='md:mb-12 mb-4 md:bg-amber-500 bg-gray-600 text-white md:text-black md:p-2 p-1 px-8 md:px-3 flex items-center justify-center md:mt-8 mt-2 md:w-36  gap-1 rounded rounded-md'>
                            <span className='font-bold  md:text-base text-sm w-full tracking-wide'>Chat Now</span>

                        </button>
                    </div>
                    <div className="md:w-3/12 md:right-24 w-6/12 md:ml-40 mt-16 mb-0 absolute bottom-0 right-0" >
                        <img src={CallCenter} alt="callcenter" className='w-full' />
                    </div>
                </div>
            </div>

            <div className="overlay flex items-center justify-center md:px-30 px-8">

                <h1 id="promotions" className='font-extrabold md:text-4xl text-xl text-center drop-shadow-lg tracking-wider'>Special Deals You Don't Want To Miss</h1>
            </div>



            <section className='block md:hidden mt-16 px-8' >



                <Swiper spaceBetween={20} effect={'coverflow'} loop={true} grabCursor={true} centeredSlides={true} slidesPerView={1} coverflowEffect={{
                    "rotate": 0,
                    "stretch": 0,
                    "depth": 100,
                    "modifier": 1,
                    "slideShadows": false
                }}
                    autoplay
                    modules={[Autoplay, Pagination, Navigation]}

                    pagination={{ clickable: true }}
                    className="mySwiper">
                    <SwiperSlide className="flex  justify-center h-full">

                        <div className="flex gap-4">
                            <div className="card-list w-6/12 flex-flex-col gap-4">
                                <img src={Special} alt="special-event" className='' />
                                <h3 className='text-amber-500 font-extrabold text-base mt-4 mb-8 '>Special Occasion Deals</h3>
                                <p className='text-white text-sm leading-6 h-48 '>Is it your special day? You can get 10% off Roadlers chauffeur service for your wedding, birthday, or other special events.</p>

                                <Link to="/customers-dashboard#offers" className="bg-amber-500 py-1  text-center text-sm px-2  font-semibold rounded rounded-md">Use Now</Link>

                            </div>

                            <div className="card-list w-6/12 flex-flex-col gap-4">
                                <img src={Refer} alt="special-event" />
                                <h3 className='text-amber-500 font-extrabold text-base mt-4 mb-14'>Refer A Friend</h3>
                                <p className='text-white text-sm leading-6 h-48 '>For every paid referral you earn ₦1,000. You can use your cash for any of the services we offer.</p>
                                <Link to="/customers-dashboard#offers" className="bg-amber-500 py-1   text-center text-sm px-2  font-semibold rounded rounded-md">Use Now</Link>


                            </div>
                        </div>

                    </SwiperSlide>
                    <SwiperSlide className="flex  justify-center h-full">

                        <div className="flex gap-4">
                            <div className="card-list w-6/12">
                                <img src={Ride} alt="special-event" />
                                <h3 className='text-amber-500 font-extrabold text-base mt-4 mb-8 '>Ride With Us Lunch On Us</h3>
                                <p className='text-white text-sm leading-6 h-48 '>For every request you make from your wallet you get a free lunch valid for the first 30 days of your sign-up. </p>
                                <Link to="/customers-dashboard#offers" className="bg-amber-500 py-1   text-center text-sm px-2  font-semibold rounded rounded-md">Use Now</Link>


                            </div>

                            <div className="card-list w-6/12 flex-flex-col gap-4">
                                <img src={Special} alt="special-event" className='' />
                                <h3 className='text-amber-500 font-extrabold text-base mt-4 mb-8 '>Special Occasion Deals</h3>
                                <p className='text-white text-xs leading-6 h-48'>Is it your special day? You can get 10% off Roadlers chauffeur service for your wedding, birthday, or other special events.</p>


                                <Link to="/customers-dashboard#offers" className="bg-amber-500 py-1   text-center text-sm px-2  font-semibold rounded rounded-md">Use Now</Link>


                            </div>
                        </div>
                    </SwiperSlide>






                </Swiper>
            </section>


            <div className="hidden md:flex xl:px-60 lg:px-36 md:px-16 mt-20 gap-6  justify-between w-full m-auto mb-20">
                <div className="card-list w-3/12 flex-flex-col gap-4">
                    <img src={Special} alt="special-event" className='' />
                    <h3 className='text-amber-500 font-extrabold text-xl my-4 '>Special Occasion Deals</h3>

                    <p className='text-white text-sm leading-6 h-36  tracking-wider text-left'>Is it your special day? You can get 10% off Roadlers chauffeur service for your wedding, birthday, or other special events.</p>


                    <NavLink to='customers-dashboard' className='bg-amber-500 hover:border-white hover:border font-extrabold hover:text-white hover:bg-transparent py-1  text-center px-6  font-bold rounded rounded-md'>Use Now</NavLink>
                </div>
                <div className="card-list w-3/12">
                    <img src={Refer} alt="special-event" />
                    <h3 className='text-amber-500 font-extrabold text-xl my-4 mb-10 '>Refer A Friend</h3>
                    <p className='text-white text-sm leading-6  h-36  tracking-wider text-left'>For every paid referral you earn ₦1,000. You can use your cash for any of the services we offer.<br /><br /></p>
                    <NavLink to='customers-dashboard' className=' bg-amber-500 hover:border-white hover:border font-extrabold hover:text-white hover:bg-transparent py-1  px-6  text-center   font-bold rounded rounded-md'>Use Now</NavLink>

                </div>
                <div className="card-list w-3/12" >
                    <img src={Ride} alt="special-event" />
                    <h3 className='text-amber-500 font-extrabold text-xl my-4  '>Ride With Us Lunch On Us</h3>
                    <p className='text-white text-sm leading-6 h-36  tracking-wider text-left'>For every request you make from your wallet you get a free lunch valid for the first 30 days of your sign-up. </p>
                    <NavLink to='customers-dashboard' className='bg-amber-500 hover:border-white hover:border font-extrabold hover:text-white hover:bg-transparent py-1  px-6  text-center   font-bold rounded rounded-md'>Use Now</NavLink>


                </div>
            </div>

            <div id="partners"></div>
            <div className="xl:px-60 lg:px-36 md:px-16 gap-10 px-8 md:mt-40 mt-24" >
                <h2 className='text-white text-xl font-bold'>Make Money With Us</h2>
                <hr className='border-amber-500 border-t w-28 mt-3  mb-12' />
                <div className=" flex md:gap-4 gap-3 md:justify-between mb-8">
                    <img src={DriversIconW} alt="drivers" className='md:w-24 md:h-24 w-16 h-16' />
                    <div className=" md:w-7/12 w-full flex flex-col gap-2 md:gap-4 ">
                        <h1 className='text-amber-500 font-bold text-base'>Drivers</h1>
                        <p className='hidden md:flex text-white text-sm leading-6 w-11/12 tracking-wider text-justify'>Are you a professional driver seeking to serve more clients? Join our team today as there are various opportunities for you to learn, serve and earn more. </p>
                        <p className='md:hidden flex text-white text-sm leading-7 w-11/12 tracking-wider text-justify'>Are you a professional driver seeking to serve more clients? Join our team today and earn more. </p>
                        <NavLink className="md:hidden flex items-center justify-center border border-white py-1 md:px-6 rounded-md h-8 mt-2 md:mt-16 text-white md:text-sm text-xs font-semibold w-1/2 md:w-full" exact to="/service-provider"> Sign Up</NavLink>

                    </div>

                    {/* <button className='hidden md:flex border border-white py-1 px-6 rounded-md h-8 mt-16 text-white text-sm font-semibold'>Sign Up</button> */}
                    <NavLink className="hidden md:flex border border-white py-1 px-6 rounded-md h-8 mt-16 text-white text-sm font-semibold" exact to="/service-provider"> Sign Up</NavLink>


                </div>
                <div className="flex gap-4 justify-between mt-16" >
                    <img src={VehicleIcon} alt="vehicle" className='md:w-24 md:h-24 w-16 h-16' />
                    <div className="md:w-7/12 w-full flex flex-col gap-2 md:gap-4">
                        <h1 className='text-amber-500 font-bold text-base'>Vehicle Owners</h1>
                        <p className='hidden md:flex text-white text-sm leading-6 w-11/12 tracking-wider text-justify'>Would you like to make some extra money from your vehicle? Then join our team to meet the needs of thousands of people and earn more.</p>
                        <p className='md:hidden flex text-white text-sm leading-7 w-11/12 tracking-wider text-justify'>Are you a private vehicle owner seeking to make extra money? Join our team, we’ll show you how to earn more.</p>

                        <NavLink className="md:hidden flex items-center justify-center border border-white py-1 md:px-6 rounded-md h-8 mt-2 md:mt-16 text-white md:text-sm text-xs font-semibold w-1/2 md:w-full" exact to="/service-provider"> Sign Up</NavLink>


                    </div>
                    <NavLink className="hidden md:flex border border-white py-1 px-6 rounded-md h-8 mt-16 text-white text-sm font-semibold" exact to="/service-provider"> Sign Up</NavLink>


                </div>



                <h2 className='md:text-amber-500 text-white text-xl font-bold md:mt-32 mt-20 tracking-wider text-justify'>What People Are Saying About Us.</h2>
                <hr className='md:border-white-500 border-amber-500 border-t w-28 mt-3  mb-12' />


                <div className="">


                    <div class="video-background md:hidden flex items-center justify-center">
                        <div class="video-foreground">

                            <iframe width="320" height="560" src="https://www.youtube.com/embed/Yxe_LmxN-Q4" title="A day with Roadlers🚀__ #nigerian #carrental #lagos #abuja #driver #travel #naija #car roadlers.com" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

                        </div>
                    </div>


                    {/* <div className="relative hidden md:flex items-center justify-center">
                        <iframe
                            width="100%"
                            height="420"
                            ref={videoPlayerRef}

                            className='rounded-2xl'
                            src="https://www.youtube.com/watch?v=-OFPYdeEz54&feature=youtu.be">
                        </iframe>
                        <button
                            onClick={(event) => {

                                event.preventDefault()
                                console.log('play na abeg', videoPlayerRef)
                                videoPlayerRef.current.click()
                            }}
                            className="absolute  md:top-48 top-50 bottom-50 left-50 right-50 md:left-80 md:px-2 md:py-2 px-4 py-2  md:rounded-xl rounded-lg md:w-60  md:text-base text-xs bg-white font-bold flex justify-center inset-auto items-center gap-2 md:gap-4">
                            <img src={PlayButton} alt="text" className='md:w-4 w-3' />
                            <p> Watch The Story</p>

                        </button>
                    </div> */}
                    <div class="video-background hidden md:flex items-center justify-center">
                        <div class="video-foreground">


                            <iframe width="320" height="560" src="https://www.youtube.com/embed/Yxe_LmxN-Q4" title="A day with Roadlers🚀__ #nigerian #carrental #lagos #abuja #driver #travel #naija #car roadlers.com" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

                        </div>
                    </div>

                    <div className="flex mt-12 gap-12 w-full  px-0 mb-20">

                        <Ratings
                            name="Chinyere"
                            google={google}
                            ratings={ratings}
                            text="The driver did a good job. He was professional and he drove really well too. The people I went with also attested to that."
                            initial="C"

                        />


                    </div>
                </div>




                <h2 className='text-white text-xl font-bold mt-20'>A Unique Gift</h2>
                <hr className='border-amber-500 border-t w-28 mt-3  mb-12' />
                <main className='pb-20'>
                    <div className="flex justify-between  my-8 gap-8 ">
                        <div className="md:w-9/12 w-full  md:gap-8 gap-3 flex flex-col">

                            {gifts.filter((item) => item?.heading?.toLowerCase().includes('gift cards')).map((gift, index) => (

                                <div className="flex mb-16 w-full" key={gift.id}>



                                    <div className="w-5/12 md:w-72 mr-4">
                                        <img src={`https://api.roadlers.com/storage/${gift.image}`} alt="Car rentals Truck Hire in Lagos Nigeria " className='md:w-10/12' />
                                    </div>



                                    <div className="w-7/12">
                                        <div className="flex items-center justify-between">
                                            <h5 className='theme-color md:text-base text-sm font-extrabold tracking-wider mb-6'>{gift.title}</h5>


                                        </div>

                                        <p className='text-white leading-8 md:text-base text-sm tracking-wider'>{gift.description}</p>

                                        <div className="mt-4 flex md:gap-12 gap-6 items-center w-full">
                                            <h5 className='text-white md:text-base text-sm  font-semibold'>₦{parseFloat(gift.commission).toLocaleString('en-US', { maximumFractionDigits: 2 })}</h5>
                                            <button
                                                onClick={(event) => {
                                                    event.preventDefault()

                                                    setCookie('gift_type', gift.title)
                                                    setCookie('gift_price', gift.commission)

                                                    // read cookie with 
                                                    console.log('gift_type', cookies.gift_type)

                                                    navigate('/gift-card-request')


                                                }}
                                                className='border border-amber-500 p-2 md:w-24 md-1/2 text-xx font-semibold text-white rounded-md md:text-sm'
                                                type="submit"

                                            >Buy Now</button>

                                        </div>
                                    </div>

                                </div>


                            ))}






                            <div className="md:gap-6 gap-2 flex mt-16">

                                <a href="#vehicles" className='text-center bg-amber-500 p-2  md:w-40 w-1/3 font-bold text-black  rounded-md md:text-sm text-xx'>Book Cars</a>
                                <a href="#drivers" className='text-center  bg-amber-500 p-2 md:w-40 w-1/3 font-bold text-black rounded-md md:text-sm text-xx'>Book Drivers</a>
                                <a href="#chat" className='text-center border border-white p-2 md:w-40  w-1/3 font-bold text-white rounded-md md:text-sm text-xx'>Chat Now</a>

                            </div>

                        </div>


                    </div>
                </main>

            </div>
        </div>
    )
}

export default Home