import { createSlice } from "@reduxjs/toolkit";
const initialState = {}
const serviceUserSlice =  createSlice({
    name: "serviceUser",
    initialState,
    reducers: {
        setserviceUser: (state, action) => {
            return {serviceUser: action.payload};
        },
    },
});
const  {reducer, actions} = serviceUserSlice;
export const { setserviceUser } = actions
export default reducer;