import React, { Fragment, useState, useEffect, useRef, memo } from 'react'
import { Menu, Dialog, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { BASE_URL } from "../utils/constant"
import getCookie from '../utils/SecuredTokens';

import Header from '../Header'
import TopForm from '../TopForm'
import Completed from './Completed'
import one from '../../Images/one.png'
import eyess from '../../Images/eyes2.svg'
import icon from '../../Images/icon.svg'

import { Form, Modal, Space } from 'antd';
import DatePicker from "react-datepicker";

import moment from 'moment';
import '../../index.css'

import dashboard from '../../Images/dashboard.png'
import payment from '../../Images/payment.png'

import refs from '../../Images/refs.png'
import Spin from "../../Images/spinner.gif"

import naira from '../../Images/naira.png'
import offers from '../../Images/offers.png'
import notifications from '../../Images/notifications.png'
import pen from '../../Images/pen.svg'
import eyes from '../../Images/eyes.png'
import closed from "../../Images/closed.svg"

import btc from '../../Images/btc.svg'
import Subheader from './Subheader';
import Bookings from './Bookings';
import driverOne from '../../Images/driver3.png'
import barCode from '../../Images/barcode.svg'
import goldenTicket from '../../Images/goldenTicket.svg'
import cancelImg from '../../Images/cancel.svg'

import transfer from '../../Images/transfer.png'
import bank from '../../Images/bank.png'

import top from '../../Images/top.png'

import refer_a_friend from '../../Images/refer-a-friend.png'

import Bookmark from 'react-bookmark';

import Twitter from '../../Images/Twitter.png'
import Facebook from '../../Images/Facebook.png'
import Whatsapp from '../../Images/WHATSAPP.png'
import IG from '../../Images/IG.png'
import Mail from '../../Images/Mail.png'
import ride_with_us from '../../Images/ride-with-us.png'
import special_events from '../../Images/special-events.png'
import closed_eye from '../../Images/closed_eye.png'
import FullDay from '../../Images/gift_faint.png';
import FullMonth from '../../Images/gift.png';
import bitcoin from '../../Images/btc.png';
import ethereum from '../../Images/ethereum.png';
import usdt from '../../Images/usdt.png';
import binance from '../../Images/binance.png';
import axios from "axios";
import cartwhite from '../../Images/cart-white.svg';
import user_icon from '../../Images/userg.png'
import { NavLink, useNavigate } from "react-router-dom";
import { useCookies } from 'react-cookie';
import { useDispatch } from 'react-redux';
import { mealOffers, ticketOffers } from '../../slices/specialOffers'
import { useContext } from 'react'
import { NavHashLink as Link } from "react-router-hash-link";

import { useFormik } from 'formik'
import * as Yup from 'yup'
import mark from '../../Images/mark.png'
import { walletToWallet, withdrawCryptoWallet } from '../../slices/cryptoWallet'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CurrencyInput from 'react-currency-input-field';
import success from '../../Images/success.png'
import roadlersRR from '../../Images/RoadlersRR.svg'

import { getTransHistory } from '../../slices/Transaction'

import { viewNotify } from '../../slices/Notification'
// import NotificationContext from '../../Context/NotificationContext'
import { setBadge } from '../../slices/badge'
function CustomersDashboard() {
    const notify = () => { toast("Copied Successfully!") };

    function ScrollToTopOnMount() {
        useEffect(() => {
            window.scrollTo(0, 0);
        }, []);

        return null;
    }

    const [cookies, setCookie, removeCookie] = useCookies();


    // ADMIN LOADER
    const [dashboardLoad, setDashboardLoad] = useState(true)

    // const { iconAlert, handleNotificationAlertIcon } = useContext(NotificationContext)

    let navigate = useNavigate();
    const dispatch = useDispatch()

    const dueRef = useRef(null)

    // const { RangePicker } = DatePicker;
    const dateFormat = 'DD/MM/YYYY';
    const [form] = Form.useForm();
    const modalVisible = true;

    const [gifts, setGifts] = useState([]);

    const [open, setOpen] = useState(false);
    const [firstOpen, setFirstOpen] = useState(false);


    let user = getCookie("user");
    let userObject = JSON.parse(user) || JSON.parse(localStorage.getItem("gogglelogin"))
    let token = userObject?.token
    let user_id = userObject?.id




    // let urls = 'http://localhost:3000/customers-dashboard?google=%7B%22token%22%3A%22eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiMGI1YjA3OGY3YTRhOGJhZTU4ZWU3OTY4ZWY2ODc0MDlhYjk0ZTE3Yzg5NTMxYjg5MzM5ZWM2NmY5NGRmZDUwYjdkMDI2Njc2Mzg0ZDI3YjMiLCJpYXQiOjE2NjIxMjI1OTMuMjYyNzQ4LCJuYmYiOjE2NjIxMjI1OTMuMjYyNzUxLCJleHAiOjE2OTM2NTg1OTMuMjUzODY4LCJzdWIiOiI1NSIsInNjb3BlcyI6W119.xIbIPCcGqYPnNGF4LiIu78G_e_Us1GqlGIFtJGR9zGXdeyzDzIYho3Fro-W7K0I3Xph7GZNuicQXO8zN4X4EIoTjErSii6Ih_wNcODa3yVA2PZiVNorWOifJ1mZyI72EO48CFGto502hcSrOLqOLyQowUEcusj_Vs0a_ggGymlG8r4vaY8DSmPmRx63HEnKQH631qi4T81DGSvEltAiX5VbbrQOPczirFuAnvJ2SutVMijauhjXImtidov9UsBAZIpIbm2oRcTpMD3ljQZ_aZg1HPJDLk3KoCmlPADnUfnms4PC-sRjFbeNpC_wzTQbkvCikfslP_-btGWtfWo2IOe_5BnJllcI7_tAhPifaqMLPnteS5sGE9LgOGNvJy3OJ51ikn_90IqW_QzUsYJ06acXBBnKkUvHxk7x5E7qY8jiiKSUmQbC8QuzWG49JU7uBI5H0OcBtcpZHn5yCf11_ert6Vb6wBqbD7g1DnkuBjO2krCFzO0Jzyo32Q-iA-fblfU8FONapb6r6zFbRu85vD8jnXDKibhkMEKlgX1i1LCwwZJRlAWwfCKWKT8do5HgHzUcjcT_RKj1iiYLUungW_-dZ_vGnBxVdjOwoKD3V9o77G4BFvhKFlpzw947bhlkjNH1oJ-2tN1wZeP75Vozy29puGiUkzZsEbhIlnjXPhVc%22%2C%22expiresin%22%3A31535999%2C%22id%22%3A55%2C%22email%22%3A%22dynamicchristianassembly%40gmail.com%22%2C%22name%22%3A%22Dynamic+Christian+Assembly%22%2C%22phone_number%22%3Anull%2C%22category%22%3Anull%2C%22service%22%3Anull%2C%22user_id%22%3A73821721%2C%22firstname%22%3Anull%2C%22lastname%22%3Anull%2C%22user_role%22%3A%22customer%22%2C%22if_register%22%3Anull%2C%22username%22%3A%22DynamicChristianAssembly633036%22%7D'


    // get the current url then convert the url params coming from goggle to JSON
    let urls = window.location.href;
    if ((urls === 'https://roadlers.com/customers-dashboard') || 
    (urls === 'http://localhost:3000/customers-dashboard') || 
    (urls === 'http://localhost:3001/customers-dashboard')
    ||  (urls === 'https://test.roadlers.com/customers-dashboard')
    ) {

    } else {

        const decodedURL = decodeURIComponent(urls);
        let newText = decodedURL.replace("https://roadlers.com/customers-dashboard?google=", "");
        let userObject = JSON.parse(newText);
        localStorage.setItem("gogglelogin", JSON.stringify(userObject));
    }





    //   const [load, setLoad] = useState("")

    const [referralLink, setReferralLink] = useState('')
    const [referralCode, setReferralCode] = useState('')
    const [fiatWallet, setFiatWalletAddress] = useState('')
    const [accountRef, setAccountRef] = useState('')
    let [bvnSetting, setBvnSetting] = useState('')
    const [balance, setBalance] = useState('')
    const [accountNumber, setAccountNumber] = useState('')
    const [bankName, setBankName] = useState('')
    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [emailAddress, setEmailAddress] = useState('')
    const [password, setPassword] = useState('')

    const [initial, setInitials] = useState('')

    const [myBookings, setMyBookings] = useState([])
    const [dues, setDue] = useState()


    const [bookingOpen, setBookingOpen] = useState(true)
    const [completedOpen, setCompletedOpen] = useState(true)
    const [referralOpen, setReferralOpen] = useState(true)
    const [giftOpen, setGiftOpen] = useState(true)
    const [offersOpen, setOffersOpen] = useState(true)

    const [bg, setBg] = useState(false)

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    let setSelectedStartDate = moment(startDate).format("DD/MM/YYYY")
    let setSelectedEndDate = moment(endDate).format("DD/MM/YYYY")
    //trigger modal for top up
    let [topUpOpen, setTopUpOpen] = useState(false)
    let [transferOpen, setTransferOpen] = useState(false)
    let [fundWalletOpen, setfundWalletOpen] = useState(false)
    let [isOpen, setIsOpen] = useState(false)
    const [selectCurrencyModal, setSelectCurrencyModal] = useState(false)
    const [selectCryptoAdressModal, setSelectCryptoAdressModal] = useState(false)
    let [successfulOpen, setSuccessfulOpen] = useState(false)
    //trigger modal for BVN Storage
    let [bvnOpen, setBvnOpen] = useState(false)
    // Golden Ticket Modal
    const [goldenTicketModal, setGoldenTicketModal] = useState(false)
    // Notification Modal
    const [notificationModal, setNotificationModal] = useState(false)

    const [transactionHistModal, setTransactionHistModal] = useState(false)


    const [load, setLoad] = useState(false)

    // Transaction History Data
    const [transactionHistoryData, setTransactionHistoryData] = useState([])

    const [completeBookingData, setCompleteBookingData] = useState([])



    let [amount, setAmount] = useState('')

    let [credit, setCredit] = useState('')


    let [usernamec, setUsername] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [customerId, setCustomerId] = useState('')

    const [url, setUrl] = useState('')
    const [viewWallet, setViewWallet] = useState(true)
    const [viewCrypto, setViewCrypto] = useState(true)

    // Wallet To Wallet Transfer data
    const [walletCurrency, setWalletCurrency] = useState('BTC')
    const [walletAddress, setWalletAddress] = useState('')
    const [walletAmount, setWalletAmount] = useState('')
    let [walletPassword, setWalletPassword] = useState('')

    // DEPOSIT CRYPTO
    const [cryptoNetworks, setCryptoNetworks] = useState('')

    // Wallet Address Text That is copied
    const [text, setText] = useState('')

    // Golden Ticket Hover Info 
    const [offerInfo, setOfferInfo] = useState(false)



    let [bvn, setBvn] = useState('')
    // Transaction Modal

    // ALERT POP UP
    const [alert, setAlert] = useState(false)

    const [fiatUpdate, setFiatUpdate] = useState(false)



    let refLink = `http://roadlers.com/sign-up${referralLink}`





    function closeModal() {
        setSelectCurrencyModal(false)
        setTransactionHistModal(false)
        setSelectCryptoAdressModal(false)
        setGoldenTicketModal(false)
        setNotificationModal(false)
        setIsOpen(false)
        setTransferOpen(false)
        setTopUpOpen(false)
        setfundWalletOpen(false)
        setBg(false)
        setBvnOpen(false)
        setSuccessfulOpen(false)

    }

    function openModal() {
        setIsOpen(true)
        setBg(true)

    }

    function hideWallet() {
        setViewWallet(false)
    }
    function showWallet() {
        setViewWallet(true)
    }


    function showCrypto() {
        setViewCrypto(true)
    }

    function hideCrypto() {
        setViewCrypto(false)
    }



    function closeBookingHeader(e) {
        e.preventDefault();
        setBookingOpen(false)
    }

    function openBookingHeader(e) {
        e.preventDefault();
        setBookingOpen(true)
    }

    function closeCompletedHeader(e) {
        e.preventDefault();
        setCompletedOpen(false)
    }

    function openCompletedHeader(e) {
        e.preventDefault();
        setCompletedOpen(true)
    }

    function closeReferralHeader(e) {
        e.preventDefault();
        setReferralOpen(false)
    }

    function openReferralHeader(e) {
        e.preventDefault();
        setReferralOpen(true)
    }


    function closeGiftHeader(e) {
        e.preventDefault();
        setGiftOpen(false)
    }

    function openGiftHeader(e) {
        e.preventDefault();
        setGiftOpen(true)
    }

    function closeOffersHeader(e) {
        e.preventDefault();
        setOffersOpen(false)
    }

    function openOffersHeader(e) {
        e.preventDefault();
        setOffersOpen(true)
    }


    function topUpModal() {

        setSelectCurrencyModal(false)
        setTransactionHistModal(false)
        setSelectCryptoAdressModal(false)
        setGoldenTicketModal(false)
        setNotificationModal(false)
        setIsOpen(false)
        setTransferOpen(false)

        setfundWalletOpen(false)

        setBvnOpen(false)
        setSuccessfulOpen(false)

        setTopUpOpen(true)
        setBg(true)
    }

    function fundWalletModal() {


        setfundWalletOpen(true)
        setBg(true)

        setSelectCurrencyModal(false)
        setTransactionHistModal(false)
        setSelectCryptoAdressModal(false)
        setGoldenTicketModal(false)
        setNotificationModal(false)
        setIsOpen(false)
        setTransferOpen(false)
        setTopUpOpen(false)

        setBvnOpen(false)
        setSuccessfulOpen(false)

    }

    function transferModal() {
        setTransferOpen(true)
        setBg(true)

    }

    function bvnModal() {
        setBvnOpen(true)
        setBg(true)

    }


    //get referral link and referral code from the end-point
    const getRef = async () => {
        await axios({
            method: "get",
            url: `${BASE_URL}/v1/referral-link`,
            dataType: "JSON",
            withcredentials: true,

            headers: {
                'Content-Type': 'application/json',

                Authorization: `Bearer ${token}`,

                "Access-Control-Allow-Origin": "*",


            }
        })
            .then((response) => {
                const reffLink = response.data.data.referral_link
                const reffCode = response.data.data.referral_code

                setReferralLink(reffLink)
                setReferralCode(reffCode)

            })
            .catch((error) => {
                console.log(error);
            });
    }

    useEffect(() => getRef(), []);

    const handleCopy = () => {
        navigator.clipboard.writeText(refLink)
        notify()
    }

    function handleFilterDate(date) {
        let dateTime = moment(date).format("DD/MM/YYYY")
        console.log('date', dateTime)


    }

    const createWalletFiat = async () => {

        let formData = new FormData();
        formData.append("currency_code", 'NGN');

        // convert the formdata to JSON data
        let object = {};
        formData.forEach((value, key) => object[key] = value);
        let json = JSON.stringify(object)

        await axios({
            method: "post",
            url: `${BASE_URL}/v1/wallet/create-fiat-wallet`,
            data: json,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            }
        })
            .then((response) => {
                const respLike = response.data.success
                const fiatWalletAddress = response.data.data.fiat_wallet_address
                console.log('fiatWalletAddress', fiatWalletAddress)
                setFiatWalletAddress(fiatWalletAddress)
            })
            .catch((error) => {
                console.log(error);
            });
    };
    useEffect(() => createWalletFiat(), []);



    //get user wallet details
    const getWalletDetails = async () => {
        await axios.get(`${BASE_URL}/v1/wallet/get-fiat-wallet/${user_id}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            }
        }).then((response) => {
            const allwalletdatas = response.data.data;

            const account_reference = allwalletdatas.account_reference
            const account_balance = allwalletdatas.balance
            const bvnSetup = allwalletdatas.bvn
            const virtualAccount = allwalletdatas.virtual_account_number
            const virtualBank = allwalletdatas.bank_name


            setAccountNumber(virtualAccount)
            setBankName(virtualBank)
            localStorage.setItem("walletBalanceData", JSON.stringify(account_balance));

            const balance = parseFloat(account_balance).toFixed(2);

            setAccountRef(account_reference)
            setBalance(balance)
            setBvnSetting(allwalletdatas.bvn)
            setBvnSetting(bvnSetup => {
                // props.handle_card_selected(props.id, !prevSelected)
                return bvnSetup
            })


        });
    };
    useEffect(() => {
        getWalletDetails();
    }, []);

    useEffect(() => {
        getWalletDetails({});
    }, [fiatUpdate]);



    //get the customer details
    const getCustomerDetails = async () => {
        await axios.get(`${BASE_URL}/v1/show-username/${user_id}`, {

            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            }
        }).then((response) => {
            const alluserName = response.data;
            const fullname = alluserName.name;
            const email = alluserName.email;
            const user_name = alluserName.username
            const nameSplit = fullname.split(/(\s+)/);
            const firstName = nameSplit[0]

            setFirstname(firstName)

            const lastName = nameSplit[2]
            setLastname(lastName)

            setEmailAddress(email)
            setUsername(user_name)

            const initials = [];
            for (let i = 0; i < lastName?.length; i++) {
                const lastname_split = lastName[i]?.split(" ");
                initials.push(lastname_split[0]);
                setInitials(initials[0]);
            }

            setDashboardLoad(false)
        });
    };
    useEffect(() => {
        getCustomerDetails();
    }, []);


    const getTransactionsDetails = async () => {
        // let id = JSON.parse(localStorage.getItem("userId"))
        await axios.get(`${BASE_URL}/v1/list-bookings/${user_id}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            }
        }).then((response) => {
            const bookingHistory = response.data.data;
            setMyBookings(bookingHistory)

            let due = bookingHistory.filter(
                obj => {
                    return obj.destination === 'Out of State' && obj.payment_status === 'UNPAID'
                }
            )

            setDue(due.length)
            // console.log(due.length, 'due.length')
        });
    };
    useEffect(() => {
        let subscribed = true
        if (subscribed) {
            getTransactionsDetails();
            return () => {
                subscribed = false;
            }
        }
    }, []);






    //get the gift cards
    const fetchPrices = (e) => {
        axios
            .get(`${BASE_URL}/v1/view-pricing`, {
                headers: {
                    "Content-Type": "application/json",

                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((response) => {
                const transData = response.data.data;



                let gift = transData.filter((data) => {
                    return data.price_category === 'gift_price'
                })



                setGifts(gift)

            });
    }
    useEffect(() => {
        fetchPrices();
    }, []);

    const formikTopUp = useFormik({
        initialValues: {
            amount: '',
            currency: "NGN"

        },
        validationSchema: Yup.object({
            // amount: Yup.string().min(5, "Must be 3 Characters or more").required("This is Required")


        }),
        onSubmit: (values) => {
            setLoad(Spin)
            axios({
                method: 'post',
                url: `${BASE_URL}/v1/initialize-paystack-transaction`,

                data: values,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    "Access-Control-Allow-Origin": "*",
                }

            }).then(function (response) {
                //handle success
                setLoad('')

                if (response.data.status === true) {

                    const resp = response.data.data;
                    const accessCode = resp.access_code
                    const authorizationUrl = resp.authorization_url
                    const ref = resp.reference

                    setUrl(authorizationUrl)
                }
                else {
                    const messsage = response.data.message
                    // navigate('/login')
                }
            })
                .catch(function (error) {
                    //handle error
                    console.log("error ", error)

                    setLoad('');
                });
        },
    })




    const getUserName = async () => {


        await axios.get(`${BASE_URL}/v1/show-username/${user_id}`, {

            headers: {
                'Content-Type': 'application/json',

                Authorization: `Bearer ${token}`,

                "Access-Control-Allow-Origin": "*",


            }
        }).then((response) => {
            const alluserName = response.data;
            const username = alluserName.username;

            const fullname = response.data.name;
            const nameSplit = fullname.split(/(\s+)/);
            const firstName = nameSplit[0]
            const phone = response.data.phone_number;






            setFirstname(firstName)

            const lastName = nameSplit[2]

            setLastname(lastName)

            const initials = [];
            for (let i = 0; i < lastName?.length; i++) {
                const lastname_split = lastName[i]?.split(" ");
                initials.push(lastname_split[0]);
                setInitials(initials[0]);
            }
            setPhoneNumber(phone)
            setUsername(username)

        });
    };
    useEffect(() => {
        getUserName();
    }, []);



    // let userName = usernamec;

    const transferFiat = async (e) => {
        e.preventDefault()

        let formData = new FormData();
        formData.append("amount", amount);
        formData.append("creditWalletUsername", credit);
        formData.append("debitWalletUsername", usernamec);
        formData.append("password", password);



        // convert the formdata to JSON data
        let object = {};
        formData.forEach((value, key) => object[key] = value);
        let json = JSON.stringify(object)
        console.log('json', json)

        axios({
            method: 'post',
            url: `${BASE_URL}/v1/wallet/transfer`,
            data: formData,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            }
        }).then(function (response) {
            //handle success

            setSuccessfulOpen(true)
            // window.location.reload();

            setFiatUpdate((prevState) => !prevState)


            const notify = () => { toast(response.data.message) };
            notify()
        })
            .catch(function (error) {
                //handle error
                console.log("error ", error.response)
                // setLoad('');

                const notify = () => { toast(error.response.data.message) };
                notify()

            });
        setTransferOpen(false)
        setBg(false)

    };



    const openInNewTab = () => {
        const newWindow = window.open(url, "_self")
        // if (newWindow) newWindow.opener = null
    }


    useEffect(() => {
        if (url != '') {
            openInNewTab();
        }

    }, [url]);

    // Objects for crypto wallet transfer
    const cryptoTransferData = {
        'currency': walletCurrency.toLowerCase(),
        'amount': walletAmount,
        'crypto_address': walletAddress,
        'password': walletPassword
    }

    // Wallet Transfer Function
    const onWalletTransfer = (e) => {
        e.preventDefault()

        dispatch(withdrawCryptoWallet(cryptoTransferData));
        setIsOpen(false)
        setBg(false)
    }

    // Deposit Crypto Details
    const onAccessWalletAddress = () => {

        const cryptoDepositDetails = {
            'currency': walletCurrency,
            'crypto_network': cryptoNetworks
        }

        localStorage.setItem("cryptoDeposit", JSON.stringify(cryptoDepositDetails));

        setSelectCryptoAdressModal(true)
        setSelectCurrencyModal(false)
        setBg(false)
        setBg(true)
    }

    // Send Wallet Address
    const sendWalletAddress = () => {
        setSelectCryptoAdressModal(false)
    }



    const [notifyListData, setNotifyListData] = useState([])


    // Handle Notification Modal
    const handleNotificationModal = () => {
        setNotificationModal(true)
        setBg(true)
        dispatch(setBadge(false))
        dispatch(viewNotify()).then(
            (response) => {
                setNotifyListData(response.payload.data)
                console.log(response.payload.data[0].data.message)
                console.log(notifyListData.length)
                console.log('notifyListData', notifyListData)
            },
            (error) => {
                const message =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString()
                console.log(message)
            }
        )
    }

    // Handle Transaction Modal
    const handleTransactionHistModal = () => {
        setTransactionHistModal(true)
        setBg(true)
        dispatch(getTransHistory(user_id)).then(
            (response) => {
                setTransactionHistoryData(response?.payload.data)
                const transactionHistData = response?.payload.data
                // localStorage.setItem("dataHistory", JSON.stringify(transactionHistData));
                setTransactionHistoryData(transactionHistData)
                console.log(transactionHistoryData)
            },
            (error) => {
                const message =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString()
                console.log(message)
            }
        )
    }

    // const showTransHistory = JSON.parse(localStorage.getItem("dataHistory"))
    // console.log(showTransHistory[0].status, "seeeeeeeeee")

    // const sortedDesc = showTransHistory?.sort(
    //   (objA, objB) => {+new Date(objB.updated_at).getTime() - +new Date(objA.updated_at).getTime();
    //   }
    // );

    // Set Alert Timeout function
    // const alerTimeout = 

    // Copy Text Function 
    const copy = async () => {
        await navigator.clipboard.writeText(text);
        alert(`Wallet Address Copied`);
        setAlert(true)
        setTimeout(() => {
            setAlert(false);
        }, 3000);
    }


    // transaction-history


    function handleCreateVirtualWallet(e) {


        e.preventDefault()
        let formData = new FormData();
        formData.append("bvn", bvn);
        axios({
            method: 'post',
            url: `${BASE_URL}/v1/setup-virtual-account`,

            data: formData,

            headers: {
                'Content-Type': 'application/json',

                Authorization: `Bearer ${token}`,

                "Access-Control-Allow-Origin": "*",


            }


        }).then((response) => {
            const respData = response.data.data

            setBvnOpen(false)
            setBg(false)

            if (response.data.status === true) {
                setTopUpOpen(true)
                setBg(true)
                const notify = () => { toast('BVN Validation Success') };
                notify()
            } else {
                alert('respData')

            }
            // setCarSearch(respData)

            // const notify = () => { toast("Copied Successfully!") };
            // notify()

        }).catch((error) => {
            setBvnOpen(false)
            setBg(false)
            // 
            
            const notify = () => { toast('BVN Validation Failed') };
            notify()






        })
    }



    const [visible, setVisible] = useState(5);

    function showMore(e) {
        e.preventDefault()
        setVisible((prevValue) => prevValue + 5)
    }

    const [goldenTicketData, setGoldenTicketData] = useState([])

    // COUPON CODE
    const getGoldenTicket = () => {
        dispatch(ticketOffers()).then((response) => {
            console.log(response.payload.data.coupon_code, 'golden Ticket offer')
            setGoldenTicketData(response.payload.data)
            // localStorage.setItem("goldenTicket", JSON.stringify(response.payload.data))
            // localStorage.setItem("goldenTicketCoupon", JSON.stringify(response.payload.data.coupon_code))
        })
    }

    // const couponCode = JSON.parse(localStorage.getItem("goldenTicketCoupon"))


    // Handle Golden Ticket Modal
    const handleGoldenTicketModal = () => {
        setGoldenTicketModal(true)
        setBg(true)

        getGoldenTicket()
    }

    const [mealTicketOffer, setMealTicketOffer] = useState([])

    // MEAL TICKETS INFORMATION
    const getMealOffer = () => {
        dispatch(mealOffers()).then((response) => {
            console.log(response?.payload?.data?.status, 'meal offer')
            // localStorage.setItem("mealOffer", JSON.stringify(response.payload.data))

            setMealTicketOffer(response.payload?.data)
            console.log(mealTicketOffer)
        })
    }

    useEffect(() => {
        getMealOffer()
    }, [])

    // const mealRide = JSON.parse(localStorage.getItem("mealOffer"))
    const mealRideDayNum = (mealTicketOffer?.count_down)
    const mealTicketNum = 5 - mealTicketOffer?.counter

    // GET COMPLETE BOOKING ARRAY
    const getCompletedBooking = () => {
        axios({
            method: "get",
            url: `${BASE_URL}/v1/completed-booking`,
            dataType: "JSON",
            withcredentials: true,

            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            }
        })
            .then((response) => {
                setCompleteBookingData(response.data.data)
            })
            .catch((error) => {
                console.log(error);
            });
    }

    useEffect(() => {
        getCompletedBooking()
    }, [])

    // GET VIEW PRICING
    const getViewPricing = () => {
        axios({
            method: 'get',
            url: `${BASE_URL}/v1/view-pricing`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            }
        })
            .then(function (response) {
                console.log(response.data.data)
            })
            .catch(function (error) {
                console.log("error ", error)
            });
    }

    useEffect(() => {
        getViewPricing()
    }, [])

    return (
        dashboardLoad ?
            (<>
                <ScrollToTopOnMount />

                <div className='h-[100vh] w-full flex justify-center items-center'>
                    <div className="lds-ring">
                        <img src={roadlersRR} alt="roadlers logo" className='embed-logo' />
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </>
            ) :
            (<div className={bg === true ? " blur-sm contrast-50 backdrop-brightness-50" : "w-full "}>
                <Header user='login_customer' />


                <Transition appear show={successfulOpen} as={Fragment}>
                    <Dialog
                        as="div"
                        className="fixed flex items-center justify-center w-full px-6 mt-8  inset-0 z-10 overflow-y-auto"
                        onClose={closeModal}
                    >
                        <div className="min-h-screen w-full xl:w-6/12 lg:w-7/12 md:w-8/12 px-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-25 bg-white"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-25"
                                leaveTo="opacity-0"
                            >
                                <Dialog.Overlay className="fixed inset-0" />
                            </Transition.Child>

                            {/* This element is to trick the browser into centering the modal contents. */}
                            <span
                                className="inline-block h-screen align-middle"
                                aria-hidden="true"
                            >
                                &#8203;
                            </span>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <div className="w-10/12 inline-block  max-w-md pt-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-md">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 text-gray-900"
                                    >
                                    </Dialog.Title>
                                    <div className="bg-white pb-4 pr-4 pt-1 pl-4">
                                        <div className="flex w-full justify-end">
                                            <button
                                                type='button'

                                                className=''>
                                                <img src={cancelImg} className='w-[30px]' onClick={closeModal} alt="Cancel Modal" />
                                            </button>
                                        </div>

                                        <img src={success} alt="" />
                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </Dialog>
                </Transition>


                <>
                    <div className="">
                        <Transition appear show={transferOpen} as={Fragment}>
                            <Dialog
                                as="div"
                                className="w-full fixed px-6 inset-0 z-10 flex justify-center items-center"
                                onClose={closeModal}
                            >
                                <div className="w-full w-full xl:w-5/12 lg:w-5/12 md:w-4/12 min-h-screen px-4 text-center">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="ease-in duration-200"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <Dialog.Overlay className="fixed inset-0" />
                                    </Transition.Child>

                                    {/* This element is to trick the browser into centering the modal contents. */}
                                    <span
                                        className="inline-block h-screen align-middle"
                                        aria-hidden="true"
                                    >
                                        &#8203;
                                    </span>
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0 scale-95"
                                        enterTo="opacity-100 scale-100"
                                        leave="ease-in duration-200"
                                        leaveFrom="opacity-100 scale-100"
                                        leaveTo="opacity-0 scale-95"
                                    >
                                        <div className="w-full inline-block max-w-md pt-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                                            <Dialog.Title
                                                as="h3"
                                                className="text-lg font-medium leading-6 text-gray-900"
                                            >
                                                <img src={transfer} alt="" />
                                            </Dialog.Title>

                                            <div className="bg-project p-6">
                                                <form>
                                                    <div className="mt-2">
                                                        <label htmlFor="" className='text-xx md:text-sm text-white ml-5'>Transfer Amount</label>
                                                        <div className="flex items-center text-sm mt-1 px-2 w-full rounded-lg border border-white  text-white">


                                                            <CurrencyInput

                                                                placeholder="Type Here..."
                                                                decimalsLimit={2}
                                                                allowNegativeValue={false}
                                                                prefix="₦"
                                                                // type="text"
                                                                className='p-2 md:text-sm text-xs w-full bg-transparent placeholder:text-xs   focus:outline-none text-white'


                                                                value={amount}
                                                                onValueChange={(value) => {
                                                                    setAmount(value)
                                                                }}



                                                            />
                                                        </div>
                                                    </div>


                                                    <div className="mt-3">
                                                        <label htmlFor="wallet" className='text-xx md:text-sm text-white ml-5'>Transfer To</label>
                                                        <div className="flex items-center text-sm mt-1 px-2 w-full rounded-lg border border-white  text-white">
                                                            <input
                                                                type="text"
                                                                placeholder='Customer Username'
                                                                className='p-2 md:text-sm text-xs w-full bg-transparent placeholder:text-xs   focus:outline-none text-white'
                                                                value={credit}
                                                                onChange={e => setCredit(e.target.value)}
                                                                autoComplete='new-password'
                                                            />
                                                        </div>
                                                    </div>


                                                    <div className="mt-3">
                                                        <label htmlFor="" className='text-xx md:text-sm text-white ml-5'>Your Password</label>
                                                        <div className="flex items-center text-sm mt-1 px-2 w-full rounded-lg border border-white  text-white">
                                                            <input
                                                                type="password"
                                                                className='p-2 w-full md:text-sm text-xs bg-transparent placeholder:text-xs   focus:outline-none text-white'
                                                                autoComplete='new-password'
                                                                value={password}
                                                                onChange={e => setPassword(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="mt-3 w-full flex items-center justify-center">
                                                        <button
                                                            type="submit"
                                                            className="inline-flex justify-center px-4 py-2 text-sm font-bold text-black bg-amber-500 border border-transparent rounded-md  focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                                                            onClick={e => transferFiat(e)}
                                                        >
                                                            Transfer
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </Transition.Child>
                                </div>
                            </Dialog>
                        </Transition>
                    </div>
                </>
                {/* set up account modal */}
                <>
                    <Transition appear show={bvnOpen} as={Fragment}>
                        <Dialog
                            as="div"
                            className="w-full md:w-11/12  fixed flex justify-center items-center px-6 inset-0 z-10 overflow-y-auto"
                            onClose={closeModal}
                        >
                            <div className="w-full xl:w-6/12 lg:w-7/12 md:w-8/12 min-h-screen px-4 text-center">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-25 bg-white"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-25"
                                    leaveTo="opacity-0"
                                >
                                    <Dialog.Overlay className="fixed inset-0" />
                                </Transition.Child>

                                {/* This element is to trick the browser into centering the modal contents. */}
                                <span
                                    className="inline-block h-screen align-middle"
                                    aria-hidden="true"
                                >
                                    &#8203;
                                </span>
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                >
                                    <div className="w-full md:w-6/12 inline-block  max-w-md pt-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                                        <Dialog.Title
                                            as="h3"
                                            className="text-lg font-medium leading-6 text-gray-600"
                                        >
                                            <img src={top} alt="" />
                                        </Dialog.Title>

                                        <div className="bg-black p-10">

                                            <form onSubmit={formikTopUp.handleSubmit}>
                                                <div className="mt-1 flex flex-col gap-4">
                                                    <label htmlFor="" className='text-sm font-semibold text-amber-500'>Set Up Your Wallet </label>
                                                    <label htmlFor="" className='text-xs font-semibold text-amber-500'>Make sure you save your profile in the profile page before submitting BVN </label>

                                                    <label htmlFor="" className='text-xs  text-white'>BVN Required </label>
                                                    <input


                                                        // type="text"
                                                        className='flex bg-transparent items-center text-sm mt-2 p-2 w-full rounded-md border border-white  text-white'
                                                        name="bvn"
                                                        id="bvn"

                                                        value={bvn}

                                                        onChange={e => setBvn(e.target.value)}
                                                    />

                                                    {/* TEXT COPIED ALERT */}
                                                    <div className={alert ? `mx-auto sm:w-3/4 md:w-2/4 fixed inset-x-0 top-10 flex items-center z-[10000] transition duration-150 ease-in` : `hidden`}>
                                                        <div
                                                            className="flex items-center bg-green-500 rounded-lg px-6 py-4 justify-center w-full mt-6"
                                                        >
                                                            <p className='m-0 text-white text-center text-lg'> Wallet Address has been successfully copied</p>
                                                        </div>
                                                    </div>

                                                </div>



                                                <div className="flex gap-2 items-center cursor-pointer mt-2">
                                                    <button
                                                        onClick={handleCreateVirtualWallet}
                                                        type="submit"
                                                        className='rounded-md mt-4 py-2 px-8 text-black text-xs font-bold bg-amber-500'>Done </button>
                                                </div>


                                                {/* 
                                            <div className="mt-3 w-full flex items-center justify-center">
                                                <button
                                                    type="button"
                                                    className="inline-flex justify-center px-4 py-2 text-sm font-bold text-black bg-amber-500 border border-transparent rounded-md  focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                                                    onClick={closeModal}
                                                >
                                                    Close
                                                </button>
                                            </div> */}
                                            </form>

                                        </div>
                                    </div>
                                </Transition.Child>
                            </div>
                        </Dialog>
                    </Transition>
                </>


                {/* Top up Modal */}
                <>
                    <Transition appear show={topUpOpen} as={Fragment}>
                        <Dialog
                            as="div"
                            className="w-full md:w-11/12  fixed flex justify-center items-center px-6 inset-0 z-10 overflow-y-auto"
                            onClose={closeModal}
                        >
                            <div className="w-fullw-full xl:w-6/12 lg:w-7/12 md:w-8/12 min-h-screen px-4 text-center">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-25 bg-white"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-25"
                                    leaveTo="opacity-0"
                                >
                                    <Dialog.Overlay className="fixed inset-0" />
                                </Transition.Child>

                                {/* This element is to trick the browser into centering the modal contents. */}
                                <span
                                    className="inline-block h-screen align-middle"
                                    aria-hidden="true"
                                >
                                    &#8203;
                                </span>
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                >
                                    <div className="w-full md:w-6/12 inline-block  max-w-md pt-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                                        <Dialog.Title
                                            as="h3"
                                            className="text-lg font-medium leading-6 text-gray-600"
                                        >
                                            <img src={top} alt="" />
                                        </Dialog.Title>

                                        <div className="bg-project p-10">

                                            <form onSubmit={formikTopUp.handleSubmit}>
                                                <div className="mt-1">
                                                    <label htmlFor="" className='text-xs  text-white'>Top-Up Amount</label>
                                                    <CurrencyInput
                                                        placeholder="Type Here...."
                                                        decimalsLimit={2}
                                                        allowNegativeValue={false}
                                                        prefix="₦"
                                                        className='flex bg-transparent items-center text-sm mt-2 p-2 w-full rounded-md border border-white  text-white'
                                                        name="amount"
                                                        id="amount"
                                                        onBlur={formikTopUp.handleBlur}
                                                        value={formikTopUp.values.amount}
                                                        onValueChange={(value) => {
                                                            formikTopUp.setFieldValue('amount', value)
                                                        }}
                                                    />
                                                </div>
                                                {formikTopUp.touched.amount && formikTopUp.errors.amount ? <p className='text-red-600 text-left text-xs w-full mt-2 mb-4'>{formikTopUp.errors.amount}</p> : null}


                                                {/* <button type="submit" className='text-white'>OK</button> */}
                                                {load ? (
                                                    <div className="bg-black border border-white my-4 p-2 text-center items-center justify-between rounded-md font-bold  flex">
                                                        <h4 className="text-white text-sm">Loading...</h4>
                                                        <input
                                                            type="image"
                                                            src={Spin}
                                                            value="Loading"
                                                            className="w-9"
                                                        />
                                                    </div>
                                                ) :
                                                    (<div className="flex gap-2 items-center cursor-pointer mt-2">
                                                        <div className="bg-black border border-white my-4 p-2 text-center items-center justify-between rounded-md font-bold  flex">
                                                            <button type="submit" className='text-white text-sm font-bold'>OK </button>
                                                            <img src={mark} alt="" />

                                                        </div>
                                                        <p className='text-white text-xs font-semibold'>Press Enter</p>
                                                    </div>)
                                                }


                                                {/* 
                                            <div className="mt-3 w-full flex items-center justify-center">
                                                <button
                                                    type="button"
                                                    className="inline-flex justify-center px-4 py-2 text-sm font-bold text-black bg-amber-500 border border-transparent rounded-md  focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                                                    onClick={closeModal}
                                                >
                                                    Close
                                                </button>
                                            </div> */}
                                            </form>

                                        </div>
                                    </div>
                                </Transition.Child>
                            </div>
                        </Dialog>
                    </Transition>
                </>


                {/* fund wallet  Modal */}


                <>
                    <Transition appear show={fundWalletOpen} as={Fragment}>
                        <Dialog
                            as="div"
                            className="w-full md:w-11/12  fixed flex justify-center items-center px-6 inset-0 z-10 overflow-y-auto"
                            onClose={closeModal}
                        >
                            <div className="w-full w-full xl:w-6/12 lg:w-7/12 md:w-8/12 min-h-screen px-4 text-center">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-25 bg-white"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-25"
                                    leaveTo="opacity-0"


                                >
                                    <Dialog.Overlay className="fixed inset-0" />
                                </Transition.Child>

                                {/* This element is to trick the browser into centering the modal contents. */}
                                <span
                                    className="inline-block h-screen align-middle"
                                    aria-hidden="true"
                                >
                                    &#8203;
                                </span>
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"


                                >
                                    <div className="w-full md:w-6/12 inline-block  max-w-md pt-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                                        <Dialog.Title
                                            as="h3"
                                            className="text-lg font-medium leading-6 text-gray-600"
                                        >
                                            <img src={bank} alt="" />
                                        </Dialog.Title>

                                        <div className="bg-project p-6 flex flex-col items-center justify-center">

                                            <h5 className="text-amber-500 mb-4">Transfer to [{firstname} {lastname}]</h5>

                                            <div className="border rounded-lg border-white p-3 flex flex-col items-center justify-center">
                                                <h5 className="text-white">{bankName}</h5>
                                                <p className="text-amber-500 my-2">{accountNumber}</p>
                                                <label className="text-white text-center text-xs">Your wallet will reflect your transaction instantly within 10 minutes</label>

                                            </div>

                                            <button
                                                type="button"
                                                className="my-3 inline-flex w-full justify-center px-4 py-2 text-xs font-extrabold text-black bg-white border border-transparent rounded-md  focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2"
                                                onClick={topUpModal}
                                            >
                                                Change Payment Method
                                            </button>




                                            <button
                                                type="submit"
                                                className="mb-2 inline-flex w-7/12 justify-center px-4 py-2 text-xs  text-white bg-transparent border border-white rounded-md  focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2"
                                                onClick={closeModal}
                                            >
                                                Cancel Payment
                                            </button>


                                        </div>
                                    </div>
                                </Transition.Child>
                            </div>
                        </Dialog>
                    </Transition>
                </>


                <>
                    <Transition appear show={isOpen} as={Fragment}>
                        <Dialog
                            as="div"
                            className="fixed px-6 inset-0 z-10 overflow-y-auto"
                            onClose={closeModal}
                        >
                            <div className="min-h-screen px-4 text-center">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-25 bg-white"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-25"
                                    leaveTo="opacity-0"
                                >
                                    <Dialog.Overlay className="fixed inset-0" />
                                </Transition.Child>

                                {/* This element is to trick the browser into centering the modal contents. */}
                                <span
                                    className="inline-block h-screen align-middle"
                                    aria-hidden="true"
                                >
                                    &#8203;
                                </span>
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                >
                                    <div className="inline-block w-full max-w-md pt-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                                        <Dialog.Title
                                            as="h3"
                                            className="text-lg font-medium leading-6 text-gray-900"
                                        >
                                            <img src={transfer} alt="" />
                                        </Dialog.Title>

                                        <div className="bg-project p-6 rounded-2xl">


                                            <div className="mt-2">
                                                <label htmlFor="" className='text-xx md:text-sm text-white ml-5'>Select Wallet Currency</label>

                                                <div className="text-xx md:text-sm mt-1  w-full rounded-lg border border-white pl-5 pr-0 py-2  text-white">
                                                    <select className=' text-xx md:text-sm bg-transparent w-11/12 focus:outline-none text-white'
                                                        value={walletCurrency}
                                                        onChange={e => setWalletCurrency(e.target.value)}
                                                    >
                                                        <option className=' text-black font-bold'> BTC</option>
                                                        <option className=' text-black font-bold'> ETH</option>
                                                        <option className=' text-black font-bold'> USDT</option>
                                                        <option className=' text-black font-bold'> BNB</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="mt-3">
                                                <label htmlFor="" className='text-xx md:text-sm text-white'>Amount</label>
                                                <div className="flex items-center text-sm mt-1 px-2 w-full rounded-lg border border-white  text-white">
                                                    <input
                                                        type="text"
                                                        defaultValue='0.0008'
                                                        className='w-6/12 p-2 md:text-sm text-xs bg-transparent rounded-lg  focus:outline-none text-white'
                                                        value={walletAmount}
                                                        onChange={e => setWalletAmount(e.target.value)}
                                                    />

                                                    <input
                                                        type="text"

                                                        defaultValue='₦25,000'
                                                        className='w-6/12 p-2 md:text-sm text-xs text-center bg-transparent rounded-lg  focus:outline-none text-amber-500' />

                                                </div>
                                            </div>

                                            <div className="mt-3">
                                                <label htmlFor="wallet" className='text-xx md:text-sm text-white'>Transfer To</label>
                                                <div className="flex items-center text-sm mt-1 px-2 w-full rounded-lg border border-white  text-white">
                                                    <input
                                                        type="text"
                                                        placeholder='Paste Wallet Address'
                                                        className='p-2 md:text-sm text-xs w-full bg-transparent placeholder:text-xs   focus:outline-none text-white'
                                                        value={walletAddress}
                                                        onChange={e => setWalletAddress(e.target.value)}
                                                    />
                                                </div>
                                            </div>


                                            <div className="mt-3">
                                                <label htmlFor="" className='text-xx md:text-sm text-white'>Your Password</label>
                                                <div className="flex items-center text-sm mt-1 px-2 w-full rounded-lg border border-white  text-white">
                                                    <input
                                                        type="password"
                                                        className='p-2 w-full md:text-sm text-xs bg-transparent placeholder:text-xs   focus:outline-none text-white'
                                                        value={walletPassword}
                                                        onChange={e => setWalletPassword(e.target.value)}
                                                    />
                                                </div>
                                            </div>


                                            <div className="mt-3 w-full flex items-center justify-center">
                                                <button
                                                    type="button"
                                                    className="inline-flex justify-center px-4 py-2 text-sm font-bold text-black bg-amber-500 border border-transparent rounded-md  focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                                                    onClick={onWalletTransfer}
                                                >
                                                    Transfer
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Transition.Child>
                            </div>
                        </Dialog>
                    </Transition>
                </>



                {/* DEPOSIT CRYPTO POP-UP */}
                <>
                    <Transition appear show={selectCurrencyModal} as={Fragment}>
                        <Dialog
                            as="div"
                            className="fixed px-6 inset-0 z-10 overflow-y-auto"
                            onClose={closeModal}
                        >
                            <div className="min-h-screen px-4 text-center">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-25 bg-white"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-25"
                                    leaveTo="opacity-0"
                                >
                                    <Dialog.Overlay className="fixed inset-0" />
                                </Transition.Child>

                                {/* This element is to trick the browser into centering the modal contents. */}
                                <span
                                    className="inline-block h-screen align-middle"
                                    aria-hidden="true"
                                >
                                    &#8203;
                                </span>
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                >
                                    <div className="inline-block w-full max-w-md pt-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                                        <Dialog.Title
                                            as="h3"
                                            className="text-lg font-medium leading-6 text-gray-900"
                                        >
                                            <img src={transfer} alt="" />
                                        </Dialog.Title>

                                        <div className="bg-project p-6">
                                            <div className="mt-2">
                                                <label htmlFor="" className='text-xx md:text-sm text-[#FFA100] ml-5'>Select Wallet Currency</label>

                                                <div className="text-xx md:text-sm mt-1  w-full rounded-lg border border-white pl-5 pr-0 py-2  text-white">
                                                    <select className=' text-xx md:text-sm bg-transparent w-11/12 focus:outline-none text-white'
                                                        value={walletCurrency}
                                                        onChange={e => setWalletCurrency(e.target.value)}
                                                    >
                                                        <option className=' text-black font-bold'> BTC</option>
                                                        <option className=' text-black font-bold'> ETH</option>
                                                        <option className=' text-black font-bold'> USDT</option>
                                                        <option className=' text-black font-bold'> BNB</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="mt-2">
                                                <label htmlFor="" className='text-xx md:text-sm text-[#FFA100] ml-5'>Select Network</label>

                                                <div className="text-xx md:text-sm mt-1  w-full rounded-lg border border-white pl-5 pr-0 py-2  text-white">
                                                    <select className=' text-xx md:text-sm bg-transparent w-11/12 focus:outline-none text-white'
                                                        value={cryptoNetworks}
                                                        onChange={e => setCryptoNetworks(e.target.value)}
                                                    >
                                                        <option className=' text-black font-bold'>EOS</option>
                                                        <option className=' text-black font-bold'>XDC</option>
                                                        <option className=' text-black font-bold'>TRON</option>
                                                        <option className=' text-black font-bold'>KLATYN</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="mt-3 w-full flex items-center justify-center">
                                                <button
                                                    type="button"
                                                    className="inline-flex justify-center px-4 py-2 text-sm font-bold text-black bg-amber-500 border border-transparent rounded-md  focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                                                    onClick={onAccessWalletAddress}
                                                >
                                                    Access Wallet Address
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Transition.Child>
                            </div>
                        </Dialog>
                    </Transition>
                </>


                {/* CRYPTO WALLET ADDRESS */}
                <>
                    <Transition appear show={selectCryptoAdressModal} as={Fragment}>
                        <Dialog
                            as="div"
                            className="fixed px-6 inset-0 z-10 overflow-y-auto"
                            onClose={closeModal}
                        >
                            <div className="min-h-screen px-4 text-center">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-25 bg-white"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-25"
                                    leaveTo="opacity-0"
                                >
                                    <Dialog.Overlay className="fixed inset-0" />

                                </Transition.Child>

                                {/* This element is to trick the browser into centering the modal contents. */}
                                <span
                                    className="inline-block h-screen align-middle"
                                    aria-hidden="true"
                                >
                                    &#8203;
                                </span>
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                >
                                    <div className="inline-block w-full max-w-md pt-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-transparent shadow-xl rounded-2xl">


                                        <div className="bg-project p-6">
                                            <label htmlFor="" className='text-xx md:text-sm text-[#FFA100] ml-5 text-center flex justify-center'>{walletCurrency} Wallet</label>

                                            <div className="flex justify-center items-center">
                                                <img
                                                    src={barCode} alt="Bar Code"
                                                />
                                            </div>

                                            <div className="mt-3">
                                                <label htmlFor="wallet" className='text-xx md:text-sm text-white ml-5'>Wallet Deposit Address</label>
                                                <div className="flex items-center text-sm mt-1 px-2 w-full rounded-lg border border-white  text-white">
                                                    <input
                                                        type="text"
                                                        // placeholder='Paste Wallet Address'
                                                        className='p-2 md:text-sm text-xs w-full bg-transparent placeholder:text-xs   focus:outline-none text-white'
                                                        value={text}
                                                        onChange={e => setText(e.target.value)}
                                                    />
                                                </div>

                                                <div className="mt-3 w-full flex items-center justify-between">
                                                    <button
                                                        type="button"
                                                        className="inline-flex justify-center px-4 py-2 text-sm font-bold text-black bg-amber-500 border border-transparent rounded-md  focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                                                        onClick={copy}
                                                    >
                                                        Copy Address
                                                    </button>

                                                    <button
                                                        type="button"
                                                        className="inline-flex justify-center px-4 py-2 text-sm font-bold text-black bg-amber-500 border border-transparent rounded-md  focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                                                        onClick={sendWalletAddress}
                                                    >
                                                        Exit
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Transition.Child>
                            </div>
                        </Dialog>
                    </Transition>
                </>

                <ToastContainer />

                {/* GOLDEN TICKET MODAL */}
                <>
                    <Transition appear show={goldenTicketModal} as={Fragment}>
                        <Dialog
                            as="div"
                            className="fixed px-6 inset-0 z-10 overflow-y-auto"
                            onClose={closeModal}
                        >
                            <div className="min-h-screen px-4 text-center">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-25 bg-white"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-25"
                                    leaveTo="opacity-0"
                                >
                                    <Dialog.Overlay className="fixed inset-0" />
                                </Transition.Child>

                                {/* This element is to trick the browser into centering the modal contents. */}
                                <span
                                    className="inline-block h-screen align-middle"
                                    aria-hidden="true"
                                >
                                    &#8203;
                                </span>
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                >
                                    <div className="inline-block w-full max-w-md pt-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-transparent shadow-xl rounded-2xl">


                                        <div className="bg-project p-6">
                                            <div className="flex justify-start items-left">
                                                <img
                                                    src={goldenTicket} alt="Golden Ticket"
                                                />
                                            </div>

                                            <div className="coupon-code-container bg-white p-3 w-full">
                                                <p className='text-black text-center font-bold'>{goldenTicketData.coupon_code}</p>
                                            </div>

                                            <div className='w-full mt-4'>
                                                <p className='text-white text-center'>Use One Time Coupon Code</p>
                                            </div>
                                        </div>
                                    </div>
                                </Transition.Child>
                            </div>
                        </Dialog>
                    </Transition>
                </>

                {/* NOTIFICATION MODAL */}
                <>
                    <Transition appear show={notificationModal} as={Fragment}>
                        <Dialog
                            as="div"
                            className="fixed px-6 inset-0 z-10 overflow-y-auto"
                            onClose={closeModal}
                        >
                            <div className="min-h-screen px-4 text-center">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-25 bg-white"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-25"
                                    leaveTo="opacity-0"
                                >
                                    <Dialog.Overlay className="fixed inset-0" />
                                </Transition.Child>

                                {/* This element is to trick the browser into centering the modal contents. */}
                                <span
                                    className="inline-block h-screen align-middle"
                                    aria-hidden="true"
                                >
                                    &#8203;
                                </span>
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                >
                                    <div className="inline-block w-full max-w-md pt-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-transparent shadow-xl rounded-2xl">


                                        <div className="bg-project p-6 rounded-2xl">
                                            <div className="header flex mb-6">
                                                <div className='w-11/12'>
                                                    <h4 className='text-white text-center font-bold text-2xl'>Notifications</h4>
                                                </div>
                                                <button
                                                    type='button'

                                                    className='w-1/12'>
                                                    <img src={cancelImg} className='w-[30px]' onClick={closeModal} alt="Cancel Modal" />
                                                </button>
                                            </div>

                                            <div className="notification-lists-container overflow-y-scroll h-[450px]">
                                                {notifyListData.map((item) => (
                                                    <div key={item?.id} className="notification-box bg-white p-6 rounded-xl mt-4">
                                                        <h5 className='font-bold text-lg'>{item.data.title}</h5>

                                                        <p>
                                                            {item.data.message}
                                                        </p>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </Transition.Child>
                            </div>
                        </Dialog>
                    </Transition>
                </>

                {/* TRANSACTION HISTORY */}
                <>
                    <Transition appear show={transactionHistModal} as={Fragment}>
                        <Dialog
                            as="div"
                            className="fixed px-6 inset-0 z-10 overflow-y-auto"
                            onClose={closeModal}
                        >
                            <div className="min-h-screen px-4 text-center">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-25 bg-white"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-25"
                                    leaveTo="opacity-0"
                                >
                                    <Dialog.Overlay className="fixed inset-0" />
                                </Transition.Child>

                                {/* This element is to trick the browser into centering the modal contents. */}
                                <span
                                    className="inline-block h-screen align-middle"
                                    aria-hidden="true"
                                >
                                    &#8203;
                                </span>
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                >
                                    <div className="inline-block w-full max-w-md pt-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-transparent shadow-xl rounded-2xl">
                                        <div className="bg-project p-6">
                                            <div className="header flex mb-6">
                                                <div className='w-11/12'>
                                                    <h4 className='text-white text-center font-bold'>Transaction History</h4>
                                                </div>
                                                <button
                                                    type='button'

                                                    className='w-1/12'>
                                                    <img src={cancelImg} className='w-[30px]' onClick={closeModal} alt="Cancel Modal" />
                                                </button>
                                            </div>

                                            <div className="notification-lists-container overflow-y-scroll h-[450px]">
                                                {
                                                    transactionHistoryData?.map((item) => (
                                                        <div className="notification-box bg-white p-6 rounded-xl mt-4">
                                                            {item.status === "UNPAID" ?
                                                                <div className="header-wrapper flex justify-between items-center">
                                                                    <h5 className='font-bold text-lg text-[#A90202]'>Transfer Successful</h5>
                                                                    <h5 className='font-bold text-lg text-[#A90202]'>{item.book_date}</h5>
                                                                </div> :
                                                                <div className="header-wrapper flex justify-between items-center">
                                                                    <h5 className='font-bold text-lg text-[#106B08]'>Top-up Successful</h5>
                                                                    <h5 className='font-bold text-lg text-[#106B08]'>{item.book_date}</h5>
                                                                </div>
                                                            }

                                                            {item.status === "UNPAID" ?
                                                                <p className='mt-2'>
                                                                    You have successfully transferred ₦{item.amount} to ID-{item.booking_id}, Ojo Ola.
                                                                </p> :
                                                                <p className='mt-2'>
                                                                    Your account has been succefully topped with ₦{item.amount}
                                                                </p>
                                                            }
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </Transition.Child>
                            </div>
                        </Dialog>
                    </Transition>
                </>








                <TopForm />

                <hr className='md:block hidden' />


                <div className="flex md:flex-row flex-col">
                    <div className='md:border-r md:border-white h-100 w-3/12 pb-24 lg:px-12 md:px-4 ml-12 hidden md:block'>

                        <div className='pt-9'>

                            <span className='flex items-center text-white'> <img src={dashboard} alt="" className='mr-3 w-8' />
                                <NavLink to="/user-profile" className='font-light' >My Profile</NavLink>
                            </span>
                        </div>

                        <div className='my-3'>
                            <span className='flex items-center text-white'> <img src={payment} alt="" className='mr-3 w-8' />
                                <a href="#wallet" className='font-light'>My Wallet</a>

                            </span>

                        </div>

                        <div className='my-3 ml-1'>
                            <span className='flex items-center text-white'> <img src={one} alt="" className='mr-3 w-6' />
                                <a href="#bookings" className='ml-1 font-light'>My Bookings </a>
                            </span>
                        </div>

                        <div className='my-3'>
                            <span className='flex items-center text-white'> <img src={refs} alt="" className='mr-3 w-8' />
                                <a href="#referral" className='font-light'>Referral Bonus</a></span>
                        </div>

                        <div className='my-3'>
                            <span className='flex items-center text-white'>
                                <img src={offers} alt="" className='md:ml-1 md:mr-5 w-6' />
                                <a href="#offers" className='font-light'>Special Offers</a></span>
                        </div>

                        <div className='my-3'>
                            <span className='flex items-center text-white' onClick={handleTransactionHistModal}>
                                <img src={naira} alt="" className='mr-3 w-8' />
                                <p className='font-light cursor-pointer'
                                >
                                    Transaction History
                                </p>
                            </span>
                        </div>



                        <div className='my-3'>
                            <span className='flex items-center text-white' onClick={handleNotificationModal}>
                                <img src={notifications} alt="" className='mr-3 w-8' />
                                <p className='font-light cursor-pointer'>Notification</p>
                            </span>
                        </div>
                    </div>



                    <div className="ml-0  md:pt-16 md:border-r xl:px-20 lg:px-12 md:px-10 px-10 md:border-white h-100 md:w-4/12 w-full md:pb-80 pb-8 md:block">
                        <div className="flex justify-between items-center">
                            <h2 id='#wallet' className='text-white font-bold mb-1 text-base'>My Wallet</h2>

                            <div className="">

                                {viewWallet ?
                                    (<button
                                        onClick={hideWallet}
                                    >
                                        <img src={eyes} alt="" className='w-6' />

                                    </button>) : (
                                        (<button
                                            onClick={showWallet}
                                        >
                                            <img src={closed_eye} alt="" className='w-6' />

                                        </button>)
                                    )
                                }

                            </div>

                        </div>

                        {viewWallet ? (
                            <div className='md:h-44 md:w-full items-start border-theme-color bg-black mt-4 rounded-lg px-8 md:px-8 py-6 flex flex-col'>
                                <h4 className='text-white text-sm'>Current Balance</h4>
                                <h3 className='text-white mt-1 lg:text-lg md:text-base text-base font-extrabold'>{firstname} . {initial}</h3>
                                <h1 className='theme-color lg:text-lg md:text-base text-base font-bold my-4 '>
                                    &#8358;{Number(balance).toLocaleString('en-US', { maximumFractionDigits: 2 })}

                                </h1>
                                <h6 className='text-white text-sm'>{usernamec}</h6>



                            </div>
                        ) : (
                            <div className='md:h-44 md:w-full border-theme-color mt-4 bg-black rounded-lg px-8 md:px-8 py-6 flex flex-col'>
                                <h4 className='text-white text-sm'>Current Balance</h4>
                                <h3 className='text-white mt-1 lg:text-lg md:text-base text-base text-base font-extrabold'>xxxxxxxx</h3>
                                <h1 className='theme-color lg:text-lg md:text-base text-base font-bold my-4 '>xxxxxx</h1>
                                <h6 className='text-white text-sm'>xxxxxx</h6>



                            </div>
                        )}




                        <div className="flex gap-2 md:w-full">
                            <div className="w-1/2">
                                {bvnSetting === null || '' ?
                                    <div className="flex items-center text-center justify-center w-full bg-white  mt-4 font-bold rounded-md md:p-2 px-1 py-2 border border-amber-500">

                                        <button
                                            onClick={bvnModal}

                                            type="button"
                                            className='font-bold'>Fund Wallet
                                        </button>
                                        {/* <div className="flex items-center justify-center text-white bg-black rounded-full p-1 md:h-5 h-4 w-4 md:w-5">
                                        <h4 className="text-white">i</h4>
                                    </div> */}
                                    </div>
                                    :

                                    <div className="flex items-center gap-2 justify-around w-full bg-white  mt-4 font-bold rounded-md md:p-2 px-2 py-2 border border-amber-500">
                                        <button

                                            onClick={fundWalletModal}

                                            type="button"
                                            className='font-bold'>Fund Wallet

                                        </button>




                                        <div className="flex items-center justify-center text-white bg-black rounded-full p-1 md:h-4 h-4 w-4 md:w-4">
                                            <Menu as="div" className="relative inline-block text-left">
                                                <div>
                                                    <Menu.Button className="inline-flex w-full justify-center rounded-md  px-4 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                                                        <h4 className="text-white text-xx">i</h4>

                                                    </Menu.Button>
                                                </div>
                                                <Transition
                                                    as={Fragment}
                                                    enter="transition ease-out duration-100"
                                                    enterFrom="transform opacity-0 scale-95"
                                                    enterTo="transform opacity-100 scale-100"
                                                    leave="transition ease-in duration-75"
                                                    leaveFrom="transform opacity-100 scale-100"
                                                    leaveTo="transform opacity-0 scale-95"
                                                >
                                                    <Menu.Items className="z-50 absolute md:left-16 -left-20 bottom-8 right-0 mt-2 md:w-76 w-72 p-4 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                        <div className="px-1 py-1 flex flex-col gap-4 items-center justify-center" >
                                                            <Menu.Item>
                                                                {({ hover }) => (

                                                                    <div className="flex items-center  text-black">
                                                                        <p>You can top-up your wallet by doing a transfer from your mobile/internet banking app or using USSD to your account Number
                                                                            <span className='text-amber-500 font-bold'> [{accountNumber}]</span> ({bankName})
                                                                        </p>
                                                                    </div>
                                                                )}


                                                            </Menu.Item>

                                                        </div>

                                                    </Menu.Items>
                                                </Transition>
                                            </Menu>

                                        </div>

                                    </div>


                                }


                            </div>


                            <button
                                onClick={transferModal}

                                type="button"
                                className='w-1/2 bg-white  mt-4 font-bold rounded-md p-2 border border-amber-500'>Transfer</button>


                        </div>

                        <div className='bg-black border-white border text-center rounded-lg  md:w-full px-8 py-2 mt-6'>
                            <NavLink to='/cart' className='text-white hover:text-white mt-1 md:text-xl text-sm font-semibold text-center'>My  Cart</NavLink>

                        </div>
                        {
                            dues > 0 ?
                                <button
                                    onClick={(e) => {
                                        dueRef.current?.scrollIntoView({ behavior: 'smooth' });

                                    }
                                    }
                                    className='w-1/2 mt-6 md:text-lg text-sm border border-theme-color bg-red-700 text-white font-bold rounded-md p-2'>Due</button>
                                : ''
                        }

                    </div>



                    <div className='md:pt-16 pt-4 xl:px-20 lg:px-16 md:px-8 w-full md:w-4/12 px-10'>

                        <div className="flex w-full  justify-between items-center">
                            <h2 className='text-white font-semibold text-base'>
                                Crypto Wallet
                            </h2>



                            <div className="">

                                {viewCrypto ?
                                    (<button
                                        onClick={hideCrypto}
                                    >
                                        <img src={eyes} alt="" className='w-6' />

                                    </button>) : (
                                        (<button
                                            onClick={showCrypto}
                                        >
                                            <img src={closed_eye} alt="" className='w-6' />
                                        </button>)
                                    )
                                }

                            </div>

                        </div>


                        {viewCrypto ? (
                            <div className='w-full  gap-4 mt-4'>

                                <div className="bg-white p-3 rounded-lg w-full mb-4">
                                    <p className='text-xs'>Current Balance</p>
                                    <div className="flex justify-between my-2">
                                        <div className="flex items-center">
                                            <img src={bitcoin} alt="" className='w-10 h-10 flex ' />
                                            <h5 className='lg:text-sm md:text-xs font-bold'>Bitcoin (BTC)</h5>

                                        </div>
                                        <div className="flex flex-col gap-2">
                                            <p className='text-xs font-bold text-amber-500'>₦0.00</p>
                                            <p className='text-sm font-semibold'>0</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="bg-white p-3 rounded-lg w-full mb-4">
                                    <p className='text-xs'>Current Balance</p>
                                    <div className="flex justify-between my-2">
                                        <div className="flex items-center">
                                            <img src={usdt} alt="" className='w-10 h-10 flex ' />
                                            <h5 className='lg:text-sm md:text-xs font-bold'>Tether (USDT)</h5>

                                        </div>
                                        <div className="flex flex-col gap-2">
                                            <p className='text-xs font-bold text-amber-500'>₦0.00</p>
                                            <p className='text-sm font-semibold'>0</p>
                                        </div>
                                    </div>

                                </div>

                                <div className="bg-white p-3 rounded-lg w-full mb-4">
                                    <p className='text-xs'>Current Balance</p>
                                    <div className="flex justify-between my-2">
                                        <div className="flex items-center">
                                            <img src={binance} alt="" className='w-10 h-10 flex ' />
                                            <h5 className='lg:text-sm md:text-xs font-bold'>Binance (BNB)</h5>

                                        </div>
                                        <div className="flex flex-col gap-2">
                                            <p className='text-xs font-bold text-amber-500'>₦0.00</p>
                                            <p className='text-sm font-semibold'>0</p>
                                        </div>
                                    </div>

                                </div>


                                <div className="bg-white p-3 rounded-lg w-full mb-4">
                                    <p className='text-xs'>Current Balance</p>
                                    <div className="flex justify-between my-2">
                                        <div className="flex items-center">
                                            <img src={ethereum} alt="" className='w-10 h-10 flex ' />
                                            <h5 className='lg:text-sm md:text-xs font-bold'>Ethereum (ETH)</h5>

                                        </div>
                                        <div className="flex flex-col gap-2">
                                            <p className='text-xs font-bold text-amber-500'>₦0.00</p>
                                            <p className='text-sm font-semibold'>0</p>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        ) :

                            (
                                <div className='w-full  gap-4 mt-4'>

                                    <div className="bg-white p-3 rounded-lg w-full mb-4">
                                        <p className='text-xs'>Current Balance</p>
                                        <div className="flex justify-between my-2">
                                            <div className="flex items-center">
                                                <img src={bitcoin} alt="" className='w-10 h-10 flex ' />
                                                <h5 className='lg:text-sm md:text-xs font-bold'>Bitcoin (BTC)</h5>

                                            </div>
                                            <div className="flex flex-col gap-2">
                                                <p className='text-xs font-bold text-amber-500'>xxxxxxxx</p>
                                                <p className='text-xs font-semibold'>xxxxxx</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="bg-white p-3 rounded-lg w-full mb-4">
                                        <p className='text-xs'>Current Balance</p>
                                        <div className="flex justify-between my-2">
                                            <div className="flex items-center">
                                                <img src={usdt} alt="" className='w-10 h-10 flex ' />
                                                <h5 className='lg:text-sm md:text-xs font-bold'>Tether (USDT)</h5>

                                            </div>
                                            <div className="flex flex-col gap-2">
                                                <p className='text-xs font-bold text-amber-500'>xxxxxxxx</p>
                                                <p className='text-xs font-semibold'>xxxxxx</p>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="bg-white p-3 rounded-lg w-full mb-4">
                                        <p className='text-xs'>Current Balance</p>
                                        <div className="flex justify-between my-2">
                                            <div className="flex items-center">
                                                <img src={binance} alt="" className='w-10 h-10 flex ' />
                                                <h5 className='lg:text-sm md:text-xs font-bold'>Binance (BNB)</h5>

                                            </div>
                                            <div className="flex flex-col gap-2">
                                                <p className='text-xs font-bold text-amber-500'>xxxxxxxx</p>
                                                <p className='text-xs font-semibold'>xxxxxx</p>
                                            </div>
                                        </div>

                                    </div>


                                    <div className="bg-white p-3 rounded-lg w-full mb-4">
                                        <p className='text-xs'>Current Balance</p>
                                        <div className="flex justify-between my-2">
                                            <div className="flex items-center">
                                                <img src={ethereum} alt="" className='w-10 h-10 flex ' />
                                                <h5 className='lg:text-sm md:text-xs font-bold'>Ethereum (ETH)</h5>

                                            </div>
                                            <div className="flex flex-col gap-2">
                                                <p className='text-xs font-bold text-amber-500'>xxxxxxxx</p>
                                                <p className='text-xs font-semibold'>xxxxxx</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )

                        }


                        <div className="flex gap-4 items-center">

                            <button
                                onClick={openModal}
                                type="button" className='text-white text-sm w-1/2 bg-transparent mt-6 font-normal rounded-md p-2 border border-white cursor-pointer'>Transfer</button>

                            <button
                                type="button"
                                onClick={() => { setSelectCurrencyModal(true); setBg(true) }}
                                className='border-theme-color md:text-sm text-xs bg-amber-500 p-2 w-full mt-6 rounded-md font-normal cursor-pointer'>Top Up Your Wallet </button>
                        </div>





                    </div>

                </div>


                <hr className='md:block hidden' />




                <div className='border border-b-2 w-full md:h-24 h-16 bg-white xl:px-40 lg:px-24 md:px-16 mt-12 md:mt-0 px-6 flex justify-between items-center'>
                    <h2 id='bookings' className='text-sm md:text-xl font-bold'>My Bookings</h2>

                    {bookingOpen ?
                        <img
                            onClick={closeBookingHeader}
                            src={eyess}
                            alt=""
                            className='w-8 md:w-8' /> :
                        <img
                            onClick={openBookingHeader}
                            src={closed}
                            alt=""
                            className='w-8 md:w-9' />

                    }
                </div>

                {bookingOpen ? (

                    myBookings.length === 0 ?
                        (<div className="w-full flex justify-center items-center m-auto my-16 px-12">
                            <div className=" rounded-2xl  lg:w-3/12 md:w-4/12 w-full overflow-hidden shad">
                                <div className="bg-white flex items-center justify-center p-6 bb">
                                </div>

                                <div className="md:p-12 p-6">
                                    <h3 className='text-white text-base  mb-4 font-bold'>Welcome to Roadlers. </h3>
                                    <p className='text-white text-sm font-light'>This segment is empty, you have no transaction yet. </p>

                                    <p className='text-white text-sm mt-4 font-light'>Let’s make your request.</p>

                                    <div className="bg-amber-500  text-xs text-black font-bold p-2 rounded-md mt-8 w-full text-center">
                                        <Link to="/#vehicles" className='bg-amber-500 text-xs text-black font-bold p-2 rounded-md mt-4 w-full'>Let’s Get Me A Nice Ride  </Link>

                                    </div>
                                    <div className="bg-amber-500  text-xs text-black font-bold p-2 rounded-md mt-4 w-full text-center">
                                        <Link to="/#drivers" className='bg-amber-500 text-xs text-black font-bold p-2 rounded-md mt-4 w-full'>Let’s Get Me A Pro Driver </Link>

                                    </div>

                                </div>

                            </div>
                        </div>) :



                        (<main className='xl:px-64 lg:px-40 md:px-24 px-6 py-20'>
                            <div className="flex justify-end mb-8 md:mb-4">

                                <div className="p-2 px-3 border cart-bg text-white rounded-md border-white flex">




                                    <button
                                        className="mr-4 text-xs md:text-base"
                                        type='button'
                                        onClick={() => {
                                            setFirstOpen((isOpen) => !isOpen)


                                        }}>

                                        {startDate ? setSelectedStartDate : '2 March 2022'}
                                    </button>


                                    <label htmlFor="" className='mr-4 text-xs md:text-base'> - </label>

                                    <button
                                        className="mr-4 text-xs md:text-base"
                                        type='button'
                                        onClick={() => setOpen((isOpen) => !isOpen)}>
                                        {endDate ? setSelectedEndDate : '2 April 2022'}
                                    </button>
                                    <img src={one} alt="" className='w-4 md:w-5' />

                                </div>

                                <div className="dateee">
                                    <DatePicker
                                        selected={startDate}
                                        onChange={(date) => {
                                            setStartDate(date)
                                            { handleFilterDate(date) }
                                            setFirstOpen(false)
                                        }}
                                        open={firstOpen}
                                        style={{ color: "white" }}
                                    />
                                </div>

                                <div className="dateee">
                                    <DatePicker
                                        selected={endDate}
                                        onChange={(date) => {
                                            setEndDate(date)
                                            console.log('setSelectedEndDate', endDate)

                                            setOpen(false)
                                        }}

                                        open={open}

                                        style={{ color: "white" }}



                                    />
                                </div>

                            </div>


                            <div className=""
                                ref={dueRef}

                            >





                                {

                                    startDate && endDate ?
                                        (myBookings.filter(obj => (obj.book_date >= (moment(startDate).format("YYYY/MM/DD")) && obj.book_date <= (moment(endDate).format("YYYY/MM/DD")) &&
                                            (obj.destination_state === '' && obj.payment_status === 'PAID')
                                            || (obj.destination === 'Out of State' && (obj.payment_status === 'UNPAID' || obj.payment_status === 'PAID')))

                                        ).slice(0, visible).map((myBooking, i) => {

                                            return (

                                                <Bookings
                                                    firstname={firstname}
                                                    lastname={lastname}
                                                    email={emailAddress}
                                                    phone={phoneNumber}
                                                    username={usernamec}
                                                    name={myBooking.booking_title}
                                                    image={myBooking.book_picture}
                                                    status={myBooking.num_of_day}
                                                    id={myBooking.booking_id}
                                                    key_id={myBooking.id}
                                                    date={myBooking.pickup_date}
                                                    time={myBooking.pickup_time}
                                                    destination={myBooking.destination}
                                                    destinationState={myBooking.destination_state}
                                                    notes={myBooking.notes}
                                                    Address={myBooking.pickup_location}
                                                    price={myBooking.amount}
                                                    insurance={myBooking.insurance_price}
                                                    days={myBooking.num_of_day}
                                                    paymentStatus={myBooking.payment_status}
                                                    vehicleId={myBooking.vehicle_id}
                                                    to={myBooking.giftcard_receiver_email}
                                                    code={myBooking.gift_card_code}
                                                    bookStatus={myBooking.book_status}
                                                    myBooking={myBooking}

                                                />
                                            )
                                        })

                                        ) : (

                                            myBookings.filter(
                                                obj => (obj.destination_state === '' && obj.payment_status === 'PAID')
                                                    || (obj.destination === 'Out of State' && (obj.payment_status === 'UNPAID' || obj.payment_status === 'PAID'))
                                            )
                                                .slice(0, visible).map((myBooking, i) => {


                                                    return (

                                                        <Bookings
                                                            myBooking={myBooking}
                                                            firstname={firstname}
                                                            lastname={lastname}
                                                            email={emailAddress}
                                                            phone={phoneNumber}
                                                            username={usernamec}
                                                            name={myBooking.booking_title}
                                                            image={myBooking.book_picture}
                                                            bookStatus={myBooking.book_status}
                                                            key_id={myBooking.id}
                                                            status={myBooking.num_of_day}
                                                            id={myBooking.booking_id}
                                                            date={myBooking.pickup_date}
                                                            time={myBooking.pickup_time}
                                                            destination={myBooking.destination}
                                                            destinationState={myBooking.destination_state}
                                                            notes={myBooking.notes}
                                                            Address={myBooking.pickup_location}
                                                            price={myBooking.amount}
                                                            days={myBooking.num_of_day}
                                                            paymentStatus={myBooking.payment_status}
                                                            vehicleId={myBooking.vehicle_id}
                                                            to={myBooking.giftcard_receiver_email}
                                                            insurance={myBooking.insurance_price}
                                                            code={myBooking.gift_card_code}
                                                        />
                                                    )

                                                }
                                                ))
                                }

                            </div>

                            {!myBookings?.length || myBookings?.length <= visible ? ('') : (
                                <div className="w-full flex items-center justify-center my-20">
                                    <button
                                        onClick={showMore}
                                        className='border border-theme-color theme-color font-semibold p-3 text-sm rounded-lg w-36'>Load More</button>
                                </div>
                            )}


                        </main>
                        )
                ) : (
                    '')
                }



                <div id='completed' className='border border-b-2 w-full md:h-24 h-16 bg-white lg:px-40 md:px-24 mt-0 px-6 flex justify-between items-center'>
                    <h2 className='text-sm md:text-xl font-bold'>Completed</h2>

                    {completedOpen ?
                        <img
                            onClick={closeCompletedHeader}
                            src={eyess}
                            alt=""
                            className='w-8 md:w-8' /> :
                        <img
                            onClick={openCompletedHeader}
                            src={closed}
                            alt=""
                            className='w-8 md:w-9' />

                    }
                </div>


                {completedOpen ? (
                    <main className='xl:px-64 lg:px-40 md:px-24 px-6 py-20'>
                        <div className="flex justify-end mb-8 md:mb-4">

                            <div className="p-2 px-3 border  text-white rounded-md border-white flex">
                                <p className='mr-4 text-xs md:text-base'>2 March 2022 - 2 April 2022</p>    <img src={one} alt="" className='w-4 md:w-6' />
                            </div>

                        </div>


                        <div className="my-12 lg:grid-cols-2 md:grid-cols-2 lg:grid  gap-6 grid w-full">
                            {
                                completeBookingData?.map((item) => {
                                    return (
                                        <Completed
                                            driverName={item.name}
                                            title={item.booking_title}
                                            bookDate={item.book_date}
                                            pickupTime={item.pickup_time}
                                            destination={item.destination}
                                            amount={item.amount}
                                            assetLocation={item.location}
                                            bookPicture={item.book_picture}
                                            bookingId={item.booking_id}

                                        />
                                    )
                                })
                            }
                        </div>

                    </main>
                ) : (
                    ''
                )
                }



                <div id='referral' className='border border-b-2 w-full md:h-24 h-16 bg-white lg:px-40 md:px-24 mt-0 px-6 flex justify-between items-center'>
                    <h2 className='text-sm md:text-xl font-bold'>Referral Bonus</h2>

                    {referralOpen ?
                        <img
                            onClick={closeReferralHeader}
                            src={eyess}
                            alt=""
                            className='w-8 md:w-8' /> :
                        <img
                            onClick={openReferralHeader}
                            src={closed}
                            alt=""
                            className='w-8 md:w-9' />

                    }
                </div>


                {referralOpen ? (
                    <main className="w-full xl:px-64 lg:px-32 md:px-24 px-6 py-20">
                        <div className="lg:w-1/2 md:w-2/3 w-full">
                            <div className="flex gap-3">
                                <img src={refer_a_friend} alt="" className='w-1/2' />

                                <div className="flex flex-col gap-4">
                                    <h3 className='text-amber-500 font-bold'>Refer And Win </h3>
                                    <p className='md:block hidden text-sm leading-8 text-white'>For every paid referral you earn ₦1,000. You can use your cash for numerous offers, including a full-day driving service, car rental, and more. </p>
                                    <p className='md:hidden text-sm leading-6 text-white'>For every paid referral you earn ₦1,000. </p>
                                </div>
                            </div>

                            <div className="mt-8">
                                <h3 className='text-white font-bold mb-3'>Your Referral Invite Link</h3>

                                <div className="flex gap-3">
                                    <input type="text"
                                        name="" id=""
                                        value={refLink}
                                        className="px-2 placeholder:text-xs text-xs md:text-sm bg-white rounded-md border border-white w-3/4  py-2 focus:outline-none" />

                                    <button
                                        onClick={handleCopy}
                                        className='w-full bg-amber-500 font-extrabold text-black w-24 py-2 text-xs rounded-md mr-4'>Copy</button>

                                </div>
                            </div>
                        </div>

                        <div className=" flex md:flex-row flex-col w-3/5 mt-12 gap-6">
                            <div className="md:w-1/2 w-full flex flex-col gap-3">
                                <h3 className='text-white font-bold'>Your Referal Code</h3>

                                <div className="flex gap-2 mt-4">
                                    <input
                                        type="text"
                                        name=""
                                        value={referralCode}
                                        id=""
                                        placeholder='Temi118'
                                        className="placeholder:text-xs text-xs md:text-sm bg-white rounded-md border border-white w-3/4 px-2 py-2 focus:outline-none"
                                    />
                                    <button
                                        onClick={handleCopy}

                                        className='w-full bg-amber-500 font-extrabold text-black w-24 py-2 text-xs rounded-md mr-4'>Copy</button>
                                </div>
                            </div>



                            <div className="md:w-1/2 w-full flex flex-col md:gap-3 gap-1 ">
                                <h3 className='text-white font-bold'>Share via socials </h3>
                                <hr />

                                <div className="flex items-center  p-0">
                                    <button className='w-16'>
                                        <a href={`https://www.facebook.com/sharer.php?u=${refLink}`}>

                                            <img src={IG} alt="" />
                                        </a>
                                    </button>
                                    <button className='w-20 h-20 md:w-16 md:h-18'>

                                        <a href={`https://api.whatsapp.com/send?text=I enjoyed my experience with Roadlers today, you should definitely check them out for chauffeurs and car hire services : ${refLink}`}>
                                            <img src={Whatsapp} alt="" />
                                        </a>
                                    </button>

                                    <button className='w-16'>
                                        <a href={`https://www.facebook.com/sharer.php?u=I enjoyed my experience with Roadlers today, you should definitely check them out for chauffeurs and car hire services : ${refLink}`}>
                                            <img src={Facebook} alt="" />

                                        </a>
                                    </button>
                                    <button className='w-16'>
                                        <a href={`https://twitter.com/intent/tweet?text=I enjoyed my experience with Roadlers today, you should definitely check them out for chauffeurs and car hire services : ${refLink}`}>
                                            <img src={Twitter} alt="" />
                                        </a>
                                    </button>

                                    <button className='w-10'>
                                        <a href={`mailto:?${refLink}`}>
                                            <img src={Mail} alt="" />
                                        </a>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </main>

                ) : (
                    ''
                )
                }



                <div className='border border-b-2 w-full md:h-24 h-16 bg-white lg:px-40 md:px-24 mt-0 px-6 flex justify-between items-center'>
                    <h2 className='text-sm md:text-xl font-bold'>Gift Cards</h2>

                    {giftOpen ?
                        <img
                            onClick={closeGiftHeader}
                            src={eyess}
                            alt=""
                            className='w-8 md:w-8' /> :
                        <img
                            onClick={openGiftHeader}
                            src={closed}
                            alt=""
                            className='w-8 md:w-9' />

                    }
                </div>

                {giftOpen ? (
                    <main className='md:py-20 xl:px-40 lg:px-28 md:px-16 px-6'>
                        <div className="flex justify-between  my-8 md:gap-8 gap-2 ">
                            <div className="md:w-9/12 w-full  md:gap-8 gap-3 flex flex-col">

                                {gifts.map((gift, index) => (

                                    <div className="flex mb-16 w-full" key={gift.id}>



                                        <div className="w-5/12 md:w-64 mr-4">

                                            <img src={`https://api.roadlers.com/storage/${gift?.image}`} alt="" className='md:w-full' />

                                        </div>




                                        <div className="w-7/12">
                                            <div className="flex items-center justify-between">
                                                <h5 className='theme-color md:text-base text-sm font-extrabold tracking-wider mb-6'>{gift.name}</h5>


                                            </div>

                                            <p className='text-white leading-8 md:text-base text-sm tracking-wider'>{gift.description}</p>

                                            <div className="mt-4 flex md:gap-12 gap-6 items-center w-full">
                                                <h5 className='text-white md:text-base text-sm  font-semibold tracking-wider'>₦{Number(gift.commission).toLocaleString('en-US', { maximumFractionDigits: 2 })}</h5>
                                                <button
                                                    onClick={(event) => {
                                                        event.preventDefault()

                                                        setCookie('gift_type', gift.type)

                                                        // read cookie with 
                                                        console.log('gift_type', cookies.gift_type)

                                                        navigate('/gift-card-request')


                                                    }}
                                                    className='border border-amber-500 p-2 md:w-24 md-1/2 text-xx font-semibold text-white rounded-md md:text-sm'
                                                    type="submit"

                                                >Buy Now</button>

                                            </div>
                                        </div>

                                    </div>


                                ))}






                                <div className="md:gap-6 gap-2 flex mt-16">

                                    <a href="#vehicles" className='text-center bg-amber-500 p-2  md:w-40 w-1/3 font-bold text-black  rounded-md md:text-sm text-xx'>Book Cars</a>
                                    <a href="#drivers" className='text-center  bg-amber-500 p-2 md:w-40 w-1/3 font-bold text-black rounded-md md:text-sm text-xx'>Book Drivers</a>
                                    <a href="#chat" className='text-center border border-white p-2 md:w-40  w-1/3 font-bold text-white rounded-md md:text-sm text-xx'>Chat Now</a>

                                </div>

                            </div>


                        </div>
                    </main>
                ) : (
                    ''
                )
                }




                <div id='offers' className='border border-b-2 w-full md:h-24 h-16 bg-white lg:px-40 md:px-24 mt-0 px-6 flex justify-between items-center'>
                    <h2 className='text-sm md:text-xl font-bold'>Special Offers</h2>

                    {offersOpen ?
                        <img
                            onClick={closeOffersHeader}
                            src={eyess}
                            alt=""
                            className='w-8 md:w-8' /> :
                        <img
                            onClick={openOffersHeader}
                            src={closed}
                            alt=""
                            className='w-8 md:w-9' />

                    }
                </div>

                {offersOpen ? (

                    <main className="xl:px-64 lg:px-40 md:px-24 px-6 md:py-20 py-8">
                        <div className="hidden md:flex flex-col mb-20">
                            <div className="flex gap-5">
                                <img src={ride_with_us} alt="" className='w-3/12' />

                                <div className="flex flex-col w-5/12 gap-2">
                                    <h3 className='text-amber-500 font-bold'>Ride With Us Lunch<br /> On Us </h3>
                                    <p className='text-sm leading-8 text-white'>For every request/ride, you make from your wallet you get a free lunch per request valid for the first 30 days of your sign-up. Maximum of 5 tickets per user.</p>


                                </div>

                                <div className="flex flex-col w-2/12 gap-10 items-center">
                                    <h3 className='text-white font-bold'>Count Down </h3>
                                    <div className="rounded-md border border-white p-4 text-center w-3/4">
                                        <p className='text-white font-semibold'>Days</p>
                                        {/* <h1 className='text-white text-5xl font-extrabold'>2<span className='text-amber-500'>8</span></h1> */}
                                        <h1 className='text-white text-5xl font-extrabold'><span className='text-amber-500'>{mealRideDayNum}</span></h1>
                                    </div>

                                </div>
                            </div>

                            {
                                mealTicketNum === 0 || mealTicketNum < 0 ?
                                    <div className='w-3/12 p-2 border border-white rounded-md font-bold text-center text-white mt-4 text-lg'> Exhausted </div>
                                    :
                                    <div className='w-3/12 p-2 border border-white rounded-md font-bold text-center text-white mt-4 text-lg'>{mealTicketNum} Meal Tickets </div>
                            }

                        </div>

                        <div className="md:hidden flex flex-col gap-5  mb-12 ">
                            <div className="flex justify-between items-center w-full">
                                <img src={ride_with_us} alt="" className='w-7/12' />

                                <div className="flex flex-col w-5/12 gap-4 items-center">
                                    <h3 className='text-white font-bold text-sm'>Count Down </h3>
                                    <div className="rounded-md border border-white p-4 text-center full">
                                        <p className='text-white font-semibold'>Days</p>
                                        <h1 className='text-white text-3xl font-extrabold'><span className='text-amber-500'>{mealRideDayNum}</span></h1>
                                    </div>

                                </div>

                            </div>


                            <div className="flex justify-between items-center gap-5 w-full">


                                <div className="w-7/12">
                                    <div className="flex flex-col  gap-2">
                                        <h3 className='text-amber-500 text-sm font-bold'>Ride With Us Lunch<br /> On Us </h3>
                                        <p className='text-xs leading-6 text-white'>For every request you make from your wallet, you get a free lunch valid for the first 30 days of your sign-up.</p>
                                    </div>

                                </div>




                                {
                                    mealTicketNum === 0 || mealTicketNum < 0 ?
                                        <div className='p-2 border border-white rounded-md font-bold text-center text-white mt-4 text-xs'> Exhausted </div>
                                        :

                                        <div className="w-5/12">
                                            <div className=' p-2 border border-white rounded-md font-bold text-center text-white mt-4 text-xs'>{mealTicketNum} Meal Tickets </div>

                                        </div>
                                }


                            </div>
                        </div>

                        <div className="md:hidden flex flex-col justify-between ">
                            <div className="flex gap-5">
                                <div className="flex flex-col gap-3 w-7/12">
                                    <img src={special_events} alt="" className='w-full' />

                                </div>

                                <div className="flex flex-col w-5/12 gap-10 items-center">
                                    <div className="flex flex-col gap-2">
                                        <h3 className='text-amber-500 text-sm font-bold'>Special Occasion Deals </h3>
                                        <p className='text-xs leading-6 text-white'>
                                            Is it your special day? You can get 10% off Roadlers chauffeur service for your wedding, birthday, or other special events. This offer is only avaliable once a year</p>
                                    </div>

                                </div>

                            </div>

                            <div className="flex w-full my-8 gap-5 relative">
                                <div className='w-6/12 p-2 border border-white rounded-md font-bold text-center text-white text-sm'>Golden Tickets  </div>
                                <button
                                    type='button'
                                    className='w-6/12 border border-amber-500 p-2 text-sm text-white rounded-xl'
                                    onMouseOver={() => setOfferInfo(true)}
                                    onMouseOut={() => setOfferInfo(false)}
                                >
                                    Use Now
                                </button>
                                {/* <div className="special-occasion-box bg-white rounded-xl p-4">
                                <p className='text-black text-center'>
                                    To use this offer you must have a minimum of 5 transaction through your wallet within the past 3 months.
                                </p>
                            </div> */}

                            </div>
                        </div>

                        <div className="hidden md:flex flex-col mb-20">
                            <div className="flex gap-5">
                                <img src={special_events} alt="" className='w-3/12' />

                                <div className="flex flex-col w-5/12 gap-2">
                                    <h3 className='text-amber-500 font-bold'>Special Occasion  <br /> Deals </h3>
                                    <p className='text-sm leading-8 text-white'>
                                        Is it your special day? You can get 10% off Roadlers chauffeur service for your wedding, birthday, or other special events. This offer is only avaliable once a year</p>
                                </div>

                                <div className="flex flex-col w-2/12 gap-10 justify-center relative">
                                    <div class={` ${offerInfo ? 'special-occasion-box bg-white rounded-xl p-4 absolute top-[-30px] right-[-150px]' : 'hidden'}`}>
                                        <p className='text-black text-center'>
                                            To use this offer you must have a minimum of 5 transaction through your wallet within the past 3 months.
                                        </p>
                                    </div>
                                    <button
                                        className='border border-amber-500 p-2 text-sm text-white rounded-xl'
                                        onMouseOver={() => setOfferInfo(true)}
                                        onMouseOut={() => setOfferInfo(false)}
                                        onClick={handleGoldenTicketModal}
                                    >
                                        Use Now
                                    </button>
                                </div>
                            </div>

                            <div className='w-3/12 p-2 border border-white rounded-md font-bold text-center text-white mt-4 text-lg'>Golden Tickets  </div>
                        </div>
                    </main>

                ) : (
                    ''
                )
                }

            </div>)
    )
}

export default memo(CustomersDashboard)