import React, { useState, useEffect, useRef, useCallback } from 'react'
import daily_driver from '../../Images/daily_driver.png';
import drivee from '../../Images/drivee.png';
import { BASE_URL } from "../utils/constant"

import locate from '../../Images/locate.png'
import prado from '../../Images/Prado.png'
import clock from '../../Images/clock.png'
import one from '../../Images/one.png'
import axios from "axios";
import car from '../../Images/driver3.png';
import dailyGiftCard from '../../Images/gift_faint.png'
import monthlyGiftCard from '../../Images/gift.png'
import moment from 'moment';
import { useNavigate } from "react-router-dom";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';




function CartComponent(props) {
    const notify = () => { toast("Cart Item Removed!") };

    const [oneOffOptionArray, setOneOffOptionArray] = useState([])
    let navigate = useNavigate()
    const {
        id,
        vehicleId,
        key,
        name,
        title,
        price,
        daily_price,
        total,
        date,
        address,
        monthlyPrice,
        image,
        time,
        state,
        days,
        category,
        destination_state,
        receiverEmail,
        receiverName,
        resumption_date,
        setCartUpdate,
        insurance,
        numMonths,
        destination,
        airportPrice
    } = props



    let amount = price;
    let tot = total;
    let amountTotal = Number(tot);
    let amountNum = Number(amount);
    let dailyPrice = Number(daily_price);
    let monthPrice = Number(monthlyPrice)


    const [vehicle, setVehicle] = useState([]);
    const [deleteCart, setDeleteCart] = useState(false);
    let [amountChange, setAmountChange] = useState(amountTotal);

    let payStackCap = 2000
    let pspercentTWO = (amountTotal * 0.015) + 100


    let localCart = JSON.parse(localStorage.getItem('cart'));


    const [cart, setCart] = useState(localCart);

    // FETCH PRICES FOR DAILY DRIVER
    const fetchPrices = (e) => {
        axios
            .get(`${BASE_URL}/v1/view-pricing`, {
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((response) => {
                const transData = response.data.data;
                setOneOffOptionArray(transData)
            });
    }
    useEffect(() => {
        fetchPrices();
    }, []);




    const getCar = async () => {
        await axios.get(`${BASE_URL}/v1/view-vehicle/${vehicleId}`).then((response) => {
            const vehicleAsset = response.data;
            console.log('car details', vehicleAsset)
            setVehicle(vehicleAsset);
        });
    };

    useEffect(
        () => getCar(),
        []
    );

    const RemoveItem = (e) => {
        notify()
        e.preventDefault()

        let localData = localStorage.getItem('cart');
        let newList = JSON.parse(localData)
        console.log(newList)
        let index = newList.findIndex(item => item.id === id)
        newList.splice(index, 1);
        console.log(newList)
        localStorage.setItem('cart', JSON.stringify(newList));
        setCartUpdate((prevState) => !prevState)
        navigate('/cart')
    }


    useEffect(() => {
        setCart({})
        setCart(JSON.parse(localStorage.getItem('cart')))
    }, [setCart])



    let [dayChange, setDayChange] = useState(days);
    let [monthChange, setMonthChange] = useState(numMonths)
    let [insuranceChange, setInsuranceChange] = useState(insurance);
    let [cartState, setCartState] = useState(cart);

    function handleIncrease(e) {
        e.preventDefault()

        if (!numMonths) {
            setDayChange(++dayChange)
        } else {
            setMonthChange(++monthChange)
        }

        if (insurance) {
            if (!numMonths) {

                if (destination === 'Airport Pickup') {

                    // if there is insurance option, and the destination 
                    if ((((dayChange * airportPrice) * 0.015) + 100) >= payStackCap) {
                        setAmountChange((dayChange * airportPrice) + (dayChange * airportPrice * 0.05) + payStackCap)

                    } else {
                        setAmountChange((dayChange * airportPrice) + (dayChange * airportPrice * 0.05) + ((dayChange * airportPrice) * 0.015) + 100)

                    }

                    setInsuranceChange(dayChange * airportPrice * 0.05)
                }
                else {

                    console.log('hiiiiiiiiiiiii',((((dayChange * dailyPrice) +(dayChange * dailyPrice * 0.05))  * 0.015) + 100))
                    if (((((dayChange * dailyPrice) +(dayChange * dailyPrice * 0.05))  * 0.015) + 100) >= payStackCap) {
                        setAmountChange((dayChange * dailyPrice) + (dayChange * dailyPrice * 0.05) + payStackCap)

                    } else {
                        setAmountChange((dayChange * dailyPrice) + (dayChange * dailyPrice * 0.05) + ((dayChange * dailyPrice +(dayChange * dailyPrice * 0.05)) * 0.015) + 100)

                    }

                    setInsuranceChange(dayChange * dailyPrice * 0.05)
                }

            } else {
                setAmountChange((monthChange * monthPrice) + (monthChange * monthPrice * 0.05))
                setInsuranceChange(monthChange * monthPrice * 0.05)
            }
        } else {
            if (!numMonths) {
                if (destination === 'Airport Pickup') {

                    if ((((dayChange * airportPrice) * 0.015) + 100) >= payStackCap) {
                        setAmountChange((dayChange * airportPrice) + payStackCap)
                    } else {
                        setAmountChange((dayChange * airportPrice) + ((dayChange * airportPrice) * 0.015) + 100)

                    }

                } else {
                    // add the paystack fee as the number of days increases
                    // setAmountChange((dayChange * dailyPrice) + ((dayChange * dailyPrice) * 0.015) + 100)
                    if ((((dayChange * dailyPrice) * 0.015) + 100) >= payStackCap) {
                        setAmountChange((dayChange * dailyPrice) + payStackCap)
                    } else {
                        setAmountChange((dayChange * dailyPrice) + ((dayChange * dailyPrice) * 0.015) + 100)

                    }
                }
            } else {
                setAmountChange(monthChange * monthPrice)
            }
        }
        let cartData = localStorage.getItem('cart');
        let newList = JSON.parse(cartData)

        let index = newList.findIndex(item => item.id === id)

        console.log(id, 'cartState id')

        let newArray = [...newList]

        if (insurance) {
            if (!numMonths) {
                if (destination === 'Airport Pickup') {


                    if ((((dayChange * airportPrice) * 0.015) + 100) >= payStackCap) {
                        newArray[index].amount = (dayChange * airportPrice) + (dayChange * airportPrice * 0.05) + payStackCap
                    } else {
                        newArray[index].amount = (dayChange * airportPrice) + (dayChange * airportPrice * 0.05) + ((dayChange * airportPrice) * 0.015) + 100

                    }
                    newArray[index].insurance_price = dayChange * airportPrice * 0.05
                } else {

                    if (((((dayChange * dailyPrice)+(dayChange * dailyPrice * 0.05)) * 0.015) + 100) >= payStackCap) {
                        newArray[index].amount = (dayChange * dailyPrice) + (dayChange * dailyPrice * 0.05) + payStackCap
                    } else {
                        newArray[index].amount = (dayChange * dailyPrice) + (dayChange * dailyPrice * 0.05) + (((dayChange * dailyPrice)+(dayChange * dailyPrice * 0.05)) * 0.015) + 100

                    }

                    newArray[index].insurance_price = dayChange * dailyPrice * 0.05
                }

            } else {
                newArray[index].amount = (monthChange * monthPrice) + (monthChange * monthPrice * 0.05)
                newArray[index].insurance_price = monthChange * monthPrice * 0.05


            }
        } else {
            if (!numMonths) {
                if (destination === 'Airport Pickup') {

                    if ((((dayChange * airportPrice) * 0.015) + 100) >= payStackCap) {
                        newArray[index].amount = (dayChange * airportPrice) + payStackCap
                    } else {
                        newArray[index].amount = (dayChange * airportPrice) + ((dayChange * airportPrice) * 0.015) + 100
                    }

                } else {
                    if ((((dayChange * dailyPrice) * 0.015) + 100) >= payStackCap) {
                        newArray[index].amount = (dayChange * dailyPrice) + payStackCap
                    } else {
                        newArray[index].amount = (dayChange * dailyPrice) + ((dayChange * dailyPrice) * 0.015) + 100
                    }
                }
            } else {
                newArray[index].amount = monthChange * monthPrice
            }
        }
        newArray[index].num_of_day = dayChange


        setCartState(newArray)
        localStorage.setItem('cart', JSON.stringify(newArray));
        setCartUpdate((prevState) => !prevState)


    }
    function handleDecrease(e) {
        e.preventDefault()
        if (dayChange === 0) {
            dayChange = 1

            if (insurance) {
                if (destination === 'Airport Pickup') {
                    amountTotal = airportPrice + (airportPrice * 0.05)

                } else {

                    amountTotal = dailyPrice + (dailyPrice * 0.05)
                }

            } else {

                if (destination === 'Airport Pickup') {
                    amountTotal = airportPrice

                } else {
                    amountTotal = dailyPrice
                }
            }

        }


        if (!numMonths) {
            setDayChange(--dayChange)
        } else {
            setMonthChange(--monthChange)
        }


        if (insurance && (amount >= daily_price || amount >= airportPrice)) {
            if (!numMonths) {

                if (destination === 'Airport Pickup') {
                    // setAmountChange((dayChange * airportPrice) + (dayChange * airportPrice * 0.05))


                    if (((((dayChange * airportPrice)+(dayChange * airportPrice * 0.05)) * 0.015) + 100) >= payStackCap) {
                        setAmountChange((dayChange * airportPrice) + (dayChange * airportPrice * 0.05) + payStackCap)
                    } else {
                        setAmountChange((dayChange * airportPrice) + (dayChange * airportPrice * 0.05) + ((dayChange * airportPrice) * 0.015) + 100)

                    }

                    setInsuranceChange(dayChange * airportPrice * 0.05)

                }
                else {

                    if (((((dayChange * dailyPrice)+(dayChange * dailyPrice * 0.05)) * 0.015) + 100) >= payStackCap) {
                        setAmountChange((dayChange * dailyPrice) + (dayChange * dailyPrice * 0.05) + payStackCap)
                    } else {
                        setAmountChange((dayChange * dailyPrice) + (dayChange * dailyPrice * 0.05) + (((dayChange * dailyPrice)+(dayChange * dailyPrice * 0.05)) * 0.015) + 100)

                    }

                    // setAmountChange((dayChange * dailyPrice) + (dayChange * dailyPrice * 0.05))
                    setInsuranceChange(dayChange * dailyPrice * 0.05)
                }

            } else {
                setAmountChange((monthChange * monthPrice) + (monthChange * monthPrice * 0.05))
                setInsuranceChange(monthChange * monthPrice * 0.05)
            }
        }
        else {
            if (!numMonths) {
                if (destination === 'Airport Pickup') {

                    if ((((dayChange * airportPrice) * 0.015) + 100) >= payStackCap) {
                        setAmountChange((dayChange * airportPrice) + payStackCap)
                    } else {
                        setAmountChange((dayChange * airportPrice) + ((dayChange * airportPrice) * 0.015) + 100)

                    }

                } else {

                    if ((((dayChange * dailyPrice) * 0.015) + 100) >= payStackCap) {
                        setAmountChange((dayChange * dailyPrice) + payStackCap)
                    } else {
                        setAmountChange((dayChange * dailyPrice) + ((dayChange * dailyPrice) * 0.015) + 100)

                    }



                }
            } else {
                setAmountChange(monthChange * monthPrice)
            }
        }

        let cartData = localStorage.getItem('cart');
        let newList = JSON.parse(cartData)
        console.log(newList)
        let index = newList.findIndex(item => item.id === id)

        let newArray = [...newList]

        if (dayChange > 0 || monthChange > 0) {
            // make sure the minimum day is always greater than zero coz of price calculation

            if (insurance && (amount >= daily_price || amount >= airportPrice || amount >= monthlyPrice)) {
                if (!numMonths) {
                    if (destination === 'Airport Pickup') {


                        if ((((dayChange * airportPrice) * 0.015) + 100) >= payStackCap) {
                            newArray[index].amount = (dayChange * airportPrice) + (dayChange * airportPrice * 0.05) + payStackCap
                        } else {
                            newArray[index].amount = (dayChange * airportPrice) + (dayChange * airportPrice * 0.05) + ((dayChange * airportPrice) * 0.015) + 100

                        }

                        newArray[index].insurance_price = dayChange * airportPrice * 0.05





                    } else {
                        if (((((dayChange * dailyPrice)+(dayChange * dailyPrice * 0.05)) * 0.015) + 100) >= payStackCap) {
                            newArray[index].amount = (dayChange * dailyPrice) + (dayChange * dailyPrice * 0.05) + payStackCap
                        } else {
                            newArray[index].amount = (dayChange * dailyPrice) + (dayChange * dailyPrice * 0.05) + (((dayChange * dailyPrice)+(dayChange * dailyPrice * 0.05)) * 0.015) + 100
                        }

                        newArray[index].insurance_price = dayChange * dailyPrice * 0.05
                    }

                } else {
                    newArray[index].amount = (monthChange * monthPrice) + (monthChange * monthPrice * 0.05)
                    newArray[index].insurance_price = monthChange * monthPrice * 0.05
                }
            } else {
                if (!numMonths) {
                    if (destination === 'Airport Pickup') {

                        if ((((dayChange * airportPrice) * 0.015) + 100) >= payStackCap) {
                            newArray[index].amount = (dayChange * airportPrice) + payStackCap

                        } else {
                            newArray[index].amount = (dayChange * airportPrice) + ((dayChange * airportPrice) * 0.015) + 100

                        }



                    } else {

                        if ((((dayChange * dailyPrice) * 0.015) + 100) >= payStackCap) {
                            newArray[index].amount = (dayChange * dailyPrice) + payStackCap

                        } else {
                            newArray[index].amount = (dayChange * dailyPrice) + ((dayChange * dailyPrice) * 0.015) + 100

                        }

                    }
                } else {
                    newArray[index].amount = monthChange * monthPrice
                }
            }


            if (!numMonths) {
                newArray[index].num_of_day = dayChange
            } else {
                newArray[index].num_of_month = monthChange
            }

        }


        setCartState(newArray)
        console.log(newArray, 'cartState')
        localStorage.setItem('cart', JSON.stringify(newArray));
        setCartUpdate((prevState) => !prevState)

    }


    // HANDLE DAILY DRIVER CHANGE
    const handleNumOfDayChange = (e) => {
        // setDayChange(e.target.value)
        let commisionPrice = Number(e.target.value)

        if (insurance) {
            setAmountChange(commisionPrice + (commisionPrice * 0.05))
        } else {
            setAmountChange(commisionPrice)
        }

        if (e.target.value === '1') {
            if (insurance) {
                setAmountChange(12000 + (12000 * 0.05))
            } else {
                setAmountChange(12000)
            }

        } else if (e.target.value === '2') {
            setAmountChange(24000)
        } else if (e.target.value === '3') {
            setAmountChange(36000)
        } else if (e.target.value === '4') {
            setAmountChange(40000)
        } else if (e.target.value === '5') {
            setAmountChange(45000)
        } else if (e.target.value === '6') {
            setAmountChange(49000)
        } else if (e.target.value === '7') {
            setAmountChange(50000)
        } else if (e.target.value === '8') {
            setAmountChange(51000)
        } else if (e.target.value === '9') {
            setAmountChange(52000)
        } else if (e.target.value === '10') {
            setAmountChange(53000)
        } else if (e.target.value === '11') {
            setAmountChange(54000)
        } else if (e.target.value === '12') {
            setAmountChange(55000)
        } else if (e.target.value === '13') {
            setAmountChange(56000)
        } else if (e.target.value === '14') {
            setAmountChange(58000)
        } else if (e.target.value === '15') {
            setAmountChange(60000)
        } else if (e.target.value === '16') {
            setAmountChange(61000)
        } else if (e.target.value === '17') {
            setAmountChange(63000)
        } else if (e.target.value === '18') {
            setAmountChange(65000)
        } else if (e.target.value === '19') {
            setAmountChange(67000)
        } else if (e.target.value === '20') {
            setAmountChange(70000)
        } else if (e.target.value === '21') {
            setAmountChange(75000)
        } else if (e.target.value === '22') {
            setAmountChange(80000)
        } else if (e.target.value === '23') {
            setAmountChange(82000)
        } else if (e.target.value === '24') {
            setAmountChange(83000)
        } else if (e.target.value === '25') {
            setAmountChange(84000)
        } else if (e.target.value === '26') {
            setAmountChange(85000)
        }

        let cartData = localStorage.getItem('cart');
        let newList = JSON.parse(cartData)
        console.log(newList)
        let index = newList.findIndex(item => item.id === id)

        console.log(id, 'cartState id')

        let newArray = [...newList]

        if (insurance) {
            newArray[index].amount = (commisionPrice) + (commisionPrice * 0.05)
            newArray[index].insurance_price = commisionPrice * 0.05
        } else {
            newArray[index].amount = commisionPrice
        }
        newArray[index].num_of_day = dayChange


        setCartState(newArray)
        localStorage.setItem('cart', JSON.stringify(newArray));
        setCartUpdate((prevState) => !prevState)
    }


    // console.log('titleeeeeeeeeeeeeeeeeeeeeee', cart)

    return (

        <div>
            {/* FOR MOBILE */}
            <div className="md:hidden block px-4 my-12">
                <div className="border cart-bg border-white rounded-xl flex px-3 py-5  h-72">

                    <div className="w-5/12 flex flex-col justify-between">
                        <div className="">
                            {


                                title !== "Full-Time Driver" ?

                                    title === "Daily Driver" ? (
                                        <h3 className='tracking-wider text-white text-base font-normal  pb-1 w-5/6'>{title} </h3>

                                    ) : (
                                        <h3 className='tracking-widest text-white text-xx font-extrabold  pb-1 w-5/6'>{title} </h3>

                                    )
                                    : <h3 className='tracking-widest text-white text-xx font-extrabold  pb-1 w-5/6'>Full Time Driver </h3>

                            }
                            {/* <h3 className='text-white text-xx font-extrabold  pb-1 w-5/6'>{vehicle.vehicle_name + " "}  {"(" + vehicle.year + ")"} </h3> */}

                            <hr className='border-t border-white w-2/4' />
                            <div className="flex items-center mb-2 -ml-2 mt-4 w-full">
                                <img src={locate} alt="" className='w-4 ' />
                                {
                                    destination_state ? (
                                        <p className='tracking-widest text-white text-xxx w-full lading-4'>{address} to {destination_state}</p>
                                    ) : (
                                        <p className='tracking-widest text-white text-xxx w-full lading-4'>{address} </p>
                                    )
                                }

                            </div>
                        </div>

                        <div className="flex w-10/12  rounded-md bg-white h-24 items-center justify-center">
                            {
                                !receiverEmail ?
                                    <>
                                        {
                                            vehicleId ?
                                                <div className="flex   ">
                                                    <img src={`https://api.roadlers.com/storage/${image}`} alt="" className='w-full h-5/6' />
                                                </div> :
                                                <div className="flex  ">
                                                    <img src={title === 'Daily Driver' ? daily_driver : drivee} alt="" className='w-full object-cover' />
                                                </div>

                                        }
                                    </>
                                    :
                                    <div className="flex bg-project p-2 rounded  ">
                                        <img src={title === 'Daily Driver' ? dailyGiftCard : monthlyGiftCard} alt="" className='w-full' />
                                    </div>

                            }
                        </div>
                        <button
                            onClick={RemoveItem}

                            className="tracking-widest bg-transparent border border-amber-500 text-amber-500 rounded-md px-3 py-1 w-16 font-normal text-xx mt-8"
                        >
                            Remove

                        </button>
                        <ToastContainer />

                    </div>

                    <div className="w-5/12  flex flex-col justify-start items-baseline">
                        <h3 className='text-white text-xx font-extrabold  pb-1 w-full tracking-widest'>Booking Details</h3>
                        <hr className='border-t border-white w-2/4' />


                        <div className="mt-10 flex  items-center  text-xx bg-transparent rounded-md border border-white w-11/12 text-center py-2 focus:outline-none text-white">

                            {!numMonths ?

                                (
                                    title === 'Daily Driver' ?
                                        <>
                                            <select className='w-full text-[#fff] bg-transparent focus:outline-none  placeholder:text-black md:placeholder:text-sm placeholder:text-xs'
                                                onChange={handleNumOfDayChange}>
                                                <option value={total} className='md:text-sm text-xx text-black'>{days} {dayChange < 2 ? ' Day' : ' Days'}</option>
                                                {
                                                    oneOffOptionArray.filter((item) => item?.heading?.includes('Daily Driver Price')).map((optionsArray) => (
                                                        // <option value={optionsArray?.period?.split(" ", 1)} className='md:text-sm text-xx text-black'>{optionsArray?.period}</option>
                                                        <option value={optionsArray?.commission} className='md:text-sm text-xx text-black'>{optionsArray?.period}</option>
                                                    ))
                                                }
                                            </select>
                                        </> :
                                        <>
                                            <label className="text-xx bg-transparent w-2/12 ml-1 mr-1 tracking-widest" >{dayChange < 1 ? 1 : dayChange}</label>
                                            <p className=' text-xx tracking-widest'>{dayChange < 2 ? 'Day' : 'Days'} </p>

                                            <div className="flex gap-1 px-2">

                                                {dayChange > 1 ? <button
                                                    onClick={handleDecrease}
                                                    type="button" className='text-xs font-extrabold text-black bg-yellow-600 px-2 rounded-sm'
                                                    disabled={dayChange <= 0 ? true : false}
                                                >
                                                    -
                                                </button> :

                                                    <button

                                                        type="button" className='text-xs font-extrabold text-gray-500 bg-gray-700 px-2 rounded-sm'
                                                        disabled={dayChange <= 0 ? true : false}
                                                    >
                                                        -
                                                    </button>}

                                                <button
                                                    onClick={handleIncrease}
                                                    type="button" className='text-xs font-extrabold  text-black bg-yellow-600 px-2 rounded-sm'>
                                                    +
                                                </button>

                                            </div>
                                        </>
                                )

                                :

                                <>
                                    <label className="text-xx bg-transparent w-2/12 ml-1 mr-1 tracking-widest" >{monthChange < 2 ? monthChange + ' Month' : monthChange + ' Months'}</label>

                                    <div className="flex gap-1 px-2">

                                        <button
                                            onClick={handleDecrease}
                                            type="button" className='text-xs font-extrabold text-black bg-yellow-600 px-2 rounded-sm'
                                            disabled={monthChange <= 0 ? true : false}
                                        >
                                            -
                                        </button>

                                        <button
                                            onClick={handleIncrease}
                                            type="button" className='text-xs font-extrabold  text-black bg-yellow-600 px-2 rounded-sm'>
                                            +
                                        </button>

                                    </div>

                                </>

                            }


                        </div>

                        <div className="flex items-center gap-2 mt-4">
                            <img src={one} alt="" className='w-5' />
                            <h4 className='text-white font-semibold text-xx tracking-widest'>{moment(date).format("DD/MM/YYYY")}</h4>
                        </div>

                        <div className="flex items-center gap-2 mt-2 -ml-1">
                            <img src={clock} alt="" className='w-7' />
                            <h4 className='text-white font-semibold text-xx tracking-widest'>{time}</h4>
                        </div>

                        <h4 className='text-white font-normal text-xx mt-2 tracking-widest'>{state}</h4>


                        <h3 className='text-white text-xx font-extrabold  pb-1 w-full mt-10 tracking-widest'>Sub-total</h3>

                    </div>

                    <div className="w-3/12  flex flex-col">
                        {
                            !numMonths ?
                                destination === 'Airport Pickup' ?
                                    <h3 className='tracking-wide text-white text-xx font-extrabold  pb-1 '>Airport Price</h3> :
                                    <h3 className='tracking-wide text-white text-xx font-extrabold  pb-1 '>Price per Day</h3>

                                :
                                <h3 className='text-white text-xx font-extrabold  pb-1 w-full tracking-wide'>Price per Month</h3>

                        }

                        <hr className='border-t border-white w-2/4' />
                        {
                            destination_state ? (
                                <p className='text-white mt-8 text-xs ml-3 font-bold tracking-widest'>&#8358; 0.00</p>

                            ) : (
                                !numMonths ?

                                    destination === 'Airport Pickup' ?
                                        <p className='text-white mt-10 text-xs ml-3 font-bold tracking-widest'>&#8358;{airportPrice?.toLocaleString('en-US', { maximumFractionDigits: 2 })}</p> :
                                        <p className='text-white mt-10 text-xs ml-3 font-bold tracking-widest'>&#8358;{dailyPrice?.toLocaleString('en-US', { maximumFractionDigits: 2 })}</p> :

                                    <p className='text-white mt-10 text-xs ml-3 font-bold tracking-widest'>
                                        &#8358;{monthPrice?.toLocaleString('en-US', { maximumFractionDigits: 2 })}
                                    </p>
                            )
                        }


                        {
                            destination_state ? (
                                <div className="mt-16">
                                    <p className='text-amber-500 text-xs ml-3 font-bold tracking-widest'>
                                        &#8358;0.00
                                    </p>
                                    <p>Insurance</p>

                                </div>
                            ) : (

                                <div className="mt-6">
                                    {
                                        insurance ?
                                            <p className='text-amber-500 text-xs ml-3 font-bold tracking-widest'>
                                                &#8358;{insurance?.toLocaleString('en-US', { maximumFractionDigits: 2 })}
                                            </p> :
                                            <p className='text-amber-500 text-xs ml-3 font-bold tracking-widest'>
                                                &#8358;0
                                            </p>
                                    }
                                    <p className='text-white tracking-wider text-xx ml-3'>Insurance</p>

                                </div>




                            )
                        }


                        <div className="mt-6 ml-3">
                            <p className='text-white text-xx font-bold'>Paystack charges</p>

                           

                            {category === 'Car Booking' &&

                                destination === 'Airport Pickup' ?
                                <p className='text-amber-500 mt-2 text-xx  font-bold tracking-widest'>

                                    {/* &#8358;{((amountChange * 0.015 )+100)?.toLocaleString('en-US', { maximumFractionDigits: 2 })} */}
                                    &#8358;{((amountChange - ((airportPrice * days) + insurance)) >= payStackCap ? payStackCap : (amountChange - ((airportPrice * days) + insurance)))?.toLocaleString('en-US', { maximumFractionDigits: 2 })}

                                </p> :
                                <p className='text-amber-500 mt-2 text-xx  font-bold tracking-widest'>

                                    &#8358;{((amountChange - ((dailyPrice * days) + insurance)) >= payStackCap ? payStackCap : (amountChange - ((dailyPrice * days) + insurance)))?.toLocaleString('en-US', { maximumFractionDigits: 2 })}
                                </p>


                            }

                        </div>




                        {
                            destination_state ? (
                                <p className='text-amber-500 mt-4 text-xs ml-3 font-bold tracking-widest'>&#8358; 0.00</p>

                            ) : (

                                <p className='text-amber-500 mt-4 text-xx ml-3 font-bold tracking-widest'>
                                    &#8358;{(amountChange)?.toLocaleString('en-US', { maximumFractionDigits: 2 })}
                                </p>
                            )
                        }
                    </div>
                </div>
            </div>





            {/* for desktop */}
            <div className="hidden md:block xl:px-56 lg:px-36 md:px-12 mb-8">
                <div className="border cart-bg border-white rounded-2xl flex p-6 gap-1">
                    <div className="w-4/12">
                        {
                            title !== "Full-Time Driver" ?

                                title === "Daily Driver" ? (
                                    <h3 className='tracking-wider text-white text-base font-normal  pb-1 w-5/6'>{title} </h3>

                                ) : (
                                    <h3 className='tracking-wider text-white text-base font-normal  pb-1 w-5/6'>{title} </h3>

                                )
                                : <h3 className='tracking-wider text-white text-base font-normal  pb-1 w-5/6'>Full Time Driver </h3>
                        }



                        <hr className='border-t border-white w-2/4' />
                        <div className="flex items-center mb-4 -ml-2 mt-4 w-full">
                            <img src={locate} alt="" className='w-6 ' />

                            {destination_state ? (
                                <p className='tracking-wider text-white text-xs w-3/4 leading-5'>{address} to {destination_state}</p>

                            ) : (
                                receiverEmail ? (
                                    <p className='tracking-wider text-white text-xs w-3/4 leading-5'>{receiverEmail}</p>

                                ) : (
                                    <p className='tracking-wider text-white text-xs w-3/4 leading-5'>{address}</p>

                                )

                            )}
                        </div>
                        {/* CONDITIONALS TO DETERMINE WHAT PICTURE SHOWS IN THE CART */}
                        {/* {
                            title !== 'Daily Driver || Full-Time Driver' ?
                            <div className="flex bg-white p-4 rounded w-8/12 ">
                                <img src={title === 'Gift Card' ? daily_driver : car} alt="" className='w-32' />
                            </div> : 
                            <div className="flex bg-white p-4 rounded w-8/12 ">
                                <img src={title === 'Daily Driver' ? daily_driver : drivee} alt="" className='w-32' />
                            </div>
                        } */}
                        {
                            !receiverEmail ?
                                <>
                                    {
                                        vehicleId ?
                                            <div className="flex items-center justify-center bg-white  rounded-lg h-40 overflow-hidden w-10/12">
                                                <img src={`https://api.roadlers.com/storage/${image}`} alt="" className='object-cover   rounded-lg' />
                                            </div> :
                                            <div className="flex rounded-2xl bg-white w-8/12 ">
                                                <img src={title === 'Daily Driver' ? daily_driver : drivee} alt="" className='w-full' />
                                            </div>

                                    }
                                </>
                                :
                                <div className="flex bg-project p-4 rounded w-8/12 ">
                                    <img src={title === 'Daily Driver' ? dailyGiftCard : monthlyGiftCard} alt="" className='w-32' />
                                </div>

                        }
                    </div>

                    <div className="w-3/12  flex flex-col ">
                        <h3 className='tracking-wider text-white text-base font-semibold  pb-1 w-5/6 '>Booking Details</h3>
                        <hr className='border-t border-white w-2/4' />

                        <div className="mt-4 flex justify-between px-2 items-center bg-transparent rounded-md border border-white xl:w-8/12 lg:w-10/12 md:w-11/12 text-center py-2 focus:outline-none text-white">


                            {
                                !numMonths ?
                                    (title === "Daily Driver" ?
                                        <>
                                            <select className='w-full text-[#fff] bg-transparent focus:outline-none  placeholder:text-black md:placeholder:text-sm placeholder:text-xs'
                                                onChange={handleNumOfDayChange}
                                            >
                                                <option value={total} className='md:text-sm text-xx text-black'>{days} {dayChange < 2 ? ' Day' : ' Days'}</option>
                                                {
                                                    oneOffOptionArray.filter((item) => item?.heading?.includes('Daily Driver Price')).map((optionsArray) => (
                                                        // <option value={optionsArray?.period?.split(" ", 1)} className='md:text-sm text-xx text-black'>{optionsArray?.period}</option>
                                                        <option value={optionsArray?.commission} className='md:text-sm text-xx text-black'>{optionsArray?.period}</option>
                                                    ))
                                                }
                                            </select>
                                        </> :
                                        <>
                                            <label className="text-sm bg-transparent w-3/12" >{dayChange < 1 ? 1 : dayChange}</label>
                                            <p className='mr-3 text-sm'> {dayChange < 2 ? 'Day' : 'Days'} </p>

                                            <div className="flex gap-2">

                                                {dayChange > 1 ?
                                                    <button
                                                        onClick={handleDecrease}
                                                        type="button"
                                                        className='text-sm font-extrabold text-black bg-yellow-600 px-2 rounded-sm'
                                                        disabled={dayChange <= 0 ? true : false}
                                                    >
                                                        -
                                                    </button> :

                                                    <button

                                                        type="button"
                                                        className='cursor-not-allowed text-sm font-extrabold text-gray-400 bg-gray-700 px-2 rounded-sm'
                                                        disabled={dayChange <= 0 ? true : false}
                                                    >
                                                        -
                                                    </button>

                                                }

                                                <button
                                                    onClick={handleIncrease}
                                                    type="button"

                                                    className='text-sm font-extrabold text-black bg-yellow-600 px-2 rounded-sm'>

                                                    +
                                                </button>

                                            </div>
                                        </>
                                    )
                                    :
                                    <>
                                        <label className="text-xs bg-transparent w-4/12 flex" >{monthChange < 2 ? monthChange + ' Month' : monthChange + ' Months'}</label>
                                        <div className="flex gap-2 ">

                                            <button
                                                onClick={handleDecrease}
                                                type="button"
                                                className='text-sm font-extrabold text-black bg-yellow-600 px-2 rounded-sm'
                                                disabled={monthChange <= 0 ? true : false}
                                            >
                                                -
                                            </button>






                                            <button
                                                onClick={handleIncrease}
                                                type="button"
                                                className='text-sm font-extrabold text-black bg-yellow-600 px-2 rounded-sm'>
                                                +
                                            </button>

                                        </div>
                                    </>

                            }

                        </div>



                        <div className="flex items-center gap-2 mt-4">
                            <img src={one} alt="" className='w-6' />
                            <h4 className='tracking-wider text-white font-semibold text-sm '>{moment(date).format("DD/MM/YYYY")}</h4>
                        </div>

                        <div className="flex items-center gap-2 mt-4">
                            <img src={clock} alt="" className='w-8 -ml-1' />
                            <h4 className='tracking-wider text-white font-semibold text-sm'>{time}</h4>

                        </div>

                        <h4 className='tracking-wider text-white font-normal text-sm mt-4'>{state}</h4>

                        <ToastContainer position="top-left" />

                        <button
                            onClick={RemoveItem}
                            className="tracking-wider bg-transparent border border-amber-500 text-amber-500 rounded-md px-3 py-1 w-24 font-normal text-xs mt-4">
                            Remove
                        </button>

                    </div>

                    <div className="w-3/12  flex flex-col">
                        {
                            !numMonths ?
                                destination === 'Airport Pickup' ?
                                    <h3 className='tracking-wider text-white text-base font-semibold  pb-1 w-5/6'>Airport Price</h3> :
                                    <h3 className='tracking-wider text-white text-base font-semibold  pb-1 w-5/6'>Price per Day</h3>

                                :
                                <h3 className='tracking-wider text-white text-base font-semibold  pb-1 w-5/6'>Price per Month</h3>
                        }
                        <hr className='border-t border-white w-2/4' />
                        {destination_state ? (
                            <p className='tracking-wider text-white mt-8 text-base'>&#8358;0</p>

                        ) : (
                            !numMonths ?
                                destination === 'Airport Pickup' ?
                                    <p className='tracking-wider text-base text-white mt-8'>&#8358;{airportPrice?.toLocaleString('en-US', { maximumFractionDigits: 2 })}</p> :
                                    <p className='tracking-wider text-base text-white mt-8'>&#8358;{dailyPrice?.toLocaleString('en-US', { maximumFractionDigits: 2 })}</p> :
                                <p className='tracking-wider text-base text-white mt-8'>&#8358;{monthPrice?.toLocaleString('en-US', { maximumFractionDigits: 2 })}</p>

                        )}

                        {destination_state ? (
                            <div className="mt-16">
                                <p className='text-white text-xs  font-semibold tracking-wider'>
                                    &#8358;0.00
                                </p>
                                <p className='text-amber-500 tracking-wider text-sm '>Insurance</p>

                            </div>

                        ) : (
                            <div className="mt-10">
                                {
                                    insurance ?
                                        <p className='text-amber-500 text-sm  font-bold tracking-widest'>
                                            &#8358;{insurance?.toLocaleString('en-US', { maximumFractionDigits: 2 })}
                                        </p> :
                                        <p className='text-amber-500 text-xs  font-bold tracking-widest'>
                                            &#8358;0
                                        </p>
                                }
                                <p className='text-amber-500 tracking-wider text-sm '>Insurance</p>



                                {category === 'Car Booking' &&

                                    destination === 'Airport Pickup' ?
                                    <p className='text-amber-500 mt-4 tracking-widest font-bold'>

                                        {/* &#8358;{((amountChange * 0.015 )+100)?.toLocaleString('en-US', { maximumFractionDigits: 2 })} */}
                                        &#8358;{((amountChange - ((airportPrice * days) + insurance)) >= payStackCap ? payStackCap : (amountChange - ((airportPrice * days) + insurance)))?.toLocaleString('en-US', { maximumFractionDigits: 2 })}

                                    </p> :
                                    <p className='text-amber-500 mt-4 tracking-widest font-bold'>

                                        &#8358;{((amountChange - ((dailyPrice * days) + insurance)) >= payStackCap ? payStackCap : (amountChange - ((dailyPrice * days) + insurance)))?.toLocaleString('en-US', { maximumFractionDigits: 2 })}
                                    </p>


                                }
                                <h3 className='text-amber-500 tracking-wider text-sm '>Paystack Charges</h3>





                            </div>
                        )}




                    </div>

                    <div className="w-2/12 flex flex-col">




                        <h3 className='tracking-wider text-white text-base font-semibold  pb-1 w-5/6 '>Sub-totals</h3>
                        <hr className='border-t border-white w-2/4' />

                        {destination_state ? (
                            <p className='tracking-wider text-amber-500 mt-4'>&#8358;0</p>


                        ) : (
                            <p className='text-amber-500 font-bold mt-8 tracking-wider'>

                                &#8358;{(amountChange)?.toLocaleString('en-US', { maximumFractionDigits: 2 })}
                            </p>


                        )}

                    </div>



                </div>

            </div>
        </div>
    )
}

export default CartComponent