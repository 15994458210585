import { createContext, useState, useEffect,useCallback } from 'react'
import axios from "axios";
import { useNavigate, useHistory } from "react-router-dom";
import Spin from "../Images/spinner.gif"
import { BASE_URL } from "../Components/utils/constant"
import getCookie from '../Components/utils/SecuredTokens';



const CustomerContext = createContext({})

// We need to export a provider
export const CustomerProvider = ({ children }) => {

    let navigate = useNavigate()

   
    // let userData = JSON.parse(localStorage.getItem("user"))
    // let token = userData?.token


    
    let userData = getCookie("user");
    let userObject
    let token
   
    // check if userData truely exists
    userData ? userObject = JSON.parse(userData) : userObject = ''
    userObject ? token = userObject?.token : token = ''
  



    let [userCarty, setUserCart] = useState([])
    let [userRequests, setUserRequests] = useState([])


    let cartUserId = JSON.parse(localStorage.getItem("cartUserId"))



    const handleGotoCart = (e) => {
        e.preventDefault();
        let formData = new FormData();
        formData.append("user_id", cartUserId);
        axios({
            method: "post",
            url: `${BASE_URL}/v1/go-to-cart`,
            data: formData,
            headers: {
                "Content-Type": "application/json",

                Authorization: `Bearer ${token}`,

                "Access-Control-Allow-Origin": "*",
            },
        }) .then(function (response) {
                //handle success

                const customer = response.data.data;
                // const errors = response.data.errors;
                setUserCart(customer)
            navigate('/admin-cart-customer')
            })
            .catch(function (error) {
                //handle error
                console.log("error ", error.response.data);
            });
    }

    const handleGotoRequest = (e) => {
        e.preventDefault();
       
        axios
        .get(`${BASE_URL}/v1/fetch-requestbyuserid/${cartUserId}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then(function (response) {
                //handle success
                const customerRequest= response.data.data;
                // const errors = response.data.errors;
                setUserRequests(customerRequest)
            navigate('/admin-requests-customer')


            })
            .catch(function (error) {
                //handle error
                console.log("error ", error.response.data);
            });
    };




    // handle terms and condition
    const [terms, setTerms] = useState(false)

    const handleTerms=(e)=>{
        e.preventDefault()
        setTerms((prev)=>!prev)
    }



    return <CustomerContext.Provider value={{

        handleGotoCart,
        handleGotoRequest,
        userCarty,
        userRequests,
        setUserRequests,

        handleTerms,
        terms,
        setTerms,

    }}>
        {children}
    </CustomerContext.Provider>
}

export default CustomerContext