import React, { Fragment, useState, useEffect, useContext } from 'react'
import { Menu, Dialog, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import Header from "../Header";
import { NavLink, useNavigate } from "react-router-dom";
import one from "../../Images/one.png";

import axios from "axios";
import moment from 'moment';
import DatePicker from "react-datepicker";

import AdminRequest from "./AdminRequest";
import daily_driver from "../../Images/daily_driver.png";
import CustomerContext from '../../Context/CustomerContext'

function AdminCustomerRequestsComponent() {
    const [value, setValue] = useState("");
    const [assetValue, setAssetValue] = useState("");
    const [open, setOpen] = useState(false);
    const [firstOpen, setFirstOpen] = useState(false);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    let setSelectedStartDate = moment(startDate).format("DD/MM/YYYY")
    let setSelectedEndDate = moment(endDate).format("DD/MM/YYYY")
    const [visible, setVisible] = useState(4);
    

    let { userRequests,setUserRequests } = useContext(CustomerContext)

    // const [requests, setRequests] = useState(userRequests);
     const [requestsResult, setRequestsResults] = useState(userRequests);


    function showMoreRequest() {
        setVisible((prevValue) => prevValue + 4);
    }


   

    console.log('userRequests data',userRequests)




    const handleSearch = (e) => {
        e.preventDefault();

        let search = e.target.value.toLowerCase();

        setValue(search)

        setRequestsResults(
            userRequests.filter((data) => {
                return data.booking_id.toLowerCase().includes(search.toLowerCase()) || (data.customer_id && data.customer_id.toLowerCase().includes(search.toLowerCase()));

            })
        )
    }

    function handleFilterDate(date) {
        let dateTime = moment(date).format("DD/MM/YYYY")
        console.log('date', dateTime)


    }

    const handleCar = (e) => {
        e.preventDefault()
        let carSearch = "Car Booking";
        setRequestsResults(
            userRequests.filter(request => {
                if (request.category === carSearch) {
                    return request
                }
                return false
            })
        )
    }

    const handleTravel = (e) => {
        e.preventDefault()
        let travelSearch = "Out of State";

        setRequestsResults(
            userRequests.filter((data) => {
                return data.destination.toLowerCase().includes(travelSearch.toLowerCase());
                // return data.includes('Out of State') 


            })
        )

    }

    const handleDriver = (e) => {
        e.preventDefault()
        setRequestsResults(
            userRequests.filter(request => {
                if (request.category.includes("Driver")) {
                    return request
                }
                return false
            })
        )

    }
  return (
    <div className=''>
            <Header user="admin" />
    
    <main id='request' className="lg:px-64 md:px-20 px-10 mt-32">
                <div className="md:flex hidden md:w-full">
                    <div className="md:w-full flex justify-between">
                        <form className="flex md:w-96 w-full">
                            <input
                                type="text"
                                onChange={handleSearch}
                                value={value}
                                className="focus:outline-none mr-2 border w-60 rounded-md border-white text-white  bg-transparent p-2"
                                placeholder="Request / Customer ID"
                            />
                            <button
                                type="submit"
                                className="w-28 bg-amber-500 font-bold rounded-md"
                            >
                                Search
                            </button>
                        </form>

                        <div className="p-2 px-3 border  text-white rounded-md border-white flex">


                            <button
                                className="mr-4 text-xs md:text-base"
                                type='button'
                                onClick={() => {
                                    setFirstOpen((isOpen) => !isOpen)


                                }}>

                                {startDate ? setSelectedStartDate : '2 March 2022'}
                            </button>


                            <label htmlFor="" className='mr-4 text-xs md:text-base'> - </label>

                            <button
                                className="mr-4 text-xs md:text-base"
                                type='button'
                                onClick={() => setOpen((isOpen) => !isOpen)}>

                                {endDate ? setSelectedEndDate : '2 April 2022'}


                            </button>

                            <img src={one} alt="" className="w-6" />



                            <div className="dateee">
                                <DatePicker
                                    selected={startDate}
                                    onChange={(date) => {
                                        setStartDate(date)
                                        { handleFilterDate(date) }

                                        setFirstOpen(false)
                                    }}

                                    open={firstOpen}

                                    style={{ marginRight: "50px" }}



                                />
                            </div>

                            <div className="dateee">
                                <DatePicker
                                    selected={endDate}
                                    onChange={(date) => {
                                        setEndDate(date)
                                        console.log('setSelectedEndDate', endDate)

                                        setOpen(false)
                                    }}

                                    open={open}

                                    style={{ color: "white" }}



                                />
                            </div>

                        </div>
                    </div>
                </div>
                <div className="md:flex hidden justify-between mt-12">
                    <div className="flex justify-between">
                        <div className="flex items-center mr-6">
                            <button
                                onClick={handleCar}

                                className="w-6 h-6 border-2 rounded-full border-white focus:bg-amber-500 mr-4"></button>
                            <h4 className="text-white">Cars</h4>
                        </div>

                        <div className="flex items-center mr-6">
                            <button

                                onClick={handleDriver}
                                className="w-6 h-6 border-2 rounded-full focus:bg-amber-500 border-white mr-4"></button>

                            <h4 className="text-white">Drivers</h4>
                        </div>

                        <div className="flex items-center mr-6">
                            <button
                                onClick={handleTravel}
                                className="w-6 h-6 border-2 rounded-full focus:bg-amber-500 border-white mr-4"></button>

                            <h4 className="text-white">Travel</h4>
                        </div>
                    </div>

                    {/* <div className="flex justify-between">
                        <button
                            onClick={handleOPen}
                            className="w-24 bg-red-700 p-1 text-white font-bold rounded-lg mr-3">
                            Open
                        </button>
                        <button
                            onClick={handlePending}


                            className="w-24  p-1 text-black bg-blue-500 font-bold rounded-lg mr-3">
                            Pending
                        </button>
                        <button
                            onClick={handleProgress}


                            className="w-24 bg-amber-500 p-1 text-black font-bold rounded-lg mr-3">
                            In Progess
                        </button>
                        <button
                            onClick={handleClosed}

                            className="w-24 bg-green-600 p-1 text-black font-bold rounded-lg">
                            Closed
                        </button>
                    </div> */}
                </div>

                <div className="flex md:hidden justify-between">
                    <div className="w-5/12">
                        <div className="flex flex-col gap-7 justify-between">
                            <div className="flex items-center">
                                <button className="w-4 h-4 border-2 rounded-full border-white bg-amber-500 mr-2"></button>
                                <h4 className="text-white text-xs">Drivers</h4>
                            </div>

                            <div className="flex items-center">
                                <button className="w-4 h-4 border-2 rounded-full border-white mr-2"></button>

                                <h4 className="text-white text-xs">Cars</h4>
                            </div>

                            <div className="flex items-center">
                                <button className="w-4 h-4 border-2 rounded-full border-white mr-2"></button>

                                <h4 className="text-white text-xs">Travel</h4>
                            </div>
                        </div>
                    </div>

                    <div className="flex justify-between w-full flex-col gap-7 md:hidden">
                        <div className="border  text-white rounded-md p-2 border-white flex items-center gap-2">
                            <p className="text-xs">2 March 2022 - 2 April 2022</p>
                            <img src={one} alt="" className="w-3 h-3" />
                        </div>
                        <form className="flex w-full">
                            <input
                                type="text"
                                className="focus:outline-none mr-2 border placeholder-text-xxx w-3/5 md:w-60 rounded-md border-white text-white  bg-transparent p-1"
                                placeholder="Request/Customer ID"
                            />
                            <button
                                type="submit"
                                className="md:w-28 w-2/5 bg-amber-500 font-bold md:text-base text-sm rounded-md"
                            >
                                Search
                            </button>
                        </form>
                    </div>
                </div>

                <div className="flex md:hidden justify-between w-full  mt-4 gap-1">
                    <button className="w-1/4 bg-red-600 p-1 text-white font-bold rounded-md text-xx">
                        Open
                    </button>
                    <button className="w-1/4  p-1 text-black bg-blue-400 font-bold rounded-md text-xx">
                        Pending
                    </button>
                    <button className="w-1/4 bg-amber-400 p-1 text-black font-bold rounded-md text-xx">
                        In Progess
                    </button>
                    <button className="w-1/4 bg-green-500 p-1 text-black font-bold rounded-md text-xx">
                        Closed
                    </button>
                </div>
            </main>

            <main className="lg:px-64 md:px-20 px-10 mt-20">
                {(requestsResult || []).length === 0 ? (
                    <>
                        <h3 className="my-20 text-white text-center text-2xl ">
                            Request Empty
                        </h3>
                    </>
                ) : (

                    startDate && endDate ?

                        (requestsResult.filter(obj => obj.book_date >= (moment(startDate).format("YYYY/MM/DD")) && obj.book_date <= (moment(endDate).format("YYYY/MM/DD"))).slice(0, visible).map((request, i) => {
                            return (
                                <div className="" key={request.id}>
                                <AdminRequest
                                        // setRequestsUpdate={setRequestsUpdate}

                                        book_image={request.book_picture}


                                        pDate={request.pickup_date}
                                        customerId={request.customer_id}
                                        requestId={request.id}
                                        numOfDays={request.num_of_day}
                                        time={request.pickup_time}
                                        address={request.pickup_location}
                                        category={request.category}
                                        bookingTitle={request.booking_title}
                                        price={request.amount}
                                        destination={request.destination}
                                        destinationState={request.destination_state}
                                        paymentStatus={request.payment_status}
                                        status={request.status}
                                        bookingId={request.booking_id}
                                        key_id={request.id}
                                        userId={request.booking_id}
                                        vehicleId={request.vehicle_id}
                                        preProvider={request.provider_id}
                                        bookStatus={request.book_status}


                                    />
                                </div>
                            );
                        })
                        )

                        :

                        (requestsResult.slice(0, visible).map((request, i) => {
                            return (
                                <div className="" key={request.id}>
                                <AdminRequest
                                        // setRequestsUpdate={setRequestsUpdate}

                                        book_image={request.book_picture}


                                        pDate={request.pickup_date}
                                        customerId={request.customer_id}
                                        requestId={request.id}
                                        numOfDays={request.num_of_day}
                                        time={request.pickup_time}
                                        address={request.pickup_location}
                                        category={request.category}
                                        bookingTitle={request.booking_title}
                                        price={request.amount}
                                        destination={request.destination}
                                        destinationState={request.destination_state}
                                        paymentStatus={request.payment_status}
                                        status={request.status}
                                        bookingId={request.booking_id}
                                        key_id={request.id}
                                        userId={request.booking_id}
                                        vehicleId={request.vehicle_id}
                                        preProvider={request.provider_id}
                                        bookStatus={request.book_status}


                                    />
                                </div>
                            );
                        })



                        ))


                }
            </main>

            {(userRequests || []).length === 0 ? (
                <></>
            ) : (
                <main className="md:px-64 px-12">
                    <div className="w-full flex items-center justify-center my-12">
                        <button
                            onClick={showMoreRequest}
                            className="border border-theme-color theme-color font-semibold p-3 text-sm rounded-lg w-36"
                        >
                            Load More
                        </button>
                    </div>
                </main>
            )}


    </div>
  )
}

export default AdminCustomerRequestsComponent