import React, {Fragment, useEffect, useState } from 'react';
import { NavLink, useNavigate } from "react-router-dom";
import users from '../../Images/user2.svg'
import sendicon from '../../Images/send.png'
import locationicon from '../../Images/location.svg'
import pen from '../../Images/pen.svg'
import profileIcon from '../../Images/profile-icon.svg'
import singleProfile from '../../Images/single-profile.jpeg'
import { IoIosShareAlt } from "react-icons/io";
import axios from 'axios'
import { BASE_URL } from "../utils/constant"

import DatePicker, { DateObject, Calendar } from "react-multi-date-picker";
import "react-multi-date-picker/styles/backgrounds/bg-dark.css"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Menu, Dialog, Transition } from "@headlessui/react";
import cancelImg from '../../Images/cancel.svg'
import getCookie from '../utils/SecuredTokens';

function Assets(props) {
    const { image,year,firstName, lastName, name, price, location, send,user,vehicleId,assetId, service, assetStatus, assetAvailability, bookStatus, driverId, uniqueId} = props
    let priceNum = Number(price);
    let vehName = name+ " ("+ year+")";

    const [driverAvailability, setDriverAvailability] = useState('')
    const [hideAssetModal, setHideAssetModal] = useState(false)
    const [bg, setBg] = useState(false)
    const [vehicleOffDays, setVehicleOffDays] = useState([])
    const [driverOffDays, setDriverOffDays] = useState([])
    const [calendarVisiblity, setCalendarVisibility] = useState(false)

    const [isVehicleArray, setIsVehicleArray] = useState([])
    const [isDriverArray, setIsDriverArray] = useState([])

    const [status, setStatus] = useState('Hide')



    let userData = getCookie("user");
    let userObject = JSON.parse(userData) 
    let token = userObject?.token
    
   


    // SET UNAVAILABILITY DATES...........
    const format = "MM/DD/YYYY";
    const today = new Date()
    const tomorrow = new Date()

    tomorrow.setDate(tomorrow.getDate() + 1)

    const [dates, setDates] = useState([])
    const [dateArray, setDateArray] = useState([])
    let dateNewArray = []
  
    useEffect(() => {
        dates.forEach((date, index) => {
            dateNewArray.push(date.format())
        })
        setDateArray(dateNewArray)
    }, [dates])


    // TOGGLE CALENDAR VISIBILITY
    const toggleCalendarVisibility = () => {
        setCalendarVisibility(!calendarVisiblity)
    }

    // GET VEHICLE CALENDAR
    const getAssetCalendar = () => {
        if(service !== 'driver') {
            axios({
                method: "get",
                url: `${BASE_URL}/v1/view-vehicle-calendar/${vehicleId}`,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    "Access-Control-Allow-Origin": "*",
                }
            })
            .then((response) => {
                // const respLike = response.data.success
                setIsVehicleArray(response.data.data)
                setVehicleOffDays(response.data.data[0]?.off_days?.split(','))
            })
            .catch((error) => {
            });
        }else {
            axios({
                method: "get",
                url: `${BASE_URL}/v1/view-driver-calendar/${driverId}`,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    "Access-Control-Allow-Origin": "*",
                }
            })
            .then((response) => {
                // const respLike = response.data.success
                setIsDriverArray(response.data.data)
                setDriverOffDays(response.data.data[0]?.off_days?.split(','))
            })
            .catch((error) => {
            });
        }
    }

    useEffect(() => {
        getAssetCalendar()
    }, [calendarVisiblity])


    // POST CALENDAR OFF DAYS
    const postCalendarOffDays = (e) => {
        e.preventDefault()
        let formData = new FormData();
        // formData.append('display', 0)
        formData.append('off_days', dateArray)

        if( uniqueId){
            formData.append('driver_id', driverId)

            if( isDriverArray.length === 0) {
                axios({
                    method: "post",
                    url: `${BASE_URL}/v1/create-calendar`,
                    data: formData,
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                        "Access-Control-Allow-Origin": "*",
                    }
                })
                .then((response) => {
                    // const respLike = response.data.success
                    setCalendarVisibility(!calendarVisiblity)
                    let notify = () => { toast("Your Calendar has been created") };
                    notify()
                })
                .catch((error) => {
                    let notify = () => { toast.error((error.response.data.message), {
                        toastClassName: 'error'
                    } ) };
                    notify()
                });
            }else {
                formData.append('display', '0')
    
                axios({
                    method: "post",
                    url: `${BASE_URL}/v1/update-calendar`,
                    data: formData,
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                        "Access-Control-Allow-Origin": "*",
                    }
                })
                .then((response) => {
                    setCalendarVisibility(!calendarVisiblity)
                    let notify = () => { toast("Your Calendar has been updated") };
                    notify()
                })
                .catch((error) => {
                    let notify = () => { toast.error((error.response.data.message), {
                        toastClassName: 'error'
                    } ) };
                    notify()
                });
            }
        }else {
            formData.append('vehicle_id', vehicleId)
            
            if( isVehicleArray.length === 0) {
                axios({
                    method: "post",
                    url: `${BASE_URL}/v1/create-calendar`,
                    data: formData,
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                        "Access-Control-Allow-Origin": "*",
                    }
                })
                .then((response) => {
                    // const respLike = response.data.success
                    setCalendarVisibility(!calendarVisiblity)
                    let notify = () => { toast("Your Calendar has been created") };
                    notify()
                })
                .catch((error) => {
                    let notify = () => { toast.error((error.response.data.message), {
                        toastClassName: 'error'
                    } ) };
                    notify()
                });
            }else {
                formData.append('display', '0')
    
                axios({
                    method: "post",
                    url: `${BASE_URL}/v1/update-calendar`,
                    data: formData,
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                        "Access-Control-Allow-Origin": "*",
                    }
                })
                .then((response) => {
                    setCalendarVisibility(!calendarVisiblity)
                    let notify = () => { toast("Your Calendar has been updated") };
                    notify()
                })
                .catch((error) => {
                    let notify = () => { toast.error((error.response.data.message), {
                        toastClassName: 'error'
                    } ) };
                    notify()
                });
            }
        }

    }

    console.log(assetAvailability, "assetAvailable")



    const setAssetAvailability = () => {
        if (uniqueId) {
            if (assetAvailability === 'Day Driver') {
                setDriverAvailability('Day Driver')
            }else if (assetAvailability === 'Night Driver') {
                setDriverAvailability('Night Driver')
            }else if (assetAvailability === 'Daily Driver') {
                setDriverAvailability('Daily Driver')
            }else if ((assetAvailability?.includes('Day Driver')) && (assetAvailability?.includes('Full-Time Driver'))) {
                setDriverAvailability('Day & Full Time')
            } else if ((assetAvailability?.includes('Day Driver')) && (assetAvailability?.includes('Full-Time Driver')) && (assetAvailability?.includes('Night Driver'))) {
                setDriverAvailability('Day, Full & Night')
            } else if ((assetAvailability?.includes('Day Driver')) && (assetAvailability?.includes('Night Driver')) && (assetAvailability?.includes('Daily Driver'))) {
                setDriverAvailability('Daily, Day, Night')
            }    else if ((assetAvailability?.includes('Night Driver')) && (assetAvailability?.includes('Full-Time Driver'))) {
                setDriverAvailability('Night & Full Time')
            } else if ((assetAvailability?.includes( 'Night Driver')) && (assetAvailability?.includes( 'Day Driver')))  {
                setDriverAvailability('Night & Day')
            }else if (assetAvailability?.includes('Full-Time Driver')) {
                setDriverAvailability('Full Time')
            }  else {
                setDriverAvailability('')
            }
        }else {

        }
    }

    useEffect(() => {
        setAssetAvailability()
    }, [])

    const handleHideAsset = () => {

        if(driverId) {

            let formDataDriver =  new FormData()

            formDataDriver.append('driver_id', driverId)
            formDataDriver.append('status', 0)

            axios({
                method: "post",
                url: `${BASE_URL}/v1/set-asset-visibilty`,
                data: formDataDriver,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    "Access-Control-Allow-Origin": "*",
                }
            })
            .then((response) => {
                setStatus('Show')
                setBg(false)
                setHideAssetModal(false)
            })
            .catch((error) => {
                let notify = () => { toast.error((error.response.data.message), {
                    toastClassName: 'error'
                } ) };
                notify()
            });
        } else {
            let formData = new FormData();
            // formData.append("id", id);
            
            formData.append('vehicle_id', vehicleId)
            formData.append('status', 0)


            axios({
                method: "post",
                url: `${BASE_URL}/v1/set-asset-visibilty`,
                data: formData,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    "Access-Control-Allow-Origin": "*",
                }
            })
            .then((response) => {
                setStatus('Show')
                setBg(false)
                setHideAssetModal(false)
            })
            .catch((error) => {
                let notify = () => { toast.error((error.response.data.message), {
                    toastClassName: 'error'
                } ) };
                notify()
            });
        }


    }

    const handleShowAsset = () => {
        if(driverId) {

            let formDataDriver =  new FormData()

            formDataDriver.append('driver_id', driverId)
            formDataDriver.append('status', 1)

            axios({
                method: "post",
                url: `${BASE_URL}/v1/set-asset-visibilty`,
                data: formDataDriver,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    "Access-Control-Allow-Origin": "*",
                }
            })
            .then((response) => {
                setStatus('Hide')
            })
            .catch((error) => {
                let notify = () => { toast.error((error.response.data.message), {
                    toastClassName: 'error'
                } ) };
                notify()
            });
        } else {
            let formData = new FormData();
            // formData.append("id", id);
            
            formData.append('vehicle_id', vehicleId)
            formData.append('status', 1)


            axios({
                method: "post",
                url: `${BASE_URL}/v1/set-asset-visibilty`,
                data: formData,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    "Access-Control-Allow-Origin": "*",
                }
            })
            .then((response) => {
                setStatus('Hide')
            })
            .catch((error) => {
                let notify = () => { toast.error((error.response.data.message), {
                    toastClassName: 'error'
                } ) };
                notify()
            });
        }
    }

    const handleHideAssetModal = () => {
        setBg(true)
        setHideAssetModal(true)
    }

    const closeModal = () => {
        setBg(false)
        setHideAssetModal(false)
    }


    return <div>
        <ToastContainer />

            {/* HANDLE HIDE REQUEST MODAL */}
            <div style={{position: "absolute", zIndex: "999"}}>
              <Transition appear show={hideAssetModal} as={Fragment}>
                  <Dialog
                      as="div"
                      className="fixed px-6 inset-0 z-[1000] overflow-y-auto"
                      onClose={closeModal}
                  >
                      <div className="min-h-screen px-4 text-center">
                          <Transition.Child
                              as={Fragment}
                              enter="ease-out duration-300"
                              enterFrom="opacity-0"
                              enterTo="opacity-25 bg-white"
                              leave="ease-in duration-200"
                              leaveFrom="opacity-25"
                              leaveTo="opacity-0"
                          >
                              <Dialog.Overlay className="fixed inset-0" />
                          </Transition.Child>

                          {/* This element is to trick the browser into centering the modal contents. */}
                          <span
                              className="inline-block h-screen align-middle"
                              aria-hidden="true"
                          >
                              &#8203;
                          </span>
                          <Transition.Child
                              as={Fragment}
                              enter="ease-out duration-300"
                              enterFrom="opacity-0 scale-95"
                              enterTo="opacity-100 scale-100"
                              leave="ease-in duration-200"
                              leaveFrom="opacity-100 scale-100"
                              leaveTo="opacity-0 scale-95"
                          >
                              <div className="notification-modal-wrapper inline-block w-full max-w-md pt-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-transparent shadow-xl rounded-2xl">
                                  <div className="bg-white rounded-2xl p-6">
                                      <div className="header flex justify-end mb-6">

                                          <button className=''>
                                              <img src={cancelImg} className='w-[30px]' onClick={closeModal} alt="Cancel Modal" />
                                          </button>
                                      </div>

                                      <div className="notification-lists-container h-[200px]">
                                            <p className='text-xl'>
                                            Every item you hide will no longer be listed on the platform, and therefore you won’t receive requests on it. You can always undo this by clicking show.
                                            </p>

                                            <div className="btn-container w-full flex justify-center mt-4 ">
                                                <button className='bg-red-600 rounded-xl flex font-bold text-base text-white justify-center p-3 px-6' onClick={handleHideAsset}>
                                                    Hide Now
                                                </button>
                                            </div>
                                      </div>
                                  </div>
                              </div>
                          </Transition.Child>
                      </div>
                  </Dialog>
              </Transition>
            </div>


        <div className="flex flex-col items-center my-4">
            <div className=" md:w-52 w-36 bg-white rounded-lg py-3 px-4 md:p-5 relative asset-wrapper">
                <div className="rounded-xl flex items-center justify-center bg-white w-full border border-gray-300 assets-img-wrapper">
                {
                    uniqueId ? 
                    <img src={`https://api.roadlers.com/storage/${image}` || singleProfile  } alt="" className='w-28 h-[157px] md:w-48 object-contain object-center rounded-xl' />:
                    <img src={`https://api.roadlers.com/storage/${image}` } alt="" className='w-28 h-[110px] md:w-48 object-contain object-center rounded-xl' />
                }
                </div>
                {
                    uniqueId ?
                    <h5 className='md:font-bold text-black md:text-base font-extrabold text-xx mt-2'>{`${firstName}  ${lastName ? lastName : ''}` }</h5> :
                    <h5 className='md:font-bold text-black md:text-base font-extrabold text-xx mt-2'>{vehName}</h5>
                }
                
                <hr className='md:w-20 w-12 md:mt-2 marg border-t border-black' />

                <div className="flex justify-between md:mt-2 mt-1 items-center font-bold">
                    {uniqueId ? 
                        (   assetStatus === 'Pending' || assetStatus === 'Rejected' || !assetStatus ?
                            <h5 className='md:text-sm text-xx'>
                                {driverAvailability}
                            </h5> : 
                            <>
                            {
                                bookStatus === 'Free' &&
                                <h5 className='md:text-sm text-xx'>
                                    Free
                                </h5> 
                            }


                            { bookStatus == 'Booked' &&
                                <h5 className='md:text-sm text-xx'>
                                    Booked
                                </h5> 
                            }

                            { !bookStatus &&
                                <h5 className='md:text-sm text-xx'>
                                    {driverAvailability}
                                </h5> 
                            }
                                     

                            </>
                        ) :
                        <h5 className='md:text-sm text-xx'>&#8358;{priceNum.toLocaleString('en-US', { maximumFractionDigits: 2 })}</h5>
                    }
                    <div className="flex items-center">
                        <img src={locationicon} alt="" className='md:w-6 w-4' />
                        <h5 className='md:text-sm text-xx'>{location}</h5>
                    </div>
                </div>

                <div className="flex items-center text-xs md:mt-3 mt-1">
                    {(assetStatus === 'Pending' || !assetStatus) &&
                        <div className="flex w-full justify-between">
                            <button className='bg-blue-700  md:px-4 p-1 md:text-xs text-xx text-white font-bold rounded-md mr-8 '>Pending</button>
                        
                            {
                                uniqueId ?

                                <NavLink to={`/approved-service-edit/${driverId}`}>
                                    <img src={profileIcon} alt="" className='cursor-pointer  w-5 md:w-6'/>
                                </NavLink> :

                                <NavLink to={`/pending-approval-edit/${assetId}`}>
                                    <img src={pen} alt="" className='cursor-pointer  w-5 md:w-6'/>
                                </NavLink>
                            }
                        </div> 
                    }

                    {(assetStatus === 'Rejected') &&
                        <div className="flex w-full justify-between">
                            <button className='bg-red-700  md:px-4 p-1 md:text-xs text-xx text-white font-bold rounded-md mr-8 '>Rejected</button>
                        
                            {
                                uniqueId ?

                                <NavLink to={`/approved-service-edit/${driverId}`}>
                                    <img src={profileIcon} alt="" className='cursor-pointer  w-5 md:w-6'/>
                                </NavLink> :

                                <NavLink to={`/pending-approval-edit/${assetId}`}>
                                    <img src={pen} alt="" className='cursor-pointer  w-5 md:w-6'/>
                                </NavLink>
                            }
                        </div> 
                    }

                    {(assetStatus === 'BLOCKED') &&
                        <div className="flex w-full justify-between">
                            <button className='bg-[#000] md:px-4 p-1 md:text-xs text-xx text-white font-bold rounded-md mr-8 ' disabled={true}>Blocked</button>
                        
                            {
                                uniqueId ?

                                <NavLink to={`/approved-service-edit/${driverId}`}>
                                    <img src={profileIcon} alt="" className='cursor-pointer  w-5 md:w-6'/>
                                </NavLink> :

                                <NavLink to={`/pending-approval-edit/${assetId}`}>
                                    <img src={pen} alt="" className='cursor-pointer  w-5 md:w-6'/>
                                </NavLink>
                            }
                        </div> 
                    }


                    {/* {status === 'Screen' ? <div className="flex w-full items-center justify-between">
                        <button className='bg-blue-700 w-2/4 md:px-4 p-1 md:text-xs text-xx text-white font-bold rounded-md mr-1 '>Screen</button>
                        {send==='send'?<img src={sendicon} alt=""  className='cursor-pointer'/>:''}
                        {user=== 'user'?<img src={users} alt=""  className='cursor-pointer md:w-7 w-5'/>:''}
                        <img src={pen} alt="" className='cursor-pointer w-6'/>
                    </div> : ''} */}

                    {/* {
                        assetStatus === 'Approved' && 
                        (
                            
                        )
                    } */}

                    {
                        assetStatus === 'Approved' && 
                        <>
                            {status === 'Show' ? <div className="flex w-full items-center justify-between">
                                <button
                                    onClick={handleShowAsset} 
                                    className='bg-amber-500 md:w-2/4 md:py-1 py-1 md:px-4 px-3 md:text-xs text-xx text-black font-bold rounded-md mr-1'>Show
                                </button>
                                {
                                    uniqueId ?
                                    ''
                                    :
                                    <>
                                        {/* {send==='send'?<img src={sendicon} alt=""  className='cursor-pointer '/>:''} */}
                                        {user=== 'user'?<img src={users} alt=""  className='cursor-pointer md:w-7 w-5'/>:''}
                                    </>
                                }

                                {
                                    uniqueId ?

                                    <NavLink to={`/approved-service-edit/${driverId}`}>
                                        <img src={profileIcon} alt="" className='cursor-pointer  w-5 md:w-6'/>
                                    </NavLink> :

                                    <NavLink to={`/pending-approval-edit/${assetId}`}>
                                        <img src={pen} alt="" className='cursor-pointer  w-5 md:w-6'/>
                                    </NavLink>

                                }


                            </div> : ''}
                            {status === 'Hide' ? <div className="flex w-full items-center justify-between">
                                <button
                                    onClick={handleHideAssetModal}
                                className='border border-black md:w-2/4 md:py-1 py-1 md:px-4 px-3 md:text-xs text-xx text-black font-bold rounded-md mr-1'>Hide
                                </button>
                                {
                                    uniqueId ?
                                    ''
                                    :

                                    <>
                                        {/* {send==='send'?<img src={sendicon} alt=""  className='cursor-pointer '/>:''} */}
                                        {user=== 'user'?<img src={users} alt=""  className='cursor-pointer md:w-7 w-5'/>:''}
                                    </>
                                    
                                }

                                {
                                    uniqueId ?

                                    <NavLink to={`/approved-service-edit/${driverId}`}>
                                        <img src={profileIcon} alt="" className='cursor-pointer  w-5 md:w-6'/>
                                    </NavLink> :

                                    <NavLink to={`/pending-approval-edit/${assetId}`}>
                                        <img src={pen} alt="" className='cursor-pointer  w-5 md:w-6'/>
                                    </NavLink>
                                }

                            </div> : ''}
                        </>
                    }
                </div>

            </div>
            {
                uniqueId ?
                <p className='font-normal text-white text-xx md:text-xs mt-2 text-center'>Asset ID: {assetId}</p> :
                <p className='font-normal text-white text-xx md:text-xs mt-2 text-center'>Asset ID: {assetId}</p>
            }
            
            {
                calendarVisiblity && 
                <div className="calendar-overall relative">
                    <div className="calendar-container absolute top-[-100px] z-[199] bg-[#312f2f] p-4 rounded-xl">
                        <div className="w-full">
                            <p className="text-white text-sm w-full">All crossed out dates indicates your bookings and you can manually pick off days  </p>

                            <p className='text-base text-white font-bold mt-2'>Dates Booked</p>
                        </div>
                        {
                            uniqueId ?
                            <Calendar
                                value={driverOffDays || dates}
                                onChange={setDates}
                                multiple
                                sort
                                format={format}
                                calendarPosition="bottom-center"
                                className='bg-dark'
                                // plugins={[<DatePanel />]}
                            /> :
                            <Calendar
                                value={vehicleOffDays || dates}
                                onChange={setDates}
                                multiple
                                sort
                                format={format}
                                calendarPosition="bottom-center"
                                className='bg-dark'
                                // plugins={[<DatePanel />]}
                            />
                        }

                        <div className="flex justify-between items-center">
                            {
                                uniqueId ?
                                <div className=" text-center submit-date bg-amber-500 mt-4 cursor-pointer h-[30px] w-2/4 md:w-2/4 md:py-1 py-1 md:px-4 px-3 md:text-xs text-xx text-black font-bold rounded-md mr-1 flex items-center justify-center" onClick={postCalendarOffDays}> Submit </div>:
                                <div className=" text-center submit-date bg-amber-500 mt-4 cursor-pointer h-[30px] w-2/4 md:w-2/4 md:py-1 py-1 md:px-4 px-3 md:text-xs text-xx text-black font-bold rounded-md mr-1 flex items-center justify-center" onClick={postCalendarOffDays}> Submit </div>
                            }

                            <div className=" text-center submit-date bg-white mt-4 cursor-pointer h-[30px] w-2/4 md:w-2/4 md:py-1 py-1 md:px-4 px-3 md:text-xs text-xx text-black font-bold rounded-md mr-1 flex items-center justify-center" onClick={toggleCalendarVisibility}> Close </div>

                        </div>


                    </div>
                </div>
            }

            {
                uniqueId ? 
                <button 
                onClick={toggleCalendarVisibility}
                className='theme w-full p-1 text-black text-sm font-semibold rounded-md mt-4 set-availability-btn'>
                    Set Avaliability
                </button> :
                <button 
                onClick={toggleCalendarVisibility}
                className='theme w-full p-1 text-black text-sm font-semibold rounded-md mt-4 set-availability-btn'>
                    Set Avaliability
                </button>
            }



        </div>

    </div>;
}

export default Assets;
