import React, { useState, Fragment, useEffect } from 'react'
import Header from '../Header'
import google from '../../Images/google.svg'
import fbb from '../../Images/fbb.svg'
import user from '../../Images/userg.png'
import closed from '../../Images/closed.svg'
import email from '../../Images/email.svg'
import calls from '../../Images/calls.svg'
import ref from '../../Images/ref.svg'
import car from '../../Images/carr.png'
import open from '../../Images/blackeye.png'
import Spin from "../../Images/spinner.gif"
import { BASE_URL } from "../utils/constant"



// import { NavLink } from "react-router-dom";
import Dropdown from '../Common/Dropdown'

import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Menu, Dialog, Transition } from "@headlessui/react";

import checkMail from '../../Images/checkMail.png'
import x from '../../Images/x.png'
import gmail from '../../Images/gmail.png'
import outlook from '../../Images/outlook.png'
import yahoo from '../../Images/yahoo.png'
import useAnalyticsEventTracker from '../Common/useAnalyticsEventTracker'
import { providerSignupEvent, providerSignupPage } from '../Common/facebookPixelEvent'

const ServiceProvider = () => {
    const gaEventTracker = useAnalyticsEventTracker('Provider Sign-Up')
    let navigate = useNavigate();
    const [passwordShown, setPasswordShown] = useState(false);
    let [checkMailOpen, setCheckMailOpen] = useState(false)
    const [bg, setBg] = useState(false)
    const [load, setLoad] = useState("")
    const [emailReg, setEmailReg] = useState('')
    const [token, setToken] = useState('')

    function closeModal() {
        setCheckMailOpen(false)
        setBg(false)
    }

    function checkMailModal() {
        setCheckMailOpen(true)
        setBg(true)
    }


    const phoneRegExp = /^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/

    const [selectedCategory, setSelectedCategory] = useState('')
    const categoryValue = selectedCategory;

    const togglePassword = (e) => {
        e.preventDefault()
        // When the handler is invoked
        // inverse the boolean state of passwordShown
        setPasswordShown(!passwordShown);
    };

    // ARRAY OF CATEGORY LIST
    const categoryArray = [
        { value: 'individual', label: 'Individual' },
        { value: 'business', label: 'Business' }
    ]

    useEffect(() => {
        providerSignupPage()
    }, [])


    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            phone_number: '',
            password: '',
            password_confirmation: '',
            user_role: 'provider',
            // referral: '',
        },
        validationSchema: Yup.object({
            name: Yup.string().min(3, "Must be 3 Characters or more").required("This is Required"),
            email: Yup.string().email("Invalid Email Address").required("This is Required"),
            phone_number: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required("Phone Number is Required"),

            password: Yup.string()    
            .required("This is Required")
            .matches(
                /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/,
                "Must Contain at least 6 Characters, One Uppercase, One Lowercase and One Number"
              ),
            password_confirmation: Yup.string()
                .oneOf([Yup.ref('password'), null], 'Password must Match').required("Confirm Password is Required"),

            acceptTerms: Yup.bool().oneOf([true], 'Accept Terms & Conditions is required')

        }),
        onSubmit: (values) => {
            gaEventTracker('SignUp Btn')
            providerSignupEvent()
            setLoad(Spin);
            axios({
                method: 'post',
                url: `${BASE_URL}/v1/provider-sign-up`,

                data: { ...values, category: `${categoryValue}` },
                headers: {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*",
                    // Authorization: `Bearer ${token}`,
                }
            })
                .then(function (response) {
                    //handle success
                    setLoad("")
                    setEmailReg(response.data.data.email)
                    setToken(response.data.data.token)

                    localStorage.setItem("userId", JSON.stringify(response.data.data.id))
                    localStorage.setItem("user", JSON.stringify(response.data.data))
                    localStorage.setItem("userToken", JSON.stringify(response.data.data.token))
                    localStorage.setItem("userCategory", JSON.stringify(response.data.data.category))
                    localStorage.setItem("isUserRegistered", JSON.stringify(response.data.data.if_register))

                    const isRegistered = response.data.data.if_register
                    if (response.data.status === true) {
                        if(isRegistered == "1") {
                            navigate('/service-provider-dashboard')
                          }else {
                            navigate('/individual-account-registration')
                          }
                    }
                })
                .catch(function (error) {
                    setLoad('')
                    //handle error
                    let notify = () => {
                        toast.error((error.response.data.message), {
                            toastClassName: 'error'
                        })
                    };
                    notify()
                    setLoad('')
                })
        },
    })


    const handleEmailSubmit = () => {
        let formData = new FormData()

        formData.append('email', emailReg)

        axios({
            method: "post",
            url: `${BASE_URL}/v1/email/resend`,
            data: formData,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            }
        })
            .then((response) => {
                let notify = () => { toast("Email Resent") };
                notify()
            })
            .catch((error) => {
                alert(error.response.data.message)
            });
    }

    return (
        <div className={bg === true ? " blur-sm contrast-50 backdrop-brightness-50" : "w-full "}>
            <ToastContainer />

            <div className="flex justify-center items-center">
                <Transition appear show={checkMailOpen} as={Fragment}>
                    <Dialog
                        as="div"
                        className="w-full md:w-11/12  fixed flex justify-center items-center px-6 inset-0 z-10 overflow-y-auto"
                        onClose={closeModal}
                    >
                        <div className="w-full md:w-6/12 min-h-screen px-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-25 bg-white"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-25"
                                leaveTo="opacity-0"
                            >
                                <Dialog.Overlay className="fixed inset-0" />
                            </Transition.Child>

                            {/* This element is to trick the browser into centering the modal contents. */}
                            <span
                                className="inline-block h-screen align-middle"
                                aria-hidden="true"
                            >
                                &#8203;
                            </span>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <div className="w-full md:w-6/12 inline-block  max-w-md pt-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-project shadow-xl rounded-lg">

                                    <div className="flex w-full justify-end">
                                        <button
                                            type='button'

                                            className=''>
                                            <img src={x} className='mr-7 w-[15px]' onClick={closeModal} alt="Cancel Modal" />
                                        </button>
                                    </div>
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg p-3 font-medium leading-6 text-gray-600"
                                    >


                                        <img src={checkMail} alt="" />
                                    </Dialog.Title>

                                    <div className="px-6 flex gap-2 items-center">

                                        <div className="w-1/3">
                                            <a href="https://www.gmail.com">
                                                <img src={gmail} alt="" />
                                            </a>



                                        </div>

                                        <div className="w-1/3">
                                            <a href="https://outlook.office.com/mail/">
                                                <img src={outlook} alt="" />
                                            </a>

                                        </div>

                                        <div className="w-1/3 bg-white rounded-md flex justify-center">
                                            <a href="https://mail.yahoo.com">

                                                <img src={yahoo} alt="" className='w-11/12 ' />
                                            </a>

                                        </div>
                                    </div>

                                    <div className="bg-project p-6 flex flex-col items-center justify-center">


                                        <button
                                            type="button"
                                            className="my-3 inline-flex w-full justify-center px-4 py-2 text-xs font-bold text-black bg-white border border-transparent rounded-md  focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2"
                                            onClick={handleEmailSubmit}
                                        >
                                            Resend
                                        </button>

                                        <label htmlFor="" className='text-xs leading-6 text-center text-amber-400'>If you haven't received a verification mail yet please click the resend button.</label>
                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </Dialog>
                </Transition>

            </div>

            <Header user='provider' />
            <div className="flex justify-center md:pt-40 pt-32 flex-col items-center w-full pb-20 mb-8 relative">
                <h1 className="md:px-20 text-center text-white font-bold text-xl md:text-2xl md:mb-4 mb-4">Make Money with Roadlers</h1>

                <h4 className="md:px-20  text-center text-[#ffa100] font-bold text-sm md:text-base md:mb-8 mb-4">Upload Your Vehicle Today</h4>
                <div className="md:bg-white bg-transparent md:p-6 md:px-16 px-12 md:w-2/6 w-full h-auto rounded-lg">
                    <form onSubmit={formik.handleSubmit} className='flex flex-col items-center justify-center mt-12 md:mt-16 relative'>
                        <img src={user} className="md:w-20  w-16 absolute md:-top-12 -top-9 left-50" alt="" />

                        <input
                            type="text"
                            placeholder='Full Name:'
                            className='p-3 w-full border border-gray-500 rounded-lg my-4 placeholder:text-xs placeholder:text-gray-700'
                            name="name"
                            id="name"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.name}
                        />
                        {formik.touched.name && formik.errors.name ? <p className='text-red-600 text-left text-xs w-full mb-4'>{formik.errors.name}</p> : null}

                        <div className='p-3 bg-white w-full flex gap-1 items-center  justify-center border border-gray-500 rounded-lg mt-2'>
                            <input
                                type="email"
                                placeholder='Your Email:'
                                className='w-11/12 placeholder:text-xs placeholder:text-gray-700'
                                name="email"
                                id="email"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.email}
                            />

                            <img src={email} className="w-6" alt="" />
                        </div>
                        {formik.touched.email && formik.errors.email ? <p className='text-red-600 text-left text-xs w-full mt-3 mb-4'>{formik.errors.email}</p> : null}



                        <div className='p-3 bg-white w-full flex gap-1 items-center  justify-center border border-gray-500 rounded-lg mt-6'>
                            <input
                                type="phone"
                                placeholder='Phone Number:'
                                className='w-11/12 placeholder:text-xs placeholder:text-gray-700'
                                name="phone_number"
                                id="phone_number"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.phone_number}

                            />

                            <img src={calls} className="w-6" alt="" />
                        </div>
                        {formik.touched.phone_number && formik.errors.phone_number ? <p className='text-red-600 text-left text-xs w-full mt-1 mb-4'>{formik.errors.phone_number}</p> : null}


                        <div className="p-2 bg-white w-full border border-gray-500 rounded-lg mt-6 pl-5 pr-0 py-3">
                            <Dropdown
                                holder='Select Category'
                                optionArray={categoryArray}
                                setSelectedOption={setSelectedCategory}
                                selectedOption={selectedCategory}
                            />
                        </div>



                        <div className='p-3 bg-white w-full flex gap-1 items-center  justify-center border border-gray-500 rounded-lg mt-6'>
                            <input
                                type={passwordShown ? "text" : "password"}
                                placeholder='Password'
                                className='w-11/12 placeholder:text-xs placeholder:text-gray-700'
                                name="password"
                                id="password"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.password}
                            />

                            {passwordShown ? (
                                <button onClick={e => togglePassword(e)}><img src={open} className="w-6 h-6" alt="" /></button>

                            ) : (
                                <button onClick={e => togglePassword(e)}><img src={closed} className="w-6 h-6" alt="" /></button>
                            )}
                        </div>
                        {formik.touched.password && formik.errors.password ? <p className='text-red-600 text-left text-xs w-full mt-2 mb-4'>{formik.errors.password}</p> : null}



                        <div className='p-3 w-full bg-white flex gap-1 items-center  justify-center border border-gray-500 rounded-lg mt-6'>
                            <input
                                type={passwordShown ? "text" : "password"}
                                placeholder='Confirm Password'
                                className='md:text-base text-xs w-11/12 placeholder:text-xs placeholder:text-gray-700'
                                name="password_confirmation"
                                id="password_confirmation"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.password_confirmation}
                            />



                            {passwordShown ? (
                                <button onClick={e => togglePassword(e)}><img src={open} className="w-6 h-6" alt="" /></button>
                            ) : (
                                <button onClick={e => togglePassword(e)}><img src={closed} className="w-6 h-6" alt="" /></button>

                            )}

                        </div>
                        {formik.touched.password_confirmation && formik.errors.password_confirmation ? <p className='text-red-600 text-left text-xs w-full mt-2 mb-4'>{formik.errors.password_confirmation}</p> : null}





                        <div className="flex items-center justify-center gap-2 my-6 w-full">
                            <input
                                type="checkbox"
                                name="acceptTerms"
                                id="acceptTerms"
                                required

                            />
                            <p className='md:text-gray-500 text-white font-semibold text-xx md:text-base'>I read & agreed to the
                                <NavLink className="font-semibold theme-color" exact to="#"> Terms and Conditions</NavLink>

                            </p>
                        </div>

                        {load ? (
                            <div className="bg-amber-500 w-11/12 font-semibold rounded-lg my-4 p-1 flex justify-center items-center ">
                                <h4 className="text-black text-sm">Please Wait!</h4>
                                <input
                                    type="image"
                                    src={Spin}
                                    value="Loading"
                                    className="w-10"
                                />
                            </div>
                        ) : (
                            <button
                                type="submit"
                                className="bg-amber-500 p-3 w-11/12 font-semibold rounded-lg my-4"
                            >
                                Sign-Up
                            </button>
                        )}
                    </form>

                    <p className='text-center my-4 text-base font-semibold md:text-gray-500 text-white'>Already have an account?

                        <NavLink className="font-semibold theme-color" exact to="/login"> Login</NavLink>

                    </p>
                    {/* <div className="flex gap-3 items-center justify-center my-16">
                        <div className="flex orange rounded-lg px-4 py-1 h-10"><button className='focus:outline-none  text-white text-xs'>Sign-up with</button> <img className='w-6' src={google} alt="" /></div>
                        <div className="flex bg-blue-700 rounded-lg px-4 py-1 h-10"><button className='focus:outline-none  text-white text-xs'>Sign-up with</button> <img className='w-6' src={fbb} alt="" /></div>
                    </div> */}

                </div>
                <img src={car} alt="" className='absolute -bottom-4 md:right-96 right-16 md:w-64 w-48' />



            </div>



        </div>
    )
}

export default ServiceProvider