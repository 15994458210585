import React, { useEffect, useState } from 'react';
import Header from './Header'
import { BASE_URL } from "./utils/constant"

import dashboard from '../Images/dashboard.png'
import admin_request from '../Images/admin_request.svg'
import service_p from '../Images/service_p.svg'
import reports from '../Images/reports.svg'
import customer from '../Images/customers.png'
import notifications from '../Images/notifications.png'
import download from '../Images/download.svg'
import car_asset from '../Images/car_asset.svg'
import icon from '../Images/icon.svg'
import car from '../Images/car.png'
import message from '../Images/message.svg'
import payment from '../Images/payment.png'
import naira from '../Images/naira.png'
import one from '../Images/one.png'
import driverOne from '../Images/driver1.png'
import pen from '../Images/pen.svg'
import roadlersRR from '../Images/RoadlersRR.svg'

import axios from "axios";
import Navigation from './Providers/Navigation';
import ApprovedServiceComponent from '../Components/Providers/ApprovedServiceComponent';
import getCookie from './utils/SecuredTokens';


function ApprovedService() {

    let userr = getCookie("user");
    let userObject = JSON.parse(userr)
    let token = userObject?.token

    let userIdProvider =  JSON.parse(localStorage.getItem("userIdProvider"))
    let driverId = JSON.parse(localStorage.getItem("driverId"))


     
 
  
    // SERVICE DASHBOARD LOADER
    const [dashboardLoad, setDashboardLoad] = useState(true)
    const [driverData, setDriverData] = useState([])

    const getDriverAssets = async () => {
        let id = JSON.parse(localStorage.getItem("userId"))
        await axios.get(`${BASE_URL}/v1/driver-assets/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            }
        }).then((response) => {
            const driverAsset = response.data.data[0]?.id;
            localStorage.setItem("providerAssetId", JSON.stringify(driverAsset))
        }).catch((err) => {
        })
    }

    useEffect(() => {
        getDriverAssets()
    }, [])


    const getProvider = () => {
        let driverAssetsId = JSON.parse(localStorage.getItem("providerAssetId"))
        axios({
            method: 'get',
            url: `${BASE_URL}/v1/pending-approval-driver/${driverAssetsId}`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            }
        })
        .then(function (response) {
            const customer = response.data;
            setDriverData([customer.data])
            setDashboardLoad(false)
        })
        .catch(function (error) {
            //handle error
        });
    }

    useEffect(() => {
        setTimeout(() => {
            getProvider()
        }, 10000)
    }, [])








    return (
    dashboardLoad ? 
    <div className='h-[100vh] w-full flex justify-center items-center'>
        <div class="lds-ring">
            <img src={roadlersRR}  alt="roadlers logo" className='embed-logo'/>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
    :
    <div>
        <Header user='provider' />

        <main className='flex md:px-12 px-12 flex-col md:flex-row overall-approval-page'>
        {/* {
            driverData.map((driverItem, index) => {
                <Navigation 
                    key={index}
                    statusApproval={driverItem.status}
                />
            })
        } */}

                <Navigation 
                    statusApproval={driverData[0]?.status}
                />  

        {
            driverData.map((driverItem, index) => {
                return (
                    <ApprovedServiceComponent 
                        key={index}
                        firstName={driverItem?.firstname}
                        lastname={driverItem.lastname}
                        address={driverItem.address}
                        category={driverItem.category}
                        service={driverItem.service}
                        availability={driverItem.availability}
                        dob={driverItem.dob}
                        stateLocation={driverItem.state}
                        countryLocation={driverItem.country}
                        providerId={driverItem.provider_id}
                        statusProfile={driverItem.status}
                        guarantorDoc={driverItem.guarantor}
                        driverDoc={driverItem.driver_document}
                    />
                )
            })
        }


        </main>
    </div>);
}

export default ApprovedService;
