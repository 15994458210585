import React from 'react'
import like from '../Images/Like-icon.png'
import like_two from '../Images/Like-icon2.png'
import { NavHashLink as Link } from "react-router-hash-link";

function RelatedService(props) {
    const { image, name, status, saved } = props


    return <>

        <div className="flex flex-col items-center justify-center">
            <div className=" md:w-52 md:h-72 w-36 bg-transparent border border-amber-500 rounded-xl py-3 flex justify-between flex-col px-4 md:p-5 relative">
                <div className="rounded-xl  md:w-40  md:h-28 flex items-center justify-center w-full mb-1">
                    <img src={image} alt="" className='w-28 md:w-full object-cover object-center' />
                </div>
                <h5 className='md:font-bold  md:text-base font-semibold text-xx text-white mt-1'>{name}</h5>
                <hr className='md:w-20 w-12 md:mt-2 mt-1 marg border-t border-white' />
                <div className="flex items-center text-xs md:mt-12 mt-4">
                    <div className="flex w-full justify-between">

                        {
                            name === 'Monthly full-time driving service' ?
                                <Link to="/#drivers" className="w-8/12 bg-transparent text-center flex items-center justify-center border border-white  md:text-xs text-xx text-white font-bold rounded-md">{status}</Link>
                                :
                                <Link to="/#vehicles" className="w-8/12 bg-transparent text-center flex items-center justify-center border border-white  md:text-xs text-xx text-white font-bold rounded-md">{status}</Link>

                        }

                        {saved ? (<button><img src={like_two} alt="" className='cursor-pointer w-9 h-8 p-0' /></button>) :
                            (<button className='W-4/12'>
                                <img src={like} alt="" className='cursor-pointer w-9 h-8 p-0' />

                            </button>)}




                    </div>

                </div>





            </div>

        </div>

    </>;
}

export default RelatedService