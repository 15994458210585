import React, { Fragment, useState, useEffect, useCallback, memo } from 'react'

import user from '../../Images/user2.svg'
import location from '../../Images/location.svg'
import userg from '../../Images/userg.png'
import { BASE_URL } from "../utils/constant"

import { Menu, Dialog, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import tickbox from '../../Images/tickbox.png'
import vmark from '../../Images/vmark.png'

import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import getCookie from '../utils/SecuredTokens'
function Provider(props) {

   
    let userData = getCookie("user");
    let userObject = JSON.parse(userData) 
    let token = userObject?.token


    const { image,
        providers } = props

    let provider = providers;

    let [providerOpen, setProviderOpen] = useState(false)
    const [details, setDetails] = useState([])

    function closeModal() {
        setProviderOpen(false)



    }
    function providerModal() {
        setProviderOpen(true)


    }

    const getProvider = useCallback(async () => {
        await axios({
            method: "get",
            url: `${BASE_URL}/v1/search-providerby-id/${providers.id}`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            },
        }).then(function (response) {
            // localStorage.removeItem("cart")
            const selectedProvider = response.data.data;

            setDetails(selectedProvider[0])

        });
    }, []);

    useEffect(() => {
        getProvider();

    }, []);

    //  function handleBlockNotice(e) {
    //         e.preventDefault();

    //         let formData = new FormData();

    //         formData.append("id", driverId);
    //         formData.append("display", 0);
    //         formData.append("status", 0);



    //         axios({
    //             method: "post",
    //             // url: `https://api.roadlers.com/api/v1/block-provider`,
    //             url: `https://api.roadlers.com/api/v1/admin-block-notice`,
    //             data: formData,
    //             headers: {
    //                 "Content-Type": "application/json",

    //                 Authorization: `Bearer ${token}`,

    //                 "Access-Control-Allow-Origin": "*",
    //             },
    //         })
    //             .then(function (response) {
    //                 //handle success

    //                 const message = response.data.data;
    //                 console.log('message', message)
    //                 const notify = () => { toast('Block Notice Sent Successfully') };
    //                 notify()
    //             })
    //             .catch(function (error) {
    //                 //handle error
    //                 console.log("error ", error.response.data);
    //             });
    //     };

    let availability = providers.availability;
    let newAvailability = availability?.split(",");
    let newAvailabilityStatus = newAvailability?.[0];

    return <>

        <Transition appear show={providerOpen} as={Fragment}>
            <Dialog
                as="div"
                className="w-full md:w-11/12  fixed flex justify-center items-center px-6 inset-0 z-10 overflow-y-auto"
                onClose={closeModal}
            >
                <div className="w-full xl:w-5/12 md:w-7/12 min-h-screen px-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-25 bg-white"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-25"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="inline-block h-screen align-middle"
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className="w-full md:w-8/12 inline-block  max-w-md  overflow-hidden text-left align-middle transition-all transform shadow-xl rounded-2xl">


                            <div className="bg-black p-10">
                                <div className="flex flex-col gap-4 ">
                                    <div className="flex gap-4">
                                        <img src={userg} alt="" className='w-8 h-8' />
                                        <h4 className='text-white'>
                                            This account is owned by <br />
                                            <span className='text-sm font-semibold text-amber-500'>
                                                Provider’s ID: {provider?.user_id}
                                            </span>

                                        </h4>
                                    </div>


                                    <div className="mt-6 flex flex-col gap-4">

                                        <div className="flex gap-4">
                                            {details?.service === 'vehicle' ?

                                                <img src={vmark} alt="" className='bg-white p-0 w-5 ml-1' /> :
                                                <img src={tickbox} alt="" className='w-7  p-1' />
                                            }
                                            <p className='text-white font-bold'>Car Owner</p>
                                        </div>
                                        <div className="flex gap-4">
                                            {details?.service === 'driver' ?

                                                <img src={vmark} alt="" className='bg-white p-0 w-5 ml-1' /> :
                                                <img src={tickbox} alt="" className='w-7  p-1' />
                                            }

                                            <p className='text-white font-bold'>Driver Only</p>
                                        </div>
                                        <div className="flex gap-4">
                                            {details?.category === 'business' ?

                                                <img src={vmark} alt="" className='bg-white p-0 w-5 ml-1' /> :
                                                <img src={tickbox} alt="" className='w-7  p-1' />
                                            }
                                            <p className='text-white font-bold'>Business</p>
                                        </div>

                                        <div className="flex gap-4">
                                            {details?.service === 'vehicle_driver' ?

                                                <img src={vmark} alt="" className='bg-white p-0 w-5 ml-1' /> :
                                                <img src={tickbox} alt="" className='w-7  p-1' />
                                            }
                                            <p className='text-white font-bold'>Driver and Car</p>
                                        </div>
                                    </div>

                                    {/* <div className="items-center cursor-pointer mt-2">
                                        <button

                                            type="submit"
                                            onClick={handleBlockNotice}

                                            className='rounded-md w-full text-center mt-4 py-2 px-8 text-black text-xs font-bold bg-amber-500'>

                                            Block User</button>
                                    </div> */}


                                </div>



                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
        <div className="flex flex-col items-center w-full">
            <div className="xl:w-48 lg:w-48 md:w-44 w-36 bg-white rounded-lg md:p-4 p-3 relative">
                <img src={image} alt="" />
                <h5 className='font-bold text-black md:text-sm text-xs mt-2'>{provider?.name}</h5>
                <hr className='w-20 mt-1 border-t border-black' />

                <div className="flex justify-between mt-2 font-bold">
                    <h5 className='md:text-xs text-xx font-bold'>
                        {newAvailabilityStatus === 'Full-Time Driver' ? 'Full Time' : newAvailabilityStatus

                        }
                    </h5>
                    <div className="flex items-center">
                        <img src={location} alt="" className='md:w-6 w-4' />
                        <h5 className='md:text-xs text-xx'>{provider?.state}</h5>

                    </div>
                </div>

                <div className="flex items-center mt-2 text-xs">
                    <button

                        className='bg-red-700 px-4 py-1 text-white font-bold rounded-md mr-2'>Due</button>


                    <img
                        onClick={providerModal}

                        src={user} alt="" className='w-5 md:w-8' />


                </div>

            </div>
            <p className='font-normal text-white text-xx md:text-xs mt-2 text-center'>Provider’s ID: {provider?.user_id}</p>


        </div>

    </>;
}

export default memo(Provider);
