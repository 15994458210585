import React, { Fragment, useState, useEffect, memo } from "react";
import { Menu, Dialog, Transition } from "@headlessui/react";
import { BASE_URL } from "./utils/constant"
import logo from '../Images/logo.png'
import notification from '../Images/notification.png'
import { HashLink } from "react-router-hash-link";
import cart from '../Images/cart.png'
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import dashboard from '../Images/dashboard.png'
import payment from '../Images/payment.png'
import service_provider from '../Images/service_provider.png'
import price_setting from '../Images/price_setting.png'
import customer from '../Images/customers.png'
import notifications from '../Images/notifications.png'
import naira from '../Images/naira.png'
import one from '../Images/one.png'
import refs from '../Images/refs.png'
import offers from '../Images/offers.png'
import user_icon from '../Images/userg.png'
import axios from "axios";
import { viewNotify } from "../slices/Notification";
import { setNotif } from "../slices/notif"
import { setBadge } from "../slices/badge";
import cancelImg from '../Images/cancel.svg'
import moment from 'moment';

import { NavHashLink as Link } from "react-router-hash-link";
import { useFormik } from 'formik'
import * as Yup from 'yup'

import Spin from '../Images/spinner.gif';

import fbb from '../Images/fbb.svg'
import calls from '../Images/calls.svg'
import email from '../Images/email.svg'

import open from '../Images/blackeye.png'
import closed from '../Images/closed.svg'
import DatePicker from "react-datepicker";
import { useCookies } from 'react-cookie';
import { fetchData } from "./utils/fetchData";
import getCookie from "./utils/SecuredTokens";





function Header(props) {

  function ScrollToTopOnMount() {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    return null;
  }



  const [cookies, setCookie, removeCookie] = useCookies();


  let users = getCookie("user");
  let userObject;
  let token;
  users ? userObject = JSON.parse(users) : userObject = ''
  users ? token = JSON.parse(users)?.token : token = ''
  let [registerAdminOpen, setRegisterAdminOpen] = useState(false)
  let [registerSuperadminOpen, setRegisterSuperadminOpen] = useState(false)


  // admin notifications filter
  // const [notificationFilterOpen, setNotificationFilterOpen] = useState(false);
  // const [notificationFilterOpenTwo, setNotificationFilterOpenTwo] = useState(false);
  // const [notificationStartDate, setNotificationStartDate] = useState('');
  // const [notificationEndDate, setNotificationEndDate] = useState('');
  const [open, setOpen] = useState(false);
  const [firstOpen, setFirstOpen] = useState(false);
  const [notificationStartDate, setNotificationStartDate] = useState('');
  const [notificationEndDate, setNotificationEndDate] = useState('');
  let setSelectedStartDate = moment(notificationStartDate).format("DD/MM/YYYY")
  let setSelectedEndDate = moment(notificationEndDate).format("DD/MM/YYYY")


  let [bg, setBg] = useState(false)


  let userCategory = JSON.parse(localStorage.getItem("userCategory"))

  let id = JSON.parse(localStorage.getItem("userId"))
  let navigate = useNavigate();
  const dispatch = useDispatch()
  const { notif } = useSelector((state) => state.notif);
  const { badge } = useSelector((state) => state.badge)

  // console.log(notif)
  const { user } = props
  const [isOpen, setIsOpen] = useState(false);
  let userLoggedIn = JSON.parse(localStorage.getItem("userId"))

  const [notificationModal, setNotificationModal] = useState(false)
  const [adminNotificationModal, setAdminNotificationModal] = useState(false)

  const [notifyListData, setNotifyListData] = useState([])
  const [showBadge, setShowBadge] = useState(badge)

  const [initialLength, setInitialLength] = useState(notif)

  let isProviderRegistered = JSON.parse(localStorage?.getItem("isUserRegistered"))
  const [providerNotificationArray, setProviderNotificationArray] = useState([])

  const [isUserRegistered, setIsUserRegistered] = useState('')

  const getIsRegistered = () => {
    setIsUserRegistered(isProviderRegistered)
  }

  useEffect(() => {
    getIsRegistered()
  }, [])


  // get admin and super admin notification
  const [adminNotifications, setAdminNotifications] = useState([]);




  useEffect(() => {
    let subscribed = true;
    const fetchNotifications = async () => {
      let notificationDatas = await fetchData(`${BASE_URL}/v1/fetch-admin-notifications`);
      if (userObject?.role === 'admin' || userObject?.role === 'super_admin') {
        if (subscribed) {
          setAdminNotifications(notificationDatas.data)
        } else {
          setAdminNotifications('')
        }
      }
    }
    fetchNotifications()
    // clean up the function
    return () => {
      subscribed = false;
    }
  }, [])

  // console.log(initialLength, 'AHHHHHHHHHHHHHHHHHHH')
  // console.log(badge, "Badge true or false")



  //on component load, notification list data is fetch and the length is crosschecked with what we have in the notif store (line 72), if the length is equal to the number in the notif state then we console.log equal but if the numbers are different (line 74), we set the new length to be stored in the store and we set the show badge to be true. so if show badge is true we can show a red dot component on the bell image. So what you have to work on is the timeout shii.... and the time interval to trigger the useEffect in line 55.


  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(viewNotify()).then(
        (response) => {
          setNotifyListData(response?.payload?.data)
          setInitialLength(response?.payload?.data?.length)
          // console.log(notifyListData.length, 'notifyListData')
          // console.log(notif, "notifffff")
        },
        (error) => {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
          console.log(message)
        }
      )
    }, 5000)

    return () => clearTimeout(timer);
  }, [])

  useEffect(() => {
    if (initialLength === notif) {
      console.log("equal")
      // setShowBadge(false)
    } else {
      dispatch(setNotif(notifyListData?.length))
      setShowBadge(true)
      dispatch(setBadge(true))

    }
  }, [])


  const handleLogout = (e) => {
    e.preventDefault();
    console.log("log out seee")
    axios({
      method: 'post',
      url: `${BASE_URL}/v1/logout`,

      config: { headers: { 'Content-Type': 'application/json' } }

    })
      .then(function (response) {
        //handle success
        localStorage.removeItem("userId")

        localStorage.removeItem("cartUserId")
        localStorage.removeItem("profile-history")
        localStorage.removeItem("user")
        localStorage.removeItem("userToken")
        localStorage.removeItem("cart")
        localStorage.removeItem("mealOffer")
        localStorage.removeItem("dataHistory")
        localStorage.removeItem("goldenTicket")
        localStorage.removeItem("goldenTicketCoupon")
        localStorage.removeItem("driverId")
        localStorage.removeItem("providerAssetId")



        if (response.data.status === true) {
          const messsage = response.data.message
          navigate('/login')

          removeCookie('user')

        }
        else {
          const messsage = response.data.message
          navigate('/login')
        }
      })
      .catch(function (error) {
        //handle error

        console.log("error leleyi", error.response.data)
      });


  }

  // Handle Notification Modal
  const handleNotificationModal = () => {
    setNotificationModal(true)
    setBg(true)
    setShowBadge(false)
    dispatch(setBadge(false))
    dispatch(viewNotify()).then(
      (response) => {
        setNotifyListData(response?.payload?.data)
        console.log(response?.payload?.data[0]?.data.message)

      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        console.log(message)
      }
    )
  }

  const [providerNotfication, setProviderNotification] = useState(false)



  function registerAdminModal() {
    setRegisterAdminOpen((prev) => !prev)
    setBg(true)
    setIsOpen(false)

  }
  function registerSuperadminModal() {
    setRegisterSuperadminOpen(true)
    setBg(true)
    setIsOpen(false)

  }

  function adminNotify() {
    setAdminNotificationModal(true)

    setBg(true)
    setIsOpen(false)

  }

  const closeModal = () => {
    setRegisterSuperadminOpen(false)
    setRegisterAdminOpen(false)
    setIsOpen(false)
    setAdminNotificationModal(false)

    setBg(false)
    setNotificationModal(false)
    setProviderNotification(false)
  }

  // GET REQUEST FOR PROVIDER NOTIFICATION
  const getProviderNotifcation = () => {
    axios.get(`${BASE_URL}/v1/fetch-provider-notification/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userObject?.token}`,
        "Access-Control-Allow-Origin": "*",
      }
    }).then((response) => {
      setProviderNotificationArray(response.data.data)
    }).catch((error) => {
      console.log(error)
    })
  }


  // HANDLE PROVIDER NOTIFICATION MODAL
  const handleProviderNotificationModal = () => {
    setBg(true)
    setProviderNotification(true)
    getProviderNotifcation()
  }


  const [load, setLoad] = useState("")


  const [passwordShown, setPasswordShown] = useState(false);
  const phoneRegExp = /^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/

  const togglePassword = (e) => {
    e.preventDefault()
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone_number: '',
      password: '',
      password_confirmation: '',
      user_role: 'admin'

    },
    validationSchema: Yup.object({
      name: Yup.string().min(3, "Must be 3 Characters or more").required("This is Required"),
      email: Yup.string().email("Invalid Email Address").required("This is Required"),
      phone_number: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required("Phone Number is Required"),


      password: Yup.string()
        .required("This is Required")
        .matches(
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/,
          "Must Contain at least 6 Characters, One Uppercase, One Lowercase and One Number"
        ),
      password_confirmation: Yup.string()
        .oneOf([Yup.ref("password"), null], "Password must Match")
        .required("Confirm Password is Required"),

      acceptTerms: Yup.bool().oneOf([true], 'Accept Terms & Conditions is required')

    }),


    onSubmit: (values) => {
      console.log('values', values)
      setLoad(Spin);
      console.log('loadingggg', load)

      axios({
        method: 'post',

        url: `${BASE_URL}/v1/add-admin`,

        data: values,

        headers: {
          'Content-Type': 'application/json',

          Authorization: `Bearer ${userObject?.token}`,

          "Access-Control-Allow-Origin": "*",


        }
      })
        .then(function (response) {
          //handle success

          console.log('response.data.data outside', response.data);

          if (response.data.status === true) {

            // localStorage.setItem("adminToken", JSON.stringify(response.data.data.token))



            const messsage = response.data.message
            navigate('/login')

          }
          else {

            const messsage = response.data.message
            navigate('/login')

          }



        })
        .catch(function (error) {
          //handle error
          console.log("error ", error)

          setLoad('');
        });

    },
  })

  const formikSuperadmin = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone_number: '',
      password: '',
      password_confirmation: '',
      user_role: 'super_admin'


    },
    validationSchema: Yup.object({
      name: Yup.string().min(3, "Must be 3 Characters or more").required("This is Required"),
      email: Yup.string().email("Invalid Email Address").required("This is Required"),
      phone_number: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required("Phone Number is Required"),


      password: Yup.string()
        .required("This is Required")
        .matches(
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/,
          "Must Contain at least 6 Characters, One Uppercase, One Lowercase and One Number"
        ),
      password_confirmation: Yup.string()
        .oneOf([Yup.ref("password"), null], "Password must Match")
        .required("Confirm Password is Required"),

      acceptTerms: Yup.bool().oneOf([true], 'Accept Terms & Conditions is required')

    }),


    onSubmit: (values) => {
      console.log('values', values)
      setLoad(Spin);
      console.log('loadingggg', load)

      axios({
        method: 'post',

        url: `${BASE_URL}/v1/super-admin-signup`,

        data: values,
        config: { headers: { 'Content-Type': 'application/json' } }

      })
        .then(function (response) {
          //handle success



          if (response.data.status === true) {

            localStorage.setItem("userToken", JSON.stringify(response.data.data.token))



            const messsage = response.data.message
            navigate('/login')

          }
          else {

            const messsage = response.data.message
            navigate('/login')

          }



        })
        .catch(function (error) {
          //handle error
          console.log("error ", error.response.data.email)

          setLoad('');
        });

    },
  })


  function handleFilterDate(date) {
    let dateTime = moment(date).format("DD/MM/YYYY")
    console.log('date', dateTime)


  }

  return (

    <>
      <div style={{ position: 'relative' }}>



        {/* register superadmin pop up */}
        <Transition appear show={registerSuperadminOpen} as={Fragment}>
          <Dialog
            as="div"
            className="w-full md:w-11/12  fixed flex justify-center items-center px-6 inset-0 z-10 overflow-y-auto"
            onClose={closeModal}
          >
            <div className="w-full md:w-full min-h-screen px-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-25 bg-white"
                leave="ease-in duration-200"
                leaveFrom="opacity-25"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="w-full md:w-9/12 inline-block  max-w-md  overflow-hidden text-left align-middle transition-all transform shadow-xl rounded-2xl">

                  <div className="bg-white px-12 md:p-6 md:px-16 w-full  h-auto rounded-lg">
                    <form onSubmit={formikSuperadmin.handleSubmit} className='flex flex-col items-center justify-center mt-12  relative'>
                      <img src={user_icon} className="md:w-20  w-16 absolute md:-top-12 -top-9 left-50" alt="" />

                      <input
                        type="text"
                        placeholder='Your Name:'
                        className='md:text-sm text-xs p-3 w-full border border-gray-500 rounded-lg my-4 placeholder:text-xs placeholder:text-gray-700'
                        name="name"
                        id="name"
                        onBlur={formikSuperadmin.handleBlur}
                        onChange={formikSuperadmin.handleChange}
                        value={formikSuperadmin.values.name}
                      />

                      {formikSuperadmin.touched.name && formikSuperadmin.errors.name ? <p className='text-red-600 text-left text-xs w-full -mt-3 mb-4'>{formikSuperadmin.errors.name}</p> : null}

                      <div className='p-3 w-full bg-white flex gap-1 items-center  justify-center border border-gray-500 rounded-lg mt-2'>
                        <input
                          type="email"
                          placeholder='Your Email:'
                          className='md:text-sm text-xs w-11/12 placeholder:text-xs placeholder:text-gray-700'
                          name="email"
                          id="email"
                          onBlur={formikSuperadmin.handleBlur}
                          onChange={formikSuperadmin.handleChange}
                          value={formikSuperadmin.values.email}
                        />

                        <img src={email} className="w-6" alt="" />
                      </div>

                      {formikSuperadmin.touched.email && formikSuperadmin.errors.email ? <p className='text-red-600 text-left text-xs w-full mt-2 mb-4'>{formikSuperadmin.errors.email}</p> : null}



                      <div className='p-3 w-full bg-white flex gap-1 items-center  justify-center border border-gray-500 rounded-lg mt-6'>
                        <input
                          type="phone"
                          placeholder='Phone Number:'
                          className='md:text-sm text-xs w-11/12 placeholder:text-xs placeholder:text-gray-700'
                          name="phone_number"
                          id="phone_number"
                          onBlur={formikSuperadmin.handleBlur}
                          onChange={formikSuperadmin.handleChange}
                          value={formikSuperadmin.values.phone_number}

                        />

                        <img src={calls} className="w-6" alt="" />
                      </div>
                      {formikSuperadmin.touched.phone_number && formikSuperadmin.errors.phone_number ? <p className='text-red-600 text-left text-xs w-full mt-1 mb-4'>{formikSuperadmin.errors.phone_number}</p> : null}



                      <div className='p-3 w-full bg-white flex gap-1 items-center  justify-center border border-gray-500 rounded-lg mt-6'>
                        <input
                          type={passwordShown ? "text" : "password"}
                          placeholder='Password: A minimum of 8 characters'
                          className='md:text-sm text-xs w-11/12 placeholder:text-xs placeholder:text-gray-700'
                          name="password"
                          id="password"
                          onBlur={formikSuperadmin.handleBlur}
                          onChange={formikSuperadmin.handleChange}
                          value={formikSuperadmin.values.password}


                        />



                        {passwordShown ? (

                          <button onClick={e => togglePassword(e)}><img src={open} className="w-6 h-6" alt="" /></button>

                        ) : (
                          <button onClick={e => togglePassword(e)}><img src={closed} className="w-6 h-6" alt="" /></button>

                        )}


                      </div>
                      {formikSuperadmin.touched.password && formikSuperadmin.errors.password ? <p className='text-red-600 text-left text-xs w-full mt-2 mb-4'>{formikSuperadmin.errors.password}</p> : null}


                      <div className='p-3 w-full bg-white flex gap-1 items-center  justify-center border border-gray-500 rounded-lg mt-6'>
                        <input
                          type={passwordShown ? "text" : "password"}
                          placeholder='Confirm Password'
                          className='md:text-sm text-xs w-11/12 placeholder:text-xs placeholder:text-gray-700'
                          name="password_confirmation"
                          id="password_confirmation"
                          onBlur={formikSuperadmin.handleBlur}
                          onChange={formikSuperadmin.handleChange}
                          value={formikSuperadmin.values.password_confirmation}


                        />



                        {passwordShown ? (

                          <button onClick={e => togglePassword(e)}><img src={open} className="w-6 h-6" alt="" /></button>

                        ) : (
                          <button onClick={e => togglePassword(e)}><img src={closed} className="w-6 h-6" alt="" /></button>

                        )}


                      </div>
                      {formikSuperadmin.touched.password_confirmation && formikSuperadmin.errors.password_confirmation ? <p className='text-red-600 text-left text-xs w-full mt-2 mb-4'>{formikSuperadmin.errors.password_confirmation}</p> : null}




                      {
                        load ? (<div className="bg-amber-500 w-11/12 font-semibold rounded-lg my-4 p-1 flex justify-center items-center "><h4 className='text-black text-sm'>Please Wait!</h4><input type="image" src={Spin} value="Loading" className="w-10" /></div>) :
                          (<button type="submit" className='bg-amber-500 p-3 w-11/12 font-semibold rounded-lg my-4'>Sign-Up</button>)
                      }

                    </form>



                  </div>

                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>


        {/* register admin pop up  */}


        <Transition appear show={registerAdminOpen} as={Fragment}>
          <Dialog
            as="div"
            className="w-full md:w-11/12  fixed flex justify-center items-center px-6 inset-0 z-10 overflow-y-auto"
            onClose={closeModal}
          >
            <div className="w-full md:w-full min-h-screen px-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-25 bg-white"
                leave="ease-in duration-200"
                leaveFrom="opacity-25"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="w-full md:w-9/12 inline-block  max-w-md  overflow-hidden text-left align-middle transition-all transform shadow-xl rounded-2xl">


                  <div className="bg-white px-12 md:p-6 md:px-16 w-full  h-auto rounded-lg">
                    <form onSubmit={formik.handleSubmit} className='flex flex-col items-center justify-center mt-12  relative'>
                      <img src={user_icon} className="md:w-20  w-16 absolute md:-top-12 -top-9 left-50" alt="" />

                      <input
                        type="text"
                        placeholder='Admin Name:'
                        className='md:text-sm text-xs p-3 w-full border border-gray-500 rounded-lg my-4 placeholder:text-xs placeholder:text-gray-700'
                        name="name"
                        id="name"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.name}
                      />

                      {formik.touched.name && formik.errors.name ? <p className='text-red-600 text-left text-xs w-full -mt-3 mb-4'>{formik.errors.name}</p> : null}

                      <div className='p-3 w-full bg-white flex gap-1 items-center  justify-center border border-gray-500 rounded-lg mt-2'>
                        <input
                          type="email"
                          placeholder='Admin Email:'
                          className='md:text-sm text-xs w-11/12 placeholder:text-xs placeholder:text-gray-700'
                          name="email"
                          id="email"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.email}
                        />

                        <img src={email} className="w-6" alt="" />
                      </div>

                      {formik.touched.email && formik.errors.email ? <p className='text-red-600 text-left text-xs w-full mt-2 mb-4'>{formik.errors.email}</p> : null}



                      <div className='p-3 w-full bg-white flex gap-1 items-center  justify-center border border-gray-500 rounded-lg mt-6'>
                        <input
                          type="phone"
                          placeholder='Admin Phone Number:'
                          className='md:text-sm text-xs w-11/12 placeholder:text-xs placeholder:text-gray-700'
                          name="phone_number"
                          id="phone_number"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.phone_number}

                        />

                        <img src={calls} className="w-6" alt="" />
                      </div>
                      {formik.touched.phone_number && formik.errors.phone_number ? <p className='text-red-600 text-left text-xs w-full mt-1 mb-4'>{formik.errors.phone_number}</p> : null}



                      <div className='p-3 w-full bg-white flex gap-1 items-center  justify-center border border-gray-500 rounded-lg mt-6'>
                        <input
                          type={passwordShown ? "text" : "password"}
                          placeholder='Password: A minimum of 8 characters'
                          className='md:text-sm text-xs w-11/12 placeholder:text-xs placeholder:text-gray-700'
                          name="password"
                          id="password"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.password}


                        />



                        {passwordShown ? (

                          <button onClick={e => togglePassword(e)}><img src={open} className="w-6 h-6" alt="" /></button>

                        ) : (
                          <button onClick={e => togglePassword(e)}><img src={closed} className="w-6 h-6" alt="" /></button>

                        )}


                      </div>
                      {formik.touched.password && formik.errors.password ? <p className='text-red-600 text-left text-xs w-full mt-2 mb-4'>{formik.errors.password}</p> : null}


                      <div className='p-3 w-full bg-white flex gap-1 items-center  justify-center border border-gray-500 rounded-lg mt-6'>
                        <input
                          type={passwordShown ? "text" : "password"}
                          placeholder='Confirm Password'
                          className='md:text-sm text-xs w-11/12 placeholder:text-xs placeholder:text-gray-700'
                          name="password_confirmation"
                          id="password_confirmation"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.password_confirmation}


                        />



                        {passwordShown ? (

                          <button onClick={e => togglePassword(e)}><img src={open} className="w-6 h-6" alt="" /></button>

                        ) : (
                          <button onClick={e => togglePassword(e)}><img src={closed} className="w-6 h-6" alt="" /></button>

                        )}


                      </div>
                      {formik.touched.password_confirmation && formik.errors.password_confirmation ? <p className='text-red-600 text-left text-xs w-full mt-2 mb-4'>{formik.errors.password_confirmation}</p> : null}






                      {
                        load ? (<div className="bg-amber-500 w-full font-semibold rounded-lg my-4 p-1 flex justify-center items-center "><h4 className='text-black text-sm'>Please Wait!</h4><input type="image" src={Spin} value="Loading" className="w-10" /></div>) :
                          (<button type="submit" className='bg-amber-500 p-3 w-full font-semibold rounded-lg mt-8 mb-4'>Add Admin</button>)
                      }

                    </form>




                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>

        {/* PROVIDER'S NOTIFICATION MODAL */}
        <div sttle={{ position: "absolute", zIndex: "999" }}>
          <Transition appear show={providerNotfication} as={Fragment}>
            <Dialog
              as="div"
              className="fixed px-6 inset-0 z-[1000] overflow-y-auto"
              onClose={closeModal}
            >
              <div className="min-h-screen px-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-25 bg-white"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-25"
                  leaveTo="opacity-0"
                >
                  <Dialog.Overlay className="fixed inset-0" />
                </Transition.Child>

                {/* This element is to trick the browser into centering the modal contents. */}
                <span
                  className="inline-block h-screen align-middle"
                  aria-hidden="true"
                >
                  &#8203;
                </span>
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <div className="notification-modal-wrapper inline-block w-full max-w-md pt-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-transparent shadow-xl rounded-2xl">
                    <div className="bg-project rounded-2xl p-6">
                      <div className="header flex mb-6">
                        <div className='w-11/12'>
                          <h4 className='text-white text-center font-bold text-2xl'>Notifications</h4>
                        </div>
                        <button className='w-1/12'>
                          <img src={cancelImg} className='w-[30px]' onClick={closeModal} alt="Cancel Modal" />
                        </button>
                      </div>

                      <div className="notification-lists-container overflow-y-scroll h-[450px]">

                        {
                          providerNotificationArray?.map((item) => {
                            return (
                              <div key={item.id} className="notification-box bg-white p-6 rounded-xl mt-4">
                                <div className="flex justify-between mb-4">
                                  <h5 className='font-bold text-lg '>{item?.data?.title}</h5>

                                  <h5 className='font-bold text-lg'>{moment(item?.created_at).format("YYYY/MM/DD")}</h5>
                                </div>


                                <p>
                                  {item?.data?.message}
                                </p>
                              </div>
                            )
                          })
                        }

                      </div>
                    </div>
                  </div>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition>
        </div>





        {/* admin and superadmin notifications */}
        <div style={{ position: "absolute", zIndex: "999" }}>
          <Transition appear show={adminNotificationModal} as={Fragment}>
            <Dialog
              as="div"
              className="fixed px-6 inset-0 z-[1000] overflow-y-auto"
              onClose={closeModal}
            >
              <div className="min-h-screen px-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-25 bg-white"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-25"
                  leaveTo="opacity-0"
                >
                  <Dialog.Overlay className="fixed inset-0" />
                </Transition.Child>

                {/* This element is to trick the browser into centering the modal contents. */}
                <span
                  className="inline-block h-screen align-middle"
                  aria-hidden="true"
                >
                  &#8203;
                </span>
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <div className="notification-modal-wrapper inline-block w-full max-w-md pt-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-transparent shadow-xl rounded-2xl">
                    <div className="bg-project rounded-2xl p-6">
                      <div className="header flex items-center mb-6 w-full justify-between">
                        <div className='w-4/12'>
                          <h4 className='text-white  font-bold text-base'>Notifications</h4>
                        </div>
                        <div className="p-2 px-3 border w-7/12  text-white rounded-md border-white flex">


                          <button
                            className="mr-4 text-xx focus:outline-none"
                            type='button'
                            onClick={() => {
                              setFirstOpen((isOpen) => !isOpen)


                            }}>

                            {notificationStartDate ? setSelectedStartDate : '2 March 2022'}
                          </button>


                          <label htmlFor="" className='mr-4 text-xx'> - </label>

                          <button
                            className="mr-4 text-xx focus:outline-none"
                            type='button'
                            onClick={() => setOpen((isOpen) => !isOpen)}>

                            {notificationEndDate ? setSelectedEndDate : '2 April 2022'}


                          </button>

                          <img src={one} alt="" className="w-5" />



                          <div className="dateee">
                            <DatePicker
                              selected={notificationStartDate}
                              onChange={(date) => {
                                setNotificationStartDate(date)
                                { handleFilterDate(date) }

                                setFirstOpen(false)
                              }}

                              open={firstOpen}

                              style={{ marginRight: "50px" }}



                            />
                          </div>

                          <div className="dateee">
                            <DatePicker
                              selected={notificationEndDate}
                              onChange={(date) => {
                                setNotificationEndDate(date)
                                console.log('setSelectedEndDate', notificationEndDate)

                                setOpen(false)
                              }}

                              open={open}

                              style={{ color: "white" }}



                            />
                          </div>

                        </div>
                        <button className='w-1/12'>
                          <img src={cancelImg} className='w-[30px]' onClick={closeModal} alt="Cancel Modal" />
                        </button>
                      </div>

                      <div className="notification-lists-container overflow-y-scroll h-[450px]">

                        {notificationStartDate && notificationEndDate ?



                          (adminNotifications.filter(obj => (moment(obj.created_at).format("YYYY/MM/DD")) >= (moment(notificationStartDate).format("YYYY/MM/DD")) && (moment(obj.created_at).format("YYYY/MM/DD")) <= (moment(notificationEndDate).format("YYYY/MM/DD"))).map((item, i) => {

                            return (
                              <div key={item.id} className="notification-box bg-white p-6 rounded-xl mt-4">
                                <h5 className='font-bold text-sm'>{moment(item.created_at).format("DD/MM/YYYY")}</h5>

                                <p>
                                  {item.contents}
                                </p>
                              </div>
                            );
                          })) :

                          (adminNotifications.map((item, i) => {

                            return (
                              <div key={item.id} className="notification-box bg-white p-6 rounded-xl mt-4">
                                <h5 className='font-bold text-sm'>{moment(item.created_at).format("DD/MM/YYYY")}</h5>

                                <p>
                                  {item.contents}
                                </p>
                              </div>
                            );
                          }))


                        }
                      </div>
                    </div>
                  </div>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition>
        </div>





        {/* NOTIFICATION MODAL */}
        <div sttle={{ position: "absolute", zIndex: "999" }}>
          <Transition appear show={notificationModal} as={Fragment}>
            <Dialog
              as="div"
              className="fixed px-6 inset-0 z-[1000] overflow-y-auto"
              onClose={closeModal}
            >
              <div className="min-h-screen px-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-25 bg-white"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-25"
                  leaveTo="opacity-0"
                >
                  <Dialog.Overlay className="fixed inset-0" />
                </Transition.Child>

                {/* This element is to trick the browser into centering the modal contents. */}
                <span
                  className="inline-block h-screen align-middle"
                  aria-hidden="true"
                >
                  &#8203;
                </span>
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <div className="notification-modal-wrapper inline-block w-full max-w-md pt-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-transparent shadow-xl rounded-2xl">
                    <div className="bg-project rounded-2xl p-6">
                      <div className="header flex mb-6">
                        <div className='w-11/12'>
                          <h4 className='text-white text-center font-bold text-2xl'>Notifications</h4>
                        </div>
                        <button className='w-1/12'>
                          <img src={cancelImg} className='w-[30px]' onClick={closeModal} alt="Cancel Modal" />
                        </button>
                      </div>

                      <div className="notification-lists-container overflow-y-scroll h-[450px]">
                        {notifyListData?.map((item) => (
                          <div key={item.id} className="notification-box bg-white p-6 rounded-xl mt-4">
                            <h5 className='font-bold text-lg'>{item.data.title}</h5>

                            <p>
                              {item.data.message}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition>
        </div>

        {/* PROVIDER'S NOTIFICATION MODAL */}
        <div sttle={{ position: "absolute", zIndex: "999" }}>
          <Transition appear show={providerNotfication} as={Fragment}>
            <Dialog
              as="div"
              className="fixed px-6 inset-0 z-[1000] overflow-y-auto"
              onClose={closeModal}
            >
              <div className="min-h-screen px-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-25 bg-white"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-25"
                  leaveTo="opacity-0"
                >
                  <Dialog.Overlay className="fixed inset-0" />
                </Transition.Child>

                {/* This element is to trick the browser into centering the modal contents. */}
                <span
                  className="inline-block h-screen align-middle"
                  aria-hidden="true"
                >
                  &#8203;
                </span>
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <div className="notification-modal-wrapper inline-block w-full max-w-md pt-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-transparent shadow-xl rounded-2xl">
                    <div className="bg-project rounded-2xl p-6">
                      <div className="header flex mb-6">
                        <div className='w-11/12'>
                          <h4 className='text-white text-center font-bold text-2xl'>Notifications</h4>
                        </div>
                        <button className='w-1/12'>
                          <img src={cancelImg} className='w-[30px]' onClick={closeModal} alt="Cancel Modal" />
                        </button>
                      </div>

                      <div className="notification-lists-container overflow-y-scroll h-[450px]">
                        {/* {notifyListData.map((item) => ( */}
                        <div className="notification-box bg-white p-6 rounded-xl mt-4">
                          <div className="flex justify-between mb-4">
                            <h5 className='font-bold text-lg '>New Request Note</h5>

                            <h5 className='font-bold text-lg'>3/March/2022</h5>
                          </div>


                          <p>
                            Please make sure you come wearing a white shirt and tie tomorrow.
                          </p>
                        </div>

                        <div className="notification-box bg-white p-6 rounded-xl mt-4">
                          <div className="flex justify-between mb-4">
                            <h5 className='font-bold text-lg'>Reminder - Confirm Now</h5>
                          </div>


                          <p>
                            You’ve got 3 requests pending, and 2 hours left to confirm, an unconfirmed request automatically goes to another service provider.
                          </p>
                        </div>
                        {/* ))} */}
                      </div>
                    </div>
                  </div>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition>
        </div>


        {user === 'customer' ? <nav className="fixed top-0 w-full z-50 bg-white md:h-20">
          <div className="max-w-7xl mx-auto px-6 sm:px-6 lg:px-8">
            <div className="flex items-center justify-between h-16">
              <div className="flex items-center justify-between items-center w-full">
                <div className="flex-shrink-0 md:overflow-hidden md:w-24 w-16 md:mt-4">
                  <NavLink className="" exact to="/">
                    <img
                      className="h-16 md:h-24 md:mr-16"
                      src={logo}
                      alt="Workflow"
                    /></NavLink>


                </div>
                <div className="hidden md:block flex justify-end">
                  <div className="ml-10 flex items-center lg:justify-center lg:space-x-4 lg:mt-4 md:mt-4">

                    <NavLink className="text-gray-700 text-sm font-medium" exact to="/">
                      Home
                    </NavLink>







                    <a href="/#services" className="text-gray-700   px-3 py-2 rounded-md text-sm font-medium"  >
                      Services
                    </a>

                    <a
                      href="/#vehicles"
                      className="text-gray-700   px-3 py-2 rounded-md text-sm font-medium"
                    >
                      Vehicles
                    </a>

                    <a
                      href="/#drivers"
                      className="text-gray-700   px-3 py-2 rounded-md text-sm font-medium"
                    >
                      Drivers
                    </a>

                    <a
                      href="/#promotions"
                      className="text-gray-700   px-3 py-2 rounded-md text-sm font-medium"
                    >
                      Promotions
                    </a>

                    <a
                      href="/#partners"
                      className="text-gray-700   px-3 py-2 rounded-md text-sm font-medium"
                    >
                      Partners
                    </a>

                    <a
                      href="https://blog.roadlers.com" target='_blank'
                      className="text-gray-700   px-3 py-2 rounded-md text-sm font-medium"
                    >
                      Blog
                    </a>

                    <NavLink
                      to="/cart"
                      className="text-gray-700   px-3 py-2 rounded-md text-sm font-medium"
                    >
                      <img src={cart} alt="" />
                    </NavLink>

                    {userLoggedIn ?
                      <div className="flex gap-2">



                        <button onClick={e => handleLogout(e)} className="text-gray-700 border-2 tw-border-gray-400   px-3 py-2 rounded-md text-sm font-medium">
                          Log-Out
                        </button>

                        <NavLink
                          to="/customers-dashboard"
                          className="flex items-center hover:text-white bg-black text-white px-3 py-2 rounded-md text-sm font-medium"
                        >
                          Profile
                        </NavLink>





                        <div className="flex items-center justify-center">

                          {userObject?.role === 'customer' ?
                            (<NavLink
                              to="/customers-dashboard"
                              className="hover-text-white bg-black text-white px-3 py-2 rounded-md text-sm font-medium"
                            >
                              Profile
                            </NavLink>) : (
                              userObject?.role === 'super_admin' ? (
                                <NavLink
                                  to="/superadmin"
                                  className="hover-text-white bg-black text-white px-3 py-2 rounded-md text-sm font-medium"
                                >
                                  Profile
                                </NavLink>
                              ) : (
                                userObject?.role === 'admin' ? (
                                  <NavLink
                                    to="/admin"
                                    className="hover-text-white bg-black text-white px-3 py-2 rounded-md text-sm font-medium"
                                  >
                                    Profile
                                  </NavLink>
                                ) : (
                                  userObject?.role === 'provider' ? (
                                    <NavLink
                                      to="/service-provider-dashboard"
                                      className="hover-text-white bg-black text-white px-3 py-2 rounded-md text-sm font-medium"
                                    >
                                      Profile
                                    </NavLink>) : ''
                                )
                              )
                            )

                          }
                        </div>


                      </div> :
                      <>


                        <NavLink
                          to="/login"
                          className="text-gray-700 border-2 tw-border-gray-400   px-3 py-2 rounded-md text-sm font-medium"
                        >
                          Log-In
                        </NavLink>

                        <NavLink
                          to="/sign-up"
                          className="bg-black text-white px-3 py-2 rounded-md text-sm font-medium"
                        >
                          Sign-Up
                        </NavLink>

                      </>
                    }



                  </div>
                </div>
              </div>

              <div className="-mr-2 flex gap-3 md:hidden  w-full">

                {userLoggedIn ?
                  (<div className="flex gap-3">


                    <NavLink
                      to="/cart"
                      className="text-gray-700 border w-16 text-center font-bold   rounded-md text-xx flex items-center justify-center"
                    >

                      <img src={cart} className='w-[30px]' />
                    </NavLink>

                    <NavLink
                      to="/customers-dashboard"
                      className="flex items-center hover:text-white bg-black text-white px-3 py-2 rounded-md text-sm font-medium"
                    >
                      Profile
                    </NavLink>
                    <div className="flex gap-2">
                      <button onClick={e => handleLogout(e)} className="text-gray-700 border w-16 text-center border-gray-400 font-bold  p-1  rounded-md text-xx flex items-center justify-center">
                        Log-Out
                      </button>


                      <div className="flex items-center justify-center">
                        {userObject?.role === 'customer' ?
                          (<NavLink
                            to="/customers-dashboard"
                            className="hover:text-white bg-black text-white  px-1 py-2 w-16 text-center rounded-md text-xs flex items-center justify-center font-semibold"

                          >
                            Profile
                          </NavLink>) : (
                            userObject?.role === 'super_admin' ? (
                              <NavLink
                                to="/superadmin"
                                className="hover:text-white bg-black text-white  px-1 py-2 w-16 text-center rounded-md text-xs flex items-center justify-center font-semibold"

                              >
                                Profile
                              </NavLink>
                            ) : (
                              userObject?.role === 'admin' ? (
                                <NavLink
                                  to="/admin"
                                  className="hover:text-white bg-black text-white  px-1 py-2 w-16 text-center rounded-md text-xs flex items-center justify-center font-semibold"

                                >
                                  Profile
                                </NavLink>
                              ) : (
                                userObject?.role === 'provider' ? (
                                  <NavLink
                                    to="/service-provider-dashboard"
                                    className="hover:text-white bg-black text-white  px-1 py-2 w-16 text-center rounded-md text-xs flex items-center justify-center font-semibold"

                                  >
                                    Profile
                                  </NavLink>) : ''
                              )
                            )
                          )

                        }


                      </div>

                    </div>
                  </div>) :


                  (<div className="flex gap-3">

                    <NavLink
                      to="/cart"
                    >
                      <img src={cart} alt="" />
                    </NavLink>

                    <NavLink
                      to="/login"
                      className="text-gray-700 border w-16 text-center border-gray-400   p-1  rounded-md text-xx flex items-center justify-center"
                    >
                      Log-In
                    </NavLink>


                    <NavLink
                      to="/sign-up"
                      className="bg-black text-white p-1 w-16 text-center rounded-md text-xx flex items-center justify-center"
                    >
                      Sign-Up
                    </NavLink>
                  </div>)
                }


                <button
                  onClick={() => setIsOpen(!isOpen)}
                  type="button"
                  className="bg-transparent focus:outline:none inline-flex items-center justify-center p-1  text-gray-400 "
                  aria-controls="mobile-menu"
                  aria-expanded="false"
                >
                  <span className="sr-only">Open main menu</span>
                  {!isOpen ? (
                    <svg
                      className="block h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4 6h16M4 12h16M4 18h16"
                      />
                    </svg>
                  ) : (
                    <svg
                      className="block h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  )}
                </button>
              </div>

            </div>
          </div>

          <Transition
            show={isOpen}
            enter="transition ease-out duration-100 transform"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="transition ease-in duration-75 transform"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            {(ref) => (
              <div className="md:hidden bg-black" id="mobile-menu">
                <div ref={ref} className="px-6 py-4 pb-3 space-y-1 sm:px-12 border-t-1 border-red-700">
                  <div className="flex items-center">
                    {/* <img src={dashboard} alt="" className="w-6" /> */}


                    <a
                      href="#"
                      className=" text-gray-300 block px-3 py-2  text-base font-medium"
                    >
                      Home
                    </a>
                  </div>


                  <div className="flex items-center">
                    {/* <img src={payment} alt="" className="w-6" /> */}
                    <a
                      href="/#services"
                      className="text-gray-300  block px-3 py-2  text-base font-medium"
                    >
                      Services
                    </a>
                  </div>


                  <div className="flex items-center">
                    {/* <img src={service_provider} alt="" className="w-6" /> */}
                    <a
                      href="/#vehicles"
                      className="text-gray-300  block px-3 py-2  text-base font-medium"
                    >
                      Vehicles
                    </a>
                  </div>



                  <div className="flex items-center">
                    {/* <img src={price_setting} alt="" className="w-6" /> */}
                    <a
                      href="/#drivers"
                      className="text-gray-300  block px-3 py-2  text-base font-medium"
                    >
                      Drivers
                    </a>
                  </div>


                  <div className="flex items-center">
                    {/* <img src={customer} alt="" className="w-6" /> */}
                    <a
                      href="/#promotions"
                      className="text-gray-300  block px-3 py-2  text-base font-medium"
                    >
                      Promotions
                    </a>
                  </div>


                  <div className="flex items-center">
                    {/* <img src={notifications} alt="" className="w-6" /> */}
                    <a
                      href="/#partners"
                      className="text-gray-300  block px-3 py-2  text-base font-medium"
                    >
                      Partners
                    </a>
                  </div>

                  <div className="flex items-center">
                    {/* <img src={naira} alt="" className="w-6" /> */}
                    <a
                      href="https://blog.roadlers.com" target='_blank'
                      className="text-gray-300  block px-3 py-2  text-base font-medium"
                    >
                      Blog
                    </a>
                  </div>







                </div>
              </div>
            )}
          </Transition>
        </nav> : ''

        }


        {user === 'admin' ? <nav className="fixed z-[200] w-full top-0 bg-white md:h-20">
          <div className="max-w-7xl  mx-auto px-6 sm:px-6 lg:px-8">
            <div className="flex items-center justify-between h-16">
              <div className="flex items-center justify-between items-center w-full">
                <div className="flex-shrink-0 md:overflow-hidden md:w-24 w-16 md:mt-4">
                  <NavLink className="" exact to="/">

                    <img
                      className="h-16 md:h-24 mr-16"
                      src={logo}
                      alt="Workflow"
                    />
                  </NavLink>

                </div>
                <div className="hidden md:block flex justify-end">
                  <div className="ml-10 flex items-center justify-center space-x-4 lg:mt-4">
                    <div className="text-gray-700 text-sm font-medium cursor-pointer relative">
                      <div className={showBadge ? "notifcation-badge w-[15px] h-[15px] rounded-full bg-[#FF3A3A] absolute top-[-2px] right-[15px] z-[10]" : "hidden"}></div>
                      <img src={notification} alt="" className={showBadge ? "animate-bell" : ""} onClick={adminNotify} />
                    </div>


                    <NavLink
                      to="/Admin"
                      className="text-gray-700   px-3 py-2 rounded-md text-sm font-medium"
                    >
                      Admin Dashboard
                    </NavLink>



                    <a
                      href="https://blog.roadlers.com/admin-blog-list" target='_blank'
                      className="text-gray-300  block px-3 py-2  text-base font-medium"
                    >
                      Blog
                    </a>



                    <button
                      onClick={e => handleLogout(e)}

                      className="text-gray-700 border-2 tw-border-gray-400   px-3 py-2 rounded-md text-sm font-medium"
                    >
                      Log-Out
                    </button>

                    {/* <a
              href="#"
              className="bg-black text-white px-3 py-2 rounded-md text-sm font-medium"
            >
              Sign-Up
            </a> */}
                  </div>
                </div>
              </div>

              <div className="-mr-2 flex gap-3 md:hidden w-full">



                <button onClick={e => handleLogout(e)} className="text-gray-700 border w-16 text-center border-gray-400   p-1  rounded-md text-xs flex items-center justify-center">
                  Log-Out
                </button>

                <a
                  href="#"
                  className="bg-black text-white p-1 w-16 text-center rounded-md text-xs flex items-center justify-center"
                >
                  Sign-Up
                </a>


                <button
                  onClick={() => setIsOpen(!isOpen)}
                  type="button"
                  className="bg-transparent focus:outline:none inline-flex items-center justify-center p-1  text-gray-400 "
                  aria-controls="mobile-menu"
                  aria-expanded="false"
                >
                  <span className="sr-only">Open main menu</span>
                  {!isOpen ? (
                    <svg
                      className="block h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4 6h16M4 12h16M4 18h16"
                      />
                    </svg>
                  ) : (
                    <svg
                      className="block h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  )}
                </button>
              </div>

            </div>
          </div>



          <Transition
            show={isOpen}
            enter="transition ease-out duration-100 transform"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="transition ease-in duration-75 transform"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            {(ref) => (
              <div className="md:hidden bg-project" id="mobile-menu">
                <div ref={ref} className="px-6 py-4 pb-3 space-y-1 sm:px-12 border-t-1 border-red-700">
                  <div className="flex items-center">
                    <img src={dashboard} alt="" className="w-6" />


                    <a
                      href="#"
                      className=" text-gray-300 block px-3 py-2  text-base font-medium"
                    >
                      Dashboard
                    </a>
                  </div>


                  <div className="flex items-center">
                    <img src={payment} alt="" className="w-6" />


                    <Link to="/admin#request"
                      className="text-gray-300  block px-3 py-2  text-base font-medium">Request</Link>

                  </div>


                  <div className="flex items-center">
                    <img src={service_provider} alt="" className="w-6" />


                    <Link to="/admin#assets"
                      className="text-gray-300  block px-3 py-2  
           text-base font-medium">Assets</Link>
                  </div>



                  <div className="flex items-center">
                    <img src={service_provider} alt="" className="w-6" />


                    <Link to="/admin#providers"
                      className="text-gray-300  block px-3 py-2  
           text-base font-medium">Service Providers</Link>

                  </div>

                  <div className="flex items-center">
                    <img src={service_provider} alt="" className="w-6" />


                    <a href="https://blog.roadlers.com/admin-blog-list" target='_blank'
                      className="text-gray-300  block px-3 py-2  
           text-base font-medium">Blog</a>

                  </div>

                 


                  <div className="flex items-center">
                    <img src={customer} alt="" className="w-6" />


                    <Link to="/admin#customers"
                      className="text-gray-300  block px-3 py-2  
           text-base font-medium">Customers</Link>
                  </div>


                  <div className="flex items-center">


                    <img src={notifications} alt="" className='w-6' onClick={handleNotificationModal} />

                    <a
                      href="#"
                      onClick={handleNotificationModal}
                      className="text-gray-300  block px-3 py-2  text-base font-medium"
                    >
                      Notifications
                    </a>
                  </div>









                </div>
              </div>
            )}
          </Transition>
        </nav> : ''

        }


        {user === 'super_admin' ? <nav className="fixed z-[200]  w-full top-0 bg-white md:h-20">
          <div className="max-w-7xl  mx-auto px-6 sm:px-6 lg:px-8">
            <div className="flex items-center justify-between h-16">
              <div className="flex items-center justify-between items-center w-full">
                <div className="flex-shrink-0 md:overflow-hidden md:w-24 w-16 md:mt-4">
                  <NavLink className="" exact to="/">

                    <img
                      className="h-16 md:h-24 mr-16"
                      src={logo}
                      alt="Workflow"
                    />
                  </NavLink>

                </div>
                <div className="hidden md:block flex justify-end">
                  <div className="ml-10 flex items-center justify-center space-x-4 lg:mt-4">
                    <div className="text-gray-700 text-sm font-medium cursor-pointer relative">
                      <div className={showBadge ? "notifcation-badge w-[15px] h-[15px] rounded-full bg-[#FF3A3A] absolute top-[-2px] right-[15px] z-[10]" : "hidden"}></div>
                      <img src={notification} alt="" className={showBadge ? "animate-bell" : ""} onClick={adminNotify} />
                    </div>


                    <NavLink
                      to="/superadmin"
                      className="text-gray-700   px-3 py-2 rounded-md text-sm font-medium"
                    >
                      SuperAdmin Dashboard
                    </NavLink>



                    <button
                      onClick={e => handleLogout(e)}

                      className="text-gray-700 border-2 tw-border-gray-400   px-3 py-2 rounded-md text-sm font-medium"
                    >
                      Log-Out
                    </button>

                    {/* <a
              href="#"
              className="bg-black text-white px-3 py-2 rounded-md text-sm font-medium"
            >
              Sign-Up
            </a> */}
                  </div>
                </div>
              </div>

              <div className="-mr-2 flex gap-3 md:hidden w-full">



                <button onClick={e => handleLogout(e)} className="text-gray-700 border w-16 text-center border-gray-400   p-1  rounded-md text-xs flex items-center justify-center">
                  Log-Out
                </button>

                <a
                  href="#"
                  className="bg-black text-white p-1 w-16 text-center rounded-md text-xs flex items-center justify-center"
                >
                  Sign-Up
                </a>


                <button
                  onClick={() => setIsOpen(!isOpen)}
                  type="button"
                  className="bg-transparent focus:outline:none inline-flex items-center justify-center p-1  text-gray-400 "
                  aria-controls="mobile-menu"
                  aria-expanded="false"
                >
                  <span className="sr-only">Open main menu</span>
                  {!isOpen ? (
                    <svg
                      className="block h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4 6h16M4 12h16M4 18h16"
                      />
                    </svg>
                  ) : (
                    <svg
                      className="block h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  )}
                </button>
              </div>

            </div>
          </div>



          <Transition
            show={isOpen}
            enter="transition ease-out duration-100 transform"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="transition ease-in duration-75 transform"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            {(ref) => (
              <div className="md:hidden bg-black" id="mobile-menu">
                <div ref={ref} className="px-6 py-4 pb-3 space-y-1 sm:px-12 border-t-1 border-red-700">
                  <div className="flex items-center">
                    <img src={dashboard} alt="" className="w-6" />


                    <a
                      href="#"
                      className=" text-gray-300 block px-3 py-2  text-base font-medium"
                    >
                      Dashboard
                    </a>
                  </div>


                  <div className="flex items-center">
                    <img src={payment} alt="" className="w-6" />
                    <Link to="/superadmin#payment"
                      className="text-gray-300  block px-3 py-2  
           text-base font-medium">Payment</Link>
                  </div>






                  <div className="flex items-center">
                    <img src={service_provider} alt="" className="w-6" />


                    <Link to="/superadmin#providers"
                      className="text-gray-300  block px-3 py-2  
           text-base font-medium">Service Providers</Link>

                  </div>




                  <div className="flex items-center">
                    <img src={price_setting} alt="" className="w-6" />


                    <Link to="/superadmin#settings"
                      className="text-gray-300  block px-3 py-2  
           text-base font-medium">Price Setting</Link>
                  </div>



                  <div className="flex items-center">
                    <img src={customer} alt="" className="w-6" />


                    <Link to="/superadmin#customers"
                      className="text-gray-300  block px-3 py-2  
           text-base font-medium">Customers</Link>
                  </div>


                  <div className="flex items-center">
                    <img src={notifications} alt="" className='w-6' onClick={handleNotificationModal} />

                    <a
                      href="#"
                      onClick={handleNotificationModal}
                      className="text-gray-300  block px-3 py-2  text-base font-medium"
                    >
                      Notifications
                    </a>
                  </div>


                  <div className="flex items-center">
                    <img src={naira} alt="" className="w-6" />
                    <a
                      href="#"
                      className="text-gray-300  block px-3 py-2  text-base font-medium"
                    >
                      Transaction History
                    </a>
                  </div>


                  <div className="flex items-center">

                    <button
                      onClick={registerAdminModal}

                      className="mt-4 text-black w-8/12 font-bold bg-amber-500 rounded block px-3 py-2  text-base font-medium"
                    >
                      Register Admin
                    </button>
                  </div>

                  <div className="flex items-center ">

                    <button

                      onClick={registerSuperadminModal}

                      className="mt-4 text-black w-8/12 font-bold bg-amber-500 rounded block px-3 py-2  text-base font-medium"
                    >
                      Register Super Admin
                    </button>
                  </div>







                </div>
              </div>
            )}
          </Transition>
        </nav> : ''

        }



        {user === 'login_customer' ? <nav className="top-0 fixed z-[200] w-full bg-white md:h-20">
          <div className="max-w-7xl  mx-auto px-6 sm:px-6 lg:px-8">
            <div className="flex items-center justify-between h-16">
              <div className="flex items-center justify-between items-center w-full">
                <div className="flex-shrink-0 md:overflow-hidden md:w-24 w-16 md:mt-4">
                  <NavLink className="" exact to="/">

                    <img
                      className="h-16 md:h-24 md:mr-16"
                      src={logo}
                      alt="Workflow"
                    />
                  </NavLink>

                </div>
                <div className="hidden md:block flex justify-end">
                  <div className="ml-10 flex items-center justify-center space-x-4 lg:mt-4">
                    <div className="text-gray-700 text-sm font-medium cursor-pointer relative">
                      <div className={showBadge ? "notifcation-badge w-[15px] h-[15px] rounded-full bg-[#FF3A3A] absolute top-[-2px] right-[15px] z-[10]" : "hidden"}></div>
                      <img src={notification} alt="" className={showBadge ? "animate-bell" : ""} onClick={handleNotificationModal} />
                    </div>



                    <NavLink
                      to="/customers-dashboard"
                      className="text-gray-700   px-3 py-2 rounded-md text-sm font-medium"
                    >
                      My Dashboard
                    </NavLink>



                    <NavLink
                      to="/cart"
                      className="text-gray-700   px-3 py-2 rounded-md text-sm font-medium"
                    >
                      <img src={cart} alt="" />
                    </NavLink>


                    <button onClick={e => handleLogout(e)} className="text-gray-700 border-2 tw-border-gray-400   px-3 py-2 rounded-md text-sm font-medium">
                      Log-Out
                    </button>




                    <div className="flex items-center justify-center">

                      <NavLink
                        to="/customers-dashboard"
                        className="hover:text-white  bg-black text-white px-3 py-2 rounded-md text-sm font-medium"
                      >
                        Profile
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>

              <div className="-mr-2 flex gap-2 md:hidden w-full">



                <NavLink
                  to="/cart"
                >
                  <img src={cart} alt="" />
                </NavLink>


                <button onClick={e => handleLogout(e)} className="text-gray-700 border w-16 text-center border-gray-400 p-1 rounded-md text-xx flex items-center justify-center">
                  Logout
                </button>




                <NavLink
                  to="/customers-dashboard"
                  className="hover:text-white bg-black text-white p-1 w-16 text-center rounded-md text-xx flex items-center justify-center"
                >
                  Profile
                </NavLink>


                <button
                  onClick={() => setIsOpen(!isOpen)}
                  type="button"
                  className="bg-transparent focus:outline:none inline-flex items-center justify-center p-1  text-gray-400 "
                  aria-controls="mobile-menu"
                  aria-expanded="false"
                >
                  <span className="sr-only">Open main menu</span>
                  {!isOpen ? (
                    <svg
                      className="block h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4 6h16M4 12h16M4 18h16"
                      />
                    </svg>
                  ) : (
                    <svg
                      className="block h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  )}
                </button>
              </div>

            </div>
          </div>

          <Transition
            show={isOpen}
            enter="transition ease-out duration-100 transform"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="transition ease-in duration-75 transform"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            {(ref) => (
              <div className="md:hidden bg-black" id="mobile-menu">
                <div ref={ref} className="px-6 py-4 pb-3 space-y-1 sm:px-12 border-t-1 border-red-700">



                  <div className="flex items-center">
                    <img src={dashboard} alt="" className="w-6" />


                    <NavLink
                      to="/customers-dashboard"
                      className="text-gray-300  block px-3 py-2  text-base font-medium"
                    >
                      My Dashboard
                    </NavLink>
                  </div>


                  <div className="flex items-center">
                    <img src={cart} alt="" className="w-6" />

                    <NavLink
                      to="/cart"
                      className="text-gray-300  block px-3 py-2  text-base font-medium"
                    >
                      Cart

                    </NavLink>
                  </div>






                  <div className="flex items-center">
                    <img src={user_icon} alt="" className="w-5" />


                    <NavLink
                      to="/user-profile"
                      className=" text-gray-300  block px-3 py-2  text-base font-medium"
                    >
                      My Profile
                    </NavLink>
                  </div>


                  <div className="flex items-center">
                    <img src={payment} alt="" className="w-6" />


                    <NavLink
                      to="/customers-dashboard"
                      className="text-gray-300  block px-3 py-2  text-base font-medium"
                    >
                      My Wallet
                    </NavLink>
                  </div>

                  <div className="flex items-center">
                    <img src={one} alt="" className="w-5" />
                    <a
                      href="/customers-dashboard#bookings"
                      className="text-gray-300  block px-3 py-2  text-base font-medium"
                    >
                      My Bookings
                    </a>
                  </div>

                  <div className="flex items-center">
                    <img src={refs} alt="" className="w-6" />
                    <a
                      href="/customers-dashboard#referral"
                      className="text-gray-300  block px-3 py-2  text-base font-medium"
                    >
                      Referral Bonus
                    </a>
                  </div>

                  <div className="flex items-center">
                    <img src={offers} alt="" className="w-6" />


                    <a
                      href="/customers-dashboard#offers"
                      className="text-gray-300  block px-3 py-2  text-base font-medium"
                    >
                      Special Offers
                    </a>
                  </div>


                  <div className="flex items-center">
                    <img src={naira} alt="" className="w-6" />



                    <NavLink
                      to="/transaction-history"
                      className="text-gray-300  block px-3 py-2  text-base font-medium"
                    >
                      Transaction History
                    </NavLink>
                  </div>


                  <div className="flex items-center">
                    <img src={notifications} alt="" className="w-6" />
                    <a
                      href="#"
                      className="text-gray-300  block px-3 py-2  text-base font-medium"
                    >
                      Notifications
                    </a>
                  </div>




                </div>
              </div>
            )}
          </Transition>
        </nav> : ''

        }




        {user === 'provider' ? <nav className="top-0 fixed z-[200] w-full bg-white md:h-20">
          <div className="max-w-7xl  mx-auto px-6 sm:px-6 lg:px-8">
            <div className="flex items-center justify-between h-16 provider-header-nav-overall">
              <div className="flex items-center justify-between items-center w-full provider-header-nav-suboverall">
                <div className="flex-shrink-0 md:overflow-hidden md:w-24 w-16 md:mt-4">
                  <NavLink className="" exact to="/">

                    <img
                      className="h-16 md:h-24 mr-16"
                      src={logo}
                      alt="Workflow"
                    />
                  </NavLink>

                </div>
                <div className="hidden desktop-service-header md:block flex justify-end">
                  <div className="ml-10 flex items-center justify-center space-x-4 lg:mt-4">
                    <div
                      // href="#"
                      className="text-gray-700 text-sm font-medium cursor-pointer"
                    >
                      <img src={notification} onClick={handleProviderNotificationModal} alt="" />
                    </div>




                    {
                      isUserRegistered == '1' &&
                      <NavLink
                        to="/service-provider-dashboard"
                        className="text-gray-700   px-3 py-2 rounded-md text-sm font-medium"
                      >
                        My Dashboard
                      </NavLink>
                    }





                    {/* <NavLink
              to="/cart"
              className="text-gray-700   px-3 py-2 rounded-md text-sm font-medium"
            >
              <img src={cart} alt="" />
            </NavLink> */}


                    <button onClick={e => handleLogout(e)} className="text-gray-700 border-2 tw-border-gray-400   px-3 py-2 rounded-md text-sm font-medium">
                      Log-Out
                    </button>

                    {
                      (userCategory === 'business' && isUserRegistered == '1') &&
                      <NavLink
                        to="/approved-service-company"
                        className="hover:text-white  bg-black text-white px-3 py-2 rounded-md text-sm font-medium"
                      >
                        Profile
                      </NavLink>
                    }

                    {

                      (userCategory === 'individual' && isUserRegistered == '1') &&
                      <NavLink
                        to="/approved-service"
                        className="hover:text-white  bg-black text-white px-3 py-2 rounded-md text-sm font-medium"
                      >
                        Profile
                      </NavLink>
                    }






                  </div>
                </div>
              </div>

              <div className="-mr-2 flex gap-3 md:hidden w-full mobile-service-header">


                <button onClick={e => handleLogout(e)} className="text-gray-700 border w-16 text-center border-gray-400 p-1 rounded-md text-xs flex items-center justify-center">
                  Logout
                </button>



                {
                  userCategory === 'business' &&
                  <NavLink
                    to="/approved-service-company"
                    className="hover:text-white  bg-black text-white p-1 w-16 text-center rounded-md text-xs flex items-center justify-center"
                  >
                    Profile
                  </NavLink>
                }

                {
                  userCategory === 'individual' &&
                  <NavLink
                    to="/approved-service"
                    className="hover:text-white  bg-black text-white p-1 w-16 text-center rounded-md text-xs flex items-center justify-center"
                  >
                    Profile
                  </NavLink>
                }



                <button
                  onClick={() => setIsOpen(!isOpen)}
                  type="button"
                  className="bg-transparent focus:outline:none inline-flex items-center justify-center p-1  text-gray-400 "
                  aria-controls="mobile-menu"
                  aria-expanded="false"
                >
                  <span className="sr-only">Open main menu</span>
                  {!isOpen ? (
                    <svg
                      className="block h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4 6h16M4 12h16M4 18h16"
                      />
                    </svg>
                  ) : (
                    <svg
                      className="block h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  )}
                </button>
              </div>

            </div>
          </div>

          <Transition
            show={isOpen}
            enter="transition ease-out duration-100 transform"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="transition ease-in duration-75 transform"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            {(ref) => (
              <div className="md:hidden bg-black mobile-menu-onload" id="mobile-menu">
                <div ref={ref} className="px-6 py-4 pb-3 space-y-1 sm:px-12 border-t-1 border-red-700">



                  <div className="flex items-center">
                    <img src={dashboard} alt="" className="w-6" />


                    <NavLink
                      to="/service-provider-dashboard"
                      className="text-gray-300  block px-3 py-2  text-base font-medium"
                    >
                      My Dashboard
                    </NavLink>
                  </div>


                  {/* <div className="flex items-center">
            <img src={cart} alt="" className="w-6" />

            <NavLink
              to="/cart"
              className="text-gray-300  block px-3 py-2  text-base font-medium"
            >
              Cart

            </NavLink>
          </div> */}






                  <div className="flex items-center">
                    <img src={user_icon} alt="" className="w-5" />


                    {
                      userCategory === 'business' &&
                      <NavLink to="/approved-service-company" className='text-gray-300  block px-3 py-2  text-base font-medium' >My Profile</NavLink>
                    }

                    {
                      userCategory === 'individual' &&
                      <NavLink to="/approved-service" className='text-gray-300  block px-3 py-2  text-base font-medium' >My Profile</NavLink>
                    }
                  </div>


                  <div className="flex items-center">
                    <img src={payment} alt="" className="w-6" />


                    <NavLink
                      to="/service-provider-dashboard"
                      className="text-gray-300  block px-3 py-2  text-base font-medium"
                    >
                      My Wallet
                    </NavLink>
                  </div>

                  <div className="flex items-center">
                    <img src={one} alt="" className="w-5" />
                    <HashLink to="/service-provider-dashboard#myRequests" className='text-gray-300  block px-3 py-2  text-base font-medium'>My Request </HashLink>
                  </div>

                  <div className="flex items-center">
                    <img src={refs} alt="" className="w-6" />
                    <HashLink to="/service-provider-dashboard#asset" className='text-gray-300  block px-3 py-2  text-base font-medium'>My Assets</HashLink>
                  </div>

                  <div className="flex items-center">
                    <img src={offers} alt="" className="w-6" />


                    <HashLink to="/service-provider-dashboard#calendar" className='text-gray-300  block px-3 py-2  text-base font-medium'>My Calendar</HashLink>
                  </div>


                  <div className="flex items-center">
                    <img src={naira} alt="" className="w-6" />



                    <NavLink
                      to="/transaction-history"
                      className="text-gray-300  block px-3 py-2  text-base font-medium"
                    >
                      Transaction History
                    </NavLink>
                  </div>


                  <div className="flex items-center">
                    <img src={notifications} alt="" className="w-6" />
                    <div
                      onClick={handleProviderNotificationModal}
                      className="text-gray-300  block px-3 py-2  text-base font-medium"
                    >
                      Notifications
                    </div>
                  </div>




                </div>
              </div>
            )}
          </Transition>
        </nav> : ''

        }





      </div>
    </>

  );
}

export default memo(Header);
