import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import NotifyService from "../services/NotificationService"

const initialState = {}

export const viewNotify = createAsyncThunk(
    "notification/view", 
    async () => {
    const res = await NotifyService.viewNotifications()
    return res.data
})

export const markNotify = createAsyncThunk(
    "notification/mark", 
    async (readValue) => {
    const res = await NotifyService.markNotification(readValue)
    return res.data
})

const notificationSlice = createSlice({
    name: "notification",
    initialState,
    extraReducers: {
      [viewNotify.fulfilled]: (state, action) => {
        state.push(action.payload)
      },
      
      [markNotify.fulfilled]: (state, action) => {
        state.push(action.payload)
      },
    },
  })
  
  const { reducer } = notificationSlice
  export default reducer