import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import TransactionService from "../services/TransactionService"

const initialState = {}

export const getTransHistory = createAsyncThunk(
    "transaction/getHistory",
    async (user_id) => {
        const res = await TransactionService.getHistoryService(user_id)
        return res.data
    }
)

const transactionSlice = createSlice({
    name: "transaction",
    initialState,
    extraReducers: {
      [getTransHistory.fulfilled]: (state, action) => {
        state.push(action.payload)
      },

    },
  })
  
  const { reducer } = transactionSlice
  export default reducer