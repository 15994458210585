import { createSlice } from "@reduxjs/toolkit";
const initialState = ""
const serviceOptionSlice =  createSlice({
    name: "serviceOption",
    initialState,
    reducers: {
        setServiceOption: (state, action) => {
            return {serviceOption: action.payload};
        },
    },
});
const  {reducer, actions} = serviceOptionSlice;
export const { setServiceOption } = actions
export default reducer;