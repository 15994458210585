

import React from 'react'
import { Navigate, Outlet } from 'react-router-dom';
import { useState, useEffect } from "react";
import { useCookies } from 'react-cookie';
import getCookie from '../Components/utils/SecuredTokens';

const ProtectedRoute = ({ auth, component: Component, ...rest }) => {
    const [cookies, setCookie, removeCookie] = useCookies();
 
    // const auth = null; // determine if authorized, from context or however you're doing it
        


        let user = getCookie("user");

        if(user){
            let userObject = JSON.parse(user);
            let userRole = userObject?.user_role
    
            if(userRole==='super_admin'){
                return <Outlet/>
            }
            else{
                return <Navigate to="/login" />
            }
           
        }
        else{
            return <Navigate to="/login" />
    
        }

    // If authorized, return an outlet that will render child elements
    // If not, return element that will navigate to login page
    
}

export default ProtectedRoute
