import React, { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'

import { BASE_URL } from "../utils/constant"


import CartComponent from './CartComponent'
import Header from '../Header'
import RelatedService from '../RelatedService'
import cartwhite from '../../Images/cart-white.svg';
import user_icon from '../../Images/userg.png'
import unsuccessful from '../../Images/unsuccessful.png'
import success from '../../Images/success.png'

import user from '../../Images/userg.png'
import driver_prado from '../../Images/driver3.png'
import prado from '../../Images/Prado.png'
import daily_driver from '../../Images/daily_driver.png'
import CallIcon from '../../Images/call.png'
import TravelRequestComponent from './TravelRequestComponent';
import BookNow from './BookNow';
import car from '../../Images/asset2.png'
import airport from '../../Images/airport.png';
import red_car from '../../Images/corolla-red-car.png';
import axios from "axios";
import { NavLink, useNavigate } from "react-router-dom";
import TopForm from '../TopForm'
import Spin from '../../Images/spinner.gif';
import transfer from '../../Images/transfer.png'
import wallet from '../../Images/wallet.png'
import login from '../../Images/login.png'

import { useDispatch } from 'react-redux';
// import { mealOffers } from '../../slices/specialOffers'
import cancelImg from '../../Images/cancel.svg'
import { mealOffers, ticketOffers } from '../../slices/specialOffers'
import { useContext } from 'react'
// import NotificationContext from '../../Context/NotificationContext'
import { viewNotify } from '../../slices/Notification'
import getCookie from '../utils/SecuredTokens'
import { baseUrl, fetchData } from '../utils/fetchData'





function Cart() {
    // const {iconAlert, handleNotificationAlertIcon} = useContext(NotificationContext)
    function ScrollToTopOnMount() {
        useEffect(() => {
            window.scrollTo(0, 0);
        }, []);

        return null;
    }

    const [assets, setAssets] = useState([]);
    const [result, setResult] = useState(assets);
    let navigate = useNavigate();
    const dispatch = useDispatch()
    const [billingRef, setBillingRef] = useState([]);
    const [totalAmount, setTotalAmount] = useState();
    const [load, setLoad] = useState('')
    const [del, setDel] = useState(false)
    const [mealOfferData, setMealOfferData] = useState([])

    const cart = JSON.parse(localStorage.getItem("cart"))

    const [userCart, setUserCart] = useState(cart)
    console.log(userCart, "User cart")
    const [cartUpdate, setCartUpdate] = useState(false)
    const saved = JSON.parse(localStorage.getItem("likedVehicle"))



    useEffect(() => {
        let subscribed = true;
        if (subscribed) {
            setUserCart(cart)
        }

        return () => {
            subscribed = false;
        }
    }, [cartUpdate])




    // const [token, setToken] = useState('')
    // const [userLoggedIn, setUserLoggedIn] = useState('')

    let userData = getCookie("user");
    let userObject;
    let token;
    let userLoggedIn;
    userData ? userObject = JSON.parse(userData) : userObject = ''
    userData ? token = JSON.parse(userData)?.token : token = ''
    userData ? userLoggedIn = JSON.parse(userData)?.id : userLoggedIn = ''


    console.log(userObject.lastname, 'userObject lssss')



    let [isOpen, setIsOpen] = useState(false)
    let [unsuccessfulOpen, setUnsuccessfulOpen] = useState(false)
    let [successfulOpen, setSuccessfulOpen] = useState(false)
    let [travelNotification, setTravelNotification] = useState(false)

    let [topUpOpen, setTopUpOpen] = useState(false)
    let [loginOpen, setLoginOpen] = useState(false)
    let [travelOpen, setTravelOpen] = useState(false)
    let [transferOpen, setTransferOpen] = useState(false)
    const [couponCodeValue, setCouponCodeValue] = useState('')

    const [bg, setBg] = useState(false)
    const [payOption, setPayOption] = useState(false)
    const [currentNoteLength, setCurrentNoteLength] = useState([])


    let [amount, setAmount] = useState('')



    let sum = 0




    // Get current Notifcation 
    useEffect(() => {
        dispatch(viewNotify()).then(
            (response) => {
                setCurrentNoteLength(response?.payload?.data)
            },
            (error) => {
                const message =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString()
                console.log(message)
            }
        )
    }, [])


    const travelRequest = async (e) => {
        e.preventDefault();
        setLoad(Spin)
        if (userLoggedIn) {
            axios({
                method: 'post',
                url: `${BASE_URL}/v1/add-booking`,
                data: userCart,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    "Access-Control-Allow-Origin": "*",
                }
            })
                .then(function (response) {
                    localStorage.removeItem("cart")
                    console.log('ori e o pe handle booking', response)
                    setTravelNotification(true)

                })

                .catch(function (error) {
                    //handle error
                    console.log("error ", error)
                    setLoad('');
                });
        } else {
            navigate('/login')
        }
    }


    const payWithThirdParty = async (e) => {
        e.preventDefault();
        setLoad(Spin);
        if (userLoggedIn) {
            axios({
                method: 'post',
                url: `${BASE_URL}/v1/add-booking`,
                data: userCart,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    "Access-Control-Allow-Origin": "*",
                }
            })
                .then(function (response) {
                    // setPayOption(true)
                    setLoad(Spin)

                    // remove cart items
                    const respData = response.data.data
                    const billing_ref = respData.billing_reference
                    let total_amount = respData.total_amount.toFixed(2)
                    setBillingRef(billing_ref)
                    setTotalAmount(total_amount)
                    let formDatas = new FormData();
                    formDatas.append('billingReference', billing_ref)
                    // formDatas.append('amount', parseFloat(total_amount - (total_amount * 0.1) ))
                    if (couponCodeValue === ticketData?.coupon_code && ticketData?.status === "ACTIVE") {
                        formDatas.append('amount', parseFloat(total_amount - (total_amount * 0.1)))
                    } else {
                        formDatas.append('amount', parseFloat(total_amount))
                    }
                    formDatas.append('currency', 'NGN')

                    axios({
                        method: 'post',
                        url: `${BASE_URL}/v1/initialize-paystack-transaction`,
                        data: formDatas,

                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                            "Access-Control-Allow-Origin": "*",
                        }
                    })
                        .then(function (response) {
                            localStorage.removeItem("cart")
                            localStorage.removeItem("profile-history")

                            setCartUpdate((prevState) => !prevState)


                            setLoad('');
                            console.log('initialize-paystack-transaction', response.data);
                            const respData = response.data.data
                            const url = respData.authorization_url
                            if (url) {
                                window.open(url, "_self")

                            }
                        })
                        .catch(function (error) {
                            //handle error
                            console.log('yeahhhh', error)


                            setLoad('');
                        });


                })
                .catch(function (error) {
                    //handle error
                    console.log("error ", error)
                    setLoad('');
                    alert(error.response.data.message)
                });



        } else {
            navigate('/login')

        }
    }

    // HANDLE PAY WITH GIFTCARD
    const handlePayViaGiftCard = async (e) => {
        e.preventDefault();

        if (userLoggedIn) {
            await axios({
                method: 'post',
                url: `${BASE_URL}/v1/gift-card-booking`,
                data: userCart,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    "Access-Control-Allow-Origin": "*"
                }
            }).then((response) => {
                // localStorage.removeItem("cart")
                console.log(response)
                setSuccessfulOpen(true)

            }).catch(function (error) {
                //handle error
                console.log(error)
                setLoad('');

                console.log('initialize-paystack-transaction error', error.response.data);

                console.log("error ", error.response.data.message)

                if (error.response.data.message) {
                    setUnsuccessfulOpen(true)
                }
            });
        } else {
            navigate('/login')
        }
    }


    // HANDLE PAY WITH WALLET
    const payWithWallet = async (e) => {
        e.preventDefault();

        setTopUpOpen(false)
        setLoad(Spin);

        if (userLoggedIn) {

            axios({
                method: 'post',
                url: `${BASE_URL}/v1/add-booking`,
                data: userCart,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    "Access-Control-Allow-Origin": "*"
                }
            })
                .then(function (response) {
                    // setPayOption(false) with

                    const respData = response.data.data
                    const billing_ref = respData.billing_reference
                    const total_amount = respData.total_amount

                    setBillingRef(billing_ref)
                    setTotalAmount(total_amount)


                    localStorage.removeItem("profile-history")

                    let formDatas = new FormData();
                    formDatas.append('billingReference', billing_ref)
                    if (couponCodeValue === ticketData?.coupon_code && ticketData?.status === "ACTIVE") {
                        formDatas.append('amount', parseFloat(total_amount - (total_amount * 0.1)))
                    } else {
                        formDatas.append('amount', parseFloat(total_amount))
                    }
                    formDatas.append('currency', 'NGN')


                    axios({
                        method: 'post',
                        url: `${BASE_URL}/v1/pay-with-wallet`,
                        data: formDatas,

                        headers: {
                            'Content-Type': 'application/json',

                            Authorization: `Bearer ${token}`,
                            "Access-Control-Allow-Origin": "*",
                        }
                    })
                        .then(function (response) {
                            setLoad('');
                            console.log(formDatas, 'forrrmmmmm')
                            console.log('receiver_email', userCart.giftcard_receiver_email)

                            console.log('initialize-paystack-transaction', response.data);
                            const respData = response.data.data
                            const url = respData.authorization_url
                            if (url) {
                                window.open(url, "_self")
                            }
                            // handleNotificationAlertIcon(currentNoteLength.length)
                            // dispatch(mealOffers()).then((response) => {
                            //     console.log(response.payload.data.status, 'meal offer')
                            //     setMealOfferData(response.payload.data)
                            //     // localStorage.setItem("mealOffer", JSON.stringify(response.payload.data))

                            //     // const mealRide = JSON.parse(localStorage.getItem("mealOffer"))
                            //     // console.log(mealRide?.status, "let's see")
                            // })
                            localStorage.removeItem("cart")
                            setCartUpdate((prevState) => !prevState)

                            // setSuccessfulOpen(true)
                            navigate('/transaction-success')

                        })
                        .catch(function (error) {
                            //handle error
                            console.log(error)
                            setLoad('');

                            console.log('initialize-paystack-transaction error', error.response.data);

                            console.log("error ", error.response.data.message)

                            if (error.response.data.message) {
                                setUnsuccessfulOpen(true)
                            }

                        });







                })
                .catch(function (error) {
                    //handle error
                    // setLoad('');
                });
        } else {
            navigate('/login')
        }
    }





    function payWithWalletOption(e) {
        e.preventDefault()
        setTopUpOpen(false)
        setBg(true)
        setPayOption(true)
    }

    function openModal() {
        setIsOpen(true)
        setBg(true)
    }


    function closeModal() {
        setIsOpen(false)
        setTransferOpen(false)
        setTopUpOpen(false)
        setLoginOpen(false)
        setTravelOpen(false)
        setBg(false)
        setUnsuccessfulOpen(false)
        setTravelNotification(false)
        setPayOption(false)
        setSuccessfulOpen(false)
    }

    function topUModal(e) {
        e.preventDefault()

        if (userLoggedIn) {
            setTopUpOpen(true)
            setBg(true)
        }
        else {
            // navigate('/login')
            setLoginOpen(true)
            setBg(true)


        }
    }

    function travelModal() {
        if (userLoggedIn) {
            setTravelOpen(true)
            setBg(true)
        }
        else {
            // navigate('/login')
            setLoginOpen(true)
            setBg(true)
        }
    }

    // PAY WITH CRYPTO
    const handlePayWithCrypto = () => {
        const cryptoWalletBalance = JSON.parse(localStorage.getItem("walletBalanceData"))
        const totalDue = sum.toLocaleString('en-US', { maximumFractionDigits: 2 })

        if (cryptoWalletBalance > totalDue) {
            navigate('/transaction-success')
        } else {
            navigate('/transaction-failed')
        }
    }

    const [ticketData, setTicketData] = useState([])

    // CALLING GOLDEN TICKET FUNCTION
    const getGoldenTicket = () => {
        dispatch(ticketOffers()).then((response) => {
            console.log(response?.payload?.data?.coupon_code, 'golden Ticket offer')
            setTicketData(response?.payload?.data)
            console.log(ticketData, 'Ticket Data')
            // localStorage.setItem("goldenTicket", JSON.stringify(response.payload.data))
            // localStorage.setItem("goldenTicketCoupon", JSON.stringify(response.payload.data.coupon_code))
        })
    }

    useEffect(() => {
        getGoldenTicket()
    }, [])

    // SETTING THE COUPON TICKET
    // const couponTicket = JSON.parse(localStorage.getItem("goldenTicket"))



    // Handle DeleteCart Item
    // const handleDelCartItem = (newBookingId) => {
    //     console.log('Cart Item Deleted', +newBookingId, card)
    //     setUserCart(userCart.filter((item) => item.newBookingId !== newBookingId))
    // }



    // const getAssets = async () => {
    //     await axios.get(`${BASE_URL}/v1/list-vehicle`).then((response) => {
    //         const allVehicle = response.data.data;
    //         console.log('my vehicles', allVehicle)
    //         const res = allVehicle.filter(x => saved.some(y => y === x.id));
    //         setAssets(res)
    //     });
    // };
    // useEffect(() => {
    //     getAssets()
    // }, [])



    useEffect(() => {
        let subscribed = true

        const getAssets = async () => {
            let allVehicle = await fetchData(`${baseUrl}/v1/list-vehicle`);
            const res = allVehicle?.data?.filter(x => saved?.some(y => y === x.id));
            if (subscribed) {
                setAssets(res)

            }
        }
        getAssets()

        return () => {
            subscribed = false;
        }
    }, [])
    return (
        <div className={bg === true ? " blur-sm contrast-50 backdrop-brightness-50" : "w-full"}>

            <ScrollToTopOnMount />

            <Transition appear show={successfulOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed flex items-center justify-center w-full px-6 mt-8  inset-0 z-10 overflow-y-auto"
                    onClose={closeModal}
                >
                    <div className="min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="w-10/12 inline-block  max-w-md pt-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-md">
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg font-medium leading-6 text-gray-900"
                                >
                                </Dialog.Title>
                                <div className="bg-white pb-4 pr-4 pt-1 pl-4">
                                    <div className="flex w-full justify-end">
                                        <button className=''>
                                            <img src={cancelImg} className='w-[30px]' onClick={closeModal} alt="Cancel Modal" />
                                        </button>
                                    </div>

                                    <img src={success} alt="" />
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>




            <Transition appear show={unsuccessfulOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed flex items-center justify-center w-full px-6 mt-8  inset-0 z-10 overflow-y-auto"
                    onClose={closeModal}
                >
                    <div className="min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="w-10/12 inline-block  max-w-md pt-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-md">
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg font-medium leading-6 text-gray-900"
                                >
                                </Dialog.Title>

                                <div className="bg-white p-4">
                                    <div className="flex w-full justify-end">
                                        <button className=''>
                                            <img src={cancelImg} className='w-[30px]' onClick={closeModal} alt="Cancel Modal" />
                                        </button>
                                    </div>

                                    <img src={unsuccessful} alt="" />
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>


            <Transition appear show={travelNotification} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed flex items-center justify-center w-full px-6 mt-8  inset-0 z-10 overflow-y-auto"
                    onClose={closeModal}
                >
                    <div className="min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="w-10/12 inline-block  max-w-md pt-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-md">
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg font-medium leading-6 text-gray-900"
                                >
                                </Dialog.Title>



                                <div className="bg-white pb-4 pr-4 pt-1 pl-4">
                                    <div className="flex w-full justify-end">
                                        <button className=''>
                                            <img src={cancelImg} className='w-[30px]' onClick={closeModal} alt="Cancel Modal" />
                                        </button>
                                    </div>

                                    <img src={success} alt="" />
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>

            {
                userLoggedIn ? <>
                    <Header user='login_customer' />
                </> : <Header user='customer' />
            }

            <TopForm component='cart' />

            {/* IF DESTINATION IS NOT OUT OF STATE DISPLAY */}
            {(userCart || []).length === 0 ?
                (<>
                    <h3 className="my-20 text-white text-center text-xl hidden ">Cart Empty</h3>
                </>) :
                (userCart?.filter(obj => !obj.destination.includes("Out of State") && !obj.token_code).reverse().map((cartItem, i) => {
                    //  couponCodeValue === (ticketData?.coupon_code) && ticketData?.status === "ACTIVE" ?  ((sum += Number(cartItem.amount)) - ((sum += Number(cartItem.amount)) * 0.1)) :  (sum += Number(cartItem.amount))

                    (sum += Number(cartItem.amount))

                    return (
                        <div className="" key={cartItem.cart_id}>
                            <CartComponent
                                setCartUpdate={setCartUpdate}
                                newBookingId={cartItem.newBookingId}
                                id={cartItem.id}
                                price={cartItem.amount}
                                total={cartItem.amount}
                                insurance={cartItem.insurance_price}
                                address={cartItem.pickup_location}
                                image={cartItem.book_picture}
                                time={cartItem.pickup_time}
                                monthlyPrice={cartItem.monthly_price}
                                state={cartItem.destination}
                                destination_state={cartItem.destination_state}
                                destination={cartItem.destination}
                                airportPrice={cartItem.airportPrice}
                                days={cartItem.num_of_day}
                                date={cartItem.pickup_date}
                                title={cartItem.booking_title}
                                category={cartItem.category}
                                daily_price={cartItem.daily_price}
                                resumption_date={cartItem.resumption_date}
                                vehicleId={cartItem.vehicle_id}
                                receiverEmail={cartItem.giftcard_receiver_email}
                                receiverName={cartItem.giftcard_receiver_name}
                                numMonths={cartItem.month_value}
                            />
                        </div>
                    )
                }
                ))
            }



            {
                (userCart?.filter(obj => obj.destination.includes("Within State")).length > 0) ? (
                    <>
                        <div className="flex justify-end md:px-20 xl:px-56 lg:px-32 md:px-16 px-6">
                            <a href="tel:2349044442200" className='bg-amber-500 p-1 rounded-md'><img src={CallIcon} alt="" className='md:w-8 w-7' /></a>
                        </div>

                        <div className="flex justify-end xl:px-56 lg:px-32 md:px-16 md:px-20 mt-6 items-center px-6 ">
                            <p className='text-white font-bold md:text-base text-xs md:mr-6 '>Add A Coupon Code:</p>
                            <div className="border border-white md:py-1 py-2 md:px-6  ml-2 pl-4">
                                <input
                                    type='text'
                                    className='text-white font-bold md:text-lg bg-transparent text-white text-xs'
                                    placeholder='XXXXXX'
                                    value={couponCodeValue}
                                    onChange={e => setCouponCodeValue(e.target.value)}
                                />
                            </div>
                        </div>

                    </>
                ) : (
                    ''

                )
            }



            <div className='w-full '>


                <Transition appear show={topUpOpen} as={Fragment}>
                    <Dialog
                        as="div"
                        className="fixed flex items-center justify-center w-full px-6 mt-8  inset-0 z-10 overflow-y-auto"
                        onClose={closeModal}
                    >
                        <div className="min-h-screen px-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Dialog.Overlay className="fixed inset-0" />
                            </Transition.Child>

                            {/* This element is to trick the browser into centering the modal contents. */}
                            <span
                                className="inline-block h-screen align-middle"
                                aria-hidden="true"
                            >
                                &#8203;
                            </span>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <div className="w-10/12 inline-block  max-w-md pt-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-md">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 text-gray-900"
                                    >
                                        <img src={transfer} alt="" />
                                    </Dialog.Title>

                                    <div className="bg-project p-6">

                                        <form>


                                            <div className="mt-2">


                                                <div className="flex items-center text-sm mt-1 p-2 w-full rounded-md border border-white  text-white">

                                                    <input
                                                        type="text"
                                                        placeholder='Amount'
                                                        className='p-2 md:text-sm text-xs w-full bg-transparent placeholder:text-xs   focus:outline-none text-white'
                                                        disabled
                                                        hidden
                                                        value={couponCodeValue == (ticketData?.coupon_code) && ticketData?.status === "ACTIVE" ? (sum - (sum * 0.1)) : sum}
                                                        onChange={e => setAmount(e.target.value)}
                                                    />

                                                    {/* CONDITION FOR GIVING 10% DISCOUNT */}
                                                    &#8358;
                                                    {couponCodeValue === (ticketData?.coupon_code) && ticketData?.status === "ACTIVE" ? (sum - (sum * 0.1)).toLocaleString('en-US', { maximumFractionDigits: 2 }) : sum.toLocaleString('en-US', { maximumFractionDigits: 2 })
                                                    }

                                                </div>
                                            </div>


                                            <div className="mt-4 w-full">



                                                <button
                                                    type="submit"
                                                    className="inline-flex w-full justify-center px-4 py-2 text-sm font-bold text-black bg-amber-500 border border-transparent rounded-md  focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                                                    // onClick={e => payWithWallet(e)}
                                                    onClick={e => payWithWalletOption(e)}
                                                >
                                                    Pay with Wallet
                                                </button>
                                            </div>


                                            <div className="mt-4 w-full">



                                                {load ? (
                                                    <div
                                                        className="inline-flex items-center justify-center w-full justify-center px-4 py-1 text-sm font-bold  bg-white border border-transparent rounded-md  focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"

                                                    >
                                                        <h4 className="text-black text-xs">Please Wait !</h4>
                                                        <input
                                                            type="image"
                                                            src={Spin}
                                                            value="Loading"
                                                            className="w-8"
                                                            alt="loading..."
                                                        />
                                                    </div>
                                                ) :

                                                    (<button
                                                        type="submit"
                                                        className="inline-flex w-full justify-center px-4 py-2 text-sm font-bold  bg-white border border-transparent rounded-md  focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                                                        onClick={e => payWithThirdParty(e)}
                                                    >
                                                        Pay with Bank
                                                    </button>)}
                                            </div>
                                        </form>

                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </Dialog>
                </Transition>
            </div>

            {/* for users that are not logged in */}
            <Transition appear show={loginOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed flex items-center justify-center w-full px-6 mt-8  inset-0 z-10 overflow-y-auto"
                    onClose={closeModal}
                >
                    <div className="min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="md:w-8/12 w-full inline-block  max-w-md pt-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-md">
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg font-medium leading-6 text-gray-900"
                                >
                                    <img src={login} alt="" />
                                </Dialog.Title>

                                <div className="bg-project p-6 flex flex-col gap-8 items-center">

                                    <p className='text-white leading-8 text-center'>
                                        To proceed with your booking, please sign-up or login to your account.
                                    </p>

                                    <NavLink to='/login' className='text-center rounded-md text-black font-bold bg-amber-500 p-2 w-8/12'>Login</NavLink>

                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>




            <div className='w-full '>




                <Transition appear show={travelOpen} as={Fragment}>
                    <Dialog
                        as="div"
                        className="fixed flex items-center justify-center w-full px-6 mt-8  inset-0 z-10 overflow-y-auto"
                        onClose={closeModal}
                    >
                        <div className="min-h-screen px-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Dialog.Overlay className="fixed inset-0" />
                            </Transition.Child>

                            {/* This element is to trick the browser into centering the modal contents. */}
                            <span
                                className="inline-block h-screen align-middle"
                                aria-hidden="true"
                            >
                                &#8203;
                            </span>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <div className="xl:w-9/12 lg:w-8/12 md:w-9/12 w-full inline-block  max-w-md pt-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-md">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 text-gray-900"
                                    >
                                        <img src={transfer} alt="" />
                                    </Dialog.Title>

                                    <div className="bg-project border border-gray-600 p-6">

                                        <form>


                                            <div className="mt-2 ">
                                                <p className='text-white text-sm'>
                                                    Once you complete your request, our logistics officer will work on your request and contact you shortly via mail and call.

                                                </p>

                                                <p className='mt-6 text-white text-sm'>
                                                    After which, the price to your travel destination will reflect in your cart for payment. Thank you for choosing Roadlers.

                                                </p>

                                                {
                                                    load === '' ?
                                                        <button
                                                            type="submit"
                                                            className="mt-4 inline-flex w-full justify-center px-4 py-2 text-sm font-bold text-black bg-amber-500 border border-transparent rounded-md  focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                                                            onClick={e => travelRequest(e)}
                                                        >
                                                            Complete Request
                                                        </button> :
                                                        <button
                                                            type="submit"
                                                            className="text-gray-500 mt-4 inline-flex w-full justify-center px-4 py-2 text-sm font-bold text-black bg-black cursor-not-allowed border border-transparent rounded-md  focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"

                                                        >
                                                            Please Wait...
                                                        </button>}
                                            </div>
                                        </form>

                                    </div>
                                </div>
                            </Transition.Child>
                        </div>
                    </Dialog>
                </Transition>
            </div>


            <Transition appear show={payOption} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed flex items-center justify-center w-full px-6 mt-8  inset-0 z-10 overflow-y-auto"
                    onClose={closeModal}
                >
                    <div className="min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="w-10/12 inline-block  max-w-md pt-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-md">
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg font-medium leading-6 text-gray-900"
                                >
                                    <img src={wallet} alt="" />
                                </Dialog.Title>

                                <div className="bg-project border border-gray-600 p-6">
                                    <form>
                                        <div className="mt-4 w-full">
                                            <button
                                                type="submit"
                                                className="inline-flex w-full justify-center px-4 py-2 text-sm font-bold text-black bg-white border border-transparent rounded-md  focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                                                onClick={e => payWithWallet(e)}
                                            >
                                                From Naira Wallet
                                            </button>
                                        </div>

                                        <div className="mt-4 w-full">
                                            <button
                                                type="submit"
                                                className="inline-flex w-full justify-center px-4 py-2 text-sm font-bold text-black bg-amber-500 border border-transparent rounded-md  focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                                                onClick={handlePayWithCrypto}
                                            >
                                                From Crypto Wallet
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>

            {userCart?.filter(obj => !obj.destination.includes("Out of State") && !obj.token_code).length > 0 ? (
                <div className="flex justify-end xl:px-56 lg:px-32 md:px-16 mt-6 items-center mb-4 px-6 mb-40">
                    <p className='text-white font-bold md:text-base text-xs mr-6'>Total:</p>
                    <div className="rounded-md md:px-5 px-6 py-2 border border-amber-500 md:mr-6 mr-2"><h4 className='text-amber-500 text-xs md:text-base'>
                        &#8358;{couponCodeValue === (ticketData?.coupon_code) && ticketData?.status === "ACTIVE" ? (sum - (sum * 0.1)).toLocaleString('en-US', { maximumFractionDigits: 2 }) : (sum)?.toLocaleString('en-US', { maximumFractionDigits: 2 })}
                    </h4></div>



                    <button
                        onClick={topUModal}
                        type='submit'
                        className='bg-white text-black py-2 md:px-5 px-2 rounded-md font-bold text-xs md:text-base'>Complete Booking</button>

                </div>) : ('')
            }

            {/* userCart?.filter(e => e.destination === 'Out of State' && !e.token_code).length === 0 ? */}

            {
                userCart?.filter(e => e.destination.includes("Out of State")).length < 1 ?
                    ('') :
                    (
                        <>
                            {/* mobile mode*/}
                            <div className=" mt-28 mb-8 md:hidden px-6">
                                <h5 className='md:font-bold  md:text-xl font-semibold text-sm text-white '>Travel Requests</h5>
                                <hr className='md:w-28 w-12 md:mt-2 marg border-t border-white mb-4' />
                            </div>

                            {/* IF DESTINATION IS OUT OF STATE SHOW */}

                            {/* Destop mode*/}
                            <div className="md:mt-40 mt-16 mb-8 xl:px-56 lg:px-36 md:px-12 px-6 md:block hidden">
                                <h5 className='md:font-bold  md:text-xl font-semibold text-sm text-white '>Travel Requests</h5>
                                <hr className='md:w-28 w-12 md:mt-2 marg border-t border-white mb-4' />
                            </div>
                        </>
                    )
            }



            {
                (userCart?.filter(obj => obj.destination.includes("Out of State") && !obj.token_code).reverse().map((cartItem, i) => {
                    sum += Number(cartItem.amount)

                    return (
                        <div className="" key={cartItem.cart_id}>
                            <TravelRequestComponent
                                id={cartItem.id}
                                setCartUpdate={setCartUpdate}
                                name={cartItem.booking_title}
                                monthlyPrice={cartItem.monthly_price}
                                price='₦0.00'
                                total='₦0.00'
                                date={cartItem.pickup_date}
                                destination_state={cartItem.destination_state}
                                address={cartItem.pickup_location}
                                image={cartItem.book_picture}
                                time={cartItem.pickup_time}
                                state={cartItem.destination}
                                days={cartItem.num_of_day}
                                vehicleId={cartItem.vehicle_id}
                                resumption_date={cartItem.resumption_date}
                                numMonths={cartItem.month_value}
                            />
                        </div>
                    )
                }
                ))
            }


            {userCart?.filter(e => e.destination === 'Out of State' && !e.token_code).length > 0 ? (
                <div className="mt-8 flex justify-end xl:px-56 lg:px-36 md:px-12 px-6 mb-40">
                    <button
                        onClick={travelModal}
                        className='bg-amber-500 text-black py-2 px-5 rounded-md font-bold'>Complete Booking</button>
                </div>
            ) : (
                ''
            )
            }
            {/* mobile mode*/}
            <div className=" mt-10 mb-8 md:hidden px-6">
                <h5 className='md:font-bold  md:text-xl font-semibold text-sm text-white '>Pay Via GiftCard</h5>
                <hr className='md:w-28 w-12 md:mt-2 marg border-t border-white mb-4' />
            </div>

            {/* IF WE ARE USING GIFTCARD TO PAY */}

            {/* Destop mode*/}


            {
                (userCart?.filter(obj => obj.token_code).length < 1) ? ('') : (
                    <div className="md:mt-10 mt-16 mb-8 lg:px-56 md:px-20 px-6 md:block hidden">
                        <h5 className='md:font-bold  md:text-xl font-semibold text-sm text-white '>Pay Via GiftCard</h5>
                        <hr className='md:w-28 w-12 md:mt-2 marg border-t border-white mb-4' />
                    </div>
                )}


            {
                (userCart?.filter(obj => obj.token_code).map((cartItem, i) => {
                    sum += Number(cartItem.amount)

                    return (
                        <div className="" key={cartItem.cart_id}>
                            <CartComponent
                                setCartUpdate={setCartUpdate}
                                newBookingId={cartItem.newBookingId}
                                cartId={cartItem.cart_id}
                                price={0}
                                total={0}
                                address={cartItem.pickup_location}
                                monthlyPrice={cartItem.monthly_price}
                                image={prado}
                                time={cartItem.pickup_time}
                                state={cartItem.destination}
                                destination_state={cartItem.destination_state}
                                days={cartItem.num_of_day}
                                date={cartItem.pickup_date}
                                category={cartItem.category}
                                title={cartItem.booking_title}
                                daily_price={cartItem.daily_price}
                                resumption_date={cartItem.resumption_date}
                                vehicleId={cartItem.vehicle_id}
                                receiverEmail={cartItem.giftcard_receiver_email}
                                receiverName={cartItem.giftcard_receiver_name}
                                numMonths={cartItem.month_value}
                            />
                        </div>
                    )
                }
                ))
            }



            {userCart?.filter(e => e.token_code)?.length > 0 ? (
                <div className="mt-8 flex justify-end md:px-56 px-6">
                    <button
                        onClick={handlePayViaGiftCard}
                        className='bg-amber-500 text-black py-1 px-5 rounded-md font-bold'>Complete Booking</button>
                </div>
            ) : (
                ''
            )
            }





            {
                saved?.length === 0 ? ('') : (
                    <div className="mb-40">
                        <div className="md:mt-40 mt-16 mb-8 xl:px-52 lg:px-24 md:px-12 px-6">
                            <h5 className='md:font-bold  md:text-xl font-semibold text-sm text-white '>Saved Items</h5>
                            <hr className='md:w-28 w-12 md:mt-2 marg border-t border-white mb-4' />
                        </div>

                        <div className="md:grid-cols-4 grid-cols-2 px-6 gap-6 md:gap-12 grid xl:px-52 lg:px-24 md:px-20 my-12">


                            {
                                assets.map((asset, i) => {
                                    return (
                                        <BookNow
                                            key={asset.id}
                                            id={asset.id}
                                            image={asset.image4}
                                            imageThree={asset.image3}
                                            name={asset.vehicle_name}
                                            price={asset.daily_price}
                                            year={asset.year}
                                            comeWithDriver={asset.come_with_driver}
                                            location={asset.state} />
                                    )
                                })
                            }

                        </div>
                    </div>
                )
            }

        </div>
    )
}

export default Cart