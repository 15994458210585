import React, { useState, useEffect, useRef, useCallback } from 'react'
import locate from '../../Images/locate.png'
import prado from '../../Images/Prado.png'
import clock from '../../Images/clock.png'
import one from '../../Images/one.png'
import envelope from '../../Images/envelope.png'
import moment from 'moment';
import { useNavigate } from "react-router-dom";

//from the cart component
function TravelRequestComponent(props) {
    let navigate = useNavigate()

    const {
        name,
        price,
        total,
        date,
        address,
        image,
        time,

        days,
        id,
        state,
        destination_state,
        vehicleId,
        resumption_date,
        setCartUpdate

    } = props

    let cart = JSON.parse(localStorage.getItem("cart"))

    let userLoggedIn = JSON.parse(localStorage.getItem("userId"))

    let [dayChange, setDayChange] = useState(days);
    // let [amountChange, setAmountChange] = useState(amountTotal);
    let [cartState, setCartState] = useState(cart);


    const RemoveItem = (e) => {




        e.preventDefault()


        let localData = localStorage.getItem('cart');
        let newList = JSON.parse(localData)


        let index = newList.findIndex(item => item.id === id)
        newList.splice(index, 1);
        localStorage.setItem('cart', JSON.stringify(newList));
        setCartUpdate((prevState) => !prevState)

        navigate('/cart')




        // if(newArray[index].destination === 'Out of State'){
        //     newArray.splice(index, 1);
        //     localStorage.setItem('cart', JSON.stringify(newArray));
        //    }

    }

    function handleIncrease(e) {
        e.preventDefault()

        setDayChange(++dayChange)

        // setAmountChange(dayChange * dailyPrice)
        let cartData = localStorage.getItem('cart');
        let newList = JSON.parse(cartData)
        console.log(newList)
        let index = newList.findIndex(item => item.id === id)
        console.log(index, 'travel index')

        let newArray = [...newList]

        //  newArray[index].amount = dayChange * dailyPrice

        if (newArray[index].destination === 'Out of State') {
            newArray[index].amount = 0
            newArray[index].num_of_day = dayChange
        }

        setCartState(newArray)
        console.log(newArray, 'cartState')
        localStorage.setItem('cart', JSON.stringify(newArray));
        setCartUpdate((prevState) => !prevState)


    }
    function handleDecrease(e) {
        e.preventDefault()
        if (dayChange === 0) {
            dayChange = 1
            // amountTotal= dailyPrice
        }
        console.log(dayChange, 'dayChange')

        setDayChange(--dayChange)
        // setAmountChange(dayChange * dailyPrice)
        let cartData = localStorage.getItem('cart');
        let newList = JSON.parse(cartData)
        console.log(newList)
        let index = newList.findIndex(item => item.id === id)

        let newArray = [...newList]

        if (dayChange > 0) {
            // mae sure the minimum day is always greater than zero coz of price calculation
            // newArray[index].amount = dayChange * dailyPrice
            newArray[index].num_of_day = dayChange
        }


        setCartState(newArray)
        console.log(newArray, 'cartState')
        localStorage.setItem('cart', JSON.stringify(newArray));
        setCartUpdate((prevState) => !prevState)

    }

    return (






        <>

            <div className='hidden md:block'>



                <div className="xl:px-56 lg:px-36 md:px-12 mb-8">
                    <div className="relative border cart-bg border-white rounded-2xl flex p-6 gap-1">
                        <div className="absolute w-16 text-extrabold text-sm  left-0 bg-amber-500  text-center bottom-12">
                            <h2>Travel</h2>
                        </div>
                        <div className="w-1/4">
                            <h3 className='text-white text-base font-semibold  pb-1 w-5/6'>{name}</h3>
                            <hr className='border-t border-white w-2/4' />
                            <div className="flex items-center  mb-4 -ml-2 mt-4 w-full">
                                <img src={locate} alt="" className='w-6 ' />

                                <p className='text-white text-xs tracking-wider w-3/4 leading-5'>{address} to {destination_state}</p>
                            </div>

                            <div className="flex items-center justify-center bg-white  rounded-lg h-40 w-11/12 overflow-hidden">
                              

                                    <img src={`https://api.roadlers.com/storage/${image}`} alt="" className='object-cover   rounded-lg' />
                                

                            </div>


                        </div>

                        <div className="w-1/4  flex flex-col">
                            <h3 className='tracking-wider text-white text-base font-semibold  pb-1 w-5/6'>Booking Details</h3>
                            <hr className='border-t border-white w-2/4' />


                            <div className="mt-4 flex justify-between px-2 items-center bg-transparent rounded-md border border-white w-8/12 text-center py-2 focus:outline-none text-white">
                                <label className="text-sm bg-transparent w-3/12" >{dayChange < 1 ? 1 : dayChange}</label>
                                <p className='mr-3 text-sm'>{dayChange < 2 ? 'Day' : 'Days'} </p>

                                <div className="flex gap-2">


                                    <button
                                        onClick={handleDecrease}
                                        type="button" className='text-xs font-extrabold text-black bg-yellow-600 px-2 rounded-sm'>
                                        -
                                    </button>

                                    <button
                                        onClick={handleIncrease}
                                        type="button" className='text-xs font-extrabold  text-black bg-yellow-600 px-2 rounded-sm'>
                                        +
                                    </button>
                                </div>
                            </div>


                            <div className="flex items-center gap-2 mt-4">
                                <img src={one} alt="" className='w-6' />
                                <h4 className='text-white font-semibold text-sm tracking-wider'>{moment(date).format("DD/MM/YYYY")}</h4>
                            </div>

                            <div className="flex items-center gap-2 mt-4">
                                <img src={clock} alt="" className='w-8 -ml-1' />
                                <h4 className='tracking-wider text-white font-semibold text-sm'>{time}</h4>
                            </div>

                            <h4 className='tracking-wider text-white font-normal text-sm mt-4'>{state}</h4>

                            <button
                                onClick={RemoveItem}

                                className="bg-transparent border border-amber-500 text-amber-500 rounded-md px-3 py-1 w-24 font-normal text-xs mt-4">Remove</button>


                        </div>

                        <div className="w-1/4  flex flex-col">
                            <h3 className='text-white text-base font-semibold  pb-1 w-5/6'>Price per Day</h3>
                            <hr className='border-t border-white w-2/4' />
                            <p className='text-white mt-8'>{price}</p>

                        </div>

                        <div className="w-1/4  flex flex-col justify-between">
                            <div className="">
                                <h3 className='text-white text-base font-semibold  pb-1 w-5/6'>Sub-total</h3>
                                <hr className='border-t border-white w-2/4' />
                                <p className='text-amber-500 mt-8'>{total}</p>
                            </div>

                            <img src={envelope} alt="" className='w-10' />

                        </div>
                    </div>

                </div>

                {/* <div className="md:px-56 mb-12 flex justify-end">
                    <button
                        className='bg-amber-500 text-black py-1 px-5 rounded-md font-bold'>Complete Booking</button>

                </div> */}
            </div>




            <div className="md:hidden block px-4 my-12">



                <div className="border border-white cart-bg rounded-2xl flex px-4 py-5 gap-1 h-72">

                    <div className="w-5/12 flex flex-col justify-between">
                        <div className="">

                            <h3 className='text-white text-xx font-extrabold  pb-1 w-5/6'>{name} </h3>



                            <hr className='border-t border-white w-2/4' />
                            <div className="flex items-start mb-2 -ml-2 mt-4 w-full">
                                <img src={locate} alt="" className='w-4 ' />


                                <p className='text-white text-xx w-full'>{address} to {destination_state}</p>



                            </div>
                        </div>

                        <div className="flex ">

                            {/* vehicle?( */}
                            <img
                                src={image}
                                alt=""
                                className='w-20' />


                        </div>

                        <button
                            onClick={RemoveItem}
                            className="bg-transparent border border-amber-500 text-amber-500 rounded-md px-3 py-1 w-16 font-normal text-xx mt-4"
                        >
                            Remove
                        </button>

                    </div>

                    <div className="w-5/12  flex flex-col justify-start items-baseline">
                        <h3 className='text-white text-xx font-extrabold  pb-1 w-full'>Booking Details</h3>
                        <hr className='border-t border-white w-2/4' />


                        <div className="mt-10 flex  items-center  text-xx bg-transparent rounded-md border border-white w-full text-center py-2 focus:outline-none text-white">
                            <label className="text-xx bg-transparent w-2/12 ml-1 mr-1 tracking-widest" >{dayChange < 1 ? 1 : dayChange}</label>
                            <p className='text-xx tracking-widest'>{dayChange < 2 ? 'Day' : 'Days'} </p>

                            <div className="flex gap-1 px-2">

                                <button
                                    onClick={handleDecrease}
                                    type="button" className='text-xs font-extrabold text-black bg-yellow-600 px-2 rounded-sm'>
                                    -
                                </button>

                                <button
                                    onClick={handleIncrease}
                                    type="button" className='text-xs font-extrabold  text-black bg-yellow-600 px-2 rounded-sm'>
                                    +
                                </button>
                            </div>
                        </div>


                        <div className="flex items-center gap-2 mt-2">
                            <img src={one} alt="" className='w-4' />
                            <h4 className='text-white font-semibold text-xx tracking-widest'> {moment(date).format("DD/MM/YYYY")}</h4>
                        </div>

                        <div className="flex items-center gap-2 mt-2">
                            <img src={clock} alt="" className='w-4' />
                            <h4 className='text-white font-semibold text-xx tracking-widest'>{time}
                            </h4>
                        </div>

                        <h4 className='text-white font-semibold text-xx tracking-widest mt-2'>{state}</h4>


                        <h3 className='text-white font-semibold text-xx tracking-widest font-extrabold  pb-1 w-full mt-12'>Sub-total</h3>

                    </div>

                    <div className="w-3/12  flex flex-col">
                        <h3 className='text-white text-xx font-extrabold  pb-1 w-full'>Price per Day</h3>
                        <hr className='border-t border-white w-2/4' />

                        <p className='text-white mt-12 font-semibold text-xs tracking-widest font-bold ml-3'>&#8358; 0.00</p>




                        <div className="mt-12">
                            <p className='text-amber-500 text-xs ml-3 font-bold tracking-widest'>
                                &#8358;0.00
                            </p>
                            <p className='text-white tracking-wider text-xx ml-3'>Insurance</p>

                        </div>



                        <p className='text-white mt-12 font-semibold text-xs tracking-widest font-bold ml-3'>&#8358; 0.00</p>


                    </div>



                </div>


            </div>

        </>
    )
}

export default TravelRequestComponent




