import React, { Fragment, useState, useEffect, memo } from 'react'
import { Menu, Dialog, Transition } from '@headlessui/react'
import { BASE_URL } from "../utils/constant"

import axios from 'axios'
import locate from '../../Images/locate.svg'
import clock from '../../Images/clock.png'
import providerImage from '../../Images/providerUser.jpeg'

import prado from '../../Images/Prado.png'
import message from '../../Images/message.svg'
import dashboard from '../../Images/dashboard.png'
import one from '../../Images/one.png'
import promotion from '../../Images/promotion.svg'
import promotions from '../../Images/promotion2.svg'
import call from '../../Images/call.svg'
import car from '../../Images/driver3.png';
import daily_driver from '../../Images/daily_driver.png';
import drivee from '../../Images/drivee.png';
import dailyGiftCard from '../../Images/gift_faint.png'
import travelPop from '../../Images/travelPop.png'
import monthlyGiftCard from '../../Images/gift.png'
import provider4 from '../../Images/provider4.png'
import userdark from '../../Images/userdark.png'
import locationicon from '../../Images/locationicon.png'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { NavLink, useNavigate } from "react-router-dom";
import pen from '../../Images/pen.svg'
import DatePicker from "react-datepicker";

import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { TimePicker } from '@mui/x-date-pickers/TimePicker';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from 'antd';
import CurrencyInput from 'react-currency-input-field';


import moment from 'moment';
import getCookie from '../utils/SecuredTokens'
import { fetchData } from '../utils/fetchData'
import { useCallback } from 'react'

function AdminRequest(props) {
    const format = 'HH:mm';
    const {
        providerPayment,

        travel,
        book_image,
        pDate,
        customerId,
        requestId,
        numOfDays,
        time,
        address,
        category,
        bookingTitle,
        price,
        title,
        receiverEmail,
        preProvider,
        destination,
        destinationState,
        paymentStatus,
        status,
        bookingId,
        vehicleId,
        key_id,
        bookStatus,
        provider_service,
        comeWithDriver,
        userId,
        email,
        pre_driver_uniqid,
        plateNumber

    } = props



    const [travelPrice, setTravelPrice] = useState(0)
    const [password, setPassword] = useState('')
    const [sendTo, setSendTo] = useState(email)

    const [completeRequest, setCompleteRequest] = useState(providerPayment)




    let userData = getCookie("user");
    let userObject = JSON.parse(userData)
    let token = userObject?.token



    let navigate = useNavigate();
    const [startDate, setStartDate] = useState()

    const [open, setOpen] = useState(false);

    const [timeOpen, setTimeOpen] = useState(false);

    const [pickupTime, setPickupTime] = useState(time)


    const changeTime = (e) => {
        console.log(e._d, 'eeeeeeeeeeeeee')
        // setPickupTime(moment(e._d).format("hh:mm a"));

        let formData = new FormData();
        formData.append("pickup_date", pDate);
        formData.append("id", key_id);
        formData.append("pickup_time", moment(e._d).format("hh:mm a"));
        axios({
            method: 'post',
            url: `${BASE_URL}/v1/change-date-time`,
            data: formData,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            }

        })
            .then(function (response) {
                //handle success
                const respData = response.data.message;
                const errors = response.data.errors;
                if (respData) {

                    window.location.reload(true)

                }


            })
            .catch(function (error) {
                //handle error

                const notify = () => { toast(error.response.data.message) };
                notify()


            });



    };

    const TravelRequest = (e) => {
        e.preventDefault()
        let formData = new FormData();
        formData.append("id", key_id);
        formData.append("price", Number(travelPrice));
        formData.append("email", sendTo);
        formData.append("password", password);
        axios({
            method: 'post',
            url: `${BASE_URL}/v1/reply-travel-request`,
            data: formData,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            }
        })
            .then(function (response) {
                //handle success

                setTravelOpen(false)

                if (response.data.data === "Your Password is incorrect") {
                    const notify = () => { toast('Your Password is incorrect') };
                    notify()
                } else {
                    const respData = response.data.message;
                    const notify = () => { toast(respData) };
                    notify()
                }





            })
            .catch(function (error) {
                //handle error

                const notify = () => { toast(error.response.data.message) };
                notify()


            });



    };

    const [phoneNumber, setPhoneNumber] = useState('')
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");



    useEffect(() => {
        let subscribed = true
        const getUserName = async () => {
            let alluserName = await fetchData(`${BASE_URL}/v1/show-usernamess/${userId}`);
            if (subscribed) {
                setPhoneNumber(alluserName?.data?.phone_number)
                const fullname = alluserName?.data?.name;
                const nameSplit = fullname?.split(/(\s+)/);
                const firstName = nameSplit[0];
                const lastName = nameSplit[2];

                setFirstname(firstName);
                setLastname(lastName);
            }
        }
        getUserName()

        return () => {
            subscribed = false;
        }
    }, [])




    function subDays(date, days) {
        let result = new Date(date);
        result.setDate(result.getDate() - days);
        return result;
    }

    function addDays(date, days) {
        let result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }





    let [providerId, setProviderId] = useState('')

    //for plate number
    let [plateId, setPlateId] = useState('')

    let [providerName, setProviderName] = useState('')
    let [providerService, setProviderService] = useState('')
    let [providerState, setProviderState] = useState('')
    let [providerPhone, setProviderPhone] = useState('')
    let [providerIds, setProviderIds] = useState('')

    let [driverId, setDriverId] = useState('')


    let [dueDate, setDueDate] = useState('')
    let [statuses, setStatus] = useState('')


    let [providerAssign, setProviderAsssign] = useState(false)

    let [confirmRequest, setConfirmRequest] = useState('')

    let [providerOpen, setProviderOpen] = useState(false)
    let [travelOpen, setTravelOpen] = useState(false)

    let [unpaidOpen, setUnpaidOpen] = useState(false)


    // for reassignning vehicle
    let [reassignOpen, setReassignOpen] = useState(false)

    // for reassigning drivers
    let [reassignDriverOpen, setReassignDriverOpen] = useState(false)

    // for assigning drivers
    let [assignOpen, setAssignOpen] = useState(false)

    let [bg, setBg] = useState(false)

    let [reassign, setReassign] = useState(false)


    function closeModal() {
        setReassignOpen(false)
        setProviderOpen(false)
        setAssignOpen(false)
        setReassignDriverOpen(false)
        setTravelOpen(false)
        setUnpaidOpen(false)
        setBg(false)
    }
    function reassignModal() {
        setReassignOpen(true)
        setBg(true)

    }

    function travelModal() {
        setTravelOpen(true)
        setBg(true)

    }

    function reassignDriverModal() {
        setReassignDriverOpen(true)
        setBg(true)

    }

    function assignModal() {
        setAssignOpen(true)
        setBg(true)

    }

    function unpaidModal() {
        setUnpaidOpen(true)
        setBg(true)

    }




    let amount = Number(price);


    // let pickupDate = new Date(date);
    // let dueDate = Math.abs(pickupDate - new Date());

    // let duration = moment.duration(dueDate, 'milliseconds');
    // let dayss = Math.round(duration.asDays());


    function handleAssign() {
        let formData = new FormData();
        formData.append("driver_uniqid", driverId);
        formData.append("booking_id", requestId);
        formData.append("user_id", preProvider);


        axios({
            method: 'post',
            url: `${BASE_URL}/v1/assign-request`,
            data: formData,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            }

        })
            .then(function (response) {
                //handle success
                setReassign(!reassign)
                const respData = response.data.data;
                const errors = response.data.errors;

                setAssignOpen(false)

                if (response.data.message === 'Request Assigned successfully') {
                    const notify = () => { toast(response.data.message) };
                    notify()

                    setProviderAsssign(true)
                    setReassignOpen(false)

                } else if (response.data.data === 'This driver is booked already') {
                    const notify = () => { toast(response.data.data) };
                    notify()
                } else if (response.data.data === 'This driver is not Approved') {
                    const notify = () => { toast(response.data.data) };
                    notify()
                }


                // if (respData) {
                //     let notify = () => { toast(response.data.message) };

                //     notify()

                //     // setProviderAsssign(true)
                //     // setReassignOpen(false)

                //     // window.location.reload(true)
                // }


            })
            .catch(function (error) {
                //handle error

                const notify = () => { toast(error.response.data.message) };
                notify()


            });


    }

    function handleReassignDriver() {
        let formData = new FormData();

        formData.append("driver_uniqid", driverId);
        formData.append("booking_id", requestId);
        formData.append("user_id", preProvider);

        formData.append("pre_driver_uniqid", pre_driver_uniqid);

        axios({
            method: 'post',
            url: `${BASE_URL}/v1/reassign-driver-request`,
            data: formData,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            }

        })
            .then(function (response) {
                //handle success
                setReassign(!reassign)
                const respData = response.data.data;
                const errors = response.data.errors;


                if (response.data.message === 'Request Assigned successfully') {
                    const notify = () => { toast(response.data.message) };
                    notify()

                    setProviderAsssign(true)
                    setReassignOpen(false)

                } else if (response.data.data === 'This driver is booked already') {
                    const notify = () => { toast(response.data.data) };
                    notify()
                } else if (response.data.data === 'This driver is not Approved') {
                    const notify = () => { toast(response.data.data) };
                    notify()
                }




            })
            .catch(function (error) {
                //handle error

                const notify = () => { toast(error.response.data.message) };
                notify()


            });


    }


    function handleReassignVehicle() {
        let formData = new FormData();
        formData.append("pre_plate_number", plateNumber);
        formData.append("booking_id", requestId);
        formData.append("plate_number", plateId);
        axios({
            method: 'post',
            url: `${BASE_URL}/v1/reassign-request`,
            data: formData,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            }

        })
            .then(function (response) {
                //handle success
                setReassign(!reassign)
                const respData = response.data.data;
                const errors = response.data.errors;
                if (respData) {
                    let notify = () => { toast(respData) };
                    notify()
                    setProviderAsssign(true)
                    setReassignOpen(false)

                    // window.location.reload(true)
                }


            })
            .catch(function (error) {
                //handle error

                const notify = () => { toast(error.response.data.message) };
                notify()


            });


    }





    function providerModal() {
        setProviderOpen(true)
        setBg(true)

    }



    useEffect(() => {
        let subscribed = true
        const getDue = async () => {
            let date = await fetchData(`${BASE_URL}/v1/booking-due-date/${key_id}`);
            if (subscribed) {
                setDueDate(date?.data)
            }
        }
        getDue()

        return () => {
            subscribed = false;
        }
    }, [])


    const getStatus = useCallback(async () => {
        let book_status = await fetchData(`${BASE_URL}/v1/book-status/${key_id}`);
        setStatus(book_status?.data)
    }, [])

    useEffect(() => {
        getStatus()

        return null
    }, [])



    const getProvider = useCallback(async () => {
        let provider = await fetchData(`${BASE_URL}/v1/search-providerby-id/${preProvider}`);
   

            setProviderName(provider?.data[0]?.name)
            setProviderState(provider?.data[0]?.state)
            setProviderService(provider?.data[0]?.service)
            setProviderPhone(provider?.data[0]?.phone_number)
            setProviderIds(provider?.data[0]?.user_id)
        
    },[])

    useEffect(() => {
        let subscribed = true
       
        getProvider()

        return () => {
            subscribed = false;
        }
    }, [reassign])







    function handleCompleteRequest(e) {
        e.preventDefault()
        axios({
            method: 'post',
            url: `${BASE_URL}/v1/admin-confirm-request/${bookingId}`,
            // data: formData,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            }
        })
            .then(function (response) {
                //handle success
                setCompleteRequest('Paid')
                const respData = response.data.data;
                const errors = response.data.errors;

            })
            .catch(function (error) {
                //handle error

                const notify = () => { toast(error.response.data.message) };
                notify()


            });


    }




    return (



        <div>
            <ToastContainer />
            <Transition appear show={reassignDriverOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="w-full md:w-11/12  fixed flex justify-center items-center px-6 inset-0 z-10 overflow-y-auto"
                    onClose={closeModal}
                >
                    <div className="w-full xl:w-5/12 lg:w-6/12 md:w-7/12  min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-25 bg-white"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-25"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="w-full md:w-8/12 inline-block  max-w-md  overflow-hidden text-left align-middle transition-all transform shadow-xl rounded-2xl">


                                <div className="bg-black p-10">


                                    <form action="" className="flex flex-col items-center gap-4 ">
                                        <input
                                            type="text"
                                            value={driverId}
                                            onChange={e => setDriverId(e.target.value)}
                                            placeholder="Driver's ID"
                                            className='w-full text-white p-2 bg-transparent border border-white rounded-md' />
                                        <button

                                            type="button"
                                            onClick={handleReassignDriver}

                                            className='rounded-md w-10/12 text-center mt-4 py-2 px-2 text-black text-xs font-bold bg-amber-500'>

                                            Re-assign Driver</button>
                                    </form>





                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>



            <Transition appear show={reassignOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="w-full md:w-11/12  fixed flex justify-center items-center px-6 inset-0 z-10 overflow-y-auto"
                    onClose={closeModal}
                >
                    <div className="w-full xl:w-5/12 lg:w-6/12 md:w-7/12  min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-25 bg-white"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-25"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="w-full md:w-8/12 inline-block  max-w-md  overflow-hidden text-left align-middle transition-all transform shadow-xl rounded-2xl">


                                <div className="bg-black p-10">


                                    <form action="" className="flex flex-col items-center gap-4 ">
                                        <input
                                            type="text"
                                            value={plateId}
                                            onChange={e => setPlateId(e.target.value)}
                                            placeholder="Plate Number"
                                            className='w-full text-white p-2 bg-transparent border border-white rounded-md' />
                                        <button

                                            type="button"
                                            onClick={handleReassignVehicle}

                                            className='rounded-md w-10/12 text-center mt-4 py-2 px-2 text-black text-xs font-bold bg-amber-500'>

                                            Re-assign Vehicle</button>
                                    </form>





                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>




            <Transition appear show={assignOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="w-full md:w-11/12  fixed flex justify-center items-center px-6 inset-0 z-10 overflow-y-auto"
                    onClose={closeModal}
                >
                    <div className="w-full xl:w-5/12 lg:w-6/12 md:w-7/12 min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-25 bg-white"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-25"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="w-full md:w-8/12 inline-block  max-w-md  overflow-hidden text-left align-middle transition-all transform shadow-xl rounded-2xl">


                                <div className="bg-black p-10">


                                    <form action="" className="flex flex-col items-center gap-4 ">
                                        <input
                                            type="text"
                                            value={driverId}
                                            onChange={e => setDriverId(e.target.value)}
                                            placeholder="Driver's ID"
                                            className='w-full text-white p-2 bg-transparent border border-white rounded-md' />
                                        <button

                                            type="button"
                                            onClick={handleAssign}

                                            className='rounded-md w-10/12 text-center mt-4 py-2 px-2 text-black text-xs font-bold bg-amber-500'>

                                            Assign Driver</button>
                                    </form>





                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>


            <Transition appear show={providerOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="w-full md:w-11/12  fixed flex justify-center items-center px-6 inset-0 z-10 overflow-y-auto"
                    onClose={closeModal}
                >
                    <div className="w-full xl:w-5/12 lg:w-6/12 md:w-7/12 min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-25 bg-white"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-25"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="w-full md:w-6/12 inline-block  max-w-md  overflow-hidden text-left align-middle transition-all transform shadow-xl rounded-2xl">


                                <div className="bg-white p-6">
                                    <div className="flex flex-col gap-4 ">
                                        <div className="flex flex-col gap-4">
                                            <div className='flex items-center pt-4 justify-center h-28 overflow-hidden border border-gray-400 rounded-lg'>
                                            <img src={providerImage} alt="" className='w-9/12' />

                                            </div>

                                            <h4 className='text-black font-bold'>
                                                {providerName}

                                            </h4>

                                            <div className="flex justify-between ">

                                                {providerService === 'vehicle_driver' ?

                                                    <h4 className='text-black'>
                                                        Driver & Car

                                                    </h4> : ''


                                                }

                                                {providerService === 'driver' ?
                                                    <h4 className='text-black'>
                                                        Driver Only

                                                    </h4> : ''

                                                }

                                                {providerService === 'vehicle' ?
                                                    <h4 className='text-black'>
                                                        Car Owner

                                                    </h4> : ''

                                                }


                                                <div className="flex gap-1 items-center">
                                                    <img src={locationicon} alt="" className='w-7' />
                                                    <h4 className='text-black'>
                                                        {providerState}

                                                    </h4>

                                                </div>

                                            </div>


                                            <div className="flex justify-between w-9/12">


                                                <div className="bg-amber-500 border border-black px-6 text-black rounded-md text-center  justify-center items-center flex">
                                                    <a href={`tel: ${providerPhone}`}

                                                    >Call</a>
                                                </div>
                                                <img src={userdark} alt="" className='w-7 h-7' />


                                            </div>

                                            <p className='font-bold text-sm'>
                                                Provider’s ID: {providerIds}
                                            </p>


                                        </div>







                                    </div>



                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>


            <Transition appear show={travelOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="w-full fixed px-6 inset-0 z-10 flex justify-center items-center"
                    onClose={closeModal}
                >
                    <div className="w-full xl:w-3/12 lg:w-4/12 md:w-5/12 min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-25 bg-white"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-25"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="w-full inline-block max-w-md pt-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg font-medium leading-6 text-gray-900"
                                >
                                    <img src={travelPop} alt="" />
                                </Dialog.Title>

                                <div className="bg-project p-6">
                                    <form>
                                        <div className="mt-2">
                                            <label htmlFor="" className='text-xx md:text-sm text-white ml-5'>Request Travel Price</label>
                                            <div className="flex items-center text-sm mt-1 px-2 w-full rounded-lg border border-white  text-white">


                                                <CurrencyInput

                                                    placeholder="Type Here..."
                                                    decimalsLimit={2}
                                                    allowNegativeValue={false}
                                                    prefix="₦"
                                                    // type="text"
                                                    className='p-2 md:text-sm text-xs w-full bg-transparent placeholder:text-xs   focus:outline-none text-white'


                                                    value={travelPrice}
                                                    onValueChange={(value) => {
                                                        setTravelPrice(value)
                                                    }}



                                                />
                                            </div>
                                        </div>


                                        <div className="mt-3">
                                            <label htmlFor="wallet" className='text-xx md:text-sm text-white ml-5'>Send To</label>
                                            <div className="flex items-center text-sm mt-1 px-2 w-full rounded-lg border border-white  text-white">
                                                <input
                                                    type="text"
                                                    placeholder='Customer Email'
                                                    className='p-2 md:text-sm text-xs w-full bg-transparent placeholder:text-xs   focus:outline-none text-white'
                                                    value={sendTo}
                                                    // onChange={e => setSendTo(e.target.value)}
                                                    readOnly
                                                    autoComplete='new-password'
                                                />
                                            </div>
                                        </div>


                                        <div className="mt-3">
                                            <label htmlFor="" className='text-xx md:text-sm text-white ml-5'>Your Password</label>
                                            <div className="flex items-center text-sm mt-1 px-2 w-full rounded-lg border border-white  text-white">
                                                <input
                                                    type="password"
                                                    className='p-2 w-full md:text-sm text-xs bg-transparent placeholder:text-xs   focus:outline-none text-white'
                                                    autoComplete='new-password'
                                                    value={password}
                                                    onChange={e => setPassword(e.target.value)}
                                                />
                                            </div>
                                        </div>

                                        <div className="mt-3 w-full flex items-center justify-center">
                                            <button
                                                type="submit"
                                                className="inline-flex justify-center px-8 py-1 text-sm font-bold text-black bg-amber-500 border border-transparent rounded-md  focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                                                onClick={e => TravelRequest(e)}
                                            >
                                                Send
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>


            <Transition appear show={unpaidOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="w-full md:w-11/12  fixed flex justify-center items-center px-6 inset-0 z-10 overflow-y-auto"
                    onClose={closeModal}
                >
                    <div className="w-full md:w-6/12 min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-25 bg-white"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-25"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="w-full md:w-6/12 inline-block  max-w-md  overflow-hidden text-left align-middle transition-all transform shadow-xl rounded-xl">


                                <div className="bg-white p-4 flex flex-col justify-center items-center">
                                    <p className="text-center font-bold">
                                        The user {firstname} {lastname} is yet to pay, click on the send invoice button to attend to the request, once they make payment, you will be able to assign a driver

                                    </p>

                                    <button
                                        onClick={closeModal}
                                        className="font-bold bg-red-500 rounded-md text-white w-10/12 px-3 py-2 mt-6"
                                    >

                                        OK I Understand
                                    </button>

                                </div>




                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>


            <div className="flex md:hidden border rounded-lg p-4 gap-7 mb-12 relative">
                <div className="flex flex-col gap-3 w-7/12">


                    <h4 className='text-white text-sm font-semibold w-full'>{bookingTitle}</h4>





                    <hr className="border-t border-gray-400 w-4/5" />

                    <div className="flex items-start mb-4 -ml-2">
                        <img src={locate} alt="" className='w-5 ' />
                        {destination === "Out of State" ? (
                            <p className='text-white text-xs w-full leading-5'>{address} to {destinationState}</p>

                        ) : (
                            <p className='text-white text-xs w-full leading-5'>{address}</p>

                        )}
                    </div>
                    <div className="flex items-center relative justify-center bg-white p-1 rounded-lg  w-full h-full">
                        {destination === "Out of State" ? <button className='absolute left-0 top-6 bg-amber-500  z-10 font-bold  text-black p-1 py-2 text-xs  w-16'>Travel</button> : ''}


                        <img src={`https://api.roadlers.com/storage/${book_image}`} alt="" className='rounded-lg w-full' />
                    </div>

                    <div className="flex items-center w-full">
                        <img src={message} alt="" className='w-6 -ml-1 mr-2' />

                        <p className=' font-bold theme-color text-xxx'>ID: {bookingId}</p>

                    </div>
                </div>
                <div className="flex flex-col w-5/12 gap-4">

                    <input type="text" value='3 Days'
                        className='text-xs bg-transparent rounded-md border border-white w-full text-center py-1 focus:outline-none text-white' />


                    <div className="flex items-center gap-2 disabled-date">
                        <div className="">
                            <DatePicker
                                selected={startDate}
                                onChange={(date) => {
                                    setStartDate(date)


                                    let formData = new FormData();
                                    formData.append("pickup_date", moment(date).format("YYYY/MM/DD"));
                                    formData.append("id", key_id);
                                    formData.append("pickup_time", time);
                                    axios({
                                        method: 'post',
                                        url: `${BASE_URL}/v1/change-date-time`,
                                        data: formData,
                                        headers: {
                                            'Content-Type': 'application/json',
                                            Authorization: `Bearer ${token}`,
                                            "Access-Control-Allow-Origin": "*",
                                        }

                                    })
                                        .then(function (response) {
                                            //handle success
                                            const respData = response.data.message;
                                            const errors = response.data.errors;
                                            if (respData) {
                                                console.log("respData response ", respData)


                                            }


                                        })
                                        .catch(function (error) {
                                            //handle error
                                            console.log("error ", error.response.data)

                                            const notify = () => { toast(error.response.data.message) };
                                            notify()


                                        });

                                    setOpen((isOpen) => !isOpen)
                                }}

                                excludeDateIntervals={[{ start: subDays(pDate, 0), end: addDays(pDate, Number(numOfDays - 1)) }]}
                                closeOnScroll={true}
                                openToDate={new Date(pDate)}
                                open={open}
                                numberOfMonths={1}

                            />
                        </div>

                        <img
                            onClick={() => setOpen((isOpen) => !isOpen)}

                            src={one} alt="" className='w-4 -ml-2' />



                        {startDate ?
                            <h4 className='text-white font-semibold text-xx tracking-wider'>

                                {moment(startDate).format("DD/MM/YYYY")}



                            </h4> :

                            <h4 className='text-white font-semibold text-xx tracking-wider'>
                                {pDate}


                            </h4>
                        }
                    </div>

                    <div className="flex items-center gap-2 timee relative">
                        <img src={clock} alt="" className='w-6 -ml-1' />
                        <TimePicker
                            // value={pickupTime}
                            value={moment(pickupTime, format)}
                            format={format}
                            use12Hours
                            onChange={(e) => changeTime(e)}


                        />

                        {
                            pickupTime ?
                                <h4 className='text-white font-semibold text-xx'>

                                    {pickupTime}
                                </h4> :
                                <h4 className='text-white font-semibold text-xx'>

                                    {
                                        pickupTime
                                    }
                                </h4>
                        }

                    </div>

                    <h4 className='text-white font-normal text-xx'>{destination}</h4>
                    <div className="flex flex-col items-center justify-start  w-full">


                        <h5 className='font-bold text-amber-500 text-xs text-left'>&#8358;{amount.toLocaleString('en-US', { maximumFractionDigits: 2 })}</h5>

                    </div>


                    <div className="flex flex-col gap-2  items-center w-full">

                        <div className="flex w-full items-center">
                            {destination === "Out of State" ? (
                                <div className="flex gap-2 items-center w-full">

                                    <a href={`tel: ${phoneNumber}`}
                                    >
                                        <img src={call} alt="" className='w-8 h-8' />
                                    </a>

                                    {
                                        paymentStatus === 'UNPAID' ?
                                            (
                                                <button
                                                    onClick={unpaidModal}
                                                    className='bg-transparent border font-bold border-white text-white p-1 py-1 text-xx w-full rounded-md '>Assign</button>
                                            ) : (
                                                <button
                                                    onClick={assignModal}
                                                    className='bg-transparent border font-bold border-white text-white p-1 py-1 text-xx w-full rounded-md '>Assign</button>
                                            )
                                    }

                                </div>) : (


                                <div className="flex gap-2 w-full">
                                    <img
                                        onClick={providerModal}

                                        src={dashboard} alt="" className='cursor-pointer w-6 h-6' />
                                    <button
                                        onClick={reassignModal}

                                        className='bg-transparent border font-bold border-white text-white p-1  text-xxx rounded-md w-full'>Re-Assign</button>
                                </div>

                            )

                            }


                        </div>

                        {
                            bookStatus === 'Pending' ?
                                (<button className='w-full bg-blue-600 font-bold text-white p-2 text-xx rounded-md  mt-3'>{bookStatus}</button>)
                                :
                                bookStatus === 'In Progress' ?
                                    (
                                        <button className='w-full bg-amber-500 font-bold text-black p-2 text-xx rounded-md  mt-3'>{bookStatus}</button>

                                    ) :
                                    bookStatus === 'Closed' ? (
                                        <button className='w-full bg-green-700 font-bold text-white p-2 text-xx rounded-md  mt-3'>{bookStatus}</button>

                                    ) :
                                        (
                                            destination === "Out of State" && paymentStatus === "UNPAID" ? (
                                                <button
                                                    onClick={travelModal}
                                                    className='w-full bg-red-700 font-bold text-white p-2 text-xx rounded-md  mt-3'>Send Invoice</button>

                                            ) : (
                                                <button className='w-full bg-red-700 font-bold text-white p-2 text-xx rounded-md mt-3'>{bookStatus}</button>

                                            )


                                        )

                        }



                        {dueDate === 'Due' ?
                            <h4 className='text-amber-500  text-xx font-bold'>
                                {dueDate}
                            </h4> : <h4 className='text-amber-500  text-xx font-bold'>
                                Due in {dueDate}
                            </h4>
                        }


                    </div>

                </div>
            </div>






            <div className="md:flex cart-bg border hidden rounded-xl p-8 gap-7 mb-12 relative">
                {destination === "Out of State" ? <button className='absolute left-0 top-6 bg-amber-500  z-10 font-bold  text-black p-1 py-2 text-sm  w-20'>Travel</button> : ''}


                <div className="w-4/12">


                    {
                        !receiverEmail ?
                            <>
                                {
                                    vehicleId ?
                                        <div className="flex items-center justify-center bg-white p-1 rounded-xl  w-full h-full">
                                            <img src={`https://api.roadlers.com/storage/${book_image}`} alt="" className='rounded-xl w-full' />
                                        </div> :
                                        <div className="flex  rounded-xl w-full ">
                                            <img src={title === 'Daily Driver' ? daily_driver : drivee} alt="" className='w-full' />
                                        </div>

                                }
                            </>
                            :
                            <div className="flex bg-project p-4 rounded-xl w-full ">
                                <img src={title === 'Daily Driver' ? dailyGiftCard : monthlyGiftCard} alt="" className='w-full' />
                            </div>

                    }

                </div>
                <div className="flex flex-col w-4/10 gap-2">


                    <h4 className='text-white text-sm font-semibold border-b border-white pb-1 w-5/6'>{bookingTitle}</h4>




                    <div className="flex items-start mb-4 -ml-2 mt-2">
                        <img src={locate} alt="" className='w-6 ' />

                        {destination === "Out of State" ? (
                            <p className='text-white text-xs w-3/4 leading-5'>{address} to {destinationState}</p>

                        ) : (

                            <p className='text-white text-xs w-3/4 leading-5'>{address}</p>

                        )}
                    </div>

                    <div className="flex items-center">
                        <img src={message} alt="" className='w-6 -ml-1 mr-2' />

                        <p className='mr-2 font-regular theme-color mr-4 text-xs'>ID: {bookingId}</p>

                    </div>

                </div>
                <div className="flex flex-col gap-4 w-1/5">




                    <label htmlFor=""
                        className='text-sm bg-transparent rounded-md border 
                    border-white w-full text-center py-1 focus:outline-none text-white'>
                        {numOfDays} {numOfDays < 2 ? ' Day' : ' Days'}</label>




                    <div className="flex items-center gap-2 disabled-date">

                        <div className="">
                            <DatePicker
                                selected={startDate}
                                onChange={(date) => {
                                    setStartDate(date)


                                    let formData = new FormData();
                                    formData.append("pickup_date", moment(date).format("YYYY/MM/DD"));
                                    formData.append("id", key_id);
                                    formData.append("pickup_time", time);
                                    axios({
                                        method: 'post',
                                        url: `${BASE_URL}/v1/change-date-time`,
                                        data: formData,
                                        headers: {
                                            'Content-Type': 'application/json',
                                            Authorization: `Bearer ${token}`,
                                            "Access-Control-Allow-Origin": "*",
                                        }

                                    })
                                        .then(function (response) {
                                            //handle success
                                            const respData = response.data.message;
                                            const errors = response.data.errors;
                                            if (respData) {
                                                console.log("respData response ", respData)


                                            }


                                        })
                                        .catch(function (error) {
                                            //handle error
                                            console.log("error ", error.response.data)

                                            const notify = () => { toast(error.response.data.message) };
                                            notify()


                                        });

                                    setOpen((isOpen) => !isOpen)
                                }}

                                excludeDateIntervals={[{ start: subDays(pDate, 0), end: addDays(pDate, Number(numOfDays - 1)) }]}
                                closeOnScroll={true}
                                openToDate={new Date(pDate)}
                                open={open}
                                numberOfMonths={1}

                            />
                        </div>

                        <img
                            onClick={() => setOpen((isOpen) => !isOpen)}

                            src={one} alt="" className='w-5' />



                        {startDate ?
                            <h4 className='text-white font-semibold text-xs tracking-wider'>

                                {moment(startDate).format("DD/MM/YYYY")}



                            </h4> :

                            <h4 className='text-white font-semibold text-xs tracking-wider'>
                                {pDate}


                            </h4>
                        }



                    </div>

                    <div className="flex items-center gap-2 timee relative">

                        <img


                            src={clock} alt="" className='absolute clock w-7 ml-1' />


                        <TimePicker
                            // value={pickupTime}
                            value={moment(pickupTime, format)}
                            format={format}
                            use12Hours
                            onChange={(e) => changeTime(e)}


                        />

                        {
                            pickupTime ?
                                <h4 className='text-white font-semibold text-xs ml-9'>

                                    {pickupTime}
                                </h4> :
                                <h4 className='text-white font-semibold text-xs ml-9'>

                                    {
                                        pickupTime
                                    }
                                </h4>
                        }


                    </div>

                    <h4 className='text-white font-normal text-xs'>{destination}</h4>


                </div>
                <div className="flex flex-col items-center justify-center gap-4  w-1/5">

                    {comeWithDriver === 'no' || comeWithDriver === null ?
                        (

                            paymentStatus === 'UNPAID' ?
                                (
                                    <button
                                        onClick={unpaidModal}
                                        className='bg-red-700  font-bold  text-white p-2  text-xs rounded-lg w-24'>Assign Driver</button>
                                ) : (




                                    pre_driver_uniqid !== null ? (
                                        <button
                                            onClick={reassignDriverModal}

                                            className='bg-red-700  font-bold  text-white p-2  text-xs rounded-lg w-28'>Re-assign Driver</button>
                                    ) : (
                                        <button
                                            onClick={assignModal}

                                            className='bg-red-700  font-bold  text-white p-2  text-xs rounded-lg w-24'>Assign Driver</button>
                                    )

                                )


                        ) :
                        (
                            // paymentStatus === 'UNPAID' ?
                            //     (
                            //         <button
                            //             onClick={unpaidModal}
                            //             className='bg-red-700  font-bold  text-white p-2  text-xs rounded-lg w-28'>Re-assign Driver</button>
                            //     ) : (

                            //         pre_driver_uniqid !== null ? (
                            //             <button
                            //                 onClick={reassignDriverModal}

                            //                 className='bg-red-700  font-bold  text-white p-2  text-xs rounded-lg w-28'>Re-assign Driver</button>
                            //         ) : (
                            //             ''
                            //         )

                            //     )
                            ''
                        )
                    }

                    <h5 className='font-bold text-white'>&#8358;{amount.toLocaleString('en-US', { maximumFractionDigits: 2 })}</h5>
                    {/* <img src={promotion} alt="" className='w-12' /> */}

                </div>


                <div className="flex flex-col justify-center gap-2  items-center w-1/5">


                    {destination === "Out of State" ?
                        <div className="flex gap-3 w-full">
                            <a href={`tel: ${phoneNumber}`}
                            >
                                <img src={call} alt="" className='w-8 h-8' />
                            </a>

                            {
                                paymentStatus === 'UNPAID' ?
                                    (
                                        <button
                                            onClick={unpaidModal}
                                            className='bg-transparent border font-bold border-white text-white p-1 py-1 text-sm rounded-lg w-44'>Re-Assign</button>
                                    ) : (
                                        <button
                                            onClick={reassignModal}

                                            className='bg-transparent border font-bold border-white text-white p-2  text-xs rounded-lg w-44'>Re-Assign</button>
                                    )
                            }

                        </div> :
                        <div className="flex gap-3 w-full">
                            <img
                                onClick={providerModal}

                                src={dashboard} alt="" className='cursor-pointer w-9 h-auto' />
                            <button
                                onClick={reassignModal}

                                className='bg-transparent border font-bold border-white text-white p-2  text-xs rounded-lg w-44'>Re-Assign</button>
                        </div>
                    }








                    {
                        bookStatus === 'Pending' ?
                            (<button className='w-full bg-blue-600 font-bold text-white p-2 text-xs rounded-lg mt-3'>{bookStatus}</button>)
                            :
                            bookStatus === 'In Progress' ?
                                (
                                    <button className='w-full bg-amber-500 font-bold text-black p-2 text-xs rounded-lg mt-3'>{bookStatus}</button>

                                ) :
                                bookStatus === 'Closed' ? (
                                    <button className='w-full bg-green-700 font-bold text-white p-2 text-xs rounded-lg mt-3'>{bookStatus}</button>

                                ) :
                                    (
                                        destination === "Out of State" && paymentStatus === "UNPAID" ? (
                                            <button
                                                onClick={travelModal}
                                                className='w-full bg-red-700 font-bold text-white p-2 text-xs rounded-lg mt-3'>Send Invoice</button>

                                        ) : (
                                            <button className='w-full bg-red-700 font-bold text-white p-2 text-xs rounded-lg mt-3'>{bookStatus}</button>

                                        )


                                    )

                    }




                    {/* <h4 className='text-amber-500 font-semibold'>Due  {duePeriod != 'Tomorrow' ? 'in ' + duePeriod + ' days' : duePeriod} </h4> : */}

                    {dueDate === 'Due' ?
                        <h4 className='text-amber-500  text-sm font-bold'>
                            {dueDate}
                        </h4> : <h4 className='text-amber-500  text-sm font-bold'>
                            Due in {dueDate}
                        </h4>
                    }






                    <h4 className='text-white font-normal text-xs'>Customer ID: {customerId}</h4>
                    {
                        bookStatus === 'Closed' ?
                            (
                                completeRequest === 'Paid' ? (

                                    <button
                                        onClick={handleCompleteRequest}
                                        className='w-full bg-green-700 font-bold text-white p-2 text-xs rounded-lg mt-3'>Request Completed</button>

                                ) : (
                                    <button
                                        onClick={handleCompleteRequest}
                                        className='w-full bg-red-700 font-bold text-white p-2 text-xs rounded-lg mt-3'>Complete Request</button>

                                )
                            ) : (
                                ''
                            )

                    }

                </div>
            </div>

        </div >
    )
}

export default memo(AdminRequest)