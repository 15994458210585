import React, { Fragment, useState,useContext } from 'react'
import { Menu, Dialog, Transition } from '@headlessui/react'
import Header from "../Header"
import google from "../../Images/google.svg"
import Spin from "../../Images/spinner.gif"
import checkMail from '../../Images/checkMail.png'
import { BASE_URL } from "../utils/constant"
import user from "../../Images/userg.png"
import axios from "axios"
import open from "../../Images/blackeye.png"
import closed from "../../Images/closed.svg"
import email from "../../Images/email.svg"
import calls from "../../Images/calls.svg"
import x from '../../Images/x.png'
import gmail from '../../Images/gmail.png'
import outlook from '../../Images/outlook.png'
import yahoo from '../../Images/yahoo.png'
import partnership from '../../Images/partnership.png'
import ref from "../../Images/ref.svg"
import car from "../../Images/carr.png"
import { NavLink, useNavigate } from "react-router-dom"
import { useParams, useLocation } from "react-router-dom"

import { useFormik } from "formik"
import * as Yup from "yup"

// import { useDispatch, useSelector } from "react-redux"

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import CustomerContext from '../../Context/CustomerContext'


const Register = () => {
  const [successful, setSuccessful] = useState(false)
  // console.log(message)
  // const dispatch = useDispatch()
  // useEffect(() => {
  //   dispatch(clearMessage())
  // }, [dispatch])
  let { terms,handleTerms } = useContext(CustomerContext)

  
 
  const [load, setLoad] = useState("")

  const search = useLocation().search
  const code = new URLSearchParams(search).get("referral_code")


  let navigate = useNavigate()

  const emailNotify = () => { toast("The email has already been taken!") };


  const [passwordShown, setPasswordShown] = useState(false)
  const phoneRegExp = /^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/

  let [checkMailOpen, setCheckMailOpen] = useState(false)
  const [bg, setBg] = useState(false)

  function closeModal() {
    setCheckMailOpen(false)
    setBg(false)

  }
  function checkMailModal() {


    setCheckMailOpen(true)
    setBg(true)



  }

  const togglePassword = (e) => {
    e.preventDefault()
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown)
  }



  const handleGoogle = async (e) => {
    e.preventDefault();
    localStorage.setItem("googleClicked", JSON.stringify('true'))

    window.open('https://api.roadlers.com/api/v1/auth/google', "_self")




  }

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone_number: "",
      password: "",
      password_confirmation: "",
      referral: code,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(3, "Must be 3 Characters or more")
        .required("This is Required"),
      email: Yup.string()
        .email("Invalid Email Address")
        .required("This is Required"),
      phone_number: Yup.string()
        .matches(phoneRegExp, "Phone number is not valid")
        .required("Phone Number is Required"),

      password: Yup.string()
      .required("This is Required")
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/,
        "Must Contain at least 6 Characters, One Uppercase, One Lowercase and One Number"
      ),
      password_confirmation: Yup.string()
        .oneOf([Yup.ref("password"), null], "Password must Match")
        .required("Confirm Password is Required"),

      acceptTerms: Yup.bool().oneOf(
        [true],
        "Accept Terms & Conditions is required"
      ),
    }),

    onSubmit: (values) => {

      setSuccessful(false)
      // setLoad(Spin)
      // So here i just dispatch register request and passing in the data that is expected there... same on login side.
      // dispatch(register(values))
      //   .unwrap()
      //   .then(() => {
      //     setSuccessful(true)
      //     console.log("Hello", message)
      //     navigate("/login")
      //   })
      //   .catch(() => {
      //     setSuccessful(false)
      //       // console.log("error", message)
      //     alert(message?.email)
      //     setLoad("")
      //   })



      setLoad(Spin);



      axios({
        method: 'post',
        url: `${BASE_URL}/v1/sign-up`,

        data: values,
        config: { headers: { 'Content-Type': 'application/json' } }

      })
        .then(function (response) {
          setLoad("")

          //handle success
          setSuccessful(true)


          if (response.data.status === true) {

            localStorage.setItem("userToken", JSON.stringify(response.data.data.token))

            const messsage = response.data.message
            checkMailModal()
            setLoad('');


          }
          else {

            const messsage = response.data.message
            navigate('/sign-up')

          }

        })
        .catch(function (error) {
          setLoad("")

          setSuccessful(false)
          //handle error

          if (error.response.data.message.email) {
            emailNotify()

          } else {

            let notify = () => {
              toast.error((error.response.data.message), {
                toastClassName: 'error'
              })
            };
            notify()
          }

          setLoad('');
        });
    },
  })

  return (
    <div className={bg === true ? " blur-sm contrast-50 backdrop-brightness-50" : "w-full "}>
      <ToastContainer />


      <div className="flex justify-center items-center">
        <Transition appear show={checkMailOpen} as={Fragment}>
          <Dialog
            as="div"
            className="w-full md:w-11/12  fixed flex justify-center items-center px-6 inset-0 z-10 overflow-y-auto"
            onClose={closeModal}
          >
            <div className="w-full md:w-6/12 min-h-screen px-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-25 bg-white"
                leave="ease-in duration-200"
                leaveFrom="opacity-25"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="w-full md:w-6/12 inline-block  max-w-md pt-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-project shadow-xl rounded-lg">

                  <div className="flex w-full justify-end">
                    <button
                      type='button'

                      className=''>
                      <img src={x} className='mr-7 w-[15px]' onClick={closeModal} alt="Cancel Modal" />
                    </button>
                  </div>
                  <Dialog.Title
                    as="h3"
                    className="text-lg p-3 font-medium leading-6 text-gray-600"
                  >


                    <img src={checkMail} alt="" />
                  </Dialog.Title>

                  <div className="px-6 flex gap-2 items-center">

                    <div className="w-1/3">
                      <a href="https://www.gmail.com">
                        <img src={gmail} alt="" />
                      </a>



                    </div>

                    <div className="w-1/3">
                      <a href="https://outlook.office.com/mail/">
                        <img src={outlook} alt="" />
                      </a>

                    </div>

                    <div className="w-1/3 bg-white rounded-md flex justify-center">
                      <a href="https://mail.yahoo.com">

                        <img src={yahoo} alt="" className='w-11/12 ' />
                      </a>

                    </div>
                  </div>

                  <div className="bg-project p-6 flex flex-col items-center justify-center">


                    <button
                      type="button"
                      className="my-3 inline-flex w-full justify-center px-4 py-2 text-xs font-bold text-black bg-white border border-transparent rounded-md  focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2"

                    >
                      Resend
                    </button>




                    <label htmlFor="" className='text-xs leading-6 text-center text-amber-400'>If you haven't received a verification mail yet please click the resend button.</label>


                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>

      </div>


      <Header user="customer" />
      <div className="flex justify-center md:pt-40 pt-32 flex-col items-center w-full pb-20 mb-8 relative">

        <div className="w-full  xl:w-2/6 lg:w-2/6 md:w-3/6 flex justify-end md:pr-0 pr-12  lg:my-20 my-12">
          <div className="flex items-center justify-end gap-4 font-semibold bg-amber-200 px-3 py-1 rounded-md">
            <img
              src={partnership}
              className="md:w-8  w-8 "
              alt=""
            />

            <NavLink className="font-semibold text-base" to="/service-provider">
              {" "}
              Signup As A Service Provider
            </NavLink>
          </div>
        </div>




        <h1 className="md:px-20 px-12  text-white font-bold text-base md:text-xl mb-4 md:mb-12">
          Create Customer’s Account {" "}
        </h1>
        <div className="xl:p-6 lg:p-4 md:p-4 xl:px-16 lg:px-12 md:px-6 w-full  xl:w-2/6 lg:w-2/6 md:w-3/6 
        md:bg-white  px-12 h-auto rounded-lg">
          <form
            onSubmit={formik.handleSubmit}
            className="flex flex-col items-center justify-center mt-12 md:mt-28 relative"
          >
            <img
              src={user}
              className="md:w-20  w-16 absolute md:-top-12 -top-9 left-50"
              alt=""
            />

            <input
              type="text"
              placeholder="Your Name:"
              className="md:text-sm text-xs p-3 w-full border border-gray-500 rounded-lg my-4 placeholder:text-xs placeholder:text-gray-700"
              name="name"
              id="name"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.name}
            />

            {formik.touched.name && formik.errors.name ? (
              <p className="text-red-600 text-left text-xs w-full -mt-3 mb-4">
                {formik.errors.name}
              </p>
            ) : null}

            <div className="p-3 w-full bg-white flex gap-1 items-center  justify-center border border-gray-500 rounded-lg mt-2">
              <input
                type="email"
                placeholder="Your Email:"
                className="md:text-sm text-xs w-11/12 placeholder:text-xs placeholder:text-gray-700"
                name="email"
                id="email"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.email}
              />

              <img src={email} className="w-6" alt="" />
            </div>

            {formik.touched.email && formik.errors.email ? (
              <p className="text-red-600 text-left text-xs w-full mt-2 mb-4">
                {formik.errors.email}
              </p>
            ) : null}

            <div className="p-3 w-full bg-white flex gap-1 items-center  justify-center border border-gray-500 rounded-lg mt-6">
              <input
                type="phone"
                placeholder="Phone Number:"
                className="md:text-sm text-xs w-11/12 placeholder:text-xs placeholder:text-gray-700"
                name="phone_number"
                id="phone_number"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.phone_number}
              />

              <img src={calls} className="w-6" alt="" />
            </div>
            {formik.touched.phone_number && formik.errors.phone_number ? (
              <p className="text-red-600 text-left text-xs w-full mt-1 mb-4">
                {formik.errors.phone_number}
              </p>
            ) : null}

            <div className="p-3 w-full bg-white flex gap-1 items-center  justify-center border border-gray-500 rounded-lg mt-6">
              <input
                type={passwordShown ? "text" : "password"}
                placeholder="Password: A minimum of 8 characters"
                className="md:text-sm text-xs w-11/12 placeholder:text-xs placeholder:text-gray-700"
                name="password"
                id="password"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.password}
              />

              {passwordShown ? (
                <button onClick={(e) => togglePassword(e)}>
                  <img src={open} className="w-6 h-6" alt="" />
                </button>
              ) : (
                <button onClick={(e) => togglePassword(e)}>
                  <img src={closed} className="w-6 h-6" alt="" />
                </button>
              )}
            </div>
            {formik.touched.password && formik.errors.password ? (
              <p className="text-red-600 text-left text-xs w-full mt-2 mb-4">
                {formik.errors.password}
              </p>
            ) : null}

            <div className="p-3 w-full bg-white flex gap-1 items-center  justify-center border border-gray-500 rounded-lg mt-6">
              <input
                type={passwordShown ? "text" : "password"}
                placeholder="Confirm Password"
                className="md:text-sm text-xs w-11/12 placeholder:text-xs placeholder:text-gray-700"
                name="password_confirmation"
                id="password_confirmation"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.password_confirmation}
              />

              {passwordShown ? (
                <button onClick={(e) => togglePassword(e)}>
                  <img src={open} className="w-6 h-6" alt="" />
                </button>
              ) : (
                <button onClick={(e) => togglePassword(e)}>
                  <img src={closed} className="w-6 h-6" alt="" />
                </button>
              )}
            </div>
            {formik.touched.password_confirmation &&
              formik.errors.password_confirmation ? (
              <p className="text-red-600 text-left text-xs w-full mt-2 mb-4">
                {formik.errors.password_confirmation}
              </p>
            ) : null}

            {code ? (
              <div className="p-3 w-full bg-white flex gap-1 items-center  justify-center border border-gray-500 rounded-lg mt-6">
                <input
                  type="text"
                  placeholder="Referral Code:"
                  className="md:text-sm text-xs w-11/12 placeholder:text-xs placeholder:text-gray-700"
                  name="referral"
                  id="referral"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.referral}
                />

                <img src={ref} className="w-6" alt="" />
              </div>
            ) : (
              <div className="p-3 w-full bg-white flex gap-1 items-center  justify-center border border-gray-500 rounded-lg mt-6">
                <input
                  type="text"
                  placeholder="Referral Code:"
                  className="md:text-sm text-xs w-11/12 placeholder:text-xs placeholder:text-gray-700"
                  name="referral"
                  id="referral"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.referral}
                />
                <img src={ref} className="w-6" alt="" />
              </div>
            )}
            {/* {formik.touched.referral && formik.errors.referral ? <p className='text-red-600 text-left text-xs w-full mt-2 mb-4'>{formik.errors.referral}</p> : null}  */}

            <div className="flex items-center justify-center gap-2 my-6 w-full">
              {terms===true?
              <input
                type="checkbox"
                onChange={handleTerms}
                name="acceptTerms"
                id="acceptTerms"
                required
                checked
              />:
              <input
                type="checkbox"
                onChange={handleTerms}
                name="acceptTerms"
                id="acceptTerms"
                required
                
              />
}
              <p className="md:text-gray-500 text-white font-semibold text-xx md:text-sm">
                I read & agreed to the
                <NavLink className="font-semibold theme-color" exact to="/terms" target="_blank">
                  {" "}
                  Terms and Conditions
                </NavLink>
              </p>
            </div>
            {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
              <p className="text-red-600 text-left text-xs w-full mt-2 mb-4">
                {formik.errors.acceptTerms}
              </p>
            ) : null}

            {load ? (
              <div className="bg-amber-500 w-11/12 font-semibold rounded-lg my-4 p-1 flex justify-center items-center ">
                <h4 className="text-black text-sm">Please Wait!</h4>
                <input
                  type="image"
                  src={Spin}
                  value="Loading"
                  className="w-10"
                />
              </div>
            ) : (
              <button
                type="submit"
                className="bg-amber-500 p-3 w-11/12 font-semibold rounded-lg my-4"
              >
                Sign-Up
              </button>
            )}
          </form>

          <p className="flex justify-center text-center my-4 text-sm font-semibold md:text-gray-500 text-white">
            Already have an account?


            <NavLink className="font-semibold theme-color z-50 " exact to="/login">
              &nbsp;
              Login
            </NavLink>
          </p>
          {/* <a href ="https://api.roadlers.com/api/v1/google-redirect"   className="focus:outline-none text-white text-xs md:text-sm">
              Sign-up with
              </a> */}
          <div className="flex gap-3 items-center justify-center my-16">
            <div className="flex orange items-center rounded-lg px-4 py-1 h-10">


              <button
                onClick={handleGoogle}
                className="focus:outline-none text-white text-xs md:text-sm">
                Sign-up with
              </button>{" "}
              <img className="w-6" src={google} alt="" />
            </div>
            {/* <div className="flex bg-blue-700 rounded-lg px-4 py-1 h-10">
              <button className="focus:outline-none text-white text-xs md:text-sm">
                Sign-up with
              </button>{" "}
              <img className="w-6" src={fbb} alt="" />
            </div> */}
          </div>


        </div>
        <img
          src={car}
          alt=""
          className="absolute -bottom-12 md:right-96 w-44 md:w-64"
        />
      </div>

      <div className="full mt-8 flex mb-28 justify-center px-12">
        <div className="md:w-2/6 w-full flex justify-between gap-3">
          <div className="bg-transparent md:w-48 md:h-40 w-1/2 h-32  rounded-lg  border border-white flex items-center justify-center flex-col">
            <h3 className="text-white font-bold text-sm md:text-xl">Drivers</h3>
            <NavLink
              exact
              to="/service-provider"
              className="bg-white font-bold p-2 rounded-md md:text-sm text-xs mt-4"
            >
              Sign-Up Here{" "}
            </NavLink>
          </div>
          <div className="bg-transparent md:w-48 md:h-40 w-1/2 h-32 rounded-lg  border border-white flex items-center justify-center flex-col">
            <h3 className="text-white font-bold text-sm md:text-xl">
              Vehicle Owners
            </h3>
            <NavLink
              exact
              to="/service-provider"
              className="bg-white font-bold p-2 rounded-md md:text-sm text-xs mt-4"
            >
              Sign-Up Here
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Register