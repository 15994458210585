import React, { useState, useEffect } from 'react'
import { BASE_URL } from "../utils/constant"

import Header from '../Header'
import icon from '../../Images/icon.svg'
import { NavLink, useNavigate } from "react-router-dom";
import Navigation from './Navigation'
import { Multiselect } from 'multiselect-react-dropdown'
import eyee from '../../Images/eyee.png'
import topArrow from "../../Images/topArrow.svg"
import bottomArrow from "../../Images/bottomArrow.svg"
import Spin from "../../Images/spinner.gif"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Resizer from "react-image-file-resizer";

import pen from '../../Images/pen.svg'

import axios from "axios";
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useRef } from 'react'
import { GoogleMap, useJsApiLoader, Autocomplete } from '@react-google-maps/api';
import Dropdown from '../Common/Dropdown'
import { useTimeout } from 'rsuite/esm/utils';
import useAnalyticsEventTracker from '../Common/useAnalyticsEventTracker';
import { driverDocUploadEvent, driverRegisterPage2, gurantorInfoUploadEvent1, gurantorInfoUploadEvent2 } from '../Common/facebookPixelEvent';
import getCookie from '../utils/SecuredTokens';
// import MultiSelect from '../Common/MultiSelect'



function IndividualAccountReg2() {
    const gaEventTracker = useAnalyticsEventTracker('Driver Registration 2')
    const { } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyDkMuW-hGvdRfUhsb2R9RFqA0Pq4Qxg4Cs",
        libraries: ['places'],
    })

    // SETTING THE ADDRESS FROM THE AUTOCOMPLETE TO THE STORED RESPECTIVE VALUES
    const [autocomplete, setAutocomplete] = useState(null)
    const [autocomplete2, setAutocomplete2] = useState(null)

    const onPlaceChanged = () => {
        if(autocomplete !== null) {
            const places = autocomplete.getPlace().formatted_address
            if(places) {
                setAddress1(places)
            }else {
                setAddress1(autocomplete.getPlace().name)
            }
        }
    }

    // RESIZER
    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
            file,
            300,
            400,
            "JPEG",
            80,
            0,
            (uri) => {
                resolve(uri);
            },
            "base64"
            );
        });

    const dataURIToBlob = (dataURI) => {
        const splitDataURI = dataURI.split(",");
        const byteString =
            splitDataURI[0].indexOf("base64") >= 0
            ? atob(splitDataURI[1])
            : decodeURI(splitDataURI[1]);
        const mimeString = splitDataURI[0].split(":")[1].split(";")[0];
        const ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);
        return new Blob([ia], { type: mimeString });
    };


    const onPlaceChanged2 = () => {
        if(autocomplete2 !== null) {
            const places2 = autocomplete2.getPlace().formatted_address
            if(places2) {
                setAddress2(places2)
            }else {
                setAddress2(autocomplete.getPlace().name)
            }
        }
    }

    let navigate = useNavigate();
    const [load, setLoad] = useState("")

    // MAKING USE OF DRIVER ID AND USER ID
    let driverId = JSON.parse(localStorage.getItem("driverId"))
    let userIdProvider = JSON.parse(localStorage.getItem("userIdProvider"))

    const [selectedFile, setSelectedFile] = useState();
	const [isSelected, setIsSelected] = useState(false);

    const [license, setLicense] = useState()
    const [licenseSelected, setLicenseSelected] = useState(false)

    // Means of Identification
    const [selectedId, setSelectedId] = useState('')
    const [selectedId2, setSelectedId2] = useState('')

    // Category Choice
    const [selectedCategory, setSelectedCategory] = useState('')
    const categoryValue = selectedCategory;

    // let token = JSON.parse(localStorage.getItem("userToken"))
    let id = JSON.parse(localStorage.getItem("userId"))

    

    let userData = getCookie("user");
    let userObject = JSON.parse(userData) 
    let token = userObject?.token
    // let id = userObject?.id
   

    let [username, setUsername] = useState('')
    const [first_name, setFirstName] = useState('')
    const [last_name, setLastName] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [showSection, setShowSection] = useState(false)
    const [showBusinessSection, setShowBusinessSection] = useState(false)
    const [showDobForm, setShowDobForm] = useState(true)
    const [showCompanyForm, setShowCompanyForm] = useState(false)
    const [showGuarantorOne, setShowGuarantorOne] = useState(false)
    const [showGuarantorTwo, setShowGuarantorTwo] = useState(false)

    const [expireMonth, setExpireMonth] = useState('')
    const [expireYear, setExpireYear] = useState('')


    const [address, setAddress] = useState('');
    const [identification, setIdentification] = useState('');
    const [idNumber, setIdNumber] = useState('');
    const [phone, setPhone] = useState('');
    const [category, setCategory] = useState('');
    const [birthday, setBirthday] = useState('');

    const [availability, setAvailability] = useState([]);
    const [state, setState] = useState('');
    const [country, setCountry] = useState('');
    const [work, setWork] = useState('');

    const [isFileSaved, setIsFileSaved] = useState(false)
    const [isForm1Saved, setIsForm1Saved] = useState(false)

    // GUARANTOR 1 VALUES
    const [firstName1, setFirstName1] = useState('')
    const [lastName1, setLastName1] = useState('')
    const [idNumber1, setIdNumber1] = useState('')
    const [phone1, setPhone1] = useState('')
    const [address1, setAddress1] = useState('')

    // GUARANTOR 2 VALUES
    const [firstName2, setFirstName2] = useState('')
    const [lastName2, setLastName2] = useState('')
    const [idNumber2, setIdNumber2] = useState('')
    const [phone2, setPhone2] = useState('')
    const [address2, setAddress2] = useState('')

    const fileInputRef = useRef()
    const passportRef = useRef()

    const guarantorOneRef = useRef(null)
    const guarantorTwoRef = useRef(null)
    // TEMPORAL FIX
    let userService = JSON.parse(localStorage.getItem("userService"))

    // Facebook Pixel
    useEffect(() => {
        driverRegisterPage2()
    }, [])

    // Checking if user selected business or individual
    useEffect(() => {
        if(selectedCategory === 'business') {
            setShowDobForm(false)
            setShowCompanyForm(true)
        }else {
            setShowDobForm(true)
            setShowCompanyForm(false)
        }
    }, [selectedCategory])

    const getUserName = async () => {
        await axios.get(`${BASE_URL}/v1/show-username/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            }
        }).then((response) => {
            const alluserName = response.data;
            const username = alluserName.username;
            const fullname = response.data.name;
            const nameSplit = fullname.split(/(\s+)/);
            const firstName = nameSplit[0]
            const phone = response.data.phone_number;
            setFirstName(firstName)
            const lastName = nameSplit[2]
            setLastName(lastName)
            setPhoneNumber(phone)
            setUsername(username)
        });
    };
    useEffect(() => {
        getUserName();
    }, []);

    // HANDLE BACkGROUND PASSPORT FILE UPLOAD
    const changeHandler = async (event) => {
        const file = event.target.files[0]
        const image = await resizeFile(file);
        const newFile = dataURIToBlob(image);
        const formData = new FormData();
        formData.append("image", newFile);
		setSelectedFile(newFile);
		setIsSelected(true);
	};

    // HANDLE DRIVER LICENSE FILE UPLOAD
    const changeLicense =async (e) => {
        const file = e.target.files[0]
        const image = await resizeFile(file);
        const newFile = dataURIToBlob(image);
        const formData = new FormData();
        formData.append("image", newFile);
        setLicense(newFile);
        setLicenseSelected(true)
    }

	const handleSubmission = () => {
	};

    // AVAILABILITY SERVICE OPTIONS
    const availabilityOptionData = [
        {value: 'Night Driver', label: 'Night Driver', id: 1},
        {value: 'Day Driver', label: 'Day Driver', id: 2},
        {value: 'Full-Time Driver', label: 'Full-Time Driver', id: 3},
        {value: 'Daily Driver', label: 'Daily Driver', id: 4}
    ]

    const [optionsValue] = useState(availabilityOptionData)

    
    // ARRAY OF ID LIST
    const idArray = [
        {value: 'bvn', label: 'BVN'}, 
        {value: 'nin', label: 'NIN'},
        {value: 'passport', label: 'PASSPORT'},
        {value: 'license', label: 'LICENSE'}
    ]

    const idArray2 = [
        {value: 'bvn', label: 'BVN'}, 
        {value: 'nin', label: 'NIN'},
        {value: 'passport', label: 'PASSPORT'},
        {value: 'license', label: 'LICENSE'}
    ]


    // ARRAY OF CATEGORY LIST
    const categoryArray = [
        {value: 'individual', label: 'Individual'},
        {value: 'business', label: 'Business'}
    ]

    // GET YEARS VALUE
    const years = require('ye-ars')

    const options = {
        initialYear: 2022,
        count: 5
    }

    const result = years(options)

    const passportClick = () => {
        passportRef.current.focus()
    }

    const phoneRegExp = /^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/

    // const onSelect = (selectedList, selectedItem) => {
    //     availabilityOptions.push(selectedItem);
    // }
    
    // const onRemove = (selectedList, removedItem) => {
        
    // }

    // HANDLE COMPANY'S DETAILS WHEN YOU SUBMIT
    const handleCompanyDetailSubmit = (e) => {
        e.preventDefault()

        navigate('/approved-service-company')
    }

    // HANDLE DRIVERS DOCUMENT SUBMIT
    const handleDriversDocument = (e) => {
        e.preventDefault()
        setLoad(Spin)

        const formData = new FormData();
        const formDatas = new FormData();

        formData.append('file', license);

        formDatas.append('file', selectedFile);
        formDatas.append('doc_type', 'passport')
        formDatas.append('driver_id', driverId)
        formDatas.append('user_id', userIdProvider)

        formData.append('doc_expire_month', expireMonth);
        formData.append('doc_expire_year', expireYear);
        formData.append('doc_type', 'driver_licence')
        formData.append('driver_id', driverId)
        formData.append('user_id', userIdProvider)


        if(!license) {
            setLoad('')
            let notify = () => { toast.error(('Upload Drivers License'), {
                toastClassName: 'error'
            } ) };
            notify()
        }
        else if(!expireMonth || !expireYear) {
            setLoad('')
            let notify = () => { toast.error(('Add expiry date'), {
                toastClassName: 'error'
            } ) };
            notify()
        }else if (!selectedFile) {
            setLoad('')
            let notify = () => { toast.error(('Upload Drivers Passport'), {
                toastClassName: 'error'
            } ) };
            notify()
        }
        else {
            const isValidTypes = selectedFile.type === 'image/jpeg' || selectedFile.type === 'image/png' 

            if(!isValidTypes) {
                let notify = () => { toast.error(('You can only upload JPG or PNG as passport'), {
                    toastClassName: 'error'
                } ) };
                notify()
                setLoad('')
            }else {
                const paspportFunc = axios({
                    method: 'post',
                    url: `${BASE_URL}/v1/driver-passport`,
                    // data: { document: `${license?.name}`, doc_type: `company logo`, id: `${id}` },
                    data: formDatas,
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                        "Access-Control-Allow-Origin": "*",
                    }
                })
        
                const licenseFunc = axios({
                    method: 'post',
                    url: `${BASE_URL}/v1/driver-licence`,
                    // data: { document: `${license?.name}`, doc_type: `company logo`, id: `${id}` },
                    data: formData,
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                        "Access-Control-Allow-Origin": "*",
                    }
                })
    
                axios.all([paspportFunc, licenseFunc]).then(axios.spread((...responses) => {
                    const responseOne = responses[0]
                    const responseTwo = responses[1]
                    // use/access the results 
                    gaEventTracker('Driver Document Upload Btn')
                    driverDocUploadEvent()
                    setLoad('')
                    setIsFileSaved(true )
                    setShowGuarantorTwo(true)
                    guarantorTwoRef.current?.scrollIntoView({behavior: 'smooth'});
                  })).catch(error => {
                    setLoad('')
                    let notify = () => { toast.error((error.response.data.message), {
                        toastClassName: 'error'
                    } ) };
                    notify()
                  })
            }
        }

    }

    const [firstnameError, setFirstnameError] = useState('')
    const [lastnameError, setLastnameError] = useState('')
    const [selectedIdError, setSelectedIdError] = useState('')
    const [idNumberError, setIdNumberError] = useState('')
    const [phoneError, setPhoneError] = useState('')
    const [addressError, setAddressError] = useState('')

    const [firstnameError2, setFirstnameError2] = useState('')
    const [lastnameError2, setLastnameError2] = useState('')
    const [selectedIdError2, setSelectedIdError2] = useState('')
    const [idNumberError2, setIdNumberError2] = useState('')
    const [phoneError2, setPhoneError2] = useState('')
    const [addressError2, setAddressError2] = useState('')


    // HANDLE GURANTOR 1 SUBMIT REMOVE THIS FUNCTION
    const handleGurantor1Submit = (e) => {
        e.preventDefault()
        
        const formData1 = new FormData();
        formData1.append('firstname', firstName1);
        formData1.append('lastname', lastName1);
        formData1.append('identification', selectedId);
        formData1.append('id_number', idNumber1);
        formData1.append('phone_number', phone1);
        formData1.append('address', address1);
        formData1.append('guarantor_type', 'guarantor1');
        formData1.append('driver_id', driverId)
        formData1.append('user_id', userIdProvider)

        if(firstName1 === ''){
            setFirstnameError('This field must be filled')

            setTimeout(() => {
                setFirstnameError('')
            }, 5000)
        }else if(lastName1 === '') {
            setLastnameError('This field must be filled')

            setTimeout(() => {
                setLastnameError('')
            }, 5000)
        }else if(address1 === '') {
            setAddressError('This field must be filled')

            setTimeout(() => {
                setAddressError('')
            }, 5000)
        }else if(selectedId === '') {
            setSelectedIdError('This field must be filled')

            setTimeout(() => {
                setSelectedIdError('')
            }, 5000)
        }else if(idNumber1 === '') {
            setIdNumberError('This field must be filled')

            setTimeout(() => {
                setIdNumberError('')
            }, 5000)
        }else if(phone1 === ''){
            setPhoneError('This field must be filled')

            setTimeout(() => {
                setPhoneError('')
            }, 5000)
        }else {
            axios({
                method: 'post',
                url: `${BASE_URL}/v1/guarantor`,
                // data: { document: `${license?.name}`, doc_type: `company logo`, id: `${id}` },
                data: formData1,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    "Access-Control-Allow-Origin": "*",
                }
            })
            .then(function (response) {
                const customer = response.data;
                gaEventTracker('Gurantor 1 Doc Submit')
                gurantorInfoUploadEvent1()
                setIsForm1Saved(true)
                setShowGuarantorTwo(true)
                guarantorTwoRef.current?.scrollIntoView({behavior: 'smooth'});
            })
            .catch(function (error) {
                let notify = () => { toast.error((error.response.data.message), {
                    toastClassName: 'error'
                } ) };
                notify()
            });
        }

    }

    // HANDLE SUBMIT AND FINISH
    const handleFinishSubmit = (e) => {
        e.preventDefault()

        const formData2 = new FormData();
        formData2.append('firstname', firstName2);
        formData2.append('lastname', lastName2);
        formData2.append('identification', selectedId2);
        formData2.append('id_number', idNumber2);
        formData2.append('phone_number', phone2);
        formData2.append('address', address2);
        formData2.append('guarantor_type', 'guarantor2');
        formData2.append('driver_id', driverId)
        formData2.append('user_id', userIdProvider)


        if(firstName2 === ''){
            setFirstnameError2('This field must be filled')

            setTimeout(() => {
                setFirstnameError2('')
            }, 5000)
        }else if(lastName2 === '') {
            setLastnameError2('This field must be filled')

            setTimeout(() => {
                setLastnameError2('')
            }, 5000)
        }else if(address2 === '') {
            setAddressError2('This field must be filled')

            setTimeout(() => {
                setAddressError2('')
            }, 5000)
        }else if(selectedId2 === '') {
            setSelectedIdError2('This field must be filled')

            setTimeout(() => {
                setSelectedIdError2('')
            }, 5000)
        }else if(idNumber2 === '') {
            setIdNumberError2('This field must be filled')

            setTimeout(() => {
                setIdNumberError2('')
            }, 5000)
        }else if(phone2 === ''){
            setPhoneError2('This field must be filled')

            setTimeout(() => {
                setPhoneError2('')
            }, 5000)
        }else {
            axios({
                method: 'post',
                url: `${BASE_URL}/v1/guarantor`,
                data: formData2,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    "Access-Control-Allow-Origin": "*",
                }
            })
            .then(function (response) {
                const customer = response.data;
                gaEventTracker('Gurantor 2 Doc Upload')
                gurantorInfoUploadEvent2()
                if(userService === 'driver') {
                    navigate('/pending-notice')
                }else {
                    navigate('/driver-car-registration')
                }
            })
            .catch(function (error) {
                let notify = () => { toast.error((error.response.data.message), {
                    toastClassName: 'error'
                } ) };
                notify()
            });
        }
        // }
    }


    // USING FORMIK TO HANDLE DATA INPUT 

    // const numRegExp = "^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$"

    return <div>
        <ToastContainer />
        <Header user='provider' />

        <main className='flex md:px-12  px-12 flex-col md:flex-row registration-2-wrapper'>
        <Navigation/>
           

            <div className=' h-100 md:w-8/12 w-full'>
                <main className='w-full'>
                <div className={'md:pl-16 md:pr-16  w-full pt-0 md:pt-32 registration-2-subwrapper'} >
                        <div className="flex items-center mb-20 gap-[50px] w-full registration-2-header-text">
                            <h2 className='text-white font-bold text-3xl '>Let’s Have Your Document </h2>
                            <p className='text-[#FFA100] font-bold text-lg'>It takes 5 Min to Finish</p>
                        </div>

                        {/* <h2 className='text-white text-lg mt-4'>Upload documents respectively</h2> */}

                        {/* DRIVERS DOCUMENT FORM */}
                        <form>
                            <div className="mt-6">
                                <div className="flex justify-between border bg-[#1D1C1C] border-white p-2 md:p-3 items-center">
                                    {
                                        licenseSelected && license?.name ?(
                                            <p className='text-white md:text-base text-xs font-normal'>{license?.name}</p>
                                        ) : (
                                            <p className='text-white md:text-base text-xs font-normal'>Driver’s License<span className="text-red-600">*</span> </p>
                                        )
                                    }


                                    {/* use useRef to trigger this input field for Document upload */}
                                    <input
                                        type="file"
                                        style={{ display: 'none' }}
                                        ref={fileInputRef}
                                        accept="image/jpeg,image/jpg,application/pdf"

                                        onChange={changeLicense} 
                                    />


                                    {
                                        ( license?.name) ? (
                                            <button
                                                onClick={(event) => {
                                                    event.preventDefault()
                                                    fileInputRef.current.click()
                                                }}
                                                className='border-theme-color px-3 py-1 md:text-base md:px-2 md:py-0 text-xs text-white'>
                                                    Change
                                            </button>

                                        ) : (
                                            <button
                                                onClick={(event) => {
                                                    event.preventDefault()
                                                    fileInputRef.current.click()
                                                }}
                                                className='border-theme-color px-3 py-1 md:text-base md:px-2 md:py-0 text-xs text-white'>
                                                    Upload
                                            </button>
                                        )
                                    }

                                </div>
                            </div>
            
                            <div className="flex my-4 md:my-6 w-full gap-5">
                                <div className="flex flex-col w-1/2">
                                <div className="text-base mt-2 bg-[#1D1C1C] w-full rounded-xl border border-white pl-5 pr-0 py-3  text-white">
                                    <select className='bg-[#1D1C1C] w-11/12 focus:outline-none text-white'
                                        onChange={(e) => setExpireMonth(e.target.value)}    
                                        value={expireMonth}
                                    >
                                        <option className=' text-black '>Month</option>
                                        <option value="January" className=' text-black '>January</option>
                                        <option value="February" className=' text-black '>February</option>
                                        <option value="March" className=' text-black '>March</option>
                                        <option value="April" className=' text-black '>April</option>
                                        <option value="May" className=' text-black '>May</option>
                                        <option value="June" className=' text-black '>June</option>
                                        <option value="July" className=' text-black '>July</option>
                                        <option value="August" className=' text-black '>August</option>
                                        <option value="September" className=' text-black '>September</option>
                                        <option value="October" className=' text-black '>October</option>
                                        <option value="November" className=' text-black '>November</option>
                                        <option value="December" className=' text-black '>December</option>
                                    </select>
                                </div>
                                </div>
                                <div className="flex flex-col w-1/2">
                                <div className="text-base mt-2 bg-[#1D1C1C] w-full rounded-xl border border-white pl-5 pr-0 py-3  text-white">
                                    
                                    <select className='bg-[#1D1C1C] w-11/12 focus:outline-none text-white'
                                        onChange={(e) => setExpireYear(e.target.value)}
                                        value={expireYear}
                                    >
                                            <option className=' text-black '>Year</option>
                                            <option value="2022" className=' text-black '>2022</option>
                                            <option value="2023" className=' text-black '>2023</option>
                                            <option value="2024" className=' text-black '>2024</option>
                                            <option value="2025" className=' text-black '>2025</option>
                                            <option value="2026" className=' text-black '>2026</option>
                                            <option value="2027" className=' text-black '>2027</option>
                                    </select>       
                                </div>

                                </div>
                            </div>
        

                            <div className="">
                                <div className="flex justify-between border bg-[#1D1C1C] border-white p-2 md:p-3 items-center">
                                    {
                                        isSelected && selectedFile?.name ?(
                                            <p className='text-white md:text-base text-xs font-normal'>{selectedFile?.name}</p>
                                        ) : (
                                            <p className='text-white md:text-base text-xs font-normal'>Passport Photograph<span className="text-red-600">*</span> </p>
                                        )
                                    }


                                    {/* use useRef to trigger this input field for Document upload */}
                                    <input
                                        type="file"
                                        style={{ display: 'none' }}
                                        ref={passportRef}
                                        accept="image/jpeg,image/jpg,application/pdf"

                                        onChange={changeHandler} 
                                    />


                                    {
                                        ( selectedFile?.name) ? (
                                            <button
                                                onClick={(event) => {
                                                    event.preventDefault()
                                                    passportRef.current.click()
                                                }}
                                                className='border-theme-color px-3 py-1 md:text-base md:px-2 md:py-0 text-xs text-white'>
                                                    Change
                                            </button>

                                        ) : (
                                            <button
                                                onClick={(event) => {
                                                    event.preventDefault()
                                                    passportRef.current.click()
                                                }}
                                                className='border-theme-color px-3 py-1 md:text-base md:px-2 md:py-0 text-xs text-white'>
                                                    Upload
                                            </button>
                                        )
                                    }

                                </div>
                            </div>

                            {
                                isFileSaved ?
                                <div className="bg-[#FFA100] md:w-1/4 w-2/4 my-8 mb-10 p-2 text-center rounded-md font-bold text-center cursor-pointer">
                                    Saved
                                </div>
                                :
                                <div className="bg-white flex justify-center items-center md:w-1/4 w-2/4 my-8 mb-10 p-2 text-center rounded-md font-bold text-center cursor-pointer" onClick={handleDriversDocument}>
                                    Save
                                    {
                                        load && 
                                        <input
                                            type="image"
                                            src={Spin}
                                            value="Loading"
                                            className="w-6"
                                        />
                                    }
                                </div>
                            }
                        </form>

                        {/* GUARANTOR ONE FORM THIS IS  NOT USED */}

                        {
                            showGuarantorOne && 
                            (
                                <form>
                                {/* GUARANTOR ONE */}
                                <div className="md:mt-16 mt-12" ref={guarantorOneRef}>
                                    <h4 className='text-amber-500 text-bold'>Guarantor 1 <span className="text-red-600">*</span></h4>
                
                                        <div className="flex my-4 md:my-6 w-full gap-5 gurantor-name-container">
                                            <div className="flex flex-col w-1/2 firstname-wrapper">
                                                <label htmlFor="First name" className='text-xs text-white font-normal'>First Name <span className="text-red-600">*</span></label>
                                                <input
                                                    type="text"
                                                    placeholder='Type here.....'
                                                    className='text-base my-2 bg-[#1D1C1C] rounded-xl border border-white px-3 py-3 focus:outline-none text-white'
                                                    name='firstname1'
                                                    value={firstName1}
                                                    onChange={(e) => setFirstName1(e.target.value)}
                                                />
                                                <p className='text-red-600 mt-1'>{firstnameError}</p>
                                            </div>
                                            <div className="flex flex-col w-1/2 lastname-wrapper">
                                                <label htmlFor="Last Name" className='text-xs text-white font-normal'>Last Name <span className="text-red-600">*</span></label>
                                                <input
                                                    type="text"
                                                    placeholder='Type here.....' className='text-base my-2 bg-[#1D1C1C] rounded-xl border border-white px-3 py-3 focus:outline-none text-white' 
                                                    name='lastname1'
                                                    value={lastName1}
                                                    onChange={(e) => setLastName1(e.target.value)}
                                                    
                                                />
                                                <p className='text-red-600 mt-1'>{lastnameError}</p>
                                            </div>
                                        </div>
                
                                        <div className="flex flex-col">
                                            <label htmlFor="Address" className='text-xs text-white font-normal'>Address <span className="text-red-600">*</span></label>

                                            <Autocomplete className='autcomplete-style'
                                                onPlaceChanged={onPlaceChanged}
                                                onLoad={(value) => setAutocomplete(value)}
                                            >
                                            <input
                                                type="phone"
                                                placeholder='Type here.....' className='text-base w-full my-2 bg-[#1D1C1C] rounded-xl border border-white px-3 py-3 focus:outline-none text-white' 
                                                name='address1'  
                                                value={address1}
                                                onChange={(e) => setAddress1(e.target.value)}
                                            />
                                            </Autocomplete>
                                            <p className='text-red-500 mt-1'>{addressError}</p>
                                        </div>
                
                
                
                                        <div className="flex flex-col my-4 md:my-6">
                                            <label htmlFor="Category:" className='text-xs text-white font-normal'>Means of Identification <span className="text-red-600">*</span></label>
                
                                            <div className="text-base mt-2 bg-[#1D1C1C] w-full rounded-xl border border-white pl-5 pr-0 py-3  text-white">
                
                                                <Dropdown 
                                                    holder= 'Select ID'
                                                    optionArray= {idArray}
                                                    setSelectedOption= {setSelectedId}
                                                    selectedOption= {selectedId}
                                                />
                                            </div>
                                            <p className='text-red-500 mt-1'>{selectedIdError}</p>
                
                                        </div>
                
                                        <div className="flex flex-col">
                                            <label htmlFor="ID Number" className='text-xs text-white font-normal'>ID Number <span className="text-red-600">*</span></label>
                
                                            <input 
                                                type="text" 
                                                Value='' 
                                                placeholder='Type here.....' className='text-base my-2 bg-[#1D1C1C] rounded-xl border border-white px-3 py-3 focus:outline-none text-white' 
                                                name='idNumber1'
                                                value={idNumber1}
                                                onChange={(e) => setIdNumber1(e.target.value)}
                                            />
                                            <p className='text-red-500 mt-1'>{idNumberError}</p>
                                        </div>
                
                                        <div className="flex flex-col my-4 md:my-6 ">
                                            <label htmlFor="Phone Number" className='text-xs text-white font-normal'>Phone Number <span className="text-red-600">*</span></label>
                
                                            <input 
                                                type="phone" 
                                                placeholder='Type here.....' className='text-base my-2 bg-[#1D1C1C] rounded-xl border border-white px-3 py-3 focus:outline-none text-white' 
                                                name='phone1'
                                                value={phone1}
                                                onChange={(e) => setPhone1(e.target.value)}
                                            />
                                            <p className='text-red-500 mt-1'>{phoneError}</p>
                                        </div>
                                </div>
    
                                {
                                    isForm1Saved ?
                                    <div className="bg-[#FFA100] md:w-1/4 w-2/4 my-8 mb-10 p-2 text-center rounded-md font-bold text-center cursor-pointer">
                                        Saved
                                    </div>
                                    :
                                    <div className="bg-white md:w-1/4 w-2/4 my-8 mb-10 p-2 text-center rounded-md font-bold text-center cursor-pointer" type="submit" onClick={handleGurantor1Submit}>
                                        Save
                                    </div>
                                }
                                </form>
                            )
                        }

                        {/* GURANTOR TWO FORM */}
                        {
                            showGuarantorTwo &&
                            (
                                <form>
                                <div className="md:mt-16 mt-12" ref={guarantorTwoRef }>
                                    <h4 className='text-amber-500 text-bold'>Guarantor  <span className="text-red-600">*</span>
                                    </h4>
                
    
                                        <div className="flex my-4 md:my-6 w-full gap-5 gurantor-name-container">
                                            <div className="flex flex-col w-1/2 firstname-wrapper">
                                                <label htmlFor="First name" className='text-xs text-white font-normal'>First Name <span className="text-red-600">*</span></label>
                                                <input 
                                                    type="text" 
                                                    placeholder='Type Here'
                                                    className='text-base my-2 bg-[#1D1C1C] rounded-xl border border-white px-3 py-3 focus:outline-none text-white' 
                                                    name='firstname2'  
                                                    value={firstName2}
                                                    onChange={(e) => setFirstName2(e.target.value)}
                                                />
                                                <p className='text-red-600 mt-1'>{firstnameError2}</p>
                                            </div>
                                            <div className="flex flex-col w-1/2 lastname-wrapper">
                                                <label htmlFor="Last Name" className='text-xs text-white font-normal'>Last Name <span className="text-red-600">*</span></label>
                                                <input 
                                                    type="text" 
                                                    placeholder='Type Here...'
                                                    className='text-base my-2 bg-[#1D1C1C] rounded-xl border border-white px-3 py-3 focus:outline-none text-white' 
                                                    name='lastname2'
                                                    value={lastName2}
                                                    onChange={(e) => setLastName2(e.target.value)}
                                                />
                                                <p className='text-red-600 mt-1'>{lastnameError2}</p>
                
                                            </div>
                                        </div>
                
                                        <div className="flex flex-col">
                                            <label htmlFor="Address" className='text-xs text-white font-normal'>Address <span className="text-red-600">*</span></label>

                                            <Autocomplete className='autcomplete-style'
                                                onPlaceChanged={onPlaceChanged2}
                                                onLoad={(value) => setAutocomplete2(value)}
                                            >
                                            <input 
                                                type="text" 
                                                placeholder='Type here.....' 
                                                className='text-base my-2 bg-[#1D1C1C] w-full rounded-xl border border-white px-3 py-3 focus:outline-none text-white' 
                                                name='address2'
                                                value={address2}
                                                onChange={(e) => setAddress2(e.target.value)}
                                            />   
                                            </Autocomplete>
                                            <p className='text-red-600 mt-1'>{addressError2}</p>             
                                        </div>
                
                
                
                                        <div className="flex flex-col my-4 md:my-6">
                                            <label htmlFor="Category:" className='text-xs text-white font-normal'>Means of Identification <span className="text-red-600">*</span></label>
                
                                            <div className="text-base mt-2 bg-[#1D1C1C] w-full rounded-xl border border-white pl-5 pr-0 py-3  text-white">
    
                                                <Dropdown 
                                                    holder= 'Select ID'
                                                    optionArray= {idArray2}
                                                    setSelectedOption= {setSelectedId2}
                                                    selectedOption= {selectedId2}
                                                />
                                            </div>
                                            <p className='text-red-600 mt-1'>{selectedIdError2}</p>
                
                                        </div>
                
                                        <div className="flex flex-col">
                                            <label htmlFor="ID Number" className='text-xs text-white font-normal'>ID Number <span className="text-red-600">*</span></label>
                
                                            <input 
                                                type="text" 
                                                Value='' 
                                                placeholder='Type here.....' 
                                                className='text-base my-2 bg-[#1D1C1C] rounded-xl border border-white px-3 py-3 focus:outline-none text-white' 
                                                name='idNumber2'  
                                                value={idNumber2}
                                                onChange={(e) => setIdNumber2(e.target.value)}    
                                            />
                                            <p className='text-red-600 mt-1'>{idNumberError2}</p>
                                        </div>
                
                                        <div className="flex flex-col my-4 md:my-6 ">
                                            <label htmlFor="Phone Number" className='text-xs text-white font-normal'>Phone Number <span className="text-red-600">*</span></label>
                
                                            <input 
                                                type="phone" 
                                                placeholder='Type here.....' 
                                                className='text-base my-2 bg-[#1D1C1C] rounded-xl border border-white px-3 py-3 focus:outline-none text-white' 
                                                name='phone2'   
                                                value={phone2}
                                                onChange={(e) => setPhone2(e.target.value)}  
                                            />
                                            <p className='text-red-600 mt-1'>{phoneError2}</p>
                                        </div>
                
    
                                </div>
    
                                <div className="bg-white md:w-2/4 w-2/4 my-8 mb-40 p-2 text-center rounded-md font-bold text-center cursor-pointer" onClick={handleFinishSubmit}>
                                    Save & Finish
                                </div>
                                </form>
                            )
                        }
        
                    </div>
                </main>
            </div>
        </main>
    </div>;
}

export default IndividualAccountReg2;