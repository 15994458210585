import React, { useState, useEffect, useRef } from 'react'
import { NavLink, useNavigate } from "react-router-dom";
import { BASE_URL } from "../utils/constant"

import Header from '../Header'

import dashboard from '../../Images/dashboard.png'
import admin_request from '../../Images/admin_request.svg'
import service_p from '../../Images/service_p.svg'
import reports from '../../Images/reports.svg'
import customer from '../../Images/customers.png'
import notifications from '../../Images/notifications.png'
import download from '../../Images/download.svg'
import car_asset from '../../Images/car_asset.svg'
import icon from '../../Images/icon.svg'
import car from '../../Images/car.png'
import message from '../../Images/message.svg'

import payment from '../../Images/payment.png'
import naira from '../../Images/naira.png'
import one from '../../Images/one.png'
import eyee from '../../Images/eyee.png'

import pen from '../../Images/pen.svg'

import axios from "axios";
import { useFormik } from 'formik'
import * as Yup from 'yup'
import getCookie from '../utils/SecuredTokens';

function BusinessAccount() {
    const fileInputRef = useRef()
    const fileInputRefTwo = useRef()

    const [load, setLoad] = useState("")

 

    let userData = getCookie("user");
    let userObject = JSON.parse(userData) 
    let token = userObject?.token
    let id = JSON.parse(localStorage.getItem("userId"))

    let providerService = JSON.parse(localStorage.getItem("providerService"))

    let [username, setUsername] = useState('')
    const [first_name, setFirstName] = useState('')
    const [last_name, setLastName] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')

    const [imageFile, setImageFile] = useState('')
    const [certificate, setCertificate] = useState('')





    const [address, setAddress] = useState('');
    const [identification, setIdentification] = useState('');
    const [idNumber, setIdNumber] = useState('');
    const [phone, setPhone] = useState('');
    const [category, setCategory] = useState('');
    const [birthday, setBirthday] = useState('');

    const [availability, setAvailability] = useState('');
    const [state, setState] = useState('');
    const [country, setCountry] = useState('');
    const [work, setWork] = useState('');
    const [companyName, setCompanyName] = useState('');



    const getUserName = async () => {


        await axios.get(`${BASE_URL}/v1/show-username/${id}`, {

            headers: {
                'Content-Type': 'application/json',

                Authorization: `Bearer ${token}`,

                "Access-Control-Allow-Origin": "*",


            }
        }).then((response) => {
            const alluserName = response.data;

            const username = alluserName.username;
            console.log('username mi oooooooooooo', username);

            const fullname = response.data.name;
            console.log('fullname', fullname)
            const nameSplit = fullname.split(/(\s+)/);
            const firstName = nameSplit[0]
            const phone = response.data.phone_number;






            setFirstName(firstName)

            const lastName = nameSplit[2]

            setLastName(lastName)


            setPhoneNumber(phone)


            setUsername(username)

        });
    };
    useEffect(() => {
        getUserName();
    }, []);




    const handleSubmit = (e) => {
        e.preventDefault()

        let formData = new FormData();

        formData.append('id', id)
        formData.append('firstname', first_name)
        formData.append('firstname', last_name)
        formData.append('address', address)
        formData.append('identification', identification)
        formData.append('id_number', idNumber)
        formData.append('phone_number', phoneNumber)
        formData.append('category', category)
        formData.append('dob', birthday)
        formData.append('service', work)
        formData.append('availability', availability)
        formData.append('state', state)
        formData.append('company_name', companyName)
        formData.append('country', country)





        axios({
            method: 'put',
            url: `${BASE_URL}/v1/update-provider/`,


            data: formData,

            headers: {
                'Content-Type': 'application/json',

                Authorization: `Bearer ${token}`,

                "Access-Control-Allow-Origin": "*",


            }

        })
            .then(function (response) {
                //handle success

                const customer = response.data;
                // const errors = response.data.errors;


                if (customer) {
                    console.log("customer customer ", customer)
                    navigate('service-provider-dashboard')

                }


            })
            .catch(function (error) {
                //handle error
                console.log("error ", error.response.data)


            });


    }





    useEffect(() => {

        if (imageFile) {
            const reader = new FileReader()

            reader.readAsDataURL(imageFile)


            let formData = new FormData();

            formData.append('user_id', id)
            formData.append('doc_type', "logo")
            formData.append('document', imageFile)

            axios({
                method: 'post',
                url: `${BASE_URL}/v1/company-logo`,
                data: formData,

                headers: {
                    'Content-Type': 'application/json',

                    Authorization: `Bearer ${token}`,

                    "Access-Control-Allow-Origin": "*",


                }
            })
                .then(function (response) {

                    alert('Posted succesfully')



                    //     }
                    console.log('shey mo kere niiiiiiiiiii', response.data);

                })
                .catch(function (error) {
                    //handle error
                    console.log(error)
                });



        } else {


        }





    }, [imageFile]);




    useEffect(() => {

        if (certificate) {
            const reader = new FileReader()

            reader.readAsDataURL(certificate)


            let formData = new FormData();

            formData.append('user_id', id)
            formData.append('doc_type', "logo")
            formData.append('document', certificate)

            axios({
                method: 'post',
                url: `${BASE_URL}/v1/business-certificate`,
                data: formData,

                headers: {
                    'Content-Type': 'application/json',

                    Authorization: `Bearer ${token}`,

                    "Access-Control-Allow-Origin": "*",


                }
            })
                .then(function (response) {

                    alert('Posted succesfully')



                    //     }
                    console.log('shey mo kere niiiiiiiiiii', response.data);

                })
                .catch(function (error) {
                    //handle error
                    console.log(error)
                });



        } else {


        }





    }, [certificate]);

    let navigate = useNavigate();

    return <div>
        <Header user='provider' />

        <main className='flex md:px-12 px-12 flex-col md:flex-row'>
            <div className='md:border-r md:border-amber-600 h-100 w-2/12 pb-16 md:block hidden md:pt-28'>
                <button className='mt-12 py-2 px-5 bg-amber-500 rounded-md text-sm font-bold text-black'>Switch To Customer </button>

                <div className='mt-6 my-3'>
                    <span className='flex items-center text-white'> <img src={dashboard} alt="" className='mr-3 w-8' /> <a href="" className='font-light' >My Profile</a></span>
                </div>

                <div className='my-3'>
                    <span className='flex items-center text-white'> <img src={payment} alt="" className='mr-3 w-8' /> <a href="" className='font-light'>My Wallet</a></span>
                </div>

                <div className='my-3'>
                    <span className='flex items-center text-white'> <img src={admin_request} alt="" className='mr-3 w-8' /> <a href="" className='font-light'>My Request </a></span>
                </div>

                <div className='my-3'>
                    <span className='flex items-center text-white'> <img src={car_asset} alt="" className='mr-3 w-8' /> <a href="" className='font-light'>My Assets</a></span>
                </div>

                <div className='my-3'>
                    <span className='flex items-center text-white'> <img src={naira} alt="" className='mr-3 w-8' /> <a href="" className='font-light'>Transaction History</a></span>
                </div>

                <div className='my-3'>
                    <span className='flex items-center text-white'> <img src={one} alt="" className='md:ml-1 md:mr-5 w-6' /> <a href="" className='font-light'>My Calendar</a></span>
                </div>

                <div className='my-3'>
                    <span className='flex items-center text-white'> <img src={notifications} alt="" className='mr-3 w-8' /> <a href="" className='font-light'>Notification</a></span>
                </div>

                <div className='my-3'>
                    <span className='flex items-center text-white'> <img alt="" className='mr-3 w-8' /> <a href="" className='font-light'>How it works</a></span>
                </div>

            </div>

            <div className='md:border-r md:border-amber-600 h-100 md:w-6/12 w-full md:px-20 pb-20 pt-12 md:pt-36'>


                <div className="flex items-center justify-between w-full">
                    <h2 className='text-white font-bold text-lg mt-8'>My Profile  </h2>
                    <img src={pen} alt="" className='w-6' />

                </div>


                <main className='w-full'>
                    <div className="flex w-full">


                        <div className="pb-20 w-full">


                            <form className='w-full mt-4'>
                                <div className="flex my-6 md:my-12 w-full gap-1">
                                    <div className="flex flex-col w-1/2">
                                        <label htmlFor="First name" className='text-xs text-white font-normal'>First Name <span className="text-red-600">*</span></label>
                                        <input
                                            type="text"
                                            value={first_name}

                                            className='text-sm mt-2 bg-transparent rounded-2xl border border-white px-3 py-3 focus:outline-none text-white'

                                        />
                                    </div>
                                    <div className="flex flex-col w-1/2">
                                        <label htmlFor="Last Name" className='text-xs text-white font-normal'>Last Name <span className="text-red-600">*</span></label>
                                        <input
                                            type="text"
                                            value={last_name}

                                            className='text-sm mt-2 bg-transparent rounded-2xl border border-white px-3 py-3 focus:outline-none text-white'

                                        />

                                    </div>
                                </div>

                                <div className="flex flex-col">
                                    <label htmlFor="Address" className='text-xs text-white font-normal'>Address <span className="text-red-600">*</span></label>

                                    <input
                                        type="text"
                                        placeholder='Plot 1225 Bishop Oluwole Street, Victoria Island ...'
                                        value={address}

                                        onChange={e => setAddress(e.target.value)}
                                        className='text-sm mt-2 bg-transparent rounded-2xl border border-white px-3 py-3 focus:outline-none text-white'

                                    />

                                </div>

                                <div className="flex flex-col my-6 md:my-12">
                                    <label htmlFor="Category:" className='text-xs text-white font-normal'>Means of Identification <span className="text-red-600">*</span></label>

                                    <div className="text-sm mt-2 bg-transparent w-full rounded-2xl border border-white pl-5 pr-0 py-3  text-white">

                                        <select
                                            className='bg-transparent w-11/12 focus:outline-none text-white'
                                            value={identification}
                                            onChange={e => setIdentification(e.target.value)}
                                        >
                                            <option className='text-black font-semibold'>Select ID Type</option>
                                            <option value='NIN' className='text-black font-semibold'>NIN</option>
                                            <option value='Passport' className='text-black font-semibold'>Passport</option>
                                            <option value='Drivers License' className='text-black font-semibold'>Driver's License</option>
                                            <option value='BVN' className='text-black font-semibold'>BVN</option>


                                        </select>
                                    </div>

                                </div>

                                <div className="flex flex-col">
                                    <label htmlFor="ID Number" className='text-xs text-white font-normal'>ID Number <span className="text-red-600">*</span></label>

                                    <input
                                        type="text"
                                        value={idNumber}
                                        onChange={e => setIdNumber(e.target.value)}
                                        placeholder='ID Number'
                                        className='text-sm mt-2 bg-transparent rounded-2xl border border-white px-3 py-3 focus:outline-none text-white'

                                    />

                                </div>




                                <div className="flex flex-col my-6 md:my-12">
                                    <label htmlFor="Category:" className='text-xs text-white font-normal'>Category <span className="text-red-600">*</span></label>


                                    <div className="text-sm mt-2 bg-transparent w-full rounded-2xl border border-white pl-5 pr-0 py-3  text-white">

                                        <select className='bg-transparent w-11/12 focus:outline-none text-white'
                                            value={category}

                                            onChange={e => setCategory(e.target.value)}
                                        >
                                            <option value='' className=' text-black font-semibold'>Business</option>
                                            {/* <option value='' className=' text-black font-semibold'>Individual</option> */}



                                        </select>
                                    </div>
                                </div>

                                {/* <div className="flex flex-col">
                                    <label htmlFor="Birthday date:" className='text-xs text-white font-normal'>Birthday date. <span className="text-red-600">*</span></label>
                                    <div className="rounded-2xl border border-white mt-2 items-center flex px-2">
                                        <input type="date" placeholder='Pick' className='w-11/12 text-sm bg-transparent rounded-2xl  p-3 focus:outline-none text-white' />
                                        <img src={icon} alt="" className='w-10 md:w-12' />
                                    </div>

                                </div> */}

                                <div className="flex flex-col my-6 md:my-12">
                                    <label htmlFor="Service Offering:" className='text-xs text-white font-normal'>Service Offering <span className="text-red-600">*</span></label>


                                    <div className="text-sm mt-2 bg-transparent w-full rounded-2xl border border-white pl-5 pr-0 py-3  text-white">
                                        <select className='bg-transparent w-11/12 focus:outline-none text-white'
                                            value={work}

                                            onChange={e => setWork(e.target.value)}
                                        >
                                            <option value={providerService} className=' text-black font-bold'>{providerService}</option>



                                        </select>
                                    </div>
                                </div>



                                <div className="flex flex-col my-6 md:my-12">
                                    <label htmlFor="Availability:" className='text-xs text-white font-normal'>Availability <span className="text-red-600">*</span></label>


                                    <div className="text-sm mt-2 bg-transparent w-full rounded-2xl border border-white pl-5 pr-0 py-3  text-white">
                                        <select className='bg-transparent w-11/12 focus:outline-none text-white'
                                            value={availability}

                                            onChange={e => setAvailability(e.target.value)}
                                        >
                                            <option value='' className=' text-black '>Tick all the ones that apply to you</option>
                                            <option value='Night Driver' className=' text-black '>Night Driver</option>
                                            <option value='Day Driver' className=' text-black '>Day Driver</option>
                                            <option value='Full-Time Driver' className=' text-black '>Full-Time Driver</option>
                                            <option value='Daily Driver' className=' text-black '>Daily Driver</option>


                                        </select>
                                    </div>
                                </div>


                                <div className="flex flex-col">
                                    <label htmlFor="Company Name:" className='text-xs text-white font-normal'>Company Name <span className="text-red-600">*</span></label>
                                    <div className="rounded-2xl border border-white mt-2 items-center flex px-2">
                                        <input
                                            type="text"
                                            placeholder='Company Name'
                                            className='w-full text-sm bg-transparent rounded-2xl  p-3 focus:outline-none text-white'
                                            value={companyName}

                                            onChange={e => setCompanyName(e.target.value)}
                                        />

                                    </div>

                                </div>


                                <div className="flex flex-col my-6 md:my-12">
                                    <label htmlFor="Phone Number:" className='text-xs text-white font-normal'>Phone Number <span className="text-red-600">*</span></label>
                                    <div className="rounded-2xl border border-white mt-2 items-center flex px-2">
                                        <input
                                            type="text"
                                            placeholder='Phone Number'
                                            className='w-full text-sm bg-transparent rounded-2xl  p-3 focus:outline-none text-white'
                                            value={phoneNumber}

                                        />

                                    </div>

                                </div>



                                <div className="flex w-full gap-1">
                                    <div className="flex flex-col w-1/2">
                                        <label htmlFor="State" className='text-xs text-white font-normal'>State <span className="text-red-600">*</span></label>
                                        <input
                                            type="text"
                                            placeholder='Lagos'
                                            className='text-sm mt-2 bg-transparent rounded-2xl border border-white px-3 py-3 focus:outline-none text-white'
                                            value={state}

                                            onChange={e => setState(e.target.value)}

                                        />
                                    </div>
                                    <div className="flex flex-col w-1/2">
                                        <label htmlFor="Country" className='text-xs text-white font-normal'>Country <span className="text-red-600">*</span></label>
                                        <input
                                            type="text"
                                            placeholder='Nigeria'
                                            className='text-sm mt-2 bg-transparent rounded-2xl border border-white px-3 py-3 focus:outline-none text-white'
                                            value={country}

                                            onChange={e => setCountry(e.target.value)}
                                        />

                                    </div>
                                </div>






                                <div className="flex gap-2 mt-10 w-full">
                                    <button className='bg-amber-500 font-semibold text-black p-2 rounded-md md:w-2/4 w-3/4'>Change Password</button>
                                    <button
                                        onClick={handleSubmit}
                                        className='bg-white font-semibold text-black p-2 rounded-md w-1/4'
                                    >Save </button>
                                </div>

                            </form>
                        </div>
                    </div>

                </main>
            </div>

            <div className='md:pl-16 md:pr-8 md:w-4/12 w-full pt-0 md:pt-36' >
                <div className="md:flex justify-end hidden"><img src={eyee} alt="" /></div>


                <div className="flex justify-between items-center w-full md:w-4/5">
                    <h2 className='text-white font-bold text-lg'>Business Documents</h2>

                    <img src={icon} alt="" className='' />

                </div>

                <div className="mt-6 w-full">
                    <div className="flex justify-between border border-white p-2 md:p-3 items-center">
                        <p className='text-white md:text-sm text-xs font-normal'>Company Logo <span className="text-red-600">*</span></p>
                        <button
                            onClick={(event) => {

                                event.preventDefault()
                                fileInputRef.current.click()
                            }}
                            className='border-theme-color px-3 py-1 md:text-sm md:px-2 md:py-0 text-xs text-white'>Upload</button>
                    </div>

                    <input
                        type="file"
                        style={{ display: 'none' }}
                        ref={fileInputRef}
                        accept="image/*"
                        onChange={(event) => {
                            const file = event.target.files[0]
                            if (file) {
                                setImageFile(file)
                                console.log('fileeeeeeeeeee3', file)
                            }
                            else {
                                setImageFile(null)
                            }
                        }}




                    />


                </div>

                <div className="">
                    <div className="flex justify-between border border-white p-2 md:p-3 items-center">
                        <p className='text-white md:text-sm text-xs font-normal'>Company Business Certificate <span className="text-red-600">*</span></p>
                        <button
                            onClick={(event) => {

                                event.preventDefault()
                                fileInputRefTwo.current.click()
                            }}
                            className='border-theme-color px-3 py-1 md:text-sm md:px-2 md:py-0 text-xs text-white'>Upload</button>
                    </div>

                    <input
                        type="file"
                        style={{ display: 'none' }}
                        ref={fileInputRefTwo}

                        onChange={(event) => {
                            const file = event.target.files[0]
                            if (file) {
                                setCertificate(file)
                                console.log('fileeeeeeeeeee3', file)
                            }
                            else {
                                setCertificate(null)
                            }
                        }}




                    />


                </div>







                <button className='bg-white md:w-1/4 w-2/4 my-8 p-2 text-center rounded-md font-bold '>Next</button>




                <div className="flex justify-between items-center w-4/5">
                    <h2 className='mb-10 text-white font-semibold mt-8 text-lg'>My Bank Details</h2>
                    <img src={icon} alt="" className='' />

                </div>

                <form action="">
                    <div className="flex gap-1 w-full">
                        <div className="w-1/2 flex flex-col">
                            <label htmlFor="first name" className='text-white text-sm font-semibold'>First Name <span className="text-red-600">*</span></label>
                            <input type="text" value="Phebe" className='mt-2 md:text-sm text-xs text-white bg-transparent border border-white p-2 rounded-md' />
                        </div>
                        <div className="w-1/2 flex flex-col">
                            <label htmlFor="last name" className='text-white font-semibold text-sm'>Last Name <span className="text-red-600">*</span></label>
                            <input type="text" value="Phebe" className='mt-2 md:text-sm text-xs text-white bg-transparent border border-white p-2 rounded-md' />
                        </div>

                    </div>

                    <div className="flex flex-col mt-6">
                        <label htmlFor="Account Number" className='text-white text-sm font-semibold'>Account Number <span className="text-red-600">*</span></label>
                        <input type="text" value="45324575657" className='mt-2 md:text-sm text-xs text-white bg-transparent border border-white p-2 rounded-md' />
                    </div>

                    <div className="flex flex-col mt-6">
                        <label htmlFor="Bank Name" className='text-white font-semibold text-sm'>Bank Name <span className="text-red-600">*</span></label>

                        <div className="w-full bg-transparent text-white border border-white p-2 rounded-md mt-2">
                            <select class="w-11/12 md:text-sm text-xs  bg-transparent focus:outline-none " >
                                <option selected className='text-white'>Choose</option>
                                <option value="access" className='text-black'>Access Bank</option>
                                <option value="citibank" className='text-black'>Citibank</option>
                                <option value="diamond" className='text-black'>Diamond Bank</option>
                                <option value="ecobank" className='text-black'>Ecobank</option>
                                <option value="fidelity" className='text-black'>Fidelity Bank</option>
                                <option value="firstbank" className='text-black'>First Bank</option>
                                <option value="fcmb" className='text-black'>First City Monument Bank (FCMB)</option>
                                <option value="gtb" className='text-black'>Guaranty Trust Bank (GTB)</option>
                                <option value="heritage" className='text-black'>Heritage Bank</option>
                                <option value="keystone" className='text-black'>Keystone Bank</option>
                                <option value="kuda" className='text-black'>Kuda Bank</option>
                                <option value="polaris" className='text-black'>Polaris Bank</option>
                                <option value="providus" className='text-black'>Providus Bank</option>
                                <option value="stanbic" className='text-black'>Stanbic IBTC Bank</option>
                                <option value="standard" className='text-black'>Standard Chartered Bank</option>
                                <option value="sterling" className='text-black'>Sterling Bank</option>
                                <option value="suntrust" className='text-black'>Suntrust Bank</option>
                                <option value="union" className='text-black'>Union Bank</option>
                                <option value="uba" className='text-black'>United Bank for Africa (UBA)</option>
                                <option value="unity" className='text-black'>Unity Bank</option>
                                <option value="wema" className='text-black'>Wema Bank</option>
                                <option value="zenith" className='text-black'>Zenith Bank</option>
                            </select>
                        </div>


                    </div>


                    <button className='bg-white md:w-1/4 w-2/4 my-8 p-2 text-center rounded-md font-bold '>Save</button>

                </form>


            </div>


        </main>
    </div>;
}

export default BusinessAccount;
