import axios from "axios"
const API_URL = "https://api.roadlers.com/api/v1/"

//Create Axios services here by just sending the data into the appropriate api path

const register = (registerData) => {
  console.log("register with", registerData)
  return axios.post(API_URL + "sign-up", registerData).then((response) => {
      localStorage.setItem("user", JSON.stringify(response.data))
  })
}

const login = (loginData) => {
  return axios.post(API_URL + "login", loginData).then((response) => {
    if (response.data.token) {
      localStorage.setItem("user", JSON.stringify(response.data))
    }
    return response.data
  })
}

const google = (googleData) => {
  return axios.post(API_URL + "auth/google/callback", googleData).then((response) => {
    if (response.data.token) {
      localStorage.setItem("user", JSON.stringify(response.data))
    }
    return response.data
  })
}

const logout = () => {
  localStorage.removeItem("user")
}
const authService = {
  register,
  login,
  google,
  logout,
}
export default authService
