import React, { useEffect, useState } from 'react'

import gso from '../../Images/gso.svg'
import pen from '../../Images/pen.svg'
import message from '../../Images/message.svg'

import { NavLink, useNavigate } from "react-router-dom";
import { useRef } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import { GoogleMap, useJsApiLoader, Autocomplete } from '@react-google-maps/api';
import Spin from "../../Images/spinner.gif"
import axios from "axios";
import moment from 'moment';
import { BASE_URL } from "../utils/constant"
import useAnalyticsEventTracker from '../Common/useAnalyticsEventTracker';
import getCookie from '../utils/SecuredTokens';

function ApprovedCompanyComponent(props) {
    const gaEventTracker = useAnalyticsEventTracker('Company Approval Page');
  
   

    let userData = getCookie("user");
    let userObject = JSON.parse(userData) 
    let token = userObject?.token
    let id = JSON.parse(localStorage.getItem("userId"))


    let navigate = useNavigate();

    const { } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyDkMuW-hGvdRfUhsb2R9RFqA0Pq4Qxg4Cs",
        libraries: ['places'],
    })

    // SETTING THE ADDRESS FROM THE AUTOCOMPLETE TO THE STORED RESPECTIVE VALUES
    const [autocomplete, setAutocomplete] = useState(null)

    const onPlaceChanged = () => {
        if(autocomplete !== null) {
            const places = autocomplete.getPlace().formatted_address
            if(places) {
                setEditAddress(places)
            }else {
                setEditAddress(autocomplete.getPlace().name)
            }
        }
    }

    const {
        firstName,
        lastName,
        address,
        category,
        services,
        availability,
        companyName,
        phoneNo,
        stateLocation,
        countryLocation,
        providerId,
        statusProfile,
        companyCertificate,
        editDate
    } = props

    const [selectedFile, setSelectedFile] = useState();
	const [isSelected, setIsSelected] = useState(false);
    const [isCertSaved, setIsCertSaved] = useState(false)

    const [editAddress, setEditAddress] = useState('')
    const [editCompanyName, setEditCompanyName] = useState('')
    const [editPhoneNo, setEditPhoneNo] = useState('')
    const [editStateLocation, setEditStateLocation] = useState('')
    const [editCountryLocation, setEditCountryLocation] = useState('')

    const [showServiceEdit, setShowServiceEdit] = useState(false)
    const [editInfo, setShowEditInfo] = useState(false)
    const [serviceOffered, setServiceOffered] = useState('')

    const [categoryChosen, setCategoryChosen] =  useState('')
    const [servicesChosen, setServicesChosen] = useState('')

    const [companyLoad, setCompanyLoad] =  useState("")
    const [certLoad, setCertLoad] =  useState("")

    const [companySaved, setCompanySaved] = useState(false)

    const today = new Date()
    const getToday = moment(today).format("YYYY-MM-DD")
    const profileEditDate = moment(editDate).format("YYYY-MM-DD")
    const compareDate = moment(getToday).isAfter(profileEditDate)


    const companyCertRef = useRef()

    // SETTING COMPANY CERTIFICATE OBJECT
    const certId = companyCertificate?.find((obj => obj?.doc_type.includes("business_certificate")))   

    // HANDLE COMPANY CERT
    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsSelected(true);
    };

    useEffect(() => {
        if(category == 'individual') {
            setCategoryChosen('Individual')
        }else{
            setCategoryChosen('Business')
        }
    }, [])

    useEffect(() => {
        if(services == 'driver') {
            setServicesChosen('Driver')
        }else if(services == 'vehicle') {
            setServicesChosen('Vehicle')
        }else {
            setServicesChosen('Driver & Vehicle')
        }
    }, [])

    let notify = () => { toast("File Successfully Uploaded!") };

    const onCompanyUpdate = (e) => {
        e.preventDefault()
        setCompanyLoad(Spin)

        let formDatas = new FormData();
        formDatas.append('id', id)
        formDatas.append('firstname', firstName)
        formDatas.append('lastname', lastName)
        formDatas.append('category', category)
        formDatas.append('service', serviceOffered || services)
        formDatas.append('availability', availability)
        formDatas.append('address', editAddress || address)
        formDatas.append('state', editStateLocation || stateLocation)
        formDatas.append('country', editCountryLocation || countryLocation)
        formDatas.append('company_name', editCompanyName || companyName)


        axios({
            method: 'post',
            url: `${BASE_URL}/v1/update-provider`,
            data: formDatas,

            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*"
            }
        })
            .then(function (response) {
                notify()
                setCompanyLoad("")
                setCompanySaved(true)
            })
            .catch(function (error) {
                //handle error
                setCompanyLoad("")
                let notify = () => { toast.error((error.response.data.message), {
                    toastClassName: 'error'
                } ) };
                notify()
                
            });
    }

    // HANDLE COMPANY DOC UPDATE
    const handleCompanyCertUpdate = (e) => {
        e.preventDefault()
        setCertLoad(Spin)

        if(selectedFile) {
            let formData = new FormData();
            formData.append('doc_type', 'business certificate')
            formData.append('file', selectedFile)
            formData.append('id', certId.id)
    
            axios({
                method: 'post',
                url: `${BASE_URL}/v1/update-business-document`,
                data: formData,
    
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    "Access-Control-Allow-Origin": "*"
                }
            })
                .then(function (response) {
                    notify()
                    setCertLoad("")
                })
                .catch(function (error) {
                    //handle error
                    setCertLoad("")
                    let notify = () => { toast.error((error.message), {
                        toastClassName: 'error'
                    } ) };
                    notify()
                });
        }else {
            alert('Upload a file before submitting')
        }
 

    }

    const  companyLogo = companyCertificate?.find((obj => obj?.doc_type.includes("company_logo"))) 

  return (
    <>
        <div className='md:border-r md:border-amber-500 h-100 md:w-6/12 w-full md:px-20 pb-20 pt-36 component-approval-form-page'>
        <ToastContainer />


            <div className="flex items-center mt-8 justify-between">
                    <h2 className='text-white font-bold text-lg'>My Business Profile   </h2>
                    {/* <img src={pen} alt="" className='w-6' /> */}
            </div>

            {
                companyLogo?.document &&
                <div className="w-24 h-24 overflow-hidden mt-8">
                    <img src={`https://api.roadlers.com/storage/${companyLogo?.document}`} alt="" className='rounded-full object-cover object-center' />
                </div>
            }



            <main className='w-full'>
                <div className="flex w-full">


                    <div className="pb-20 w-full">
                        <form className='w-full mt-4'>
                            <div className="flex my-6 md:my-12 w-full gap-5 component-approval-form-name-wrapper">
                                <div className="flex flex-col w-1/2">
                                    <label htmlFor="First name" className='text-xs text-white font-normal'>First Name <span className="text-red-600">*</span></label>
                                    <input type="text" value={firstName}  className='text-sm mt-2 bg-[#1D1C1C] rounded-xl border border-white px-3 py-3 focus:outline-none text-white' />
                                </div>
                                <div className="flex flex-col w-1/2">
                                    <label htmlFor="Last Name" className='text-xs text-white font-normal'>Last Name <span className="text-red-600">*</span></label>
                                    <input type="text" value={lastName === "undefined" ? '' : lastName} className='text-sm mt-2 bg-[#1D1C1C] rounded-xl border border-white px-3 py-3 focus:outline-none text-white' />
                                </div>
                            </div>

                            <div className="flex flex-col">
                                <label htmlFor="Address" className='text-xs text-white font-normal'>Address <span className="text-red-600">*</span></label>

                                <Autocomplete className='autcomplete-style'
                                    onPlaceChanged={onPlaceChanged}
                                    onLoad={(value) => setAutocomplete(value)}
                                >
                                    <input type="text" 
                                        defaultValue={editAddress || address} 
                                        onChange={e => setEditAddress(e.target.value)}
                                        className='text-sm w-full mt-2 bg-[#1D1C1C] rounded-xl border border-white px-3 py-3 focus:outline-none text-white' 
                                    />
                                </Autocomplete>
                            </div>




                            <div className="flex flex-col my-6 md:my-12">
                                <label htmlFor="Category:" className='text-xs text-white font-normal'>Category <span className="text-red-600">*</span></label>

                                <input type="text" defaultValue={categoryChosen} className='text-sm mt-2 bg-[#1D1C1C] rounded-xl border border-white px-3 py-3 focus:outline-none text-white' />
                            </div>


                            {/* SERVICE OFFERING */}
                            <div className="flex flex-col my-6 md:my-12">
                                <div className="flex justify-between items-center relative">
                                    <label htmlFor="Service Offering:" className='text-xs text-white font-normal'>Service Offering <span className="text-red-600">*</span></label>

                                    {
                                        statusProfile === 'Approved' &&
                                        <img src={pen} alt="Edit Pen" 
                                            className='w-6 cursor-pointer'
                                            onClick={() => {setShowServiceEdit(showServiceEdit => !showServiceEdit)}}
                                            onMouseOver={() => setShowEditInfo(true)}
                                            onMouseOut={() => setShowEditInfo(false)}
                                        />
                                    }

                                    {
                                        statusProfile === 'Rejected' &&
                                        <img src={pen} alt="Edit Pen" 
                                            className='w-6 cursor-pointer'
                                            onClick={() => setShowServiceEdit(showServiceEdit => !showServiceEdit)}
                                            onMouseOver={() => setShowEditInfo(true)}
                                            onMouseOut={() => setShowEditInfo(false)}
                                        />
                                    }



                                    {
                                        editInfo && 
                                        <div className="flex absolute bottom-8 right-0 items-center rounded-xl p-4 bg-[#fff]">
                                            <p className='text-center'> Click this if you wish to change service offered</p>
                                        </div>
                                    }   
                                </div>
                                {
                                    !showServiceEdit &&

                                    <input type="text" defaultValue={servicesChosen} className='text-sm mt-2 bg-[#1D1C1C] rounded-xl border border-white px-3 py-3 focus:outline-none text-white' />
                                }

                                {
                                    showServiceEdit &&
                                    <div className="text-base mt-2 bg-[#1D1C1C] w-full rounded-xl border border-white pl-5 pr-0 py-3  text-white">
                                        <select
                                            value={serviceOffered}
                                            onChange={e => setServiceOffered(e.target.value)}
                                            className='bg-[#1D1C1C] w-11/12 focus:outline-none text-white'
                                        >
                                            <option className=' text-black font-bold'>Select Service</option>
                                            <option value="driver" className=' text-black font-bold'>Only Driver</option>
                                            <option value="vehicle" className=' text-black font-bold'>Only Vehicle</option>
                                            <option value="vehicle_driver" className=' text-black font-bold'> Driver & Vehicle</option>
                                            {/* <option value='car only' className=' text-black font-bold'>Car Only</option>
                                            <option value='driver and car' className='text-black font-bold'>Driver & Car</option> */}
                                        </select>
                                    </div>
                                }

                            </div>

                            

                            



                            {/* <div className="flex flex-col my-6 md:my-12">
                                <label htmlFor="Availability:" className='text-xs text-white font-normal'>Availability <span className="text-red-600">*</span></label>

                                <input type="text" defaultValue={availability} className='text-sm mt-2 bg-[#1D1C1C] rounded-xl border border-white px-3 py-3 focus:outline-none text-white' />
                            </div> */}

                            <div className="flex flex-col">
                                <label htmlFor="Address" className='text-xs text-white font-normal'>Company Name <span className="text-red-600">*</span></label>

                                <input type="text" 
                                    defaultValue={editCompanyName || companyName} 
                                    onChange={e => setEditCompanyName(e.target.value)}
                                    className='text-sm mt-2 bg-[#1D1C1C] rounded-xl border border-white px-3 py-3 focus:outline-none text-white' />
                            </div>

                            <div className="flex flex-col my-6 md:my-12">
                                <label htmlFor="Address" className='text-xs text-white font-normal'>Phone Number <span className="text-red-600">*</span></label>

                                <input type="Phone" 
                                    defaultValue={editPhoneNo || phoneNo} 
                                    onChange={e => setEditPhoneNo(e.target.value)}
                                    className='text-sm mt-2 bg-[#1D1C1C] rounded-xl border border-white px-3 py-3 focus:outline-none text-white' />
                            </div>




                            <div className="flex my-6 md:my-12 w-full gap-5 component-approval-form-location-wrapper">
                                <div className="flex flex-col w-1/2">
                                    <label htmlFor="State" className='text-xs text-white font-normal'>State <span className="text-red-600">*</span></label>
                                    <input type="text" 
                                        defaultValue={editStateLocation || stateLocation} 
                                        onChange={e => setEditStateLocation(e.target.value)}
                                        className='text-sm mt-2 bg-[#1D1C1C] rounded-xl border border-white px-3 py-3 focus:outline-none text-white' />
                                </div>
                                <div className="flex flex-col w-1/2">
                                    <label htmlFor="Country" className='text-xs text-white font-normal'>Country <span className="text-red-600">*</span></label>
                                    <input type="text" defaultValue={editCountryLocation || countryLocation} className='text-sm mt-2 bg-[#1D1C1C] rounded-xl border border-white px-3 py-3 focus:outline-none text-white' 
                                    onChange={e => setEditCountryLocation(e.target.value)}/>

                                </div>
                            </div>


                            <div className="flex gap-2 mt-10 w-full">
                                <button className='bg-[#083D8C] font-semibold text-white p-2 rounded-md md:w-2/4 w-3/4' onClick={() => {navigate('/service-provider-dashboard')
                                gaEventTracker('To dashboard')    
                                }
                                }
                                >
                                    Go To Dashboard
                                </button>
                            </div>

                            <div className="flex  mt-[5px] flex-col ">
                            {
                                statusProfile === 'Pending' &&
                                <button className='bg-blue-900 rounded text-white px-4 w-1/2 py-2 font-semibold text-sm'>Pending</button>
                            }

                            {
                                statusProfile === 'Approved' &&
                                <>
                                    {/* <button className='bg-amber-500 rounded text-black px-4 py-1 font-bold text-sm  md:w-28'>Approved</button> */}
                                    <div className="my-6 mb-2 flex gap-5 mt-8 md:gap-2 w-11/12">
                                        <button className='bg-blue-900 rounded text-white px-4 py-2 md:py-2 text-xs md:px-3 font-bold md:text-sm w-8/12'>Account Verified</button>
                                        {
                                            (compareDate === false) &&
                                            <button
                                                className='bg-white bg-opacity-50 rounded text-black  px-4 py-2 md:py-2 text-xs  font-bold md:text-sm w-4/12'
                                                disabled={true}
                                            >
                                                Save
                                            </button>
                                        } 

                                        {
                                            (compareDate === true) &&
                                            (
                                                !companySaved ?
                                                <button
                                                    className='flex justify-center items-center bg-white rounded text-black  px-4 py-2 md:py-2 text-xs  font-bold md:text-sm w-6/12 md:w-4/12'
                                                    onClick={onCompanyUpdate}
                                                >
                                                    Save
                                                    {
                                                        companyLoad && 
                                                        <input
                                                            type="image"
                                                            src={Spin}
                                                            value="Loading"
                                                            className="w-4 md:w-6"
                                                        />
                                                    }
                                                </button> :
                                                <button
                                                    className='flex justify-center items-center bg-[#FFA100] rounded text-black  px-4 py-2 md:py-2 text-xs  font-bold md:text-sm w-6/12 md:w-4/12'
                                                >
                                                    Saved
                                                </button>
                                            )
                                        } 
                                    </div>  
                                    <p className='text-red-600 mb-2 text-xs'>You are eigible to edit 30 days after approval</p>                          
                                </>
                            }

                            {
                                statusProfile === 'Rejected' && 
                                <>
                                    <div className="flex items-center gap-[20px]">
                                        <button className='bg-red-600 rounded text-white px-4 py-1 font-semibold text-sm w-1/4'>Rejected</button>

                                        <p className='text-[#FFA100]'>Edit your informations and resubmit for Approval</p>
                                    </div>
                                    <div className="my-6 flex gap-5 mt-8 md:gap-2 w-11/12">

                                        {
                                            !companySaved ?
                                            <button
                                                className='flex justify-center items-center bg-white rounded text-black  px-4 py-2 md:py-2 text-xs  font-bold md:text-sm w-6/12 md:w-4/12'
                                                onClick={onCompanyUpdate}
                                            >
                                                Save
                                                {
                                                    companyLoad && 
                                                    <input
                                                        type="image"
                                                        src={Spin}
                                                        value="Loading"
                                                        className="w-4 md:w-6"
                                                    />
                                                }
                                            </button> :
                                            <button
                                                className='flex justify-center items-center bg-[#FFA100] rounded text-black  px-4 py-2 md:py-2 text-xs  font-bold md:text-sm w-6/12 md:w-4/12'
                                            >
                                                Saved
                                            </button>
                                        }
                                    </div> 
                                </>
                            }
                            </div>

                        </form>
                    </div>
                </div>

            </main>
        </div>

        <div className='md:px-10 md:w-4/12 w-full pt-12 md:pt-36 component-approval-doc-section' >
            

            <div className="md:flex justify-end hidden"><h4 className='font-bold text-amber-500 mb-8'>Provider’s ID: {providerId}</h4></div>

            <div className="flex justify-between mb-10 items-center">
                <h2 className='text-white font-bold text-lg'>My Documents</h2>
                {/* <img src={eyee} alt="" className='md:w-10' /> */}
            </div>

            {/* <div className="my-8">
                <div className="flex justify-between border border-white p-2 md:p-3 items-center">
                    <p className='text-white md:text-sm text-xs font-normal'>Driver’s License </p>
                    <button className='border-theme-color px-3 py-1 md:text-sm md:px-2 md:py-0 text-xs text-white'>View</button>
                </div>

                <div className="flex justify-end mt-4">
                    <button className='bg-amber-500 rounded text-black p-1 font-bold text-sm  w-20'>Accepted</button>
                </div>
            </div> */}

            {
                (companyCertificate?.filter(obj => obj?.doc_type.includes("business_certificate")).map((docItem) => {
                    return (
                        <div className="">
                        {
                            (docItem.status === "Pending") &&
                            <div className="flex justify-between border border-white p-2 md:p-3 items-center">
                                <p className='text-white md:text-sm text-xs font-normal'> Company Business certificate </p>
                                <a target="_blank" href={`https://api.roadlers.com/storage/${docItem.document}`} className='border-theme-color px-3 py-1 md:text-sm md:px-2 md:py-0 text-xs text-white'>View</a>
                            </div>
                        }

                        {
                            (docItem.status === "Accept") &&
                            (
                                moment(getToday).isAfter(moment(docItem.edit_date).format("YYYY-MM-DD")) === false ?
                                <div className="flex justify-between border border-white p-2 md:p-3 items-center">
                                    <p className='text-white md:text-sm text-xs font-normal'> Company Business certificate </p>
                                    <a target="_blank" href={`https://api.roadlers.com/storage/${docItem.document}`} className='border-theme-color px-3 py-1 md:text-sm md:px-2 md:py-0 text-xs text-white'>View</a>
                                </div> :
                                <div className="flex justify-between border bg-[#1D1C1C] border-white p-2 md:p-3 items-center">
                                    {
                                        isSelected && selectedFile?.name ?(
                                            <p className='text-white md:text-base text-xs font-normal'>{selectedFile?.name}</p>
                                        ) : (
                                            <p className='text-white md:text-base text-xs font-normal'>Company Business Certificate<span className="text-red-600">*</span> </p>
                                        )
                                    }


                                    {/* use useRef to trigger this input field for Document upload */}
                                    <input
                                        type="file"
                                        style={{ display: 'none' }}
                                        ref={companyCertRef}
                                        accept="image/jpeg,image/jpg,application/pdf"

                                        onChange={changeHandler} 
                                    />


                                    {
                                        ( selectedFile?.name) ? (
                                            <button
                                                onClick={(event) => {
                                                    event.preventDefault()
                                                    companyCertRef.current.click()
                                                }}
                                                className='border-theme-color px-3 py-1 md:text-base md:px-2 md:py-0 text-xs text-white'>
                                                    Change
                                            </button>

                                        ) : (
                                            <button
                                                onClick={(event) => {
                                                    event.preventDefault()
                                                    companyCertRef.current.click()
                                                }}
                                                className='border-theme-color px-3 py-1 md:text-base md:px-2 md:py-0 text-xs text-white'>
                                                    Upload
                                            </button>
                                        )
                                    }

                                </div> 
                            )
                        }

                        {
                            (docItem.status === "Rejected") &&
                            <div className="flex justify-between border bg-[#1D1C1C] border-white p-2 md:p-3 items-center">
                                {
                                    isSelected && selectedFile?.name ?(
                                        <p className='text-white md:text-base text-xs font-normal'>{selectedFile?.name}</p>
                                    ) : (
                                        <p className='text-white md:text-base text-xs font-normal'>Company Business Certificate<span className="text-red-600">*</span> </p>
                                    )
                                }


                                {/* use useRef to trigger this input field for Document upload */}
                                <input
                                    type="file"
                                    style={{ display: 'none' }}
                                    ref={companyCertRef}
                                    accept="image/jpeg,image/jpg,application/pdf"

                                    onChange={changeHandler} 
                                />


                                {
                                    ( selectedFile?.name) ? (
                                        <button
                                            onClick={(event) => {
                                                event.preventDefault()
                                                companyCertRef.current.click()
                                            }}
                                            className='border-theme-color px-3 py-1 md:text-base md:px-2 md:py-0 text-xs text-white'>
                                                Change
                                        </button>

                                    ) : (
                                        <button
                                            onClick={(event) => {
                                                event.preventDefault()
                                                companyCertRef.current.click()
                                            }}
                                            className='border-theme-color px-3 py-1 md:text-base md:px-2 md:py-0 text-xs text-white'>
                                                Upload
                                        </button>
                                    )
                                }

                            </div>
                        }

                        <div className="flex justify-end mt-4">
                            <div className="flex justify-end mt-4">
                                {
                                    docItem.status === "Rejected" ?
                                    
                                    <div className="flex justify-end mt-2 gap-3">
                                        {
                                            !isCertSaved &&
                                            <button className=' flex justify-center items-center bg-white rounded text-[#000] px-4 py-1 font-semibold text-sm' onClick={handleCompanyCertUpdate}>
                                                Resend
                                                {
                                                    certLoad && 
                                                    <input
                                                        type="image"
                                                        src={Spin}
                                                        value="Loading"
                                                        className="w-4 md:w-6"
                                                    />
                                                }
                                            </button>
                                        }

                                        {
                                            isCertSaved &&
                                            <button className='bg-amber-600 rounded text-[#000] px-4 py-1 font-semibold text-sm' disabled={true}>Saved</button>
                                        }

                                        <button className='bg-red-600 rounded text-white px-4 py-1 font-semibold text-sm' disabled={true}>Rejected</button>
                                    </div> :
                                    <>
                                        {
                                            docItem.status === "Pending" &&
                                            <button className='bg-blue-900 rounded text-white px-4 py-1 font-semibold text-sm' disabled={true}>Pending</button>
                                        }

                                        {
                                            docItem.status === "Accept" &&
                                            <div className="flex justify-end mt-2 gap-3">
                                                {
                                                    (!isCertSaved && (moment(getToday).isAfter(moment(docItem.edit_date).format("YYYY-MM-DD")) === true)) &&
                                                    <button className=' flex justify-center items-center bg-white rounded text-[#000] px-4 py-1 font-semibold text-sm' onClick={handleCompanyCertUpdate}>
                                                        Resend
                                                        {
                                                            certLoad && 
                                                            <input
                                                                type="image"
                                                                src={Spin}
                                                                value="Loading"
                                                                className="w-4 md:w-6"
                                                            />
                                                        }
                                                    </button>
                                                }

                                                {
                                                    isCertSaved &&
                                                    <button className='bg-amber-600 rounded text-[#000] px-4 py-1 font-semibold text-sm' disabled={true}>Saved</button>
                                                }

                                                <button className='bg-amber-500 rounded text-white px-4 py-1 font-semibold text-sm' disabled={true}>Accepted</button>
                                            </div>


                                        }
                                    </>
                                }
                            </div>

        
                        </div>
                    </div>
                    )
                }))
            }


            <div className="flex flex-col items-end justify-center gap-4 w-full mt-8">
                {
                    services === 'vehicle' || services === 'vehicle_driver' ?
                    <button className='bg-white rounded text-black p-2  font-bold md:text-sm text-xs w-3/4' onClick={() => navigate('/driver-car-registration')}>Add Vehicle</button> :
                    ''
                }

                {
                    services === 'driver' || services ==='vehicle_driver' ?
                    <button className='bg-white rounded text-black p-2 font-bold md:text-sm text-xs w-3/4' onClick={() => navigate('/driver-registeration')}>Add a Driver</button> :
                    ''
                }
            </div>
        </div>
    </>
  )
}

export default ApprovedCompanyComponent