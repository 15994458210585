import { createSlice } from "@reduxjs/toolkit";
const initialState = 0;
const notifSlice = createSlice({
  name: "notif",
  initialState,
  reducers: {
    setNotif: (state, action) => {
      return { notif: action.payload };
    },
  },
});
const { reducer, actions } = notifSlice;
export const { setNotif } = actions
export default reducer;