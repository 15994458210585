import React, { Fragment, useState, useEffect, useRef, useContext } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import axios from "axios";
import { BASE_URL } from "../utils/constant"

import Paginate from '../Paginate';
import BookNow from './BookNow';
import location from '../../Images/locationicon.png'
import date from '../../Images/date.png'
import asset_one from '../../Images/driver3.png'
import Header from "../Header"
import Spin from "../../Images/spinner.gif"

import cancelImg from '../../Images/cancel.svg'
import DataContext from '../../Context/DataContext'
import DatePicker from "react-datepicker";
import moment from 'moment';

import { GoogleMap, useJsApiLoader, Autocomplete } from '@react-google-maps/api';

function SearchPage() {
    let { carSearch, setCarSearch } = useContext(DataContext)

    let { search, setSearch } = useContext(DataContext)
    let {  handleFilter, handleSearchLocation } = useContext(DataContext)



    const { } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyDkMuW-hGvdRfUhsb2R9RFqA0Pq4Qxg4Cs",
        libraries: ['places'],

    })
    const refDiv = useRef()

    const [load, setLoad] = useState("")


    const [open, setOpen] = useState(false);


    const [vehicleType, setVehicleType] = useState('')
    const [searchLocation, setSearchLocation] = useState('')
    const [searchPrice, setSearchPrice] = useState(0)
    const [searchModel, setSearchModel] = useState('')
    const [searchAvailability, setSearchAvailability] = useState('')
    const [startDate, setStartDate] = useState('');



    // handles the button bg color for filter
    const [suvState, setSuvState] = useState("inactive");
    const [saloonState, setSaloonState] = useState("inactive");
    const [luxuryState, setLuxuryState] = useState("inactive");

    const [tenState, setTenState] = useState("inactive");
    const [fifteenState, setFifteenState] = useState("inactive");
    const [twentyOneState, setTwentyOneState] = useState("inactive");

    const [withinState, setWithinState] = useState("inactive");
    const [interState, setInterState] = useState("inactive");

    async function setSUVFilter(e) {
        e.preventDefault()
        setVehicleType('SUV Car(cars)')
        setSuvState('active')

        setSaloonState('inactive')
        setLuxuryState('inactive')

        await axios({
            method: 'get',
            url: `${BASE_URL}/v1/filter-search-vehicle?search_location=${searchLocation}&search_vehicle=SUV Car(cars)&price=${searchPrice}&model=${searchModel}&availability=${searchAvailability}&date=${startDate}`,
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
            }
        }).then((response) => {
            const respData = response.data.data
            setAssets(respData);
            setResult(respData)
        }).catch((error) => {
            console.log(error)
        })


    }

    async function setSaloonFilter(e) {
        e.preventDefault()
        setVehicleType('Saloon Car(cars)')
        setSaloonState('active')
        setSuvState('inactive')
        setLuxuryState('inactive')
        await axios({
            method: 'get',
            url: `${BASE_URL}/v1/filter-search-vehicle?search_location=${searchLocation}&search_vehicle=Saloon Car(cars)&price=${searchPrice}&model=${searchModel}&availability=${searchAvailability}&date=${startDate}`,
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
            }
        }).then((response) => {
            const respData = response.data.data
            setAssets(respData);
            setResult(respData)
        }).catch((error) => {
            console.log(error)
        })

    }
    async function setLuxuryFilter(e) {
        e.preventDefault()
        setVehicleType('Luxury Car(cars)')
        setLuxuryState('active')
        setSaloonState('inactive')
        setSuvState('inactive')
        await axios({
            method: 'get',
            url: `${BASE_URL}/v1/filter-search-vehicle?search_location=${searchLocation}&search_vehicle=Luxury Car(cars)&price=${searchPrice}&model=${searchModel}&availability=${searchAvailability}&date=${startDate}`,
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
            }
        }).then((response) => {
            const respData = response.data.data
            setAssets(respData);
            setResult(respData)
        }).catch((error) => {
            console.log(error)
        })


    }


    async function clickModelTen(e) {
        e.preventDefault()
        setSearchModel(2010)
        setTenState('active')

        setFifteenState('inactive')
        setTwentyOneState('inactive')

        await axios({
            method: 'get',
            url: `${BASE_URL}/v1/filter-search-vehicle?search_location=${searchLocation}&search_vehicle=${vehicleType}&price=${searchPrice}&model=2010&availability=${searchAvailability}&date=${startDate}`,
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
            }
        }).then((response) => {
            const respData = response.data.data
            setAssets(respData);
            setResult(respData)
        }).catch((error) => {
            console.log(error)
        })
    }

    async function clickModelFifteen(e) {
        e.preventDefault()
        setSearchModel(2015)

        setTenState('inactive')

        setFifteenState('active')
        setTwentyOneState('inactive')
        await axios({
            method: 'get',
            url: `${BASE_URL}/v1/filter-search-vehicle?search_location=${searchLocation}&search_vehicle=${vehicleType}&price=${searchPrice}&model=2015&availability=${searchAvailability}&date=${startDate}`,
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
            }
        }).then((response) => {
            const respData = response.data.data
            setAssets(respData);
            setResult(respData)
        }).catch((error) => {
            console.log(error)
        })
    }
    async function clickModelTwentyOne(e) {
        e.preventDefault()
        setSearchModel(2021)
        setTenState('inactive')

        setFifteenState('inactive')
        setTwentyOneState('active')
        await axios({
            method: 'get',
            url: `${BASE_URL}/v1/filter-search-vehicle?search_location=${searchLocation}&search_vehicle=${vehicleType}&price=${searchPrice}&model=2021&availability=${searchAvailability}&date=${startDate}`,
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
            }
        }).then((response) => {
            const respData = response.data.data
            setAssets(respData);
            setResult(respData)
        }).catch((error) => {
            console.log(error)
        })
    }

    async function clickWithinState(e) {
        e.preventDefault()
        setSearchAvailability('no')

        setWithinState('active')
        setInterState('inactive')

        await axios({
            method: 'get',
            url: `${BASE_URL}/v1/filter-search-vehicle?search_location=${searchLocation}&search_vehicle=Saloon Car(cars)&price=${searchPrice}&model=${searchModel}&availability=no&date=${startDate}`,
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
            }
        }).then((response) => {
            const respData = response.data.data
            setAssets(respData);
            setResult(respData)
        }).catch((error) => {
            console.log(error)
        })
    }

    async function clickInterState(e) {
        e.preventDefault()
        setSearchAvailability('yes')

        setWithinState('inactive')
        setInterState('active')

        await axios({
            method: 'get',
            url: `${BASE_URL}/v1/filter-search-vehicle?search_location=${searchLocation}&search_vehicle=Saloon Car(cars)&price=${searchPrice}&model=${searchModel}&availability=yes&date=${startDate}`,
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
            }
        }).then((response) => {
            const respData = response.data.data
            setAssets(respData);
            setResult(respData)
        }).catch((error) => {
            console.log(error)
        })
    }


    const [car, setCar] = useState(false)
    const [bus, setBus] = useState(false)
    const [truck, setTruck] = useState(false)
    const [van, setVan] = useState(false)
    const [bike, setBike] = useState(false)
    const [all, setAll] = useState(false)
    const [suv, setSuv] = useState(false)
    const [saloon, setSaloon] = useState(false)
    const [luxury, setLuxury] = useState(false)

    const [bg, setBg] = useState(false)


    //Search State
    const [value, setValue] = useState('');
    const [locationValue, setLocationValue] = useState('');

    const [assets, setAssets] = useState(carSearch);
    const [result, setResult] = useState(carSearch);
    useEffect(() => {
        setResult(carSearch)
    }, [carSearch])

    // const [currentPage, setCurrentPage] = useState(1)
    // const [postPerPage, setPostPerPage] = useState(12)


    const [filterOpen, setFilterOpen] = useState(false)

    const clickBus = async (e) => {
        e.preventDefault()
        setBus(true)
        setCar(false)
        setTruck(false)
        setVan(false)
        setBike(false)
        setSuv(false)
        setSaloon(false)
        setLuxury(false)
        const searchVehicle = 'Bus'
        await axios({
            method: 'get',
            url: `${BASE_URL}/v1/filter-search-vehicle?search_vehicle=${searchVehicle}`,
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
            }
        }).then(function (response) {
            const respData = response.data.data;

            setAssets(respData);
            setResult(respData)

        })
            .catch(function (error) {
                console.log("error ", error)
            });


    }

     const clickMini = async (e) => {
        e.preventDefault()
        setBus(true)
        setCar(false)
        setTruck(false)
        setVan(false)
        setBike(false)
        setSuv(false)
        setSaloon(false)
        setLuxury(false)
        const searchVehicle = 'Mini Bus(bus)'
        await axios({
            method: 'get',
            url: `${BASE_URL}/v1/filter-search-vehicle?search_vehicle=${searchVehicle}`,
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
            }
        }).then(function (response) {
            const respData = response.data.data;

            setAssets(respData);
            setResult(respData)
        })
            .catch(function (error) {
                console.log("error ", error)
            });
    }


    const clickCoaster = async (e) => {
        e.preventDefault()
        setBus(true)
        setCar(false)
        setTruck(false)
        setVan(false)
        setBike(false)
        setSuv(false)
        setSaloon(false)
        setLuxury(false)
        const searchVehicle = 'Coaster Bus(bus)'
        await axios({
            method: 'get',
            url: `${BASE_URL}/v1/filter-search-vehicle?search_vehicle=${searchVehicle}`,
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
            }
        }).then(function (response) {
            const respData = response.data.data;

            setAssets(respData);
            setResult(respData)
        })
            .catch(function (error) {
                console.log("error ", error)
            });
    }


    const clickLuxuryB = async (e) => {
        e.preventDefault()
        setBus(true)
        setCar(false)
        setTruck(false)
        setVan(false)
        setBike(false)
        setSuv(false)
        setSaloon(false)
        setLuxury(false)
        const searchVehicle = 'Luxury  Bus'
        await axios({
            method: 'get',
            url: `${BASE_URL}/v1/filter-search-vehicle?search_vehicle=${searchVehicle}`,
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
            }
        }).then(function (response) {
            const respData = response.data.data;

            setAssets(respData);
            setResult(respData)
        })
            .catch(function (error) {
                console.log("error ", error)
            });
    }

    const clickTruck = async (e) => {
        e.preventDefault()
        setTruck(true)
        setVan(false)
        setBus(false)
        setCar(false)
        setBike(false)
        setSuv(false)
        setSaloon(false)
        setLuxury(false)
        const searchVehicle = 'Truck'
        await axios({
            method: 'get',
            url: `${BASE_URL}/v1/filter-search-vehicle?search_vehicle=${searchVehicle}`,
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
            }
        }).then(function (response) {
            const respData = response.data.data;

            setAssets(respData);
            setResult(respData)

        })
            .catch(function (error) {
                console.log("error ", error)
            });


    }

     const clickFlatTrailer = async (e) => {
        e.preventDefault()
        setTruck(true)
        setVan(false)
        setBus(false)
        setCar(false)
        setBike(false)
        setSuv(false)
        setSaloon(false)
        setLuxury(false)
        const searchVehicle = 'Flat Bird Trailer'
        await axios({
            method: 'get',
            url: `${BASE_URL}/v1/filter-search-vehicle?search_vehicle=${searchVehicle}`,
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
            }
        }).then(function (response) {
            const respData = response.data.data;

            setAssets(respData);
            setResult(respData)

        })
            .catch(function (error) {
                console.log("error ", error)
            });


    }

    const clickTonsTrailer = async (e) => {
        e.preventDefault()
        setTruck(true)
        setVan(false)
        setBus(false)
        setCar(false)
        setBike(false)
        setSuv(false)
        setSaloon(false)
        setLuxury(false)
        const searchVehicle = 'Tons Trailer'
        await axios({
            method: 'get',
            url: `${BASE_URL}/v1/filter-search-vehicle?search_vehicle=${searchVehicle}`,
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
            }
        }).then(function (response) {
            const respData = response.data.data;

            setAssets(respData);
            setResult(respData)

        })
            .catch(function (error) {
                console.log("error ", error)
            });


    }

    const clickCaterpillar = async (e) => {
        e.preventDefault()
        setTruck(true)
        setVan(false)
        setBus(false)
        setCar(false)
        setBike(false)
        setSuv(false)
        setSaloon(false)
        setLuxury(false)
        const searchVehicle = 'Caterpillar'
        await axios({
            method: 'get',
            url: `${BASE_URL}/v1/filter-search-vehicle?search_vehicle=${searchVehicle}`,
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
            }
        }).then(function (response) {
            const respData = response.data.data;

            setAssets(respData);
            setResult(respData)

        })
            .catch(function (error) {
                console.log("error ", error)
            });


    }

    const clickVan = async (e) => {
        e.preventDefault()
        setVan(true)
        setBus(false)
        setCar(false)
        setTruck(false)
        setBike(false)
        setSuv(false)
        setSaloon(false)
        setLuxury(false)

        const searchVehicle = 'Van'
        await axios({
            method: 'get',
            url: `${BASE_URL}/v1/filter-search-vehicle?search_vehicle=${searchVehicle}`,
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
            }
        }).then(function (response) {
            const respData = response.data.data;

            setAssets(respData);
            setResult(respData)

        })
            .catch(function (error) {
                console.log("error ", error)
            });


    }

    const clickVanEmergency = async (e) => {
        e.preventDefault()
        setVan(true)
        setBus(false)
        setCar(false)
        setTruck(false)
        setBike(false)
        setSuv(false)
        setSaloon(false)
        setLuxury(false)

        const searchVehicle = 'Emergency'
        await axios({
            method: 'get',
            url: `${BASE_URL}/v1/filter-search-vehicle?search_vehicle=${searchVehicle}`,
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
            }
        }).then(function (response) {
            const respData = response.data.data;

            setAssets(respData);
            setResult(respData)

        })
            .catch(function (error) {
                console.log("error ", error)
            });


    }

    const clickVanLuxury = async (e) => {
        e.preventDefault()
        setVan(true)
        setBus(false)
        setCar(false)
        setTruck(false)
        setBike(false)
        setSuv(false)
        setSaloon(false)
        setLuxury(false)
        const searchVehicle = 'Luxury  Bus/Van'
        await axios({
            method: 'get',
            url: `${BASE_URL}/v1/filter-search-vehicle?search_vehicle=${searchVehicle}`,
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
            }
        }).then(function (response) {
            const respData = response.data.data;
            setAssets(respData);
            setResult(respData)
        })
            .catch(function (error) {
                console.log("error ", error)
            });
    }
    //Handle get vehicle for all listed vehicles on the database
    const clickAll = async (e) => {
        e.preventDefault()
        setCar(false)
        setBus(false)
        setTruck(false)
        setVan(false)
        setBike(false)
        setAll(true)
        await axios({
            method: 'get',
            url: `${BASE_URL}/v1/list-vehicle`,
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
            }
        }).then(function (response) {
            const allVehicle = response.data.data;

            setAssets(allVehicle);
            setResult(allVehicle)

        })
            .catch(function (error) {
                console.log("error ", error)
            });


    }

    //Handle get vehicle for all CArs category on the database
    const clickCar = async (e) => {
        e.preventDefault()
        setCar(true)
        setBus(false)
        setTruck(false)
        setVan(false)
        setBike(false)

        const searchVehicle = 'cars'
        await axios({
            method: 'get',
            url: `${BASE_URL}/v1/filter-search-vehicle?search_vehicle=${searchVehicle}`,
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
            }
        }).then(function (response) {
            const suvVehicle = response.data.data;

            setAssets(suvVehicle);
            setResult(suvVehicle)

        })
            .catch(function (error) {
                console.log("error ", error)
            });


    }


    // const getAssets = async () => {
    //     await axios.get("https://api.roadlers.com/api/v1/list-vehicle").then((response) => {
    //         const allVehicle = response.data.data;
    //         console.log('my vehicles', allVehicle)
    //         setAssets(allVehicle);
    //         setResult(allVehicle)

    //     });
    // };

    // useEffect(() => {
    //     getAssets();
    // }, []);

    // handle Filter pop up
    let setPickedDate = moment(startDate).format("DD/MM/YYYY")

    const handleFilterSearch = (e) => {
        e.preventDefault();
        closeModal()
        // axios({
        //     method: 'get',
        //     url: `${BASE_URL}/v1/filter-search-vehicle?search_location=${searchLocation}&search_vehicle=${vehicleType}&price=${searchPrice}&model=${searchModel}&availability=${searchAvailability}&date=${setPickedDate}`,
        //     // data: formData,
        //     headers: {
        //         'Content-Type': 'application/json',
        //         "Access-Control-Allow-Origin": "*",
        //     }
        // }).then((response) => {
        //     const respData = response.data.data
        //     setAssets(respData);
        //     setResult(respData)

        // }).catch((error) => {
        //     console.log(error)
        // })
    }
    //Handle get vehicle for all SUVs on the database
    const clickSuv = async (e) => {
        e.preventDefault()
        setSuv(true)
        setSaloon(false)
        setLuxury(false)
        const searchVehicle = 'SUV Car(cars)'
        await axios({
            method: 'get',
            url: `${BASE_URL}/v1/filter-search-vehicle?search_vehicle=${searchVehicle}`,
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
            }
        }).then(function (response) {
            const suvVehicle = response.data.data;

            setAssets(suvVehicle);
            setResult(suvVehicle)

        })
            .catch(function (error) {
                console.log("error ", error)
            });


    }

    // end of Handle get vehicle for all SUVs on the database



    //Handle get vehicle for all saloon cars on the database
    const clickSaloon = async (e) => {
        e.preventDefault()
        setSuv(false)
        setSaloon(true)
        setLuxury(false)
        const searchVehicle = 'Saloon Car(cars)'
        await axios({
            method: 'get',
            url: `${BASE_URL}/v1/filter-search-vehicle?search_vehicle=${searchVehicle}`,
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
            }
        }).then(function (response) {
            const suvVehicle = response.data.data;

            setAssets(suvVehicle);
            setResult(suvVehicle)

        })
            .catch(function (error) {
                console.log("error ", error)
            });


    }
    // end of Handle get vehicle for all saloon cars on the database


    //Handle get vehicle for all luxury cars on the database

    const clickLuxury = async (e) => {
        e.preventDefault()
        setLuxury(true)
        setSaloon(false)
        setSuv(false)
        const searchVehicle = 'Luxury'
        await axios({
            method: 'get',
            url: `${BASE_URL}/v1/filter-search-vehicle?search_vehicle=${searchVehicle}`,
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
            }
        }).then(function (response) {
            const luxVehicle = response.data.data;
console.log('luxVehicle',luxVehicle)
            setAssets(luxVehicle);
            setResult(luxVehicle)
        })
            .catch(function (error) {
                //handle error
                console.log("error ", error)
            });

    }
    // end of Handle get vehicle for all luxury cars on the database


    function closeFilterModal() {
        setFilterOpen(false)
        setBg(false)


    }

    function filterModal() {

        setFilterOpen(true)
        setBg(true)


    }


    const handleSearch = (e) => {
        let search = e.target.value.toLowerCase();
        setSearch(e.target.value)

        setValue(search)

        setResult(
            assets.filter((data) => {
                return data.vehicle_name.toLowerCase().includes(search.toLowerCase()) || (data.year && data.year.toLowerCase().includes(search.toLowerCase()));
            })
        )
        e.preventDefault();
    }

    const handleSearchByLocation = (e) => {
        e.preventDefault();

        let searchLocations = e.target.value.toLowerCase();
        setLocationValue(searchLocations)

        setResult(
            assets.filter((data) => {
                return data.state.toLowerCase().includes(searchLocations.toLowerCase())
            })
        )
    }

    // for search page button
    function handleFilterSearchs(e) {
        console.log('type search')
        e.preventDefault()
        setLoad(Spin)


        axios({
            method: 'get',
            url: `${BASE_URL}/v1/filter-search-vehicle?search_location=${searchLocation}&search_vehicle=${search}`,
            headers: {
                'Content-Type': 'application/json',
                // Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            }
        }).then((response) => {
            setSearch('')
            setSearchLocation('')
            const respData = response.data.data

            console.log('car ti mo search', respData)
            setAssets(respData)
            setResult(respData)

            setLoad("")
            //  navigate('/search')


        }).catch((error) => {
            console.log(error)

            setLoad("")
        })
    }




    function closeModal() {


        setFilterOpen(false)
        setBg(false)


    }
    const ResetFilter = async (e) => {
        e.preventDefault();

        await axios.get(`${BASE_URL}/v1/list-vehicle`).then((response) => {
            const allVehicle = response.data.data;
            console.log('my vehicles', allVehicle)
            setAssets(allVehicle);
            setResult(allVehicle)

        });
        setSuvState('inactive')
        setSaloonState('inactive')
        setLuxuryState('inactive')
        setTenState('inactive')
        setFifteenState('inactive')
        setTwentyOneState('inactive')
        setWithinState('inactive')
        setInterState('inactive')


        setSearchPrice(0)
    }

    const [visible, setVisible] = useState(8);

    function showMore() {
        setVisible((prevValue) => prevValue + 8)
    }



    // function handleFilter(e) {
    //     console.log('type search')
    //     e.preventDefault()
    //     setLoad(Spin)

    //     axios({
    //         method: 'get',
    //         url: `${BASE_URL}/v1/filter-search-vehicle?search_location=${locationValue}&search_vehicle=${value}`,
    //         headers: {
    //             'Content-Type': 'application/json',
    //             // Authorization: `Bearer ${token}`,
    //             "Access-Control-Allow-Origin": "*",
    //         }
    //     }).then((response) => {
    //         const respData = response.data.data

    //         console.log('car ti mo search', respData)
    //         setResult(respData)

    //         setLoad("")
    //         //  navigate('/search')


    //     }).catch((error) => {
    //         console.log(error)

    //         // setLoad("")
    //     })
    // }


   

      const clickBike = async (e) => {
        e.preventDefault()
        setBike(true)
        setCar(false)
        setBus(false)
        setTruck(false)
        setVan(false)
        setSuv(false)
        setSaloon(false)
        setLuxury(false)
        const searchVehicle = 'bike'
        await axios({
            method: 'get',
            url: `${BASE_URL}/v1/filter-search-vehicle?search_vehicle=${searchVehicle}`,
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
            }
        }).then(function (response) {
            const suvVehicle = response.data.data;

            setAssets(suvVehicle);
            setResult(suvVehicle)

        })
            .catch(function (error) {
                console.log("error ", error)
            });


    }


    const clickDelivery = async (e) => {
        e.preventDefault()
        setBike(true)
        setCar(false)
        setBus(false)
        setTruck(false)
        setVan(false)
        setSuv(false)
        setSaloon(false)
        setLuxury(false)
        const searchVehicle = 'Delivery'
        await axios({
            method: 'get',
            url: `${BASE_URL}/v1/filter-search-vehicle?search_vehicle=${searchVehicle}`,
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
            }
        }).then(function (response) {
            const suvVehicle = response.data.data;

            setAssets(suvVehicle);
            setResult(suvVehicle)

        })
            .catch(function (error) {
                console.log("error ", error)
            });


    }


    const clickPowerBike = async (e) => {
        e.preventDefault()
        setBike(true)
        setCar(false)
        setBus(false)
        setTruck(false)
        setVan(false)
        setSuv(false)
        setSaloon(false)
        setLuxury(false)
        const searchVehicle = 'Power Bike'
        await axios({
            method: 'get',
            url: `${BASE_URL}/v1/filter-search-vehicle?search_vehicle=${searchVehicle}`,
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*",
            }
        }).then(function (response) {
            const suvVehicle = response.data.data;

            setAssets(suvVehicle);
            setResult(suvVehicle)

        })
            .catch(function (error) {
                console.log("error ", error)
            });


    }
    return (
        <div className={bg === true ? " blur-sm contrast-50 backdrop-brightness-50" : "w-full"}>


            <Transition appear show={filterOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed flex  items-center justify-center w-full px-6 mt-12  inset-0 z-10 overflow-y-auto"
                    onClose={closeModal}
                >
                    <div className="min-h-screen w-full md:w-4/12 px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="w-full inline-block  max-w-md pt-3 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow rounded-md">


                                <div className=" p-6">

                                    <form>


                                        <div className="">
                                            <div className=" w-full gap-4 flex items-center justify-end">




                                                <div className="flex w-full justify-end">
                                                    <button
                                                        type='button'
                                                        className=''>
                                                        <img src={cancelImg} className='mt-12 md:mt-0 w-[30px]' onClick={closeFilterModal} alt="Cancel Modal" />
                                                    </button>
                                                </div>
                                            </div>

                                            <label htmlFor="" className='text-xs md:text-sm font-bold text-black'>Location</label>


                                            <div className="mt-3 rounded p-1 border border-gray-600 flex justify-between items-center text-sm  w-full ">

                                                <Autocomplete className='autcomplete-style'>

                                                    <input
                                                        type="text"

                                                        className='text-black md:text-sm text-xs w-full bg-transparent placeholder:text-black placeholder:text-xs  focus:outline-none'
                                                        // placeholder
                                                        value={searchLocation}
                                                        onChange={async(e) => {setSearchLocation(e.target.value)
                                                            await axios({
                                                                method: 'get',
                                                                url: `${BASE_URL}/v1/filter-search-vehicle?search_location=${e.target.value}&search_vehicle=${vehicleType}&price=${searchPrice}&model=${searchModel}&availability=${searchAvailability}&date=${startDate}`,
                                                                headers: {
                                                                    'Content-Type': 'application/json',
                                                                    "Access-Control-Allow-Origin": "*",
                                                                }
                                                            }).then((response) => {
                                                                const respData = response.data.data
                                                                setAssets(respData);
                                                                setResult(respData)
                                                            }).catch((error) => {
                                                                console.log(error)
                                                            })
                                                        }}



                                                    />
                                                </Autocomplete>


                                                <img src={location} alt="" />


                                            </div>
                                        </div>



                                        <div className="mt-3">
                                            <label htmlFor="" className=' text-xs md:text-sm font-bold text-black'>Category</label>

                                            <div className="mt-3 flex gap-4">

                                                <button
                                                    onClick={setSUVFilter}


                                                    type='button'
                                                    className={suvState === "active" ? 'bg-amber-500 border border-black p-1 w-1/3 font-bold text-black rounded md:text-xs text-xx' : 'focus:bg-transparent border border-black p-1 w-1/3 font-bold text-black rounded md:text-xs text-xx'}>
                                                    SUVs
                                                </button>

                                                <button
                                                    type='button'
                                                    // onClick={clickSaloon}
                                                    onClick={setSaloonFilter}

                                                    className={saloonState === "active" ? 'bg-amber-500 border border-black p-1 w-1/3 font-bold text-black rounded md:text-xs text-xx' : 'focus:bg-transparent border border-black p-1 w-1/3 font-bold text-black rounded md:text-xs text-xx'}>

                                                    Saloon Cars
                                                </button>

                                                <button
                                                    type='button'
                                                    onClick={setLuxuryFilter}

                                                    className={luxuryState === "active" ? 'bg-amber-500 border border-black p-1 w-1/3 font-bold text-black rounded md:text-xs text-xx' : 'focus:bg-transparent border border-black p-1 w-1/3 font-bold text-black rounded md:text-xs text-xx'}>
                                                    Luxury Cars
                                                </button>
                                            </div>





                                        </div>






                                        <div className="mt-3">
                                            <label htmlFor="" className='text-xs md:text-sm font-bold text-black'>Model</label>

                                            <div className="flex gap-4 mt-3">
                                                <button
                                                    type='button'
                                                    onClick={clickModelTen}

                                                    className={tenState === 'active' ? 'bg-amber-500 border border-black p-1   w-1/3 font-bold text-black rounded md:text-xs text-xx' : ' border border-black p-1   w-1/3 font-bold text-black rounded md:text-xs text-xx'}>
                                                    2007 - 2010
                                                </button>

                                                <button
                                                    type='button'
                                                    onClick={clickModelFifteen}
                                                    className={fifteenState === 'active' ? 'bg-amber-500 border border-black p-1   w-1/3 font-bold text-black rounded md:text-xs text-xx' : ' border border-black p-1   w-1/3 font-bold text-black rounded md:text-xs text-xx'}>

                                                    2011 - 2015
                                                </button>

                                                <button
                                                    type='button'
                                                    onClick={clickModelTwentyOne}
                                                    className={twentyOneState === 'active' ? 'bg-amber-500 border border-black p-1   w-1/3 font-bold text-black rounded md:text-xs text-xx' : ' border border-black p-1   w-1/3 font-bold text-black rounded md:text-xs text-xx'}>

                                                    2016 - 2021
                                                </button>
                                            </div>





                                        </div>


                                        <div className="mt-3 relative">
                                            <label htmlFor="" className=' text-xs md:text-sm font-bold text-black'>Price</label>


                                            <div class="flex justify-between mt-3">
                                                <h4>₦{Number(searchPrice).toLocaleString('en-US', { maximumFractionDigits: 2 })}</h4>
                                                <h4>₦900,000</h4>
                                            </div>

                                            <div class="price slidecontainer mt-2" >
                                                <input type="range" min="0" max="900000"
                                                        value={searchPrice}

                                                       onChange={async(e) => {
                                                            
                                                        setSearchPrice(e.target.value)
                                                        await axios({
                                                            method: 'get',
                                                            url: `${BASE_URL}/v1/filter-search-vehicle?search_location=${searchLocation}&search_vehicle=Saloon Car(cars)&price=${e.target.value}&model=${searchModel}&availability=${searchAvailability}&date=${startDate}`,
                                                            headers: {
                                                                'Content-Type': 'application/json',
                                                                "Access-Control-Allow-Origin": "*",
                                                            }
                                                        }).then((response) => {
                                                            const respData = response.data.data
                                                            setAssets(respData);
                                                            setResult(respData)
                                                        }).catch((error) => {
                                                            console.log(error)
                                                        })
                                                    }}

                                                    class="mySlider" id="myRange" />

                                                <div
                                                    id="background"

                                                ></div>

                                                <div
                                                    id="progress"
                                                    ref={refDiv}
                                                    style={{ width: searchPrice / 900000 * 100 + '%' }}

                                                >

                                                </div>
                                            </div>


                                        </div>


                                        <div className="mt-3">
                                            <label htmlFor="" className=' text-xs md:text-sm font-bold text-black'>Availability</label>

                                            <div className="flex gap-4 mt-3">
                                                <button
                                                    type='button'

                                                    onClick={clickWithinState}
                                                    className={withinState === 'active' ? 'bg-amber-500 border border-black p-1   w-1/3 font-bold text-black rounded md:text-xs text-xx' : ' border border-black p-1   w-1/3 font-bold text-black rounded md:text-xs text-xx'}>

                                                    Within State
                                                </button>

                                                <button
                                                    type='button'
                                                    onClick={clickInterState}
                                                    className={interState === 'active' ? 'bg-amber-500 border border-black p-1   w-1/3 font-bold text-black rounded md:text-xs text-xx' : ' border border-black p-1   w-1/3 font-bold text-black rounded md:text-xs text-xx'}>

                                                    Inter-state
                                                </button>


                                            </div>





                                        </div>


                                        {/* <div className="my-4 flex flex-col gap-3 dateee">
                                            <label htmlFor="" className='mt-3 text-xs md:text-sm font-bold text-black'>Date</label>


                                            <button
                                                type='button'
                                                onClick={() => setOpen((isOpen) => !isOpen)}>

                                                <div

                                                    className="flex  items-center gap-4 ">
                                                    <img src={date} alt="" className="w-7 " />
                                                    <label className='text-xs font-regular' htmlFor="input-id">When you need it</label>
                                                </div>
                                            </button>


                                            <div className="">
                                                <DatePicker
                                                    selected={startDate}
                                                    onChange={(date) => {
                                                        setStartDate(date)
                                                        setOpen(false)
                                                    }}

                                                    open={open}

                                                    style={{ color: "white" }}



                                                />
                                            </div>





                                        </div> */}




                                        <div className=" flex gap-4 mt-6">


                                            <button
                                                type='button'
                                                onClick={handleFilterSearch}

                                                className='bg-black border p-2   w-2/3 font-bold text-white rounded md:text-xs text-xx'>
                                                Apply Filter ({assets.length}) Results
                                            </button>

                                            <button
                                                type='button'
                                                onClick={ResetFilter}
                                                className='focus:bg-amber-500 border border-black p-2   w-1/3 font-bold text-black rounded md:text-xs text-xx'>
                                                Reset All
                                            </button>



                                        </div>



                                    </form>

                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>

            <Header user='login_customer' />


            <div className="flex flex-col px-8 w-full mb-20">
                <div className="flex flex-col md:mt-32 mt-24 justify-center md:w-2/6 w-full m-auto">
                    <p className='text-amber-500 text-center text-lg hidden md:block'>Best Pricing</p>
                    <h1 className='text-white text-center md:text-2xl text-xl font-bold mt-4 '>Find it - Book it - Ride it</h1>
                    <div className="flex md:justify-center md:items-center w-full">
                    <div className="flex md:justify-center md:items-center w-full">
                    <div className="mt-8 flex md:gap-8 gap-2 md:w-auto w-full sm:w-full">
                        <button
                            onClick={clickCar}
                            className={car === true ? "bg-amber-500 rounded-md text-xs md:text-base md:px-3 py-1 md:py-0 w-1/4 md:w-24 font-bold" : "hover:bg-amber-500 hover:text-black bg-transparent border text-xs md:text-base border-white rounded-md md:px-3 py-2 md:py-1 w-1/4 md:w-24  text-white"}>Cars</button>
                        <button onClick={clickBus} className={bus === false ? "hover:bg-amber-500 hover:text-black bg-transparent border text-xs md:text-base border-white rounded-md md:px-3 py-2 md:py-1 w-1/4 md:w-24  text-white" : "bg-amber-500  text-xs md:text-base font-bold  rounded-md md:px-3 py-2 md:py-1 w-1/4 md:w-24  text-black"}>Buses</button>
                        <button onClick={clickVan} className={van === false ? "hover:bg-amber-500 hover:text-black bg-transparent border text-xs md:text-base border-white rounded-md md:px-3 py-2 md:py-1 w-1/4 md:w-24  text-white" : "bg-amber-500  text-xs md:text-base font-bold  rounded-md md:px-3 py-2 md:py-1 w-1/4 md:w-24  text-black"}>Vans</button>
                        <button onClick={clickTruck} className={truck === false ? "hover:bg-amber-500 hover:text-black bg-transparent border text-xs md:text-base border-white rounded-md md:px-3 py-2 md:py-1 w-1/4 md:w-24  text-white" : "bg-amber-500  text-xs md:text-base font-bold  rounded-md md:px-3 py-2 md:py-1 w-1/4 md:w-24  text-black"}>Trucks</button>
                        <button onClick={clickBike} className={bike === false ? "md:flex hidden hover:bg-amber-500 hover:text-black bg-transparent border text-xs md:text-base border-white rounded-md md:px-3 py-2 md:py-1 w-1/4 md:w-24  text-white" : "md:flex hidden bg-amber-500  text-xs md:text-base font-bold  rounded-md md:px-3 py-2 md:py-1 w-1/4 md:w-24  text-black"}>Delivery</button>
                        <button onClick={clickBike} className={bike === false ? "flex justify-center md:hidden hover:bg-amber-500 hover:text-black bg-transparent border text-xs md:text-base border-white rounded-md md:px-3 py-2 md:py-1 w-1/4 md:w-24  text-white" : "flex justify-center md:hidden bg-amber-500  text-xs md:text-base font-bold  rounded-md md:px-3 py-2 md:py-1 w-1/4 md:w-24  text-black"}>Bikes</button>
                        <button onClick={clickAll} className={all === false ? "hover:bg-amber-500 hover:text-black bg-transparent border text-xs md:text-base border-white rounded-md md:px-3 py-2 md:py-1 w-1/4 md:w-24  text-white" : "bg-amber-500  text-xs md:text-base font-bold  rounded-md md:px-3 py-2 md:py-1 w-1/4 md:w-24  text-black"}>All</button>
                    
                    
                    </div>
                </div>
                </div>

                </div>


                <div className="mt-16 flex flex-col gap-8 xl:px-36 lg:px-16 md:px-12 w-full">
                   
                    <form action="" className='w-full m-auto md:flex gap-5 justify-center hidden'>
                        <input
                            type="text"
                            name=""
                            className='w-4/12 bg-white py-2 px-4 rounded-md placeholder:text-xs '
                            placeholder='Search by car name, model, and category'
                            onChange={handleSearch}


                            value={value}
                        />

                        <input
                            type="text"
                            name=""
                            className='w-3/12 bg-white py-2 rounded-md placeholder:text-xs px-4'
                            placeholder='Pick-Up Location e.g. Lagos'
                            onChange={(e) => handleSearchByLocation(e)}
                            value={locationValue}
                        />

                        {load ? (
                            <div className="bg-amber-500 rounded-md px-3 py-1 w-24 font-bold text-sm flex items-center">
                                <h4 className="text-black text-xs">Loading</h4>
                                <input
                                    type="image"
                                    src={Spin}
                                    value="Loading"
                                    className="w-8"
                                    alt="loading..."
                                />
                            </div>
                        ) :
                            (
                                <button
                                    type="button"
                            // onClick={handleFilter}

                                    onClick={handleFilterSearchs}
                                    className="bg-amber-500 rounded-md px-3 py-1 w-24 font-bold text-sm">Search</button>

                            )
                        }
                        <button
                            type="button"

                            onClick={filterModal}

                            className="bg-transparent border border-white rounded-md px-3 py-1 w-24  text-xs text-white">+ Add Filter</button>
                    </form>

                    <form action="" className='w-full m-auto flex flex-col gap-5 justify-center md:hidden'>
                        <div className="flex gap-3">
                            <input
                                type="text"
                                name=""
                                className='w-8/12 bg-white py-2 px-4 rounded-md placeholder:text-xs '
                                placeholder='Search by car name, model, and category'
                                onChange={(e) => handleSearch(e)}
                                value={value}

                            />


                            {load ? (
                                <div className="flex bg-amber-500 rounded-md px-3 py-1 w-4/12 font-bold text-xs flex items-center">
                                    <h4 className="text-black text-xs">Loading</h4>
                                    <input
                                        type="image"
                                        src={Spin}
                                        value="Loading"
                                        className="w-8"
                                        alt="loading..."
                                    />
                                </div>
                            ) :
                                (
                                    <button
                                        type="button"
                                        onClick={handleFilterSearchs}

                                        className="bg-amber-500 rounded-md px-3 py-1 w-4/12 font-bold text-xs">Search</button>

                                )}

                        </div>
                        <div className="flex gap-3">
                            <input
                                onChange={(e) => handleSearchByLocation(e)}
                                value={locationValue}
                                type="text"
                                name="" className='w-8/12 bg-white py-2 rounded-md placeholder:text-xs px-4'
                                placeholder='Pick-Up Location e.g. Lagos' />
                            <button
                                type="button"
                                onClick={filterModal}
                                className="bg-transparent border border-white rounded-md px-3 py-1 w-4/12  text-xx text-white">+ Add Filter</button>





                        </div>

                    </form>



                    {
                        car ?
                            <div className="flex  justify-around xl:w-3/6 lg:w-4/6 md:w-5/6 w-full gap-2 m-auto  mt-8 md:px-10">
                                <div className="flex md:gap-3 gap-2 items-center">
                                    <button
                                        onClick={clickSuv}
                                        className={suv === true ? "bg-amber-500 rounded-full p-2 md:w-6 w-5 h-5 md:h-6 border-2 border-white " : 'bg-transparent rounded-full p-2 md:w-6 w-5 h-5 md:h-6 border-2 border-white '}></button>
                                    <h4 className='text-white md:text-sm text-xs'> SUVs</h4>

                                </div>

                                <div className="flex  md:gap-3 gap-2 items-center">
                                    <button onClick={clickSaloon} className={saloon === true ? "bg-amber-500 rounded-full p-2 md:w-6 w-5 h-5 md:h-6 border-2 border-white " : "bg-transparent rounded-full p-2 md:w-6 w-5 h-5 md:h-6 border-2 border-white "}></button>
                                    <h4 className='text-white md:text-sm text-xs'> Saloon Cars</h4>

                                </div>

                                <div className="flex  md:gap-3 gap-2 items-center">
                                    <button onClick={clickLuxury} className={luxury === true ? "bg-amber-500 rounded-full p-2 md:w-6 w-5 h-5 md:h-6 border-2 border-white " : "bg-transparent rounded-full p-2 md:w-6 w-5 h-5 md:h-6 border-2 border-white "}></button>
                                    <h4 className='text-white md:text-sm text-xs'> Luxury Cars</h4>

                                </div>


                            </div> : ''}

                    {
                        bus ?
                            <div className="flex  justify-around lg:w-3/6 md:w-5/6 w-full gap-2 m-auto  mt-8 md:px-10">
                                <div className="flex md:gap-3 gap-2 items-center">
                                    <button
                                        onClick={clickMini}
                                        className='focus:bg-amber-500 bg-transparent rounded-full p-2 md:w-6 w-5 h-5 md:h-6 border-2 border-white '></button>
                                    <h4 className='text-white md:text-sm text-xs'> Mini Bus</h4>

                                </div>

                                <div className="flex  md:gap-3 gap-2 items-center">
                                    <button onClick={clickCoaster} className="focus:bg-amber-500 bg-transparent rounded-full p-2 md:w-6 w-5 h-5 md:h-6 border-2 border-white "></button>
                                    <h4 className='text-white md:text-sm text-xs'> Coaster Bus</h4>

                                </div>

                                <div className="flex  md:gap-3 gap-2 items-center">
                                    <button onClick={clickLuxuryB} className="focus:bg-amber-500 bg-transparent rounded-full p-2 md:w-6 w-5 h-5 md:h-6 border-2 border-white "></button>
                                    <h4 className='text-white md:text-sm text-xs'> Luxury Bus/Van</h4>

                                </div>


                            </div> : ''}

                    {
                        van ?
                            <div className="flex  justify-around lg:w-3/6 md:w-5/6 w-full gap-2 m-auto  mt-8 md:px-10">
                                <div className="flex md:gap-3 gap-2 items-center">
                                    <button
                                        onClick={clickVanEmergency}
                                        className="focus:bg-amber-500 bg-transparent rounded-full p-2 md:w-6 w-5 h-5 md:h-6 border-2 border-white "></button>

                                    <h4 className='text-white md:text-sm text-xs'> Emergency Van</h4>

                                </div>



                                <div className="flex  md:gap-3 gap-2 items-center">
                                    <button
                                        onClick={clickVanLuxury}

                                        className="focus:bg-amber-500 bg-transparent rounded-full p-2 md:w-6 w-5 h-5 md:h-6 border-2 border-white "></button>

                                    <h4 className='text-white md:text-sm text-xs'> Luxury Van</h4>

                                </div>


                            </div> : ''}

                    {truck ?
                        <div className="flex  justify-around lg:w-4/6 md:w-5/6 w-full gap-2 m-auto  mt-8 md:px-10">
                            <div className="flex md:gap-3 gap-2 items-center">
                                <button
                                    onClick={clickFlatTrailer}

                                    className="focus:bg-amber-500 bg-transparent rounded-full p-2 md:w-6 w-5 h-5 md:h-6 border-2 border-white "></button>

                                <h4 className='text-white md:text-sm text-xs'> Flat Bird Trailer</h4>

                            </div>

                            <div className="flex  md:gap-3 gap-2 items-center">
                                <button
                                    onClick={clickTonsTrailer}

                                    className="focus:bg-amber-500 bg-transparent rounded-full p-2 md:w-6 w-5 h-5 md:h-6 border-2 border-white "></button>
                                <h4 className='text-white md:text-sm text-xs'> 5-40 Tons Trailer</h4>

                            </div>

                            <div className="flex  md:gap-3 gap-2 items-center">
                                <button
                                    onClick={clickCaterpillar}

                                    className="focus:bg-amber-500 bg-transparent rounded-full p-2 md:w-6 w-5 h-5 md:h-6 border-2 border-white "></button>

                                <h4 className='text-white md:text-sm text-xs'> Caterpillars Trailer</h4>

                            </div>


                        </div> : ''}

                    {bike ?
                        <div className="flex  justify-around lg:w-3/6 md:w-5/6 w-full gap-2 m-auto  mt-8 md:px-10">
                            <div className="flex md:gap-3 gap-2 items-center">
                                <button
                                    onClick={clickDelivery}

                                    className="focus:bg-amber-500 bg-transparent rounded-full p-2 md:w-6 w-5 h-5 md:h-6 border-2 border-white "></button>

                                <h4 className='text-white md:text-sm text-xs'> Delivery Bikes</h4>

                            </div>

                            <div className="flex  md:gap-3 gap-2 items-center">
                                <button
                                    onClick={clickPowerBike}

                                    className="focus:bg-amber-500 bg-transparent rounded-full p-2 md:w-6 w-5 h-5 md:h-6 border-2 border-white "></button>
                                <h4 className='text-white md:text-sm text-xs'> Power Bikes</h4>

                            </div>




                        </div> : ''}
             {
                        !result?.length ? (
                            <div className="w-full flex iems-center justify-center">
                                <h1 className="text-3xl text-white my-20">Empty Query</h1>

                            </div>
                        )
                            : (<div
                                id='vehicles' className="my-8 xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-4 grid-cols-2 md:gap-4 xl:gap-8 gap-4 grid w-full">

                                {result?.map((asset, i) => {
                                    return (

                                        <BookNow

                                            key={asset.id}


                                            id={asset.id}
                                            image={asset.image4}
                                            imageThree={asset.image3}
                                            name={asset.vehicle_name}
                                            price={asset.daily_price}
                                            year={asset.year}
                                            providerService={asset.provider_service}
                                            location={asset.state} />

                                    )
                                })

                                }

                            </div>)}


                    {/* {!assets?.length || assets?.length <= visible ? ('') : (

                        <div className="w-full flex items-center justify-center my-12">
                            <button
                                onClick={showMore}

                                className='border border-theme-color theme-color font-semibold p-3 text-sm rounded-lg w-36'>Load More</button>

                        </div>
                    )} */}


                </div>
            </div>
        </div>
    )
}

export default SearchPage