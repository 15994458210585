import React, { Fragment, useState, useEffect, useContext,memo } from 'react'

import { Dialog, Transition } from '@headlessui/react'
import CurrencyInput from 'react-currency-input-field';
import axios from "axios";
import { BASE_URL } from "../utils/constant"
import getCookie from '../utils/SecuredTokens';

const InsuranceRate = ({ insuranceRates }) => {
    let [insuranceOpen, setInsuranceOpen] = useState(false)
    let [bg, setBg] = useState(false)

    function closeModal() {
        setInsuranceOpen(false)
        setBg(false)
    }

    function insuranceModal() {
        setInsuranceOpen(true)
        setBg(true)

    }




    let userData = getCookie("user");
    let userObject = JSON.parse(userData) 
    let token = userObject?.token
   

    let [insuranceDescription, setInsuranceDescription] = useState(insuranceRates?.description)
    let [insuranceCommission, setInsuranceCommission] = useState(insuranceRates?.commission)






    const updateInsurancePrice = async () => {
        let formData = new FormData();
        formData.append("title", 'Insurance Rate');
        formData.append("heading", 'insurance_rate');
        formData.append("price_category", 'insurance');
        formData.append("commission", insuranceCommission);
        // formData.append("description", );
        // formData.append("margin", price);
        // formData.append("minimum", price);
        // formData.append("maximum", price);
        formData.append("id", insuranceRates?.id);
        formData.append("description", insuranceDescription);
        await axios({
            method: "post",
            url: `${BASE_URL}/v1/update-pricing`,
            data: formData,
            headers: {
                'Content-Type': 'application/json',

                Authorization: `Bearer ${token}`,

                "Access-Control-Allow-Origin": "*",


            }
        })
            .then((response) => {
                const respData = response.data.message;
                console.log('respData', respData)


                window.location.reload(true)


            })
            .catch((error) => {
                console.log(error);
            });
    };
    return (
        <div>

            <Transition appear show={insuranceOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="w-full md:w-11/12  fixed flex justify-center items-center px-6 inset-0 z-10 overflow-y-auto"
                    onClose={closeModal}
                >
                    <div className="w-full xl:w-5/12 lg:w-6/12 md:w-9/12 min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-25 bg-white"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-25"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="w-full md:w-11/12 inline-block  max-w-md  overflow-hidden text-left align-middle transition-all transform shadow-xl rounded-2xl">


                                <div className="bg-black p-10">
                                    <h3 className="text-white mb-8">Update Insurance Price</h3>

                                    <form action="" className="flex xl:flex-row md:flex-row flex-col gap-4 items-center gap-4 ">


                                        <input

                                            className='w-full bg-transparent text-white p-2  border-b border-white '
                                            placeholder='Description'
                                            value={insuranceDescription}
                                            onChange={e => setInsuranceDescription(e.target.value)}








                                        />





                                        <CurrencyInput
                                            suffix='%'
                                            allowNegativeValue={false}
                                            value={insuranceCommission}
                                            className='w-full bg-transparent text-white p-2  border-b border-white '
                                            placeholder='Commission'

                                            onValueChange={(value) => {
                                                setInsuranceCommission(value)
                                            }}
                                        />






                                        <button

                                            type="button"
                                            onClick={updateInsurancePrice}

                                            className='rounded-md text-center  py-2 px-2 text-black text-xs font-bold bg-amber-500'>

                                            Update</button>
                                    </form>





                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
            <div className="w-full flex justify-between items-center  px-0  p-2 ">
                <div className="w-5/12 text-white justify-center flex items-center rounded-md border border-white bg-project px-3 py-1 ">
                    <p className='md:text-base text-center text-sm'>&bull; {insuranceRates?.description}</p>
                </div>

                <div className="w-2/12 ">


                    <input
                        value={insuranceRates?.commission + '%'}
                        className='w-full  py-1 curr bg-transparent text-center theme-color  md:text-base  text-sm font-semibold'
                    />
                </div>

                <button
                    onClick={insuranceModal}
                    type="button" className='xl:w-2/12 md:w-3/12 bg-white  xl:p-1 p-2 md:p-2 text-black font-semibold  text-sm rounded-md '>Change</button>


            </div>
        </div>
    )
}

export default memo(InsuranceRate)