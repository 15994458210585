

import Header from '../Header'
import Navigation from './Navigation'

import React, { Fragment, useState, useEffect, useRef, useMemo  } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { BASE_URL } from "../utils/constant"

import dashboard from '../../Images/dashboard.png'
import admin_request from '../../Images/admin_request.svg'
import service_p from '../../Images/service_p.svg'
import reports from '../../Images/reports.svg'
import customer from '../../Images/customers.png'
import notifications from '../../Images/notifications.png'
import download from '../../Images/download.svg'
import car_asset from '../../Images/car_asset.svg'
import icon from '../../Images/icon.svg'
import topArrow from "../../Images/topArrow.svg"
import bottomArrow from "../../Images/bottomArrow.svg"
import car from '../../Images/car.png'
import cancel from '../../Images/cancel.svg'
import message from '../../Images/message.svg'
import success from '../../Images/success.png'
import Spin from "../../Images/spinner.gif"

import payment from '../../Images/payment.png'
import naira from '../../Images/naira.png'
import one from '../../Images/one.png'
import eyee from '../../Images/eyee.png'
import mark from '../../Images/mark.png'
// import success from '../../Images/success.png'
import next from '../../Images/next.png'
import prev from '../../Images/prev.png'
import Compressor from 'compressorjs';

import pen from '../../Images/pen.svg'
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Multiselect } from 'multiselect-react-dropdown'
import Dropdown from '../Common/Dropdown'
import CurrencyInput from 'react-currency-input-field';
import { GoogleMap,useJsApiLoader, Autocomplete} from '@react-google-maps/api';
import Resizer from "react-image-file-resizer";
// import Autocomplete from "react-google-autocomplete";


import { useFormik } from 'formik'
import * as Yup from 'yup'
import { NavLink, useNavigate } from "react-router-dom";
import Switch from "react-switch";
import Select from 'react-select'
import countryList from 'react-select-country-list'
import useAnalyticsEventTracker from '../Common/useAnalyticsEventTracker'
import { proofOwnerDocEvent, vehicleDocsUploadEvent, vehicleInfoUploadEvent, vehicleRegisterPage } from '../Common/facebookPixelEvent'
import getCookie from '../utils/SecuredTokens'
// import { useDispatch, useSelector } from "react-redux"
// import {setCarInit} from "../../slices/carInit"

function DriverCarRegistration() {
    const gaEventTracker = useAnalyticsEventTracker('Vehicle Registration')
    const {isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyDkMuW-hGvdRfUhsb2R9RFqA0Pq4Qxg4Cs",
        libraries: ['places'],
    })

    const [autocomplete, setAutocomplete] = useState(null)

    const onPlaceChanged = () => {
        if(autocomplete !== null) {
            const places = autocomplete.getPlace().formatted_address
            if(places) {
                setAddress(places)
            }else {
                setAddress(autocomplete.getPlace().name)
            }
        }
    }

    // RESIZER
    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
            file,
            300,
            400,
            "JPEG",
            80,
            0,
            (uri) => {
                resolve(uri);
            },
            "base64"
            );
        });

    const dataURIToBlob = (dataURI) => {
        const splitDataURI = dataURI.split(",");
        const byteString =
            splitDataURI[0].indexOf("base64") >= 0
            ? atob(splitDataURI[1])
            : decodeURI(splitDataURI[1]);
        const mimeString = splitDataURI[0].split(":")[1].split(";")[0];
        const ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);
        return new Blob([ia], { type: mimeString });
    };

    const contextClass = {
        success: "bg-blue-600",
        error: "bg-red-600",
        info: "bg-gray-600",
        warning: "bg-orange-400",
        default: "bg-green-600",
        dark: "bg-white-600 font-gray-300",
      };


    const [vehicleData, setVehicleData] = useState([])
    let userService1 = JSON.parse(localStorage.getItem("userService"))

    // Country Value
    const [countryValue, setCountryValue] = useState([])
    const optionsCountry = useMemo(() => countryList().getData(), [])

    const changeHandlerCountry = value => {
        setCountryValue(value)
    }

    const [load, setLoad] = useState("")
    const [formLoad, setFormLoad] = useState("")

    let notify = () => { toast("File Successfully Uploaded!") };
   

    let userData = getCookie("user");
    let userObject = JSON.parse(userData) 
    let token = userObject?.token
    let userId = JSON.parse(localStorage.getItem("userId"))


    let userOwnership = JSON.parse(localStorage.getItem("proof-of-ownership"))
    let ownership = userOwnership?.document

    let userLicense = JSON.parse(localStorage.getItem("licence"))
    let license = userLicense?.document

    let userInsurance = JSON.parse(localStorage.getItem("insurance"))
    let insuranceFile = userInsurance?.document


    let userWorthiness = JSON.parse(localStorage.getItem("worthiness"))
    let worthinessFile = userWorthiness?.document

    let userHackney = JSON.parse(localStorage.getItem("hackney"))
    let hackneyFile = userHackney?.document

    let navigate = useNavigate();
    const [checked, setChecked] = useState(false);

    // document upload state
    const [upload, setUpload] = useState(false);
    const [dataSave, setDataSave] = useState({})
    const [licenceUpload, setLicenceUpload] = useState(false);
    const [insuranceUpload, setInsuranceUpload] = useState(false);
    const [hackneyUpload, setHackneyUpload] = useState(false);
    const [worthinessUpload, setWorthinessUpload] = useState(false);

    // CHOOSING INSURANCE TO USE
    const [selectedInsurance, setSelectedInsurance] = useState('')
    const [insuranceValue, selectInsuranceValue] = useState('')

    const [selectDriverAvailability, setSelectDriverAvaialability] = useState('')
    const [driverAvailabilityValue, setDriverAvailabilityValue] = useState('')

    // CHOOSING VEHICLE TYPE
    const [selectedVechicleType, setSelectedVehicleType] = useState('')
    
    // MULTI TASK
    const [availabilitySelect, setAvailabilitySelect] = useState([])
    
    

    const [expMonth, setExpMonth] = useState();

    const [expYear, setExpYear] = useState();
    const [errorMessage, setErrorMessage] = useState('')

    const [check, setCheck] = useState(false);
    const [travelAvailable, setTravelAvailable] = useState('No')
    const [airCon, setAirCon] = useState('No')


    const [proof, setProof] = useState()
    const [licence, setLicence] = useState()
    const [insurance, setInsurance] = useState()
    const [worthiness, setWorthiness] = useState()
    const [hackney, setHackney] = useState()
     
    const [vehicleName, setVehicleName] = useState(vehicleData?.vehicle_name || '')
    const [year, setYear] = useState(vehicleData?.year || '')
    const [plateNumber, setPlateNumber] = useState('')
    // const [addressNew, setAddressNew] = useState('')
    const [address, setAddress] = useState('')
    const [dailyPrice, setDailyPrice] = useState('')
    const [monthlyPrice, setMonthlyPrice] = useState('')
    const [hourAvailable, setHourAvailable] = useState('')

    const [vehicleType, setVehicleType] = useState('')
    const [vehicleColor, setVehicleColor] = useState('')
    const [service, setService] = useState('')
    const [vehicleLocation, setVehicleLocation] = useState('')
    const [imageOne, setImageOne] = useState()
    const [imageTwo, setImageTwo] = useState()
    const [imageThree, setImageThree] = useState()
    const [notes, setNotes] = useState('')
    const [noOfSeats, setNoOfSeats] = useState('')
    const [airportPrice, setAirportPrice] = useState('')

    const [selectedImage, setSelectedImage] = useState();
    const [selectedImage1, setSelectedImage1] = useState()
    const [selectedImage2, setSelectedImage2] = useState()

    const [airConditioner, setAirConditioner] = useState('yes')
    const [availableTravel, setAvailableTravel] = useState('yes')
    const [compInsurance, setCompInsurance] = useState('yes')

    const [showSubmitBtn, setShowSubmitBtn] = useState(false)
    const [showDocumentSection, setShowDocumentSection] = useState(false)
    const [isProofSaved, setIsProofSaved] = useState(false)
    const [isRegistered, setIsRegistered] = useState(false)

    // CONVERTING DAILY PRICE TO NUMBER
    const numDailyPrice = Number(dailyPrice) 
    const numMonthlyPrice = Number(monthlyPrice)
    const numAirportPrice =  Number(airportPrice)

    const prefix = '₦'

    // Facebook Pixel
    useEffect(() => {
        vehicleRegisterPage()
    }, [])

    // MULTISELECT ..........
    const convertArray = (array, property) => {
        var result = []
        for(var i = 0; i<array.length; i+=1) {
            result.push(array[i][property])
        }
        setUpdateAvailability(result)
    }
    const [updateAvialablity, setUpdateAvailability] = useState([])
    useEffect(() => {
        setUpdateAvailability([])
        convertArray(availabilitySelect, 'value')
    }, [availabilitySelect.length])

    // This function will be triggered when the file field change
    const imageChange = async (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0]
            const image = await resizeFile(file);
            const newFile = dataURIToBlob(image);
            const formData = new FormData();
            formData.append("image", newFile);
            setSelectedImage(newFile)
        }else{
            setSelectedImage(null)
        }
        // setSelectedImage(compressedResult)
    };

    const imageChange1 =async (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0]
            const image = await resizeFile(file);
            const newFile = dataURIToBlob(image);
            const formData = new FormData();
            formData.append("image", newFile);
            setSelectedImage1(newFile)
        }
    };

    const imageChange2 =async (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0]
            const image = await resizeFile(file);
            const newFile = dataURIToBlob(image);
            console.log(newFile, "newFile")
            const formData = new FormData();
            formData.append("image", newFile);
            setSelectedImage2(newFile)
        }
    };

    // This function will be triggered when the "Remove This Image" button is clicked
    const removeSelectedImage = () => {
        setSelectedImage();
    };

    const removeSelectedImage1 = () => {
        setSelectedImage1();
    };

    const removeSelectedImage2 = () => {
        setSelectedImage2();
    };


    // AVAILABILITY SERVICE OPTIONS
    const availabilityOptionData = [
        {value: 'Full-tank per day', label: 'Full-tank per day', id: 3},
        {value: 'No-fuel', label: 'No-fuel ', id: 4},
        {value: 'Available  Night', label: 'Available  Night ', id: 5},
        {value: 'Available Monthly Full-time', label: 'Available Monthly Full-time', id: 6},
        {value: 'Available  Weekly', label: 'Available  Weekly', id: 7}
    ]

    const [optionsValue] = useState(availabilityOptionData)

    // ARRAY OF INSURANCE 
    const insuranceArray = [
        {value: 'basic_insurance', label: 'Basic Insurance'}, 
        {value: 'comprehensive_insurance', label: 'Comprehensive Insurance'},
        {value: 'zero_insurance', label: 'Not Interested'},
    ]

    // ARRAY FOR COME WITH DRIVER OR NOT
    const comesWithDriverArray = [
        {value: 'yes', label: 'Yes, it does'}, 
        {value: 'no', label: `No, it does not`},
    ]

    // VEHICLE TYPE ARRAY
    const vehicleArray = [
        {value: 'SUV Car(cars)', label: 'SUV Car'}, 
        {value: 'Saloon Car(cars)', label: 'Saloon Car '},
        {value: 'Luxury Car(cars)', label: 'Luxury Car'},
        {value: 'Mini Bus(bus)', label: 'Mini Bus'},
        {value: 'Coaster Bus(bus)', label: 'Coaster Bus'},
        {value: 'Luxury  Bus/Van(bus, vans)', label: 'Luxury  Bus/Van'},
        {value: 'Cargo Van/Truck(vans,trucks)', label: 'Cargo Van/Truck'},
        {value: 'Pickup/Tow Truck(trucks)', label: 'Pickup/Tow Truck'},
        {value: '10-40ft Trucks(trucks)', label: '10-40ft Trucks'},
        {value: 'Flat Bird Trailer(trucks)', label: 'Flat Bird Trailer'},
        {value: '5-40 Tons Trailer(trucks)', label: '5-40 Tons Trailer'},
        {value: 'Caterpillars Trailer(trucks)', label: 'Caterpillars Trailer'},
        {value: 'Delivery Bikes(bikes)', label: 'Delivery Bikes'},
        {value: 'Power Bikes(bikes)', label: 'Power Bikes'},
        {value: 'Emergency Van(vans)', label: 'Emergency Van'},
        {value: 'Others', label: 'Others'},
    ]





    const fileSelectOne = (event) => {
        setImageOne(event.target.files[0])
    }

    const fileSelectTwo = (event) => {
        setImageTwo(event.target.files[0])
    }

    const fileSelectThree = (event) => {
        setImageThree(event.target.files[0])
    }




  

    const docRef = useRef(null)

    const fileInputRef = useRef()
    const image1Ref = useRef()
    const image2Ref = useRef()
    const image3Ref = useRef()

    const fileInputRefOne = useRef()
    const fileInputRefTwo = useRef()
    const fileInputRefThree = useRef()
    const licenceInputRef = useRef()
    const insuranceInputRef = useRef()
    const worthinessInputRef = useRef()
    const hackneyInputRef = useRef()
    

    const refDiv = useRef()
    const handleAirCon = (e) => {
        setAirConditioner(e.target.value)
    };





    const handleTravel = (e) => {
        setAvailableTravel(e.target.value)
    };


    const handleInsurance = (e) => {
        setCompInsurance(e.target.value)
    }

    // HANDLE SHOW SUBMIT BTN
    const handleShowSubmitBtn = (e) => {
        e.preventDefault()

        setShowSubmitBtn(true)
    }

    // HANDLE DAILY PRICE
    const handleDailyPrice = (value) => {
        setDailyPrice(value)
    }






    const [width, setWidth] = useState(10)
    const [status, setStatus] = useState('Start Booking - Let’s Move')
    let [question, setQuestion] = useState(1)
    const [allQuestion, setAllQuestion] = useState(19)


    const onClickStart = (e) => {
        e.preventDefault()
        setshowFieldOne(true)
        setshowFieldTwo(false)
        setshowFieldThree(false)
        setshowFieldFour(false)
        setshowFieldFive(false)
        // setshowFieldsFiveb(false)
        setshowFieldSix(false)
        setshowFieldSeven(false)
        setshowFieldEight(false)

        setshowFieldNine(false)

        setshowFieldTen(false)
        setshowFieldEleven(false)
        setshowFieldTwelve(false)
        setshowFieldThirteen(false)
        setshowFieldFourteen(false)
        setShowFieldFifteen(false)
        setShowFieldSixteen(false)
        setShowFieldSeventeen(false)
        setShowFieldEighteen(false)
        setShowFieldNineteen(false)
        setQuestion(question - 1)
        setWidth(5)
    }

    const onClickOneBack = (e) => {
        e.preventDefault()
        setshowFieldOne(false)

        setshowFieldTwo(true)
        setshowFieldThree(false)
        setshowFieldFour(false)
        setshowFieldFive(false)
        // setshowFieldFiveb(false)
        setshowFieldSix(false)
        setshowFieldSeven(false)
        setshowFieldEight(false)

        setshowFieldNine(false)

        setshowFieldTen(false)
        setshowFieldEleven(false)
        setshowFieldTwelve(false)
        setshowFieldThirteen(false)
        setshowFieldFourteen(false)
        setShowFieldFifteen(false)
        setShowFieldSixteen(false)
        setShowFieldSeventeen(false)
        setShowFieldEighteen(false)
        setShowFieldNineteen(false)
        setQuestion(question - 1)

        setWidth(10)

    }

    const onClickTwoBack = (e) => {
        e.preventDefault()


        setshowFieldThree(true)

        setshowFieldOne(false)

        setshowFieldTwo(false)
        setshowFieldFour(false)
        setshowFieldFive(false)
        // setshowFieldsFiveb(false)
        setshowFieldSix(false)
        setshowFieldSeven(false)
        setshowFieldEight(false)
        setshowFieldNine(false)
        setshowFieldTen(false)
        setshowFieldEleven(false)
        setshowFieldTwelve(false)
        setshowFieldThirteen(false)
        setshowFieldFourteen(false)
        setShowFieldFifteen(false)
        setShowFieldSixteen(false)
        setShowFieldSeventeen(false)
        setShowFieldEighteen(false)
        setShowFieldNineteen(false)
        setQuestion(question - 1)


        setWidth(15)


    }


    const onClickThreeBack = (e) => {
        e.preventDefault()
        setshowFieldOne(false)

        setshowFieldTwo(false)
        setshowFieldThree(false)
        setshowFieldFour(true)
        setshowFieldFive(false)
        // setshowFieldFiveb(false)
        setshowFieldSix(false)

        setshowFieldSeven(false)
        setshowFieldEight(false)

        setshowFieldNine(false)

        setshowFieldTen(false)
        setshowFieldEleven(false)
        setshowFieldTwelve(false)
        setshowFieldThirteen(false)
        setshowFieldFourteen(false)
        setShowFieldFifteen(false)
        setShowFieldSixteen(false)
        setShowFieldSeventeen(false)
        setShowFieldEighteen(false)
        setShowFieldNineteen(false)
        setQuestion(question - 1)
        setWidth(20)


    }


    const onClickFourBack = (e) => {
        e.preventDefault()
        setshowFieldOne(false)

        setshowFieldTwo(false)
        setshowFieldThree(false)
        setshowFieldFour(false)
        setshowFieldFive(true)
        // setshowFieldFiveb(false)
        setshowFieldSix(false)
        setshowFieldSeven(false)
        setshowFieldEight(false)

        setshowFieldNine(false)

        setshowFieldTen(false)
        setshowFieldEleven(false)
        setshowFieldTwelve(false)
        setshowFieldThirteen(false)
        setshowFieldFourteen(false)
        setShowFieldFifteen(false)
        setShowFieldSixteen(false)
        setShowFieldSeventeen(false)
        setShowFieldEighteen(false)
        setShowFieldNineteen(false)
        setQuestion(question - 1)
        setWidth(25)


    }

    const onClickFiveBack = (e) => {
        e.preventDefault()
        setshowFieldOne(false)

        setshowFieldTwo(false)
        setshowFieldThree(false)
        setshowFieldFour(false)
        setshowFieldFive(false)
        // setshowFieldFiveb(true)
        setshowFieldSix(true)
        setshowFieldSeven(false)
        setshowFieldEight(false)

        setshowFieldNine(false)

        setshowFieldTen(false)
        setshowFieldEleven(false)
        setshowFieldTwelve(false)
        setshowFieldThirteen(false)
        setshowFieldFourteen(false)
        setShowFieldFifteen(false)
        setShowFieldSixteen(false)
        setShowFieldSeventeen(false)
        setShowFieldEighteen(false)
        setShowFieldNineteen(false)
        setQuestion(question - 1)
        setWidth(30)

    }

    const onClickSixBack = (e) => {
        e.preventDefault()
        setshowFieldOne(false)

        setshowFieldTwo(false)
        setshowFieldThree(false)
        setshowFieldFour(false)
        setshowFieldFive(false)
        // setshowFieldFiveb(true)
        setshowFieldSix(false)
        setshowFieldSeven(true)
        setshowFieldEight(false)

        setshowFieldNine(false)

        setshowFieldTen(false)
        setshowFieldEleven(false)
        setshowFieldTwelve(false)
        setshowFieldThirteen(false)
        setshowFieldFourteen(false)
        setShowFieldFifteen(false)
        setShowFieldSixteen(false)
        setShowFieldSeventeen(false)
        setShowFieldEighteen(false)
        setShowFieldNineteen(false)
        setQuestion(question - 1)
        setWidth(35)

    }

    const onClickSevenBack = (e) => {
        e.preventDefault()
        setshowFieldOne(false)

        setshowFieldTwo(false)
        setshowFieldThree(false)
        setshowFieldFour(false)
        setshowFieldFive(false)
        // setshowFieldFiveb(true)
        setshowFieldSix(false)
        setshowFieldSeven(false)
        setshowFieldEight(true)

        setshowFieldNine(false)

        setshowFieldTen(false)
        setshowFieldEleven(false)
        setshowFieldTwelve(false)
        setshowFieldThirteen(false)
        setshowFieldFourteen(false)
        setShowFieldFifteen(false)
        setShowFieldSixteen(false)
        setShowFieldSeventeen(false)
        setShowFieldEighteen(false)
        setShowFieldNineteen(false)
        setQuestion(question - 1)
        setWidth(40)

    }

    const onClickEightBack = (e) => {
        e.preventDefault()
        setshowFieldOne(false)

        setshowFieldTwo(false)
        setshowFieldThree(false)
        setshowFieldFour(false)
        setshowFieldFive(false)
        // setshowFieldFiveb(true)
        setshowFieldSix(false)
        setshowFieldSeven(false)
        setshowFieldEight(false)
        setshowFieldNine(true)

        setshowFieldTen(false)
        setshowFieldEleven(false)
        setshowFieldTwelve(false)
        setshowFieldThirteen(false)
        setshowFieldFourteen(false)
        setShowFieldFifteen(false)
        setShowFieldSixteen(false)
        setShowFieldSeventeen(false)
        setShowFieldEighteen(false)
        setShowFieldNineteen(false)
        setQuestion(question - 1)
        setWidth(45)

    }

    const onClickNineBack = (e) => {
        e.preventDefault()
        setshowFieldOne(false)

        setshowFieldTwo(false)
        setshowFieldThree(false)
        setshowFieldFour(false)
        setshowFieldFive(false)
        // setshowFieldFiveb(true)
        setshowFieldSix(false)
        setshowFieldSeven(false)
        setshowFieldEight(false)
        setshowFieldNine(false)
        setshowFieldTen(true)
        setshowFieldEleven(false)
        setshowFieldTwelve(false)
        setshowFieldThirteen(false)
        setshowFieldFourteen(false)
        setShowFieldFifteen(false)
        setShowFieldSixteen(false)
        setShowFieldSeventeen(false)
        setShowFieldEighteen(false)
        setShowFieldNineteen(false)
        setQuestion(question - 1)
        setWidth(50)

    }

    const onClickTenBack = (e) => {
        e.preventDefault()
        setshowFieldOne(false)

        setshowFieldTwo(false)
        setshowFieldThree(false)
        setshowFieldFour(false)
        setshowFieldFive(false)
        // setshowFieldFiveb(true)
        setshowFieldSix(false)
        setshowFieldSeven(false)
        setshowFieldEight(false)
        setshowFieldNine(false)
        setshowFieldTen(false)
        setshowFieldEleven(true)
        setshowFieldTwelve(false)
        setshowFieldThirteen(false)
        setshowFieldFourteen(false)
        setShowFieldFifteen(false)
        setShowFieldSixteen(false)
        setShowFieldSeventeen(false)
        setShowFieldEighteen(false)
        setShowFieldNineteen(false)
        setQuestion(question - 1)
        setWidth(55)

    }


    const onClickElevenBack = (e) => {
        e.preventDefault()
        setshowFieldOne(false)

        setshowFieldTwo(false)
        setshowFieldThree(false)
        setshowFieldFour(false)
        setshowFieldFive(false)
        // setshowFieldFiveb(true)
        setshowFieldSix(false)
        setshowFieldSeven(false)
        setshowFieldEight(false)
        setshowFieldNine(false)
        setshowFieldTen(false)
        setshowFieldEleven(false)
        setshowFieldTwelve(true)
        setshowFieldThirteen(false)
        setshowFieldFourteen(false)
        setShowFieldFifteen(false)
        setShowFieldSixteen(false)
        setShowFieldSeventeen(false)
        setShowFieldEighteen(false)
        setShowFieldNineteen(false)
        setQuestion(question - 1)
        setWidth(60)

    }


    const onClickTwelveBack = (e) => {
        e.preventDefault()
        setshowFieldOne(false)

        setshowFieldTwo(false)
        setshowFieldThree(false)
        setshowFieldFour(false)
        setshowFieldFive(false)
        // setshowFieldFiveb(true)
        setshowFieldSix(false)
        setshowFieldSeven(false)
        setshowFieldEight(false)
        setshowFieldNine(false)
        setshowFieldTen(false)
        setshowFieldEleven(false)
        setshowFieldTwelve(false)
        setshowFieldThirteen(true)
        setshowFieldFourteen(false)
        setShowFieldFifteen(false)
        setShowFieldSixteen(false)
        setShowFieldSeventeen(false)
        setShowFieldEighteen(false)
        setShowFieldNineteen(false)
        setQuestion(question - 1)
        setWidth(65)

    }

    const onClickThirteenBack = (e) => {
        e.preventDefault()
        setshowFieldOne(false)

        setshowFieldTwo(false)
        setshowFieldThree(false)
        setshowFieldFour(false)
        setshowFieldFive(false)
        // setshowFieldFiveb(true)
        setshowFieldSix(false)
        setshowFieldSeven(false)
        setshowFieldEight(false)
        setshowFieldNine(false)
        setshowFieldTen(false)
        setshowFieldEleven(false)
        setshowFieldTwelve(false)
        setshowFieldThirteen(false)
        setshowFieldFourteen(true)
        setShowFieldFifteen(false)
        setShowFieldSixteen(false)
        setShowFieldSeventeen(false)
        setShowFieldEighteen(false)
        setShowFieldNineteen(false)
        setQuestion(question - 1)
        setWidth(70)
    }

    const onClickFourteenBack = (e) => {
        e.preventDefault()
        setshowFieldOne(false)

        setshowFieldTwo(false)
        setshowFieldThree(false)
        setshowFieldFour(false)
        setshowFieldFive(false)
        // setshowFieldFiveb(true)
        setshowFieldSix(false)
        setshowFieldSeven(false)
        setshowFieldEight(false)
        setshowFieldNine(false)
        setshowFieldTen(false)
        setshowFieldEleven(false)
        setshowFieldTwelve(false)
        setshowFieldThirteen(false)
        setshowFieldFourteen(false)
        setShowFieldFifteen(true)
        setShowFieldSixteen(false)
        setShowFieldSeventeen(false)
        setShowFieldEighteen(false)
        setShowFieldNineteen(false)
        setQuestion(question - 1)
        setWidth(75)
    }

    const onClickFifteenBack = (e) => {
        e.preventDefault()
        setshowFieldOne(false)

        setshowFieldTwo(false)
        setshowFieldThree(false)
        setshowFieldFour(false)
        setshowFieldFive(false)
        // setshowFieldFiveb(true)
        setshowFieldSix(false)
        setshowFieldSeven(false)
        setshowFieldEight(false)
        setshowFieldNine(false)
        setshowFieldTen(false)
        setshowFieldEleven(false)
        setshowFieldTwelve(false)
        setshowFieldThirteen(false)
        setshowFieldFourteen(false)
        setShowFieldFifteen(false)
        setShowFieldSixteen(true)
        setShowFieldSeventeen(false)
        setShowFieldEighteen(false)
        setShowFieldNineteen(false)
        setQuestion(question - 1)
        setWidth(80)
    }

    const onClickSixteenBack = (e) => {
        e.preventDefault()
        setshowFieldOne(false)

        setshowFieldTwo(false)
        setshowFieldThree(false)
        setshowFieldFour(false)
        setshowFieldFive(false)
        // setshowFieldFiveb(true)
        setshowFieldSix(false)
        setshowFieldSeven(false)
        setshowFieldEight(false)
        setshowFieldNine(false)
        setshowFieldTen(false)
        setshowFieldEleven(false)
        setshowFieldTwelve(false)
        setshowFieldThirteen(false)
        setshowFieldFourteen(false)
        setShowFieldFifteen(false)
        setShowFieldSixteen(false)
        setShowFieldSeventeen(true)
        setShowFieldEighteen(false)
        setShowFieldNineteen(false)
        setQuestion(question - 1)
        setWidth(85)
    }

    const onClickSeventeenBack = (e) => {
        e.preventDefault()
        setshowFieldOne(false)

        setshowFieldTwo(false)
        setshowFieldThree(false)
        setshowFieldFour(false)
        setshowFieldFive(false)
        // setshowFieldFiveb(true)
        setshowFieldSix(false)
        setshowFieldSeven(false)
        setshowFieldEight(false)
        setshowFieldNine(false)
        setshowFieldTen(false)
        setshowFieldEleven(false)
        setshowFieldTwelve(false)
        setshowFieldThirteen(false)
        setshowFieldFourteen(false)
        setShowFieldFifteen(false)
        setShowFieldSixteen(false)
        setShowFieldSeventeen(false)
        setShowFieldEighteen(true)
        setShowFieldNineteen(false)
        setQuestion(question - 1)
        setWidth(90)  
    }



    

    const [plateNoError, setPlateNoError] = useState('')
    const [showFieldTwo, setshowFieldTwo] = useState(false)
    const onClickTwo = (e) => {
        e.preventDefault()

        if(!plateNumber || plateNumber === '') {
            setPlateNoError('This is required')

            setTimeout(() => {
                setPlateNoError('')
            },5000)
        } else {
            setshowFieldTwo(false)
            setshowFieldThree(true)
            setQuestion(++question)
            setWidth(20)
            setStatus('Start Booking - Let’s Move')
        }
    }

    const [showFieldOne, setshowFieldOne] = useState(true)
    const [vehicleNameError, setVehicleNameError] = useState('')
    const [yearError, setYearError] = useState('')
    const onClickOne = (e) => {
        e.preventDefault()

        if((!vehicleName || vehicleName === '') && (!year || year === '')){
            setVehicleNameError('This is required')
            setYearError('This is required')

            setTimeout(() => {
                setVehicleNameError('')
                setYearError('')
            }, 5000)
        }
        else if(!vehicleName || vehicleName === '') {
            setVehicleNameError('This is required')

            setTimeout(() => {
                setVehicleNameError('')
            }, 5000)
        }else if (!year || year === '') {
            setYearError('This is required')

            setTimeout(() => {
                setYearError('')
            }, 10000)
        }
        else {
            setshowFieldOne(false)
            setshowFieldTwo(true)
            setQuestion(++question)
            setWidth(10)
            setStatus('Start Booking - Let’s Move')
        }
    }

    const [vehicleTypeError, setVehicleTypeError] = useState('')
    const [showFieldThree, setshowFieldThree] = useState(false)
    const onClickThree = (e) => {
        e.preventDefault()

        if(!selectedVechicleType || selectedVechicleType === '') {
            setVehicleTypeError('This is required') 

            setTimeout(() => {
                setVehicleTypeError('')
            }, 5000)
        }else {
            setshowFieldThree(false)
            setshowFieldFour(true)
            setQuestion(++question)
            setWidth(25)
            setStatus('Start Booking - Let’s Move')
        }
    }

    const [showFieldFour, setshowFieldFour] = useState(false)
    const [seatsNoError, setSeatsNoError] = useState('')
    const onClickFour = (e) => {
        e.preventDefault()

        if(!noOfSeats || noOfSeats === '') {
            setSeatsNoError('This is required')

            setTimeout(() => {
                setSeatsNoError('')
            }, 5000)
        }else {
            setshowFieldFour(false)
            setshowFieldFive(true)
            setQuestion(++question)
            setWidth(30)
            setStatus('Almost Done - Keep Moving')
        }
    }


    const [addressError, setAddressError] = useState('')
    const [showFieldFive, setshowFieldFive] = useState(false)
    const onClickFive = (e) => {
        e.preventDefault()

        if(!address || address === '') {
            setAddressError('This is required')

            setTimeout(() => {
                setAddressError('')
            }, 5000)
        }else {
            setshowFieldFive(false)
            setshowFieldSix(true)
            setQuestion(++question)
            setWidth(35)
            setStatus('Almost Done - Keep Moving')
        }
    }

    const [dailyPriceError, setDailyPriceError] = useState('')
    const [showFieldSix, setshowFieldSix] = useState(false)
    const onClickSix = (e) => {
        e.preventDefault()

        if(!dailyPrice || dailyPrice === '') {
            setDailyPriceError('This is required')

            setTimeout(() => {
                setDailyPriceError('')
            }, 5000)
        }else if (dailyPrice === '0') {
            setDailyPriceError('This cannot be zero')

            setTimeout(() => {
                setDailyPriceError('')
            }, 5000)
        }else {
            setshowFieldSix(false)
            setshowFieldSeven(true)
            setQuestion(++question)
            setWidth(40)
            setStatus('Almost Done - Keep Moving')
        }
    }

    const [airportPriceError, setAirportPriceError] = useState('')
    const [showFieldSeven, setshowFieldSeven] = useState(false)
    const onClickSeven = (e) => {
        e.preventDefault()

        if( !airportPrice || airportPrice === '') {
            setAirportPriceError('This is required')

            setTimeout(() => {
                setAirportPriceError('')
            }, 5000)
        }else if (airportPrice === '0') {
            setAirportPriceError('This cannot be zero')

            setTimeout(() => {
                setAirportPriceError('')
            }, 5000)
        }else {
            setshowFieldSeven(false)
            setshowFieldEight(true)
            setQuestion(++question)
            setWidth(45)
            setStatus('Almost Done - Keep Moving')
        }
    }

    const [showFieldEight, setshowFieldEight] = useState(false)
    const [monthlyPriceError, setMonthlyPriceError] = useState('')
    const onClickEight = (e) => {
        e.preventDefault()
        // if(monthlyPrice < 85000) {
        //     setErrorMessage(`Monthly Price can't be less than ${prefix}85,000`)
        //     setshowFieldEight(true)
        //     setTimeout(() => {
        //         setErrorMessage('')
        //     }, [3000])
        // }else {
        //     setshowFieldEight(false)
        //     setshowFieldNine(true)
        //     setQuestion(++question)
        //     setWidth(50)
        //     setStatus('Almost Done - Keep Moving')
        // }
    
        if(!monthlyPrice || monthlyPrice === '') {
            setMonthlyPriceError('This is required')

            setTimeout(() => {
                setMonthlyPriceError('')
            }, 5000)
        }else if (monthlyPrice === '0') {
            setMonthlyPriceError('This cannot be zero')

            setTimeout(() => {
                setMonthlyPriceError('')
            }, 5000)
        }else {
            setshowFieldEight(false)
            setshowFieldNine(true)
            setQuestion(++question)
            setWidth(50)
            setStatus('Almost Done - Keep Moving')
        }
    }

    const [showFieldNine, setshowFieldNine] = useState(false)
    const onClickNine = (e) => {
        e.preventDefault()

        if (hourAvailable >= 12 ) {
            setshowFieldNine(false)

            setshowFieldTen(true)
            setQuestion(++question)
            setWidth(55)
            setStatus('Almost Done - Keep Moving')
        }else {
            setErrorMessage('Minimum is 12 Hours')
            setTimeout(() => {
                setErrorMessage('')
            }, [5000])
        }

        // setshowFieldNine(false)

        // setshowFieldTen(true)
        // setQuestion(++question)
        // setWidth(55)
        // setStatus('Almost Done - Keep Moving')
    }

    

    const [showFieldTen, setshowFieldTen] = useState(false)
    const onClickTen = (e) => {
        e.preventDefault()
        setshowFieldTen(false)

        setshowFieldEleven(true)
        setQuestion(++question)
        setWidth(60)
        setStatus('Almost Done - Keep Moving')

    }
    const [showFieldEleven, setshowFieldEleven] = useState(false)
    const onClickEleven = (e) => {
        e.preventDefault()
        setshowFieldEleven(false)

        setshowFieldTwelve(true)
        setQuestion(++question)
        setWidth(65)
        setStatus('Almost Done - Keep Moving')
    }

    const [showFieldTwelve, setshowFieldTwelve] = useState(false)
    const [vehicleColorError, setVehicleColorError] = useState('')
    const onClickTwelve = (e) => {
        e.preventDefault()

        if(!vehicleColor || vehicleColor === '') {
            setVehicleColorError('This is required')

            setTimeout(() => {
                setVehicleColorError('')
            }, 5000)
        }else {
            setshowFieldTwelve(false)
            setshowFieldThirteen(true)
            setQuestion(++question)
            setWidth(70)
            setStatus('Almost Done - Keep Moving')
        }
    }

    const [availibilityError, setAvailibilityError] = useState('')
    const [showFieldThirteen, setshowFieldThirteen] = useState(false)
    const onClickThirteeen = (e) => {
        e.preventDefault()

        if(!selectDriverAvailability || selectDriverAvailability === '') {
            setAvailibilityError('This is required')

            setTimeout(() => {
                setAvailibilityError('')
            }, 5000)
        }else {
            setshowFieldThirteen(false)
            setshowFieldFourteen(true)
            setQuestion(++question)
            setWidth(75)
            setStatus('Almost Done - Keep Moving')
        }
    }

    const [stateError, setStateError] = useState('')
    const [countryError, setCountryError] = useState('')
    const [showFieldFourteen, setshowFieldFourteen] = useState(false)
    const onClickFourteen = (e) => {
        e.preventDefault()

        if((!vehicleLocation || vehicleLocation === '') && (!countryValue.label || countryValue.label === '')) {
            setStateError('This is required')
            setCountryError('This is required')

            setTimeout(() => {
                setStateError('')
                setCountryError('')
            }, 5000)
        }else if(!vehicleLocation || vehicleLocation === '') {
            setStateError('This is required')

            setTimeout(() => {
                setStateError('')
            }, 5000)
        }else if (!countryValue.label || countryValue.label === '') {
            setCountryError('This is required')

            setTimeout(() => {
                setCountryError('')
            }, 5000)
        }else {
            setshowFieldFourteen(false)
            setShowFieldFifteen(true)
            setQuestion(++question)
            setWidth(80)
            setStatus('Almost Done - Keep Moving')
        }
    }

    const [showFieldFifteen, setShowFieldFifteen] = useState(false)
    const onClickFifteen = (e) => {
        e.preventDefault()
        setShowFieldFifteen(false)

        setShowFieldSixteen(true)
        setQuestion(++question)
        setWidth(85)
        setStatus('Almost Done - Keep Moving')
    }

    const [selectedInsuranceError, setSelectedInsuranceError] = useState('')
    const [showFieldSixteen, setShowFieldSixteen] = useState(false)
    const onClickSixteen = (e) => {
        e.preventDefault()

        if (!selectedInsurance || selectedInsurance === '') {
            setSelectedInsuranceError('This is required')

            setTimeout(() => {
                setSelectedInsuranceError('')
            }, 5000)
        }else {
            setShowFieldSixteen(false)
            setShowFieldSeventeen(true)
            setQuestion(++question)
            setWidth(90)
            setStatus('Almost Done - Keep Moving')
        }
    }

    const [imageError, setImageError] = useState('')
    const [showFieldSeventeen, setShowFieldSeventeen] = useState(false)
    const onClickSeventeen = (e) => {
        e.preventDefault()

        if(!selectedImage || !selectedImage1 || !selectedImage2) {
            setImageError('This is required')

            setTimeout(() => {
                setImageError('')
            }, 5000)
        }else {
            setShowFieldSeventeen(false)

            setShowFieldEighteen(true)
            setQuestion(++question)
            setWidth(95)
            setStatus('Almost Done - Keep Moving')
        }
    }

    const [carServiceError, setCarServiceError] = useState('')
    const [showFieldEighteen, setShowFieldEighteen] = useState(false)
    const onClickEighteen = (e) => {
        e.preventDefault()

        if(updateAvialablity.length === 0) {
            setCarServiceError('This is required')

            setTimeout(() => {
                setCarServiceError('')
            }, 5000)
        }else {
            setShowFieldEighteen(false)

            setShowFieldNineteen(true)
            setQuestion(++question)
            setWidth(100)
            setStatus('Completed')
        }
    }

    const [showFieldNineteen, setShowFieldNineteen] = useState(false)


    const phoneRegExp = /^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/

    const setId = Math.floor(Math.random() * 10000) + 1;






    const [bg, setBg] = useState(false)
    let [successOpen, setSuccessOpen] = useState(false)


    function closeModal() {
        setSuccessOpen(false)
        setBg(false)


    }

    // Use this  to update the state that handles address
    const handleNewAddressChange = (e) => {
        setAddress(e.target.value)
    }

    // useEffect(() => {
    //     setAddress(addressNew)
    // }, [addressNew])

    let carOwnerId = JSON.parse(localStorage.getItem("carownerId"))

    const handleCarReg = (e) => {
        e.preventDefault();
        let formData = new FormData();
        setFormLoad(Spin)


        formData.append('vehicle_name', vehicleName)
        formData.append('year', year)

        formData.append('plate_number', plateNumber)
        formData.append('vehicle_address', address)
        formData.append('daily_price', numDailyPrice)
        formData.append('monthly_price',  numMonthlyPrice)
        formData.append('hour_available', hourAvailable)
        formData.append('air_con', airConditioner)
        formData.append('travel_available', availableTravel)
        formData.append('vehicle_type', selectedVechicleType)
        formData.append('vehicle_color', vehicleColor)
        formData.append('come_with_driver', selectDriverAvailability)
        formData.append('service', updateAvialablity)
        formData.append('passenger_seat', noOfSeats)
        formData.append('airport_price', numAirportPrice)
        formData.append('vehicle_insurance', compInsurance )
        formData.append('insurance_option', selectedInsurance)
        formData.append('state', vehicleLocation)
        formData.append('file', selectedImage)
        formData.append('file2', selectedImage1)
        formData.append('file3', selectedImage2)
        formData.append('notes', notes)
        formData.append('country', countryValue.label)
        formData.append('provider_service', userService1)
        formData.append('carowner_id', carOwnerId)

    const formDataObj = {};
    formData.forEach((value, key) => (formDataObj[key] = value));
    const {file, file2, file3, ...newData} = formDataObj; // newData gets all except destructured i think
    setDataSave(newData);
    localStorage.setItem("newData", JSON.stringify(newData))

        axios({
            method: 'post',
            url: `${BASE_URL}/v1/add-vehicle`,
            data: formData,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            }
        })
            .then(function (response) {
                //handle success
                gaEventTracker('Save Car Register Btn')
                vehicleInfoUploadEvent()
                const vehicleData = response.data

                // TEMPORAL, WILL CHANGE DURING RESTRUCTURE
                localStorage.setItem("vehicleId", JSON.stringify(vehicleData.data.id))
                setFormLoad("")
                localStorage.removeItem("carownerId")

                setShowDocumentSection(true)
                setIsRegistered(true)
                docRef.current?.scrollIntoView({behavior: 'smooth'});

                // setSuccessOpen(true)
                // if (successOpen === true) {
                //     setBg(true)
                // }
                // setSuccessOpen(false)

            })
            .catch((error)  => {
                // alert(error)
                setFormLoad("")
                let notify = () => { toast.error((error.response.data.message), {
                    toastClassName: 'error'
                } ) };
                notify()
            });
    }

    // SET VEHICLE ID FROM LOCAL STORAGE
    let vehicleId = JSON.parse(localStorage.getItem("vehicleId"))

    useEffect(() => {
        let newDatas = JSON.parse(localStorage.getItem("newData"))
        setVehicleData(newDatas)
    }, [])

    // SETTING PENDING NOTICE DIRECTION
    localStorage.setItem("pendingDirection", JSON.stringify("vehicle-pending"))


    const handleProofSubmit = (e) => {
        e.preventDefault()

        if (proof) {
            setLoad(Spin)
            const reader = new FileReader()

            reader.readAsDataURL(proof)


            let formDatas = new FormData();
            formDatas.append('file', proof)
            formDatas.append('doc_type', 'proof of ownership')
            formDatas.append('vehicle_id', vehicleId)

            axios({
                method: 'post',
                url: `${BASE_URL}/v1/proof-of-ownership`,
                data: formDatas,

                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    "Access-Control-Allow-Origin": "*"

                }
            })
                .then(function (response) {
                    localStorage.setItem("proof-of-ownership", JSON.stringify(response.data.data))
                    gaEventTracker('Handle Proof Submit')
                    proofOwnerDocEvent()
                    notify()
                    setLoad('')
                    setIsProofSaved(true)

                })
                .catch(function (error) {
                    let notify = () => { toast.error((error.response.data.message), {
                        toastClassName: 'error'
                    } ) };
                    notify()
                });

        } else {
            let notify = () => { toast.error(('Upload a document before you save'), {
                toastClassName: 'error'
            } ) };
            notify()
            setLoad('')
        }
    }

    // COMBINING AXIOS POST
    const handleTotalDocSubmit = (e) => {
        e.preventDefault()

        let formDataLicense = new FormData();
        let formDataInsurance = new FormData();
        let formDataWorthiness = new FormData();
        let formDataHackney = new FormData();
        let formData = new FormData();

        formDataLicense.append('file', licence)
        formDataLicense.append('doc_type', 'vehicle license')
        formDataLicense.append('vehicle_id', vehicleId)

        formDataInsurance.append('file', insurance)
        formDataInsurance.append('doc_type', 'car insurance')
        formDataInsurance.append('vehicle_id', vehicleId)

        formDataWorthiness.append('file', worthiness)
        formDataWorthiness.append('doc_type', 'road worthiness')
        formDataWorthiness.append('vehicle_id', vehicleId)

        formDataHackney.append('file', hackney)
        formDataHackney.append('doc_type', 'hackney permit')
        formDataHackney.append('vehicle_id', vehicleId)

        formData.append('user_id', userId)
        formData.append('month', expMonth)
        formData.append('vehicle_id', vehicleId)
        formData.append('year', expYear)


            if(isProofSaved && licence && expMonth && expYear) {
                const vehicleLicensePost = axios({
                    method: 'post',
                    url: `${BASE_URL}/v1/vehicle-licence`,
                    data: formDataLicense,
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token || null,
                        "Access-Control-Allow-Origin": "*",
                    }
                })
        
                const vehicleInsurancePost = axios({
                    method: 'post',
                    url: `${BASE_URL}/v1/car-insurance`,
                    data: formDataInsurance,
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token || null,
                        "Access-Control-Allow-Origin": "*",
                    }
                })
        
                const vehicleWorthinessPost = axios({
                    method: 'post',
                    url: `${BASE_URL}/v1/road-worthiness`,
                    data: formDataWorthiness,
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token || null,
                        "Access-Control-Allow-Origin": "*",
                    }
                })
        
                const vehicleHackneyPermit = axios({
                    method: 'post',
                    url: `${BASE_URL}/v1/hackney-permit`,
                    data: formDataHackney,
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token || null,
                        "Access-Control-Allow-Origin": "*",
                    }
                })
        
                const docExpirePost = axios({
                    method: 'post',
                    url: `${BASE_URL}/v1/document-expiration`,
                    data: formData,
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                        "Access-Control-Allow-Origin": "*",
                    }
                })
                
            axios.all([vehicleLicensePost, vehicleInsurancePost, vehicleWorthinessPost , vehicleHackneyPermit , docExpirePost]).then(axios.spread((...responses) => {
                const responseOne = responses[0]
                const responseTwo = responses[1]
                const responseThree = responses[2]
                const responseFour = responses[3]
                const responseFive = responses[4]
                // use/access the results 
                gaEventTracker('Total Vehicle Doc Submit Btn')
                vehicleDocsUploadEvent()
                navigate('/onboarding')
              })).catch(errors => {
                let notify = () => { toast.error((errors.response.data.message), {
                    toastClassName: 'error'
                } ) };
                notify()
              })
            }else if(!isProofSaved) {
                alert('Proof of ownership must be uploaded and saved')
            }else if(!licence) {
                alert('Upload vehicle license')
            }else  {
                alert('Document expiration date is empty')
            }
    }




    const options = [
        { value: 'Comes with driver', label: 'Comes with driver' },
        { value: 'Comes without driver', label: 'Comes without driver' },
        { value: 'Full-tank per day', label: 'Full-tank per day' },
        { value: 'No Fuel', label: 'No Fuel' },
        { value: 'Available Night', label: 'Available Night' },
        { value: 'Available Monthly Full-time', label: 'Available Monthly Full-time' },
        { value: 'Available Weekly', label: 'Available Weekly' }
    ]


    return <div>
        <Header user='provider' />
        <ToastContainer
            toastClassName={({ type }) => contextClass[type || "default"] + 
                " relative flex p-1 min-h-10 rounded-md justify-between overflow-hidden cursor-pointer"
            }
            bodyClassName={() => "text-sm font-white font-med block p-3"}
            // position="bottom-left"
            autoClose={3000}
            />

        <Transition appear show={successOpen} as={Fragment}>
            <Dialog
                as="div"
                className="fixed flex items-center justify-center w-full px-6 mt-8  inset-0 z-10 overflow-y-auto"
                onClose={closeModal}
            >
                <div className="min-h-screen px-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="inline-block h-screen align-middle"
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className="md:w-10/12 p-6  inline-block  max-w-md pt-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-md">
                            <Dialog.Title
                                as="h3"
                                className="text-lg font-medium leading-6 text-gray-900 flex justify-center"
                            >
                                <img src={success} alt="" className='w-8/12' />
                            </Dialog.Title>
                            {/* 
                            <div className=" flex flex-col gap-4 items-center mb-8">


                                <button className='text-xs text-center rounded-md text-white font-bold bg-black p-2 w-full'
                                    onClick={handleCarReg}
                                >Duplicate Previous Upload</button>
                               
                                <button  onClick={handleStart} className='text-xs text-center rounded-md text-black font-bold bg-amber-500 p-2 w-full'>Start a new Upload</button>

                            </div> */}
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>




        <main className='flex flex-col md:flex-row md:px-12 px-12 vehicle-reg-overall'>

            <Navigation />

            {/* <div className='h-100 md:w-2/5 w-full md:px-20 pb-20 pt-12 md:pt-36'> */}

            <div className='h-100 md:w-8/12 w-full md:px-20 pb-20 pt-12 md:pt-36 vehicle-reg-wrapper'>


                <div className="flex items-center justify-between vehicle-reg-header-text">
                    <h2 className='text-white font-bold text-3xl mt-0'>Upload Your Vehicle in 3 Mins</h2>
                </div>
                <h4 className='md:text-amber-500 text-[18px] text-amber-500 font-medium mb-20 md:font-semibold mt-[60px]'>{status}</h4>

                <div className="w-full mt-12 relative">
                    <input type="range" min="1" max={allQuestion} value={question} className="slider" id="myRange" />
                    <p className='text-white text-base font-semibold absolute -top-10 left-0 '>{question}/{allQuestion}</p>
                    <p className='text-white text-base  font-semibold absolute -top-10 right-0 '>{allQuestion}</p>

                    <div
                        id="background"

                    ></div>
                    <div
                        id="progress"
                        ref={refDiv}
                        style={{ width: width + '%' }}

                    >
                    </div>


                </div>






                <form>
                    {showFieldOne && (
                        <>
                            <div className="flex gap-3 mt-12 w-full">

                                <div className="flex flex-col w-1/2">
                                    <label htmlFor="Vehicle name" className='text-white  text-base font-semibold'>Vehicle Name <span className="text-red-600">*</span></label>
                                    <input
                                        type="text"
                                        placeholder="Type here....."
                                        className=' mt-2 text-black md:text-base text-base bg-white border border-white p-2 rounded-xl'

                                        name="vehicleName"
                                        id="vehicleName"
                                        defaultValue={vehicleName}
                                        onChange={e => setVehicleName(e.target.value)}
                                    />
                                    <p className='text-red-600'>{vehicleNameError}</p>
                                </div>


                                <div className="flex flex-col w-1/2">
                                    <label htmlFor="Year" className='text-white font-semibold  text-base'>Year <span className="text-red-600">*</span></label>
                                    <input
                                        type="text"
                                        placeholder="Type here....."

                                        className=' mt-2 md:text-base text-base text-black bg-white border border-white p-2 rounded-xl'
                                        name="year"
                                        id="year"
                                        defaultValue={year}
                                        onChange={e => setYear(e.target.value)}
                                    />
                                    <p className='text-red-600'>{yearError}</p>    
                                </div>
                            </div>


                            <div className="flex gap-2 items-center justify-between">
                                <div className="flex gap-2 items-center cursor-pointer">
                                    <div className="bg-black border border-white  my-8 p-2 text-center items-center gap-2 rounded-md font-bold  flex">
                                        <button
                                            onClick={onClickOne}
                                            className='text-white font-bold'>OK </button>
                                        <img src={mark} alt="" />
                                    </div>
                                    <h4 className='text-white font-bold'>Press Enter</h4>
                                </div>

                                <div className="flex gap-4 text-white font-extrabold">

                                    <button
                                        onClick={onClickOne}
                                    >
                                        <img src={next} alt="Next-btn" />
                                    </button>
                                </div>
                            </div>
                        </>
                    ) }



                    {showFieldTwo &&
                        (
                            <>

                                <div className="mt-12">
                                    <label htmlFor="Plate Number" className='text-base text-white font-normal '>Plate Number <span className="text-red-600">*</span> </label>

                                    <div className="mt-2 flex border border-white text-black bg-white  justify-between items-center rounded-xl">


                                        <input type="text"
                                            className='w-full p-3 bg-transparent text-black rounded-xl  bg-white'
                                            placeholder="Type here....."

                                            // name="plate_number"
                                            // id="plate_number"
                                            value={plateNumber}
                                            onChange={e => setPlateNumber(e.target.value)}
                                        />

                                    </div>
                                    <p className='text-red-600'>{plateNoError}</p>
                                </div>



                                <div className="flex items-center justify-between">

                                    <div className="flex gap-2 items-center cursor-pointer">
                                        <div className="bg-black border border-white  my-8 p-2 text-center items-center gap-2 rounded-md font-bold  flex">
                                            <button
                                                onClick={onClickTwo}
                                                className='text-white font-bold flex items-center justify-center'>OK <img src={mark} alt="" /></button>


                                        </div>
                                        <h4 className='text-white font-bold'>Press Enter</h4>
                                    </div>

                                    <div className="flex gap-4 text-white font-extrabold">
                                        <button
                                            onClick={onClickStart}

                                        >
                                            <h2>
                                            <img src={prev} alt="Prev-btn" />
                                            </h2></button>
                                        <button
                                            onClick={onClickTwo}
                                        >
                                            <img src={next} alt="Next-btn" />
                                        </button>
                                    </div>
                                </div>
                            </>) }

                    
                    {/* VEHICLE TYPE */}
                    {showFieldThree &&
                        (
                            <>
                                <div className="mt-12">
                                    <label htmlFor="Vehicle Type" className='text-base text-white font-normal '>Vehicle Type <span className="text-red-600">*</span> </label>

                                    <div className="text-base mt-2  w-full rounded-xl border border-white bg-white px-3 py-3 text-black">
                                        <Dropdown 
                                            holder= {selectedVechicleType || 'Select vehicle Type'}
                                            optionArray= {vehicleArray}
                                            setSelectedOption= {setSelectedVehicleType}
                                            selectedOption= {selectedVechicleType}
                                        />
                                    </div>
                                    <p className='text-red-600'>{vehicleTypeError}</p>
                                </div>


                                <div className="flex items-center justify-between">
                                    <div className="flex gap-2 items-center cursor-pointer">
                                        <div className="bg-black border border-white  my-8 p-2 text-center items-center gap-2 rounded-md font-bold  flex">
                                            <button
                                                onClick={onClickThree}
                                                className='text-white font-bold flex items-center justify-center'>OK <img src={mark} alt="" /></button>
                                        </div>
                                        <h4 className='text-white font-bold'>Press Enter</h4>
                                    </div>

                                    <div className="flex gap-4 text-white font-extrabold">
                                        <button
                                            onClick={onClickOneBack}
                                        >
                                            <h2>
                                            <img src={prev} alt="Prev-btn" />
                                            </h2></button>
                                        <button
                                            onClick={onClickThree}
                                        >
                                           <img src={next} alt="Next-btn" />
                                        </button>
                                    </div>
                                </div>
                            </>
                        ) 
                    }


                    {/* NO OF PASSENGER SEATS */}

                    {showFieldFour &&
                        (
                            <>
                                <div className="mt-12">
                                    <label htmlFor="Plate Number" className='text-base text-white font-normal '>No of passenger seats <span className="text-red-600">*</span> </label>


                                    <div className="mt-2 flex border border-white text-black bg-white items-center rounded-xl">

                                        <input type="number"
                                            className='w-full p-3 text-black rounded-xl  bg-white'
                                            placeholder="Type here..."
                                            name="noOfSeats"
                                            id="noOfSeats"
                                            value={noOfSeats}
                                            onChange={e => setNoOfSeats(e.target.value)}

                                        />

                                    </div>
                                    <p className='text-red-600'>{seatsNoError}</p>
                                </div>

                                <div className="flex items-center justify-between">

                                    <div className="flex gap-2 items-center cursor-pointer">
                                        <div className="bg-black border border-white  my-8 p-2 text-center items-center gap-2 rounded-md font-bold  flex">
                                            <button
                                                onClick={onClickFour}

                                                className='text-white font-bold flex items-center justify-center'>OK <img src={mark} alt="" /></button>


                                        </div>
                                        <h4 className='text-white font-bold'>Press Enter</h4>
                                    </div>

                                    <div className="flex gap-4 text-white font-extrabold">
                                        <button
                                            onClick={onClickTwoBack}

                                        >
                                            <h2>
                                            <img src={prev} alt="Prev-btn" />
                                            </h2></button>
                                        <button

                                            onClick={onClickFour}
                                        >
                                            <img src={next} alt="Next-btn" />
                                        </button>
                                    </div>

                                </div>
                            </>
                        ) 
                    }

                    {showFieldFive &&
                        (
                            <>
                                <div className="mt-12">
                                    <label htmlFor="Plate Number" className='text-base text-white font-normal '>Address <span className="text-red-600">*</span> </label>

                                    <div className="mt-2 flex border border-white text-black bg-white items-center rounded-xl">
                                    <Autocomplete className='autcomplete-style'
                                        onPlaceChanged={onPlaceChanged}
                                        onLoad={(value) => setAutocomplete(value)}
                                        
                                    >
                                        <input type="text"
                                            className='w-full p-3 text-black rounded-xl  bg-white'
                                            placeholder="Type here..."
                                            name="address"
                                            id="address"
                                            value={address}
                                            // defaultValue={address}
                                            onChange={(e) => setAddress(e.target.value)}
                                            // onSubmit={e => { e.preventDefault(); }}
                                        />
                                    </Autocomplete>    
                                    </div>
                                    <p className='text-red-600'>{addressError}</p>
                                </div>


                                <div className="flex items-center justify-between">

                                    <div className="flex gap-2 items-center cursor-pointer">
                                        <div className="bg-black border border-white  my-8 p-2 text-center items-center gap-2 rounded-md font-bold  flex">
                                            <button
                                                type="submit"
                                                onClick={onClickFive}
                                                className='text-white font-bold flex items-center justify-center'>OK <img src={mark} alt="" /></button>


                                        </div>
                                        <h4 className='text-white font-bold'>Press Enter</h4>
                                    </div>

                                    <div className="flex gap-4 text-white font-extrabold">
                                        <button
                                            type="submit"
                                            onClick={onClickThreeBack}
                                        >
                                            <h2>
                                            <img src={prev} alt="Prev-btn" />
                                            </h2></button>
                                        <button
                                            type="submit"
                                            onClick={onClickFive}
                                        >
                                            <img src={next} alt="Next-btn" />
                                        </button>
                                    </div>
                                </div>
                            </>
                        )            
                    
                    }



                    {showFieldSix &&
                        (
                            <>

                                <div className="mt-12">
                                    <label htmlFor="Daily Price" className='text-base text-white font-normal '>Daily Rental Price <span className="text-red-600">*</span> </label>


                                    <div className="mt-2 flex border border-white text-black bg-white items-center  rounded-xl">
{/* 
                                        <input type="text"
                                            className='w-full p-3 text-black rounded-xl  bg-white'
                                            placeholder="Type here....."

                                            name="daily_price"
                                            id="daily_price"
                                            prefix={prefix}
                                            value={(numDailyPrice).toLocaleString('en-US', {maximumFractionDigits:10})}
                                            onChange={e => setDailyPrice(e.target.value)}
                                        /> */}

                                        <CurrencyInput
                                            id="input-example"
                                            name="input-name"
                                            placeholder="Please enter a number"
                                            prefix={prefix}
                                            value={dailyPrice}
                                            disableAbbreviations={true}
                                            decimalsLimit={2}
                                            onValueChange={(value, name) => handleDailyPrice(value)}
                                            className="curency-input"
                                        />;
                                    </div>
                                    <p className='text-red-600'>{dailyPriceError}</p>


                                    <div className="flex my-4 md:my-4 justify-between">
                                        <div className="flex flex-col mr-4">
                                            <label htmlFor="VAT 7.5%" className='text-xs text-white font-normal'>VAT 7.5%</label>
                                        </div>
                                        <div className="flex flex-col">
                                            <label htmlFor="Service fee 3.5% to 10%" className='text-xs text-white font-normal'>Service fee 3.5% to 10%</label>

                                        </div>
                                    </div>

                                </div>

                                <div className="flex items-center justify-between">

                                    <div className="flex gap-2 items-center cursor-pointer">
                                        <div className="bg-black border border-white  my-8 p-2 text-center items-center gap-2 rounded-md font-bold  flex">
                                            <button
                                                onClick={onClickSix}

                                                className='text-white font-bold flex items-center justify-center'>OK <img src={mark} alt="" /></button>


                                        </div>
                                        <h4 className='text-white font-bold'>Press Enter</h4>
                                    </div>

                                    <div className="flex gap-4 text-white font-extrabold">
                                        <button
                                            onClick={onClickFourBack}
                                        >
                                            <h2>
                                            <img src={prev} alt="Prev-btn" />
                                            </h2></button>
                                        <button

                                            onClick={onClickSix}
                                        >
                                           <img src={next} alt="Next-btn" />
                                        </button>
                                    </div>
                                </div>
                            </>)}

                    
                    {/* AIRPORT PICKUP PRICE */}
                    {showFieldSeven &&
                        (
                            <>
                                <div className="mt-12">
                                    <label htmlFor="Plate Number" className='text-base text-white font-normal '>Airport Pick-up Price <span className="text-red-600">*</span> </label>


                                    <div className="mt-2 flex border border-white text-black bg-white items-center rounded-xl">
                                        <CurrencyInput
                                            id="input-example"
                                            name="input-name"
                                            placeholder="Please enter a number"
                                            prefix={prefix}
                                            value={airportPrice}
                                            disableAbbreviations={true}
                                            decimalsLimit={2}
                                            onValueChange={(value, name) => setAirportPrice(value)}
                                            className="curency-input"
                                        />
                                    </div>
                                    <p className='text-red-600'>{airportPriceError}</p>

                                    <div className="flex my-4 md:my-4 justify-between">
                                        <div className="flex flex-col mr-4">
                                            <label htmlFor="VAT 7.5%" className='text-xs text-white font-normal'>VAT 7.5%</label>
                                        </div>
                                        <div className="flex flex-col">
                                            <label htmlFor="Service fee 3.5% to 10%" className='text-xs text-white font-normal'>Service fee 3.5% to 10%</label>
                                        </div>
                                    </div>
                                </div>


                                <div className="flex items-center justify-between">

                                    <div className="flex gap-2 items-center cursor-pointer">
                                        <div className="bg-black border border-white  my-8 p-2 text-center items-center gap-2 rounded-md font-bold  flex">
                                            <button
                                                onClick={onClickSeven}

                                                className='text-white font-bold flex items-center justify-center'>OK <img src={mark} alt="" /></button>


                                        </div>
                                        <h4 className='text-white font-bold'>Press Enter</h4>
                                    </div>
                                    <div className="flex gap-4 text-white font-extrabold">
                                        <button
                                            onClick={onClickFiveBack}

                                        >
                                            <h2>
                                            <img src={prev} alt="Prev-btn" />
                                            </h2></button>
                                        <button

                                            onClick={onClickSeven}
                                        >
                                            <img src={next} alt="Next-btn" />
                                        </button>
                                    </div>
                                </div>
                            </>
                        ) 
                    }




                    {showFieldEight &&
                        (
                            <>
                                <div className="mt-12">
                                    <label htmlFor="Monthly Price" className='text-base text-white font-normal '>Monthly Price (1 Month Rental) <span className="text-red-600">*</span> </label>


                                    <div className="flex border border-white text-black bg-white justify-between items-center mt-2 rounded-xl">

                                        {/* <input type="text"
                                            className='w-full p-3 text-black rounded-xl  bg-white'
                                            placeholder="Type here....."

                                            name="monthly_price"
                                            id="monthly_price"

                                            value={monthlyPrice}
                                            onChange={e => setMonthlyPrice(e.target.value)}

                                        /> */}

                                        <CurrencyInput
                                        id="input-example"
                                        name="input-name"
                                        placeholder="Please enter a number"
                                        prefix={prefix}
                                        value={monthlyPrice}
                                        disableAbbreviations={true}
                                        decimalsLimit={2}
                                        onValueChange={(value) => setMonthlyPrice(value)}
                                        className="curency-input"
                                        />

                                    </div>
                                    <p className="text-red-600">{monthlyPriceError}</p>

                                    <div className="flex my-4 md:my-4 justify-between">
                                        <div className="flex flex-col mr-4">
                                            <label htmlFor="VAT 7.5%" className='text-xs text-white font-normal'>VAT 7.5%</label>
                                        </div>
                                        <div className="flex flex-col">
                                            <label htmlFor="Service fee 3.5% to 10%" className='text-xs text-white font-normal'>Service fee 3.5% to 10%</label>

                                        </div>
                                    </div>

                                </div>


                                <div className="flex items-center justify-between">

                                    <div className="flex gap-2 items-center cursor-pointer">
                                        <div className="bg-black border border-white  my-8 p-2 text-center items-center gap-2 rounded-md font-bold  flex">
                                            <button
                                                onClick={onClickEight}
                                                className='text-white font-bold flex items-center justify-center'>OK <img src={mark} alt="" /></button>


                                        </div>
                                        <h4 className='text-white font-bold'>Press Enter</h4>
                                    </div>

                                    <div className="flex gap-4 text-white font-extrabold">
                                        <button
                                            onClick={onClickSixBack}

                                        >
                                            <h2>
                                            <img src={prev} alt="Prev-btn" />
                                            </h2></button>
                                        <button

                                            onClick={onClickEight}
                                        >
                                            <img src={next} alt="Next-btn" />
                                        </button>
                                    </div>
                                </div>
                            </>) }


                    {showFieldNine &&
                        (
                            <>
                                <div className="mt-12">
                                    <label htmlFor="Monthly Price" className='text-base text-white font-normal '>Hours Available <span className="text-red-600">*</span> </label>


                                    <div className="flex border border-white text-black bg-white items-center mt-2 rounded-xl">

                                        <input type="number"
                                            min='12'
                                            className='w-full p-3 text-black rounded-xl  bg-white'
                                            placeholder="Minimum is 12 hours"
                                            name="hours_available"
                                            id="hours_available"
                                            value={hourAvailable}
                                            onChange={e => setHourAvailable(e.target.value)}
                                        />
                                    </div>
                                    <p className="text-red-500">{errorMessage}</p>
                                </div>

                                <div className="flex items-center justify-between">
                                    <div className="flex gap-2 items-center cursor-pointer">
                                        <div className="bg-black border border-white  my-8 p-2 text-center items-center gap-2 rounded-md font-bold  flex">
                                            <button
                                                onClick={onClickNine}

                                                className='text-white font-bold flex items-center justify-center'>OK <img src={mark} alt="" /></button>


                                        </div>
                                        <h4 className='text-white font-bold'>Press Enter</h4>
                                    </div>

                                    <div className="flex gap-4 text-white font-extrabold">
                                        <button
                                            onClick={onClickSevenBack}

                                        >
                                            <h2>
                                            <img src={prev} alt="Prev-btn" />
                                            </h2></button>
                                        <button

                                            onClick={onClickNine}
                                        >
                                            <img src={next} alt="Next-btn" />
                                        </button>
                                    </div>
                                </div>
                            </>) }



                    {showFieldTen &&
                        (
                            <>


                                <div className=" mb-8 flex justify-start flex-col items-start w-full mt-12">
                                    <p className='md:text-base text-xx mb-4 text-white'>Does this vehicle have a working Air-Conditioner? <span className="text-red-600">*</span></p>

                                    <div className="radio-container flex items-center w-full gap-5 pl-3">
                                        <label className='text-white radio-wrapper'>
                                            <input type="radio" name="air-con" value='yes' className="radio-style cursor-pointer" checked={airConditioner === "yes"} onChange={handleAirCon} />
                                            Yes
                                        </label>
 
                                        <label className='text-white radio-wrapper'>
                                            <input type="radio" name="air-con" value='no' className="radio-style cursor-pointer" checked={airConditioner === "no"} onChange={handleAirCon}  />
                                            No
                                        </label>
                                    </div>

                                </div>



                                <div className="flex items-center justify-between">
                                    <div className="flex gap-2 items-center cursor-pointer">
                                        <div className="bg-black border border-white  my-8 p-2 text-center items-center gap-2 rounded-md font-bold  flex">
                                            <button
                                                onClick={onClickTen}

                                                className='text-white font-bold flex items-center justify-center'>OK <img src={mark} alt="" /></button>


                                        </div>
                                        <h4 className='text-white font-bold'>Press Enter</h4>
                                    </div>

                                    <div className="flex gap-4 text-white font-extrabold">
                                        <button
                                            onClick={onClickEightBack}

                                        >
                                            <h2>
                                            <img src={prev} alt="Prev-btn" />
                                            </h2></button>
                                        <button

                                            onClick={onClickTen}
                                        >
                                            <img src={next} alt="Next-btn" />
                                        </button>
                                    </div>
                                </div>
                            </>) }




                    {showFieldEleven &&
                        (
                            <>
                                <div className=" mb-8 flex justify-start flex-col items-start w-full mt-12">
                                    <p className='md:text-base text-xx mb-4 text-white'>Is this vehicle avaliable for Travel? <span className="text-red-600">*</span></p>

                                    
                                    <div className="radio-container flex items-center w-full gap-5 pl-3">
                                        <label className='text-white radio-wrapper'>
                                            <input type="radio" name="available-vehicle" value='yes' className="radio-style cursor-pointer" checked={availableTravel === "yes"} onChange={ handleTravel}/>
                                            Yes
                                        </label>
 
                                        <label className='text-white radio-wrapper'>
                                            <input type="radio" name="available-vehicle" value='no' className="radio-style cursor-pointer" checked={availableTravel === "no"} onChange={ handleTravel} />
                                            No
                                        </label>
                                    </div>



                                </div>



                                <div className="flex items-center justify-between">

                                    <div className="flex gap-2 items-center cursor-pointer">
                                        <div className="bg-black border border-white  my-8 p-2 text-center items-center gap-2 rounded-md font-bold  flex">
                                            <button
                                                onClick={onClickEleven}

                                                className='text-white font-bold flex items-center justify-center'>OK <img src={mark} alt="" /></button>


                                        </div>
                                        <h4 className='text-white font-bold'>Press Enter</h4>
                                    </div>
                                    <div className="flex gap-4 text-white font-extrabold">
                                        <button
                                            onClick={onClickNineBack}

                                        >
                                            <h2>
                                            <img src={prev} alt="Prev-btn" />
                                            </h2></button>
                                        <button

                                            onClick={onClickEleven}
                                        >
                                            <img src={next} alt="Next-btn" />
                                        </button>
                                    </div>
                                </div>
                            </>) }




                    {showFieldTwelve &&
                        (
                            <>
                                <div className="mt-12">
                                    <label htmlFor="Vehicle Color" className='text-base text-white font-normal '>Vehicle Color <span className="text-red-600">*</span> </label>


                                    <div className="flex border border-white text-black bg-white justify-between items-center mt-2 rounded-xl">
                                        <input 
                                            type="text"
                                            className='w-full p-3 text-black rounded-xl  bg-white'
                                            placeholder="Type here....."
                                            name="vehicle_color"
                                            id="vehicle_color"
                                            value={vehicleColor}
                                            onChange={e => setVehicleColor(e.target.value)}
                                        />
                                    </div>
                                    <p className='text-red-600'>{vehicleColorError}</p>

                                </div>


                                <div className="flex items-center justify-between">

                                    <div className="flex gap-2 items-center cursor-pointer">
                                        <div className="bg-black border border-white  my-8 p-2 text-center items-center gap-2 rounded-md font-bold  flex">
                                            <button
                                                onClick={onClickTwelve}
                                                className='text-white font-bold flex items-center justify-center'>OK <img src={mark} alt="" /></button>


                                        </div>
                                        <h4 className='text-white font-bold'>Press Enter</h4>
                                    </div>

                                    <div className="flex gap-4 text-white font-extrabold">
                                        <button
                                            onClick={onClickTenBack}

                                        >
                                            <h2>
                                            <img src={prev} alt="Prev-btn" />
                                            </h2></button>
                                        <button

                                            onClick={onClickTwelve}
                                        >
                                            <img src={next} alt="Next-btn" />
                                        </button>
                                    </div>
                                </div>
                            </>) }


                    {showFieldThirteen &&
                        (
                            <>

                                <div className="mt-12">
                                    <label htmlFor="Service" className='text-base text-white font-normal '>Does your vehicle come with a driver? <span className="text-red-600">*</span> </label>

                                    <div className="text-base mt-2  w-full rounded-xl border border-white bg-white px-3 py-3 text-black">

                                    <Dropdown 
                                        holder= {driverAvailabilityValue || 'Select Your choice'}
                                        optionArray= {comesWithDriverArray}
                                        setSelectedOption= {setSelectDriverAvaialability}
                                        selectedOption= {selectDriverAvailability}
                                        setItemDisplayValue={setDriverAvailabilityValue}
                                        itemDisplayValue= {driverAvailabilityValue}
                                    />
                                    </div>
                                    <p className='text-red-600'>{availibilityError}</p>



                                </div>

                                <div className="flex items-center justify-between">

                                    <div className="flex gap-2 items-center cursor-pointer">
                                        <div className="bg-black border border-white  my-8 p-2 text-center items-center gap-2 rounded-md font-bold  flex">
                                            <button
                                                onClick={onClickThirteeen}

                                                className='text-white font-bold flex items-center justify-center'>OK <img src={mark} alt="" /></button>


                                        </div>
                                        <h4 className='text-white font-bold'>Press Enter</h4>
                                    </div>

                                    <div className="flex gap-4 text-white font-extrabold">
                                        <button
                                            onClick={onClickElevenBack}

                                        >
                                            <h2>
                                            <img src={prev} alt="Prev-btn" />
                                            </h2></button>
                                        <button

                                            onClick={onClickThirteeen}
                                        >
                                            <img src={next} alt="Next-btn" />
                                        </button>
                                    </div>
                                </div>
                            </>) }


                    {showFieldFourteen &&
                        (
                            <>


                                <div className="flex gap-5 my-6 md:my-12 w-full">
                                    <div className="flex flex-col w-1/2">
                                        <label htmlFor="State" className='text-xs text-white font-normal'>Vehicle Location <span className="text-red-600">*</span> </label>
                                        <input
                                            type="text"
                                            placeholder="State"

                                            className='text-base mt-2 bg-white rounded-xl border border-white px-3 py-3 focus:outline-none text-black'

                                            value={vehicleLocation}
                                            onChange={e => setVehicleLocation(e.target.value)}
                                        />
                                        <p className='text-red-600'>{stateError}</p>
                                    </div>
                                    <div className="flex flex-col w-1/2 country-value ">
                                        <label htmlFor="Country" className='text-xs text-white font-normal'>&nbsp;</label>
                                        <Select className='countrySelectInput' options={optionsCountry} value={countryValue} onChange={changeHandlerCountry} />
                                        {/* <input
                                            type="text"
                                            placeholder="Country"
                                            className='text-base mt-2 bg-white rounded-xl border border-white px-3 py-3 focus:outline-none text-black' 
                                            value={countryValue}
                                            onChange={e => setCountryValue(e.target.value)}    
                                        /> */}
                                        <p className='text-red-600'>{countryError}</p>
                                    </div>
                                </div>





                                <div className="flex items-center justify-between">

                                    <div className="flex gap-2 items-center cursor-pointer">
                                        <div className="bg-black border border-white  my-8 p-2 text-center items-center gap-2 rounded-md font-bold  flex">
                                            <button
                                                onClick={onClickFourteen}
                                                className='text-white font-bold flex items-center justify-center'>OK <img src={mark} alt="" /></button>
                                            {/* <input 
                                        type="submit" 
                                        className="form-button2" 
                                        onClick={e => handleCarReg(e)} value='Ok'/> */}

                                        </div>
                                        <h4 className='text-white font-bold'>Press Enter</h4>
                                    </div>

                                    <div className="flex gap-4 text-white font-extrabold">
                                        <button
                                            onClick={onClickTwelveBack}
                                        >
                                            <h2>
                                            <img src={prev} alt="Prev-btn" />
                                            </h2>
                                        </button>
                                        <button

                                            onClick={onClickFourteen}
                                        >
                                            <img src={next} alt="Prev-btn" />
                                        </button>

                                    </div>
                                </div>
                            </>)}


                    {/* COMPREHENSIVE INSURANCE? */}

                    {
                        showFieldFifteen &&

                        (
                            <>
    
    
                                <div className=" mb-8 flex justify-start flex-col items-start w-full mt-12">
                                    <p className='md:text-base text-xx mb-4 text-white'>Does this vehicle have a comprehensive  insurance? <span className="text-red-600">*</span></p>
    
                                    <div className="radio-container flex items-center w-full gap-5 pl-3">
                                        <label className='text-white radio-wrapper'>
                                            <input type="radio" name="available-vehicle" value='yes' className="radio-style cursor-pointer" checked={compInsurance === "yes"} onChange={ handleInsurance}/>
                                            Yes
                                        </label>
 
                                        <label className='text-white radio-wrapper'>
                                            <input type="radio" name="available-vehicle" value='no' className="radio-style cursor-pointer" checked={compInsurance === "no"} onChange={ handleInsurance} />
                                            No
                                        </label>
                                    </div>

    
    
                                </div>
    
    
    
                                <div className="flex items-center justify-between">
    
                                    <div className="flex gap-2 items-center cursor-pointer">
                                        <div className="bg-black border border-white  my-8 p-2 text-center items-center gap-2 rounded-md font-bold  flex">
                                            <button
                                                onClick={onClickFifteen}
    
                                                className='text-white font-bold flex items-center justify-center'>OK <img src={mark} alt="" /></button>
    
    
                                        </div>
                                        <h4 className='text-white font-bold'>Press Enter</h4>
                                    </div>
    
                                    <div className="flex gap-4 text-white font-extrabold">
                                        <button
                                            onClick={onClickThirteenBack}
    
                                        >
                                            <h2>
                                            <img src={prev} alt="Prev-btn" />
                                            </h2></button>
                                        <button
    
                                            onClick={onClickFifteen}
                                        >
                                            <img src={next} alt="Next-btn" />
                                        </button>
                                    </div>
                                </div>
                            </>
                        ) 
                    }


                    {/* SELECT VEHICLE INSURANCE DROPDOWN */}

                    {
                        showFieldSixteen &&
                        (
                            <>
                                <div className=" mb-8 flex justify-start flex-col items-start w-full mt-12">
                                    <p className='md:text-base text-xx mb-4 text-white'>Select a vehicle insurance option <span className="text-red-600">*</span></p>
    
                                    <div className="text-base mt-2  w-full rounded-xl border border-white bg-white px-3 py-3 text-black">

                                    <Dropdown 
                                        holder= {insuranceValue || 'Select Your choice'}
                                        optionArray= {insuranceArray}
                                        setSelectedOption= {setSelectedInsurance}
                                        selectedOption= {selectedInsurance}
                                        setItemDisplayValue={selectInsuranceValue}
                                        itemDisplayValue= {insuranceValue}
                                    />
                                    </div>
                                    <p className='text-red-600'>{selectedInsuranceError}</p>
    
                                </div>
    
    
    
                                <div className="flex items-center justify-between">
    
                                    <div className="flex gap-2 items-center cursor-pointer">
                                        <div className="bg-black border border-white  my-8 p-2 text-center items-center gap-2 rounded-md font-bold  flex">
                                            <button
                                                onClick={onClickSixteen}
    
                                                className='text-white font-bold flex items-center justify-center'>OK <img src={mark} alt="" /></button>
                                        </div>
                                        <h4 className='text-white font-bold'>Press Enter</h4>
                                    </div>
    
                                    <div className="flex gap-4 text-white font-extrabold">
                                        <button
                                            onClick={onClickFourteenBack}
    
                                        >
                                            <h2>
                                            <img src={prev} alt="Prev-btn" />
                                            </h2></button>
                                        <button
    
                                            onClick={onClickSixteen}
                                        >
                                            <img src={next} alt="Next-btn" />
                                        </button>
                                    </div>
                                </div>
                            </>
                        ) 
                    }


                    {showFieldSeventeen &&
                        (
                            <>


                                <div className="flex flex-wrap justify-start gap-4 md:gap-5 mt-8 w-full md:w-full img-view ">

                                    <div className="upload-overall">
                                        <div className={!selectedImage ? 'bg-[#000] upload-img-overall' : 'bg-white upload-img-overall' }>
                                            <div className='upload-img-container'>
                                            {/* <img src={car} alt="" className='contrast-75 brightness-50 md:w-36 w-28 h-auto bg-white rounded-md p-2 object-cover object-center' /> */}
                                                        <input
                                                        accept="image/*"
                                                        style={{ display: 'none' }}
                                                        ref={image1Ref}
                                                        type="file"
                                                        onChange={imageChange}
                                                        />

                                                        {
                                                            !selectedImage ? 
                                                            <div className=
                                                                "upload-img-cta absolute w-10/12 rounded-md border text-white font-light text-xx md:text-base border-amber-400 py-1 flex justify-center items-center z-[10] cursor-pointer"
                                                                onClick={(e) => {
                                                                    e.preventDefault()
                                                                    image1Ref.current.click()
                                                                }}
                                                            >
                                                            Upload Image
                                                            </div>
                                                            :
                                                            // <div className=
                                                            //     "absolute top-6 md:top-[40%] left-4 w-8/12 md:left-7 rounded-md border text-white font-light text-xx md:text-base border-amber-400 bg-amber-400 py-1 flex justify-center items-center z-[-1] cursor-pointer"
                                                            //     onClick={imageChange}
                                                            // >
                                                            //   Change Image
                                                            // </div>
                                                            ''
                                                        }

                                                        {selectedImage && (
                                                        <div className='img-preview'>
                                                            <img
                                                            src={URL.createObjectURL(selectedImage)}
                                                            className="uploaded-img "
                                                            alt="Thumb"
                                                            />
                                                            <button  onClick={removeSelectedImage} className="delete-img">
                                                                <img src={cancel} alt="cancel btn" />
                                                            </button>
                                                        </div>
                                                        )}
                                            </div>
                                            <p className='text-white mt-3'>Image 1 <span className='text-red-500'>*</span> <span className='text-[#FFA100]'>(Front View)</span></p>
                                        </div>  
                                    </div>


                                    <div className={!selectedImage1 ? 'bg-[#000] upload-img-overall individual-img-wrapper' : 'bg-white upload-img-overall individual-img-wrapper' }>
                                        <div className="upload-img-overall">
                                            <div className='upload-img-container'>
                                            {/* <img src={car} alt="" className='contrast-75 brightness-50 md:w-36 w-28 h-auto bg-white rounded-md p-2 object-cover object-center' /> */}
                                                        <input
                                                        accept="image/*"
                                                        style={{ display: 'none' }}
                                                        type="file"
                                                        ref={image2Ref}
                                                        onChange={imageChange1}
                                                        />

                                                        {
                                                            !selectedImage1 ? 
                                                            <div className=
                                                                "w-10/12 upload-img-cta rounded-md border text-white font-light text-xx md:text-base border-amber-400 py-1 flex justify-center items-center z-[10] cursor-pointer"
                                                                onClick={(e) => {
                                                                    e.preventDefault()
                                                                    image2Ref.current.click()
                                                                }}
                                                            >
                                                            Upload Image
                                                            </div>
                                                            :
                                                            // <div className=
                                                            //     "absolute top-6 md:top-[40%] left-4 w-8/12 md:left-7 rounded-md border text-white font-light text-xx md:text-base border-amber-400 bg-amber-400 py-1 flex justify-center items-center z-[-1] cursor-pointer"
                                                            //     onClick={imageChange}
                                                            // >
                                                            //   Change Image
                                                            // </div>
                                                            ''
                                                        }

                                                        {selectedImage1 && (
                                                        <div className='img-preview'>
                                                            <img
                                                            src={URL.createObjectURL(selectedImage1)}
                                                            className="uploaded-img object-cover object-center"
                                                            alt="Thumb"
                                                            />
                                                            <button  onClick={removeSelectedImage1} className="delete-img">
                                                                <img src={cancel} alt="cancel btn" />
                                                            </button>
                                                        </div>
                                                        )}
                                            </div>
                                        </div> 
                                        <p className='text-white mt-3'>Image 2 <span className='text-red-500'>*</span> <span className='text-[#FFA100]'>(Interior)</span></p>
                                    </div>


                                    <div className={!selectedImage2 ? 'bg-[#000] upload-img-overall individual-img-wrapper' : 'bg-white upload-img-overall individual-img-wrapper' }>
                                        <div className="upload-img-overall">
                                            <div className='upload-img-container'>
                                            {/* <img src={car} alt="" className='contrast-75 brightness-50 md:w-36 w-28 h-auto bg-white rounded-md p-2 object-cover object-center' /> */}
                                                        <input
                                                        accept="image/*"
                                                        style={{ display: 'none' }}
                                                        ref={image3Ref}
                                                        type="file"
                                                        onChange={imageChange2}
                                                        />

                                                        {
                                                            !selectedImage2 ? 
                                                            <div className=
                                                                " w-10/12 upload-img-cta rounded-md border text-white font-light text-xx md:text-base border-amber-400 py-1 flex justify-center items-center z-[10] cursor-pointer"
                                                                onClick={(e) => {
                                                                    e.preventDefault()
                                                                    image3Ref.current.click()
                                                                }}
                                                            >
                                                            Upload Image
                                                            </div>
                                                            :
                                                            // <div className=
                                                            //     "absolute top-6 md:top-[40%] left-4 w-8/12 md:left-7 rounded-md border text-white font-light text-xx md:text-base border-amber-400 bg-amber-400 py-1 flex justify-center items-center z-[-1] cursor-pointer"
                                                            //     onClick={imageChange}
                                                            // >
                                                            //   Change Image
                                                            // </div>
                                                            ''
                                                        }

                                                        {selectedImage2 && (
                                                        <div className='img-preview'>
                                                            <img
                                                            src={URL.createObjectURL(selectedImage2)}
                                                            className="uploaded-img object-cover object-center"
                                                            alt="Thumb"
                                                            />
                                                            <button  onClick={removeSelectedImage2} className="delete-img">
                                                                <img src={cancel} alt="cancel btn" />
                                                            </button>
                                                        </div>
                                                        )}
                                            </div>
                                        </div>   
                                        <p className='text-white mt-3'>Image 3 <span className='text-red-500'>*</span> <span className='text-[#FFA100]'>(Back View)</span></p>
                                    </div>

                                </div>
                                <p className='text-red-600'>{imageError}</p>

                                <div className="image-info mt-4">
                                    <p className="text-white text-base">Please note that your account manager will upload a cover image </p>
                                </div>



                                <div className="flex items-center justify-between">

                                    <div className="flex gap-2 items-center cursor-pointer">
                                        <div className="bg-black border border-white  my-8 p-2 text-center items-center gap-2 rounded-md font-bold  flex">
                                            <button
                                                onClick={onClickSeventeen}

                                                className='text-white font-bold flex items-center justify-center'>OK <img src={mark} alt="" /></button>


                                        </div>
                                        <h4 className='text-white font-bold'>Press Enter</h4>
                                    </div>

                                    <div className="flex gap-4 text-white font-extrabold">
                                        <button
                                            onClick={onClickFifteenBack}

                                        >
                                            <h2>
                                            <img src={prev} alt="Prev-btn" />
                                            </h2></button>
                                        <button

                                            onClick={onClickSeventeen}
                                        >
                                            <img src={next} alt="Next-btn" />
                                        </button>
                                    </div>
                                </div>
                            </>) }


                    {showFieldEighteen &&
                        (   
                        <>
                            <div className="flex flex-col mt-12">
                                <label htmlFor="Service" className='text-base text-white font-normal mb-2'>Service </label>
                                <Multiselect 
                                    customCloseIcon={<img src={cancel} className="cursor-pointer"/>}
                                    options={optionsValue} 
                                    displayValue="label" 
                                    onRemove={e => (setAvailabilitySelect(e))}
                                    onSelect={e => (setAvailabilitySelect(e))}

                                    placeholder={"Tick all the ones that apply to you"}
                                    hidePlaceholder={true}
                                    style={{
                                        chips: {
                                        border: 'solid 1px black',
                                        background: '#fff',
                                        color: '#000'
                                        },
                                        multiselectContainer: {
                                        color: '#000',
                                        cursor: 'pointer',
                                        },
                                        searchBox: {
                                        border: 'solid 1px #fff',
                                        'borderRadius': '13px',
                                        'padding': '10px',
                                        background: '#fff'
                                        },
                                        closeIcon: {
                                        'filter': 'grayscale(100%)'
                                        }
                                    }}
                                />

                            </div>
                            <p className='text-red-600'>{carServiceError}</p>

                            <div className="flex items-center justify-between">

                            <div className="flex gap-2 items-center cursor-pointer">
                                <div className="bg-black border border-white  my-8 p-2 text-center items-center gap-2 rounded-md font-bold  flex">
                                    <button
                                        onClick={onClickEighteen}
                                        className='text-white font-bold flex items-center justify-center'>OK <img src={mark} alt="" /></button>
                                </div>
                                <h4 className='text-white font-bold'>Press Enter</h4>
                            </div>

                            <div className="flex gap-4 text-white font-extrabold">
                                <button
                                    onClick={onClickSixteenBack}
                                >
                                    <h2>
                                    <img src={prev} alt="Prev-btn" />
                                    </h2>
                                </button>

                                <button

                                onClick={onClickEighteen}
                                >
                                <img src={next} alt="Next-btn" />
                                </button>

                            </div>
                            </div>
                        </>

                    )}


                    {
                        showFieldNineteen &&
                        <>
                        <div className="flex flex-col mt-12">
                            <label htmlFor="Daily Price" className='text-base text-white font-normal'>Add A Note / description  </label>
                            <div className="rounded-xl border border-white bg-white text-black mt-2 flex px-2">
                                <input
                                    type="text"
                                    placeholder='Add A Note / description'
                                    className='w-11/12 text-base bg-white rounded-xl  px-3 py-3 focus:outline-none text-black'
                                    value={notes}
                                    onChange={e => setNotes(e.target.value)}
                                />
                                <img src={message} alt="" className='w-7' />
                            </div>

                        </div>


                        <div className="flex items-center justify-between">

                            <div className="flex gap-2 items-center cursor-pointer">
                                <div className="bg-black border border-white  my-8 p-2 text-center items-center gap-2 rounded-md font-bold  flex">
                                    <button
                                        onClick={handleShowSubmitBtn}
                                        className='text-white font-bold flex items-center justify-center'>OK <img src={mark} alt="" /></button>
                                    {/* <input 
                                type="submit" 
                                className="form-button2" 
                                onClick={e => handleCarReg(e)} value='Ok'/> */}

                                </div>
                                <h4 className='text-white font-bold'>Press Enter</h4>
                            </div>

                            <div className="flex gap-4 text-white font-extrabold">
                                <button
                                    onClick={onClickSeventeenBack}
                                >
                                    <h2>
                                    <img src={prev} alt="Prev-btn" />
                                    </h2></button>

                            </div>
                        </div>
                    </>
                    }

                    {
                        showSubmitBtn &&
                        <div className="save-vehicle-reg">
                            <div className="w-full flex justify-end">
                            {
                            isRegistered ? 
                                <button 
                                disabled={true}
                                className='font-bold bg-[#FFF] w-1/4 my-8 p-2 text-center rounded-md font-bold '
                                // onClick={handleExpiration}
                                >
                                    {'Saved'}
                                </button> :
                                <button 
                                onClick={e => handleCarReg(e)}
                                className='flex justify-center items-center font-bold bg-[#FFA100] w-1/4 my-8 p-2 text-center rounded-md font-bold '
                                // onClick={handleExpiration}
                                >
                                    {'Save'}
                                    {
                                        formLoad && 
                                        <input
                                            type="image"
                                            src={Spin}
                                            value="Loading"
                                            className="w-4 md:w-6"
                                        />
                                    }
                                </button>
                                
                            }
                            </div>
                        </div>
                    }

                    
                </form>

                {/* VEHICLE DOCUMENTS */}

                {
                    showDocumentSection &&

                    <div className='md:px-0 w-full pt-20 md:pt-16 pb-20'  ref={docRef}>
                        <div className="md:flex justify-end hidden "><img src={eyee} alt="" className='md:w-10' /></div>


                        <div className="flex justify-between items-center md:w-4/5 w-full">
                            <h2 className='text-white font-bold text-lg'>Vehicle Documents</h2>

                            <img src={icon} alt="" className='md:w-8' />

                        </div>

                        {/* PROOF OF OWNERSHIP */}

                        <div className="mt-6">
                            <div className="flex justify-between border bg-[#1D1C1C] border-white p-2 md:p-3 items-center">
                                {
                                    proof && proof?.name ? (
                                        <p className='text-white md:text-base text-xs font-normal'>{proof?.name}</p>
                                    ) : (
                                        <p className='text-white md:text-base text-xs font-normal'>Proof of ownership <span className="text-red-600">*</span> </p>
                                    )
                                }


                                {/* use useRef to trigger this input field for proof of ownership upload */}
                                <input
                                    type="file"
                                    style={{ display: 'none' }}
                                    ref={fileInputRef}
                                    accept="image/jpeg,image/jpg,application/pdf"

                                    onChange={(event) => {
                                        const file = event.target.files[0]
                                        setIsProofSaved(false)
                                        if (file) {
                                            setProof(file)
                                            setUpload(true)
                                        }
                                        else {
                                            setProof(null)
                                        }
                                    }} 
                                />

                                {
                                    ( proof && proof?.name  ) ? (
                                        <button
                                            onClick={(event) => {
                                                event.preventDefault()
                                                fileInputRef.current.click()
                                            }}
                                            className='border-theme-color px-3 py-1 md:text-base md:px-2 md:py-0 text-xs text-white'>
                                                Change
                                        </button>

                                    ) : (
                                        <button
                                            onClick={(event) => {
                                                event.preventDefault()
                                                fileInputRef.current.click()
                                            }}
                                            className='border-theme-color px-3 py-1 md:text-base md:px-2 md:py-0 text-xs text-white'>
                                                Upload
                                        </button>
                                    )
                                }

                            </div>
                        </div>

                        {
                            isProofSaved ?

                            <div className="bg-amber-500 w-1/4 my-8 p-2 text-center rounded-md font-bold  ">
                                <button 
                                className='font-semibold'
                                >
                                    Saved
                                </button>
                            </div> :
                                <button 
                                className='flex justify-center items-center bg-white w-1/4 my-8 p-2 text-center rounded-md font-bold cursor-pointer'
                                onClick={handleProofSubmit}
                                >
                                    Save
                                    {
                                        load && 
                                        <input
                                            type="image"
                                            src={Spin}
                                            value="Loading"
                                            className="w-6"
                                        />
                                    }
                                </button>

                        }


                        {/* VEHICLE REGISTRATION */}
                        <div className="">
                            <div className="flex justify-between border bg-[#1D1C1C] border-white p-2 md:p-3 items-center">
                                {licence && licence?.name ?
                                    <p className='text-white md:text-base text-xs font-normal'>{licence?.name}</p>:
                                    <p className='text-white md:text-base text-xs font-normal'>Vehicle license / registration  <span className="text-red-600">*</span></p>

                                }
                                


                                <input
                                    type="file"
                                    style={{ display: 'none' }}
                                    ref={licenceInputRef}
                                    accept="image/jpeg,image/jpg,application/pdf"


                                    onChange={(event) => {
                                        const file = event.target.files[0]
                                        if (file) {
                                            setLicence(file)
                                            setLicenceUpload(true)
                                        }
                                        else {
                                            setLicence(null)
                                        }
                                    }} />


                                {
                                    (licence && licence?.name) ? (
                                        <button
                                            onClick={(event) => {
                                                event.preventDefault()
                                                licenceInputRef.current.click()
                                            }}
                                            className='border-theme-color px-3 py-1 md:text-base md:px-2 md:py-0 text-xs text-white'>
                                                Change
                                        </button>
                                    ) : (
                                        <button
                                            onClick={(event) => {
                                                event.preventDefault()
                                                licenceInputRef.current.click()
                                            }}
                                            className='border-theme-color px-3 py-1 md:text-base md:px-2 md:py-0 text-xs text-white'>
                                                Upload
                                        </button>
                                    )
                                }
                            </div>
                        </div>


                        {/* CAR INSURANCE */}
                        <input
                            type="file"
                            style={{ display: 'none' }}
                            ref={insuranceInputRef}

                            accept="image/jpeg,image/jpg,application/pdf"

                            onChange={(event) => {
                                const file = event.target.files[0]
                                if (file) {
                                    setInsurance(file)
                                    setInsuranceUpload(true)
                                }
                                else {
                                    setInsurance(null)
                                }
                            }} />


                        <div className="">
                            <div className="flex justify-between border bg-[#1D1C1C] border-white p-2 md:p-3 items-center">
                                {
                                    insurance && insurance?.name ?
                                    <p className='text-white md:text-base text-xs font-normal'>{insurance?.name} </p> :
                                    <p className='text-white md:text-base text-xs font-normal'>Certificate of car insurance <span  className='text-[#EBAF4A]'> {'(optional)'}</span> </p>
                                }
                            
                            {   (insurance && insurance?.name ) ? ( 
                                <button
                                onClick={(event) => {
                                    event.preventDefault()
                                    insuranceInputRef.current.click()
                                }}
                                className='border-theme-color px-3 py-1 md:text-base md:px-2 md:py-0 text-xs text-white'>Change</button>
                            
                            ):(
                                <button
                                    onClick={(event) => {
                                        event.preventDefault()
                                        insuranceInputRef.current.click()
                                    }}
                                    className='border-theme-color px-3 py-1 md:text-base md:px-2 md:py-0 text-xs text-white'>Upload</button>
                            )    
                            }
                            </div>
                        </div>



                        {/* ROAD WORTHINESS */}

                        <input
                            type="file"
                            style={{ display: 'none' }}
                            ref={worthinessInputRef}
                            accept="image/jpeg,image/jpg,application/pdf"
                            onChange={(event) => {
                                const file = event.target.files[0]
                                if (file) {
                                    setWorthiness(file)  
                                    setWorthinessUpload(true)
                                }
                                else {
                                    setWorthiness(null)
                                }
                            }} />


                        <div className="">
                            <div className="flex justify-between border bg-[#1D1C1C] border-white p-2 md:p-3 items-center">
                                {
                                    worthiness && worthiness?.name ?
                                    <p className='text-white md:text-base text-xs font-normal'>{worthiness?.name} </p> :
                                    <p className='text-white md:text-base text-xs font-normal'>Certificate of road worthiness <span  className='text-[#EBAF4A]'> {'(optional)'}</span> </p>

                                }
                                {   (worthiness && worthiness?.name) ? (
                                    <button
                                    onClick={(event) => {
                                        event.preventDefault()
                                        worthinessInputRef.current.click()
                                    }}
                                    className='border-theme-color px-3 py-1 md:text-base md:px-2 md:py-0 text-xs text-white'>Change</button>
                            
                        
                                ):(
                                    <button
                                    onClick={(event) => {
                                        event.preventDefault()
                                        worthinessInputRef.current.click()
                                    }}
                                    className='border-theme-color px-3 py-1 md:text-base md:px-2 md:py-0 text-xs text-white'>Upload</button>
                            
                        
                                )}
                            
                        
                            </div>
                        </div>




                        {/* HACKNEY PERMIT */}
                        <input
                            type="file"
                            style={{ display: 'none' }}
                            ref={hackneyInputRef}

                            accept="image/jpeg,image/jpg,application/pdf"

                            onChange={(event) => {
                                const file = event.target.files[0]
                                if (file) {
                                    setHackney(file)
                                    setHackneyUpload(true)
                                }
                                else {
                                    setHackney(null)
                                }
                            }} />

                        <div className="">
                            <div className="flex justify-between border bg-[#1D1C1C] border-white p-2 md:p-3 items-center">
                                {
                                    hackney && hackney?.name ?
                                    <p className='text-white md:text-base text-xs font-normal'>{hackney?.name} </p> :
                                    <p className='text-white md:text-base text-xs font-normal'>Hackney permit <span  className='text-[#EBAF4A]'> {'(optional)'}</span> </p>

                                }
                            
                            
                                {   (hackney && hackney?.name) ? (
                                    <button
                                    onClick={(event) => {
                                        event.preventDefault()
                                        hackneyInputRef.current.click()
                                    }}
                                    className='border-theme-color px-3 py-1 md:text-base md:px-2 md:py-0 text-xs text-white'>Change</button>
                            
                        
                                ):(
                                    <button
                                    onClick={(event) => {
                                        event.preventDefault()
                                        hackneyInputRef.current.click()
                                    }}
                                    className='border-theme-color px-3 py-1 md:text-base md:px-2 md:py-0 text-xs text-white'>Upload</button>
                            
                        
                                )}

                                
                            </div>


                        </div>


                        {/* DOCUMENT EXPIRATION DATE */}
                        <div className="py-8">


                            <form action="">
                                <label className="text-amber-500 text-base" htmlFor="">Document Expiration Date <span className='text-red-500'> * </span></label>
                                <div className="flex w-full  gap-4 mt-2">
                                    <div
                                        className='w-1/2 mt-1 text-white md:text-base text-base bg-[#1D1C1C] border border-white p-2 rounded-xl'>
                                        <select
                                            name=""
                                            id=""
                                            className='w-11/12 bg-[#1D1C1C] text-white color focus:outline-none'
                                            value={expMonth}
                                            onChange={(e) => setExpMonth(e.target.value)}
                                        >
                                            <option value="">Month</option>
                                            <option value="January">January</option>
                                            <option value="February">February</option>
                                            <option value="March">March</option>
                                            <option value="April">April</option>
                                            <option value="May">May</option>
                                            <option value="June">June</option>
                                            <option value="July">July</option>
                                            <option value="August">August</option>
                                            <option value="September">September</option>
                                            <option value="October">October</option>
                                            <option value="November">November</option>
                                            <option value="December">December</option>
                                        </select>
                                    </div>

                                    <input
                                        type="number"
                                        className='w-1/2 mt-2 text-white md:text-base text-base bg-[#1D1C1C] border border-white p-2 rounded-xl placeholder:text-white '

                                        min="2005"
                                        max="2099"
                                        step="1"
                                        placeholder="Year" 
                                        value={expYear}
                                            onChange={(e) => setExpYear(e.target.value)}
                                    />
                                </div>

                            </form>

                        </div>



                        <div className="bg-white w-2/4 my-8 p-2 text-center rounded-md font-bold ">
                            <button 
                            type='submit' 
                            className='font-semibold'
                            onClick={ handleTotalDocSubmit}
                            >
                                Finish
                            </button>
                            {/* <NavLink to='/onboarding'>Next</NavLink> */}
                        </div>
                    </div>
                }
                
            </div>

        </main>
    </div>;
}

export default DriverCarRegistration;