import React, { Fragment, useState, useEffect, useContext, useRef, memo } from 'react'
import CustomerContext from '../../Context/CustomerContext'

import { Dialog, Transition } from '@headlessui/react'
import { BASE_URL } from "../utils/constant"

import user from '../../Images/user2.svg'
import location from '../../Images/location.svg'
import providerOne from '../../Images/provider1.png'
import tickbox from '../../Images/tickbox.png'
import vmark from '../../Images/vmark.png'
import axios from "axios";
import userg from '../../Images/userg.png'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import getCookie from '../utils/SecuredTokens'

function Actions({ blockUser }) {
  

    let userData = getCookie("user");
    let userObject = JSON.parse(userData) 
    let token = userObject?.token
    
    

    function handleUnblockProvider(e) {
        e.preventDefault();
        let formData = new FormData();
        formData.append("id", blockUser?.id);

        formData.append("display", '1');
        formData.append("status", 'Approved');

        axios({
            method: "post",
            url: `${BASE_URL}/v1/block-provider`,
            // url: `${BASE_URL}/v1/admin-block-notice`,
            data: formData,
            headers: {
                "Content-Type": "application/json",

                Authorization: `Bearer ${token}`,

                "Access-Control-Allow-Origin": "*",
            },
        })
            .then(function (response) {
                //handle success
                setBlockOpen(false)

                const message = response.data.data;
                console.log('message', message)
                const notify = () => { toast('Unblocked Successfully') };
                notify()
                window.location.reload(true)

            })
            .catch(function (error) {
                //handle error
                console.log("error ", error.response.data);
            });
    };

    function handleBlockProvider(e) {
        e.preventDefault();

        let formData = new FormData();

        formData.append("id", blockUser?.id);
        formData.append("display", 0);
        formData.append("status", 'BLOCKED');



        axios({
            method: "post",
            url: `${BASE_URL}/v1/block-provider`,
            // url: `${BASE_URL}/v1/admin-block-notice`,
            data: formData,
            headers: {
                "Content-Type": "application/json",

                Authorization: `Bearer ${token}`,

                "Access-Control-Allow-Origin": "*",
            },
        })
            .then(function (response) {
                //handle success
                setBlockOpen(false)

                const message = response.data.data;
                console.log('message', message)
                const notify = () => { toast('Provider Blocked Successfully') };
                notify()
                window.location.reload(true)

            })
            .catch(function (error) {
                //handle error
                console.log("error ", error.response.data);
            });
    };



    let [blockOpen, setBlockOpen] = useState(false)

    let [bg, setBg] = useState(false)
    let [providerOpen, setProviderOpen] = useState(false)


    const [details, setDetails] = useState([])

    const getProvider = async () => {
        await axios({
            method: "get",
            url: `${BASE_URL}/v1/search-providerby-id/${blockUser.id}`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            },
        }).then(function (response) {
            // localStorage.removeItem("cart")
            const selectedProvider = response.data.data;
    
    console.log('selectedProvider',selectedProvider)
            setDetails(selectedProvider[0])
    
        });
    };
    
     useEffect(() => {
            getProvider();
            
        }, []);

        

function providerModal() {
    setProviderOpen(true)


}
    function closeModal() {
        setBlockOpen(false)
        setProviderOpen(false)

        setBg(false)
    }

    function blockModal() {
        setBlockOpen(true)
        setBg(true)

    }

    return <>

        <Transition appear show={blockOpen} as={Fragment}>
            <Dialog
                as="div"
                className="w-full md:w-11/12  fixed flex justify-center items-center px-6 inset-0 z-10 overflow-y-auto"
                onClose={closeModal}
            >
                <div className="w-full xl:w-5/12 lg:w-7/12 md:9/12 min-h-screen px-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-25 bg-white"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-25"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="inline-block h-screen align-middle"
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className="w-full md:w-9/12 inline-block  max-w-md  overflow-hidden text-left align-middle transition-all transform shadow-xl rounded-2xl">


                            <div className="bg-black p-10">

                                {blockUser?.status === 'BLOCKED' ?
                                    <p className="text-white mb-6">Are you sure you want to Unblock this User?</p>
                                    :
                                    <p className="text-amber-500 mb-6">Are you sure you want to Block this User?</p>


                                }

                                <div className="flex gap-4">

                                    {blockUser?.status === 'BLOCKED' ?


                                        <button
                                            onClick={handleUnblockProvider}
                                            className='bg-blue-900 px-4 py-1 text-white font-bold rounded-md mr-2'>Unblock</button>
                                        :

                                        <button
                                            onClick={handleBlockProvider}
                                            className='bg-amber-500 px-4 py-1 text-black font-bold rounded-md mr-2'>Confirm</button>

                                    }



                                    <button
                                        onClick={closeModal}
                                        className='bg-white px-4 py-1 text-black font-bold rounded-md mr-2'>Cancel</button>

                                </div>

                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>

        <Transition appear show={providerOpen} as={Fragment}>
            <Dialog
                as="div"
                className="w-full md:w-11/12  fixed flex justify-center items-center px-6 inset-0 z-10 overflow-y-auto"
                onClose={closeModal}
            >
                <div className="w-full xl:w-5/12 md:w-7/12 min-h-screen px-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-25 bg-white"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-25"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="inline-block h-screen align-middle"
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className="w-full md:w-8/12 inline-block  max-w-md  overflow-hidden text-left align-middle transition-all transform shadow-xl rounded-2xl">


                            <div className="bg-black p-10">
                                <div className="flex flex-col gap-4 ">
                                    <div className="flex gap-4">
                                        <img src={userg} alt="" className='w-8 h-8' />
                                        <h4 className='text-white'>
                                            This account is owned by <br />
                                            <span className='text-sm font-semibold text-amber-500'>
                                                Provider’s ID: {blockUser?.user_id}
                                            </span>

                                        </h4>
                                    </div>


                                    <div className="mt-6 flex flex-col gap-4">

                                        <div className="flex gap-4">
                                            {details?.service === 'vehicle' ?

                                                <img src={vmark} alt="" className='bg-white p-0 w-5 ml-1' /> :
                                                <img src={tickbox} alt="" className='w-7  p-1' />
                                            }
                                            <p className='text-white font-bold'>Car Owner</p>
                                        </div>
                                        <div className="flex gap-4">
                                            {details?.service === 'driver' ?

                                                <img src={vmark} alt="" className='bg-white p-0 w-5 ml-1' /> :
                                                <img src={tickbox} alt="" className='w-7  p-1' />
                                            }

                                            <p className='text-white font-bold'>Driver Only</p>
                                        </div>
                                        <div className="flex gap-4">
                                            {details?.category === 'business' ?

                                                <img src={vmark} alt="" className='bg-white p-0 w-5 ml-1' /> :
                                                <img src={tickbox} alt="" className='w-7  p-1' />
                                            }
                                            <p className='text-white font-bold'>Business</p>
                                        </div>

                                        <div className="flex gap-4">
                                            {details?.service === 'vehicle_driver' ?

                                                <img src={vmark} alt="" className='bg-white p-0 w-5 ml-1' /> :
                                                <img src={tickbox} alt="" className='w-7  p-1' />
                                            }
                                            <p className='text-white font-bold'>Driver and Car</p>
                                        </div>
                                    </div>

                                    {/* <div className="items-center cursor-pointer mt-2">
                                        <button

                                            type="submit"
                                            onClick={handleBlockNotice}

                                            className='rounded-md w-full text-center mt-4 py-2 px-8 text-black text-xs font-bold bg-amber-500'>

                                            Block User</button>
                                    </div> */}


                                </div>



                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>

        <div className="flex flex-col items-center w-full">
            <div className="xl:w-48 lg:w-44 md:w-40 w-36 bg-white rounded-lg md:p-4 p-3 relative">
                <img src={providerOne} alt="" />
                {
                    blockUser?.firstname === null ? (
                        <h5 className='font-bold text-black md:text-sm text-xs mt-2'>{blockUser?.name}</h5>

                    ) : (
                        <h5 className='font-bold text-black md:text-sm text-xs mt-2'>{blockUser?.firstname} {blockUser?.lastname}</h5>

                    )
                }
                <hr className='w-20 mt-1 border-t border-black' />

                <div className="flex justify-between mt-2 font-bold">
                    <h5 className='md:text-sm text-xx font-bold'>New off</h5>
                    <div className="flex items-center">
                        <img src={location} alt="" className='md:w-6 w-4' />
                        <h5 className='md:text-sm text-xx'>{blockUser?.state}</h5>

                    </div>
                </div>

                {blockUser?.status === 'BLOCKED' ?
                    (<div className="flex items-center mt-2 text-xs">
                        <button
                            onClick={blockModal}
                            className='bg-amber-500 px-2 py-1 text-black font-bold rounded-md mr-2'>
                            Unblock
                        </button>
                        <img src={user} alt="" className='w-5 md:w-8' />

                        <div className='bg-black text-white  absolute right-0 md:w-16 w-15 text-center p-1 flex  overflow-hidden'>
                            <div className='bg-white rotate-45 w-12 h-12 absolute -left-11  -top-3'>

                            </div>
                            <p className='ml-3 md:text-xs text-xxx'>Blocked</p>

                        </div>
                    </div>
                    ) :

                    (<div className="flex items-center mt-2 text-xs">
                        <button
                            onClick={blockModal}
                            className='bg-blue-900 px-4 py-1 text-white font-bold rounded-md mr-2'>Block</button>
                        <img 
                        onClick={providerModal}
                        
                        src={user} alt="" className='w-5 md:w-8' />


                    </div>

                    )
                }


            </div>
            <p className='font-normal text-white text-xx md:text-xs mt-2 text-center'>Provider’s ID: {blockUser?.user_id}</p>


        </div>

    </>;
}

export default memo(Actions);
