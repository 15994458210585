import React, { useState, useEffect, useRef,memo } from 'react'
import { BASE_URL } from "../utils/constant"

import Header from '../Header'
import Example from '../Menu'
import cartwhite from '../../Images/cart-white.svg';
import daily_driver from '../../Images/daily_driver.png';
import car from '../../Images/asset2.png';
import monthly from '../../Images/monthly.png';
import airport from '../../Images/airport.png';
import dd from '../../Images/dd.png';
import asset_one from '../../Images/driver3.png'

import prado from '../../Images/Prado.png';

import CarBookingComponent from './CarBookingComponent';
import RelatedService from '../RelatedService';
import Ratings from '../Ratings';
import google from '../../Images/google.png'
import interior from '../../Images/interior.png'
import ratings from '../../Images/ratings.svg'
// import dd from '../../Images/dd.png';
import { NavLink, useParams } from "react-router-dom";
import axios from "axios";
import { useCallback } from 'react';

function ScrollToTopOnMount() {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  
    return null;
  }

function CarBooking() {

    const [vehicle, setVehicle] = useState([]);
    // const [serviceProvider, setServiceProvider] = useState('');


    const { id } = useParams();

    const getCar = useCallback(async () => {
        await axios.get(`${BASE_URL}/v1/view-vehicle/${id}`).then((response) => {

            const vehicleAsset = response.data.data[0];
            console.log('car booking', vehicleAsset)
            setVehicle(vehicleAsset);
        });
    },[]);
    

    useEffect(() => {
        getCar()
      }, []);

    return (
        <div>
        <Header user='customer' />
        <ScrollToTopOnMount />
            <div className="mt-16">
                <CarBookingComponent
                    textOne="At Roadlers all our vehicles are inclusive of fuel and chauffeurs within Lagos for a maximum of 10 hours per day. Extra hours attract extra charges. 
 
                    "
                    textTwo="
                    For services outside Lagos, clients will be responsible for fuel and driver’s accommodation.
Kindly contact our customer care line..+234904444 2200
                    "
                    title={vehicle?.vehicle_name}
                    Location={vehicle?.state}
                    price={vehicle?.daily_price}
                    airportPrice={vehicle?.airport_price}
                    priceTwo={vehicle?.daily_price}
                    image={vehicle?.image1}
                    imageTwo={vehicle?.image2}
                    imageThree={vehicle?.image3}
                    bookPicture={vehicle?.image4}
                    firstPrice={vehicle?.first_price}
                    commissions={vehicle?.commission}
                    vat={vehicle?.vat}
                   
                    
                    comeWithDriver={vehicle?.come_with_driver}
                    year={vehicle?.year}
                    vehicleId={id}
                    plateNumber={vehicle?.plate_number}

                    serviceProviderId={vehicle?.user_id}
                    airCon={vehicle?.air_con}  
                  
                    travelAvailable={vehicle?.travel_available}  
                    vehicle_color={vehicle?.vehicle_color}
                    service={vehicle?.service}  
                />
            </div>



            <div className="md:mt-40 mt-16 mb-8 lg:px-32 md:px-16 xl:px-56 px-8">
                <h5 className='md:font-bold  md:text-xl font-semibold text-sm text-white '>Related Offers</h5>
                <hr className='md:w-28 w-12 md:mt-2 marg border-t border-white mb-4' />
            </div>


            <div className="mb-8 grid gap-6 items-center
             justify-center  xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-3 grid-cols-2 lg:px-32 md:px-16 xl:px-56 px-6">



                <RelatedService
                    image={airport}
                    name="Car Rental From ₦25,000"
                    status="View Offer"
                />
                <RelatedService
                    image={monthly}
                    name="Monthly full-time driving service"
                    status="Access Offer"
                />

                <RelatedService
                    image={airport}
                    name="Travel driving service "
                    status="Access Offer"
                />

                <RelatedService
                    image={airport}
                    name="Airport Pickups"
                    status="Learn More"
                />
            </div>



            <div className="mt-16 mb-8 xl:px-56 lg:px-32 md:px-16 px-6 hidden md:block">
                <h5 className='md:font-bold  md:text-xl font-semibold text-sm text-white '>Reviews</h5>
                <hr className='md:w-28 w-12 md:mt-2 marg border-t border-white mb-12' />
            </div>


            <div className="flex mt-12 gap-12 xl:px-56 lg:px-32 md:px-16 px-6 mb-20 hidden md:block">
                <Ratings
                    name="Chinyere"
                    google={google}
                    ratings={ratings}
                    text="The driver did a good job. He was professional and he drove really well too. The people I went with also attested to that."
                    initial="C"
                    add
                />
            </div>
        </div>
    )
}

export default memo(CarBooking)