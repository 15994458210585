import React, { Fragment, useState, useEffect, useRef } from 'react'

import logo from '../Images/logo.png'
import fb from '../Images/fb.svg'
import insta from '../Images/insta.svg'
import twitter from '../Images/twitter.svg'
import locate from '../Images/locate.png'
import call from '../Images/call.svg'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Link, useNavigate } from "react-router-dom";


import axios from "axios";


function Footer() {
    const year = new Date()
    const yearNow = year.getFullYear();
    const phoneRegExp = /^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/


    const formik = useFormik({
        initialValues: {

            name: '',
            email: '',
            phone_number: '',
            purpose: ''
        },
        validationSchema: Yup.object({
            name: Yup.string().min(3, "Must be 3 Characters or more").required("This is Required"),
            phone_number: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required("Phone Number is Required"),

            email: Yup.string().email("Invalid Email Address").required("This is Required"),

            purpose: Yup.string().required("This is Required"),


        }),
        onSubmit: (values) => {
            axios({
                method: 'post',
                url: 'https://api.roadlers.com/api/v1/send-message',

                data: values,

                headers: {
                    'Content-Type': 'application/json',



                    "Access-Control-Allow-Origin": "*",


                }


            })
                .then(function (response) {

                    if (response.data.status && response.data.data.token) {


                    }

                    else {

                        const messsage = response.data.message



                    }
                }).catch(function (error) {
                    //handle error
                    const errorMessage = error.response.data.message
                });

        },
    })

    return (
        <>
            <div className="h-16 md:h-20 bg-white w-full">
                <div className="md:px-20 px-6 flex justify-between items-center overflow-hidden">

                    <Link className="" exact to="/">
                        <img src={logo} alt="" className=' h-16 md:h-24' />
                    </Link>
                    <div className="flex md:mr-28">
                        <a href="https://www.facebook.com/Roadlers-106180495292040/" className='mr-2 rounded-full border border-black p-2 h-8 w-8 flex justify-center items-center'><img src={fb} alt="" className='w-2' /></a>
                        <a href="https://instagram.com/roadlers?igshid=YmMyMTA2M2Y=" className='mr-2 rounded-full border border-black p-2 h-8 w-8 flex justify-center items-center'><img src={insta} alt="" /></a>
                        <a href="https://twitter.com/roadlers?t=pFGGOse56wlj5DwK0al7Sg&s=08" className='rounded-full border border-black p-2 h-8 w-8 flex justify-center items-center'><img src={twitter} alt="" /></a>
                    </div>
                </div>
            </div>

            <main>
                <div className="md:px-28 flex-col md:flex-row px-12 pt-12 flex justify-between pb-14  footer-wrapper">
                    <div className="md:w-4/12 w-full ">
                        <h1 className='text-white font-bold md:text-xl text-xl'>Car Rental in Lagos <br /> Nigeria</h1>
                        <p className='text-white font-base text-xs mt-4'>Want to visit us?</p>

                        <div className="flex items-center my-4">
                            <img src={locate} alt="" className='w-7 mr-2' />
                            <p className='text-white font-normal text-xs leading-6 md:text-sm mt-4'>2nd Floor, Suite 205 Plot 1225 Bishop Oluwole Street, Victoria Island Lagos. (Edo House)</p>
                        </div>

                        <div className="flex items-center mt-6">
                            <img src={call} alt="" className='w-7 mr-2' />
                            <div className="">
                                <p className='text-white font-normal text-xs md:text-sm mb-2'>+2349044442200 </p>
                                <p className='text-white font-normal text-xs md:text-sm '>E-mail: support@roadlers.com</p>
                            </div>

                        </div>
                    </div>
                    <div className="w-full flex justify-between gap-2 md:w-5/12 mt-8">
                        {/* <img src={map} alt="" className='w-full md:mt-0 mt-8' /> */}

                        <div className="w-3/12  flex items-end md:hidden">
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.778728735539!2d3.411422214770179!3d6.422469995353229!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b8b1fe1d1175b%3A0xd8609c7eb1bfb974!2sRoadlers%20Limited!5e0!3m2!1sen!2sng!4v1644996377492!5m2!1sen!2sng"
                                width="130"
                                height="120"
                            ></iframe>
                        </div>


                        <div className=" w-6/12 mt-8 md:hidden">
                            <form onSubmit={formik.handleSubmit}>
                                <div className="">
                                    <div className="flex flex-col">
                                        <label htmlFor="first name" className='text-xs text-white font-normal'>Name</label>
                                        <input
                                            type="text"
                                            className='-mt-6 pl-10 text-sm bg-transparent  border-b border-white md:p-2 p-1 focus:outline-none text-white'
                                            name="name"
                                            id="name"
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            value={formik.values.name}
                                        />

                                        {formik.touched.name && formik.errors.name ? <p className='text-red-600 text-left text-xs w-full mt-2 mb-4'>{formik.errors.name}</p> : null}

                                    </div>

                                    <div className="flex flex-col mt-2">
                                        <label htmlFor="Email" className='text-xs text-white font-normal'>Email</label>
                                        <input
                                            type="text"
                                            className='-mt-6 pl-10 text-sm bg-transparent border-b border-white md:p-2 p-1 focus:outline-none text-white'
                                            name="email"
                                            id="email"
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            value={formik.values.email}
                                        />

                                        {formik.touched.email && formik.errors.email ? <p className='text-red-600 text-left text-xs w-full mt-2 mb-4'>{formik.errors.email}</p> : null}

                                    </div>

                                    <div className="flex flex-col mt-2">
                                        <label htmlFor="Phone number:" className='text-xs text-white font-normal'>Phone number:</label>
                                        <input
                                            type="text"
                                            className='-mt-6  pl-20 text-sm bg-transparent border-b border-white md:p-2 p-1 focus:outline-none text-white'
                                            name="phone_number"
                                            id="phone_number"
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            value={formik.values.phone_number}
                                        />

                                        {formik.touched.phone_number && formik.errors.phone_number ? <p className='text-red-600 text-left text-xs w-full mt-2 mb-4'>{formik.errors.phone_number}</p> : null}

                                    </div>


                                    <div className="flex flex-col mt-2">
                                        <label htmlFor="Purpose:" className='text-xs text-white font-normal'>Purpose:</label>
                                        <textarea

                                            cols="10"
                                            rows=""
                                            className='-mt-8 pl-16 text-sm bg-transparent border-b border-white md:p-2 p-1 focus:outline-none text-white'

                                            name="purpose"
                                            id="purpose"
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            value={formik.values.purpose}



                                        ></textarea>
                                        {formik.touched.purpose && formik.errors.purpose ? <p className='text-red-600 text-left text-xs w-full mt-2 mb-4'>{formik.errors.purpose}</p> : null}

                                    </div>

                                    <button
                                        type='submit'
                                        className='mt-4 p-2 bg-transparent border border-white w-full text-white rounded-md text-sm font-bold'>Book a Meeting</button>


                                </div>
                            </form>
                        </div>


                    </div>

                    <div className="w-6/12  md:flex  hidden">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.778728735539!2d3.411422214770179!3d6.422469995353229!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b8b1fe1d1175b%3A0xd8609c7eb1bfb974!2sRoadlers%20Limited!5e0!3m2!1sen!2sng!4v1644996377492!5m2!1sen!2sng"
                            width="250"
                            height="250"
                            allowfullscreen="" loading="lazy"

                        ></iframe>
                    </div>

                    <div className="md:w-4/12 w-full mt-8 md:block hidden">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="">
                                <div className="flex flex-col">
                                    <label htmlFor="first name" className='text-xs text-white font-normal'>Name</label>
                                    <input
                                        type="text"
                                        className='text-sm bg-transparent  border-b border-white md:p-2 p-1 focus:outline-none text-white'
                                        name="name"
                                        id="name"
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.name}
                                    />

                                    {formik.touched.name && formik.errors.name ? <p className='text-red-600 text-left text-xs w-full mt-2 mb-4'>{formik.errors.name}</p> : null}

                                </div>

                                <div className="flex flex-col mt-4">
                                    <label htmlFor="Email" className='text-xs text-white font-normal'>Email</label>
                                    <input
                                        type="text"
                                        className='text-sm bg-transparent border-b border-white md:p-2 p-1 focus:outline-none text-white'
                                        name="email"
                                        id="email"
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.email}
                                    />

                                    {formik.touched.email && formik.errors.email ? <p className='text-red-600 text-left text-xs w-full mt-2 mb-4'>{formik.errors.email}</p> : null}

                                </div>

                                <div className="flex flex-col mt-4">
                                    <label htmlFor="Phone number:" className='text-xs text-white font-normal'>Phone number:</label>
                                    <input
                                        type="text"
                                        className='text-sm bg-transparent border-b border-white md:p-2 p-1 focus:outline-none text-white'
                                        name="phone_number"
                                        id="phone_number"
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.phone_number}
                                    />

                                    {formik.touched.phone_number && formik.errors.phone_number ? <p className='text-red-600 text-left text-xs w-full mt-2 mb-4'>{formik.errors.phone_number}</p> : null}

                                </div>


                                <div className="flex flex-col mt-4">
                                    <label htmlFor="Purpose:" className='text-xs text-white font-normal'>Purpose:</label>
                                    <textarea

                                        cols="10"
                                        rows=""
                                        className='text-sm bg-transparent border-b border-white md:p-2 p-1 focus:outline-none text-white'

                                        name="purpose"
                                        id="purpose"
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.purpose}



                                    ></textarea>
                                    {formik.touched.purpose && formik.errors.purpose ? <p className='text-red-600 text-left text-xs w-full mt-2 mb-4'>{formik.errors.purpose}</p> : null}

                                </div>

                                <button
                                    type='submit'
                                    className='mt-12 p-2 bg-transparent border border-white w-full text-white rounded-md text-sm font-bold'>Book a Meeting</button>


                            </div>
                        </form>
                    </div>

                </div>

            </main>

            <div className="bg-black w-full">
                <div className="md:px-20 px-12 flex justify-center h-20 items-center text-white">
                    <p className='text-sm text-center'> &copy; Copyright Roadlers Limited {yearNow} </p>
                </div>
            </div>
        </>
    )
}

export default Footer;
