import React, { Fragment, useState, useEffect, memo } from 'react'
import { Menu, Dialog, Transition } from '@headlessui/react'
import { BASE_URL } from "../utils/constant"
import Spin from "../../Images/spinner.gif"

import one from '../../Images/one.png'
import gso from '../../Images/gso.svg'

import providerImage from '../../Images/providerUser.jpeg'
import { ToastContainer, toast } from 'react-toastify';
import close from '../../Images/close.png'

import moment from 'moment';
import axios from 'axios'
import getCookie from '../utils/SecuredTokens'

const Payment = ({ paymentRequest,
}) => {


    let userData = getCookie("user");
    let userObject = JSON.parse(userData)
    let token = userObject?.token


    let [paymentOpen, setPaymentOpen] = useState(false)
    let [otpOpen, setOtpOpen] = useState(false)



    let [userNa, setUserNa] = useState('')

    let [otp, setOtp] = useState('')
    let [load, setLoad] = useState('')
    let [paymentStatus, setPaymentStatus] = useState(paymentRequest.status)

    function closeModal() {
        setOtpOpen(false)
        setPaymentOpen(false)

    }

    function payModal() {
        setPaymentOpen(true)
        // setBg(true)

    }

    function otpModal() {
        setOtpOpen(true)

    }

    const handleOTP = async () => {
        setLoad(Spin);

        let formData = new FormData();
        formData.append("id", paymentRequest.id);
        otpModal()

        // formData.append("bank_code", paymentRequests);
        await axios({
            method: 'post',
            url: `${BASE_URL}/v1/pay-provider-request`,
            data: formData,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            }

        })
            .then(function (response) {
                setLoad('');
                setPaymentOpen(false)
                //handle success
                const respData = response.data.data;
                console.log('respData for transcode', respData.data.data)
                localStorage.setItem("transfer_code", JSON.stringify(response.data.data.data.transfer_code))
                otpModal()
            })
            .catch(function (error) {
                setLoad('');
                setPaymentOpen(false)

                //handle error
                console.log("error ", error.response.data)

                const notify = () => { toast(error.response.data.message) };
                notify()


            });
    }


    const handlePayment = async () => {

        if (otp === '') {
            setOtpOpen(true)
            setLoad('');


        } else {

            let formData = new FormData();
            let transferCode = JSON.parse(localStorage.getItem("transfer_code"))

            formData.append("transfer_code", transferCode);
            formData.append("otp", otp);
            // formData.append("bank_code", paymentRequests);
            await axios({
                method: 'post',
                url: `${BASE_URL}/v1/transfer-otp`,
                data: formData,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    "Access-Control-Allow-Origin": "*",
                }

            })
                .then(function (response) {
                    setLoad('');
                    setPaymentOpen(false)
                    setOtpOpen(false)

                    //handle success
                    // const respData = response.data.data;
                    setPaymentStatus('COMPLETED')
                    const notify = () => { toast(response.data.message) };
                    notify()
                    localStorage.removeItem("transfer_code")

                })
                .catch(function (error) {
                    setLoad('');
                    setPaymentOpen(false)

                    //handle error
                    console.log("error ", error.response.data)

                    const notify = () => { toast(error.response.data.message) };
                    notify()


                });
        }

    }
    return <div>

        <ToastContainer />

        <Transition appear show={paymentOpen} as={Fragment}>
            <Dialog
                as="div"
                className="w-full md:w-11/12  fixed flex justify-center items-center px-6 inset-0 z-10 overflow-y-auto"
                onClose={closeModal}
            >
                <div className="w-full md:w-6/12 min-h-screen px-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-white bg-opacity-[0.8]" />


                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="inline-block h-screen align-middle"
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className="w-full md:w-6/12 inline-block  max-w-md  overflow-hidden text-left align-middle transition-all transform shadow-xl rounded-xl">


                            <div className="bg-white p-4 flex flex-col justify-center items-center">
                                <p className="text-center font-bold">
                                    Please note that by clicking the pay now button below. Irreversible payment to the service provider will be authorized.
                                </p>



                                {load ? (
                                    <div className="flex justify-center items-center font-bold bg-red-500 rounded-md text-white w-10/12 px-3 py-2 mt-6">

                                        <h4 className="text-white text-sm">Please Wait!</h4>
                                        <input
                                            type="image"
                                            src={Spin}
                                            value="Loading"
                                            className="w-6"
                                        />
                                    </div>
                                ) : (
                                    <button
                                        onClick={handleOTP}
                                        className="font-bold bg-red-500 rounded-md text-white w-10/12 px-3 py-2 mt-6"
                                    >
                                        Pay Now
                                    </button>
                                )}



                            </div>




                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>

        <Transition appear show={otpOpen} as={Fragment}>
            <Dialog
                as="div"
                className="w-full md:w-11/12  fixed flex justify-center items-center px-6 inset-0 z-10 overflow-y-auto"
                onClose={handlePayment}
            >
                <div className="w-full md:w-8/12 lg:w-6/12 min-h-screen px-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-25 bg-white"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-25"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className="inline-block h-screen align-middle"
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className="w-full md:w-6/12 inline-block  max-w-md  overflow-hidden text-left align-middle transition-all transform shadow-xl rounded-xl">


                            <div className="bg-white p-4 flex flex-col justify-center items-center relative">

                                <button type='button' onClick={closeModal} className='bg-black absolute mb-6 rounded-full p-2  right-4 top-4'> <img className='w-4 h-4' src={close} alt='close-icon' /> </button>
                                <p className="text-center font-bold mt-12">
                                    Kindly Confirm this payment request with the correct OTP sent to your Phone
                                </p>

                                <input type='number' value={otp} onChange={(e) => setOtp(e.target.value)} className='mt-4 p-2 border border-gray-600 rounded-md w-10/12' />

                                {load ? (
                                    <div className="flex justify-center items-center font-bold bg-red-500 rounded-md text-white w-10/12 px-3 py-2 mt-6">

                                        <h4 className="text-white text-sm">Please Wait!</h4>
                                        <input
                                            type="image"
                                            src={Spin}
                                            value="Loading"
                                            className="w-6"
                                        />
                                    </div>
                                ) : (

                                    otp === '' ?
                                        <button
                                            type='button'
                                            className="cursor-not-allowed font-bold bg-black rounded-md text-gray-300 w-10/12 px-3 py-2 mt-2"
                                        >
                                            Confirm Payment
                                        </button> :
                                        <button
                                            onClick={handlePayment}
                                            className="font-bold bg-red-500 rounded-md text-white w-10/12 px-3 py-2 mt-2"
                                        >
                                            Confirm Payment
                                        </button>


                                )}



                            </div>




                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>


        <main className='my-6  md:block '>

            <div

                className="flex border rounded-lg md:p-8 p-4  justify-between overflow-hidden mt-12">
                <div className="hidden md:block">
                    <img src={providerImage} alt="driverOne" className='xl:w-44 lg:w-40 md:w-32' />
                </div>
                <div className="flex flex-col items-start">
                    <h4 className='text-white xl:text-lg md:text-base text-xs font-semibold mb-4'>{paymentRequest.account_name}</h4>
                    <div className="flex items-center mb-4">
                        <img src={one} alt="" className='xl:w-8 lg:w-7 md:w-6 w-4 md:mr-4 mr-2 ml-0' />

                        <p className='text-white text-xs md:text-sm'>{moment(paymentRequest.created_at).format("DD/MM/YYYY")}</p>
                    </div>
                    <div className="flex items-center">
                        <p className='mr-2 font-normal theme-color md:mr-4 mr-2 md:text-base text-xs'>ID: {paymentRequest.provider_username}</p>
                    </div>
                </div>
                {/* <div className="flex flex-col items-center gap-4">
                    <h4 className='theme-color font-semibold'>Transactions</h4>
                    <h5 className='font-semibold text-white'>6</h5>
                </div> */}
                <div className="flex flex-col items-center gap-4">
                    <h4 className='theme-color font-semibold'>Amount</h4>

                    <h5 className='font-semibold text-white text-xs md:text-sm'>
                       
                        &#8358;{Number(paymentRequest.withdrawal_amount).toLocaleString('en-US', { maximumFractionDigits: 2 })}
                    </h5>

                </div>
                <div className="flex flex-col gap-2">

                    {paymentRequest.status === 'COMPLETED' ?

                        <button

                            className='bg-green-700 text-white p-2 text-sm font-semibold rounded-lg  '>
                            Completed

                        </button>

                        :
                        <button
                            onClick={payModal}
                            className='bg-red-700 text-white md:px-2 py-2 px-1 text-xs md:text-sm font-semibold rounded-lg  '>
                            Pay Now
                           

                        </button>

                    }
                    <button className='theme  text-black font-semibold md:px-2 py-2 px-1 text-xs md:text-sm rounded-lg '>

                        Bal. : &nbsp;
                        &#8358;{Number(paymentRequest.wallet_balance).toLocaleString('en-US', { maximumFractionDigits: 2 })}

                    </button>




                </div>
            </div>


        </main>
    </div>;
}

export default memo(Payment)
