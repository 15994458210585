import React, { useEffect, useState } from 'react';
import Header from './Header'
import { BASE_URL } from "./utils/constant"

import dashboard from '../Images/dashboard.png'
import admin_request from '../Images/admin_request.svg'
import service_p from '../Images/service_p.svg'
import reports from '../Images/reports.svg'
import customer from '../Images/customers.png'
import notifications from '../Images/notifications.png'
import download from '../Images/download.svg'
import car_asset from '../Images/car_asset.svg'
import icon from '../Images/icon.svg'
import car from '../Images/car.png'
import message from '../Images/message.svg'
import roadlersRR from '../Images/RoadlersRR.svg'

import payment from '../Images/payment.png'
import naira from '../Images/naira.png'
import one from '../Images/one.png'
import eyee from '../Images/eyee.png'
import gso from '../Images/gso.svg'

import pen from '../Images/pen.svg'

import { NavLink, useNavigate } from "react-router-dom";
import Navigation from './Providers/Navigation';
import axios from 'axios';
import ApprovedCompanyComponent from './Providers/ApprovedCompanyComponent';
import getCookie from './utils/SecuredTokens';

function ApprovedServiceCompany() {
    let navigate = useNavigate();

    
    let userr = getCookie("user");
    let userObject = JSON.parse(userr)
    let token = userObject?.token
    let userIdProvider = userObject?.id

    // SERVICE DASHBOARD LOADER
    const [dashboardLoad, setDashboardLoad] = useState(true)

    const [companyData, setCompanyData] = useState([])
    const getProvider = () => {
        axios({
            method: 'GET',
            url: `${BASE_URL}/v1/pending-approval-business/${userIdProvider}`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            }
        })
        .then(function (response) {
            const company = response.data;
            setCompanyData(company.data)
            setDashboardLoad(false)
        })
        .catch(function (error) {
        });
    }

    useEffect(() => {
        getProvider()
    }, [])

    



    return (
    dashboardLoad ? 
    <div className='h-[100vh] w-full flex justify-center items-center'>
        <div class="lds-ring">
            <img src={roadlersRR}  alt="roadlers logo" className='embed-logo'/>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
    :
    <div>
        <Header 
            user='provider' 
        />

        <main className='flex md:px-12 px-12 flex-col md:flex-row overall-approval-page'>
            <Navigation
                statusApproval={companyData[0]?.status}
            />

            {
                companyData?.map((compItem) => {
                    return (
                        <ApprovedCompanyComponent 
                            firstName={compItem.firstname}
                            lastName={compItem.lastname}
                            address={compItem.address}
                            category={compItem.category}
                            services={compItem.service}
                            availability={compItem.availability}
                            companyName={compItem.company_name}
                            phoneNo={compItem.phone_number}
                            stateLocation={compItem.state}
                            countryLocation={compItem.country}
                            providerId={compItem.provider_id}
                            statusProfile={compItem.status}
                            companyCertificate={compItem.business_document}
                            editDate={compItem.edit_date}
                        />
                    )
                })
            }

        </main>
    </div>);
}

export default ApprovedServiceCompany;
