import React, { useState } from 'react'
import topArrow from "../../Images/topArrow.svg"
import bottomArrow from "../../Images/bottomArrow.svg"

const Dropdown = ({optionArray, holder, setSelectedOption, selectedOption, setItemDisplayValue, itemDisplayValue, bgColor}) => {
    const [placeHolder, setPlaceHolder] = useState(holder)
    const [showDropDown, setShowDropDown] = useState(false)
    // const [selectedOption, setSelectedOption] = useState('')
    console.log(optionArray)

    const handleClick = (item) => {
        setPlaceHolder(item.label)
        setSelectedOption(item.value)
        setItemDisplayValue(item.label)
        console.log(selectedOption)
    }

  return (
    <div className='relative flex items-center justify-between cursor-pointer pr-6'onClick={() => setShowDropDown(!showDropDown)} >
        {placeHolder}
        {
            showDropDown && (
            <div className={`option-container absolute top-[35px] left-[0] bg-white text-[#000] w-[98%] p-3`}>
                {optionArray.map((item, index) => (
                    <p key={index} className='item-list-option' onClick={() => handleClick(item)}>{item?.label}</p>
                ))}
            </div>
            )
        }
        <div className="arrow-container">
            {
               showDropDown ?  
               <img src={topArrow} alt="arrow" /> 
               :
               <img src={bottomArrow} alt="arrow" />
            }
        </div>
    </div>
  )
}

export default Dropdown