import apiRequest from "../http-common"

const viewNotifications = () => {
    return apiRequest.get("/view-notifications")
}

const markNotification = (readValue) => {
    return apiRequest.post("/mark-as-read", readValue)
}

const NotifyService = {
    viewNotifications,
    markNotification
}

export default NotifyService