
import dashboard from '../../Images/dashboard.png'
import admin_request from '../../Images/admin_request.svg'
import service_p from '../../Images/service_p.svg'
import reports from '../../Images/reports.svg'
import customer from '../../Images/customers.png'
import notifications from '../../Images/notifications.png'
import download from '../../Images/download.svg'
import car_asset from '../../Images/car_asset.svg'
import icon from '../../Images/icon.svg'
import car from '../../Images/car.png'
import message from '../../Images/message.svg'
import payment from '../../Images/payment.png'
import naira from '../../Images/naira.png'
import one from '../../Images/one.png'
import next from '../../Images/next.png'
import prev from '../../Images/prev.png'
import { useEffect, useState } from 'react'

function VehicleApprovalComponent(props) {

    const {
        vehicleName,
        vehicleYear,
        vehicleColor,
        vehicleCountry,
        vehicleState,
        vehicleNote,
        vehicleType,
        plateNo,
        passengerSeats,
        address,
        dailyPrice,
        airportPrice,
        monthlyPrice,
        hoursAvailable,
        services,
        airCondition,
        travelAvailable,
        vehicleInsurance,
        statusDoc,
        img1,
        img2,
        img3,
        vehicleDoc,
        providerId,
        currentPage,
        goToNextPage,
        goToPreviousPage,
        getPaginationGroup,
        changePage,
        pagesNo,
        carOwnerId, 
        carOwnerProfile,
    } = props

    const [airConditioner, setAirConditioner] = useState('yes')
    const [availableTravel, setAvailableTravel] = useState('yes')
    const [compInsurance, setCompInsurance] = useState('yes')

    const [nextDisabled, setNextDisabled] = useState(false)
    const [prevDisabled, setPrevDisabled] = useState(false)

    const setStateVehicleCondition = () => {
        setAirConditioner(airCondition)
        setAvailableTravel(travelAvailable)
        setCompInsurance(vehicleInsurance)
    }

    useEffect(() => {
        setStateVehicleCondition()
    }, [])

    const handleDisabledBtn = () => {
        if(currentPage === pagesNo) {
            setNextDisabled(true)
        }else {
            setNextDisabled(false)
        }
    
        if(currentPage === 1) {
            setPrevDisabled(true)
        }else {
            setPrevDisabled(false)
        }
    }

    useEffect(() => {
        handleDisabledBtn()
    }, [goToNextPage, goToPreviousPage, currentPage, pagesNo ])

  return (
    <>
        <div className='md:border-r md:border-amber-500 h-100 w-full md:w-6/12 md:px-20 md:pb-20 pt-12 component-approval-form-page'>

            <div className="flex justify-between items-center mt-20">
                <h2 className='text-white font-bold text-lg md:mt-14 mt-16'>Vehicle Profile </h2>

                {/* NAVIGATION CONTROL */}
                <div className="pagination-box flex gap-2 items-center mt-16">
                    <button onClick={goToPreviousPage} disabled={prevDisabled} className={currentPage === 1 ? `disabledNext` : 'prev'}>
                        <img src={prev} alt="Prev Btn" />
                    </button>
                    {getPaginationGroup().map((item, index) => (
                        <button
                            key={index}
                            onClick={changePage}
                            className={`${currentPage == item && 'activePage'} ${
                                item > pagesNo && 'disabledNext'
                            }
                            pagination-box-list_item rounded centered`}
                        >
                            <span>{item}</span>
                        </button>
                    ))}
                    <button onClick={goToNextPage} className={`${
                        currentPage == pagesNo ? 'disabledNext' : 'next'
                    }`}>
                        <img src={next} alt="Next Btn" />
                    </button>
                </div>
            </div>
            


            <main className=''>
                <div className="flex w-full">


                    <div className="pb-20 w-full">
                        <form className='w-full mt-4 '>
                            {/* NAME AND YEAR OF VEHICLE */}
                            <div className="flex gap-5 my-6 md:my-6 w-full component-approval-form-name-wrapper">
                                <div className="flex flex-col w-1/2">
                                    <label htmlFor="vehicle name" className='text-xs text-white font-normal'>Vehicle Name</label>
                                    <input
                                        type="text"
                                        placeholder='Prado'
                                        className='text-sm mt-2 bg-[#000] rounded-2xl border border-white px-3 py-3 focus:outline-none text-white'
                                        value={vehicleName}
                                    />
                                </div>
                                <div className="flex flex-col w-1/2">
                                    <label htmlFor="Year" className='text-xs text-white font-normal'>Year</label>
                                    <input
                                        type="text"
                                        placeholder='2020'
                                        className='text-sm mt-2 bg-[#000] rounded-2xl border border-white px-3 py-3 focus:outline-none text-white'
                                        value={vehicleYear}
                                    />

                                </div>
                            </div>

                            {/* PLATE NUMBER */}
                            <div className="flex flex-col my-6">
                                <label htmlFor="Plate Number"
                                    className='text-xs text-white font-normal'>Plate Number <span className="text-red-600">*</span>  </label>

                                <input
                                    type="text"
                                    className='text-sm mt-2 bg-[#000] rounded-2xl border border-white px-3 py-3 focus:outline-none text-white' 
                                    value={plateNo}/>
                                    

                            </div>


                            {/* VEHICLE TYPE */}
                            <div className="flex flex-col my-6">
                                <label htmlFor="Vehicle Type"
                                    className='text-xs text-white font-normal'>Vehicle Type <span className="text-red-600">*</span>  </label>

                                <input
                                    type="text"
                                    placeholder="Select vehicle type"
                                    value={vehicleType}
                                    className='text-sm mt-2 bg-[#000] rounded-2xl border border-white px-3 py-3 focus:outline-none text-white' 
                                />

                            </div>


                            {/* NO OF PASSENGER SEATS */}
                            <div className="flex flex-col my-6">
                                <label htmlFor="Number of Passenger Seats"
                                    className='text-xs text-white font-normal'>No of passenger seats <span className="text-red-600">*</span>  </label>

                                <input
                                    type="text"
                                    placeholder="Type here..."
                                    value={passengerSeats}
                                    className='text-sm mt-2 bg-[#000] rounded-2xl border border-white px-3 py-3 focus:outline-none text-white' />

                            </div>

                            {/* ADDRESS */}
                            <div className="flex flex-col my-6 md:my-6">
                                <label htmlFor="Address:" className='text-xs text-white font-normal'>Address: <span className="text-red-600">*</span> </label>
                                <input type="text"
                                    value={address}
                                    className='text-sm mt-2 bg-[#000] rounded-2xl border border-white px-3 py-3 focus:outline-none text-white' 
                                />

                            </div>



                            {/* DAILY RENTAL PRICE */}
                            <>
                                <div className="flex flex-col mt-6">
                                    <label htmlFor="Daily Price" className='text-xs text-white font-normal'>Daily Rental Price <span className="text-red-600">*</span> </label>
                                    <div className="rounded-2xl bg-[#000] border border-white mt-2 flex items-center text-white px-2">

                                        <span className=''>&#8358;</span>
                                        <input
                                            type="text"
                                            value={dailyPrice}
                                            className='w-11/12 text-sm bg-[#000] rounded-2xl  px-3 py-3 focus:outline-none text-white' />
                                        <img src={icon} alt="" className='w-12' />


                                    </div>

                                </div>

                                <div className="flex  mt-4 mb-6 justify-between">
                                    <div className="flex flex-col mr-4">
                                        <label htmlFor="VAT 7.5%" className='text-xs text-white font-normal'>VAT 7.5%</label>
                                    </div>
                                    <div className="flex flex-col">
                                        <label htmlFor="Service fee 3.5% to 10%" className='text-xs text-white font-normal'>Service fee 3.5% to 10%</label>
                                    </div>
                                </div>
                            </>


                            {/* AIRPORT PICKUP PRICE */}
                            <>
                                <div className="flex flex-col mt-6">
                                    <label htmlFor="Daily Price" className='text-xs text-white font-normal'>Airport Pick-up price </label>
                                    <div className="rounded-2xl bg-[#000] border border-white mt-2 flex px-2 flex items-center text-white">
                                        <span className=''>&#8358;</span>

                                        <input
                                            type="text"
                                            value={airportPrice}
                                            className='w-11/12 text-sm bg-[#000] rounded-2xl  px-3 py-3 focus:outline-none text-white' />
                                        <img src={icon} alt="" className='w-12' />
                                    </div>

                                </div>



                                <div className="flex  mt-4 mb-6 justify-between">
                                    <div className="flex flex-col mr-4">
                                        <label htmlFor="VAT 7.5%" className='text-xs text-white font-normal'>VAT 7.5%</label>
                                    </div>
                                    <div className="flex flex-col">
                                        <label htmlFor="Service fee 3.5% to 10%" className='text-xs text-white font-normal'>Service fee 3.5% to 10%</label>
                                    </div>
                                </div>
                            </>


                            {/* MONTHLY PRICE */}
                            <>
                                <div className="flex flex-col mt-6">
                                    <label htmlFor="Daily Price" className='text-xs text-white font-normal'>Monthly Price </label>
                                    <div className="rounded-2xl border bg-[#000] border-white mt-2 flex px-2 flex items-center text-white">
                                        <span className=''>&#8358;</span>

                                        <input
                                            type="text"
                                            value={monthlyPrice}
                                            className='w-11/12 text-sm bg-[#000] rounded-2xl  px-3 py-3 focus:outline-none text-white' />
                                        <img src={icon} alt="" className='w-12' />
                                    </div>

                                </div>



                                <div className="flex mt-4 mb-6 justify-between">
                                    <div className="flex flex-col mr-4">
                                        <label htmlFor="VAT 7.5%" className='text-xs text-white font-normal'>VAT 7.5%</label>
                                    </div>
                                    <div className="flex flex-col">
                                        <label htmlFor="Service fee 3.5% to 10%" className='text-xs text-white font-normal'>Service fee 3.5% to 10%</label>

                                    </div>
                                </div>
                            </>





                            {/* HOURS AVAILABLE */}
                            <div className="flex flex-col my-6 md:my-6">
                                <label htmlFor="Hours Available:" className='text-xs text-white font-normal'>Hours Available  </label>
                                <input
                                    type="text"
                                    className='text-sm mt-2 bg-[#000] rounded-2xl border border-white px-3 py-3 focus:outline-none text-white' 
                                    value={hoursAvailable}    
                                />
                                    
                            </div>


                            {/* WORKING AIRCONDITIONER */}
                            <div className=" mb-8 flex justify-start flex-col items-start w-full my-6">
                                <p className='md:text-sm text-xx mb-4 text-white'>Does this vehicle have a working Air-Conditioner?</p>

                                <div className="radio-container flex items-center w-full gap-5 pl-3">
                                    <label className='text-white radio-wrapper'>
                                        <input type="radio" name="air-con" value='yes' className="radio-style cursor-pointer" checked={airConditioner === "yes"}  />
                                        Yes
                                    </label>

                                    <label className='text-white radio-wrapper'>
                                        <input type="radio" name="air-con" value='no' className="radio-style cursor-pointer" checked={airConditioner === "no"}  />
                                        No
                                    </label>
                                </div>


                            </div>



                            {/* AVAILABLE FOR TRAVEL */}
                            <div className=" mb-8 flex justify-start flex-col items-start w-full my-6">
                                <p className='md:text-sm text-xx mb-4 text-white'>Is this vehicle avaliable for Travel? <span className='text-red-600'>*</span></p>

                                <div className="radio-container flex items-center w-full gap-5 pl-3">
                                    <label className='text-white radio-wrapper'>
                                        <input type="radio" name="available-vehicle" value='yes' className="radio-style cursor-pointer" checked={availableTravel === "yes"} />
                                        Yes
                                    </label>

                                    <label className='text-white radio-wrapper'>
                                        <input type="radio" name="available-vehicle" value='no' className="radio-style cursor-pointer" checked={availableTravel === "no"}  />
                                        No
                                    </label>
                                </div>



                            </div>


                            {/* VEHICLE COLOR */}
                            <div className="flex flex-col my-6 md:my-6">
                                <label htmlFor="Vehicle Color" className='text-xs text-white font-normal'>Vehicle Color <span className="text-red-600">*</span> </label>

                                <input
                                    type="text"
                                    className='text-sm mt-2 bg-[#000] rounded-2xl border border-white px-3 py-3 focus:outline-none text-white' 
                                    value={vehicleColor}    
                                />

                            </div>


                            {/* SERVICE OFFERED */}
                            <div className="flex flex-col my-6 md:my-6">
                                <label htmlFor="Address:" className='text-xs text-white font-normal'>Service <span className="text-red-600">*</span> </label>

                                <input
                                    type="text"
                                    className='text-sm mt-2 bg-[#000] rounded-2xl border border-white px-3 py-3 focus:outline-none text-white' 
                                    value={services}    
                                />

                            </div>




                            {/* VEHICLE LOCATION */}
                            <div className="flex gap-5 my-6 md:my-6 w-full component-approval-form-location-wrapper">
                                <div className="flex flex-col w-1/2">
                                    <label htmlFor="State" className='text-xs text-white font-normal'>Vehicle Location <span className="text-red-600">*</span> </label>
                                    <input 
                                    type="text" 
                                    value={vehicleState}
                                    className='text-sm mt-2 bg-[#000] rounded-2xl border border-white px-3 py-3 focus:outline-none text-white' />
                                </div>
                                <div className="flex flex-col w-1/2">
                                    <label htmlFor="Country" className='text-xs text-white font-normal'>&nbsp;</label>
                                    <input type="text" 
                                    value={vehicleCountry} 
                                    className='text-sm mt-2 bg-[#000] rounded-2xl border border-white px-3 py-3 focus:outline-none text-white' />
                                </div>
                            </div>

                            
                            {/* COMPREHENSIVE INSURANCE */}
                            <div className=" my-6 flex justify-start flex-col items-start w-full mb-12">
                                <p className='md:text-sm text-xx mb-4 text-white'>Does this vehicle have a comprehensive insurance? <span className='text-red-600'>*</span></p>

                                <div className="radio-container flex items-center w-full gap-5 pl-3">
                                    <label className='text-white radio-wrapper'>
                                        <input type="radio" name="comprehensive insurance" value='yes' className="radio-style cursor-pointer" checked={compInsurance === "yes"}/>
                                        Yes
                                    </label>

                                    <label className='text-white radio-wrapper'>
                                        <input type="radio" name="comprehensive insurance" value='no' className="radio-style cursor-pointer" checked={compInsurance === "no"} />
                                        No
                                    </label>
                                </div>


                            </div>

                            {/* VEHICLE DESCRIPTION */}
                            <div className="flex flex-col my-6">
                                <label htmlFor="Address:" className='text-xs text-white font-normal'>Vehicle Description <span className="text-red-600">*</span> </label>

                                <div className="flex items-center text-sm mt-2 px-2 bg-[#000] w-full rounded-2xl border border-white  text-white">
                                    <input 
                                    type="text" 
                                    value={vehicleNote}
                                    className='w-11/12 p-2 text-sm mt-2 bg-[#000] rounded-2xl  focus:outline-none text-white' />
                                    <img src={message} alt="" className='w-6' />
                                </div>
                            </div>

                            
                            {/* IMAGE SECTION */}
                            <div className="flex flex-wrap justify-start gap-4 md:gap-5 mt-8 w-full md:w-full img-view ">

                                <div className="upload-overall">
                                    <div className={'bg-[#fff] upload-img-overall' }>
                                        <div className='upload-img-container'>
                                                    <div className='img-preview'>
                                                        <img
                                                        src={`https://api.roadlers.com/storage/${img1}`}
                                                        className="uploaded-img "
                                                        alt="Thumb"
                                                        />
                                                    </div>
                                        </div>
                                    </div>  
                                    <p className='text-white mt-3'>Image 1 <span className='text-red-500'>*</span></p>
                                </div>


                                <div className={'bg-[#fff] upload-img-overall' }>
                                    <div className="upload-img-overall">
                                        <div className='upload-img-container'>
                                                    <div className='img-preview'>
                                                        <img
                                                        src={`https://api.roadlers.com/storage/${img2}`}
                                                        className="uploaded-img object-cover object-center"
                                                        alt="Thumb"
                                                        />
                                                    </div>
                                                    
                                        </div>
                                    </div> 
                                    <p className='text-white mt-3'>Image 2 <span className='text-red-500'>*</span></p>
                                </div>


                                <div className={ 'bg-[#fff] upload-img-overall individual-img-approval' }>
                                    <div className="upload-img-overall">
                                        <div className='upload-img-container'>
                                                    <div className='img-preview'>
                                                        <img
                                                        src={`https://api.roadlers.com/storage/${img3}`}
                                                        className="uploaded-img object-cover object-center"
                                                        alt="Thumb"
                                                        />
                                                    </div>
                                        </div>
                                    </div>   
                                    <p className='text-white mt-3'>Image 3 <span className='text-red-500'>*</span></p>
                                </div>

                            </div>

                            {/* CAR OWNER INFORMATION SECTION */}
                            {
                                carOwnerId !== "null" && 
                                <div className="mt-[80px] text-white">
                                    <h4 className='text-white text-lg font-bold'>Car Owner's Information</h4>
                                    <div className="flex gap-5 my-6 md:my-6 w-full component-approval-form-name-wrapper">
                                        <div className="flex flex-col w-1/2">
                                            <label htmlFor="first name" className='text-xs text-white font-normal'>First Name</label>
                                            <input
                                                type="text"
                                                placeholder='First Name'
                                                className='text-sm mt-2 bg-[#000] rounded-2xl border border-white px-3 py-3 focus:outline-none text-white'
                                                defaultValue={carOwnerProfile?.firstname}
                                                // onChange={(e) => setEditVehicleName(e.target.value)}
                                            />
                                        </div>
                                        <div className="flex flex-col w-1/2">
                                            <label htmlFor="last name" className='text-xs text-white font-normal'>Last Name</label>
                                            <input
                                                type="text"
                                                placeholder='Last Name'
                                                className='text-sm mt-2 bg-[#000] rounded-2xl border border-white px-3 py-3 focus:outline-none text-white'
                                                defaultValue={carOwnerProfile?.lastname}
                                                // onChange={(e) => setEditVehicleYear(e.target.value)}
                                            />

                                        </div>
                                    </div>

                                    <div className="mt-4">
                                        <div className="flex flex-col my-6 md:my-6">
                                            <label htmlFor="Owner's Address" className='text-xs text-white font-normal'>Address</label>
                                            <input
                                                type="text"
                                                className='text-sm mt-2 bg-[#000] rounded-2xl border border-white px-3 py-3 focus:outline-none text-white' 
                                                 defaultValue={carOwnerProfile?.address}
                                            />    
                                        </div>
                                    </div>

                                    <div className="mt-4">
                                        <div className="flex flex-col my-6 md:my-6">
                                            <label htmlFor="Owner's Identification" className='text-xs text-white font-normal'>Identification</label>
                                            <input
                                                type="text"
                                                className='text-sm mt-2 bg-[#000] rounded-2xl border border-white px-3 py-3 focus:outline-none text-white' 
                                                 defaultValue={carOwnerProfile?.identification.toUpperCase()}
                                            />    
                                        </div>
                                    </div>

                                    <div className="mt-4">
                                        <div className="flex flex-col my-6 md:my-6">
                                            <label htmlFor="Owner's Identification Number" className='text-xs text-white font-normal'>Identification Number</label>
                                            <input
                                                type="text"
                                                className='text-sm mt-2 bg-[#000] rounded-2xl border border-white px-3 py-3 focus:outline-none text-white' 
                                                 defaultValue={carOwnerProfile?.id_number}
                                            />    
                                        </div>
                                    </div>

                                    <div className="mt-4">
                                        <div className="flex flex-col my-6 md:my-6">
                                            <label htmlFor="Owner's Phone Number" className='text-xs text-white font-normal'>Phone Number</label>
                                            <input
                                                type="text"
                                                className='text-sm mt-2 bg-[#000] rounded-2xl border border-white px-3 py-3 focus:outline-none text-white' 
                                                 defaultValue={carOwnerProfile?.phone_number}
                                            />    
                                        </div>
                                    </div>

                                    <div className=" mt-4 flex flex-col">
                                        <label htmlFor="Birthday date:" className='text-xs text-[#FFF] font-normal'>Birthday date.</label>
                                        <div className="rounded-2xl border border-white mt-2 flex px-2 bg-[#000]">
                                            <input type="date" 
                                                defaultValue={carOwnerProfile?.dob} 
                                                // onChange={e => setEditDob(e.target.value)}
                                                className='w-11/12 text-sm bg-[#000] rounded-2xl  p-3 focus:outline-none text-white' />
                                            <img src={icon} alt="" className='w-12' />
                                        </div>

                                    </div>

                                    <div className="flex gap-5 my-6 md:my-6 w-full component-approval-form-name-wrapper">
                                        <div className="flex flex-col w-1/2">
                                            <label htmlFor="Owner's State" className='text-xs text-white font-normal'>State</label>
                                            <input
                                                type="text"
                                                placeholder='State'
                                                className='text-sm mt-2 bg-[#000] rounded-2xl border border-white px-3 py-3 focus:outline-none text-white'
                                                defaultValue={carOwnerProfile?.state}
                                                // onChange={(e) => setEditVehicleName(e.target.value)}
                                            />
                                        </div>
                                        <div className="flex flex-col w-1/2">
                                            <label htmlFor="Owner's country" className='text-xs text-white font-normal'>Country</label>
                                            <input
                                                type="text"
                                                placeholder='Country'
                                                className='text-sm mt-2 bg-[#000] rounded-2xl border border-white px-3 py-3 focus:outline-none text-white'
                                                defaultValue={carOwnerProfile?.country}
                                                // onChange={(e) => setEditVehicleYear(e.target.value)}
                                            />

                                        </div>
                                    </div>
                                </div>
                            }


                            {/*APPROVED SERVICE  */}
                            <div className="flex  mt-[70px] flex-col ">
                            {
                               (statusDoc == 'Pending' || !statusDoc) &&
                                <button className='bg-blue-900 rounded text-white px-4 w-1/2 py-1 font-semibold text-sm'>Pending</button>
                            }

                            {
                                statusDoc === 'Approved' &&
                                <>
                                    <button className='bg-amber-500 rounded text-black px-4 py-1 font-bold text-sm  md:w-28'>Approved</button>
                                    <div className="my-6 flex gap-5 mt-8 md:gap-2 w-11/12">
                                        <button className='bg-blue-900 rounded text-white px-4 py-2 md:py-2 text-xs md:px-3 font-bold md:text-sm w-8/12'>Account Verified</button>
                                        {
                                            // profileEditable === true ?
                                            <button
                                                className='bg-white bg-opacity-50 rounded text-black  px-4 py-2 md:py-2 text-xs  font-bold md:text-sm w-4/12'
                                                disabled={true}
                                            >
                                                Save
                                            </button>
                                        }

                                    </div>  
                                </>
                            }

                            {
                                statusDoc === 'Rejected' && 
                                <button className='bg-red-600 rounded text-white px-4 py-1 font-semibold text-sm'>Rejected</button>
                            }
                            </div>





                            {/* <div className="my-6 flex gap-5 md:gap-2 w-11/12">
                                <button className='bg-blue-900 rounded text-white px-4 py-2 md:py-2 text-xs md:px-3 font-bold md:text-sm w-8/12'>Account Verified</button>
                                <button
                                    className='bg-white rounded text-black  px-4 py-2 md:py-2 text-xs  font-bold md:text-sm w-4/12'

                                    onClick={e => handleSave(e)}
                                >Save
                                </button>

                            </div> */}

                        </form>
                    </div>
                </div>

            </main>
        </div>
        <div className="border-t border-amber-400 -px-12"></div>
        <div className=' md:w-4/12 w-full md:pt-36 pt-16 mb-12 md:px-16 component-approval-doc-section' >
            <div className="md:flex justify-end hidden "><h4 className='font-bold text-amber-500 mb-8 '>Provider’s ID: {providerId}</h4></div>

            <h2 className='mb-10 text-white font-bold text-lg'>Vehicle Documents</h2>

            {/*PROOF OF OWNERSHIP  */}
            {
                (vehicleDoc?.filter(obj => obj.doc_type.includes("proof of ownership")).map((docItem) => {
                    return (
                        <div className="my-8">
                            <div className="flex justify-between border border-white p-2 md:p-3 items-center">
                                <p className='text-white md:text-sm text-xs font-normal'>Proof of ownership *</p>
                                <a href={`https://api.roadlers.com/storage/${docItem.document}`} target="_blank" className='border-theme-color px-3 py-1 md:text-sm md:px-2 md:py-0 text-xs text-white'>View</a>
                            </div>
            
                            <div className="flex justify-end mt-4">
                                {
                                    docItem.status === "Rejected" ?
                                    <div className="flex justify-end mt-4">
                                        <img src={message} alt="" className='mr-2 w-8' />
                                        <button className='bg-red-600 rounded text-white px-4 py-1 font-semibold text-sm'>Rejected</button>
                                    </div> :
                                    <>
                                        {
                                            docItem.status === "Pending" ?
                                            <button className='bg-blue-900 rounded text-white px-4 py-1 font-semibold text-sm'>Pending</button>:
                                            <button className='bg-amber-500 rounded text-white px-4 py-1 font-semibold text-sm'>Accepted</button>
                                        }
                                    </>
                                }

            
                            </div>
                        </div>
                    )
                }))
            }

            {/* VEHICLE LICENSE */}
            {
                (vehicleDoc?.filter(obj => obj.doc_type.includes("vehicle license")).map((docItem) => {
                    return (
                        <div className="my-8">
                            <div className="flex justify-between border border-white p-2 md:p-3 items-center">
                            <p className='text-white md:text-sm text-xs font-normal'>Vehicle license / registration *</p>
                                <a href={`https://api.roadlers.com/storage/${docItem.document}`} target="_blank" className='border-theme-color px-3 py-1 md:text-sm md:px-2 md:py-0 text-xs text-white'>View</a>
                            </div>
            
                            <div className="flex justify-end mt-4">
                                {
                                    docItem.status === "Rejected" ?
                                    <div className="flex justify-end mt-4">
                                        <img src={message} alt="" className='mr-2 w-8' />
                                        <button className='bg-red-600 rounded text-white px-4 py-1 font-semibold text-sm'>Rejected</button>
                                    </div> :
                                    <>
                                        {
                                            docItem.status === "Pending" ?
                                            <button className='bg-blue-900 rounded text-white px-4 py-1 font-semibold text-sm'>Pending</button>:
                                            <button className='bg-amber-500 rounded text-white px-4 py-1 font-semibold text-sm'>Accepted</button>
                                        }
                                    </>
                                }
                            </div>
                        </div>
                    )
                }))
            }


            {/* CERTIFICATE OF CAR INSURANCE */}
            {
                (vehicleDoc?.filter(obj => obj.doc_type.includes("car insurance")).map((docItem) => {
                    return (
                        <div className="my-8">
                            <div className="flex justify-between border border-white p-2 md:p-3 items-center">
                            <p className='text-white md:text-sm text-xs font-normal'>Certificate of car insurance</p>
                                <a href={`https://api.roadlers.com/storage/${docItem.document}`} target="_blank" className='border-theme-color px-3 py-1 md:text-sm md:px-2 md:py-0 text-xs text-white'>View</a>
                            </div>
            
                            <div className="flex justify-end mt-4">
                                {
                                    docItem.status === "Rejected" ?
                                    <div className="flex justify-end mt-4">
                                        <img src={message} alt="" className='mr-2 w-8' />
                                        <button className='bg-red-600 rounded text-white px-4 py-1 font-semibold text-sm'>Rejected</button>
                                    </div> :
                                    <>
                                        {
                                            docItem.status === "Pending" ?
                                            <button className='bg-blue-900 rounded text-white px-4 py-1 font-semibold text-sm'>Pending</button>:
                                            <button className='bg-amber-500 rounded text-white px-4 py-1 font-semibold text-sm'>Accepted</button>
                                        }
                                    </>
                                }
                            </div>
                        </div>
                    )
                }))
            }


            {/* CERTIFICATE OF ROAD WORHTINESS */}
            {
                (vehicleDoc?.filter(obj => obj.doc_type.includes("road worthiness")).map((docItem) => {
                    return (
                        <div className="my-8">
                            <div className="flex justify-between border border-white p-2 md:p-3 items-center">
                            <p className='text-white md:text-sm text-xs font-normal'>Certificate of road worthiness</p>
                                <a href={`https://api.roadlers.com/storage/${docItem.document}`} target="_blank" className='border-theme-color px-3 py-1 md:text-sm md:px-2 md:py-0 text-xs text-white'>View</a>
                            </div>
            
                            <div className="flex justify-end mt-4">
                                {
                                    docItem.status === "Rejected" ?
                                    <div className="flex justify-end mt-4">
                                        <img src={message} alt="" className='mr-2 w-8' />
                                        <button className='bg-red-600 rounded text-white px-4 py-1 font-semibold text-sm'>Rejected</button>
                                    </div> :
                                    <>
                                        {
                                            docItem.status === "Pending" ?
                                            <button className='bg-blue-900 rounded text-white px-4 py-1 font-semibold text-sm'>Pending</button>:
                                            <button className='bg-amber-500 rounded text-white px-4 py-1 font-semibold text-sm'>Accepted</button>
                                        }
                                    </>
                                }
                            </div>
                        </div>
                    )
                }))
            }






            {/* HACKNEY PERMIT */}
            {
                (vehicleDoc?.filter(obj => obj.doc_type.includes("hackney permit")).map((docItem) => {
                    return (
                        <div className="my-8">
                            <div className="flex justify-between border border-white p-2 md:p-3 items-center">
                            <p className='text-white md:text-sm text-xs font-normal'>Hackney permit  </p>
                                <a href={`https://api.roadlers.com/storage/${docItem.document}`} target="_blank" className='border-theme-color px-3 py-1 md:text-sm md:px-2 md:py-0 text-xs text-white'>View</a>
                            </div>
            
                            <div className="flex justify-end mt-4">
                                {
                                    docItem.status === "Rejected" ?
                                    <div className="flex justify-end mt-4">
                                        <img src={message} alt="" className='mr-2 w-8' />
                                        <button className='bg-red-600 rounded text-white px-4 py-1 font-semibold text-sm'>Rejected</button>
                                    </div> :
                                    <>
                                        {
                                            docItem.status === "Pending" ?
                                            <button className='bg-blue-900 rounded text-white px-4 py-1 font-semibold text-sm'>Pending</button>:
                                            <button className='bg-amber-500 rounded text-white px-4 py-1 font-semibold text-sm'>Accepted</button>
                                        }
                                    </>
                                }
                            </div>
                        </div>
                    )
                }))
            }







        </div>
    </>
  )
}

export default VehicleApprovalComponent