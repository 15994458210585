export const providerSignupPage = () => {
    window.fbq('track', 'Provider-Signup-Page');
}

export const LoginPage = () => {
    window.fbq('track', 'Login-Page');
}

export const providerRegisterPage = () => {
    window.fbq('track', 'Provider-Register-Page');
}

export const driverRegisterPage1 = () => {
    window.fbq('track', 'Driver-Register-Page1');
}

export const driverRegisterPage2 = () => {
    window.fbq('track', 'Driver-Register-Page2');
}

export const vehicleRegisterPage = () => {
    window.fbq('track', 'Vehicle-Register-Page');
}

export const providerDashboardPage = () => {
    window.fbq('track', 'Provider-Dashboard-Page');
}

// BUTTON EVENTS

export const providerSignupEvent = () => {
    window.fbq('track', 'Provider-Signup-Event');
}

export const LoginEvent = () => {
    window.fbq('track', 'Login-Event');
}

export const providerRegisterEvent = () => {
    window.fbq('track', 'Provider-Register-Event');
}

export const businessDocUploadEvent = () => {
    window.fbq('track', 'Business-Doc-Upload-Event');
}

export const driverRegisterEvent = () => {
    window.fbq('track', 'Driver-Register-Event');
}

export const driverDocUploadEvent = () => {
    window.fbq('track', 'Driver-Doc-Upload-Event');
}

export const gurantorInfoUploadEvent1 = () => {
    window.fbq('track', 'Guarantor-Info-Upload-Event1');
}

export const gurantorInfoUploadEvent2 = () => {
    window.fbq('track', 'Guarantor-Info-Upload-Event2');
}

export const vehicleInfoUploadEvent = () => {
    window.fbq('track', 'Vehicle-Info-Upload-Event')
}

export const proofOwnerDocEvent = () => {
    window.fbq('track', 'Proof-Owner-Doc-Event');
}

export const vehicleDocsUploadEvent = () => {
    window.fbq('track', 'Vehicle-Docs-Upload-Event');
}
