import React, { useEffect, useState, Fragment } from "react"
import Header from "./Header"
import google from "../Images/google.svg"
import fbb from "../Images/fbb.svg"
import user from "../Images/userg.png"
import closed from "../Images/closed.svg"
import open from "../Images/blackeye.png"
import car from "../Images/carr.png"
import reset from "../Images/reset.svg"
import x from '../Images/x.png'
import gmail from '../Images/gmail.png'
import outlook from '../Images/outlook.png'
import yahoo from '../Images/yahoo.png'
import checkMail from "../Images/check-mail.svg"
import cancel from "../Images/cancel.svg"
import { NavLink, useNavigate } from "react-router-dom"
import axios from "axios"
import Spin from "../Images/spinner.gif"
import { BASE_URL } from "./utils/constant"
import { Menu, Dialog, Transition } from "@headlessui/react";

import { useFormik } from "formik"
import * as Yup from "yup"
// import { useDispatch, useSelector } from "react-redux"
import { clearMessage } from "../slices/message"
import { login } from "../slices/auth"
import { ToastContainer, toast } from 'react-toastify';
import useAnalyticsEventTracker from "./Common/useAnalyticsEventTracker"
import { LoginEvent, LoginPage } from "./Common/facebookPixelEvent"
import { useCookies } from 'react-cookie';
import getCookie from "./utils/SecuredTokens"

const Login = () => {

  function ScrollToTopOnMount() {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    return null;
  }

  let cart = JSON.parse(localStorage.getItem("cart"))

  const gaEventTracker = useAnalyticsEventTracker('Login');
  const [cookies, setCookie] = useCookies(['user']);
  const [successful, setSuccessful] = useState(false)
  const [emailReg, setEmailReg] = useState('')

  const [forgotMailModal, setForgetMailModal] = useState(false)
  const [checkMailModal, setCheckMailModal] = useState(false)
  const [emailReceive, setEmailReceive] = useState('')
  let [checkMailOpen, setCheckMailOpen] = useState(false)
  const [bg, setBg] = useState(false)
  const [emailVerifySent, setEmailVerifySent] = useState(false)
  const [verifyLoad, setVerifyLoad] = useState("")
  // const { message } = useSelector((state) => state.message)

  // const passwordNotify = () => { toast(message) };
  // const userNotify = () => { toast(message) };
  const [forgotErrorMessage, setForgotErroMessage] = useState('')
  // console.log("Login Message", message)

  // const dispatch = useDispatch()
  //   useEffect(() => {
  //     dispatch(clearMessage())
  //   }, [dispatch])

  useEffect(() => {
    LoginPage()
  }, [])

  let navigate = useNavigate()
  const [load, setLoad] = useState("")
  // let token = JSON.parse(localStorage.getItem("userToken"))

  const [passwordShown, setPasswordShown] = useState(false)
  const togglePassword = (e) => {
    e.preventDefault()
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown)
  }

  // HANDLE EMAIL SUBMIT FOR FORGOT PASSWORD
  const handleEmailSubmit = (e) => {
    e.preventDefault();

    const data = {
      email: emailReceive
    }

    localStorage.setItem("userEmail", JSON.stringify(emailReceive))

    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (emailReceive === '') {
      setForgotErroMessage('Please Type in Your email')
      setTimeout(() => {
        setForgotErroMessage('')
      }, 2000)
    } else if (!emailReceive.match(mailformat)) {
      setForgotErroMessage('Invalid Mail Format')
      setTimeout(() => {
        setForgotErroMessage('')
      }, 2000)
    } else {
      setLoad(Spin);

      axios({
        method: 'post',
        url: `${BASE_URL}/v1/password-reset-link`,
        data: data,
        headers: {
          'Content-Type': 'application/json',
          //    Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        }
      })
        .then(function (response) {
          //handle success
          // if (response.data.status) {
          // localStorage.setItem("userToken", JSON.stringify(response.data.data.token))
          // }
          // else {
          //     const messsage = response.data.message
          //     setLoad('');
          // }

          const messsage = response.data.data.responseMessage
          // alert(messsage)
          setEmailReceive('')
          setForgetMailModal(false)
          setCheckMailModal(true)
        }).catch(function (error) {
          //handle error
          setForgotErroMessage('The selected email is invalid')
          setTimeout(() => {
            setForgotErroMessage('')
          }, 2000)
          setLoad('');
        });
    }
  }

  const handleGoogle = async (e) => {
    e.preventDefault();
    localStorage.setItem("googleClicked", JSON.stringify('true'))

    window.open('https://api.roadlers.com/api/v1/auth/google', "_self")

  }

  // HANDLE EMAIL RESEND
  const handleEmailResend = (e) => {
    e.preventDefault()

    const data = {
      email: emailReceive
    }

    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (emailReceive === '') {
      setForgotErroMessage('Please Type in Your email')
      setTimeout(() => {
        setForgotErroMessage('')
      }, 2000)
    } else if (!emailReceive.match(mailformat)) {
      setForgotErroMessage('Invalid Mail Format')
      setTimeout(() => {
        setForgotErroMessage('')
      }, 2000)
    } else {
      setLoad(Spin);

      axios({
        method: 'post',
        url: `${BASE_URL}/v1/password-reset-link`,
        data: data,
        headers: {
          'Content-Type': 'application/json',
          //    Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
        }
      })
        .then(function (response) {
          //handle success
          if (response.data.status) {
            localStorage.setItem("userToken", JSON.stringify(response.data.data.token));
            const messsage = response.data.message
            setEmailReceive('')
          }
          else {
            const messsage = response.data.message
            setLoad('');
          }
        }).catch(function (error) {
          //handle error
          alert('Email not correct')
          setLoad('');
        });
    }
  }

  // HANDLE EMAIL VERIFICATION SUBMIT
  const handleEmailVerificationSubmit = () => {
    let formData = new FormData()
    setVerifyLoad(Spin)
    formData.append('email', emailReg)

    axios({
      method: "post",
      url: `${BASE_URL}/v1/email/resend`,
      data: formData,
      headers: {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
      }
    })
      .then((response) => {
        setEmailVerifySent(true)
        setVerifyLoad("")
      })
      .catch((error) => {
        setVerifyLoad("")
        alert(error.response.data.message)
      });
  }


  // ***********************************************************8888
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid Email Address")
        .required("This is Required"),
      password: Yup.string()
      .required("This is Required")
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/,
        "Must Contain at least 6 Characters, One Uppercase, One Lowercase and One Number"
      ),
    }),
    onSubmit: async (values) => {
      gaEventTracker('Login call')
      LoginEvent()
      setSuccessful(false)
      //************************************** */
      setLoad(Spin);
      axios({
        method: 'post',
        url: `${BASE_URL}/v1/login`,
        data: values,

        headers: {
          'Content-Type': 'application/json',
          // Authorization: `Bearer ${token}` || '',
          "Access-Control-Allow-Origin": "*",
        }

      })
        .then(function (response) {
          //handle success

          // if (response.data.status && response.data.data.token) {
          localStorage.setItem("userId", JSON.stringify(response.data.data.id))

          localStorage.setItem("userCategory", JSON.stringify(response.data.data.category))
          localStorage.setItem("isUserRegistered", JSON.stringify(response.data.data.if_register))

          setCookie('user', response.data.data, { path: '/', secure: true });



          const isRegistered = response.data.data.if_register
          const messsage = response.data.message

       

          let user = getCookie("user");
          let userObject = JSON.parse(user);
          const role = userObject?.user_role



          // const service = response.data.data.service
          if (role === 'super_admin') {
            navigate('/superadmin')
          }
          else if (role === 'customer') {
            if (cart) {
              navigate('/cart')
            }
            else {
              navigate('/customers-dashboard')
            }
          }
          else if (role === 'provider') {
            if (isRegistered == "1") {
              // navigate('/service-provider-dashboard')
              if (cart) {
                navigate('/cart')
              }
              else {
                navigate('/service-provider-dashboard')

              }
            } else {
              if (cart) {
                navigate('/cart')
              }
              else {
                navigate('/individual-account-registration')
              }
            }
          }

          else if (role === "admin") {
            navigate("/Admin")
          }
          // }
          // else {
          //     const messsage = response.data.message
          //     setLoad('');
          // }

        }).catch(function (error) {
          //handle error
          setLoad('');
          const errorMessage = error?.response?.data?.message
          if (errorMessage === "Please check your email to verify your account") {
            setCheckMailOpen(true)
            setBg(true)
          } else {
            let notify = () => {
              toast.error((errorMessage), {
                toastClassName: 'error'
              })
            };
            notify()
          }
          setLoad('');
        });
    },
  })


  function closeModal() {
    setCheckMailOpen(false)
    setBg(false)
  }


  return (
    <div>
      <ScrollToTopOnMount/>
      <Header user="customer" />
      <ToastContainer />


      <div
        // onClick={() => setForgetMailModal(false)}
        className={forgotMailModal ? 'overlay-wrapper' : 'no-display hidden'}
      >
        <div className="overlay-inner-wrapper absolute w-full h-full top-[0] left-[0] cursor-pointer bg-transparent" onClick={() => setForgetMailModal(false)}>
        </div>
        <div className="cancelModalContainer absolute top-[20px] right-[20px] z-[3500]"><img src={cancel} alt="" className="w-[40px] cursor-pointer" onClick={() => setForgetMailModal(false)} /></div>

        <div className="forgot-password-container">
          <div className="inner-wrapper">
            <div className="image-container">
              <img src={reset} alt="Reset Image" />
            </div>

            <form className="content-container" onSubmit={handleEmailSubmit}>
              <div className="input-section">
                <label>What's your email <span>*</span></label>
                <input
                  type="email"
                  placeholder="Type here..."
                  onChange={e => setEmailReceive(e.target.value)}
                  value={emailReceive}
                />
                <p className="text-red-600 text-center mt-2 mb-0">{forgotErrorMessage}</p>
              </div>

              <div className="btn-wrapper">
                <button type="submit">
                  Send Mail
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* EMAIL VERIFICATION MODAL */}
      <div className="flex justify-center items-center">
        <Transition appear show={checkMailOpen} as={Fragment}>
          <Dialog
            as="div"
            className="w-full md:w-11/12  fixed flex justify-center items-center px-6 inset-0 z-[1000] overflow-y-auto"
            onClose={closeModal}
          >
            <div className="w-full md:w-6/12 min-h-screen px-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-25 bg-white"
                leave="ease-in duration-200"
                leaveFrom="opacity-25"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="w-full md:w-6/12 inline-block  max-w-md pt-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-project shadow-xl rounded-lg">

                  <div className="flex w-full justify-end">
                    <button
                      type='button'

                      className=''>
                      <img src={x} className='mr-7 w-[15px]' onClick={closeModal} alt="Cancel Modal" />
                    </button>
                  </div>
                  <Dialog.Title
                    as="h3"
                    className="text-lg p-3 font-medium leading-6 text-gray-600"
                  >
                    <h2 className="text-center text-white text-lg font-bold">Please Verify your mail</h2>
                  </Dialog.Title>

                  <div className="p-6 py-1 w-full">
                    <input
                      type="mail"
                      className='text-sm mt-2 bg-[#1D1C1C] rounded-xl w-full border border-white px-3 py-3 focus:outline-none text-white'
                      placeholder='Type in your mail'
                      value={emailReg}
                      onChange={e => {
                        setEmailReg(e.target.value)
                        setEmailVerifySent(false)
                      }}
                    />
                  </div>

                  <div className="bg-project p-6 pt-2 flex flex-col items-center justify-center">
                    {
                      !emailVerifySent ?
                        <button
                          type="button"
                          className="my-3 inline-flex w-full justify-center px-4 py-2 text-xs font-bold text-black bg-white border border-transparent rounded-md  focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2"
                          onClick={handleEmailVerificationSubmit}
                        >
                          {verifyLoad === "" ?
                            "Resend" :
                            <input
                              type="image"
                              src={Spin}
                              value="Loading"
                              className="w-4 md:w-6"
                            />
                          }

                        </button> :
                        <button
                          type="button"
                          className="my-3 inline-flex w-full justify-center px-4 py-2 text-xs font-bold text-black bg-green-500 border border-transparent rounded-md  focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2"
                          disabled={true}
                        >
                          Sent
                        </button>
                    }
                    <label htmlFor="" className='text-xs leading-6 text-center text-amber-400'>If you haven't received a verification mail yet please click the resend button.</label>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>

      </div>

      <div className={checkMailModal ? 'overlay-wrapper' : 'no-display hidden'}>
        <div className="overlay-inner-wrapper absolute w-full h-full top-[0] left-[0] cursor-pointer bg-transparent" onClick={() => setForgetMailModal(false)}>
        </div>
        <div className={checkMailModal ? "cancelModalContainer absolute top-[20px] right-[20px] z-[3500]" : 'no-display'}><img src={cancel} alt="" className="w-[80px] cursor-pointer" onClick={() => setCheckMailModal(false)} /></div>
        <div className="forgot-password-container check-your-mail">
          <div className={checkMailModal ? 'inner-wrapper' : 'no-display'}>
            <div className="image-container">
              <img src={checkMail} alt="Reset Image" />
            </div>

            <form
              onSubmit={handleEmailResend}
              className={checkMailModal ? 'content-container' : 'no-display'}
            >
              <div className="input-section">
                <label>What's your email <span>*</span></label>
                <input
                  type="email"
                  placeholder="Type here..."
                  onChange={e => setEmailReceive(e.target.value)}
                  value={emailReceive}
                />
                <p className="text-red-600 text-center mt-2 mb-0">{forgotErrorMessage}</p>
              </div>

              <div className="btn-wrapper">
                <button className="resend" type="submit" >
                  Resend
                </button>
              </div>

              <p className="notify-text text-center text-[#EBAF4A]">
                If you haven't received a mail yet please click the resend button.
              </p>
            </form>
          </div>
        </div>
      </div>

      <div className="flex justify-center md:pt-40 pt-32 flex-col items-center w-full px-12  pb-20 mb-20 relative">
        <h1 className="md:px-20  text-white font-bold md:text-3xl text-xl mb-0 md:mb-8">
          Welcome Back!{" "}
        </h1>
        <div className="bg-transparent md:bg-white xl:p-6 lg:p-6 md:p-4 xl:px-16 lg:px-12 md:px-6 w-full  xl:w-2/6 lg:w-2/6 md:w-2/6 h-auto rounded-lg">
          <form
            onSubmit={formik.handleSubmit}
            className="flex flex-col items-center justify-center mt-16 relative"
          >
            <img
              src={user}
              className="md:w-20  w-16 absolute md:-top-12 -top-9 left-50"
              alt=""
            />

            <input
              type="email"
              placeholder="Your Email:"
              className="md:text-base text-xs p-3 w-full border border-gray-500 rounded-lg my-4 placeholder:text-xs placeholder:text-gray-700"
              name="email"
              id="email"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.email}
            />
            {formik.touched.email && formik.errors.email ? (
              <p className="text-red-600 text-left text-xs w-full -mt-2 mb-4">
                {formik.errors.email}
              </p>
            ) : null}

            <div className="bg-white p-3 w-full flex gap-1 items-center  justify-between border border-gray-500 rounded-lg mt-2">
              <input
                type={passwordShown ? "text" : "password"}
                name="password"
                id="password"
                placeholder="Password: A minimum of 8 characters"
                className="bg-transparent w-11/12 placeholder:text-xs md:text-base text-xs placeholder:text-gray-700"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.password}
              />

              {passwordShown ? (
                <button onClick={(e) => togglePassword(e)}>
                  <img src={open} className="w-6 h-6" alt="" />
                </button>
              ) : (
                <button onClick={(e) => togglePassword(e)}>
                  <img src={closed} className="w-6 h-6" alt="" />
                </button>
              )}
            </div>
            {formik.touched.password && formik.errors.password ? (
              <p className="text-red-600 text-left text-xs w-full mt-2 mb-4">
                {formik.errors.password}
              </p>
            ) : null}

            <p
              className="mt-8 text-white md:text-base text-base md:text-gray-500 font-semibold cursor-pointer"
              onClick={() => {
                setForgetMailModal(true)
                gaEventTracker('Forgot Password')
              }}
            >
              Forgot Password?
            </p>

            {load ? (
              <div className="bg-amber-500 p-1 w-full font-semibold rounded-lg my-4 flex justify-center items-center">
                <h4 className="text-black text-base">Please Wait!</h4>
                <input
                  type="image"
                  src={Spin}
                  value="Loading"
                  className="w-10"
                />
              </div>
            ) : (
              <button
                type="submit"
                className="bg-amber-500 p-3 w-full font-semibold rounded-lg my-4"
              >
                Login
              </button>
            )}
          </form>

          <p className="text-center my-4 text-base font-semibold text-white md:text-gray-500">
            Don’t have an account?

            <NavLink className="font-semibold theme-color"  to="/sign-up"
              onClick={(e) => {
                e.preventDefault()
                gaEventTracker('Sign Up')
                navigate('/sign-up')
              }}
            >
              &nbsp;

              Sign-up
            </NavLink>
          </p>
          <div className="flex gap-3 items-center justify-center my-16">
            <div className="flex orange rounded-lg px-4 py-1 h-10">
              <button className="text-white text-xs focus:outline-none"
                onClick={(e) => {
                  e.preventDefault()
                  gaEventTracker('Login With Google')

                  localStorage.setItem("googleClicked", JSON.stringify('true'))

                  window.open('https://api.roadlers.com/api/v1/auth/google', "_self")

                }}
              >
                Login with
              </button>{" "}
              <img className="w-6" src={google} alt="" />
            </div>
            {/* <div className="flex bg-blue-700 rounded-lg px-4 py-1 h-10">
              <button className="text-white text-xs focus:outline-none"
                onClick={(e) => {
                  e.preventDefault()
                  gaEventTracker('login with fb')

                  window.open('https://api.roadlers.com/api/v1/auth/facebook', "_self")

                }}
              >
                Login with
              </button>{" "}
              <img className="w-6" src={fbb} alt="" />
            </div> */}
          </div>
        </div>
        <img
          src={car}
          alt=""
          className="absolute -bottom-12 md:right-96 md:w-56 w-44"

        />
      </div>
    </div>
  )
}

export default Login
