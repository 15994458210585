import apiRequest from "../http-common"

const goldenTicketOffer = () => {
  return apiRequest.get("/golden-ticket")
}

const mealTicketOffer = () => {
  return apiRequest.get("/meal-ticket")
}

const SpecialOffersService = {
  goldenTicketOffer,
  mealTicketOffer
}

export default SpecialOffersService
