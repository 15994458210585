import React, { useState, useRef, useEffect } from 'react'
import cartwhite from '../../Images/cart-white.svg';
import daily_driver from '../../Images/daily_driver.png';
import ratings from '../../Images/ratings.svg'
import locationicon from '../../Images/location.svg'
import message from '../../Images/message.png'
import mark from '../../Images/mark.png'
import logo_circle from '../../Images/logo-circle.png'
import like from '../../Images/Like-icon.png'
import gift_faint from '../../Images/gift_faint.png'
import drivee from '../../Images/drivee.png'
import prev from '../../Images/prev.png'
import onee from '../../Images/onee.png'
import next from '../../Images/next.png'
import user from '../../Images/userg.png'
import send from '../../Images/send.png'
import infoIcon from "../../Images/infoIcon.svg"
import clockb from '../../Images/clockb.png'
import Spin from '../../Images/spinner.gif';
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

import { NavLink, useNavigate } from "react-router-dom";
import { addCart } from '../../slices/booking';
import { useDispatch } from 'react-redux';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { GoogleMap, useJsApiLoader, Autocomplete } from '@react-google-maps/api';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { BASE_URL } from "../utils/constant"
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';

function DriversComponent(props) {
    const { } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyDkMuW-hGvdRfUhsb2R9RFqA0Pq4Qxg4Cs",
        libraries: ['places'],

    })

    const [autocomplete, setAutocomplete] = useState(null)

    const onPlaceChanged = () => {
        if(autocomplete !== null) {
            const places = autocomplete.getPlace().formatted_address
            setPickupLocation(places)
        }
    }

    let navigate = useNavigate();
    const dispatch = useDispatch()

    const { Location, price, image, type, p1, p2, p3, p4, list, tokenCode, dayCode } = props

    // SETTING STATES FOR THETYPE OF FULLTIME DRIVER CARD
    const [title, setTitle] = useState('Full-time Regular Driver')
    const [textOne, setTextOne] = useState('This is a trained professional individuals, supported by dedicated customer care agents to meet your needs. He would work with you 10 hours daily from morning till evening 6 days a week. Some of the benefits include:')
    const [textTwo, setTextTwo] = useState()

    const [monthValue, setMonthValue] = useState(1)
    const [status, setStatus] = useState('Start Booking - Let’s Move')

    const [isInsured, setIsInsured] = useState(false)
    const [fulltimeOptionArray, setFulltimeOptionArray] = useState([])


    const refDiv = useRef()
    console.log("myref", refDiv.current)

    const [load, setLoad] = useState('')

    const [proceed, setProceed] = useState(false)

    const [width, setWidth] = useState(7)
    let [question, setQuestion] = useState(1)
    const [allQuestion, setAllQuestion] = useState(7)

    // HOVER INSURANCE INFO
    const [insuanceInfo, setInsuranceInfo] = useState(false)




    // const [resumptionDate, setResumptionDate] = useState('')
    const [startDate, setStartDate] = useState(new Date());

    const [typeOfFullTime, settypeOfFullTime] = useState('')
    const [pickupLocation, setPickupLocation] = useState('')

    const [dailyTime, setDailyTime] = useState(null)

    const [destination, setDestination] = useState('')
    const [notes, setNotes] = useState('')
    const [fullTimePrice, setFullTimePrice] = useState('85000')

    const driverPrice = Number(fullTimePrice)



    const [typeOfFullTimeError, setTypeOfFullTimeError] = useState('')
    const [showFieldsTwo, setshowFieldsTwo] = useState(false)
    const onClickTwo = (e) => {
        e.preventDefault()

        if(typeOfFullTime === '') {
            setTypeOfFullTimeError('This is required')

            setTimeout(() => {
                setTypeOfFullTimeError('')
            }, 3000)
        }else {
            setshowFieldsTwo(false)
            setshowFieldsThree(true)
            setQuestion(++question)
            setWidth(50)
            console.log('width in two', width)
        }

    }

    const [startDateError, setStartDateError] = useState('')
    const [showFieldsOne, setshowFieldsOne] = useState(true)
    const onClickOne = (e) => {
        e.preventDefault()

        if(startDate === '') {
            setStartDateError('This is required')
            setTimeout(() => {
                setStartDateError('')
            }, 3000)
        }else {
            setshowFieldsOne(false)

            setshowFieldsTwo(true)
            setQuestion(++question)
            setWidth(30)
        }

        console.log('width in one', width)
    }
    const [pickupLocationError, setPickupLocationError] = useState('')
    const [showFieldsThree, setshowFieldsThree] = useState(false)
    const onClickThree = (e) => {
        e.preventDefault()

        if(pickupLocation === '') {
            setPickupLocationError('This is required')

            setTimeout(() => {
                setPickupLocationError('')
            }, 3000)
        }else {
            setshowFieldsThree(false)
            setshowFieldsFour(true)
            setQuestion(++question)
            setWidth(70)
            console.log('width in three', width)
            setStatus('Almost Done - Keep Moving')
        }
    }

    const [monthValueError, setMonthValueError] = useState('')
    const [showFieldsFour, setshowFieldsFour] = useState(false)
    const onClickFour = (e) => {
        e.preventDefault()

        if(monthValue === '') {
            setMonthValueError('This is required') 

            setTimeout(() => {
                setMonthValueError('')
            }, 3000)
        } else {
            setshowFieldsFour(false)
            setshowFieldsFive(true)
            setQuestion(++question)
            setStatus('Almost Done - Keep Moving')
            setWidth(85)
            console.log('width in four', width)
        }
    }

    const [ dailyTimeError, setDailyTimeError] = useState('')
    const [showFieldsFive, setshowFieldsFive] = useState(false)
    const onClickFive = (e) => {
        e.preventDefault()

        if(dailyTime === '' || !dailyTime ) {
            setDailyTimeError('This is required')

            setTimeout(() => {
                setDailyTimeError('')
            }, 3000);
        }else {
            setshowFieldsFive(false)
            setshowFieldsSix(true)
            setQuestion(++question)
            setWidth(90)
            console.log('width in five', width)
        }


    }


    const [notesError, setNotesError] = useState('')
    const [showFieldsSix, setshowFieldsSix] = useState(false)
    const onClickSix = (e) => {
        e.preventDefault()

        if(notes === '') {
            setNotesError('This is required')

            setTimeout(() => {
                setNotesError('')
            }, 3000)
        }else {
            setshowFieldsSix(false)
            setshowFieldsSeven(true)
            setQuestion(++question)
            setStatus('Completed - Proceed to Move with Ease')
            setWidth(100)
            console.log('width in six', width)
        }
    }

    const [showFieldsSeven, setshowFieldsSeven] = useState(false)
    const onClickSeven = (e) => {
        e.preventDefault()
        setProceed(true)
        console.log('width in seven', width)
    }

    const onClickStart = (e) => {
        e.preventDefault()
        setshowFieldsOne(true)
        setshowFieldsTwo(false)
        setshowFieldsThree(false)
        setshowFieldsFour(false)
        setshowFieldsFive(false)
        setshowFieldsSeven(false)
        // setshowFieldsFiveb(false)
        setshowFieldsSix(false)
        setQuestion(question - 1)
        setWidth(10)
    }

    const onClickOneBack = (e) => {
        e.preventDefault()
        setshowFieldsOne(false)

        setshowFieldsTwo(true)
        setshowFieldsThree(false)
        setshowFieldsFour(false)
        setshowFieldsFive(false)
        // setshowFieldsFiveb(false)
        setshowFieldsSix(false)
        setshowFieldsSeven(false)
        setQuestion(question - 1)
        console.log('1 back', question)

        setWidth(30)

    }

    const onClickSixBack = (e) => {
        e.preventDefault()
        setshowFieldsOne(false)

        setshowFieldsTwo(false)
        setshowFieldsThree(false)
        setshowFieldsFour(false)
        setshowFieldsFive(false)
        setshowFieldsFive(false)
        setshowFieldsSix(true)
        setshowFieldsSeven(false)
        setQuestion(question - 1)
        console.log('six back', question)
        setWidth(85)
    }

    const onClickFiveBack = (e) => {
        e.preventDefault()
        setshowFieldsOne(false)

        setshowFieldsTwo(false)
        setshowFieldsThree(false)
        setshowFieldsFour(false)
        setshowFieldsFive(false)
        setshowFieldsFive(true)
        setshowFieldsSix(false)
        setshowFieldsSeven(false)
        setQuestion(question - 1)
        console.log('five back', question)
        setWidth(70)

    }

    const onClickTwoBack = (e) => {
        e.preventDefault()


        setshowFieldsThree(true)

        setshowFieldsOne(false)

        setshowFieldsTwo(false)

        setshowFieldsFour(false)
        setshowFieldsFive(false)
        // setshowFieldsFiveb(false)
        setshowFieldsSix(false)
        setshowFieldsSeven(false)
        setQuestion(question - 1)
        console.log('2 back', question)

        setWidth(50)
    }

    const onClickThreeBack = (e) => {
        e.preventDefault()
        setshowFieldsOne(false)

        setshowFieldsTwo(false)
        setshowFieldsThree(false)
        setshowFieldsFour(true)
        setshowFieldsFive(false)
        // setshowFieldsFiveb(false)
        setshowFieldsSix(false)
        setshowFieldsSeven(false)
        setQuestion(question - 1)
        console.log('3 back', question)
        setWidth(70)
    }

    // SELECTING TYPE OF DRIVER
    const handleFullTimeDriver = (e) => {
        e.preventDefault()

        const fulltimeType =  e.target.value
        const fulltimeTypeArray = fulltimeType?.split(",")
        const getTypePicked = fulltimeTypeArray[0]
        const getPricePicked = fulltimeTypeArray[1]

        console.log(fulltimeTypeArray, "getType")
        console.log(getPricePicked, 'get')
        settypeOfFullTime(getTypePicked)
        setFullTimePrice(getPricePicked)

        if(getTypePicked?.includes('Police Driver')) {
            // setFullTimePrice('150000');
            setTitle('Full-time Police Driver')
            setTextOne('This is a trained security personal, supported by dedicated customer care agents. Who would work with you 10 hours daily from morning till evening 6 days a week. Some of the benefits include:')
        }else if(getTypePicked?.includes('Night Driver')) {
            // setFullTimePrice('100000')
            setTitle('Full-time Night Driver')
            setTextOne('This is a trained professional individuals, supported by dedicated customer care agents. Who would work with you 10 hours daily from evening till morning 6 days a week. Some of the benefits include:')
        }else if(getTypePicked?.includes('Regular Driver')) {
            // setFullTimePrice('85000')
            setTitle('Full-time Regular Driver')
            setTextOne('This is a trained professional individuals, supported by dedicated customer care agents to meet your needs. He would work with you 10 hours daily from morning till evening 6 days a week. Some of the benefits include:')
        }else if(getTypePicked?.includes('3 days Weekly')) {
            // setFullTimePrice('45000')
            setTitle('3 Days Weekly Driver')
            setTextOne('This is a trained professional individuals, supported by dedicated customer care agents. Who would work with you 10 hours daily from morning till evening 3 days a week. Some of the benefits include:')
            setTextTwo('')
        }else {
            // setFullTimePrice('85000')
            setTitle('Full-time Regular Driver')
            setTextOne('This is a trained professional individuals, supported by dedicated customer care agents to meet your needs. He would work with you 10 hours daily from morning till evening 6 days a week. Some of the benefits include:')
        }

    }

    // HANDLE CONDITIONS FOR CHANGE OF MONTH
    const handleMonthChange = (e) => {
        e.preventDefault()

        if (e.target.value === '1') {
            setMonthValue(1)
        }else if (e.target.value === '2') {
            setMonthValue(2)
        }else if (e.target.value === '3') {
            setMonthValue(3)
        }else if (e.target.value === '4') {
            setMonthValue(4)
        }else if (e.target.value === '5') {
            setMonthValue(5)
        }else if (e.target.value === '6') {
            setMonthValue(6)
        }else if (e.target.value === '7') {
            setMonthValue(7)
        }else if (e.target.value === '8') {
            setMonthValue(8)
        }else if (e.target.value === '9') {
            setMonthValue(9)
        }else if (e.target.value === '10') {
            setMonthValue(10)
        }else if (e.target.value === '11') {
            setMonthValue(11)
        }else if (e.target.value === '12') {
            setMonthValue(12)
        }else {
            setMonthValue(1)
        }
    }

    const fetchPrices = () => {
        axios
            .get(`${BASE_URL}/v1/view-pricing`, {
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((response) => {
                const transData = response.data.data;
                setFulltimeOptionArray(transData)
            });
    }
    useEffect(() => {
        fetchPrices();
    }, []);

    // HANDLE INSURANCE CHANGE
    const handleInsuranceChange = (e) => {
        if(e.target.value === "Yes") {
            setIsInsured(true)
        }else {
            setIsInsured(false)
        }
    }

    const fullTimeDriverPrice = () => {
        if (isInsured) {
            return ((driverPrice * monthValue) + (0.05 * driverPrice * monthValue))
        }else {
            return (driverPrice * monthValue)
        }
    }

    const priceForDriver = fullTimeDriverPrice()



    const handleProceed = (e) => {
        console.log('now starting form data')

        e.preventDefault();


        let userId = JSON.parse(localStorage.getItem("userId"))

        let formData = new FormData();


        let exist = JSON.parse(localStorage.getItem("cart"));
        if (exist == null) {
            exist = [];
        }

        let bookingDetails = {
            'resumption_date': moment(startDate).format("YYYY/MM/DD"),
            'pickup_location': pickupLocation,
            'destination': destination,
            'notes': notes,
            'pickup_time': dailyTime,
            'category': typeOfFullTime,
            'amount': priceForDriver,
            'monthly_price': driverPrice,
            'booking_title': 'Full-Time Driver',
            'pickup_date': moment(startDate).format("YYYY/MM/DD"),
            'token_code': tokenCode,
            'month_value': monthValue,
            'insurance_price': 0.05 * driverPrice * monthValue,
        }

        exist.push(bookingDetails);
        // THE CODE BELOW SELECTS EACH ITEM IN THE EXIST ARRAY AND ADDS AN ID TO EACH ONE, INCREASING EACH ID BY ONE
        exist.map((item, i) => {
            item.id = i + 1;
        });

        localStorage.setItem("cart", JSON.stringify(exist));

        dispatch(addCart(exist))
        navigate('/cart')
    }

    return (

        <div>
            <div className="my-20 flex flex-col gap-8 md:px-36 w-full">
                <form action="" className='hidden w-full m-auto md:flex gap-4 md:justify-center flex-wrap'>
                    <input type="text" name="" className='flex justify-center items-center w-4/12 bg-transparent border border-white text-white py-2 px-4 rounded-md placeholder:text-xs ' placeholder='Search by car, model, company....' />

                    <input type="text" name="" className='w-2/12 bg-transparent border border-white text-white py-2 rounded-md placeholder:text-xs px-4' placeholder='Pick-Up Location' />
                    <button className="bg-amber-500 rounded-md px-3 py-1 w-24 font-bold text-sm">Search</button>
                    <div className="text-white px-4 text-xs bg-transparent border border-white rounded-md flex items-center justify-center">
                        <img src={user} alt="" className='w-5' />

                        <select className='bg-transparent' name="" id="">
                            <option value="">Account</option>
                        </select>
                    </div>


                    <div className="flex bg-transparent border gap-1 border-white rounded-md px-3 py-1 w-24  text-xs text-white">
                        <img src={cartwhite} alt="" className='w-7' />
                        <button className="">  Cart</button>
                    </div>
                </form>



                <form className='flex flex-col md:hidden w-full px-6'>
                    <div className="flex w-full gap-2">
                        <input type="text" name="" className='text-sm w-6/12 bg-transparent border border-white text-white rounded-md placeholder-text-xxx p-2' placeholder='Search by car, model, company....' />

                        <input type="text" name="" className='text-sm w-4/12 bg-transparent border border-white text-white rounded-md placeholder-text-xxx p-2' placeholder='Pick-Up Location' />
                        <button className="bg-amber-500 rounded-md   w-2/12 font-bold text-xx p-2">Search</button>

                    </div>

                    <div className="flex w-full gap-2 mt-4">
                        <div className="text-white px-4 text-xs bg-transparent border border-white rounded-md flex items-center justify-center">
                            <img src={user} alt="" className='w-5' />

                            <select className='bg-transparent' name="" id="">
                                <option value="">Account</option>
                            </select>
                        </div>


                        <div className="flex bg-transparent border gap-1 border-white rounded-md px-3 py-1 w-20 text-xs text-white">
                            <img src={cartwhite} alt="" className='w-7' />
                            <button className="">  Cart</button>
                        </div>
                    </div>
                </form>

            </div>

            <div className="flex md:flex-row flex-col gap-12 md:px-28 px-8 mt-16 md:w-10/12 w-full  m-auto">
                <div className="flex flex-col md:w-5/12 w-full">


                    <div className="relative w-11/12">


                        <img src={image} alt="" className='w-full' />
                        {price ? <div className="absolute bottom-8 rounded-r-lg font-bold text-center bg-amber-500 p-1 w-24"><h4>{price}</h4></div> : ''}
                        <button className='absolute  top-4 left-4'> <img src={like} alt="" className='w-12' /></button>
                        <button className='absolute  bottom-2 right-3'> <img src={send} alt="" className='w-8' /></button>

                    </div>


                    <div className="flex flex-col w-10/12 mt-6 mb-16 md:hidden">
                        <h3 className='text-amber-500 font-semibold text-sm '> {title}</h3>
                        <hr className='border-gray-500 border-t mt-2 w-7/12 ' />
                    </div>



                    <div className="w-11/12 bg-white p-4 rounded-xl mt-4 md:flex hidden flex-col gap-1">
                        <h3 className='text-amber-500 font-bold'>{title}</h3>
                        <hr className='border-t border-gray-500 w-28' />
                        <div className="flex gap-1 items-center ml-0 pl-0 -ml-2">
                            <img src={locationicon} alt="" className='md:w-5 w-4' />
                            <p className='text-xs font-semibold'>{Location}</p>

                        </div>
                        <p className='text-xs font-semibold leading-6'>{textOne}</p>
                        <br />
                        {p1 ? <ul>
                            <li className='text-xs font-semibold leading-6'>&bull; {p1}</li>
                            <li className='text-xs font-semibold leading-6'>&bull; {p2}</li>
                            <li className='text-xs font-semibold leading-6'>&bull; {p3}</li>
                            <li className='text-xs font-semibold leading-6'>&bull; {p4}</li>

                        </ul> : null}

                        <div className="flex justify-between mt-2">
                            <img src={ratings} alt="" className='w-32' />
                            <button className="border border-black rounded-3xl py-1 px-5 text-xs">Verified</button>
                        </div>
                    </div>
                </div>
               
               
                <form onSubmit={handleProceed} className='md:w-7/12 '>

                <div className="flex flex-col w-full">
                    <h4 className='text-[#FFA100] font-medium md:font-semibold'>{status}</h4>


                    <div className="w-full mt-12 relative">
                        <input type="range" min="1" max={allQuestion} value={question} className="slider" id="myRange" />
                        <p className='text-white font-semibold absolute -top-5 left-0'>{question}/{allQuestion}</p>
                        <p className='text-white font-semibold absolute -top-5 right-0'>{allQuestion}</p>
                        <div
                            id="background"
                        ></div>

                        <div
                            id="progress"
                            ref={refDiv}
                            style={{ width: width + '%' }}
                        >
                        </div>
                    </div>

                    {type ? (
                        <div className="flex bg-white p-3 justify-between items-center mt-12 rounded-xl">
                            <input type="text" className='w-11/12  px-1 placeholder:text-black' placeholder='Add A Note:' />
                            <img src={message} alt="" className='md:w-9 w-6' />

                        </div>
                    ) : ''}


                    {showFieldsOne ?
                        (
                            <>
                                <label htmlFor="Resumption Date:" className='mt-12 text-white md:text-sm text-xs'>Resumption Date:</label>
                                <div className="flex bg-white border border-white text-white p-3 justify-between items-center mt-1 rounded-xl">
                                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                                </div>
                                <p className="text-red-600 mt-2">{startDateError}</p>

                                <div className="flex gap-2 items-center justify-between">

                                    <div className="flex gap-2 items-center cursor-pointer">
                                        <div className="bg-black border border-white  my-8 p-2 text-center items-center gap-2 rounded-md font-bold  flex">
                                            <button onClick={onClickOne} className='text-white font-bold flex items-center justify-center'>OK <img src={mark} alt="" /></button>
                                        </div>
                                        <h4 className='text-white font-bold'>Press Enter</h4>
                                    </div>

                                    <div className="flex gap-4 text-white font-extrabold">
                                        <button
                                            onClick={onClickOne}
                                        >
                                            <img src={next} alt="Next-btn" />
                                        </button>
                                    </div>
                                </div>
                            </>) : ''}


                    {showFieldsTwo ? (
                        <>
                            {
                                tokenCode && dayCode ?
                                <>
                                    <div className="flex flex-col gap-3 mt-12">  
                                        <label className='text-white'>Type of full-time driver</label>             
                                        <input 
                                            className='w-full text-[#242321] bg-white focus:outline-none py-3 px-4  placeholder:text-black rounded-lg md:placeholder:text-sm placeholder:text-xs'
                                            name="numOfDay"
                                            id="numOfDay"
                                            value={"Regular Driver  |   ₦85,000"}
                                        />
                                    </div>
                                </>
                                :
                                <>
                                <div className="flex bg-white border border-white p-3 justify-between items-center mt-12 rounded-xl">
                                    <select 
                                        // value={typeOfFullTime}
                                        onChange={handleFullTimeDriver}
                                        className='text-[#242321] w-full bg-transparent outline-none focus:outline-none  px-1'
                                    >
                                        <option className='bg-black text-white'>Type of full-time driver</option>
                                        {
                                            fulltimeOptionArray?.filter((item) => item?.heading?.includes('fulltime_driver_price') || item?.heading?.includes('Full-time Driving Price')).map((optionArray) => (
                                                <option value={optionArray?.description + "," + optionArray?.commission} className='bg-black text-white'>{optionArray?.description}     |   ₦{parseFloat(optionArray?.commission).toLocaleString('en-US', { maximumFractionDigits: 2 })}</option>
                                            ))
                                        }
                                    </select>



                                </div>
                                <p className="text-red-600 mt-2">{typeOfFullTimeError}</p>
                                </>

                            }

                            <div className="flex items-center justify-between">

                                <div className="flex gap-2 items-center cursor-pointer">
                                    <div className="bg-black border border-white  my-8 p-2 text-center items-center gap-2 rounded-md font-bold  flex">
                                        <button onClick={onClickTwo} className='text-white font-bold flex items-center justify-center'>OK <img src={mark} alt="" /></button>


                                    </div>
                                    <h4 className='text-white font-bold'>Press Enter</h4>
                                </div>

                                <div className="flex gap-4 text-white font-extrabold">
                                    <button
                                        onClick={onClickStart}
                                    >
                                        <h2>
                                        <img src={prev} alt="Prev-btn" />
                                        </h2></button>
                                    <button
                                        onClick={onClickTwo}
                                    >
                                        <img src={next} alt="Next-btn" />
                                    </button>
                                </div>
                            </div>
                        </>
                    ) : ''}



                    {showFieldsThree ? (
                        <>

                            <div className="flex border border-white bg-white p-3 justify-between items-center mt-12 rounded-xl">
                                <Autocomplete className='autcomplete-style'
                                    onPlaceChanged={onPlaceChanged}
                                    onLoad={(value) => setAutocomplete(value)}
                                >
                                    <input
                                        type="text"
                                        className='w-11/12  px-1 placeholder:text-black'
                                        placeholder='Pick-up Location:'
                                        name="pickupLocation"
                                        id="pickupLocation"
                                        value={pickupLocation}
                                        onChange={e => setPickupLocation(e.target.value)}
                                    />
                                </Autocomplete>
                                <img src={locationicon} alt="" className='md:w-6 w-4' />
                            </div>
                            <p className="text-red-600 mt-2">{pickupLocationError}</p>
                            <div className="flex items-center justify-between">
                                    <div className="flex gap-2 items-center cursor-pointer">
                                        <div className="bg-black border border-white  my-8 p-2 text-center items-center gap-2 rounded-md font-bold  flex">
                                            <button onClick={onClickThree} className='text-white font-bold flex items-center justify-center'>OK <img src={mark} alt="" /></button>
                                        </div>
                                        <h4 className='text-white font-bold'>Press Enter</h4>
                                    </div>

                                    <div className="flex gap-4 text-white font-extrabold">
                                        <button
                                            onClick={onClickOneBack}
                                        >
                                            <h2>
                                            <img src={prev} alt="Prev-btn" />
                                            </h2>
                                        </button>
                                        <button

                                            onClick={onClickThree}
                                        >
                                            <img src={next} alt="Next-btn" />
                                        </button>
                                    </div>
                            </div>
                    </>
                    ) : ''}


                    {showFieldsFour ? (
                            <>
                                {
                                    tokenCode && dayCode ?
                                    
                                    <>
                                        <div className="flex flex-col gap-3 mt-12">  
                                            <label className='text-white'>Number of Months</label>             
                                            <input 
                                                className='w-full text-[#242321] bg-white focus:outline-none py-3 px-4  placeholder:text-black rounded-lg md:placeholder:text-sm placeholder:text-xs'
                                                name="numOfDay"
                                                id="numOfDay"
                                                value={dayCode}
                                            />
                                        </div>
                                    </>

                                    :
                                    <>
                                    <label className="text-white mt-12 mb-2">Number of Months</label>
                                    <div className="flex  justify-between items-center rounded-lg bg-white">
                                            <select
                                            className='w-11/12 bg-transparent  p-3 placeholder:text-black rounded-lg md:placeholder:text-sm placeholder:text-xs'
                                            name="number_of_days"
                                            id="number_of_days"
                                            // onBlur={formik.handleBlur}
                                            onChange={handleMonthChange}
                                        >
                                            <option value='1' className='md:text-sm text-xx'>1 Month</option>
                                            <option value='2' className='md:text-sm text-xx'>2 Months</option>
                                            <option value='3' className='md:text-sm text-xx'>3 Months</option>
                                            <option value='4' className='md:text-sm text-xx'>4 Months</option>
                                            <option value='5' className='md:text-sm text-xx'>5 Months</option>
                                            <option value='6' className='md:text-sm text-xx'>6 Months</option>
                                            <option value='7' className='md:text-sm text-xx'>7 Months</option>
                                            <option value='8' className='md:text-sm text-xx'>8 Months</option>
                                            <option value='9' className='md:text-sm text-xx'>9 Months</option>
                                            <option value='10' className='md:text-sm text-xx'>10 Months</option>
                                            <option value='11' className='md:text-sm text-xx'>11 Months</option>
                                            <option value='12' className='md:text-sm text-xx'>12 Months</option>
                                            </select>

                                    </div>
                                    <p className="text-red-600 mt-2">{monthValueError}</p>
                                    </>
                                }


                                <div className="flex items-center justify-between">
                               
                                <div className="flex gap-2 items-center cursor-pointer">
                                    <div className="bg-black border border-white  my-8 p-2 text-center items-center gap-2 rounded-md font-bold  flex">

                                        <button onClick={onClickFour} className='text-white font-bold flex items-center justify-center'>OK <img src={mark} alt="" /></button>


                                    </div>
                                    <h4 className='text-white font-bold'>Press Enter</h4>
                                </div>

                               <div className="flex gap-4 text-white font-extrabold">
                                           <button
                                               onClick={onClickTwoBack}
                                           >
                                               <h2>
                                               <img src={prev} alt="Prev-btn" />
                                               </h2></button>
                                           <button

                                               onClick={onClickFour}
                                           >
                                               <img src={next} alt="Next-btn" />
                                           </button>
                                       </div>

                            </div>

                                
                            </>
                        ) : ''}



                    {showFieldsFive ? (
                        <>

                        
                            <div className="mt-12 flex flex-col gap-2">
                            <label htmlFor="" className='text-white'>Average daily resumption time:</label>
                            <div className="flex time border bg-white border-white justify-between items-center rounded-xl">
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <TimePicker
                                        value={dailyTime}
                                        onChange={(time) => {
                                            setDailyTime(time);
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </div>
                            <p className="text-red-600 mt-2">{dailyTimeError}</p>

                            </div>
                            <div className="flex items-center justify-between">
                               
                               <div className="flex gap-2 items-center cursor-pointer">
                                   <div className="bg-black border border-white  my-8 p-2 text-center items-center gap-2 rounded-md font-bold  flex">

                                       <button onClick={onClickFive} className='text-white font-bold flex items-center justify-center'>OK <img src={mark} alt="" /></button>


                                   </div>
                                   <h4 className='text-white font-bold'>Press Enter</h4>
                               </div>

                               <div className="flex gap-4 text-white font-extrabold">
                                           <button
                                               onClick={onClickThreeBack}
                                           >
                                               <h2>
                                               <img src={prev} alt="Prev-btn" />
                                               </h2></button>
                                           <button

                                               onClick={onClickFive}
                                           >
                                               <img src={next} alt="Next-btn" />
                                           </button>
                                       </div>

                            </div>
                        </>
                    ) : ''}

                    {
                        showFieldsSix ? (
                            <>
                            <div className="flex bg-white p-3 justify-between items-center mt-12 rounded-xl">
                                <input 
                                type="text" 
                                className='w-11/12  px-1 placeholder:text-black' 
                                placeholder='Add A Note'

                                name="notes"
                                        id="notes"
                                        value={notes}
                                        onChange={e => setNotes(e.target.value)}
                                />
                                <img src={message} alt="" className='md:w-6 w-4' />

                            </div>
                            <p className="text-red-600 mt-2">{notesError}</p>
                            <div className="flex justify-between items-center">

                                <div className="flex gap-2 items-center cursor-pointer">
                                    <div className="bg-black border border-white  my-8 p-2 text-center items-center gap-2 rounded-md font-bold  flex">
                                        <button
                                            type='button'
                                            onClick={onClickSix}
                                            className='text-white font-bold flex items-center justify-center'>OK <img src={mark} alt="" /></button>
                                    </div>
                                    <h4 className='text-white font-bold' onClick={onClickSix}>Press Enter</h4>
                                </div>


                                <div className="flex gap-4 text-white font-extrabold">
                                    <button
                                        onClick={onClickFiveBack}
                                    >
                                        <h2>
                                            <img src={prev} alt="Prev-btn" />
                                        </h2>
                                    </button>

                                    <button
                                    onClick={onClickSix}
                                    >
                                        <img src={next} alt="Next-btn" />
                                    </button>
                                </div>

                            </div>
                        </>
                        ) : ''
                    }

                    {
                        showFieldsSeven ? (
                            <>
                            <label className='text-white font-bold mt-8' >Would you like to be covered by insurance on this trip?</label>
                            <div className="flex  items-center mt-4 rounded-lg bg-white relative">
                                <select
                                    className='w-11/12 bg-transparent p-3 placeholder:text-black rounded-lg md:placeholder:text-sm placeholder:text-xs'
                                    // onBlur={formik.handleBlur}   
                                    onChange={handleInsuranceChange}
                                >
                                    <option className='md:text-sm text-xx'>Select your preferred choice</option>
                                    <option value='Yes' className='md:text-sm text-xx'>Yes</option>
                                    <option value='No' className='md:text-sm text-xx'>No</option>
                                </select>

                                <div class={` ${insuanceInfo ? 'absolute special-occasion-box bg-white rounded-xl p-4 bottom-[-150px] right-[-120px] w-[170px]' : 'hidden'}`}>
                                    <p className='text-black text-center'>
                                    Your insurance coverage is 5% of the price of your service. It covers only this particular transaction on Roadlers.  
                                    </p>
                                </div>

                                <img 
                                    src={infoIcon} 
                                    alt="infoIcon" 
                                    className='cursor-pointer absolute right-[70px]' 
                                    onMouseOver={() => setInsuranceInfo(true)}
                                    onMouseOut={() => setInsuranceInfo(false)}
                                />

                            </div>
                            <div className="flex justify-between items-center">

                                <div className="flex gap-2 items-center cursor-pointer">

                                    <div className="bg-black border border-white  my-8 p-2 text-center items-center gap-2 rounded-md font-bold  flex">
                                        <button
                                            type='button'
                                            onClick={onClickSeven}
                                            className='text-white font-bold flex items-center justify-center'>OK <img src={mark} alt="" /></button>
                                    </div>
                                    <h4 className='text-white font-bold' onClick={onClickSeven}>Press Enter</h4>
                                </div>


                                <div className="flex gap-4 text-white font-extrabold">
                                    <button
                                        onClick={onClickSixBack}
                                    >
                                        <h2>
                                            <img src={prev} alt="Prev-btn" />
                                        </h2></button>
                                </div>

                            </div>
                        </>
                        ) : ''
                    }



 
                    <div className="flex gap-5 w-full">
                            {
                              isInsured ? 
                                <button className='mt-8 p-2 rounded-lg border border-white w-5/12 text-white font-bold'>
                                    &#8358;{((driverPrice * monthValue) + (0.05 * driverPrice * monthValue)).toLocaleString('en-US', {maximumFractionDigits:2})}
                                </button>
                              :
                              <>
                                {
                                    tokenCode && dayCode ? 
                                    <button className='mt-8 p-2 rounded-lg border border-white w-5/12 text-white font-bold'>
                                        &#8358;{(driverPrice * dayCode).toLocaleString('en-US', {maximumFractionDigits:2})}
                                    </button> 
                                    :
                                    <button className='mt-8 p-2 rounded-lg border border-white w-5/12 text-white font-bold'>
                                        &#8358;{(driverPrice * monthValue).toLocaleString('en-US', {maximumFractionDigits:2})}
                                    </button>
                                }
                              </>
                            }
                         
                            {proceed &&

                            (load ? (
                                <div className="bg-amber-500 p-1 w-full font-semibold rounded-lg my-4 flex justify-center items-center"><h4 className='text-black text-sm'>Please Wait!</h4><input type="image" src={Spin} value="Loading" className="w-10" /></div>
                            ) : (

                                <button

                                    type="submit"

                                    className='mt-8 p-2 rounded-lg  bg-amber-500 w-7/12 text-black md:text-base text-sm font-bold'>Proceed to Booking</button>
                            ))
                            }

                    </div>
                    

                    <div className="md:hidden w-full bg-white p-4 rounded-xl mt-8 flex flex-col gap-1">
                        <h3 className='font-bold'>{title}</h3>
                        <hr className='border-t border-gray-500 w-28' />

                        <div className="flex gap-1 items-center ml-0 pl-0 -ml-2 mt-1">
                            <img src={locationicon} alt="" className='md:w-5 w-4' />
                            <p className='text-xs font-semibold'>{Location}</p>
                        </div>

                        <p className='text-xs font-semibold leading-6'>{textOne}</p>
                        <br />

                        {p1 ? <ul>
                            <li className='text-xs font-semibold leading-6'>&bull; {p1}</li>
                            <li className='text-xs font-semibold leading-6'>&bull; {p2}</li>
                            <li className='text-xs font-semibold leading-6'>&bull; {p3}</li>
                            <li className='text-xs font-semibold leading-6'>&bull; {p4}</li>
                        </ul> : null}
                        <p className='text-xs font-semibold leading-6'>{textTwo}</p>

                        <div className="flex justify-between mt-2">
                            <img src={ratings} alt="" className='w-24' />
                            <button className="border border-black rounded-3xl py-1 px-5 text-xs">Verified</button>
                        </div>
                    </div>

                    <div className="flex flex-col p-6 border border-white mt-12 rounded-lg">
                        <div className="flex gap-3 items-center">
                            <img src={logo_circle} alt="" className='w-10' />
                            <h3 className='text-white font-bold'>Roadlers</h3>
                        </div>
                        <h4 className='font-bold my-4 text-white'>Listing Company</h4>
                        <p className='text-white leading-7 md:text-sm text-xs'>This vehicle is listed by Roadlers, on behalf of an accredited partner, accompanied by highly trained drivers and dedicated customer care agents to meet your expectation.</p>

                    </div>
                </div>
                </form>

            </div>
        </div>
    )
}

export default DriversComponent