import React from 'react';
import eyes from '../../Images/eyes2.svg'

function Subheader(props) {
  return <>
  {props.text==='Service Providers'?(<div className="hidden md:flex w-full md:h-24 h-16 bg-white md:px-40 mt-12 md:mt-0 px-12 justify-between items-center">
      <h2 className='text-sm md:text-xl font-bold'>{props.text}</h2>
      <img src={eyes} alt="" className='w-8 md:w-8' />
    </div>):
   
    (<div className={props.text==='My Assets'||'Total Assets' ?'w-full md:h-24 h-16 bg-white md:px-40 mt-12 md:mt-0 px-8 flex justify-between items-center':'w-full md:h-24 h-16 bg-white md:px-40 mt-12 md:mt-0 px-12 flex justify-between items-center'}>
      <h2 className='text-sm md:text-xl font-bold'>{props.text}</h2>
      <img src={eyes} alt="" className='w-8 md:w-8' />
    </div>)}
  </>;
}

export default Subheader;
