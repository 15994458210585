import React from 'react';
import Header from '../Header'
import Navigation from './Navigation'
import { BASE_URL } from "../utils/constant"

import dashboard from '../../Images/dashboard.png'
import admin_request from '../../Images/admin_request.svg'
import service_p from '../../Images/service_p.svg'
import reports from '../../Images/reports.svg'
import customer from '../../Images/customers.png'
import notifications from '../../Images/notifications.png'
import download from '../../Images/download.svg'
import car_asset from '../../Images/car_asset.svg'
import icon from '../../Images/icon.svg'
import car from '../../Images/car.png'
import message from '../../Images/message.svg'
import payment from '../../Images/payment.png'
import naira from '../../Images/naira.png'
import one from '../../Images/one.png'
import roadlersRR from '../../Images/RoadlersRR.svg'
import { NavLink, useNavigate } from "react-router-dom";
import VehicleApprovalComponent from './VehicleApprovalComponent'

import { useState, useEffect, useRef } from "react";
import Switch from "react-switch";
import axios from "axios";
import Spin from '../../Images/spinner.gif';
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import getCookie from '../utils/SecuredTokens';

function PendingApproval() {

    // SERVICE DASHBOARD LOADER
    const [dashboardLoad, setDashboardLoad] = useState(true)

    const [currentPage, setCurrentPage] = useState(1);
    const [pagesNo, setPagesNo] = useState(0)

    

    let userData = getCookie("user");
    let userObject = JSON.parse(userData) 
    let token = userObject?.token
    // let id = userObject?.id
    let id = JSON.parse(localStorage.getItem("userId"))

    const [vehicleDataSet, setVehicleDataSet] = useState([])

    const getProvider = () => {
        axios({
            method: 'GET',
            url: `${BASE_URL}/v1/pending-approval-vehicle/${id}`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            }
        })
        .then(function (response) {
            const vehicle = response.data;
            setVehicleDataSet(vehicle.data)
            setDashboardLoad(false)
        })
        .catch(function (error) {
            let notify = () => { toast.error((error.response.data.message), {
                toastClassName: 'error'
            } ) };
            notify()
        });
    }

    useEffect(() => {
        getProvider()
    }, [])


    // PAGINATION 
    function goToNextPage() {
        setCurrentPage((page) => page +1)
    }

    function goToPreviousPage() {
        setCurrentPage((page) => page -1)
    }

    function changePage(event) {
        const pageNumber = Number(event.target.textContent);
        setCurrentPage(pageNumber)
    }


    const getPaginationData = () => {
        const startIndex = currentPage * 1 - 1;
        const endIndex = startIndex + 1;
        return vehicleDataSet?.slice(startIndex, endIndex);
    }

    useEffect(() => {
        setPagesNo(Math.round(vehicleDataSet?.length / 1))
    }, [vehicleDataSet])

    const getPaginationGroup = () => {
        let start = Math.floor((currentPage - 1) / 4) * 4;
        return new Array(4).fill(1).map((_, idx) => start + idx + 1)
    }



    return (
    dashboardLoad ? 
    <div className='h-[100vh] w-full flex justify-center items-center'>
        <div class="lds-ring">
            <img src={roadlersRR}  alt="roadlers logo" className='embed-logo'/>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
    :
    <div>
        <ToastContainer />
        <Header user='provider' />


        <main className='flex flex-col md:flex-row md:px-12 px-12 overall-approval-page'>
            {/* <button className='mt-40 p-2 bg-blue-900 rounded-md text-sm font-normal text-white'>Pending Approval</button> */}

            <Navigation />

            {
                getPaginationData()?.map((vehicleItem, index) => {
                    return (
                        <VehicleApprovalComponent 
                            key={index}
                            vehicleName={vehicleItem.vehicle_name}
                            vehicleYear={vehicleItem.year}
                            vehicleColor={vehicleItem.vehicle_color}
                            vehicleCountry={vehicleItem.country}
                            vehicleState={vehicleItem.state}
                            vehicleNote={vehicleItem.notes}
                            vehicleType={vehicleItem.vehicle_type}
                            plateNo={vehicleItem.plate_number}
                            passengerSeats={vehicleItem.passenger_seat}
                            address={vehicleItem.vehicle_address}
                            dailyPrice={vehicleItem.daily_price}
                            airportPrice={vehicleItem.airport_price}
                            monthlyPrice={vehicleItem.monthly_price}
                            hoursAvailable={vehicleItem.hour_available}
                            services={vehicleItem.service}
                            airCondition={vehicleItem.air_con}
                            travelAvailable={vehicleItem.travel_available}
                            vehicleInsurance={vehicleItem.vehicle_insurance}
                            statusDoc={vehicleItem.status}
                            img1={vehicleItem.image1}
                            img2={vehicleItem.image2}
                            img3={vehicleItem.image3}
                            vehicleDoc={vehicleItem.vehicle_document}
                            providerId={vehicleItem.asset_id}
                            currentPage={currentPage}
                            goToNextPage={goToNextPage}
                            goToPreviousPage={goToPreviousPage}
                            getPaginationGroup={getPaginationGroup}
                            changePage={changePage}
                            pagesNo={pagesNo}
                            driverId={vehicleItem.driver_id}
                            carOwnerId={vehicleItem.carowner_id}
                            carOwnerProfile={vehicleItem.carowner_profile}
                        />
                    )
                })
            }

            {/* <div className="pagination-box flex gap-2 items-center absolute right-[100px] bottom-[200px]">
                <button onClick={goToPreviousPage} className={currentPage === 1 ? `disabled` : 'prev'}>
                    prev
                </button>
                {getPaginationGroup().map((item, index) => (
                    <button
                        key={index}
                        onClick={changePage}
                        className={`${currentPage == item && 'active'} ${
                            item > pagesNo && 'disabled'
                        }
                        pagination-box-list_item rounded centered`}
                    >
                          <span>{item}</span>
                    </button>
                ))}
                <button onClick={goToNextPage} className={`${
                    currentPage === pagesNo ? 'disabled' : 'next'
                }`}>
                    next
                </button>
            </div> */}
        </main>
    </div>);
}

export default PendingApproval;


// Just some styles
const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      paddingTop: 50,
    },
    preview: {
      marginTop: 50,
      display: "flex",
      flexDirection: "column",
    },
    image: { maxWidth: "100%", maxHeight: 320 },
    delete: {
      cursor: "pointer",
      padding: 15,
      background: "red",
      color: "white",
      border: "none",
    },
  };