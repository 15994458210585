import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom'

import axios from "axios";
import Header from '../Header';
import Navigation from './Navigation';
import ApprovedIndividualComponent from './ApprovedIndividualComponent';
import { BASE_URL } from "../utils/constant"
import roadlersRR from '../../Images/RoadlersRR.svg'
import getCookie from '../utils/SecuredTokens';



function ApprovedServiceEdit() {
    const params = useParams()

    

    let userData = getCookie("user");
    let userObject = JSON.parse(userData) 
    let token = userObject?.token

   
    // SERVICE DASHBOARD LOADER
    const [dashboardLoad, setDashboardLoad] = useState(true)
    const [driverData, setDriverData] = useState([])

    const getProvider = () => {
        axios({
            method: 'get',
            url: `${BASE_URL}/v1/pending-approval-driver/${params.providerId}`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                "Access-Control-Allow-Origin": "*",
            }
        })
        .then(function (response) {
            const customer = response.data;
            setDriverData([customer.data])  
            setDashboardLoad(false) 
        })
        .catch(function (error) {
            //handle error
        });
    }

    useEffect(() => {
        getProvider()
    }, [])

  return (
    dashboardLoad ? 
    <div className='h-[100vh] w-full flex justify-center items-center'>
        <div class="lds-ring">
            <img src={roadlersRR}  alt="roadlers logo" className='embed-logo'/>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
    :
    <>
        <Header user='provider' />

        <main className='flex md:px-12 px-12 flex-col md:flex-row overall-approval-page'>
            <Navigation />

        {
            driverData?.map((driverItem, index) => {
                return (
                    <ApprovedIndividualComponent 
                        key={index}
                        firstName={driverItem?.firstname}
                        lastname={driverItem.lastname}
                        editDate={driverItem.edit_date}
                        address={driverItem.address}
                        category={driverItem.category}
                        service={driverItem.service}
                        availability={driverItem.availability}
                        dob={driverItem.dob}
                        stateLocation={driverItem.state}
                        countryLocation={driverItem.country}
                        providerId={driverItem.provider_id}
                        status={driverItem.status}
                        statusProfile={driverItem.status}
                        driverDoc={driverItem.driver_document}
                        guarantorDoc={driverItem.guarantor}
                        paramsId={params.providerId}
                        driverId={driverItem.id}
                    />
                )
            })
        }


        </main>       
    </>
  )
}

export default ApprovedServiceEdit