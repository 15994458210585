import React, { Fragment, useState, useEffect,  memo } from 'react'
import { BASE_URL } from "../utils/constant"

import Chart from "react-apexcharts";
import axios from "axios";
import getCookie from '../utils/SecuredTokens';
import { baseUrl, fetchData } from '../utils/fetchData';


 function ApexChart() {


  let userData = getCookie("user");
  let userObject = JSON.parse(userData)
  let token = userObject?.token


  const [transactions, setTransactions] = useState([]);



  useEffect(() => {
    let subscribed = true
    const fetchRevenue = async () => {
      let transData = await fetchData(`${baseUrl}/v1/fetch-total-revenue`);
      if (subscribed) {
        setTransactions(transData?.data?.pieChartData)
      }
    }
    fetchRevenue()

    // clean up the function
    return () => {
      subscribed = false;
    }
  }, [])

  console.log(transactions, 'trannnan')






  return React.createElement(Chart, {
    type: "pie",
    series: [transactions.grossProfit,
    transactions.serviceCost, transactions.vehicleCost, transactions.vat],
    width: "250",


    options: {
      labels: ["Gross Profit", "Service Cost", "Vehicle Cost", "VAT"],
      legend: {
        show: false
      },
      stroke: {
        show: false,

      },
      colors: ["#FFA100", "#74ABFF", "#FF3A3A", "#fff"],
      foreColor: '#000',

    }
  });
}

export default memo(ApexChart)