import React, { Fragment, useState, useEffect, useRef, useContext } from 'react'
import AdminCart from './AdminCart'
import Header from '../Header'
import driver_prado from '../../Images/driver3.png'
import prado from '../../Images/Prado.png'
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
// import { mealOffers } from '../../slices/specialOffers'
import cancelImg from '../../Images/cancel.svg'
import { mealOffers, ticketOffers } from '../../slices/specialOffers'
import { viewNotify } from '../../slices/Notification'
import CustomerContext from '../../Context/CustomerContext'



function AdminCartComponent() {
    let { userCarty } = useContext(CustomerContext)
    console.log(userCarty, 'context cart search')
    let navigate = useNavigate();
    const dispatch = useDispatch()

    const cart = JSON.parse(localStorage.getItem("cart"))
    let userData = JSON.parse(localStorage.getItem("user"))
    let token = userData?.token
    let userLoggedIn = userData?.id
    const [currentNoteLength, setCurrentNoteLength] = useState([])


    const [visible, setVisible] = useState(5);

    function showMore() {
        setVisible((prevValue) => prevValue + 5)
    }


    let sum = 0


    console.log('cartoooo', userCarty)

    // Get current Notifcation 
    useEffect(() => {
        dispatch(viewNotify()).then(
            (response) => {
                setCurrentNoteLength(response.payload.data)
                console.log(currentNoteLength.length)
            },
            (error) => {
                const message =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString()
                console.log(message)
            }
        )
    }, [])











    return (
        <div className="w-full">

            {
                userLoggedIn ? <>
                    <Header user='admin'  />
                </> : <Header user='customer' />
            }

         <div className="md:mb-40"></div>

            {/* IF DESTINATION IS NOT OUT OF STATE DISPLAY */}
            {(userCarty || []).length === 0 ?
                (<>
                    <h3 className="my-20 text-white text-center text-xl hidden ">Cart Empty</h3>
                </>) :
                (userCarty.slice(0, visible).map((cartItem, i) => {
                    //  couponCodeValue === (ticketData?.coupon_code) && ticketData?.status === "ACTIVE" ?  ((sum += Number(cartItem.amount)) - ((sum += Number(cartItem.amount)) * 0.1)) :  (sum += Number(cartItem.amount))

                    (sum += Number(cartItem.amount))

                    return (
                        <div  key={cartItem.cart_id}>
                            <AdminCart
                                
                                newBookingId={cartItem.newBookingId}
                                id={cartItem.id}
                                price={cartItem.amount}
                                total={cartItem.amount}
                                insurance={cartItem.insurance_price}
                                address={cartItem.pickup_location}
                                image={prado}
                                time={cartItem.pickup_time}
                                state={cartItem.destination}
                                destination_state={cartItem.destination_state}
                                days={cartItem.num_of_day}
                                date={cartItem.pickup_date}
                                title={cartItem.booking_title}
                                daily_price={cartItem.daily_price}
                                resumption_date={cartItem.resumption_date}
                                vehicleId={cartItem.vehicle_id}
                                receiverEmail={cartItem.giftcard_receiver_email}
                                receiverName={cartItem.giftcard_receiver_name}
                                numMonths={cartItem.month_value}
                            />
                        </div>
                    )
                }
                ))
            }


            {!userCarty?.length || userCarty?.length <= visible ? ('') : (
                        <div className="w-full flex items-center justify-center my-20">
                            <button
                                onClick={showMore}
                                className='border border-theme-color theme-color font-semibold p-3 text-sm rounded-lg w-36'>Load More</button>
                        </div>
                    )}































        </div>
    )
}

export default AdminCartComponent