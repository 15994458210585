import React from 'react'
import Header from './Header'
import google from '../Images/google.svg'
import fbb from '../Images/fbb.svg'
import usertheme from '../Images/usertheme.svg'
import closed from '../Images/closed.svg'
import onboard from '../Images/onboard.png'

import { NavLink, useNavigate } from "react-router-dom";

import car from '../Images/carr.png'
import getCookie from './utils/SecuredTokens'

function TransactionSuccess() {

    let userData = getCookie("user");
    let userObject;
    let token;
    let userLoggedIn;
    userData ? userObject = JSON.parse(userData) : userObject = ''
    userData ? token = JSON.parse(userData)?.token : token = ''
    userData ? userLoggedIn = JSON.parse(userData)?.id : userLoggedIn = ''


    console.log(userObject.lastname, 'userObject lssss')

    return (
        <div>

            <Header user='login_customer' />
            <div className="flex justify-center md:p-20 px-6 pt-20 p-6 flex-col items-center w-full pb-20 mb-20 relative">
                <h1 className=" text-white font-bold md:text-3xl text-lg my-8 ">Thank You!</h1>
                <div className="bg-white pb-16 md:w-2/6 w-full h-auto rounded-lg flex flex-col  justify-center items-center relative">
                    <img src={onboard} alt="" className='blurr w-full h-auto md:w-96 md:h-96 ' />

                    <div className="flex text-center items-center justify-center bg-amber-500 p-2 w-10/12">
                    {userObject.firstname === null || userObject.lastname===null ?
                       
                       
                        <NavLink to='/user-profile' className='md:text-base text-sm text-black font-bold rounded w-full m-auto'>Complete Profile</NavLink>:
                        <NavLink to='/customers-dashboard' className='md:text-base text-sm text-black font-bold rounded w-full m-auto'>Proceed to Dashboard</NavLink>
                    }
                    </div>

                    <img src={car} alt="" className='absolute md:-bottom-24 -bottom-12 -right-6 md:-right-20 md:w-56 w-36' />


                </div>


            </div>

        </div>
    )
}

export default TransactionSuccess