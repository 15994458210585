import React from 'react'
import Header from './Header'
import google from '../Images/google.svg'
import fbb from '../Images/fbb.svg'
import usertheme from '../Images/usertheme.svg'
import closed from '../Images/closed.svg'
import unsuccessful from '../Images/unsuccessful.png'


import car from '../Images/carr.png'

function TransactionFailed() {
    return (
        <div>

            <Header user='customer' />
            <div className="flex justify-center md:p-20 px-12 pt-20 p-6 flex-col items-center w-full pb-20 mb-20 relative">
                <h1 className=" text-white font-bold md:text-3xl text-lg mb-8">Something Went Wrong</h1>
                <div className="bg-white px-6 pb-16 md:w-2/6 w-full h-auto rounded-lg flex flex-col  justify-center items-center relative">
                    <img src={unsuccessful} alt="" className='blurr md:w-96 md:h-96 '/>

                    <button className='bg-amber-500 p-2 md:text-base text-sm text-black font-bold rounded w-full mt-4'>Try Again</button>
                  
                    <img src={car} alt="" className='absolute md:-bottom-24 -bottom-12 -right-10 md:-right-20 md:w-56 w-36' />

                </div>

               
            </div>

        </div>
    )
}

export default TransactionFailed