
import React, { Fragment, useState, useEffect, useContext, memo } from 'react'
import CustomerContext from '../../Context/CustomerContext'

import { Dialog, Transition } from '@headlessui/react'

const Notes = ({ note }) => {
  const [notes, setNotes] = useState(note);


  function pin() {

    notes.unshift(notes.message);
  }
  return (

    <div className="w-full rounded-lg border border-white bg-black pt-1 px-3 pb-3 mt-8">
      <button
        onClick={pin}
        className='text-right w-full text-xs text-white'>&bull;&bull;&bull;</button>
      <p className="text-white text-center  font-semibold">{note.message}</p>
    </div>

  )
}

export default memo(Notes)